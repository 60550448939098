/* @flow */
import {
    FETCH_ALERT_NAME,
    FETCH_ALERT_NAME_SUCCESS,
    FETCH_ALERT_NAME_ERROR,
} from './constants.landingPage';

const initialState = {
    alertNames: [],
    isLoading: false,
};

export type stateType = {
    alertNames: Array<Object>,
    isLoading: boolean,
};

export type actionType = {
    type: string,
    payload: {
        alertNames: {},
    },
};

export default (state: stateType = initialState, action: actionType) => {
    switch (action.type) {
    case FETCH_ALERT_NAME:
        return {
            ...state,
            isLoading: true,
        };
    case FETCH_ALERT_NAME_SUCCESS:
        return {
            ...state,
            isLoading: false,
            alertNames: action.payload.alertNames,
        };
    case FETCH_ALERT_NAME_ERROR:
        return {
            ...state,
            isLoading: false,
        };
    default:
        return state;
    }
};
