/* @flow */
import React from 'react';
import { Link } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { Tooltip } from '@mui/material';
import AppLoader from '../../../components/AppLoader';
import ListComponent from '../../../components/SharedComponents/ListComponent/ListComponent';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    isUpdatePreference,
    rearrangeColumns,
} from '../../../helper-classes/listUtil';
import MiniDrawer from '../../../components/SideMenu/SideMenuDrawer';
import config, { RECORDS_PER_PAGE } from '../../../constants/Config';
import IMPORT_HISTORY_PREF_KEY from './constants.history';
import analytics from '../../../analytics/index';
import cancelIcon from '../../../assets/icons/close.svg';
import { reduxConnect } from '../../../hoc';
import { convertDateTime, getTimeFormatAsperUserSettting, downloadFile, getData as fetchHistory, putData as cancelImportJob } from '../../../helper-classes/utility-functions';
import * as notificationActions from '../../../components/SharedComponents/NotificationHandler/actions.appNotifications';

const styles = () => ({
    errorColor: {
        color: '#F44336',
    },
});

type Props = {
    resetTable: Function,
    location: Object,
    history: {
        action: string,
        push: Function,
        location: {
            key: string,
            state: Object,
        },
    },
    classes: Object,
    removeInProgressImportJob: Function,
};

type State = {
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{}>,
        totalRecords: number,
        ischeckBox: boolean,
        isGroup: boolean,
        isEditable: boolean,
    },
    rowsPerPage: number,
    pageNumber: number,
    order: string,
    orderBy: string,
    loading: boolean,
    isUpdating: boolean,
};

class ImportHistory extends React.Component<Props, State> {
    userPreferences: any;
    locationKey: string;
    notificationCardClicked: boolean;
    firstCallObj: Object;

    constructor(props) {
        super(props);
        this.state = this.initState(true);
        this.userPreferences = null;
        this.locationKey = '';
        this.notificationCardClicked = false;
        this.firstCallObj = { isMountCall: false, orderBy: 'lastUpdated' };
    }

    componentDidMount() {
        const locationStateData = { ...this.props.history.location.state };
        if (locationStateData.statusType && (this.props.history.action === 'PUSH')) {
            this.firstCallObj.isMountCall = true;
            if (locationStateData.statusType === 'inprogress') this.firstCallObj.orderBy = 'status';
        }
        getUserPreference(this, IMPORT_HISTORY_PREF_KEY);
    }

    componentDidUpdate() {
        const isValidRedirectHistoryAction = ['PUSH', 'REPLACE'].includes(this.props.history.action);
        if (this.locationKey && isValidRedirectHistoryAction
            && this.props.history.location.key !== this.locationKey) {
            this.notificationCardClicked = true;
        }
        this.locationKey = this.props.history.location.key;

        if (isValidRedirectHistoryAction && this.notificationCardClicked) {
            const locationStateData = { ...this.props.history.location.state };
            if (locationStateData.statusType) {
                let orderBy = 'lastUpdated';
                if (locationStateData.statusType === 'inprogress') orderBy = 'status';
                this.getData(0, this.state.rowsPerPage, 'desc', orderBy, true, false);
            }
            this.notificationCardClicked = false;
        }
    }

    showLoader = loading => this.setState({ loading });

    initState = isLoading => ({
        tableData: {
            listHeaders: this.getListHeader(),
            isEditable: false,
            listData: [],
            totalRecords: 0,
            ischeckBox: false,
            isGroup: false,
        },
        rowsPerPage: RECORDS_PER_PAGE,
        pageNumber: 0,
        order: 'asc',
        orderBy: 'dateCreated',
        loading: isLoading,
        isUpdating: isLoading,
    });

    getData = (
        pageNumber: number = this.state.pageNumber,
        rowsPerPage: number = this.state.rowsPerPage || RECORDS_PER_PAGE,
        order: string = this.state.order,
        orderBy: string = this.state.orderBy,
        clearData: boolean = false,
        updatePreference: boolean = false,
    ) => {
        let stateToUpdate = {
            isUpdating: true,
            pageNumber,
            rowsPerPage,
            order: (this.firstCallObj.isMountCall) ? 'desc' : order,
            orderBy: (this.firstCallObj.isMountCall) ? this.firstCallObj.orderBy : orderBy,
        };

        if (clearData) {
            const { tableData } = this.state;
            tableData.listData = [];
            stateToUpdate = { ...stateToUpdate, tableData };
        }

        this.setState(stateToUpdate, () => {
            if (updatePreference) saveUserPreference(this, IMPORT_HISTORY_PREF_KEY);
            let url = `${config.get('FLEET_IMPORT_SERVICES_URL')}/importjobs?cb=${new Date().getTime()}&start=${pageNumber}&limit=${rowsPerPage}`;
            if (this.state.orderBy) url = `${url}&sort=${this.state.orderBy}:${this.state.order}`;
            this.firstCallObj.isMountCall = false;

            fetchHistory(url).then((res) => {
                if (res) {
                    const { tableData } = this.state;
                    tableData.listData = res.jobs.map(d => ({ ...d, id: d.jobId })) || [];
                    tableData.totalRecords = res.total || 0;
                    this.setState({
                        tableData,
                        loading: false,
                        isUpdating: false,
                    });
                } else this.setState({ ...this.initState(false) });
            }).catch(() => this.setState({ ...this.initState(false) }));
        });
    }

    downloadReport = (jobId, fileName) => {
        this.showLoader(true);
        const fileUrl = `${config.get('FLEET_IMPORT_SERVICES_URL')}/importjobs/${jobId}/download?name=${fileName}`;
        downloadFile(fileUrl, fileName, { type: 'csv' }, () => this.showLoader(false));
    }

    getEntityName = (entity, url = false) => {
        let name = '';
        switch (entity) {
        case 'USER':
            name = 'users';
            break;
        case 'LANDMARK_ADDRESS':
        case 'LANDMARK_LAT_LNG':
        case 'LANDMARK_POLYGON':
            name = 'landmarks';
            break;
        case 'ASSET':
            name = 'assets';
            break;
        case 'DRIVER':
            name = 'drivers';
            break;
        default:
            name = '';
            break;
        }
        if (!url) return name.charAt(0).toUpperCase() + name.slice(1);
        return name;
    }

    getStatus = (type) => {
        switch (type) {
        case 'CANCELLED': return 'Cancelled';
        case 'INPROGRESS': return 'In Progress';
        case 'COMPLETED': return 'Completed';
        case 'PROCESSING_STARTED': return 'In Progress';
        default: return type;
        }
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            { readUnread: 'Read/Unread', ...getColumnPreferences('readUnread', preferences), show: false },
            {
                dateCreated: 'Date/Time Submitted',
                ...getColumnPreferences('dateCreated', preferences),
                customMarkup: (d) => {
                    const timeFormat = (getTimeFormatAsperUserSettting() === '24h') ? 'MMM D, YYYY HH:mm' : 'MMM D, YYYY hh:mm A';
                    return convertDateTime(d.dateCreated, timeFormat);
                },
            },
            {
                lastUpdated: 'Date/Time Completed',
                ...getColumnPreferences('lastUpdated', preferences),
                customMarkup: (d) => {
                    if (d.status === 'INPROGRESS' || d.status === 'PROCESSING_STARTED') return 'In Progress';
                    const timeFormat = (getTimeFormatAsperUserSettting() === '24h') ? 'MMM D, YYYY HH:mm' : 'MMM D, YYYY hh:mm A';
                    return convertDateTime(d.lastUpdated, timeFormat);
                },
            },
            {
                createdBy: 'Submitted By',
                ...getColumnPreferences('submittedBy', preferences),
            },
            {
                success: 'Successful',
                ...getColumnPreferences('successful', preferences),
                disableSort: true,
                customMarkup: d => `${d.successRecords} of ${d.totalRecords}`,
            },
            {
                failed: 'Failed',
                disableSort: true,
                ...getColumnPreferences('failed', preferences),
                customMarkup: (d) => {
                    const link = (d.failedRecords > 0 && d.status.toLowerCase() !== 'inprogress') ? (
                        <Link to={`/import/${this.getEntityName(d.entityType, true)}/${d.jobId}`} className={this.props.classes.errorColor} >
                            {`${d.failedRecords} of ${d.totalRecords}`}
                        </Link>
                    ) : `${d.failedRecords} of ${d.totalRecords}`;
                    return link;
                },
            },
            {
                cancelled: 'Cancelled',
                disableSort: true,
                ...getColumnPreferences('cancelled', preferences),
                customMarkup: d => `${d.cancelledRecords} of ${d.totalRecords}`,
            },
            {
                fileName: 'Download',
                ...getColumnPreferences('fileName', preferences),
                disableSort: true,
                customMarkup: (d) => {
                    const link = (
                        <div
                            onClick={() => this.downloadReport(d.jobId, d.fileName)}
                            onKeyDown={() => {}}
                            role="link"
                            tabIndex="-1"
                            style={{
                                cursor: 'pointer',
                                outline: 'none',
                                border: 'none',
                                display: 'inline',
                                textDecoration: 'underline',
                                color: '#007aff',
                            }}
                        >
                            <Tooltip title={d.fileName || ''}><span>{d.fileName}</span></Tooltip>
                        </div>
                    );
                    return link;
                },
            },
            {
                entityType: 'Type',
                ...getColumnPreferences('entityType', preferences),
                customMarkup: data => this.getEntityName(data.entityType),
                disableSort: true,
            },
            {
                status: 'Status',
                ...getColumnPreferences('status', preferences),
                customMarkup: data => this.getStatus(data.status),
            },
        ];
        if (preferences) return getReArrangedTableHeaders(columns);
        return columns;
    }

    loadNextPage = (rows, value, order, orderBy) => this.getData(
        rows, value, order, orderBy, true,
        isUpdatePreference(this, { rowsPerPage: value, order, orderBy }),
    );

    rearrangeColumns = listHeaders =>
        rearrangeColumns(this, listHeaders, { prefKey: IMPORT_HISTORY_PREF_KEY });

    sortTableColumn = (order, orderBy, page) => {
        this.getData(page.pageNumber, page.rowsPerPage, order, orderBy, true, true);
        const params = {
            feature: 'Admin',
            sortColumn: orderBy,
        };
        analytics.track('IMPORTS_SORT', params);
    }

    refreshList = page => this.getData(page.page, page.rowsPerPage);

    listTypeContextAction = (action, page = { page: 0, rowsPerPage: RECORDS_PER_PAGE }) => {
        if (action === 'refresh') this.refreshList(page);
        return true;
    }

    cancelImportJob = (jobID, jobDetails) => {
        const url = `${config.get('FLEET_IMPORT_SERVICES_URL')}/importjobs/${jobID}/cancel`;
        cancelImportJob(url, {}, {}).then((res) => {
            if (res.jobId) {
                this.props.removeInProgressImportJob([{
                    jobId: jobDetails.jobId,
                    status: 'CANCELLED',
                    subStatus: res.status,
                    entityType: jobDetails.entityType,
                    fileName: jobDetails.fileName,
                }], this.props.history);
                this.getData();
            }
        });
    }

    render() {
        const { isUpdating } = this.state;
        const { history } = this.props;

        return (
            <React.Fragment>
                {this.state.loading && !isUpdating && <AppLoader type="fullScreen" />}
                <MiniDrawer redirectTo={history.push}>
                    <ListComponent
                        redirectTo={history.push}
                        tableData={this.state.tableData}
                        loadNextPage={this.loadNextPage}
                        isUpdating={isUpdating}
                        rearrangeColumns={this.rearrangeColumns}
                        resetTable={this.props.resetTable}
                        sortTableColumn={this.sortTableColumn}
                        tableTitle="Import History"
                        pathName={this.props.location.pathname}
                        tableButtons={['Refresh']}
                        listTypeContextAction={this.listTypeContextAction}
                        resizableHeader
                        showSearch={false}
                        pageNumber={this.state.pageNumber}
                        rowsPerPage={this.state.rowsPerPage}
                        order={this.state.order}
                        orderBy={this.state.orderBy}
                        rowActions={[{
                            label: 'Cancel Job',
                            clickAction: (id, data) => this.cancelImportJob(id, data),
                            icon: cancelIcon,
                            eleToCheck: 'status',
                            conditionTocheck: status => (status.toLowerCase() === 'inprogress' || status.toLowerCase() === 'processing_started'),
                        }]}
                    />
                </MiniDrawer>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(reduxConnect(ImportHistory, notificationActions));
