import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import styles from '../../Steppers//AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';

type Props = {
    formData: {
        config: {
            idleMinutes: Number,
            realert: Boolean,
            realertMinutes: Number,
        },
    },
    handleTextboxChange: Function,
    handleCheckboxChange: Function,
};

class IdleAlert extends React.Component<Props> {
    state = {};

    render() {
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    <div className={styles['step-rightside']}>
                        <form noValidate autoComplete="off">
                            <div>
                                <div>{ALERT_DETAILS.LABEL.IDLE_EVENT}</div>
                                <div>
                                    <TextField
                                        required
                                        margin="normal"
                                        className={styles['text-wid']}
                                        id="idleMinutes"
                                        name="idleMinutes"
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: ALERT_DETAILS.VALUE.IDL_MIN,
                                                max: ALERT_DETAILS.VALUE.IDL_MAX,
                                            },
                                        }}
                                        value={this.props.formData.config.idleMinutes}
                                        onChange={this.props.handleTextboxChange('idleMinutes')}
                                    />
                                    <span className={styles['unit-Name']}>{ALERT_DETAILS.LABEL.IDLE_MINUTES}*</span>
                                </div>
                            </div>
                            <div className={styles['div-pad']}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            id="realert"
                                            name="realert"
                                            checked={
                                                this.props.formData.config.realert === true
                                            }
                                            onChange={(e) => {
                                                this.props.handleCheckboxChange('realert')(e);
                                                if (!e.target.checked) {
                                                    this.props
                                                        .handleTextboxChange('realertMinutes')({
                                                            target: {
                                                                value:
                                                                    ALERT_DETAILS.VALUE.IDL_R_DEF,
                                                            },
                                                        });
                                                }
                                            }}
                                        />
                                    }
                                    label={ALERT_DETAILS.LABEL.IDLE_EVENT_REALERT}
                                />
                            </div>
                            <div>
                                <TextField
                                    required
                                    margin="normal"
                                    className={styles['text-wid']}
                                    id="realertMinutes"
                                    name="realertMinutes"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: ALERT_DETAILS.VALUE.IDL_R_MIN,
                                            max: ALERT_DETAILS.VALUE.IDL_R_MAX,
                                        },
                                    }}
                                    disabled={!this.props.formData.config.realert}
                                    value={this.props.formData.config.realertMinutes}
                                    onChange={this.props.handleTextboxChange('realertMinutes')}
                                />
                                <span className={styles['unit-Name']}>{ALERT_DETAILS.LABEL.IDLE_MINUTES}</span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default IdleAlert;
