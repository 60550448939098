/* @flow */

import React, { Component } from 'react';
import { reduxConnect } from '../../../hoc/index';
import * as actions from './actions.alertList';
import MiniDrawer from '../../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../../components/SharedComponents/ListComponent/ListComponent';
import MaintenanceFilterForm from '../../../components/Maintenance/MaintenanceFilterForm';
import MaintenanceFilterHelper from '../../../components/Maintenance/MaintenanceFilterHelper';
import { RECORDS_PER_PAGE } from '../../../constants/Config';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    showHideColumns,
    rearrangeColumns,
    isUpdatePreference,
} from '../../../helper-classes/listUtil';
import AppLoader from '../../../components/AppLoader';
import { ALERT_LIST_PREF_KEY } from './constants.alertList';
import analytics from '../../../analytics';

export type Props = {
    isUpdating: boolean,
    fetchAlertList: Function,
    resetTable: Function,
    history: {
        push: Function,
    },
};

export type State = {
    tableData: {
        listHeaders: any,
        listData: Array<{}>,
        totalRecords: number,
    },
    order: string,
    orderBy: string,
    rowsPerPage: number,
    selectedFilters: Array<{
        id: string,
        property: string,
        data: {
            id: string
        },
    }>,
    showListing: boolean,
};

class AlertList extends Component<Props, State> {
    userPreferences: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            tableData: {
                listHeaders: this.getListHeader(),
                isGroup: false,
                listData: [],
                totalRecords: 0,
            },
            order: 'desc',
            orderBy: 'alertDate',
            rowsPerPage: RECORDS_PER_PAGE,
            selectedFilters: MaintenanceFilterHelper.alertListPreselects,
            showListing: false,
        };
    }

    UNSAFE_componentWillMount() {
        getUserPreference(this, ALERT_LIST_PREF_KEY);
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (nextprops.alerts.length > 0) {
            const { alerts, totalAlerts } = nextprops;
            const { tableData } = this.state;
            tableData.listData = alerts;
            tableData.totalRecords = totalAlerts;
            this.setState({
                tableData,
            });
        } else {
            const { tableData } = this.state;
            tableData.listData = [];
            tableData.totalRecords = 0;
            this.setState({
                tableData,
            });
        }
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            { alertDate: 'Date', ...getColumnPreferences('alertDate', preferences) },
            { alertTime: 'Time', ...getColumnPreferences('alertTime', preferences) },
            { alertTypeName: 'Alert Type', ...getColumnPreferences('alertTypeName', preferences), disableSort: true },
            { alertSpecName: 'Service Plan Name', ...getColumnPreferences('alertSpecName', preferences), disableSort: true },
            { assetName: 'Asset', ...getColumnPreferences('assetName', preferences), disableSort: true },
            { smsOutput: 'Details', ...getColumnPreferences('smsOutput', preferences), disableSort: true },
            { locationName: 'Location', ...getColumnPreferences('locationName', preferences), disableSort: true },
        ];
        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    getData = () => {
        this.props.fetchAlertList(
            0,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.state.selectedFilters,
        );
        this.setState({ showListing: true });
    }

    sortTableColumn = (order, orderBy, page) => {
        this.setState({ order, orderBy, rowsPerPage: page.rowsPerPage }, () => {
            saveUserPreference(this, ALERT_LIST_PREF_KEY);
        });
        this.props.fetchAlertList(
            page.pageNumber,
            page.rowsPerPage,
            order, orderBy,
            this.state.selectedFilters,
        );
        const params = {
            feature: 'Maintenance',
            sortColumn: orderBy,
        };
        analytics.track('ALERTS_SORT', params);
    }

    onFiltersSelected = (selectedFilters) => {
        this.props.fetchAlertList(
            0,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            selectedFilters,
        );
        this.setState({ selectedFilters });
        let assets = true;
        let date = '';
        let plans = true;
        selectedFilters.forEach((x) => {
            if (x.property === 'assetId') {
                assets = false;
            } if (x.property === 'filterDate') {
                date = x.label;
            } if (x.property === 'servicePlanId') {
                plans = false;
            }
        });
        const params = {
            feature: 'Maintenance',
            allAssets: assets,
            dateRange: date,
            allPlans: plans,
        };
        analytics.track('ALERTS_FILTER', params);
    }

    filterComponent = () => {
        const { selectedFilters } = this.state;
        const { dateRangeFilter } = MaintenanceFilterHelper;
        const filterOptions = [];
        const dateRangeSelected = MaintenanceFilterHelper.dateRangeSelected(selectedFilters);
        dateRangeFilter.subgroupsHidden = !dateRangeSelected;
        filterOptions.push(dateRangeFilter);
        filterOptions.push(MaintenanceFilterHelper.assetFilter);
        filterOptions.push(MaintenanceFilterHelper.servicePlanFilter);
        return (
            <MaintenanceFilterForm
                filters={filterOptions}
                selectedFilters={this.state.selectedFilters}
                defaultSelections={MaintenanceFilterHelper.alertListPreselects}
                onFilterSelected={this.onFiltersSelected}
            />
        );
    }

    listTypeContextAction = (action) => {
        if (action === 'filter') {
            return this.filterComponent();
        }
        return true;
    }

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: ALERT_LIST_PREF_KEY };
        rearrangeColumns(this, listHeaders, options);
    }

    showHideColumn = (header) => {
        const options = { prefKey: ALERT_LIST_PREF_KEY };
        showHideColumns(this, header, options);
    }

    render() {
        return (
            <MiniDrawer redirectTo={this.props.history.push}>
                {this.state.showListing ?
                    <ListComponent
                        redirectTo={this.props.history.push}
                        tableData={this.state.tableData}
                        loadNextPage={(page, rowsPerPage, order, orderBy) => {
                            const updateUserPref = isUpdatePreference(
                                this,
                                { rowsPerPage, order, orderBy },
                            );
                            this.setState({ order, orderBy, rowsPerPage }, () => {
                                if (updateUserPref) {
                                    saveUserPreference(this, ALERT_LIST_PREF_KEY);
                                }
                            });
                            this.props.fetchAlertList(
                                page,
                                rowsPerPage,
                                order,
                                orderBy,
                                this.state.selectedFilters,
                            );
                        }}
                        rearrangeColumns={this.rearrangeColumns}
                        resetTable={this.props.resetTable}
                        isUpdating={this.props.isUpdating}
                        showHideColumn={this.showHideColumn}
                        sortTableColumn={this.sortTableColumn}
                        tableTitle="Maintenance Alerts"
                        tableButtons={['Filter List']}
                        filtersApplied={this.state.selectedFilters.length > 1 ||
                            this.state.selectedFilters[0].data.id !== 5}
                        resizableHeader
                        showSearch={false}
                        listTypeContextAction={this.listTypeContextAction}
                        orderBy={this.state.orderBy}
                        order={this.state.order}
                        rowsPerPage={this.state.rowsPerPage}
                    />
                    :
                    <div style={{ height: '100%' }}>
                        <AppLoader type="fullScreen" />
                    </div>
                }
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => ({
    alerts: state.alertList.alerts,
    totalAlerts: state.alertList.totalAlerts,
    isUpdating: state.alertList.isUpdating,
});

export default reduxConnect(AlertList, actions, mapStateToProps);
