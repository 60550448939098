/* @flow */

import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.userRoleList';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../components/SharedComponents/ListComponent/ListComponent';
import { RECORDS_PER_PAGE } from '../../constants/Config';
import { getParamValue } from '../../helper-classes/utility-functions';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    isUpdatePreference,
} from '../../helper-classes/listUtil';
import UserRole from './../UserRole/AddUserRole/UserRole';
import AppLoader from '../../components/AppLoader';
import { USER_ROLES_PREF_KEY } from './constants.userRoleList';
import analytics from '../../analytics/index';

export type Props = {
    isUpdating: boolean,
    fetchUserRolesList: Function,
    resetTable: Function,
    location: Object,
    classes : Object,
    history: {
        push: Function,
    },
    match: {
        path: any,
    },
};

export type State = {
    tableData: {
        listHeaders: Array<{show : boolean}>,
        listData: Array<{}>,
        totalRecords: number,
        ischeckBox: boolean,
        isGroup: boolean,
        isEditable: boolean,
    },
    rowsPerPage: number,
    pageNumber: number,
    order: string,
    orderBy: string,
    isAddEdit: boolean,
    showListing: boolean,
};

const themeStyles = () => ({
    checked: {
        color: '#007aff',
    },
    label: {
        color: '#696969',
    },
    hide: {
        display: 'none',
    },
});

class UserRoleList extends Component<Props, State> {
    search: string;
    id: string;
    addUrl: string;
    listUrl: string;
    editUrl: string;
    addUrl: string;
    userPreferences: any;
    constructor(props: Props) {
        super(props);
        let isAddEdit = false;
        this.addUrl = '/user-roles/add';
        this.listUrl = '/user-roles';
        this.editUrl = '/user-roles/edit/:id';
        if (getParamValue(this.props, 'id') || this.props.match.path === this.addUrl) {
            isAddEdit = true;
        }
        this.state = {
            tableData: {
                listHeaders: this.getListHeader(),
                isEditable: true,
                listData: [],
                totalRecords: 0,
                ischeckBox: false,
                isGroup: false,
            },
            rowsPerPage: RECORDS_PER_PAGE,
            pageNumber: 0,
            order: 'asc',
            orderBy: 'name',
            isAddEdit,
            showListing: false,
        };
        this.search = '';
        this.userPreferences = null;
    }

    UNSAFE_componentWillMount() {
        if (!this.state.isAddEdit) {
            getUserPreference(this, USER_ROLES_PREF_KEY);
        }
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (nextprops.userRole.length > 0) {
            const { userRole, totalUserRole } = nextprops;
            const { tableData } = this.state;
            const formatUserRoleList = userRole.map((item => ({
                ...item,
                // Commenting Messaging permissions until MVP.
                // Need to remove this format function when we need messaging
                permissionsDigest: item.permissionsDigest ? item.permissionsDigest.replace('Messaging Section:None,', ' ').replace('Messaging Section:View,', ' ').replace('Messaging Section:Modify,', ' ').replace('Compliance Section:Manager,', 'Compliance Section:Modify,')
                    .replace('Dashcam Section:Modify', 'CamCoach Section:Modify')
                    .replace('Dashcam Section:None', 'CamCoach Section:None') : '',
            })));
            tableData.listData = formatUserRoleList;
            tableData.totalRecords = totalUserRole;
            this.setState({ tableData });
        } else if (!this.state.isAddEdit) {
            const { tableData } = this.state;
            tableData.listData = [];
            tableData.totalRecords = 0;
            this.setState({ tableData });
        }
    }

    componentDidUpdate() {
        let { isAddEdit } = this.state;
        if (isAddEdit && this.props.match.path === this.listUrl) {
            isAddEdit = false;
            this.getData();
            this.updateEditState(isAddEdit);
        } else if (!isAddEdit &&
            (this.props.match.path === this.editUrl ||
            this.props.match.path === this.addUrl)) {
            isAddEdit = true;
            this.updateEditState(isAddEdit);
        }
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            { name: 'Name', ...getColumnPreferences('name', preferences) },
            { description: 'Comments', ...getColumnPreferences('description', preferences) },
            { permissionsDigest: 'Permissions', disableSort: true, ...getColumnPreferences('permissionsDigest', preferences) },
            { accountUserCount: 'User Count', disableSort: true, ...getColumnPreferences('accountUserCount', preferences) },
            { scope: 'Created By', ...getColumnPreferences('scope', preferences) },
        ];
        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    updateEditState = (isAddEdit) => {
        this.setState({ isAddEdit });
    }


    getData = () => {
        this.props.fetchUserRolesList(
            this.state.pageNumber,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.getFilterData(),
        );
        this.setState({ showListing: true });
    }

    updateState = (order, orderBy, rowsPerPage, pageNumber) => {
        const updateUserPref = isUpdatePreference(this, { rowsPerPage, order, orderBy });
        this.setState({
            order,
            orderBy,
            rowsPerPage,
            pageNumber,
        }, () => {
            if (updateUserPref) {
                saveUserPreference(this, USER_ROLES_PREF_KEY);
            }
        });
    }

    loadNextPage = (
        rows,
        value,
        order,
        orderBy,
    ) => {
        this.updateState(order, orderBy, value, rows);
        this.props.fetchUserRolesList(
            rows,
            value,
            order,
            orderBy,
            this.getFilterData(),
        );
    }

    sortTableColumn = (order, orderBy, page) => {
        this.props.fetchUserRolesList(
            page.pageNumber,
            page.rowsPerPage,
            order,
            orderBy,
            this.getFilterData(),
        );
        this.updateState(order, orderBy, page.rowsPerPage, page.pageNumber);
        const params = {
            feature: 'Admin',
            sortColumn: orderBy,
        };
        analytics.track('USER_ROLES_SORT', params);
    }

    refreshList = (page) => {
        this.props.fetchUserRolesList(
            page.page,
            page.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            { search: this.search },
            this.getFilterData(),
        );
    }

    listTypeContextAction = (action, page = { page: 0, rowsPerPage: RECORDS_PER_PAGE }) => {
        if (action === 'refresh') {
            this.refreshList(page);
        } else if (action === 'add') {
            this.props.history.push(`${this.props.location.pathname}/add`);
        }
        return true;
    }

    ListSearch = (order, orderBy, filter) => {
        this.search = filter.search || '';
        this.props.fetchUserRolesList(
            filter.pageNumber,
            filter.rowsPerPage,
            order, orderBy,
            this.getFilterData(),
        );
        this.updateState(order, orderBy, filter.rowsPerPage, filter.pageNumber);
    }

    getFilterData = () => ({
        search: this.search || '',
    })

    clickRow = (n:any) => {
        this.props.history.push(`/user-roles/edit/${n.id}`);
    };

    rearrangeColumns = (listHeaders) => {
        const { tableData } = this.state;
        tableData.listHeaders = listHeaders;
        this.setState({
            tableData,
        }, () => {
            saveUserPreference(this, USER_ROLES_PREF_KEY);
        });
    }

    showHideColumn = (header) => {
        const { tableData } = this.state;
        tableData.listHeaders.some((h) => {
            const newH = h;
            if (h[Object.keys(h)[0]] === header[Object.keys(header)[0]]) {
                newH.show = !header.show;
                return true;
            }
            return false;
        });
        this.setState({
            tableData,
        }, () => {
            saveUserPreference(this, USER_ROLES_PREF_KEY);
        });
    }

    render() {
        const { isAddEdit } = this.state;
        const { classes } = this.props;
        return (
            <MiniDrawer
                redirectTo={this.props.history.push}
            >
                {isAddEdit &&
                    <UserRole />
                }
                <div className={(!isAddEdit) ? '' : classes.hide}>
                    {this.state.showListing ?
                        <ListComponent
                            redirectTo={this.props.history.push}
                            tableData={this.state.tableData}
                            loadNextPage={this.loadNextPage}
                            isUpdating={this.props.isUpdating}
                            rearrangeColumns={this.rearrangeColumns}
                            resetTable={this.props.resetTable}
                            showHideColumn={this.showHideColumn}
                            sortTableColumn={this.sortTableColumn}
                            ListSearch={this.ListSearch}
                            clickRow={this.clickRow}
                            listTypeContextAction={this.listTypeContextAction}
                            tableTitle="User Roles"
                            pathName={this.props.location.pathname}
                            tableButtons={['Refresh', /* 'Edit', */ 'Add Items']}
                            resizableHeader
                            orderBy={this.state.orderBy}
                            order={this.state.order}
                            rowsPerPage={this.state.rowsPerPage}
                        />
                        :
                        <div style={{ height: '100%' }}>
                            <AppLoader type="fullScreen" />
                        </div>
                    }
                </div>
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => ({
    userRole: state.userRoleList.userRoles,
    totalUserRole: state.userRoleList.totalUserRoles,
    isUpdating: state.userRoleList.isUpdating,
    orderBy: state.orderBy,
});

export default withStyles(themeStyles)(reduxConnect(UserRoleList, actions, mapStateToProps));
