/* @flow */

import { ADD_LANDMARK, ADD_LANDMARK_SUCCESS, CLOSE_LANDMARK_EDIT_DIALOG, ADD_LANDMARK_ERROR, FETCH_LANDMARK, DELETE_LANDMARK, GET_LANDMARK_GROUPS, GET_LANDMARK_GROUPS_ERROR, GET_LANDMARK_GROUPS_SUCCESS, FETCH_LANDMARK_ERROR, FETCH_LANDMARK_SUCCESS, UPDATE_LANDMARK_LOADER, UPDATE_LANDMARK, UPDATE_LANDMARK_SUCCESS, UPDATE_LANDMARK_ERROR, DELETE_LANDMARK_SUCCESS, DELETE_LANDMARK_ERROR } from './constants.landmark';
import type { LandmarkTypeAction } from './constants.landmark';

export const addLandmark = (data: Object) => ({
    type: ADD_LANDMARK,
    payload: { data },
});

export const addLandmarkSuccess = (data: Object) => ({
    type: ADD_LANDMARK_SUCCESS,
    payload: { data },
});

export const addLandmarkError = (error: Object) => ({
    type: ADD_LANDMARK_ERROR,
    payload: { error },
});

export const closeDialog = () => ({
    type: CLOSE_LANDMARK_EDIT_DIALOG,
    payload: {},
});

export const updateLandmark = (data: Object) => ({
    type: UPDATE_LANDMARK,
    payload: { data },
});

export const updateLandmarkSuccess = (response: Object) => ({
    type: UPDATE_LANDMARK_SUCCESS,
    payload: { response },
});

export const updateLandmarkError = (error: Object) => ({
    type: UPDATE_LANDMARK_ERROR,
    payload: { error },
});


export const updateLoader = (loading: boolean) => ({
    type: UPDATE_LANDMARK_LOADER,
    payload: { loading },
});

export const fetchLandmark = (id: string): LandmarkTypeAction => ({
    type: FETCH_LANDMARK,
    payload: { id },
});

export const fetchLandmarkSuccess = (response: Object): LandmarkTypeAction => ({
    type: FETCH_LANDMARK_SUCCESS,
    payload: { landmark: response },
});

export const fetchLandmarkError = (error: Object): LandmarkTypeAction => ({
    type: FETCH_LANDMARK_ERROR,
    payload: { error },
});

export const deleteLandmark = (id: string): LandmarkTypeAction => ({
    type: DELETE_LANDMARK,
    payload: { id },
});

export const deleteLandmarkSuccess = (): LandmarkTypeAction => ({
    type: DELETE_LANDMARK_SUCCESS,
});

export const deleteLandmarkError = (error: Object): LandmarkTypeAction => ({
    type: DELETE_LANDMARK_ERROR,
    payload: { error },
});

export const getlandmarkGroups = (): LandmarkTypeAction => ({
    type: GET_LANDMARK_GROUPS,
});

export const getlandmarkGroupsSuccess = (response: Object): LandmarkTypeAction => ({
    type: GET_LANDMARK_GROUPS_SUCCESS,
    payload: { landmarkGroup: response },
});

export const getlandmarkGroupsError = (error: Object): LandmarkTypeAction => ({
    type: GET_LANDMARK_GROUPS_ERROR,
    payload: { error },
});

