/* @flow */

export const GRAILS_FETCH_ASSETS: string = 'GRAILS_FETCH_ASSETS';
export const GRAILS_FETCH_ASSETS_SUCCESS: string = 'GRAILS_FETCH_ASSETS_SUCCESS';
export const GRAILS_FETCH_ASSETS_ERROR: string = 'GRAILS_FETCH_ASSETS_ERROR';
export const GRAILS_POST_ASSETS: string = 'GRAILS_POST_ASSETS';
export const GRAILS_FETCH_SELECTED_ASSETS: string = 'GRAILS_FETCH_SELECTED_ASSETS';
export const GRAILS_FETCH_SELECTED_ASSETS_SUCCESS: string = 'GRAILS_FETCH_SELECTED_ASSETS_SUCCESS';
export const GRAILS_POST_ASSETS_GROUP_SUCCESS: string = 'GRAILS_POST_ASSETS_GROUP_SUCCESS';
export const FETCH_ASSET_GROUP_DETAILS_GLOBALID = 'FETCH_ASSET_GROUP_DETAILS_GLOBALID';
export const FETCH_ASSET_GROUP_DETAILS_GLOBALID_SUCCESS = 'FETCH_ASSET_GROUP_DETAILS_GLOBALID_SUCCESS';
export const DELETE_ASSET_GROUP_GLOBALID = 'DELETE_ASSET_GROUP_GLOBALID';
export const DELETE_ASSET_GROUP_GLOBALID_SUCCESS = 'DELETE_ASSET_GROUP_GLOBALID_SUCCESS';
export const ASSET_AND_SELECTED_ASSET = 'ASSET_AND_SELECTED_ASSET';
export const GRAILS_FETCHASSETS_AND_SELECTED_ASSETS_SUCCESS = 'GRAILS_FETCHASSETS_AND_SELECTED_ASSETS_SUCCESS';
export const GRAILS_FETCHASSETS_AND_SELECTED_ASSETS_ERROR = 'GRAILS_FETCHASSETS_AND_SELECTED_ASSETS_ERROR';
export const CLOSE_DIALOG: string = 'CLOSE_DIALOG';
export const SHOW_LOADER: string = 'SHOW_LOADER';
export const ASSET_PER_PAGE: number = 50;
export const AVAILABLE_ASSET_FILTER: Array<any> = [['assetGroupId', 'exists:no'], ['deviceId', 'exists:yes']];
export const SELECTED_ASSET_LIMIT: number = 500;
export type AssetGroupManageAction = {
    type: string;
    payload?: Object;
}
