/* eslint jsx-a11y/no-noninteractive-element-interactions: 0 */
/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import customstyles from './AddressDetails.module.scss';
import DetailActions from './DetailActions';
import { getAddressLine1, getAddressLine2, getPermissionValue } from './../../helper-classes/utility-functions';

const styles = () => ({
});

export type Props = {
    redirectTo: Function,
    nearestAsset: Function,
    isLoader: boolean,
    addressData: {
        address: Object,
        landmarkName: string,
        isPresent: boolean,
        subAddress: string,
        landmarkId: string,
    }
};

export type State = {
};

class AddressDetails extends Component<Props, State> {
    props: Props;
    state = {
    };

    getFormattedAddress = (address: any) => {
        const formattedAddress = (
            <React.Fragment>
                <Typography id="address-header" className={customstyles['address-header']} component="p">
                    {getAddressLine1(address)}
                </Typography>
                <Typography id="sub-header" className={customstyles['sub-header']} component="p">
                    {getAddressLine2(address)}
                </Typography>
            </React.Fragment>
        );
        return formattedAddress;
    };
    render() {
        const { addressData, isLoader } = this.props;
        return (
            <div className="app">
                {addressData && !isLoader ?
                    <div id="address-details-container" className={customstyles['address-details']} >
                        <Card raised={false} id="header-card" className={customstyles.card}>
                            <CardContent className={customstyles['card-content']}>
                                {(this.props.addressData.isPresent) &&
                                    <Typography id="address-landmark-name" className={customstyles['address-header']} paragraph component="p">
                                        {this.props.addressData.landmarkName}
                                    </Typography>
                                }
                                {(!this.props.addressData.isPresent) &&
                                    this.getFormattedAddress(this.props.addressData.address)
                                }
                                {(this.props.addressData.isPresent) &&
                                    <React.Fragment>
                                        <Typography id="address-header" className={customstyles['address-header']} component="p">
                                            {this.props.addressData.address}
                                        </Typography>
                                        <Typography id="sub-header" className={customstyles['sub-header']} component="p">
                                            {this.props.addressData.subAddress}
                                        </Typography>
                                    </React.Fragment>
                                }
                            </CardContent>
                        </Card>
                        <Divider />
                        {(getPermissionValue('Assets') !== 'None' || getPermissionValue('Landmarks') === 'Modify') &&
                        <DetailActions
                            landmarkInfo={this.props.addressData}
                            redirectTo={this.props.redirectTo}
                            nearestAsset={this.props.nearestAsset}
                            redirectLink={this.props.addressData.isPresent ? `/landmarks/edit/${this.props.addressData.landmarkId}` : '/landmarks/add?isGeoAddress=true'}
                        />
                        }
                    </div>
                    :
                    <div>Loading data...</div>
                }
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(AddressDetails);
