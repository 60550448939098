/* @flow */

import { FETCH_USER_ROLES_LIST, FETCH_USER_ROLES_LIST_SUCCESS, FETCH_USER_ROLES_LIST_ERROR, RESET_USER_ROLES_LIST } from './constants.userRoleList';
import type { UserRoleListTypeAction } from './constants.userRoleList';

export const fetchUserRolesList = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filter: string,
): UserRoleListTypeAction => ({
    type: FETCH_USER_ROLES_LIST,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
        filter,
    },
});

export const fetchUserRolesListSuccess =
    (userRoles: any, totalUserRoles: number): UserRoleListTypeAction => ({
        type: FETCH_USER_ROLES_LIST_SUCCESS,
        payload: { userRoles, totalUserRoles },
    });

export const fetchUserRolesListError = (error: string): UserRoleListTypeAction => ({
    type: FETCH_USER_ROLES_LIST_ERROR,
    payload: { error },
});

export const resetTable =
    (): UserRoleListTypeAction => ({
        type: RESET_USER_ROLES_LIST,
    });
