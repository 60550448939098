/* @flow */
import { FETCH_USER_ACCESS_LIST_ERROR, FETCH_USER_ACCESS_LIST_SUCCESS, FETCH_USER_ACCESS_LIST, RESET_USER_ACCESS_LIST } from './constants.userAccessList';

const initialState = {
    userAccess: [],
    isUpdating: false,
};

type stateType = {
    userAccess: Array<Object>,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        userAccess:{},
        totalUserAccess: number,
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_USER_ACCESS_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case FETCH_USER_ACCESS_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            userAccess: action.payload.userAccess,
            totalUserAccess: action.payload.totalUserAccess,
        };
    case FETCH_USER_ACCESS_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    case RESET_USER_ACCESS_LIST:
        return {
            ...state,
            isUpdating: false,
            userAccess: [],
            totalUserAccess: 0,
        };
    default:
        return state;
    }
};
