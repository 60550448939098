/* @flow */

import React, { Component } from 'react';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { Grow, Zoom, InputAdornment, Grid, TextField, FormGroup, FormControlLabel, Checkbox, Collapse, Chip, Avatar, MenuList, Card, CardHeader, CardContent, CardActions } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import filter2Icon from '../../../assets/icons/assets_List/filter2.svg';
import editIcon from '../../../assets/icons/assets_List/edit.svg';
import csvIcon from '../../../assets/icons/assets_List/csv.svg';
import DropDown from '../../../components/Common/dropDown';
import Dialog from './../../../components/SharedComponents/Dialog/Dialog';
import searchIcon from '../../../assets/icons/search.svg';
import backArrow from '../../../assets/icons/black_arrow.svg';
import ToolbarAutosuggest from './ToolbarAutosuggest';
import RadioSelector from '../../Common/RadioSelector';
import { getTitle } from './../../../helper-classes/utility-react-functions';
import GetSvgIcon from './../../../util/svgImage_util';
import analytics from '../../../analytics/index';
import ShowHideColumnComponent from './ShowHideColumnComponent';
import { getPermissionValue } from './../../../helper-classes/utility-functions';
import SearchBox from './../../SharedComponents/SearchBox';

export type Props = {
    onToolbarIconClick: Function,
    openImport: Function,
    numSelected: number,
    toolbarTitle: string,
    classes: any,
    tbButtons: Array<string>,
    advanceFilter: Function,
    listHeaders: any,
    ListSearch: Function,
    toolbarSubTitle: string,
    showColumnDropdown: boolean,
    showHideColumn: Function,
    showToolbarBackButton: boolean,
    backClick: Function,
    showSearch: boolean,
    autoSuggestive: Object,
    tableDataCount: number,
    filterApplied: boolean,
    dialogboxInfo: Object,
    userPermission: string,
    headerDropDown: {
        onChange: Function,
        showDropDown: boolean,
        selectedValue: string,
    },
    radioSelectorOptions: Object,
    tableMenu: {
        showTableMenu: boolean,
        title: string,
        options: Array<{
            key: string,
            lable: string,
        }>,
        selected: string,
        onChange: Function,
    },
    searchKey: string,
    savedAdvancedFilter: any,
}

export type State = {
    isSearch: boolean,
    search: string,
    key: number,
    needToRefresh: boolean,
    toggle: boolean,
    advancedFilterData: Array<Object>,
    isAdvancedFilterApplied: boolean,
    open: boolean,
    openPopper: boolean,
    tbButton: string,
    openColumn: boolean,
    selectedOption: Object,
};

// if more then one page have advanceFilter then pass this data from
// respective component and add more case if needed

const getOptions = () => ([
    {
        lable: 'CSV',
        value: 'csv',
        key: 'CSV',
    },
    {
        lable: 'XLS',
        value: 'xls',
        key: 'XLS',
    },
    {
        lable: 'PDF',
        value: 'pdf',
        key: 'PDF',
    },
]);

const advancedFilter = [
    {
        id: 'name',
        type: 'input',
        lable: 'Asset Name',
        value: '',
        autoFocus: false,
    },
    {
        id: 'assetGroupName',
        type: 'input',
        lable: 'Group',
        value: '',
        autoFocus: false,
    },
    {
        id: 'deviceSerialNumber',
        type: 'input',
        lable: 'Serial Number',
        value: '',
        autoFocus: false,
    },
    {
        id: 'vin',
        type: 'input',
        lable: 'VIN',
        value: '',
        autoFocus: false,
    },
    {
        id: 'year',
        type: 'input',
        lable: 'Year',
        value: '',
        autoFocus: false,
    },
    {
        id: 'make',
        type: 'input',
        lable: 'Make',
        value: '',
        autoFocus: false,
    },
    {
        id: 'model',
        type: 'input',
        lable: 'Model',
        value: '',
        autoFocus: false,
    },
    {
        id: 'tags',
        type: 'input',
        lable: 'Tag',
        value: '',
        autoFocus: false,
    },
    // {
    //     type: 'checkboxGroup',
    //     value: [
    //         {
    //             id: 'moving',
    //             label: 'Moving',
    //             value: false,
    //         },
    //         {
    //             id: 'idle',
    //             label: 'Idle',
    //             value: false,
    //         },
    //         {
    //             id: 'stopped',
    //             label: 'Stopped',
    //             value: false,
    //         },
    //     ],
    // },
];
const marginRight = 17;
const marginBottom = 0;
const minimumChar = 3;

const isAdvancedSearch = tbButtons => tbButtons.indexOf('Advanced Search') > -1;

class EnhancedTableToolbar extends Component <Props, State> {
    oldSearchValue: Array<Object>;
    anchorEl: any;
    popperEl: any;
    anchorElColumn: any;
    dialogButtons: any;
    options: Array<Object>;
    constructor(props) {
        super(props);
        let isSearchKeyPresent = false;
        if (this.props.searchKey && this.props.searchKey.length > 0) {
            isSearchKeyPresent = true;
        }
        this.state = {
            isSearch: isSearchKeyPresent,
            search: this.props.searchKey || '',
            key: Math.random(),
            needToRefresh: isSearchKeyPresent,
            toggle: false,
            advancedFilterData: this.processSavedAdvancedFilter(),
            isAdvancedFilterApplied:
                (this.props.savedAdvancedFilter && this.props.savedAdvancedFilter.length > 0),
            open: isSearchKeyPresent,
            openPopper: false,
            tbButton: '',
            openColumn: false,
            selectedOption: this.props.dialogboxInfo.selectedOption || {},
        };
        this.options = this.props.dialogboxInfo.options || getOptions();
        this.anchorEl = null;
        this.popperEl = null;
        this.anchorElColumn = null;
        this.dialogButtons = [{
            title: 'Cancel',
            handleClick: () =>
                this.props.dialogboxInfo.dialogHandleClick('hide', this.state.selectedOption),
        },
        {
            title: 'Download',
            color: 'primary',
            variant: 'contained',
            href: this.props.dialogboxInfo.href || '',
            handleClick: () =>
                this.props.dialogboxInfo.dialogHandleClick('download', this.state.selectedOption),
        },
        ];
    }

    showPopover = (open, tbButton, event) => {
        this.popperEl = event.currentTarget;
        this.setState({ openPopper: true, tbButton });
    };

    createButtons = () => {
        const {
            tableDataCount,
            numSelected,
            classes,
            userPermission,
            openImport,
            filterApplied = false,
            onToolbarIconClick,
            tbButtons,
        } = this.props;
        const buttons = [];
        const imgMap = {
            Export: tableDataCount > 0 ? <GetSvgIcon type="sort-desc" /> : <GetSvgIcon type="sort-desc-disabled" />,
            Import: typeof openImport === 'function' ? <GetSvgIcon type="sort-asc" /> : <GetSvgIcon type="sort-asc-disabled" />,
            Refresh: <GetSvgIcon type="refresh" />,
            Map: filter2Icon,
            'Filter List': <FilterListIcon style={{ fontSize: 27 }} color={(filterApplied) ? 'primary' : 'inherit'} />,
            'Add Items': <GetSvgIcon type="add" />,
            Delete: (
                <GetSvgIcon type="delete" style={{ fontSize: 19, paddingBottom: 1 }} fillcolor={numSelected <= 0 ? 'rgba(0, 0, 0, 0.26)' : '#000'} />
            ),
            Edit: editIcon,
            csvSvg: csvIcon,
        };

        for (let i = 0; i < tbButtons.length; i += 1) {
            let addIconFlag = true;

            if (userPermission === 'View') {
                if (tbButtons[i] === 'Delete' || tbButtons[i] === 'Add Items' || tbButtons[i] === 'Edit' || tbButtons[i] === 'Import') {
                    addIconFlag = false;
                }
            }

            if (tbButtons[i] === 'Import' && (getPermissionValue('Admin') === 'None' || getPermissionValue('Admin') === 'View')) {
                addIconFlag = false;
            }

            if (addIconFlag) {
                buttons.push((
                    <div key={tbButtons[i]} className={classes.actions}>
                        {imgMap[tbButtons[i]] &&
                            this.getToolbarIcon(
                                tbButtons[i],
                                onToolbarIconClick,
                                imgMap,
                                openImport,
                            )
                        }
                    </div>
                ));
            }
        }
        return buttons;
    };


    getDisable = (key, openImport) => {
        if ((key === 'Export' && this.props.tableDataCount <= 0) || (key === 'Import' && typeof openImport !== 'function')) {
            return true;
        }
        if (key === 'Delete' && this.props.numSelected <= 0) {
            return true;
        }
        return false;
    };

    getToolbarIcon = (
        tbButton,
        onToolbarIconClick,
        imgMap,
        openImport,
    ) => {
        const disabled = this.getDisable(tbButton, openImport);
        const iconButton = (
            <IconButton
                aria-label={tbButton}
                aria-owns={(this.state.openPopper) ? 'icon-list-grow' : undefined}
                aria-haspopup="true"
                onClick={(event) => {
                    if (tbButton === 'Delete') {
                        return this.showPopover(true, tbButton, event);
                    }
                    return onToolbarIconClick(true, tbButton);
                }}
                disabled={disabled}
                size="large"
            >
                {typeof imgMap[tbButton] === 'string' ?
                    <img src={imgMap[tbButton]} alt={tbButton} /> :
                    imgMap[tbButton]
                }
            </IconButton>
        );
        let toolTipTitle = tbButton;
        if (tbButton === 'Export') {
            toolTipTitle = 'Download';
        } else if (tbButton === 'Import') {
            toolTipTitle = 'Upload';
        }
        return (!disabled) ?
            <Tooltip title={toolTipTitle} disableFocusListener>{iconButton}</Tooltip>
            : iconButton;
    }

    toggleButton = (toggle: boolean) => {
        const isOpen = this.state.toggle;
        if (!isOpen) {
            let { advancedFilterData } = this.state;
            advancedFilterData = (this.oldSearchValue && this.oldSearchValue.length > 0)
                ? JSON.parse(JSON.stringify(this.oldSearchValue)) :
                JSON.parse(JSON.stringify(advancedFilter));
            this.setState({ advancedFilterData });
        }
        this.setState({ toggle });
    };

    handleDialogChange = (event) => {
        let option = '';
        this.options.some((o) => {
            if (o.value === event.target.value) {
                option = o;
                return true;
            }
            return false;
        });

        if (option) {
            this.setState({ selectedOption: option });
            this.props.dialogboxInfo.dialogHandleClick('show', option);
        }
    };

    processSavedAdvancedFilter = () => {
        const data = JSON.parse(JSON.stringify(advancedFilter));
        this.oldSearchValue = [];

        if (this.props.savedAdvancedFilter) {
            this.props.savedAdvancedFilter.forEach((filter) => {
                data.some((d) => {
                    if (d.id === filter[0]) {
                        const newD = d;
                        const value = filter[1];
                        newD.value = value;
                        return true;
                    }
                    return false;
                });
            });
            this.oldSearchValue = data;
        }
        return data;
    }

    handleChange = (e) => {
        this.setState({
            search: e,
        }, () => {
            const { search, needToRefresh } = this.state;

            if (search.length >= minimumChar) {
                if (this.props.ListSearch && typeof this.props.ListSearch === 'function') {
                    this.props.ListSearch(search);
                    this.setState({ needToRefresh: true });
                }
            } else if (needToRefresh && e === '') {
                if (this.props.ListSearch) {
                    this.props.ListSearch('');
                }
                this.setState({ needToRefresh: false });
            }

            // autosuggestion specific
            if (this.props.autoSuggestive && typeof this.props.autoSuggestive === 'object' && typeof this.props.autoSuggestive.onSuggestionSearch === 'function') {
                this.props.autoSuggestive.onSuggestionSearch(search);
                this.setState({ needToRefresh: true });
            }
        });
    };

    clearSearch = () => {
        this.setState({
            search: '',
            key: Math.random(),
        });

        if (this.props.ListSearch && typeof this.props.ListSearch === 'function' && (this.state.search.length >= minimumChar || this.state.needToRefresh)) {
            this.props.ListSearch('');
            this.setState({ needToRefresh: false });
        }

        if (this.props.autoSuggestive && typeof this.props.autoSuggestive === 'object' && typeof this.props.autoSuggestive.onSuggestionSearch === 'function') {
            this.props.autoSuggestive.onClearSearch();
            this.setState({ needToRefresh: true });
        }
    };


    clearFilter = () => {
        const { isAdvancedFilterApplied } = this.state;
        this.setState({ advancedFilterData: JSON.parse(JSON.stringify(advancedFilter)) });

        if (isAdvancedFilterApplied) {
            this.setState({ isAdvancedFilterApplied: false });
            this.oldSearchValue = [];
            this.props.advanceFilter({});
        }
    };

    searchData = () => {
        const { advancedFilterData } = this.state;
        const searchValue = {};
        this.oldSearchValue = [];
        advancedFilterData.forEach((f) => {
            const newData = f;
            switch (newData.type) {
            case 'input': if (newData.value) {
                if (newData.value.trim().length > 0) {
                    searchValue[newData.id] = newData.value.trim();
                    newData.value = f.value.trim();
                } else {
                    newData.value = '';
                }
            }
                break;
            case 'checkboxGroup': newData.value.forEach((c) => {
                if (c.value) {
                    if (searchValue[newData.type]) {
                        searchValue[newData.type].push(c.id);
                    } else {
                        searchValue[newData.type] = [];
                        searchValue[newData.type] = [c.id];
                    }
                }
            });
                break;
            default: break;
            }
        });

        if (this.props.advanceFilter && typeof this.props.advanceFilter === 'function') {
            this.props.advanceFilter(searchValue);
            this.oldSearchValue = JSON.parse(JSON.stringify(advancedFilterData));
            this.setState({ isAdvancedFilterApplied: true });
        }
        const params = {
            feature: 'FLEET_Asset',
            name: this.oldSearchValue[0].value ? 'true' : 'false',
            assetGroupName: this.oldSearchValue[1].value ? 'true' : 'false',
            deviceSerialNumber: this.oldSearchValue[2].value ? 'true' : 'false',
            vin: this.oldSearchValue[3].value ? 'true' : 'false',
            year: this.oldSearchValue[4].value ? 'true' : 'false',
            make: this.oldSearchValue[5].value ? 'true' : 'false',
            model: this.oldSearchValue[6].value ? 'true' : 'false',
            tags: this.oldSearchValue[7].value ? 'true' : 'false',
        };
        analytics.track('ADVANCED_SEARCH', params);
    };

    updateFilterValue = (e, d) => {
        let { advancedFilterData } = this.state;
        advancedFilterData = advancedFilterData.map((f) => {
            const newData = f;
            switch (newData.type) {
            case 'input': if (d.id === newData.id) {
                newData.value = e;
            }
                break;
            case 'checkboxGroup': newData.value = newData.value.map((c) => {
                const newCheckbox = c;
                if (c.id === d.id) {
                    newCheckbox.value = e;
                }
                return newCheckbox;
            });
                break;
            default: break;
            }
            return newData;
        });
        this.setState({ advancedFilterData });
    };

    getAdvanceFilter = () => {
        const { classes } = this.props;
        const { toggle, advancedFilterData } = this.state;
        return (
            <Collapse
                in={toggle}
                key="advanceSearch"
            >
                <div elevation={4} className={classes.paper}>
                    <Grid container spacing={2} className={classes.grid}>
                        {advancedFilterData.map((d) => {
                            switch (d.type) {
                            case 'input': return (
                                <Grid item xs={4} style={{ padding: '0 12px' }} key={d.lable}>
                                    <TextField
                                        id={d.id}
                                        label={d.lable}
                                        fullWidth
                                        autoFocus={d.autoFocus}
                                        value={d.value}
                                        onChange={e => this.updateFilterValue(e.target.value, d)}
                                        className={
                                            classNames(
                                                classes.textField,
                                                classes.dense,
                                                classes.textAreaWidth,
                                            )
                                        }
                                        InputProps={{
                                            classes: {
                                                input: classes.input,
                                            },
                                        }}
                                        InputLabelProps={{
                                            className: classes.label,
                                        }}
                                        margin="dense"
                                        autoComplete="off"
                                    />
                                </Grid>
                            );
                            case 'checkboxGroup': return (
                                <Grid item xs={6} style={{ paddingTop: 0 }} key={d.type}>
                                    <FormGroup row>
                                        {d.value.map(c => (<FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={c.value}
                                                    color="primary"
                                                    onChange={e =>
                                                        this.updateFilterValue(e.target.checked, c)
                                                    }
                                                />
                                            }
                                            key={c.id}
                                            label={c.label}
                                        />))
                                        }
                                    </FormGroup>
                                </Grid>
                            );
                            default: return '';
                            }
                        })}
                    </Grid>
                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            onClick={() => this.toggleButton(false)}
                            style={{ marginBottom }}
                        >
                            CLOSE
                        </Button>
                        <Button
                            className={classes.button}
                            onClick={() => this.clearFilter()}
                            style={{ marginBottom }}
                        >
                            CLEAR
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.searchData()}
                            className={`${classes.button}`}
                            style={{ marginRight, marginBottom }}
                        >
                            SEARCH
                        </Button>
                    </Grid>
                </div>
            </Collapse>
        );
    };

    clearChip = (data) => {
        this.oldSearchValue = this.oldSearchValue.filter((d) => {
            if (d.id !== data.id) {
                return true;
            }
            return false;
        });
        this.updateFilterValue('', data);
        this.searchData();
    };

    getChips = () => {
        const { toggle } = this.state;
        const { classes } = this.props;
        const needToshowDiv = this.oldSearchValue.some((d) => {
            if (d.value && d.type === 'input') {
                return true;
            }
            return false;
        });
        return (
            <Collapse
                in={!toggle && needToshowDiv}
                key="chips"
            >
                <div className={classes.chips}>
                    {this.oldSearchValue.map((d) => {
                        if (d.value && d.type === 'input' && d.value.trim().length > 0) {
                            let textValue = d.value;
                            if (textValue && textValue.length > 20) {
                                textValue = `${textValue.slice(0, 20)}...`;
                            }
                            return (
                                <Chip
                                    avatar={
                                        <Avatar
                                            className={classes.avatarText}
                                        >
                                            {d.lable}
                                        </Avatar>
                                    }
                                    label={textValue}
                                    onDelete={() => this.clearChip(d)}
                                    key={d.id}
                                    variant="outlined"
                                    className={classes.chipLabel}
                                />
                            );
                        }
                        return '';
                    })}
                    {this.oldSearchValue.length > 0 &&
                    <Button className={`${classes.button} ${classes.buttonColor}`} onClick={() => this.clearFilter()}>
                        CLEAR ALL
                    </Button>
                    }
                </div>
            </Collapse>
        );
    };

    closeSearch = () => {
        if (this.state.search.length <= 0) {
            this.setState({ isSearch: false, needToRefresh: false });
        }

        if (this.props.autoSuggestive && typeof this.props.autoSuggestive === 'object' && typeof this.props.autoSuggestive.onClearSearch === 'function') {
            this.setState({ isSearch: false, needToRefresh: false });
            this.props.autoSuggestive.onClearSearch();
        }
    };

    handleToggle = (event) => {
        this.anchorEl = event.currentTarget;
        this.setState(state => ({ open: !state.open }));
    };

    handleClose = (event) => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }
        this.setState({ open: false });
    };

    handleClosePopper = (event) => {
        if (this.popperEl.contains(event.target)) {
            return;
        }
        this.setState({ openPopper: false });
    }

    handleBackClick = () => {
        if (this.props.backClick === undefined) {
            window.history.back();
        } else {
            this.props.backClick();
        }
    };

    getDialog = () => {
        const { dialogboxInfo } = this.props;
        const { selectedOption } = this.state;
        const dialog = (
            <Dialog
                open={dialogboxInfo.showDialog}
                customTitle={dialogboxInfo.title}
                button={this.dialogButtons}
                size="lg"
            >
                <RadioGroup
                    aria-label="Ringtone"
                    name="ringtone"
                    value={selectedOption.value}
                    onChange={this.handleDialogChange}
                >
                    {this.options.map(option => (
                        <FormControlLabel
                            value={option.value}
                            key={option.key}
                            control={<Radio color="primary" />
                            }
                            label={option.lable}
                        />
                    ))}
                </RadioGroup>
            </Dialog>
        );
        return dialog;
    }

    render() {
        const {
            numSelected, classes, toolbarTitle, tbButtons,
            showSearch, autoSuggestive, showColumnDropdown,
            toolbarSubTitle, showToolbarBackButton,
            headerDropDown,
            radioSelectorOptions,
            tableMenu,
            dialogboxInfo,
            listHeaders,
            showHideColumn,
        } = this.props;

        const {
            isSearch,
            search,
            key,
            toggle,
            // anchorEl,
        } = this.state;

        // const open = Boolean(anchorEl);
        const { open, openColumn } = this.state;

        const renderSearchInputComponent = () => (
            <SearchBox
                id="search"
                autoFocus={isSearch}
                style={{ width: 329 }}
                value={search}
                key={key}
                placeholder={`Please enter at least ${minimumChar} characters`}
                onBlur={() => this.closeSearch()}
                onChange={e => this.handleChange(e.target.value)}
                endAdornment={
                    <InputAdornment position="end">
                        {(search.length > 0) ?
                            <Tooltip key="close" title="Clear">
                                <Close onClick={() => this.clearSearch()} sx={{ color: '#000000' }}/>
                            </Tooltip> :
                            <Tooltip key="search" title="Search">
                                <img
                                    src={searchIcon}
                                    alt="search"
                                />
                            </Tooltip>
                        }
                    </InputAdornment>}
                inputProps={{
                    'aria-label': 'Search',
                    style: { fontWeight: 500 },
                }}
            />
        );

        return (
            <React.Fragment>
                <Toolbar
                    className={classNames(classes.root)}
                >
                    <Grid container spacing={3} justifyContent="flex-start" alignItems="center">
                        <Grid item className={classes.title}>
                            <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
                                <Grid item>
                                    {showToolbarBackButton &&
                                        <span
                                            className={classes.backButton}
                                        >
                                            <IconButton onClick={() => { this.handleBackClick(); }} size="large">
                                                <img src={backArrow} alt="backArrow" />
                                            </IconButton>
                                        </span>
                                    }
                                    <Typography variant="h6" id="tableTitle" className={classes.titleWrap} style={(isSearch && showSearch) ? { display: 'flex' } : {}}>
                                        <span>{getTitle(toolbarTitle)}</span>
                                        {
                                            toolbarSubTitle &&
                                            <span className={classes.subTitle}>
                                                {toolbarSubTitle}
                                            </span>
                                        }
                                        {(!isSearch && showSearch) &&
                                            <Zoom in={!isSearch}>
                                                <Tooltip title="Search">
                                                    <IconButton
                                                        aria-label="Search"
                                                        className={(toggle) ? classes.disabledButton : ''}
                                                        onClick={
                                                            () => this.setState({ isSearch: true })
                                                        }
                                                        disabled={toggle}
                                                        size="large"
                                                    >
                                                        <img src={searchIcon} alt="search" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Zoom>
                                        }
                                        {(isSearch && showSearch) &&
                                            <Grow
                                                in={isSearch}
                                                style={{ transformOrigin: '0 0 0', marginLeft: 24 }}
                                                {...(isSearch ? { timeout: 500 } : {})}
                                            >
                                                <div>
                                                    {!autoSuggestive.isSuggestion
                                                        ? renderSearchInputComponent() :
                                                        <ToolbarAutosuggest
                                                            suggestions={autoSuggestive.suggestions}
                                                            onSuggestionSearch={this.handleChange}
                                                            onSuggestionClick={
                                                                autoSuggestive.onSuggestionClick
                                                            }
                                                            clearSearch={this.clearSearch}
                                                            closeSearch={this.closeSearch}
                                                            placeholder={autoSuggestive.placeholder}
                                                        />
                                                    }
                                                </div>
                                            </Grow>
                                        }
                                        {isAdvancedSearch(tbButtons) &&
                                            <Button className={`${classes.button} ${classes.buttonColor}`} onClick={() => this.toggleButton(!toggle)} disabled={isSearch}>
                                                Advanced { toggle ?
                                                    <ArrowDropDown
                                                        style={{ color: 'inherit' }}
                                                    /> :
                                                    <ArrowDropUp
                                                        style={{ color: 'inherit' }}
                                                    />
                                                }
                                            </Button>
                                        }
                                    </Typography>
                                </Grid>
                                {
                                    radioSelectorOptions &&
                                    <Grid item>
                                        <RadioSelector
                                            {...radioSelectorOptions}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        {headerDropDown.showDropDown &&
                            <Grid item className={classes.dropdown}>
                                <DropDown
                                    selectedValue={headerDropDown.selectedValue}
                                    onChange={
                                        (e, filterDate) => headerDropDown.onChange(e, filterDate)
                                    }
                                />
                            </Grid>
                        }
                        {(dialogboxInfo.showDialog) && this.getDialog()}
                        <Grid item className={classes.iconWrapper}>
                            {this.createButtons()}
                            {
                                tableMenu.showTableMenu &&
                                <React.Fragment>
                                    <div key={tableMenu.title} className={classes.actions}>
                                        <Tooltip title={tableMenu.title} disableFocusListener>
                                            <IconButton
                                                aria-label={tableMenu.title}
                                                aria-owns={open ? 'menu-list-grow' : undefined}
                                                aria-haspopup="true"
                                                onClick={this.handleToggle}
                                                size="large"
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Popper
                                        open={open}
                                        anchorEl={this.anchorEl}
                                        transition
                                        disablePortal
                                        style={{ zIndex: 2 }}
                                    >
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                id="menu-list-grow"
                                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener
                                                        onClickAway={(e) => this.handleClose(e)}
                                                        disableReactTree
                                                    >
                                                        <MenuList>
                                                            {tableMenu.options.map(option => (
                                                                <MenuItem
                                                                    key={option.key}
                                                                    onClick={() =>
                                                                        tableMenu
                                                                            .onChange(option)
                                                                    }
                                                                    style={{
                                                                        color: (option.key === tableMenu.selected) ? '#007aff' : 'rgba(0, 0, 0, 0.54)' }}
                                                                >
                                                                    {option.lable}
                                                                </MenuItem>
                                                            ))}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </React.Fragment>
                            }
                            {
                                showColumnDropdown &&
                                <ShowHideColumnComponent
                                    classes={classes}
                                    openColumn={openColumn}
                                    listHeaders={listHeaders}
                                    showHideColumn={showHideColumn}
                                />
                            }
                        </Grid>
                    </Grid>
                    <Popper
                        open={this.state.openPopper}
                        anchorEl={this.popperEl}
                        transition
                        disablePortal
                        style={{ zIndex: 2 }}
                        placement="right-start"
                    >
                        {({ TransitionProps }) => (
                            <Grow
                                {...TransitionProps}
                                id="icon-list-grow"
                                style={{ transformOrigin: 'center top' }}
                            >
                                <Paper>
                                    <ClickAwayListener
                                        onClickAway={this.handleClosePopper}
                                    >
                                        <Card>
                                            <CardHeader
                                                title={
                                                    <span style={{ fontSize: 20 }}>
                                                        Delete ({numSelected}) {toolbarTitle}?
                                                    </span>
                                                }
                                                style={{ paddingBottom: 8 }}
                                            />
                                            <CardContent className={classes.deleteMessage}>
                                                Once deleted, they cannot be restored.
                                            </CardContent>
                                            <CardActions style={{ justifyContent: 'flex-end' }}>
                                                <Button style={{ color: '#6d7278' }} onClick={this.handleClosePopper}>CANCEL</Button>
                                                <Button
                                                    color="primary"
                                                    onClick={(event) => {
                                                        this.props
                                                            .onToolbarIconClick(
                                                                true,
                                                                this.state.tbButton,
                                                            );
                                                        this.handleClosePopper(event);
                                                    }}
                                                >
                                                    CONFIRM
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Toolbar>
                {isAdvancedSearch(tbButtons) &&
                    [this.getAdvanceFilter(), this.getChips()]
                }
            </React.Fragment>
        );
    }
}

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing(),
        display: 'flex',
    },
    highlight:
        theme.palette.mode === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: 'rgba(0, 122, 255, 0.2) !important',
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    actions: {
        color: theme.palette.text.secondary,
        display: 'inline-flex',
    },
    title: {
        flex: '1 1 auto',
        display: 'flex',
        marginTop: 8,
    },
    dropdown: {
        flex: '1 1 auto',
    },
    iconWrapper: {
        flex: '1 1 auto',
        textAlign: 'right',
    },
    subTitle: {
        color: 'rgba(0, 0, 0, 0.60)',
    },
    backButton: {
        verticalAlign: 'middle',
        paddingRight: 20,
        marginLeft: -12,
    },
    chips: {
        paddingLeft: 20,
        paddingBottom: 8,
    },
    titleWrap: {
        lineHeight: 3,
    },
    menuItem: {
        paddingBottom: '11px !important',
    },
    buttonColor: {
        color: '#007aff',
    },
    disabledButton: {
        opacity: 0.5,
    },
    button: {
        margin: theme.spacing(),
    },
    paper: {
        boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12)',
        paddingBottom: 16,
        paddingTop: 6,
    },
    input: {
        fontSize: 14,
    },
    label: {
        fontSize: 14,
    },
    grid: {
        paddingLeft: 22,
    },
    textAreaWidth: {
        width: '94%',
    },
    chipLabel: {
        margin: 6,
        backgroundColor: 'transparent',
    },
    avatarText: {
        width: 'auto !important',
        padding: '0 10px',
        borderRadius: 16,
        height: '32px !important',
        fontSize: '1rem !important',
    },
    deleteMessage: {
        paddingTop: 0,
        paddingBottom: 42,
        fontSize: 16,
        color: 'rgba(0, 0, 0, 0.54)',
    },
});

// EnhancedTableToolbar.propTypes = {
//     classes: {},
//     numSelected: PropTypes.number.isRequired,
// };

export default withStyles(toolbarStyles)(EnhancedTableToolbar);
