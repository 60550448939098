/* @flow */
import React from 'react';
import { Grid, Button } from '@mui/material';
import customStyles from './ImportComponent.module.scss';
import { ASSET, ASSET_TEMPLATE_NAME } from './constants.import';
import { hasFeature } from '../../../helper-classes/utility-functions';

export type Props = {
    downloadTemplate: Function,
};

class AssetImportFormComponent extends React.PureComponent<Props> {
    render() {
        const needInputValidation = hasFeature('fleet.Eld');
        return (
            <Grid container justifyContent="flex-start" spacing={1}>
                <Grid item xs={12} style={{ margin: '18px 0' }}>
                    <h2 className={customStyles.header}>Assets</h2>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={() => this.props.downloadTemplate(ASSET, ASSET_TEMPLATE_NAME)}>Download Template</Button>
                </Grid>
                <Grid item xs={12} style={{ margin: '18px 0' }}>
                    <div className={customStyles.highlight}>
                        <div className={customStyles.subHeader}>
                            Importing an Assets .CSV
                        </div>
                        Please read the following instructions. It is important that
                        the import file has the columns required and optional in
                        order from left to right as shown in the sample import file
                    </div>
                    <div>
                        Use the Asset Import to add or update vehicles into the
                        system. The Asset Name identifies an existing Asset Name
                        to update, or to add if that specific Asset Name is not
                        found in the account. Double check your Asset Names for accuracy
                        before import. The asset fields that can be entered are
                        outlined below. Commas are not allowed in the data.
                        Column headers are mandatory and may not be moved.
                        If you wish to delete a field value from an asset, enter the value
                        DELETE and it will be deleted. If the value is left empty, no
                        change will occur.

                        <div className={customStyles.subHeader}>Required:</div>
                        <ul>
                            <li>
                                Asset Name: A unique asset identifier such as
                                name or number
                            </li>
                            <li>
                                Asset Icon: Choose only ONE: Boat, Bus, Car,
                                Cement Truck, Dump Truck, Excavator, Flat Bed,
                                Generator, Motorcycle, Snowmobile, Tractor,
                                Trailer, Truck, Van
                            </li>
                            <li>
                                Asset Type: Choose only ONE: Equipment, Trailer,
                                Vehicle-Heavy Duty, Vehicle-Medium Duty,
                                Vehicle-Light Duty
                            </li>
                            { needInputValidation && <li>VIN: Asset VIN</li> }
                        </ul>
                        <div className={customStyles.subHeader}>
                            Optional (may be left empty):
                        </div>
                        <ul>
                            { !needInputValidation && <li>VIN: Asset VIN</li> }
                            <li>Make: Make of asset</li>
                            <li>Model: Model of asset</li>
                            <li>
                                Year: Year of asset (4 digits max)
                            </li>
                            <li>
                                Initial Odometer: (Mileage Before GPS Install)
                                The asset&apos;s mileage at the point of install,
                                the GPS device will take over from this point
                                (value must be in miles even if your account
                                displays KM, max 15 digits)
                            </li>
                            <li>
                                Asset Group: The group this asset belongs to.
                                We will add a new group or update to an existing
                                group based on name
                            </li>
                            <li>
                                Device Serial Number: Enter a device serial number
                                from your account optionally to automatically pair
                                the asset to that device on import
                            </li>
                            <li>
                                CamCoach IMEI: Enter a CamCoach IMEI (found on the
                                CamCoach or the box label) optionally to automatically
                                pair the asset to that CamCoach on import. Must have
                                purchased a CamCoach through Spireon to have this
                                feature enabled
                            </li>
                            <li>
                                Tags: Multiple tags can be separated by comma
                            </li>
                        </ul>
                        {/*  TODO needed for phase 2 import feature */}
                        {/* <div className={customStyles.subHeader}>
                            The following fields are only applicable for accounts that have the
                            Fuel Card feature active (optional):
                        </div>
                        <ul>
                            <li>Fuel Type: Unleaded or Diesel</li>
                            <li>Fuel Tank Capacity: Enter value in gallons (digits only)</li>
                            <li>
                                Fuel Account Number:
                                Enter the account number you have with your fuel provider
                            </li>
                            <li>
                                Fuel Card: Fuel Card Number
                            </li>
                        </ul> */}
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default AssetImportFormComponent;
