/* @flow */

import { FETCH_DEVICES_LIST, FETCH_DEVICES_LIST_SUCCESS, FETCH_DEVICES_LIST_ERROR, RESET_DEVICES_LIST } from './constants.deviceList';
import type { DeviceListTypeAction } from './constants.deviceList';

export const fetchDevicesList = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filter: string,
): DeviceListTypeAction => ({
    type: FETCH_DEVICES_LIST,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
        filter,
    },
});

export const fetchDevicesListSuccess =
    (devices: any, totalDevices: number): DeviceListTypeAction => ({
        type: FETCH_DEVICES_LIST_SUCCESS,
        payload: { devices, totalDevices },
    });

export const fetchDevicesListError = (error: string): DeviceListTypeAction => ({
    type: FETCH_DEVICES_LIST_ERROR,
    payload: { error },
});

export const resetTable =
    (): DeviceListTypeAction => ({
        type: RESET_DEVICES_LIST,
    });
