/* @flow */

export const FETCH_NEAREST_ASSET: string = 'FETCH_NEAREST_ASSET';
export const FETCH_NEAREST_LANDMARK: string = 'FETCH_NEAREST_LANDMARK';
export const FETCH_NEAREST_ASSET_LANDMARK_SUCCESS: string = 'FETCH_NEAREST_ASSET_LANDMARK_SUCCESS';
export const FETCH_NEAREST_ASSET_LANDMARK_ERROR: string = 'FETCH_NEAREST_ASSET_LANDMARK_ERROR';
export type NearestAssetLandmarkTypeAction = {
    type: string;
    payload?: Object;
}
