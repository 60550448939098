/* @flow */
import { combineEpics } from 'redux-observable';
import { ADD_LANDMARK, GET_LANDMARK_GROUPS, FETCH_LANDMARK, UPDATE_LANDMARK, DELETE_LANDMARK } from './constants.landmark';
import { getlandmarkGroupsSuccess, getlandmarkGroupsError, fetchLandmarkSuccess, fetchLandmarkError, addLandmarkSuccess, addLandmarkError, updateLandmarkSuccess, updateLandmarkError, deleteLandmarkSuccess, deleteLandmarkError } from './actions.landmark';
import { getGrailsAppBasedURL, getAuthorizationHeader } from '../../../helper-classes/utility-functions';
import { flattenGroups } from './../../Maintenance/helper-classes/common-services';

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    of: Function,
    put: Function,
    deleteJSON: Function,
}

export const addLandmark =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(ADD_LANDMARK)
            .mergeMap((action) => {
                const headers = {
                    'Content-Type': 'application/json; charset=utf-8',
                    Authorization: getAuthorizationHeader(),
                };
                return postJSON(
                    `${getGrailsAppBasedURL()}/rest/json/landmark?_dc=${new Date().getTime()}`,
                    action.payload.data,
                    headers,
                )
                    .map((result) => {
                        if (result.response && result.response.success) {
                            return addLandmarkSuccess(result);
                        }
                        return addLandmarkError(result.response);
                    })
                    .catch(error => of(addLandmarkError(error.response || error)));
            });

export const updateLandmark =
    (actions$: Function, store: Object, { put, of }: ObservarblesTypes) =>
        actions$
            .ofType(UPDATE_LANDMARK)
            .mergeMap((action) => {
                const headers = {
                    'Content-Type': 'application/json; charset=utf-8',
                    Authorization: getAuthorizationHeader(),
                };
                const landmarkId = action.payload.data.id;
                return put(
                    `${getGrailsAppBasedURL()}/rest/json/landmark/${landmarkId}?_dc=${new Date().getTime()}`,
                    action.payload.data,
                    headers,
                )
                    .map((result) => {
                        if (result.response && result.response.success) {
                            return updateLandmarkSuccess(result);
                        }
                        return updateLandmarkError(result.response);
                    })
                    .catch(error => of(updateLandmarkError(error.response || error)));
            });


export const deleteLandmark =
    (actions$: Function, store: Object, { deleteJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(DELETE_LANDMARK)
            .mergeMap((action) => {
                const headers = {
                    'Content-Type': 'application/json',
                    Authorization: getAuthorizationHeader(),
                };

                const landmarkId = action.payload.id;

                return deleteJSON(
                    `${getGrailsAppBasedURL()}/operation/json/localFleetLandmarkService/deleteLandmark/${landmarkId}?_dc=${new Date().getTime()}`,
                    headers,
                )
                    .map((result) => {
                        if (result.response && result.response.success) {
                            return deleteLandmarkSuccess();
                        }
                        return deleteLandmarkError(result.response);
                    })
                    .catch(error => of(deleteLandmarkError(error.response || error)));
            });

export const getLandmarkGroups =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(GET_LANDMARK_GROUPS)
            .mergeMap(() => {
                const options = {
                    headers: {
                        Authorization: getAuthorizationHeader(),
                    },
                };
                const randVal = new Date().getTime();
                const sort = encodeURIComponent('[{"property":"leaf","direction":"ASC"}]');
                let url = `${getGrailsAppBasedURL()}/tree/viewLandmarkGroups.json`;
                url = `${url}?_dc=${randVal}&sort=${sort}`;
                return getJSON(url, options.headers)
                    .map((response) => {
                        const groups = flattenGroups(response.children);
                        return getlandmarkGroupsSuccess({ data: groups, total: groups.length });
                    })
                    .catch(error => of(getlandmarkGroupsError({ error })));
            });

export const getLandmark =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_LANDMARK)
            .mergeMap((action) => {
                const headers = {
                    'Content-Type': 'application/json',
                    Authorization: getAuthorizationHeader(),
                };
                return getJSON(
                    `${getGrailsAppBasedURL()}/rest/json/landmark?globalId=${action.payload.id}&_dc=${new Date().getTime()}`,
                    headers,
                )
                    .map(result => fetchLandmarkSuccess(result))
                    .catch(error => of(fetchLandmarkError({ error })));
            });

export default combineEpics(
    addLandmark,
    getLandmark,
    getLandmarkGroups,
    updateLandmark,
    deleteLandmark,
);
