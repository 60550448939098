/* @flow */
import {
    FETCH_ASSETS_DETAILS_SUCCESS,
    FETCH_ASSETS_DETAILS_ERROR,
    FETCH_ASSETS_DETAILS,
    FETCH_ASSETS_TRIPS_SUCCESS,
    UPDATE_TRIPS_LOADER,
    FETCH_ASSETS_TRIPS,
    FETCH_ASSETS_EVENTS,
    FETCH_ASSETS_EVENTS_SUCCESS,
    FETCH_ASSETS_EVENTS_ERROR,
    FETCH_TRIPS_ERROR,
    UPDATE_MAP_REFRESH_LOADER,
} from './constants.assets';

type stateType = {
    assetsDetails: Object,
    isLoader: boolean,
    trips: Object,
    events: Object,
    showTripLoader: boolean,
    isTripLoader: boolean,
    isEventLoader: boolean,
    isTripsFetchError: boolean,
    mapRefreshLoader: boolean,
}

type actionType = {
    type: string,
    payload :{
        response:{},
        error: {
            response: Object,
        },
        initTrip: boolean,
        initEvent: boolean,
        isLoader: boolean,
        trips: {},
        events: Object,
        showTripLoader: false,
        isTripLoader: boolean,
        isEventLoader: boolean,
        showLoader: boolean,
        isTripsFetchError: boolean,
        mapRefreshLoader: boolean,
    }
}

export const initialState = {
    assetsDetails: {
        lat: 0,
        lng: 0,
    },
    isLoader: false,
    trips: {},
    events: {
        content: [],
    },
    isEventLoader: false,
    showTripLoader: false,
    isTripLoader: false,
    mapRefreshLoader: false,
    isTripsFetchError: false,
};

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_ASSETS_DETAILS_SUCCESS:
        return {
            ...state,
            assetsDetails: action.payload.response,
            isLoader: false,
            landmarkDetails: {},
            directions: {},
            directionsDetails: {},
        };
    case FETCH_ASSETS_DETAILS_ERROR:
        return {
            ...state,
            isLoader: false,
            assetsDetails: {},
            landmarkDetails: {},
            directions: {},
            directionsDetails: {},
        };
    case FETCH_ASSETS_DETAILS:
        return {
            ...state,
            isLoader: action.payload.showLoader,
            assetsDetails: {},
            landmarkDetails: {},
            directions: {},
            directionsDetails: {},
        };
    case FETCH_ASSETS_TRIPS:
        return {
            ...state,
            isTripLoader: true,
            isEventLoader: true,
            landmarkDetails: {},
            directions: {},
            directionsDetails: {},
        };
    case FETCH_ASSETS_TRIPS_SUCCESS: {
        return {
            ...state,
            trips: action.payload.response,
            isTripLoader: action.payload.initTrip,
            isEventLoader: action.payload.initEvent,
            landmarkDetails: {},
            directions: {},
            directionsDetails: {},
            isTripsFetchError: false,
            isLoader: false,
            mapRefreshLoader: false,
        };
    }
    case FETCH_TRIPS_ERROR:
        return {
            ...state,
            isLoader: false,
            assetsDetails: {},
            landmarkDetails: {},
            directions: {},
            directionsDetails: {},
            isTripsFetchError: true,
            isTripLoader: action.payload.initTrip,
            isEventLoader: action.payload.initTrip,
            mapRefreshLoader: false,
        };
    case UPDATE_MAP_REFRESH_LOADER:
        return {
            ...state,
            mapRefreshLoader: action.payload.mapRefreshLoader,
        };
    case UPDATE_TRIPS_LOADER:
        return {
            ...state,
            isTripLoader: action.payload.showTripLoader,
            isEventLoader: action.payload.showTripLoader,
        };
    case FETCH_ASSETS_EVENTS:
        return {
            ...state,
            isEventLoader: true,
        };
    case FETCH_ASSETS_EVENTS_SUCCESS:
        return {
            ...state,
            events: action.payload.response,
            isEventLoader: false,
            mapRefreshLoader: false,
        };
    case FETCH_ASSETS_EVENTS_ERROR:
        return {
            ...state,
            isEventLoader: false,
            mapRefreshLoader: false,
        };
    default:
        return state;
    }
};
