/* @flow */

import {
    FETCH_ALERT_HISTORY,
    FETCH_ALERT_HISTORY_SUCCESS,
    FETCH_ALERT_HISTORY_ERROR,
    RESET_ALERT_HISTORY_LIST,
} from './constants.alertHistory';
import type { AlertHistoryTypeAction } from './constants.alertHistory';

export const fetchAlertHistory = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    alertHistoryFiltersToSend: any,
): AlertHistoryTypeAction => ({
    type: FETCH_ALERT_HISTORY,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
        alertHistoryFiltersToSend,
    },
});

export const fetchAlertHistorySuccess = (
    alertHistory: any,
    totalRecords: number,
): AlertHistoryTypeAction => ({
    type: FETCH_ALERT_HISTORY_SUCCESS,
    payload: { alertHistory, totalRecords },
});

export const resetTable = (): AlertHistoryTypeAction => ({
    type: RESET_ALERT_HISTORY_LIST,
});

export const fetchAlertHistoryError = (error: string): AlertHistoryTypeAction => ({
    type: FETCH_ALERT_HISTORY_ERROR,
    payload: { error },
});
