import reduxAnalyticsImpl from 'redux-analytics';
import localAnalyticsImpl from './index';
import { getLocalStorageValue, removeLocalStorageItem } from './../helper-classes/utility-functions';

export const middlewareFactory = (reduxAnalytics, localAnalytics) =>
    reduxAnalytics(({ type, payload }) => {
        // allow the redux-analytics to trigger setUser/clearUser
        const newPayload = payload || {};
        const impersonated = getLocalStorageValue('fleet-impersonated') || '';
        if (type === 'LOGOUT_SUCCESS') {
            localAnalytics.clearUser();
            if (newPayload && impersonated === 'true') {
                removeLocalStorageItem('fleet-impersonated');
                newPayload.impersonationSessionEnd = 'YES';
            }
        } else if (type === 'LOGIN_SUCCESS') {
            localAnalytics.setUser(payload);
            if (newPayload && impersonated === 'true') {
                newPayload.impersonationSessionStarted = 'YES';
            }
        }
        if (newPayload && newPayload.username && typeof newPayload.username === 'string') {
            newPayload.username = newPayload.username.toLowerCase();
        }
        localAnalytics.track(type, newPayload);
    });

export default middlewareFactory(reduxAnalyticsImpl, localAnalyticsImpl);
