/* @flow */

export const GRAILS_FETCH_LANDMARKS: string = 'GRAILS_FETCH_LANDMARKS';
export const GRAILS_FETCH_LANDMARKS_SUCCESS: string = 'GRAILS_FETCH_LANDMARKS_SUCCESS';
export const GRAILS_FETCH_LANDMARKS_ERROR: string = 'GRAILS_FETCH_LANDMARKS_ERROR';
export const GRAILS_POST_LANDMARK_GROUP: string = 'GRAILS_POST_LANDMARK_GROUP';
export const GRAILS_FETCH_SELECTED_LANDMARKS: string = 'GRAILS_FETCH_SELECTED_LANDMARKS';
export const GRAILS_FETCH_SELECTED_LANDMARKS_SUCCESS: string = 'GRAILS_FETCH_SELECTED_LANDMARKS_SUCCESS';
export const GRAILS_POST_LANDMARK_GROUP_SUCCESS: string = 'GRAILS_POST_LANDMARK_GROUP_SUCCESS';
export const FETCH_LANDMARK_GROUP_DETAILS_GLOBALID: string = 'FETCH_LANDMARK_GROUP_DETAILS_GLOBALID';
export const FETCH_LANDMARK_GROUP_DETAILS_GLOBALID_SUCCESS: string = 'FETCH_LANDMARK_GROUP_DETAILS_GLOBALID_SUCCESS';
export const DELETE_LANDMARK_GROUP_GLOBALID: string = 'DELETE_LANDMARK_GROUP_GLOBALID';
export const DELETE_LANDMARK_GROUP_GLOBALID_SUCCESS: string = 'DELETE_LANDMARK_GROUP_GLOBALID_SUCCESS';
export const LANDMARK_AND_SELECTED_LANDMARK: string = 'LANDMARK_AND_SELECTED_LANDMARK';
export const GRAILS_FETCHLANDMARK_AND_SELECTED_LANDMARKS_SUCCESS: string = 'GRAILS_FETCHLANDMARK_AND_SELECTED_LANDMARKS_SUCCESS';
export const GRAILS_FETCHLANDMARK_AND_SELECTED_LANDMARKS_ERROR: string = 'GRAILS_FETCHLANDMARK_AND_SELECTED_LANDMARKS_ERROR';
export const CLOSE_DIALOG: string = 'CLOSE_DIALOG';
export const SHOW_LOADER: string = 'SHOW_LOADER';
export const LANDMARK_PER_PAGE: number = 50;
export const AVAILABLE_LANDMARK_FILTER: Array<any> = [['landmarkGroupId', 'exists:no']];
export const SELECTED_LANDMARK_LIMIT: number = 1500;
export const SOMETHING_WENT_WRONG: string = 'Something went wrong';
export type LandmarkGroupManageAction = {
    type: string;
    payload?: Object;
}
