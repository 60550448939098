/* @flow */
// eslint-disable-next-line no-unused-vars
import { of as rxOf } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { combineEpics } from 'redux-observable';
import * as c from './constants.appNotifications';
import * as actions from './actions.appNotifications';
import config from '../../../constants/Config';
import { getUserToken, setSessionStorageItem, getSessionStorageItem, removeSessionStorageItem } from '../../../helper-classes/utility-functions';

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    of: Function,
};

const CALL_INTERVAL_IN_MS = 30000;
let importJobsSubs$;
const getHeaders = () => ({
    'X-Nspire-UserToken': getUserToken(),
    'Content-Type': 'application/json',
});

export const unsubscribePolling =
    (actions$: Function) =>
        actions$
            .ofType(c.UNSUBSCRIBER_POLLING_JOBS)
            .map(() => {
                removeSessionStorageItem(c.IMPORT_JOB_SUBSCRIBER_SS_NAME);
                if (importJobsSubs$) importJobsSubs$.unsubscribe();
                return actions.notificationPollingHandler();
            });

const checkImportJobStatus = (of, historyObj: Object, isFirstCall: boolean = false) => {
    const inProgressJobIDs = () => actions.importJobsCookieDataHandler([], c.IMPORT_ACTION_GET);
    const activeSubscribers = getSessionStorageItem(c.IMPORT_JOB_SUBSCRIBER_SS_NAME);

    if (!activeSubscribers.includes(c.IMPORT_JOB_POLLING_SUBSCRIBER)) {
        const importJobsObs$ = Observable.interval(CALL_INTERVAL_IN_MS)
            .map(() => inProgressJobIDs());

        setSessionStorageItem(
            c.IMPORT_JOB_SUBSCRIBER_SS_NAME,
            c.IMPORT_JOB_POLLING_SUBSCRIBER,
        );

        if (isFirstCall && inProgressJobIDs().length > 0) {
            actions.checkImportJobStatusHandler({
                historyObj,
                inProgressJobIDs: inProgressJobIDs(),
            });
        }

        importJobsSubs$ = importJobsObs$.subscribe((response) => {
            if (getUserToken() === '') {
                removeSessionStorageItem(c.IMPORT_JOB_SUBSCRIBER_SS_NAME);
                importJobsSubs$.unsubscribe();
            } else if (response.constructor === Array && response.length > 0) {
                actions.checkImportJobStatusHandler({
                    historyObj,
                    inProgressJobIDs: response,
                });
            }

            return actions.notificationPollingHandler();
        });
    }

    return actions.notificationPollingHandler();
};

export const startJobStatusPolling =
    (actions$: Function, store: Object, { of }: ObservarblesTypes) =>
        actions$
            .ofType(c.NOTIFICATION_POLLING)
            .map((action) => {
                checkImportJobStatus(of, action.payload.historyObj, true);
                return actions.notificationPollingHandler();
            });

const resHandler = (response, of, historyObj: Object) => {
    if ((Object.keys(response).length > 0) && response.jobs && response.count) {
        of(actions.importJobsCookieDataHandler(
            response.jobs,
            c.IMPORT_ACTION_ADD, historyObj,
        ));
    }
    checkImportJobStatus(of, historyObj, true);
    return actions.notificationPollingHandler();
};

export const getInProgressImportJobs =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) => actions$
        .ofType(c.FETCH_IN_PROGRESS_IMPORT_JOBS)
        .mergeMap((action) => {
            let params = 'page=0&limit=10&sort=date:desc&status=INPROGRESS';
            params += `&cb=${new Date().getTime()}`;
            return getJSON(`${config.get('FLEET_IMPORT_SERVICES_URL')}/importjobs?${params}`, getHeaders())
                .map(result => resHandler(result, of, action.payload.historyObj))
                .catch(error => of(resHandler(error, of, action.payload.historyObj)));
        });

export const checkInProgressImportJobStatus =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) => actions$
        .ofType(c.FETCH_IN_PROGRESS_IMPORT_JOBS_STATUS)
        .mergeMap(action => postJSON(`${config.get('FLEET_IMPORT_SERVICES_URL')}/importjobs`, action.payload.inProgressJobIDs, getHeaders(), { bypassAuthError: true })
            .map((res) => {
                actions.importJobStatusHandler(
                    action.payload.historyObj,
                    res.status || 0,
                    (res.response && res.response.constructor === Array) ? res.response : [],
                );
                return actions.notificationPollingHandler();
            })
            .catch(() => of(actions.notificationPollingHandler())));

export const addNewInProgressImportJob = (actions$: Function) =>
    actions$
        .ofType(c.ADD_NEW_IN_PROGRESS_IMPORT_JOB)
        .map((action) => {
            actions.importJobsCookieDataHandler(
                action.payload.data,
                c.IMPORT_ACTION_ADDNEW,
                action.payload.historyObj,
            );
            return actions.notificationPollingHandler();
        });

export const removeInProgressImportJob = (actions$: Function) =>
    actions$
        .ofType(c.REMOVE_IN_PROGRESS_IMPORT_JOB)
        .map((action) => {
            actions.importJobsCookieDataHandler(
                action.payload.data,
                c.IMPORT_ACTION_REMOVE,
                action.payload.historyObj,
            );
            return actions.notificationPollingHandler();
        });

export default combineEpics(
    addNewInProgressImportJob, removeInProgressImportJob, startJobStatusPolling,
    getInProgressImportJobs, checkInProgressImportJobStatus, unsubscribePolling,
);
