/* @flow */
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Avatar, Grid, ListItem, Tooltip, Typography } from '@mui/material';
import customStyles from './AssetGroupDetails.module.scss';
import { getAssetColorFromStatus } from './../../util/trip_util';
import GetSvgIcon, { AssetNonReporting } from './../../util/svgImage_util';
import { getAssetIconType, hoverIcon, haloEffectTimeout, getLandmarkOrLocation } from '../../util/map_utils';
import {
    getAssetStatusStartDate,
    getPermissionValue,
    setCustomAssetStatusStartDate,
    roundOffUnitValueAsPerUserSetting,
    getSpeedUnitString,
    isPairedAsset,
    isAssetNonReporting,
    getTimeStr,
    getTimeDiff,
    getNonReportingToolTip,
} from '../../helper-classes/utility-functions';

let height = 0;
if (document.documentElement) {
    height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

type Props = {
    asset: Object,
    redirectTo: Function,
}

const styles = theme => ({
    root: {
        height: height - 348,
        width: '100%',
        overflow: 'auto',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
    },
});

function statusHelper(assetStatus) {
    return assetStatus.charAt(0).toUpperCase() + assetStatus.substr(1);
}

function AssetListItem(props : Props) {
    const { asset } = props;
    let isHoverIconCalled = false;
    const isNonReporting = isAssetNonReporting(asset);
    let lastReporting = '';

    if (isNonReporting) {
        lastReporting = asset.lastEventDate ? `last report ${getTimeStr(getTimeDiff(asset.lastEventDate, new Date()))} ago` : 'never reported';
    }

    const haloEffect = (isHover: boolean) => {
        isHoverIconCalled = isHover;
        hoverIcon(asset, isHover);
    };

    const handleClusterRedirect = (e: Object, redirectURL: string) => {
        e.preventDefault();
        e.stopPropagation();
        props.redirectTo(e, redirectURL);
    };

    return (
        <ListItem
            button={getPermissionValue('Assets') !== 'None'}
            id="asset-list-item"
            className={customStyles['list-item']}
            onMouseEnter={() => haloEffectTimeout(() => haloEffect(true))}
            onMouseOver={() => !isHoverIconCalled && haloEffectTimeout(() => haloEffect(true))}
            onFocus={() => {}}
            onMouseLeave={() => haloEffectTimeout(() => haloEffect(false))}
        >
            <Grid container spacing={0}>
                <Grid item xs={2}>
                    <React.Fragment>
                        {isNonReporting &&
                        <React.Fragment>
                            <AssetNonReporting
                                className={customStyles.tooltip}
                            />
                            <span className={customStyles.tooltipText}>
                                {getNonReportingToolTip(asset)}
                            </span>
                        </React.Fragment>
                        }
                        <Avatar id="asset-avatar" className={customStyles['asset-avatar']} style={{ backgroundColor: getAssetColorFromStatus(asset) }}>
                            <GetSvgIcon
                                type={getAssetIconType(asset.attributes.icon || asset.typeCode)}
                            />
                        </Avatar>
                    </React.Fragment>
                </Grid>
                <Grid item xs={10}>
                    <Grid container spacing={0}>
                        <Grid item xs={9}>
                            <div>
                                <Tooltip title={asset.name || ''} disableFocusListener>
                                    <Typography id="asset-name" className={customStyles['asset-name']} component="p">
                                        {asset.name}
                                    </Typography>
                                </Tooltip>
                                <div className={customStyles['movement-and-last-location']}>
                                    {isPairedAsset(asset) ?
                                        <React.Fragment>
                                            {isNonReporting ?
                                                <React.Fragment>
                                                    <span className={customStyles[`${statusHelper(asset.status)}`]}>
                                                        {statusHelper(asset.status)}
                                                    </span>
                                                    <Tooltip title={lastReporting || ''}>
                                                        <span
                                                            className={
                                                                customStyles.last_reporting_text
                                                            }
                                                        >
                                                            &nbsp;({lastReporting})
                                                        </span>
                                                    </Tooltip>
                                                </React.Fragment>
                                                :
                                                <Typography
                                                    id="asset-status"
                                                    className={customStyles[`${statusHelper(asset.status)}`]}
                                                    component="p"
                                                >
                                                    {asset.status ?
                                                        `${statusHelper(asset.status)} ${setCustomAssetStatusStartDate(getAssetStatusStartDate(asset))}` :
                                                        '-- --'
                                                    }
                                                </Typography>
                                            }
                                            <Typography className={customStyles['last-location']} id="asset-last-location" component="p">
                                                {getLandmarkOrLocation(
                                                    asset,
                                                    true,
                                                    handleClusterRedirect,
                                                )}
                                            </Typography>
                                        </React.Fragment>
                                        :
                                        <div className={customStyles['last-location']}>
                                            <div>This asset is not currently</div>
                                            <div>connected to a device</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div id="asset-direction">
                                {(asset.speed != null) &&
                                    <Typography paragraph className={customStyles['asset-details-right-side']} align="right" component="p" id="asset-speed">
                                        {`${roundOffUnitValueAsPerUserSetting(asset.speed || 0)} ${getSpeedUnitString()}`}
                                    </Typography>}

                                {(!asset.speed) && <Typography paragraph align="right" component="p" />}
                                <div className={customStyles['asset-direction-and-direction-icon']}>
                                    {asset.direction &&
                                    <React.Fragment>
                                        <i className={customStyles[`${asset.direction}_dir`]} />
                                        <Typography id="asset-direction" className={customStyles['asset-details-right-side']} align="right" component="p">
                                            {asset.direction}
                                        </Typography>
                                    </React.Fragment>
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ListItem>
    );
}

export default withStyles(styles)(AssetListItem);
