import React, { Component } from 'react';
import { reduxConnect } from '../../../hoc';
import * as actions from './actions.alertRecipient';
import AlertRecipient from '../../../components/Alerts/Steppers/AlertRecipient';
import { ALERT_RECIPIENT } from '../constants.alerts';
import { validEmailOrPhoneNumber, recipientLabel, validateEmail, validatePhoneNumber } from '../../../util/alert_utils';

type Props = {
    onChange: Function,
    formData: any,
    fetchRecipientList: Function,
    getRecipientJson: Function,
    recipientData: [],
    hideComments: boolean,
};

type State = {
    chipData: any,
    chipObject: any,
    recipientInputValue: any,
    searchIsLoading: boolean,
    initialPageLoad: boolean,
    recipientJsonArray: any,
};

let recipientList = [];

class AlertRecipientList extends Component<Props, State> {
    constructor() {
        super();
        this.state = {
            chipData: [],
            recipientInputValue: '',
            searchIsLoading: false,
            initialPageLoad: true,
            errors: {},
            recipientJsonArray: [],
        };
    }

    UNSAFE_componentWillMount() {
        if (this.props.recipientData.length > 0) {
            const chipData = this.props.recipientData.map((object, index) => {
                let label = '';
                label = object.email.length > 0 ? object.email : object.phoneNumber;
                const chipObject = {
                    key: index,
                    label,
                };
                return chipObject;
            });
            this.setState({
                recipientJsonArray: this.props.recipientData,
                chipData,
            });
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.recipientList.isLoading === false) {
            const response = newProps.recipientList;
            recipientList = [];
            if (
                response.recipientList.data !== undefined &&
                response.recipientList.data.length > 0
            ) {
                recipientList = response.recipientList.data.map((object) => {
                    const emailOrMobile = (object.email === '' || object.email === null) ?
                        object.mobileNumber : object.email;
                    const recipientWithLabel = {
                        label: emailOrMobile,
                    };
                    return recipientWithLabel;
                });
            }
            this.setState({
                searchIsLoading: false,
                initialPageLoad: false,
                errors: {},
            });
        }
    }

    // Triggers on every searchField text change
    onTextChange = (e) => {
        const userSearchValue = e.target.value;
        this.setState({
            recipientInputValue: userSearchValue,
        });
        // Send API call
        if (userSearchValue.length > 3) {
            this.setState({
                searchIsLoading: true,
            });
            this.props.fetchRecipientList(userSearchValue);
        }
    };

    // Update state on selection of user from list
    onRecipientSelect = (event) => {
        this.setState({
            recipientInputValue: event,
        });
    };
    // Update alertRecipientData and populate the data on Add
    onRecipientAdd = () => {
        const errors = {};
        let formIsValid = true;
        const { recipientInputValue } = this.state;
        if (recipientInputValue.toString().length === 0) {
            formIsValid = false;
            errors.name = ALERT_RECIPIENT.ERROR.EMPTY;
        }
        if (!validEmailOrPhoneNumber(recipientInputValue)) {
            formIsValid = false;
            errors.name = ALERT_RECIPIENT.ERROR.INVALID;
        }
        this.setState({
            errors,
        });
        // form-validation before adding chip data
        if (formIsValid && recipientInputValue.toString().length > 0) {
            // Checking for givenUserEmail is unique or not
            let recipientEmailMobileUnique = true;

            // email validation
            const isItEmail = validateEmail(recipientInputValue);
            // phone number validation
            const isItPhoneNumber = validatePhoneNumber(recipientInputValue);

            let label = '';
            if (isItEmail) {
                label = recipientInputValue;
            } else if (isItPhoneNumber) {
                label = recipientLabel(recipientInputValue);
            }

            const chipDataObject = this.state.chipData.find(chipObject => (
                chipObject.label.toLowerCase() === label.toLowerCase()
            ));
            if (chipDataObject !== undefined && chipDataObject !== null) {
                recipientEmailMobileUnique = false;
            }

            if (recipientEmailMobileUnique) {
                let key = 0;
                if (this.state.chipData.length > 0) {
                    for (let i = 0; i <= this.state.chipData.length; i += 1) {
                        const chipKeyPresent = this.state.chipData
                            .filter(chipObject => (chipObject.key === i));
                        if (chipKeyPresent.length === 0) {
                            key = i;
                            break;
                        }
                    }
                }
                const chipObject = {
                    key,
                    label,
                };
                const { chipData } = this.state;
                chipData.push(chipObject);

                // Post Recipient Json Object
                const recipientArray = chipData.map((item) => {
                    const recipientJsonObject = {
                        email: '',
                        phoneNumber: '',
                    };

                    if (validateEmail(item.label)) {
                        recipientJsonObject.email = item.label;
                        recipientJsonObject.phoneNumber = '';
                    } else {
                        recipientJsonObject.email = '';
                        recipientJsonObject.phoneNumber = item.label;
                    }
                    return recipientJsonObject;
                });
                this.setState({
                    chipData,
                    recipientInputValue: '',
                    errors: {},
                    recipientJsonArray: recipientArray,
                }, () => {
                    this.handleRecipientJsonUpdate(this.state.recipientJsonArray);
                });
            }
        }
    };
    // Delete alert Recipient data
    handleDelete = (data) => {
        this.setState((state) => {
            const chipData = [...state.chipData];
            const chipToDelete = chipData.indexOf(data);
            chipData.splice(chipToDelete, 1);
            this.handleRecipientJsonOnDelete(data);
            return { chipData };
        });
    };

    handleRecipientJsonOnDelete = (data) => {
        const recipientJsonArray = this.state.recipientJsonArray.filter((object) => {
            const label = object.email.length > 0 ? object.email : object.phoneNumber;
            return (label !== data.label);
        });
        this.state.recipientJsonArray = recipientJsonArray;
        this.handleRecipientJsonUpdate(recipientJsonArray);
    };

    handleRecipientJsonUpdate = (recipientJsonArray) => {
        this.props.getRecipientJson(recipientJsonArray);
    };

    render() {
        return (
            <AlertRecipient
                handleCheckboxChange={this.handleCheckboxChange}
                recipientChecked={this.state.recipientChecked}
                onRecipientSelect={this.onRecipientSelect}
                onRecipientAdd={this.onRecipientAdd}
                handleDelete={this.handleDelete}
                chipData={this.state.chipData}
                recipientInputValue={this.state.recipientInputValue}
                onTextChange={this.onTextChange}
                initialPageLoad={this.state.initialPageLoad}
                recipientList={recipientList}
                searchIsLoading={this.state.searchIsLoading}
                errors={this.state.errors}
                onChange={this.props.onChange}
                formData={this.props.formData}
                hideComments={this.props.hideComments}
            />
        );
    }
}

const mapStateToProps = state => ({
    recipientList: state.recipientList,
});

AlertRecipientList.defaultProps = {
    recipientData: [],
    hideComments: false,
};

export default reduxConnect(AlertRecipientList, actions, mapStateToProps);
