/* @flow */
import { ASSET_SEARCH, ASSET_SEARCH_ERROR, ASSET_SEARCH_SUCCESS } from './constants.assetSearch';

export const assetSearch = (query: string, page: number, rowsPerPage: number) => ({
    type: ASSET_SEARCH,
    payload: { query, page, rowsPerPage },
});

export const assetSearchError = (error: any, response: any) => ({
    type: ASSET_SEARCH_ERROR,
    payload: { error, response },
    meta: {
        analytics: {
            type: ASSET_SEARCH_ERROR,
        },
    },
});

export const assetSearchSuccess = (data: any, count: number, total: number, query: string) => ({
    type: ASSET_SEARCH_SUCCESS,
    payload: {
        data, count, total, query,
    },
});

