/* @flow */

import { FETCH_USER_ACCESS_LIST, FETCH_USER_ACCESS_LIST_SUCCESS, FETCH_USER_ACCESS_LIST_ERROR, RESET_USER_ACCESS_LIST } from './constants.userAccessList';
import type { UserAccessListTypeAction } from './constants.userAccessList';

export const fetchUserAccessList = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filter: string,
): UserAccessListTypeAction => ({
    type: FETCH_USER_ACCESS_LIST,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
        filter,
    },
});

export const fetchUserAccessListSuccess =
    (userAccess: any, totalUserAccess: number): UserAccessListTypeAction => ({
        type: FETCH_USER_ACCESS_LIST_SUCCESS,
        payload: { userAccess, totalUserAccess },
    });

export const fetchUserAccessListError = (error: string): UserAccessListTypeAction => ({
    type: FETCH_USER_ACCESS_LIST_ERROR,
    payload: { error },
});

export const resetTable =
    (): UserAccessListTypeAction => ({
        type: RESET_USER_ACCESS_LIST,
    });

