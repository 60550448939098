/* @flow */
import React, { Component, Fragment } from 'react';
import { ArrowBack } from '@mui/icons-material';
import {
    Paper,
    Typography,
    Toolbar,
    IconButton,
    Button,
    Grid,
    Select,
    MenuItem,
    Tooltip,
    Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import customStyles from './../EditDashcam/EditDashcam.module.scss';
import styles from './EventSummary.module.scss';
import {
    getEventSetting,
    updateEventSetting,
    getDeviceTelemetry,
    getAssetByDashcamIdentifier,
} from './../../../containers/DashCam/helper-classes/dashcamServices';
import { EVENT_TYPE_MAPPING, SUPPORTED_EVENT_TYPES, EVENT_TYPES_CATEGORY, EVENT_TAILGATING_TIME_TO_COLLISION } from './constants';
import analytics from '../../../analytics/index';
import { DASHCAM_ANALYTICS, DASHCAM_EVENT_SETTING } from '../../../containers/DashCam/constants.dashcam';
import {
    capitalizeFirstChar,
    getCalibrationURL,
} from '../../../helper-classes/utility-functions';
import AppDialog from '../../SharedComponents/Dialog/Dialog';
import ExternalSite from '../../ExternalSite/ExternalSite';
import { ReCalibration } from './../../../util/svgImage_util';
import { getDashcamOrgId, getDashcamToken } from '../../../util/dashcam_util';
import dashcamRefresh from './../../../assets/icons/refresh.png';

export type Props = {
    imei: string,
    dashcamName: string,
    status: string,
    showNotification: Function,
    showEvent: Function,
    showLoader: Function,
};

export type State = {
  setting: Object,
  responseData: Object,
  disableSaveButton: boolean,
  settingEventObj: Object,
  prevResponseEvents: Object,
  hideMessage: boolean,
  force: boolean,
  showReCalibration: boolean,
  hideEventSettingLoader: boolean,
  hideDeviceTelemetryLoader: boolean,
  telemetryData: any,
  assetId: string,
};

class EventSetting extends Component<Props, State> {
    constructor(props: Object) {
        super(props);
        this.state = {
            setting: this.initSettingState([]),
            responseData: {},
            disableSaveButton: false,
            settingEventObj: {},
            prevResponseEvents: {},
            hideMessage: true,
            force: false,
            showReCalibration: false,
            hideEventSettingLoader: false,
            hideDeviceTelemetryLoader: false,
            telemetryData: {},
            assetId: '',
        };
    }

    componentDidMount() {
        this.getAssetData();
        window.addEventListener('message', this.handleMessage);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleMessage);
    }

    getAssetData = () => {
        this.props.showLoader(true);
        getAssetByDashcamIdentifier(this.props.imei).then((res) => {
            if (res.assetId) {
                this.setState({ assetId: res.assetId });
            }
            this.getEventSettingData(this.props.imei);
            this.getDeviceTelemetryData();
        });
    }

    handleMessage = (e: any) => {
        if (e.data === 'close-app-dialog') {
            this.handleDialogClose();
        } else if (e.data === 'successfully-calibrated') {
            if (!this.checkReCalibrationStatus()) {
                this.setState({ showReCalibration: true });
            }
            analytics.track('CALIBRATE_SUCCESS', { feature: 'Dashcam' });
        } else if (e.data === 'calibration-error') {
            analytics.track('CALIBRATE_ERROR', { feature: 'Dashcam' });
        }
    }

    handleDialogClose = () => {
        this.setState({
            hideMessage: true,
        });
    }

    initSettingState = (settings: Array<Object>) => {
        const settingsValue = JSON.parse(JSON.stringify(EVENT_TYPE_MAPPING));
        if (settings && Array.isArray(settings)) {
            settings.forEach((d) => {
                if (settingsValue[d.eventType] && settingsValue[d.eventType].value) {
                    settingsValue[d.eventType].value = d.dataType;
                    if (d.config && d.eventType === 'tailgating') {
                        settingsValue[d.eventType].config = this.setTailgatingConfig(d.config);
                    }
                }
            });
        }

        return settingsValue;
    }

    setTailgatingConfig = (config: Object) => {
        const { headwayAlertDayThreshold } = config;
        if (headwayAlertDayThreshold === 2000) return 'default';
        else if (headwayAlertDayThreshold === 1000) return 'lessSensitive';
        else if (headwayAlertDayThreshold === 3000) return 'moreSensitive';
        return 'default';
    }

    trackAnalytics = (settingEventObj: Object) => {
        analytics.track(
            DASHCAM_ANALYTICS,
            {
                eventName: DASHCAM_EVENT_SETTING,
                feature: DASHCAM_ANALYTICS,
                ...settingEventObj,
            },
        );
    }

    hideLoader = () => {
        const { hideDeviceTelemetryLoader, hideEventSettingLoader } = this.state;
        if (hideDeviceTelemetryLoader && hideEventSettingLoader) this.props.showLoader(false);
    }

    getDeviceTelemetryData = () => {
        const { imei } = this.props;
        this.props.showLoader(true);
        getDeviceTelemetry({ imei }).then((response: any) => {
            this.setState({ telemetryData: {} }, () => {
                this.setState({ hideDeviceTelemetryLoader: true }, () => {
                    this.hideLoader();
                });
                if (response.status === 200 && response.data) {
                    this.setState({ telemetryData: response.data });
                } else {
                    this.setState({ telemetryData: { showRefresh: true } });
                }
            });
        });
    }

    getEventSettingData = (imei: string) => {
        this.props.showLoader(true);
        const { assetId } = this.state;
        getEventSetting({ imei, assetId }).then((response: any) => {
            this.setState({ hideEventSettingLoader: true }, () => {
                this.hideLoader();
            });

            if (response.status === 200 && response.data) {
                if (response.data && response.data.events) {
                    const setting = this.initSettingState(response.data.events);
                    this.setState({
                        setting,
                        responseData: response.data,
                        prevResponseEvents: response.data.events,
                    });
                }
            } else {
                this.props.showNotification(response.message);
                this.setState({ disableSaveButton: true });
            }
        });
    }

    generateRequestData = (settings: Object) => {
        const eventsData = SUPPORTED_EVENT_TYPES.map((k: string) => {
            const { config } = settings[k];
            if (k === 'tailgating' && config) {
                return ({
                    dataType: settings[k].value,
                    eventType: k,
                    config: EVENT_TAILGATING_TIME_TO_COLLISION[config],
                    virtualEvent: settings[k].virtualEvent,
                    isModified: settings[k].isModified,
                });
            }
            return ({
                dataType: settings[k].value,
                eventType: k,
                virtualEvent: settings[k].virtualEvent,
                isModified: settings[k].isModified,
            });
        });
        const isSurfSightEventsModified = eventsData
            .some(d => d.isModified && d.virtualEvent === false);
        const surfSightEvents = eventsData.filter(d => d.dataType !== 'off' && d.virtualEvent === false);
        const virtualEventData = eventsData.filter(d => d.virtualEvent === true);
        const isVirtualEventsModified = virtualEventData.some(d => d.isModified);
        return {
            surfSightEvents,
            isSurfSightEventsModified,
            virtualEventData,
            isVirtualEventsModified,
        };
    }

    updateInitSettting = () => {
        const setting = this.initSettingState([]);
        this.setState({ setting });
    }

    updateEventSettingClick = () => {
        this.props.showLoader(true);
        const settings = { ...this.state.setting };
        const { responseData, assetId } = this.state;
        const requestData = this.generateRequestData(settings);

        const surfSightEvents = {
            vehicleType: responseData.vehicleType,
            events: requestData.surfSightEvents.map((d) => {
                const newData = d;
                if (newData.isModified) delete newData.isModified;
                if (newData.virtualEvent) delete newData.virtualEvent;
                return newData;
            }),
        };
        const virtualEventData = {
            eventSettings: requestData.virtualEventData,
        };

        updateEventSetting(this.props.imei, {
            ...requestData,
            surfSightEvents,
            virtualEventData,
            assetId,
        }).then((response: any) => {
            this.props.showLoader(false);

            if (response.status === 200) {
                this.eventSettingTrack();
                this.props.showEvent();
                this.updateInitSettting();
                this.props.showNotification('Event settings updated successfully');
            } else this.props.showNotification(response.message);
        });
    }

    calibrate = (forceFlag: boolean) => {
        this.setState({
            hideMessage: false,
            force: forceFlag,
        });
        if (forceFlag) {
            analytics.track('RECALIBRATE', { feature: 'Dashcam' });
        } else analytics.track('CALIBRATE', { feature: 'Dashcam' });
    }

    getKeyBasedText = (key: string) => (EVENT_TYPE_MAPPING[key] ? EVENT_TYPE_MAPPING[key].name : '')

    handleChange = (value: string, key: string) => {
        const { setting, settingEventObj } = this.state;

        if (key === 'tailgatingCollisionTime' && value) {
            setting.tailgating.config = value;
            setting.tailgating.isModified = true;
            settingEventObj.tailgating = { config: value, tailgating: setting.tailgating.value };
        } else if (value) {
            setting[key].value = value;
            setting[key].isModified = true;
            settingEventObj[key] = value;
        }

        if (key === 'sharpTurnLeft') {
            setting.sharpTurnRight.isModified = true;
            setting.sharpTurnRight.value = value;
        }
        this.setState({
            setting,
            settingEventObj,
        });
    }

    checkDisable = (key: string) => {
        if (key !== 'inCabinCameraRecording') return false;
        return this.state.setting &&
        this.state.setting.driverCamera && this.state.setting.driverCamera.value;
    }

    checkReCalibrationStatus = () => {
        const { telemetryData } = this.state;
        const isTelemetryData = Object.keys(telemetryData).length > 0;
        return isTelemetryData &&
        telemetryData.adasCalibrationReady &&
        telemetryData.adasCalibrationCompleted;
    }

    getTailgatingField = (k: string, key: string) => {
        let innerContent = '';
        const { telemetryData } = this.state;
        const { showReCalibration } = this.state;
        const isTelemetryData = Object.keys(telemetryData).length > 0;
        const gridItemView = { first: 4, second: 8 };
        let subKey = '';
        if (this.props.status === 'offline') {
            gridItemView.first = 3;
            gridItemView.second = 9;
            innerContent = (
                <div className={`${customStyles.offlineDashcam} ${customStyles.offline}`}>
                    Dashcam must be powered on to change tailgating settings.
                </div>);
        } else if (isTelemetryData &&
            telemetryData.showRefresh) {
            gridItemView.first = 3;
            gridItemView.second = 9;
            innerContent = (
                <div className={`${customStyles.offlineDashcam} ${customStyles.refresh}`}>
                    <div>
                        <Tooltip title="Refresh" disableFocusListener>
                            <Button
                                className={customStyles.ReCalibrate}
                                onClick={() => this.getDeviceTelemetryData()}
                            >
                                <img src={dashcamRefresh} alt="DashcamRefresh" style={{ width: 22 }} />
                            </Button>
                        </Tooltip>
                    </div>
                    <div>Tailgating status unavailable. Please refresh.</div>
                </div>);
        } else if (isTelemetryData && !telemetryData.adasCalibrationReady) {
            gridItemView.first = 1;
            gridItemView.second = 11;
            innerContent = (
                <div className={customStyles.calibrationReady}>
                    This dashcam needs to be driven more before it can be calibrated.
                    Please reach out to customer support for any questions.
                </div>);
        } else if (isTelemetryData &&
                telemetryData.adasCalibrationReady &&
                !telemetryData.adasCalibrationCompleted && !showReCalibration) {
            innerContent = (
                <div className={customStyles.notCalibrationCompleted}>
                    <Button
                        className={`${customStyles.button} ${customStyles.settingCalibrateButton}`}
                        variant="contained"
                        color="primary"
                        onClick={() => this.calibrate(false)}
                    >
                        CALIBRATE
                    </Button>
                </div>
            );
            subKey = (
                <div className={customStyles.notCalibrationCompletedSubHeader}>
                    Tailgating is not set up on this device.
                </div>
            );
        } else if (this.checkReCalibrationStatus() || showReCalibration) {
            innerContent = this.getFields(k);
            subKey = (
                <Tooltip title="Re-calibrate device" disableFocusListener>
                    <Button
                        className={customStyles.ReCalibrate}
                        onClick={() => this.calibrate(true)}
                    >
                        <ReCalibration style={{ fontSize: 18 }} />
                    </Button>
                </Tooltip>
            );
        }

        return (
            <Grid container key={k} style={{ height: 50, margin: '16px 0px' }}>
                <Grid item xs={gridItemView.first} className={customStyles.gridItem}>
                    {key}
                    {subKey}
                </Grid>
                <Grid item xs={gridItemView.second}>
                    {innerContent}
                </Grid>
            </Grid>
        );
    }

    getFields = (key: string) => (
        <Fragment>
            <Select
                className={customStyles.eventSelect}
                value={this.state.setting[key].value || ''}
                onChange={e => this.handleChange(e.target.value, key)}
                disabled={key === 'accOn' || key === 'accOff'}
            >
                <MenuItem className={styles.menuItem} value="off">Off</MenuItem>
                {!this.state.setting[key].virtualEvent && <MenuItem className={styles.menuItem} value="none">Events</MenuItem>}
                <MenuItem className={styles.menuItem} value="snapshot">Snapshot</MenuItem>
                <MenuItem className={styles.menuItem} value="video">Video</MenuItem>
                <Divider light sx={{ m: '0px !important' }} />
            </Select>
            {key === 'tailgating' &&
                this.getFollowDistanceDropdown(key)
            }
        </Fragment>
    )

    getFollowDistanceDropdown = (key: string) => (
        <Select
            className={customStyles.eventTailgating}
            value={this.state.setting[key].config || ''}
            onChange={e => this.handleChange(e.target.value, 'tailgatingCollisionTime')}
            disabled={this.state.setting[key].value === 'off'}
        >
            <MenuItem disabled className={customStyles.menuItemLabel}>
                Follow Distance
            </MenuItem>
            <MenuItem value="default">Default (2s)</MenuItem>
            <MenuItem value="lessSensitive">{'Less Sensitive (<1s)'}</MenuItem>
            <MenuItem value="moreSensitive">{'More Sensitive (<3s)'}</MenuItem>
        </Select>
    )

    getViewContent = (k: string) => {
        const key = (k !== 'sharpTurnRight') ? this.getKeyBasedText(k) : '';
        if (!key) return '';
        const hideVirtualEventSetting = this.state.setting[k].virtualEvent && !this.state.assetId;

        if (hideVirtualEventSetting) return null;

        return k === 'tailgating' ? this.getTailgatingField(k, key) : (
            <Grid container key={k} style={{ height: 44 }}>
                <Grid item xs={4} className={customStyles.gridItem}>{key}</Grid>
                <Grid item xs={8}>{this.getFields(k)}</Grid>
            </Grid>);
    }

    getEventsViewContent = (eventName: string, eventTypes: any) => (
        <div className={customStyles.eventCategory} key={eventName}>
            <Typography variant="h6" color="inherit" className={customStyles.eventCategoryTitle}>
                <span>{eventName}</span>
                <div className={customStyles.eventsName}>
                    {eventTypes.map(d => this.getViewContent(d))}
                </div>
            </Typography>
        </div>
    )


    backButtonClick = () => {
        this.updateInitSettting();
        this.props.showEvent();
    }

    eventSettingTrack = () => {
        const { settingEventObj, prevResponseEvents } = this.state;
        Object.keys(settingEventObj).forEach((v) => {
            prevResponseEvents.some((r) => {
                if (r.eventType === v && r.dataType !== settingEventObj[v] && !r.config) {
                    const eventObj = {
                        settingEventName: EVENT_TYPE_MAPPING[v].name,
                        settingEventType: capitalizeFirstChar(settingEventObj[v]),
                    };
                    this.trackAnalytics(eventObj);
                    return true;
                } else if (r.eventType === v && r.dataType !== settingEventObj[v] && r.config) {
                    const isEventOff = settingEventObj[v] === 'off';
                    const eventObj = {
                        settingEventName: EVENT_TYPE_MAPPING[v].name,
                        settingEventType: isEventOff ?
                            capitalizeFirstChar(settingEventObj[v]) :
                            capitalizeFirstChar(settingEventObj[v][v]),
                        settingEventFollowDistance: isEventOff ?
                            'Default' : capitalizeFirstChar(settingEventObj[v].config),
                    };
                    this.trackAnalytics(eventObj);
                    return true;
                }
                return false;
            });
        });
    };

    render() {
        const { hideMessage } = this.state;
        const dOpen = !hideMessage;
        return (
            <div className={customStyles.mainContainer}>
                <Paper className={customStyles.paper}>
                    <Toolbar className={customStyles.toolbar}>
                        <span className={customStyles.backButton}>
                            <IconButton onClick={() => this.backButtonClick()} size="large">
                                <ArrowBack />
                            </IconButton>
                        </span>
                        <div className={customStyles.editTitle}>
                            <Typography variant="h6" color="inherit" className={customStyles.titleWrap}>
                                <span>Event Settings</span>
                                <Tooltip title={this.props.dashcamName || ''} placement="bottom-start">
                                    <div className={customStyles.subHeader}>
                                        {this.props.dashcamName}
                                    </div>
                                </Tooltip>
                            </Typography>
                        </div>
                    </Toolbar>
                    <div className={customStyles.textWrap}>
                        {EVENT_TYPES_CATEGORY.map(d => this.getEventsViewContent(d.name, d.events))}
                        <Divider className={customStyles.divider} />
                        <Grid
                            container
                            className={`${customStyles.gridContainer} ${customStyles.gridButton}`}
                        >
                            <Grid
                                item
                                xs={7}
                                className={`${customStyles.gridItem} ${customStyles.center}`}
                            >
                                <Button
                                    className={`${customStyles.button} ${customStyles.cancelButton} ${customStyles.settingCancelButton}`}
                                    onClick={() => this.backButtonClick()}
                                >
                                    CANCEL
                                </Button>
                            </Grid>
                            <Grid item xs={5} className={customStyles.gridItem}>
                                <Button
                                    className={`${customStyles.button} ${customStyles.settingCancelButton}`}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.updateEventSettingClick()}
                                    disabled={this.state.disableSaveButton}
                                >
                                    SAVE
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <AppDialog
                        size="lg"
                        open={dOpen}
                        hideTitle
                        hideAction
                        onClose={() => this.handleDialogClose()}
                        customStyle={{ content: customStyles.content }}
                    >
                        <IconButton
                            onClick={() => this.handleDialogClose()}
                            color="default"
                            style={{
                                position: 'absolute',
                                right: 10,
                                top: 18,
                            }}
                            id="calibrationDialogClose"
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                        <ExternalSite
                            style={{ width: 614, height: 579 }}
                            url={`${getCalibrationURL()}/organization/${getDashcamOrgId()}/device/${this.props.imei}/calibration?token=${getDashcamToken()}&force=${String(this.state.force)}`}
                        />
                    </AppDialog>
                </Paper>
            </div>
        );
    }
}

export default EventSetting;
