/* @flow */
import React, { Component } from 'react';
import moment from 'moment/moment';
import {
    Button, Divider, Grid, Tooltip, Typography,
    FormControl, Input, TextField, NativeSelect, FormHelperText,
} from '@mui/material';
import { reduxConnect } from '../../../hoc/index';
import * as actions from './actions.alertHistory';
import * as alertTypeActions from '../AlertLandingPage/actions.landingPage';
import MiniDrawer from '../../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../../components/SharedComponents/ListComponent/ListComponent';
import customStyles from './AlertHistory.module.scss';
import { filterAlerts } from '../../../util/mapAlertTypes';
import { getAssetsGrails } from '../../../containers/Maintenance/helper-classes/common-services';
import { validateDate } from '../../../util/alert_utils';
import AssetDropDown from './AssetDropDown';
import { getAlertFilterDates, getCurrentAccountUserId } from '../../../helper-classes/utility-functions';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    showHideColumns,
    isUpdatePreference,
    rearrangeColumns,
} from '../../../helper-classes/listUtil';
import AppLoader from '../../../components/AppLoader';
import { ALERT_HISTORY_LIST_PREF_KEY } from './constants.alertHistory';
import { RECORDS_PER_PAGE } from '../../../constants/Config';
import analytics from '../../../analytics';

export type Props = {
    isUpdating: boolean,
    fetchAlertHistory: Function,
    fetchAlertNameList: Function,
    resetTable: Function,
    history: {
        push: Function,
    },
    alertTypes: any,
};

export type State = {
    tableData: {
        listHeaders: Array<{}>,
        listData: Array<{}>,
        totalRecords: number,
    },
    rowsPerPage: number,
    pageNumber: number,
    order: string,
    orderBy: string,
    showHideDatepicker: any,
    filterItems: any,
    alertHistoryFiltersToSend: any,
    assetList: any,
    isSelectClear: boolean,
    selectedAsset: any,
    showListing: boolean,
};

const alertFilterDates = {
    startDate: getAlertFilterDates('7').startDate,
    endDate: getAlertFilterDates('7').endDate,
};

class AlertHistory extends Component<Props, State> {
    unitText: string;
    error: any;
    isDisabled: any;
    userPreferences: any;

    constructor(props: Props) {
        super(props);
        const dateOne = new Date();
        const dateTwo = new Date();
        let dateFrom = new Date(dateOne.setDate(dateOne.getDate() - 7));
        dateFrom = dateFrom.toISOString().slice(0, -14).concat('T00:00:00');

        let dateTo = new Date(dateTwo.setDate(dateTwo.getDate()));
        dateTo = dateTo.toISOString().slice(0, -14).concat('T23:59:59');

        this.state = {
            tableData: {
                listHeaders: this.getListHeader(),
                listData: [],
                totalRecords: 0,
            },
            rowsPerPage: RECORDS_PER_PAGE,
            pageNumber: 0,
            order: 'desc',
            orderBy: 'alertDate',
            showHideDatepicker: 'Lastsevendays',
            filterItems: {
                DateFrom: dateFrom,
                DateTo: dateTo,
                alertSpecName: '',
            },
            alertHistoryFiltersToSend: this.initialFilter(),
            assetList: [],
            isSelectClear: false,
            selectedAsset: null,
            showListing: false,
        };
    }

    UNSAFE_componentWillMount() {
        getUserPreference(this, ALERT_HISTORY_LIST_PREF_KEY);
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (nextprops.alertHistoryList !== undefined) {
            if (nextprops.alertHistoryList.length > 0) {
                const { alertHistoryList, totalRecords } = nextprops;
                this.setState({
                    tableData: {
                        ...this.state.tableData,
                        listData: alertHistoryList,
                        totalRecords,
                    },
                });
            } else {
                this.setState({
                    tableData: {
                        ...this.state.tableData,
                        listData: [],
                        totalRecords: 0,
                    },
                });
            }
        }
    }

    // this function is called from getUserPreference listUtils function
    getData = () => {
        this.props.fetchAlertHistory(
            this.state.pageNumber,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.state.alertHistoryFiltersToSend,
        );
        getAssetsGrails(0, 1500, '', '').then((response) => {
            if (response && response.data) {
                this.setState({
                    assetList: response.data,
                });
            }
        });
        setTimeout(() => {
            this.props.fetchAlertNameList();
        }, 1000);
        this.setState({ showListing: true });
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            { alertDate: 'Date / Time', ...getColumnPreferences('alertDate', preferences) },
            { alertTypeName: 'Alert Type ', ...getColumnPreferences('alertTypeName', preferences), disableSort: true },
            { alertSpecName: 'Alert Name', ...getColumnPreferences('alertSpecName', preferences), disableSort: true },
            { assetName: 'Asset', ...getColumnPreferences('assetName', preferences), disableSort: true },
            { smsOutput: 'Details', ...getColumnPreferences('smsOutput', preferences), disableSort: true },
            {
                locationName: 'Location',
                ...getColumnPreferences('locationName', preferences),
                customMarkup: d => this.getAddress(d),
                disableSort: true,
            },
        ];
        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    initialFilter = () => `[{"property":"accountId","type":"Long","value":${getCurrentAccountUserId()},"operator":"eq"},{"type":"Date","property":"alertDate","value":"${alertFilterDates.startDate}","value2":"${alertFilterDates.endDate}","operator":"between"}]`;

    getComma = d => ((d.city || d.state || d.zip) ? ',' : ' ');

    getAddress = (d) => {
        let location = '';
        if (d.locationName) {
            const address = `${(d.address) ? `${d.address}${this.getComma(d)}` : ''}${(d.city) ? ` ${d.city}` : ''}${(d.state) ? ` ${d.state}` : ''}${(d.zip) ? ` ${d.zip}` : ''}`;
            location = (
                <Tooltip title={address} disableFocusListener>
                    <span>{d.locationName}</span>
                </Tooltip>
            );
        }
        return location;
    }

    clearFilter = () => {
        const alertHistoryFiltersToSend = `[{"property":"accountId","type":"Long","value":${getCurrentAccountUserId()},"operator":"eq"},{"type":"Date","property":"alertDate","value":"${alertFilterDates.startDate}","value2":"${alertFilterDates.endDate}","operator":"between"}]`;

        this.setState({
            showHideDatepicker: 'Lastsevendays',
            ...this.state.filterItems,
            filterItems: {
                alertTypeId: 'null',
                alertSpecName: '',
                assetId: 'All',
            },
            alertHistoryFiltersToSend,
            isSelectClear: true,
            selectedAsset: null,
        });

        this.props.fetchAlertHistory(
            this.state.pageNumber,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            alertHistoryFiltersToSend,
        );
    };

    isOverdue = (x: number) => {
        if (x < 0) return <font color="red">{x}</font>;
        return x;
    };

    isTimeOverdue = (time: string) => {
        if (time && time.charAt(0) === '-') {
            return <font color="red">{time}</font>;
        }
        return time;
    };

    sortTableColumn = (order, orderBy, page) => {
        this.props.fetchAlertHistory(
            page.pageNumber,
            page.rowsPerPage,
            order,
            orderBy,
            this.state.alertHistoryFiltersToSend,
        );
        this.setState({
            order,
            orderBy,
            rowsPerPage: page.rowsPerPage,
            pageNumber: page.pageNumber,
        }, () => {
            saveUserPreference(this, ALERT_HISTORY_LIST_PREF_KEY);
        });
        const params = {
            feature: 'Alerts',
            sortColumn: orderBy,
        };
        analytics.track('ALERT_HISTORY_SORT', params);
    };

    showHideDate = (e) => {
        this.setState({ showHideDatepicker: e.target.value }, () => {
            saveUserPreference(this, ALERT_HISTORY_LIST_PREF_KEY);
        });
        this.handleInputChange(e);
    };

    handleInputChange = e => this.setState({
        ...this.state.filterItems,
        filterItems: {
            ...this.state.filterItems,
            [e.target.name]: e.target.value,
        },
        isSelectClear: false,
    });

    handleFromDateChange = e => this.setState({
        ...this.state.filterItems,
        filterItems: {
            ...this.state.filterItems,
            DateFrom: e.target.value,
        },
    });

    handleToDateChange = e => this.setState({
        ...this.state.filterItems,
        filterItems: {
            ...this.state.filterItems,
            DateTo: e.target.value,
        },
    });

    handleAlertNameTrimSpaces = e => this.setState({
        ...this.state.filterItems,
        filterItems: {
            ...this.state.filterItems,
            alertSpecName: e.target.value.trim(),
        },
    });

    handleAssetSelection = value => this.setState({ selectedAsset: value }, () => {
        this.handleInputChange({
            target: {
                name: 'assetId',
                value: value ? value.value : 'All',
            },
        });
    });

    applyAlertHistoryFilters = () => {
        const { filterItems } = this.state;
        let alertHistoryFiltersToSend = [{
            property: 'accountId',
            type: 'Long',
            value: getCurrentAccountUserId(),
            operator: 'eq',
        }];
        const specName = filterItems.alertSpecName.trim();
        const alertType = filterItems.alertTypeId;
        let valueDate = getAlertFilterDates('today').startDate;
        let valueTwoDate = getAlertFilterDates('today').endDate;

        switch (filterItems.alertDate) {
        case 'Today':
            alertHistoryFiltersToSend.push({
                type: 'Date',
                property: 'alertDate',
                value: valueDate,
                value2: valueTwoDate,
                operator: 'between',
            });
            break;
        case 'Yesterday':
            valueDate = getAlertFilterDates('yesterday').startDate;
            valueTwoDate = getAlertFilterDates('yesterday').endDate;
            alertHistoryFiltersToSend.push({
                type: 'Date',
                property: 'alertDate',
                value: valueDate,
                value2: valueTwoDate,
                operator: 'between',
            });
            break;
        case 'Lastthreedays':
            valueDate = getAlertFilterDates('3').startDate;
            valueTwoDate = getAlertFilterDates('3').endDate;
            alertHistoryFiltersToSend.push({
                type: 'Date',
                property: 'alertDate',
                value: valueDate,
                value2: valueTwoDate,
                operator: 'between',
            });
            break;
        case 'Lastsevendays':
            valueDate = getAlertFilterDates('last7days').startDate;
            valueTwoDate = getAlertFilterDates('last7days').endDate;
            alertHistoryFiltersToSend.push({
                type: 'Date',
                property: 'alertDate',
                value: valueDate,
                value2: valueTwoDate,
                operator: 'between',
            });
            break;
        case 'Daterange':
            if (!filterItems.DateFrom || !filterItems.DateTo) {
                alertHistoryFiltersToSend.push({
                    type: 'Date',
                    property: 'alertDate',
                    value: alertFilterDates.startDate,
                    value2: alertFilterDates.endDate,
                    operator: 'between',
                });
            } else {
                valueDate = moment.utc(moment(filterItems.DateFrom).startOf('day')).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
                valueTwoDate = moment.utc(moment(filterItems.DateTo).endOf('day')).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
                alertHistoryFiltersToSend.push({
                    type: 'Date',
                    property: 'alertDate',
                    value: valueDate,
                    value2: valueTwoDate,
                    operator: 'between',
                });
            }
            break;
        default:
            alertHistoryFiltersToSend.push({
                type: 'Date',
                property: 'alertDate',
                value: alertFilterDates.startDate,
                value2: alertFilterDates.endDate,
                operator: 'between',
            });
        }

        if (alertHistoryFiltersToSend) {
            if (specName) {
                alertHistoryFiltersToSend.push({
                    type: 'String',
                    property: 'alertSpecName',
                    value: specName,
                    operator: 'ilike',
                });
            }

            if (alertType !== undefined) {
                if (alertType !== 'null') {
                    alertHistoryFiltersToSend.push({
                        type: 'Long',
                        property: 'alertTypeId',
                        value: alertType,
                        operator: 'eq',
                    });
                }
            }

            if (filterItems.assetId !== undefined) {
                if (filterItems.assetId !== 'All') {
                    alertHistoryFiltersToSend.push({
                        type: 'Long',
                        property: 'assetId',
                        value: filterItems.assetId,
                        operator: 'eq',
                    });
                }
            }
        }

        alertHistoryFiltersToSend = JSON.stringify(alertHistoryFiltersToSend);
        this.setState({
            alertHistoryFiltersToSend,
            pageNumber: 0,
        });

        this.props.fetchAlertHistory(
            0,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            alertHistoryFiltersToSend,
        );
        const { alertTypeId, assetId, alertSpecName } = this.state.filterItems || {};
        const params = {
            feature: 'Alerts',
            dateRange: this.state.showHideDatepicker,
            alertType: alertTypeId && alertTypeId !== 'null' ? 'true' : 'false',
            allAssets: (assetId === undefined || assetId === 'All') ? 'true' : 'false',
            alertName: alertSpecName ? 'true' : 'false',
        };
        analytics.track('ALERT_HISTORY_FILTER', params);
    };

    loadNextPage = (rows, value, order, orderBy) => {
        const updateUserPref = isUpdatePreference(this, { rowsPerPage: value, order, orderBy });
        this.setState({
            pageNumber: rows,
            rowsPerPage: value,
            order,
            orderBy,
        }, () => {
            if (updateUserPref) saveUserPreference(this, ALERT_HISTORY_LIST_PREF_KEY);
        });

        this.props.fetchAlertHistory(
            rows,
            value,
            order,
            orderBy,
            this.state.alertHistoryFiltersToSend,
        );
    }

    refreshList = (page) => {
        this.props.fetchAlertHistory(
            page.page,
            page.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.state.alertHistoryFiltersToSend,
        );
    }

    renderAlertHistoryFilters = (
        action,
        page = { page: 0, rowsPerPage: this.state.rowsPerPage },
    ) => {
        if (action === 'refresh') {
            this.refreshList(page);
        } else {
            const styles = {
                button: { flex: 'auto', opacity: '.87', color: '#007aff' },
                checkbox: { color: '#007aff' },
                subheading: { fontSize: 15, opacity: '.87', padding: 20 },
                title: {
                    flex: 'auto',
                    fontSize: '20px',
                    opacity: '.87',
                    padding: 20,
                },
            };

            const inputProps = {
                max: new Date().toISOString().slice(0, 16),
            };

            return (
                <div style={{ padding: 20 }}>
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6" id="filterTitle" style={styles.title}>
                        Filters
                        </Typography>
                        <Button
                            className="classes.button"
                            color="primary"
                            style={styles.button}
                            onClick={() => this.clearFilter()}
                        >
                        CLEAR
                        </Button>
                    </div>
                    <Divider />
                    <div className={customStyles.filterItems}>
                        <div>Date Range*</div>
                        <div className={customStyles.filterItem}>
                            <FormControl className="ghgh">
                                <NativeSelect
                                    name="alertDate"
                                    className="fgfg"
                                    onChange={e => this.showHideDate(e)}
                                    value={this.state.showHideDatepicker}
                                >
                                    <option value="Today">Today</option>
                                    <option value="Yesterday">Yesterday</option>
                                    <option value="Lastthreedays">Last 3 Days</option>
                                    <option value="Lastsevendays">Last 7 days </option>
                                    <option value="Daterange">Date Range</option>
                                </NativeSelect>
                            </FormControl>
                        </div>
                    </div>
                    <div className={customStyles.filterItems}>
                        <div className={customStyles.filterItemDate}>
                            {this.state.showHideDatepicker === 'Daterange' ? (
                                <Grid container spacing={2.8} id="divShow">
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="datefrom"
                                            name="DateFrom"
                                            label="Date From"
                                            type="datetime-local"
                                            inputProps={inputProps}
                                            defaultValue={this.state.filterItems.DateFrom}
                                            onChange={this.handleFromDateChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="dateto"
                                            name="DateTo"
                                            onChange={this.handleToDateChange}
                                            label="DateTo"
                                            type="datetime-local"
                                            inputProps={inputProps}
                                            defaultValue={this.state.filterItems.DateTo}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <FormHelperText style={{ color: 'red' }}>
                                            { this.error }
                                        </FormHelperText>
                                    </Grid>
                                </Grid>
                            ) : ('')}
                        </div>
                    </div>
                    <div className={customStyles.filterItems}>
                        <div>Alert Type</div>
                        <div className={customStyles.filterItem}>
                            <FormControl className="ghgh">
                                {this.props.alertTypes === undefined ? (
                                    <NativeSelect>
                                        <option value="">Loading...</option>
                                    </NativeSelect>
                                ) : (
                                    <NativeSelect
                                        name="alertTypeId"
                                        className="fgfg"
                                        onChange={this.handleInputChange}
                                        value={this.state.filterItems.alertTypeId}
                                    >
                                        <option value="null">[Select Alert Type]</option>
                                        {
                                            filterAlerts(this.props.alertTypes)
                                                .sort((a, b) => a.name.localeCompare(b.name))
                                                .map(e => (
                                                    <option key={e.id} value={e.id}>
                                                        {e.name}
                                                    </option>
                                                ))
                                        }
                                    </NativeSelect>
                                )}
                            </FormControl>
                        </div>
                    </div>
                    <div className={customStyles.filterItems}>
                        <div>Asset</div>
                        <div style={{ width: '99%' }}>
                            <AssetDropDown
                                assetList={this.state.assetList}
                                assetId={this.state.filterItems.assetId}
                                handleAssetSelection={this.handleAssetSelection}
                                isSelectClear={this.state.isSelectClear}
                                selectedAsset={this.state.selectedAsset}
                            />
                        </div>
                    </div>
                    <div className={customStyles.filterItems}>
                        <div>Alert Name</div>
                        <div className={customStyles.filterItem}>
                            {' '}
                            <Input
                                placeholder="[Enter alert Name]"
                                name="alertSpecName"
                                onChange={this.handleInputChange}
                                onBlur={this.handleAlertNameTrimSpaces}
                                value={this.state.filterItems.alertSpecName}
                            />
                        </div>
                    </div>
                    <br />
                    <div className={customStyles.filterItems}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.applyAlertHistoryFilters}
                            disabled={this.isDisabled}
                        >
                        Apply
                        </Button>
                    </div>
                </div>
            );
        }
        return '';
    };

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: ALERT_HISTORY_LIST_PREF_KEY };
        rearrangeColumns(this, listHeaders, options);
    }

    showHideColumn = (header) => {
        const options = { prefKey: ALERT_HISTORY_LIST_PREF_KEY };
        showHideColumns(this, header, options);
    }

    render() {
        const validationErrors = validateDate(
            this.state.filterItems.DateFrom,
            this.state.filterItems.DateTo,
        );
        const errorMessage = Object.values(validationErrors)[0];
        this.error = errorMessage;
        const isDisabled = Object.keys(validationErrors).some(x => validationErrors[x]);
        this.isDisabled = isDisabled;

        return (
            <MiniDrawer redirectTo={this.props.history.push}>
                {this.state.showListing ?
                    <ListComponent
                        redirectTo={this.props.history.push}
                        tableData={this.state.tableData}
                        loadNextPage={this.loadNextPage}
                        FiltersToSend={this.state.alertHistoryFiltersToSend}
                        resetTable={this.props.resetTable}
                        isUpdating={this.props.isUpdating}
                        sortTableColumn={this.sortTableColumn}
                        showHideColumn={this.showHideColumn}
                        listTypeContextAction={this.renderAlertHistoryFilters}
                        tableTitle="Alert History"
                        tableButtons={['Refresh', 'Filter List']}
                        resizableHeader
                        showSearch={false}
                        clickRow={() => { }}
                        orderBy={this.state.orderBy}
                        order={this.state.order}
                        rowsPerPage={this.state.rowsPerPage}
                        rearrangeColumns={this.rearrangeColumns}
                    />
                    :
                    <div style={{ height: '100%' }}>
                        <AppLoader type="fullScreen" />
                    </div>
                }
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => ({
    alertHistoryList: state.alertHistoryList.alertHistory,
    totalRecords: state.alertHistoryList.totalRecords,
    isUpdating: state.alertHistoryList.isUpdating,
    alertTypes: state.alertNames.alertNames.data,
});

const mapDispatchToProps = {
    ...actions,
    ...alertTypeActions,
};

export default reduxConnect(AlertHistory, mapDispatchToProps, mapStateToProps);
