/* @flow */

import {
    FETCH_LANDMARKS,
    FETCH_LANDMARKS_SUCCESS,
    FETCH_LANDMARKS_ERROR,
    FETCH_NEAREST_ASSET,
    FETCH_NEAREST_ASSET_SUCCESS,
    FETCH_NEAREST_ASSET_ERROR,
    FETCH_ASSET_IN_LANDMARK,
    FETCH_ASSET_IN_LANDMARK_SUCCESS,
    FETCH_ASSET_IN_LANDMARK_ERROR,
} from './constants.landmarks';
import type { LandmarkTypeAction } from './constants.landmarks';
import store from '../../../constants/Store';

export const fetchLandmarks = (id: string, assetInLandmarkfilter: Object =
{ showLoader: true, recordsPerPage: 50, currentPage: 0 }): LandmarkTypeAction => ({
    type: FETCH_LANDMARKS,
    payload: { id, showLoader: assetInLandmarkfilter.showLoader, assetInLandmarkfilter },
});

export const fetchAssetInLandmark = (id: string, assetInLandmarkfilter: Object =
{ showLoader: true, recordsPerPage: 50, currentPage: 0 }): LandmarkTypeAction => ({
    type: FETCH_ASSET_IN_LANDMARK,
    payload: { id, showLoader: assetInLandmarkfilter.showLoader, assetInLandmarkfilter },
});

export const fetchLandmarksSuccess = (response: { id: string, assetInLandmarkfilter: Object }):
LandmarkTypeAction => {
    if (response.assetInLandmarkfilter && response.assetInLandmarkfilter.fatchAsset) {
        store.dispatch(fetchAssetInLandmark(response.id, response.assetInLandmarkfilter));
    }
    return {
        type: FETCH_LANDMARKS_SUCCESS,
        payload: { response },
    };
};

export const fetchLandmarksError = (error: string): LandmarkTypeAction => ({
    type: FETCH_LANDMARKS_ERROR,
    payload: { error },
});

export const fetchAssetInLandmarkSuccess = (response: {}): LandmarkTypeAction => ({
    type: FETCH_ASSET_IN_LANDMARK_SUCCESS,
    payload: { response },
});

export const fetchAssetInLandmarkError = (error: string): LandmarkTypeAction => ({
    type: FETCH_ASSET_IN_LANDMARK_ERROR,
    payload: { error },
});

export const fetchNearestAsset = (lat: string, lng: string): LandmarkTypeAction => ({
    type: FETCH_NEAREST_ASSET,
    payload: { lat, lng },
});

export const fetchNearestAssetSuccess = (assetId: string): LandmarkTypeAction => ({
    type: FETCH_NEAREST_ASSET_SUCCESS,
    payload: { assetId },
});

export const fetchNearestAssetError = (error: string): LandmarkTypeAction => ({
    type: FETCH_NEAREST_ASSET_ERROR,
    payload: { error },
});
