/* @flow */

import {
    FETCH_ADDRESS,
    FETCH_ADDRESS_SUCCESS,
    FETCH_ADDRESS_ERROR,
    UPDATE_LOADER,
    FETCH_ADDRESS_WITH_LAT_LNG,
    FETCH_ADDRESS_WITH_LAT_LNG_SUCCESS,
    FETCH_ADDRESS_WITH_LAT_LNG_ERROR,
} from './constants.address';
import type { AddressTypeAction } from './constants.address';

export const fetchAddress = (placeId: string): AddressTypeAction => ({
    type: FETCH_ADDRESS,
    payload: { placeId },
});

export const updateLoader = (showLoader: boolean) => ({
    type: UPDATE_LOADER,
    payload: { showLoader },
});

export const fetchAddressSuccess = (response: any): AddressTypeAction => ({
    type: FETCH_ADDRESS_SUCCESS,
    payload: { response },
});

export const fetchAddressError = (error: string): AddressTypeAction => ({
    type: FETCH_ADDRESS_ERROR,
    payload: { error },
});

export const fetchAddressWithLatLng = (lat: number, lng:number): AddressTypeAction => ({
    type: FETCH_ADDRESS_WITH_LAT_LNG,
    payload: { lat, lng },
});

export const fetchAddressWithLatLngSuccess = (response: any): AddressTypeAction => ({
    type: FETCH_ADDRESS_WITH_LAT_LNG_SUCCESS,
    payload: { response },
});

export const fetchAddressWithLatLngError = (error: string): AddressTypeAction => ({
    type: FETCH_ADDRESS_WITH_LAT_LNG_ERROR,
    payload: { error },
});
