/* @flow */
import { OverlayView } from 'react-google-maps';
import React, { PureComponent } from 'react';
import { mapColor } from './../constants.map';
import GetSvgIcon from '../../util/svgImage_util';
import CustomOverlayView from './CustomOverlayView';

type Props = {
    lat: number,
    lng: number,
    imageType: string,
    iconColor: string,
}

const markerStyle = {
    background: {
        background: mapColor.icon,
        borderRadius: '100%',
        float: 'center',
        width: 40,
        margin: '-40px -20px',
        height: 40,
        cursor: 'pointer',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    },
    img: {
        transform: 'translate(-50%, -50%)',
        position: 'relative',
        left: 20,
        display: 'flex',
        height: 18,
        justifyContent: 'center',
        overFlow: 'hidden',
        overflowX: 'hidden',
        overflow: 'hidden',
        top: -29,
    },
};

const getStyle = (type) => {
    switch (type) {
    case 'square-blue':
    case 'square-purple':
    case 'square-white':
    case 'square-yellow': return {
        ...markerStyle.img,
        left: 19,
        height: 14,
    };
    case 'star-blue':
    case 'star-purple':
    case 'star-white':
    case 'star-yellow': return {
        ...markerStyle.img,
        left: 21,
        height: 20,
        top: -28,
    };
    case 'diamond-blue':
    case 'diamond-purple':
    case 'diamond-white':
    case 'diamond-yellow': return { ...markerStyle.img };
    case 'oil-tower': return {
        ...markerStyle.img,
        height: 18,
        top: -31,
    };
    case 'house':
    case 'office-3': return {
        ...markerStyle.img,
        height: 16,
    };
    case 'office-1': return {
        ...markerStyle.img,
        height: 14,
    };
    case 'office-2':
    case 'gas-station':
    case 'high-rise': return {
        ...markerStyle.img,
        left: 21,
        height: 16,
        top: -30,
    };
    case 'airport': return {
        ...markerStyle.img,
        height: 16,
    };
    default: return { ...markerStyle.img };
    }
};

class CustomMarker extends PureComponent<Props> {
    render() {
        const { lat, lng } = this.props;

        return (
            <CustomOverlayView
                position={{ lat, lng }}
                mapPaneName={(OverlayView) ? OverlayView.OVERLAY_MOUSE_TARGET : ''}
            >
                <div style={{ margin: '-39px 0px 0px -19px' }}>
                    <GetSvgIcon type="background_image" fillcolor={mapColor.icon} />
                    <GetSvgIcon
                        style={getStyle(this.props.imageType || 'location')}
                        fillcolor={this.props.iconColor || '#fff'}
                        type={this.props.imageType || 'location'}
                    />
                </div>
            </CustomOverlayView>
        );
    }
}

export default CustomMarker;
