/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_ASSETS_GROUP_LIST } from './constants.assetGroupList';
import { fetchAssetGroupListError, fetchAssetGroupListSuccess } from './actions.assetGroupList';
import { getUserToken, getAppToken } from '../../helper-classes/utility-functions';
import config from '../../constants/Config';

type ObservarblesTypes = {
    postJSON: Function,
    of: Function
}

export const getGroupAssetsList =
(actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) =>
    actions$
        .ofType(FETCH_ASSETS_GROUP_LIST)
        .distinctUntilChanged()
        .debounceTime(config.get('DEBOUNCE_TIME'))
        .mergeMap((action) => {
            const {
                order,
                orderBy,
                pageNumber,
                pageSize,
                filter = {},
            } = action.payload;
            const appToken = getAppToken();
            const headers = {
                'X-Nspire-AppToken': appToken,
                'X-Nspire-UserToken': getUserToken(),
                'Content-Type': 'application/json',
            };
            const data = {
                limit: pageSize,
                sortParam: order && orderBy ? [[orderBy, order]] : undefined,
                start: pageNumber * pageSize,
                type: 'parentChildList',
                searchParams: [''],
            };

            if (filter.search && filter.search.length > 0 && typeof filter.search === 'string') {
                data.type = 'preLimitSearch';
                data.searchParams = [filter.search.replace(/[\s]{2,}/gi, ' ').trim()];
            } else {
                delete data.searchParams;
            }

            return postJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/assetgroups`, data, headers)
                .map(result => fetchAssetGroupListSuccess(
                    result.response.data,
                    result.response.total,
                ))
                .catch(error => of(fetchAssetGroupListError(error)));
        });

export default combineEpics(getGroupAssetsList);
