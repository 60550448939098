/* @flow */
import { FETCH_ASSETS_LIST_ERROR, FETCH_ASSETS_LIST_SUCCESS, FETCH_ASSETS_LIST, RESET_ASSETS_LIST } from './constants.assetList';

const initialState = {
    assets: [],
    isUpdating: false,
};

type stateType = {
    assets: Array<Object>,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        assets:{},
        totalAssets: number
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_ASSETS_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case RESET_ASSETS_LIST:
        return {
            ...state,
            isUpdating: false,
            assets: [],
            totalAssets: 0,
        };
    case FETCH_ASSETS_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            assets: action.payload.assets,
            totalAssets: action.payload.totalAssets,
        };
    case FETCH_ASSETS_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    default:
        return state;
    }
};
