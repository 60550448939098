/* @flow */
import React, { Component } from 'react';
import classNames from 'classnames';
import Drawer from '@mui/material/Drawer';
import withStyles from '@mui/styles/withStyles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import customStyles from './RightDrawer.module.scss';

const defaultDrawerWidth = 388;
const styles = theme => ({
    card: {
        backgroundColor: '#999b9e',
    },
    minWidth: {
        width: 50,
    },
    drawerPaper: {
        background: 'transparent',
        border: 'none',
        height: 'calc(100vh - 22px)',
        paddingLeft: 5,
    },
    drawerNormal: { top: '46px' },
    drawerWithFilter: { top: '85px' },
    drawerPaperClose: {
        overflowX: 'hidden',
        height: 'auto',
        [theme.breakpoints.up('sm')]: {
        },
    },
    toggleButton: {
        marginLeft: '-5px',
        position: 'absolute',
        width: 20,
        zIndex: 99,
    },
    toggleButtonOpen: {
    },
    listHeight: {
        overflow: 'auto',
        height: '560px',
    },
    toggleButtonIcon: {
        backgroundColor: '#343e48',
        borderRadius: '4px 0 0 4px',
        cursor: 'pointer',
        height: 90,
        outline: 'none',
        width: '100%',
    },
    buttonSeperator: {
        background: 'rgba(128, 128, 128, 0.5)',
        border: 0,
        display: 'inline',
        height: 75,
        position: 'absolute',
        top: 0,
        width: 1,
    },
    toggleIcon: {
        left: '-2px',
        position: 'absolute',
        top: 32,
    },
    toggleRotate: {
        transform: 'rotate(180deg)',
    },
    drawerChild: {
        marginLeft: '15px',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
    },
    drawer: {
        flexShrink: 0,
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: 300,
        }),
        transform: 'none !Important',
        visibility: 'unset !Important',
        overflowX: 'hidden',
        width: `calc(${theme.spacing(3 / 2)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(3 / 2)} + 1px)`,
        },
    },
    drawerOpen: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: 300,
        }),
    },
});

export type Props = {
    classes: Object,
    children: any,
    showDrawer: boolean,
    drawerWidth: number,
    toggleDrawer: Function,
    isAddress: boolean,
    withTopFilter: boolean,
};

export type State = {
    isDrawerOpen: boolean,
};

class RightDrawer extends Component<Props, State> {
    totalHeight: number;
    static defaultProps = {
        toggleDrawer: () => {},
    }

    constructor(props) {
        super(props);
        this.totalHeight = 0;
        this.state = {
            isDrawerOpen: props.showDrawer || false,
        };
    }

    handleShowHideDrawer = (status: boolean = true) => this.setState({ isDrawerOpen: status });

    toggleDrawer = () => {
        this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
        this.props.toggleDrawer(!this.state.isDrawerOpen);
    }

    getDrawerClasses = classes => ({
        [classes.drawerOpen]: this.state.isDrawerOpen,
        [classes.drawerClose]: !this.state.isDrawerOpen,
    });

    render() {
        const { classes } = this.props;
        const { isDrawerOpen } = this.state;
        const width = this.props.drawerWidth ? this.props.drawerWidth : defaultDrawerWidth;
        const height = this.props.isAddress ? 'auto' : 'calc(100% + 2px)';
        const drawerFilterClass = this.props.withTopFilter
            ? classes.drawerWithFilter : classes.drawerNormal;

        return (
            <div className={customStyles.app}>
                <Drawer
                    className={classNames(classes.drawer, this.getDrawerClasses(classes))}
                    variant="permanent"
                    anchor="right"
                    classes={{
                        paper: classNames(
                            classes.drawerPaper, this.getDrawerClasses(classes),
                            drawerFilterClass,
                        ),
                    }}
                    open={isDrawerOpen}
                    style={{ width }}
                    data-qa={isDrawerOpen ? 'drawer-open' : 'drawer-closed'}
                >
                    <div className={
                        `${classes.toggleButton}
                        ${isDrawerOpen ? classes.toggleButtonOpen : ''}`
                    }
                    >
                        <button
                            className={classes.toggleButtonIcon}
                            onClick={this.toggleDrawer}
                        >
                            <ChevronRightIcon viewBox="0 0 21 21" fill="#fff" fillOpacity="0.5" className={`${classes.toggleIcon} ${(!isDrawerOpen) ? classes.toggleRotate : ''}`} />
                        </button>
                        <hr className={classes.buttonSeperator} />
                    </div>
                    <div
                        className={classes.drawerChild}
                        style={{
                            width: width - 15,
                            backgroundColor: '#ffffff',
                            height,
                            overflowX: 'hidden',
                        }}
                    >
                        {this.props.children}
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(RightDrawer);
