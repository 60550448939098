/* @flow */
import { LOGOUT, LOGOUT_SUCCESS } from '../Login/contants.login';
import {
    GLOBAL_SEARCH,
    GLOBAL_SEARCH_ERROR,
    GLOBAL_SEARCH_SUCCESS,
    USER_IDENTITY,
    USER_IDENTITY_SUCCESS,
    USER_IDENTITY_ERROR,
    GLOBAL_SEARCH_UPDATE_TAB,
    UPDATE_USER_IDENTITY_LOADER,
    UPDATE_GLOBAL_SEARCH,
    NEAREST_ASSET_LANDMARK_SUCCESS,
    NEAREST_ASSET_LANDMARK_ERROR,
    NEAREST_ASSET_LANDMARK,
} from './contants.globalsearch';


export const globalSearch = (query: string, page: number) => ({
    type: GLOBAL_SEARCH,
    payload: { query, page },
});

export const updateTab = (tab: number) => ({
    type: GLOBAL_SEARCH_UPDATE_TAB,
    payload: { tab },
});

export const updateSearchText = (query: string) => ({
    type: UPDATE_GLOBAL_SEARCH,
    payload: { query },
});


export const logout = (alert: Object = { message: '', needToShow: false }) => ({
    type: LOGOUT,
    payload: { alert },
    meta: {
        analytics: {
            type: LOGOUT_SUCCESS,
        },
    },
});

export const globalSearchError = (error: any, response: any) => ({
    type: GLOBAL_SEARCH_ERROR,
    payload: { error, response },
    meta: {
        analytics: {
            type: GLOBAL_SEARCH_ERROR,
        },
    },
});

export const globalSearchSuccess = (response: any) => ({
    type: GLOBAL_SEARCH_SUCCESS,
    payload: { response },
});

export const userIdentity = () => ({
    type: USER_IDENTITY,
});

export const fetchNearestAssetLandmark = (searchType: string, placeId: string) => ({
    type: NEAREST_ASSET_LANDMARK,
    payload: { searchType, placeId },
});

export const fetchNearestAssetLandmarkSuccess = (assetOrLandmarkId: string) => ({
    type: NEAREST_ASSET_LANDMARK_SUCCESS,
    payload: { assetOrLandmarkId },
});

export const fetchNearestAssetLandmarkError = (error: string) => ({
    type: NEAREST_ASSET_LANDMARK_ERROR,
    payload: { error },
});

export const userIdentitySuccess = (response: any) => ({
    type: USER_IDENTITY_SUCCESS,
    payload: { response },
});

export const userIdentityError = (error: any) => ({
    type: USER_IDENTITY_ERROR,
    payload: { error },
    meta: {
        analytics: {
            type: USER_IDENTITY_ERROR,
        },
    },
});

export const updateLoader = (showLoader: boolean) => ({
    type: UPDATE_USER_IDENTITY_LOADER,
    payload: { showLoader },
});

