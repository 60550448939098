/* @flow */
import moment from 'moment';
import csvDownload from 'json-to-csv-export';
import {
    setLocalStorageItem,
    getLocalStorageValue,
    removeLocalStorageItem,
    isEmpty,
    getTimeFormatAsperUserSettting,
    convertDateTime,
    getAppUserSettings,
} from '../helper-classes/utility-functions';
import { getDeviceMediaToken } from './../containers/DashCam/helper-classes/dashcamServices';
import { MEDIA_TOKEN_EXPIRY_IN_MINS, SPA_USER_TOKEN, SPA_ORG_ID } from './../containers/DashCam/constants.dashcam';
import {
    HARD_CORNERING_EVENT_TYPES,
    MONITORING_EVENT_TYPES,
    SAFETY_EVENT_TYPES,
} from './../components/DashCam/Events/constants';

const statusColor = {
    online: '#6dd400',
    standby: '#f7b500',
    offline: '#4a4a4a',
};
const liveViewColor = {
    noCameras: '#ACACAC',
    haveCameras: '#606060',
    selected: '#007AFE',
};
const mediaTokens = {};

export const setDashcamToken = (resp: Object) => {
    if (resp.userToken) setLocalStorageItem(SPA_USER_TOKEN, resp && (resp.userToken));
    if (resp.organizationId) setLocalStorageItem(SPA_ORG_ID, resp && (resp.organizationId));
};

export const isDashcamAuthenticated = () => ((getLocalStorageValue(SPA_USER_TOKEN) !== '') && (getLocalStorageValue(SPA_ORG_ID) !== ''));

export const getDashcamToken = () => getLocalStorageValue(SPA_USER_TOKEN);

export const getDashcamOrgId = () => getLocalStorageValue(SPA_ORG_ID);

export const getDashcamTokenObj = () => ({
    [SPA_USER_TOKEN]: getLocalStorageValue(SPA_USER_TOKEN),
    [SPA_ORG_ID]: getLocalStorageValue(SPA_ORG_ID),
});

export const clearDashcamLSObj = () => {
    removeLocalStorageItem(SPA_USER_TOKEN);
    removeLocalStorageItem(SPA_ORG_ID);
};

export const getDashcamColorFromStatus = (dashcam: Object) => {
    const status = (dashcam && dashcam.status) ? dashcam.status.toLowerCase() : '';
    let color = '';

    switch (status) {
    case 'online':
        color = statusColor.online;
        break;
    case 'standby':
        color = statusColor.standby;
        break;
    default:
        color = statusColor.offline;
        break;
    }

    return color;
};

export const isDashcamSelected = (dashcam: Object, selectedCameras: Array<Object> = []) => {
    if (dashcam.status && dashcam.status === 'online' && !isEmpty(selectedCameras) && selectedCameras.some(cam => (cam.imei === dashcam.imei))) {
        return true;
    }
    return false;
};

export const isDashcamCameraSelected = (
    dashcam: Object,
    selectedCameras: Array<Object>,
    selectedCameraId: number,
) => {
    if (!isEmpty(selectedCameras) && selectedCameras.some(cam => (cam.imei === dashcam.imei)
        && dashcam.cameras.some(c => (cam.cameraIds.includes(c.cameraId)
            && cam.cameraIds.includes(selectedCameraId))))) {
        return true;
    }
    return false;
};

export const getDashcamLiveViewColor = (dashcam: Object, isSelected: boolean = false) => {
    let status = (dashcam && (dashcam.status === 'online') && dashcam.cameras && dashcam.cameras.length > 0) ? 'haveCameras' : 'noCameras';
    if (isSelected) status = 'selected';
    let color = '';

    switch (status) {
    case 'haveCameras':
        color = liveViewColor.haveCameras;
        break;
    case 'selected':
        color = liveViewColor.selected;
        break;
    default:
        color = liveViewColor.noCameras;
        break;
    }
    return color;
};

export const getTimeInMinutes = (timeIn24Hrs: string) => {
    const timeAry = timeIn24Hrs.split(':');

    return (timeAry.length === 2)
        ? ((parseInt(timeAry[0], 10) * 60) + parseInt(timeAry[1], 10)) : 0;
};

export const getTimeToDisplayAsPerAccSettings =
(timeInMinutes: number, force24h: boolean = false) => {
    let timeToDisplay = '';
    let hours = Math.floor(timeInMinutes / 60);
    const minutes = (`0${(timeInMinutes % 60)}`).slice(-2);

    if (!force24h && getTimeFormatAsperUserSettting() === '12h') {
        let AMPM = 'AM';

        if (hours >= 12) {
            hours -= 12;
            AMPM = 'PM';
        }

        if (hours === 0) hours = 12;
        timeToDisplay = `${(`0${hours}`).slice(-2)}:${minutes} ${AMPM}`;
    } else timeToDisplay = `${(`0${hours}`).slice(-2)}:${minutes}`;

    return timeToDisplay;
};

export const getMediaSourceUrl = (type: string, params: Object = {}) => {
    let sourceUrl = '';

    switch (type) {
    case 'webrtc':
        sourceUrl = `https://${params.mediaAddress}/webrtc/#PEERID#/${params.imei}/${params.cameraId}/${params.mediaToken}`;
        break;
    case 'hls':
        sourceUrl = `https://${params.mediaAddress}/${params.imei}/${params.cameraId}/${params.mediaToken}/playlist.m3u8`;
        break;
    case 'recording':
        sourceUrl = `https://${params.mediaAddress}/rec/${params.imei}/${params.cameraId}/${params.mediaToken}/${params.startTime}/${params.videoDuration}/${params.qualityLevel}/rec.mp4`;
        break;
    case 'recordingDownload':
        sourceUrl = `https://${params.mediaAddress}/download/${params.imei}/${params.mediaToken}/${params.imei}_${params.cameraId}_${params.startTime}_${params.videoDuration}_${params.qualityLevel}.mp4`;
        break;
    default:
        break;
    }

    return sourceUrl;
};

const checkExpiry = (tokenData: Object, imei: string) => {
    if (tokenData && tokenData.expiry && moment().isAfter(tokenData.expiry)) {
        mediaTokens[imei] = {};
        return true;
    }
    return false;
};

export const getDeviceMediaTokenData = (imei: string, callback: Function) => {
    let tokenData = mediaTokens[imei];

    if (tokenData && tokenData.expiry && !checkExpiry(tokenData, imei)) {
        return callback(null, tokenData.token);
    }

    return getDeviceMediaToken({ imei }).then((response: Object) => {
        if (response.status === 200 && response && response.data) {
            tokenData = {};
            tokenData.token = {
                mediaAddress: response.data.address,
                mediaToken: response.data.mediaToken,
            };
            tokenData.expiry = moment().add(MEDIA_TOKEN_EXPIRY_IN_MINS, 'minute');
            mediaTokens[imei] = tokenData;
            callback(null, tokenData.token);
        } else callback(response.message, null);
    });
};

export const formatBytes = (bytes: number, decimals: number = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};

export const checkRecordingAvailibity =
(
    intervals: Array<Object>,
    selectedTimeStamp: any,
    secondsBefore: number = 0,
    secondsAfter: number = 0,
) => {
    let isRecordingAvailable = false;
    if (!secondsBefore && !secondsAfter) return isRecordingAvailable;

    if (intervals) {
        intervals.some((i) => {
            const recordingStartDate = convertDateTime(i.start, 'YYYY-MM-DD', true);
            const recordingEndDate = convertDateTime(i.end, 'YYYY-MM-DD', true);

            if (recordingStartDate === recordingEndDate) {
                const startDateStr = convertDateTime(i.start, 'YYYY-MM-DD HH:mm', true);
                const startReminder = convertDateTime(i.start, 'ss');
                let startTimeStamp = moment.tz(startDateStr, getAppUserSettings('userTz'));
                startTimeStamp = startTimeStamp.unix() + (parseInt(startReminder, 10) || 0);

                const endDateStr = convertDateTime(i.end, 'YYYY-MM-DD HH:mm', true);
                const endReminder = convertDateTime(i.end, 'ss');
                let endTimeStamp = moment.tz(endDateStr, getAppUserSettings('userTz'));
                endTimeStamp = endTimeStamp.unix() + (parseInt(endReminder, 10) || 0);

                if (((selectedTimeStamp - secondsBefore) >= startTimeStamp)
                && ((selectedTimeStamp + secondsAfter) <= endTimeStamp)) {
                    isRecordingAvailable = true;
                    return true;
                }
            }

            return false;
        });
    }

    return isRecordingAvailable;
};

export const getCurrentBrowser = () => (window.AdapterJS ? window.AdapterJS.webrtcDetectedBrowser : '').toLowerCase();

export default getDashcamTokenObj;

export const eventsSummaryCount = (events: any) => {
    const eventSummaryCount = {
        total: 0,
        safety: {},
        monitoring: {},
    };
    let safetyTotal = 0;
    let monitoringTotal = 0;

    SAFETY_EVENT_TYPES.forEach((eventType) => {
        if (!HARD_CORNERING_EVENT_TYPES.includes(eventType)) {
            const filteredEvents = events.filter(event => event.eventType === eventType);
            eventSummaryCount.safety[eventType] = { eventType, count: filteredEvents.length };
            safetyTotal += filteredEvents.length;
        }
    });

    // combine sharp left and sharp right turn event into one hard cornering event type
    const hardCorneringEvents = events.filter(event =>
        HARD_CORNERING_EVENT_TYPES.includes(event.eventType));
    eventSummaryCount.safety[HARD_CORNERING_EVENT_TYPES[0]] = {
        eventType: HARD_CORNERING_EVENT_TYPES[0],
        count: hardCorneringEvents.length,
    };
    safetyTotal += hardCorneringEvents.length;
    eventSummaryCount.safety.total = safetyTotal;

    MONITORING_EVENT_TYPES.forEach((eventType) => {
        const filteredEvents = events.filter(event => event.eventType === eventType);
        eventSummaryCount.monitoring[eventType] = { eventType, count: filteredEvents.length };
        monitoringTotal += filteredEvents.length;
    });
    eventSummaryCount.monitoring.total = monitoringTotal;
    eventSummaryCount.total = safetyTotal + monitoringTotal;
    return eventSummaryCount;
};

export const buildEventObject = (events: any, startDate: Date) => {
    const ISOStartDate = `${startDate}.000Z`
    const historyEventCount =
    eventsSummaryCount(events.filter(event =>
        moment(ISOStartDate).isSameOrAfter(moment(event.time))));
    const currentEvent =
    events.filter(event => moment(ISOStartDate).isSameOrBefore(moment(event.time)));
    const currentEventCount = eventsSummaryCount(currentEvent);
    return { historyEventCount, currentEventCount, currentEvent };
};

export const videoPlayPromise = (videoPlayer: any) => {
    const isPlaying = videoPlayer.currentTime > 0 && !videoPlayer.paused && !videoPlayer.ended
        && videoPlayer.readyState > videoPlayer.HAVE_CURRENT_DATA;

    if (isPlaying) {
        videoPlayer.pause();
    }
};

export const getCsvData = (data: any, fields: any, filename: string) => csvDownload({
    data, filename, delimiter: ',', headers: fields,
});
