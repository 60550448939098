/* @flow */
import React, { Component } from 'react';
import withRouter from 'react-router-dom/withRouter';
import { Card, CardContent, Typography, InputAdornment } from '@mui/material';
import customStyles from './MapAssetList.module.scss';
import { isEmpty, isPairedAsset } from '../../helper-classes/utility-functions';
import List from '../SharedComponents/List/List';
import analytics from '../../analytics/index';
import AppLoader from './../../components/AppLoader';
import { getAssets } from '../../containers/Maintenance/helper-classes/common-services';
import SearchBox from '../SharedComponents/SearchBox';
import AssetCard from './../Assets/List/AssetCard';
import { ZOOM_LEVEL_FOUR } from '../../constants/Config';

export type Props = {
    appliedFiltersObj: Object,
    history: {
        push: Function,
    },
    showHideMapAssetListRefreshChip: Function,
    disableFilters: Function,
};

export type State = {
    assetListData: Object,
    isAssetListUpdating: boolean,
    isSearchOpen: boolean,
    searchKeyword: string,
    searchCalled: boolean,
    isRefresh: boolean,
    isApiCalled: boolean,
};

const MIN_SEARCH_LENGTH = 3;
const delay = 400;

class MapAssetList extends Component<Props, State> {
    currentlyLoadedPage: number;
    assetListPerPageItems: number;
    resetPage: boolean;
    timer: TimeoutID;
    prevent: boolean;
    isClickToZoom: boolean;

    constructor(props) {
        super(props);
        const isSearch = false;
        this.state = {
            assetListData: {
                data: [], count: 0, total: 0, currentPage: 0, totalPage: 0,
            },
            isAssetListUpdating: true,
            isSearchOpen: isSearch,
            searchKeyword: '',
            searchCalled: false,
            isRefresh: false,
            isApiCalled: false,
        };

        this.currentlyLoadedPage = 0;
        this.assetListPerPageItems = 50;
        this.resetPage = false;
        this.prevent = false;
        this.isClickToZoom = false;
    }

    UNSAFE_componentWillMount() {
        // call map asset list API
        this.getAssetsForList(this.currentlyLoadedPage, this.props.appliedFiltersObj);

        // handling map auto refresh
        this.props.showHideMapAssetListRefreshChip(this.refreshAssetListData);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // handle map filter change here, also ignore/skip hide asset/landmark filters
        const { appliedFiltersObj } = nextProps;
        const nextPropsAppliedFiltersObj = { ...appliedFiltersObj };
        delete nextPropsAppliedFiltersObj.hide_assets;
        delete nextPropsAppliedFiltersObj.hide_landmarks;

        const propsAppliedFiltersObj = { ...this.props.appliedFiltersObj };
        delete propsAppliedFiltersObj.hide_assets;
        delete propsAppliedFiltersObj.hide_landmarks;

        if (!this.isClickToZoom && !isEmpty(nextPropsAppliedFiltersObj) &&
            JSON.stringify(nextPropsAppliedFiltersObj) !== JSON.stringify(propsAppliedFiltersObj)) {
            this.resetListData(nextPropsAppliedFiltersObj);
        }

        if (propsAppliedFiltersObj.selectedAsset !== appliedFiltersObj.selectedAsset) {
            this.isClickToZoom = false;
        }
    }

    resetListData = (appliedFilters: Object) => {
        this.resetPage = true;
        this.setState(
            {
                isAssetListUpdating: true,
                assetListData: {
                    data: [], count: 0, total: 0, currentPage: 0, totalPage: 0,
                },
            },
            () => this.getAssetsForList(this.currentlyLoadedPage, appliedFilters, true),
        );
    }

    loadNextPageAssets = (page) => {
        // block repeatation of asset API calls
        if (!this.resetPage && (this.currentlyLoadedPage !== page)
            && (this.currentlyLoadedPage < page)) {
            this.currentlyLoadedPage = page;
            this.setState(
                { isAssetListUpdating: true },
                () => this.getAssetsForList(page, this.props.appliedFiltersObj),
            );
        } else if (this.resetPage) this.resetPage = false;
    }

    disableFilters = (disable) => {
        if (this.props.disableFilters && typeof this.props.disableFilters === 'function') {
            this.props.disableFilters(disable);
        }
    }

    refreshAssetListData = () => {
        this.setState({ isRefresh: true }, () => {
            if (this.state.isApiCalled) return null;
            this.getAssetsForList(this.currentlyLoadedPage, this.props.appliedFiltersObj, false);
            this.disableFilters(true);
            return true;
        });
    }

    updateApiLoader = (isApiCalled) => {
        this.setState({ isApiCalled });
    }

    getAssetsForList = (offset, appliedFiltersObj: Object = {}, resetPage: boolean = false) => {
        const currentPage = resetPage ? 0 : offset;
        const currentAssetListData = resetPage ?
            {
                data: [], count: 0, total: 0, currentPage: 0, totalPage: 0,
            }
            : this.state.assetListData;
        if (resetPage) this.currentlyLoadedPage = 0;

        // check applied search filters
        const filtersToSend = [];
        if (Object.keys(appliedFiltersObj).length > 0) {
            if (appliedFiltersObj.asset_status) {
                filtersToSend.push(['status', `eq:${appliedFiltersObj.asset_status}`]);
            }
            if (appliedFiltersObj.asset_landmark) {
                filtersToSend.push(['landmarkName', `exists:${(appliedFiltersObj.asset_landmark === 'true' ? 'yes' : 'no')}`]);
            }
        }

        filtersToSend.push(['deviceId', 'exists:yes']);
        filtersToSend.push(['deviceStatus', 'eq:true']);

        // call asset API to fetch details based on params
        let paginationFrom = currentPage * this.assetListPerPageItems;
        let paginationTo = this.assetListPerPageItems;
        const { isRefresh } = this.state;
        if (isRefresh) {
            paginationFrom = 0;
            paginationTo = (currentPage + 1) * this.assetListPerPageItems;
        }

        this.updateApiLoader(true);

        getAssets(
            paginationFrom,
            paginationTo, this.state.searchKeyword,
            [], filtersToSend, undefined, true, 'mapAssetSearch',
        ).then(responseWithQuery => (responseWithQuery.response.status === 200)
            && responseWithQuery.response.json()).then((response) => {
            this.disableFilters(false);
            this.updateApiLoader(false);
            if (response && response.data) {
                let { data } = currentAssetListData;
                if (isRefresh) {
                    if (paginationFrom >= 0) {
                        data.splice(paginationFrom, paginationTo, ...response.data);
                    }
                } else data = data.concat(response.data);

                this.setState({
                    assetListData: {
                        data,
                        count: isRefresh ?
                            currentAssetListData.data.length :
                            currentAssetListData.count + response.count,
                        total: response.total,
                        currentPage,
                        totalPage: Math.ceil(response.total / this.assetListPerPageItems),
                    },
                    isAssetListUpdating: false,
                    isRefresh: false,
                });
            } else this.setState({ isAssetListUpdating: false, isRefresh: false });
        }).catch((error) => {
            if (error) {
                this.disableFilters(false);
                this.updateApiLoader(false);
                this.setState({ isRefresh: false });
            }
        });
    }

    handleAssetCardClick = (assetData: Object, isDoubleClick: boolean = false) => {
        const locationStateData = {};
        if (isDoubleClick) {
            const params = {
                feature: 'Map',
                assetName: assetData.name,
                navigatedFrom: 'Double Click',
            };
            analytics.track('NAVIGATE_ASSET_DETAIL', params);
            locationStateData.comingFromZoomedAsset = true;
        } else {
            const params = {
                feature: 'Map',
                assetName: assetData.name,
            };
            analytics.track('VIEW_ASSET', params);
            locationStateData.assetDataForZoom = {
                lat: (assetData.lastLocation && Object.keys(assetData.lastLocation).length > 0) ?
                    assetData.lastLocation.y : (assetData.longitude || 0),
                lng: (assetData.lastLocation && Object.keys(assetData.lastLocation).length > 0) ?
                    assetData.lastLocation.x : (assetData.latitude || 0),
                updateZoomLevel: true,
                reduceZoomLevelBy: ZOOM_LEVEL_FOUR,
            };
        }

        this.props.history.push({
            pathname: '/home',
            search: `?assetId=${assetData.id}&zoom=true`,
            state: { ...locationStateData },
        });
    }

    handleClusterRedirect = (e, redirectURL: string) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.history.push(redirectURL, { comingFromZoomedAsset: true });
        return false;
    }

    toggleAssetListSearch = (isSearchOpen = true) => {
        // this.props.toggleAssetListSearch(!isSearchOpen);
        this.setState({ isSearchOpen, searchCalled: false });
    }

    handleSearch = (e, reset: boolean = false) => {
        let searchKeyword = '';
        let searchModified = this.state.searchCalled;

        if (!reset) {
            searchKeyword = e.target.value;
            if (searchKeyword.length >= MIN_SEARCH_LENGTH) {
                searchModified = true;
                const params = {
                    feature: 'Map',
                    searchedValue: searchKeyword,
                };
                analytics.track('ASSET_SEARCH', params);
            }
        }

        this.setState({ searchKeyword, searchCalled: searchModified }, () => {
            if ((reset && searchModified) || (searchKeyword.length >= MIN_SEARCH_LENGTH)
                || (searchKeyword.length === 0 && searchModified)) {
                this.resetListData(this.props.appliedFiltersObj);
            }
            if (reset) this.toggleAssetListSearch(false);
        });
    }

    handleClick = (assetData: Object) => {
        const $this = this;
        $this.isClickToZoom = true;
        this.timer = setTimeout(() => {
            if (!this.prevent) $this.handleAssetCardClick(assetData);
            this.prevent = false;
        }, delay);
    }

    handleDoubleClick = (assetData: Object) => {
        if (isPairedAsset(assetData)) {
            clearTimeout(this.timer);
            this.prevent = true;
            this.handleAssetCardClick(assetData, true);
        }
    }

    getAssetCardContent = (asset: Object) => (
        <AssetCard
            asset={asset}
            checkPaired={false}
            appliedFiltersObj={this.props.appliedFiltersObj}
            handleClick={this.handleClick}
            handleDoubleClick={this.handleDoubleClick}
            key={asset.id}
            redirectTo={this.handleClusterRedirect}
            showSpeed
            showDistance={false}
        />
    )

    getLoader = () => (<AppLoader
        loaderStyle={{
            'padding-top': '10px',
            'padding-bottom': '10px',
            'text-align': 'center',
            display: 'block',
        }}
    />)

    render() {
        const { assetListData: assets, isAssetListUpdating } = this.state;
        const { isSearchOpen, isRefresh } = this.state;
        const subHeader = `Showing ${assets.count} of ${assets.total}`;

        return (
            <React.Fragment>
                {assets ?
                    <div className={customStyles.container}>
                        <Card raised={false} className={customStyles.asset_list_header_card}>
                            <CardContent className={customStyles.card_content}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 'auto' }}>
                                        {!isSearchOpen &&
                                            <React.Fragment>
                                                <Typography variant="h5" className={customStyles.asset_list_header_title} component="h2">
                                                    <span>All Assets</span>
                                                </Typography>
                                                <button
                                                    onClick={() =>
                                                        this.toggleAssetListSearch(true)}
                                                    id="search-btn"
                                                    className={customStyles.search_icon_white}
                                                />
                                            </React.Fragment>
                                        }
                                        {isSearchOpen &&
                                            <div className={customStyles.search_box_container}>
                                                <div className={customStyles.search_box}>
                                                    <SearchBox
                                                        sx={{ '& input': { letterSpacing: 0, padding: '6px 0px 7px' } }}
                                                        autoFocus
                                                        placeholder="Search for assets"
                                                        title="Please enter at least 3 characters"
                                                        className={customStyles.asset_search_input}
                                                        onChange={e => this.handleSearch(e)}
                                                        fullWidth
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <i className={customStyles
                                                                        .search_icon}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <i className={customStyles.cancel_search} onClick={() => this.handleSearch({}, true)} onKeyDown={() => {}} role="button" tabIndex="-1" />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        fieldtype="TextField"
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <Typography className={customStyles.asset_list_subheader_title} paragraph component="p">
                                            {subHeader}
                                        </Typography>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        {isAssetListUpdating && assets.count <= 0 ?
                            this.getLoader()
                            :
                            <div className={customStyles.available_assets}>
                                <Card
                                    raised={false}
                                    className={customStyles.available_assets_header}
                                >
                                    <CardContent className={customStyles.card_content}>
                                        <span>Available Assets ({assets.total})</span>
                                    </CardContent>
                                </Card>
                                <div className={customStyles.available_assets_list}>
                                    {isRefresh && <AppLoader type="fullScreen" fullScreenStyle={{ position: 'absolute', height: '100%' }} />}
                                    <List
                                        data={assets.data}
                                        height={81}
                                        currentPage={assets.currentPage}
                                        totalNumberofPage={assets.totalPage}
                                        pagination={{
                                            isPagination: true,
                                            pageChanged: this.loadNextPageAssets,
                                        }}
                                        getListItem={this.getAssetCardContent}
                                        showMessage
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div> {isAssetListUpdating && this.getLoader()}
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default withRouter(MapAssetList);
