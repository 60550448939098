/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import {
    Paper,
    Typography,
    Toolbar,
    IconButton,
    TextField,
    FormControl,
    FormGroup,
    Divider,
    Grid,
    Button,
    Select,
    MenuItem,
} from '@mui/material';
import { CompactPicker } from 'react-color';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { reduxConnect } from '../../hoc';
import { getImage } from '../../util/trip_util';
import fetchGeoCodeByAddress from '../../util/map_utils';
import MapsWrapper from './../../containers/MapsWrapper';
import back from '../../assets/icons/back.png';
import PhoneNumber from './../SharedComponents/PhoneNumber/PhoneNumber';
import AppLoader from '../AppLoader';
import { coordinate } from './../../containers/constants.map';
import dropdownNested from '../../assets/icons/dropdown-nested.svg';
import { getPermissionValue, setSessionStorageItem, removeSessionStorageItem } from '../../helper-classes/utility-functions';
import analytics from '../../analytics/index';
import {
    MAX_LENGTH_PHONE_NUMBER,
    LANDMARK_DEFAULT_LINECOLOR,
    LANDMARK_DEFAULT_FILLCOLOR,
    LANDMARK_DEFAULT_RADIUS,
    LANDMARK_DEFAULT_LINE_WIDTH,
    LANDMARK_DEFAULT_TRANSPARENCY,
    LANDMARK_DEFAULT_ICONVALUE,
    LANDMARK_SUPPORTED_ASCII_CHARACTERS,
} from './../../containers/Landmark/AddLandmark/constants.landmark';
import { MAP_ZOOM_AND_CENTER_SESSION_NAME, LM_MAP_ZOOM_AND_CENTER_MODIFIED } from '../../containers/Home/constants.home';
import * as actions from './../../containers/Address/actions.address';

const data = [
    { key: 'Airport', value: 'airport', imagePath: 'airport' },
    { key: 'Gas Station', value: 'gas-station', imagePath: 'gas-station' },
    { key: 'High Rise', value: 'high-rise', imagePath: 'high-rise' },
    { key: 'House', value: 'house', imagePath: 'house' },
    { key: 'Office1', value: 'office-1', imagePath: 'office-1' },
    { key: 'Office2', value: 'office-2', imagePath: 'office-2' },
    { key: 'Office3', value: 'office-3', imagePath: 'office-3' },
    { key: 'Oil Tower', value: 'oil-tower', imagePath: 'oil-tower' },
    { key: 'Diamond Blue', value: 'diamond-blue', imagePath: 'diamond-blue' },
    { key: 'Diamond Purple', value: 'diamond-purple', imagePath: 'diamond-purple' },
    { key: 'Diamond White', value: 'diamond-white', imagePath: 'diamond-white' },
    { key: 'Diamond Yellow', value: 'diamond-yellow', imagePath: 'diamond-yellow' },
    { key: 'Square Blue', value: 'square-blue', imagePath: 'square-blue' },
    { key: 'Square Purple', value: 'square-purple', imagePath: 'square-purple' },
    { key: 'Square White', value: 'square-white', imagePath: 'square-white' },
    { key: 'Square Yellow', value: 'square-yellow', imagePath: 'square-yellow' },
    { key: 'Star Blue', value: 'star-blue', imagePath: 'star-blue' },
    { key: 'Star Purple', value: 'star-purple', imagePath: 'star-purple' },
    { key: 'Star White', value: 'star-white', imagePath: 'star-white' },
    { key: 'Star Yellow', value: 'star-yellow', imagePath: 'star-yellow' },
];

const customStyles = () => ({
    root: {
        padding: 16,
        display: 'flex',
    },
    toolbar: {
        backgroundColor: '#343e48',
        color: '#ffffff',
    },
    formControl: {
        padding: '0 16px 16px',
        width: '90%',
    },
    card: {
        backgroundColor: '#999b9e',
    },
    app: {
        paddingTop: 16,
        paddingLeft: 17,
        paddingRight: 23,
    },
    button: {
        margin: '0 10px',
    },
    clearButton: {
        color: '#007aff',
        margin: '0 10px',
    },
    saveButton: {
        '&:hover': {
            backgroundColor: '#007aff',
        },
        backgroundColor: '#007aff',
        color: '#ffffff',
    },
    backButton: {
        verticalAlign: 'middle',
        paddingRight: 10,
        marginLeft: -12,
    },
    requiredField: {
        fontSize: 14,
        opacity: 0.87,
        whiteSpace: 'nowrap',
    },
    titleWrap: {
        lineHeight: 2,
    },
    divider: {
        borderRadius: 2,
    },
    sideBySide: {
        display: 'flex',
        overflow: 'hidden',
        marginBottom: 8,
    },
    textField: {
        width: '100%',
        marginTop: 0,
        whiteSpace: 'nowrap',
    },
    properties: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        marginTop: -4,
    },
    propertyTextField: {
        flex: 'auto',
        margin: '0 16px 0 0',
        maxWidth: 170,
        minWidth: 126,
    },
    dropdownContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '12px 0',
        justifyContent: 'space-between',
    },
    dropdown: {
        flex: 'auto',
        minWidth: 152,
        margin: '0 20px 0 0',
        whiteSpace: 'nowrap',
    },
    mapContainer: {
        border: '1px solid rgba(0,0,0, .5)',
        margin: '20px 16px 20px 0',
    },
    colorPicker: {
        height: 'auto',
        paddingBottom: '10px !important',
    },
    colorPickerLabel: {
        borderRadius: 4,
        fontStyle: 'normal',
        padding: '0 8px',
        margin: '0 4px',
    },
    colorPickerLableSpan: {
        color: 'inherit',
        filter: 'invert(1)',
        fontStyle: 'normal',
        background: 'transparent',
        mixBlendMode: 'difference',
    },
    landmarkIcon: {
        height: 21,
        padding: '0 10px 0 6px',
    },
    landmarkIconSelectValidator: {
        width: '31%',
    },
    stateTextValidator: {
        width: '100%',
    },
    zipTextValidator: {
        width: '40%',
    },
    whiteSpace: {
        whiteSpace: 'normal',
    },
});

export type Props = {
    classes: Object,
    createLandmark: Function,
    fetchLandmarkGroupsOptions: Function,
    landmarkGroup: Object,
    isUpdating: boolean,
    landmark: Object,
    isEdit: boolean,
    updateLandmark: Function,
    deleteLandmark: Function,
    geoAddress: {
        address : {
            home: string,
            postal_code: string,
            street: string,
            region: string,
            city: string,
            country: string,
        },
        latitude: number,
        longitude: number,
    },
    isGeoAddress: boolean,
    fetchAddressWithLatLng: Function,
};

export type State = {
    accountId: number,
    accountLabel: string,
    shapeValue: string,
    groupValue: string,
    groupName: string,
    iconValue: string,
    lineColor: string,
    fillColor: string,
    iconColor: string,
    radius: number,
    lineWidth: number,
    transparency: number,
    bounds: Object,
    name: string,
    comments: string,
    phoneNumber: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    lat: number,
    lng: number,
    id: string,
    groupData: Array<Object>,
    version: number,
    dateCreated: string,
    isValidAddress: boolean,
    // DO NOT DELETE -> for future use
    // isColorDisable: boolean,
    points: Array<any>,
    mixpanelOriginalState: any,
    unsupportedCharacters: string,
};

class AddLandmark extends Component<Props, State> {
    count: number;
    getCircleValue: any;
    getPolygonValue: any;
    getRectangleValue: any;
    isShapeMoved: boolean;
    mapCenterSnapedData: Object;

    constructor(props) {
        super(props);
        this.state = this.getInitState();
        this.count = 0;
        this.getCircleValue = null;
        this.getPolygonValue = null;
        this.getRectangleValue = null;
        this.handleMapCentering(true);
        this.isShapeMoved = false;
    }

    UNSAFE_componentWillMount() {
        ValidatorForm.addValidationRule('isEmpty', (value) => {
            if (typeof value === 'string' && value.replace(/\s/gi, '') === '') {
                return false;
            } else if (typeof value === 'number' && value === 0) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('isState', (value) => {
            if (typeof value === 'string' && value.length > 0) {
                return value.match(/^[A-Z]{2}$/g);
            }
            return true;
        });

        ValidatorForm.addValidationRule('isPhone', (value) => {
            if (typeof value === 'string' && value.length > 0) {
                // eslint-disable-next-line
                return value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g);
            }
            return true;
        });

        ValidatorForm.addValidationRule('isAddress', (value) => {
            if (typeof value === 'string' && value.length > 0) {
                // This regex supports ASCII character 32 - 255.
                // Any other character set will be invalidated.
                const unsupportedCharactersList = value
                    .match(LANDMARK_SUPPORTED_ASCII_CHARACTERS);
                if (unsupportedCharactersList !== null) {
                    this.setState({
                        unsupportedCharacters:
                            this.removeDuplicateCharacter(unsupportedCharactersList),
                    });
                }
                return !unsupportedCharactersList;
            }
            return true;
        });
    }
    componentDidMount() {
        this.props.fetchLandmarkGroupsOptions();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isEdit && nextProps.landmark && typeof nextProps.landmark === 'object' && Object.keys(nextProps.landmark).length > 0) {
            this.updateLandMarkData(nextProps.landmark);
        }

        if (nextProps.landmarkGroup.data && nextProps.landmarkGroup.data.length > 0) {
            this.setState({ groupData: nextProps.landmarkGroup.data });
        }
        if (this.isShapeMoved && nextProps.geoAddress && nextProps.geoAddress.address) {
            const address = nextProps.geoAddress.address || '';
            if (address) {
                const landmarkAddress = `${address.home || ''} ${address.street || ''}`;
                this.setState({
                    address: landmarkAddress.trim(),
                    city: address.city || '',
                    state: address.region || '',
                    zip: address.postal_code || '',
                });
            }
        }
    }

    getInitState = () => {
        const address = (this.props.isGeoAddress === 'true' && this.props.geoAddress && this.props.geoAddress.address) ? this.props.geoAddress.address : '';
        const landmarkAddress = `${(address && address.home) ? address.home : ''} ${(address && address.street) ? address.street : ''}`;
        return {
            accountId: 0,
            accountLabel: '',
            groupValue: '',
            groupName: '',
            shapeValue: 'editableCircle',
            lineColor: LANDMARK_DEFAULT_LINECOLOR,
            fillColor: LANDMARK_DEFAULT_FILLCOLOR,
            iconColor: '',
            radius: LANDMARK_DEFAULT_RADIUS,
            lineWidth: LANDMARK_DEFAULT_LINE_WIDTH,
            name: '',
            comments: '',
            phoneNumber: '',
            address: landmarkAddress.trim(),
            city: address && address.city ? address.city : '',
            state: address && address.region ? address.region : '',
            zip: address && address.postal_code ? address.postal_code : '',
            dateCreated: '',
            iconValue: LANDMARK_DEFAULT_ICONVALUE,
            id: '',
            lat: this.props.isGeoAddress === 'true' && this.props.geoAddress ?
                this.props.geoAddress.latitude : coordinate.lat,
            lng: this.props.isGeoAddress === 'true' && this.props.geoAddress ?
                this.props.geoAddress.longitude : coordinate.lng,
            transparency: LANDMARK_DEFAULT_TRANSPARENCY,
            version: 0,
            groupData: [],
            // DO NOT DELETE -> for future use
            // isColorDisable: false,
            isValidAddress: true,
            bounds: {
                north: coordinate.lat + 0.0040,
                south: coordinate.lat - 0.0040,
                east: coordinate.lng + 0.0059,
                west: coordinate.lng - 0.0059,
            },
            points: [
                [coordinate.lat - 0.0040, coordinate.lng - 0.0059],
                [coordinate.lat - 0.0040, coordinate.lng + 0.0059],
                [coordinate.lat + 0.0040, coordinate.lng + 0.0059],
                [coordinate.lat + 0.0040, coordinate.lng - 0.0059],
            ],
            mixpanelOriginalState: [],
            unsupportedCharacters: '',
        };
    }

    getShapeValue = (type) => {
        switch (type) {
        case 'circle': return 'editableCircle';
        case 'rectangle': return 'editableRectangle';
        case 'polygon': return 'editablePolygon';
        case 'editableCircle': return 'circle';
        case 'editableRectangle': return 'rectangle';
        case 'editablePolygon': return 'polygon';
        default: return '';
        }
    }

    updateShapeBounds = (landmarkData) => {
        if (landmarkData.maxLat ||
            landmarkData.maxLng ||
            landmarkData.minLat ||
            landmarkData.minLng) {
            const north = landmarkData.maxLat;
            const south = landmarkData.minLat;
            const east = landmarkData.maxLng;
            const west = landmarkData.minLng;
            this.setState({
                bounds: {
                    north, south, east, west,
                },
            });
        }
    }

    updateShapePoints = (landmarkData) => {
        if (landmarkData.polygonPoints && landmarkData.polygonPoints.length > 1) {
            this.setState({ points: landmarkData.polygonPoints });
        } else if (landmarkData.maxLat ||
            landmarkData.maxLng ||
            landmarkData.minLat ||
            landmarkData.minLng) {
            this.setState({
                points: [[landmarkData.minLat, landmarkData.minLng],
                    [landmarkData.minLat, landmarkData.maxLng],
                    [landmarkData.maxLat, landmarkData.maxLng],
                    [landmarkData.maxLat, landmarkData.minLng],
                ],
            });
        }
    }

    updateLandMarkData = (landmark) => {
        if (landmark.data && Array.isArray(landmark.data) && landmark.data.length > 0) {
            let {
                shapeValue, lineColor, fillColor, iconColor, accountLabel, radius, accountId,
                lineWidth, name, comments, phoneNumber, address, city, state, zip, iconValue,
                transparency, lat, lng, groupValue, groupName, dateCreated, version, id,
            } = this.getInitState();
            const landmarkData = landmark.data.pop();
            const icon = (landmarkData.attributesJSON) ?
                JSON.parse(landmarkData.attributesJSON.replace(/[.]*/g, '')).pop() : '';
            accountId = landmarkData.accountId || accountId;
            accountLabel = landmarkData.accountLabel || accountLabel;
            shapeValue = this.getShapeValue(landmarkData.type);
            lineColor = (landmarkData.lineColor) ? `#${landmarkData.lineColor}` : lineColor;
            fillColor = (landmarkData.fillColor) ? `#${landmarkData.fillColor}` : fillColor;
            iconColor = (landmarkData.iconColor) ? `#${landmarkData.iconColor}` : iconColor;
            radius = landmarkData.radius || radius;
            groupValue = landmarkData.groupId || groupValue;
            groupName = landmarkData.groupName || groupName;
            lineWidth = (landmarkData.lineWidth || landmarkData.lineWidth === 0) ?
                landmarkData.lineWidth : lineWidth;
            name = landmarkData.name || name;
            comments = landmarkData.description || comments;
            phoneNumber = landmarkData.phoneNumber || phoneNumber;
            address = landmarkData.address || '';
            city = landmarkData.city || city;
            state = landmarkData.state || state;
            zip = landmarkData.zip || zip;
            version = landmarkData.version || 0;
            dateCreated = landmarkData.dateCreated || dateCreated;
            iconValue = (icon && icon.value) ? icon.value.split('/').pop() : iconValue;
            transparency = (landmarkData.fillDensity || landmarkData.fillDensity === 0) ?
                (100 - (landmarkData.fillDensity * 100)) : transparency;
            lat = landmarkData.lat || lat;
            lng = landmarkData.lng || lng;
            id = landmarkData.id || id;

            this.mapCenterSnapedData = {
                address,
                city,
                state,
                zip,
                lat,
                lng,
            };
            this.setState({
                shapeValue,
                lineColor,
                fillColor,
                iconColor,
                accountLabel,
                radius,
                accountId,
                lineWidth,
                name,
                comments,
                phoneNumber,
                address,
                city,
                state,
                zip,
                iconValue,
                transparency,
                lat,
                lng,
                groupValue,
                groupName,
                dateCreated,
                version,
                id,
            });
            this.updateIconColor(iconValue);
            this.updateShapeBounds(landmarkData);
            this.updateShapePoints(landmarkData);
            const params = {
                shapeValue,
                lineWidth,
                name,
                comments,
                phoneNumber,
                address,
                city,
                state,
                zip,
                iconValue,
                transparency,
                radius,
                lat,
                lng,
                groupValue,
            };
            this.setState({ mixpanelOriginalState: params });
        }
    }

    updateIconColor = (value) => {
        let color = '';
        // DO NOT DELETE -> for future use
        // let isColorDisable = true;
        switch (value) {
        case 'diamond-blue':
        case 'square-blue':
        case 'star-blue':
            color = '#73D8FF';
            break;
        case 'diamond-purple':
        case 'square-purple':
        case 'star-purple':
            color = '#7B64FF';
            break;
        case 'diamond-white':
        case 'square-white':
        case 'star-white':
            color = '#FFFFFF';
            break;
        case 'diamond-yellow':
        case 'square-yellow':
        case 'star-yellow':
            color = '#FCDC00';
            break;
        default: color = '#FFFFFF';
        // DO NOT DELETE -> for future use
            // isColorDisable = false;
        }
        this.setState({
            iconColor: color,
            // DO NOT DELETE -> for future use
            // isColorDisable,
        });
        return '';
    }

    handleMapCentering = (initialLoad: boolean = false) => {
        if (initialLoad) this.mapCenterSnapedData = {};
        const mapCenterChanged = Object.keys(this.mapCenterSnapedData).some(key =>
            this.mapCenterSnapedData[key] !== this.state[key]);
        setSessionStorageItem(LM_MAP_ZOOM_AND_CENTER_MODIFIED, ((!initialLoad && mapCenterChanged) ? 'true' : 'false'));

        if (!initialLoad && mapCenterChanged) {
            removeSessionStorageItem(MAP_ZOOM_AND_CENTER_SESSION_NAME);
        }
    }

    handleChange = (key, value) => {
        if (!key) return;
        let newValue = value;
        if (!value) newValue = '';

        if (key === 'iconValue') this.updateIconColor(value);
        else if (['address', 'city', 'state', 'zip'].includes(key)) this.isShapeMoved = false;
        this.setState({ [key]: newValue });
    }

    handleChangeLatLng = (key, value) => {
        let { lat, lng } = this.state;
        const { shapeValue } = this.state;
        if (!key) return;
        const newValue = (value) ? +value : 0;

        if (Number.isNaN(newValue)) return;

        if (key === 'lat') lat = value;
        else if (key === 'lng') lng = value;

        if (shapeValue === 'editableRectangle') this.updateNewBounds(lat, lng);
        this.setState({ [key]: newValue });
    }

    handleColorChange = (key, value) => {
        if (!value.hex) return;
        this.setState({ [key]: value.hex });
    }

    handleNumberChange = (key, value) => {
        if (!value || value < 0) return;
        this.setState({ [key]: +value });
    }

    getPoints = (shape) => {
        switch (shape) {
        case 'editableCircle': return { lat: this.state.lat, lng: this.state.lng };
        case 'editableRectangle': return {
            lat: this.state.lat, lng: this.state.lng,
        };
        case 'editablePolygon': return {
            lat: this.state.lat, lng: this.state.lng,
        };
        default: return { lat: this.state.lat, lng: this.state.lng };
        }
    }

    updateShape = (key, value, center) => {
        if (!value || !key) return;
        this.isShapeMoved = true;
        this.props.fetchAddressWithLatLng(center.lat || 0, center.lng || 0);
        const updatedObj = {
            radius: +value.radius.toFixed(2) || this.state.radius,
            lat: center.lat || 0,
            lng: center.lng || 0,
        };

        if (['editableCircle', 'editableRectangle'].includes(key)) this.setState({ ...updatedObj, bounds: value.bounds });
        else if (key === 'editablePolygon') this.setState({ ...updatedObj, points: value.points });
    }

    updateNewBounds = (nextLat, nextLng) => {
        let { bounds } = this.state;
        const { lat, lng } = this.state;
        const resultLat = nextLat - lat;
        const resultLng = nextLng - lng;

        bounds = {
            north: resultLat + bounds.north,
            south: resultLat + bounds.south,
            east: resultLng + bounds.east,
            west: resultLng + bounds.west,
        };

        this.setState({ lat: nextLat, lng: nextLng, bounds });
    }

    addressChange = () => {
        const {
            address, state, zip, city, shapeValue,
        } = this.state;

        if (address && state && zip && city) {
            const createAddress = `${address || ''}, ${city || ''}, ${state || ''}, ${zip || ''}`;
            fetchGeoCodeByAddress(createAddress, (error, result) => {
                if (result && (result.lat || result.lng)) {
                    if (shapeValue === 'editableRectangle') {
                        this.updateNewBounds(result.lat, result.lng);
                    } else this.setState({ lat: result.lat, lng: result.lng });
                } else if (error) {
                    this.setState({ isValidAddress: false });
                    setTimeout(() => {
                        this.setState({ isValidAddress: true });
                    }, 3000);
                }
            });
        }
    }

    getPolygonPoints = (type) => {
        const shape = this.getShapeValue(this.state.shapeValue);
        let polygon = [];
        let polygonString = '';

        switch (shape) {
        case 'circle': {
            polygon.push([this.state.lat, this.state.lng]);
            polygonString = `${this.state.lat} ${this.state.lng}`;
            break;
        }
        case 'rectangle': {
            const {
                north, south, west, east,
            } = this.state.bounds;

            polygon.push([south, west], [south, east], [north, east], [north, west]);
            polygonString = `${south} ${west},${south} ${east},${north} ${east},${north} ${west}`;
            break;
        }
        case 'polygon': {
            polygon = this.state.points;
            let value = [];
            value = this.state.points.map(d => `${d[0]} ${d[1]}`);
            polygonString = value.join(',');
            break;
        }
        default: break;
        }

        if (type === 'string') return polygonString;
        return polygon;
    }

    updateCenter(newShapeValue) {
        const { shapeValue } = this.state;
        let boundsValue = this.state.bounds;
        let radiusValue = this.state.radius;
        let latValue = this.state.lat;
        let lngValue = this.state.lng;
        let value = null;

        switch (shapeValue) {
        case 'editableCircle': {
            if (this.getCircleValue) {
                value = this.getCircleValue();
                this.getRectangleValue = null;
                this.getPolygonValue = null;
            }
            break;
        }
        case 'editableRectangle': {
            if (this.getRectangleValue) {
                value = this.getRectangleValue();
                this.getCircleValue = null;
                this.getPolygonValue = null;
            }
            break;
        }
        case 'editablePolygon': {
            if (this.getPolygonValue) {
                value = this.getPolygonValue();
                this.getCircleValue = null;
                this.getRectangleValue = null;
            }
            break;
        }
        default: break;
        }

        if (value) {
            boundsValue = value.bounds;
            radiusValue = +value.radius.toFixed(2);
            latValue = value.center.lat();
            lngValue = value.center.lng();
        }

        let polygonPoints = [];
        if (value && value.newPoints) {
            polygonPoints = value.newPoints;
        } else {
            polygonPoints.push(
                [boundsValue.south, boundsValue.west],
                [boundsValue.south, boundsValue.east],
                [boundsValue.north, boundsValue.east],
                [boundsValue.north, boundsValue.west],
            );
        }

        this.setState({
            shapeValue: newShapeValue,
            bounds: boundsValue,
            points: polygonPoints,
            radius: radiusValue,
            lat: latValue,
            lng: lngValue,
        });
    }

    formatData = () => ({
        accountId: this.state.accountId,
        accountLabel: this.state.accountLabel,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
        phoneNumber: this.state.phoneNumber,
        circleCoordinates: [this.state.lat, this.state.lng],
        dateCreated: this.state.dateCreated,
        description: this.state.comments,
        deviceId: 0,
        landmarkType: '',
        deviceLabel: '',
        attributesJSON: `[{"attributeTypeId":1405, "value":"/images/landmarkCustomIcons/${this.state.iconValue}"}]`,
        fillDensity: (100 - this.state.transparency) / 100,
        groupId: this.state.groupValue || 0,
        groupLabel: '',
        groupName: this.state.groupName,
        lat: this.state.lat,
        lng: this.state.lng,
        maxLat: -1000,
        maxLng: -1000,
        minLat: -1000,
        minLng: -1000,
        lineColor: (this.state.lineColor) ? this.state.lineColor.slice(1) : '',
        fillColor: (this.state.fillColor) ? this.state.fillColor.slice(1) : '',
        lineWidth: (this.state.lineWidth) ? this.state.lineWidth : 1,
        name: this.state.name,
        par43NonPlate: 0,
        par43Plate: 0,
        par47NonPlate: 0,
        parAny: 0,
        polygon: this.getPolygonPoints('string'),
        polygonPoints: this.getPolygonPoints('array'),
        radius: this.state.radius,
        type: this.getShapeValue(this.state.shapeValue),
        tolerance: '',
        tags: '',
        version: this.state.version || 0,
        fillTransparency: 80 / 100, // not used
        id: '',
    });

    saveData = () => {
        if (!this.isShapeMoved) this.addressChange();
        setTimeout(() => {
            if (this.state.phoneNumber.length > 0 &&
            this.state.phoneNumber.length !== MAX_LENGTH_PHONE_NUMBER) return;
            const landMark = this.formatData();
            if (this.validateData()) {
                this.handleMapCentering();
                const pars = {
                    feature: 'FLEET_Landmark',
                    shape: this.state.shapeValue ? 'true' : 'false',
                    landmarkIcon: this.state.iconValue ? 'true' : 'false',
                    lineColor: this.state.lineColor ? 'true' : 'false',
                    fillColor: this.state.fillColor ? 'true' : 'false',
                    lineWidth: this.state.lineWidth ? 'true' : 'false',
                    radius: this.state.radius ? 'true' : 'false',
                    transparency: this.state.transparency ? 'true' : 'false',
                    number: this.state.phoneNumber ? 'true' : 'false',
                    group: this.state.groupValue ? 'true' : 'false',
                    address: this.state.address ? 'true' : 'false',
                    city: this.state.city ? 'true' : 'false',
                    state: this.state.state ? 'true' : 'false',
                    zip: this.state.zip ? 'true' : 'false',
                    latitude: this.state.lat ? 'true' : 'false',
                    longitude: this.state.lng ? 'true' : 'false',
                };
                if (this.props.isEdit) {
                    landMark.id = this.state.id;
                    this.props.updateLandmark(landMark);
                    const changedDataArr = [];
                    let changedData = '';
                    Object.keys(this.state.mixpanelOriginalState).forEach((v) => {
                        if (this.state[v] !== this.state.mixpanelOriginalState[v]) {
                            if (v === 'name') {
                                changedDataArr.push('landmarkName');
                            } else if (v === 'lat') {
                                changedDataArr.push('latitude');
                            } else if (v === 'lng') {
                                changedDataArr.push('longitude');
                            } else changedDataArr.push(v);
                        }
                    });
                    changedData = changedDataArr.join(', ');
                    if (changedData.length !== 0) {
                        analytics.track('CHANGE_LANDMARK', { changedData, feature: 'FLEET_Landmark' });
                    }
                    analytics.track('EDIT_LANDMARK', pars);
                } else {
                    delete landMark.id;
                    this.props.createLandmark(landMark);
                    analytics.track('ADD_LANDMARK', pars);
                }
            }
        }, 2000);
    };

    deleteLandmark = () => {
        this.props.deleteLandmark(this.state.id);
    }

    validateData() {
        const {
            name, lat, lng, iconValue,
        } = this.state;

        if (name.length === 0 || lat === 0 || lng === 0 || iconValue === '') return false;
        return true;
    }

    getDropDownValue = (e) => {
        let menu = { name: 'Select Group' };
        this.state.groupData.some((d) => {
            if (d.id === e) {
                menu = d;
                return true;
            }
            return false;
        });
        return menu.name;
    }

    getMenu = (landmarkGroup, groupName) => {
        const value = landmarkGroup.map((d) => {
            const selected = d.name === groupName;
            const menu = (
                <MenuItem
                    key={d.id}
                    value={d.id}
                    selected={selected}
                >
                    <span style={{ paddingLeft: d.nestLevel * 15 }}>
                        {(d.nestLevel !== 0) ? <img style={{ paddingRight: 6 }} src={dropdownNested} alt="dropdownNested" /> : ''}{`${d.name}`}
                    </span>
                </MenuItem>
            );
            return menu;
        });
        return value;
    }

    removeDuplicateCharacter = charactersList => charactersList.filter((item, index) =>
        charactersList.indexOf(item) === index).join(' ')

    render() {
        const { classes, isUpdating, isEdit } = this.props;
        let {
            lineWidth = LANDMARK_DEFAULT_LINE_WIDTH,
            transparency = LANDMARK_DEFAULT_TRANSPARENCY,
        } = this.state;

        const {
            shapeValue = '',
            groupValue = '',
            groupName = '',
            iconValue = '',
            lineColor = '',
            fillColor = '',
            iconColor = '',
            radius = LANDMARK_DEFAULT_RADIUS,
            bounds,
            name = '',
            comments = '',
            address = '',
            phoneNumber = '',
            city = '',
            isValidAddress,
            state = '',
            zip = '',
            lat = '',
            lng = '',
            // DO NOT DELETE -> for future use
            // isColorDisable,
            points,
        } = this.state;

        if (!shapeValue) {
            lineWidth = 0;
            transparency = 0;
        }

        const landmarkGroup = this.state.groupData;
        const displayEmpty = true;
        const latlng = this.getPoints(shapeValue);

        return (
            <div className={classes.app}>
                <Paper>
                    <Toolbar className={classes.toolbar}>
                        <span className={classes.backButton}>
                            <IconButton onClick={() => window.history.back()} size="large">
                                <img src={back} alt="backArrow" />
                            </IconButton>
                        </span>
                        <Typography variant="h6" color="inherit" className={classes.titleWrap}>
                            <span>{isEdit ? 'Edit' : 'Add'} Landmark</span>
                        </Typography>
                    </Toolbar>
                    <ValidatorForm
                        autoComplete="off"
                        className={classes.form}
                        onSubmit={() => { this.saveData(); }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup style={{ marginTop: 12 }}>
                                        <TextValidator
                                            label="Name*"
                                            onChange={e => this.handleChange('name', e.target.value)}
                                            name="name"
                                            value={name}
                                            validators={['isEmpty']}
                                            errorMessages={['this field is required']}
                                            className={classes.textField}
                                            margin="normal"
                                        />
                                        <TextField
                                            id="lm-comments"
                                            label="Comments"
                                            className={classes.textField}
                                            margin="normal"
                                            value={comments}
                                            onChange={e => this.handleChange('comments', e.target.value)}
                                        />
                                        <div style={{ margin: '17px 0' }}>
                                            <PhoneNumber
                                                handleChange={e => this.handleChange('phoneNumber', e)}
                                                errorText="Please Enter A Vaild Phone Number"
                                                value={phoneNumber}
                                            />
                                        </div>
                                        <div className={classes.sideBySide}>
                                            <Select
                                                value={groupValue}
                                                onChange={(e) => {
                                                    this.handleChange('groupValue', e.target.value);
                                                    this.handleChange('groupName', e.target.text);
                                                }}
                                                renderValue={e => this.getDropDownValue(e)}
                                                displayEmpty={displayEmpty}
                                                style={{ minWidth: 150, marginTop: 16 }}
                                            >
                                                <MenuItem value="">Select Group</MenuItem>
                                                {(isEdit && landmarkGroup &&
                                                    landmarkGroup.length === 0) &&
                                                    <MenuItem value={groupValue}>
                                                        {(isEdit && groupName && landmarkGroup.length <= 0) ? groupName : ''}
                                                    </MenuItem>
                                                }
                                                {landmarkGroup &&
                                                    this.getMenu(landmarkGroup, groupName)}
                                                {isUpdating &&
                                                    <MenuItem value="">
                                                        <AppLoader
                                                            loaderStyle={customStyles.loader}
                                                            height={20}
                                                            width={3}
                                                        />
                                                    </MenuItem>
                                                }
                                            </Select>
                                            <Button
                                                color="primary"
                                                className={classes.clearButton}
                                                onClick={() => {
                                                    this.handleChange('groupValue', '');
                                                    this.handleChange('groupName', '');
                                                }}
                                            >Clear
                                            </Button>
                                        </div>
                                        <TextValidator
                                            label="Landmark Center Point Address"
                                            name="landmarkAddress"
                                            className={classes.textField}
                                            margin="normal"
                                            value={address}
                                            validators={['isAddress']}
                                            errorMessages={[
                                                <span className={classes.whiteSpace}>
                                                    Invalid input characters found:&nbsp;
                                                    {this.state.unsupportedCharacters}
                                                </span>,
                                            ]}
                                            onBlur={() => this.addressChange()}
                                            onChange={e => this.handleChange('address', e.target.value)}
                                        />
                                        <TextField
                                            id="lm-city"
                                            label="City"
                                            name="city"
                                            className={classes.textField}
                                            margin="normal"
                                            value={city}
                                            onBlur={() => this.addressChange()}
                                            onChange={e => this.handleChange('city', e.target.value)}
                                        />
                                        <div className={classes.sideBySide}>
                                            <TextValidator
                                                label="State"
                                                onChange={e => this.handleChange('state', e.target.value)}
                                                onBlur={() => this.addressChange()}
                                                name="state"
                                                value={state}
                                                validators={['isState']}
                                                errorMessages={['enter a valid state in CAPS']}
                                                className={classes.textField}
                                                margin="normal"
                                                autoComplete="none"
                                                containerProps={{
                                                    className: classes.stateTextValidator,
                                                }}
                                            />
                                            <TextValidator
                                                label="Zip"
                                                onChange={e => this.handleChange('zip', e.target.value)}
                                                name="zip"
                                                value={zip}
                                                onBlur={() => this.addressChange()}
                                                className={classes.textField}
                                                margin="normal"
                                                style={{ minWidth: '100px', marginLeft: '30px', width: '30%' }}
                                                autoComplete="none"
                                                containerProps={{
                                                    className: classes.zipTextValidator,
                                                }}
                                            />
                                        </div>
                                        <TextValidator
                                            label="Latitude*"
                                            onChange={e => this.handleChangeLatLng('lat', e.target.value)}
                                            name="lat"
                                            value={lat}
                                            type="number"
                                            inputProps={{ step: 0.001 }}
                                            validators={['isEmpty']}
                                            errorMessages={['this field is required']}
                                            className={classes.textField}
                                            margin="normal"
                                        />
                                        <TextValidator
                                            label="Longitude*"
                                            onChange={e => this.handleChangeLatLng('lng', e.target.value)}
                                            name="lng"
                                            value={lng}
                                            type="number"
                                            inputProps={{ step: 0.001 }}
                                            validators={['isEmpty']}
                                            errorMessages={['this field is required']}
                                            className={classes.textField}
                                            margin="normal"
                                        />
                                        {!isValidAddress &&
                                            <span style={{ color: '#f44336' }}>*Unable to geocode current address. Please try again later.</span>
                                        }
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={8}>
                                <div>
                                    <div className={classes.dropdownContainer}>
                                        <TextField
                                            value={shapeValue}
                                            onChange={e => this.updateCenter(e.target.value)}
                                            className={classes.dropdown}
                                            select
                                            name="shape"
                                            label="Shape"
                                        >
                                            <MenuItem value="editableCircle">Circle</MenuItem>
                                            <MenuItem value="editableRectangle">Rectangle</MenuItem>
                                            <MenuItem value="editablePolygon">Polygon</MenuItem>
                                        </TextField>
                                        <SelectValidator
                                            value={iconValue}
                                            onChange={e => this.handleChange('iconValue', e.target.value)}
                                            className={classes.dropdown}
                                            label="Landmark Icon*"
                                            name="iconValue"
                                            select
                                            validators={['isEmpty']}
                                            errorMessages={['this field is required']}
                                            sx={{ width: '92%' }}
                                            containerProps={{
                                                className: classes.landmarkIconSelectValidator,
                                            }}
                                        >
                                            {data.map(menu => (
                                                <MenuItem
                                                    value={menu.value}
                                                    key={menu.value}
                                                >
                                                    <img
                                                        className={classes.landmarkIcon}
                                                        src={getImage(menu.imagePath)}
                                                        alt={menu.key}
                                                    />
                                                    {menu.key}
                                                </MenuItem>
                                            ))}
                                        </SelectValidator>
                                        { /* DO NOT DELETE -> for future use */ }
                                        {/* <Select
                                            value=""
                                            onChange={() => {}}
                                            className={classes.dropdown}
                                            displayEmpty={displayEmpty}
                                            disabled={isColorDisable}
                                        >
                                            <MenuItem value="">
                                                <em
                                                    className={classes.colorPickerLabel}
                                                    style={{ background: iconColor }}
                                                >
                                                    <span className={classes.colorPickerLableSpan}>
                                                        Icon Color
                                                    </span>
                                                </em>
                                            </MenuItem>
                                            <MenuItem
                                                value="iconColor"
                                                className={classes.colorPicker}
                                            >
                                                <CompactPicker
                                                    color={iconColor}
                                                    onChangeComplete={val =>
                                                        this.handleColorChange('iconColor', val)
                                                    }
                                                />
                                            </MenuItem>
                                        </Select> */}
                                        <Select
                                            value=""
                                            onChange={() => {}}
                                            className={classes.dropdown}
                                            displayEmpty={displayEmpty}
                                        >
                                            <MenuItem value="">
                                                <em
                                                    className={classes.colorPickerLabel}
                                                    style={{ background: lineColor }}
                                                >
                                                    <span className={classes.colorPickerLableSpan}>
                                                        Line Color
                                                    </span>
                                                </em>
                                            </MenuItem>
                                            <MenuItem value="lineColor" className={classes.colorPicker}>
                                                <CompactPicker
                                                    color={lineColor}
                                                    onChangeComplete={val => this.handleColorChange('lineColor', val)}
                                                />
                                            </MenuItem>
                                        </Select>
                                        <Select
                                            value=""
                                            onChange={() => {}}
                                            className={classes.dropdown}
                                            displayEmpty={displayEmpty}
                                        >
                                            <MenuItem value="">
                                                <em
                                                    className={classes.colorPickerLabel}
                                                    style={{ background: fillColor }}
                                                >
                                                    <span className={classes.colorPickerLableSpan}>
                                                        Fill Color
                                                    </span>
                                                </em>
                                            </MenuItem>
                                            <MenuItem value="fillColor" className={classes.colorPicker}>
                                                <CompactPicker
                                                    onChangeComplete={val => this.handleColorChange('fillColor', val)}
                                                />
                                            </MenuItem>
                                        </Select>
                                    </div>
                                    <div className={classes.properties}>
                                        <TextField
                                            id="line-width"
                                            label="Line Width"
                                            className={classes.propertyTextField}
                                            margin="normal"
                                            type="number"
                                            inputProps={{ step: 1, min: 0, max: 10 }}
                                            value={lineWidth}
                                            onChange={e => this.handleNumberChange('lineWidth', e.target.value)}
                                        />
                                        {shapeValue === 'editableCircle' &&
                                            <TextField
                                                id="radius"
                                                label="Radius (m)"
                                                className={classes.propertyTextField}
                                                margin="normal"
                                                type="number"
                                                inputProps={{ step: 1, min: 1 }}
                                                value={Math.round(radius)}
                                                onChange={e => this.handleNumberChange('radius', e.target.value)}
                                            />
                                        }
                                        <TextField
                                            id="transparency"
                                            label="Transparency (%)"
                                            className={classes.propertyTextField}
                                            margin="normal"
                                            type="number"
                                            inputProps={{ step: 10, min: 0, max: 100 }}
                                            value={transparency}
                                            onChange={e => this.handleNumberChange('transparency', e.target.value)}
                                        />
                                    </div>
                                    <div className={classes.mapContainer} key="lm-map-container">
                                        <MapsWrapper
                                            key="lm-map-for-shape"
                                            coordinates={{ lat: latlng.lat, lng: latlng.lng }}
                                            hideViewButton
                                            hideRightClick
                                            markers={{
                                                shape: {
                                                    type: shapeValue,
                                                    points: latlng,
                                                    props: {
                                                        radius,
                                                        bounds,
                                                        iconValue,
                                                        points,
                                                        lineColorValue: lineColor,
                                                        fillColorValue: fillColor,
                                                        iconColorValue: iconColor,
                                                        lineWidth,
                                                        transparency,
                                                    },
                                                    updateShape: this.updateShape,
                                                    getCircleValue: (cr) => {
                                                        this.getCircleValue = cr;
                                                    },
                                                    getRectangleValue: (rr) => {
                                                        this.getRectangleValue = rr;
                                                    },
                                                    getPolygonValue: (pr) => {
                                                        this.getPolygonValue = pr;
                                                    },
                                                },
                                            }}
                                            height="calc(50vh + 100px)"
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                        <Toolbar>
                            <Grid item>
                                <div className={classes.requiredField}>
                                * Required Field
                                </div>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Grid item>
                                    <Button
                                        className={classes.button}
                                        onClick={() => { window.history.back(); }}
                                    >
                                        Cancel
                                    </Button>
                                    {isEdit &&
                                    <Button
                                        className={classes.button}
                                        onClick={() => { this.deleteLandmark(); }}
                                        disabled={getPermissionValue('Landmarks') !== 'Modify'}
                                    >
                                        Delete
                                    </Button>
                                    }
                                    <Button
                                        color="primary"
                                        className={classes.saveButton}
                                        variant="contained"
                                        type="submit"
                                        disabled={getPermissionValue('Landmarks') !== 'Modify'}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </ValidatorForm>
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    geoAddress: state.address.address,
});

export default withStyles(customStyles)(reduxConnect(AddLandmark, actions, mapStateToProps));
