/* @flow */
import {
    ACCOUNT_DETAIL_SUCCESS, ACCOUNT_DETAIL_ERROR,
    SAVE_ACCOUNT_DETAIL_SUCCESS, SAVE_ACCOUNT_DETAIL_ERROR,
    SAVE_APPSETTINGS_SUCCESS, SAVE_APPSETTINGS_ERROR,
    SAVE_PREFERENCES_SUCCESS, SAVE_PREFERENCES_ERROR, UPDATE_ACCOUNT_DETAIL_LOADER,
    SAVE_PREFERENCES, GET_USER_BY_IDENTITY_SUCCESS, GET_USER_SETTINGS_SUCCESS,
    ACCEPT_RESELLER_TERMS_AND_CONDITION_SUCCESS,
    ACCEPT_RESELLER_TERMS_AND_CONDITION,
    ACCEPT_RESELLER_TERMS_AND_CONDITION_ERROR,
} from './constants.settings';

const initialState = {
    settings: [],
    isLoader: false,
    termsAndCondition: '',
};

type stateType = {
    settings: any,
    isLoader: boolean,
    termsAndCondition: string,
}
type actionType = {
    type: string,
    payload :{
        response: Object,
        error: Object,
        token: string,
        showLoader: boolean,
        isLoader: boolean,
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case ACCOUNT_DETAIL_SUCCESS:
        return {
            ...state,
            buttonClicked: false,
            fetchAccountDetails: true,
            updateAccountDetails: null,
            updateAppSettings: null,
            updatePreferences: null,
            settings: action.payload.response,
            isLoader: false,
        };
    case ACCOUNT_DETAIL_ERROR:
        return {
            ...state,
            buttonClicked: false,
            updateAccountDetails: null,
            fetchAccountDetails: false,
            updateAppSettings: null,
            updatePreferences: null,
            isLoader: false,
        };
    case SAVE_ACCOUNT_DETAIL_SUCCESS:
        return {
            ...state,
            buttonClicked: false,
            updateAccountDetails: true,
            fetchAccountDetails: null,
            updateAppSettings: null,
            updatePreferences: null,
            userAccountDetailsByIdentity: action.payload.response,
            isLoader: false,
        };

    case GET_USER_BY_IDENTITY_SUCCESS:
        return {
            ...state,
            userAccountDetailsByIdentity: action.payload.response.user || {},
        };

    case GET_USER_SETTINGS_SUCCESS:
        return {
            ...state,
            settings: action.payload.response,
        };
    case SAVE_ACCOUNT_DETAIL_ERROR:
        return {
            ...state,
            buttonClicked: false,
            updateAccountDetails: false,
            fetchAccountDetails: null,
            updateAppSettings: null,
            updatePreferences: null,
            isLoader: false,
        };
    case SAVE_APPSETTINGS_SUCCESS:
        return {
            ...state,
            buttonClicked: false,
            updateAppSettings: true,
            updateAccountDetails: null,
            fetchAccountDetails: null,
            updatePreferences: null,
            updateTime: Date.now(),
            settings: action.payload.response,
            isLoader: false,
        };
    case SAVE_APPSETTINGS_ERROR:
        return {
            ...state,
            buttonClicked: false,
            updateAccountDetails: null,
            fetchAccountDetails: null,
            updateAppSettings: false,
            updatePreferences: false,
            isLoader: false,
        };
    case SAVE_PREFERENCES_SUCCESS:
        return {
            ...state,
            buttonClicked: false,
            updatePreferences: true,
            updateAccountDetails: null,
            fetchAccountDetails: null,
            updateAppSettings: null,
            updateTime: Date.now(),
            settings: action.payload.response,
            isLoader: false,
        };
    case SAVE_PREFERENCES_ERROR:
        return {
            ...state,
            buttonClicked: false,
            updateAccountDetails: null,
            fetchAccountDetails: null,
            updateAppSettings: null,
            updatePreferences: false,
            settings: action.payload.error,
        };
    case UPDATE_ACCOUNT_DETAIL_LOADER:
        return {
            ...state,
            isLoader: action.payload.showLoader,
        };
    case SAVE_PREFERENCES:
        return {
            ...state,
            isLoader: false,
        };
    case ACCEPT_RESELLER_TERMS_AND_CONDITION:
        return {
            ...state,
            termsAndCondition: 'processing',
        };
    case ACCEPT_RESELLER_TERMS_AND_CONDITION_SUCCESS:
        return {
            ...state,
            termsAndCondition: 'accepted',
        };
    case ACCEPT_RESELLER_TERMS_AND_CONDITION_ERROR:
        return {
            ...state,
            termsAndCondition: 'error',
        };
    default:
        return {
            ...state,
            updateAccountDetails: null,
            fetchAccountDetails: null,
            updateAppSettings: null,
            updatePreferences: null,
        };
    }
};
