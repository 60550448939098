import React from 'react';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import styles from './AlertStepper.module.scss';
import SearchRecipient from './SearchUsers';
import { ALERT_RECIPIENT } from '../../../containers/Alerts/constants.alerts';

type Props = {
    onRecipientSelect: any,
    recipientInputValue: any,
    onRecipientAdd: any,
    chipData: any,
    handleDelete: any,
    onTextChange: any,
    initialPageLoad: any,
    recipientList: any,
    searchIsLoading: any,
    errors: any,
    onChange: Function,
    formData: {
        comments: String,
    },
    hideComments: boolean,
};
type State = {};

class AlertRecipient extends React.Component<Props, State> {
    state = {};
    render() {
        return (
            <div className={styles['step-content']}>
                <form>
                    <br />
                    <br />
                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs={5} className={styles['']}>
                                <FormControl className={styles['email-container']}>
                                    <SearchRecipient
                                        searchFieldId="searchFieldId"
                                        onSelect={this.props.onRecipientSelect}
                                        recipientInputValue={this.props.recipientInputValue}
                                        onTextChange={this.props.onTextChange}
                                        initialPageLoad={this.props.initialPageLoad}
                                        recipientList={this.props.recipientList}
                                        searchIsLoading={this.props.searchIsLoading}
                                        errors={this.props.errors}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={1}>
                                <FormControl>
                                    <Button
                                        id="recipientAddButton"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.props.onRecipientAdd}
                                    >
                                        {ALERT_RECIPIENT.LABEL.ADD}
                                    </Button>
                                </FormControl>
                            </Grid>
                            {
                                this.props.hideComments ? null : (
                                    <Grid item xs={5}>
                                        <FormControl>
                                            <Input
                                                id="recipientComments"
                                                className={styles['comments-field']}
                                                placeholder={ALERT_RECIPIENT.LABEL.COMMENTS}
                                                onChange={this.props.onChange}
                                                name="comments"
                                                value={this.props.formData.comments}
                                                inputProps={{
                                                    'aria-label': 'Description',
                                                }}
                                                autoComplete="off"
                                            />
                                        </FormControl>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </div>
                    <br />
                    <br />
                    <div className={styles['Recipient-chip-container']}>
                        {
                            this.props.chipData.map(data => (
                                <Chip
                                    id={data.key}
                                    className={styles['chip-spacing']}
                                    key={data.key}
                                    label={data.label}
                                    onDelete={() => this.props.handleDelete(data)}
                                />
                            ))
                        }
                    </div>
                </form>
            </div>
        );
    }
}

AlertRecipient.defaultProps = {
    formData: {},
    hideComments: false,
};

export default AlertRecipient;
