/* eslint-disable */
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import withStyles from '@mui/styles/withStyles';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import styles from '../../Steppers/AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';
import AsyncSelectWrapper from '../../../SharedComponents/AsyncSelectWrapper';
import dropdownNested from '../../../../assets/icons/arrow-down.svg';
import stylesAsset from '../../../SharedComponents/AssetCriteria/AssetCriteriaStyles';
import {
    fetchLandmarkList,
} from '../../../../containers/Maintenance/helper-classes/common-services';

type Props = {
    formData: {
        config: {
            alertArrival: Boolean,
            alertDeparture: Boolean,
            selectedLandmarkGroups: any,
            selectedLandmarkFilters: any,
        },
    },
    handleTextboxChange: Function,
    handleCheckboxChange: Function,
    clearLandmarkSelects: Function,
    allLandmarkGroups: any,
};
type State = {
    selectedTab: string,
    assetCriteria: { assets: Array<{}>, groups: Array<{}>, tags: Array<{}>, type: string },
    allLandmarkGroups: any,
};

class LandmarkArrivalDepartureAlert extends React.Component<Props> {
    constructor(props) {
        super(props);
        const landmarks = props.landmarks || {};

        landmarks.groups = props.formData.config.selectedLandmarkGroups || landmarks.groups;
        landmarks.landmarks = props.formData.config.selectedLandmarkFilters || landmarks.landmarks;
        this.state = {
            landmarks,
            allLandmarkGroups: [],
        };
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        const { allLandmarkGroups } = nextprops;
        const { stateAllLandmarkGroups } = this.state;
        if (allLandmarkGroups && allLandmarkGroups.length > 0 &&
            stateAllLandmarkGroups !== allLandmarkGroups) {
            this.setState({
                allLandmarkGroups,
            });
        }
    }

    labelForType = type => (type === 'groups' ? 'name' : 'landmarks')

    loadOptions = (type, inputValue) =>
        new Promise((resolve) => {
            const loadCriteria = this.props.allLandmarkGroups;
            let optionData = loadCriteria.map(datum => ({
                value: datum.id,
                label: datum.name,
                data: datum,
            }));
            if (inputValue) {
                optionData = optionData.filter(option => (
                    option.label.toLowerCase().includes(inputValue.toLowerCase())
                ));
            }
            resolve(optionData);
        });

    onItemSelected = (type, selected) => {
        const { landmarks } = this.state;
        if (type === 'groups') {
            if(landmarks.landmarks.length >= 1 && landmarks[type].length ===0 ){
                landmarks.landmarks=[];
                this.props.clearLandmarkSelects(landmarks.landmarks);
            }
        }
        if (landmarks[type].filter(item => item.id === selected.data.id).length === 0) {
            landmarks[type].push(selected.data);
            this.setState({ landmarks }, () => {
                this.props.handleTextboxChange(type)({
                    target: {
                        value: landmarks[type],
                    },
                });
            });
        }
    }

    handleDelete = (type, data) => {
        const { landmarks } = this.state;
        if (type === 'groups') {
            const landmarksGroups = landmarks[type];
            const chipToDelete = landmarksGroups.indexOf(data);
            landmarksGroups.splice(chipToDelete, 1);
            landmarks[type] = landmarksGroups;
            const deletlandmarklist = landmarks.landmarks.filter(item =>
                item.parentGroupId !== data.id);
            landmarks.landmarks = deletlandmarklist;
            this.setState({
                ...this.state,
                landmarks,
            }, () => {
                this.props.handleTextboxChange(type)({
                    target: {
                        value: landmarks[type],
                    },
                });
                this.props.clearLandmarkSelects(deletlandmarklist);
            });
        } else {
            const landmarksGroups = landmarks[type];
            const chipToDelete = landmarksGroups.indexOf(data);
            landmarksGroups.splice(chipToDelete, 1);
            landmarks[type] = landmarksGroups;
            this.setState({
                ...this.state,
                landmarks,
            }, () => {
                this.props.handleTextboxChange(type)({
                    target: {
                        value: landmarks[type],
                    },
                });
            });
        }
    }

    loadNext = loadedOptions => new Promise((resolve, reject) => {
        fetchLandmarkList(loadedOptions.length, this.props.formData.config.selectedLandmarkGroups).then((response) => {
            const { landmarkList, data } = response;
            if (data && data.total) {
                const hasMore = (loadedOptions.length + data.data.length) < data.total;
                if (data) {
                    const optionData = landmarkList.map(datum => ({
                        value: datum.id,
                        label: datum.name,
                        data: datum,
                    }));
                    resolve({ options: optionData, hasMore });
                } else reject();
            } else reject();
        });
    });

    render() {
        let landmarkGroupSection = null;
        let landmarksSection = null;
        const disableChips = true;
        if (this.state.allLandmarkGroups !== undefined && this.state.allLandmarkGroups.length > 0) {
            const type = 'groups';
            landmarkGroupSection = (
                <div>
                    <Grid container spacing={5}>
                        <Grid item xs={10}>
                            <FormControl className={styles['landmark-selection']}>
                                <AsyncSelectWrapper
                                    key={`${type}_${disableChips}`}
                                    handleDelete={item => this.handleDelete(type, item.id)}
                                    loadOptions={inputValue => this.loadOptions(type, inputValue)}
                                    onItemSelected={selected => this.onItemSelected(type, selected)}
                                    title="All Groups"
                                    disableChips={disableChips}
                                    disablePaging
                                    styles={disableChips ? { container: stylesAsset.selectInternalContainer } : { select: { width: '20%' } }}
                                    selectStyles={{
                                        control: () => ({
                                            display: 'flex',
                                            border: 'none',
                                            boxShadow: 'none !important',
                                        }),
                                        option: (s, { data }) => {
                                            const obj = data.data || {};
                                            if (obj.nestLevel) {
                                                return {
                                                    ...s,
                                                    marginLeft: 8 + (obj.nestLevel * 12),
                                                    paddingLeft: obj.nestLevel ? 24 : s.paddingLeft,
                                                    backgroundImage: `url(${dropdownNested})`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'left center',
                                                };
                                            }
                                            return s;
                                        },
                                    }}
                                    menuPlacement="bottom"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        const type = 'landmarks';
        const landmarkList = this.state.allLandmarkGroups;
        const lmgIds = this.props.formData.config.groups ? this.props.formData.config.groups.map(lmg => lmg.id) :
            this.props.formData.config.groups;
        if (this.props.formData.config.landmarks) {
            for (let i = 0; i < this.state.landmarks.landmarks.length; i++) {
                for (let j = 0; j < this.props.formData.config.landmarks.length; j++) {
                    if (this.state.landmarks.landmarks[i]['value'] === this.props.formData.config.landmarks[j]['id']) {
                        this.state.landmarks.landmarks[i] = this.props.formData.config.landmarks[j];
                    }
                }
            }
        }
        landmarksSection = (
                <div style={{ width: '100%' }}>
                    <Grid container spacing={5}>
                        <Grid item xs={10}>
                            <FormControl className={styles['landmark-selection']}>
                                <AsyncSelectWrapper
                                    key={`${type}_${disableChips}_${lmgIds}`}
                                    handleDelete={item => this.handleDelete(type, item.id)}
                                    selectedOptions={landmarkList.map(item => ({
                                        value: item.id,
                                        label: item.name,
                                        data: item,
                                    }))}
                                    loadOptions={(inputValue, loadedOptions) => this.loadNext(loadedOptions)}
                                    onItemSelected={selected => this.onItemSelected(type, selected)}
                                    title="All Landmarks"
                                    disableChips={disableChips}
                                    styles={disableChips ? { container: stylesAsset.selectInternalContainer } : { select: { width: '20%' } }}
                                    selectStyles={{
                                        control: () => ({
                                            display: 'flex',
                                            border: 'none',
                                            boxShadow: 'none !important',
                                        }),
                                        option: (s, { data }) => {
                                            const obj = data.data || {};
                                            if (obj.nestLevel) {
                                                return {
                                                    ...s,
                                                    marginLeft: 8 + (obj.nestLevel * 12),
                                                    paddingLeft: obj.nestLevel ? 24 : s.paddingLeft,
                                                    backgroundImage: `url(${dropdownNested})`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'left center',
                                                };
                                            }
                                            return s;
                                        },
                                    }}
                                    menuPlacement="bottom"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            );
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    <div className={styles['step-rightside']}>
                        <form noValidate autoComplete="off">
                            <div>
                                <div>{ALERT_DETAILS.LABEL.LANDMARK_GROUP}</div>
                                {landmarkGroupSection}
                            </div>
                            <div>
                                {
                                    this.state.landmarks.groups.map(data => (
                                        <Chip
                                            id={data.id}
                                            className={styles['chip-spacing']}
                                            key={data.id}
                                            label={data.name}
                                            onDelete={() => this.handleDelete('groups', data)}
                                        />
                                    ))
                                }
                            </div>
                            <div className={styles['div-pad']}>
                                <div>{ALERT_DETAILS.LABEL.LANDMARK}</div>
                                {landmarksSection}
                            </div>
                            <div>
                                {
                                    this.state.landmarks.landmarks.map(data => (
                                        <Chip
                                            id={data.id}
                                            className={styles['chip-spacing']}
                                            key={data.id ? data.id : data.value}
                                            label={data.name}
                                            onDelete={() => this.handleDelete('landmarks', data)}
                                        />
                                    ))
                                }
                            </div>
                            <div className={styles['div-pad']}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            id="alertArrivalCB"
                                            checked={(this.props.formData && this.props.formData.config &&
                                                this.props.formData.config.alertArrival) ?
                                                this.props.formData.config.alertArrival : false}
                                            onChange={this.props.handleCheckboxChange('alertArrival')}
                                        />
                                    }
                                    label={ALERT_DETAILS.LABEL.ALERT_ON_ARRIVAL}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            id="alertDepartureCB"
                                            checked={(this.props.formData && this.props.formData.config &&
                                                this.props.formData.config.alertDeparture) ?
                                                this.props.formData.config.alertDeparture : false}
                                            onChange={this.props.handleCheckboxChange('alertDeparture')}
                                        />
                                    }
                                    label={ALERT_DETAILS.LABEL.ALERT_ON_DEPARTURE}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(stylesAsset)(LandmarkArrivalDepartureAlert);
