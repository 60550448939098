/* @flow */
import React from 'react';
import { Card, CardHeader, Typography, Tooltip, Avatar, Grid } from '@mui/material';
import GetSvgIcon, { AssetNonReporting } from '../../../util/svgImage_util';
import {
    setCustomAssetStatusStartDate,
    getAssetStatusStartDate,
    getCorrectDistance,
    getDistanceString,
    roundOffUnitValueAsPerUserSetting,
    getSpeedUnitString,
    isPairedAsset,
    isAssetNonReporting,
    getTimeStr,
    getTimeDiff,
    getNonReportingToolTip,
} from '../../../helper-classes/utility-functions';
import { getAssetIconType, getAssetStatusTextColor, getLandmarkOrLocation, hoverIcon, haloEffectTimeout } from '../../../util/map_utils';
import { getAssetColorFromStatus } from './../../../util/trip_util';
import customStyles from './AssetCard.module.scss';
import analytics from '../../../analytics/index';

export type Props = {
    asset: Object,
    appliedFiltersObj?: Object,
    checkPaired: boolean,
    handleClick: Function,
    redirectTo: Function,
    handleDoubleClick?: Function,
    showSpeed: boolean,
    showDistance: boolean,
};

const AssetCard = (props: Props) => {
    const { asset, appliedFiltersObj = {} } = props;
    const iconClr = getAssetColorFromStatus(asset, props.checkPaired);
    const statusTextColor = getAssetStatusTextColor(asset);
    const statusText = asset.status || '';
    const selected = (asset.id === appliedFiltersObj.selectedAsset);
    let isHoverIconCalled = false;
    const isNonReporting = isAssetNonReporting(asset);
    let lastReporting = '';

    if (isNonReporting) {
        lastReporting = asset.lastEventDate ? `last report ${getTimeStr(getTimeDiff(asset.lastEventDate, new Date()))} ago` : 'never reported';
    }

    const handleClusterRedirect = (e: Object, redirectURL: string) => {
        e.preventDefault();
        e.stopPropagation();
        if (redirectURL.includes('landmarkId')) {
            const params = {
                feature: 'Map',
                landmarkName: asset.landmarkName,
            };
            analytics.track('NAVIGATE_LANDMARK_DETAIL', params);
        } else {
            const params = {
                feature: 'Map',
                assetName: asset.name,
                navigatedFrom: 'Link',
            };
            analytics.track('NAVIGATE_ASSET_DETAIL', params);
        }
        props.redirectTo(e, redirectURL);
    };

    const handleDoubleClick = (item: Object) => {
        if (props.handleDoubleClick) {
            props.handleDoubleClick(item);
        }
    };

    const haloEffect = (isHover: boolean) => {
        isHoverIconCalled = isHover;
        hoverIcon(asset, isHover);
    };

    return (
        <div
            className={`${customStyles.asset_card_holder} ${selected ? customStyles.selected_card_holder : ''}`}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            onMouseEnter={() => haloEffectTimeout(() => haloEffect(true))}
            onMouseOver={() => !isHoverIconCalled && haloEffectTimeout(() => haloEffect(true))}
            onFocus={() => {}}
            onMouseLeave={() => haloEffectTimeout(() => haloEffect(false))}
        >
            <Card className={`${customStyles.assetCard} ${selected ? customStyles.selected_card : ''}`}>
                <CardHeader
                    className={customStyles.assetCardHeader}
                    onClick={() => props.handleClick(asset)}
                    onDoubleClick={() => handleDoubleClick(asset)}
                    avatar={
                        <React.Fragment>
                            {isNonReporting &&
                            <React.Fragment>
                                <AssetNonReporting
                                    className={customStyles.tooltip}
                                />
                                <span className={customStyles.tooltipText}>
                                    {getNonReportingToolTip(asset)}
                                </span>
                            </React.Fragment>
                            }
                            <Avatar style={{ backgroundColor: [iconClr], top: '-9px' }}>
                                <GetSvgIcon
                                    type={getAssetIconType(asset.attributes
                                        && asset.attributes.icon)}
                                    fillcolor="#FFF"
                                />
                            </Avatar>
                        </React.Fragment>
                    }
                    title={
                        <React.Fragment>
                            <div className={customStyles['space-between']}>
                                <button
                                    className={customStyles.asset_name_btn}
                                    onClick={e => isPairedAsset(asset) && handleClusterRedirect(e, `/home?assetId=${asset.id}&zoom=true`)}
                                >
                                    <Tooltip title={asset.name || ''} disableFocusListener>
                                        <Typography noWrap className={customStyles.asset_name} style={(asset.noData) && { paddingTop: 9 }} component="h5" variant="h5">
                                            {asset.name || ''}
                                        </Typography>
                                    </Tooltip>
                                </button>
                                <div className={customStyles['ellipsis-text']} style={{ maxWidth: 100 }}>
                                    <Tooltip title={asset.driverName || ''} disableFocusListener>
                                        <span>{asset.driverName || ''}</span>
                                    </Tooltip>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    subheader={
                        <Grid container>
                            <Grid item xs={8}>
                                <Typography
                                    noWrap
                                    className={customStyles.card_subtitle}
                                    variant="body1"
                                    color="textSecondary"
                                >
                                    {isPairedAsset(asset) ?
                                        <React.Fragment>
                                            {isNonReporting ?
                                                <React.Fragment>
                                                    <span style={{ color: [statusTextColor] }}>
                                                        {statusText}
                                                    </span>
                                                    <Tooltip title={lastReporting || ''}>
                                                        <span
                                                            className={
                                                                customStyles.last_reporting_text
                                                            }
                                                        >
                                                            &nbsp;({lastReporting})
                                                        </span>
                                                    </Tooltip>
                                                </React.Fragment>
                                                :
                                                <span style={{ color: [statusTextColor] }}>
                                                    {statusText} {statusText ? setCustomAssetStatusStartDate(getAssetStatusStartDate(asset)) : ''}
                                                </span>
                                            }
                                            <span className={customStyles.asset_landmark}>
                                                {getLandmarkOrLocation(
                                                    asset,
                                                    true,
                                                    handleClusterRedirect,
                                                )}
                                            </span>
                                        </React.Fragment> :
                                        <span>
                                            This asset is not currently <br />
                                            connected to a device
                                        </span>
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className={customStyles.unit}>
                                        {(props.showSpeed && asset.speed != null) &&
                                            <Typography paragraph className={customStyles.speed_dir_txt} align="right" component="p">
                                                {`${roundOffUnitValueAsPerUserSetting(asset.speed || 0)} ${getSpeedUnitString()}`}
                                            </Typography>
                                        }
                                        {(props.showDistance && asset.distance) &&
                                            <Typography paragraph className={customStyles.distance_txt} align="right" component="p">
                                                {(+getCorrectDistance(asset.distance, 'mi').toFixed(1) * 100) / 100} {getDistanceString()} away
                                            </Typography>
                                        }
                                        {props.showSpeed && asset.direction &&
                                            <Typography className={customStyles.speed_dir_txt} align="right" component="p">
                                                &nbsp;{asset.direction}
                                            </Typography>
                                        }
                                    </div>
                                    <div className={customStyles.button}>
                                        <div
                                            className={customStyles.history}
                                            tabIndex={0}
                                            onKeyDown={() => {}}
                                            role="button"
                                            onClick={e => props.redirectTo(e, `/assets/${asset.id}/trips`)}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    }
                />
            </Card>
        </div>
    );
};

AssetCard.defaultProps = {
    handleDoubleClick: () => {},
    appliedFiltersObj: {},
};

export default AssetCard;
