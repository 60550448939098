/* @flow */
import { FETCH_USERS_LIST, FETCH_USERS_LIST_SUCCESS, FETCH_USERS_LIST_ERROR, RESET_USERS_LIST } from './constants.userList';
import type { UserListTypeAction } from './constants.userList';

export const fetchUsersList = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filter: string,
): UserListTypeAction => ({
    type: FETCH_USERS_LIST,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
        filter,
    },
});

export const fetchUsersListSuccess =
    (users: any, totalUsers: number): UserListTypeAction => ({
        type: FETCH_USERS_LIST_SUCCESS,
        payload: { users, totalUsers },
    });

export const fetchUsersListError = (error: string): UserListTypeAction => ({
    type: FETCH_USERS_LIST_ERROR,
    payload: { error },
});

export const resetTable = (): UserListTypeAction => ({
    type: RESET_USERS_LIST,
});
