/* @flow */
import React from 'react';
import customstyles from './TripsStyle.module.scss';
import { convertDateTime, getTimeFormatAsperUserSettting, getLandmarkName } from '../../helper-classes/utility-functions';

export type Props = {
    trip: Object,
};

export type State = {
};

const TripsTimeline = (props: Props) => {
    const {
        startLocation, endLocation, startDate, endDate, type,
    } = props.trip;
    return (
        <ul className={`${customstyles.timeline} ${customstyles.timelineSplit}`}>
            {type !== 'STOPPED' &&
                <li id="start-timeline-address" className={endLocation ? `${customstyles.timelineItem} ${customstyles.timelineFirst}` : `${customstyles.timelineItem}`}>
                    <div className={customstyles.timelineInfo}>
                        <span>{convertDateTime(startDate, (getTimeFormatAsperUserSettting() === '12h') ? 'h:mm A' : 'H:mm')}</span>
                    </div>
                    <div className={`${customstyles.timelineMarker} ${customstyles.green}`} />
                    <div className={customstyles.timelineContent}>
                        {(startLocation.address) &&
                            <React.Fragment>
                                <span className={customstyles['address-line1']}>
                                    {getLandmarkName(startLocation)}
                                    {startLocation.address.line1}{', '}
                                </span>
                                <span className={customstyles['address-state-city']}>
                                    {startLocation.address.city}
                                    , {startLocation.address.state} {startLocation.address.zipCode}
                                </span>
                            </React.Fragment>
                        }
                    </div>
                </li>
            }
            {endLocation ?
                <li id="end-timeline-address" className={customstyles.timelineItem}>
                    <div className={customstyles.timelineInfo}>
                        <span>{convertDateTime(endDate, (getTimeFormatAsperUserSettting() === '12h') ? 'h:mm A' : 'H:mm')}</span>
                    </div>
                    <div className={`${customstyles.timelineMarker} ${customstyles.red}`} />
                    <div className={customstyles.timelineContent}>
                        {(endLocation.address) &&
                            <React.Fragment>
                                <span className={customstyles['address-line1']}>
                                    {getLandmarkName(endLocation)}
                                    {endLocation.address.line1}{', '}
                                </span>
                                <span className={customstyles['address-state-city']}>
                                    {endLocation.address.city}
                                    , {endLocation.address.state} {endLocation.address.zipCode}
                                </span>
                            </React.Fragment>
                        }
                    </div>
                </li>
                :
                ''
            }
        </ul>
    );
};


export default (TripsTimeline);
