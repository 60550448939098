/* @flow */
import { FETCH_SERVICE_PLANS_LIST_ERROR, FETCH_SERVICE_PLANS_LIST_SUCCESS, FETCH_SERVICE_PLANS_LIST, RESET_SERVICE_PLANS_LIST } from './constants.planList';

const initialState = {
    plans: [],
    isUpdating: false,
};

type stateType = {
    plans: Array<Object>,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        plans:{},
        totalPlans: number
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_SERVICE_PLANS_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case RESET_SERVICE_PLANS_LIST:
        return {
            ...state,
            isUpdating: false,
            plans: [],
            totalPlans: 0,
        };
    case FETCH_SERVICE_PLANS_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            plans: action.payload.plans,
            totalPlans: action.payload.totalPlans,
        };
    case FETCH_SERVICE_PLANS_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    default:
        return state;
    }
};
