/* @flow */
import { ALERT } from '../containers/Alerts/constants.alerts';

function getAlertTypeObject(ALERT_TYPE) {
    return {
        code: ALERT_TYPE.CODE,
        name: ALERT_TYPE.NAME,
        description: ALERT_TYPE.DESCRIPTION,
        imageLocation: ALERT_TYPE.IMAGE_LOCATION,
        idPostfix: ALERT_TYPE.ID_POSTFIX,
    };
}

export const mapAlertTypeToCategory = (alertNames: {}) => {
    const AlertTypeList = [];
    const Safety = [];
    const Productivity = [];
    const AssetHealth = [];
    const Monitoring = [];

    if (alertNames != null) {
        Object.keys(alertNames).forEach((key) => {
            const { code } = alertNames[key];
            switch (code) {
            case ALERT.HARD_BRAKING_ACCELERATION_ALERT.CODE:
                Safety[0] = getAlertTypeObject(ALERT.HARD_BRAKING_ACCELERATION_ALERT);
                break;
            case ALERT.ODD_HOURS_ALERT.CODE:
                Safety[1] = getAlertTypeObject(ALERT.ODD_HOURS_ALERT);
                break;
            case ALERT.POSTED_SPEED_LIMIT_ALERT.CODE:
                Safety[2] = getAlertTypeObject(ALERT.POSTED_SPEED_LIMIT_ALERT);
                break;
            case ALERT.SPEED_THRESHOLD_ALERT.CODE:
                Safety[3] = getAlertTypeObject(ALERT.SPEED_THRESHOLD_ALERT);
                break;
            case ALERT.UNAUTHORIZED_MOVEMENT.CODE:
                Safety[4] = getAlertTypeObject(ALERT.UNAUTHORIZED_MOVEMENT);
                break;
            case ALERT.SEATBELT_STATUS_ALERT.CODE:
                Safety[5] = getAlertTypeObject(ALERT.SEATBELT_STATUS_ALERT);
                break;
            case ALERT.IDLE_ALERT.CODE:
                Productivity[0] = getAlertTypeObject(ALERT.IDLE_ALERT);
                break;
            case ALERT.STOP_ALERT.CODE:
                Productivity[1] = getAlertTypeObject(ALERT.STOP_ALERT);
                break;
            case ALERT.INPUT_ALERT.CODE:
                Productivity[2] = getAlertTypeObject(ALERT.INPUT_ALERT);
                break;
            case ALERT.LANDMARK_ARRIVAL_DEPARTURE_ALERT.CODE:
                Productivity[3] = getAlertTypeObject(ALERT.LANDMARK_ARRIVAL_DEPARTURE_ALERT);
                break;
            case ALERT.EXTERNAL_DEVICE_POWER_ON_ALERT.CODE:
                Monitoring[0] = getAlertTypeObject(ALERT.EXTERNAL_DEVICE_POWER_ON_ALERT);
                break;
            case ALERT.DEVICE_POWER_DISCONNECT_ALERT.CODE:
                Monitoring[1] = getAlertTypeObject(ALERT.DEVICE_POWER_DISCONNECT_ALERT);
                break;
            case ALERT.TEMPERATURE_ALERT.CODE:
                Monitoring[2] = getAlertTypeObject(ALERT.TEMPERATURE_ALERT);
                break;
            case ALERT.FUEL_TANK_ALERT.CODE:
                Monitoring[3] = getAlertTypeObject(ALERT.FUEL_TANK_ALERT);
                break;
            case ALERT.DTC_ALERT.CODE:
                AssetHealth[0] = getAlertTypeObject(ALERT.DTC_ALERT);
                break;
            case ALERT.LOW_ASSET_BATTERY_ALERT.CODE:
                AssetHealth[1] = getAlertTypeObject(ALERT.LOW_ASSET_BATTERY_ALERT);
                break;
            // case ALERT.LOW_DEVICE_BATTERY_ALERT.CODE:
            //     AssetHealth[0] = getAlertTypeObject(ALERT.LOW_DEVICE_BATTERY_ALERT);
            //     break;
            case ALERT.TIRE_PRESSURE_ALERT.CODE:
                AssetHealth[2] = getAlertTypeObject(ALERT.TIRE_PRESSURE_ALERT);
                break;
            case ALERT.ENGINE_OIL_LIFE_ALERT.CODE:
                AssetHealth[3] = getAlertTypeObject(ALERT.ENGINE_OIL_LIFE_ALERT);
                break;
            default:
                break;
            }
        });
    }

    if (Safety.length > 0) {
        AlertTypeList.push({
            alertCategory: ALERT.CATEGORY.SAFETY,
            alertCategoryID: ALERT.CATEGORY.SAFETY_ID,
            alertTypes: Safety,
        });
    }
    if (Productivity.length > 0) {
        AlertTypeList.push({
            alertCategory: ALERT.CATEGORY.PRODUCTIVITY,
            alertCategoryID: ALERT.CATEGORY.PRODUCTIVITY_ID,
            alertTypes: Productivity,
        });
    }
    if (Monitoring.length > 0) {
        AlertTypeList.push({
            alertCategory: ALERT.CATEGORY.MONITORING,
            alertCategoryID: ALERT.CATEGORY.MONITORING_ID,
            alertTypes: Monitoring,
        });
    }
    if (AssetHealth.length > 0) {
        AlertTypeList.push({
            alertCategory: ALERT.CATEGORY.ASSET_HEALTH,
            alertCategoryID: ALERT.CATEGORY.ASSET_HEALTH_ID,
            alertTypes: AssetHealth,
        });
    }
    return AlertTypeList;
};

export const filterAlerts = (alertNames: {}) => {
    const filteredAlertList = [];

    if (alertNames != null) {
        Object.keys(alertNames).forEach((key) => {
            const { code, id } = alertNames[key];
            let name = null;
            switch (code) {
            case ALERT.HARD_BRAKING_ACCELERATION_ALERT.CODE:
                name = ALERT.HARD_BRAKING_ACCELERATION_ALERT.NAME;
                break;
            case ALERT.ODD_HOURS_ALERT.CODE:
                name = ALERT.ODD_HOURS_ALERT.NAME;
                break;
            case ALERT.POSTED_SPEED_LIMIT_ALERT.CODE:
                name = ALERT.POSTED_SPEED_LIMIT_ALERT.NAME;
                break;
            case ALERT.SPEED_THRESHOLD_ALERT.CODE:
                name = ALERT.SPEED_THRESHOLD_ALERT.NAME;
                break;
            case ALERT.UNAUTHORIZED_MOVEMENT.CODE:
                name = ALERT.UNAUTHORIZED_MOVEMENT.NAME;
                break;
            case ALERT.SEATBELT_STATUS_ALERT.CODE:
                name = ALERT.SEATBELT_STATUS_ALERT.NAME;
                break;
            case ALERT.IDLE_ALERT.CODE:
                name = ALERT.IDLE_ALERT.NAME;
                break;
            case ALERT.STOP_ALERT.CODE:
                name = ALERT.STOP_ALERT.NAME;
                break;
            case ALERT.INPUT_ALERT.CODE:
                name = ALERT.INPUT_ALERT.NAME;
                break;
            case ALERT.LANDMARK_ARRIVAL_DEPARTURE_ALERT.CODE:
                name = ALERT.LANDMARK_ARRIVAL_DEPARTURE_ALERT.NAME;
                break;
            case ALERT.EXTERNAL_DEVICE_POWER_ON_ALERT.CODE:
                name = ALERT.EXTERNAL_DEVICE_POWER_ON_ALERT.NAME;
                break;
            case ALERT.DEVICE_POWER_DISCONNECT_ALERT.CODE:
                name = ALERT.DEVICE_POWER_DISCONNECT_ALERT.NAME;
                break;
            case ALERT.TEMPERATURE_ALERT.CODE:
                name = ALERT.TEMPERATURE_ALERT.NAME;
                break;
            case ALERT.FUEL_TANK_ALERT.CODE:
                name = ALERT.FUEL_TANK_ALERT.NAME;
                break;
            case ALERT.DTC_ALERT.CODE:
                name = ALERT.DTC_ALERT.NAME;
                break;
            case ALERT.LOW_ASSET_BATTERY_ALERT.CODE:
                name = ALERT.LOW_ASSET_BATTERY_ALERT.NAME;
                break;
            // case ALERT.LOW_DEVICE_BATTERY_ALERT.CODE:
            //     name = ALERT.LOW_DEVICE_BATTERY_ALERT.NAME;
            //     break;
            case ALERT.TIRE_PRESSURE_ALERT.CODE:
                name = ALERT.TIRE_PRESSURE_ALERT.NAME;
                break;
            case ALERT.ENGINE_OIL_LIFE_ALERT.CODE:
                name = ALERT.ENGINE_OIL_LIFE_ALERT.NAME;
                break;
            default:
                break;
            }
            if (name != null) {
                filteredAlertList.push({
                    code,
                    id,
                    name,
                });
            }
        });
    }
    return filteredAlertList;
};

export const getAlertID = (alertList: [], alertType: String) => {
    let alertID = 0;
    const alertObject = alertList.find(alertObj => (alertObj.code === alertType));
    if (alertObject !== undefined && alertObject !== null) {
        alertID = alertObject.id;
    }
    return alertID;
};

export const getAlertType = (alertList: [], alertId: String) => {
    let alertType = '';
    const alertObject = alertList.find(alertObj => (alertObj.id === alertId));
    if (alertObject !== undefined && alertObject !== null) {
        alertType = alertObject.code;
    }
    return alertType;
};

export const filterAlertsCode = (data: any) => {
    if (!data && data.length <= 0) return [];

    return data.map((d) => {
        const alertSaved = Object.assign({}, d);
        if (d.alertTypeId === 146) {
            const alertTypeName = ALERT.EXTERNAL_DEVICE_POWER_ON_ALERT.NAME;
            alertSaved.alertTypeName = alertTypeName;
        }
        return alertSaved;
    });
};
