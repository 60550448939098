/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
    container: {
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 99999,
        marginTop: theme.spacing(),
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
});

export type Props = {
    classes: Object,
    value: string,
    suggestionWidth: string,
    inputProps: Object,
    getSuggestion: Array<Object>,
    onSuggestionClick: Function,
    placeholder: String,
    onRef: Function,
    disabled: boolean,
}

export type State = {
    value: string,
    needToRenderPopper: boolean,
    suggestions: Array<Object>,
    suggestionsData: Array<Object>,
}

class AppAutoSuggestion extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            suggestions: [],
            value: this.props.value,
            needToRenderPopper: Math.random(),
        };
        this.handleChange = this.handleChange.bind(this);
        this.onBlurHandler = this.onBlurHandler.bind(this);
    }

    componentDidMount() {
        if (this.props.onRef && typeof this.props.onRef === 'function') {
            this.props.onRef(this);
        }
    }

    renderInputComponent = (inputProps: any) => {
        const {
            classes,
            inputRef = () => {},
            ref,
            customClasses,
            ...other
        } = inputProps;
        return (
            <TextField
                fullWidth
                InputProps={{
                    inputRef: (node) => {
                        ref(node);
                        inputRef(node);
                    },
                    classes: { input: customClasses || '' },
                }}
                {...other}
            />
        );
    }

    renderSuggestion = (suggestion, { query, isHighlighted }) => {
        const matches = match(suggestion.label, query);
        const parts = parse(suggestion.label, matches);
        return (
            <MenuItem selected={isHighlighted} component="div">
                <div>
                    {parts.map(part => (
                        part.highlight ?
                            <span
                                key={Math.random()}
                            >
                                <b>{part.text}</b>
                            </span> :
                            <span
                                key={Math.random()}
                            >
                                {part.text}
                            </span>
                    ))}
                </div>
            </MenuItem>
        );
    }

    getSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        const { getSuggestion } = this.props;
        const suggestionsData = getSuggestion && typeof getSuggestion === 'function' ? getSuggestion(inputValue) : [];
        let count = 0;
        return inputLength === 0
            ? []
            : suggestionsData.filter((suggestion) => {
                const keep =
                count < 5 && suggestion.label.toLowerCase().indexOf(inputValue) > -1;
                if (keep) {
                    count += 1;
                }
                return keep;
            });
    }

    getSuggestionValue = suggestion => suggestion.label;

    handleSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value),
        });
    }

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleChange = name => (event, { newValue }) => {
        let { needToRenderPopper } = this.state;
        const { suggestions } = this.state;
        let clearSuggestion = false;
        if (!(event.keyCode === 38 || event.keyCode === 40)) {
            needToRenderPopper = Math.random();
            clearSuggestion = true;
        }
        this.setState({
            [name]: newValue,
            suggestions: clearSuggestion ? [] : suggestions,
            needToRenderPopper,
        });
    };


    handleSuggestionsFetchSelected = (event, {
        suggestionValue,
    }) => {
        this.props.onSuggestionClick(event, suggestionValue);
    };

    onBlurHandler = () => {
        this.setState({ value: this.props.value });
    }

    getRenderedSuggestion = () => this.state.suggestions;

    updateInputBox = (value) => {
        this.setState({
            value,
            suggestions: [],
        });
        this.handleSuggestionsFetchRequested({ value });
    }


    render() {
        const {
            anchorEl,
        } = this.state;

        const {
            classes,
            suggestionWidth,
            disabled,
        } = this.props;

        const autosuggestProps = {
            renderInputComponent: this.renderInputComponent,
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue: this.getSuggestionValue,
            renderSuggestion: this.renderSuggestion,
        };

        const inputProps = {
            classes,
            placeholder: this.props.placeholder || '',
            value: this.state.value,
            onChange: this.handleChange('value'),
            onBlur: this.onBlurHandler,
            inputRef: (node) => {
                if (!this.state.anchorEl) this.setState({ anchorEl: node });
            },
        };


        return (
            <Autosuggest
                {...autosuggestProps}
                autoFocus
                onSuggestionSelected={this.handleSuggestionsFetchSelected}
                inputProps={{ ...inputProps, ...this.props.inputProps, disabled }}
                focusInputOnSuggestionClick
                theme={{
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                renderSuggestionsContainer={options => (
                    <Popper
                        anchorEl={anchorEl}
                        open={Boolean(options.children)}
                        key={this.state.needToRenderPopper}
                    >
                        <Paper
                            square
                            {...options.containerProps}
                            style={{ width: !suggestionWidth && anchorEl ? anchorEl.clientWidth : `${suggestionWidth || 'auto'}` }}
                        >
                            {options.children}
                        </Paper>
                    </Popper>
                )}
            />
        );
    }
}

AppAutoSuggestion.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AppAutoSuggestion);
