/* @flow */
import React, { Component } from 'react';
import { Input } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import styles from './GlobalSearch.module.scss';
import { IconWrapper } from '../SideMenu/MenuIcons';
import SearchResults from '../SearchResults/SearchResults';
import { LeftMenuItems, recordsPerPage } from '../../containers/Header/contants.globalsearch';
import type { typeAssets, typeLandmarks, typeDrivers, typeAddresses } from '../../containers/Header/types';
import { getPermissionValue, getSavedPage, isReseller, getResellerInfo } from '../../helper-classes/utility-functions';
import backIcon from '../../assets/icons/back.png';
import SearchBox from '../../components/SharedComponents/SearchBox';
import analytics from '../../analytics';

type Props = {
    globalSearch: Function,
    updateSearchText: Function,
    openMenu: [],
    closeMenu: [],
    isStateOpen:boolean,
    searchData: {
        assets: typeAssets,
        landmarks: typeLandmarks,
        drivers: typeDrivers,
        addresses: typeAddresses,
        tab: number,
    },
    redirectTo: Function,
    selectedTab: Function,
    showResult:? Object,
    redirectToNearest: Function,
};
type State = {
    search: string,
    displayMsg: string,
    textActive: string,
    containerClass: string,
    showSearchBox: boolean,
    tab: number,
    showSearchResults: boolean,
    showLogo: boolean,
};

const minimumChar: number = 3;
let shouldLoadMore: boolean = true;
class GlobalSearch extends Component<Props, State> {
    searchBoxRef: Input;
    globalSearchHandler: Function;
    onFocusHandler: Function;
    valueChange: Function;
    selectedTab: Function;
    showResult: Object;
    headerDivRef: any;
    initialTab: number;
    constructor(props: Props) {
        super(props);
        this.state = {
            search: '',
            displayMsg: '',
            textActive: '',
            containerClass: '',
            showSearchBox: false,
            tab: 0,
            showSearchResults: false,
            showLogo: true,
        };
        this.valueChange = this.valueChange.bind(this);
        this.onFocusHandler = this.onFocusHandler.bind(this);
        this.globalSearchHandler = this.globalSearchHandler.bind(this);
        this.headerDivRef = React.createRef();
        this.initialTab = 0;
    }

    componentDidMount() {
        this.updateInputBox(this.props.showResult);
    }

    valueChange(key: string, event: HTMLInputElement) {
        const { search, displayMsg } = this.state;
        this.setState({
            [key]: event.value,
            showSearchBox: true,
            showSearchResults: true,
            displayMsg: (search.trim().length >= (minimumChar - 1)) ? '' : displayMsg,
        }, () => {
            const { search: latestSearch } = this.state;
            const searchedText = latestSearch.trim();
            if (searchedText.length >= minimumChar) {
                const params = {
                    feature: 'UniversalSearch',
                    searchValue: latestSearch,
                };
                analytics.track('UNIVERSAL_SEARCH', params);
                this.props.globalSearch(latestSearch, 0);
            } else if (searchedText.length < minimumChar) {
                this.props.updateSearchText(searchedText);
            }
        });
    }

    selectedTab(tab: number = 0) {
        this.props.selectedTab(tab);
        this.setState({
            tab,
        });
    }

    updateInputBox(showResult: any) {
        if (showResult && showResult.query.trim().length >= minimumChar) {
            this.setState({
                search: showResult.query,
                showSearchBox: true,
                tab: showResult.tab || 0,
            });
        }
    }

    globalSearchHandler(event: KeyboardEvent) {
        event.preventDefault();
        const { search } = this.state;
        let stateObj = { displayMsg: '', search: '' };
        if (event.keyCode === 13) {
            if (search.trim().length >= minimumChar) {
                stateObj = {
                    displayMsg: '',
                    textActive: styles['txt-active'],
                    showSearchBox: true,
                    showSearchResults: true,
                };
            } else {
                stateObj = {
                    textActive: styles['txt-active'],
                    displayMsg: `Please enter at least ${minimumChar} characters to perform the search.`,
                    showSearchBox: false,
                    showSearchResults: false,
                };
            }
            this.setState(stateObj);
        }
    }

    importScript = (url: string) => {
        const scriptObj = document.createElement('script');
        scriptObj.type = 'text/javascript';
        scriptObj.async = true;
        scriptObj.src = url;
        const headObj = document.getElementsByTagName('head')[0];
        headObj.appendChild(scriptObj);
    }

    onFocusHandler() {
        const { body } = document;
        if (!window.googleMaps && body && window.FLEET_CONFIG) {
            this.importScript(`${window.FLEET_CONFIG.GOOGLE_MAPS_BASE_URL}/js?key=${window.FLEET_CONFIG.GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places&callback=initMap`);
        }
        this.setState(() => ({
            containerClass: styles.focused,
            showSearchBox: true,
            showSearchResults: true,
        }));
    }

    handleClickAway = () => {
        this.setState({ showSearchResults: false });
    }

    onScroll = (e: any) => {
        const { searchData } = this.props;
        const tabs = ['assets', 'landmarks', 'drivers', 'addresses'];
        const nextPageNumber =
            searchData[tabs[searchData.tab]].data.length < searchData[tabs[searchData.tab]].total ?
                searchData[tabs[searchData.tab]].data.length / recordsPerPage
                :
                0;

        const multiplier = window.outerWidth / window.innerWidth > 1 ?
            window.outerWidth / window.innerWidth :
            1;
        if (shouldLoadMore && Math.ceil((multiplier * e.target.scrollTop) + e.target.clientHeight)
            >= e.target.scrollHeight) {
            const elem = e.target;
            shouldLoadMore = false;
            setTimeout(() => {
                elem.scrollTop -= 14;
            }, 800);
            setTimeout(() => {
                shouldLoadMore = true;
            }, 3000);
            this.props.globalSearch(this.state.search, nextPageNumber);
        }
    }

    redirectTo = (url: string) => {
        this.setState({ showSearchBox: false });
        this.props.redirectTo(url);
    }

    redirectToNearest = (key: string, id: string) => {
        this.setState({ showSearchBox: false });
        this.props.redirectToNearest(key, id);
    }

    goBackToCustomUrl = (url: string) => {
        this.props.redirectTo(url);
    }

    getLeftMenuItem = () => {
        let defaultTab = '';
        const leftMenuItems = LeftMenuItems.map((data) => {
            const item = { ...data };
            switch (item.text) {
            case 'Assets':
                item.show = (getPermissionValue('Assets') !== 'None');
                defaultTab = item.show && defaultTab === '' ? '0' : defaultTab;
                break;
            case 'Landmarks':
                item.show = (getPermissionValue('Landmarks') !== 'None');
                defaultTab = item.show && defaultTab === '' ? '1' : defaultTab;
                break;
            case 'Drivers':
                item.show = (getPermissionValue('Drivers') !== 'None');
                defaultTab = item.show && defaultTab === '' ? '2' : defaultTab;
                break;
            case 'Addresses':
                defaultTab = defaultTab === '' ? '3' : defaultTab;
                break;
            default:
                break;
            }
            return item;
        });
        this.initialTab = this.state.tab === 0 && defaultTab !== '' ? Number(defaultTab) : this.state.tab;
        return leftMenuItems;
    }

    getLogoImage = () => {
        if (isReseller()) {
            const resellerInfo = getResellerInfo();
            if (resellerInfo.appLogo) {
                return (<img
                    className={
                        this.state.showLogo ?
                            styles.resellerLogo
                            :
                            styles.hide
                    }
                    src={resellerInfo.appLogo}
                    alt={resellerInfo.brandedTabTitle}
                    onError={() => { this.setState({ showLogo: false }); }}
                />);
            }
            return '';
        }
        return (<IconWrapper
            data-itemid="Logo"
            style={{
                fontSize: 22,
                marginTop: -1,
                marginLeft: -20,
                width: 154,
                height: 'auto',
                position: 'absolute',
            }}
        />);
    }

    render() {
        const {
            search,
            displayMsg,
            textActive,
            containerClass,
            showSearchBox,
            showSearchResults,
        } = this.state;
        const {
            isStateOpen, openMenu, closeMenu, searchData,
        } = this.props;
        const classes = `${styles['search-bar']} ${textActive}`;
        const prevPage = getSavedPage();
        const leftMenuItems = this.getLeftMenuItem();
        return (
            <div
                ref={this.headerDivRef}
                className={`${styles['search-dropdown-holder']} ${containerClass}
                ${(prevPage && prevPage.url) ? styles.blue : styles.grey}`}
            >
                <React.Fragment>
                    {prevPage && prevPage.url ?
                        <div className={styles['back-button-wrapper']}>
                            <div>
                                <button
                                    className={styles['back-button']}
                                    onClick={() => { this.goBackToCustomUrl(prevPage.url); }}
                                >
                                    <img src={backIcon} alt="backArrow" />
                                </button>
                            </div>
                            <div className={styles['back-button-text']}>Back</div>
                        </div>
                        :
                        <React.Fragment>
                            <div className={styles['side-menu-icons']}>{isStateOpen ? closeMenu : openMenu}</div>
                            <div className={styles.company_logo}>
                                <span className={styles.first_txt} style={{ display: 'none' }}>Fleet</span>
                                <span className={styles.second_txt} style={{ display: 'none' }} >Locate</span>
                                {this.getLogoImage()}
                            </div>
                        </React.Fragment>
                    }
                </React.Fragment>
                <ClickAwayListener onClickAway={this.handleClickAway}>
                    <div>
                        <SearchBox
                            data-qa="search-element"
                            inputRef={(input) => {
                                this.searchBoxRef = input;
                                return this.searchBoxRef;
                            }}
                            classes={{
                                focused: ` ${styles['txt-active']}`,
                            }}
                            type="text"
                            placeholder="Search"
                            endAdornment={
                                (displayMsg && (
                                    <div id="search-box-error-container" className={styles['search-box-error-container']}>
                                        <i className={styles['error-icon']} />
                                        <div id="error-message" data-qa="error-message" className={styles['error-msg']}>{displayMsg}</div>
                                    </div>
                                )
                                )}
                            onBlur={() => {
                                this.headerDivRef.current.classList.remove(styles.focused);
                            }}
                            onFocus={() => {
                                if (this.headerDivRef.current) {
                                    this.headerDivRef.current.classList.add(styles.focused);
                                }
                                this.onFocusHandler();
                            }}
                            className={`${classes} ${(prevPage && prevPage.url) ? styles.lightBlue : styles.lightGrey}`}
                            disableUnderline
                            value={search}
                            onChange={e => this.valueChange('search', e.target)}
                            onKeyUp={this.globalSearchHandler}
                            id="search-box"
                            autoComplete="off"
                            sx={{ '& input': { letterSpacing: 0, height: '1.1em', padding: '6px 0px 7px' } }}
                        />
                        {(leftMenuItems
                            && this.state.search.trim().length > 2
                            && leftMenuItems.length > 0
                            && showSearchBox
                            && showSearchResults) &&
                            <SearchResults
                                globalSearchData={leftMenuItems}
                                searchData={searchData}
                                redirectTo={this.redirectTo}
                                redirectToNearest={(key, id) => this.redirectToNearest(key, id)}
                                selectedTab={(tab) => {
                                    this.selectedTab(tab);
                                }}
                                updateTab={this.initialTab}
                                onScroll={this.onScroll}
                            />
                        }
                    </div>
                </ClickAwayListener>
            </div>);
    }
}

export default GlobalSearch;
