/* @flow */
import React, { Component } from 'react';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.trips';
import TripsDetailComponent from '../../components/TripsDetail/TripsDetailComponent';

export type Props = {
    trips: {
        tripEvents: Object,
        trips: Array<Object>,
    },
    assetsDetails: {
        assetId: string,
        assetName: string,
        deviceSerialNumber: string,
        assetFeatures: Object,
        deviceModelCode: string,
    },
    tripDetailIndex: number,
    returnToTripsView: Function,
    fromDate: any,
    toDate: any,
    handleRefresh: Function,
    loadMapDirectionsFromTrips: Function,
    setDrawerWidth: Function,
    setZoomEvent: Function,
    zoomEvent: Object,
    setTripDetailIndex: Function,
};

export type State = {
    isFirstTrip: boolean,
    isLastTrip: boolean,
};

class TripsDetail extends Component<Props, State> {
    static defaultProps = {
        trips: {
            tripEvents: {},
            trips: [{}],
        },
    };
    constructor(props: Props) {
        super(props);
        const filteredTrips = this.filterTrip(this.props.trips.trips);
        const tripIndex = this.props.tripDetailIndex;
        this.state = {
            isFirstTrip: tripIndex === 0,
            isLastTrip: tripIndex === filteredTrips.length - 1,
        };
        this.reloadMap(tripIndex);
    }

    filterTrip = trips => trips.filter(trip => trip.type === 'COMPLETED' || trip.type === 'INPROCESS');

    getPrevTrip = () => {
        const newTripIndex = this.props.tripDetailIndex - 1;
        let isFirstTrip = false;
        if (newTripIndex === 0) {
            isFirstTrip = true;
        }
        if (newTripIndex >= 0) {
            this.setState({ isFirstTrip, isLastTrip: false });
            this.props.setTripDetailIndex(newTripIndex);
            this.props.setZoomEvent();
            this.reloadMap(newTripIndex);
        }
    };

    getNextTrip = () => {
        const filteredTrips = this.filterTrip(this.props.trips.trips);
        const newTripIndex = this.props.tripDetailIndex + 1;
        let isLastTrip = false;
        if (newTripIndex === filteredTrips.length - 1) {
            isLastTrip = true;
        }
        if (newTripIndex <= filteredTrips.length - 1) {
            this.setState({ isLastTrip, isFirstTrip: false });
            this.props.setTripDetailIndex(newTripIndex);
            this.props.setZoomEvent();
            this.reloadMap(newTripIndex);
        }
    };

    reloadMap = (index) => {
        const { trips, loadMapDirectionsFromTrips, setZoomEvent } = this.props;
        const filteredTrips = this.filterTrip(trips.trips);
        const tripId = filteredTrips[index].id;
        setZoomEvent();
        loadMapDirectionsFromTrips([tripId]);
    };

    render() {
        const { tripEvents, trips } = this.props.trips;
        const filteredTrips = this.filterTrip(trips);
        const tripId = filteredTrips[this.props.tripDetailIndex].id;
        const {
            assetName,
            deviceSerialNumber,
            assetFeatures,
            deviceModelCode,
        } = this.props.assetsDetails;
        const eventsInTrip = tripEvents.content.filter(event => event.tripId === tripId);
        const tripData = filteredTrips.filter(trip => trip.id === tripId)[0];
        this.props.setDrawerWidth(700);
        return (
            <TripsDetailComponent
                events={eventsInTrip}
                tripData={tripData}
                assetName={assetName}
                serialNumber={deviceSerialNumber}
                getPrevTrip={this.getPrevTrip}
                getNextTrip={this.getNextTrip}
                isFirstTrip={this.state.isFirstTrip}
                isLastTrip={this.state.isLastTrip}
                returnToTripsView={this.props.returnToTripsView}
                fromDate={this.props.fromDate}
                toDate={this.props.toDate}
                handleRefresh={this.props.handleRefresh}
                setZoomEvent={this.props.setZoomEvent}
                zoomEvent={this.props.zoomEvent}
                assetFeatures={assetFeatures}
                deviceModelCode={deviceModelCode}
            />
        );
    }
}

const mapStateToProps = state => ({
    trips: state.assets.trips,
    assetsDetails: state.assets.assetsDetails,
});

export default reduxConnect(TripsDetail, actions, mapStateToProps);
