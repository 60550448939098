/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_LANDMARKS_LIST } from './constants.landmarksList';
import { fetchLandmarksListError, fetchLandmarksListSuccess } from './actions.landmarksList';
import { getUserToken, convertDateTime } from '../../../helper-classes/utility-functions';
import config from '../../../constants/Config';

type ObservarblesTypes = {
    postJSON: Function,
    of: Function
}

export const formatData = (data: any) => {
    if (!data || (data && data.length <= 0)) return [];

    return data.map((d) => {
        const landmark = Object.assign({}, d);

        if (d.dateCreated) {
            landmark.dateCreated = convertDateTime(d.dateCreated);
        }

        if (d.attributes) {
            landmark.attributes = JSON.stringify(d.attributes).replace(/\{|\}/g, '').replace(/,/g, ', ');
        }

        return landmark;
    });
};

export const getHeader = () => {
    const headers = {
        'X-Nspire-UserToken': getUserToken(),
        'Content-Type': 'application/json',
    };
    return headers;
};

export const getRequestPayload = (payload: Object) => {
    // this function is used many places please check once you modify this function
    const {
        pageNumber,
        pageSize,
        order,
        orderBy,
        filter = {},
    } = payload;
    const data = {
        sortParam: order && orderBy ? [[orderBy, order]] : undefined,
        start: pageNumber * pageSize,
        limit: pageSize,
        filterParam: [],
        searchParams: [''],
    };

    if (orderBy === 'fullAddress') {
        data.sortParam = [['address', order], ['city', order], ['state', order], ['zipCode', order]];
    } else if (orderBy === 'formattedPhoneNumber') {
        data.sortParam = [['phoneNumber', order]];
    }

    if (filter.landmarkFiltersToSend && filter.landmarkFiltersToSend.length > 0) {
        data.filterParam = filter.landmarkFiltersToSend;
    } else {
        delete data.filterParam;
    }

    if (filter.search && filter.search.length > 0 && typeof filter.search === 'string') {
        data.searchParams = [filter.search.replace(/[\s]{2,}/gi, ' ').trim()];
    } else {
        delete data.searchParams;
    }
    return data;
};

export const getURL = () => {
    const url = `${config.get('FLEET_VIEW_SERVICES_URL')}/landmarks`;
    return url;
};

export const getLandmarks =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_LANDMARKS_LIST)
            .distinctUntilChanged()
            .debounceTime(config.get('DEBOUNCE_TIME'))
            .mergeMap((action) => {
                const {
                    pageNumber,
                    pageSize,
                    order,
                    orderBy,
                    filter = {},
                } = action.payload;
                const data = getRequestPayload({
                    pageNumber,
                    pageSize,
                    order,
                    orderBy,
                    filter,
                });
                return postJSON(getURL(), data, getHeader())
                    .map((result) => {
                        const formatedData = formatData(result.response.data);
                        return fetchLandmarksListSuccess(formatedData, result.response.total);
                    })
                    .catch(error => of(fetchLandmarksListError(error)));
            });

export default combineEpics(getLandmarks);
