/* @flow */
import {
    FETCH_ACCOUNT_SETTINGS_SUCCESS,
    FETCH_ACCOUNT_SETTINGS_ERROR,
} from './constants.spudNotification';

export type stateType = {
    spudMessage: string,
    accountSettings: Object,
};

export const initialState = {
    spudMessage: '',
    accountSettings: {},
};

export type actionType = {
    payload: {
        spudMessage: string,
        accountSettings: Object,
    },
    type: string,
};

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_ACCOUNT_SETTINGS_SUCCESS:
        return {
            ...state,
            accountSettings: action.payload.accountSettings,
        };
    case FETCH_ACCOUNT_SETTINGS_ERROR:
        return {
            ...state,
        };
    default:
        return state;
    }
};
