/* @flow */
import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { reduxConnect } from '../../../hoc';
import * as actions from './actions.scoreCardTrips';
import MiniDrawer from '../../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../../components/SharedComponents/ListComponent/ListComponent';
import {
    getParamValue,
    getLocalStorageValue,
    mngClmnBasedonSettings,
    getDriverDropDownDates,
    downloadFile,
    convertDateTime,
    getHeaderKeys,
    getUserSettingTimeZone,
    getDistanceStringLong,
    getTimeFormatAsperUserSettting,
    getMilesDrivenOrScore,
    getDistanceString,
    getSpeedUnitString,
    getScorecardSettingsText,
} from '../../../helper-classes/utility-functions';
import {
    calculateTimeInHrs,
    getConvertedStartDateValue,
    getConvertedEndDateValue,
    getDateRangeDropdownValue,
} from '../../../util/trip_util';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    showHideColumns,
    rearrangeColumns, isUpdatePreference,
} from '../../../helper-classes/listUtil';
import { getTitle, getSplittedHeader } from './../../../helper-classes/utility-react-functions';
import { getDriverTripsApiUrl } from './epics.scoreCardTrips';
import AppLoader from '../../../components/AppLoader';
import * as settingActions from '../Settings/actions.driverSettings';
import analytics from '../../../analytics/index';
import { FETCH_DRIVER_SCORECARD_TRIPS, TRIP_CENTRIC_SCORECARD_PREF_KEY } from './constants.scoreCardTrips';
import { RECORDS_PER_PAGE } from '../../../constants/Config';

export type Props = {
    loading: boolean,
    driverScorecardTrips: Object,
    listType: string,
    fetchDriverScorecardTrips: Function,
    resetTable: Function,
    history: {
        push: Function,
    },
    location: any,
    driverSettings: Object,
    fetchDriverSettings: Function,
};

export type State = {
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{}>,
        totalRecords: number,
    },
    pageNumber: number,
    loading: boolean,
    rowsPerPage: number,
    order: string,
    orderBy: string,
    filters: Object,
    showDialogbox: true,
    openExport: boolean,
    selectedOption: Object,
    showListing: boolean,
};

class ScoreCardTrips extends Component<Props, State> {
    reportingRange: Object;
    selectedReportingDays: string;
    isUIHaveDriverID: boolean;
    isInitialRender: boolean;
    iframeUserToken: string;
    isIframe: boolean;
    userPreferences: any;
    constructor(props: Props) {
        super(props);
        this.selectedReportingDays = getLocalStorageValue('driverFilter') || '30';
        const driverCustomDateFilter = JSON.parse(getLocalStorageValue('driverCustomDateFilter') || '{"endDate":null,"startDate":null}');
        this.reportingRange = getDriverDropDownDates(this.selectedReportingDays);
        if (this.selectedReportingDays === 'custom') {
            this.reportingRange = driverCustomDateFilter;
        }
        this.isIframe = this.props.location.pathname.match(/^\/iframe/i);
        this.state = {
            showListing: false,
            selectedOption: {
                lable: 'CSV',
                value: 'csv',
                key: 'CSV',
            },
            openExport: false,
            tableData: {
                ischeckBox: false,
                isGroup: false,
                listHeaders: this.getListHeader(this.props.driverSettings),
                listData: [],
                totalRecords: 0,
            },
            pageNumber: 0,
            rowsPerPage: RECORDS_PER_PAGE,
            order: 'desc',
            loading: false,
            orderBy: 'startDate',
            filters: {
                vehicleId: getParamValue(this.props, 'vehicleId'),
                name: 'default',
                startDate: getConvertedStartDateValue(this.reportingRange.startDate),
                endDate: getConvertedEndDateValue(this.reportingRange.endDate),
                searchParam: '',
            },
            showDialogbox: true,
        };

        if (getParamValue(this.props, 'driverId') === undefined) {
            this.isUIHaveDriverID = false;
        } else {
            this.state.filters.driverId = getParamValue(this.props, 'driverId');
            this.isUIHaveDriverID = true;
        }
        this.isInitialRender = false;
        this.userPreferences = null;
    }

    UNSAFE_componentWillMount() {
        getUserPreference(this, TRIP_CENTRIC_SCORECARD_PREF_KEY);
        const iframeUsertoken = localStorage.getItem('iframeUserToken');
        if (iframeUsertoken) {
            this.iframeUserToken = iframeUsertoken;
        }
        if (Object.keys(this.props.driverSettings).length === 0) {
            const dUrlConfig = {
                id: getLocalStorageValue('currentAccountGlobalId') || '',
                name: 'default', // for future uses
            };
            this.props.fetchDriverSettings(dUrlConfig);
        }
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (JSON.stringify(this.props.driverSettings)
            !== JSON.stringify(nextprops.driverSettings)) {
            const { tableData } = this.state;
            tableData.listHeaders = this.getListHeader(nextprops.driverSettings);
            this.setState({ tableData });
        }

        if (this.props.listType !== nextprops.listType) {
            if (nextprops.listType === 'ScoreDriver') {
                const {
                    pageNumber, rowsPerPage, order, orderBy, filters,
                } = this.state;
                this.props.fetchDriverScorecardTrips(
                    pageNumber, rowsPerPage,
                    order, this.changeTableColumnConditionally(orderBy), filters,
                );
            }
        }

        if (nextprops.driverScorecardTrips.content) {
            const { tableData } = this.state;
            if (nextprops.driverScorecardTrips.content.length > 0) {
                const { driverScorecardTrips } = nextprops;
                tableData.listData = driverScorecardTrips.content;
                tableData.totalRecords = driverScorecardTrips.total;
            } else {
                tableData.listData = [];
                tableData.totalRecords = 0;
            }
            this.setState({ tableData });
        }
    }

    getData = () => {
        const { pageNumber, rowsPerPage } = this.state;
        this.props.fetchDriverScorecardTrips(
            pageNumber,
            rowsPerPage,
            this.state.order,
            this.changeTableColumnConditionally(this.state.orderBy),
            this.state.filters,
        );
        this.setState({ showListing: true });
    }

    getListHeader = (propsData) => {
        let driverSettings = {};
        if (Object.keys(propsData).length > 0 && !propsData.settings) {
            driverSettings = { ...propsData.driverSettings };
        } else {
            driverSettings = propsData;
        }
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [{
            startDate: 'Trip Start',
            ...getColumnPreferences('startDate', preferences),
            showInDropDown: getColumnPreferences('startDate', preferences),
            customMarkup: (d) => {
                const timeFormat = (getTimeFormatAsperUserSettting() === '24h') ? 'MMM D, YYYY HH:mm' : 'MMM D, YYYY hh:mm A';
                let prependUrl = '';
                if (this.isIframe) {
                    prependUrl = '/iframe';
                }
                const link = (
                    <Link
                        to={`${prependUrl}/driver-scorecard/trip/${d.tripId}/${d.startDate}/${d.endDate}/${this.state.filters.startDate}/${this.state.filters.endDate}`}
                        style={{ color: 'inherit' }}
                        onClick={() => {
                            analytics.track('TRIP_START_LINK', { feature: 'DriverScorecard' });
                        }}
                    >
                        {convertDateTime(d.startDate, timeFormat)}
                    </Link>
                );
                return link;
            },
            style: {
                minWidth: 140,
            },
        },
        {
            endDate: 'Trip End',
            ...getColumnPreferences('endDate', preferences),
            showInDropDown: getColumnPreferences('endDate', preferences),
            customMarkup: (d) => {
                const timeFormat = (getTimeFormatAsperUserSettting() === '24h') ? 'MMM D, YYYY HH:mm' : 'MMM D, YYYY hh:mm A';
                return convertDateTime(d.endDate, timeFormat);
            },
            style: {
                minWidth: 140,
            },
        },
        {
            ...getSplittedHeader(`Miles driven (${getDistanceString()})`, '', 'milesDriven'),
            ...getColumnPreferences('milesDriven', preferences),
            showInDropDown: getColumnPreferences('milesDriven', preferences),
            customMarkup: d => getMilesDrivenOrScore(d, 'milesDriven'),
        },
        {
            driveTime: 'Drive Time',
            ...getColumnPreferences('driveTime', preferences),
            showInDropDown: getColumnPreferences('driveTime', preferences),
            customMarkup: d => (d.driveTime !== null ? calculateTimeInHrs(d.driveTime) : 'N/A'),
        },
        {
            locationBegin: 'Start Location',
            ...getColumnPreferences('locationBegin', preferences),
            showInDropDown: getColumnPreferences('locationBegin', preferences),
            customMarkup: (d) => {
                let locationBegin = '';
                if (Object.keys(d.locationBegin).length > 0) {
                    locationBegin = `${(d.locationBegin.line1 || '')}, ${(d.locationBegin.city || '')} ${(d.locationBegin.state || '')} ${(d.locationBegin.zipCode || '')}`;
                }
                return locationBegin;
            },
        },
        {
            locationEnd: 'End Location',
            ...getColumnPreferences('locationEnd', preferences),
            showInDropDown: getColumnPreferences('locationEnd', preferences),
            customMarkup: (d) => {
                let locationEnd = '';
                if (Object.keys(d.locationEnd).length > 0) {
                    locationEnd = `${(d.locationEnd.line1 || '')}, ${(d.locationEnd.city || '')} ${(d.locationEnd.state || '')} ${(d.locationEnd.zipCode || '')}`;
                }
                return locationEnd;
            },
        },
        {
            ...getSplittedHeader(
                `Speed Threshold (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-speed-threshold', 'est71To80')})`,
                'est71To80',
            ),
            ...getColumnPreferences('est71To80', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'est71To80') : false }),
            showInDropDown: false,
            customMarkup: data => (data.est71To80 !== null ? data.est71To80 : 'N/A'),
        },
        {
            ...getSplittedHeader(
                `Speed Threshold (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-speed-threshold', 'est81To90')})`,
                'est81To90',
            ),
            ...getColumnPreferences('est81To90', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'est81To90') : false }),
            showInDropDown: false,
            customMarkup: data => (data.est81To90 !== null ? data.est81To90 : 'N/A'),
        },
        {
            ...getSplittedHeader(
                `Speed Threshold (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-speed-threshold', 'estGt90')})`,
                'estGt90',
            ),
            ...getColumnPreferences('estGt90', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'estGt90') : false }),
            showInDropDown: false,
            customMarkup: data => (data.estGt90 !== null ? data.estGt90 : 'N/A'),
        },
        {
            ...getSplittedHeader(
                `Posted Speed (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-posted-speed', 'eps10To20')})`,
                'eps10To20',
            ),
            ...getColumnPreferences('eps10To20', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'eps10To20') : false }),
            showInDropDown: false,
            customMarkup: data => (data.eps10To20 !== null ? data.eps10To20 : 'N/A'),
        },
        {
            ...getSplittedHeader(
                `Posted Speed (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-posted-speed', 'eps21To30')})`,
                'eps21To30',
            ),
            ...getColumnPreferences('eps21To30', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'eps21To30') : false }),
            showInDropDown: false,
            customMarkup: data => (data.eps21To30 !== null ? data.eps21To30 : 'N/A'),
        },
        {
            ...getSplittedHeader(
                `Posted Speed (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-posted-speed', 'epsGt30')})`,
                'epsGt30',
            ),
            ...getColumnPreferences('epsGt30', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'epsGt30') : false }),
            showInDropDown: false,
            customMarkup: data => (data.epsGt30 !== null ? data.epsGt30 : 'N/A'),
        },
        {
            ...getSplittedHeader('Idle Time (min)', `(${getScorecardSettingsText('idling', 'idle6To18')})`, 'idle6To18'),
            ...getColumnPreferences('idle6To18', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'idle6To18') : false }),
            showInDropDown: false,
            customMarkup: data => (data.idle6To18 !== null ? data.idle6To18 : 'N/A'),
        },
        {
            ...getSplittedHeader('Idle Time (min)', `(${getScorecardSettingsText('idling', 'idle19To30')})`, 'idle18To30'),
            ...getColumnPreferences('idle18To30', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'idle19To30') : false }),
            showInDropDown: false,
            customMarkup: data => (data.idle18To30 !== null ? data.idle18To30 : 'N/A'),
        },
        {
            ...getSplittedHeader('Idle Time (min)', `(${getScorecardSettingsText('idling', 'idleGt30')})`, 'idleGt30'),
            ...getColumnPreferences('idleGt30', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'idleGt30') : false }),
            showInDropDown: false,
            customMarkup: data => (data.idleGt30 !== null ? data.idleGt30 : 'N/A'),
        },
        {
            hardBrake: 'Hard Braking',
            ...getColumnPreferences('hardBrake', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'hardBrake') : false }),
            showInDropDown: false,
            customMarkup: data => (data.hardBrake !== null ? data.hardBrake : 'N/A'),
        },
        {
            ...getSplittedHeader('Hard Acceleration', '', 'hardAccel'),
            ...getColumnPreferences('hardAccel', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'hardAccel') : false }),
            showInDropDown: false,
            customMarkup: data => (data.hardAccel !== null ? data.hardAccel : 'N/A'),
        },
        ];
        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    sortTableColumn = (order, orderBy, page) => {
        this.props.fetchDriverScorecardTrips(
            page.pageNumber,
            page.rowsPerPage,
            order,
            this.changeTableColumnConditionally(orderBy),
            this.state.filters,
        );
        this.setState({
            order,
            orderBy,
        }, () => {
            saveUserPreference(this, TRIP_CENTRIC_SCORECARD_PREF_KEY);
        });
        const params = {
            feature: 'DriverScorecard',
            sortColumn: orderBy,
            viewBy: 'Trip',
        };
        analytics.track('DRIVER_SCORECARD_SORT', params);
    }

    loadNextPage = (pageNumber, rowsPerPage, order, orderBy) => {
        const updateUserPref = isUpdatePreference(this, { rowsPerPage, order, orderBy });
        this.setState({
            pageNumber,
            rowsPerPage,
            order,
            orderBy,
        }, () => {
            if (updateUserPref) {
                saveUserPreference(this, TRIP_CENTRIC_SCORECARD_PREF_KEY);
            }
        });
        this.props.fetchDriverScorecardTrips(
            pageNumber,
            rowsPerPage,
            order, this.changeTableColumnConditionally(orderBy),
            this.state.filters,
        );
    }

    reportingRangeChange = (value, filterValue) => {
        this.selectedReportingDays = value;
        const filters = Object.assign({}, this.state.filters);
        filters.startDate = getConvertedStartDateValue(filterValue.startDate);
        filters.endDate = getConvertedEndDateValue(filterValue.endDate);
        localStorage.setItem('driverFilter', value);
        this.setState({ filters, pageNumber: 0 }, () => {
            const {
                rowsPerPage, order, orderBy,
            } = this.state;
            this.props.fetchDriverScorecardTrips(
                0, rowsPerPage, order,
                this.changeTableColumnConditionally(orderBy), filters,
            );
        });
        const params = {
            feature: 'DriverScorecard',
            range: getDateRangeDropdownValue(value) || value,
        };
        analytics.track('TRIP_REPORTING_RANGE', params);
    }

    changeTableColumnConditionally = (orderBy) => {
        if (orderBy === 'locationBegin' || orderBy === 'locationEnd') {
            return `${orderBy}.line1`;
        }
        return orderBy;
    }

    dialogHandleClick = (type, value) => {
        if (type === 'show') {
            this.setState({ selectedOption: value });
        } else if (type === 'download') {
            this.openExport(false);
            const {
                tableData,
                order,
                orderBy,
                selectedOption,
            } = this.state;
            const params = {
                feature: 'DriverScorecard',
                exportType: value.value,
            };
            analytics.track('TRIP_DOWNLOAD', params);
            const url = getDriverTripsApiUrl({
                payload: {
                    pageNumber: 0,
                    pageSize: tableData.totalRecords,
                    order,
                    orderBy: this.changeTableColumnConditionally(orderBy),
                    filters: this.state.filters,
                },
                type: FETCH_DRIVER_SCORECARD_TRIPS,
            });
            this.setState({ loading: true });
            const header = getHeaderKeys(tableData.listHeaders);
            let timeFormat = getTimeFormatAsperUserSettting();
            timeFormat = (timeFormat === '24h') ? '24' : '12';
            downloadFile(
                `${url}&timeZone=${getUserSettingTimeZone()}&unitMeasurement=${getDistanceStringLong()}&reportType=${selectedOption.value}&reportColumns=${header.join(',')}&timeFormat=${timeFormat}`,
                `Vehicles ${moment().format('YYYY-MM-DD HH-mm-ss')}.${selectedOption.value}`,
                { type: selectedOption.value },
                () => this.setState({ loading: false }),
            );
        } else {
            this.openExport(false);
        }
    }

    openExport = (openExport) => {
        this.setState({ openExport });
    }

    listTypeContextAction = (type) => {
        switch (type) {
        case 'export': this.openExport(true);
            break;
        default: break;
        }
    }

    getListTemplate = (driverScorecardTrips, selectedOption, openExport, isIframe) => (
        this.state.showListing ?
            <ListComponent
                redirectTo={this.props.history.push}
                tableData={this.state.tableData}
                loadNextPage={this.loadNextPage}
                isUpdating={this.props.loading}
                rearrangeColumns={this.rearrangeColumns}
                resetTable={this.props.resetTable}
                showHideColumn={this.showHideColumn}
                sortTableColumn={this.sortTableColumn}
                showDialogbox={this.state.showDialogbox}
                tableTitle="Driver Scorecard"
                colSpan={8}
                tableSubTitle={` - ${driverScorecardTrips.content.length > 0 ? `${this.isUIHaveDriverID ? `${driverScorecardTrips.content[0].operatorFirstName} ${driverScorecardTrips.content[0].operatorLastName} ` : ''}(${driverScorecardTrips.content[0].assetName})` : '#'}`}
                showToolbarBackButton
                tableButtons={['Export']}
                dialogboxInfo={{
                    title: <span>Export Trips data as {selectedOption.lable} for {(driverScorecardTrips.content.length > 0 && driverScorecardTrips.content[0].assetName) ? getTitle(driverScorecardTrips.content[0].assetName) : 'Vehicle'}</span>,
                    dialogHandleClick: this.dialogHandleClick,
                    showDialog: openExport,
                    selectedOption,
                }}
                showDropdown
                resizableHeader
                showSearch={false}
                headerDropDown={{
                    showDropDown: true,
                    onChange: this.reportingRangeChange,
                    selectedValue: this.selectedReportingDays,
                }}
                listTypeContextAction={this.listTypeContextAction}
                language={{
                    emptyTable: 'There are no trips for this vehicle in the reporting range.',
                }}
                isIframe={isIframe}
                orderBy={this.state.orderBy}
                order={this.state.order}
                rowsPerPage={this.state.rowsPerPage}
            />
            :
            <div style={{ height: '100%' }}>
                <AppLoader type="fullScreen" />
            </div>
    );

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: TRIP_CENTRIC_SCORECARD_PREF_KEY };
        rearrangeColumns(this, listHeaders, options);
    }

    showHideColumn = (header) => {
        const options = { prefKey: TRIP_CENTRIC_SCORECARD_PREF_KEY };
        showHideColumns(this, header, options);
        let colName = '';
        if (header.reportColumnTitle) {
            colName = header.reportColumnTitle;
        } else if (header.startDate) {
            colName = 'Trip Start';
        } else if (header.endDate) {
            colName = 'Trip End';
        } else if (header.driveTime) {
            colName = 'Drive Time';
        } else if (header.locationBegin) {
            colName = 'Start Location';
        } else if (header.locationEnd) {
            colName = 'End Location';
        }
        const params = {
            feature: 'DriverScorecard',
            selectedColumn: colName,
            showColumn: header.show,
        };
        analytics.track('HIDE_SHOW_TRIPS_COLUMN', params);
    }

    render() {
        const { driverScorecardTrips } = this.props;
        const { selectedOption, openExport, loading } = this.state;
        return (
            <React.Fragment>
                {loading && <AppLoader type="fullScreen" />}
                <React.Fragment>
                    {this.isIframe ?
                        this.getListTemplate(
                            driverScorecardTrips,
                            selectedOption,
                            openExport,
                            true,
                        )
                        :
                        <MiniDrawer
                            redirectTo={this.props.history.push}
                        >
                            {this.getListTemplate(
                                driverScorecardTrips,
                                selectedOption,
                                openExport,
                                false,
                            )}
                        </MiniDrawer>
                    }
                </React.Fragment>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    driverSettings: state.driverSettings.driverSettings,
    driverScorecardTrips: state.driverScorecardTrips.driverScorecardTrips,
    loading: state.driverScorecardTrips.loading,
});

const mapDispatchToProps = {
    ...actions,
    ...settingActions,
};

export default reduxConnect(ScoreCardTrips, mapDispatchToProps, mapStateToProps);
