/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_ASSETGROUP, PAGE_SIZE, SEARCH_ASSETGROUP } from './constants.assetgroup';
import { fetchAssetGroupSuccess, fetchAssetGroupError, searchAssetGroupSuccess, searchAssetGroupError } from './actions.assetgroup';
import config from '../../constants/Config';

type ObservarblesTypes = {
    getJSON: Function,
    of: Function,
    postJSON: Function,
}

const foundDataInStore = (storeData, id, of) => {
    let dataFound = false;
    let dataPosition = 0;
    dataFound = storeData.assetGroup.assetGroup.data.some((d, index) => {
        if (d.id === id) {
            dataFound = true;
            dataPosition = index;
        }
        return dataFound;
    });
    return (!dataFound) ? null : of({
        data: [storeData
            .assetGroup.assetGroup.data[dataPosition]],
    });
};

const formatData = data => ({
    data: [{
        id: data.id || '',
        name: data.name || '',
        type: data.type || '',
        accountId: data.accountId || '',
        assetGroupId: data.assetGroupId || '',
        assetGroupName: data.assetGroupName || '',
        status: data.status || '',
        deviceSerialNumber: data.deviceSerialNumber || '',
        statusStartDate: data.statusStartDate || '',
        lastLocation: {
            x: (data.lastLocation.x) ? data.lastLocation.x : 0,
            y: (data.lastLocation.y) ? data.lastLocation.y : 0,
        },
    }],
});

let assetGroupData = {};

export const getAssetGroup =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_ASSETGROUP)
            .mergeMap((action) => {
                const storeData = store.getState();
                const headers = {
                    'X-Nspire-UserToken': store.getState().userSession.userToken,
                    'Content-Type': 'application/json',
                };
                const { id, pageNumber } = action.payload;
                const assetGroupDataFromStore = (storeData.assetGroup
                    && storeData.assetGroup.assetGroup
                    && storeData.assetGroup.assetGroup.data
                    && storeData.assetGroup.assetGroup.data.length > 0)
                    ? foundDataInStore(store.getState(), id, of) : null;
                if (!assetGroupDataFromStore) { // make the api call
                    return getJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/assetgroups/${id}/assets?start=${pageNumber}&limit=${PAGE_SIZE}&cb=${new Date().getTime()}`, headers)
                        .map(result => fetchAssetGroupSuccess(result))
                        .takeUntil(actions$.ofType(FETCH_ASSETGROUP))
                        .retry(2)
                        .catch(error => of(fetchAssetGroupError(error)));
                }
                // take from the store
                return assetGroupDataFromStore.mergeMap((res) => {
                    if (!res.data) {
                        assetGroupData = formatData({});
                    } else {
                        assetGroupData = formatData(res.data[0]);
                    }
                    return [assetGroupData];
                }).map(() => fetchAssetGroupSuccess(assetGroupData))
                    .catch(error => of(fetchAssetGroupError(error)));
            });

export const searchAssetGroup =
    (actions$: Function, store: Object, { getJSON, of, postJSON }: ObservarblesTypes) =>
        actions$
            .ofType(SEARCH_ASSETGROUP)
            .distinctUntilChanged()
            .debounceTime(1000)
            .mergeMap((actions) => {
                const {
                    payload: {
                        search, pageNumber, id, filters,
                    },
                } = actions;
                const headers = {
                    'X-Nspire-UserToken': store.getState().userSession.userToken,
                    'Content-Type': 'application/json',
                };
                if (filters) {
                    return postJSON(
                        `${config.get('FLEET_VIEW_SERVICES_URL')}/assets?cb=${new Date().getTime()}`,
                        filters,
                        headers,
                    )
                        .map(result => searchAssetGroupSuccess(result))
                        .takeUntil(actions$.ofType(SEARCH_ASSETGROUP))
                        .retry(2)
                        .catch(error => of(searchAssetGroupError(error)));
                }
                return getJSON(
                    `${config.get('FLEET_VIEW_SERVICES_URL')}/assetgroups/${id}/assets?search=${search}&start=${pageNumber}&limit=${PAGE_SIZE}&cb=${new Date().getTime()}`,
                    headers,
                )
                    .map(result => searchAssetGroupSuccess(result))
                    .takeUntil(actions$.ofType(SEARCH_ASSETGROUP))
                    .retry(2)
                    .catch(error => of(searchAssetGroupError(error)));
            });

export default combineEpics(getAssetGroup, searchAssetGroup);
