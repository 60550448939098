/* @flow */

const mapColor = {
    landmark: {
        fillColor: '#a7c5e7',
        strokeColor: '#02a9f4',
        fillOpacity: '0.5',
        icon: '#009688',
        group: ['#f5a623', '#ff5722', '#bd10e0', '#03a9f4', '#9b9b9b', '#ff4081', '#9013fe', '#2cc5b4'],
    },
    icon: '#ff5252',
    asset: {
        icon: '#009688',
        group: ['#f5a623', '#ff5722', '#bd10e0', '#03a9f4', '#9b9b9b', '#ff4081', '#9013fe', '#2cc5b4'],
    },
    cluster: {
        moving: '#7ed321',
        idle: '#f5a623',
        stopped: '#fc0207',
        notreporting: '#9E9E9E',
        landmark: 'rgb(255, 82, 82)',
        landmarkColor: '#6700c2',
    },
    statusText: {
        moving: '#689f38',
        idle: '#f5a623',
        stopped: '#d32f2f',
        notreporting: '#000000a3',
    },
};

const RESIZE_TO_LANDMARKS = 'Resize to Landmarks';
const RESIZE_TO_ASSETS = 'Resize to Assets';
const HALO_EFFECT_ZINDEX = 1000;
const HALO_EFFECT_TIMEOUT = 500;
const FLEET_LOCATE_BOOKMARK_KEY = 'bookmark';
const FLEET_LOCATE_MAP_CONFIG_KEY = 'fleetLocate.mapConfig';

const coordinate = { lat: 33.706689, lng: -117.83822572 };

module.exports = {
    mapColor,
    coordinate,
    RESIZE_TO_LANDMARKS,
    RESIZE_TO_ASSETS,
    HALO_EFFECT_ZINDEX,
    HALO_EFFECT_TIMEOUT,
    FLEET_LOCATE_BOOKMARK_KEY,
    FLEET_LOCATE_MAP_CONFIG_KEY,
};
