/* @flow */
import React from 'react';
import { Grid, Button } from '@mui/material';
import customStyles from './ImportComponent.module.scss';
import { LANDMARK_LAT_LNG, LANDMARK_LAT_LNG_TEMPLATE_NAME } from './constants.import';

export type Props = {
    downloadTemplate: Function,
};

class LandmarkLatLngImportFormComponent extends React.PureComponent<Props> {
    render() {
        return (
            <Grid container justifyContent="flex-start" spacing={1} style={{ marginBottom: 130 }}>
                <Grid item xs={12} style={{ margin: '18px 0' }}>
                    <h2 className={customStyles.header}>Lat/Long</h2>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={() => this.props.downloadTemplate(LANDMARK_LAT_LNG, LANDMARK_LAT_LNG_TEMPLATE_NAME)}>Download Template</Button>
                </Grid>
                <Grid item xs={12} style={{ margin: '18px 0' }}>
                    <div>
                        <p>Step 1: Download template</p>
                        <p>Step 2: Check the below requirements. Headings must follow order
                            in template. Commas are not allowed in the data.
                            Column headers are mandatory and may not be moved.
                            If you wish to delete a field value from landmark,
                            enter the value DELETE and it will be deleted.
                            If the value is left empty, no change will occur.
                        </p>
                        <p>Step 3: Add rows of data and save csv</p>
                        <p>Step 4: Select csv file and upload</p>
                    </div>
                    <div className={customStyles.highlight}>
                        <div className={customStyles.subHeader}>
                            Importing a Latitude and Longitude .CSV
                        </div>
                        Import files must
                        have the same header name rows as the import template shows.
                    </div>
                    <div>
                        <div className={customStyles.subHeader}>Required:</div>
                        <ul>
                            <li>
                                Column A&nbsp;: Landmark Name - Required
                            </li>
                            <li>
                                Column B&nbsp;: Landmark Group - Optional
                            </li>
                            <li>
                                Column C&nbsp;:
                                Comments (up to 50 characters) - Optional
                            </li>
                            <li>
                                Column D&nbsp;: Phone Number
                                (enter without commas, enter as 10 digit value) - Optional
                            </li>
                            <li>
                                Column E&nbsp;: Fill color Color in HEX - optional
                                (user can provide color to ensure LM is automatically colored
                                on import)
                            </li>
                            <li>
                                Column F&nbsp;: Line color Color in HEX - optional
                                (user can provide color to ensure LM is automatically colored
                                on import)
                            </li>
                            <li>
                                Column G&nbsp;: Transparency as % for fill color
                                - optional (user can provide transparency in percentage number to
                                ensure LM is automatically given transparency for fill color
                                on import)
                            </li>
                            <li>
                                Column H&nbsp;: Landmark Icon
                                (if not provided, will default to Diamond White) - Optional
                            </li>
                            <li>Column I&nbsp;:
                                Import Type (SETBYLATLONG required for each row) - Required
                            </li>
                            <li>Column J&nbsp;: Latitude (enter without commas) - Required</li>
                            <li>Column K&nbsp;&nbsp;&nbsp;: Longitude (enter without commas)
                                - Required
                            </li>
                            <li>Column L&nbsp;: Radius in meters
                                (enter without commas, enter up to a 5 digit value) - Required
                            </li>
                        </ul>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default LandmarkLatLngImportFormComponent;
