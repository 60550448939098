/* @flow */

import { FETCH_MAINTENANCE_STATUS, FETCH_MAINTENANCE_STATUS_SUCCESS, FETCH_MAINTENANCE_STATUS_ERROR, RESET_MAINTENANCE_STATUS } from './constants.maintenanceStatus';
import type { MaintenanceStatusTypeAction } from './constants.maintenanceStatus';

export const fetchMaintenanceStatus = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filtersToSend: Array<{}>,
): MaintenanceStatusTypeAction => ({
    type: FETCH_MAINTENANCE_STATUS,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
        filtersToSend,
    },
});

export const fetchMaintenanceStatusSuccess =
    (statuses: any, totalRecords: number): MaintenanceStatusTypeAction => ({
        type: FETCH_MAINTENANCE_STATUS_SUCCESS,
        payload: { statuses, totalRecords },
    });

export const resetTable =
    (): MaintenanceStatusTypeAction => ({
        type: RESET_MAINTENANCE_STATUS,
    });

export const fetchMaintenanceStatusError = (error: string): MaintenanceStatusTypeAction => ({
    type: FETCH_MAINTENANCE_STATUS_ERROR,
    payload: { error },
});

