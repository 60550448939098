/* eslint-disable */
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import styles from '../../Steppers/AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';

type Props = {
    formData: {
        config: {
            alertOverInflationDaysCheck: Boolean,
            alertUnderInflationDaysCheck: Boolean,
            realert: Boolean,
            realertDays: Number,
        },
    },
    handleTextboxChange: Function,
    handleCheckboxChange: Function,
};

class TirePressureAlert extends React.Component<Props> {
    state = {};

    render() {
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    <div className={styles['step-rightside']}>
                        <form noValidate autoComplete="off">
                            <div>
                                <div>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    id="alertOverInflationDaysCheck"
                                                    name="alertOverInflationDaysCheck"
                                                    value="alertOverInflationDaysCheck"
                                                    checked={
                                                        this.props.formData.config
                                                            .alertOverInflationDaysCheck === true
                                                    }
                                                    onChange={
                                                        this.props
                                                            .handleCheckboxChange('alertOverInflationDaysCheck')
                                                    }
                                                />
                                            }
                                            label={ALERT_DETAILS.LABEL.TIRE_PRESSURE_OVER}
                                        />
                                    </div>
                                    <div className={styles['text-op']}>
                                        {ALERT_DETAILS.LABEL.TIRE_PRESSURE_GREATER_RECOMMENDED}
                                    </div>
                                    <div className={styles['div-pad']}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    id="alertUnderInflationDaysCheck"
                                                    name="alertUnderInflationDaysCheck"
                                                    value="alertUnderInflationDaysCheck"
                                                    checked={
                                                        this.props.formData.config
                                                            .alertUnderInflationDaysCheck === true
                                                    }
                                                    onChange={
                                                        this.props
                                                            .handleCheckboxChange('alertUnderInflationDaysCheck')
                                                    }
                                                />
                                            }
                                            label={ALERT_DETAILS.LABEL.TIRE_PRESSURE_UNDER}
                                        />
                                    </div>
                                    <div className={styles['text-op']}>
                                        {ALERT_DETAILS.LABEL.TIRE_PRESSURE_LESSER_RECOMMENDED}
                                    </div>
                                    <div>
                                        <div className={styles['div-pad']}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        id="realert"
                                                        name="realert"
                                                        value="realert"
                                                        checked={
                                                            this.props.formData.config.realert === true
                                                        }
                                                        onChange={(e) => {
                                                            this.props.handleCheckboxChange('realert')(e);
                                                            if (!e.target.checked) {
                                                                this.props
                                                                    .handleTextboxChange('realertDays')({
                                                                        target: {
                                                                            value: ALERT_DETAILS.VALUE.TP_DEF,
                                                                        },
                                                                    });
                                                            }
                                                        }}
                                                    />
                                                }
                                                label={ALERT_DETAILS.LABEL.TIRE_PRESSURE_OVER_UNDER}
                                            />
                                            <div>
                                            <TextField
                                                required
                                                className={styles['text-wid']}
                                                id="realertDays"
                                                name="realertDays"
                                                type="number"
                                                InputProps={{
                                                    inputProps: {
                                                        min: ALERT_DETAILS.VALUE.TP_MIN,
                                                        max: ALERT_DETAILS.VALUE.TP_MAX,
                                                    },
                                                }}
                                                disabled={!this.props.formData.config.realert}
                                                value={this.props.formData.config.realertDays}
                                                onChange={this.props.handleTextboxChange('realertDays')}
                                            />
                                            <span className={styles['unit-Name-style']}>
                                                {ALERT_DETAILS.LABEL.TIRE_PRESSURE_DAYS}
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles['div-pad']}>
                                    <div className={styles['text-op']}>
                                        {ALERT_DETAILS.LABEL.TIRE_PRESSURE_NOTE}
                                    </div>
                                </div>
                                <div className={styles['in-padd']}>
                                    <div className={styles['text-op']}>
                                        {ALERT_DETAILS.LABEL.TIRE_PRESSURE_EX}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default TirePressureAlert;
