/* @flow */

export const FETCH_DRIVERS_LIST: string = 'FETCH_DRIVERS_LIST';
export const FETCH_DRIVERS_LIST_SUCCESS: string = 'FETCH_DRIVERS_LIST_SUCCESS';
export const FETCH_DRIVERS_LIST_ERROR: string = 'FETCH_DRIVERS_LIST_ERROR';
export const RESET_DRIVERS_LIST: string = 'RESET_DRIVERS_LIST';
export const DRIVER_LIST_PREF_KEY: string = 'fleetLocate.driverList';
export type DriverListTypeAction = {
    type: string;
    payload?: Object;
}
