/* @flow */
import {
    FETCH_ALERT_NAME_LIST,
    FETCH_ALERT_NAME_LIST_SUCCESS,
    FETCH_ALERT_NAME_LIST_ERROR,
} from './constants.alertNameCheck';

const initialState = {
    alertNameList: [],
    isLoading: false,
};

type stateType = {
    alertNameList: Array<Object>,
    isLoading: boolean,
};

type actionType = {
    type: string,
    payload: {
        alertNameList: {},
    },
};

export default (state: stateType = initialState, action: actionType) => {
    switch (action.type) {
    case FETCH_ALERT_NAME_LIST:
        return {
            ...state,
            isLoading: true,
        };
    case FETCH_ALERT_NAME_LIST_SUCCESS:
        return {
            ...state,
            isLoading: false,
            alertNameList: action.payload.alertNameList,
        };
    case FETCH_ALERT_NAME_LIST_ERROR:
        return {
            ...state,
        };
    default:
        return state;
    }
};
