/* @flow */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { reduxConnect } from '../../../hoc';
import * as actions from './actions.landmarkGroupsList';
import MiniDrawer from '../../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../../components/SharedComponents/ListComponent/ListComponent';
import * as landmarkGroupActions from '../LandmarkGroupManage/actions.landmarkGroupManage';
import deleteIcon from '../../../assets/icons/assets_List/ic-delete.svg';
import editIcon from '../../../assets/icons/assets_List/edit.svg';
import addIcon from '../../../assets/icons/assets_List/more.svg';
import AppLoader from '../../../components/AppLoader';
import { RECORDS_PER_PAGE } from '../../../constants/Config';
import {
    getParamValue,
    savePage,
    getSavedPage,
    removeSavedPage,
    getValueFromObjectByKey,
} from '../../../helper-classes/utility-functions';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    showHideColumns,
    rearrangeColumns,
    isUpdatePreference,
} from '../../../helper-classes/listUtil';
import LandmarkGroupManage from './../LandmarkGroupManage/LandmarkGroupManage';
import analytics from '../../../analytics/index';
import { LANDMARK_GRP_LIST_PREF_KEY, PARENT_GROUP_DELETE } from './constants.landmarkGroupsList';

export type Props = {
    isUpdating: boolean,
    listType: string,
    classes : Object,
    fetchLandmarkGroupsList: Function,
    resetTable: Function,
    history: {
        push: Function,
    },
    match: {
        path: any,
    },
    location: any,
    deleteLandmarkGroupGlobalId: Function,
    isLandmarkGroupDeleting: boolean,
};

const themeStyles = () => ({
    hide: {
        display: 'none',
    },
});

export type State = {
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{}>,
        totalRecords: number,
    },
    order: string,
    orderBy: string,
    pageNumber: number,
    rowsPerPage: number,
    deleteGroupDialog: {
        open: boolean,
        dialog: string,
        header: string,
        canDelete: boolean,
        data: Object,
    },
    showDeleteMessage: boolean,
    isAddEdit: boolean,
    showListing: boolean,
};

class LandmarkGroupsList extends Component<Props, State> {
    search: string;
    pageOffset: number;
    id: string;
    addUrl: string;
    listUrl: string;
    editUrl: string;
    addSubgroupUrl: string;
    userPreferences: any;
    constructor(props: Props) {
        super(props);
        const pageData = this.initPage();
        this.state = pageData.state;
        this.search = pageData.search;
        this.userPreferences = pageData.userPreferences;
    }

    UNSAFE_componentWillMount() {
        if (!this.state.isAddEdit) {
            getUserPreference(this, LANDMARK_GRP_LIST_PREF_KEY);
        }
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (this.props.listType !== nextprops.listType) {
            if (nextprops.listType === 'LandmarkGroups') {
                this.pageOffset = 0;
                this.props.fetchLandmarkGroupsList(this.state.pageNumber, this.state.rowsPerPage, this.state.order, this.state.orderBy, { search: this.search || '' });
            }
        }
        if (nextprops.landmarkGroups.length > 0) {
            const { landmarkGroups, totalLandmarkGroups } = nextprops;
            const formattedLandmarkGroupsList = landmarkGroups.map((item => ({
                directLandmarks: item.landmarkCount && item.landmarkCount.direct ? item.landmarkCount.direct : '',
                indirectLandmarks: item.landmarkCount && item.landmarkCount.indirect ? item.landmarkCount.indirect : '',
                totalLandmarks: item.landmarkCount && item.landmarkCount.total ? item.landmarkCount.total : '',
                ...item,
            })));
            const { tableData } = this.state;
            tableData.listData = formattedLandmarkGroupsList;
            tableData.totalRecords = totalLandmarkGroups;
            this.setState({
                tableData,
            });
        } else if (!this.state.isAddEdit) {
            const { tableData } = this.state;
            tableData.listData = [];
            tableData.totalRecords = 0;
            this.setState({
                tableData,
            });
        }

        if (this.state.showDeleteMessage && nextprops.landmarkGroupDeleteResponse) {
            if (nextprops.landmarkGroupDeleteResponse.success === false) {
                this.setState({
                    deleteGroupDialog: {
                        canDelete: false,
                        data: {},
                        header: 'Error',
                        dialog: nextprops.landmarkGroupDeleteResponse.msg,
                        open: true,
                    },
                    showDeleteMessage: false,
                });
            } else if (nextprops.landmarkGroupDeleteResponse.success === true) {
                analytics.track('DELETE_LANDMARK_GROUP', { feature: 'FLEET_Landmark_Group' });
                this.setState({
                    deleteGroupDialog: {
                        canDelete: false,
                        data: {},
                        header: 'Success',
                        dialog: 'Landmark group deleted successfully',
                        open: true,
                    },
                    showDeleteMessage: false,
                });
                this.props.fetchLandmarkGroupsList(this.state.pageNumber, this.state.rowsPerPage, this.state.order, this.state.orderBy, { search: this.search || '' });
            }
        }
    }

    componentDidUpdate() {
        let { isAddEdit } = this.state;
        if (isAddEdit && this.props.match.path === this.listUrl) {
            isAddEdit = false;
            const savedPage = getSavedPage();
            if (savedPage) {
                const pageData = this.initPage();
                this.updateSavedPageState(pageData.state);
                this.search = pageData.search;
            } else {
                getUserPreference(this, LANDMARK_GRP_LIST_PREF_KEY);
            }
            removeSavedPage();
            this.updateEditState(isAddEdit);
        } else if (!isAddEdit &&
            (this.props.match.path === this.editUrl ||
            this.props.match.path === this.addUrl ||
            this.props.match.path === this.addSubgroupUrl)) {
            isAddEdit = true;
            this.updateEditState(isAddEdit);
        }
    }

    initPage = () => {
        const savedPage = getSavedPage();
        let isAddEdit = false;
        this.addUrl = '/landmark-groups/add';
        this.listUrl = '/landmark-groups';
        this.editUrl = '/landmark-groups/edit/:groupId';
        this.addSubgroupUrl = '/landmark-groups/add/:parentId';
        if (getParamValue(this.props, 'groupId') || this.props.match.path === this.addUrl || this.props.match.path === this.addSubgroupUrl) {
            isAddEdit = true;
        } else {
            removeSavedPage();
        }
        const data = {
            state: {
                tableData: {
                    listData: [],
                    totalRecords: 0,
                    ischeckBox: false,
                    isGroup: true,
                    listHeaders: this.getListHeader(),
                },
                order: getValueFromObjectByKey(savedPage, 'order', 'asc'),
                orderBy: getValueFromObjectByKey(savedPage, 'orderBy', 'name'),
                pageNumber: getValueFromObjectByKey(savedPage, 'pageNumber', 0),
                rowsPerPage: getValueFromObjectByKey(savedPage, 'rowsPerPage', RECORDS_PER_PAGE),
                deleteGroupDialog: {
                    open: false,
                    dialog: '',
                    header: '',
                    canDelete: false,
                    data: {},
                },
                showDeleteMessage: false,
                isAddEdit,
                showListing: false,
            },
            search: getValueFromObjectByKey(savedPage, 'searchKey', ''),
            userPreferences: null,
        };
        return data;
    }

    updateSavedPageState = (state) => {
        // call this funciton from component did update only to set the saved page
        this.setState({ ...state }, () => {
            getUserPreference(this, LANDMARK_GRP_LIST_PREF_KEY);
        });
    }


    getData = () => {
        this.pageOffset = 0;
        this.props.fetchLandmarkGroupsList(this.state.pageNumber, this.state.rowsPerPage, this.state.order, this.state.orderBy, { search: this.search || '' });
        this.setState({ showListing: true });
    }

    updateEditState = (isAddEdit) => {
        this.setState({ isAddEdit });
    }

    updateState = (order, orderBy, pageNumber, rowsPerPage) => {
        const updateUserPref = isUpdatePreference(this, { rowsPerPage, order, orderBy });
        this.setState({
            order,
            orderBy,
            pageNumber,
            rowsPerPage,
        }, () => {
            if (updateUserPref) {
                saveUserPreference(this, LANDMARK_GRP_LIST_PREF_KEY);
            }
        });
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            {
                name: 'Name',
                path: 'landmark-group',
                ...getColumnPreferences('name', preferences),
                customMarkup: (d) => {
                    const link = (
                        d.directLandmarkCount > 0 ? <Link to={`landmark-groups/${d.id}`} style={{ color: 'inherit' }} onClick={() => this.setCustomBackUrl()}> {d.name} </Link> : d.name
                    );
                    return link;
                },
                keyName: 'id',
            },
            { directLandmarkCount: 'Direct Landmarks', ...getColumnPreferences('directLandmarkCount', preferences) },
            { indirectLandmarkCount: 'Indirect Landmarks', ...getColumnPreferences('indirectLandmarkCount', preferences), disableSort: true },
            { totalLandmarkCount: 'Total Landmarks', ...getColumnPreferences('totalLandmarkCount', preferences), disableSort: true },
        ];
        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    setCustomBackUrl = () => {
        const pageConfig = {
            url: '/landmark-groups',
            pageNumber: this.state.pageNumber,
            rowsPerPage: this.state.rowsPerPage,
            order: this.state.order,
            orderBy: this.state.orderBy,
            searchKey: this.search,
        };
        savePage(pageConfig);
        analytics.track('SELECT_LANDMARK_GROUP', { feature: 'FLEET_Landmark_Group' });
    }

    sortTableColumn = (order, orderBy, page) => {
        this.updateState(order, orderBy, page.pageNumber, page.rowsPerPage);
        this.pageOffset = page.pageNumber;
        this.props.fetchLandmarkGroupsList(page.pageNumber, page.rowsPerPage, order, orderBy, { search: this.search || '' });
        let sortCol = '';
        if (orderBy === 'indirectLandmarkCount') {
            sortCol = 'Indirect Landmarks';
        } else if (orderBy === 'directLandmarkCount') {
            sortCol = 'Direct Landmarks';
        } else if (orderBy === 'totalLandmarkCount') {
            sortCol = 'Total Landmarks';
        }
        const params = {
            feature: 'FLEET_Landmark_Group',
            sortColumn: sortCol === '' ? orderBy : sortCol,
        };
        analytics.track('LANDMARK_GROUP_SORT', params);
    }

    loadNextPage = (
        rows,
        value,
        order,
        orderBy,
    ) => {
        this.updateState(order, orderBy, rows, value);
        const filter = {
            search: this.search || '',
        };
        this.pageOffset = rows;
        this.props.fetchLandmarkGroupsList(
            rows,
            value,
            order,
            orderBy,
            filter,
        );
    }

    ListSearch = (order, orderBy, filter) => {
        this.updateState(order, orderBy, filter.pageNumber, filter.rowsPerPage);
        const filters = {
            search: filter.search || '',
        };
        this.pageOffset = filter.pageNumber;
        this.search = filter.search;
        this.props.fetchLandmarkGroupsList(
            filter.pageNumber,
            filter.rowsPerPage,
            order, orderBy,
            filters,
        );
    }

    refreshList = (page) => {
        this.props.fetchLandmarkGroupsList(
            page.page,
            page.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            { search: this.search },
        );
    }

    listTypeContextAction = (action, page = { page: 0, rowsPerPage: RECORDS_PER_PAGE }) => {
        if (action === 'refresh') {
            this.refreshList(page);
        } else if (action === 'add') {
            this.props.history.push(`${this.props.location.pathname}/add`);
        }
        return true;
    }

    editLandmarkGroup = (id) => {
        this.props.history.push(`/landmark-groups/edit/${id}`);
    }
    deleteLandmarkGroupConfirm = (id, data) => {
        const deleteData = {
            group: data,
            id,
        };
        if (data.children && data.children.length > 0) {
            this.setState({
                deleteGroupDialog: {
                    canDelete: false,
                    data: {},
                    header: 'Error',
                    dialog: PARENT_GROUP_DELETE,
                    open: true,
                },
            });
        } else if (data.totalLandmarkCount > 0) {
            this.setState({
                deleteGroupDialog: {
                    canDelete: false,
                    data: {},
                    header: 'Error',
                    dialog: 'Group with existing landmarks cannot be deleted.',
                    open: true,
                },
            });
        } else {
            this.setState({
                deleteGroupDialog: {
                    canDelete: true,
                    data: deleteData,
                    header: 'Delete Group',
                    dialog: 'Are you sure want to delete?',
                    open: true,
                },
            });
        }
    }
    addSubGroup = (id) => {
        this.props.history.push(`/landmark-groups/add/${id}`);
    }
    closeDelteDialog = () => {
        this.setState({
            deleteGroupDialog: {
                canDelete: false,
                data: {},
                header: '',
                dialog: '',
                open: false,
            },
        });
    }
    deleteLandmarkGroup = (id) => {
        this.setState({
            deleteGroupDialog: {
                canDelete: false,
                data: {},
                header: '',
                dialog: '',
                open: false,
            },
            showDeleteMessage: true,
        });
        this.props.deleteLandmarkGroupGlobalId(id);
    }

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: LANDMARK_GRP_LIST_PREF_KEY };
        rearrangeColumns(this, listHeaders, options);
    }

    showHideColumn = (header) => {
        const options = { prefKey: LANDMARK_GRP_LIST_PREF_KEY };
        showHideColumns(this, header, options);
    }

    render() {
        const { isAddEdit } = this.state;
        const { classes } = this.props;
        return (
            <div>
                {this.props.isLandmarkGroupDeleting ? <AppLoader type="fullScreen" /> : ''}
                <MiniDrawer
                    redirectTo={this.props.history.push}
                >
                    {isAddEdit &&
                        <LandmarkGroupManage />
                    }
                    <div className={(!isAddEdit) ? '' : classes.hide}>
                        {this.state.showListing ?
                            <ListComponent
                                redirectTo={this.props.history.push}
                                tableData={this.state.tableData}
                                loadNextPage={this.loadNextPage}
                                rearrangeColumns={this.rearrangeColumns}
                                resetTable={this.props.resetTable}
                                isUpdating={this.props.isUpdating}
                                ListSearch={this.ListSearch}
                                showHideColumn={this.showHideColumn}
                                sortTableColumn={this.sortTableColumn}
                                listTypeContextAction={this.listTypeContextAction}
                                tableTitle="Landmark Groups"
                                tableButtons={['Refresh', 'Add Items']}
                                pathName={this.props.location.pathname}
                                rowActions={[
                                    {
                                        label: 'Edit',
                                        clickAction: (id) => { this.editLandmarkGroup(id); },
                                        icon: editIcon,
                                    },
                                    {
                                        label: 'Delete',
                                        clickAction: (id, data) => {
                                            this.deleteLandmarkGroupConfirm(id, data);
                                        },
                                        icon: deleteIcon,
                                    },
                                    {
                                        label: 'Add Subgroup',
                                        clickAction: (id) => { this.addSubGroup(id); },
                                        icon: addIcon,
                                    },
                                ]}
                                order={this.state.order}
                                orderBy={this.state.orderBy}
                                pageNumber={this.state.pageNumber}
                                rowsPerPage={this.state.rowsPerPage}
                                searchKey={this.search}
                            />
                            :
                            <div style={{ height: '100%' }}>
                                <AppLoader type="fullScreen" />
                            </div>
                        }
                        <Dialog
                            open={this.state.deleteGroupDialog.open}
                            onClose={this.closeDelteDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{this.state.deleteGroupDialog.header}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {this.state.deleteGroupDialog.dialog}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                {this.state.deleteGroupDialog.canDelete ?
                                    <span>
                                        <Button onClick={this.closeDelteDialog} color="primary">
                                            No
                                        </Button>
                                        <Button onClick={() => { this.deleteLandmarkGroup(this.state.deleteGroupDialog.data.id); }} color="primary" autoFocus>
                                            Yes
                                        </Button>
                                    </span>
                                    :
                                    <Button onClick={this.closeDelteDialog} color="primary">
                                        Ok
                                    </Button>
                                }
                            </DialogActions>
                        </Dialog>
                    </div>
                </MiniDrawer>
            </div>
        );
    }
}

const mapDispatchToProps = {
    ...actions,
    ...landmarkGroupActions,
};
const mapStateToProps = state => ({
    landmarkGroups: state.landmarkGroupsList.landmarkGroups,
    totalLandmarkGroups: state.landmarkGroupsList.totalLandmarkGroups,
    isUpdating: state.landmarkGroupsList.isUpdating,
    landmarkGroupDeleteResponse: state.landmarkGroupManage.deleteResponse,
    isLandmarkGroupDeleting: state.landmarkGroupManage.isLandmarkGroupDeleting,
});

export default
withStyles(themeStyles)(reduxConnect(LandmarkGroupsList, mapDispatchToProps, mapStateToProps));

