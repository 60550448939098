/* @flow */
import React, { Component } from 'react';
import { reduxConnect } from '../../../hoc';
import * as actions from './actions.landmarks';
import styles from './Landmarks.module.scss';
import LandmarkDetail from '../../../components/LandmarkDetails/LandmarkDetails';
import RightDrawer from '../../../components/SharedComponents/RightDrawer/RightDrawer';

export type Props = {
    landmarkID: String,
    fetchLandmarks: Function,
    fetchAssetInLandmark: Function,
    redirectTo: Function,
    landmarks: Object,
    refreshLandmarkPage: Function,
    assetInLandmark: Array<{}>,
    assetLoader: boolean,
    landmarksDataLoadedCallback: Function,
    hideRightDrawer: boolean,
    showBackButton: boolean,
};

export type State = {
    recordsPerPage: number,
    currentPage: number,
    showAssetRefeshChip: boolean,
};

class LandmarkDetails extends Component<Props, State> {
    isLoaded: boolean;
    constructor(props) {
        super(props);
        this.state = {
            recordsPerPage: 50,
            currentPage: 0,
            showAssetRefeshChip: false,
        };
        this.isLoaded = false;
    }

    componentDidMount() {
        this.getPageData(false, true);
        this.props.refreshLandmarkPage(this.getPageData);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.landmarks &&
            typeof this.props.landmarksDataLoadedCallback === 'function' &&
            !Object.prototype.hasOwnProperty.call(this.props.landmarks, 'id') &&
            Object.prototype.hasOwnProperty.call(nextProps.landmarks, 'id')
        ) {
            this.props.landmarksDataLoadedCallback(nextProps.landmarks);
        }
    }

    getPageData = (showChip, showAssetLoader = false) => {
        this.props.fetchLandmarks(this.props.landmarkID, {
            showLoader: showAssetLoader,
            fatchAsset: showAssetLoader,
            recordsPerPage: this.state.recordsPerPage,
            currentPage: this.state.currentPage,
        });
        if (showChip && !this.state.showAssetRefeshChip) {
            this.setState({ showAssetRefeshChip: showChip });
        }
    }

    getAssetListData = (page, showLoader) => {
        this.setState({ currentPage: page.currentPage }, () => {
            this.props.fetchAssetInLandmark(
                this.props.landmarkID,
                {
                    showLoader,
                    recordsPerPage: this.state.recordsPerPage,
                    currentPage: this.state.currentPage,
                },
            );
        });
    }

    pageChanged = page => this.getAssetListData(page, false);

    refreshAssetListData = (refresh) => {
        if (refresh) this.getAssetListData({ currentPage: 0 }, true);
        this.setState({ showAssetRefeshChip: false });
    }

    getChild = () => {
        const {
            redirectTo,
            landmarks,
            assetInLandmark,
            assetLoader,
            hideRightDrawer,
        } = this.props;

        const Landmark = (
            <LandmarkDetail
                landmarksData={landmarks}
                redirectTo={redirectTo}
                assetInLandmark={assetInLandmark}
                isLoader={false}
                assetLoader={assetLoader}
                showAssetRefeshChip={this.state.showAssetRefeshChip}
                refreshAssetListData={this.refreshAssetListData}
                pageChanged={this.pageChanged}
                showBackButton={this.props.showBackButton}
            />
        );

        if (!hideRightDrawer) {
            return (
                <RightDrawer showDrawer>
                    {Landmark}
                </RightDrawer>
            );
        }

        return Landmark;
    }

    render() {
        return (
            <div className={styles.container}>
                {this.getChild()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    landmarks: state.landmarks.landmarks,
    assetInLandmark: state.landmarks.assetInLandmark,
    assetLoader: state.landmarks.assetLoader,
});

export default reduxConnect(LandmarkDetails, actions, mapStateToProps);
