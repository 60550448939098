/* @flow */

export const FETCH_DRIVER_SCORECARD_TRIPS: string = 'FETCH_DRIVER_SCORECARD_TRIPS';
export const FETCH_DRIVER_SCORECARD_TRIPS_SUCCESS: string = 'FETCH_DRIVER_SCORECARD_TRIPS_SUCCESS';
export const FETCH_DRIVER_SCORECARD_TRIPS_ERROR: string = 'FETCH_DRIVER_SCORECARD_TRIPS_ERROR';
export const RESET_DRIVER_SCORECARD_TRIPS: string = 'RESET_DRIVER_SCORECARD_TRIPS';
export const CLOSE_DRIVER_SCORECARD_DIALOG: string = 'CLOSE_DRIVER_SCORECARD_DIALOG';
export const UPDATE_DRIVER_SCORECARD_LOADER: string = 'UPDATE_DRIVER_SCORECARD_LOADER';
export const TRIP_CENTRIC_SCORECARD_PREF_KEY: string = 'fleetLocate.tripCentricScorecard';
export type DriverScorecardTripsAction = {
    type: string;
    payload?: Object;
}
