/* @flow */
import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import { Scrollbars } from 'react-custom-scrollbars';
import AppLoader from '../../AppLoader';
import styles from './Drivers.module.scss';
import { getMylocalTimeDiff, getPermissionValue, removeSavedPage } from '../../../helper-classes/utility-functions';
import type { typeDriverPrediction } from '../../../containers/Header/types';
import analytics from '../../../analytics';

const handleDriverAnalytics = (e) => {
    const params = {
        feature: 'UniversalSearch',
    };
    if (e.type === 'Score') {
        analytics.track('DRIVER_SCORE', params);
    } else if (e.type === 'Edit') {
        analytics.track('DRIVER_EDIT', params);
    }
};

const Drivers = (predictions: typeDriverPrediction) => {
    const style = { width: 496, height: predictions.setMaxHeight };
    const items = (predictions.predictions).map((item) => (
        <ListItem
            key={item.id}
            data-itemid={item.id}
            data-name={`${item.firstName}, ${item.lastName}`}
        >
            <Avatar className={styles.oval}>
                <i
                    data-itemid={item.id}
                    data-name={`${item.firstName}, ${item.lastName}`}
                    className={styles.icon}
                />
            </Avatar>
            <ListItemText
                data-itemid={item.id}
                data-name={`${item.firstName}, ${item.lastName}`}
                className={styles['list-items']}
                primary={
                    <span
                        id="main-address"
                        data-itemid={item.id}
                        data-name={`${item.firstName}, ${item.lastName}`}
                        className={styles['main-address']}
                        alt={item.assetName !== undefined ? `${item.firstName}, ${item.lastName} - ${item.assetName} ` : `${item.firstName}, ${item.lastName}`}
                        title={item.assetName !== undefined ? `${item.firstName}, ${item.lastName} - ${item.assetName} ` : `${item.firstName}, ${item.lastName}`}
                    >
                        <span
                            data-itemid={item.id}
                            data-name={item.assetName}
                            className={styles['main-address-header']}
                            style={{ maxWidth: item.assetName ? '120px' : '100%' }}
                        >{item.firstName}, {item.lastName}
                        </span>
                        {((item.assetName !== null)) ?
                            <span
                                data-itemid={item.assetId}
                                data-name={item.assetName}
                                className={styles['main-address-sub-header']}
                            >
                                {((item.assetName !== undefined)) &&
                                <span className={styles.hyphen}> - </span>}
                                {getPermissionValue('Assets') !== 'None' ?
                                    <div
                                        data-itemid={item.assetId}
                                        data-name={item.assetName}
                                        className={styles['vehicle-assigned']}
                                        data-type="AssetRoute"
                                        role="button"
                                        tabIndex="-1"
                                        onClick={() => {
                                            predictions.redirectTo(`/home?assetId=${item.assetId}&zoom=true`);
                                            const params = {
                                                feature: 'UniversalSearch',
                                            };
                                            analytics.track('DRIVER_ASSET_LINK', params);
                                        }}
                                        onKeyUp={() => {
                                        }}
                                    >
                                        {item.assetName}
                                    </div> :
                                    item.assetName
                                }
                            </span> : null}
                    </span>
                }
                secondary={
                    <span
                        id="sub-address"
                        data-itemid={item.id}
                        data-name={`${item.firstName}, ${item.lastName}`}
                        className={styles['sub-address']}
                    >
                        {(item.assetName !== undefined) ?
                            <span
                                data-itemid={item.id}
                                data-name={`${item.firstName}, ${item.lastName}`}
                                className={styles['main-address-sub-header']}
                                alt={item.assetAddress !== undefined ? `${item.assetAddress}` : ''}
                                title={item.assetAddress !== undefined ? `${item.assetAddress}` : ''}
                            >
                                { item.assetAddress !== undefined ? `${item.assetAddress}, ` : ''}
                                { item.assetCity !== undefined ? `${item.assetCity}, ` : ''}
                                { item.assetState !== undefined ? `${item.assetState}, ` : ''}
                                @ {getMylocalTimeDiff(item.assetLastLocationReported)}
                            </span> :
                            <span
                                data-itemid={item.id}
                                data-name={`${item.firstName}, ${item.lastName}`}
                                className={styles['main-address-sub-header']}
                            >Not assigned to a vehicle.
                            </span>

                        }
                    </span>
                }
            />
            <div
                id="list-icon-container"
                data-itemid={item.id}
                className={`${styles['list-icon-container']}`}
            >
                {getPermissionValue('Driver Behavior') !== 'None' &&
                <ListItemIcon className={styles.listIconItem}>
                    <div
                        data-itemid={item.id}
                        data-name={`${item.firstName}, ${item.lastName}`}
                        data-type="Score"
                        role="link"
                        tabIndex="-1"
                        onClick={() => {
                            removeSavedPage();
                            predictions.redirectTo(`/driver-scorecard/driver/${item.id}`);
                        }}
                        onKeyUp={() => {}}
                    >
                        <i
                            data-itemid={item.id}
                            data-name={`${item.firstName}, ${item.lastName}`}
                            data-type="Score"
                            className={styles['score-copy']}
                        />
                        <span
                            data-itemid={item.id}
                            data-name={`${item.firstName}, ${item.lastName}`}
                            data-type="Score"
                            className={styles['icon-span-text']}
                        >Score
                        </span>
                    </div>
                </ListItemIcon>
                }
                {getPermissionValue('Drivers') === 'Modify' &&
                    <ListItemIcon className={styles.listIconItem}>
                        <div
                            data-itemid={item.id}
                            data-name={`${item.firstName}, ${item.lastName}`}
                            data-type="Edit"
                            role="link"
                            tabIndex="-1"
                            onClick={() => {
                                removeSavedPage();
                                predictions.redirectTo(`/drivers/edit/${item.id}`);
                            }}
                            onKeyUp={() => {
                            }}
                        >
                            <i
                                data-itemid={item.id}
                                data-name={`${item.firstName}, ${item.lastName}`}
                                data-type="Edit"
                                className={styles.edit}
                            />
                            <span
                                data-itemid={item.id}
                                data-name={`${item.firstName}, ${item.lastName}`}
                                data-type="Edit"
                                className={styles['icon-span-text']}
                            >Edit
                            </span>
                        </div>
                    </ListItemIcon>
                }
            </div>
        </ListItem>));

    return (
        <List
            id="drivers-dropdown"
            className={`${styles['address-block']}`}
            onClick={e => handleDriverAnalytics(e.target.dataset)}
            style={{ maxHeight: predictions.setMaxHeight }}
        >
            <Scrollbars style={style} key="Drivers" onScroll={predictions.onScroll}>
                {items}
                { predictions.isLoading ?
                    <div style={{ padding: 10, textAlign: 'center' }}>
                        <AppLoader
                            type="scale"
                            height={18}
                            width={2}
                            margin="1px"
                        />
                    </div>
                    : ''
                }
            </Scrollbars>
        </List>);
};


export default Drivers;
