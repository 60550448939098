/* @flow */

import { FETCH_LANDMARKGROUPS_LIST, FETCH_LANDMARKGROUPS_LIST_SUCCESS, FETCH_LANDMARKGROUPS_LIST_ERROR, RESET_LANDMARKGROUPS_LIST } from './constants.landmarkGroupsList';
import type { LandmarkGroupsListTypeAction } from './constants.landmarkGroupsList';

export const fetchLandmarkGroupsList = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filter: Object,
): LandmarkGroupsListTypeAction => ({
    type: FETCH_LANDMARKGROUPS_LIST,
    payload: {
        order,
        orderBy,
        pageNumber,
        pageSize,
        filter,
    },
});

export const fetchLandmarkGroupsListSuccess =
    (landmarkGroups: string, totalLandmarkGroups: number): LandmarkGroupsListTypeAction => ({
        type: FETCH_LANDMARKGROUPS_LIST_SUCCESS,
        payload: { landmarkGroups, totalLandmarkGroups },
    });

export const resetTable =
    (): LandmarkGroupsListTypeAction => ({
        type: RESET_LANDMARKGROUPS_LIST,
    });

export const fetchLandmarkGroupsListError = (error: string): LandmarkGroupsListTypeAction => ({
    type: FETCH_LANDMARKGROUPS_LIST_ERROR,
    payload: { error },
});
