/* flow */
import React, { Component } from 'react';
import { Card, Grid, CardActions } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { NavLink } from 'react-router-dom';
import ImageWrapper from './LookIcons';
import customstyles from './LookLandingPage.module.scss';

type Props = {
    data: Object,
};

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

class LookType extends Component<Props> {
    state = {};
    render() {
        return (
            <Grid item id={'div'.concat(this.props.data.idPostfix)} className={customstyles['col-md-5ths']}>
                <NavLink
                    exact
                    to={`/configure-module?lookType=${this.props.data.code}`}
                    style={{ textDecoration: 'none' }}
                >
                    <Card className={customstyles['lookimg-container']}>
                        <div>
                            <ImageWrapper dataitemid={this.props.data} />
                        </div>
                        <CardActions id={'crd'.concat(this.props.data.idPostfix)} className={customstyles['look-action']}>
                            <p id={'p'.concat(this.props.data.idPostfix)} className={customstyles['look-name']}>{this.props.data.name}</p>
                        </CardActions>
                    </Card>
                </NavLink>
            </Grid>
        );
    }
}
export default withStyles(styles)(LookType);
