/* @flow */
import { FETCH_SCOREVEHICLE_LIST_ERROR, FETCH_SCOREVEHICLE_LIST_SUCCESS, FETCH_SCOREVEHICLE_LIST, RESET_SCOREVEHICLE_LIST } from './constants.scoreVehicleList';

const initialState = {
    scorevehicle: [],
    totalvehiclecards: 0,
    isUpdating: false,
};

type stateType = {
    scorevehicle: Array<Object>,
    totalvehiclecards: number,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        response: {
            content: [],
            total: number
        }
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_SCOREVEHICLE_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case FETCH_SCOREVEHICLE_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            scorevehicle: action.payload.response.content,
            totalvehiclecards: action.payload.response.total,
        };
    case FETCH_SCOREVEHICLE_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    case RESET_SCOREVEHICLE_LIST:
        return {
            ...state,
            isUpdating: false,
            scorevehicle: [],
            totaldrivercards: 0,
        };
    default:
        return state;
    }
};
