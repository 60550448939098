import React from 'react';

type Props = {
    color:? string,
    height:? string,
    width: ? string,
    showOverlay: ? boolean,
    style: ? any,
    children: ? any,
};
const CustomOverlay = (props: Props) => {
    let style = {
        zIndex: 2,
        position: 'absolute',
        height: props.height || '100%',
        width: props.width || '100%',
        backgroundColor: props.color || '#ffff',
        opacity: 0.4,
    };
    style = { ...style, ...props.style };
    return (
        <div
            style={{
                position: 'relative',
            }}
        >
            {props.showOverlay &&
                <div
                    style={style}
                />
            }
            { props.children }
        </div>
    );
};
export default CustomOverlay;
