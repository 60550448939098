/* eslint-disable react/no-danger */
import React from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Snackbar,
    SnackbarContent,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as actions from '../../containers/Settings/actions.settings';
import * as loginAction from '../../containers/Login/actions.login';
import { reduxConnect } from '../../hoc';
import AppLoader from '../../components/AppLoader';
import { getTermsAndConditions } from '../../helper-classes/utility-functions';
import classes from './TermsAndCondition.module.scss';

export type Props = {
    acceptResellerTermsAndCondition: Function,
    termsAndCondition: string,
    logout: Function,
};

export type State = {
    reachedScrollEnd: boolean,
    termsAccepted: boolean,
    showError: boolean,
    content: string,
};

class TermsAndCondition extends React.Component<Props, State> {
    contentRef: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            reachedScrollEnd: false,
            termsAccepted: false,
            showError: false,
            termsAndCondition: getTermsAndConditions(),
        };
        this.contentRef = React.createRef();
    }
    componentDidMount() {
        if (this.contentRef.current.clientHeight === this.contentRef.current.scrollHeight ||
            this.state.termsAndCondition.content.length < 1000) {
            this.enableSsaAcceptance();
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.termsAndCondition === 'accepted') {
            window.location.reload();
        } else if (nextProps.termsAndCondition === 'error') {
            this.setState({ showError: true });
        }
    }
    enableSsaAcceptance = () => {
        this.setState({ reachedScrollEnd: true });
    }
    findScrollend = (e) => {
        if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight - 10) {
            this.enableSsaAcceptance();
        }
    }
    handleCheckbox = (event) => {
        this.setState({ termsAccepted: event.target.checked });
    };
    closeErrorMessage = () => {
        this.setState({ showError: false });
    }
    render() {
        const { termsAndCondition } = this.state;
        return (
            <div className={classes.container}>
                {this.props.termsAndCondition === 'processing' &&
                    <AppLoader type="fullScreen" />
                }
                <div className={classes.content}>
                    <h3>Subscription Service Agreement</h3>
                    <div
                        ref={this.contentRef}
                        className={classes.contentHeight}
                        onScroll={!this.state.reachedScrollEnd ? this.findScrollend : null}
                    >
                        {termsAndCondition.content &&
                            <div
                                className={classes.contentWrap}
                                dangerouslySetInnerHTML={
                                    { __html: termsAndCondition.content }
                                }
                            />
                        }
                    </div>
                    <div>
                        <FormControlLabel
                            className={classes.checkBox}
                            control={<Checkbox color="primary" disabled={!this.state.reachedScrollEnd} onChange={this.handleCheckbox} />}
                            label={"I've read and accept the new Terms of Service"}
                        />
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button
                            type="button"
                            color="primary"
                            onClick={() => {
                                this.props.logout();
                            }}
                        >
                            LOGOUT
                        </Button>
                        <Button
                            type="button"
                            color="primary"
                            disabled={!this.state.termsAccepted}
                            onClick={() => {
                                this.props.acceptResellerTermsAndCondition(termsAndCondition.id);
                            }}
                        >
                            CONTINUE
                        </Button>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.showError}
                    onClose={this.handleClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                >
                    <SnackbarContent
                        className={classes.errorMessage}
                        message="Something went wrong!"
                        action={[
                            <IconButton
                                key="close"
                                aria-label="close"
                                color="inherit"
                                onClick={this.closeErrorMessage}
                                size="large"
                            >
                                <CloseIcon className={classes.icon} />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    updateAppSettings: state.settings.updateAppSettings,
    isLoader: state.globalSearch.isLoader,
    updatePreferences: state.settings.updatePreferences,
    hasAppsettings: state.userSession.hasAppsettings,
    termsAndCondition: state.settings.termsAndCondition,
});
const mapDispatchToProps = {
    ...actions,
    ...loginAction,
};
export default
(reduxConnect(TermsAndCondition, mapDispatchToProps, mapStateToProps));
