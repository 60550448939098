/* @flow */
import { hasFeature } from '../../../helper-classes/utility-functions';

const PWR_DISC_ALERT_ID = 'devicePowerDisconnectAlert';

const settingFilters = (responseJSON: any) => {
    const filters = [{ property: 'deviceAlert', value: 'false' }, { property: 'active', value: 'true' }];
    if (!(hasFeature('fleetTemperature'))) {
        filters.push({ property: 'code', comparison: 'ne', value: 'localFleetTemperatureAlertAlert' });
    }

    if (!(responseJSON.hasPowerDisconnectDevice)) {
        filters.push({ property: 'code', comparison: 'ne', value: PWR_DISC_ALERT_ID });
    }
    if (!(responseJSON.hasSeatBelt)) {
        filters.push({ property: 'code', comparison: 'ne', value: 'localFleetSeatbeltAlert' });
    }
    if (!responseJSON.hasFuelLevel) {
        filters.push({ property: 'code', comparison: 'ne', value: 'localFleetFuelTankAlert' });
    }
    if (!responseJSON.hasOilLife) {
        filters.push({ property: 'code', comparison: 'ne', value: 'localFleetEngineOilLifeAlert' });
    }
    if (!responseJSON.hasTirePressure) {
        filters.push({ property: 'code', comparison: 'ne', value: 'localFleetTirePressureAlert' });
    }

    if (!(hasFeature('fleetMaintenanceDtc'))) {
        filters.push({ property: 'code', comparison: 'ne', value: 'maintenanceDtcAlert' });
    }

    // Default Filter
    filters.push({ property: 'code', comparison: 'ne', value: 'localFleetMaintenanceAlertDistanceAlert' });
    filters.push({ property: 'code', comparison: 'ne', value: 'localFleetMaintenanceAlertEngineHoursAlert' });
    filters.push({ property: 'code', comparison: 'ne', value: 'localFleetMaintenanceAlertTimePeriodAlert' });
    return filters;
};
export default settingFilters;
