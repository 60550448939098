/* @flow */

import {
    FETCH_ASSETS_DETAILS,
    FETCH_ASSETS_DETAILS_SUCCESS,
    FETCH_ASSETS_DETAILS_ERROR,
    FETCH_ASSETS_TRIPS,
    FETCH_ASSETS_TRIPS_SUCCESS,
    UPDATE_TRIPS_LOADER,
    FETCH_ASSETS_EVENTS,
    FETCH_ASSETS_EVENTS_SUCCESS,
    FETCH_ASSETS_EVENTS_ERROR,
    FETCH_TRIPS_ERROR,
    UPDATE_MAP_REFRESH_LOADER,
} from './constants.assets';
import type { AssetTypeAction } from './constants.assets';

export const fetchAssetsDetails = (id: string, showLoader: boolean = true): AssetTypeAction => ({
    type: FETCH_ASSETS_DETAILS,
    payload: { id, showLoader },
});

export const fetchAssetsDetailsSuccess = (response: {}): AssetTypeAction => ({
    type: FETCH_ASSETS_DETAILS_SUCCESS,
    payload: { response },
});

export const fetchAssetsDetailsError = (error: string): AssetTypeAction => ({
    type: FETCH_ASSETS_DETAILS_ERROR,
    payload: { error },
});

export const
    fetchTrips = (id: string, paramsUrl: string, initFlag: boolean = false): AssetTypeAction => ({
        type: FETCH_ASSETS_TRIPS,
        payload: { id, paramsUrl, initFlag },
    });

export const
    fetchTripsSuccess = (response: {}, initTrip: boolean, initEvent: boolean): AssetTypeAction => ({
        type: FETCH_ASSETS_TRIPS_SUCCESS,
        payload: { response, initTrip, initEvent },
    });

export const fetchTripsError = (error: string, initTrip: boolean): AssetTypeAction => ({
    type: FETCH_TRIPS_ERROR,
    payload: { error, initTrip },
});

export const updateTripsLoader = (showTripLoader: boolean) => ({
    type: UPDATE_TRIPS_LOADER,
    payload: { showTripLoader },
});

export const updateMapRefreshLoader = (mapRefreshLoader: boolean) => ({
    type: UPDATE_MAP_REFRESH_LOADER,
    payload: { mapRefreshLoader },
});

export const fetchAssetsEvents = (id: string, paramsUrl: string): AssetTypeAction => ({
    type: FETCH_ASSETS_EVENTS,
    payload: { id, paramsUrl },
});

export const fetchAssetsEventsSuccess = (response: {}): AssetTypeAction => ({
    type: FETCH_ASSETS_EVENTS_SUCCESS,
    payload: { response },
});

export const fetchAssetsEventsError = (error: string): AssetTypeAction => ({
    type: FETCH_ASSETS_EVENTS_ERROR,
    payload: { error },
});
