/* @flow */
import React from 'react';
import { Input, TextField } from '@mui/material';
import { SEARCH_CHAR_LIMIT } from '../../constants/Config';

const SearchBox = (props: any) => {
    if (!props.fieldtype) {
        return (<Input
            {...props}
            inputProps={{ maxLength: SEARCH_CHAR_LIMIT, ...props.inputProps }}
        />);
    }
    return (<TextField
        {...props}
        inputProps={{ maxLength: SEARCH_CHAR_LIMIT, ...props.inputProps }}
    />);
};
export default (SearchBox);
