/* @flow */
import React, { Component } from 'react';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EventsList from '../Events/EventsList';
import customstyles from '../Trips/style.module.scss';
import GetSvgIcon from '../../util/svgImage_util';
import {
    convertDateTime,
    getTimeFormatAsperUserSettting,
    getUserPreference,
} from '../../helper-classes/utility-functions';
import { TRIP_EVENTS_PREF_KEY } from '../../containers/Assets/constants.assets';
import getListHeader from '../Events/EventsListUtil';
import AppLoader from '../AppLoader';

const styles = () => ({
    textContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
        maxWidth: 75,
    },
    satIcon: {
        width: 12,
        height: 16,
        marginBottom: -4,
    },
    tirePressure: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export type Props = {
    classes: Object,
    events: Object,
    tripData: Object,
    assetName: string,
    serialNumber: string,
    getPrevTrip: Function,
    getNextTrip: Function,
    isFirstTrip: boolean,
    isLastTrip: boolean,
    returnToTripsView: Function,
    fromDate: any,
    toDate: any,
    handleRefresh: Function,
    setZoomEvent: Function,
    zoomEvent: Object,
    assetFeatures: Object,
    deviceModelCode: string,
    userPreferences: any,
};

export type State = {
    tableData: Object,
};

class TripsDetailComponent extends Component<Props, State> {
    userPreferences: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            tableData: { listHeaders: {}, userPreferences: null },
        };
    }

    componentDidMount() {
        this.setTripEventsPreference();
    }

    setTripEventsPreference = () => {
        getUserPreference(TRIP_EVENTS_PREF_KEY).then((response) => {
            const userPreference = response && response.value ? response.value : null;
            const listHeaders = getListHeader(
                { value: userPreference },
                this.props.classes,
                this.props.assetFeatures,
                this.props.deviceModelCode,
            );
            this.setState({ tableData: { listHeaders, userPreferences: response } });
        });
    };

    getTripsDetail = () => {
        const { events } = this.props;
        if ((events && events.length > 0)) {
            return (this.state.tableData.listHeaders &&
                this.state.tableData.listHeaders.length > 0 ? <EventsList
                    events={events}
                    setZoomEvent={this.props.setZoomEvent}
                    zoomEvent={this.props.zoomEvent}
                    overrideStyle={{
                        tableWrapper: {
                            height: 'calc(100vh - 196px)',
                        },
                    }}
                    listHeaders={this.state.tableData.listHeaders}
                    userPreferences={this.state.tableData.userPreferences}
                    tripId={this.props.tripData && this.props.tripData.id}
                /> : <div className={customstyles['trips-loader']}><AppLoader /></div>
            );
        }

        const list = (
            <div className={customstyles['no-trips']}>
                No Events available for the selected asset
            </div>);

        return list;
    };

    render() {
        const { assetName, serialNumber, tripData } = this.props;
        const startDate = convertDateTime(tripData.startDate, (getTimeFormatAsperUserSettting() === '12h') ? 'MM/DD/YY h:mm A' : 'MM/DD/YY H:mm');
        let endDate = 'In Progress';
        if (tripData.endDate !== null) {
            endDate = convertDateTime(tripData.endDate, (getTimeFormatAsperUserSettting() === '12h') ? 'MM/DD/YY h:mm A' : 'MM/DD/YY H:mm');
        }
        return (
            <div className="app">
                <div className={customstyles.container}>
                    <Grid container justifyContent="flex-start" alignItems="center" id="header-card" className={customstyles.card}>
                        <Grid item xs={10}>
                            <Grid container justifyContent="flex-start" alignItems="center">
                                <Grid item>
                                    <button
                                        className={customstyles['back-button']}
                                        onClick={this.props.returnToTripsView}
                                    >
                                        <i className={customstyles['back-icon']} />
                                    </button>
                                </Grid>
                                <Grid item className={customstyles['trip-detail-head-wrapper']}>
                                    <Typography variant="h5" className={customstyles['asset-header']} component="div" id="Asset-Name">
                                        <span>
                                        Trip Details
                                        </span>
                                    </Typography>
                                    <Typography id="sub-header" className={customstyles['sub-header']} component="div">
                                        {assetName} | {serialNumber}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justifyContent="flex-end" spacing={1} className={customstyles['icon-holder']}>
                                <button onClick={() =>
                                    this.props.handleRefresh(
                                        this.props.fromDate,
                                        this.props.toDate,
                                    )}
                                >
                                    <Grid item>
                                        <GetSvgIcon type="refresh" fillcolor="#fff" opacity={1} />
                                    </Grid>
                                </button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={customstyles['trip-selector']}>
                            <GetSvgIcon type="left-arrow" fillcolor="#fff" opacity={this.props.isFirstTrip ? 0.5 : 1} onClick={this.props.getPrevTrip} className={customstyles['left-arrow-icon']} />
                            <span id="sub-header" className={customstyles['sub-header']}>{startDate} - {endDate}</span>
                            <GetSvgIcon type="right-arrow" fillcolor="#fff" opacity={this.props.isLastTrip ? 0.5 : 1} onClick={this.props.getNextTrip} className={customstyles['right-arrow-icon']} />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            {this.getTripsDetail()}
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(TripsDetailComponent);
