/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_SCORECARDS_LIST } from './constants.scorecardsList';
import { fetchscorecardsListError, fetchScoreCardsListSuccess } from './actions.scorecardsList';
import { getIframeUserToken } from '../../helper-classes/utility-functions';
import config from '../../constants/Config';

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    of: Function
}

const formatData = (data: any) => {
    if (!data && data.length <= 0) return [];

    return data.map((d) => {
        const landmark = Object.assign({}, d);

        if (d.marker) {
            landmark.lat = d.marker.y ? d.marker.y : 'N/A';
            landmark.lng = d.marker.x ? d.marker.x : 'N/A';
        }

        if (d.dateCreated) {
            landmark.dateCreated = new Date(d.dateCreated).toLocaleString('en-US');
        }

        if (d.attributes) {
            landmark.attributes = JSON.stringify(d.attributes).replace(/\{|\}/g, '').replace(/,/g, ', ');
        }

        return landmark;
    });
};

export const getURL = (data: Object) => {
    // this function is used many places please check once you modify this function
    const {
        pageNumber,
        pageSize,
        filters,
        order,
        orderBy,
    } = data;
    const sortString = `${orderBy}:${order}`;
    const offset = pageSize * pageNumber;
    return `${config.get('DRIVER_SCORECARD_SERVICES_URL')}/scorecard/trips/${filters.tripId}?name=default&limit=${pageSize}&offset=${offset}&sort=${sortString}&startDate=${filters.startDate}&endDate=${filters.endDate}`;
};

export const getscorecards =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_SCORECARDS_LIST)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getIframeUserToken(),
                    'Content-Type': 'application/json',
                };
                return getJSON(getURL(action.payload), headers);
            })
            .map((result) => {
                // const formatedData = formatData(result.response.data);
                const formatedData = formatData(result.content);
                return fetchScoreCardsListSuccess(formatedData, result.total);
                // return fetchScoreCardsListSuccess(formatedData, result.response.total);
            })
            .catch(error => of(fetchscorecardsListError(error)));
    // });

export default combineEpics(getscorecards);
