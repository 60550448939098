/* @flow */
import {
    FETCH_ALERT_NAME,
    FETCH_ALERT_NAME_SUCCESS,
    FETCH_ALERT_NAME_ERROR,
} from './constants.landingPage';

import type { AlertNameTypeAction } from './constants.landingPage';

export const fetchAlertNameList = (alertNames: {} = {}):
    AlertNameTypeAction => ({
    type: FETCH_ALERT_NAME,
    payload: { alertNames },
});

export const fetchAlertNameSuccess = (alertNames: {}): AlertNameTypeAction => ({
    type: FETCH_ALERT_NAME_SUCCESS,
    payload: { alertNames },
});

export const fetchAlertNameError = (error: string): AlertNameTypeAction => ({
    type: FETCH_ALERT_NAME_ERROR,
    payload: { error },
});
