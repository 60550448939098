import React, { Component } from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import TableSortLabel from '@mui/material/TableSortLabel';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import styles from './AssetGroupManageStyles';
import AppLoader from '../AppLoader';
import customStyles from './AssetGroupManage.module.scss';
import { MIN_SEARCH_LENGTH } from './../../constants/Config';
import { ASSET_PER_PAGE } from './../../containers/AssetGroupManage/constants.assetGroupManage';

type Props = {
    selectedAssets: Array<{}>,
    onAssetsSelected: Function,
    assets: Array<{}>,
    totalAssets: number,
    loadAssets: Function,
    isAssetsListUpdating: boolean,
    addBulkAssets: Function,
    isAssetGroupDetailsFetching: boolean,
};
type State = {
    tableData: {
        listData: Array<{}>,
        totalRecords: number,
    },
    search: string,
    allSelected: boolean,
    openDialog: boolean,
    orderBy: string,
    order: string;
};

class AssetGroupList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            tableData: {
                listData: [],
                totalRecords: 0,
            },
            search: '',
            allSelected: false,
            openDialog: false,
            orderBy: 'name',
            order: 'asc',
        };
    }

    componentDidMount() {
        this.loadNextPage(this.queryConfig, false);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const tableData = {
            listData: nextProps.assets,
            totalRecords: nextProps.totalAssets,
            selectedAssets: nextProps.selectedAssets,
        };
        this.setState({ tableData });
    }

    loadNextPage = (query, resetPage) => {
        const { tableData, search } = this.state;
        let listData = tableData.listData ? tableData.listData : [];
        let offset = 0;
        if ((query === search || !query) && !resetPage) {
            offset = listData.length;
        } else {
            listData = [];
        }

        const params = {
            selectedAssets: this.props.selectedAssets,
            search: this.state.search,
            sort: {
                orderBy: this.state.orderBy,
                order: this.state.order,
            },
            clearResult: false,
        };
        this.props.loadAssets(offset, ASSET_PER_PAGE, params);
    };

    isSelected = (id) => {
        let selected = false;
        const { selectedAssets } = this.props;
        if (selectedAssets) {
            selectedAssets.forEach((asset) => {
                if (asset.id === id) {
                    selected = true;
                }
            });
        }
        return selected;
    }

    onTableScroll = (e) => {
        const { tableData } = this.state;
        if (e.target.scrollTop / e.target.scrollHeight > 0.7) {
            if (tableData.totalRecords > tableData.listData.length
                && !this.props.isAssetsListUpdating && this.canLoadNextPage()) {
                this.loadNextPage(this.queryConfig);
            }
        }
    }

    canLoadNextPage = () => {
        const { tableData } = this.state;
        const totalLoaded = tableData.listData ? tableData.listData.length : 0;
        const totalAvailable = tableData.totalRecords || 0;
        return totalAvailable > totalLoaded || totalLoaded === 0;
    }

    headersArray = () => [{ key: 'Asset', value: 'name' }, { key: 'Serial Number', value: 'deviceSerialNumber' }]

    handleSearchChange = (e) => {
        const search = (e != null && e.target != null) ? e.target.value : e;
        this.setState({ search });
        const params = {
            selectedAssets: this.props.selectedAssets,
            search,
            sort: {
                orderBy: this.state.orderBy,
                order: this.state.order,
            },
            clearResult: true,
        };

        if (search.length >= MIN_SEARCH_LENGTH || search.length === 0) {
            this.props.loadAssets(0, ASSET_PER_PAGE, params);
        }
    }

    onSelectAllClicked = () => {
        this.setState({ openDialog: true });
    }

    handleClose = () => {
        this.setState({ openDialog: false });
    }

    confirmSelectAll = () => {
        this.props.addBulkAssets(this.state.tableData.listData);
        this.setState({ openDialog: false });
    }

    getLoadingIndicator = () => (this.canLoadNextPage() ? <AppLoader loaderStyle={styles.loader} height={30} width={3} /> : '')

    handleItemSelect = (row, index) => (event) => {
        this.props.onAssetsSelected(event.target.checked, row, index);
    }

    createSortHandler = (item) => {
        const order = (this.state.order === 'asc') ? 'desc' : 'asc';
        this.setState({
            orderBy: item,
            order,
        });

        const params = {
            selectedAssets: this.props.selectedAssets,
            search: this.state.search,
            sort: {
                orderBy: item,
                order,
            },
            clearResult: true,
        };
        this.props.loadAssets(0, ASSET_PER_PAGE, params);
    }

    render() {
        const {
            allSelected,
            tableData,
            order,
            orderBy,
        } = this.state;
        const totalLoaded = tableData.listData ? tableData.listData.length : '0';
        const totalAvailable = tableData.totalRecords || '0';
        return (
            <div className={customStyles['available-container']}>
                <div style={styles.assetsHeader}>
                    <span className={customStyles['available-heading-content']} >Available Assets </span>
                    <span className={customStyles['asset-search-wrapper']}>
                        <input
                            type="text"
                            name="search"
                            className={customStyles['asset-search-box']}
                            autoComplete="off"
                            onChange={this.handleSearchChange}
                        />
                    </span>
                </div>
                <div className={customStyles['available-page-info']}>Showing {totalLoaded} of {totalAvailable} </div>
                <div style={{ flex: 1 }}>
                    <div style={styles.tableWrapper} onScroll={this.onTableScroll} >
                        <table>
                            <thead>
                                <tr className={customStyles['table-tr']}>
                                    <th className={customStyles['table-th']}>
                                        <Checkbox
                                            checked={allSelected}
                                            onChange={
                                                tableData && tableData.listData &&
                                                tableData.listData.length > 0 ?
                                                    this.onSelectAllClicked
                                                    :
                                                    () => {}
                                            }
                                            style={{ padding: 0 }}
                                        />
                                    </th>
                                    {this.headersArray().map(header => (
                                        <th className={customStyles['table-th']} key={header.key}>
                                            <span className={customStyles['available-table-header']}>
                                                <TableSortLabel
                                                    active={orderBy === header.value}
                                                    direction={order}
                                                    onClick={() => {
                                                        this.createSortHandler(header.value);
                                                    }}
                                                >
                                                    {header.key}
                                                </TableSortLabel>
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableData && tableData.listData ?
                                    tableData.listData.map((row, index) =>
                                        (
                                            <tr className={customStyles['table-tr']} key={row.id}>
                                                <td className={customStyles['checkbox-td']} >
                                                    <Checkbox
                                                        onChange={this.handleItemSelect(row, index)}
                                                        style={{ padding: 0 }}
                                                        color="primary"
                                                        checked={this.isSelected(row.id)}
                                                    />
                                                </td>
                                                <td>{row.name}</td>
                                                <td>{row.deviceSerialNumber}</td>
                                            </tr>))
                                    : null
                                }
                            </tbody>
                        </table>
                        {this.props.isAssetsListUpdating &&
                        !this.props.isAssetGroupDetailsFetching ?
                            this.getLoadingIndicator() : null}
                    </div>
                </div>
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{ fontSize: '16px' }}>
                            Are you sure you want to add all assets?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ p: 1.25, m: 1 }}>
                        <Button onClick={this.handleClose}>
                            No
                        </Button>
                        <Button onClick={this.confirmSelectAll} color="primary" variant="contained" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
export default AssetGroupList;
