/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_KMLS, DELETE_KMLS, ADD_KML } from './constants.kml';
import { fetchKmlSuccess, fetchKmlError, deleteKmlSuccess, deleteKmlError, addKmlSuccess, addKmlError } from './actions.kml';
import config from '../../constants/Config';
import { getUserToken } from './../../helper-classes/utility-functions';

export const apiUrl = `${config.get('FLEET_SERVICES_URL')}/kmls`;
export const getHeader = () => ({
    'X-Nspire-UserToken': getUserToken(),
});

type ObservarblesTypes = {
    getJSON: Function,
    of: Function,
    deleteJSON: Function,
    postJSON: Function,
};

const formatData = data => ({
    total: data.total,
    max: data.count,
    data: data.content,
});

export const getKmls =
    (actions$: Function, store: Object, { of, getJSON }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_KMLS)
            .mergeMap(() => getJSON(`${apiUrl}?cb=${new Date().getTime()}`, getHeader())
                .mergeMap(res => of(fetchKmlSuccess(formatData(res))))
                .catch(error => of(fetchKmlError(error))));

export const deleteKml =
(actions$: Function, store: Object, { of, deleteJSON }: ObservarblesTypes) =>
    actions$
        .ofType(DELETE_KMLS)
        .mergeMap((action) => {
            const { id } = action.payload;
            return deleteJSON(`${apiUrl}/${id}`, getHeader())
                .mergeMap(res => of(deleteKmlSuccess(res)))
                .catch(error => of(deleteKmlError(error.response)));
        });
export const addKml =
        (actions$: Function, store: Object, { of, postJSON }: ObservarblesTypes) =>
            actions$
                .ofType(ADD_KML)
                .mergeMap((action) => {
                    const { formData } = action.payload;
                    return postJSON(apiUrl, formData, getHeader())
                        .mergeMap(res => of(addKmlSuccess(res.response)))
                        .catch(error => of(addKmlError(error.response)));
                });
export default combineEpics(getKmls, deleteKml, addKml);
