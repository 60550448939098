/* @flow */
export const settingData = [{
    grpKey: 'exceed-speed-threshold',
    grpDText: 'Speed Threshold (MPH)',
    grpDTextInKm: 'Speed Threshold (KPH)',
    disableKpi: false,
    data: [
        {
            settKey: 'est71To80',
            settDText: 'Over 70 - Under 80',
            settDTextInKm: 'Over 113 - Under 129',
            enabled: false,
            sliderClass: '',
            weightage: 1,
        },
        {
            settKey: 'est81To90',
            settDText: '80 through 89',
            settDTextInKm: '129 through 144',
            enabled: false,
            sliderClass: '',
            weightage: 1,
        },
        {
            settKey: 'estGt90',
            settDText: '90 and Above',
            settDTextInKm: '145 and Above',
            enabled: false,
            sliderClass: '',
            weightage: 1,
        },
    ],
},
{
    grpKey: 'exceed-posted-speed',
    grpDText: 'Posted Speed (MPH Over Limit)',
    grpDTextInKm: 'Posted Speed (KPH Over Limit)',
    disableKpi: false,
    data: [
        {
            settKey: 'eps10To20',
            settDText: 'Over 10 - Under 20',
            settDTextInKm: 'Over 16 - Under 32',
            enabled: false,
            sliderClass: '',
            weightage: 1,
        },
        {
            settKey: 'eps21To30',
            settDText: '20 through 29',
            settDTextInKm: '32 through 47',
            enabled: false,
            sliderClass: '',
            weightage: 1,
        },
        {
            settKey: 'epsGt30',
            settDText: '30 and Above',
            settDTextInKm: '48 and Above',
            enabled: false,
            sliderClass: '',
            weightage: 1,
        },
    ],
},
{
    grpKey: 'idling',
    grpDText: 'Idle Time (Min)',
    grpDTextInKm: 'Idle Time (Min)',
    disableKpi: false,
    data: [
        {
            settKey: 'idle6To18',
            settDText: 'Over 6 - Under 18',
            settDTextInKm: 'Over 6 - Under 18',
            enabled: false,
            sliderClass: '',
            weightage: 1,
        },
        {
            settKey: 'idle19To30',
            settDText: '18 through 29',
            settDTextInKm: '18 through 29',
            enabled: false,
            sliderClass: '',
            weightage: 1,
        },
        {
            settKey: 'idleGt30',
            settDText: '30 and Above',
            settDTextInKm: '30 and Above',
            enabled: false,
            sliderClass: '',
            weightage: 1,
        },
    ],
},
{
    grpKey: 'aggressive-driving',
    grpDText: 'Aggressive Driving',
    grpDTextInKm: 'Aggressive Driving',
    disableKpi: false,
    data: [
        {
            settKey: 'hardBrake',
            settDText: 'Hard Braking',
            settDTextInKm: 'Hard Braking',
            enabled: false,
            sliderClass: '',
            weightage: 1,
        },
        {
            settKey: 'hardAccel',
            settDText: 'Hard Acceleration',
            settDTextInKm: 'Hard Acceleration',
            enabled: false,
            sliderClass: '',
            weightage: 1,
        },
    ],
},
{
    grpKey: 'safety',
    grpDText: 'Safety',
    grpDTextInKm: 'Safety',
    disableKpi: true,
    data: [
        {
            settKey: 'seatBelt',
            settDText: 'No Seatbelt',
            settDTextInKm: 'No Seatbelt',
            enabled: false,
            sliderClass: '',
            weightage: 1,
        },
    ],
}];

export const dummy = 'test';
