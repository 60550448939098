import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';

export type Props = {
    onChange: Function,
    inputRef: any,
};

class TextInput extends Component<Props, {}> {
    checkHours = (value) => {
        if (value[0] === '1') {
            return /[0-2]/;
        }
        return /[0-9]/;
    };

    render() {
        const { onChange, inputRef, ...other } = this.props;
        return (
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                mask={[
                    /[0-1]/,
                    this.checkHours(other.value),
                    ':',
                    /[0-5]/,
                    /[0-9]/,
                    ':',
                    /[0-5]/,
                    /[0-9]/,
                    ' ',
                    /[AaPp]/,
                    /[Mm]/,
                ]}
                onChange={e => onChange(e)}
                placeholder="HH:MM:SS"
            />
        );
    }
}

export default TextInput;
