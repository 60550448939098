/* @flow */

export const ACCOUNT_DETAIL: string = 'ACCOUNT_DETAIL';
export const ACCOUNT_DETAIL_SUCCESS: string = 'ACCOUNT_DETAIL_SUCCESS';
export const ACCOUNT_DETAIL_ERROR: string = 'ACCOUNT_DETAIL_ERROR';
export const SAVE_ACCOUNT_DETAIL: string = 'SAVE_ACCOUNT_DETAIL';
export const GET_ACCOUNT_DETAIL: string = 'GET_ACCOUNT_DETAIL';
export const GET_USER_BY_IDENTITY_SUCCESS: string = 'GET_USER_BY_IDENTITY_SUCCESS';
export const SAVE_ACCOUNT_DETAIL_SUCCESS: string = 'SAVE_ACCOUNT_DETAIL_SUCCESS';
export const SAVE_ACCOUNT_DETAIL_ERROR: string = 'SAVE_ACCOUNT_DETAIL_ERROR';
export const SAVE_APPSETTINGS: string = 'SAVE_APPSETTINGS';
export const SAVE_APPSETTINGS_SUCCESS: string = 'SAVE_APPSETTINGS_SUCCESS';
export const SAVE_APPSETTINGS_ERROR: string = 'SAVE_APPSETTINGS_ERROR';
export const SAVE_PREFERENCES: string = 'SAVE_PREFERENCES';
export const SAVE_PREFERENCES_SUCCESS: string = 'SAVE_PREFERENCES_SUCCESS';
export const SAVE_PREFERENCES_ERROR: string = 'SAVE_PREFERENCES_ERROR';
export const GET_USER_SETTINGS: string = 'GET_USER_SETTINGS';
export const GET_USER_SETTINGS_SUCCESS: string = 'GET_USER_SETTINGS_SUCCESS';
export const GET_USER_SETTINGS_ERROR: string = 'GET_USER_SETTINGS_ERROR';


export const UPDATED_SUCCESSFULLY: string = 'Updated successfully';
export const FAILED_TO_UPDATE_DATA: string = 'Failed to update data!';
export const PLEASE_ENTER_FIRSTNAME: string = 'Please Enter firstName.';
export const PLEASE_ENTER_LASTNAME: string = 'Please Enter lastName.';
export const PLEASE_ENTER_VALID_EMAIL: string = 'Please Enter valid email.';
export const PLEASE_ENTER_VALID_PHONE: string = 'Please Enter valid phone.';
export const NOTHING_TO_UPDATE: string = 'Nothing to update';
export const PLEASE_ENTER_VALID_CURR_PWD: string = 'Please Enter Your Current Password.';
export const PLEASE_ENTER_VALID_NEW_PWD: string = 'Please Enter Valid Password.';
export const PLEASE_ENTER_VALID_PWD: string = 'Please Enter A Valid Password, must contain at-least 1 numeric, 1 character and 1 special character and minimum length should be 6.';
export const CONFIRM_NEW_PWD_DONT_MATCH: string = 'Confirm password and new passwords do not match.';
export const CONFIRM_PASSOWORD_FIELDS_EMPTY: string = 'Confirm password should not be empty.';
export const OLD_AND_NEW_PWD_MATCH: string = 'New password cannot be same as old password';

export const UPDATE_ACCOUNT_DETAIL_LOADER: string = 'UPDATE_ACCOUNT_DETAIL_LOADER';
export const APP_DEFAULT_PAGE: string = 'home';
export const ACCEPT_RESELLER_TERMS_AND_CONDITION: string = 'ACCEPT_RESELLER_TERMS_AND_CONDITION';
export const ACCEPT_RESELLER_TERMS_AND_CONDITION_SUCCESS: string = 'ACCEPT_RESELLER_TERMS_AND_CONDITION_SUCCESS';
export const ACCEPT_RESELLER_TERMS_AND_CONDITION_ERROR: string = 'ACCEPT_RESELLER_TERMS_AND_CONDITION_ERROR';
