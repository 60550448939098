/* @flow */
import {
    GLOBAL_SEARCH,
    GLOBAL_SEARCH_ERROR,
    GLOBAL_SEARCH_SUCCESS,
    CategoryTypes,
    USER_IDENTITY_SUCCESS,
    USER_IDENTITY_ERROR,
    GLOBAL_SEARCH_UPDATE_TAB,
    UPDATE_USER_IDENTITY_LOADER,
    UPDATE_GLOBAL_SEARCH,
    NEAREST_ASSET_LANDMARK_ERROR,
    NEAREST_ASSET_LANDMARK_SUCCESS,
    NEAREST_ASSET_LANDMARK,
} from './contants.globalsearch';
import type { typeAddresses, typeAssets, typeDrivers, typeLandmarks } from './types';

export const defaultValues = {
    data: [],
    loader: false,
    count: 0,
    total: 0,
    isLoading: false,
};
export const initialState = {
    assets: {
        ...defaultValues,
        category: CategoryTypes.ASSETS,
    },
    landmarks: {
        ...defaultValues,
        category: CategoryTypes.LANDMARKS,
    },
    drivers: {
        ...defaultValues,
        category: CategoryTypes.DRIVERS,
    },
    addresses: {
        ...defaultValues,
        category: CategoryTypes.ADDRESSES,
    },
    userIdentity: {
        user: {
            firstName: '',
            lastName: '',
            email: '',
        },
    },
    query: '',
    tab: 0,
};


type stateType = {
    addresses: typeAddresses,
    assets: typeAssets,
    landmarks: typeLandmarks,
    drivers: typeDrivers
};

type actionType = {
    type: string,
    payload :{
        response:{
            addresses:[],
            asset:{
                data: [],
                count: number,
                total: number,
                isLoading: boolean
            },
            landmark:{
                data: [],
                count: number,
                total: number,
                isLoading: boolean
            },
            driver:{
                data: [],
                count: number,
                total: number,
                isLoading: boolean
            },
        },
        query: string,
        tab: number,
        assetOrLandmarkId: string,
    },
}
// TODO, Need to work on the `payload.response` once the actual API is integrated
export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case GLOBAL_SEARCH: {
        const addresses = {
            ...state.addresses,
            ...defaultValues,
            loader: true,
            isLoading: true,
        };
        const assets = {
            ...state.assets,
            ...defaultValues,
            loader: true,
            isLoading: true,
        };
        const landmarks = {
            ...state.landmarks,
            ...defaultValues,
            loader: true,
            isLoading: true,
        };
        const drivers = {
            ...state.drivers,
            ...defaultValues,
            loader: true,
            isLoading: true,
        };

        return {
            ...state,
            addresses,
            assets,
            landmarks,
            drivers,
            query: action.payload.query,
        };
    }
    case UPDATE_GLOBAL_SEARCH: {
        return {
            ...state,
            query: action.payload.query || '',
        };
    }
    case GLOBAL_SEARCH_UPDATE_TAB: {
        return {
            ...state,
            tab: action.payload.tab,
        };
    }
    case GLOBAL_SEARCH_SUCCESS: {
        const {
            addresses: googlePlaces,
            asset: { data: assetsData, count: assetsCount, total: assetsTotal },
            driver: { data: driversData, count: driversCount, total: driversTotal },
            landmark: { data: landmarksData, count: landmarksCount, total: landmarksTotal },
        } = action.payload.response;
        const addresses = {
            ...state.addresses,
            data: googlePlaces,
            count: (googlePlaces) ?
                (googlePlaces).length : 0,
            total: (googlePlaces) ?
                (googlePlaces).length : 0,
            loader: false,
            isLoading: false,
        };
        const assets = {
            ...state.assets,
            data: assetsData,
            count: assetsCount || 0,
            total: assetsTotal || 0,
            loader: false,
            isLoading: false,
        };
        const landmarks = {
            ...state.landmarks,
            data: landmarksData,
            count: landmarksCount || 0,
            total: landmarksTotal || 0,
            loader: false,
            isLoading: false,
        };
        const drivers = {
            ...state.drivers,
            data: driversData,
            count: driversCount || 0,
            total: driversTotal || 0,
            loader: false,
            isLoading: false,
        };

        return {
            ...state,
            addresses,
            assets,
            landmarks,
            drivers,
        };
    }
    case GLOBAL_SEARCH_ERROR: {
        const addresses = {
            ...state.addresses,
            ...defaultValues,
        };
        const assets = {
            ...state.assets,
            ...defaultValues,
        };
        const landmarks = {
            ...state.landmarks,
            ...defaultValues,
        };
        const drivers = {
            ...state.drivers,
            ...defaultValues,
        };
        return {
            ...state,
            addresses,
            assets,
            landmarks,
            drivers,
        };
    }
    case USER_IDENTITY_SUCCESS:
        return {
            ...state,
            userIdentity: action.payload.response,
            isLoader: false,
        };
    case USER_IDENTITY_ERROR:
        return {
            ...state,
            isLoader: false,
        };
    case UPDATE_USER_IDENTITY_LOADER:
        return {
            ...state,
            isLoader: true,
        };
    case NEAREST_ASSET_LANDMARK:
        return {
            ...state,
            isLoader: true,
            assetOrLandmarkId: '',
        };
    case NEAREST_ASSET_LANDMARK_SUCCESS:
        return {
            ...state,
            assetOrLandmarkId: action.payload.assetOrLandmarkId,
            isLoader: false,
        };
    case NEAREST_ASSET_LANDMARK_ERROR:
        return {
            ...state,
            isLoader: false,
            assetOrLandmarkId: '',
        };
    default:
        return state;
    }
};
