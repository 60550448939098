/* @flow */
import React from 'react';
import { Divider, TableSortLabel, Checkbox, Card, Button, Select, MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import LandmarkGroupList from './LandmarkGroupList';
import styles from './LandmarkGroupManageStyles';
import customStyles from './LandmarkGroupManage.module.scss';
import { getPermissionValue, getParentGroupsRelation, formatLandmarkAddress } from '../../helper-classes/utility-functions';
import analytics from '../../analytics/index';
import { getLandmarkGroupsFVS, getLandmarkGroupById, getSelectedLandmarkDbId } from './../../services/landmark/landmarkGroup';
import dropdownNested from '../../assets/icons/dropdown-nested.svg';
import Dialog from '../SharedComponents/Dialog/Dialog';
import { SOMETHING_WENT_WRONG } from './../../containers/Landmark/LandmarkGroupManage/constants.landmarkGroupManage';

type Props = {
    landmarks: any,
    totalLandmarks: number,
    loadLandmarks: Function,
    isLandmarksListUpdating: boolean,
    postLandmarkGroup: Function,
    updateLandmarkGroup: Function,
    selectedLandmarks: any,
    actionType: string,
    selectedLandmarkGroup: Object,
    parentGroup: Object,
    history: Object,
    isFetchingLandmarkGroupDetails: boolean,
    resetLandmark: Function,
    selectedLandmarkGroup: any,
    showLoader: Function,
};
type State = {
    selectedLandmarks: any,
    groupName: string,
    orderBy: string,
    order: string,
    landmarkGroups: [],
    landmarkParentGroups: Array<Object>,
    parentGroupSelected: number,
    isError: boolean,
};

class LandmarkGroupManage extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            selectedLandmarks: [],
            orderBy: 'name',
            order: 'asc',
            landmarkGroups: [],
            landmarkParentGroups: [],
            parentGroupSelected: -1,
            groupName: '',
            isError: false,
        };
    }

    componentDidMount = () => {
        getLandmarkGroupsFVS().then((response) => {
            if (response && response.data) {
                this.setState({
                    landmarkGroups: response.data,
                    landmarkParentGroups: getParentGroupsRelation(response.data),
                });
            }
        });
        this.setState({
            selectedLandmarks: [],
        });
        ValidatorForm.addValidationRule('isMin3Characters', (value) => {
            if (value.length < 3) {
                return false;
            }
            return true;
        });
    }

    UNSAFE_componentWillReceiveProps = (nextProps: any) => {
        this.setState({ selectedLandmarks: nextProps.selectedLandmarks });

        if (nextProps.parentGroup && nextProps.parentGroup.id) {
            this.setState({
                parentGroupSelected: nextProps.parentGroup.id,
            });
        }

        if (nextProps.selectedLandmarkGroup && nextProps.selectedLandmarkGroup.name
            && this.props.selectedLandmarkGroup.name !== nextProps.selectedLandmarkGroup.name) {
            this.setState({
                groupName: nextProps.selectedLandmarkGroup.name,
                parentGroupSelected: nextProps.selectedLandmarkGroup.parentGroupId || -1,
            });
        }
    }

    resetSelectedLandmarks = () => {
        this.setState({ selectedLandmarks: [] });
        this.resetLandmarks([]);
    }

    selectLandmarks = (landmarksToAdd: Object) => {
        const { selectedLandmarks } = this.state;
        const isLandmarkFound = selectedLandmarks
            .some(l => l.id === landmarksToAdd.id);

        if (!isLandmarkFound) {
            selectedLandmarks.push(landmarksToAdd);
        }
        this.resetLandmarks(this.state.selectedLandmarks);
    }

    addBulkLandmarks = (landmarksToAdd: Array<Object>) => {
        const { selectedLandmarks } = this.state;
        const newLandmarks = [...selectedLandmarks];
        landmarksToAdd.forEach((l) => {
            const isLandmarkFound = selectedLandmarks.some(sl => sl.id === l.id);
            if (!isLandmarkFound) {
                newLandmarks.push(l);
            }
        });
        this.setState({ selectedLandmarks: newLandmarks });
        this.resetLandmarks(newLandmarks);
    }

    removeLandmark = (landmarkToRemove: Object) => {
        const selectedLandmarks = this.state.selectedLandmarks.filter(landmark => (
            landmarkToRemove.id !== landmark.id
        ));
        this.resetLandmarks(selectedLandmarks);
    }

    resetLandmarks = (selectedLandmarks: any) => {
        const params = {
            selectedLandmarks,
            selectedLandmarkGroups: this.props.selectedLandmarkGroup,
            clearResult: true,
        };
        this.props.resetLandmark(params, 50);
    }

    clickSelectedElement = (row: Object) => (event: any) => {
        if (!event.target.checked) {
            this.removeLandmark(row);
        }
    }

    getSelectedLandmarks() {
        const { selectedLandmarks } = this.state;
        return selectedLandmarks.map(row => (
            <tr className={customStyles['table-tr']} key={row.id}>
                <td className={customStyles['checkbox-td']} >
                    <Checkbox
                        onChange={this.clickSelectedElement(row)}
                        style={{ padding: 0, color: '#007aff' }}
                        checked
                    />
                </td>
                <td>{row.name}</td>
                <td>{formatLandmarkAddress(row)}</td>
            </tr>
        ));
    }

    getMembersDbIdAndSave = () => {
        let members = this.state.selectedLandmarks.map(a => a.id);
        if (members && members.length > 0) {
            this.props.showLoader(true);
            getSelectedLandmarkDbId(members).then((res: Object) => {
                if (res.data && res.data.length > 0) {
                    members = res.data;
                    this.saveLandmarkGroup(members);
                } else if (res.isError) {
                    this.props.showLoader(false);
                    this.setState({ isError: true });
                } else {
                    this.props.showLoader(false);
                    this.saveLandmarkGroup([]);
                }
            });
        } else {
            this.saveLandmarkGroup(members);
        }
    }

    saveLandmarkGroup = (members: Array<string>) => {
        const requestBody = {
            newRecords: [],
            removedRecords: [],
            updatedRecords: [],
        };
        const { groupName } = this.state;
        const parentGroupId = this.state.parentGroupSelected
            || parseInt(this.props.parentGroup.id, 10);
        const params = {
            feature: 'FLEET_Landmark_Group',
            group: this.state.parentGroupSelected !== -1 ? 'true' : 'false',
            selectedAssets: this.state.selectedLandmarks.length ? 'true' : 'false',
        };
        if (this.props.actionType === 'add') {
            requestBody.newRecords = [
                {
                    description: groupName,
                    id: 0,
                    members,
                    name: groupName,
                    parentGroupId,
                },
            ];
            if (parentGroupId && parentGroupId !== -1) {
                getLandmarkGroupById(parentGroupId).then((res: Object) => {
                    if (res.data) {
                        requestBody.newRecords[0].parentGroupId = res.data.id;
                    }
                    this.props.postLandmarkGroup(requestBody, groupName);
                });
            } else {
                this.props.postLandmarkGroup(requestBody, groupName);
            }
            if (this.state.parentGroupSelected !== -1) {
                analytics.track('ADD_SUBGROUP', params);
            } else analytics.track('ADD_LANDMARK_GROUP', params);
        } else if (this.props.actionType === 'edit') {
            getLandmarkGroupById(this.props.selectedLandmarkGroup.id).then((res: Object) => {
                const selectedLandmarkGroup = res.data;
                requestBody.updatedRecords = [
                    {
                        description: groupName,
                        id: selectedLandmarkGroup.id || 0,
                        members,
                        name: groupName,
                        parentGroupId,
                    },
                ];

                if (this.state.parentGroupSelected === -1 ||
                    this.state.parentGroupSelected ===
                        this.props.selectedLandmarkGroup.parentGroupId) {
                    if (this.state.parentGroupSelected !== -1) {
                        requestBody.updatedRecords[0].parentGroupId =
                        selectedLandmarkGroup.parentGroupId;
                    }
                    this.props.postLandmarkGroup(requestBody, groupName);
                } else {
                    const filterResult = this.state.landmarkParentGroups
                        .filter(f => f.id === this.state.parentGroupSelected).pop();
                    const selectedGroupData = this.state.landmarkParentGroups
                        .filter(f => f.id ===
                            this.props.selectedLandmarkGroup.parentGroupId).pop() || {};
                    getLandmarkGroupById(filterResult.id).then((pGroupData) => {
                        const selectedPGroupData = pGroupData.data;
                        let firstCallUpdatedRecords = [
                            {
                                description: groupName,
                                id: selectedLandmarkGroup.id || 0,
                                members,
                                name: groupName,
                                parentGroupId: selectedLandmarkGroup.parentGroupId || -1,
                            },
                            {
                                description: selectedPGroupData.name || '',
                                id: selectedPGroupData.id || 0,
                                name: selectedPGroupData.name || '',
                                parentGroupId: -1,
                            },
                        ];

                        let secondCallUpdateRecord = [{
                            description: groupName,
                            id: selectedLandmarkGroup.id || 0,
                            name: groupName,
                            parentGroupId: selectedPGroupData.id || -1,
                        },
                        {
                            description: selectedPGroupData.name || '',
                            id: selectedPGroupData.id || 0,
                            name: selectedPGroupData.name || '',
                            parentGroupId: selectedLandmarkGroup.parentGroupId || -1,
                        }];

                        if (selectedGroupData.superParent !== filterResult.superParent) {
                            firstCallUpdatedRecords = [
                                {
                                    description: groupName,
                                    id: selectedLandmarkGroup.id || 0,
                                    members,
                                    name: groupName,
                                    parentGroupId: -1,
                                },
                            ];
                            secondCallUpdateRecord = [
                                {
                                    description: groupName,
                                    id: selectedLandmarkGroup.id || 0,
                                    name: groupName,
                                    parentGroupId: selectedPGroupData.id || -1,
                                },
                            ];
                        } else if (!filterResult.parentId ||
                                    filterResult.parentId <= 0
                        ) {
                            secondCallUpdateRecord = [
                                {
                                    description: groupName,
                                    id: selectedLandmarkGroup.id || 0,
                                    name: groupName,
                                    parentGroupId: selectedPGroupData.id || -1,
                                },
                            ];
                        }

                        const dataForUpdate = {
                            firstCallData: {
                                newRecords: [],
                                removedRecords: [],
                                updatedRecords: firstCallUpdatedRecords,
                            },
                            secondCallData: {
                                newRecords: [],
                                removedRecords: [],
                                updatedRecords: secondCallUpdateRecord,
                            },
                        };
                        this.props.updateLandmarkGroup(dataForUpdate, groupName);
                    });
                }
            });
            analytics.track('EDIT_LANDMARK_GROUP', params);
        }
    };

    getSortedData = (data: Array<Object>, order: string, orderBy: string) => data.sort((a, b) => {
        const sd1 = a[orderBy] || '';
        const sd2 = b[orderBy] || '';
        if (order === 'asc') {
            return sd1 < sd2 ? -1 : Number(sd1 > sd2);
        }
        return sd1 > sd2 ? -1 : Number(sd1 < sd2);
    });

    createSelectedSortHandler = (item: string) => {
        const order = (this.state.order === 'asc') ? 'desc' : 'asc';
        const selectedLandmarks =
        this.getSortedData([...this.state.selectedLandmarks], order, (item || '').toLowerCase());
        this.setState({
            orderBy: item,
            order,
            selectedLandmarks,
        });
    }

    renderCustomCriteriaSection = () => {
        const { selectedLandmarks, orderBy, order } = this.state;
        return (
            <div >
                <Divider />
                <div style={styles.selectedLandmarksContainer}>
                    <div style={styles.column}>
                        <LandmarkGroupList
                            selectedLandmarks={selectedLandmarks}
                            onLandmarksSelected={(selected, landmark) => {
                                if (selected) {
                                    this.selectLandmarks(landmark);
                                } else {
                                    this.removeLandmark(landmark);
                                }
                            }}
                            landmarks={this.props.landmarks}
                            totalLandmarks={this.props.totalLandmarks}
                            loadLandmarks={this.props.loadLandmarks}
                            isLandmarksListUpdating={this.props.isLandmarksListUpdating}
                            addBulkLandmarks={this.addBulkLandmarks}
                            isFetchingLandmarkGroupDetails={
                                this.props.isFetchingLandmarkGroupDetails
                            }
                        />
                    </div>
                    <div style={styles.column}>
                        <div style={styles.landmarksHeader}>
                            <span className={customStyles['selected-heading-text']}>
                                Selected Landmarks
                                <div className={customStyles['selected-page-info']}>
                                    Showing&nbsp;
                                    {this.state.selectedLandmarks &&
                                    this.state.selectedLandmarks.length ?
                                        this.state.selectedLandmarks.length : 0 }
                                    &nbsp;results
                                </div>
                            </span>
                            <button
                                type="button"
                                style={styles.reset}
                                onClick={
                                    this.state.selectedLandmarks &&
                                    this.state.selectedLandmarks.length > 0 ?
                                        this.resetSelectedLandmarks
                                        :
                                        () => {}
                                }
                            >
                                <span
                                    className={
                                        this.state.selectedLandmarks &&
                                        this.state.selectedLandmarks.length > 0 ?
                                            customStyles.resetText
                                            :
                                            customStyles.diabledResetText
                                    }
                                >
                                    RESET
                                </span>
                            </button>
                        </div>
                        <div style={styles.selectedWrapper}>
                            <table>
                                <thead>
                                    <tr className={customStyles['table-tr']}>
                                        <th className={customStyles['table-th']} key="blank-th" />
                                        {['Name', 'Address'].map(header => (
                                            <th className={customStyles['table-th']} key={header}>
                                                <span className={customStyles['available-table-header']}>
                                                    <TableSortLabel
                                                        active={orderBy === header}
                                                        direction={order}
                                                        onClick={() => {
                                                            this.createSelectedSortHandler(header);
                                                        }}
                                                    >
                                                        {header}
                                                    </TableSortLabel>
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.getSelectedLandmarks()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    changeGroupName = () => (event: any) => {
        this.setState({
            groupName: event.target.value,
        });
    };

    goTolandmarkListing = () => {
        this.props.history.push('/landmark-groups');
    }

    handleClickOk = () => {
        this.setState({ isError: false });
        this.props.history.goBack();
    }

    getParentGrpDropdownOptions = () => {
        if (this.state.landmarkGroups.length <= 0) {
            return false;
        }
        const value = this.state.landmarkGroups.map((grp: Object) => {
            if (this.props.selectedLandmarkGroup.id
                && this.props.selectedLandmarkGroup.id === grp.id) {
                return false;
            }
            const selected = grp.id === this.state.parentGroupSelected;
            const options = (
                <MenuItem
                    key={grp.id}
                    value={grp.id}
                    selected={selected}
                >
                    <span
                        style={{ paddingLeft: grp.nestLevel * 15 }}
                    >
                        {(grp.nestLevel !== 0) ? <img style={{ paddingRight: 6 }} src={dropdownNested} alt="dropdownNested" /> : ''}{`${grp.name}`}
                    </span>
                </MenuItem>
            );
            return options;
        });
        return value;
    }

    onParentDropdownChange = (e: any) => {
        this.setState({ parentGroupSelected: e.target.value });
    }

    getDropDownValue = (e: any) => {
        let menu = { name: 'Select Group' };
        this.state.landmarkGroups.some((d: Object) => {
            if (d.id === e) {
                menu = d;
                return true;
            }
            return false;
        });
        return menu.name;
    }

    render() {
        let section = null;
        section = this.renderCustomCriteriaSection();
        const { groupName, isError } = this.state;
        const { parentGroup, actionType } = this.props;
        return (
            <Card className={customStyles['content-wrapper']}>
                <div className={customStyles['header-wrapper']}>
                    <div
                        className={customStyles['back-arrow-icon']}
                        tabIndex={0}
                        onClick={this.goTolandmarkListing}
                        onKeyDown={() => {}}
                        role="button"
                    />
                    <div className={customStyles['header-text']}>
                        {parentGroup && parentGroup.id !== 0 ?
                            <div>
                                <div>{actionType === 'add' ? 'Add' : 'Edit'} Sub Group</div>
                                <div className={customStyles['subgroup-text']}>
                                    {parentGroup.name}
                                </div>
                            </div>
                            :
                            <div>{actionType === 'add' ? 'Add' : 'Edit'} Landmark Group</div>
                        }
                    </div>
                    <div className={customStyles['header-pull-right']}>
                        <span className={customStyles['required-text']}>* Required Field</span>
                    </div>
                </div>
                <ValidatorForm
                    onSubmit={this.getMembersDbIdAndSave}
                >
                    <div style={styles.container}>
                        <div style={styles.header}>
                            <TextValidator
                                style={styles.textField}
                                value={groupName || ''}
                                onChange={this.changeGroupName()}
                                label="*Name"
                                name="name"
                                validators={['required', 'isMin3Characters']}
                                errorMessages={['Name is required', 'Name should be atleast 3 characters']}
                                autoComplete="off"
                            />
                            <div style={styles.parentGrpDropdown}>
                                <Select
                                    value={this.state.parentGroupSelected}
                                    onChange={(e) => {
                                        this.onParentDropdownChange(e);
                                    }}
                                    inputProps={{
                                        name: 'landmark-groups',
                                        id: 'landmark-groups',
                                    }}
                                    renderValue={e => this.getDropDownValue(e)}
                                    sx={{ minWidth: '125px' }}
                                >
                                    <MenuItem
                                        key="select-parent-group"
                                        value={-1}
                                        selected={this.state.parentGroupSelected === -1}
                                    >
                                        Select Group
                                    </MenuItem>
                                    {this.getParentGrpDropdownOptions()}
                                </Select>
                            </div>
                        </div>
                        {section}
                    </div>
                    <div className={customStyles['content-footer']}>
                        <div className={customStyles['action-buttons-wrapper']}>
                            <Button onClick={this.goTolandmarkListing} className={customStyles['cancel-button-label']}>
                                CANCEL
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disableRipple
                                disabled={getPermissionValue('Admin') !== 'Modify'}
                                type="submit"
                                sx={{ minHeight: '36px !important' }}
                            >
                                <span className={customStyles['save-button-label']}>SAVE</span>
                            </Button>
                        </div>
                    </div>
                </ValidatorForm>
                <Dialog
                    open={isError}
                    type="error"
                    customTitle=""
                    button={[{
                        title: 'OK',
                        color: 'primary',
                        variant: 'contained',
                        handleClick: () => this.handleClickOk(),
                    }]}
                    content={SOMETHING_WENT_WRONG}
                    size="lg"
                />
            </Card>
        );
    }
}

export default withStyles(styles)(LandmarkGroupManage);
