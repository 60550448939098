/* @flow */

export const FETCH_SCORECARDS_LIST: string = 'FETCH_SCORECARD_LIST';
export const FETCH_SCORECARDS_LIST_SUCCESS: string = 'FETCH_SCORECARD_LIST_SUCCESS';
export const FETCH_SCORECARDS_LIST_ERROR: string = 'FETCH_SCORECARD_LIST_ERROR';
export const RESET_SCORECARDS_LIST: string = 'RESET_SCORECARD_LIST';
export const INFRACTION_CENTRIC_SCORECARD_PREF_KEY: string = 'fleetLocate.infractionCentricScorecard';
export type ScoreCardsListTypeAction = {
    type: string;
    payload?: Object;
}
