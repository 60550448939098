/* @flow */
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export type Props = {
    open: boolean,
    handleClose: Function,
    dialogTitle: string,
    dialogMessage: string,
    dialogBtn1Text: string,
    dialogBtn1Click: Function,
    dialogBtn2Text?: string,
    dialogBtn2Click?: Function,
};

const SaveErrorDialog = (props: Props) => {
    const { dialogTitle } = props;

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                data-qa={`${dialogTitle}_dialogBox`}
            >
                <DialogTitle id="alert-dialog-title" data-qa="dialog_title">{dialogTitle}</DialogTitle>
                <DialogContent data-qa="dialog_content">
                    <DialogContentText id="alert-dialog-description" data-qa="dialog_msg" sx={{ fontSize: '16px' }}>
                        {props.dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ p: 1.25, m: 1 }} data-qa="dialog_actions" >
                    { props.dialogBtn1Text ?
                        <Button onClick={props.dialogBtn1Click} color="primary" variant="contained" autoFocus data-qa="dialog_btn1">
                            {props.dialogBtn1Text}
                        </Button>
                        : ''
                    }
                    { props.dialogBtn2Text ?
                        <Button onClick={props.dialogBtn2Click} variant="contained" autoFocus data-qa="dialog_btn2">
                            {props.dialogBtn2Text}
                        </Button>
                        : ''
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
};

SaveErrorDialog.defaultProps = {
    dialogBtn2Text: '',
    dialogBtn2Click: () => {},
};

export default SaveErrorDialog;
