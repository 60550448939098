/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_ALERT_NAME_LIST } from './constants.alertNameCheck';
import { fetchAlertListSuccess, fetchAlertListError } from './actions.alertNameCheck';
import config from '../../../../constants/Config';
import { getAuthorizationHeader, getGrailsAppBasedURL } from '../../../../helper-classes/utility-functions';

type ObservarblesTypes = {
    getJSON: Function,
    of: Function,
};

export const fetchAlertListAPI =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_ALERT_NAME_LIST)
            .debounceTime(config.get('DEBOUNCE_TIME'))
            .mergeMap((action) => {
                const headers = {
                    Authorization: getAuthorizationHeader(),
                    'Content-Type': 'application/x-www-form-urlencoded',
                };
                const filterContents = escape(`{"alertSpecName":"${action.payload.alertName}", "alertTypeId":${action.payload.alertTypeId}}`);
                return getJSON(`${getGrailsAppBasedURL()}/operation/json/alertSpecService/findAlertSpec?filters=${filterContents}&page=1&offset=0&max=250`, headers)
                    .map(result => fetchAlertListSuccess(result))
                    .catch(error => of(fetchAlertListError(error)));
            });

export default combineEpics(fetchAlertListAPI);
