/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.userAccessList';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../components/SharedComponents/ListComponent/ListComponent';
import { RECORDS_PER_PAGE } from '../../constants/Config';
import UserAccess from './../User/AddUserAccess/User';
import { getParamValue } from '../../helper-classes/utility-functions';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    isUpdatePreference,
} from '../../helper-classes/listUtil';
import AppLoader from '../../components/AppLoader';
import { USER_ACCESS_LIST_PREF_KEY } from './constants.userAccessList';
import analytics from '../../analytics';

export type Props = {
    isUpdating: boolean,
    fetchUserAccessList: Function,
    resetTable: Function,
    classes: Object,
    location: Object,
    history: {
        push: Function,
    },
    match: {
        path: any,
    },
};

export type State = {
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{}>,
        totalRecords: number,
        ischeckBox: boolean,
        isGroup: boolean,
        isEditable: boolean,
    },
    rowsPerPage: number,
    pageNumber: number,
    order: string,
    orderBy: string,
    isAddEdit: boolean,
    showListing: boolean,
};

const themeStyles = () => ({
    checked: { color: '#007aff' },
    label: { color: '#696969' },
    hide: { display: 'none' },
});

class UserAccessList extends Component<Props, State> {
    search: string;
    id: string;
    addUrl: string;
    listUrl: string;
    editUrl: string;
    addUrl: string;
    userPreferences: any;

    constructor(props: Props) {
        super(props);
        let isAddEdit = false;
        this.addUrl = '/user-access/add';
        this.listUrl = '/user-access';
        this.editUrl = '/user-access/edit/:id';
        if (getParamValue(this.props, 'id') || this.props.match.path === this.addUrl) {
            isAddEdit = true;
        }

        this.state = {
            tableData: {
                listHeaders: this.getListHeader(),
                isEditable: true,
                listData: [],
                totalRecords: 0,
                ischeckBox: false,
                isGroup: false,
            },
            rowsPerPage: RECORDS_PER_PAGE,
            pageNumber: 0,
            order: 'asc',
            orderBy: 'name',
            isAddEdit,
            showListing: false,
        };
        this.search = '';
        this.userPreferences = null;
    }

    UNSAFE_componentWillMount() {
        if (!this.state.isAddEdit) {
            getUserPreference(this, USER_ACCESS_LIST_PREF_KEY);
        }
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        const { userAcces } = nextprops;
        const { tableData } = this.state;

        if (userAcces.length > 0) {
            tableData.listData = userAcces;
            tableData.totalRecords = nextprops.totalUserAcces;
            this.setState({ tableData });
        } else if (!this.state.isAddEdit) {
            tableData.listData = [];
            tableData.totalRecords = 0;
            this.setState({ tableData });
        }
    }

    componentDidUpdate() {
        let { isAddEdit } = this.state;
        const { match } = this.props;

        if (isAddEdit && match.path === this.listUrl) {
            isAddEdit = false;
            this.getData();
            this.updateEditState(isAddEdit);
        } else if (!isAddEdit && (match.path === this.editUrl || match.path === this.addUrl)) {
            isAddEdit = true;
            this.updateEditState(isAddEdit);
        }
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            { name: 'Name', ...getColumnPreferences('name', preferences) },
            { description: 'Comments', ...getColumnPreferences('description', preferences) },
            { readOnly: 'Created By', ...getColumnPreferences('readOnly', preferences) },
        ];

        if (preferences) return getReArrangedTableHeaders(columns);
        return columns;
    }

    updateEditState = isAddEdit => this.setState({ isAddEdit });

    getData = () => {
        this.props.fetchUserAccessList(
            this.state.pageNumber,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.getFilterData(),
        );
        this.setState({ showListing: true });
    }

    updateState = (order, orderBy, rowsPerPage, pageNumber) => {
        const updateUserPref = isUpdatePreference(this, { rowsPerPage, order, orderBy });
        this.setState({
            order,
            orderBy,
            rowsPerPage,
            pageNumber,
        }, () => {
            if (updateUserPref) saveUserPreference(this, USER_ACCESS_LIST_PREF_KEY);
        });
    }

    loadNextPage = (rows, value, order, orderBy) => {
        this.updateState(order, orderBy, value, rows);
        this.props.fetchUserAccessList(
            rows,
            value,
            order,
            orderBy,
            this.getFilterData(),
        );
    }

    sortTableColumn = (order, orderBy, page) => {
        this.props.fetchUserAccessList(
            page.pageNumber,
            page.rowsPerPage,
            order,
            orderBy,
            this.getFilterData(),
        );
        this.updateState(order, orderBy, page.rowsPerPage, page.pageNumber);
        const params = {
            feature: 'Admin',
            sortColumn: orderBy,
        };
        analytics.track('USER_ACCESS_SORT', params);
    }

    ListSearch = (order, orderBy, filter) => {
        this.search = filter.search || '';
        this.props.fetchUserAccessList(
            filter.pageNumber,
            filter.rowsPerPage,
            order, orderBy,
            this.getFilterData(),
        );
        this.updateState(order, orderBy, filter.rowsPerPage, filter.pageNumber);
    }

    getFilterData = () => ({ search: this.search || '' })

    refreshList = (page) => {
        this.props.fetchUserAccessList(
            page.page,
            page.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.getFilterData(),
        );
    }

    listTypeContextAction = (action, page = { page: 0, rowsPerPage: RECORDS_PER_PAGE }) => {
        if (action === 'refresh') {
            this.refreshList(page);
        } else if (action === 'add') {
            this.props.history.push(`${this.props.location.pathname}/add`);
        }
        return true;
    }

    clickRow = (n:any) => this.props.history.push(`/user-access/edit/${n.id}`);

    rearrangeColumns = (listHeaders) => {
        const { tableData } = this.state;
        tableData.listHeaders = listHeaders;
        this.setState({ tableData }, () => {
            saveUserPreference(this, USER_ACCESS_LIST_PREF_KEY);
        });
    }

    showHideColumn = (header) => {
        const { tableData } = this.state;
        tableData.listHeaders.some((h) => {
            const newH = h;
            if (h[Object.keys(h)[0]] === header[Object.keys(header)[0]]) {
                newH.show = !header.show;
                return true;
            }
            return false;
        });

        this.setState({ tableData }, () => {
            saveUserPreference(this, USER_ACCESS_LIST_PREF_KEY);
        });
    }

    render() {
        const { isAddEdit } = this.state;
        const { history } = this.props;

        return (
            <MiniDrawer redirectTo={history.push}>
                {isAddEdit && <UserAccess /> }
                <div className={(!isAddEdit) ? '' : this.props.classes.hide}>
                    {this.state.showListing ?
                        <ListComponent
                            redirectTo={history.push}
                            tableData={this.state.tableData}
                            loadNextPage={this.loadNextPage}
                            isUpdating={this.props.isUpdating}
                            rearrangeColumns={this.rearrangeColumns}
                            resetTable={this.props.resetTable}
                            showHideColumn={this.showHideColumn}
                            sortTableColumn={this.sortTableColumn}
                            clickRow={this.clickRow}
                            tableTitle="User Access"
                            ListSearch={this.ListSearch}
                            listTypeContextAction={this.listTypeContextAction}
                            tableButtons={['Refresh', /* 'Edit', */ 'Add Items']}
                            resizableHeader
                            pathName={this.props.location.pathname}
                            orderBy={this.state.orderBy}
                            order={this.state.order}
                            rowsPerPage={this.state.rowsPerPage}
                        />
                        :
                        <div style={{ height: '100%' }}>
                            <AppLoader type="fullScreen" />
                        </div>
                    }
                </div>
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => ({
    userAcces: state.userAccessList.userAccess,
    totalUserAcces: state.userAccessList.totalUserAccess,
    isUpdating: state.userAccessList.isUpdating,
    orderBy: state.orderBy,
});

export default withStyles(themeStyles)(reduxConnect(UserAccessList, actions, mapStateToProps));
