/* @flow */

import {
    FETCH_USERROLE,
    FETCH_USERROLE_SUCCESS,
    FETCH_USERROLE_ERROR,
    FETCH_USERROLES,
    FETCH_USERROLES_SUCCESS,
    FETCH_USERROLES_ERROR,
    ADD_USERROLE,
    ADD_USERROLE_SUCCESS,
    ADD_USERROLE_ERROR,
    EDIT_USERROLE,
    EDIT_USERROLE_SUCCESS,
    EDIT_USERROLE_ERROR,
    DELETE_USERROLE,
    DELETE_USERROLE_SUCCESS,
    DELETE_USERROLE_ERROR,
    UPDATE_USERROLE_LOADER,
    CLOSE_USERROLE_DIALOG,
} from './constants.userRole';
import type { UserRoleTypeAction } from './constants.userRole';


export const fetchUserRole = (id: any): UserRoleTypeAction => ({
    type: FETCH_USERROLE,
    payload: { id },
});

export const fetchUserRoleSuccess = (userRole: any): UserRoleTypeAction => ({
    type: FETCH_USERROLE_SUCCESS,
    payload: { userRole },
});

export const fetchUserRoleError = (error: any): UserRoleTypeAction => ({
    type: FETCH_USERROLE_ERROR,
    payload: { error },
});

export const fetchUserRoles = (): UserRoleTypeAction => ({
    type: FETCH_USERROLES,
    payload: {},
});

export const fetchUserRolesSuccess = (userRoles: any): UserRoleTypeAction => ({
    type: FETCH_USERROLES_SUCCESS,
    payload: { userRoles },
});

export const fetchUserRolesError = (error: any): UserRoleTypeAction => ({
    type: FETCH_USERROLES_ERROR,
    payload: { error },
});

export const addUserRole = (requestBody: any): UserRoleTypeAction => ({
    type: ADD_USERROLE,
    payload: { requestBody },
});

export const addUserRoleSuccess = (data: any): UserRoleTypeAction => ({
    type: ADD_USERROLE_SUCCESS,
    payload: { data },
});

export const addUserRoleError = (error: any): UserRoleTypeAction => ({
    type: ADD_USERROLE_ERROR,
    payload: { error },
});

export const editUserRole = (requestBody: any): UserRoleTypeAction => ({
    type: EDIT_USERROLE,
    payload: { requestBody },
});

export const editUserRoleSuccess = (response: any): UserRoleTypeAction => ({
    type: EDIT_USERROLE_SUCCESS,
    payload: { response },
});

export const editUserRoleError = (error: any): UserRoleTypeAction => ({
    type: EDIT_USERROLE_ERROR,
    payload: { error },
});

export const deleteUserRole = (id: string): UserRoleTypeAction => ({
    type: DELETE_USERROLE,
    payload: { id },
});

export const deleteUserRoleSuccess = (): UserRoleTypeAction => ({
    type: DELETE_USERROLE_SUCCESS,
});

export const deleteUserRoleError = (error: Object): UserRoleTypeAction => ({
    type: DELETE_USERROLE_ERROR,
    payload: { error },
});

export const updateLoader = (loading: boolean) => ({
    type: UPDATE_USERROLE_LOADER,
    payload: { loading },
});

export const closeDialog = () => ({
    type: CLOSE_USERROLE_DIALOG,
    payload: {},
});

