/* @flow */
import { FETCH_ASSETGROUP, FETCH_ASSETGROUP_SUCCESS, FETCH_ASSETGROUP_ERROR, UPDATE_ASSETGROUP_LOADER, SEARCH_ASSETGROUP, SEARCH_ASSETGROUP_SUCCESS } from './constants.assetgroup';

export const initialState = {
    assetGroup: {},
    isUpdating: false,
    isLoader: false,
    isSearch: false,
};

type stateType = {
    assetGroup: {},
    isUpdating: boolean,
    isLoader: boolean,
    isSearch: boolean,
}
type actionType = {
    type: string,
    payload :{
        response:{},
        isLoader: boolean,
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_ASSETGROUP:
        return {
            ...state,
            isUpdating: true,
        };
    case FETCH_ASSETGROUP_SUCCESS:
        return {
            ...state,
            assetGroup: action.payload.response,
            isUpdating: false,
            isLoader: false,
            isSearch: false,
        };
    case UPDATE_ASSETGROUP_LOADER:
        return {
            ...state,
            isLoader: action.payload.isLoader,
        };
    case SEARCH_ASSETGROUP:
        return {
            ...state,
            isUpdating: true,
        };
    case SEARCH_ASSETGROUP_SUCCESS:
        return {
            ...state,
            assetGroup: action.payload.response,
            isUpdating: false,
            isLoader: false,
            isSearch: true,
        };
    case FETCH_ASSETGROUP_ERROR:
        return {
            ...state,
            isLoader: false,
        };
    default:
        return state;
    }
};
