/* @flow */
import { FETCH_USERS_LIST_ERROR, FETCH_USERS_LIST_SUCCESS, FETCH_USERS_LIST, RESET_USERS_LIST } from './constants.userList';

const initialState = {
    users: [],
    isUpdating: false,
};

type stateType = {
    users: Array<Object>,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        users:{},
        totalUsers: number
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_USERS_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case FETCH_USERS_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            users: action.payload.users,
            totalUsers: action.payload.totalUsers,
        };
    case FETCH_USERS_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    case RESET_USERS_LIST:
        return {
            ...state,
            isUpdating: false,
            users: [],
            totalUsers: 0,
        };
    default:
        return state;
    }
};
