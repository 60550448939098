/* @flow */
import {
    FETCH_RECIPIENT_LIST_ADMIN,
    FETCH_RECIPIENT_LIST_SUCCESS_ADMIN,
    FETCH_RECIPIENT_LIST_ERROR_ADMIN,
    FETCH_RECIPIENT_ALERTS,
    FETCH_RECIPIENT_ALERTS_SUCCESS,
    FETCH_RECIPIENT_ALERTS_FAILURE,
    DELETE_RECIPIENT_ALERTS,
    DELETE_RECIPIENT_ALERTS_SUCCESS,
    DELETE_RECIPIENT_ALERTS_FAILURE,
    RESET_RECIPIENT_ALERT_LIST,
} from './constants.recipentList';

const initialState = {
    recipients: [],
    alerts: [],
    totalAlerts: 0,
    response: {},
    isLoading: false,
};

type stateType = {
    recipients: Array<Object>,
    alerts: Array<Object>,
    totalAlerts: any,
    response: Object,
    isLoading: boolean,
};

type actionType = {
    type: string,
    payload: {
        recipients: {},
        alerts: {},
        totalAlerts: 0,
        response: {}
    },
};

export default (state: stateType = initialState, action: actionType) => {
    switch (action.type) {
    case FETCH_RECIPIENT_LIST_ADMIN:
        return {
            response: {},
            ...state,
        };
    case FETCH_RECIPIENT_LIST_SUCCESS_ADMIN:
        return {
            ...state,
            response: {},
            recipients: action.payload.recipients,
        };
    case FETCH_RECIPIENT_LIST_ERROR_ADMIN:
        return {
            response: {},
            ...state,
        };
    case FETCH_RECIPIENT_ALERTS:
        return {
            ...state,
            response: {},
            isLoading: true,
        };
    case FETCH_RECIPIENT_ALERTS_SUCCESS:
        return {
            ...state,
            response: {},
            isLoading: false,
            alerts: action.payload.alerts,
            totalAlerts: action.payload.totalAlerts,
        };
    case FETCH_RECIPIENT_ALERTS_FAILURE:
        return {
            ...state,
            response: {},
            isLoading: false,
        };

    case DELETE_RECIPIENT_ALERTS:
        return {
            ...state,
            isLoading: true,
        };
    case DELETE_RECIPIENT_ALERTS_SUCCESS:
        return {
            ...state,
            isLoading: false,
            response: action.payload.response,
        };
    case DELETE_RECIPIENT_ALERTS_FAILURE:
        return {
            ...state,
            isLoading: false,
            response: action.payload.response,
        };
    case RESET_RECIPIENT_ALERT_LIST:
        return {
            ...state,
            isLoading: false,
            alerts: [],
            totalAlerts: 0,
        };
    default:
        return state;
    }
};
