/* @flow */
import React from 'react';
import { Card, CardContent, Typography, Grid, Avatar, Tooltip } from '@mui/material';
import customStyles from './NearestAssetListHeader.module.scss';
import GetSvgIcon from '../../../util/svgImage_util';

const NearestAssetListHeader = (props: Object) => (
    <div className={customStyles.container}>
        <Card raised={false} className={customStyles.card}>
            <CardContent className={customStyles['card-content']}>
                <div className={customStyles['back-wrapper']}>
                    <button className={customStyles['back-button']} onClick={() => props.history.goBack()}>
                        <GetSvgIcon type="back" />
                    </button>
                </div>
                <div>
                    <Typography variant="h5" className={customStyles['backlabel-header']} component="h2">
                        Nearest Assets
                    </Typography>
                </div>
                <div className={customStyles['landmark-container']}>
                    <Grid container>
                        <Grid item xs={2}>
                            <Avatar className={customStyles['landmark-icon']}>
                                <GetSvgIcon fillcolor="#343e48" />
                            </Avatar>
                        </Grid>
                        <Grid item xs={10}>
                            <Tooltip title={props.landmarkData.name} placement="bottom-start">
                                <Typography className={customStyles['landmark-header']} component="h2">
                                    {props.landmarkData.name}
                                </Typography>
                            </Tooltip>
                            <Tooltip title={props.landmarkData.address} placement="bottom-end">
                                <Typography className={customStyles['landmark-address']} component="p">
                                    {props.landmarkData.address}
                                </Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>
    </div>
);

export default NearestAssetListHeader;
