
import React, { Component } from 'react';
import { Button, Divider, Grid, TextField } from '@mui/material';
import moment from 'moment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import MomentUtils from '@date-io/moment';
import Typography from '@mui/material/Typography';
import AsyncSelectWrapper from '../SharedComponents/AsyncSelectWrapper';

export type Props = {
    filters: [
        {
            dataArrayParam: String,
            dataCountParam: String,
            dataTotalParam: String,
            datumLabelParam: String,
            datumValueParam: String,
            filterOptions: Function,
            filterType: String,
            isMulti: boolean,
            perPage: number;
            property: String,
            propertyType: String,
            selectedOptions: Array<{}>,
        },
    ],
    selectedFilters: Array<{}>,
    defaultSelections: Array<{}>,
    onFilterSelected: Function,
};

const defaultDataArrayParam = 'content';
const defaultDataCountParam = 'count';
const defaultDataTotalParam = 'total';
const defaultDatumLabelParam = 'name';
const defaultDatumValueParam = 'id';
const defaultPerPage = 25;

const styles = {
    button: {
        flex: 'auto',
        opacity: '.87',
        color: '#007aff',
        fontWeight: 'bold',
    },
    checkbox: { color: '#007aff' },
    subheading: {
        fontSize: 15,
        opacity: '.87',
        padding: 20,
        width: '100%',
    },
    title: {
        flex: 'auto',
        fontSize: '20px',
        opacity: '.87',
        padding: 20,
        fontWeight: 'bold',
    },
};

export type State = {
    selectedFilters: Array<{}>,
};

class MaintenanceFilterForm extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            selectedFilters: props.selectedFilters,
            endDateError: '',
            startDateError: '',
            datePickerOpen: false,
            startDate: moment().subtract(7, 'days').calendar(),
            endDate: moment(),
            startDateFlag: false,
            endDateFlag: false,
        };
    }

    filterOptions = (filter, inputValue, loadedOptions) => new Promise((resolve, reject) => {
        const perPage = filter.perPage || defaultPerPage;
        filter.filterOptions(loadedOptions.length, perPage, inputValue).then((response) => {
            const count = response[filter.dataCountParam || defaultDataCountParam];
            const total = response[filter.dataTotalParam || defaultDataTotalParam];
            const hasMore = loadedOptions.length + count < total;

            if (response) {
                const responseData = response[filter.dataArrayParam || defaultDataArrayParam];
                if (responseData) {
                    const optionData = responseData.map(datum => ({
                        value: datum[filter.datumValueParam || defaultDatumValueParam],
                        label: datum[filter.datumLabelParam || defaultDatumLabelParam],
                        data: datum,
                    }));
                    resolve({
                        options: optionData,
                        hasMore,
                    });
                } else {
                    reject();
                }
            } else {
                reject();
            }
        });
    });

    clearFilters = () => {
        this.setState({ selectedFilters: this.props.defaultSelections });
        this.props.onFilterSelected(this.props.defaultSelections);
    }

    onFilterSelected = (filter, selected) => {
        let { selectedFilters } = this.state;
        const formattedSelection = selected;
        formattedSelection.property = filter.property;
        selectedFilters = selectedFilters.filter((selectedFilter) => {
            const { data, property } = selectedFilter;
            if (filter.isMulti || property !== filter.property) {
                return !data.parentProperty || data.parentProperty !== filter.property;
            }
            return false;
        });
        selectedFilters.push(formattedSelection);
        if (filter.subgroups) {
            const selectedValues = selectedFilters.map(f => f.value);
            filter.subgroups.forEach((sub) => {
                if (selectedValues.includes(sub.parent)) {
                    selectedFilters.push({
                        value: sub.filter.defaultValue,
                        label: sub.filter.property,
                        property: sub.filter.property,
                        data: sub.filter,
                    });
                }
            });
        }
        this.setState({ selectedFilters }, () => {
            const { startDateError, endDateError } = this.state;
            if (!startDateError && !endDateError) {
                this.props.onFilterSelected(selectedFilters);
            }
        });
    };

    handleDelete = (filter, value) => {
        let { selectedFilters } = this.state;
        selectedFilters = selectedFilters.filter((selectedFilter) => {
            if (selectedFilter.property !== filter.property && selectedFilter.value !== value) {
                return filter.parentProperty !== selectedFilter.property;
            }
            return false;
        });
        this.setState({ selectedFilters }, () => {
            this.props.onFilterSelected(this.state.selectedFilters);
        });
    }

    handleDateFromTextField = (dt, max, filter) => {
        const selected = { value: dt, data: filter };
        if (!dt) {
            this.setState({ startDateError: '' });
        } else if (!moment(dt).isValid()) {
            this.setState({ startDateError: 'invalidDate' });
            this.onFilterSelected(filter, selected);
        } else if (moment(dt).isAfter(max, 'd')) {
            this.setState({ startDateError: 'maxDate' });
            this.onFilterSelected(filter, selected);
        } else if ((moment(dt).isAfter(moment(max).endOf('day')))) {
            this.setState({ startDateError: 'disableFuture' });
            this.onFilterSelected(filter, selected);
        } else {
            this.setState({ startDateError: '', startDate: dt, startDateFlag: true });
        }
    }

    handleDateFromChange = (dt, filter) => {
        if (this.state.startDateFlag) {
            const selected = { value: dt, data: filter };
            this.onFilterSelected(filter, selected);
        }
    }

    handleDateToTextField = (dt, max, min, filter) => {
        const selected = { value: dt, data: filter };
        if (!dt) {
            this.setState({ endDateError: '' });
        } else if (!moment(dt).isValid()) {
            this.setState({ endDateError: 'invalidDate' });
            this.onFilterSelected(filter, selected);
        } else if (moment(dt).isBefore(min, 'd')) {
            this.setState({ endDateError: 'minDate' });
            this.onFilterSelected(filter, selected);
        } else if ((moment(dt).isAfter(moment(max).endOf('day')))) {
            this.setState({ endDateError: 'disableFuture' });
            this.onFilterSelected(filter, selected);
        } else {
            this.setState({ endDateError: '', endDate: dt, endDateFlag: true });
        }
    }

    handleDateToChange = (dt, filter) => {
        if (this.state.endDateFlag) {
            const selected = { value: dt, data: filter };
            this.onFilterSelected(filter, selected);
        }
    }

    handleDateBlur = (property, filter) => {
        if (property === 'startDate') {
            this.handleDateFromChange(this.state.startDate, filter);
        } else {
            this.handleDateToChange(this.state.endDate, filter);
        }
    }

    handleDatePickerOpen = (property) => {
        this.setState({ datePickerOpen: true });
        if (property === 'startDate') {
            this.setState({ startDateFlag: true })
        } else {
            this.setState({ endDateFlag: true })
        }
    }

    getStartDateErrorMessage = () => {
        switch (this.state.startDateError) {
        case 'maxDate': {
            return 'Date From should not be after Date To';
        }
        case 'disableFuture': {
            return 'Date From should not be in future';
        }
        case 'invalidDate': {
            return 'Invalid Date format';
        }
        default: {
            return '';
        }
        }
    }

    getEndDateErrorMessage = () => {
        switch (this.state.endDateError) {
        case 'minDate': {
            return 'Date To should not be before Date From';
        }
        case 'disableFuture': {
            return 'Date To should not be in future';
        }
        case 'invalidDate': {
            return 'Invalid Date format';
        }
        default: {
            return '';
        }
        }
    }

    renderSelect = (filter) => {
        const { selectedFilters } = this.state;
        const { property } = filter;

        const propertySelections = [];
        if (selectedFilters) {
            selectedFilters.forEach((selectedFilter) => {
                if (selectedFilter.property === property) {
                    propertySelections.push(selectedFilter);
                }
            });
        }

        let selectStyles = {
            control: () => ({
                display: 'flex',
                'border-bottom': '1px solid rgba(0, 0, 0, 0.12)',
                boxShadow: 'none !important',
            }),
        };
        if (filter.selectStyles) {
            selectStyles = { ...selectStyles, ...filter.selectStyles };
        }

        return (
            <div key={filter.property} >
                <AsyncSelectWrapper
                    handleDelete={selectedOption => (
                        this.handleDelete(property, selectedOption.value, true)
                    )}
                    loadOptions={(inputValue, loadedOptions) =>
                        this.filterOptions(filter, inputValue, loadedOptions)
                    }
                    onItemSelected={selected => {
                        if (selected.data.name === 'Date Range') {
                            this.setState({ startDateError: '', endDateError: '' });
                        }
                        this.onFilterSelected(filter, selected);
                    }}
                    selectedOptions={propertySelections}
                    title={filter.selectPlaceholder || filter.filterTitle}
                    selectStyles={selectStyles}
                    disableSearch={filter.disableSearch}
                    disableChips={filter.disableChips}
                    showSelection={filter.showSelection}
                    hideDivider
                />
            </div>
        );
    }

    renderDatePicker = (filter, hideDivider) => {
        const { selectedFilters } = this.state;
        const { property, filterTitle, defaultValue } = filter;
        let selectedFilter = null;
        const formattedToday = moment().format('YYYY-MM-DD');
        let min;
        let max = formattedToday;

        if (selectedFilters) {
            selectedFilters.forEach((s) => {
                if (s.property === property) {
                    selectedFilter = s;
                }
            });
            if ((filter.minProperty || filter.maxProperty)) {
                selectedFilters.forEach((s) => {
                    if (s.property === filter.minProperty) {
                        min = s.value;
                    }
                    if (s.property === filter.maxProperty) {
                        max = s.value;
                    }
                });
            }
        }
        const date = selectedFilter && selectedFilter.value ? selectedFilter.value : defaultValue;
        return (
            <div key={property} >
                {filterTitle}
                <div>
                    <div>
                        <LocalizationProvider dateAdapter={MomentUtils}>
                            <DatePicker
                                id="date"
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        helperText={property === 'startDate' ? this.getStartDateErrorMessage() : this.getEndDateErrorMessage()}
                                        error={
                                            property === 'startDate' ? !!this.state.startDateError : !!this.state.endDateError
                                        }
                                        onBlur={() => this.handleDateBlur(property, filter)}
                                        onKeyDown={(event) => { if (event.key === 'Enter') { this.handleDateBlur(property, filter) } }}
                                    />
                                )}
                                closeOnSelect
                                PopperProps={{
                                    placement: 'bottom-end',
                                    sx: { '.MuiPickersToolbar-penIconButton': { display: 'none' } },
                                }}
                                showToolbar
                                minDate={min}
                                maxDate={max}
                                onChange={(dt) => {
                                    if (this.state.datePickerOpen) {
                                        if (property === 'startDate') {
                                            this.handleDateFromTextField(dt, max, filter);
                                            this.handleDateFromChange(dt, filter);
                                        } else {
                                            this.handleDateToTextField(dt, max, min, filter);
                                            this.handleDateToChange(dt, filter);
                                        }
                                    } else if (property === 'startDate') {
                                        this.handleDateFromTextField(dt, max, filter);
                                    } else {
                                        this.handleDateToTextField(dt, max, min, filter);
                                    }
                                }}
                                onOpen={() => {
                                    this.setState({ datePickerOpen: true });
                                    if (property === 'startDate') {
                                        this.setState({ startDateFlag: true })
                                    } else {
                                        this.setState({ endDateFlag: true })
                                    }
                                }}
                                onClose={() => { this.setState({ datePickerOpen: false }) }}
                                format="MM/DD/YYYY"
                                disableFuture
                                value={date}
                                toolbarTitle=""
                                mask="__/__/____"
                                onError={(error) => {
                                    if (property === 'startDate') {
                                        this.setState({ startDateError: error });
                                    } else if (property === 'endDate') {
                                        this.setState({ endDateError: error });
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                { hideDivider ? null : <Divider /> }
            </div>
        );
    }

    render() {
        const { filters } = this.props;
        const { selectedFilters } = this.state;
        const selectedValues = selectedFilters.map(f => f.value);
        const filterComponents = [];
        filters.forEach((filter) => {
            const hasSubgroups = filter.subgroups && !filter.subgroupsHidden;
            let mainFilter = null;
            if (filter.filterType === 'select') {
                mainFilter = this.renderSelect(filter, hasSubgroups);
            } else if (filter.filterType === 'date') {
                mainFilter = this.renderDatePicker(filter);
            }
            filterComponents.push((
                <div
                    key={filter.filterTitle}
                    style={{ padding: 8, marginLeft: 20, marginRight: 20 }}
                >
                    {filter.filterTitle}
                    {mainFilter}
                </div>
            ));
            if (hasSubgroups) {
                const subEls = filter.subgroups.map((sub) => {
                    if (sub.filter.filterType === 'date' && selectedValues.includes(sub.parent)) {
                        return (
                            <Grid
                                key={sub.filter.property}
                                item
                                xs={6}
                                style={{ display: 'flex', padding: 3 }}
                            >
                                {this.renderDatePicker(sub.filter, true)}
                            </Grid>
                        );
                    }
                    return null;
                });
                filterComponents.push((
                    <div style={{ padding: 8, marginLeft: 20, marginRight: 20 }} key={`${filter.id}.subgroups`}>
                        <Grid container spacing={3} >
                            {subEls}
                        </Grid>
                    </div>
                ));
            }
        });


        return (
            <div style={{ padding: 20 }}>
                <div style={{ display: 'flex', width: '100%' }}>
                    <Typography variant="h6" id="filterTitle" style={styles.title}>Filters</Typography>
                    <Button
                        className="classes.button"
                        color="primary"
                        style={styles.button}
                        onClick={this.clearFilters}
                    >
                        CLEAR
                    </Button>
                </div>
                <Divider />
                {filterComponents}
            </div>
        );
    }
}

MaintenanceFilterForm.defaultProps = {
    filters: [],
    defaultSelections: [],
};

export default MaintenanceFilterForm;
