/* @flow */
import React from 'react';
import { Grid, Button } from '@mui/material';
import customStyles from './ImportComponent.module.scss';
import { USER, USER_TEMPLATE_NAME } from './constants.import';

export type Props = {
    downloadTemplate: Function,
};

class UserImportFormComponent extends React.PureComponent<Props> {
    render() {
        return (
            <Grid container justifyContent="flex-start" spacing={1} style={{ marginBottom: 26 }}>
                <Grid item xs={12} style={{ margin: '18px 0' }}>
                    <h2 className={customStyles.header}>Users</h2>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={() => this.props.downloadTemplate(USER, USER_TEMPLATE_NAME)}>Download Template</Button>
                </Grid>
                <Grid item xs={12} style={{ margin: '18px 0' }}>
                    <div className={customStyles.highlight}>
                        <div className={customStyles.subHeader}>
                            Importing a User .CSV
                        </div>
                        Please read the following instructions. It is important that
                        the import file has the columns required and optional in order
                        from left to right as shown in the sample import file
                    </div>
                    <div>
                        A header row is required as shown in the sample file. Users are
                        identified by their username. If a user pre-exists then the
                        values will be updated with following rules: if the value is
                        DELETE then it will be deleted, if the value is left blank then
                        the old value is retained, else the value gets updated with the
                        new value. The following columns are permitted.
                        <div className={customStyles.subHeader}>Required:</div>
                        <ul>
                            <li>
                                First Name: First name of the user
                                (required, max 250 chars)
                            </li>
                            <li>
                                Last Name: Last name of the user
                                (required, max 250 chars)
                            </li>
                            <li>
                                Email: Email address for the user
                                (required, must be a valid email format)
                            </li>
                            <li>
                                Username: Desired Username (required, max 250 chars)
                            </li>
                        </ul>
                        <div className={customStyles.subHeader}>
                            Optional (may be left empty):
                        </div>
                        <ul>
                            <li>
                                Phone: Phone Number (enter as 10 digit value)
                            </li>
                            <li>
                                User Access: The set of assets and landmarks this user
                                has access to view within the LocalFleet UI (The user
                                access must already exist in the system. If this value
                                is left blank or found not be pre-exist, then full
                                access is granted)
                            </li>
                            <li>
                                User Role: Governs the pages and functions a user has
                                permission to interact with within LocalFleet (The user
                                role must already exist in the system. If this value is
                                left blank or found not be pre-exist, then Dispatch
                                role is granted)
                            </li>
                            <li>
                                Comments: Up to 250 chars
                            </li>
                            <li>
                                Invitation: Send the user an invitation with
                                instructions on how to log in to the LocalFleet
                                application? (Yes and No values accepted, defaults
                                to No if left blank)
                            </li>
                        </ul>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default UserImportFormComponent;
