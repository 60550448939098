/* @flow */
import { FETCH_LANDMARKS_LIST_ERROR, FETCH_LANDMARKS_LIST_SUCCESS, FETCH_LANDMARKS_LIST, RESET_LANDMARKS_LIST } from './constants.landmarksList';

const initialState = {
    landmarks: [],
    isUpdating: false,
};

type stateType = {
    landmarks: Array<Object>,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        landmarks:{},
        totalLandmarks: number
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_LANDMARKS_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case FETCH_LANDMARKS_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            landmarks: action.payload.landmarks,
            totalLandmarks: action.payload.totalLandmarks,
        };
    case FETCH_LANDMARKS_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    case RESET_LANDMARKS_LIST:
        return {
            ...state,
            isUpdating: false,
            landmarks: [],
            totalLandmarks: 0,
        };
    default:
        return state;
    }
};
