/* @flow */
import moment from 'moment';
import { combineEpics } from 'redux-observable';
import { FETCH_MAINTENANCE_ALERTS_LIST } from './constants.alertList';
import { fetchAlertListSuccess, fetchAlertListError } from './actions.alertList';
import MaintenanceFilterHelper from '../../../components/Maintenance/MaintenanceFilterHelper';
import {
    convertDateTime,
    getGrailsAppBasedURL,
    getTimeFormatAsperUserSettting,
} from '../../../helper-classes/utility-functions';

type ObservablesTypes = {
    getJSON: Function,
    of: Function
}

export const getAlerts = (actions$: Function, store: Object, { getJSON, of }: ObservablesTypes) =>
    actions$
        .ofType(FETCH_MAINTENANCE_ALERTS_LIST)
        .mergeMap((action) => {
            const {
                order,
                orderBy,
                pageNumber,
                pageSize,
                filtersToSend,
            } = action.payload;

            const {
                weekRangeOption,
                dateRangeOption,
            } = MaintenanceFilterHelper;

            const headers = {
                Authorization: `Bearer ${store.getState().userSession.userToken}`,
            };

            const randVal = new Date().valueOf();
            const filtersObj = [{
                property: 'accountId',
                type: 'Long',
                value: localStorage.getItem('currentAccountUserId') || '',
                operator: 'eq',
            }];

            const dateFilter = {
                property: 'filterDate',
                type: 'Date',
                value: weekRangeOption.getStartDate(),
                value2: weekRangeOption.getEndDate(),
                operator: 'between',
            };

            if (filtersToSend) {
                const hasDateRange = filtersToSend.filter(filter => (
                    filter.property === 'filterDate' && filter.value === dateRangeOption.id
                )).length > 0;
                filtersToSend.forEach((filter) => {
                    const { data } = filter;
                    if (hasDateRange && data.parentProperty === 'filterDate') {
                        if (filter.property === 'startDate') {
                            dateFilter.value = moment(filter.value).utc().toISOString();
                        } else if (filter.property === 'endDate') {
                            dateFilter.value2 = moment(filter.value).endOf('day').utc().toISOString();
                        }
                    } else if (filter.property === 'filterDate' && data.getStartDate && data.getEndDate) {
                        dateFilter.value = moment.utc(data.getStartDate()).toISOString();
                        dateFilter.value2 = moment.utc(data.getEndDate()).toISOString();
                    } else {
                        filtersObj.push({
                            property: filter.property,
                            type: filter.type || 'string',
                            value: `${filter.value}`,
                            operator: 'eq',
                        });
                    }
                });
            }

            filtersObj.push(dateFilter);
            let sortParams = encodeURIComponent('[{"property":"alertDate","direction":"desc"}]');
            if (order && orderBy) {
                sortParams = encodeURIComponent(`[{"property":"${orderBy === 'alertTime' ? 'alertDate' : orderBy}","direction":"${order}"}]`);
            }

            const sorts = `&sorts=${sortParams}`;
            const filtersParam = encodeURIComponent(JSON.stringify(filtersObj));
            const baseUrl = `${getGrailsAppBasedURL()}`
            const path = '/operation/json/maintenanceAlertService/getAlerts';
            const url = `${baseUrl}${path}?_dc=${randVal}&offset=${pageSize * pageNumber}&max=${pageSize}&filters=${filtersParam}&${sorts}`;

            const res = getJSON(url, headers)
                .map((result) => {
                    result.data.forEach((datum) => {
                        /* eslint no-param-reassign: 0 */
                        let convertedDate = '';
                        let convertedTime = '';
                        if (datum.alertDate) {
                            convertedDate = convertDateTime(datum.alertDate, 'MMM D, YYYY');
                            const tf = getTimeFormatAsperUserSettting() === '24h' ? 'H:mm' : 'h:mm A';
                            convertedTime = convertDateTime(datum.alertDate, tf);
                        }
                        datum.alertDate = convertedDate;
                        datum.alertTime = convertedTime;
                    });
                    return fetchAlertListSuccess(result.data, result.total);
                })
                .catch(error => of(fetchAlertListError(error)));
            return res;
        });

export default combineEpics(getAlerts);
