import * as constants from './constants.addDriver';

type stateType = {
    driverData: Object,
    licenseStates: Array,
    unassignedKeyFobs: Array,
    responseType: string,
    responseStatus: boolean,
    responseData: Object,
}

const initialState = {
    driverData: {},
    licenseStates: [],
    unassignedKeyFobs: [],
    responseType: '',
    responseStatus: false,
    responseData: {},
};

type actionType = {
    type: string,
    payload :{
        loading: boolean,
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case constants.FETCH_LICENSE_STATES_SUCCESS:
        return {
            ...state,
            licenseStates: action.payload.filteredData,
            responseType: 'get',
            responseStatus: true,
            loading: false,
        };
    case constants.FETCH_LICENSE_STATES_ERROR:
        return {
            ...state,
            licenseStates: [],
            responseType: 'get',
            responseStatus: false,
            responseData: action.payload,
            loading: false,
        };
    case constants.CREATE_KEY_FOB_ERROR:
        return {
            ...state,
            keyFobValue: null,
            responseType: 'keyFob',
            responseStatus: false,
            responseData: action.payload,
            loading: false,
        };
    case constants.FETCH_UNASSIGNED_KEY_FOBS_SUCCESS:
        return {
            ...state,
            unassignedKeyFobs: action.payload.response,
            responseType: 'get',
            responseStatus: true,
            loading: false,
        };
    case constants.FETCH_UNASSIGNED_KEY_FOBS_ERROR:
        return {
            ...state,
            unassignedKeyFobs: [],
            responseType: 'get',
            responseStatus: false,
            responseData: action.payload,
            loading: false,
        };
    case constants.ADD_DRIVER_SUCCESS:
        return {
            ...state,
            responseType: 'add',
            responseStatus: true,
            loading: false,
        };
    case constants.ADD_DRIVER_ERROR:
        return {
            ...state,
            responseType: 'add',
            responseStatus: false,
            responseData: action.payload.error,
            loading: false,
        };
    case constants.FETCH_DRIVER:
        return {
            ...state,
            driverData: {},
            responseType: 'get',
            responseStatus: false,
        };
    case constants.FETCH_DRIVER_SUCCESS:
        return {
            ...state,
            driverData: action.payload.response,
            responseType: 'get',
            responseStatus: true,
            loading: false,
        };
    case constants.FETCH_DRIVER_ERROR:
        return {
            ...state,
            driverData: {},
            responseType: 'get',
            responseStatus: false,
            responseData: action.payload,
            loading: false,
        };
    case constants.UPDATE_DRIVER_SUCCESS:
        return {
            ...state,
            driverData: {},
            responseType: 'update',
            responseStatus: true,
            responseData: action.payload,
            loading: false,
        };
    case constants.UPDATE_DRIVER_ERROR:
        return {
            ...state,
            driverData: {},
            responseType: 'update',
            responseStatus: false,
            responseData: action.payload,
            loading: false,
        };
    case constants.RESEND_INVITE:
        return {
            ...state,
            loading: true,
            responseType: 'resendInvite',
            responseStatus: false,
        };
    case constants.RESEND_INVITE_SUCCESS:
        return {
            ...state,
            responseStatus: true,
            loading: false,
            responseType: 'resendInvite',
        };
    case constants.RESEND_INVITE_ERROR:
        return {
            ...state,
            responseStatus: false,
            loading: false,
            responseType: 'resendInvite',
            responseData: action.payload,
        };
    case constants.DELETE_DRIVER_SUCCESS:
        return {
            ...state,
            responseType: 'delete',
            responseStatus: true,
            loading: false,
        };
    case constants.DELETE_DRIVER_ERROR:
        return {
            ...state,
            responseType: 'delete',
            responseStatus: false,
            responseData: action.payload,
            loading: false,
        };
    case constants.UPDATE_DRIVER_LOADER:
        return {
            ...state,
            loading: action.payload.loading,
        };
    case constants.INIT_DRIVER_PROPS:
        return {
            ...initialState,
        };
    default:
        return state;
    }
};
