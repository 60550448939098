import React from 'react';
import styles from '../../Steppers/AlertStepper.module.scss';

class DtcAlert extends React.Component {
    state = {};

    render() {
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    {''}
                </div>
            </div>
        );
    }
}

export default DtcAlert;
