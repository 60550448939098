/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_SCOREVEHICLE_LIST } from './constants.scoreVehicleList';
import { fetchScoreVehicleListError, fetchScoreVehicleListSuccess } from './actions.scoreVehicleList';
import { getIframeUserToken } from '../../helper-classes/utility-functions';
import config from '../../constants/Config';

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    of: Function
}

export const getURL = (data: any) => {
    // this function is used many places please check once you modify this function
    const {
        id,
        pageNumber,
        pageSize,
        filter,
        order,
        orderBy,
    } = data;
    let url = `${config.get('DRIVER_SCORECARD_SERVICES_URL')}/scorecard/`;
    if (id) {
        url += `drivers/${id}?limit=${pageSize}&offset=${pageNumber * pageSize}`;
    } else {
        url += `assets?limit=${pageSize}&offset=${pageNumber * pageSize}`;
    }
    if (filter && filter.search) {
        url += `&search=${filter.search}`;
    }
    if (filter && filter.dateFilter && filter.dateFilter.startDate) {
        url += `&startDate=${filter.dateFilter.startDate}&endDate=${filter.dateFilter.endDate}`;
    }
    if (order && orderBy) {
        url += `&sort=${orderBy}:${order}`;
    }
    return url;
};

export const getscoreddriver =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_SCOREVEHICLE_LIST)
            .distinctUntilChanged()
            .debounceTime(config.get('DEBOUNCE_TIME'))
            .mergeMap((action) => {
                const url = getURL(action.payload);
                const headers = {
                    'X-Nspire-UserToken': getIframeUserToken(),
                    'Content-Type': 'application/json',
                };
                return getJSON(url, headers)
                    .map(result => fetchScoreVehicleListSuccess(result))
                    .catch(error => of(fetchScoreVehicleListError(error)));
            });

export default combineEpics(getscoreddriver);
