/* @flow */
import { combineEpics } from 'redux-observable';
import {
    ACCOUNT_DETAIL,
    SAVE_ACCOUNT_DETAIL,
    SAVE_APPSETTINGS,
    SAVE_PREFERENCES,
    GET_ACCOUNT_DETAIL,
    GET_USER_SETTINGS,
    ACCEPT_RESELLER_TERMS_AND_CONDITION,
} from './constants.settings';
import {
    accountDetailSuccess,
    accountDetailError,
    saveAccountDetailSuccess,
    saveAccountDetailError,
    saveAppSettingsSuccess,
    saveAppSettingsError,
    savePreferencesSuccess,
    savePreferencesError,
    accountDetailByIdentitySuccess,
    getUserSettingsSuccess,
    getUserSettingsError,
    acceptResellerTermsAndConditionSuccess,
    acceptResellerTermsAndConditionError,
} from './actions.settings';
import config from '../../constants/Config';
import {
    getLocalStorageValue,
    getAppUserSettings,
    getAppToken,
    getGrailsAppBasedURL,
    getAuthorizationHeader,
    getUserToken,
} from '../../helper-classes/utility-functions';
import { fetchUserSettings } from './../Login/epics.login';

type ObservarblesTypes = {
    getJSON: Function,
    put: Function,
    postJSON: Function,
    of: Function,
}

const getUserId = () => getLocalStorageValue('currentAccountUserId');

export const getUserAccount =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(ACCOUNT_DETAIL)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                };
                const { payload: { id } } = action;
                const timeStamp = (new Date()).getTime();
                return getJSON(`${config.get('PLATFORM_API_BASE_URL')}/users/${id}?date=${timeStamp}`, headers)
                    .map(result => accountDetailSuccess(result))
                    .retry(2)
                    .catch(error => of(accountDetailError(error)));
            });

export const userIdentity =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(GET_ACCOUNT_DETAIL)
            .mergeMap(() => {
                const appToken = getAppToken();
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'X-Nspire-AppToken': appToken,
                };
                const timeStamp = (new Date()).getTime();
                return getJSON(`${config.get('PLATFORM_API_BASE_URL')}/identity?date=${timeStamp}`, headers)
                    .map(result => accountDetailByIdentitySuccess(result))
                    .catch(error => of(accountDetailError(error)));
            });

export const getUserSettings =
    (actions$: Function, store: Object, { Observable, of }: ObservarblesTypes) =>
        actions$
            .ofType(GET_USER_SETTINGS)
            .mergeMap(() => Observable.merge(fetchUserSettings({ token: getUserToken() }))
                .mergeMap(result => of(getUserSettingsSuccess({}, result)))
                .catch(error => of(getUserSettingsError(error))));

export const saveAccountDetailsData =
    (actions$: Function, store: Object, { put, of }: ObservarblesTypes) =>
        actions$
            .ofType(SAVE_ACCOUNT_DETAIL)
            .mergeMap((action) => {
                const { payload: { data, id } } = action;
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };
                const timeStamp = (new Date()).getTime();
                return put(`${config.get('PLATFORM_API_BASE_URL')}/users/${id}?date=${timeStamp}`, data, headers)
                    .map(result => saveAccountDetailSuccess(result.response))
                    .catch(error => of(saveAccountDetailError(error)));
            });

export const saveAppSettingsData =
    (actions$: Function, store: Object, { put, of }: ObservarblesTypes) => actions$
        .ofType(SAVE_APPSETTINGS)
        .mergeMap((action) => {
            const { payload: { data } } = action;
            const userSettings = getAppUserSettings();
            userSettings.tz = data.userTz;
            userSettings.units = data.userUnits;
            userSettings.timeFormat = data.timeDisplay;
            if (data.firstName) {
                userSettings.firstName = data.firstName;
            }
            if (data.lastName) {
                userSettings.lastName = data.lastName;
            }
            if (data.phone) {
                userSettings.phone = data.phone;
            }
            if (data.email) {
                userSettings.email = data.email;
            }
            const headers = {
                'Content-Type': 'application/json',
                Authorization: getAuthorizationHeader(),
            };
            const randVal = new Date().getTime();
            return put(`${getGrailsAppBasedURL()}/rest/json/accountUser/${getUserId()}?dc=${randVal}`, userSettings, headers)
                .map(() => saveAppSettingsSuccess(data))
                .catch(error => of(saveAppSettingsError(error)));
        });

export const savePreferencesData =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(SAVE_PREFERENCES)
            .mergeMap((action) => {
                const { payload: { data } } = action;
                const headers = {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: getAuthorizationHeader(),
                };
                const prefData = {
                    defaultPage: data.defaultPage,
                    tzDST: data.tzOffset,
                    tzOffset: data.tzOffset,
                    tz: data.tz,
                    units: data.unitOfMeasure,
                    timeDisplay: data.timeDisplay,
                };
                const prefPostData = {
                    name: 'app.settings',
                    valueType: 'json',
                    category: 'p7.preferences',
                    visible: 'false',
                    editable: 'false',
                    value: JSON.stringify(prefData),
                };
                const url = `${getGrailsAppBasedURL()}`;
                return postJSON(`${url}/userPreference/putPreferenceValue.json`, prefPostData, headers)
                    .map((result) => savePreferencesSuccess(result))
                    .catch(error => of(savePreferencesError(error)));
            });

export const acceptResellerTermsAndCondition =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(ACCEPT_RESELLER_TERMS_AND_CONDITION)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'X-Nspire-AppToken': getAppToken(),
                    'Content-Type': 'application/json',
                };
                const { payload: { id } } = action;
                return postJSON(`${config.get('PLATFORM_API_BASE_URL')}/ssaAcceptances`, { ssaId: id }, headers)
                    .map(result => acceptResellerTermsAndConditionSuccess(result))
                    .catch(error => of(acceptResellerTermsAndConditionError(error)));
            });

export default combineEpics(
    getUserAccount, saveAccountDetailsData, saveAppSettingsData,
    savePreferencesData, userIdentity, getUserSettings,
    acceptResellerTermsAndCondition,
);
