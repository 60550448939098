/* @flow */

export const FETCH_ASSETS_LIST: string = 'FETCH_ASSETS_LIST';
export const FETCH_ASSETS_LIST_SUCCESS: string = 'FETCH_ASSETS_LIST_SUCCESS';
export const FETCH_ASSETS_LIST_ERROR: string = 'FETCH_ASSETS_LIST_ERROR';
export const RESET_ASSETS_LIST: string = 'RESET_ASSETS_LIST';
export const ASSET_LIST_PREF_KEY: string = 'fleetLocate.assetList';
export type AssetListTypeAction = {
    type: string;
    payload?: Object;
}
