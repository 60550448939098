/* @flow */
import {
    FETCH_USERROLE,
    FETCH_USERROLE_SUCCESS,
    FETCH_USERROLE_ERROR,
    FETCH_USERROLES,
    FETCH_USERROLES_SUCCESS,
    FETCH_USERROLES_ERROR,
    ADD_USERROLE_SUCCESS,
    ADD_USERROLE_ERROR,
    EDIT_USERROLE_SUCCESS,
    EDIT_USERROLE_ERROR,
    DELETE_USERROLE_SUCCESS,
    DELETE_USERROLE_ERROR,
    CLOSE_USERROLE_DIALOG,
    UPDATE_USERROLE_LOADER,
} from './constants.userRole';

const initialState = {
    userRole: {},
    userRoles: {},
    isUpdating: false,
    isError: false,
    success: false,
    error: {},
};

type stateType = {
    userRole: Object,
    userRoles: Object,
    isUpdating: boolean,
    isError: boolean,
    success: boolean,
}
type actionType = {
    type: string,
    payload :{
        userRole:{},
        userRoles:{},
        loading: boolean,
        error: Object,
    },
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_USERROLE:
        return {
            ...state,
            userRole: {},
            isUpdating: true,
        };
    case FETCH_USERROLES:
        return {
            ...state,
            isUpdating: true,
        };
    case FETCH_USERROLE_SUCCESS:
        return {
            ...state,
            loading: false,
            isError: false,
            userRole: action.payload.userRole,
        };
    case FETCH_USERROLE_ERROR:
    case FETCH_USERROLES_ERROR:
        return {
            ...state,
            loading: false,
            isUpdating: false,
            isError: true,
            userRole: {},
        };
    case FETCH_USERROLES_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            userRoles: action.payload.userRoles,
        };
    case ADD_USERROLE_SUCCESS:
    case EDIT_USERROLE_SUCCESS:
    case DELETE_USERROLE_SUCCESS:
        return {
            ...state,
            isError: false,
            loading: false,
            success: true,
        };
    case ADD_USERROLE_ERROR:
    case EDIT_USERROLE_ERROR:
    case DELETE_USERROLE_ERROR:
        return {
            ...state,
            loading: false,
            isUpdating: false,
            isError: true,
            error: action.payload.error,
            success: false,
        };
    case UPDATE_USERROLE_LOADER:
        return {
            ...state,
            loading: action.payload.loading,
        };
    case CLOSE_USERROLE_DIALOG:
        return {
            ...state,
            loading: false,
            isUpdating: false,
            success: false,
            isError: false,
            error: '',
        };
    default:
        return state;
    }
};
