/* @flow */
import React, { Component } from 'react';
import Slider from '@mui/material/Slider';
import { Link } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button, Paper, Typography, Switch } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import driverStyles from './Settings.module.scss';
import { settingData } from './constants.settings';
import { getDistanceString, getPermissionValue, isReseller } from '../../helper-classes/utility-functions';
import config from '../../constants/Config';
import analytics from '../../analytics/index';

export type Props = {
    classes: Object,
    driverSettings: Object,
    submitSettings: Function,
    iframeUserToken: string,
};

export type State = {
    settings: any,
    isAdvanceScoring: boolean,
};

const styles = () => ({
    iOSSwitchBase: {
        '&.Mui-checked': {
            color: '#007aff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#007aff',
            },
        },
    },
    iOSChecked: {
        transform: 'translateX(15px)',
        '& + $iOSBar': {
            opacity: 0.5,
            border: 'none',
        },
    },
    iOSBar: {
        opacity: 1,
        backgroundColor: 'rgba(34, 31, 31, 0.26)',
    },
    txtWeightage: {
        width: '35px',
        top: '-11px',
        '&  .MuiFormHelperText-root.Mui-error': {
            lineHeight: 1,
            letterSpacing: 0,
        },
    },
    trackAfterWrapper: {
        backgroundColor: 'transparent',
    },
});

class DriverSettings extends Component<Props, State> {
    sliderConfig: Object;
    scoreHelp: Object;
    userHavePermission: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            settings: settingData,
            isAdvanceScoring: false,
        };
        this.sliderConfig = { min: 1, max: 3, step: 1 };
        this.scoreHelp = {
            docURL: isReseller() ? config.get('RESELLER_DRIVER_SCORECARD_HELP_DOC_URL') : config.get('DRIVER_SCORECARD_HELP_DOC_URL'),
            text: 'How are these scores calculated?',
            hoverText: 'click to open Driver Scorecard user guide',
        };
        this.setUserPermission();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((this.props.driverSettings !== nextProps.driverSettings)
            && Object.keys(nextProps.driverSettings).length > 0
            && nextProps.driverSettings.settings) {
            this.updateSettingsData(nextProps.driverSettings);
        }
    }

    setUserPermission = () => {
        const { iframeUserToken } = this.props;
        this.userHavePermission = getPermissionValue('Driver Setup') === 'Modify' || !(!iframeUserToken || iframeUserToken.replace(/\s/, '').length === 0);
    }

    updateSettingsData = (driverSettings) => {
        const nS = Object.assign({}, this.state);
        const { settings } = driverSettings;
        nS.isAdvanceScoring = driverSettings.advancedScoring || false;

        this.state.settings.map((g, gI) => g.data.map((s, sI) => {
            nS.settings[gI].data[sI].enabled = settings[s.settKey];
            nS.settings[gI].data[sI].weightage = settings[`${s.settKey}Weightage`];
            return true;
        }));

        this.sliderConfig = { min: 1, max: (nS.isAdvanceScoring) ? 20 : 3, step: 1 };
        this.setState(nS);
    }

    weightageChange = (grpIndex, settIndex) => (e, weightage) => {
        const newState = Object.assign({}, this.state);
        const temp = parseInt(Math.abs(weightage || e.target.value) || 0, 10);
        newState.settings[grpIndex].data[settIndex].weightage
            = (temp > this.sliderConfig.max) ? this.sliderConfig.max : temp;
        this.setState(newState);
    }

    settingToggle = (grpIndex, settIndex) => (e) => {
        const newState = Object.assign({}, this.state);
        newState.settings[grpIndex].data[settIndex].enabled = e.target.checked;
        if (!e.target.checked) newState.settings[grpIndex].data[settIndex].weightage = 1;
        this.setState(newState);
    };

    advanceScoringToggle = () => (e) => {
        this.sliderConfig = { min: 1, max: (e.target.checked) ? 20 : 3, step: 1 };
        this.setState({ isAdvanceScoring: e.target.checked });
    }

    handleSubmit = () => {
        const settingsToSend = {};
        const { settings, isAdvanceScoring } = this.state;

        settings.map((g, gI) => g.data.map((s, sI) => {
            settingsToSend[settings[gI].data[sI].settKey] = settings[gI].data[sI].enabled;
            let kpiWeightage = this.sliderConfig.min;
            if (settings[gI].data[sI].enabled) {
                kpiWeightage = settings[gI].data[sI].weightage;
                if (!isAdvanceScoring) {
                    if (settings[gI].data[sI].weightage > this.sliderConfig.max) {
                        kpiWeightage = this.sliderConfig.max;
                    } else if (settings[gI].data[sI].weightage === 0) {
                        kpiWeightage = this.sliderConfig.min;
                    }
                }
            }
            settingsToSend[`${settings[gI].data[sI].settKey}Weightage`] = kpiWeightage;
            return true;
        }));

        this.props.submitSettings(settingsToSend, isAdvanceScoring);
        analytics.track('SAVE_DRIVER_SETTINGS', { feature: 'DriverScorecard' });
    }

    getKPIElements = () => {
        const { classes } = this.props;
        const { settings, isAdvanceScoring } = this.state;
        const currentUnit = getDistanceString();
        return settings.map((settingGroup, gIndex) => {
            const {
                grpKey, grpDText, grpDTextInKm, disableKpi,
            } = settingGroup;

            if (!disableKpi) {
                return (
                    <React.Fragment key={grpKey}>
                        <Typography component="div" className={driverStyles['settings-subheader']}>
                            {currentUnit === 'mi' ? grpDText : grpDTextInKm}
                        </Typography>
                        {settingGroup.data.map((setting, sIndex) => {
                            const { settKey, settDText, settDTextInKm } = setting;
                            return (
                                <Typography component="div" className={driverStyles['settings-data']} key={settKey}>
                                    <Typography className={driverStyles['settings-violation']} component="div">
                                        <Typography component="div">
                                            <span className={driverStyles.settings}>
                                                {currentUnit === 'mi' ? settDText : settDTextInKm}
                                            </span>
                                        </Typography>
                                    </Typography>
                                    <Typography className={`settings-action ${driverStyles['settings-action']}`} component="div">
                                        <Typography component="div" className="switch" data-elemet="switch">
                                            <Switch
                                                checked={setting.enabled}
                                                onChange={this.settingToggle(gIndex, sIndex)}
                                                classes={{
                                                    switchBase: classes.iOSSwitchBase,
                                                    track: classes.iOSBar,
                                                    icon: classes.iOSIcon,
                                                    iconChecked: classes.iOSIconChecked,
                                                    checked: this.userHavePermission
                                                        ? classes.iOSChecked : undefined,
                                                }}
                                                disabled={!this.userHavePermission}
                                            />
                                        </Typography>
                                    </Typography>
                                    <Typography className={`${driverStyles['settings-importance']}`} component="div">
                                        <div className={driverStyles['weightage-slider-outer']}>
                                            <div className={driverStyles['weightage-slider-base']} >
                                                <div className={driverStyles['weightage-dividers']} >
                                                    <span className={driverStyles['left-text']} >{isAdvanceScoring ? this.sliderConfig.min : 'Not Very'}</span>
                                                    {!isAdvanceScoring && <span className={driverStyles['center-text']} >Somewhat</span>}
                                                    <span className={driverStyles['right-text']} >{isAdvanceScoring ? this.sliderConfig.max : 'Very'}</span>
                                                </div>
                                                <Slider
                                                    size="small"
                                                    sx={{
                                                        padding: '0px',
                                                        left: '0px',
                                                        top: '36px',
                                                        position: 'absolute',
                                                        height: '1.5px',
                                                        '& .MuiSlider-rail': { backgroundColor: 'rgba(0, 0, 0, 0)', height: '2px' },
                                                        '& .MuiSlider-thumb.Mui-disabled': {
                                                            width: '9px',
                                                            cursor: 'no-drop',
                                                            height: '9px',
                                                            backgroundColor: '#bdbdbd',
                                                        },
                                                        '& .MuiSlider-track': { height: '2px' },
                                                    }}
                                                    value={settings[gIndex].data[sIndex].weightage}
                                                    min={this.sliderConfig.min}
                                                    max={this.sliderConfig.max}
                                                    step={this.sliderConfig.step}
                                                    disabled={
                                                        !setting.enabled || !this.userHavePermission
                                                    }
                                                    classes={{
                                                        trackAfter: classes.trackAfterWrapper,
                                                    }}
                                                    onChange={this.weightageChange(gIndex, sIndex)}
                                                />
                                            </div>
                                        </div>
                                    </Typography>
                                    {isAdvanceScoring &&
                                    <TextValidator
                                        id={`txtWeightage-${gIndex}-${sIndex}`}
                                        name={`txtWeightage-${gIndex}-${sIndex}`}
                                        type={!setting.enabled ? 'text' : 'number'}
                                        className={classes.txtWeightage}
                                        disabled={
                                            !setting.enabled || !this.userHavePermission
                                        }
                                        value={!setting.enabled ? '-' : settings[gIndex].data[sIndex].weightage.toString()}
                                        inputProps={{
                                            maxLength: 2,
                                            min: this.sliderConfig.min,
                                            max: this.sliderConfig.max,
                                            style: { textAlign: 'center' },
                                        }}
                                        validators={!setting.enabled ? [] : [`minNumber:${this.sliderConfig.min}`, `maxNumber:${this.sliderConfig.max}`]}
                                        errorMessages={[`range is ${this.sliderConfig.min} to ${this.sliderConfig.max}`, `range is ${this.sliderConfig.min} to ${this.sliderConfig.max}`]}
                                        onChange={this.weightageChange(gIndex, sIndex)}
                                    />
                                    }
                                </Typography>
                            );
                        })
                        }
                    </React.Fragment>
                );
            }
            return null;
        });
    }

    render() {
        const { classes } = this.props;
        const { isAdvanceScoring } = this.state;

        return (
            <Paper className={driverStyles.container}>
                <Typography component="div" className={driverStyles['content-wrapper']}>
                    <ValidatorForm noValidate autoComplete="off" onSubmit={() => this.handleSubmit()}>
                        <Typography variant="h5" className={driverStyles.heading} component="div">
                            <span style={{ marginTop: '3px' }}>Driver Scorecard - <span className={driverStyles['heading-sub']}>Settings</span></span>
                            { this.userHavePermission &&
                            <span className={driverStyles['save-btn-wrapper']}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                >
                                Save
                                </Button>
                            </span>
                            }
                            <Typography variant="h1" className={driverStyles.advanceScoringWrapper} component="div">
                                <React.Fragment>
                                    {this.scoreHelp.docURL &&
                                        <Link to={{ pathname: this.scoreHelp.docURL }} title={this.scoreHelp.hoverText} rel="noreferrer" target="_blank" style={{ color: 'inherit', textDecoration: 'none' }}>
                                            {this.scoreHelp.text}
                                        </Link>
                                    }
                                    <Typography component="div" className={`switch ${driverStyles.switchWrapper}`} data-elemet="switch">
                                        Advanced Scoring
                                        <Switch
                                            checked={isAdvanceScoring}
                                            onChange={this.advanceScoringToggle()}
                                            classes={{
                                                switchBase: classes.iOSSwitchBase,
                                                track: classes.iOSBar,
                                                icon: classes.iOSIcon,
                                                iconChecked: classes.iOSIconChecked,
                                                checked: this.userHavePermission
                                                    ? classes.iOSChecked : undefined,
                                            }}
                                            disabled={!this.userHavePermission}
                                        />
                                    </Typography>
                                </React.Fragment>
                            </Typography>
                        </Typography>
                        <Typography component="div" className={driverStyles['settings-section']}>
                            <Typography className={driverStyles['settings-violation']} component="div">
                                <Typography component="div">
                                    <span className={driverStyles['settings-header']}>Violation</span>
                                </Typography>
                            </Typography>
                            <Typography className={driverStyles['settings-action']} component="div">
                                <Typography component="div">
                                    <span className={driverStyles['settings-header']}>Enable/Disable</span>
                                </Typography>
                            </Typography>
                            <Typography className={driverStyles['settings-importance']} component="div">
                                <Typography component="div">
                                    <span className={driverStyles['settings-header']}>Importance</span>
                                </Typography>
                            </Typography>
                        </Typography>
                        {this.getKPIElements()}
                    </ValidatorForm>
                </Typography>
            </Paper>
        );
    }
}

export default withStyles(styles, { withTheme: true })(DriverSettings);
