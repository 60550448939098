/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Paper, Chip, Grid } from '@mui/material';
import withRouter from 'react-router-dom/withRouter';
import customStyles from './AssetGroupDetails.module.scss';
import SearchHeader from '../SharedComponents/SearchHeader/SearchHeader';
import GroupFilters from '../SharedComponents/GroupFilters/GroupFilters';
import List from '../SharedComponents/List/List';
import AssetListItem from './AssetListItem';
import { getPermissionValue, isPairedAsset } from '../../helper-classes/utility-functions';
import { getRefreshChip } from '../../util/map_utils';
import analytics from '../../analytics/index';

const MIN_SEARCH_LENGTH = 3;
export type Props = {
    assetGroup: Object,
    pageChanged: Function,
    isLoader: boolean,
    redirectTo: Function,
    setMarkerArray: Function,
    updateLoader: Function,
    filterData: Array<Object>,
    filtersSelected: boolean,
    searchedString: string,
    refreshData: Function,
    showRefreshButton: boolean,
    clearListData: Function,
    history: {
        push: Function,
    },
};
const isPagination = true;
type State = {
    data: any,
    ShowFilterDrawer: boolean,
    isSearch: boolean,
    prevFilterState: boolean,
    totalNumberofPage: number,
    page: number,
    lastSearch: string,
    filters: Array<{
        key: string,
        value: Array<{
            type: string,
            value: string,
            checked: boolean,
            label: string,
        }>
    }>,
    filtersSelected: boolean
}

class AssetGroupDetails extends Component<Props, State> {
    toggleFilterDrawer: Function;
    isPagination: boolean;
    currentPage: number;
    groupName: string;

    constructor(props) {
        super(props);
        let isSearch = false;
        if (this.props.searchedString && this.props.searchedString.length > 0) {
            isSearch = true;
        }
        this.state = {
            ShowFilterDrawer: false,
            isSearch,
            totalNumberofPage: 0,
            page: 0,
            data: [],
            lastSearch: this.props.searchedString || '',
            filters: JSON.parse(JSON.stringify(this.props.filterData)),
            filtersSelected: this.props.filtersSelected || false,
            prevFilterState: false,
        };

        this.isPagination = true;
        this.currentPage = 0;
        this.groupName = '';
    }

    componentDidMount() {
        if (this.props.clearListData && typeof this.props.clearListData === 'function') {
            this.props.clearListData(this.initState);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const response = nextProps.assetGroup;
        if (response && response.data) {
            const { data } = response;
            let newData;
            if (this.state.data && this.state.data.length > 0) {
                newData = this.state.data;
                let stateId = '';
                if (newData.length > 0) {
                    stateId = newData[newData.length - 1].id;
                }
                if (data.length > 0
                && stateId !== data[data.length - 1].id) {
                    data.forEach((d) => {
                        newData.push(d);
                    });
                    this.setState({
                        data: newData,
                        page: this.currentPage,
                    });
                    if (this.state.totalNumberofPage <= 0) {
                        this.calculatedPageCount(response);
                    }
                    this.props.setMarkerArray(newData);
                }
            } else if (this.props.isLoader && !nextProps.isLoader) {
                this.setState({
                    data,
                    page: this.currentPage,
                });
                if (this.state.totalNumberofPage <= 0) {
                    this.calculatedPageCount(response);
                }
                this.props.setMarkerArray(data);
            }
        }
    }

    calculatedPageCount = (res: any) => {
        if (res.total && res.total > 0) {
            const count = res.count || 10;
            const totalNumberofPage = Math.ceil(res.total / count);
            this.setState({ totalNumberofPage });
        }
    };

    initState = (lastSearch) => {
        this.setState({
            totalNumberofPage: 0,
            page: 0,
            data: [],
            lastSearch,
        });
        this.props.setMarkerArray([]);
        this.currentPage = 0;
    }

    isFilterApplied() {
        const { filters } = this.state;
        return filters.some((category) => {
            const found = category.value.some((filter) => {
                if (filter.checked) {
                    return true;
                }
                return false;
            });
            return found;
        });
    }

    toggleFilterDrawer = (open) => {
        this.setState({
            ShowFilterDrawer: open,
            isSearch: open,
            prevFilterState: this.state.filtersSelected,
        });

        const chips = this.isFilterApplied();
        if (!open && (chips || this.state.prevFilterState)) {
            this.initState('');
            this.props.updateLoader(true);
            this.props.pageChanged(
                0,
                this.state.isSearch,
                '',
                true,
                this.state.filters,
            );
        }
    }

    toggleFilterSearchHeader = (open) => {
        this.setState({ isSearch: open });
        this.initState('');

        if (!open) {
            this.props.updateLoader(true);
            this.props.pageChanged(
                0,
                this.state.isSearch,
                '',
                true,
                this.state.filters,
            );
        }
    };

    handleClusterRedirect = (e, redirectURL: string) => {
        e.preventDefault();
        e.stopPropagation();
        analytics.track('ASSET_GROUP_DETAILS_LIST', {
            ClickedItemType: 'Asset Group List Item - Cluster URL', RedirectURL: redirectURL,
        });
        this.props.history.push(redirectURL, { comingFromZoomedAsset: true });
        return false;
    }

    listItems = (data) => {
        let item = '';
        if (data) {
            item = (
                <div key={`asset_item_container_${data.id}`} role="link" tabIndex={0} onKeyDown={() => {}} onClick={() => this.goToAssetDetails(data)}>
                    <AssetListItem
                        asset={data}
                        key={`asset_item_${data.id}`}
                        redirectTo={this.handleClusterRedirect}
                    />
                    <div className={customStyles['list-border']} />
                </div>
            );
        }
        return item;
    };

    goToAssetDetails = (asset) => {
        if (getPermissionValue('Assets') !== 'None' && isPairedAsset(asset)) {
            this.props.redirectTo(`/home?assetId=${asset.id}&zoom=true`);
        }
        return true;
    }

    handleSearchChange = (e) => {
        this.setState({
            isSearch: true,
        }, () => {
            this.initState(e);
            if (e.length >= MIN_SEARCH_LENGTH) {
                this.props.updateLoader(true);
                this.props.pageChanged(0, true, e, true, this.state.filters);
            } else {
                this.props.updateLoader(false);
            }
        });
    };

    getHeaderName() {
        const groupName = (this.props.assetGroup.data && this.props.assetGroup.data.length > 0
        && this.props.assetGroup.data[0] && this.props.assetGroup.data[0].assetGroupName)
            ? this.props.assetGroup.data[0].assetGroupName : '';
        if (!this.groupName && groupName) {
            this.groupName = groupName;
        }
        return groupName || (this.groupName || ' ');
    }

    pageChanged = (page) => {
        this.currentPage = page;
        this.props.pageChanged(
            page, this.state.isSearch,
            this.state.lastSearch, false, this.state.filters,
        );
    };

    getAssetTotal() {
        let count = 0;
        if (this.state.data.length > 0) {
            count = this.props.assetGroup.total || 0;
        }
        return count;
    }

    getHeader = () => (
        <SearchHeader
            headerName={this.getHeaderName()}
            subHeader={`Showing ${this.state.data.length} of ${this.getAssetTotal()}`}
            title="Asset"
            toggleFilterDrawer={this.toggleFilterDrawer}
            toggleFilterSearchHeader={flag => this.toggleFilterSearchHeader(flag)}
            handleChange={this.handleSearchChange}
            filtersSelected={this.state.filtersSelected}
            searchedString={this.props.searchedString}
        />
    );

    getList = () => {
        let calHeight = 0;
        let filtersCount = 0;
        const { isLoader, setMarkerArray } = this.props;
        const {
            isSearch,
            page,
            data,
            totalNumberofPage,
            lastSearch = '',
            filters,
        } = this.state;

        filters.map(category => category.value.filter(filter => filter.checked))
            .forEach((d) => {
                filtersCount = (d.length) ? filtersCount + d.length : filtersCount;
            });

        if (filtersCount > 0) calHeight = Math.ceil(filtersCount / 3) * 46;

        return (
            <List
                data={data}
                isLoader={isLoader}
                height={calHeight}
                pagination={{ isPagination, pageChanged: this.pageChanged }}
                currentPage={page}
                totalNumberofPage={totalNumberofPage}
                isSearch={(isSearch && lastSearch.length < MIN_SEARCH_LENGTH)}
                getListItem={this.listItems}
                setMarkerArray={setMarkerArray}
            />
        );
    };

    filtersSelected() {
        let selectedFilters = [];
        if (this.state.filters) {
            this.state.filters.forEach(category =>
                category.value.forEach(filter =>
                    selectedFilters.push(filter.checked)));
        }
        selectedFilters = selectedFilters.filter(selected => selected === true);
        return selectedFilters.length > 0;
    }

    handleChange = (key, name, getNewAssets) => {
        let { filters } = this.state;
        let hasFiltersSelected;

        if (key && name) {
            filters.some((d, i) => {
                let flag = false;
                if (d.key === key) {
                    d.value.forEach((v, index) => {
                        if (v.value === name && v.type === 'Checkbox') {
                            filters[i].value[index].checked =
                                !filters[i].value[index].checked;
                        }
                    });
                    flag = true;
                }
                return flag;
            });
            hasFiltersSelected = this.filtersSelected();
        } else {
            filters = JSON.parse(JSON.stringify(this.props.filterData));
            for (let i = 0; i < filters.length; i += 1) {
                for (let k = 0; k < filters[i].value.length; k += 1) {
                    filters[i].value[k].checked = false;
                }
            }
            hasFiltersSelected = false;
        }

        this.setState(
            { filters, filtersSelected: hasFiltersSelected },
            () => {
                if (getNewAssets) {
                    this.initState(this.state.lastSearch);
                    this.props.updateLoader(true);
                    this.props.pageChanged(
                        0, this.state.isSearch,
                        this.state.lastSearch, true, filters,
                    );
                }
            },
        );
    };

    getFilterChips = () => {
        const { filters } = this.state;
        if (filters && filters.length > 0) {
            return (
                <Grid container>
                    {filters.map(category => category.value.map((filter) => {
                        if (filter.checked) {
                            const filterLabel = filter.label;
                            return (
                                <Chip
                                    className={customStyles['filter-chip']}
                                    key={filterLabel}
                                    label={filterLabel}
                                    onDelete={() =>
                                        this.handleChange(category.key, filter.value, true)
                                    }
                                />
                            );
                        }
                        return null;
                    }))}
                </Grid>
            );
        }
        return null;
    };

    render() {
        return (
            <div className={customStyles.container}>
                {!this.state.ShowFilterDrawer ?
                    <div id="asset-group-details">
                        {this.getHeader()}
                        {this.props.showRefreshButton && getRefreshChip(this.props.refreshData)}
                        {this.getFilterChips()}
                        {this.getList()}
                    </div> :
                    <Paper>
                        <GroupFilters
                            filterData={this.state.filters}
                            handleChange={this.handleChange}
                            toggleFilterDrawer={flag => this.toggleFilterDrawer(flag)}
                        />
                    </Paper>
                }
            </div>
        );
    }
}

export default withRouter(withStyles({}, { withTheme: true })(AssetGroupDetails));
