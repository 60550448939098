/* @flow */
import React, { PureComponent } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Card, CardContent, Typography, Tooltip, Button, SvgIcon } from '@mui/material';
import customStyles from './AssetDetails.module.scss';
import DetailAttributes from './DetailAttributes';
import Dialog from '../SharedComponents/Dialog/Dialog';
import DetailActions from './DetailActions';
import {
    setCustomAssetStatusStartDate,
    getAssetStatusDisplayDate,
    getAssetStatusStartDate,
    getPermissionValue,
    isPairedAsset,
    isAssetNonReporting,
    getTimeStr,
    getTimeDiff,
    hasFeature,
} from '../../helper-classes/utility-functions';
import { getAssetStatusTextColor, hoverIcon } from '../../util/map_utils';
import AppLoader from '../AppLoader';
import GetSvgIcon from '../../util/svgImage_util';
import { getDashcamColorFromStatus, isDashcamAuthenticated, setDashcamToken } from '../../util/dashcam_util';
import { getDashCamUserToken, getOrganizationDevices } from '../../containers/DashCam/helper-classes/dashcamServices';

const styles = () => ({
    editAssetBtn: {
        padding: '8px 0 0 0',
        minWidth: 24,
        minHeight: 24,
        background: 'inherit',
    },
});

export type Props = {
    assetsData: {
        assetId: string,
        assetName: string,
        type: string,
        typeName: string,
        status: string,
        deviceSerialNumber: string,
        locationLastReported: string,
        lat: string,
        lng: string,
        address: string,
        city: string,
        state: string,
        zipCode: string,
        statusStartDate: string,
        statusDisplayDate: string,
        landmarkId: string,
        landmarkName: string,
        assetStatus: {
            heading: string,
            speed: string,
            lastEvent: string,
            lastEventDate: string,
        },
        assetDetails: {
            assetGroupName: string,
            driver: string,
            tags: string,
            details: string,
            data: Object,
        },
        assetFeatures: {
            seatBeltEnabled: boolean,
            oilLifeEnabled: boolean,
            tirePressureEnabled: boolean,
        },
        reportingStatus: string,
    },
    setActiveTab: Function,
    isLoader: boolean,
    redirectTo: Function,
    assetDataLoadedCallback: Function,
    showBackButton: boolean,
    history: {
        goBack: Function,
    }
};

type State = {
    showDialog: boolean,
    dialogAttributes: {
        message: string,
        type: string,
        button: Array<Object>,
    },
    dashcamStatus: string,
};

const formatAddress = (data: Object) => {
    const secondAddress = [];
    if (data.city) secondAddress.push(data.city);
    if (data.state && data.zipCode) {
        secondAddress.push(`${data.state} ${data.zipCode}`);
    } else if (data.state) {
        secondAddress.push(data.state);
    } else if (data.zipCode) {
        secondAddress.push(data.zipCode);
    }

    return secondAddress.join(', ');
};

class AssetDetails extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showDialog: false,
            dialogAttributes: {
                message: '',
                type: '',
                button: [],
            },
            dashcamStatus: '',
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.assetsData &&
            typeof this.props.assetDataLoadedCallback === 'function' &&
            !Object.prototype.hasOwnProperty.call(this.props.assetsData, 'assetId') &&
            Object.prototype.hasOwnProperty.call(nextProps.assetsData, 'assetId')
        ) {
            hoverIcon({ ...nextProps.assetsData, id: nextProps.assetsData.assetId }, false);
            if (!isPairedAsset(nextProps.assetsData)) {
                const dialogAttributes = {
                    message: 'This asset is not currently connected to a device. Click OK to redirect to the previous page',
                    type: 'error',
                    button: [{
                        title: 'OK',
                        color: 'primary',
                        variant: 'contained',
                        handleClick: () => this.closeUnpairedDialog(),
                    }],
                };
                this.showDialog(dialogAttributes);
            } else {
                if (this.showDashcam() && nextProps.assetsData.assetDetails.data
                    && nextProps.assetsData.assetDetails.data.dashcamIdentifier) {
                    this.getDashcamData(nextProps.assetsData.assetDetails.data.dashcamIdentifier);
                }
                this.props.assetDataLoadedCallback(nextProps.assetsData);
            }
        }
    }

    showDashcam = () => hasFeature('fleet.Dashcam')

    getDashcamData = (dashcamIMEI: string) => {
        if (!dashcamIMEI) return;
        if (!isDashcamAuthenticated()) {
            getDashCamUserToken().then((response) => {
                if (response.status === 200) {
                    setDashcamToken(response);
                    this.getOrgDevices(dashcamIMEI);
                }
            });
        } else {
            this.getOrgDevices(dashcamIMEI);
        }
    }

    showDialog = dialogAttributes => this.setState({
        showDialog: true,
        dialogAttributes,
    });

    closeDialog = (callback) => {
        this.setState({
            showDialog: false,
        }, () => {
            if (typeof callback === 'function') callback();
        });
    }

    getOrgDevices = (dashcamIMEI: string) => {
        getOrganizationDevices(dashcamIMEI, 'imei').then((resp) => {
            if (resp && resp.data && resp.data[0] && resp.data[0].status) {
                this.setState({ dashcamStatus: resp.data[0].status });
            }
        });
    }

    closeUnpairedDialog = () => this.closeDialog(this.goBack);

    goBack = () => this.props.history.goBack();

    render() {
        const {
            assetsData: asset, isLoader, redirectTo, setActiveTab,
        } = this.props;
        const { dialogAttributes } = this.state;
        const { assetDetails } = asset || {};
        const statusColor = getAssetStatusTextColor(asset, true);
        const deviceInfo = { typeCode: '', modelCode: '' };
        let deviceGlobalId = '';
        const dashcam = { status: this.state.dashcamStatus };
        const dashcamColor = getDashcamColorFromStatus(dashcam);

        if (assetDetails && assetDetails.data) {
            deviceInfo.typeCode = assetDetails.data.deviceTypeCode;
            deviceInfo.modelCode = assetDetails.data.deviceModelCode;
            deviceGlobalId = assetDetails.data.deviceId;
        }

        const statusText = asset.assetStatus
            && setCustomAssetStatusStartDate(getAssetStatusStartDate(asset));
        const isNonReporting = isAssetNonReporting(asset);
        let lastReporting = '';

        if (isNonReporting) {
            lastReporting = (asset && asset.assetStatus && asset.assetStatus.lastEventDate) ? `not reported in ${getTimeStr(getTimeDiff(asset.assetStatus.lastEventDate, new Date()))}` : 'never reported';
        }

        return (
            <React.Fragment>
                {asset ?
                    <div id="asset-details-container" className={customStyles.container}>
                        <Card raised={false} id="header-card" className={customStyles.card}>
                            <CardContent className={customStyles['card-content']}>
                                <div style={{ display: 'flex' }}>
                                    {this.props.showBackButton &&
                                    <div>
                                        <button
                                            className={customStyles.back_button}
                                            onClick={() => window.history.back()}
                                        >
                                            <i className={customStyles.back_icon} />
                                        </button>
                                    </div>}
                                    <div style={{ flex: 'auto' }}>
                                        <Typography variant="h5" className={customStyles['asset-header']} component="h2" id="Asset-Name">
                                            <Tooltip title={asset.assetName || ''} className={customStyles['asset-header-name']} disableFocusListener>
                                                <span>{asset.assetName || ''}</span>
                                            </Tooltip>
                                            {this.state.dashcamStatus !== '' &&
                                                <Tooltip
                                                    title={this.state.dashcamStatus}
                                                    disableFocusListener
                                                    className={customStyles.dashcam_status}
                                                >
                                                    <span>
                                                        <GetSvgIcon
                                                            type={this.state.dashcamStatus === 'offline' ? 'offlineDashcam' : 'dashcamIcon'}
                                                            viewBox={dashcam.status === 'offline' ? '0 0 27 22' : '0 0 22 14'}
                                                            fillcolor={dashcamColor}
                                                            className={
                                                                customStyles.dashcam_status_icon
                                                            }
                                                        />
                                                    </span>
                                                </Tooltip>
                                            }
                                        </Typography>
                                        <Button
                                            id="action-edit-assets"
                                            onClick={() => redirectTo(`/assets/edit/${asset.assetId}`, 'editAsset')}
                                            style={{
                                                marginTop: 4, marginRight: -15, padding: 0, minWidth: 36, float: 'right',
                                            }}
                                            disabled={getPermissionValue('Assets') !== 'Modify'}
                                        >
                                            <Tooltip title="Edit Asset" disableFocusListener>
                                                <SvgIcon>
                                                    <path fill="#ffffff" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                                                </SvgIcon>
                                            </Tooltip>
                                        </Button>
                                        <Typography id="sub-header" className={customStyles['sub-header']} paragraph component="p">
                                            {asset.deviceSerialNumber || ''}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={customStyles['card-content-bottom']}>
                                    {asset.landmarkId !== 0 &&
                                    <Typography className={customStyles.address} component="p">
                                        {asset.landmarkName}
                                    </Typography>
                                    }
                                    <Typography id="address-line-one" className={customStyles.address} component="p">
                                        {asset.address || ''}
                                    </Typography>
                                    <Typography id="address-line-two" className={customStyles.address} component="p">
                                        {formatAddress(asset)}
                                    </Typography>
                                    <Typography id="lastLocation" className={customStyles.lastlocation} component="p">
                                        {getAssetStatusDisplayDate(asset)}
                                    </Typography>
                                    {!isNonReporting &&
                                    <div id="status" className={customStyles.status}>
                                        <Typography style={{ color: [statusColor] }} align="right" component="p">
                                            <span className={customStyles.statusText}>
                                                {asset.status || ''}
                                            </span>
                                        </Typography>
                                        <Typography style={{ color: [statusColor] }} align="right" component="p">
                                            <span style={{ color: [statusColor] }}>
                                                {statusText || ''}
                                            </span>
                                        </Typography>
                                    </div>
                                    }
                                </div>
                            </CardContent>
                            {isNonReporting &&
                            <div className={customStyles.insufficient_data}>
                                <SvgIcon width="29" height="24" viewBox="0 0 29 24">
                                    <path xmlns="http://www.w3.org/2000/svg" fill="#343E48" d="M27.747 20.617L16.013 1.237a2.223 2.223 0 0 0-3.802 0L.478 20.616a2.223 2.223 0 0 0 1.901 3.373h23.466a2.223 2.223 0 0 0 1.902-3.373z" />
                                    <path xmlns="http://www.w3.org/2000/svg" fill="#D8D8D8" d="M15.35 19.767h-2.476v-2.473h2.476zM15.35 14.811h-2.476V6.897h2.476z" />
                                </SvgIcon>
                                <span className={customStyles.insufficient_text}>
                                        Asset has {lastReporting}.
                                    <br />Asset may no longer be at this location.
                                </span>
                            </div>}
                        </Card>
                        {isLoader && <div className={customStyles.detailLoader}><AppLoader /></div>}
                        {asset.assetId && <DetailActions
                            setActiveTab={setActiveTab}
                            assetId={asset.assetId}
                            redirectTo={redirectTo}
                            deviceInfo={deviceInfo}
                            deviceGlobalId={deviceGlobalId}
                            assetName={asset.assetName}
                        />}
                        {asset.assetStatus && <DetailAttributes
                            assetStatus={asset.assetStatus}
                            assetDetails={assetDetails}
                            assetFeatures={asset.assetFeatures}
                        />}
                    </div>
                    :
                    <div> {isLoader && 'Data Loading...'} </div>
                }

                <Dialog
                    open={this.state.showDialog}
                    type={dialogAttributes.type}
                    customTitle=""
                    button={dialogAttributes.button}
                    content={dialogAttributes.message}
                    size="lg"
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles, { withTheme: true })(AssetDetails);
