/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_DEVICES_LIST } from './constants.deviceList';
import { fetchDevicesListError, fetchDevicesListSuccess } from './actions.deviceList';
import { getUserToken } from '../../helper-classes/utility-functions';
import config from '../../constants/Config';

type ObservarblesTypes = {
    postJSON: Function,
    of: Function
}

const formatData = (data: any) => {
    if (!data && data.length <= 0) return [];
    return data.map((d) => {
        const devices = Object.assign({}, d);
        devices.active = d.active ? 'Active' : 'Inactive';
        return devices;
    });
};

export const getDevices =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_DEVICES_LIST)
            .distinctUntilChanged()
            .debounceTime(config.get('DEBOUNCE_TIME'))
            .mergeMap((action) => {
                const {
                    pageNumber,
                    pageSize,
                    order,
                    orderBy,
                    filter = {},
                } = action.payload;
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };
                const data = {
                    sortParam: order && orderBy ? [[orderBy, order]] : undefined,
                    start: pageNumber * pageSize,
                    limit: pageSize,
                    filterParam: [],
                    searchParams: [''],
                };
                if (filter.deviceFiltersToSend && filter.deviceFiltersToSend.length > 0) {
                    data.filterParam = filter.deviceFiltersToSend;
                } else {
                    delete data.filterParam;
                }

                if (filter.search && filter.search.length > 0 && typeof filter.search === 'string') {
                    data.searchParams = [filter.search.replace(/[\s]{2,}/gi, ' ').trim()];
                } else {
                    delete data.searchParams;
                }

                return postJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/devices`, data, headers)
                    .map((result) => {
                        const formatedData = formatData(result.response.data);
                        return fetchDevicesListSuccess(formatedData, result.response.total);
                    })
                    .catch(error => of(fetchDevicesListError(error)));
            });

export default combineEpics(getDevices);
