/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_ADDED_LOOKS, DELETE_ADDED_LOOKS } from './constants.configureDashboard';
import {
    fetchAddedLooksError,
    fetchAddedLooksSuccess,
    deleteAddedLooksSuccess,
    deleteAddedLooksError,
} from './actions.configureDashboard';
import config from '../../constants/Config';
import { convertDateTimeAsperUserSetting, getTimeFormatAsperUserSettting, getUserToken, getAppToken } from '../../helper-classes/utility-functions';

type ObservablesTypes = {
    getJSON: Function,
    deleteJSON: Function,
    of: Function,
};

const formatData = (data: any) => {
    if (!data && data.length <= 0) return [];

    const timeFormat = getTimeFormatAsperUserSettting() === '24h' ? 'MM/DD/YYYY k:mm' : 'MM/DD/YYYY h:mm A';
    return data.map((d) => {
        const addedLooks = Object.assign({}, d);
        if (d.dateCreated) {
            addedLooks.dateCreated = convertDateTimeAsperUserSetting(d.dateCreated, timeFormat);
        }
        if (d.recipients) {
            addedLooks.recipients = d.recipients.join(', ');
        }
        return addedLooks;
    });
};

export const deleteAddedLooksAPI = (
    actions$: Function,
    store: Object,
    { deleteJSON, of }: ObservablesTypes,
) =>
    actions$.ofType(DELETE_ADDED_LOOKS)
        .mergeMap((action) => {
            const headers = {
                'X-Nspire-UserToken': getUserToken(),
                'Content-Type': 'application/json',
                'X-Nspire-AppToken': getAppToken(),
            };
            const url = `${config.get('FLEET_VIEW_SERVICES_URL')}/dashboard-api/dashboard/tiles/${action.payload.id}`;
            return deleteJSON(url, headers)
                .map((result) => {
                    if (result) {
                        return deleteAddedLooksSuccess(result);
                    }
                    return deleteAddedLooksError(result);
                })
                .catch(error => of(deleteAddedLooksError(error)));
        });

export const getAddedLooks = (
    actions$: Function,
    store: Object,
    { getJSON, of }: ObservablesTypes,
) =>
    actions$.ofType(FETCH_ADDED_LOOKS).mergeMap((action) => {
        const userToken = getUserToken();
        let userid = localStorage.getItem('currentAccountUserId');
        if (!userid) {
            userid = '';
        }

        const { editLook } = action.payload;

        const headers = {
            'X-Nspire-UserToken': userToken,
            'Content-Type': 'application/json',
            'X-Nspire-AppToken': getAppToken(),
        };
        return getJSON(
            `${config.get('FLEET_VIEW_SERVICES_URL')}/dashboard-api/dashboard/tiles`,
            headers,
        )
            .map((result) => {
                const formattedData = editLook ? result : formatData(result);
                return fetchAddedLooksSuccess(formattedData, result.total);
            })
            .catch(error => of(fetchAddedLooksError(error)));
    });

export default combineEpics(getAddedLooks, deleteAddedLooksAPI);
