/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Checkbox, Tooltip, Grid, IconButton } from '@mui/material';
import KmlDetailsHeader from './kmlDetailsHeader';
import customStyles from './kml.module.scss';
import AppLoader from '../../components/AppLoader';
import List from './../../components/SharedComponents/List/List';
import { saveUserPreference } from '../../helper-classes/utility-functions';
import { KML_MAP_PREF_KEY } from '../Home/constants.home';
import {
    kmlListHeight,
    ERROR_KML_FETCHING,
    NO_DATA,
    DELETE_KMLS_ERROR_MSG,
    ADD_KMLS_ERROR_MSG,
    SOMETHING_WENT_WRONG,
    ADD_KMLS_SUCCESSFULLY_MSG,
    DELETE_KMLS_SUCCESSFULLY_MSG,
    DELETE_KMLS_MSG,
} from './constants.kml';
import Dialog from '../../components/SharedComponents/Dialog/Dialog';
import GetSvgIcon from '../../util/svgImage_util';
import { getRefreshChip } from '../../util/map_utils';
import analytics from '../../analytics/index';

export type Props = {
    showKmlRefeshChip: Boolean,
    refreshKmlListData: Function,
    kmlLoader: boolean,
    pageChanged: Function,
    response: any,
    kmlPreference: any,
    updateKmlPreferenceUrls: Function,
    zoomToKmlLayer: Function,
    deleteKml: Function,
    isError: boolean,
    addedSuccessfully: boolean,
    closeDialog: Function,
    isDeleted: boolean,
    isDeleteError: boolean,
    error: any,
    addKml: Function,
    kmlPreferenceMethod: string,
    classes: Object,
    isKmlListError: boolean,
    addedKml: any,
    showBackButton: boolean,
    handleKmlBackBtnClick: Function,
};

type State = {
    data: any,
    listHeight: number,
    page: number,
    totalCount: number,
    totalNumberOfPage: number,
    kmlPreference: any,
    id: string,
    isDelete: boolean,
    zoomToKmlLayer: string,
}

const themeStyles = () => ({
    fileName: {
        fontSize: 16,
        color: 'rgba(0, 0, 0, 0.87)',
        lineHeight: 1.5,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        padding: '10px 0',
        whiteSpace: 'nowrap',
    },
    kmlListIcons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
});

class KmlDetailContainer extends Component<Props, State> {
    currentPage: number;
    button: any;
    clearFormData: any;
    constructor(props) {
        super(props);
        this.currentPage = 0;
        this.state = {
            ...this.getInitState(),
            listHeight: kmlListHeight,
            kmlPreference: this.props.kmlPreference,
        };
        this.button = [];
        this.clearFormData = null;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.response && nextProps.response.data) {
            const { response } = nextProps;
            const { data } = nextProps.response;
            if (data && data.length > 0) {
                let newData;
                if (this.state.data && this.state.data.length > 0) {
                    newData = this.state.data;
                    let stateId = '';
                    if (newData.length > 0) {
                        stateId = newData[newData.length - 1].id;
                    }
                    if (data.length > 0
                        && stateId !== data[data.length - 1].id) {
                        data.forEach((d) => {
                            newData.push(d);
                        });
                        this.setState({
                            data: newData,
                            page: this.currentPage,
                        });
                        if (this.state.totalNumberOfPage <= 0) {
                            this.calculatedPageCount(response);
                        }
                    }
                } else if (this.props.kmlLoader && !nextProps.kmlLoader) {
                    this.setState({
                        data,
                        page: this.currentPage,
                    });
                    if (this.state.totalNumberOfPage <= 0) {
                        this.calculatedPageCount(response);
                    }
                }
            }
        }
    }

    calculatedPageCount = (res: any) => {
        if (res.total && res.total > 0) {
            const count = res.max || 10;
            const totalNumberOfPage = Math.ceil(res.total / count);
            this.setState({ totalNumberOfPage, totalCount: res.total });
        }
    };

    getInitState = () => ({
        totalNumberOfPage: 0,
        page: 0,
        totalCount: 0,
        data: [],
        id: '',
        isDelete: false,
        zoomToKmlLayer: '',
    })

    updateKmlPreferenceUrls = (kmlPreference) => {
        saveUserPreference(KML_MAP_PREF_KEY, {
            config: kmlPreference,
            method: this.props.kmlPreferenceMethod,
        }).then((response) => {
            const kmlPreferenceData = response && response.value ? response.value : null;
            const kmlPreferenceUrls = kmlPreferenceData ? JSON.parse(kmlPreferenceData) : null;
            this.props.updateKmlPreferenceUrls(kmlPreferenceUrls);
        });
    };

    zoomToKmlLayer = (url) => {
        this.props.zoomToKmlLayer('');
        this.setState({ zoomToKmlLayer: url }, () => {
            this.props.zoomToKmlLayer(url);
        });
    }

    updateDeleteKml = (id) => {
        let kml = {};
        let { kmlPreference } = this.state;
        this.state.data.some((d) => {
            if (d.id === id) {
                kml = d;
                return true;
            }
            return false;
        });

        if (kml && kml.url) {
            kmlPreference = kmlPreference.filter(d => d !== kml.url);
            this.setState({ kmlPreference }, () => {
                this.zoomToKmlLayer('');
                this.updateKmlPreferenceUrls(kmlPreference);
            });
        }
    }

    handleChange = (target) => {
        let { kmlPreference } = this.state;
        const { zoomToKmlLayer } = this.state;
        const { value } = target;
        if (target.checked) {
            kmlPreference.push(value);
        } else if (target.checked === false) {
            kmlPreference = kmlPreference.filter(d => d !== value);
            if (zoomToKmlLayer && value === zoomToKmlLayer) {
                this.zoomToKmlLayer('');
            }
        }
        this.setState({ kmlPreference });
        this.updateKmlPreferenceUrls(kmlPreference);
    }

    isChecked = (value) => {
        const { kmlPreference } = this.state;
        if (Array.isArray(kmlPreference)) {
            return kmlPreference.some(d => d === value);
        }
        return false;
    }

    deleteKml = id => this.setState({ isDelete: true, id });

    listItems = data => (
        <Grid container key={data.id} sx={{ '&.MuiGrid-root': { height: '48px' } }}>
            <Grid item xs={2}>
                <Checkbox
                    checked={this.isChecked(data.url)}
                    onChange={e => this.handleChange(e.target)}
                    value={data.url}
                    color="primary"
                    inputProps={{
                        'aria-label': 'primary checkbox',
                    }}
                    style={{ paddingLeft: 16 }}
                />
            </Grid>
            <Grid item xs={7}>
                <div className={this.props.classes.fileName}>
                    <Tooltip title={data.fileName || ''} disableFocusListener>
                        <span>
                            {data.fileName}
                        </span>
                    </Tooltip>
                </div>
            </Grid>
            <Grid
                item
                xs={3}
                className={this.props.classes.kmlListIcons}
            >
                <IconButton
                    aria-label="view"
                    disabled={!this.isChecked(data.url)}
                    style={{ marginRight: -10 }}
                    onClick={() => {
                        this.zoomToKmlLayer(data.url);
                        const params = {
                            feature: 'Map',
                            KMLname: data.fileName,
                        };
                        analytics.track('VIEW_KML', params);
                    }}
                    size="large"
                >
                    <Tooltip title="View KML" disableFocusListener>
                        <span>
                            <GetSvgIcon
                                type="view-kml"
                                viewBox="-3 0 24 18"
                                fillcolor={!this.isChecked(data.url) ? 'rgba(0, 0, 0, 0.26)' : '#000'}
                            />
                        </span>
                    </Tooltip>
                </IconButton>
                <IconButton aria-label="delete" onClick={() => this.deleteKml(data.id)} size="large">
                    <Tooltip title="Delete KML" disableFocusListener>
                        <span>
                            <GetSvgIcon
                                type="delete-kml"
                                viewBox="0 -2 18 22"
                            />
                        </span>
                    </Tooltip>
                </IconButton>
            </Grid>
        </Grid>
    );

    pageChanged = (page) => {
        this.currentPage = page;
        this.props.pageChanged({ currentPage: page });
    }


    refreshKmlListData = (loadNewData) => {
        if (loadNewData) {
            this.setState({ ...this.getInitState() });
            this.currentPage = 0;
        }
        this.props.refreshKmlListData(loadNewData);
    }

    isShowAdd = (addKmlScreen, size) => this.setState({
        listHeight: addKmlScreen ? kmlListHeight + size : kmlListHeight,
    });

    getList = () => {
        const { kmlLoader, isKmlListError } = this.props;
        const {
            totalNumberOfPage,
            page,
            data,
            listHeight,
        } = this.state;
        let list = '';
        const isData = data && data.length > 0;

        if (isData) {
            list = (<List
                data={data}
                height={listHeight}
                currentPage={page}
                totalNumberofPage={totalNumberOfPage}
                pagination={{ isPagination: true, pageChanged: this.pageChanged }}
                getListItem={this.listItems}
                showMessage={false}
            />);
        }

        return (
            <React.Fragment>
                {list}
                {kmlLoader ? <div className={customStyles.detailLoader}><AppLoader /></div> : ''}
                {!isData && !kmlLoader ?
                    <div className={customStyles['no-kml']}>
                        {isKmlListError ? ERROR_KML_FETCHING : NO_DATA}
                    </div> : ''}
            </React.Fragment>
        );
    }

    formatError = (content: string, error: any) => {
        let newContent = content;
        if (error && error.errors) {
            newContent =
                (
                    <div>
                        {newContent}
                        <ul>
                            {
                                Object.keys(error.errors).map(d =>
                                    <li key={d}>{d}: {error.errors[d]}</li>)
                            }
                        </ul>
                    </div>
                );
        } else if (error && error.message) {
            newContent = `${newContent} ${error.message}`;
        } else if (error && error.msg) {
            newContent = `${newContent} ${error.msg}`;
        } else {
            newContent = `${newContent} ${SOMETHING_WENT_WRONG}`;
        }
        return newContent;
    }

    getContent = () => {
        const {
            isError,
            addedSuccessfully,
            error,
            isDeleted,
            isDeleteError,
        } = this.props;
        const { isDelete } = this.state;
        let content = '';

        if (isError) {
            content = (isDeleteError) ? DELETE_KMLS_ERROR_MSG : ADD_KMLS_ERROR_MSG;
            content = this.formatError(content, error);
        } else if (addedSuccessfully) {
            content = ADD_KMLS_SUCCESSFULLY_MSG;
            analytics.track('ADD_KML', { feature: 'Map' });
        } else if (isDeleted) {
            content = DELETE_KMLS_SUCCESSFULLY_MSG;
            analytics.track('DELETE_KML', { feature: 'Map' });
        }
        if (isDelete) {
            content = DELETE_KMLS_MSG;
        }
        return content;
    }

    handleClickOk = () => {
        const { isDelete, id } = this.state;
        const { addedSuccessfully, isDeleted } = this.props;
        if (isDelete) {
            this.props.deleteKml(id);
            this.setState({ isDelete: false });
        } else if (addedSuccessfully) {
            const { kmlPreference } = this.state;
            const { addedKml } = this.props;

            if (addedKml &&
                addedKml.url &&
                kmlPreference &&
                Array.isArray(kmlPreference)
            ) {
                kmlPreference.push(addedKml.url);
                this.updateKmlPreferenceUrls(kmlPreference);
                this.setState({ zoomToKmlLayer: '' }, () => {
                    this.zoomToKmlLayer(addedKml.url);
                });
            }

            this.refreshKmlListData(true);
            if (this.clearFormData && typeof this.clearFormData === 'function') {
                this.clearFormData();
            }
        } else if (isDeleted) {
            this.updateDeleteKml(this.state.id);
            this.refreshKmlListData(true);
        }
        this.props.closeDialog();
    }

    getType = () => {
        const { isError, addedSuccessfully, isDeleted } = this.props;
        const { isDelete } = this.state;
        let type = '';
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
        }];

        if (addedSuccessfully || isDeleted) {
            type = 'success';
        } else if (isError) {
            type = 'error';
        } else if (isDelete) {
            type = 'confirm';
            this.button.splice(0, 0, { title: 'Cancel', color: 'primary', handleClick: () => this.setState({ isDelete: false }) });
        }
        return type;
    }

    getHeader = () => (
        <KmlDetailsHeader
            isShowAdd={this.isShowAdd}
            totalCount={this.state.totalCount}
            count={this.state.data.length}
            addKml={this.props.addKml}
            clearFormData={(f) => {
                this.clearFormData = f;
            }}
            showBackButton={this.props.showBackButton}
            handleKmlBackBtnClick={this.props.handleKmlBackBtnClick}
        />
    )

    render() {
        const { isError, addedSuccessfully, isDeleted } = this.props;
        const { isDelete } = this.state;

        return (
            <div>
                {this.getHeader()}
                {this.props.showKmlRefeshChip && getRefreshChip(this.refreshKmlListData)}
                {this.getList()}
                <Dialog
                    open={addedSuccessfully || isError || isDelete || isDeleted}
                    type={this.getType()}
                    customTitle=""
                    button={this.button}
                    content={this.getContent()}
                    size="lg"
                />
            </div>
        );
    }
}

export default withStyles(themeStyles, { withTheme: true })(KmlDetailContainer);
