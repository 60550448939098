/* @flow */
import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import customStyles from './Directions.module.scss';
import {
    convertSpeedAsperUserSetting,
    getAssetStatusStartDate,
    isAssetNonReporting,
    getTimeStr,
    getTimeDiff,
    getNonReportingToolTip,
} from '../../../helper-classes/utility-functions';
import DirectionsAttributes from './DirectionsAttributes';
import DirectionsStepsDetails from './DirectionsStepsDetails';
import List from '../List/List';
import GetSvgIcon, { AssetNonReporting } from '../../../util/svgImage_util';

export type Props = {
    pathName: string,
    landmarksData: {
        id: string,
        name: string,
        asset: string,
        address: string,
    },
    assetData: {
        assetId: string,
        assetName: string,
        address: string,
        city: string,
        state: string,
        zipCode: string,
        status: string,
        statusStartDate: string,
        assetStatus: {
            heading: string,
            speed: string,
        },
        lastEventDate: string,
        reportingStatus: string,
    },
    directionsDetails: Object,
    history: {
        goBack: Function,
    },
    assetToLandmark: boolean,
};

export type State = {
};

class NearestAssetLandmarkDetails extends Component<Props, State> {
    props: Props;
    firstDataisLoaded: boolean;

    listItems = (data: any) => {
        let item = '';

        if (data) {
            item = (
                <div key={Math.random()}>
                    { this.firstDataisLoaded &&
                    <DirectionsAttributes directionsDetails={this.props.directionsDetails} />
                    }
                    <DirectionsStepsDetails steps={data} key="directionSteps" />
                </div>
            );
            this.firstDataisLoaded = false;
        }
        return item;
    };

    getList = () => {
        this.firstDataisLoaded = true;
        const {
            directionsDetails,
        } = this.props;
        return (
            <List
                data={directionsDetails.steps}
                getListItem={this.listItems}
                height={136}
            />
        );
    };

    getAssetStatusText = () => {
        const statusStartDate = getAssetStatusStartDate(this.props.assetData);
        if (statusStartDate === '') return '';
        return ` for ${statusStartDate}`;
    }

    getStatusColor = () => {
        const { assetData } = this.props;
        let statusColor;
        switch (assetData && assetData.status && assetData.status.toLowerCase()) {
        case 'moving':
            statusColor = customStyles.moving;
            break;
        case 'idle':
            statusColor = customStyles.idle;
            break;
        default:
            statusColor = customStyles.stopped;
            break;
        }
        return statusColor;
    }

    getAssetInfo = (secondaryData: boolean) => {
        const { assetData } = this.props;
        const isNonReporting = isAssetNonReporting(assetData);
        let lastReporting = '';

        if (isNonReporting) {
            lastReporting = assetData.lastEventDate ? `last report ${getTimeStr(getTimeDiff(assetData.lastEventDate, new Date()))} ago` : 'never reported';
        }

        return (
            <div style={secondaryData ? { paddingTop: '32px' } : {}}>
                <Grid container spacing={2} style={{ position: 'relative' }} className={customStyles.gridContainer}>
                    <Grid item xs={2}>
                        <div>
                            {isNonReporting &&
                                <React.Fragment>
                                    <AssetNonReporting
                                        className={customStyles.tooltip}
                                    />
                                    <span className={customStyles.tooltipText}>
                                        {getNonReportingToolTip(assetData)}
                                    </span>
                                </React.Fragment>
                            }
                            <Avatar style={{ backgroundColor: '#FFFFFF' }}>
                                <GetSvgIcon type="asset" fillcolor="#343e48" />
                            </Avatar>
                        </div>
                    </Grid>
                    <Grid item xs={7}>
                        <Tooltip title={assetData.assetName || ''} placement="bottom-start">
                            <Typography className={customStyles['asset-header']}>
                                {assetData.assetName}
                            </Typography>
                        </Tooltip>
                        {isNonReporting ?
                            <Typography component="p" className={customStyles['asset-status']}>
                                <span className={this.getStatusColor()}>
                                    {assetData.status}
                                </span>
                                <Tooltip title={lastReporting || ''}>
                                    <span
                                        className={customStyles.last_reporting_text}
                                    >
                                        &nbsp;({lastReporting})
                                    </span>
                                </Tooltip>
                            </Typography>
                            :
                            <Typography component="p" className={customStyles['asset-status']}>
                                <span className={this.getStatusColor()}>
                                    {assetData.status}{this.getAssetStatusText()}
                                </span>
                            </Typography>
                        }
                        <Tooltip title={assetData.address || ''} placement="bottom-start">
                            <Typography className={customStyles['asset-address']} component="p">
                                {assetData.address}
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        {assetData.assetStatus &&
                        <div>
                            <Typography paragraph className={customStyles['asset-speed']} align="right" component="p">
                                {convertSpeedAsperUserSetting(assetData.assetStatus.speed)}
                            </Typography>
                            <div className={customStyles['asset-direction-and-direction-icon']}>
                                {assetData.assetStatus.heading &&
                                <div>
                                    <div className={customStyles['asset-direction-icon']}>
                                        <i className={customStyles[`${assetData.assetStatus.heading}`]}>
                                            <GetSvgIcon type="back" style={{ height: '17px', width: '17px' }} />
                                        </i>
                                    </div>
                                    <Typography id="asset-direction" className={customStyles['asset-speed']} align="right" component="p">
                                        {assetData.assetStatus.heading}
                                    </Typography>
                                </div>
                                }
                            </div>
                        </div>
                        }
                    </Grid>
                </Grid>
            </div>
        );
    };

    getLandmarkInfo = (secondaryData: boolean) => {
        const { landmarksData } = this.props;
        return (
            <div style={secondaryData ? { paddingTop: '22px' } : {}}>
                <Grid container spacing={2} className={customStyles.gridContainer}>
                    <Grid item xs={2}>
                        <Avatar style={{ backgroundColor: '#FFFFFF' }}>
                            <GetSvgIcon fillcolor="#343e48" />
                        </Avatar>
                    </Grid>
                    <Grid item xs={7}>
                        <Tooltip title={landmarksData.name || ''} placement="bottom-start">
                            <Typography className={customStyles['landmark-header']} component="h2">
                                {landmarksData.name}
                            </Typography>
                        </Tooltip>
                        <Tooltip title={landmarksData.address || ''} placement="bottom-end">
                            <Typography className={customStyles['landmark-address']} component="p">
                                {landmarksData.address}
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <div>
                            <Typography paragraph className={customStyles['asset-speed']} align="right" component="p">
                                {landmarksData.asset}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    };

    redirectTo = () => {
        this.props.history.goBack();
    };

    getNearestAssetContent = (secondStep: boolean) => {
        if (this.props.assetToLandmark) {
            return secondStep ? this.getLandmarkInfo(secondStep) : this.getAssetInfo(secondStep);
        }
        return secondStep ? this.getAssetInfo(secondStep) : this.getLandmarkInfo(secondStep);
    }

    render() {
        const {
            landmarksData,
            assetData,
            directionsDetails,
            pathName,
        } = this.props;

        return (
            <div className="app">
                {landmarksData && assetData ?
                    <div id="asset-details-container" className={customStyles.container}>
                        <Card raised={false} className={customStyles.card}>
                            <CardContent className={customStyles['card-content']}>
                                <div className={customStyles['back-wrapper']}>
                                    <button className={customStyles['back-button']} onClick={() => this.redirectTo()}>
                                        <GetSvgIcon type="back" />
                                    </button>
                                </div>
                                <div>
                                    <Typography variant="h5" className={customStyles['backlabel-header']} component="h2">
                                        { pathName === 'nearest-landmark' ? 'Nearest Landmark' : 'Nearest Asset' }
                                    </Typography>
                                </div>
                                {pathName === 'nearest-landmark' ? this.getAssetInfo(false) : this.getNearestAssetContent(false)}
                                <div className={`${customStyles.timelineMarker}`} />
                                {pathName === 'nearest-landmark' ? this.getLandmarkInfo(true) : this.getNearestAssetContent(true)}
                            </CardContent>
                        </Card>
                        <div>
                            {
                                directionsDetails.steps ?
                                    this.getList() :
                                    <span className={customStyles['direction-header']} style={{ marginLeft: '110px' }}> No Directions Found </span>
                            }
                        </div>
                    </div>
                    :
                    <div>Loading data...</div>
                }
            </div>

        );
    }
}

export default (NearestAssetLandmarkDetails);
