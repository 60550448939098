/* @flow */
import React, { Component } from 'react';
import { isEmpty, getData } from '../../../helper-classes/utility-functions';
import Direction from '../../Direction/Direction';
import AppLoader from '../../AppLoader';
import { getFVSAssets } from '../../../containers/Maintenance/helper-classes/common-services';
import { formatAssetData, getShapeData } from './../../../containers/NearestAssetLandmark/epics.nearestAssetLandmark';
import config from './../../../constants/Config';

export type Props = {
    history: Object,
    assetId: string,
    landmarkId: string,
    landmark: Object,
};

export type State = {
    isUpdating: boolean,
    assetData: Object,
    landmarkData: Object,
};

class NearestAssetDetail extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isUpdating: false,
            assetData: {},
            landmarkData: this.props.landmark,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData =() => {
        if (this.props.landmarkId) {
            this.getLandmark();
        }
        this.getAsset();
    }

    getData = async () => {
        this.setState({ isUpdating: true });
        const data = await getFVSAssets([this.props.assetId]);
        let landmarkData = this.props.landmark;

        if (this.props.landmarkId) {
            const response = await getData(`${config.get('FLEET_VIEW_SERVICES_URL')}/landmarks/${this.props.landmarkId}?cb=${new Date().getTime()}`);
            if (response && response.data && response.data.length > 0) {
                [landmarkData] = response.data;
                landmarkData.address = landmarkData.fullAddress || '';
                landmarkData.lat = landmarkData.marker ? landmarkData.marker.y : 0;
                landmarkData.lng = landmarkData.marker ? landmarkData.marker.x : 0;
                landmarkData.shape = getShapeData(landmarkData);
                landmarkData.icon = landmarkData.attributes ? landmarkData.attributes.lfLandmarkIcon : '';
            }
        }

        const asset = (data && Array.isArray(data) && data.length > 0) ?
            formatAssetData(data[0]) : {};
        this.setState({ assetData: asset, isUpdating: false, landmarkData });
    }

    render() {
        const { assetData, landmarkData } = this.state;

        return (
            <React.Fragment>
                {!isEmpty(assetData) && !this.state.isUpdating ?
                    <React.Fragment>
                        <Direction
                            assetData={assetData}
                            history={this.props.history}
                            landmark={landmarkData}
                            landmarkId={this.props.landmarkId}
                        />
                    </React.Fragment>
                    :
                    <AppLoader type="fullScreen" />
                }
            </React.Fragment>
        );
    }
}

export default NearestAssetDetail;
