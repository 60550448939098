/* @flow */
import {
    FETCH_ALERT_HISTORY_ERROR,
    FETCH_ALERT_HISTORY_SUCCESS,
    FETCH_ALERT_HISTORY,
    RESET_ALERT_HISTORY_LIST,
} from './constants.alertHistory';

const initialState = {
    alertHistory: [],
    isUpdating: false,
};

type stateType = {
    alertHistory: Array<Object>,
    isUpdating: boolean,
};
type actionType = {
    type: string,
    payload: {
        alertHistory: {},
        totalRecords: number,
    },
};

export default (state: stateType = initialState, action: actionType) => {
    switch (action.type) {
    case FETCH_ALERT_HISTORY:
        return {
            ...state,
            isUpdating: true,
        };
    case RESET_ALERT_HISTORY_LIST:
        return {
            ...state,
            isUpdating: false,
            alertHistory: [],
            totalRecords: 0,
        };
    case FETCH_ALERT_HISTORY_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            alertHistory: action.payload.alertHistory,
            totalRecords: action.payload.totalRecords,
        };
    case FETCH_ALERT_HISTORY_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    default:
        return state;
    }
};
