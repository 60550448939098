/* @flow */
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from './../containers/Home/Home';
import Dashboard from './../containers/Dashboard/Dashboard';
import ConfigureDashboard from './../containers/ConfigureDashboard/ConfigureDashboard';
import LookSteps from './../containers/Looks/AddLook/LookSteps';
import LookLandingPage from './../components/Dashboard/LookLandingPage/LookLandingPage';
import PrivateRoute from '../components/PrivateRoute';
import { reduxConnect } from '../hoc';
import { fetchLoggedInUser } from '../containers/Login/actions.login';
import Address from '../containers/Address/Address';
import Landmarks from './../containers/Landmark/LandmarkDetails/Landmarks';
import LandmarkGroup from '../containers/Landmark/LandmarkGroup/LandmarkGroup';
import Settings from '../containers/Settings/Settings';
import Reports from '../containers/Reports/Reports';
import Assets from '../containers/Assets/Assets';
import AssetGroup from '../containers/AssetGroup/AssetGroup';
import AssetList from '../containers/AssetList/AssetList';
import Driver from '../containers/Driver/Driver';
import AssetGroupList from '../containers/AssetGroupList/AssetGroupList';
import LandmarksList from '../containers/Landmark/LandmarksList/LandmarksList';
import LandmarkGroupsList from '../containers/Landmark/LandmarkGroupsList/LandmarkGroupsList';
import AlertLandingPage from '../components/Alerts/AlertLandingPage/AlertLandingPage';
import PlanList from '../containers/Maintenance/ServicePlans/PlanList';
import ScoreCardList from '../containers/ScoreCardList/ScoreCardList';
import ScoreDriverList from '../containers/ScoreCardDriverList/ScoreDriverList';
import ScoreVehicleList from '../containers/ScoreCardVehicleList/ScoreVehicleList';
import DeviceList from '../containers/DeviceList/DeviceList';
import DriverList from '../containers/DriverList/DriverList';
import RecordListContainer from '../containers/Maintenance/ServiceRecords/RecordListContainer';
import AlertList from '../containers/Maintenance/Alerts/AlertList';
import MaintenanceStatus from '../containers/Maintenance/MaintenanceStatus/MaintenanceStatus';
import ScoreCardTrips from '../containers/Driver/ScoreCardTrips/ScoreCardTrips';
import AlertHistory from '../containers/Alerts/AlertHistory/AlertHistory';
import UserList from '../containers/UserList/UserList';
import UserRoleList from '../containers/UserRoleList/UserRoleList';
import UserAccessList from '../containers/UserAccessList/UserAccessList';
import SavedAlerts from '../containers/Alerts/SavedAlerts/SavedAlerts';
import RecipientList from '../containers/recipient/RecipientList';
import { getPermissionValue, hasFeature, clearGlobalVariables, clearCookieOnLogout, isReseller, getResellerInfo, getResellerData, redirectToAuthService } from '../helper-classes/utility-functions';
import Compliance from '../containers/Compliance/Compliance';
import DiagnosticListContainer from '../containers/Maintenance/Diagnostics/DiagnosticListContainer';
import ImpersonationLogin from '../containers/Login/ImpersonationLogin';
import ErrorPage from '../containers/ErrorPage';
import NearestAssetLandmark from '../containers/NearestAssetLandmark/NearestAssetLandmark';
import DispatchAction from '../containers/Assets/DispatchAction/DispatchAction';
import AppLoader from '../components/AppLoader';
import ImportLogsList from '../containers/Import/LogsList/LogsList';
import TermsAndCondition from '../containers/Reseller/TermsAndCondition';
import ImportHistory from '../containers/Import/History/History';
import NearestAssets from '../containers/Assets/NearestAssets';
import NearestAsset from '../containers/Assets/NearestAsset';
import DashCam from '../containers/DashCam/DashCam';
import LMDashCam from '../containers/LMDashCam/LMDashCam';
import AuthLogin from '../containers/AuthLogin/AuthLogin';
import { AUTH_LOGIN_URL, IMPERSONATION_LOGIN_URL } from './Config';

type Props = {
    isAuthenticated?: boolean,
    fetchLoggedInUser: Function,
    userSettingsUpdated: boolean,
    warning: Object,
};

class Routes extends Component<Props> {
    static defaultProps = {
        isAuthenticated: false,
    };

    UNSAFE_componentWillMount() {
        if (window.location.href.toLowerCase().indexOf(IMPERSONATION_LOGIN_URL) > -1) {
            localStorage.clear();
            sessionStorage.clear();
            clearGlobalVariables();
            clearCookieOnLogout();
        }
    }

    componentDidMount() {
        // if (this.props.isAuthenticated) {
        //     removed this call coz looks like no use to fetch user on each refresh
        //     if updated user info is needed then can be used.
        //     this.props.fetchLoggedInUser();
        // }
        if (this.props.isAuthenticated) {
            this.props.fetchLoggedInUser();
            if (isReseller() && !getResellerInfo().appLogo) {
                getResellerData();
            }
        }
    }

    render() {
        if (!this.props.isAuthenticated &&
            !(window.location.href.toLowerCase().indexOf(AUTH_LOGIN_URL) > -1 ||
            window.location.href.toLowerCase().indexOf(IMPERSONATION_LOGIN_URL) > -1)
        ) {
            const data = { isError: false, errorCode: '', ref: '' };
            if (this.props.warning && this.props.warning.needToShow) {
                data.isError = true;
                data.errorCode = 'SESSION_EXPIRED';
            }

            if (this.props.location && this.props.location.search && this.props.location.search.indexOf('?section=reports') !== -1) {
                data.ref = encodeURIComponent(this.props.location.search);
            }

            redirectToAuthService(data);

            return <AppLoader type="fullScreen" />;
        }

        return (
            (!this.props.isAuthenticated || this.props.userSettingsUpdated) ?
                <Switch>
                    {/** app level route start */}
                    <PrivateRoute path="/home" component={Home} isAuthenticated={this.props.isAuthenticated} show="View" />
                    <PrivateRoute path="/reports" component={Reports} {...this.props} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Reports')} />
                    <PrivateRoute path="/settings" component={Settings} {...this.props} isAuthenticated={this.props.isAuthenticated} show="View" />
                    <Route path="/auth/login" component={AuthLogin} {...this.props} show="View" />
                    <Route path="/auth/tokenLogin/" component={ImpersonationLogin} {...this.props} show="View" />
                    {/** End */}

                    {/** Dashboard Route Start */}
                    <PrivateRoute path="/dashboard" component={Dashboard} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Dashboard')} />
                    <PrivateRoute path="/configure-dashboard" component={ConfigureDashboard} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Dashboard')} />
                    <PrivateRoute exact path="/configure-module" {...this.props} component={LookSteps} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Dashboard')} />
                    <PrivateRoute exact path="/module-types" {...this.props} component={LookLandingPage} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Dashboard')} />
                    {/** End */}

                    {/** Maintenance Routes Start */}
                    <PrivateRoute exact path="/status" component={MaintenanceStatus} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Maintenance')} />
                    <PrivateRoute exact path="/plans" component={PlanList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Maintenance')} />
                    <PrivateRoute exact path="/records" component={RecordListContainer} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Maintenance')} />
                    <PrivateRoute exact path="/maintenance-alerts" component={AlertList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Maintenance')} />
                    <PrivateRoute exact path="/diagnostics" component={DiagnosticListContainer} isAuthenticated={this.props.isAuthenticated} show={hasFeature('fleetMaintenanceDtc') ? 'View' : 'None'} />
                    {/** End */}

                    {/** User route start */}
                    <PrivateRoute exact path="/users" component={UserList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Admin')} />
                    <PrivateRoute exact path="/users/add" component={UserList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Admin')} />
                    <PrivateRoute exact path="/users/edit/:id" component={UserList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Admin')} />
                    <PrivateRoute exact path="/user-roles" component={UserRoleList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Admin')} />
                    <PrivateRoute exact path="/user-roles/add" component={UserRoleList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Admin')} />
                    <PrivateRoute exact path="/user-roles/edit/:id" component={UserRoleList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Admin')} />
                    <PrivateRoute exact path="/user-access" component={UserAccessList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Admin')} />
                    <PrivateRoute exact path="/user-access/add" component={UserAccessList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Admin')} />
                    <PrivateRoute exact path="/user-access/edit/:id" component={UserAccessList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Admin')} />
                    {/** End */}

                    {/** Landmark route start */}
                    <PrivateRoute exact path="/landmarks" component={LandmarksList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Landmarks')} />
                    <PrivateRoute exact path="/landmarks/add" component={LandmarksList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Landmarks')} />
                    <PrivateRoute exact path="/landmarks/edit/:id" component={LandmarksList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Landmarks')} />
                    <PrivateRoute exact path="/landmark-groups/add/:parentId" component={LandmarkGroupsList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Landmark Groups')} />
                    <PrivateRoute exact path="/landmark-groups/add" component={LandmarkGroupsList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Landmark Groups')} />
                    <PrivateRoute exact path="/landmark-groups/edit/:groupId" component={LandmarkGroupsList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Landmark Groups')} />
                    <PrivateRoute exact path="/landmark-groups" component={LandmarkGroupsList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Landmark Groups')} />
                    <PrivateRoute exact path="/landmarks/:id/nearest-assets" {...this.props} component={NearestAssets} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Landmarks')} />
                    <PrivateRoute exact path="/landmarks/:id/nearest-assets/:assetId" {...this.props} component={NearestAsset} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Landmarks')} />
                    <PrivateRoute exact path="/landmarks/:id" component={Landmarks} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Landmarks')} />
                    <PrivateRoute exact path="/landmark-groups/:id" component={LandmarkGroup} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Landmark Groups')} />
                    {/** End */}

                    {/** Asset route start */}
                    <PrivateRoute exact path="/assets" component={AssetList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Assets')} />
                    <PrivateRoute exact path="/assets/add" component={AssetList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Assets')} />
                    <PrivateRoute exact path="/assets/edit/:id" component={AssetList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Assets')} />
                    <PrivateRoute exact path="/asset-groups/add/:parentId" component={AssetGroupList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Asset Groups')} />
                    <PrivateRoute exact path="/asset-groups/add" component={AssetGroupList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Asset Groups')} />
                    <PrivateRoute exact path="/asset-groups/edit/:groupId" component={AssetGroupList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Asset Groups')} />
                    <PrivateRoute exact path="/asset-groups" component={AssetGroupList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Asset Groups')} />
                    <PrivateRoute exact path="/assets/:id/nearest-landmark" component={NearestAssetLandmark} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Assets')} />
                    <PrivateRoute exact path="/assets/:id/dispatch-action" component={DispatchAction} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Assets')} />
                    <PrivateRoute exact path="/assets/:id/:activeTab?/:isPrint?/:fromDate?/:toDate?" component={Assets} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Assets')} />
                    <PrivateRoute exact path="/asset-groups/:id" component={AssetGroup} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Asset Groups')} />
                    <PrivateRoute exact path="/nearest-assets" {...this.props} component={NearestAssets} isAuthenticated={this.props.isAuthenticated} show="View" />
                    <PrivateRoute exact path="/nearest-assets/:assetId" {...this.props} component={NearestAsset} isAuthenticated={this.props.isAuthenticated} show="View" />
                    {/** End */}

                    {/** Driver route start */}
                    <PrivateRoute exact path="/drivers" component={DriverList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Admin')} />
                    <PrivateRoute exact path="/drivers/add" component={DriverList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Admin')} />
                    <PrivateRoute exact path="/drivers/edit/:id" component={DriverList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Admin')} />
                    <PrivateRoute exact path="/driver-setup" component={Driver} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Driver Setup') === 'Modify' ? 'Show' : 'None'} />
                    <PrivateRoute exact path="/driver-scorecard" component={ScoreDriverList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Driver Behavior')} />
                    <PrivateRoute exact path="/driver-scorecard/vehicles" component={ScoreVehicleList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Driver Behavior')} />
                    <PrivateRoute exact path="/driver-scorecard/driver/:driverId" component={ScoreVehicleList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Driver Behavior')} />
                    <PrivateRoute exact path="/driver-scorecard/trip/:id/:startDate/:endDate/:filterStartDate/:filterEndDate" component={ScoreCardList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Driver Behavior')} />
                    <PrivateRoute exact path="/driver-scorecard/trips/:vehicleId/:driverId?" component={ScoreCardTrips} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Driver Behavior')} />
                    {/** End */}

                    {/** DashCam route start */}
                    <PrivateRoute exact path="/dashcam" component={DashCam} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Dashcam')} />
                    {/** End */}

                    {/** LM DashCam route start */}
                    <PrivateRoute exact path="/lm-dashcam" component={LMDashCam} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('LightMetricsDashcam')} />
                    {/** End */}

                    {/** Alert route start */}
                    <PrivateRoute exact path="/alert-types" {...this.props} component={AlertLandingPage} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Alerts')} />
                    <PrivateRoute exact path="/alert-types/create-alert" {...this.props} component={SavedAlerts} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Alerts')} />
                    <PrivateRoute exact path="/alerts" component={AlertHistory} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Alerts')} />
                    <PrivateRoute path="/saved-alerts" component={SavedAlerts} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Alerts')} />
                    {/** End */}

                    {/** Trip route start */}
                    <PrivateRoute exact path="/devices" component={DeviceList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Devices')} />
                    {/** End here */}

                    {/** Trip route start */}
                    <PrivateRoute exact path="/address/:placeId" component={Address} isAuthenticated={this.props.isAuthenticated} show="View" />
                    {/** End */}

                    {/** Recipient route start */}
                    <PrivateRoute exact path="/recipients" component={RecipientList} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Admin')} />
                    {/** End here */}

                    <PrivateRoute exact path="/terms-condition" {...this.props} component={TermsAndCondition} isAuthenticated={this.props.isAuthenticated} show="View" />

                    {/** Compliance route start */}
                    <PrivateRoute path="/summary" {...this.props} component={Compliance} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Compliance')} componentProps={{ iframeURL: 'summary' }} />
                    <PrivateRoute path="/logs" {...this.props} component={Compliance} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Compliance')} componentProps={{ iframeURL: 'logs' }} />
                    <PrivateRoute path="/driver-status" {...this.props} component={Compliance} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Compliance')} componentProps={{ iframeURL: 'driverStatus' }} />
                    <PrivateRoute path="/inspections" {...this.props} component={Compliance} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Compliance')} componentProps={{ iframeURL: 'inspections' }} />
                    <PrivateRoute path="/compliance-reports" {...this.props} component={Compliance} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Compliance')} componentProps={{ iframeURL: 'report' }} />
                    <PrivateRoute path="/usdot-management" {...this.props} component={Compliance} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Compliance')} componentProps={{ iframeURL: 'usdot/management' }} />
                    <PrivateRoute path="/compliance-terminals" {...this.props} component={Compliance} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Compliance')} componentProps={{ iframeURL: 'filter/list/default' }} />
                    <PrivateRoute path="/policy" {...this.props} component={Compliance} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Compliance')} componentProps={{ iframeURL: 'policy' }} />
                    <PrivateRoute path="/repair-work-orders" {...this.props} component={Compliance} isAuthenticated={this.props.isAuthenticated} show={getPermissionValue('Repair Work Orders')} componentProps={{ iframeURL: 'workorders/list' }} />

                    {/** End */}

                    {/** Iframe routes */}
                    <Route exact path="/iframe/driver-scorecard" component={ScoreDriverList} {...this.props} show="View" />
                    <Route exact path="/iframe/driver-scorecard/vehicles" component={ScoreVehicleList} {...this.props} show="View" />
                    <Route exact path="/iframe/driver-scorecard/driver/:driverId" component={ScoreVehicleList} {...this.props} show="View" />
                    <Route exact path="/iframe/driver-scorecard/trip/:id/:startDate/:endDate/:filterStartDate/:filterEndDate" component={ScoreCardList} {...this.props} show="View" />
                    <Route exact path="/iframe/driver-scorecard/trips/:vehicleId/:driverId?" component={ScoreCardTrips} {...this.props} show="View" />
                    <Route exact path="/iframe/driver-setup" component={Driver} {...this.props} show="View" />
                    <Route exact path="/error-page/:type" component={ErrorPage} {...this.props} show="View" />
                    {/** End */}

                    {/** Import routes */}
                    <PrivateRoute exact path="/import/history" {...this.props} component={ImportHistory} isAuthenticated={this.props.isAuthenticated} show={(getPermissionValue('Admin') !== 'None') ? 'View' : 'None'} />
                    <PrivateRoute exact path="/import/:type/:jobId" {...this.props} component={ImportLogsList} isAuthenticated={this.props.isAuthenticated} show={(getPermissionValue('Admin') !== 'None') ? 'View' : 'None'} />
                    {/** End */}

                    {/** default route start */}
                    <Redirect from="/" to="/home" {...this.props} />
                    <Redirect from="**" to="/home" {...this.props} />
                    {/** End */}
                </Switch>
                :
                <AppLoader type="fullScreen" />
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.userSession.loggedIn,
    warning: state.userSession.warning,
    userSettingsUpdated: state.userSession.userSettingsUpdated,
});

export default reduxConnect(Routes, { fetchLoggedInUser }, mapStateToProps);
