/* eslint-disable  */
import React from 'react';
import { Tooltip, Chip } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { mapColor, HALO_EFFECT_ZINDEX, HALO_EFFECT_TIMEOUT } from '../containers/constants.map';
import { getDistanceString, isEmpty } from '../helper-classes/utility-functions';
import { getAssetColorFromStatus } from './trip_util';
import mapStyles from './../containers/MapsWrapper.module.scss'; 

let haloTimeout = null;

const fetchGeoCodeByAddress = (address, callback) => {
    const google = window.googleMaps;
    if (!google && !google.maps) return callback({ error: 'something went wrong' }, null);
    const geocoder = new google.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
            const position =
            { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
            return callback(null, position);
        }
        return callback({ error: `ERROR: Found Empty Place : ${status}` }, null);
    });
};

export const getAssetIconType = (name) => {
    if (name) {
        try {
            return `${name.split('.').slice(-1)[0]}`;
        } catch (error) {
        }
    }
    return 'truck';
};

export const getLandmarkIconType = (name) => {
    if (name) {
        try {
            return `${name.split('/').slice(-1)[0]}`;
        } catch (error) {
        }
    }
    return 'default';
};

export const getAssetColor = (asset: Object) => {
    const status = (asset && asset.status) ? asset.status.toLowerCase() : '';
    const { cluster } = mapColor;
    let color = '';
    switch (status) {
    case 'stopped':
        color = cluster.stopped;
        break;
    case 'idle':
        color = cluster.idle;
        break;
    case 'moving':
        color = cluster.moving;
        break;
    case 'notreporting':
    case 'non-reporting':
        color = cluster.notreporting;
        break;
    default:
        color = '#007aff';
        break;
    }
    return color;
};

export const getAssetStatusTextColor = (asset: Object, nrWhite: boolean = false) => {
    const status = (asset && asset.status) ? asset.status.toLowerCase() : '';
    const { statusText } = mapColor;
    let statusTxtColor = '';
    switch (status) {
    case 'stopped':
        statusTxtColor = statusText.stopped;
        break;
    case 'idle':
        statusTxtColor = statusText.idle;
        break;
    case 'moving':
        statusTxtColor = statusText.moving;
        break;
    case 'notreporting':
    case 'non-reporting':
        if (!nrWhite) statusTxtColor = statusText.notreporting;
        break;
    default:
        statusTxtColor = '#fff';
        break;
    }
    return statusTxtColor;
};

export const getLandmarkOrLocation = (asset: Object, defaultTooltip: boolean = false, handleLandmarkClick: Function = undefined) => {
    let location = `${(asset.address || '')}${(asset.city ? ` , ${asset.city}` : '')}${(asset.stateOrProvince ? ` , ${asset.stateOrProvince}` : '')}${(asset.zipCode ? ` ${asset.zipCode}` : '')}`;

    if (asset.landmarkId) {
        location = (
            <Tooltip title={location} disableFocusListener>
                {!handleLandmarkClick ?
                    <Link
                        to={`/home?landmarkId=${asset.landmarkId}&zoom=true`}
                        style={{ color: 'inherit' }}
                    >
                        {asset.landmarkName}
                    </Link>
                    :
                    <a
                        style={{ color: 'inherit', textDecoration: 'underline' }}
                        onClick={e => (handleLandmarkClick ? handleLandmarkClick(e, `/home?landmarkId=${asset.landmarkId}&zoom=true`) : {})}
                    >
                        {asset.landmarkName}
                    </a>
                }
            </Tooltip>
        );
    } else if (defaultTooltip) {
        if (location !== '') {
            location = (
                <Tooltip title={location} disableFocusListener>
                    <span>{location}</span>
                </Tooltip>
            );
        } else location = '- -';
    }
    return location === '' ? 'Unknown' : location;
};

export const getRefreshChip = (onRefresh: Function, refreshLabel: string = 'Click to Refresh Data') => (<Chip
    icon={<Refresh style={{ cursor: 'pointer' }} />}
    clickable
    sx={{
        backgroundColor: 'primary.main',
        margin: 'calc(100% - 409px) 25%',
        position: 'absolute',
        zIndex: 1300,
    }}
    key="refresh-panel-data"
    label={refreshLabel}
    onDelete={() => onRefresh(false)}
    onClick={() => onRefresh(true)}
    color="primary"
/>);

export const getAddressFromCoordinates = (coordinates: Object, callback: Function) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat: parseFloat(coordinates.lat), lng: parseFloat(coordinates.lng) };
    geocoder.geocode({
        location: latlng,
    }, (results, status) => ((status === 'OK' && results[0]) ? callback(results[0]) : callback({})));
}

export const fetchLocationByPlaceId = (value: string, callback: Function) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId: value }, (results, status) => ((status === 'OK' && !isEmpty(results)) ? callback(results[0]) : callback({})));
};

export const getDirectionFromCoordinates = (coordinates: Object, callback: Function) => {
    const directionsService = new window.googleMaps.DirectionsService();
    directionsService.route({
        origin: new window.googleMaps.LatLng(
            coordinates.origin.lat,
            coordinates.origin.lng,
        ),
        destination: new window.googleMaps.LatLng(
            coordinates.destination.lat,
            coordinates.destination.lng,
        ),
        travelMode: window.googleMaps.TravelMode.DRIVING || '',
        unitSystem: getUnitSystem(),
        drivingOptions: {
            departureTime: new Date(),
            trafficModel: 'pessimistic',
        },
    }, (result, status) => ((status === 'OK' && result) ? callback(result) : callback([])));
}

export const getUnitSystem = () => {
    if (window.googleMaps && window.googleMaps.UnitSystem) {
        if (getDistanceString() === 'mi') {
            return window.googleMaps.UnitSystem.IMPERIAL;
        }
        return window.googleMaps.UnitSystem.METRIC;
    }
    return '';
};

export const hoverIcon = (asset, appandClass) => {
    const color = getAssetColorFromStatus(asset);
    const colorCode = (color) ? `-${color.substr(1, color.length)}` : '';
    const assetId = (colorCode) ? `${asset.id}${colorCode}` : `${asset.id}`;
    const assetMapIcon = document.getElementById(assetId);
    let allPulseClasses = document.querySelectorAll('[class^=MapsWrapper_pulse]');
    allPulseClasses = [].slice.call(allPulseClasses);

    if (allPulseClasses && allPulseClasses.length > 0) {
        allPulseClasses.forEach((e) => {
            if (e) {
                e.classList.remove(e.className);
                e.parentElement.style.zIndex = (+e.parentElement.style.zIndex - HALO_EFFECT_ZINDEX);
            }
        });
    }

    if (assetMapIcon && appandClass) {
        assetMapIcon.parentElement.style.zIndex = (+assetMapIcon.parentElement.style.zIndex + HALO_EFFECT_ZINDEX) || HALO_EFFECT_ZINDEX;
        assetMapIcon.classList.add(mapStyles[`pulse${colorCode}`]);
    }
};

export const haloEffectTimeout = (callback: Function) => {
    if (haloTimeout) clearTimeout(haloTimeout);
    haloTimeout = setTimeout(callback, HALO_EFFECT_TIMEOUT);
}

export default fetchGeoCodeByAddress;
