import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Tooltip, TextField } from '@mui/material';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { getDriverDropDownDates, getLocalStorageValue, setLocalStorageItem, removeLocalStorageItem } from '../../helper-classes/utility-functions';
import constants from './constants.common';

type Props = {
    selectedValue: string,
    onChange: Function,
    classes: any,
}

const styles = {
    button: {
        display: 'block',
    },
    range: {
        fontSize: 15,
        fontWeight: 550,
        color: 'rgba(0, 0, 0, 0.87)',
        paddingRight: 27,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
    },
    dialogButton: {
        display: 'flex',
        whiteSpace: 'nowrap',
    },
    formControl: {
        minWidth: 95,
    },
};

const stylesClass = () => ({
    root: {
        padding: 6,
    },
    text: {
        padding: '5px 11px 0px 11px',
        display: 'inline-block',
    },
});

class ControlledOpenSelect extends React.Component<Props, {}> {
    constructor(props) {
        super(props);
        const driverCustomDateFilter = JSON.parse(getLocalStorageValue('driverCustomDateFilter') || '{"endDate":null,"startDate":null}');
        this.state = {
            selectedValue: this.props.selectedValue || '',
            open: false,
            toDate: driverCustomDateFilter.endDate || null,
            fromDate: driverCustomDateFilter.startDate || null,
            isError:
            this.monthValidation(driverCustomDateFilter.startDate, driverCustomDateFilter.endDate),
            openFromDatePicker: false,
            openToDatePicker: false,
        };
    }

    handleChange = (event) => {
        this.setState({ selectedValue: event.target.value });
        if (event.target.value !== 'custom') {
            this.setState({ toDate: null, fromDate: null, isError: false });
            removeLocalStorageItem('driverCustomDateFilter');
            this.props.onChange(event.target.value, getDriverDropDownDates(event.target.value));
        }
    };

    applyDateRange = () => {
        const { toDate, fromDate } = this.state;
        const dates = {
            startDate: moment(fromDate).startOf('day'),
            endDate: moment(toDate).endOf('day'),
        };
        setLocalStorageItem('driverCustomDateFilter', JSON.stringify(dates));
        this.props.onChange('custom', dates);
    }

    handleDateRangeDateToChange = (date: Date) => {
        if (moment(date).isSame(this.state.toDate, 'd')) return;

        this.setState({ toDate: date, isError: false, openToDatePicker: false }, () => {
            if (!this.state.isError) this.applyDateRange();
        });
    }

    monthValidation = (startDate: Date, endDate: Date) => (endDate ? moment(startDate).add(3, 'months').isBefore(endDate, 'd') : false);

    handleDateRangeDateFromChange = (date: Date) => {
        if (moment(date).isSame(this.state.fromDate, 'd')) return;

        this.setState({ fromDate: date, openFromDatePicker: false });
        let { toDate } = this.state;

        if (this.state.toDate) {
            if (moment(date).isAfter(toDate)) {
                toDate = date;
            }
            const isError = this.monthValidation(date, toDate);
            this.setState({ toDate, isError }, () => {
                if (!isError) this.applyDateRange();
            });
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    fromDatePickerOpenHandler = () => {
        if (!this.state.fromDate) {
            this.setState({ fromDate: moment() })
        }
    };

    toDatePickerOpenHandler = () => {
        if (!this.state.toDate) {
            this.setState({ toDate: moment() })
        }
    };

    handleToDatepickerClick = () => {
        this.toDatePickerOpenHandler();
        this.setState({ openToDatePicker: true })
    };

    handleFromDatepickerClick = () => {
        this.fromDatePickerOpenHandler();
        this.setState({ openFromDatePicker: true })
    };

    render() {
        const {
            selectedValue, open, toDate, fromDate,
            isError,
        } = this.state;
        const { classes } = this.props;
        let dateToMinDate = moment().subtract(1, 'year');
        if (fromDate) {
            dateToMinDate = fromDate;
        }
        const dateFromMaxDate = moment();
        const calenderColor = toDate ? '#007aff' : '#767676';
        return (
            <div style={styles.dialogButton}>
                <div style={{ alignSelf: 'center' }}>
                    <InputLabel style={styles.range} htmlFor="demo-controlled-open-select"> Reporting Range</InputLabel>
                </div>
                <div>
                    <form autoComplete="on">
                        <FormControl style={styles.formControl}>
                            <Select
                                disableUnderline
                                open={open}
                                onClose={this.handleClose}
                                onOpen={this.handleOpen}
                                value={selectedValue}
                                onChange={this.handleChange}
                            >
                                <MenuItem value="yesterday">Yesterday</MenuItem>
                                <MenuItem value="7">Last 7 Days</MenuItem>
                                <MenuItem value="30">Last 30 Days</MenuItem>
                                <MenuItem value="lastMonth">Last Month</MenuItem>
                                <MenuItem value="90">Last three months</MenuItem>
                                <MenuItem value="365">Last year</MenuItem>
                                <MenuItem value="396">Last 13 months</MenuItem>
                                <MenuItem value="custom">Date Range</MenuItem>
                            </Select>
                        </FormControl>
                        {selectedValue === 'custom' &&
                        <React.Fragment>
                            <FormControl style={{ ...styles.formControl, paddingLeft: 22 }}>
                                <LocalizationProvider dateAdapter={MomentUtils}>
                                    <DatePicker
                                        onChange={this.handleDateRangeDateFromChange}
                                        onOpen={this.fromDatePickerOpenHandler}
                                        open={this.state.openFromDatePicker}
                                        renderInput={params => (
                                            <TextField
                                                sx={{ '.MuiSvgIcon-root': { fontSize: 18, fill: fromDate ? constants.primaryColor : constants.grayColor },
                                                    width: '100px' }}
                                                {...params}
                                                onClick={this.handleFromDatepickerClick}
                                                inputProps={{ readOnly: 'true', value: fromDate ? moment(this.state.fromDate).format('MM/DD/YY') : '' }}
                                            />
                                        )}
                                        onClose={() => this.setState({ openFromDatePicker: false })}
                                        value={fromDate}
                                        format="MM/DD/YY"
                                        disableFuture
                                        minDate={moment().subtract(1, 'year')}
                                        maxDate={dateFromMaxDate}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                            <div className={classes.text}>
                                to
                            </div>
                            <FormControl style={styles.formControl}>
                                <LocalizationProvider dateAdapter={MomentUtils}>
                                    <Tooltip
                                        title={isError ? <span style={{ fontSize: '10px' }}>{constants.monthValidationErrorMessage}</span> : ''}
                                    >
                                        <span>
                                            <DatePicker
                                                onChange={this.handleDateRangeDateToChange}
                                                onOpen={this.toDatePickerOpenHandler}
                                                open={this.state.openToDatePicker}
                                                value={toDate}
                                                error={isError}
                                                format="MM/DD/YY"
                                                disableFuture
                                                disabled={!fromDate}
                                                minDate={dateToMinDate}
                                                maxDate={moment(fromDate).add(3, 'months')}
                                                onClose={() => this.setState({
                                                    openToDatePicker: false,
                                                })}
                                                maxDateMessage=""
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        sx={{ '.MuiSvgIcon-root': { fontSize: 18, fill: isError ? constants.redColor : calenderColor }, width: '100px' }}
                                                        onClick={this.handleToDatepickerClick}
                                                        inputProps={{ readOnly: 'true', value: toDate ? moment(this.state.toDate).format('MM/DD/YY') : '' }}
                                                    />
                                                )}
                                            />
                                        </span>
                                    </Tooltip>
                                </LocalizationProvider>
                            </FormControl>
                        </React.Fragment>
                        }
                    </form>
                </div>
            </div>
        );
    }
}

ControlledOpenSelect.propTypes = {
    selectedValue: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default withStyles(stylesClass)(ControlledOpenSelect);
