/* @flow */
import React from 'react';
import { Typography, Tooltip } from '@mui/material';
import GetSvgIcon from '../../../util/svgImage_util';
import { isDashcamCameraSelected, getDashcamLiveViewColor } from '../../../util/dashcam_util';
import customStyles from './DashcamCard.module.scss';
import { isEmpty } from '../../../helper-classes/utility-functions';

export type Props = {
    dashcam: Object,
    appliedFiltersObj?: Object,
    handleInnerLiveViewClick: Function,
};

const LiveViewCamerasContent = (props: Props) => {
    const { dashcam = {}, appliedFiltersObj = {} } = props;

    return dashcam.cameras && dashcam.cameras.map((c) => {
        const isCameraSelected = isDashcamCameraSelected(
            dashcam,
            !isEmpty(appliedFiltersObj) ? appliedFiltersObj.selectedDashcamsForLiveView : [],
            c.cameraId,
        );
        const isInCabinRecording = appliedFiltersObj.selectedDashcamsForLiveView.some(cam =>
            (cam.imei === dashcam.imei && cam.isCabinRecording));
        const cameraLiveViewIconColor = (!isInCabinRecording && c.cameraId === 2) ?
            getDashcamLiveViewColor({}, false) : getDashcamLiveViewColor(dashcam, isCameraSelected);

        return (
            <div key={`${dashcam.imei}-${c.cameraId}`} className={customStyles.dashcam_live_view_container}>
                <Tooltip title={dashcam.name || ''} disableFocusListener>
                    <Typography noWrap component="h3" variant="h3">
                        {c.name || ''}
                    </Typography>
                </Tooltip>
                <div style={{ position: 'relative', marginLeft: '170px' }}>
                    <Tooltip
                        title={`${c.name} Live View`}
                        disableFocusListener
                    >
                        <div>
                            <GetSvgIcon
                                type="dashcamLiveView"
                                data-dashcam-imei={dashcam.imei}
                                data-camera-id={c.cameraId}
                                fillcolor={cameraLiveViewIconColor}
                                onClick={e => props.handleInnerLiveViewClick(
                                    e, dashcam, c.cameraId,
                                    isCameraSelected,
                                    isInCabinRecording,
                                )}
                            />
                        </div>
                    </Tooltip>
                </div>
            </div>);
    });
};

LiveViewCamerasContent.defaultProps = {
    appliedFiltersObj: {},
};

export default LiveViewCamerasContent;
