/* @flow */

export const DISPATCH_FETCH_ASSETS_DETAILS: string = 'DISPATCH_FETCH_ASSETS_DETAILS';
export const DISPATCH_FETCH_ASSETS_DETAILS_SUCCESS: string = 'DISPATCH_FETCH_ASSETS_DETAILS_SUCCESS';
export const DISPATCH_FETCH_ASSETS_DETAILS_ERROR: string = 'DISPATCH_FETCH_ASSETS_DETAILS_ERROR';
export const DISPATCH_SEND_DIRECTION: string = 'DISPATCH_SEND_DIRECTION';
export const DISPATCH_SEND_DIRECTION_SUCCESS: string = 'DISPATCH_SEND_DIRECTION_SUCCESS';
export const DISPATCH_SEND_DIRECTION_ERROR: string = 'DISPATCH_SEND_DIRECTION_ERROR';
export const CLOSE_DIALOG: string = 'CLOSE_DIALOG';
export type DispatchTypeAction = {
    type: string;
payload?: Object;
}
