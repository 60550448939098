/* @flow */
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Drawer, List, ListItem, ListItemText, Badge, Collapse, Divider, IconButton, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Header from '../../containers/Header/Header';
import customStyles from './SideMenuDrawer.module.scss';
import { IconWrapper } from './MenuIcons';
import {
    getPermissionValue,
    showCompliance,
    trackAnalytics,
    hasFeature,
    removeSavedPage,
    getUserPreference,
    checkUserSettings,
} from '../../helper-classes/utility-functions';
import { getOverdueCount } from '../../containers/Maintenance/helper-classes/common-services';
import gorillaSafetyLogo from '../../assets/icons/side_menu/GorillaSafetyLogo.svg';
import lytxLogo from '../../assets/icons/side_menu/lytx.png';
import config from '../../constants/Config';
import EditAccountPopup from './EditAccountPopup';
import { DASHCAM_ANALYTICS, DASHCAM_LIST, LIGHT_METRICS_DASHCAM_ANALYTICS } from './../../containers/DashCam/constants.dashcam';
import analytics from './../../../src/analytics/index';
import { SCORECARD_PREF_KEY } from '../../containers/ScoreCardDriverList/constants.scoredriverList';

const drawerWidth = 256;
const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 'calc(100vh - 46px)',
        zIndex: 1,
        overflowX: 'hidden',
        position: 'relative',
        display: 'flex',
        backgroundColor: '#f5f5f5',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 0,
        marginRight: 24,
        color: 'white',
        borderRadius: 5,
    },
    hide: {
        display: 'none',
    },
    minWidth: {
        width: 50,
    },
    itemSelected: {
        fontWeight: 'bold',
    },
    subheading: {
        fontSize: 14,
    },
    poweredBy: {
        fontSize: 10,
    },
    nested: {
        padding: `5px 24px 5px ${theme.spacing(5)}`,
        '&:hover': {
            backgroundColor: '#d8d8d8',
            fontWeight: 'bold',
            borderRadius: 5,
            width: '100% !important',
        },
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        background: 'transparent',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
    },
    navMenuList: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 15,
        left: -8,
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        [theme.breakpoints.up('sm')]: {
            width: 60,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        overflow: 'auto',
    },
    iconHover: {
        opacity: 0.67,
        '&:hover': {
            opacity: 1,
        },
    },
});

export type Props = {
    classes: Object,
    children: [],
    redirectTo: Function,
    selectedTab: string,
};

export type State = {
    open: boolean,
    selectedLevel1: string,
    subMenus: {
        Map: boolean,
        Dashboard: boolean,
        Fleet: boolean,
        Reports: boolean,
        Alerts: boolean,
        Admin: boolean,
        'Driver Behavior': boolean,
        CamCoach: boolean,
        lmCamCoach: boolean,
        Maintenance: boolean,
        Compliance: boolean,
    },
    notificationCounts: {},
    MaintenanceOverdueCount: any,
    selectedTab: string,
    updateSelectedTab: Function,
};

const navItems = () => [{
    id: 1,
    title: 'Map',
    show: getPermissionValue('Home'),
    path: 'home',
    eventName: undefined,
}, {
    id: 9,
    title: 'Dashboard',
    show: (getPermissionValue('Dashboard') !== 'None' && (config.get('FLEETPWA_ENABLE_DASHBOARD_MODULE') === true || config.get('FLEETPWA_ENABLE_DASHBOARD_MODULE') === 'true')) ? 'View' : 'None',
    path: 'dashboard',
    eventName: undefined,
}, {
    id: 2,
    title: 'Fleet',
    show: getPermissionValue('Admin') !== 'None' ? getPermissionValue('Admin') : getPermissionValue('Fleet'),
    subMenu: [{
        id: 1,
        name: 'Assets',
        path: 'assets',
        show: getPermissionValue('Fleet'),
        eventName: undefined,
    },
    {
        id: 2,
        name: 'Landmarks',
        path: 'landmarks',
        show: getPermissionValue('Fleet'),
        eventName: undefined,
    },
    {
        id: 3,
        name: 'Asset Groups',
        path: 'asset-groups',
        show: getPermissionValue('Asset Groups'),
        eventName: undefined,
    },
    {
        id: 4,
        name: 'Landmark Groups',
        path: 'landmark-groups',
        show: getPermissionValue('Landmark Groups'),
        eventName: undefined,
    },
    {
        id: 5,
        name: 'Drivers',
        path: 'drivers',
        show: getPermissionValue('Admin'),
        eventName: undefined,
    },
    ],
},
{
    id: 7,
    title: 'Maintenance',
    show: getPermissionValue('Maintenance'),
    subMenu: [{
        id: 1,
        name: 'Status',
        path: 'status',
        show: 'Show',
        eventName: undefined,
    },
    {
        id: 2,
        name: 'Service Plans',
        path: 'plans',
        show: 'Show',
        eventName: undefined,
    },
    {
        id: 3,
        name: 'Service Records',
        path: 'records',
        show: 'Show',
        eventName: undefined,
    },
    {
        id: 4,
        name: 'Alerts',
        path: 'maintenance-alerts',
        show: 'Show',
        eventName: undefined,
    },
    {
        id: 5,
        name: 'Diagnostics',
        path: 'diagnostics',
        show: hasFeature('fleetMaintenanceDtc') ? 'Show' : 'None',
        eventName: undefined,
    },
    ],
}, {
    id: 3,
    title: 'Reports',
    path: 'reports',
    show: getPermissionValue('Reports'),
    eventName: undefined,
}, {
    id: 4,
    title: 'Alerts',
    show: getPermissionValue('Alerts'),
    subMenu: [{
        id: 1,
        name: 'Alert Types',
        path: 'alert-types',
        show: getPermissionValue('Alerts') === 'Modify' ? 'Show' : 'None',
        eventName: undefined,
    },
    {
        id: 2,
        name: 'Alert History',
        path: 'alerts',
        show: 'Show',
        eventName: undefined,
    },
    {
        id: 3,
        name: 'Saved Alerts',
        path: 'saved-alerts',
        show: 'Show',
        eventName: undefined,
    }],
}, {
    id: 6,
    title: 'Driver Behavior',
    show: getPermissionValue('Driver Behavior'),
    subMenu: [
        {
            id: 1,
            name: 'Scorecard',
            path: 'driver-scorecard',
            show: getPermissionValue('Driver Scorecard'),
            eventName: undefined,
        },
        {
            id: 2,
            name: 'Setup',
            path: 'driver-setup',
            show: getPermissionValue('Driver Setup') === 'Modify' ? 'Show' : 'None',
            eventName: undefined,
        },
    ],
}, {
    id: 10,
    title: 'CamCoach',
    show: getPermissionValue('Dashcam'),
    path: 'dashcam',
    eventName: DASHCAM_ANALYTICS,
    subMenu: [
        {
            id: 11,
            name: 'Powered by:',
            path: 'none',
            class: 'gs-icon',
            show: 'Show',
            eventName: undefined,
        },
    ],
}, {
    id: 11,
    menuLabel: 'CamCoach',
    title: 'lmCamCoach',
    show: getPermissionValue('LightMetricsDashcam'),
    eventName: LIGHT_METRICS_DASHCAM_ANALYTICS,
    subMenu: [
        {
            id: 1,
            name: 'Dashboard',
            path: 'lm-dashcam/lm-home',
            show: 'Show',
            eventName: 'LIGHT_METRICS_DASHCAM_DASHBOARD_CLICK',
        },
        {
            id: 2,
            name: 'Safety Events',
            path: 'lm-dashcam/lm-safety-events',
            show: 'Show',
            eventName: 'LIGHT_METRICS_DASHCAM_SAFETY_EVENTS_CLICK',
        },
        {
            id: 3,
            name: 'Live View',
            path: 'lm-dashcam/lm-live-view',
            show: 'Show',
            eventName: 'LIGHT_METRICS_DASHCAM_LIVE_VIEW_EVENTS_CLICK',
        },
        // Commenting out the coaching tab for now will be added later
        // {
        //     id: 4,
        //     name: 'Coaching',
        //     path: 'lm-dashcam/lm-coaching',
        //     show: 'Show',
        //     eventName: 'LIGHT_METRICS_DASHCAM_COACHING_CLICK',
        // },
        {
            id: 5,
            name: 'Video Requests',
            path: 'lm-dashcam/lm-video-requests',
            show: 'Show',
            eventName: 'LIGHT_METRICS_DASHCAM_VIDEO_REQUEST_EVENTS_CLICK',
        },
        // Commenting out the `Drivers and Challenges` tab for now will be added later
        // {
        //     id: 6,
        //     name: 'Drivers',
        //     path: 'lm-dashcam/lm-drivers',
        //     show: 'Show',
        //     eventName: 'LIGHT_METRICS_DASHCAM_DRIVERS_EVENTS_CLICK',
        // },
        // {
        //     id: 7,
        //     name: 'Challenges',
        //     path: 'lm-dashcam/lm-challenges',
        //     show: 'Show',
        //     eventName: 'LIGHT_METRICS_DASHCAM_CHALLENGES_CLICK',
        // },
        {
            id: 8,
            name: 'Assets',
            path: 'lm-dashcam/lm-assets',
            show: 'Show',
            eventName: 'LIGHT_METRICS_DASHCAM_ASSETS_EVENTS_CLICK',
        },
        {
            id: 9,
            name: 'Configurations',
            path: 'lm-dashcam/lm-configurations',
            show: 'Show',
            eventName: 'LIGHT_METRICS_DASHCAM_CONGIGURATIONS_EVENTS_CLICK',
        },
    ],
}, {
    id: 5,
    title: 'Admin',
    show: getPermissionValue('Admin'),
    subMenu: [
        {
            id: 1,
            name: 'Users',
            path: 'users',
            show: 'Show',
            eventName: undefined,
        },
        {
            id: 2,
            name: 'User Roles',
            path: 'user-roles',
            show: 'Show',
            eventName: undefined,
        },
        {
            id: 3,
            name: 'User Access',
            path: 'user-access',
            show: 'Show',
            eventName: undefined,
        },
        {
            id: 4,
            name: 'Recipients',
            path: 'recipients',
            show: 'Show',
            eventName: undefined,
        },
        {
            id: 5,
            name: 'Devices',
            path: 'devices',
            show: getPermissionValue('Devices'),
            eventName: undefined,
        },
        {
            id: 6,
            name: 'Imports',
            path: 'import/history',
            show: 'Show',
            eventName: undefined,
        },
    ],
}, {
    id: 8,
    title: 'Compliance',
    show: showCompliance() ? 'Show' : 'None',
    subMenu: [
        {
            id: 1,
            name: 'Summary',
            path: 'summary',
            show: 'Show',
            eventName: 'COMPLIANCE_SUMMARY_TAB_CLICK',
        },
        {
            id: 2,
            name: 'Logs',
            path: 'logs',
            show: 'Show',
            eventName: 'COMPLIANCE_LOGS_TAB_CLICK',
        },
        {
            id: 3,
            name: 'Driver',
            path: 'driver-status',
            show: 'Show',
            eventName: 'COMPLIANCE_DRIVER_TAB_CLICK',
        },
        {
            id: 4,
            name: 'Inspections',
            path: 'inspections',
            show: 'Show',
            eventName: 'COMPLIANCE_INSPECTIONS_TAB_CLICK',
        },
        {
            id: 5,
            name: 'Repair Work Orders',
            path: 'repair-work-orders',
            show: hasFeature('fleetEldWorkOrders') ? 'Show' : 'None',
            eventName: 'COMPLIANCE_REPAIR_WORK_ORDER_TAB_CLICK',
        },
        {
            id: 6,
            name: 'Reports',
            path: 'compliance-reports',
            show: 'Show',
            eventName: 'COMPLIANCE_REPORTS_TAB_CLICK',
        },
        {
            id: 7,
            name: 'USDOT No. Management',
            path: 'usdot-management',
            show: 'Show',
            eventName: 'COMPLIANCE_USDOT_No._Management_TAB_CLICK',
        },
        {
            id: 8,
            name: 'Terminals',
            path: 'compliance-terminals',
            show: 'Show',
            eventName: 'COMPLIANCE_TERMINALS_TAB_CLICK',
        },
        {
            id: 9,
            name: 'Policies & Procedures',
            path: 'policy',
            show: 'Show',
            eventName: 'COMPLIANCE_POLICIES_TAB_CLICK',
        },
        {
            id: 10,
            name: 'Powered by:',
            path: 'none',
            class: 'gs-icon',
            show: 'Show',
            eventName: undefined,
        },
    ],
}];

class SideMenuDrawer extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        const Menu = this.getSelectedTab();
        this.state = {
            subMenus: Menu.subMenu,
            open: true,
            selectedLevel1: Menu.selectedLevel1,
            notificationCounts: {},
            MaintenanceOverdueCount: (localStorage.getItem('maintenanceOverdueCount') !== undefined || localStorage.getItem('maintenanceOverdueCount') !== 'null') ? localStorage.getItem('maintenanceOverdueCount') : '',
            selectedTab: '',
            updateSelectedTab: this.updateSelectedTab.bind(this),
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        prevState.updateSelectedTab(nextProps, prevState);
        return null;
    }

    componentDidMount() {
        let isMaintanenceTabVisible = navItems().find(item => item.title === 'Maintenance');
        isMaintanenceTabVisible = (isMaintanenceTabVisible && isMaintanenceTabVisible.show) !== 'None';

        if (isMaintanenceTabVisible) {
            setTimeout(() => this.refreshOverdueMaintenance(), 1500);
            document.addEventListener('updateOverdueCount', () => {
                this.refreshOverdueMaintenance();
            });
            document.addEventListener('updateOverdueStatuses', () => {
                setTimeout(() => this.refreshOverdueMaintenance(), 1500);
            });
        }
    }

    updateSelectedTab = (props, state) => {
        if ((state.selectedTab || props.selectedTab) && state.selectedTab !== props.selectedTab) {
            this.setState({ selectedTab: props.selectedTab }, () => {
                const Menu = this.getSelectedTab();
                this.setState({
                    subMenus: Menu.subMenu,
                    selectedLevel1: Menu.selectedLevel1,
                });
            });
        }
    }

    refreshOverdueMaintenance = () => {
        getOverdueCount().then((response: any) => {
            if (response && (response.total === 0 || response.total)) {
                let overdue = '';
                const regExp = /^[\d]*$/gi;
                if (regExp.test(response.total)) {
                    if (response.total > 99) {
                        overdue = '99+';
                    } else if (response.total > 0) {
                        overdue = response.total;
                    }
                }
                this.setState({ MaintenanceOverdueCount: overdue });
                localStorage.setItem('maintenanceOverdueCount', overdue);
            }
        });
    }

    getSelectedTab = () => {
        const subMenu = {
            Map: false,
            Dashboard: false,
            Fleet: false,
            Reports: false,
            Alerts: false,
            Admin: false,
            'Driver Behavior': false,
            CamCoach: false,
            lmCamCoach: false,
            Maintenance: false,
            Compliance: false,
        };
        let selectedLevel1 = 'Map';
        const url = document.location.pathname;
        const urlParams = url.split('/');
        let { selectedTab } = this.state || { selectedTab: '' };
        if (urlParams.some(u => u === 'lm-dashcam') && !selectedTab) {
            selectedTab = 'lm-home';
        }
        navItems().some((m) => {
            if (m.path && (urlParams.some(u => u === `${m.path}`) || m.path.indexOf(`/${selectedTab}`) >= 0)) {
                selectedLevel1 = m.title;
                return true;
            }
            if (m.subMenu) {
                return m.subMenu.some((sm) => {
                    if (sm.path && (url === `/${sm.path}` || urlParams.some(u => u === `${sm.path}`) || (selectedTab !== '' && sm.path.indexOf(`/${selectedTab}`) >= 0))) {
                        selectedLevel1 = m.title;
                        return true;
                    }
                    return false;
                });
            }
            return false;
        });
        subMenu[selectedLevel1] = true;
        return { subMenu, selectedLevel1 };
    }

    handleDrawerOpen = () => {
        const { selectedLevel1, subMenus } = this.state;
        Object.keys(subMenus).forEach((d) => {
            subMenus[d] = (d === selectedLevel1) ? subMenus[d] : false;
        });
        this.setState({ open: true, subMenus });
    };

    handleDrawerClose = () => {
        const { selectedLevel1, subMenus } = this.state;
        Object.keys(subMenus).forEach((d) => {
            subMenus[d] = (d === selectedLevel1);
        });
        this.setState({ open: false, subMenus });
    };

    selectMenu = (selectedName: string, eventName?: string) => {
        const mainMenuItemClicked = { mainMenuItemClicked: true };
        if (eventName) {
            if (eventName === DASHCAM_ANALYTICS) {
                analytics.track(
                    DASHCAM_ANALYTICS,
                    { feature: DASHCAM_ANALYTICS, eventName: DASHCAM_LIST },
                );
            } else trackAnalytics(eventName);
        }
        removeSavedPage();
        if (selectedName === 'driver-scorecard') {
            return getUserPreference(SCORECARD_PREF_KEY).then((response) => {
                this.setState({ selectedTab: '' });
                if (response && response.value) {
                    this.props.redirectTo(
                        `/${(response.value).replace(/"/g, '')}`,
                        mainMenuItemClicked,
                    );
                } else {
                    this.props.redirectTo(
                        '/driver-scorecard/vehicles',
                        mainMenuItemClicked,
                    );
                }
            });
        }
        this.setState({ selectedTab: '' });

        if (selectedName.indexOf('lm-dashcam/') >= 0) {
            return this.props.redirectTo('/lm-dashcam', { ...mainMenuItemClicked, selectedTab: selectedName.split('/').pop() });
        }

        return this.props.redirectTo(`/${selectedName}`, mainMenuItemClicked);
    };

    handleCurrentListItem = (e) => {
        if (e.itemid) {
            const { subMenus, open } = this.state;
            this.setState({
                selectedLevel1: e.itemid,
                subMenus: {
                    ...subMenus,
                    [e.itemid]: (open) ? !subMenus[e.itemid] : true,
                },
            }, () => {
                this.handleDrawerOpen();
            });
        }
    };

    getSelectorClass = isopen => ((isopen) ?
        customStyles['active-menu'] :
        customStyles['active-menu-closed']);

    render() {
        const { classes, children } = this.props;
        const { open, selectedLevel1, selectedTab } = this.state;

        return (
            <div className="app">
                {checkUserSettings() &&
                    <EditAccountPopup />
                }
                <Header
                    isStateOpen={open}
                    openMenu={
                        <IconButton
                            aria-label="open drawer"
                            onClick={() => this.handleDrawerOpen()}
                            className={`${classes.menuButton} ${open ? classes.hide : ''}`}
                            size="large"
                        >
                            <MenuIcon />
                        </IconButton>}
                    closeMenu={
                        <IconButton
                            className={classes.menuButton}
                            onClick={() => this.handleDrawerClose()}
                            size="large"
                        >
                            <MenuIcon />
                        </IconButton>
                    }
                />
                <div className={classes.root} style={{ clear: 'both' }}>
                    <Drawer
                        variant="permanent"
                        anchor="right"
                        classes={{
                            paper: `${classes.drawerPaper}
                            ${!open ? classes.drawerPaperClose : ''}
                            ${classes.minWidth}`,
                        }}
                        open={open}
                    >
                        <Divider />
                        <List
                            className={classes.navMenuList}
                            id="menu-list"
                            onClick={e => this.handleCurrentListItem(e.target.dataset)}
                        >
                            {navItems().map(list => (
                                (['Show', 'View', 'Modify', 'Manager'].indexOf(list.show) > -1) &&
                                <React.Fragment key={list.title}>
                                    <ListItem
                                        key={list.title}
                                        data-itemid={list.title}
                                        className={`${(selectedLevel1 === (list.title)) ?
                                            this.getSelectorClass(open) : ''} ${customStyles['zero-padding']}`}
                                        onClick={() => {
                                            if (list.path) {
                                                this.selectMenu(list.path, list.eventName);
                                            }
                                        }}
                                    >
                                        <Tooltip
                                            title={list.menuLabel ? list.menuLabel : list.title}
                                        >
                                            <IconButton
                                                id={list.title}
                                                data-qa={list.title}
                                                data-itemid={list.title}
                                                className={customStyles['custom-btn']}
                                                size="large"
                                            >
                                                <IconWrapper
                                                    data-itemid={list.title}
                                                    className={classes.iconHover}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                        <ListItemText
                                            data-itemid={list.title}
                                            disableTypography
                                            style={{ paddingLeft: '8px', paddingRight: 16 }}
                                            primary={
                                                <div
                                                    data-itemid={list.title}
                                                    className={customStyles['menu-text']}
                                                >
                                                    {list.menuLabel ? list.menuLabel : list.title}
                                                    {
                                                        open && this.state[`${list.title}OverdueCount`] &&
                                                        <Badge badgeContent={this.state[`${list.title}OverdueCount`]} className={customStyles['menu-count']}>
                                                            <span />
                                                        </Badge>
                                                    }
                                                </div>
                                            }
                                            secondary={undefined}
                                        />
                                    </ListItem>
                                    {
                                        list.subMenu && list.subMenu.map((subMenuItem) => {
                                            const subHeadingClass = (subMenuItem.class && subMenuItem.class === 'gs-icon') ? classes.poweredBy : classes.subheading;
                                            const poweredByStyle = {
                                                padding: subMenuItem.class !== 'gs-icon' ? undefined : 0,
                                                paddingLeft: subMenuItem.class !== 'gs-icon' ? 30 : 0,
                                                margin: subMenuItem.class !== 'gs-icon' ? undefined : '-4px 2px 0 0',
                                            };

                                            return (['Show', 'View', 'Modify'].indexOf(subMenuItem.show) > -1 || list.show === 'Manager') &&
                                            <Collapse
                                                component="li"
                                                key={`${subMenuItem.id}`}
                                                in={this.state.subMenus[(list.title)] &&
                                            open === true}
                                                timeout="auto"
                                                unmountOnExit
                                                className={subMenuItem.class && subMenuItem.class === 'gs-icon' && customStyles['disable-clicks']}
                                            >
                                                <List component="div" disablePadding>
                                                    <ListItem
                                                        className={classes.nested}
                                                        style={{
                                                            justifyContent: subMenuItem.class !== 'gs-icon' ? '' : 'flex-end',
                                                            paddingRight: subMenuItem.class !== 'gs-icon' ? undefined : 0,
                                                        }}
                                                        button
                                                        id={subMenuItem.name.replace(/ +/g, '')}
                                                        onClick={() =>
                                                            this.selectMenu(
                                                                subMenuItem.path,
                                                                subMenuItem.eventName,
                                                            )
                                                        }
                                                    >
                                                        <div className={customStyles
                                                            .powered_by_logo_container
                                                        }
                                                        >
                                                            <ListItemText
                                                                classes={
                                                                    (document.location.pathname
                                                                        .indexOf(`/${subMenuItem.path
                                                                            .toLowerCase()}`) > -1 ||
                                                                            (selectedTab && subMenuItem.path.indexOf(`/${selectedTab}`) > -1)) ?
                                                                        {
                                                                            primary: `${classes.itemSelected} ${subHeadingClass}`,
                                                                        } : {
                                                                            primary:
                                                                                subHeadingClass,
                                                                        }}
                                                                inset
                                                                style={poweredByStyle}
                                                                primary={subMenuItem.name}
                                                            />
                                                            {subMenuItem.class && subMenuItem.class === 'gs-icon' && (list.title === 'Compliance') && <img src={gorillaSafetyLogo} alt="gorilla" className={customStyles.powered_by_logo} />}
                                                            {subMenuItem.class && subMenuItem.class === 'gs-icon' && (list.title === 'CamCoach') && list.path === 'dashcam' && <img src={lytxLogo} alt="lytx" className={customStyles.powered_by_logo} />}
                                                        </div>
                                                    </ListItem>
                                                </List>
                                            </Collapse>
                                        })
                                    }
                                </React.Fragment>
                            ))}
                        </List>
                    </Drawer>
                    <main
                        className={classes.content}
                        style={{ padding: '0', background: 'transparent', width: '80%' }}
                    >
                        {children}
                    </main>
                </div>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SideMenuDrawer);
