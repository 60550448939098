/* @flow */
import React, { Component } from 'react';
import { ArrowBack } from '@mui/icons-material';
import {
    Paper,
    Typography,
    Toolbar,
    IconButton,
    Button,
    Grid,
    Switch,
    Select,
    MenuItem,
    Tooltip,
} from '@mui/material';
import customStyles from './EditDashcam.module.scss';
import { getDeviceSetting, updateDashcamSetting } from './../../../containers/DashCam/helper-classes/dashcamServices';
import Dialog from './../../../components/SharedComponents/Dialog/Dialog';

export type Props = {
    imei: string,
    dashcamName: string,
    showNotification: Function,
    showDashcam: Function,
    showLoader: Function,
};

export type State = {
  setting: Object,
  updateDialog: boolean,
};

// Dashcam Setting
class DashcamSetting extends Component<Props, State> {
    constructor(props: Object) {
        super(props);
        this.state = {
            setting: this.initSettingState({}),
            updateDialog: false,
        };
    }

    componentDidMount() {
        this.getDeviceSetting(this.props.imei);
    }

    initSettingState = (settings: Object) => ({
        voiceRecording: settings.voiceRecording ? settings.voiceRecording : { value: false },
        distractedDriver: settings.distractedDriver ?
            settings.distractedDriver : { value: false },
        audioAlarms: settings.audioAlarms ? settings.audioAlarms : { value: false },
        inCabinCameraRecording: settings.inCabinCameraRecording ?
            settings.inCabinCameraRecording : { value: false },
        textOverlay: settings.textOverlay ? settings.textOverlay : { value: false },
        driverPosition: settings.driverPosition ? settings.driverPosition : { value: '' },
        speedUnits: settings.speedUnits ? settings.speedUnits : { value: 'mph' },
        notifyLiveStreaming: settings.notifyLiveStreaming ?
            settings.notifyLiveStreaming : { value: false },
        adminPin: settings.adminPin ? settings.adminPin : { value: '' },
        driverPin: settings.driverPin ? settings.driverPin : { value: '' },
        brightness: settings.brightness ? settings.brightness : { value: 1 },
        dateTimeUnits: settings.dateTimeUnits ? settings.dateTimeUnits : { value: 'us' },
        liveVideo: settings.liveVideo ? settings.liveVideo : { value: false },
        standby: settings.standby ? { value: settings.standby.timeout } : { value: 30 },
        hotSpot: (settings.hotSpot && settings.hotSpot.internetAccess) ?
            { value: settings.hotSpot.internetAccess } : { value: false },
    })

    getDeviceSetting = (imei: string) => {
        this.props.showLoader(true);

        getDeviceSetting(imei).then((response: any) => {
            this.props.showLoader(false);

            if (response.apiStatus === 200 && response.data) {
                const setting = this.initSettingState(response.data);
                this.setState({ setting });
            } else this.props.showNotification(response.message);
        });
    }

    showUpdateDialog = (updateDialog: boolean) => this.setState({ updateDialog });

    updateDashcamSetting = () => {
        this.showUpdateDialog(false);
        this.props.showLoader(true);
        const settings = { ...this.state.setting };
        settings.standby = { timeout: settings.standby.value };
        settings.hotSpot = { internetAccess: settings.hotSpot.value };

        updateDashcamSetting(this.props.imei, settings).then((response: any) => {
            this.props.showLoader(false);

            if (response.status === 200) {
                this.props.showDashcam();
                this.props.showNotification('Device settings updated successfully');
            } else this.props.showNotification(response.message);
        });
    }

    getKeyBasedText = (key: string) => {
        switch (key) {
        case 'distractedDriver': return 'Enable Distracted Driver';
        case 'audioAlarms': return 'Enable Audio Alerts';
        case 'inCabinCameraRecording': return 'In Cabin Recordings';
        case 'textOverlay': return 'Video Text Overlay';
        case 'speedUnits': return 'Speed units in Text Overlay';
        case 'dateTimeUnits': return 'Time Units in Text Overlay';
        case 'liveVideo': return 'Enable Live Video Streaming';
        case 'standby': return 'Idle minutes to enter standby';
        case 'voiceRecording': return 'Record Audio In Front and Cabin';
        default: return '';
        }
    }

    handleChange = (value: boolean, key: string) => {
        const { setting } = this.state;
        setting[key].value = value;
        this.setState({ setting });
    }

    getFields = (key: string) => {
        switch (key) {
        case 'dateTimeUnits': return (
            <Select
                className={customStyles.select}
                value={this.state.setting[key].value || ''}
                onChange={e => this.handleChange(e.target.value, key)}
            >
                <MenuItem value="us">12HR</MenuItem>
                <MenuItem value="eu">24HR</MenuItem>
            </Select>);
        case 'speedUnits': return (
            <Select
                className={customStyles.select}
                value={this.state.setting[key].value || ''}
                onChange={e => this.handleChange(e.target.value, key)}
            >
                <MenuItem value="mph">Mp/H</MenuItem>
                <MenuItem value="kmh">Km/H</MenuItem>
            </Select>);
        case 'standby': return (
            <Select
                className={customStyles.select}
                value={this.state.setting[key].value || ''}
                onChange={e => this.handleChange(e.target.value, key)}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
            >
                {[10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]
                    .map(t => <MenuItem value={t} key={t}>{t}</MenuItem>)}
            </Select>);
        default: return (
            <Switch
                classes={{ root: customStyles.switchRoot, switchBase: customStyles.switchBase }}
                checked={(this.state.setting[key].value)}
                onChange={e => this.handleChange(e.target.checked, key)}
                value={(this.state.setting[key].value) || false}
                color="primary"
            />
        );
        }
    }

    getViewContent = (k: string) => {
        const key = this.getKeyBasedText(k);

        return key && (
            <Grid container key={k} style={{ height: 44 }}>
                <Grid item xs={4} className={customStyles.gridItem}>{key}</Grid>
                <Grid item xs={8}>{this.getFields(k)}</Grid>
            </Grid>);
    }

    render() {
        return (
            <div>
                <Paper className={customStyles.paper}>
                    <Toolbar className={customStyles.toolbar}>
                        <span className={customStyles.backButton}>
                            <IconButton onClick={() => this.props.showDashcam()} size="large">
                                <ArrowBack />
                            </IconButton>
                        </span>
                        <div className={customStyles.editTitle}>
                            <Typography variant="h6" color="inherit" className={customStyles.titleWrap}>
                                <span>Settings</span>
                                <Tooltip title={this.props.dashcamName || ''} placement="bottom-start">
                                    <div className={customStyles.subHeader}>
                                        {this.props.dashcamName}
                                    </div>
                                </Tooltip>
                            </Typography>
                        </div>
                    </Toolbar>
                    <div className={customStyles.textWrap}>
                        {Object.keys(this.state.setting).map(d => this.getViewContent(d))}
                        <Grid container className={customStyles.gridContainer}>
                            <Grid
                                item
                                xs={4}
                                className={`${customStyles.gridItem} ${customStyles.center}`}
                            >
                                <Button
                                    className={`${customStyles.button} ${customStyles.cancelButton} ${customStyles.settingCancelButton}`}
                                    onClick={() => this.props.showDashcam()}
                                >
                                    Back
                                </Button>
                            </Grid>
                            <Grid item xs={8} className={customStyles.gridItem}>
                                <Button
                                    className={`${customStyles.button} ${customStyles.settingCancelButton}`}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.showUpdateDialog(true)}
                                >
                                    SAVE
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
                <Dialog
                    open={this.state.updateDialog}
                    type="confirm"
                    customTitle=""
                    button={[
                        {
                            title: 'Cancel',
                            color: 'primary',
                            handleClick: () => this.showUpdateDialog(false),
                        },
                        {
                            title: 'OK',
                            color: 'primary',
                            variant: 'contained',
                            handleClick: () => this.updateDashcamSetting(),
                        },
                    ]}
                    content="Any camera's local changes will be overwritten with this configuration"
                    size="lg"
                />
            </div>
        );
    }
}

export default DashcamSetting;
