/* @flow */
/* eslint radix: ["error","as-needed"] */

import { combineEpics } from 'redux-observable';
import { FETCH_USER_ACCESS_LIST } from './constants.userAccessList';
import { fetchUserAccessListError, fetchUserAccessListSuccess } from './actions.userAccessList';
import { getGrailsAppBasedURL, getAuthorizationHeader } from '../../helper-classes/utility-functions';
import config from '../../constants/Config';

type ObservarblesTypes = {
    getJSON: Function,
    of: Function
}

const formatData = (data: any) => {
    if (!data && data.length <= 0) return [];
    return data.map((d) => {
        const userAccess = Object.assign({}, d);
        userAccess.readOnly = d.readOnly ? 'System' : 'Account';
        return userAccess;
    });
};

const formatSortingString = (orderBy: string, order: string) => {
    const sorts = order && orderBy ? escape(`[{"property":"${orderBy}","direction":"${order}"}]`) : escape('[{"property":"name","direction":"ASC"}]');
    return sorts;
};

const formatFilterString = (filter: Object) => {
    let searchString = {};
    if (filter && filter.search && filter.search.length > 0 && typeof filter.search === 'string') {
        searchString = [filter.search.replace(/[\s]{2,}/gi, ' ').trim()];
    }
    const filterParam = searchString.length > 0 ? escape(`[{"value":"${searchString[0]}","property":"name","comparison":"ilike"},{"value":"${searchString[0]}","property":"description","comparison":"ilike"}]`) : null;
    return filterParam;
};

export const getUserAccess =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_USER_ACCESS_LIST)
            .distinctUntilChanged()
            .debounceTime(config.get('DEBOUNCE_TIME'))
            .mergeMap((action) => {
                const {
                    pageNumber,
                    pageSize,
                    order,
                    orderBy,
                    filter,
                } = action.payload;
                const offset = (parseInt(pageNumber) * parseInt(pageSize));
                const headers = {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: getAuthorizationHeader(),
                };
                // Sorting Parameter creation
                const sorts = formatSortingString(orderBy, order);
                // Search Parameter creation
                const filterParam = formatFilterString(filter);
                let reqURL = `page=${pageNumber}&offset=${offset}&max=${pageSize}&sorts=${sorts}`;
                if (filterParam !== null) {
                    reqURL = `${reqURL}&filter=${filterParam}&filterOperator=or`;
                }
                return getJSON(`${getGrailsAppBasedURL()}/rest/json/universe?${reqURL}`, headers)
                    .map((result) => {
                        const formatedData = formatData(result.data);
                        return fetchUserAccessListSuccess(formatedData, result.total);
                    })
                    .catch(error => of(fetchUserAccessListError(error)));
            });

export default combineEpics(getUserAccess);
