/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Card, CardContent, Button, Tooltip, TextField, Collapse, Paper, Grid, IconButton, Chip } from '@mui/material';
import customStyles from './kml.module.scss';
import GetSvgIcon from '../../util/svgImage_util';
import { isEmpty } from './../../helper-classes/utility-functions';

export type Props = {
    isShowAdd: Function,
    totalCount: number,
    count: number,
    addKml: Function,
    classes: Object,
    clearFormData: Function,
    showBackButton: boolean,
    handleKmlBackBtnClick: Function,
};

type State = {
    showAddKml: boolean,
    name: string,
    pageError: boolean,
    errorMsg: string,
    file: any,
    inputKey: string,
}

const themeStyles = theme => ({
    button: {
        margin: 12,
        width: 197,
        marginLeft: 0,
        borderBottom: '1px solid #ffffff',
        color: '#ffffff',
        borderRadius: 'inherit',
        padding: 1,
    },
    input: {
        color: '#ffffff',
    },
    kmlFileSelect: {
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: '100%',
        opacity: 0,
    },
    errorChip: {
        backgroundColor: theme.palette.error.dark,
        margin: '-8px 0 8px',
        display: 'flex',
    },
    fileName: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%',
        textAlign: 'left',
        textTransform: 'none',
    },
});

class KmlDetailsHeader extends Component<Props, State> {
    currentPage: number;
    clearFormData: any;
    constructor(props) {
        super(props);
        this.currentPage = 0;
        this.state = {
            showAddKml: false,
            name: '',
            pageError: false,
            errorMsg: '',
            file: {},
            inputKey: this.generateInputKey(),
        };
        this.clearFormData = this.clearFormData.bind(this);
    }

    componentDidMount() {
        const { clearFormData } = this.props;
        if (clearFormData && typeof clearFormData === 'function') {
            clearFormData(this.clearFormData);
        }
    }

    generateInputKey = () => Math.random().toString(36)

    clearFormData = () => {
        this.setState({
            name: '',
            pageError: false,
            file: {},
            inputKey: this.generateInputKey(),
        });
    }

    showHideAddKml = (showAddKml) => {
        this.setState({
            showAddKml,
            name: '',
            pageError: false,
            file: {},
            inputKey: this.generateInputKey(),
        });
        this.props.isShowAdd(showAddKml, 128);
    }

    handleFileSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileSize = file.size / 1024 / 1024;
            const regex = /^(.+)\.kml$/g;
            if (!file.name.match(regex)) {
                this.setState({ pageError: true, errorMsg: 'The uploaded file must end in ".kml"' });
            } else if (fileSize > 10) {
                this.setState({ pageError: true, errorMsg: 'Max file size limit 10MB' });
                this.props.isShowAdd(this.state.showAddKml, 159);
            } else {
                this.setState({
                    pageError: false,
                    file,
                    errorMsg: '',
                    inputKey: this.generateInputKey(),
                });
                this.props.isShowAdd(this.state.showAddKml, 128);
            }
        }
    }

    upload = () => {
        const value = this.state.name ? this.state.name.trim() : '';
        if (!value) {
            this.setState({ pageError: true, errorMsg: 'KML name is required' });
        } else if (isEmpty(this.state.file)) {
            this.setState({ pageError: true, errorMsg: 'KML file is required' });
        } else {
            this.setState({ pageError: false, errorMsg: '' });
            const formData = new FormData();
            formData.append('file', this.state.file);
            formData.append('name', value);
            this.props.addKml(formData);
        }
    }

    getHeader = () => (
        <Card raised={false} id="header-card" className={customStyles.card}>
            <CardContent className={customStyles['card-content']}>
                <Grid container>
                    <Grid item xs={10}>
                        <div>
                            {this.props.showBackButton &&
                            <button
                                className={customStyles.back_button}
                                onClick={() => this.props.handleKmlBackBtnClick()}
                            >
                                <i className={customStyles.back_icon} />
                            </button>
                            }
                            <div className={customStyles['kml-text']}>KML Overlays</div>
                            <div className={customStyles['kml-count']}>Showing {this.props.count} of {this.props.totalCount}</div>
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton
                            id="action-edit-kml"
                            onClick={() => this.showHideAddKml(true)}
                            style={{
                                margin: '-8px 0px 0px 25px',
                            }}
                            size="large"
                        >
                            <Tooltip title="Add KML" disableFocusListener>
                                <span>
                                    <GetSvgIcon type="add-kml" viewBox="-2 -1 24 24" />
                                </span>
                            </Tooltip>
                        </IconButton>
                    </Grid>
                </Grid>
            </CardContent>
            <Collapse in={this.state.showAddKml}>
                <Paper style={{ backgroundColor: 'transparent' }}>
                    <CardContent className={customStyles['add-kml']}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ borderTop: '1px solid rgba(255, 255, 255, 0.15)' }}
                        >
                            <Grid
                                item
                                xs={10}
                                style={{
                                    paddingBottom: 5,
                                }}
                            >
                                <TextField
                                    label="Name"
                                    required
                                    value={this.state.name}
                                    onChange={e => this.setState({ name: e.target.value })}
                                    margin="none"
                                    inputProps={{ className: this.props.classes.input }}
                                    InputLabelProps={{
                                        className: this.props.classes.input,
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={2}
                                style={{ paddingLeft: 20 }}
                            >
                                <IconButton onClick={() => this.showHideAddKml(false)} size="large">
                                    <Tooltip title="Close" disableFocusListener>
                                        <GetSvgIcon type="close" viewBox="-5 0 24 15" />
                                    </Tooltip>
                                </IconButton>
                            </Grid>
                            <Grid item xs={9}>
                                <Button
                                    label="Choose a KML File"
                                    className={this.props.classes.button}
                                >
                                    <span className={this.props.classes.fileName}>
                                        {this.state.file.name ? `${this.state.file.name}` : 'Select File *'}
                                    </span>
                                    <input
                                        type="file"
                                        className={this.props.classes.kmlFileSelect}
                                        accept=".kml"
                                        key={this.state.inputKey}
                                        title={this.state.file.name || ''}
                                        onChange={e => this.handleFileSelect(e)}
                                    />
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                style={{ padding: '16px 0 20px 0' }}
                            >
                                <Button
                                    id="action-edit-assets"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.upload()}
                                >
                                        UPLOAD
                                </Button>
                            </Grid>
                            {this.state.pageError &&
                                <Grid item xs={12}>
                                    <Chip
                                        className={this.props.classes.errorChip}
                                        key="Over Size"
                                        label={this.state.errorMsg}
                                        color="primary"
                                    />
                                </Grid>
                            }
                        </Grid>
                    </CardContent>
                </Paper>
            </Collapse>
        </Card>
    )

    render() {
        return (
            <React.Fragment>
                {this.getHeader()}
            </React.Fragment>
        );
    }
}

export default withStyles(themeStyles, { withTheme: true })(KmlDetailsHeader);
