/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Snackbar, IconButton, Card, CardHeader, Avatar, Typography, ClickAwayListener, Tooltip } from '@mui/material';
import { Close } from '@mui/icons-material';
import * as notificationActions from '../../../components/SharedComponents/NotificationHandler/actions.appNotifications';
import * as c from './constants.appNotifications';
import { reduxConnect } from '../../../hoc';
import GetSvgIcon from '../../../util/svgImage_util';
import analytics from '../../../analytics/index';
import { setLocalStorageItem, getLocalStorageValue, removeSessionStorageItem } from '../../../helper-classes/utility-functions';

export type Props = {
    classes: Object,
    AppNotificationHandler: Object,
    appNotificationHandler: Function,
    removeCardNotification: Function,
    clickAwayNotification: Function,
};

export type State = {
    reRenderHandler: boolean,
};

const statusIconColorMap = {
    inprogress: '#BABABA',
    info: '#44A2B9',
    success: '#007AFF',
    error: '#B3282D',
};

const themeStyles = () => ({
    notification_card: {
        borderRadius: 0,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#d8d8d8',
        },
    },
    card_close_btn: {
        marginRight: 10,
        marginTop: '-5px',
        padding: 5,
    },
    card_notification_container: {
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
        maxHeight: 345,
        overflowY: 'overlay',
        position: 'absolute',
        right: 0,
        width: '400px',
        zIndex: 2,
    },
    card_notification_status_icon: { marginLeft: 5 },
    card_title: { fontSize: 16, textTransform: 'capitalize', width: 260 },
    card_subtitle: { fontSize: 14, minHeight: 9, width: 260 },
});

class AppNotifications extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { reRenderHandler: false };
    }

    componentDidMount() {
        window.onbeforeunload = () => {
            removeSessionStorageItem(c.IMPORT_JOB_SUBSCRIBER_SS_NAME);
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        nextProps.AppNotificationHandler.map((notificationObj) => {
            if (['snackbar'].indexOf(notificationObj.type) > -1) {
                const { type, nConfig } = notificationObj;
                if (type === 'snackbar' && nConfig
                    && typeof (nConfig.onDataChange) === 'function') {
                    nConfig.onDataChange(notificationObj);
                }
            }
            return true;
        });
    }

    snackbarCloseHandler = (e, reason, key: string = '') => {
        const snackbarObj = this.props.AppNotificationHandler.find(i => i && (i.type === 'snackbar')
            && i.overrideConfig && (i.overrideConfig.key === key));
        if (snackbarObj && Object.keys(snackbarObj).length > 0 && reason !== 'clickaway') {
            const { nConfig } = snackbarObj;
            if (nConfig && typeof (nConfig.onCloseNotification) === 'function') {
                nConfig.onCloseNotification(e, reason, snackbarObj);
            } else {
                this.props.appNotificationHandler([{
                    type: 'snackbar',
                    isOpen: false,
                    extraData: {},
                    overrideConfig: {
                        key: snackbarObj.overrideConfig.key,
                        autoHideDuration: 2000,
                        action: undefined,
                    },
                }]);
            }
            this.setState({ reRenderHandler: !this.state.reRenderHandler });
        }
    }

    handleCardNotificationClose = (
        event: any,
        notificationFor: string, nCardIDToRemove: string,
        titleToRemove: string, statusToRemove: string,
        statusDateToRemove: string,
    ) => {
        analytics.track('APP_NOTIFICATION_PANEL', {
            NotificationFor: notificationFor, NotificationID: nCardIDToRemove, NotificationTitle: titleToRemove, NotificationStatus: statusToRemove, ClickedItemType: 'Close Notification',
        });
        this.props.removeCardNotification({
            nCardIDToRemove,
            titleToRemove,
            statusToRemove,
            statusDateToRemove,
        });
        const closedNotificationAry = JSON.parse(getLocalStorageValue('CCNDAry') || '[]');
        closedNotificationAry.push({
            id: nCardIDToRemove,
            title: titleToRemove,
            status: statusToRemove,
            statusDate: statusDateToRemove,
        });
        setLocalStorageItem('CCNDAry', JSON.stringify(closedNotificationAry));
        this.setState({ reRenderHandler: !this.state.reRenderHandler });
        event.preventDefault();
        event.stopPropagation();
    }

    getCardContent = (nObj: Object) => {
        const { classes } = this.props;
        const iconClr = statusIconColorMap[nObj.statusType] || '#007AFF';

        return (
            <React.Fragment>
                <CardHeader
                    onClick={() => nObj.clickable && nObj.onClick && nObj.onClick(nObj.id)}
                    avatar={
                        <Avatar style={{ backgroundColor: [iconClr] }}>
                            <GetSvgIcon type={nObj.icon || 'list'} fillcolor={(nObj.statusType === 'inprogress') ? '#4A4A4A' : '#fff'} />
                        </Avatar>
                    }
                    action={
                        (!nObj.noData) &&
                        <React.Fragment>
                            <Tooltip title="Close" disableFocusListener placement="bottom">
                                <IconButton
                                    aria-label="close"
                                    className={classes.card_close_btn}
                                    onClick={event =>
                                        this.handleCardNotificationClose(
                                            event,
                                            nObj.for,
                                            nObj.id,
                                            nObj.title,
                                            nObj.status,
                                            nObj.statusDate,
                                        )
                                    }
                                    size="large"
                                >
                                    <Close />
                                </IconButton>
                            </Tooltip>
                            <div className={classes.card_notification_status_icon}>
                                <GetSvgIcon type={nObj.statusType || 'info'} fillcolor={iconClr} />
                            </div>
                        </React.Fragment>
                    }
                    title={
                        <Typography noWrap className={classes.card_title} style={(nObj.noData) && { paddingTop: 9 }} component="h5" variant="h5">
                            {nObj.title}
                        </Typography>
                    }
                    subheader={
                        <Typography
                            noWrap
                            className={classes.card_subtitle}
                            variant="body1"
                            color="textSecondary"
                        >
                            <span style={(nObj.statusType === 'error') ? { color: [iconClr] } : undefined}>{nObj.status || ''} </span>
                            <span>{nObj.statusDate || ''}</span>
                        </Typography>
                    }
                />
            </React.Fragment>
        );
    }

    getNotificationCards = (notifications: Array<Object>) => {
        const { classes } = this.props;
        if (notifications.length > 0) {
            return notifications.map((nObj) => {
                const cardID = nObj.title + nObj.id + nObj.status + Math.random();
                return (
                    <Card
                        className={classes.notification_card}
                        key={cardID}
                        id={cardID}
                        data-id="notification-card"
                    >
                        { this.getCardContent(nObj) }
                    </Card>
                );
            });
        }

        return (
            <Card
                className={classes.notification_card}
                key="no-app-notifications"
                id="no-app-notifications"
                data-id="notification-card"
            >
                {this.getCardContent({ title: 'No new notification', statusType: 'info', noData: true })}
            </Card>
        );
    }

    render() {
        const { classes } = this.props;
        return this.props.AppNotificationHandler.map((notification) => {
            const {
                type, isOpen, message, nConfig, overrideConfig = {}, extraData = {},
            } = notification;

            return (
                <React.Fragment key={type + overrideConfig.key + extraData.commandSent} >
                    {(type === 'snackbar' && nConfig && Object.keys(nConfig).length > 0) &&
                        <Snackbar
                            anchorOrigin={{
                                vertical: nConfig.vertical || 'bottom',
                                horizontal: nConfig.horizontal || 'center',
                            }}
                            autoHideDuration={3000}
                            open={isOpen}
                            onClose={(e, reason) =>
                                this.snackbarCloseHandler(e, reason, overrideConfig.key)}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                                style: nConfig.style || { backgroundColor: '#fff', color: '#000' },
                            }}
                            message={message}
                            action={[
                                <IconButton
                                    key={type + overrideConfig.key + extraData.commandSent}
                                    aria-label="Close"
                                    color="inherit"
                                    onClick={this.snackbarCloseHandler}
                                    size="large"
                                >
                                    <Close />
                                </IconButton>,
                            ]}
                            {...overrideConfig}
                        />
                    }
                    {(type === 'nCard') && isOpen &&
                    <ClickAwayListener onClickAway={this.props.clickAwayNotification}>
                        <div className={classes.card_notification_container}>
                            {this.getNotificationCards(message)}
                        </div>
                    </ClickAwayListener>
                    }
                </React.Fragment>
            );
        });
    }
}

const mapStateToProps = state => state.appNotifications;

const mapDispatchToProps = {
    ...notificationActions,
};

export default
withStyles(themeStyles)(reduxConnect(AppNotifications, mapDispatchToProps, mapStateToProps));
