/* @flow */
import {
    FETCH_ADDED_LOOKS,
    FETCH_ADDED_LOOKS_SUCCESS,
    FETCH_ADDED_LOOKS_ERROR,
    FETCH_ADDED_LOOKS_LIST,
    DELETE_ADDED_LOOKS,
    DELETE_ADDED_LOOKS_SUCCESS,
    DELETE_ADDED_LOOKS_FAILURE,
} from './constants.configureDashboard';

const initialState = {
    addedLooks: [],
    isUpdating: false,
    isLookDeleted: false,
};

type stateType = {
    addedLooks: Array<Object>,
    isUpdating: boolean,
};
type actionType = {
    type: string,
    payload: {
        addedLooks: {},
        totalRecords: number,
    },
};

export default (state: stateType = initialState, action: actionType) => {
    switch (action.type) {
    case FETCH_ADDED_LOOKS:
        return {
            ...state,
            isUpdating: true,
            isLookDeleted: false,
        };
    case FETCH_ADDED_LOOKS_LIST:
        return {
            ...state,
            isUpdating: false,
            addedLooks: [],
            totalRecords: 0,
        };
    case FETCH_ADDED_LOOKS_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            addedLooks: action.payload.addedLooks,
            totalRecords: action.payload.totalRecords,
        };
    case FETCH_ADDED_LOOKS_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    case DELETE_ADDED_LOOKS:
        return {
            ...state,
            isLoading: true,
            isLookDeleted: false,
        };
    case DELETE_ADDED_LOOKS_SUCCESS:
        return {
            ...state,
            isLoading: false,
            isLookDeleted: true,
            response: action.payload,
        };
    case DELETE_ADDED_LOOKS_FAILURE:
        return {
            ...state,
            isLoading: false,
            response: action.payload,
        };
    default:
        return state;
    }
};
