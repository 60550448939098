/* @flow */
import * as constants from './constants.scoreCardTrips';

type stateType = {
    driverScorecardTrips: {
        content: Array<Object>,
        count: number,
        total: number,
    },
    responseType: string,
    responseStatus: boolean,
    responseData: Object,
    loading: boolean,
}

const initialState = {
    driverScorecardTrips: {
        content: [],
        count: 0,
        total: 0,
    },
    responseType: '',
    responseStatus: false,
    responseData: {},
    loading: true,
};

type actionType = {
    type: string,
    payload :{
        loading: boolean,
        response: Object,
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case constants.FETCH_DRIVER_SCORECARD_TRIPS:
        return {
            ...state,
            loading: true,
        };
    case constants.FETCH_DRIVER_SCORECARD_TRIPS_SUCCESS:
        return {
            ...state,
            driverScorecardTrips: action.payload.response,
            responseType: 'get',
            responseStatus: true,
            loading: false,
        };
    case constants.FETCH_DRIVER_SCORECARD_TRIPS_ERROR:
        return {
            ...state,
            driverScorecardTrips: initialState.driverScorecardTrips,
            responseType: 'get',
            responseStatus: false,
            responseData: action.payload,
            loading: false,
        };
    case constants.UPDATE_DRIVER_SCORECARD_LOADER:
        return {
            ...state,
            loading: action.payload.loading,
        };
    case constants.RESET_DRIVER_SCORECARD_TRIPS:
        return {
            ...state,
            loading: false,
            driverScorecardTrips: initialState.driverScorecardTrips,
            totalScorecards: 0,
        };
    default:
        return state;
    }
};
