/* @flow */
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Button, SvgIcon, Typography, Grid } from '@mui/material';
import { getPermissionValue } from '../../helper-classes/utility-functions';

type Props = {
    classes : Object,
    redirectTo : Function,
    nearestAsset: Function,
    redirectLink : string,
    landmarkInfo: {
        isPresent: boolean,
        landmarkId: string,
    }
}

const styles = theme => ({
    button: {
        margin: theme.spacing(),
        padding: '8px 42px',
        flexDirection: 'column',
    },
    label: {
        flexDirection: 'column',
    },
    buttonLabel: {
        fontSize: '14px',
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.4px',
        marginTop: '5px',
        textAlign: 'center',
        color: '#ffffff',
        textTransform: 'none',
        whiteSpace: 'initial',
    },
    icon: {
        width: '24px',
        height: '17px',
        objectFit: 'contain',
    },
    extendedIcon: {
        marginRight: theme.spacing(),
    },
    root: {
        backgroundColor: '#343e48',
        minHeight: '90px',
    },
});

function DetailActions(props: Props) {
    const { classes, redirectTo, nearestAsset } = props;
    return (
        <Grid id="actions-container" container className={classes.root} spacing={0}>
            {getPermissionValue('Assets') !== 'None' &&
            <Grid item xs={6}>
                <Button
                    onClick={() => nearestAsset()}
                    id="btn-nearest-asset"
                    classes={{ root: classes.button, label: classes.label }}
                >
                    <SvgIcon>
                        <path
                            fill="#ffffff"
                            d="M3,13.5L2.25,12H7.5L6.9,10.5H2L1.25,9H9.05L8.45,7.5H1.11L0.25,6H4A2,2 0 0,1 6,4H18V8H21L24,12V17H22A3,3 0 0,1 19,20A3,3 0 0,1 16,17H12A3,3 0 0,1 9,20A3,3 0 0,1 6,17H4V13.5H3M19,18.5A1.5,1.5 0 0,0 20.5,17A1.5,1.5 0 0,0 19,15.5A1.5,1.5 0 0,0 17.5,17A1.5,1.5 0 0,0 19,18.5M20.5,9.5H18V12H22.46L20.5,9.5M9,18.5A1.5,1.5 0 0,0 10.5,17A1.5,1.5 0 0,0 9,15.5A1.5,1.5 0 0,0 7.5,17A1.5,1.5 0 0,0 9,18.5Z"
                        />
                    </SvgIcon>
                    <Typography className={classes.buttonLabel}>
                        Nearest
                    </Typography>
                    <Typography className={classes.buttonLabel}>
                        Assets
                    </Typography>
                </Button>
            </Grid>
            }
            {getPermissionValue('Landmarks') === 'Modify' &&
            <Grid item xs={6}>
                <Button
                    onClick={() => redirectTo(props.redirectLink)}
                    id="btn-add-landmark"
                    classes={{ root: classes.button, label: classes.label }}
                >
                    {(props.landmarkInfo.isPresent) ?
                        <SvgIcon>
                            <path
                                fill="#ffffff"
                                d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                            />
                        </SvgIcon>
                        :
                        <SvgIcon>
                            <path
                                fill="#ffffff"
                                d="M12 0c3.96 0 7.2 3.24 7.2 7.2 0 6.122-7.2 13.312-7.2 13.312S4.8 13.44 4.8 7.2C4.8 3.24 8.04 0 12 0zm-.072 3a3.928 3.928 0 1 0 0 7.857 3.928 3.928 0 0 0 0-7.857zm2.096 4.5H12.5v1.524h-1.143V7.5H9.833V6.357h1.524V4.833H12.5v1.524h1.524V7.5zm-8.287 8.25c-1.514.314-2.784.698-3.787 1.238-.502.267-.952.571-1.312.975-.361.403-.638.989-.638 1.612 0 .984.638 1.702 1.35 2.212.712.511 1.608.882 2.663 1.2C6.127 23.62 8.906 24 12 24c3.094 0 5.873-.38 7.987-1.012 1.055-.32 1.95-.69 2.663-1.2.712-.511 1.35-1.229 1.35-2.213 0-1.252-.933-2.063-1.95-2.625-1.017-.563-2.32-.942-3.863-1.2l-.375 2.363c1.341.225 2.41.567 3.076.937.445.244.609.45.674.525-.037.042-.112.127-.3.263-.374.267-1.054.595-1.95.862-1.785.534-4.406.9-7.312.9-2.906 0-5.527-.366-7.313-.9-.895-.267-1.575-.595-1.95-.862a1.659 1.659 0 0 1-.3-.263c.09-.098.3-.267.638-.45.675-.36 1.819-.727 3.188-1.012l-.526-2.363z"
                            />
                        </SvgIcon>
                    }
                    <Typography className={classes.buttonLabel}>
                        {(props.landmarkInfo.isPresent) ? 'Edit Landmark' : 'Add Landmark'}
                    </Typography>
                </Button>
            </Grid>
            }
        </Grid>
    );
}

export default withStyles(styles)(DetailActions);
