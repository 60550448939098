/* @flow */
import React, { Component } from 'react';
import { reduxConnect } from '../../../hoc';
import * as actions from './actions.landmarkGroup';
import MapsWrapper from '../../MapsWrapper';
import MiniDrawer from '../../../components/SideMenu/SideMenuDrawer';
import styles from './LandmarkGroup.module.scss';
import LandmarkGroupDetails from '../../../components/LandmarkGroupDetails/LandmarkGroupDetails';
import { getParamValue } from '../../../helper-classes/utility-functions';
import { filterData, PAGE_SIZE } from './constants.landmarkGroup';
import { mapColor } from '../../constants.map';
import { getShapeData } from '../LandmarkDetails/epics.landmarks';
import RightDrawer from '../../../components/SharedComponents/RightDrawer/RightDrawer';

export type Props = {
    searchLandmarkGroup: Function,
    isLoader: boolean,
    isUpdating: boolean,
    updateLoader: Function,
    history: {
        push: Function,
    },
    landmarkGroup: {
        data: Array < {
            id: string,
            name: string,
            accountId: string,
            landmarkGroupId: string,
            landmarkGroupName: string,
            totalAssets: number,
            address: string,
            city: string,
            state: string,
            shape: string
        } >,
        count: string,
        total: string,
    },
};

export type State = {
    markerArray: Array < {
        id: string,
        lat: string,
        lng: string,
    } >,
    filters: Array < {
        key: string,
        value: Array < {
            type: string,
            rangeData: Object,
            name: string,
            label: string,
        } >
    } >,
    showRefreshButton: boolean,
    resetRefreshTimer: boolean,
    searchedString: string,
    selectedFilters: any,
    zoom: number,
};

class LandmarkGroup extends Component<Props, State> {
    currentPage: number;
    id: string;
    clearListData: Function;
    map: Object;

    constructor(props) {
        super(props);
        this.state = {
            markerArray: [],
            filters: filterData,
            showRefreshButton: false,
            resetRefreshTimer: false,
            searchedString: '',
            selectedFilters: [],
            zoom: 3,
        };
        this.id = getParamValue(this.props, 'id');
        this.currentPage = 0;
        this.map = {};
    }

    componentDidMount() {
        this.refreshPage();
    }

    refreshPage = () => {
        this.props.updateLoader(true);
        const formattedFilters =
            this.getFormattedFilters(
                this.state.searchedString,
                this.currentPage,
                this.state.selectedFilters,
            );
        this.props.searchLandmarkGroup(this.id, '', this.currentPage * PAGE_SIZE, formattedFilters);
    }

    getFormattedFilters = (search, page, filters) => ({
        searchParams: search ? [this.id, search] : [this.id],
        type: search ? 'groupIdAndName' : 'groupId',
        start: page * PAGE_SIZE,
        limit: PAGE_SIZE,
        filterParam: filters,
    });

    pageChanged = (page, isSearch, search, isPageReset, filters) => {
        this.currentPage = isPageReset ? 0 : page;
        const formattedFilters = this.getFormattedFilters(search, page, filters);
        this.setState({ selectedFilters: filters, searchedString: search || '' });
        this.props.searchLandmarkGroup(this.id, (isSearch ? search : ''), ((this.currentPage >= 0) ? page * PAGE_SIZE : 0), formattedFilters);
    };

    setMarkerArray = (marker) => {
        if (marker !== undefined && Array.isArray(marker)) {
            let filteredMarker = marker.filter(d => d.marker);
            if (filteredMarker.length === 0) {
                this.setState({ markerArray: [] });
            } else if (filteredMarker && filteredMarker.length > 0) {
                filteredMarker = filteredMarker.map((d) => {
                    const newData = d;
                    newData.latitude = d.marker.y || 0;
                    newData.longitude = d.marker.x || 0;
                    newData.lat = d.marker.y || 0;
                    newData.lng = d.marker.x || 0;
                    newData.redirectTo = this.props.history.push;
                    newData.shapeData = getShapeData(d);
                    let getColorNumber =
                        Math.floor(Math.random() * (mapColor.landmark.group.length - 1));
                    if (getColorNumber > (mapColor.landmark.group.length - 1)) {
                        getColorNumber = mapColor.landmark.group.length - 1;
                    }
                    if (!newData.getColorNumber) {
                        newData.getColorNumber = getColorNumber;
                    }
                    return newData;
                });
                this.setState({ markerArray: filteredMarker });
            }
        }
    };

    mapRefreshDataCallback = () => this.setState({ showRefreshButton: true });

    refreshData = (needToRefresh) => {
        if (needToRefresh) {
            this.currentPage = 0;
            this.refreshPage();
            if (this.clearListData && typeof this.clearListData === 'function') {
                this.clearListData();
            }
        }
        this.setState({ showRefreshButton: false, resetRefreshTimer: true });
        setTimeout(() => {
            this.setState({ resetRefreshTimer: false });
        }, 500);
    }

    getBoundary = (map) => {
        this.map = map;
    }

    // callback from map page when any bound change, tiles will be loaded on the map.
    boundaryChanged = () => {
        if (this.map && Object.keys(this.map).length > 0) {
            this.setState({ zoom: this.map.getZoom() });
        }
    }

    render() {
        const {
            filters, markerArray, showRefreshButton, resetRefreshTimer,
        } = this.state;

        const {
            isLoader, landmarkGroup, updateLoader, history, isUpdating,
        } = this.props;

        const shape = {
            type: 'markerGroup',
            id: 'landmark',
            key: 'landmark',
            data: markerArray.length > 0 ? markerArray : [],
        };

        const options = {};
        options.zoom = this.state.zoom;

        return (
            <MiniDrawer redirectTo={this.props.history.push}>
                <div key="landmark-group-map-container" className={styles.mapWrapper}>
                    <MapsWrapper
                        key="landmark-group-map"
                        refreshData={this.mapRefreshDataCallback}
                        resetRefreshTimer={resetRefreshTimer}
                        markers={{ shape }}
                        moduleName="landmark-group"
                        getBoundaries={(boundary) => { this.getBoundary(boundary); }}
                        boundaryChanged={() => this.boundaryChanged()}
                        {...options}
                    />
                </div>
                {landmarkGroup &&
                    <div className={styles.container}>
                        <RightDrawer showDrawer>
                            <LandmarkGroupDetails
                                landmarkGroup={landmarkGroup}
                                filterData={filters}
                                currentPage={this.currentPage}
                                pageChanged={this.pageChanged}
                                isLoader={isLoader}
                                isUpdating={isUpdating}
                                updateLoader={updateLoader}
                                setMarkerArray={this.setMarkerArray}
                                redirectTo={history.push}
                                showRefreshButton={showRefreshButton}
                                refreshData={this.refreshData}
                                clearListData={(clearData) => { this.clearListData = clearData; }}
                            />
                        </RightDrawer>
                    </div>
                }
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => state.landmarkGroup;

export default reduxConnect(LandmarkGroup, actions, mapStateToProps);
