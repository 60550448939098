/* @flow */

import { FETCH_ASSETS_GROUP_LIST, FETCH_ASSETS_GROUP_LIST_SUCCESS, FETCH_ASSETS_GROUP_LIST_ERROR, RESET_ASSETS_GROUP_LIST } from './constants.assetGroupList';
import type { AssetGroupListTypeAction } from './constants.assetGroupList';

export const fetchAssetGroupList = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filter: Object,
): AssetGroupListTypeAction => ({
    type: FETCH_ASSETS_GROUP_LIST,
    payload: {
        order,
        orderBy,
        pageNumber,
        pageSize,
        filter,
    },
});

export const fetchAssetGroupListSuccess =
    (assetGroups: string, totalAssetsGroups: number):AssetGroupListTypeAction => ({
        type: FETCH_ASSETS_GROUP_LIST_SUCCESS,
        payload: { assetGroups, totalAssetsGroups },
    });

export const resetTable = (): AssetGroupListTypeAction => ({
    type: RESET_ASSETS_GROUP_LIST,
});

export const fetchAssetGroupListError = (error: string):AssetGroupListTypeAction => ({
    type: FETCH_ASSETS_GROUP_LIST_ERROR,
    payload: { error },
});
