/* @flow */

export const FETCH_SAVED_ALERTS: string = 'FETCH_SAVED_ALERTS';
export const FETCH_SAVED_ALERTS_SUCCESS: string = 'FETCH_SAVED_ALERTS_SUCCESS';
export const FETCH_SAVED_ALERTS_ERROR: string = 'FETCH_SAVED_ALERTS_ERROR';
export const FETCH_SAVED_ALERTS_LIST: string = 'FETCH_SAVED_ALERTS_LIST';
export const DELETE_SAVED_ALERTS: string = 'DELETE_SAVED_ALERTS';
export const DELETE_SAVED_ALERTS_SUCCESS: string = 'DELETE_SAVED_ALERTS_SUCCESS';
export const DELETE_SAVED_ALERTS_FAILURE: string = 'DELETE_RECIPIENT_ALERTS_FAILURE';
export const SAVED_ALERT_LIST_PREF_KEY: string = 'fleetLocate.savedAlertList';
export type SavedAlertsTypeAction = {
    type: string,
    payload?: Object,
};
