/* @flow */
import React, { PureComponent } from 'react';
import { getParamValue } from '../helper-classes/utility-functions';

export type Props = {
};

class ErrorPage extends PureComponent<Props> {
    props: Props;
    errorType: string;
    UNSAFE_componentWillMount() {
        this.errorType = getParamValue(this.props, 'type');
    }

    getErrorTemplate = () => {
        let template = '';
        switch (this.errorType) {
        case 'invalid-session':
            template = (
                <div style={{ textAlign: 'center', paddingTop: '5px' }}>
                    Due to extended inactivity, please log out and try again.
                </div>
            );
            break;
        default:
            template = (
                <div style={{ textAlign: 'center', paddingTop: '5px' }}>
                    Something went wrong!
                </div>
            );
            break;
        }
        return template;
    }

    render() {
        return (
            this.getErrorTemplate()
        );
    }
}

export default ErrorPage;
