/* @flow */
import React from 'react';
import { Typography, Tooltip, Grid } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import GetSvgIcon from '../../../util/svgImage_util';
import customStyles from './EventSummaryCard.module.scss';
import { EVENT_TYPE_MAPPING } from './constants';

export type Props = {
    eventKey: string,
    currentEventsCount: Object,
    historyEventsCount: Object,
    eventType: string,
    applyFilter: Function,
    isSelected: boolean,
    isComparisonVisible: boolean,
};

const getEventCount = (eventsCount: any, eventType: string, eventKey: string) => {
    if (eventsCount[eventType] && eventsCount[eventType][eventKey]) {
        return eventsCount[eventType][eventKey].count;
    }
    return 0;
};

const EventSummaryCard = (props: Props) => {
    const {
        eventKey,
        eventType,
        isSelected,
        isComparisonVisible,
        currentEventsCount,
        historyEventsCount,
    } = props;
    const eventName = EVENT_TYPE_MAPPING[eventKey].name;
    const eventTypeIcon = EVENT_TYPE_MAPPING[eventKey].icon;
    const currentCount = getEventCount(currentEventsCount, eventType, eventKey) || 0;
    const historyCount = getEventCount(historyEventsCount, eventType, eventKey) || 0;
    let countDelta = currentCount - historyCount;
    const isCurrentEventUp = countDelta > 0;
    const isEventEqual = countDelta === 0;
    countDelta = Math.abs(countDelta);

    const getArrow = () => (isCurrentEventUp ?
        <ArrowDropUp
            viewBox="3 -2 20 20"
            style={{ fontSize: 18, color: '#e02020' }}
        /> :
        <ArrowDropDown
            viewBox="3 -2 20 20"
            style={{ fontSize: 18, color: '#6dd400' }}
        />);

    return (
        <div
            className={`${customStyles.event_card_holder} ${isSelected ? customStyles.event_card_holder_selected : ''}`}
            id={eventKey}
            onClick={() => props.applyFilter({ eventType, eventKey })}
            tabIndex={0}
            onKeyDown={() => {}}
            role="button"
        >
            <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item xs={5} className={customStyles.count}>
                    <Tooltip title={currentCount} disableFocusListener>
                        <Typography noWrap className={customStyles.event_count} component="h5" variant="h5">
                            {currentCount}
                        </Typography>
                    </Tooltip>
                    { isComparisonVisible &&
                    <div className={customStyles.history_count}>
                        <span style={isEventEqual ? { fontSize: 18 } : {}}>
                            {
                                isEventEqual ?
                                    '- -' :
                                    <Tooltip title={countDelta || 0} disableFocusListener>
                                        <span>{countDelta || 0}</span>
                                    </Tooltip>
                            }
                        </span>
                        { !isEventEqual && getArrow()}
                    </div>
                    }
                </Grid>
                <Grid item xs={3}>
                    <Tooltip
                        title={eventName}
                        disableFocusListener
                    >
                        <span>
                            <GetSvgIcon
                                type={eventTypeIcon}
                                className={customStyles.event_icon}
                            />
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item xs={4} className={customStyles.name_Wrap}>
                    <Tooltip title={eventName} disableFocusListener>
                        <Typography className={customStyles.event_name} component="h5" variant="h5">
                            {eventName}
                        </Typography>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    );
};

export default EventSummaryCard;
