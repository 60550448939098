/* @flow */
import React, { Component } from 'react';
import queryString from 'query-string';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.assetgroup';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import MapsWrapper from '../MapsWrapper';
import { mapColor } from '../constants.map';
import AssetGroupDetails from '../../components/AssetGroupDetails/AssetGroupDetails';
import styles from './AssetGroup.module.scss';
import { getParamValue } from '../../helper-classes/utility-functions';
import { PAGE_SIZE, filterData } from './constants.assetgroup';
import RightDrawer from '../../components/SharedComponents/RightDrawer/RightDrawer';

export type Props = {
    searchAssetGroup: Function,
    isLoader: boolean,
    updateLoader: Function,
    history: {
        push: Function,
        replace: Function,
    },
    location: {
        search: string,
    },
    assetGroup: {
        data: Array<{
            id: string,
            name: string,
            type: string,
            accountId: string,
            assetGroupId: string,
            assetGroupName: string,
            status: string,
            speed: number,
            direction: string,
            lastEvent: string,
            lastEventDate: string,
            city: string,
            address: string,
            zipCode: string
        }>,
        count: string,
        total: string,
        response: Object,
    },
};

export type State = {
    markerArray: Array<{
        id: string,
        lat: string,
        lng: string,
    }>,
    filters: Object,
    searchedString: string,
    showRefreshButton: boolean,
    resetRefreshTimer: boolean,
    zoom: number,
};

class AssetGroup extends Component<Props, State> {
    currentPage: number;
    filtersSelected: boolean;
    id: string;
    clearListData: Function;
    map: Object;

    constructor(props) {
        super(props);
        const parsed = queryString.parse(this.props.location.search);
        const selectedFilter = (parsed && parsed.filter) ? parsed.filter : '';
        const searchedString = (parsed && parsed.search) ? parsed.search : '';
        this.filtersSelected = false;
        this.state = {
            markerArray: [],
            filters: this.getFilter(selectedFilter),
            searchedString,
            showRefreshButton: false,
            resetRefreshTimer: false,
            zoom: 3,
        };
        this.currentPage = 0;
        this.id = getParamValue(this.props, 'id');
        this.map = {};
    }

    componentDidMount() {
        this.refreshPage();
    }

    refreshPage = () => {
        this.props.updateLoader(true);
        const formattedFilters =
        this.getFormattedFilters(this.state.searchedString, this.currentPage, this.state.filters);
        this.props.searchAssetGroup(
            this.id,
            this.state.searchedString,
            this.currentPage * PAGE_SIZE,
            formattedFilters,
        );
    }

    getFilter = (selectedFilter) => {
        if (selectedFilter && selectedFilter.length > 0) {
            const filter = selectedFilter.split(',');
            const filters = JSON.parse(JSON.stringify(filterData));
            filters.forEach((category) => {
                category.value.forEach((f) => {
                    const newF = f;

                    if (filter.indexOf(f.value) >= 0) {
                        newF.checked = true;
                        this.filtersSelected = true;
                    }
                });
            });

            return filters;
        }
        this.filtersSelected = false;
        return JSON.parse(JSON.stringify(filterData));
    }

    getFormattedFilters(search, page, filters) {
        const selectedFilters = [];

        if (filters) {
            filters.forEach((category) => {
                const innerFilterArray = [category.key];
                let filterString = '';
                category.value.forEach((filter) => {
                    // if item is first item
                    if (filter.checked && filterString.length === 0) {
                        if (category.key === 'landmarkName') {
                            filterString = `exists:${filter.value}`;
                        } else {
                            filterString = `eq:${filter.value}`;
                        }
                    } else if (filter.checked) {
                        filterString = `${filterString},${filter.value}`;
                    }
                });

                if (filterString !== '') {
                    innerFilterArray.push(filterString);
                    selectedFilters.push(innerFilterArray);
                }
            });
        }

        return {
            searchParams: search ? [getParamValue(this.props, 'id'), search] : [getParamValue(this.props, 'id')],
            type: search ? 'groupIdAndName' : 'groupId',
            start: page * PAGE_SIZE,
            limit: PAGE_SIZE,
            filterParam: selectedFilters,
        };
    }

    pageChanged = (page, isSearch, search, isPageReset, filters) => {
        this.currentPage = page;
        if (isPageReset) {
            this.currentPage = 0;
        }
        if (!search) {
            this.setState({ searchedString: '' });
        } else {
            this.setState({ searchedString: search || '' });
        }
        if (filters) {
            this.setState({ filters });
        }

        const formattedFilters = this.getFormattedFilters(search, page, filters);
        if (filters) {
            const url = [];
            filters.forEach((category) => {
                category.value.forEach((f) => {
                    if (f.checked) {
                        url.push(f.value);
                    }
                });
            });

            if (url.length > 0) {
                let filterString = '';

                if (search) {
                    filterString = `?filter=${url.join(',')}&search=${search}`;
                } else {
                    filterString = `?filter=${url.join(',')}`;
                }
                this.props.history.replace(filterString);
            } else if (search) {
                this.props.history.replace(`?search=${search}`);
            } else {
                this.props.history.replace('?');
            }
        } else if (search) {
            this.props.history.replace(`?search=${search}`);
        } else {
            this.props.history.replace('?');
        }

        if (this.currentPage >= 0) {
            if (isSearch) {
                this.props.searchAssetGroup(getParamValue(this.props, 'id'), search, page * PAGE_SIZE, formattedFilters);
            } else {
                this.props.searchAssetGroup(getParamValue(this.props, 'id'), '', page * PAGE_SIZE, formattedFilters);
            }
        } else if (isSearch) {
            // first page of search
            this.props.searchAssetGroup(getParamValue(this.props, 'id'), search, 0, formattedFilters);
        }
    };

    setMarkerArray = (marker) => {
        if (marker !== undefined && Array.isArray(marker)) {
            let filteredMarker = marker.filter(d => d.lastLocation);

            if (filteredMarker.length === 0) {
                this.setState({ markerArray: [] });
            } else if (filteredMarker && filteredMarker.length > 0) {
                filteredMarker = filteredMarker.map((d) => {
                    const newData = d;
                    newData.latitude = d.lastLocation.y || 0;
                    newData.longitude = d.lastLocation.x || 0;
                    newData.lat = d.lastLocation.y || 0;
                    newData.lng = d.lastLocation.x || 0;
                    newData.redirectTo = this.props.history.push;
                    let getColorNumber =
                    Math.floor(Math.random() * (mapColor.asset.group.length - 1));

                    if (getColorNumber > (mapColor.asset.group.length - 1)) {
                        getColorNumber = mapColor.asset.group.length - 1;
                    }

                    if (!newData.getColorNumber) {
                        newData.getColorNumber = getColorNumber;
                    }
                    return newData;
                });
                this.setState({ markerArray: filteredMarker });
            }
        }
    };

    mapRefreshDataCallback = () => this.setState({ showRefreshButton: true });

    refreshData = (needToRefresh) => {
        if (needToRefresh) {
            this.currentPage = 0;
            this.refreshPage();
            if (this.clearListData && typeof this.clearListData === 'function') {
                this.clearListData();
            }
        }
        this.setState({ showRefreshButton: false, resetRefreshTimer: true });
        setTimeout(() => {
            this.setState({ resetRefreshTimer: false });
        }, 500);
    }

    getBoundary = (map) => {
        this.map = map;
    }

    // callback from map page when any bound change, tiles will be loaded on the map.
    boundaryChanged = () => {
        if (this.map && Object.keys(this.map).length > 0) {
            this.setState({ zoom: this.map.getZoom() });
        }
    }

    render() {
        const {
            markerArray,
            filters,
            searchedString,
            showRefreshButton,
            resetRefreshTimer,
        } = this.state;

        const {
            isLoader,
            assetGroup,
            updateLoader,
            history,
        } = this.props;

        const shape = {
            type: 'markerGroup',
            id: 'asset',
            key: 'asset',
            data: markerArray.length > 0 ? markerArray : [],
        };

        const options = {};
        options.zoom = this.state.zoom;

        return (
            <MiniDrawer redirectTo={this.props.history.push}>
                <div key="asset-group-map-container" className={styles.mapWrapper}>
                    <MapsWrapper
                        key="asset-group-map"
                        refreshData={this.mapRefreshDataCallback}
                        resetRefreshTimer={resetRefreshTimer}
                        markers={{ shape }}
                        moduleName="asset-group"
                        getBoundaries={(boundary) => { this.getBoundary(boundary); }}
                        boundaryChanged={() => this.boundaryChanged()}
                        {...options}
                    />
                </div>
                {this.props.assetGroup &&
                    <div className={styles.container}>
                        <RightDrawer showDrawer >
                            <AssetGroupDetails
                                assetGroup={assetGroup && assetGroup.response ?
                                    assetGroup.response : assetGroup}
                                currentPage={this.currentPage}
                                pageChanged={this.pageChanged}
                                isLoader={isLoader}
                                updateLoader={updateLoader}
                                setMarkerArray={this.setMarkerArray}
                                redirectTo={history.push}
                                filterData={filters}
                                filtersSelected={this.filtersSelected}
                                searchedString={searchedString}
                                showRefreshButton={showRefreshButton}
                                refreshData={this.refreshData}
                                clearListData={(clearData) => {
                                    this.clearListData = clearData;
                                }
                                }
                            />
                        </RightDrawer>
                    </div>
                }
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => state.assetGroup;

export default reduxConnect(AssetGroup, actions, mapStateToProps);
