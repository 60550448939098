export const FETCH_ADDRESS: string = 'FETCH_ADDRESS';
export const FETCH_ADDRESS_SUCCESS: string = 'FETCH_ADDRESS_SUCCESS';
export const FETCH_ADDRESS_ERROR: string = 'FETCH_ADDRESS_ERROR';
export const UPDATE_LOADER: string = 'UPDATE_LOADER';
export const FETCH_ADDRESS_WITH_LAT_LNG: string = 'FETCH_ADDRESS_WITH_LAT_LNG';
export const FETCH_ADDRESS_WITH_LAT_LNG_SUCCESS: string = 'FETCH_ADDRESS_WITH_LAT_LNG_SUCCESS';
export const FETCH_ADDRESS_WITH_LAT_LNG_ERROR: string = 'FETCH_ADDRESS_WITH_LAT_LNG_ERROR';
export type AddressTypeAction = {
    type: string;
payload?: Object;
}
