/* @flow */

// This order is used in EventSetting page to render UI
export const EVENT_TYPE_MAPPING = {
    smoking: {
        name: 'Smoking', icon: 'dashcamEvent_smoking', value: 'off', virtualEvent: false, isModified: false,
    },
    foodDrink: {
        name: 'Food & Drink', icon: 'dashcamEvent_foodDrink', value: 'off', virtualEvent: false, isModified: false,
    },
    driverUnbelted: {
        name: 'Driver Unbelted', icon: 'dashcamEvent_driverUnbelted', value: 'off', virtualEvent: false, isModified: false,
    },
    cellPhoneUse: {
        name: 'Cellphone Use', icon: 'dashcamEvent_cellPhoneUse', value: 'off', virtualEvent: false, isModified: false,
    },
    distractedDriving: {
        name: 'Distracted Driving', icon: 'dashcamEvent_distractedDriving', value: 'off', virtualEvent: false, isModified: false,
    },
    button: {
        name: 'Button Pressed', icon: 'dashcamEvent_buttonPressed', value: 'off', virtualEvent: false, isModified: false,
    },
    jolt: {
        name: 'Vibration', icon: 'dashcamEvent_vibration', value: 'off', virtualEvent: false, isModified: false,
    },
    coverOpened: {
        name: 'Tamper', icon: 'dashcamEvent_tamper', value: 'off', virtualEvent: false, isModified: false,
    },
    accOn: {
        name: 'Power On', icon: 'dashcamEvent_powerOn', value: 'none', virtualEvent: false, isModified: false,
    },
    accOff: {
        name: 'Power Off', icon: 'dashcamEvent_powerOff', value: 'none', virtualEvent: false, isModified: false,
    },
    powerDisconnectAlarm: {
        name: 'Dashcam Power Disconnect', icon: 'dashcamEvent_powerDisconnect', value: 'off', virtualEvent: false, isModified: false,
    },
    sharpTurnLeft: {
        name: 'Hard Cornering', icon: 'dashcamEvent_hardCornering', value: 'off', virtualEvent: false, isModified: false,
    },
    sharpTurnRight: {
        name: 'Hard Cornering', icon: 'dashcamEvent_hardCornering', value: 'off', virtualEvent: false, isModified: false,
    },
    obstruction: {
        name: 'Obstruction', icon: 'dashcamEvent_obstruction', value: 'off', virtualEvent: false, isModified: false,
    },
    tailgating: {
        name: 'Tailgating',
        icon: 'dashcamEvent_tailgating',
        value: 'off',
        config: 'default',
        virtualEvent: false,
        isModified: false,
    },
    accident: {
        name: 'Collision', icon: 'dashcamEvent_collision', value: 'off', virtualEvent: false, isModified: false,
    },
    HARD_ACCELERATION: {
        name: 'Hard Acceleration', icon: 'dashcamEvent_hardAcceleration', value: 'off', virtualEvent: true, isModified: false,
    },
    HARD_BRAKING: {
        name: 'Hard Braking', icon: 'dashcamEvent_hardBrake', value: 'off', virtualEvent: true, isModified: false,
    },
    UNAUTHORIZED_MOVEMENT: {
        name: 'Unauthorized Movement', icon: 'dashcamEvent_unauthorizeMovement', value: 'video', virtualEvent: true, isModified: false,
    },
    DEVICE_POWER_DISCONNECT: {
        name: 'Device Power Disconnect', icon: 'dashcamEvent_devicePowerDisconnect', value: 'video', virtualEvent: true, isModified: false,
    },
};


export const HARD_CORNERING_EVENT_TYPES = ['sharpTurnLeft', 'sharpTurnRight'];

export const SAFETY_VIRTUAL_EVENTS = ['HARD_ACCELERATION', 'HARD_BRAKING'];
export const MONITORING_VIRTUAL_EVENTS = ['UNAUTHORIZED_MOVEMENT', 'DEVICE_POWER_DISCONNECT'];

// This order is used in EventSummary page to render UI
export const SUPPORTED_EVENT_TYPES = [
    'smoking',
    'foodDrink',
    'driverUnbelted',
    'cellPhoneUse',
    'distractedDriving',
    'button',
    'jolt',
    'coverOpened',
    'accOn',
    'accOff',
    'powerDisconnectAlarm',
    ...HARD_CORNERING_EVENT_TYPES,
    'obstruction',
    'tailgating',
    'accident',
    ...SAFETY_VIRTUAL_EVENTS,
    ...MONITORING_VIRTUAL_EVENTS,
];

export const SAFETY_EVENT_TYPES = [
    'smoking',
    'foodDrink',
    'driverUnbelted',
    'cellPhoneUse',
    'distractedDriving',
    ...HARD_CORNERING_EVENT_TYPES,
    'tailgating',
    'accident',
    ...SAFETY_VIRTUAL_EVENTS,
];

// This order is used in EventSummary page to render UI
export const MONITORING_EVENT_TYPES = [
    'button',
    'jolt',
    'coverOpened',
    'accOn',
    'accOff',
    'powerDisconnectAlarm',
    'obstruction',
    ...MONITORING_VIRTUAL_EVENTS,
];

export const EVENT_TYPES_CATEGORY = [
    {
        name: 'Safety',
        events: SAFETY_EVENT_TYPES,
    },
    {
        name: 'Monitoring',
        events: MONITORING_EVENT_TYPES,
    },
];

export const EVENT_TYPE_COVER_OPENED = 'coverOpened';

export const EVENT_TAILGATING_TIME_TO_COLLISION = {
    default: {
        headwayAlertDayThreshold: 2000,
        headwayAlertNightThreshold: 2000,
        headwayAlertTime: 5000,
    },
    lessSensitive: {
        headwayAlertDayThreshold: 1000,
        headwayAlertNightThreshold: 1000,
        headwayAlertTime: 5000,
    },
    moreSensitive: {
        headwayAlertDayThreshold: 3000,
        headwayAlertNightThreshold: 3000,
        headwayAlertTime: 5000,
    },
};

export const DEFAULT_EVENT_SETTINGS = {
    vehicleType: 'Private',
    events: [
        {
            dataType: 'video',
            eventType: 'smoking',
        },
        {
            dataType: 'video',
            eventType: 'foodDrink',
        },
        {
            dataType: 'video',
            eventType: 'driverUnbelted',
        },
        {
            dataType: 'video',
            eventType: 'cellPhoneUse',
        },
        {
            dataType: 'video',
            eventType: 'distractedDriving',
        },
        {
            dataType: 'video',
            eventType: 'button',
        },
        {
            dataType: 'video',
            eventType: 'jolt',
        },
        {
            dataType: 'video',
            eventType: 'coverOpened',
        },
        {
            dataType: 'none',
            eventType: 'accOn',
        },
        {
            dataType: 'none',
            eventType: 'accOff',
        },
        {
            dataType: 'video',
            eventType: 'powerDisconnectAlarm',
        },
        {
            dataType: 'video',
            eventType: 'sharpTurnLeft',
        },
        {
            dataType: 'video',
            eventType: 'sharpTurnRight',
        },
        {
            dataType: 'video',
            eventType: 'obstruction',
        },
        {
            dataType: 'video',
            eventType: 'accident',
        },
    ],
};

export const WHITE_COLOR = '#fff';
export const GRAY_COLOR = '#f0f0f0';
export const DATE_FORMAT = 'MM/DD/YYYY';
