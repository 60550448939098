/* @flow */
import React, { Component } from 'react';
import { Paper } from '@mui/material';
import customStyles from '../VideoPlayer/VideoPlayer.module.scss';
import Webrtc from './Webrtc';
import Hls from './Hls';
import { isWebRtcAllowed } from './../../../containers/DashCam/helper-classes/dashcamServices';
import {
    LIVE_VIDEO_CONFIG,
    DASHCAM_ANALYTICS,
    DASHCAM_LIVE_VIEW_STREAM,
} from './../../../containers/DashCam/constants.dashcam';
import analytics from '../../../analytics/index';

export type Props = {
    removeVideo: Function,
    imei: string,
    cameraId: number,
    name: string,
    errorNotification: Function,
};

export type State = {
    type: string,
}

class WebrtcContainer extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            type: LIVE_VIDEO_CONFIG.defaultVideoType,
        };
    }

    componentDidMount() {
        this.trackAnalytics();
    }

    trackAnalytics = () => {
        analytics.track(
            DASHCAM_ANALYTICS,
            {
                eventName: DASHCAM_LIVE_VIEW_STREAM,
                feature: DASHCAM_ANALYTICS,
                cameraType: this.props.cameraId === 1 ? 'Front' : 'Rear',
                videoType: this.state.type === 'webRtc' ? 'webRtc' : 'hls',
            },
        );
    }

    removeVideo = () => this.props.removeVideo(this.props.imei, this.props.cameraId);

    handleTypeChange = (type: string) => {
        this.setState({ type }, () => {
            this.trackAnalytics();
        });
    }

    errorNotification = (message: string) => this.props.errorNotification(message);

    getVideo = () => (
        this.state.type === 'webRtc' && isWebRtcAllowed() ?
            <Webrtc
                imei={this.props.imei}
                cameraId={this.props.cameraId}
                handleTypeChange={type => this.handleTypeChange(type)}
                errorNotification={this.errorNotification}
            /> :
            <Hls
                imei={this.props.imei}
                cameraId={this.props.cameraId}
                handleTypeChange={type => this.handleTypeChange(type)}
                errorNotification={this.errorNotification}
            />
    );

    render() {
        return (
            <div className={customStyles.liveVideoContainer}>
                <Paper className={customStyles.content}>
                    <div className={customStyles.card}>
                        <div className={customStyles.nameContainer}>
                            <div className={customStyles.name}>
                                {this.props.name} - {this.props.cameraId === 1 ? 'Front' : 'Rear'}
                            </div>
                            <div
                                onClick={() => this.removeVideo()}
                                onKeyPress={() => {}}
                                role="button"
                                tabIndex="0"
                                className={customStyles.removeVideo}
                            >
                                Remove
                            </div>
                        </div>
                        {this.getVideo()}
                    </div>
                </Paper>
            </div>
        );
    }
}

export default WebrtcContainer;
