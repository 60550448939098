/* @flow */

export const FETCH_ASSETS_GROUP_LIST: string = 'FETCH_ASSETS_GROUP_LIST';
export const FETCH_ASSETS_GROUP_LIST_SUCCESS: string = 'FETCH_ASSETS_GROUP_LIST_SUCCESS';
export const FETCH_ASSETS_GROUP_LIST_ERROR: string = 'FETCH_ASSETS_GROUP_LIST_ERROR';
export const RESET_ASSETS_GROUP_LIST: string = 'RESET_ASSETS_GROUP_LIST';
export const ASSET_GRP_LIST_PREF_KEY: string = 'fleetLocate.assetGroup';
export type AssetGroupListTypeAction = {
    type: string;
    payload?: Object;
}
