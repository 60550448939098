/* @flow */

import {
    FETCH_USER,
    FETCH_USER_SUCCESS,
    FETCH_USER_ERROR,
    FETCH_USERROLES,
    FETCH_USERROLES_SUCCESS,
    FETCH_USERROLES_ERROR,
    FETCH_USERACCESS,
    FETCH_USERACCESS_SUCCESS,
    FETCH_USERACCESS_ERROR,
    ADD_USER,
    ADD_EDIT_USER_SUCCESS,
    ADD_USER_ERROR,
    EDIT_USER,
    EDIT_USER_ERROR,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    UPDATE_USER_LOADER,
    CLOSE_USER_DIALOG,
} from './constants.user';
import type { UserTypeAction } from './constants.user';

export const fetchUser = (id: any): UserTypeAction => ({
    type: FETCH_USER,
    payload: { id },
});

export const fetchUserSuccess = (user: any): UserTypeAction => ({
    type: FETCH_USER_SUCCESS,
    payload: { user },
});

export const fetchUserError = (error: any): UserTypeAction => ({
    type: FETCH_USER_ERROR,
    payload: { error },
});

export const fetchUserRoles = (): UserTypeAction => ({
    type: FETCH_USERROLES,
    payload: {},
});

export const fetchUserRolesSuccess = (userRoles: any): UserTypeAction => ({
    type: FETCH_USERROLES_SUCCESS,
    payload: { userRoles },
});

export const fetchUserRolesError = (error: any): UserTypeAction => ({
    type: FETCH_USERROLES_ERROR,
    payload: { error },
});

export const fetchUserAccess = (): UserTypeAction => ({
    type: FETCH_USERACCESS,
    payload: {},
});

export const fetchUserAccessSuccess = (userAccess: any): UserTypeAction => ({
    type: FETCH_USERACCESS_SUCCESS,
    payload: { userAccess },
});

export const fetchUserAccessError = (error: any): UserTypeAction => ({
    type: FETCH_USERACCESS_ERROR,
    payload: { error },
});

export const createUser = (requestBody: any): UserTypeAction => ({
    type: ADD_USER,
    payload: { requestBody },
});

export const addEditUserSuccess = (successMsg: any): UserTypeAction => ({
    type: ADD_EDIT_USER_SUCCESS,
    payload: { successMsg },
});

export const addUserError = (error: any): UserTypeAction => ({
    type: ADD_USER_ERROR,
    payload: { error },
});

export const editUser = (requestBody: any): UserTypeAction => ({
    type: EDIT_USER,
    payload: { requestBody },
});

export const editUserError = (error: any): UserTypeAction => ({
    type: EDIT_USER_ERROR,
    payload: { error },
});

export const deleteUser = (id: string): UserTypeAction => ({
    type: DELETE_USER,
    payload: { id },
});

export const deleteUserSuccess = (successMsg: any): UserTypeAction => ({
    type: DELETE_USER_SUCCESS,
    payload: { successMsg },
});

export const deleteUserError = (error: any): UserTypeAction => ({
    type: DELETE_USER_ERROR,
    payload: { error },
});

export const updateLoader = (loading: boolean) => ({
    type: UPDATE_USER_LOADER,
    payload: { loading },
});

export const closeDialog = () => ({
    type: CLOSE_USER_DIALOG,
    payload: {},
});
