export const convertKilometersToMiles = (kilometers) => {
    let miles = 0;
    if (kilometers && !Number.isNaN(Number(kilometers)) && parseInt(kilometers, 10) > 0) {
        miles = parseInt(kilometers, 10) / 1.609344;
    }
    return miles;
};

export const convertMilesToKilometers = (miles) => {
    let kilometers = 0;
    if (miles && !Number.isNaN(Number(miles)) && parseFloat(miles) > 0) {
        kilometers = Math.floor(parseFloat(miles) * 1.609344);
    }
    return kilometers;
};

export const convertFahrenheitToCelsius = (fahrenheitInput) => {
    const fahrenheit = (fahrenheitInput && (!Number.isNaN(Number(fahrenheitInput)))) ?
        parseFloat(fahrenheitInput, 10) : 0;
    return ((fahrenheit - 32) * (5 / 9));
};

export const convertCelsiusToFahrenheit = (celsiusInput) => {
    const celsius = (celsiusInput && (!Number.isNaN(Number(celsiusInput)))) ?
        parseFloat(celsiusInput, 10) : 0;
    return parseInt((((celsius * 9) / 5) + 32), 10);
};
