/* @flow */
import {
    FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST,
    FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST_SUCCESS,
    FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST_ERROR,
} from './constants.landmarks';

import type { LandmarksTypeAction } from './constants.landmarks';

// Landmark-group actions
export const fetchAlertCreationLandmarkGroupsList = (): LandmarksTypeAction => ({
    type: FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST,
    payload: {},
});

export const fetchAlertCreationLandmarkGroupsListSuccess =
    (landmarkGroups: []): LandmarksTypeAction => ({
        type: FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST_SUCCESS,
        payload: { landmarkGroups },
    });

export const fetchAlertCreationLandmarkGroupsListError = (error: string): LandmarksTypeAction => ({
    type: FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST_ERROR,
    payload: { error },
});
