/* @flow */

import {
    FETCH_SAVED_ALERTS,
    FETCH_SAVED_ALERTS_SUCCESS,
    FETCH_SAVED_ALERTS_ERROR,
    FETCH_SAVED_ALERTS_LIST,
    DELETE_SAVED_ALERTS,
    DELETE_SAVED_ALERTS_SUCCESS,
    DELETE_SAVED_ALERTS_FAILURE,
} from './constants.savedAlerts';
import type { SavedAlertsTypeAction } from './constants.savedAlerts';

export const fetchSavedAlerts = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    savedAlertsFiltersToSend: any,
    editAlert: Boolean,
): SavedAlertsTypeAction => ({
    type: FETCH_SAVED_ALERTS,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
        savedAlertsFiltersToSend,
        editAlert,
    },
});

export const fetchSavedAlertsSuccess = (
    savedAlerts: any,
    totalRecords: number,
): SavedAlertsTypeAction => ({
    type: FETCH_SAVED_ALERTS_SUCCESS,
    payload: { savedAlerts, totalRecords },
});

export const resetTable = (): SavedAlertsTypeAction => ({
    type: FETCH_SAVED_ALERTS_LIST,
});

export const fetchSavedAlertsError = (error: string): SavedAlertsTypeAction => ({
    type: FETCH_SAVED_ALERTS_ERROR,
    payload: { error },
});

export const deleteSavedAlert = (id: number): SavedAlertsTypeAction => ({
    type: DELETE_SAVED_ALERTS,
    payload: { id },
});

export const deleteSavedAlertSuccess = (response : {}): SavedAlertsTypeAction => ({
    type: DELETE_SAVED_ALERTS_SUCCESS,
    payload: { response },
});

export const deleteSavedAlertError = (response: {}): SavedAlertsTypeAction => ({
    type: DELETE_SAVED_ALERTS_FAILURE,
    payload: { response },
});
