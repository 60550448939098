/* @flow */
import React, { Component } from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import AccountDetails from './AccountDetails';
import styles from './UserAccount.module.scss';
import AppSettings from './AppSettings';
import { trackAnalytics, getAppUserSettings, redirectToAuthServiceChangePassword } from '../../helper-classes/utility-functions';

type Props = {
    accountDetail: {
        firstName: string,
        username: string,
        lastName: string,
        email: string,
        id: string,
        phone: string,
    },
    buttonClicked: boolean,
    updateAccountDetails: boolean,
    updateAppSettings: boolean,
    updatePreferences: boolean,
    saveAccountDetail: Function,
    saveAppSettings: Function,
    savePreferences: Function,
    appSettings: Object,
    disableBackButton: Function,
};

type State = {
    userAccountSection: string,
};

class Settings extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            userAccountSection: 'Account Details',
        };
    }

    handleOption = (e: string, eventName: string) => {
        trackAnalytics(eventName);
        this.setState({ userAccountSection: e });
    };

    renderElement() {
        const {
            userAccountSection,
        } = this.state;
        if (userAccountSection === 'Account Details' && this.props.accountDetail && this.props.accountDetail.id) {
            return (
                <AccountDetails
                    accountDetail={this.props.accountDetail}
                    buttonClicked={this.props.buttonClicked}
                    saveAccountDetail={this.props.saveAccountDetail}
                    updateAccountDetails={this.props.updateAccountDetails}
                    disableBackButton={this.props.disableBackButton}
                />
            );
        } else if (userAccountSection === 'App Settings') {
            const userSettings = getAppUserSettings();
            const defaultPage = userSettings['app.settings'] ? userSettings['app.settings'].value.defaultPage : '';
            const unitOfMeasure = userSettings['app.settings'] ? userSettings['app.settings'].value.units : '';
            const timeDisplay = userSettings['app.settings'] ? userSettings['app.settings'].value.timeDisplay : '';
            const tzOffset = userSettings['app.settings'] ? userSettings['app.settings'].value.tzOffset : '0';
            const tzDST = userSettings['app.settings'] ? userSettings['app.settings'].value.tzDST : '0';
            const tz = userSettings['app.settings'] ? userSettings['app.settings'].value.tz : '';
            const userTz = userSettings ? userSettings.userTz : '';
            const userUnits = userSettings ? userSettings.userUnits : '';
            const appSettings = {
                defaultPage: `${defaultPage}`,
                tzOffset,
                tzDST,
                tz: `${tz}`,
                userTz,
                userUnits,
                unitOfMeasure: `${unitOfMeasure}`,
                timeDisplay: `${timeDisplay}`,
                message: '',
            };
            return (
                <AppSettings
                    appSettings={appSettings}
                    buttonClicked={this.props.buttonClicked}
                    saveAppSettings={this.props.saveAppSettings}
                    savePreferences={this.props.savePreferences}
                    updateAppSettings={this.props.updateAppSettings}
                    updatePreferences={this.props.updatePreferences}
                    disableBackButton={this.props.disableBackButton}
                />
            );
        }
        return (
            <div />
        );
    }

    render() {
        const {
            userAccountSection,
        } = this.state;
        return (
            <div className={styles['user-account']}>
                <div className={styles['user-account-nav']}>
                    <List component="nav">
                        <ListItem
                            onClick={e => this.handleOption(e.target.dataset.section ? e.target.dataset.section : 'Account Details', 'MY_ACCOUNT_ACCOUNT_DETAILS_TAB_CLICK')}
                            button
                            className={userAccountSection === 'Account Details' ? styles.active : ''}
                            data-section="Account Details"
                            data-qa="account_details_tab"
                        >
                            <ListItemIcon data-section="Account Details" className={styles['list-Item-Icon']}>
                                <span className={styles['account-icon']} data-section="Account Details" />
                            </ListItemIcon>
                            <ListItemText
                                className={styles['list-item-text']}
                                data-section="Account Details"
                                primary={
                                    <div
                                        data-section="Account Details"
                                        className={styles['list-item-text']}
                                    >
                                        Account Details
                                    </div>
                                }
                            />
                        </ListItem>
                        <ListItem
                            button
                            onClick={e => this.handleOption(e.target.dataset.section ? e.target.dataset.section : 'App Settings', '')}
                            className={userAccountSection === 'App Settings' ? styles.active : ''}
                            data-section="App Settings"
                            data-qa="account_settings_tab"
                        >
                            <ListItemIcon data-section="App Settings" className={styles['list-Item-Icon']}>
                                <span className={styles['app-settings']} data-section="App Settings" />
                            </ListItemIcon>
                            <ListItemText
                                className={styles['list-item-text']}
                                data-section="App Settings"
                                primary={
                                    <div
                                        data-section="App Settings"
                                        id="nav-app-settings"
                                        className={styles['list-item-text']}
                                    >
                                        App Settings
                                    </div>
                                }
                            />
                        </ListItem>
                        <ListItem
                            onClick={() => redirectToAuthServiceChangePassword()}
                            button
                            className={userAccountSection === 'Password' ? styles.active : ''}
                            data-section="Password"
                            data-qa="account_password_tab"
                        >
                            <ListItemIcon data-section="Password" className={styles['list-Item-Icon']}>
                                <span className={styles.password} data-section="Password" />
                            </ListItemIcon>
                            <ListItemText
                                className={styles['list-item-text']}
                                data-section="Password"
                                primary={
                                    <div
                                        data-section="Password"
                                        className={styles['list-item-text']}
                                    >
                                        Password
                                    </div>
                                }
                            />
                        </ListItem>
                    </List>
                </div>
                {this.renderElement()}
            </div>
        );
    }
}

export default Settings;
