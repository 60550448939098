/* @flow */
import {
    FETCH_CLUSTERPOINTS_SUCCESS,
    FETCH_CLUSTERPOINTS_ERROR,
    GET_CLUSTER_EXPLODE_SUCCESS,
    FETCH_CLUSTERPOINTS,
} from './constants.home';

const initialState = {
    assets: [],
    assetExplodePoints: {},
    landmarkExplodePoints: {},
    assetPoints: {},
    assetPointsCount: {},
    landmarkPointsCount: {},
    landmarkPoints: {},
    totalAssets: 0,
    loadingCluster: false,
};

type stateType = {
    assets: Array<Object>,
    assetExplodePoints: Object,
    landmarkExplodePoints: Object,
    assetPoints: Object,
    assetPointsCount: Object,
    landmarkPointsCount: Object,
    landmarkPoints: Object,
    totalAssets: number,
    loadingCluster: boolean,
}
type actionType = {
    type: string,
    payload :{
        assets: {},
        totalAssets: 0,
        assetPoints: {},
        assetPointsCount: {},
        landmarkPointsCount: {},
        landmarkPoints: {},
        assetExplodePoints: {},
        landmarkExplodePoints: {},
        loadingCluster: false,
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_CLUSTERPOINTS_SUCCESS:
        return {
            ...state,
            assetPoints: action.payload.assetPoints,
            assetPointsCount: action.payload.assetPointsCount,
            landmarkPointsCount: action.payload.landmarkPointsCount,
            landmarkPoints: action.payload.landmarkPoints,
            loadingCluster: false,
        };
    case FETCH_CLUSTERPOINTS_ERROR:
        return {
            ...state,
            loadingCluster: false,
        };
    case GET_CLUSTER_EXPLODE_SUCCESS:
        return {
            ...state,
            assetExplodePoints: action.payload.assetPoints,
            landmarkExplodePoints: action.payload.landmarkPoints,
            loadingCluster: false,
        };
    case FETCH_CLUSTERPOINTS:
        return {
            ...state,
            loadingCluster: true,
        };
    default:
        return state;
    }
};
