/* @flow */
import React, { Component } from 'react';
import { reduxConnect } from '../../../hoc';
import * as actions from './actions.landingPage';
import AppLoader from '../../../components/AppLoader';
import AlertCategory from '../../../components/Alerts/AlertLandingPage/AlertCategory';
import { mapAlertTypeToCategory } from '../../../util/mapAlertTypes';

export type Props = {
    fetchAlertNameList: Function,
    alertNames: any,
    isLoading: boolean,
};
export type State = {};

class AlertLandingPageList extends Component<Props, State> {
    componentDidMount() {
        this.props.fetchAlertNameList();
    }
    filterAlertCategories() {
        const AlertTypeList = mapAlertTypeToCategory(this.props.alertNames.data);
        return AlertTypeList.map(el => <AlertCategory data={el} key={el.alertCategory} />);
    }
    render() {
        return this.props.isLoading === false ? (
            <div>{this.filterAlertCategories()}</div>
        ) : (
            <AppLoader
                loaderStyle={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    'text-align': 'center',
                }}
            />
        );
    }
}

const mapStateToProps = state => ({
    alertNames: state.alertNames.alertNames,
    isLoading: state.alertNames.isLoading,
});

export default reduxConnect(AlertLandingPageList, actions, mapStateToProps);
