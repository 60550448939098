/* @flow */

export const ASSET = 'asset';
export const LANDMARK_ADDRESS = 'landmark_address';
export const LANDMARK_LAT_LNG = 'landmark_lat_lng';
export const LANDMARK_POLYGON = 'landmark_polygon';
export const DRIVER = 'driver';
export const USER = 'user';
export const LANDMARK_ADDRESS_TEMPLATE_NAME = 'Landmark_Address_Template.csv';
export const LANDMARK_LAT_LNG_TEMPLATE_NAME = 'Landmark_Lat_Lng_Template.csv';
export const LANDMARK_POLYGON_TEMPLATE_NAME = 'Landmark_Polygon_Template.csv';
export const DRIVER_TEMPLATE_NAME = 'Driver_Template.csv';
export const ASSET_TEMPLATE_NAME = 'Asset_Template.csv';
export const USER_TEMPLATE_NAME = 'User_Template.csv';
export const FILESIZE = 5;
export const ERRORMSG = 'Something went wrong';
export const SUCCESSMSG = 'Your Request is being Processed';

