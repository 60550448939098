/* @flow */
/* eslint-disable no-console */
import React, { Component } from 'react';
import HLS from 'hls.js';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { verifyHlsSource } from './../../../containers/DashCam/helper-classes/dashcamServices';
import { isDashcamAuthenticated, getMediaSourceUrl, getDeviceMediaTokenData } from './../../../util/dashcam_util';
import { HLS_CONFIG, LIVE_VIDEO_CONFIG } from './../../../containers/DashCam/constants.dashcam';

type Props = {
    imei: string,
    cameraId: number,
    handleTypeChange: Function,
    errorNotification: Function,
}

type State = {
    isPlaying: boolean,
}

class Hls extends Component<Props, State> {
    hlsRef: Object;
    source: string;
    config: Object;
    hlsTimer: any;
    hls: any;
    runningCounterTime: number;

    constructor(props: Props) {
        super(props);
        this.state = {
            isPlaying: LIVE_VIDEO_CONFIG.isPlaying,
        };
        this.hlsRef = React.createRef();
        this.source = '';
        this.hlsTimer = null;
        this.hls = null;
        this.runningCounterTime = new Date().getTime();
    }

    componentDidMount() {
        if (isDashcamAuthenticated()) this.connectMedia();
    }

    componentWillUnmount() {
        this.destroyVideoHls();
    }

    connectMedia = () => {
        getDeviceMediaTokenData(this.props.imei, (error, data) => {
            if (error) return this.props.errorNotification(error);
            if (!data || !data.mediaAddress || !data.mediaToken) return '';

            return this.initVideo({
                mediaAddress: data.mediaAddress || '',
                mediaToken: data.mediaToken || '',
            });
        });
    }

    clearHlsTimer = () => {
        if (this.hlsTimer) {
            clearInterval(this.hlsTimer);
            this.hlsTimer = null;
        }
    }

    initVideo = (data: Object) => {
        this.source = getMediaSourceUrl('hls', {
            mediaAddress: data.mediaAddress,
            mediaToken: data.mediaToken,
            imei: this.props.imei,
            cameraId: this.props.cameraId,
        });

        if (!this.state.isPlaying) return;

        if (LIVE_VIDEO_CONFIG.isPlaying && this.getVideoRef()) {
            this.getVideoRef().startTimer();
        }
        this.createVideoHls();
    }

    getVideoRef = () => this.hlsRef.current;

    getVideoElement = () => this.getVideoRef() && this.getVideoRef().getVideoElement();

    createVideoHls = () => {
        this.destroyVideoHls();
        if (!this.source) return;
        const video = this.getVideoElement();

        if (HLS.isSupported() && video) {
            this.hls = new HLS(HLS_CONFIG);
            this.runningCounterTime = new Date().getTime();
            this.hls.attachMedia(video);

            this.hls.on(HLS.Events.MEDIA_ATTACHED, () => {
                console.log('video and hls.js are now bound together !');
                this.hls.loadSource(this.source);
            });

            this.hls.on(HLS.Events.MANIFEST_PARSED, () => {
                console.log('start playing video !');
                video.play();
            });

            this.hls.on(HLS.Events.MANIFEST_LOADED, () => {
                if (LIVE_VIDEO_CONFIG.isPlaying && this.getVideoRef()) {
                    this.getVideoRef().startTimer();
                }
            });

            this.hls.on(HLS.Events.ERROR, (event, data) => {
                console.log(event, data);
            });

            this.hls.on(HLS.Events.BUFFER_APPENDED, (event, data) => {
                this.runningCounterTime = new Date().getTime();
                console.log('BUFFER_APPENDED', event, data);
            });

            this.hlsTimer = setInterval(this.hlsRestartTimer, 1000);

            this.hls.loadSource(this.source);
        } else if (video && video.canPlayType('application/vnd.apple.mpegurl')) {
            verifyHlsSource({ url: this.source }).then((response) => {
                if (response.status === 200) {
                    video.src = this.source;
                    video.addEventListener('canplay', () => setTimeout(() => video.play()));
                } else {
                    this.props.errorNotification(response.message);
                }
            });
        }
    }

    hlsRestartTimer = () => {
        const currentTime = new Date().getTime();

        if (currentTime - this.runningCounterTime > 10000) {
            this.clearHlsTimer();
            this.destroyVideoHls();
            this.restartHlsVideo();
            this.runningCounterTime = currentTime;
        }
    }

    resetVideo = () => {
        const videoElement = this.getVideoElement();

        if (videoElement) {
            videoElement.pause();
            videoElement.src = '';
            videoElement.load();
        }
    }

    restartHlsVideo = () => {
        setTimeout(() => {
            this.createVideoHls();
        }, 3000);
    }

    destroyVideoHls = () => {
        this.clearHlsTimer();
        const video = this.getVideoElement();

        if (this.hls) {
            this.hls.destroy();
        } else if (video) {
            video.pause();
            video.src = null;
        }
    }

    // future purpose
    // eslint-disable-next-line no-unused-vars
    playPause = (isPlaying: boolean, isAutoPaused: boolean = false) => {
        if (isPlaying) {
            this.destroyVideoHls();
        } else this.createVideoHls();
        this.setState({ isPlaying: !isPlaying });
    }

    handleTypeChange = (type: string) => {
        if (type !== 'hls') this.props.handleTypeChange(type);
    }

    render() {
        return (
            <VideoPlayer
                playPause={isPlaying => this.playPause(isPlaying)}
                isPlaying={this.state.isPlaying}
                ref={this.hlsRef}
                handleTypeChange={type => this.handleTypeChange(type)}
                type="hls"
            />
        );
    }
}

export default Hls;
