/* @flow */
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Radio, Chip, Divider, Table, TableBody, IconButton, Tooltip, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import AsyncSelectWrapper from '../AsyncSelectWrapper';
import AssetCriteriaSearchList from './AssetCriteriaSearchList';
import ListTableRow from '../ListComponent/ListTableRow';
import { getAssetGroups, getAssetTags, getFVSAssets } from '../../../containers/Maintenance/helper-classes/common-services';
import AppLoader from '../../AppLoader';
import styles from './AssetCriteriaStyles';
import dropdownNested from '../../../assets/icons/dropdown-nested.svg';

const themeStyles = () => (styles);
type Props = {
    assetCriteria: { assets: Array<any>, groups: Array<any>, tags: Array<{}>, type: string },
    onAssetCriteriaChanged: Function,
    detailsOnlyMode: boolean,
    assetFilter?: any,
    isGrailsAPIReq?: boolean,
    isAlert?: boolean,
    hideAllAssetsType: boolean,
    hideTag: boolean,
};

type State = {
    selectedTab: string,
    assetCriteria: { assets: Array<any>, groups: Array<any>, tags: Array<{}>, type: string },
    expandedGroups: Array<any>,
    openDialog: boolean,
    assetLoading: boolean,
};

const AllAssetsType = 'all-assets';
const initAssetCriteria = {
    assets: [], groups: [], tags: [], type: 'groups-tags',
};

class AssetCriteria extends React.Component<Props, State> {
    static defaultProps: Object;
    constructor(props) {
        super(props);
        const criteria = Object.keys(props.assetCriteria).length > 0 ?
            props.assetCriteria : { ...initAssetCriteria };

        this.state = {
            assetCriteria: criteria,
            selectedTab: criteria.type || AllAssetsType,
            expandedGroups: [],
            openDialog: false,
            assetLoading: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps:Object) {
        const criteria = Object.keys(nextProps.assetCriteria).length > 0 ?
            nextProps.assetCriteria : { ...initAssetCriteria };
        this.resolveAssetCriteriaDetail(criteria);
    }

    resolveAssetCriteriaDetail = (criteria) => {
        const detailedCriteria = criteria;
        if (criteria.assets.length > 0) {
            const assetIds = detailedCriteria.assets.map(asset => asset.assetId);
            const detailedAssets = getFVSAssets(assetIds);
            if (detailedAssets instanceof Promise) {
                detailedAssets.then((data) => {
                    if (data instanceof Array) {
                        data.forEach((asset) => {
                            const index = detailedCriteria.assets.map(e => e.assetId)
                                .indexOf(asset.id);
                            detailedCriteria.assets[index] = asset;
                        });

                        this.setState({
                            assetCriteria: detailedCriteria,
                            selectedTab: detailedCriteria.type || AllAssetsType,
                            expandedGroups: [],
                        });
                    }
                });
            } else {
                detailedAssets.forEach((asset) => {
                    const index = detailedCriteria.assets.map(e => e.assetId)
                        .indexOf(asset.id);
                    detailedCriteria.assets[index] = asset;
                });

                this.setState({
                    assetCriteria: detailedCriteria,
                    selectedTab: detailedCriteria.type || AllAssetsType,
                    expandedGroups: [],
                });
            }
        } else {
            this.setState({
                assetCriteria: criteria,
                selectedTab: criteria.type || AllAssetsType,
                expandedGroups: [],
            });
        }
    }

    getAssetCriteria = () => {
        const { assetCriteria, selectedTab } = this.state;
        assetCriteria.type = selectedTab;
        return assetCriteria;
    }

    onAssetCriteriaChanged = () => {
        const { onAssetCriteriaChanged } = this.props;
        if (onAssetCriteriaChanged) onAssetCriteriaChanged(this.getAssetCriteria());
    }

    labelForType = type => (type === 'groups' ? 'name' : 'tag')

    isGroupExpanded = group => this.state.expandedGroups.some(g => g.id === group.id);

    expandGroup = (group, expand) => {
        let { expandedGroups } = this.state;
        if (expand) expandedGroups.push(group);
        else {
            expandedGroups = expandedGroups.filter(g => g.id !== group.id);
        }
        this.setState({ expandedGroups: expandedGroups.length > 0 ? expandedGroups : [] });
    }

    loadOptions = (type, inputValue, label) =>
        new Promise((resolve, reject) => {
            const loadCriteria = type === 'groups' ? getAssetGroups : getAssetTags;

            loadCriteria().then((response) => {
                if (response && response.data) {
                    let optionData = response.data.map(datum => ({
                        value: datum.id,
                        label: datum[label],
                        data: datum,
                    }));

                    if (inputValue) {
                        optionData = optionData.filter(option => (
                            option.label &&
                            option.label.toLowerCase().includes(inputValue.toLowerCase())
                        ));
                    }
                    this.optionsLoaded(optionData);
                    resolve(optionData);
                } else reject();
            });
        });

    selectTab = (e) => {
        const resetAssetCriteria = {
            assets: [], groups: [], tags: [], type: e.id,
        };
        this.setState({
            assetCriteria: { ...resetAssetCriteria },
            selectedTab: e.id,
        }, () => { this.onAssetCriteriaChanged(); });
    }

    renderRadio = (section, name) => {
        const checked = this.state.selectedTab === section;
        return (
            <label htmlFor={section}>
                <Radio
                    checked={checked}
                    value={name}
                    id={section}
                    style={checked ? styles.radio : styles.gray}
                />
                {name}
            </label>
        );
    }

    handleDelete = (section, id) => {
        const { assetCriteria } = this.state;
        assetCriteria[section] = assetCriteria[section].filter(item => item.id !== id);
        this.setState({ assetCriteria }, () => { this.onAssetCriteriaChanged(); });
    }

    onCriteriaSelected = (type, selected) => {
        const { assetCriteria } = this.state;
        if (assetCriteria[type].filter(item => item.id === selected.data.id).length === 0) {
            assetCriteria[type].push(selected.data);
            this.setState({ assetCriteria }, () => { this.onAssetCriteriaChanged(); });
        }
    }

    optionsLoaded = (optionData) => {
        const { assetCriteria } = this.state;
        if (assetCriteria.groups === null ||
            assetCriteria.groups.length < 1) {
            return;
        }
        assetCriteria.groups.forEach((item) => {
            if (item.name !== undefined) {
                return;
            }
            optionData.some((curGroup) => {
                if (String(item.id) === String(curGroup.data.id)) {
                    /* eslint no-param-reassign:
                    ["error", { "props": true, "ignorePropertyModificationsFor": ["item"] }] */
                    item.name = `${curGroup.data.name}`;
                    item.label = curGroup.data.name;
                    item.data = curGroup.data;
                    return true;
                }
                return false;
            });
        });
        this.setState({
            assetCriteria,
        });
    }

    renderSelect = (type, title, disableChips) => {
        const typeLabel = this.labelForType(type);
        return (
            <AsyncSelectWrapper
                key={`${type}_${disableChips ? 1 : 0}`}
                handleDelete={item => this.handleDelete(type, item.data.id)}
                selectedOptions={this.state.assetCriteria[type].map(item => ({
                    value: item.id,
                    label: item[typeLabel],
                    data: item,
                }))}
                loadOptions={inputValue => this.loadOptions(type, inputValue, typeLabel)}
                onItemSelected={selected => this.onCriteriaSelected(type, selected)}
                title={title}
                disableChips={disableChips}
                disablePaging
                styles={disableChips ? { container: styles.selectInternalContainer } : { select: { width: '20%' } }}
                selectStyles={{
                    control: () => ({
                        display: 'flex',
                        border: 'none',
                        boxShadow: 'none !important',
                    }),
                    option: (s, { data }) => {
                        const obj = data.data || {};
                        let newStyle = { ...s };
                        if (obj.nestLevel) {
                            newStyle = {
                                ...newStyle,
                                marginLeft: 8 + (obj.nestLevel * 16),
                            };
                        }
                        if (obj.childNodes && obj.childNodes.length > 0) {
                            newStyle = {
                                ...newStyle,
                                marginLeft: (newStyle.marginLeft || 0) + 6,
                                paddingLeft: 18,
                                backgroundImage: `url(${dropdownNested})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'left center',
                            };
                        }
                        return newStyle;
                    },
                }}
                menuPlacement="bottom"
            />
        );
    }

    resetSelectedAssets = () => (this.getSelectedAssetsSection().length > 0 ?
        this.setState({ openDialog: true }) : this.setState({ openDialog: false }));

    selectAssets = (assetsToAdd) => {
        const { assetCriteria } = this.state;
        const existingAssets = assetCriteria.assets.map(asset => asset.assetId || asset.id);
        assetsToAdd.forEach((assetToAdd) => {
            const filter = existingAssets.filter(id =>
                id === (assetToAdd.assetId || assetToAdd.id));
            if (filter.length === 0) assetCriteria.assets.push(assetToAdd);
        });
        setTimeout(() => {
            this.setState({ assetLoading: false, assetCriteria }, () => {
                this.onAssetCriteriaChanged();
            });
        }, 100);
    }

    onRemoveAssets = (assets) => {
        const { assetCriteria } = this.state;
        const assetsToRemove = assets.map(asset => asset.assetId || asset.id);
        assetCriteria.assets = assetCriteria.assets.filter(a => (
            !assetsToRemove.includes(a.assetId || a.id)
        ));
        setTimeout(() => {
            this.setState({ assetLoading: false, assetCriteria }, () => {
                this.onAssetCriteriaChanged();
            });
        }, 100);
    }

    getChevronForGroup = (group) => {
        const expanded = this.isGroupExpanded(group);
        return (
            <Tooltip
                title={expanded ? 'Collapse' : 'Expand'}
                style={{ marginLeft: 12 }}
                disableFocusListener
                disableTouchListener
                interactive
            >
                <IconButton
                    aria-label={expanded ? 'Collapse' : 'Expand'}
                    style={styles.icon}
                    onClick={() => this.expandGroup(group, !expanded)}
                    size="large"
                >
                    {expanded ? <ExpandMore /> : <ChevronRight />}
                </IconButton>
            </Tooltip>
        );
    }

    getSelectedAssetsSection = () => {
        const { assetCriteria } = this.state;
        const notInGroup = { id: -1, name: 'Not in Group', deviceSerialNumber: undefined };
        const groups = [];
        const assetMap = {};
        if (assetCriteria && assetCriteria.assets.length > 0) {
            assetCriteria.assets.forEach((asset) => {
                const group = {
                    id: asset.assetGroupId || notInGroup.id,
                    name: asset.assetGroupName || notInGroup.name,
                    deviceSerialNumber: asset.deviceSerialNumber || notInGroup.deviceSerialNumber,
                };

                if (groups.filter(g => g.id === group.id).length === 0) {
                    groups.push(group);
                }

                if (!assetMap[group.id]) assetMap[group.id] = [];
                const selectedAsset = asset;
                selectedAsset.name = asset.assetName || asset.name;
                assetMap[group.id].push(selectedAsset);
            });
        }

        const groupSections = groups.map(group => (
            <div style={styles.selectedGroupContainer} key={`groupSelections_${group.id}`}>
                {group.name}
                {this.getChevronForGroup(group)}
                {this.isGroupExpanded(group) ? this.getAssetsTable(assetMap[group.id]) : null}
            </div>
        ));
        return groupSections;
    }

    getAssetsTable = assets => (
        <Table className={styles['results-table']}>
            <TableBody>
                {assets.map(row => (
                    <ListTableRow
                        data={row}
                        key={Math.random()}
                        listHeaders={[
                            { name: 'Asset', show: true },
                            { serialNumber: 'Serial Number', show: row.serialNumber },
                            { deviceSerialNumber: 'Serial Number', show: row.deviceSerialNumber },
                        ]}
                        ischeckBox
                        handleClick={() => this.onRemoveAssets([row])}
                        handleDoubleClick={() => {}}
                        isSelected
                        index={assets.length || 0}
                        userPermission="Modify"
                        selectSingleRow={() => {}}
                    />
                ))}
            </TableBody>
        </Table>
    );

    renderCustomCriteriaSection = () => {
        const { assetCriteria } = this.state;
        const chips = [];
        ['groups', 'tags'].forEach((type) => {
            assetCriteria[type].forEach((item) => {
                chips.push(<Chip
                    style={{ margin: 8 }}
                    key={item.id}
                    label={item[this.labelForType(type)]}
                    onDelete={() => this.handleDelete(type, item.id)}
                />);
            });
        });
        return (
            <div >
                <div style={styles.customSelectContainer}>
                    {this.renderSelect('groups', 'Filter by Groups', true)}
                    {this.renderSelect('tags', 'Filter by Tags', true)}
                </div>
                <Divider />
                {chips.length > 0 &&
                    <div style={styles.chipContainer}>
                        {chips}
                        <Divider />
                    </div>
                }
                <div style={styles.selectedAssetsContainer}>
                    <div style={styles.column}>
                        <AssetCriteriaSearchList
                            searchGroups={assetCriteria.groups}
                            searchTags={assetCriteria.tags}
                            selectedAssets={assetCriteria.assets}
                            assetFilter={this.props.assetFilter}
                            isGrailsAPIReq={this.props.isGrailsAPIReq}
                            isAlert={this.props.isAlert}
                            onAssetsSelected={(selected, assets) => {
                                if (selected) {
                                    this.setState({ assetLoading: true }, () => {
                                        this.selectAssets(assets);
                                    });
                                } else {
                                    this.setState({ assetLoading: true }, () => {
                                        this.onRemoveAssets(assets);
                                    });
                                }
                            }}
                        />
                    </div>
                    <div style={styles.column}>
                        <div style={styles.assetsHeader}>
                            <span style={{ width: '100%' }}>Selected Assets</span>
                            <button style={styles.reset} onClick={this.resetSelectedAssets} >
                                RESET
                            </button>
                        </div>
                        {(!this.state.assetLoading) ?
                            <div style={this.getSelectedAssetsSection().length > 0 ?
                                styles.selectedWrapper : {
                                    ...styles.selectedWrapper,
                                    ...styles.assetSection,
                                }}
                            >
                                {this.getSelectedAssetsSection().length > 0 ?
                                    this.getSelectedAssetsSection() :
                                    <span style={styles.assetContainer}>
                                        No Assets Data
                                    </span>}
                            </div> : this.getLoadingIndicator()
                        }
                    </div>
                </div>
            </div>
        );
    }

    renderDetailSection = (section, showAll) => {
        let itemEls = 'All';
        if (!showAll) {
            const items = this.props.assetCriteria[section];
            itemEls = !items ? [] : items.map(item => (
                <Chip
                    style={{ margin: 8 }}
                    key={`${section}_${item}`}
                    label={item}
                    deletable="false"
                />
            ));
        }

        return (
            <div>
                <div style={styles.detailSection}>
                    {section}
                </div>
                <div style={styles.detailItemSection}>
                    {itemEls}
                </div>
            </div>
        );
    }

    getLoadingIndicator = () => <AppLoader loaderStyle={{ ...styles.loader, float: 'center' }} height={30} width={3} />

    handleClose = () => this.setState({ openDialog: false });

    confirmClearAll = () => {
        const { assetCriteria } = this.state;
        this.setState({ assetCriteria: { ...assetCriteria, assets: [] } }, () => {
            this.onAssetCriteriaChanged();
        });
        this.setState({ openDialog: false });
    }

    render() {
        if (this.props.detailsOnlyMode) {
            const { tags, assets, groups } = this.props.assetCriteria;
            const hasTags = (tags && tags.length > 0);
            const hasAssets = (assets && assets.length > 0);
            const hasGroups = (groups && groups.length > 0);
            const showAll = (!hasTags && !hasAssets && !hasGroups);

            return (
                <div style={{ overflow: 'scroll', height: 'inherit' }}>
                    {this.renderDetailSection('groups', showAll)}
                    {this.renderDetailSection('assets', showAll)}
                    {this.renderDetailSection('tags', showAll)}
                </div>
            );
        }

        let section = null;
        if (this.state.selectedTab === 'groups-tags') {
            section = (
                <div style={{ width: '100%' }}>
                    <div>{this.renderSelect('groups', 'Select Groups')}</div>
                    {!this.props.hideTag && this.renderSelect('tags', 'Select Tags')}
                </div>
            );
        } else if (this.state.selectedTab === 'custom') {
            section = this.renderCustomCriteriaSection();
        }

        return (
            <div style={styles.container}>
                <div style={styles.header}>
                    <span style={styles.headerTitle}>Asset Criteria</span>
                    <ul style={styles.radioList} onChange={e => this.selectTab(e.target)}>
                        {!this.props.hideAllAssetsType && this.renderRadio(AllAssetsType, 'All Assets')}
                        {this.renderRadio('groups-tags', `${!this.props.hideTag ? 'Select Groups and/or Tags' : 'Select Groups'}`)}
                        {this.renderRadio('custom', 'Custom Select')}
                    </ul>
                </div>
                {section}
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{ fontSize: '16px' }}>
                            Are you sure you want to clear all assets?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ p: 1.25, m: 1 }}>
                        <Button onClick={this.handleClose}>No</Button>
                        <Button onClick={this.confirmClearAll} color="primary" variant="contained" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

AssetCriteria.defaultProps = {
    assetCriteria: { assets: [], groups: [], tags: [] },
    detailsOnlyMode: false,
    assetFilter: '',
    hideAllAssetsType: false,
    isGrailsAPIReq: false,
    isAlert: false,
    hideTag: false,
};

export default withStyles(themeStyles)(AssetCriteria);
