/* @flow */

import { FETCH_SCORECARDS_LIST, FETCH_SCORECARDS_LIST_SUCCESS, FETCH_SCORECARDS_LIST_ERROR, RESET_SCORECARDS_LIST } from './constants.scorecardsList';
import type { ScoreCardsListTypeAction } from './constants.scorecardsList';

export const fetchScoreCardsList = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filters: string,
): ScoreCardsListTypeAction => ({
    type: FETCH_SCORECARDS_LIST,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
        filters,
    },
});
export const fetchScoreCardsListSuccess =
    (scorecards: any, totalScorecards: number): ScoreCardsListTypeAction => ({
        type: FETCH_SCORECARDS_LIST_SUCCESS,
        payload: { scorecards, totalScorecards },
    });

export const fetchscorecardsListError = (error: string): ScoreCardsListTypeAction => ({
    type: FETCH_SCORECARDS_LIST_ERROR,
    payload: { error },
});

export const resetTable =
    (): ScoreCardsListTypeAction => ({
        type: RESET_SCORECARDS_LIST,
    });
