import React from 'react';
import { ScaleLoader, RingLoader, ClipLoader, BarLoader } from 'react-spinners';
import customstyles from './AppLoader.module.scss';

type Props = {
    loading:? boolean,
    color:? string,
    type:? string,
    height :? number,
    width :? number,
    loaderStyle:? Object,
    radius :? number,
    margin :? string,
    size :? number,
    fullScreenStyle:? Object,
}

const AppLoader = (props: Props) => {
    const getLoader = () => {
        const {
            loading = true,
            color = '#007AFF',
            type = 'scale',
            height = 35,
            width = 4,
            radius = 2,
            margin = '2px',
            size = 20,
            loaderStyle = {},
            fullScreenStyle = {},
        } = props;
        switch (type) {
        case 'scale': return <ScaleLoader loaderStyle={loaderStyle} margin={margin} radius={radius} width={width} height={height} color={color} loading={loading} />;
        case 'ring': return <RingLoader color={color} width={width} height={height} loading={loading} />;
        case 'clip': return <ClipLoader loaderStyle={loaderStyle} color={color} loading={loading} size={size} />;
        case 'bar': return <BarLoader loaderStyle={loaderStyle} margin={margin} radius={radius} width={width} height={height} color={color} loading={loading} />;
        case 'fullScreen': return (
            <div className={customstyles.customStyle} style={fullScreenStyle}>
                <ScaleLoader
                    loaderStyle={loaderStyle}
                    margin={margin}
                    className={customstyles.customStyle1}
                    radius={radius}
                    width={width}
                    height={height}
                    color="#007AFF"
                    loading={loading}
                />
            </div>
        );
        default:
            return (<ScaleLoader
                color={color}
                width={width}
                radius={radius}
                height={height}
                loading={loading}
            />);
        }
    };

    return (
        <div id="sweet-loading" className={customstyles['sweet-loading']}>
            {getLoader()}
        </div>
    );
};

export default AppLoader;
