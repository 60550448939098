/* @flow */
export const LOGIN_SUCCESS: string = 'USERS_LOGIN_SUCCESS';
export const USER_LOGIN_SUCCESS: string = 'LOGIN_SUCCESS';

export const LOGIN_ERROR: string = 'USERS_LOGIN_ERROR';
export const LOGOUT: string = 'USERS_LOGOUT';
export const FETCH_LOGGED_IN_USER: string = 'FETCH_LOGGED_IN_USER';
export const FETCH_LOGGED_IN_USER_SUCCESS: string = 'FETCH_LOGGED_IN_USER_SUCCESS';
export const FETCH_LOGGED_IN_USER_ERROR: string = 'FETCH_LOGGED_IN_USER_ERROR';
export const LOGIN_FAIL: string = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS: string = 'LOGOUT_SUCCESS';
export const SCREEN_SIGN_IN: string = 'SCREEN_SIGN_IN';
export const UPDATE_WARNING_MESSAGE: string = 'UPDATE_WARNING_MESSAGE';

export const GRAILS_SIGIN: string = 'GRAILS_SIGIN';
export const GRAILS_SIGIN_ACCOUNT: string = 'GRAILS_SIGIN_ACCOUNT';
export const GRAILS_GET_USERSETTINGS: string = 'GRAILS_GET_USERSETTINGS';

export const GRAILS_SSO_SIGNIN: string = 'GRAILS_SSO_SIGNIN';
export const GRAILS_SSO_SIGNIN_ERROR: string = 'GRAILS_SSO_SIGNIN_ERROR';

export const AUTH_SERVICE_LOGIN_HANDLER: string = 'AUTH_SERVICE_LOGIN_HANDLER';
export const AUTH_SERVICE_LOGIN_ERROR_HANDLER: string = 'AUTH_SERVICE_LOGIN_ERROR_HANDLER';

export const SPIREON_COOKIE_DOMAIN: string = '.spireon.com';
export const PRIVATE_LABEL_COOKIE_DOMAIN: string = '.gpsservicenetwork.com';
export const NSPIRE_USER_TOKEN_COOKIE_NAME = 'X-Nspire-UserToken';

export const ACCOUNT_SUSPENDED_LOGIN_ERROR_MSG = 'Your account has been suspended.';
export const UNABLE_TO_LOGIN = 'Unable to log in with provided credentials.';
export const RESET_PASSWORD = 'It\'s time to change your password.';
export const SSAS_URL_PARAMS = '/ssas?accepted=false&canAccept=true&platformPassthrough=true';
export const UNABLE_TO_LOGIN_SSA_EXPIRED = 'User is not eligible to log in, ssa expired.';
