/* @flow */
import React, { Component } from 'react';
import { reduxConnect } from '../../../hoc';
import * as actions from './actions.dispatchAction';
import MiniDrawer from '../../../components/SideMenu/SideMenuDrawer';
import { getParamValue } from '../../../helper-classes/utility-functions';
import AppLoader from '../../../components/AppLoader';
import DispatchActionDetails from '../../../components/AssetDetails/DispatchAction/DispatchActionDetails';

export type Props = {
    fetchDispatchAssetsDetails: Function,
    assetsDetails: Object,
    isLoader: boolean,
    history: {
        push: Function,
    },
    sendDirection: Function,
    successMsg: string,
    error: string,
    isLoading: boolean,
    isEmailSend: boolean,
    isError: boolean,
    closeDispatchDialog: Function,
};

export type State = {
};

class DispatchAction extends Component<Props, State> {
    height: number;
    assetId: string;
    constructor(props: Props) {
        super(props);
        this.assetId = '';
        this.height = 0;
    }

    componentDidMount() {
        this.props.fetchDispatchAssetsDetails(getParamValue(this.props, 'id'));
        this.assetId = getParamValue(this.props, 'id');
    }

    componentDidUpdate() {
        const id = getParamValue(this.props, 'id');
        if (id && id !== this.assetId) {
            this.props.fetchDispatchAssetsDetails(getParamValue(this.props, 'id'));
            this.assetId = id;
        }
    }

    redirectTo = () => {
        window.history.back();
    }

    render() {
        const {
            assetsDetails,
            isLoader,
            successMsg,
            error,
            isLoading,
            isEmailSend,
            isError,
        } = this.props;

        return (
            <MiniDrawer
                redirectTo={this.props.history.push}
            >
                {isLoader ? <AppLoader type="fullScreen" /> : ''}
                <DispatchActionDetails
                    redirectTo={this.redirectTo}
                    assetsDetails={assetsDetails}
                    sendDirection={this.props.sendDirection}
                    successMsg={successMsg}
                    error={error}
                    isLoader={isLoader}
                    isLoading={isLoading}
                    isEmailSend={isEmailSend}
                    isError={isError}
                    closeDispatchDialog={this.props.closeDispatchDialog}
                />
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => state.dispatchAction;

export default reduxConnect(DispatchAction, actions, mapStateToProps);

