/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Typography } from '@mui/material';
import AppLoader from '../AppLoader';
import customStyles from './LandmarkDetails.module.scss';
import List from '../SharedComponents/List/List';
import AssetListItem from './AssetInLandmarkListItem';
import { getRefreshChip } from '../../util/map_utils';
import { isPairedAsset } from '../../helper-classes/utility-functions';

export type Props = {
    assetLoader: boolean,
    assetInLandmark: Object,
    pageChanged: Function,
    refreshAssetListData: Function,
    showAssetRefeshChip: boolean,
    redirectTo: Function,
    landmarkId: String,
};
type State = {
    totalNumberOfPage: number,
    page: number,
    totalCount: number,
    data: Array<{ id: string, name: string }>,
}

class AssetInLandmark extends Component<Props, State> {
    currentPage: number;
    constructor(props) {
        super(props);
        this.state = this.getInitState();
        this.currentPage = 0;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.assetInLandmark && nextProps.assetInLandmark.data) {
            const response = nextProps.assetInLandmark;
            const { data } = nextProps.assetInLandmark;
            if (data && data.length > 0) {
                let newData;
                if (this.state.data && this.state.data.length > 0) {
                    newData = this.state.data;
                    let stateId = '';
                    if (newData.length > 0) {
                        stateId = newData[newData.length - 1].id;
                    }
                    if (data.length > 0
                    && stateId !== data[data.length - 1].id) {
                        data.forEach((d) => {
                            newData.push(d);
                        });
                        this.setState({
                            data: newData,
                            page: this.currentPage,
                        });
                        if (this.state.totalNumberOfPage <= 0) {
                            this.calculatedPageCount(response);
                        }
                    }
                } else if (this.props.assetLoader && !nextProps.assetLoader) {
                    this.setState({
                        data,
                        page: this.currentPage,
                    });
                    if (this.state.totalNumberOfPage <= 0) {
                        this.calculatedPageCount(response);
                    }
                }
            }
        }
    }

    getInitState = () => ({
        totalNumberOfPage: 0,
        page: 0,
        totalCount: 0,
        data: [],
    })

    calculatedPageCount = (res: any) => {
        if (res.total && res.total > 0) {
            const count = res.count || 10;
            const totalNumberOfPage = Math.ceil(res.total / count);
            this.setState({ totalNumberOfPage, totalCount: res.total });
        }
    };

    pageChanged = (page) => {
        this.currentPage = page;
        this.props.pageChanged({ currentPage: page });
    };

    getHeader = () => {
        const { totalCount, data } = this.state;
        return (
            <React.Fragment>
                <div className={customStyles['assets-in-landmark']}>
                    <div className={customStyles.header}>
                        <Typography variant="h5" className={customStyles['main-header']} component="h2">
                            Assets in Landmark
                        </Typography>
                        <Typography className={customStyles['asset-sub-header']} paragraph component="p">
                            Showing {data.length} of {totalCount}
                        </Typography>
                    </div>
                </div>
                <div className={customStyles['header-border']} />
            </React.Fragment>
        );
    };

    listItems = data => (
        <div
            onClick={() => {
                if (data && isPairedAsset(data)) {
                    this.props.redirectTo(`/home?assetId=${data.id}&back=true&zoom=true`, 'assetInLandmark');
                }
            }}
            role="link"
            tabIndex={0}
            onKeyDown={() => {}}
            key={data.id}
        >
            <AssetListItem
                asset={data}
                landmarkId={this.props.landmarkId}
            />
        </div>
    );

    getList = () => {
        const { assetLoader, landmarkId } = this.props;
        const { totalNumberOfPage, page, data } = this.state;
        let list = '';
        const isData = data && data.length > 0;
        if (isData) {
            list = (<List
                data={data}
                height={280}
                currentPage={page}
                totalNumberofPage={totalNumberOfPage}
                pagination={{ isPagination: true, pageChanged: this.pageChanged }}
                getListItem={this.listItems}
                showMessage={false}
                landmarkId={landmarkId}
            />);
        }
        return (
            <React.Fragment>
                {list}
                { assetLoader ? <div className={customStyles.detailLoader}><AppLoader /></div> : ''}
                {!isData && !assetLoader && landmarkId ? <div className={customStyles['no-assets-in-landmark']}>There are currently no assets in this landmark.</div> : ''}
            </React.Fragment>
        );
    }

    refreshAssetListData = (loadNewData) => {
        if (loadNewData) {
            this.setState({ ...this.getInitState() });
            this.currentPage = 0;
        }
        this.props.refreshAssetListData(loadNewData);
    }

    render() {
        return (
            <React.Fragment>
                {this.getHeader()}
                {this.props.showAssetRefeshChip
                    && getRefreshChip(this.refreshAssetListData)}
                {this.getList()}
            </React.Fragment>
        );
    }
}

export default withStyles({}, { withTheme: true })(AssetInLandmark);
