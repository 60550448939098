/* @flow */
export const FETCH_USERROLE: string = 'FETCH_USERROLE';
export const FETCH_USERROLE_SUCCESS: string = 'FETCH_USERROLE_SUCCESS';
export const FETCH_USERROLE_ERROR: string = 'FETCH_USERROLE_ERROR';
export const FETCH_USERROLES: string = 'FETCH_USERROLES';
export const FETCH_USERROLES_SUCCESS: string = 'FETCH_USERROLES_SUCCESS';
export const FETCH_USERROLES_ERROR: string = 'FETCH_USERROLES_ERROR';
export const ADD_USERROLE: string = 'ADD_USERROLE';
export const ADD_USERROLE_SUCCESS: string = 'ADD_USERROLE_SUCCESS';
export const ADD_USERROLE_ERROR: string = 'ADD_USERROLE_ERROR';
export const EDIT_USERROLE: string = 'EDIT_USERROLE';
export const EDIT_USERROLE_SUCCESS: string = 'EDIT_USERROLE_SUCCESS';
export const EDIT_USERROLE_ERROR: string = 'EDIT_USERROLE_ERROR';
export const DELETE_USERROLE: string = 'DELETE_USERROLE';
export const DELETE_USERROLE_SUCCESS: string = 'DELETE_USERROLE_SUCCESS';
export const DELETE_USERROLE_ERROR: string = 'DELETE_USERROLE_ERROR';
export const CLOSE_USERROLE_DIALOG: string = 'CLOSE_USERROLE_DIALOG';
export const UPDATE_USERROLE_LOADER: string = 'UPDATE_USERROLE_LOADER';

const supportedRoleValues: Array<Object> = [
    {
        id: 'view',
        value: 'View',
        title: 'View',
    },
    {
        id: 'modify',
        value: 'Modify',
        title: 'Modify',
    },
];

export const USER_ROLE_ARRAY: Array<Object> = [
    {
        roleCode: 'localFleet-map',
        roleStateName: 'roleMapEnabled',
        roleValueStateName: 'roleMapSelectedVal',
        title: 'Map & Fleet Tab (Asset and Landmark Section)',
        values: supportedRoleValues,
    },
    {
        roleCode: 'localFleet-maintenance',
        roleStateName: 'roleMaintenanceEnabled',
        roleValueStateName: 'roleMaintenanceSelectedVal',
        title: 'Maintenance Section',
        values: supportedRoleValues,
    },
    {
        roleCode: 'localFleet-reports',
        roleStateName: 'roleReportsEnabled',
        roleValueStateName: 'roleReportsSelectedVal',
        title: 'Reports Section',
        values: supportedRoleValues.map(i => ((i.id === 'view') ? {
            id: 'view',
            value: 'View',
            title: 'View',
            disabled: true,
        } : i)),
    },
    {
        roleCode: 'localFleet-alerts',
        roleStateName: 'roleAlertsEnabled',
        roleValueStateName: 'roleAlertsSelectedVal',
        title: 'Alerts Section',
        values: supportedRoleValues,
    },
    {
        roleCode: 'localFleet-eld',
        roleStateName: 'roleEldEnabled',
        roleValueStateName: 'roleEldSelectedVal',
        title: 'Compliance Section',
        featureCheck: 'fleetEld', // use this key if you want to render this role using nspire feature
        values: supportedRoleValues.map(i => ((i.id === 'modify') ? {
            id: 'manager',
            value: 'Manager',
            title: 'Modify',
        } : {
            id: 'view',
            value: 'View',
            title: 'View',
        })),
    },
    {
        roleCode: 'localFleet-admin',
        roleStateName: 'roleAdminEnabled',
        roleValueStateName: 'roleAdminSelectedVal',
        title: 'Admin & Fleet Tab (Asset, Landmark Group & Driver Section)',
        values: supportedRoleValues,
    },
    {
        roleCode: 'localFleet-dashboard',
        roleStateName: 'roleDashboardEnabled',
        roleValueStateName: 'roleDashboardSelectedVal',
        title: 'Dashboard Section',
        values: supportedRoleValues,
    },
    {
        roleCode: 'localFleet-driver-behavior',
        roleStateName: 'roleDriverBehaviorEnabled',
        roleValueStateName: 'roleDriverBehaviorSelectedVal',
        title: 'Driver Behavior Section',
        values: supportedRoleValues,
    },
    {
        roleCode: 'localFleet-messaging',
        roleStateName: 'roleMessagingEnabled',
        roleValueStateName: 'roleMessagingSelectedVal',
        title: 'Messaging Section',
        disabled: true, // use this key if you don't want this role to be visible in UI
        values: supportedRoleValues,
    },
    {
        roleCode: 'localFleet-dashcam',
        roleStateName: 'roleDashcamEnabled',
        roleValueStateName: 'roleDashcamSelectedVal',
        title: 'CamCoach Section',
        featureCheck: 'fleetDashcam, fleetLightMetricsDashcam',
        values: supportedRoleValues.map(i => ((i.id === 'view') ? {
            id: 'view',
            value: 'View',
            title: 'View',
            disabled: true,
        } : i)),
    },
    {
        roleCode: 'localFleet-install-app',
        roleStateName: 'roleInstallAppEnabled',
        roleValueStateName: 'roleInstallAppSelectedVal',
        title: 'Install App',
        values: supportedRoleValues,
    },
];

export type UserRoleTypeAction = {
    type: string;
    payload?: {};
}
