/* @flow */

export const FETCH_USER_ACCESS_LIST: string = 'FETCH_USER_ACCESS_LIST';
export const FETCH_USER_ACCESS_LIST_SUCCESS: string = 'FETCH_USER_ACCESS_LIST_SUCCESS';
export const FETCH_USER_ACCESS_LIST_ERROR: string = 'FETCH_USER_ACCESS_LIST_ERROR';
export const RESET_USER_ACCESS_LIST: string = 'RESET_USER_ACCESS_LIST';
export const USER_ACCESS_LIST_PREF_KEY: string = 'fleetLocate.userAccessList';
export type UserAccessListTypeAction = {
    type: string;
    payload?: Object;
}
