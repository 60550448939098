/* @flow */
import React from 'react';
import { Paper, Grid, Button, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import customStyles from './AddEditComponent.module.scss';
import backArrow from '../../../assets/icons/back-arrow-white.svg';
import { getPermissionValue } from '../../../helper-classes/utility-functions';

export type Props = {
    saveClick: Function,
    deleteClick: Function,
    nextClick: Function,
    backClick: Function,
    showingButtons: Array<string>,
    cancelClick: Function,
    show: boolean,
    classes: Object,
    children: typeof React,
    windowTitle: string,
    saveDisabled: boolean,
    deleteDisabled: boolean,
    nextDisabled: boolean,
    backDisabled: boolean,
    footNoteText: string,

};

const styles = () => ({
    root: { margin: 15, marginLeft: 10, zIndex: 1 },
    top: { flexGrow: 1, height: '100%' },
});

class AddEditComponent extends React.PureComponent<Props> {
    static defaultProps = {
        saveClick: null,
        deleteClick: null,
        cancelClick: null,
        nextClick: null,
        backClick: null,
        show: null,
        children: undefined,
        windowTitle: '',
        saveDisabled: false,
        deleteDisabled: false,
        nextDisabled: false,
        backDisabled: false,
        showingButtons: [],
        footNoteText: null,
    };

    render() {
        const { classes, show, showingButtons } = this.props;
        return (
            <Paper className={show ? classes.root : [classes.root, customStyles['display-none']].join(' ')} id="add-edit-container">
                <Grid container className={classes.top}>
                    <Grid item xs={12} className={customStyles.header}>
                        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                            <Grid item xs>
                                <IconButton
                                    aria-label="Back"
                                    onClick={() => this.props.cancelClick()}
                                    style={{ margin: '-6px 0 0 14px' }}
                                    size="large"
                                >
                                    <img src={backArrow} alt="back" />
                                </IconButton>
                                <p style={{ paddingLeft: 10, display: 'inline-flex' }}>{show ? this.props.windowTitle : ' '}</p>
                            </Grid>
                            {showingButtons.includes('delete') &&
                                <Grid item xs={3} style={{ textAlign: 'end', marginRight: 12 }}>
                                    <Button
                                        color="error"
                                        variant="contained"
                                        onClick={() => this.props.deleteClick()}
                                        disabled={this.props.deleteDisabled || getPermissionValue('Maintenance') !== 'Modify'}
                                    >
                                        DELETE
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>{this.props.children}</Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <Grid container justifyContent="space-around" alignItems="center" className={customStyles.footer} spacing={1}>
                                <Grid item xs={6}>
                                    { this.props.footNoteText &&
                                        <span className={customStyles['foot-note-text']}>{this.props.footNoteText}</span>
                                    }
                                </Grid>
                                <Grid container justifyContent="flex-end" spacing={1} style={{ minWidth: 346 }}>
                                    { showingButtons.includes('cancel') &&
                                        <Grid item>
                                            <Button variant="contained" onClick={() => this.props.cancelClick()}>CANCEL</Button>
                                        </Grid>
                                    }
                                    { showingButtons.includes('back') &&
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => this.props.backClick()}
                                                disabled={this.props.backDisabled}
                                            >
                                                BACK
                                            </Button>
                                        </Grid>
                                    }
                                    { showingButtons.includes('next') &&
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => this.props.nextClick()}
                                                disabled={this.props.nextDisabled}
                                            >
                                                NEXT
                                            </Button>
                                        </Grid>
                                    }
                                    { showingButtons.includes('save') &&
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => this.props.saveClick()}
                                                disabled={this.props.saveDisabled || getPermissionValue('Maintenance') !== 'Modify'}
                                            >
                                                SAVE
                                            </Button>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

AddEditComponent.propTypes = {
    saveClick: Function,
    deleteClick: Function,
    cancelClick: Function,
    nextClick: Function,
    backClick: Function,
    show: PropTypes.bool,
    children: PropTypes.element,
    windowTitle: PropTypes.string,
    saveDisabled: PropTypes.bool,
    deleteDisabled: PropTypes.bool,
    nextDisabled: PropTypes.bool,
    backDisabled: PropTypes.bool,
    showingButtons: PropTypes.arrayOf(PropTypes.string),
    footNoteText: PropTypes.string,
};

export default withStyles(styles)(AddEditComponent);
