import React from 'react';
import TextField from '@mui/material/TextField';
import styles from '../../Steppers/AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';

type Props = {
    formData: {
        config: {
            stopDays: Number,
            stopHours: Number,
            stopMinutes: Number,
        },
    },
    handleTextboxChange: Function,
};

class StopAlert extends React.Component<Props> {
    state = {};

    render() {
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    <div className={styles['step-rightside']}>
                        <form noValidate autoComplete="off">
                            <div>
                                <div>{ALERT_DETAILS.LABEL.STOP_EVENT}*</div>
                                <div>
                                    <TextField
                                        required
                                        margin="normal"
                                        className={styles['text-wid']}
                                        id="stopDays"
                                        name="stopDays"
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: ALERT_DETAILS.VALUE.STD_MIN,
                                                max: ALERT_DETAILS.VALUE.STD_MAX,
                                            },
                                        }}
                                        value={this.props.formData.config.stopDays}
                                        onChange={this.props.handleTextboxChange('stopDays')}
                                    />
                                    <span className={styles['unit-Name']}>{ALERT_DETAILS.LABEL.STOP_DAYS}</span>
                                </div>
                                <div>
                                    <TextField
                                        required
                                        margin="normal"
                                        className={styles['text-wid']}
                                        id="stopHours"
                                        name="stopHours"
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: ALERT_DETAILS.VALUE.STH_MIN,
                                                max: ALERT_DETAILS.VALUE.STH_MAX,
                                            },
                                        }}
                                        value={this.props.formData.config.stopHours}
                                        onChange={this.props.handleTextboxChange('stopHours')}
                                    />
                                    <span className={styles['unit-Name']}>{ALERT_DETAILS.LABEL.STOP_HOURS}</span>
                                </div>
                                <div>
                                    <TextField
                                        required
                                        margin="normal"
                                        className={styles['text-wid']}
                                        id="stopMinutes"
                                        name="stopMinutes"
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: ALERT_DETAILS.VALUE.STM_MIN,
                                                max: ALERT_DETAILS.VALUE.STM_MAX,
                                            },
                                        }}
                                        value={this.props.formData.config.stopMinutes}
                                        onChange={this.props.handleTextboxChange('stopMinutes')}
                                    />
                                    <span className={styles['unit-Name']}>{ALERT_DETAILS.LABEL.STOP_MINUTES}</span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default StopAlert;
