/* Re-usable component for searching a user */
/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import AppLoader from '../../AppLoader';
import { ALERT_RECIPIENT } from '../../../containers/Alerts/constants.alerts';

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;
    return (
        <MenuItem
            {...itemProps}
            key={suggestion.label}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {suggestion.label}
        </MenuItem>
    );
}
renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.number,
    index: PropTypes.number,
    itemProps: PropTypes.object,
    selectedItem: PropTypes.string,
    suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

const styles = {
    formHelperText: {
        color: 'red',
        width: '200px',
        lineHeight: '1em',
        marginTop: '8px',
        marginLeft: '0px',
    },
};

class IntegrationDownshift extends Component {
    renderInput(inputProps) {
        const { InputProps, ...other } = inputProps;

        return (
            <TextField
                InputProps={{
                    classes: {
                        root: 'classes.inputRoot',
                        input: 'classes.inputInput',
                    },
                    ...InputProps,
                }}
                {...other}
            />
        );
    }

    render() {
        const suggestionsTest = this.props.recipientList;
        function getSuggestions(value) {
            const inputValue = deburr(value.trim()).toLowerCase();
            const inputLength = inputValue.length;
            let count = 0;

            return inputLength === 0
                ? []
                : suggestionsTest.filter(suggestion => {
                      const keep =
                          count < 5 &&
                          suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

                      if (keep) {
                          count += 1;
                      }

                      return keep;
                  });
        }
        return (
            <div className="{classes.root}">
                <Downshift
                    id="downshift-simple"
                    inputValue={this.props.recipientInputValue}
                    onChange={this.props.onSelect}
                >
                    {({
                        getInputProps,
                        getItemProps,
                        getMenuProps,
                        highlightedIndex,
                        isOpen,
                        inputValue: inputValue2,
                        selectedItem,
                    }) => (
                        <div>
                            <div className="{classes.container}">
                                {this.renderInput({
                                    fullWidth: true,
                                    InputProps: getInputProps({
                                        placeholder: ALERT_RECIPIENT.LABEL.EMAIL_OR_PHONE,
                                        onChange: this.props.onTextChange,
                                        disabled: this.props.disabled,
                                        id: this.props.searchFieldId,
                                    }),
                                })}
                                <div {...getMenuProps()}>
                                    {this.props.initialPageLoad === false &&
                                    this.props.recipientInputValue.toString().length > 0 &&
                                    this.props.recipientList.length === 0 ? (
                                        <div>
                                            <FormHelperText sx={styles.formHelperText}>
                                                {!this.props.errors.name ?
                                                ALERT_RECIPIENT.LABEL.NO_RESULTS :
                                                this.props.errors.name}
                                            </FormHelperText>
                                        </div>
                                    ) : null}
                                    {this.props.errors && this.props.recipientInputValue.toString().length === 0 ? (
                                        <div>
                                            <FormHelperText sx={styles.formHelperText}>
                                                {this.props.errors.name}
                                            </FormHelperText>
                                        </div>
                                    ) : null}
                                    {isOpen ? (
                                        <Paper className="{classes.paper}" square>
                                            {getSuggestions(inputValue2).map((suggestion, index) =>
                                                renderSuggestion({
                                                    suggestion,
                                                    index,
                                                    itemProps: getItemProps({
                                                        item: suggestion.label,
                                                    }),
                                                    highlightedIndex,
                                                    selectedItem,
                                                }),
                                            )}
                                        </Paper>
                                    ) : null}
                                </div>
                            </div>
                            <div>
                                {this.props.searchIsLoading === true ? (
                                    <AppLoader
                                        type="clip"
                                        loaderStyle={{
                                            position: 'absolute',
                                            left: '100%',
                                            top: '0%',
                                        }}
                                    />
                                ) : null}
                            </div>
                        </div>
                    )}
                </Downshift>
            </div>
        );
    }
}

export default IntegrationDownshift;
