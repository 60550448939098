/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_LANDMARKGROUPS_LIST } from './constants.landmarkGroupsList';
import { fetchLandmarkGroupsListError, fetchLandmarkGroupsListSuccess } from './actions.landmarkGroupsList';
import config from '../../../constants/Config';
import { getUserToken } from '../../../helper-classes/utility-functions';

type ObservarblesTypes = {
    postJSON: Function,
    of: Function
}

export const getLandmarkGroups = (
    actions$: Function,
    store: Object,
    { postJSON, of }: ObservarblesTypes,
) =>
    actions$
        .ofType(FETCH_LANDMARKGROUPS_LIST)
        .distinctUntilChanged()
        .debounceTime(config.get('DEBOUNCE_TIME'))
        .mergeMap((action) => {
            const {
                order,
                orderBy,
                pageNumber,
                pageSize,
                filter = {},
            } = action.payload;
            const headers = {
                'X-Nspire-UserToken': getUserToken(),
                'Content-Type': 'application/json',
            };

            const data = {
                sortParam: order && orderBy ? [[orderBy, order]] : undefined,
                start: pageNumber * pageSize,
                limit: pageSize,
                type: 'parentChildList',
                searchParams: [''],
            };

            if (filter.search && filter.search.length > 0 && typeof filter.search === 'string') {
                data.type = 'preLimitSearch';
                data.searchParams = [filter.search.replace(/[\s]{2,}/gi, ' ').trim()];
            } else {
                delete data.searchParams;
            }

            return postJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/landmarkgroups`, data, headers)
                .map(result => fetchLandmarkGroupsListSuccess(
                    result.response.data,
                    result.response.total,
                ))
                .catch(error => of(fetchLandmarkGroupsListError(error)));
        });

export default combineEpics(getLandmarkGroups);
