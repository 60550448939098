/* @flow */
import React, { Component } from 'react';
import moment from 'moment';
import { FormControl, Grid, Typography, Button, InputLabel, TextField, Select, MenuItem } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { prepareRecording } from '../helper-classes/dashcamServices';
import { getDeviceMediaTokenData, getMediaSourceUrl, checkRecordingAvailibity, getCurrentBrowser } from '../../../util/dashcam_util';
import { getAppUserSettings } from '../../../helper-classes/utility-functions';
import { RECORDING_DOWNLOAD_BEFORE_AFTER_TIME_SLOTS_IN_SEC, NORMAL_QUALITY_TEXT, HIGH_QUALITY_TEXT } from '../../../containers/DashCam/constants.dashcam';
import customStyles from './CameraRecordings.module.scss';
import AppLoader from './../../../components/AppLoader';
import TimeInputMask from '../../../components/SharedComponents/TimeInputMask';

export type Props = {
    showNotification: Function,
    intervals: Array<Object>,
    handleClose: Function,
    imei: string,
    selectedCameraId: number | typeof undefined,
    selectedCameraName: string,
    getStartTimeStamp: Function,
    selectedRecordingDate: Object,
    timeSelected: {
        start: number,
        startTimeStamp: number,
        timeToDisplay: string,
    },
};

export type State = {
    qualityLevel: string,
    timeSelected: string,
    startTimeStamp: number,
    secondsBefore: number,
    secondsAfter: number,
    videoDuration: number,
    downloadURL: string,
    showDownloadBtn: boolean,
    preparingDownload: boolean,
    isRecordingAvailable: boolean,
};

class DownloadRecordings extends Component<Props, State> {
    autoRefreshTimer: any;
    browser: string;

    constructor(props: Object) {
        super(props);
        this.state = this.initState();
        this.autoRefreshTimer = null;
        this.browser = getCurrentBrowser();
    }

    UNSAFE_componentWillMount() {
        ValidatorForm.addValidationRule('isEmpty', (value) => {
            if (typeof value === 'string' && value.replace(/\s/gi, '') === '') {
                return false;
            } else if (typeof value === 'number' && value === 0) {
                return false;
            }
            return true;
        });
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    getTzBasedStartTimeStamp = () => moment.unix(this.props.timeSelected.startTimeStamp).tz(getAppUserSettings('userTz')).format('HH:mm:ss');

    initState = () => ({
        qualityLevel: 'lq',
        timeSelected: this.getTzBasedStartTimeStamp(),
        startTimeStamp: this.props.timeSelected.startTimeStamp,
        secondsBefore: 0,
        secondsAfter: 5,
        videoDuration: 5,
        downloadURL: '',
        showDownloadBtn: false,
        preparingDownload: false,
        isRecordingAvailable: checkRecordingAvailibity(
            this.props.intervals, this.props.timeSelected.startTimeStamp,
            0, 5,
        ),
    });

    startAutoRefreshTimer = (
        recordingURL: string,
        mediaAddress: string, mediaToken: string, isFirst: boolean,
    ) => {
        this.autoRefreshTimer = setTimeout(
            () => prepareRecording(recordingURL).then((r) => {
                if (r.status === 200) {
                    // now get download URL
                    const downloadURL = getMediaSourceUrl('recordingDownload', {
                        mediaAddress,
                        mediaToken,
                        imei: this.props.imei,
                        cameraId: this.props.selectedCameraId,
                        startTime: this.state.startTimeStamp - this.state.secondsBefore,
                        videoDuration: this.state.videoDuration,
                        qualityLevel: this.state.qualityLevel,
                    });
                    this.clearTimer();
                    this.setState({ downloadURL, showDownloadBtn: true, preparingDownload: false });
                } else {
                    this.startAutoRefreshTimer(recordingURL, mediaAddress, mediaToken, false);
                }
            }),
            isFirst ? 0 : 5000,
        );
    };

    clearTimer = () => {
        if (this.autoRefreshTimer) clearTimeout(this.autoRefreshTimer);
    };

    prepareDownload = () => {
        this.setState({ preparingDownload: true });
        getDeviceMediaTokenData(this.props.imei, (error, response) => {
            if (!error && response && response.mediaAddress && response.mediaToken) {
                // prepare recording URL
                const recordingURL = getMediaSourceUrl('recording', {
                    mediaAddress: response.mediaAddress,
                    mediaToken: response.mediaToken,
                    imei: this.props.imei,
                    cameraId: this.props.selectedCameraId,
                    startTime: this.state.startTimeStamp - this.state.secondsBefore,
                    videoDuration: this.state.videoDuration,
                    qualityLevel: this.state.qualityLevel,
                });

                this.startAutoRefreshTimer(
                    recordingURL,
                    response.mediaAddress,
                    response.mediaToken,
                    true,
                );
            } else this.props.showNotification(error || '');
        });
    };

    handleChange = (key: string, value: string) => {
        this.setState({ [key]: value });
    }

    handleClose = () => {
        const nextState = this.initState();
        this.clearTimer();
        this.setState(nextState, () => this.props.handleClose());
    }

    handleTimeChange = (e: Object) => {
        this.clearTimer();
        const startTimeStamp = this.props.getStartTimeStamp(e.target.value);

        const isRecordingAvailable = checkRecordingAvailibity(
            this.props.intervals, startTimeStamp,
            this.state.secondsBefore, this.state.secondsAfter,
        );

        this.setState({
            timeSelected: e.target.value || this.getTzBasedStartTimeStamp(),
            startTimeStamp,
            preparingDownload: false,
            showDownloadBtn: false,
            isRecordingAvailable,
        });
    }

    handleTimeChangeIE = (e: Object) => {
        const { value } = e.target;
        const time = moment(value, 'hh:mm:ss aZ').format('HH:mm:ss');
        if (time !== 'Invalid date') {
            this.handleTimeChange({ target: { value: time } });
        } else {
            this.handleTimeChange({ target: { value: this.getTzBasedStartTimeStamp() } });
        }
    }

    handleDDLChange = (key: string, value: string) => {
        this.clearTimer();
        let { videoDuration, secondsBefore, secondsAfter } = this.state;

        if (key === 'secondsBefore') {
            secondsBefore = parseInt(value, 10);
            videoDuration = parseInt(secondsBefore, 10) + this.state.secondsAfter;
        } else if (key === 'secondsAfter') {
            secondsAfter = parseInt(value, 10);
            videoDuration = this.state.secondsBefore + parseInt(secondsAfter, 10);
        }

        const isRecordingAvailable = checkRecordingAvailibity(
            this.props.intervals, this.state.startTimeStamp,
            secondsBefore, secondsAfter,
        );

        this.setState({
            [key]: value,
            videoDuration,
            downloadURL: '',
            preparingDownload: false,
            showDownloadBtn: false,
            isRecordingAvailable,
        });
    }

    getPrepareButton = () => (this.state.preparingDownload ? (
        <Button
            color="primary"
            style={{
                width: 96,
                height: 37,
            }}
            disabled={!this.state.isRecordingAvailable}
        >
            <AppLoader height={25} />
        </Button>) :
        (
            <Button
                color="primary"
                type="submit"
                disabled={!this.state.isRecordingAvailable}
            >
                PREPARE
            </Button>))

    render() {
        const { isRecordingAvailable } = this.state;

        return (
            <Grid container spacing={3} className={customStyles.downloadRecordingPopOver} >
                <Grid item xs={12} className={customStyles.gridItem}>
                    <Typography variant="h6" color="inherit">
                        <span>Download Recording</span>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ValidatorForm autoComplete="off" onSubmit={this.prepareDownload}>
                        <FormControl component="fieldset" className={customStyles.fieldset}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} className={customStyles.gridItem}>
                                    <span className={customStyles.cameraName}>
                                        {this.props.selectedCameraName}
                                    </span>
                                    <span className={customStyles.selectedDate}>
                                        {this.props.selectedRecordingDate.format('MMMM DD, YYYY')}
                                    </span>
                                </Grid>
                                {this.browser !== 'ie' &&
                                <Grid item xs={12} className={customStyles.gridItem}>
                                    <TextField
                                        id="time-selected"
                                        label="Time"
                                        type="time"
                                        value={this.state.timeSelected}
                                        onChange={e => this.handleTimeChange(e)}
                                        className={customStyles.timePicker}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 10, // in seconds
                                        }}
                                    />
                                </Grid>
                                }
                                {this.browser === 'ie' &&
                                <Grid item xs={12} className={customStyles.gridItem}>
                                    <TextField
                                        label="Time"
                                        id="time-selected-ie"
                                        value={`${moment(
                                            this.state.timeSelected,
                                            'HH:mm:ss',
                                        ).format('hh:mm:ss A')}`}
                                        onChange={e => this.handleTimeChangeIE(e)}
                                        name="Time"
                                        InputProps={{
                                            inputComponent: TimeInputMask,
                                            endAdornment: <div />,
                                        }}
                                        className={customStyles.timePicker}
                                    />
                                </Grid>
                                }
                                <Grid item xs={12} className={customStyles.gridItem}>
                                    <FormControl className={customStyles.ddlContainer}>
                                        <InputLabel className={customStyles.inputLabel} htmlFor="age-simple">Quality</InputLabel>
                                        <Select
                                            value={this.state.qualityLevel}
                                            onChange={e => this.handleDDLChange('qualityLevel', e.target.value)}
                                            className={customStyles.ddl}
                                            inputProps={{
                                                name: 'Select Video Quality',
                                                id: 'dashcam-recording-video-quality',
                                            }}
                                        >
                                            <MenuItem value="lq">{NORMAL_QUALITY_TEXT}</MenuItem>
                                            <MenuItem value="hq">{HIGH_QUALITY_TEXT}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} className={customStyles.gridItem}>
                                    <FormControl className={customStyles.ddlContainer}>
                                        <InputLabel className={customStyles.inputLabel} htmlFor="recording-download-seconds_before">Seconds Before</InputLabel>
                                        <Select
                                            value={this.state.secondsBefore}
                                            onChange={e => this.handleDDLChange('secondsBefore', e.target.value)}
                                            className={customStyles.ddl}
                                            inputProps={{
                                                name: 'Seconds Before',
                                                id: 'recording-download-seconds_before',
                                            }}
                                        >
                                            {RECORDING_DOWNLOAD_BEFORE_AFTER_TIME_SLOTS_IN_SEC
                                                .map(time => (
                                                    <MenuItem key={`secondsBefore-${time}`} value={time}>
                                                        {`${time}`}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} className={customStyles.gridItem}>
                                    <FormControl className={customStyles.ddlContainer}>
                                        <InputLabel className={customStyles.inputLabel} htmlFor="recording-download-seconds_after">Seconds After</InputLabel>
                                        <Select
                                            value={this.state.secondsAfter}
                                            onChange={e => this.handleDDLChange('secondsAfter', e.target.value)}
                                            className={customStyles.ddl}
                                            inputProps={{
                                                name: 'Seconds After',
                                                id: 'recording-download-seconds_after',
                                            }}
                                        >
                                            {RECORDING_DOWNLOAD_BEFORE_AFTER_TIME_SLOTS_IN_SEC
                                                .map(time => (
                                                    <MenuItem key={`secondsAfter-${time}`} value={time}>
                                                        {`${time}`}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {!isRecordingAvailable &&
                                <label htmlFor="time-selected" className={customStyles.recNotAvailableError}>
                                    Invalid time range selected. Please adjust before downloading.
                                </label>
                                }
                                <Grid item xs={12} className={customStyles.gridItem}>
                                    <div className={customStyles.buttonContainer}>
                                        <Button onClick={() => this.handleClose()}>
                                            CANCEL
                                        </Button>
                                        {(this.state.showDownloadBtn && isRecordingAvailable) ?
                                            <a
                                                className={customStyles.downloadAnchor}
                                                href={this.state.downloadURL}
                                                download
                                                target="_self"
                                                onClick={() => this.handleClose()}
                                            >
                                                <span>DOWNLOAD</span>
                                            </a>
                                            :
                                            this.getPrepareButton()
                                        }

                                    </div>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </ValidatorForm>
                </Grid>
            </Grid>
        );
    }
}

export default DownloadRecordings;
