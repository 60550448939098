export const FETCH_LICENSE_STATES: string = 'FETCH_LICENSE_STATES';
export const FETCH_LICENSE_STATES_SUCCESS: string = 'FETCH_LICENSE_STATES_SUCCESS';
export const FETCH_LICENSE_STATES_ERROR: string = 'FETCH_LICENSE_STATES_ERROR';
export const CREATE_KEY_FOB_ERROR: string = 'CREATE_KEY_FOB_ERROR';
export const FETCH_UNASSIGNED_KEY_FOBS: string = 'FETCH_UNASSIGNED_KEY_FOBS';
export const FETCH_UNASSIGNED_KEY_FOBS_SUCCESS: string = 'FETCH_UNASSIGNED_KEY_FOBS_SUCCESS';
export const FETCH_UNASSIGNED_KEY_FOBS_ERROR: string = 'FETCH_UNASSIGNED_KEY_FOBS_ERROR';
export const ADD_DRIVER: string = 'ADD_DRIVER';
export const ADD_DRIVER_SUCCESS: string = 'ADD_DRIVER_SUCCESS';
export const ADD_DRIVER_ERROR: string = 'ADD_DRIVER_ERROR';
export const FETCH_DRIVER: string = 'FETCH_DRIVER';
export const FETCH_DRIVER_SUCCESS: string = 'FETCH_DRIVER_SUCCESS';
export const FETCH_DRIVER_ERROR: string = 'FETCH_DRIVER_ERROR';
export const UPDATE_DRIVER: string = 'UPDATE_DRIVER';
export const UPDATE_DRIVER_SUCCESS: string = 'UPDATE_DRIVER_SUCCESS';
export const UPDATE_DRIVER_ERROR: string = 'UPDATE_DRIVER_ERROR';
export const DELETE_DRIVER: string = 'DELETE_DRIVER';
export const DELETE_DRIVER_SUCCESS: string = 'DELETE_DRIVER_SUCCESS';
export const DELETE_DRIVER_ERROR: string = 'DELETE_DRIVER_ERROR';
export const CLOSE_DRIVER_DIALOG: string = 'CLOSE_DRIVER_DIALOG';
export const UPDATE_DRIVER_LOADER: string = 'UPDATE_DRIVER_LOADER';
export const INIT_DRIVER_PROPS: string = 'INIT_DRIVER_PROPS';
export const RESEND_INVITE: string = 'RESEND_INVITE';
export const RESEND_INVITE_SUCCESS: string = 'RESEND_INVITE_SUCCESS';
export const RESEND_INVITE_ERROR: string = 'RESEND_INVITE_ERROR';
export type DriverTypeAction = {
    type: string;
    payload?: Object;
}
