/* @flow */
/* eslint-disable no-console */
import axios from 'axios';
import moment from 'moment';
import { getDashcamToken, getDashcamOrgId } from '../../../util/dashcam_util';
import { isEmpty, getUserToken, getAuthorizationHeader } from '../../../helper-classes/utility-functions';
import {
    ADD_DASHCAM_ERROR_INVALID_CAMERA_IMEI,
    DASHCAM_LIST_DEFAULT_ITEM_LIMIT,
    ERROR_MSG_ADD_DASHCAM_EVENT_SETTING_FAIL,
    DASHCAM_NAME_DEFAULT_ITEM_LIMIT,
    DASHCAM_MAX_EVENT_PER_PAGE_LIMIT,
    DASHCAM_LIST_DEFAULT_PARALLEL_CHUNK_SIZE,
    DASHCAM_EVENT_DOWNLOAD_API_CALL_THESHOLD,
} from '../../../containers/DashCam/constants.dashcam';
import {
    DEFAULT_EVENT_SETTINGS,
    EVENT_TYPE_MAPPING,
    EVENT_TYPES_CATEGORY,
    HARD_CORNERING_EVENT_TYPES,
    MONITORING_VIRTUAL_EVENTS,
    SAFETY_VIRTUAL_EVENTS,
    SUPPORTED_EVENT_TYPES,
} from '../../../components/DashCam/Events/constants';
import config from '../../../constants/Config';

const getOptionalHeaders = () => ({
    cTypeOnly: {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: '',
        url: config.get('DASHCAM_SURFSIGHT_API_URL'),
    },
    authWithData: {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getDashcamToken()}`,
        },
        data: '',
        url: config.get('DASHCAM_SURFSIGHT_API_URL'),
    },
    fleetDashcamWithAuth: {
        method: 'post',
        headers: {
            Authorization: getAuthorizationHeader(),
            'Content-Type': 'application/json',
        },
        data: '',
        url: config.get('FLEET_DASHCAM_SERVICE_URL'),
    },
    fleetDevicesWithAuth: {
        method: 'post',
        headers: {
            'X-Nspire-Usertoken': `${getUserToken()}`,
            'Content-Type': 'application/json',
        },
        data: '',
        url: config.get('FLEET_VIEW_SERVICES_URL'),
    },
});

const responseHandler = (
    response: Object = {},
    hideErrorMessage: boolean = false,
    isSurfsightDown: boolean = false,
) => {
    let responseToReturn = { status: response.status, apiStatus: response.status };

    if (response.data && !isEmpty(response.data)) {
        if (response.data.data) {
            let data;

            if (response.data.data instanceof Array) {
                data = (!isEmpty(response.data.data)) ? [...response.data.data] : [];
            } else if (response.data.data instanceof Object) {
                data = (!isEmpty(response.data.data)) ? { ...response.data.data } : {};
            }
            responseToReturn = { ...responseToReturn, data };
        } else {
            responseToReturn = { ...responseToReturn, ...response.data };
        }

        if (response.data.metadata && !isEmpty(response.data.metadata)) {
            responseToReturn = { ...responseToReturn, ...response.data.metadata };
        }
    }

    if (response.status >= 400 && !hideErrorMessage) {
        responseToReturn = {
            ...responseToReturn,
            message: isSurfsightDown ? 'FleetLocate Dashcam is currently down, please try again later. We apologize for the inconvenience. Your dashcams will continue to record video locally.' :
                'We are sorry a system error has occurred while processing your request, please try your request again later.',
        };
    }

    return responseToReturn;
};

export const getDashCamUserToken = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthorizationHeader(),
        },
        url: config.get('SURFSIGHTPARTNERADAPTER_API_URL'),
    };
    options.url += '/userToken';

    return axios({ ...options })
        .then(response => responseHandler(response))
        .catch(error => responseHandler(error.response, false, true));
};

export const getOrganizationDevices = (searchKeyword: string = '', searchKey: string = 'imei', pageNo: number = 0, limit: number = DASHCAM_LIST_DEFAULT_ITEM_LIMIT) => {
    const options = { ...getOptionalHeaders().authWithData };
    options.method = 'GET';
    options.url += `/organizations/${getDashcamOrgId()}/devices?limit=${limit}`;
    const offset = (pageNo > 0) ? (pageNo * limit) : undefined;
    if (offset) options.url += `&offset=${offset}`;
    if (searchKeyword) options.url += `&${searchKey}=${searchKeyword}`;

    return axios({ ...options })
        .then((response) => {
            const { data } = response.data;
            if (data && data.length === 0 && searchKey === 'imei') {
                return getOrganizationDevices(searchKeyword, 'name', pageNo, limit);
            }
            return responseHandler(response);
        })
        .catch(error => responseHandler(error.response, false, true));
};

export const getRecordingAvailability = (queryParams: Object) => {
    const options = { ...getOptionalHeaders().authWithData };
    options.method = 'GET';
    options.url += `/devices/${queryParams.imei}/recording-ranges?start=${queryParams.start}&end=${queryParams.end}`;
    return axios({ ...options })
        .then(response => responseHandler(response))
        .catch(error => responseHandler(error.response));
};

export const getDeviceEvents = (imei: any, startDate: any, endDate: any) => {
    const options = { ...getOptionalHeaders().authWithData };
    options.method = 'GET';
    options.url += `/devices/${imei}/events?start=${startDate}.000Z&end=${endDate}.000Z`;

    return axios({ ...options })
        .then((response) => {
            if (response.data && response.data.data) {
                const { data } = response.data;
                response.data.data = data.map((d) => {
                    const newData = d;
                    if (newData.eventType.toLowerCase() === 'virtualevent') {
                        newData.eventType = newData.metadata;
                    }
                    return d;
                });
            }
            return responseHandler(response);
        })
        .catch(error => responseHandler(error.response));
};

export const getEventFileLink = (imei: any, fileId: any, fileType: any, cameraId: any) => {
    const options = { ...getOptionalHeaders().authWithData };
    options.method = 'GET';
    options.url += `/devices/${imei}/event-file-link?fileId=${fileId}&fileType=${fileType}&cameraId=${cameraId}`;

    return axios({ ...options })
        .then(response => responseHandler(response))
        .catch(error => responseHandler(error.response));
};

export const getDeviceMediaToken = (queryParams: Object) => {
    const options = { ...getOptionalHeaders().authWithData };
    options.url += `/devices/${queryParams.imei}/connect-media`;

    return axios({ ...options })
        .then(response => responseHandler(response))
        .catch(error => responseHandler(error.response));
};

export const verifyHlsSource = (queryParams: Object) => axios({ url: queryParams.url })
    .then(response => responseHandler(response))
    .catch(error => responseHandler(error.response));

export const getDeviceTelemetry = (queryParams: Object) => {
    const options = { ...getOptionalHeaders().authWithData };
    options.method = 'GET';
    options.url += `/devices/${queryParams.imei}/telemetry`;

    return axios({ ...options })
        .then(response => responseHandler(response))
        .catch(error => responseHandler(error.response));
};

export const deleteDashCam = (data: Object) => {
    const options = { ...getOptionalHeaders().authWithData };
    options.method = 'DELETE';
    options.url += `/organizations/${getDashcamOrgId()}/devices/${data.imei}`;

    return axios({ ...options })
        .then(response => responseHandler(response))
        .catch(error => responseHandler(error.response));
};

export const prepareRecording = (recordingURL: string) => {
    const options = {
        method: 'GET',
        url: recordingURL,
        headers: {
            range: 'bytes=0-',
            accept: '*/*',
        },
    };

    return axios({ ...options })
        .then(response => responseHandler(response))
        .catch(error => responseHandler(error.response));
};

export const getDeviceSetting = (imei: string) => {
    const options = { ...getOptionalHeaders().authWithData };
    options.method = 'GET';
    options.url += `/devices/${imei}/device-config`;

    return axios({ ...options })
        .then(response => responseHandler(response))
        .catch(error => responseHandler(error.response));
};

export const updateDashcamSetting = (imei: string, data: Object) => {
    const options = { ...getOptionalHeaders().authWithData };
    options.method = 'PUT';
    options.data = data;
    options.url += `/devices/${imei}/device-config`;

    return axios({ ...options })
        .then(response => responseHandler(response))
        .catch(error => responseHandler(error.response));
};

export async function getEventSetting(data: any) {
    const { imei, assetId } = data;
    const eventCall = {
        ...getOptionalHeaders().authWithData,
        method: 'GET',
    };
    eventCall.url += `/devices/${imei}/event-config`;

    const virtualEventCall = {
        ...getOptionalHeaders().fleetDashcamWithAuth,
        method: 'GET',
    };
    const randVal = new Date().getTime();
    virtualEventCall.url += `/assetDashcams/${assetId}/eventSettings?_dc=${randVal}`;

    try {
        const eventCallResponse = await axios({ ...eventCall });
        let virtualEventCallResponse = {};

        if (assetId) {
            virtualEventCallResponse = await axios({ ...virtualEventCall });
        }

        if (eventCallResponse.data && !isEmpty(eventCallResponse.data)) {
            if (eventCallResponse.data.data &&
                eventCallResponse.data.data.events &&
                virtualEventCallResponse.data &&
                virtualEventCallResponse.data.eventSettings) {
                const eventSettings = virtualEventCallResponse.data.eventSettings.map((d) => {
                    const newData = d;
                    newData.dataType = newData.dataType.toLowerCase();
                    newData.eventType = newData.type;
                    return newData;
                });
                eventCallResponse.data.data.events
                    .push(...eventSettings);
            }
        }
        return responseHandler(eventCallResponse);
    } catch (error) {
        return responseHandler(error.response);
    }
}

export async function updateEventSetting(
    imei: string,
    data: Object,
    isDashcamLoadReq: boolean = false,
) {
    const eventCall = {
        ...getOptionalHeaders().authWithData,
        method: 'PUT',
        data: data.surfSightEvents,
    };
    eventCall.url += `/devices/${imei}/event-config`;

    const virtualEventCall = {
        ...getOptionalHeaders().fleetDashcamWithAuth,
        method: 'PUT',
        data: {
            eventSettings: data.virtualEventData ? data.virtualEventData.eventSettings.map(d => ({
                dataType: d.dataType.toUpperCase(),
                type: d.eventType,
            })) : [],
        },
    };
    const randVal = new Date().getTime();
    virtualEventCall.url += `/assetDashcams/${data.assetId}/eventSettings?_dc=${randVal}`;

    try {
        let response = {};
        if (data.isSurfSightEventsModified) {
            response = await axios({ ...eventCall });
        }
        if (data.assetId && data.isVirtualEventsModified) {
            response = await axios({ ...virtualEventCall });
        }
        return responseHandler(response);
    } catch (error) {
        const errorResponse = error.response;
        if (isDashcamLoadReq && errorResponse.data) {
            errorResponse.data.message = ERROR_MSG_ADD_DASHCAM_EVENT_SETTING_FAIL;
            errorResponse.data.isDashcamLoadReq = isDashcamLoadReq;
            return responseHandler(errorResponse, true);
        }
        return responseHandler(errorResponse);
    }
}

export const addDashCam = (data: any) => {
    const options = { ...getOptionalHeaders().authWithData };
    options.data = data;
    options.url += `/organizations/${getDashcamOrgId()}/devices`;

    return axios({ ...options })
        .then((response) => {
            if (response && response.status === 200) {
                //  During Creation of Dashcam setting the default value for event setting
                return updateEventSetting(data.imei, {
                    surfSightEvents: DEFAULT_EVENT_SETTINGS,
                    isSurfSightEventsModified: true,
                }, true);
            }
            return responseHandler(response);
        })
        .catch((error) => {
            if (error.response.data &&
                error.response.data.error.toLowerCase() === 'notfounderror') {
                const modifyResponseData = { ...error.response };
                modifyResponseData.data.message = `${data.imei} 
                ${ADD_DASHCAM_ERROR_INVALID_CAMERA_IMEI}`;
                return responseHandler(modifyResponseData, true);
            }
            return responseHandler({ ...error.response }, true);
        });
};

export const isWebRtcAllowed = () => {
    let isSupported = true;
    if (window.AdapterJS && window.AdapterJS.webrtcDetectedBrowser) {
        const browser = (window.AdapterJS.webrtcDetectedBrowser || '').toLowerCase();
        switch (browser) {
        case 'edge': isSupported = (window.AdapterJS.webrtcDetectedVersion > 15);
            break;
        case 'ie': isSupported = false;
            break;
        default: break;
        }
    }
    return isSupported;
};

const chunkArray = (arrayData: Array<any>, chunkSize: number) => {
    const result = new Array(Math.ceil(arrayData.length / chunkSize))
        .fill()
        .map(() => arrayData.splice(0, chunkSize));
    return result;
};

export const getAllDeviceForEvent = async () => {
    let page = 0;
    const allDevices = [];
    try {
        const firstPageDashcam = await getOrganizationDevices('', 'imei', page, DASHCAM_MAX_EVENT_PER_PAGE_LIMIT);
        allDevices.push(...firstPageDashcam.data);
        if (firstPageDashcam.count > DASHCAM_MAX_EVENT_PER_PAGE_LIMIT) {
            const maxCallCount = Math.ceil(
                DASHCAM_NAME_DEFAULT_ITEM_LIMIT / DASHCAM_MAX_EVENT_PER_PAGE_LIMIT,
            );
            let newArray = new Array(maxCallCount - 1).fill(null);
            newArray = newArray.map(() => {
                page += 1;
                return getOrganizationDevices('', 'imei', page, DASHCAM_MAX_EVENT_PER_PAGE_LIMIT);
            });
            const dashcamChunkPromiseData = chunkArray(
                newArray,
                DASHCAM_LIST_DEFAULT_PARALLEL_CHUNK_SIZE,
            );
            for (let i = 0; i <= dashcamChunkPromiseData.length - 1; i += 1) {
                try {
                    // eslint-disable-next-line no-await-in-loop
                    const result = await Promise.all(dashcamChunkPromiseData[i]);
                    result.forEach((d) => {
                        allDevices.push(...d.data);
                    });
                } catch (error) {
                    allDevices.push(...[]);
                }
            }
            return { data: allDevices };
        }
        return { data: allDevices };
    } catch (error) {
        return responseHandler(error);
    }
};

export const getOrganizationEventsCall =
async (startDate, endDate, eventType, offset) => axios.post(
    `${config.get('DASHCAM_SURFSIGHT_API_URL')}/organizations/${getDashcamOrgId()}/events?start=${startDate}.000Z&end=${endDate}.000Z${offset ? `&offset=${offset}` : ''}`,
    JSON.stringify({ eventTypes: Array.isArray(eventType) ? eventType : [eventType] }),
    { headers: getOptionalHeaders().authWithData.headers },
);

const getEventRequests = (events, dateRange) => {
    const eventsCall = [];
    events.forEach((eventType) => {
        if (!EVENT_TYPE_MAPPING[eventType].virtualEvent) {
            eventsCall.push(
                {
                    eventType,
                    request: getOrganizationEventsCall(
                        dateRange.startDate,
                        dateRange.endDate,
                        eventType,
                    ),
                    response: null,
                },
            );
        }
    });
    return eventsCall;
}

const getEventsCountData = async (category, dateRange) => {
    const data = {
        total: 0,
    };
    const eventsCall = getEventRequests(category.events, dateRange);
    return axios.all(eventsCall.map(e => e.request)).then(axios.spread((...responses) => {
        responses.forEach((response, index) => {
            if (response.data && response.data.metadata) {
                if (HARD_CORNERING_EVENT_TYPES.includes(eventsCall[index].eventType)) {
                    if (data[HARD_CORNERING_EVENT_TYPES[0]]) {
                        data[HARD_CORNERING_EVENT_TYPES[0]].count += response.data.metadata.count;
                    } else {
                        data[HARD_CORNERING_EVENT_TYPES[0]] = {
                            eventType: HARD_CORNERING_EVENT_TYPES[0],
                            count: response.data.metadata.count,
                        };
                    }
                } else {
                    data[eventsCall[index].eventType] = {
                        eventType: eventsCall[index].eventType,
                        count: response.data.metadata.count,
                    };
                }
                data.total += response.data.metadata.count;
            }
        });
        return data;
    }));
}

const getEventCounts = async (dateRange, virtualEvents, fatchData = true) => {
    const data = {
        total: 0,
        safety: {
            total: 0,
        },
        monitoring: {
            total: 0,
        },
    };
    if (fatchData) {
        for (let i = 0; i <= EVENT_TYPES_CATEGORY.length - 1; i += 1) {
            const eventType = EVENT_TYPES_CATEGORY[i].name.toLowerCase();
            data[eventType] =
            // eslint-disable-next-line no-await-in-loop
            await getEventsCountData(EVENT_TYPES_CATEGORY[i], dateRange);

            virtualEvents.forEach((d) => {
                if (eventType === 'safety' && SAFETY_VIRTUAL_EVENTS.includes(d.eventType)) {
                    if (data[eventType][d.eventType]) {
                        data[eventType][d.eventType].count += 1;
                    } else {
                        data[eventType][d.eventType] = { eventType: d.eventType, count: 1 };
                    }
                    data[eventType].total += 1;
                }

                if (eventType === 'monitoring' && MONITORING_VIRTUAL_EVENTS.includes(d.eventType)) {
                    if (data[eventType][d.eventType]) {
                        data[eventType][d.eventType].count += 1;
                    } else {
                        data[eventType][d.eventType] = { eventType: d.eventType, count: 1 };
                    }
                    data[eventType].total += 1;
                }
            });
            data.total += data[eventType].total;
        }
    }
    return data;
}

export const getAllPagesData = async ({
    totalCount,
    limit,
    eventType,
    maximumCalls,
    startDate,
    endDate,
    initValue = 1,
}) => {
    const allData = [];
    let totalPages = Math.ceil(totalCount / limit);
    totalPages = totalPages > maximumCalls ? maximumCalls : totalPages;
    for (let i = initValue; i < totalPages; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const data = await getOrganizationEventsCall(startDate, endDate, eventType, i * limit);
        allData.push(...data.data.data);
    }
    return allData;
}

const getVirtualEvents = async (startDate, endDate) => {
    let virtualEvents = [];
    try {
        const data = await getOrganizationEventsCall(startDate, endDate, 'virtualEvent');
        if (data.data && data.data.metadata) {
            virtualEvents = data.data.data;
            if (data.data.metadata.count > data.data.metadata.limit) {
                const allPageData = await getAllPagesData({
                    totalCount: data.data.metadata.count,
                    limit: data.data.metadata.limit,
                    eventType: 'virtualEvent',
                    maximumCalls: DASHCAM_EVENT_DOWNLOAD_API_CALL_THESHOLD,
                    startDate,
                    endDate,
                });
                virtualEvents.push(...allPageData);
            }
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
    virtualEvents = virtualEvents.map((d) => {
        const newData = d;
        newData.eventType = newData.metadata;
        return newData;
    });
    return virtualEvents;
}

export const getOrganizationEvents = async (dateRange: any) => {
    const ISOStartDate = `${dateRange.startDate}.000Z`
    const virtualEvents = await getVirtualEvents(
        dateRange.makeComparisonApiCall ? dateRange.newStartDate : dateRange.startDate,
        dateRange.endDate,
    );
    const historyVirtualEvents = virtualEvents.filter(event =>
        moment(ISOStartDate).isAfter(moment(event.time)));
    const currentVirtualEvents =
    virtualEvents.filter(event => moment(ISOStartDate).isSameOrBefore(moment(event.time)));

    const currentEventsCount = await getEventCounts(dateRange, currentVirtualEvents);
    const historyEventsCount = dateRange.makeComparisonApiCall ?
        await getEventCounts({
            startDate: dateRange.newStartDate,
            endDate: dateRange.newEndDate,
        }, historyVirtualEvents) :
        await getEventCounts(dateRange, [], false);
    return { historyEventsCount, currentEventsCount, currentEvents: currentVirtualEvents };
};

export const getAllSupportedEvents = () => {
    const events = SUPPORTED_EVENT_TYPES.filter(e =>
        !(SAFETY_VIRTUAL_EVENTS.includes(e) || MONITORING_VIRTUAL_EVENTS.includes(e)));
    events.push('virtualEvent');
    return events;
}

export const getAllEventsWithPagination = async (dateRange: any, offset: number) => {
    const events = getAllSupportedEvents()
    const data = await getOrganizationEventsCall(
        dateRange.startDate,
        dateRange.endDate,
        events,
        offset,
    );

    if (data.data && data.data.data) {
        data.data.data = data.data.data.map((d) => {
            const newData = d;
            if (d.eventType === 'virtualEvent') {
                newData.eventType = newData.metadata;
                return newData;
            }
            return newData;
        });
    }

    return data ? data.data : { data: [], metadata: {} };
}

export const createAssetDashcam = (content: any) => {
    const options = { ...getOptionalHeaders().fleetDashcamWithAuth };
    options.data = content;
    const randVal = new Date().getTime();
    options.url += `/assetDashcams?_dc=${randVal}`;

    return axios({ ...options })
        .then(response => responseHandler(response))
        .catch(error => responseHandler(error.response, true));
};

export const deleteAssetDashcam = (assetId: string) => {
    const options = { ...getOptionalHeaders().fleetDashcamWithAuth };
    options.method = 'DELETE';
    const randVal = new Date().getTime();
    options.url += `/assetDashcams/${assetId}?_dc=${randVal}`;

    return axios({ ...options })
        .then(response => responseHandler(response))
        .catch(error => responseHandler(error.response, true));
};

export const getAssetDashcamByAssetId = (assetId: string) => {
    const options = { ...getOptionalHeaders().fleetDashcamWithAuth };
    options.method = 'GET';
    const randVal = new Date().getTime();
    options.url += `/assetDashcams/${assetId}?_dc=${randVal}`;

    return axios({ ...options })
        .then(response => responseHandler(response))
        .catch(error => responseHandler(error.response, true));
};

export const getAssets = (offset: number, limit: number, query: any) => {
    const search = query.replace(/[\s]{2,}/gi, ' ').trim();
    const options = {
        method: 'GET',
        headers: {
            Authorization: getAuthorizationHeader(),
            'Content-Type': 'application/json',
        },
        data: '',
        url: config.get('FLEET_VIEW_SERVICES_URL'),
    };

    const randVal = new Date().getTime();
    options.url += `/assets?search=${search}&start=${offset}&limit=${limit}&hasDashcam=false&_dc=${randVal}`;

    return axios({ ...options })
        .then(response => (response.data ? response.data : null));
};

export const getAssetByDashcamIdentifier = (dashcamIdentifier: string) => {
    const options = { ...getOptionalHeaders().fleetDashcamWithAuth };
    options.method = 'GET';
    const randVal = new Date().getTime();
    options.url += `/assetDashcams/dashcam/${dashcamIdentifier}?_dc=${randVal}`;

    return axios({ ...options })
        .then(response => (response.data ? response.data : null))
        .catch(error => (error.response ? error.response : null));
};

export const getLMDevices = (offset: number, limit: number, query: any) => {
    const options = { ...getOptionalHeaders().fleetDevicesWithAuth };
    options.url += '/devices';
    options.data = {
        sortParam: [['serialNumber', 'asc']],
        start: offset,
        limit,
        filterParam: [
            ['active', 'eq:true'],
            ['assetId', 'exists:no'],
            ['deviceTypeCode', 'eq:dashcam'],
        ],
    }

    if (query) {
        options.data.searchApiKeys = ['serialNumber'];
        options.data.searchParams = [query];
    }

    return axios({ ...options })
        .then(response => (response.data ? response.data : null))
        .catch(error => (error.response ? error.response : null));
};
