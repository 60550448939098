/* @flow */
import moment from 'moment';
import React, { Fragment } from 'react';
import { Paper, Grid, Button, IconButton, FormControlLabel, Radio, Tooltip } from '@mui/material';
import { Cancel, CheckCircle } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import customStyles from './ImportComponent.module.scss';
import backArrow from '../../../assets/icons/back-arrow-black.svg';
import GetSvgIcon from '../../../util/svgImage_util';
import ImportFormComponent from './ImportFormComponent';
import { postData } from './../../../helper-classes/utility-functions';
import config from './../../../constants/Config';
import Dialog from './../Dialog/Dialog';
import { reduxConnect } from '../../../hoc';
import { ERRORMSG, SUCCESSMSG, ASSET, LANDMARK_ADDRESS, LANDMARK_POLYGON, USER, DRIVER, LANDMARK_LAT_LNG, FILESIZE } from './constants.import';
import * as notificationActions from '../../../components/SharedComponents/NotificationHandler/actions.appNotifications';

export type Props = {
    closeImport: Function,
    classes: Object,
    importType: string,
    updateLoader: Function,
    history: {
        push: Function,
    },
    addNewInProgressImportJob: Function,
};

export type State = {
    selectedOption: string,
    msgContent: string,
    file: any,
    buttons: any,
    inputKey: string,
    type: string,
    showDialog: boolean,
};

const styles = () => ({
    root: {
        margin: 15,
        marginLeft: 10,
        zIndex: 1,
        minHeight: 'calc(100vh - 80px)',
    },
    error: {
        width: 40,
        height: 40,
        marginBottom: -12,
        paddingRight: 10,
    },
    success: {
        fill: 'green',
        width: 40,
        height: 40,
        marginBottom: -12,
        paddingRight: 10,
    },
    fileSelect: {
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: '100%',
        opacity: 0,
    },
    inputField: {
        textTransform: 'none',
        maxWidth: 328,
        borderBottom: '1px solid #d3d7db',
        borderRadius: 'inherit',
        marginTop: 14,
        paddingBottom: 6,
    },
    fileName: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textAlign: 'left',
        paddingRight: 4,
    },
    closeButton: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    fileNameText: {
        color: '#d3d7db',
    },
    footer: {
        alignContent: 'flex-end',
        justifyContent: 'flex-end',
        display: 'flex',
        padding: '11px 15px 16px',
        borderTop: '1px solid rgba(0, 0, 0, 0.24)',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    },
    uploadGrid: {
        paddingLeft: 10,
        marginTop: 4,
    },
});

class ImportComponent extends React.PureComponent<Props, State> {
    static defaultProps = {
        closeImport: () => {},
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedOption: 'Address',
            file: null,
            msgContent: '',
            inputKey: this.generateInputKey(),
            showDialog: false,
            type: '',
            buttons: [{
                title: 'OK',
                color: 'primary',
                variant: 'contained',
                handleClick: () => this.handleClickOk(),
            }],
        };
    }

    selectOption = selectedOptionVal => this.setState({
        selectedOption: selectedOptionVal,
        file: null,
    });

    getFillColor = importType => (this.state.selectedOption === importType ? '#007AFF' : '#f5f5f5');

    getContrastFillColor = importType => (this.state.selectedOption === importType ? '#FFF' : '#d8d8d8');

    generateInputKey = () => Math.random().toString(36)

    getEntity = (importType) => {
        let entity = '';
        switch (importType) {
        case 'Assets': entity = ASSET;
            break;
        case 'Address': entity = LANDMARK_ADDRESS;
            break;
        case 'LatLng': entity = LANDMARK_LAT_LNG;
            break;
        case 'Polygon': entity = LANDMARK_POLYGON;
            break;
        case 'Drivers': entity = DRIVER;
            break;
        case 'Users': entity = USER;
            break;
        default: break;
        }
        return entity;
    }

    updateDialogState = (msgContent, type) => {
        this.setState({
            msgContent,
            type,
            file: null,
            showDialog: true,
            inputKey: this.generateInputKey(),
        });
    }

    uploadFile = (file, importType) => {
        const formData = new FormData();
        let type = importType;
        if (importType === 'Landmarks') {
            type = this.state.selectedOption;
        }
        formData.append('file', file);
        this.props.updateLoader(true);
        const entityType = this.getEntity(type);

        const url = `${config.get('FLEET_IMPORT_SERVICES_URL')}/import/?entity=${entityType}`;
        postData(url, formData, {}).then((res) => {
            if (res.jobId) {
                this.props.addNewInProgressImportJob([{
                    jobId: res.jobId,
                    date: moment(),
                    successRecords: 0,
                    failedRecords: 0,
                    totalRecords: 0,
                    status: 'INPROGRESS',
                    entityType: entityType.toUpperCase(),
                }], this.props.history);

                this.updateDialogState(res.message || SUCCESSMSG, 'success');
                this.props.updateLoader(false);
            } else {
                this.props.updateLoader(false);
                this.updateDialogState(ERRORMSG, 'error');
            }
        }).catch((error) => {
            this.props.updateLoader(false);
            this.updateDialogState(error.message || ERRORMSG, 'error');
        });
    }

    handleFileSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileSize = file.size / 1024 / 1024;
            const regex = /^(.+)\.csv$/g;
            if (!file.name.match(regex)) {
                this.updateDialogState('You have uploaded an incorrect file type or your file contains incorrect formatting. Please see template for correct formatting.', 'error');
            } else if (fileSize > FILESIZE) {
                this.updateDialogState(`Maximum allowed file size is ${FILESIZE}MB. Please try again with a smaller file size.`, 'error');
            } else {
                this.setState({
                    file,
                    msgContent: '',
                    inputKey: this.generateInputKey(),
                    type: '',
                    showDialog: false,
                });
            }
        }
    }

    clearFile = () => {
        this.setState({ file: null });
    }

    getUploadButtonSection = (importType) => {
        const { file } = this.state;
        const name = file ? file.name : '';
        return (
            <Fragment>
                <ImportFormComponent
                    importType={importType}
                    updateLoader={this.props.updateLoader}
                />
                <Grid container>
                    <Grid item xs={7}>
                        <div className={`${this.props.classes.inputField} ${name ? '' : this.props.classes.fileNameText}`}>
                            {name ?
                                <Grid container>
                                    <Grid item xs={11} className={this.props.classes.fileName}>
                                        <Tooltip title={name || ''} placement="top-start">
                                            <span>{name}</span>
                                        </Tooltip>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        className={this.props.classes.closeButton}
                                    >
                                        <Tooltip title="Clear">
                                            <GetSvgIcon type="clear" onClick={() => this.clearFile()} fillcolor="#929292" />
                                        </Tooltip>
                                    </Grid>
                                </Grid> :
                                'Choose File *'}
                        </div>
                    </Grid>
                    <Grid item xs={5} className={this.props.classes.uploadGrid}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={this.state.file !== null}
                            label={`Choose a ${importType} File`}
                        >
                            <span>
                                UPLOAD
                            </span>
                            <input
                                type="file"
                                className={this.props.classes.fileSelect}
                                accept=".csv"
                                key={this.state.inputKey}
                                title={name || ''}
                                onChange={e => this.handleFileSelect(e)}
                            />
                        </Button>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    getLandmarkImportTemplate = () => {
        const { selectedOption } = this.state;
        return (
            <Fragment>
                <Grid item xs={4}>
                    <Grid container direction="column" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <GetSvgIcon type="addressImport" onClick={() => { this.selectOption('Address'); }} fillcolor={this.getFillColor('Address')} contrastfillcolor={this.getContrastFillColor('Address')} />
                            <FormControlLabel
                                value="end"
                                control={
                                    <Radio color="primary" value="Address" checked={selectedOption === 'Address'} onChange={(e) => { this.selectOption(e.target.value); }} />
                                }
                                label="Address"
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item>
                            <GetSvgIcon type="latLngImport" onClick={() => { this.selectOption('LatLng'); }} fillcolor={this.getFillColor('LatLng')} contrastfillcolor={this.getContrastFillColor('LatLng')} />
                            <FormControlLabel
                                value="end"
                                control={
                                    <Radio color="primary" value="LatLng" checked={selectedOption === 'LatLng'} onChange={(e) => { this.selectOption(e.target.value); }} />
                                }
                                label="Lat/Long"
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item>
                            <GetSvgIcon type="polygonImport" onClick={() => { this.selectOption('Polygon'); }} fillcolor={this.getFillColor('Polygon')} contrastfillcolor={this.getContrastFillColor('Polygon')} />
                            <FormControlLabel
                                value="end"
                                control={
                                    <Radio color="primary" value="Polygon" checked={selectedOption === 'Polygon'} onChange={(e) => { this.selectOption(e.target.value); }} />
                                }
                                label="Polygon Points"
                                labelPlacement="end"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {this.getUploadButtonSection(selectedOption)}
                </Grid>
            </Fragment>
        );
    }

    getCommonTemplate = (importType, type) => (
        <Fragment>
            <Grid item xs={4} className={customStyles.uploadButton}>
                <GetSvgIcon type={type} fillcolor="#007AFF" />
                <div>{importType}</div>
            </Grid>
            <Grid item xs={8}>
                {this.getUploadButtonSection(importType)}
            </Grid>
        </Fragment>
    )

    getImportType = (importType) => {
        switch (importType) {
        case 'Assets': return this.getCommonTemplate(importType, 'assetImport');
        case 'Drivers': return this.getCommonTemplate(importType, 'driverImport');
        case 'Users': return this.getCommonTemplate(importType, 'userImport');
        case 'Landmarks': return this.getLandmarkImportTemplate();
        default: return '';
        }
    };

    getCustomTitle = () => {
        const { importType, classes } = this.props;
        const { type, selectedOption } = this.state;
        let { msgContent } = this.state;
        if (type === 'success') {
            msgContent = <div> <CheckCircle color="success" className={classes.success} />{`${importType === 'Landmarks' ? `Landmark ${selectedOption}` : importType}`}</div>;
        } else if (type === 'error') {
            msgContent = <div> <Cancel color="error" className={classes.error} /> {`${importType === 'Landmarks' ? `Landmark ${selectedOption}` : importType} file upload error`}</div>;
        }
        return msgContent;
    }

    handleClickOk = () => {
        this.setState({ msgContent: '', type: '', showDialog: false });
        if (this.state.type === 'success') this.props.closeImport('dialog button clicked');
    }

    render() {
        const { classes, closeImport, importType } = this.props;
        const { showDialog, type, msgContent } = this.state;

        return (
            <Paper className={classes.root}>
                <Grid container>
                    <Grid item xs={12} className={customStyles.header}>
                        <Grid container alignItems="center" justifyContent="flex-start">
                            <Grid item xs>
                                <IconButton
                                    aria-label="Back"
                                    onClick={() => closeImport('back button clicked')}
                                    size="large"
                                >
                                    <img src={backArrow} alt="back" />
                                </IconButton>
                                <p>Import {importType}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={customStyles.body}>
                        <Grid container justifyContent="flex-start" spacing={1}>
                            {this.getImportType(importType)}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.footer}>
                            <Button
                                style={{ marginRight: 10 }}
                                onClick={() => closeImport('cancel button clicked')}
                            >
                                CANCEL
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!this.state.file}
                                onClick={() => this.uploadFile(this.state.file, importType)}
                            >
                                IMPORT
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                <Dialog
                    open={showDialog}
                    type={type}
                    customTitle={this.getCustomTitle()}
                    button={this.state.buttons}
                    content={msgContent}
                    size="lg"
                />
            </Paper>
        );
    }
}

export default
withStyles(styles)(reduxConnect(ImportComponent, notificationActions));
