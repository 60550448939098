/* @flow */
import React, { Component } from 'react';
import {
    Divider,
    Grid,
    Button,
    Typography, Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import moment from 'moment';
import { reduxConnect } from '../../../hoc';
import * as actions from './actions.landmarksList';
import MiniDrawer from '../../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../../components/SharedComponents/ListComponent/ListComponent';
import ImportComponent from '../../../components/SharedComponents/ImportComponent/ImportComponent';
import {
    getHeaderKeysForPost,
    downloadFile,
    getUserSettingTimeZone,
    getDistanceStringLong,
    getTimeFormatAsperUserSettting,
    getParamValue,
    savePage,
    getSavedPage,
    removeSavedPage,
    getValueFromObjectByKey,
    getPermissionValue,
    bulkDeleteLandmark,
} from '../../../helper-classes/utility-functions';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    showHideColumns,
    rearrangeColumns,
    isUpdatePreference,
} from '../../../helper-classes/listUtil';
import { RECORDS_PER_PAGE } from '../../../constants/Config';
import { getURL, getRequestPayload } from './epics.landmarksList';
import AppLoader from '../../../components/AppLoader';
import Landmark from '../../Landmark/AddLandmark/Landmark';
import analytics from '../../../analytics/index';
import { LANDMARK_LIST_PREF_KEY } from './constants.landmarksList';
import Dialog from '../../../components/SharedComponents/Dialog/Dialog';

export type Props = {
    isUpdating: boolean,
    classes : Object,
    fetchLandmarksList: Function,
    location: Object,
    resetTable: Function,
    match: {
        path: any,
    },
    history: {
        push: Function,
    },
};

const themeStyles = () => ({
    hide: {
        display: 'none',
    },
});

export type State = {
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{}>,
        totalRecords: number,
        isEditable: boolean,
    },
    pageNumber: number,
    rowsPerPage: number,
    order: string,
    orderBy: string,
    fltrRanMin: string,
    fltrRanMax: string,
    isFormValid: boolean,
    selectedOption: Object,
    loading: boolean,
    openExport: boolean,
    openImport: boolean,
    isAddEdit: boolean,
    showListing: boolean,
    deletedErrorMessage: any,
};

class LandmarksList extends Component<Props, State> {
    search: string;
    landmarkFiltersToSend: any;
    id: string;
    addUrl: string;
    userPreferences: any;
    button: Array<Object>;
    constructor(props: Props) {
        super(props);
        const pageData = this.initPage();
        this.state = pageData.state;
        this.search = pageData.search;
        this.landmarkFiltersToSend = pageData.landmarkFiltersToSend;
        this.userPreferences = pageData.userPreferences;
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.setState({ deletedErrorMessage: '' }),
        }];
    }

    UNSAFE_componentWillMount() {
        if (!this.state.isAddEdit) {
            getUserPreference(this, LANDMARK_LIST_PREF_KEY);
        }
        ValidatorForm.addValidationRule('compare', (value, typeWithCompareState) => {
            const compareData = typeWithCompareState.split('-');
            if (typeof value === 'number') {
                if (this.state[compareData[1]] === '') {
                    return true;
                } else if (compareData[0] === 'lesser' && value < this.state[compareData[1]]) {
                    return true;
                } else if (compareData[0] === 'greater' && value > this.state[compareData[1]]) {
                    return true;
                }
            } else if (value === '' && this.state[compareData[1]] === '') {
                return true;
            }
            return false;
        });
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (nextprops.landmarks.length > 0) {
            const { landmarks, totalLandmarks } = nextprops;
            const { tableData } = this.state;
            tableData.listData = landmarks;
            tableData.totalRecords = totalLandmarks;
            this.setState({
                tableData,
            });
        } else if (!this.state.isAddEdit) {
            const { tableData } = this.state;
            tableData.listData = [];
            tableData.totalRecords = 0;
            this.setState({
                tableData,
            });
        }
    }

    componentDidUpdate() {
        let { isAddEdit } = this.state;
        if (isAddEdit && this.props.match.path === '/landmarks') {
            isAddEdit = false;
            const savedPage = getSavedPage();
            if (savedPage) {
                const pageData = this.initPage();
                this.updateSavedPageState(pageData.state);
                this.search = pageData.search;
                this.landmarkFiltersToSend = pageData.landmarkFiltersToSend;
            } else {
                getUserPreference(this, LANDMARK_LIST_PREF_KEY);
            }
            removeSavedPage();
            this.updateEditState(isAddEdit);
        } else if (!isAddEdit &&
            (this.props.match.path === '/landmarks/edit/:id' ||
            this.props.match.path === this.addUrl)) {
            isAddEdit = true;
            this.updateEditState(isAddEdit);
        }
    }

    initPage = () => {
        const savedPage = getSavedPage();
        let isAddEdit = false;
        this.addUrl = '/landmarks/add';
        if (getParamValue(this.props, 'id') || this.props.match.path === this.addUrl) {
            isAddEdit = true;
        } else {
            removeSavedPage();
        }
        const data = {
            state: {
                tableData: {
                    listData: [],
                    totalRecords: 0,
                    ischeckBox: true,
                    isGroup: false,
                    isEditable: true,
                    listHeaders: this.getListHeader(),
                },
                pageNumber: getValueFromObjectByKey(savedPage, 'pageNumber', 0),
                rowsPerPage: getValueFromObjectByKey(savedPage, 'rowsPerPage', RECORDS_PER_PAGE),
                order: getValueFromObjectByKey(savedPage, 'order', 'asc'),
                orderBy: getValueFromObjectByKey(savedPage, 'orderBy', 'name'),
                fltrRanMin: '',
                fltrRanMax: '',
                isFormValid: false,
                selectedOption: {
                    lable: 'CSV',
                    value: 'csv',
                    key: 'CSV',
                },
                loading: false,
                openExport: false,
                openImport: false,
                isAddEdit,
                showListing: false,
                deletedErrorMessage: '',
            },
            search: getValueFromObjectByKey(savedPage, 'searchKey', ''),
            landmarkFiltersToSend: getValueFromObjectByKey(savedPage, 'landmarkFiltersToSend', []),
            userPreferences: null,
        };
        return data;
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            {
                name: 'Name',
                path: 'landmarks',
                keyName: 'id',
                ...getColumnPreferences('name', preferences),
                customMarkup: (d) => {
                    const link = (
                        <Link to={`/home?landmarkId=${d.id}&zoom=true`} style={{ color: 'inherit' }} onClick={() => this.setCustomBackUrl()}> {d.name} </Link>
                    );
                    return link;
                },
            },
            {
                landmarkGroupName: 'Group Name',
                ...getColumnPreferences('landmarkGroupName', preferences),
                customMarkup: (d) => {
                    let link = d.landmarkGroupName;
                    if (getPermissionValue('Landmark Groups') !== 'None' && d.landmarkGroupId) {
                        link = (
                            <Tooltip title={d.landmarkGroupName || ''} disableFocusListener>
                                <Link
                                    to={`landmark-groups/${d.landmarkGroupId}`}
                                    style={{ color: 'inherit' }}
                                    onClick={() => this.setCustomBackUrl()}
                                >
                                    {d.landmarkGroupName}
                                </Link>
                            </Tooltip>
                        );
                    }
                    return link;
                },
            },
            { totalAssets: 'Assets Present', ...getColumnPreferences('totalAssets', preferences) },
            { description: 'Description', ...getColumnPreferences('description', preferences) },
            { formattedPhoneNumber: 'Phone Number', ...getColumnPreferences('formattedPhoneNumber', preferences) },
            { fullAddress: 'Address', ...getColumnPreferences('fullAddress', preferences) },
            { markerLat: 'Latitude', ...getColumnPreferences('markerLat', preferences), disableSort: true },
            { markerLng: 'Longitude', ...getColumnPreferences('markerLng', preferences), disableSort: true },
            { shape: 'Shape', ...getColumnPreferences('shape', preferences) },
            { fillColor: 'Fill Color', ...getColumnPreferences('fillColor', preferences), disableSort: true },
            { lineColor: 'Line Color', ...getColumnPreferences('lineColor', preferences), disableSort: true },
            { transparency: 'Transparency (%)', ...getColumnPreferences('transparency', preferences), disableSort: true },
            { dateCreated: 'Created Date', ...getColumnPreferences('dateCreated', preferences) },
            // { attributes: 'Custom', show: true, disableSort: true },
        ];
        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    updateSavedPageState = (state) => {
        // call this funciton from component did update only to set the saved page
        this.setState({ ...state }, () => {
            getUserPreference(this, LANDMARK_LIST_PREF_KEY);
        });
    }

    updateEditState = (isAddEdit) => {
        this.setState({ isAddEdit });
    }

    getData = () => {
        const {
            pageNumber, rowsPerPage, order, orderBy,
        } = this.state;
        this.loadLandmarkData(pageNumber, rowsPerPage, order, orderBy, false);
        this.setState({ showListing: true });
    }


    getComma = (data: any, keys: any) => keys.some(d => data[d]);

    getAppliedFilters = () => ({
        landmarkFiltersToSend: this.landmarkFiltersToSend,
        search: this.search,
    })

    loadLandmarkData = (pageNumber, rowsPerPage, order, orderBy, setState = true) => {
        this.props.fetchLandmarksList(
            pageNumber,
            rowsPerPage,
            order, orderBy,
            this.getAppliedFilters(),
        );
        if (setState) {
            const updateUserPref = isUpdatePreference(this, { rowsPerPage, order, orderBy });
            this.setState({
                pageNumber,
                rowsPerPage,
                order,
                orderBy,
            }, () => {
                if (updateUserPref) {
                    saveUserPreference(this, LANDMARK_LIST_PREF_KEY);
                }
            });
        }
    }

    loadNextPage = (
        rows,
        value,
        order,
        orderBy,
    ) => {
        this.loadLandmarkData(rows, value, order, orderBy);
    }

    sortTableColumn = (order, orderBy, page) => {
        this.loadLandmarkData(page.pageNumber, page.rowsPerPage, order, orderBy);
        const params = {
            feature: 'FLEET_Landmark',
            sortColumn: orderBy,
        };
        analytics.track('LANDMARK_SORT', params);
    }

    ListSearch = (order, orderBy, page) => {
        this.search = page.search || '';
        this.loadLandmarkData(page.pageNumber, page.rowsPerPage, order, orderBy);
    }

    refreshList = (page) => {
        const { order, orderBy } = this.state;
        this.loadLandmarkData(page.page, page.rowsPerPage, order, orderBy, false);
    }

    listTypeContextAction = (action, page = {}) => {
        if (action === 'refresh') {
            this.refreshList(page);
        } else if (action === 'add') {
            this.props.history.push(`${this.props.location.pathname}/add?isGeoAddress=false`);
        } else if (action === 'filter') {
            const {
                fltrRanMin, fltrRanMax, isFormValid,
            } = this.state;
            if (this.landmarkFiltersToSend.length > 0 && !isFormValid && page === true) {
                const ranAry = this.landmarkFiltersToSend[0][1].split(':');
                if (ranAry[0] === 'ran') {
                    this.setState({
                        fltrRanMin: ranAry[1],
                        fltrRanMax: ranAry[2],
                    });
                }
            } else if ((fltrRanMin !== '' || fltrRanMax !== '') && !isFormValid && page === true) {
                this.setState({
                    fltrRanMin: '',
                    fltrRanMax: '',
                }, () => { this.landmarkFiltersToSend = []; });
            }
            return this.renderFilters();
        } else if (action === 'export') {
            this.openExport(true);
            analytics.track('EXPORT', { feature: 'FLEET_Landmark' });
        } else if (action === 'import') {
            this.openImport(true);
            analytics.track('IMPORT', { feature: 'FLEET_Landmark' });
        } else if (action === 'delete') {
            if (page.selected.length > 0) {
                this.deleteSelectedLandmarks(page).then();
            }
        }
        return true;
    }

    getLandmarkNameById = (landmarkId) => {
        let landmarkName = '';
        for (let i = 0; i < this.state.tableData.listData.length; i += 1) {
            // $FlowFixMe
            if (landmarkId === this.state.tableData.listData[i].id) {
                landmarkName = this.state.tableData.listData[i].name;
                break;
            }
        }
        return landmarkName;
    }

    deleteSelectedLandmarks = async (landmarks: any) => {
        const notDeletedLandmarks = [];

        try {
            // eslint-disable-next-line no-restricted-syntax
            for (const landmark of landmarks.selected) {
                // eslint-disable-next-line no-await-in-loop
                const response = await bulkDeleteLandmark(landmark);
                if (!response) {
                    const landmarkName = this.getLandmarkNameById(landmark);
                    notDeletedLandmarks.push(landmarkName);
                }
            }
            this.refreshList(landmarks);
            if (notDeletedLandmarks.length > 0) {
                const errorMessage = (
                    <div>
                        <p>The following landmarks could not be deleted:</p>
                        <ol>
                            {notDeletedLandmarks.map(landmark => (
                                <li key={landmark}>{landmark}</li>
                            ))}
                        </ol>
                    </div>
                );
                this.setState({ deletedErrorMessage: errorMessage });
            } else {
                this.setState({ deletedErrorMessage: '' });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }

    handleFilterChanges(e) {
        let val = e.target.value.trimStart().replace(/[^0-9]/g, '');
        if (this.state[e.target.name] !== val) {
            if (parseInt(val, 10)) {
                val = parseInt(val, 10);
            } else {
                val = '';
            }

            if (this.state[e.target.name] !== val) {
                this.setState(
                    {
                        [e.target.name]: val,
                        isFormValid: false,
                    },
                    () => this.getFilteredData(),
                );
            }
        }
    }

    getFilteredData = () => {
        const {
            rowsPerPage, order, orderBy, fltrRanMin, fltrRanMax,
        } = this.state;

        if (fltrRanMin !== '' && fltrRanMax !== '' && fltrRanMax > fltrRanMin && parseInt(fltrRanMax, 10) <= 10000) {
            this.landmarkFiltersToSend = [];
            this.landmarkFiltersToSend.push(['totalAssets', `ran:${fltrRanMin}:${fltrRanMax}`]);
            this.setState(
                {
                    isFormValid: true,
                    pageNumber: 0,
                },
                () => this.loadLandmarkData(0, rowsPerPage, order, orderBy, false),
            );
        }
    }

    clearFilters = () => {
        if (this.landmarkFiltersToSend.length > 0) {
            const {
                rowsPerPage, order, orderBy,
            } = this.state;
            this.landmarkFiltersToSend = [];
            this.setState({
                pageNumber: 0,
                fltrRanMin: '',
                fltrRanMax: '',
            }, () => this.loadLandmarkData(0, rowsPerPage, order, orderBy, false));
        }
    }

    clickRow = (n:any) => {
        this.props.history.push(`/landmarks/edit/${n.id}`);
    };

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: LANDMARK_LIST_PREF_KEY };
        rearrangeColumns(this, listHeaders, options);
    }

    showHideColumn = (header) => {
        const options = { prefKey: LANDMARK_LIST_PREF_KEY };
        showHideColumns(this, header, options);
    }

    renderFilters = () => {
        const styles = {
            fltrContainer: {
                padding: 20,
                flexDirection: 'column',
                cursor: 'default',
            },
            fltrHeader: { display: 'flex' },
            button: {
                flex: 'auto',
                opacity: '.87',
                color: '#007aff',
            },
            subheading: {
                fontSize: 15,
                opacity: '.87',
                padding: '20px 20px 0',
                cursor: 'default',
            },
            title: {
                flex: 'auto',
                fontSize: '20px',
                opacity: '.87',
                padding: 20,
                cursor: 'default',
            },
            mainGrid: {
                margin: 0,
                padding: '0 50px',
            },
            centerGrid: {
                textAlign: 'center',
            },
            textField: {
                width: '100%',
                margin: 0,
                whiteSpace: 'nowrap',
            },
            gridItem: {
                height: 80,
            },
        };

        return (
            <div style={styles.fltrContainer}>
                <div style={styles.fltrHeader}>
                    <Typography variant="h6" id="filterTitle" style={styles.title}>Filters</Typography>
                    <Button
                        color="primary"
                        style={styles.button}
                        onClick={this.clearFilters}
                    >
                        CLEAR
                    </Button>
                </div>
                <Divider />
                <ValidatorForm noValidate autoComplete="off" onSubmit={() => true}>
                    <Typography style={styles.subheading}>Vehicles Present</Typography>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={styles.mainGrid}
                    >
                        <Grid item xs={5} style={styles.gridItem}>
                            <TextValidator
                                id="fltrRanMin"
                                name="fltrRanMin"
                                label="[Min]"
                                style={styles.textField}
                                margin="normal"
                                value={this.state.fltrRanMin}
                                inputProps={{ maxLength: 4 }}
                                validators={['minNumber:0', 'maxNumber:9999', 'compare:lesser-fltrRanMax']}
                                errorMessages={['range is 0 to 9999', 'range is 0 to 9999', 'must be less than max']}
                                onChange={e => this.handleFilterChanges(e)}
                            />
                        </Grid>
                        <Grid item xs={2} style={styles.centerGrid}>to</Grid>
                        <Grid item xs={5} style={styles.gridItem}>
                            <TextValidator
                                id="fltrRanMax"
                                name="fltrRanMax"
                                label="[Max]"
                                style={styles.textField}
                                margin="normal"
                                value={this.state.fltrRanMax}
                                inputProps={{ maxLength: 5 }}
                                validators={['minNumber:1', 'maxNumber:10000', 'compare:greater-fltrRanMin']}
                                errorMessages={['range is 1 to 10000', 'range is 1 to 10000', 'must be greater than min']}
                                onChange={e => this.handleFilterChanges(e)}
                            />
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </div>
        );
    }

    dialogHandleClick = (type, value) => {
        if (type === 'show') {
            this.setState({ selectedOption: value });
        } else if (type === 'download') {
            const {
                tableData,
                selectedOption,
                order,
                orderBy,
            } = this.state;
            const filter = this.getAppliedFilters();
            this.openExport(false);
            this.setState({ loading: true });
            const requestPayLoad = getRequestPayload({
                pageNumber: 0,
                pageSize: tableData.totalRecords,
                order,
                orderBy,
                filter,
            });
            const reportRequestPayload = {
                ...requestPayLoad,
                reportColumns: getHeaderKeysForPost(tableData.listHeaders),
                timeZone: getUserSettingTimeZone(),
                unitMeasurement: getDistanceStringLong(),
                timeFormat: getTimeFormatAsperUserSettting(),
            };
            downloadFile(
                getURL(),
                `Landmarks ${moment().format('YYYY-MM-DD HH-mm-ss')}.${selectedOption.value}`,
                { type: selectedOption.value },
                () => this.setState({ loading: false }),
                reportRequestPayload,
            );
        } else {
            this.openExport(false);
        }
    }

    openExport = openExport => this.setState({ openExport });
    openImport = openImport => this.setState({ openImport });
    closeImport = () => {
        this.setState({ openImport: false });
    }

    setCustomBackUrl = () => {
        const pageConfig = {
            url: '/landmarks',
            pageNumber: this.state.pageNumber,
            rowsPerPage: this.state.rowsPerPage,
            order: this.state.order,
            orderBy: this.state.orderBy,
            searchKey: this.search,
            landmarkFiltersToSend: this.landmarkFiltersToSend,
        };
        savePage(pageConfig);
    }

    updateLoader = (loading) => {
        this.setState({ loading });
    }

    render() {
        const { loading, isAddEdit, openImport } = this.state;
        const { classes } = this.props;
        return (
            <React.Fragment>
                {loading && <AppLoader type="fullScreen" />}
                <MiniDrawer
                    redirectTo={this.props.history.push}
                >
                    {isAddEdit &&
                    <Landmark />
                    }
                    <div className={(!isAddEdit && !openImport) ? '' : classes.hide}>
                        {this.state.showListing ?
                            <ListComponent
                                tableData={this.state.tableData}
                                loadNextPage={this.loadNextPage}
                                isUpdating={this.props.isUpdating}
                                ListSearch={this.ListSearch}
                                resetTable={this.props.resetTable}
                                sortTableColumn={this.sortTableColumn}
                                showHideColumn={this.showHideColumn}
                                listTypeContextAction={this.listTypeContextAction}
                                tableTitle="Landmarks"
                                filtersApplied={this.landmarkFiltersToSend.length > 0}
                                pathName={this.props.location.pathname}
                                clickRow={this.clickRow}
                                redirectTo={this.props.history.push}
                                tableButtons={['Import', 'Export', 'Refresh', /* 'Map', */ 'Filter List', 'Delete', /* 'Edit', */ 'Add Items']}
                                resizableHeader
                                rearrangeColumns={this.rearrangeColumns}
                                dialogboxInfo={{
                                    title: `Export data as ${this.state.selectedOption.lable} for Landmark list`,
                                    dialogHandleClick: this.dialogHandleClick,
                                    showDialog: this.state.openExport,
                                    selectedOption: this.state.selectedOption,
                                }}
                                openImport={this.openImport}
                                order={this.state.order}
                                orderBy={this.state.orderBy}
                                pageNumber={this.state.pageNumber}
                                rowsPerPage={this.state.rowsPerPage}
                                searchKey={this.search}
                            />
                            :
                            <div style={{ height: '100%' }}>
                                <AppLoader type="fullScreen" />
                            </div>
                        }
                    </div>
                    {openImport &&
                        <ImportComponent closeImport={this.closeImport} updateLoader={this.updateLoader} importType="Landmarks" />
                    }
                </MiniDrawer>
                <Dialog
                    open={this.state.deletedErrorMessage !== ''}
                    type="error"
                    customTitle=""
                    button={this.button}
                    content={this.state.deletedErrorMessage}
                    size="lg"
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    landmarks: state.landmarksList.landmarks,
    totalLandmarks: state.landmarksList.totalLandmarks,
    isUpdating: state.landmarksList.isUpdating,
});

export default withStyles(themeStyles)(reduxConnect(LandmarksList, actions, mapStateToProps));

