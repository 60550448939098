/* @flow */

import {
    DISPATCH_FETCH_ASSETS_DETAILS,
    DISPATCH_FETCH_ASSETS_DETAILS_SUCCESS,
    DISPATCH_FETCH_ASSETS_DETAILS_ERROR,
    DISPATCH_SEND_DIRECTION,
    DISPATCH_SEND_DIRECTION_ERROR,
    DISPATCH_SEND_DIRECTION_SUCCESS,
    CLOSE_DIALOG,
} from './constants.dispatchAction';
import type { DispatchTypeAction } from './constants.dispatchAction';

export const fetchDispatchAssetsDetails = (id: string): DispatchTypeAction => ({
    type: DISPATCH_FETCH_ASSETS_DETAILS,
    payload: { id },
});

export const fetchDispatchAssetsDetailsSuccess = (response: {}): DispatchTypeAction => ({
    type: DISPATCH_FETCH_ASSETS_DETAILS_SUCCESS,
    payload: { response },
});

export const fetchDispatchAssetsDetailsError = (error: string): DispatchTypeAction => ({
    type: DISPATCH_FETCH_ASSETS_DETAILS_ERROR,
    payload: { error },
});

export const sendDirection = (requestBody: any): DispatchTypeAction => ({
    type: DISPATCH_SEND_DIRECTION,
    payload: { requestBody },
});

export const sendDirectionSuccess = (successMsg: string): DispatchTypeAction => ({
    type: DISPATCH_SEND_DIRECTION_SUCCESS,
    payload: { successMsg },
});

export const sendDirectionError = (error: string): DispatchTypeAction => ({
    type: DISPATCH_SEND_DIRECTION_ERROR,
    payload: { error },
});

export const closeDispatchDialog = () => ({
    type: CLOSE_DIALOG,
    payload: {},
});
