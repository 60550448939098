import * as constants from './constants.addDriver';
import type { DriverTypeAction } from './constants.addDriver';

export const fetchLicenseStates = (data: Object): DriverTypeAction => ({
    type: constants.FETCH_LICENSE_STATES,
    payload: { ...data },
});

export const fetchLicenseStatesSuccess = (response: Object): DriverTypeAction => {
    let filteredData = [];
    if (response.length > 0) {
        filteredData = response.filter(element => element.active === true).map(element => ({
            id: element.id,
            shortCode: element.shortCode,
        }));
    }
    return ({
        type: constants.FETCH_LICENSE_STATES_SUCCESS,
        payload: { filteredData },
    });
};

export const driveInitProp = () => ({
    type: constants.INIT_DRIVER_PROPS,
    payload: {},
});

export const fetchLicenseStatesError = (error: Object): DriverTypeAction => ({
    type: constants.FETCH_LICENSE_STATES_ERROR,
    payload: { error },
});

export const createKeyFobError = (error: Object): DriverTypeAction => ({
    type: constants.CREATE_KEY_FOB_ERROR,
    payload: { error },
});

export const fetchUnassignedKeyFobs = (data: Object): DriverTypeAction => ({
    type: constants.FETCH_UNASSIGNED_KEY_FOBS,
    payload: { ...data },
});

export const fetchUnassignedKeyFobsSuccess = (response: Object): DriverTypeAction => ({
    type: constants.FETCH_UNASSIGNED_KEY_FOBS_SUCCESS,
    payload: { response },
});

export const fetchUnassignedKeyFobsError = (error: Object): DriverTypeAction => ({
    type: constants.FETCH_UNASSIGNED_KEY_FOBS_ERROR,
    payload: { error },
});

export const addDriver = (data: Object): DriverTypeAction => ({
    type: constants.ADD_DRIVER,
    payload: { ...data },
});

export const addDriverSuccess = (response: Object): DriverTypeAction => ({
    type: constants.ADD_DRIVER_SUCCESS,
    payload: { response },
});

export const addDriverError = (error: Object): DriverTypeAction => ({
    type: constants.ADD_DRIVER_ERROR,
    payload: { error },
});

export const fetchDriver = (data: Object): DriverTypeAction => ({
    type: constants.FETCH_DRIVER,
    payload: { ...data },
});

export const fetchDriverSuccess = (response: Object): DriverTypeAction => ({
    type: constants.FETCH_DRIVER_SUCCESS,
    payload: { response },
});

export const fetchDriverError = (error: Object): DriverTypeAction => ({
    type: constants.FETCH_DRIVER_ERROR,
    payload: { error },
});

export const updateDriver = (data: Object): DriverTypeAction => ({
    type: constants.UPDATE_DRIVER,
    payload: { ...data },
});

export const updateDriverSuccess = (response: Object): DriverTypeAction => ({
    type: constants.UPDATE_DRIVER_SUCCESS,
    payload: { response },
});

export const updateDriverError = (error: Object): DriverTypeAction => ({
    type: constants.UPDATE_DRIVER_ERROR,
    payload: { error },
});

export const deleteDriver = (data: Object): DriverTypeAction => ({
    type: constants.DELETE_DRIVER,
    payload: { ...data },
});

export const deleteDriverSuccess = (): DriverTypeAction => ({
    type: constants.DELETE_DRIVER_SUCCESS,
    payload: {},
});

export const deleteDriverError = (error: Object): DriverTypeAction => ({
    type: constants.DELETE_DRIVER_ERROR,
    payload: { error },
});

export const resendInvite = (data: Object): DriverTypeAction => ({
    type: constants.RESEND_INVITE,
    payload: { ...data },
});

export const resendInviteSuccess = (): DriverTypeAction => ({
    type: constants.RESEND_INVITE_SUCCESS,
    payload: {},
});

export const resendInviteError = (error: Object): DriverTypeAction => ({
    type: constants.RESEND_INVITE_ERROR,
    payload: { error },
});

export const closeDialog = () => ({
    type: constants.CLOSE_DRIVER_DIALOG,
    payload: {},
});

export const updateLoader = (loading: boolean) => ({
    type: constants.UPDATE_DRIVER_LOADER,
    payload: { loading },
});
