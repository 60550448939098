import React from 'react';
import { TextField, FormHelperText } from '@mui/material';
import styles from '../../Steppers/AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';
import { getSpeedUnitString, convertUnitValueAsPerUserSetting } from '../../../../helper-classes/utility-functions';

type Props = {
    formData:{
        config:{
            speedLimitExceedMPH: Number,
        },
    },
    handleTextboxChange: Function,
    validationError: String,
};

class PostedSpeedLimitAlert extends React.Component<Props> {
    state = {
        unit: '',
    };
    UNSAFE_componentWillMount() {
        const unit = getSpeedUnitString();
        this.setState({
            unit,
        });
    }
    render() {
        let { speedLimitExceedMPH = 0 } = this.props.formData.config;
        if (speedLimitExceedMPH) {
            speedLimitExceedMPH = Math.round(speedLimitExceedMPH);
        }
        const minValue = convertUnitValueAsPerUserSetting(ALERT_DETAILS.VALUE.PSL_MIN, 'round');
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    <div className={styles['step-rightside']}>
                        <form noValidate autoComplete="off">
                            <div>{ALERT_DETAILS.LABEL.POSTED_SPEED_LIMIT}</div>
                            <div>
                                <TextField
                                    required
                                    margin="normal"
                                    className={styles['text-wid']}
                                    id="speedLimitExceedMPH"
                                    name="speedLimitExceedMPH"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: minValue,
                                            max: ALERT_DETAILS.VALUE.PSL_MAX,
                                        },
                                    }}
                                    value={speedLimitExceedMPH}
                                    onKeyPress={(event) => {
                                        if (event.charCode === 46) {
                                            event.preventDefault();
                                            return false;
                                        }
                                        return true;
                                    }}
                                    onChange={(e) => {
                                        let inputValue = e.target.value;
                                        if (inputValue !== '') {
                                            inputValue = (Math.trunc(inputValue)).toString();
                                            if (inputValue.indexOf('.') !== -1) {
                                                inputValue = inputValue.substring(1, inputValue.indexOf('.'));
                                            }
                                        }
                                        this.props.handleTextboxChange('speedLimitExceedMPH')({
                                            target: {
                                                value: inputValue,
                                            },
                                        });
                                    }}
                                />
                                <span className={styles['unit-Name']}>{this.state.unit}*</span>
                                <FormHelperText style={{ color: 'red' }}>
                                    {this.props.validationError}
                                </FormHelperText>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default PostedSpeedLimitAlert;
