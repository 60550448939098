/* eslint-disable no-nested-ternary */

import React from 'react';
import { Chip } from '@mui/material';
import { components } from 'react-select';
import 'babel-polyfill';
import AsyncSelect from 'react-select/lib/Async';
import AsyncPaginate from 'react-select-async-paginate';
import DropdownIndicator from '../../assets/icons/dropdown-indicator.svg';
import CustomStyles from './styles.module.scss';
import { MIN_SEARCH_LENGTH, SEARCH_CHAR_LIMIT } from '../../constants/Config';

export type Props = {
    disableChips: Boolean,
    handleDelete: Function,
    loadOptions: Function,
    onItemSelected: Function,
    selectedOptions: Array<{ value: any, label: String, data: {} }>,
    initialOptions: Array<{ value: any, label: String }>,
    styles: {},
    selectStyles: {},
    title: String,
    disablePaging: boolean,
    disableSearch: boolean,
    showSelection: true,
    hideDivider: boolean,
    menuPlacement: String,
}

class AsyncSelectWrapper extends React.Component<Props> {
    handleSelection = (selected) => {
        if (Array.isArray(selected) && selected.length === 0) {
            return;
        }
        this.props.onItemSelected(selected);
    }

    handleInvalids = (value) => {
        const { selectedOptions, initialOptions } = this.props;
        if (initialOptions && Object.keys(initialOptions).length > 0) {
            if (!initialOptions.allowedFreeText &&
                selectedOptions && (selectedOptions.value !== initialOptions.value
                    || selectedOptions.label !== initialOptions.label)) {
                this.props.onItemSelected(initialOptions || {});
            } else if (!selectedOptions && initialOptions.allowedFreeText) {
                this.props.onItemSelected({ value, label: value });
            }
        }
    }

    restrictSearchString = (e) => {
        if (e.target.value.length > SEARCH_CHAR_LIMIT - 1) {
            e.target.value = e.target.value.substring(0, SEARCH_CHAR_LIMIT - 1);
        }
    }

    loadSearchOptions = (val, options) => {
        let truncatedKey = val;
        if (val && val.length > SEARCH_CHAR_LIMIT) {
            truncatedKey = val.substring(0, SEARCH_CHAR_LIMIT);
        }
        if (truncatedKey.length > 0 && truncatedKey.length < MIN_SEARCH_LENGTH) {
            return null;
        }
        return this.props.loadOptions(truncatedKey, options);
    }

    render() {
        const {
            disableChips, handleDelete, loadOptions, selectedOptions, styles,
            selectStyles, title, disablePaging, disableSearch, showSelection,
            hideDivider, menuPlacement,
        } = this.props;

        // NOTE: This fixes a bug with AsyncPaginate not loading on initial load
        // the height must be taller than height of the dropdown to initialize scroll captor
        // https://github.com/JedWatson/react-select/issues/2862#issuecomment-419062652
        const NoOptionsMessage = messageProps => (
            <div style={{ height: 300, padding: 20 }} >
                {messageProps.children}
            </div>
        );

        const Placeholder = ({ children, ...props }) => (
            <components.SingleValue {...props} className={CustomStyles.placeholder}>
                {title}
            </components.SingleValue>
        );

        const Option = props => (
            <components.Option {...props} className={CustomStyles.optionClass} />
        );

        let chips = [];
        if (selectedOptions && selectedOptions.length > 0 && !disableChips) {
            chips = selectedOptions.map(selectedOption => (
                <Chip
                    style={{ margin: 8 }}
                    key={selectedOption.value}
                    label={selectedOption.label}
                    onDelete={() => handleDelete(selectedOption)}
                />
            ));
        }

        const componentOverrides = {
            NoOptionsMessage, Placeholder, Option, IndicatorSeparator: () => null,
        };

        if (!showSelection) {
            const SingleValue = ({ children, ...props }) => (
                <components.SingleValue {...props} className={CustomStyles.placeholder}>
                    {title}
                </components.SingleValue>
            );
            componentOverrides.SingleValue = SingleValue;
        }

        if (hideDivider) {
            const indicatorStyle = {
                display: 'flex', padding: 8, width: 20, height: 20,
            };

            componentOverrides.IndicatorsContainer = () => (
                <div style={
                    styles && styles.indicatorContainer ? styles.indicatorContainer : indicatorStyle
                }
                >
                    <img src={DropdownIndicator} alt="" />
                </div>
            );
        }

        let select;
        if (disablePaging) {
            select = (
                <AsyncSelect
                    onChange={selected => this.handleSelection(selected)}
                    placeholder={title}
                    loadOptions={loadOptions}
                    value={selectedOptions}
                    cacheOptions
                    defaultOptions
                    components={componentOverrides}
                    styles={{
                        ...selectStyles,
                        menu: base => ({
                            ...base,
                            zIndex: 10000,
                        }),
                    }}
                    isSearchable={!disableSearch}
                    menuPlacement={menuPlacement || 'auto'}
                    openMenuOnFocus
                />
            );
        } else {
            select = (
                <AsyncPaginate
                    onChange={selected => this.handleSelection(selected)}
                    placeholder={title}
                    loadOptions={this.loadSearchOptions}
                    value={selectedOptions}
                    components={componentOverrides}
                    styles={{
                        ...selectStyles,
                        root: base => ({ ...base, input: { textTransform: 'uppercase' } }),
                        menu: base => ({
                            ...base,
                            zIndex: 10000,
                        }),
                    }}
                    isSearchable={!disableSearch}
                    onBlur={e => this.handleInvalids(e.target.value)}
                    menuPlacement={menuPlacement || 'auto'}
                    openMenuOnFocus
                    onKeyDown={this.restrictSearchString}
                />
            );
        }

        return (
            <div style={styles && styles.container ? styles.container : { flex: 1, padding: 20 }}>
                <div style={styles && styles.select ? styles.select : {}}>
                    {select}
                </div>
                <div style={styles && styles.chips ? styles.chips : {}}>
                    {chips}
                </div>
            </div>
        );
    }
}
export default AsyncSelectWrapper;
