/* @flow */
/* eslint-disable no-dupe-keys */
/* eslint no-underscore-dangle: ["error", { "allow": ["__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__"] }] */
import React from 'react';
import {
    Button,
    Table,
    TableBody,
    TablePagination,
    Paper,
    Typography,
    Drawer,
    ClickAwayListener,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Dialog from './../../../components/SharedComponents/Dialog/Dialog';
import EnhancedTableToolbar from './ToolbarComponent';
import TableHeaderComponent from './TableHeaderComponent';
import AppLoader from './../../AppLoader';
import ListTableRow from './ListTableRow';
import { getPermissionValue } from '../../../helper-classes/utility-functions';
import { RECORDS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants/Config';
import TablePaginationComponent from './TablePaginationComponent';

export type Props = {
    classes: any,
    loadNextPage: Function,
    isUpdating: boolean,
    redirectTo: Function,
    resetTable: Function,
    addClick: Function,
    clickRow: Function,
    tableTitle: string,
    openImport: Function,
    order: string,
    orderBy: string,
    sortTableColumn: Function,
    rearrangeColumns: Function,
    listTypeContextAction: Function,
    tableButtons: Array<string>,
    advanceFilter: Function,
    ListSearch: Function,
    headerDropDown: Object,
    dialogboxInfo: Object,
    showColumnDropdown: boolean,
    showDialog: boolean,
    showHideColumn: Function,
    filtersApplied: false,
    validationDialogBox: {
        isError: boolean,
        msg: string,
    },
    filterApplied: boolean,
    tableData: {
        listData: Array<{
            children: any,
            expanded: boolean,
            keyName: string,
            id: number,
            isLink: boolean,
            path: string,
        }>,
        listHeaders: Array<{
            id: number,
            isLink: boolean,
            path: string,
            keyName: string,
            children: any,
        }>,
        totalRecords: number,
        ischeckBox: boolean,
        isEditable: boolean,
        isDeletable: boolean,
        isAddButton: boolean,
        addButtonTooltip: string,
        isGroup : boolean,
        addDelete: boolean,
        editTitle: string,
    },
    tableClasses: Object,
    resizableHeader: boolean,
    showSearch: boolean,
    tableMenu: {
        showTableMenu: boolean,
        title: string,
        options: Array<{
            key: string,
            lable: string,
        }>,
        selected: string,
        onChange: Function,
    },
    tableSubTitle: string,
    showToolbarBackButton: boolean,
    backClick: Function,
    rowActions: Object,
    pathName: string,
    autoSuggestive : Object,
    tableNoDataMessage: String,
    radioSelectorOptions: Object,
    overrideStyle: Object,
    hidePagination: boolean,
    language: {
        emptyTable: string,
        zeroRecords: string,
    },
    editIcon: Object,
    isIframe: boolean,
    tableConfig: Object,
    pageNumber: any,
    rowsPerPage: number,
    searchKey: string,
    savedAdvancedFilter: any,
    hideToolbar: boolean,
    setSelectedRow: Function,
    selectedRow: Object,
    permissionKey: string,
    rootClasses: Object,
    footerActionButton: Array<Object>,
    rowsPerPageOptions: Array<number>
};

export type State = {
    data: Array<{
        children: any,
        expanded: boolean,
        keyName: string,
        id: number,
        isLink: boolean,
        path: string,
    }>,
    order: string,
    orderBy: string,
    userPermission: string,
    selected: Array<number>,
    rowsPerPage: number,
    allExpanded: boolean,
    shouldShowLoader: boolean,
    open: boolean,
    validationDialogBox: {
        isError: boolean,
        msg: string,
        header: string,
    },
    minValue: number,
    rowsPerPageOptions: Array<number>
};

const ENABLE_COLUMN_REARRANGE = true;
const ENABLE_SHOW_HIDE_COLUMN = true;

const styles = () => ({
    root: { margin: 15, width: '98%' },
    iframeHeight: { height: '80.6vh' },
    checkbox: { color: '#4a4a4a', opacity: '0.54' },
    checked: { color: '#007aff !important', opacity: 1 },
    drawer: { width: 370 },
    alignCenter: { textAlign: 'center' },
    emptyRow: { height: 288 },
    tableWrapper: {
        'overflow-x': 'scroll',
        height: '78vh',
        maxHeight: '100%',
        position: 'relative',
    },
    loaderStyle: {
        'padding-top': '200px',
        'text-align': 'center',
    },
    table: {
        '& tr:nth-child(even)': { backgroundColor: '#f4f4f4' },
    },
});

const inlineCss = {
    loaderStyle: {
        textAlign: 'center',
        position: 'sticky',
        margin: '30vh auto',
    },
};

class EnhancedTable extends React.Component<Props, State> {
    button: Array<Object>;
    page: number;
    isChanged: boolean;
    language: Object;
    static defaultProps = {
        setSelectedRow: () => {},
        tableClasses: {},
    }
    constructor(props) {
        super(props);

        // FLINT-251 allow dashboard view access to modify
        let permissionValue = getPermissionValue(this.props.permissionKey ? this.props.permissionKey : this.props.tableTitle) || 'None';
        if (this.props.permissionKey && this.props.permissionKey === 'Dashboard') {
            permissionValue = 'Modify';
        }

        this.state = {
            order: this.props.order ? this.props.order : 'asc', // Dont change the default value
            orderBy: this.props.orderBy ? this.props.orderBy : 'name', // Dont change the default value
            userPermission: permissionValue,
            selected: [],
            data: this.props.tableData.listData,
            rowsPerPage: this.props.rowsPerPage ? this.props.rowsPerPage : RECORDS_PER_PAGE,
            shouldShowLoader: false,
            allExpanded: false,
            open: false,
            validationDialogBox: {
                isError: false,
                msg: '',
                header: '',
            },
            minValue: 1,
            rowsPerPageOptions: this.props.rowsPerPageOptions
                ? this.props.rowsPerPageOptions : ROWS_PER_PAGE_OPTIONS,
        };
        this.isChanged = false;
        this.page = 0;
        /* language object introduced for future enhancement */
        /* using this object and props we will override table texts */
        this.language = {
            emptyTable: (this.props.language && this.props.language.emptyTable) || 'No Data Found',
            zeroRecords: (this.props.language && this.props.language.zeroRecords) || 'No Data Found',
            msgToDisplay: '',
            // info: 'Showing _START_ to _END_ of _TOTAL_ entries',
            // infoEmpty: 'Showing 0 to 0 of 0 entries',
            // infoFiltered: '(filtered from _MAX_ total entries)',
            // lengthMenu: 'Show _MENU_ entries',
            // loadingRecords: 'Loading...',
            // processing: 'Processing...',
            // search: 'Search:',
            // paginate: {
            //     first: 'First',
            //     last: 'Last',
            //     next: 'Next',
            //     previous: 'Previous',
            // },
            // aria: {
            //     sortAscending: ': activate to sort column ascending',
            //     sortDescending: ': activate to sort column descending',
            // },
        };
        this.setTableMessage();
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (!nextProps.isUpdating) {
            this.setStateData(nextProps.tableData.listData);
        }
        if (nextProps.rowsPerPage !== this.state.rowsPerPage) {
            this.setState({ rowsPerPage: nextProps.rowsPerPage });
        }
        if (nextProps.orderBy !== this.state.orderBy || nextProps.order !== this.state.order) {
            this.setState({ orderBy: nextProps.orderBy, order: nextProps.order });
        }
        if (nextProps.filtersApplied !== undefined) {
            this.setTableMessage('filter', nextProps.filtersApplied);
        }
        if (nextProps.selectedRow) {
            this.setState({ selected: [nextProps.selectedRow.id] });
        }
    }

    componentWillUnmount() {
        if (this.props.resetTable && typeof this.props.resetTable === 'function') {
            this.props.resetTable();
            this.page = 0;
        }
    }

    setStateData(data) {
        if (this.props.pageNumber >= 0 && this.props.tableData.totalRecords > 0) {
            const newLastPage =
                Math.max(
                    0,
                    Math.ceil(this.props.tableData.totalRecords / this.state.rowsPerPage) - 1,
                );
            this.page = this.props.pageNumber;
            if (newLastPage < this.props.pageNumber && !this.isChanged) {
                this.props.loadNextPage(
                    newLastPage,
                    this.state.rowsPerPage,
                    this.state.order,
                    this.state.orderBy,
                );
                this.isChanged = true;
                this.page = newLastPage;
            }
        }
        if (!data) return;
        let newData = data;
        if (this.state.allExpanded) {
            newData = this.updateExpandAll([...data], true);
        }
        this.setState({ data: newData, selected: [] });
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
        if (this.props.sortTableColumn && typeof this.props.sortTableColumn === 'function') {
            this.props.sortTableColumn(order, orderBy, {
                pageNumber: this.page, rowsPerPage: this.state.rowsPerPage,
            });
        }
    };

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            this.setState(state => ({ selected: state.data.map(n => n.id) }));
            return;
        }
        this.setState({ selected: [] });
    };

    ListSearch = (value: string) => {
        if (this.props.ListSearch && typeof this.props.ListSearch === 'function') {
            const { order, orderBy, rowsPerPage } = this.state;
            const filterData = {
                search: value,
                pageNumber: 0,
                rowsPerPage,
            };
            this.page = 0;
            this.setTableMessage('search', value);
            this.props.ListSearch(order, orderBy, filterData);
        }
    }

    setTableMessage = (type:string = 'default', val:any = '') => {
        this.language.msgToDisplay = this.language.emptyTable;
        switch (type) {
        case 'search':
            if (val !== '') {
                this.language.msgToDisplay = this.language.zeroRecords;
            }
            break;
        case 'filter':
            if (val) {
                this.language.msgToDisplay = this.language.zeroRecords;
            }
            break;
        default:
            this.language.msgToDisplay = this.language.emptyTable;
            break;
        }
    }

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        let time = 0;
        if (this.props.tableData.isGroup) {
            newSelected = (event) ? [id] : [];
            time = 100;
        } else if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setTimeout(() => {
            this.setState({ selected: newSelected });
        }, time);
    };

    handleChangePage = (event, page, tableBody) => {
        this.setState({ selected: [] });
        if (tableBody.length > 0 && event) {
            this.props.loadNextPage(
                page,
                this.state.rowsPerPage,
                this.state.order,
                this.state.orderBy,
            );
            this.page = page;
        }
        /* event is only availabe when you click on next, pre from pagination */
        /* below condition is for filter case & date range filters in scorecard also */
        if (!event) {
            this.page = 0;
        }
    };

    // Save Alert Delete
    handleDoubleClick = (event, n) => {
        if (this.props.clickRow !== undefined) {
            if (event === 'DELETE') {
                this.props.clickRow(n, 'delete');
            } else {
                this.props.clickRow(n);
            }
        }
    }

    handleChangeRowsPerPage = (event) => {
        if (event.target.value === this.state.rowsPerPage) return;
        let nextPageNumber = Math.round((this.state.rowsPerPage * this.page) / event.target.value);
        let lastPage = this.props.tableData.totalRecords / event.target.value;

        if (lastPage <= 1) nextPageNumber = 0;
        lastPage = Math.floor(lastPage);

        if (nextPageNumber > lastPage) nextPageNumber = lastPage;
        this.page = nextPageNumber;
        this.setState({ rowsPerPage: event.target.value });
        this.showLoader();
        this.props.loadNextPage(
            nextPageNumber,
            event.target.value,
            this.state.order,
            this.state.orderBy,
        );
    };

    handleLinkClick = (path, keyName, listItem) => {
        this.props.redirectTo(`/${path}/${listItem[keyName]}`);
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    selectSingleRow = (id) => {
        if (this.state.selected.indexOf(id) !== -1) {
            this.props.setSelectedRow(null);
        } else {
            this.props.setSelectedRow(id);
        }
    };

    showLoader = () => {
        this.setState({ shouldShowLoader: true });

        setTimeout(() => {
            this.setState({ shouldShowLoader: false });
        }, 100);
        return true;
    }

    getAllRows(props: any, data: any, rows: any, style: any) {
        data.forEach((n) => {
            const isSelected = this.isSelected(n.id);
            const rowData = n;
            const row =
                (<ListTableRow
                    data={rowData}
                    key={n.id}
                    listHeaders={props.listHeaders}
                    rowClasses={props.tableClasses ? (props.tableClasses.rowClasses || '') : ''}
                    ischeckBox={props.ischeckBox}
                    isEditable={props.isEditable}
                    isDeletable={props.isDeletable}
                    isAddButton={props.isAddButton}
                    addButtonTooltip={props.addButtonTooltip}
                    addDelete={props.addDelete}
                    editTitle={props.editTitle}
                    isGroup={props.isGroup}
                    handleClick={(event, id) => this.handleClick(event, id)}
                    handleDoubleClick={event => this.handleDoubleClick(event, rowData)}
                    isSelected={isSelected}
                    handleLinkClick={this.handleLinkClick}
                    expandRow={(id, expand) => this.expandRow(id, expand)}
                    iconStyle={style}
                    index={rows.length || 0}
                    rowActions={this.props.rowActions}
                    userPermission={this.state.userPermission}
                    editIcon={this.props.editIcon}
                    selectSingleRow={this.selectSingleRow}
                />);
            rows.push(row);
            if (rowData.children && rowData.children.length > 0 && rowData.expanded) {
                const marginLeft = (style.marginLeft || 0) + 25;
                this.getAllRows(props, rowData.children, rows, { marginLeft });
            }
        });
        return rows;
    }

    updateExpandState(data, id, expand) {
        const newData = data;
        for (let i = 0; i < data.length; i += 1) {
            const row = data[i];
            if (row.id === id) {
                row.expanded = expand;
                newData[i] = row;
                break;
            } else if (row.children && row.children.length > 0) {
                row.children = this.updateExpandState(row.children, id, expand);
                newData[i] = row;
            }
        }
        return newData;
    }

    updateExpandAll(data, expand) {
        const newData = data;
        for (let i = 0; i < data.length; i += 1) {
            const row = data[i];
            row.expanded = expand;
            newData[i] = row;
            if (row.children && row.children.length > 0) {
                row.children =
                    this.updateExpandAll(row.children, expand);
                newData[i] = row;
            }
        }
        return newData;
    }

    expandRow(id, expand) {
        const { data } = this.state;
        let allExpanded = false;
        if (id === 'all') {
            allExpanded = expand;
            const newData = this.updateExpandAll([...data], expand);
            this.setState({ allExpanded, data: [...newData] });
        } else {
            const newData = this.updateExpandState([...data], id, expand);
            this.setState({ data: [...newData] });
        }
    }

    getTableBody() {
        const {
            listHeaders, ischeckBox, isEditable, isDeletable,
            addDelete, isGroup, isAddButton, editTitle, addButtonTooltip,
        } = this.props.tableData;
        const { data } = this.state;
        const props = {
            listHeaders,
            ischeckBox,
            isEditable,
            isDeletable,
            addDelete,
            isGroup,
            isAddButton,
            editTitle,
            addButtonTooltip,
            tableClasses: this.props.tableClasses || {},
        };
        return this.getAllRows(props, data, [], {});
    }

    advanceFilter = (data) => {
        if (this.props.advanceFilter && typeof this.props.advanceFilter === 'function') {
            this.props.advanceFilter(data);
        }
    }

    handleToolbarIconClick = (open, clickedIcon = '') => {
        const { pathName, listTypeContextAction } = this.props;
        const { rowsPerPage } = this.state;

        switch (clickedIcon.toString().toLowerCase()) {
        case 'filter list':
            this.setState({
                open: !this.state.open,
            });
            listTypeContextAction('filter', !this.state.open);
            break;
        case 'add items':
            listTypeContextAction('add');
            break;
        case 'edit':
            if (!this.state.selected || this.state.selected.length === 0) {
                const pageName = this.props.tableTitle ? ` ${this.props.tableTitle}` : '';
                this.setState({
                    validationDialogBox: {
                        isError: true,
                        msg: `Please select a record ${pageName !== '' ? ' from' : ''} ${pageName} to edit`,
                        header: `Edit ${pageName}`,
                    },
                });
            } else if (this.state.selected.length > 1) {
                const pageName = this.props.tableTitle ? `  ${this.props.tableTitle}` : '';
                this.setState({
                    validationDialogBox: {
                        isError: true,
                        msg: `Please select only one record ${pageName !== '' ? ' from' : ''} ${pageName} to edit`,
                        header: `Edit ${pageName}`,
                    },
                });
            } else if (this.state.selected.length === 1) {
                this.props.redirectTo(`${pathName}/edit/${this.state.selected[0]}`);
            }
            break;
        case 'delete':
            listTypeContextAction('delete', { selected: this.state.selected, page: this.page, rowsPerPage });
            break;
        case 'export':
            listTypeContextAction('export');
            break;
        case 'import':
            listTypeContextAction('import');
            break;
        case 'refresh':
            listTypeContextAction('refresh', { page: this.page, rowsPerPage });
            break;
        default:
            if (!document.getElementById('picker-popover')) {
                this.setState({
                    open: false,
                });
            }
        }
    };

    getType = () => {
        let type = '';
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.closePostDialogBox(),
        }];
        if (this.state.validationDialogBox.isError) {
            type = 'error';
        }
        return type;
    }

    closePostDialogBox = () => {
        this.setState({
            validationDialogBox: {
                isError: false,
                header: '',
                msg: '',
            },
        });
    }

    isDisablePagination = () => (this.props.tableData.ischeckBox && this.state.selected.length > 0);

    getActionsComponent = (tableBody) => {
        const { rowsPerPage, minValue } = this.state;
        const { footerActionButton = [] } = this.props;
        return (
            <React.Fragment>
                <TablePaginationComponent
                    rowsPerPage={rowsPerPage}
                    count={this.props.tableData.totalRecords}
                    onChangePage={(event, pageNumber) =>
                        this.handleChangePage(event, pageNumber, tableBody)}
                    page={this.page}
                    minValue={minValue}
                    disabled={this.isDisablePagination()}
                />
                {footerActionButton.map(button => (
                    <div key={button.text} style={{ padding: '0 12px 0 0' }}>
                        <Button
                            color={button.color}
                            variant={button.variant}
                            onClick={button.onClick}
                        >{button.text}
                        </Button>
                    </div>
                ))}
            </React.Fragment>
        );
    }

    render() {
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
        const {
            classes,
            showSearch = true,
            autoSuggestive = { isSuggestion: false },
            headerDropDown = { showDropDown: false },
            tableMenu = { showTableMenu: false },
            dialogboxInfo = { showDialog: false },
            overrideStyle,
            showColumnDropdown = ENABLE_SHOW_HIDE_COLUMN && this.props.showHideColumn && typeof this.props.showHideColumn === 'function',
            isIframe,
            hideToolbar,
            rootClasses,
        } = this.props;
        const {
            data, selected, rowsPerPage, order, orderBy, validationDialogBox, userPermission,
            rowsPerPageOptions,
        } = this.state;
        const { listHeaders, totalRecords } = this.props.tableData;
        const tableBody = this.getTableBody();

        return (data.length >= 0 && listHeaders.length > 0) ?
            <Paper
                className={!rootClasses ? classes.root : rootClasses}
                id="asset-list-container"
                style={overrideStyle ? overrideStyle.paper : {}}
            >
                {!hideToolbar && <EnhancedTableToolbar
                    numSelected={selected.length}
                    toolbarTitle={this.props.tableTitle}
                    tbButtons={this.props.tableButtons}
                    tableMenu={tableMenu}
                    tableDataCount={totalRecords || 0}
                    filterApplied={this.props.filtersApplied || false}
                    dialogboxInfo={dialogboxInfo}
                    onToolbarIconClick={this.handleToolbarIconClick}
                    openImport={this.props.openImport}
                    advanceFilter={filterData => this.advanceFilter(filterData)}
                    listHeaders={listHeaders}
                    ListSearch={this.ListSearch}
                    toolbarSubTitle={this.props.tableSubTitle}
                    showColumnDropdown={showColumnDropdown}
                    showHideColumn={this.props.showHideColumn}
                    showToolbarBackButton={this.props.showToolbarBackButton}
                    backClick={this.props.backClick}
                    showSearch={showSearch}
                    autoSuggestive={autoSuggestive}
                    userPermission={userPermission}
                    headerDropDown={headerDropDown}
                    radioSelectorOptions={this.props.radioSelectorOptions}
                    searchKey={this.props.searchKey}
                    savedAdvancedFilter={this.props.savedAdvancedFilter}
                />}
                <div
                    className={
                        `${classes.tableWrapper}
                        ${isIframe ? classes.iframeHeight : ''}`
                    }
                    style={overrideStyle ? overrideStyle.tableWrapper : {}}
                >
                    <Table
                        id="list-table"
                        className={classes.table}
                        style={overrideStyle ? overrideStyle.table : {}}
                        aria-labelledby="tableTitle"
                    >
                        <TableHeaderComponent
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            expandRow={(id, expand) => this.expandRow(id, expand)}
                            isExpanded={this.state.allExpanded}
                            iconStyle={{}}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rearrangeColumns={this.props.rearrangeColumns}
                            enableColumnRearrange={ENABLE_COLUMN_REARRANGE}
                            rowCount={data.length}
                            tableData={this.props.tableData}
                            sortTableColumn={this.props.sortTableColumn}
                            resizableHeader={this.props.resizableHeader}
                            rowActions={this.props.rowActions}
                            userPermission={userPermission}
                        />
                        <TableBody key="tablebody">
                            {!this.props.isUpdating &&
                                tableBody
                            }
                        </TableBody>
                    </Table>
                    {
                        (this.props.isUpdating || this.state.shouldShowLoader) ?
                            <AppLoader loaderStyle={inlineCss.loaderStyle} />
                            :
                            [
                                totalRecords === 0 &&
                                <Typography
                                    style={inlineCss.loaderStyle}
                                    variant="h6"
                                    key="totalRecords"
                                >
                                    {this.language.msgToDisplay}
                                </Typography>,
                            ]
                    }
                </div>
                {
                    !this.props.hidePagination &&
                    <TablePagination
                        sx={{ '& .MuiToolbar-root': { minHeight: '56px' } }}
                        component="div"
                        id="table-pagination"
                        ActionsComponent={() => this.getActionsComponent(tableBody)}
                        count={totalRecords}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        page={this.page}
                        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                        onPageChange={(event, pageNumber) =>
                            this.handleChangePage(event, pageNumber, tableBody)}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                        SelectProps={{
                            disabled: this.isDisablePagination(),
                        }}
                    />
                }
                <Drawer anchor="right" open={this.state.open}>
                    <div
                        className={classes.drawer}
                        tabIndex={0}
                        role="button"
                        style={{ outline: 'none' }}
                    >
                        {
                            this.props.listTypeContextAction &&
                            <ClickAwayListener onClickAway={this.handleToolbarIconClick}>
                                <div>{this.props.listTypeContextAction('filter')}</div>
                            </ClickAwayListener>
                        }
                    </div>
                </Drawer>
                <Dialog
                    open={validationDialogBox.isError}
                    type={this.getType()}
                    customTitle={validationDialogBox.header}
                    button={this.button}
                    content={validationDialogBox.msg}
                    size="lg"
                />
            </Paper> :
            <div>
                {
                    (data.length === 0 && this.props.isUpdating === true) ?
                        <AppLoader loaderStyle={classes.loaderStyle} />
                        :
                        <Typography style={{ textAlign: 'center', marginTop: '12px' }} variant="h4" gutterBottom>
                            {this.language.emptyTable}
                        </Typography>
                }
            </div>;
    }
}

export default withStyles(styles)(EnhancedTable);
