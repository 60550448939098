/* @flow */
import React, { Component } from 'react';

import { reduxConnect } from '../../hoc';
import Dialog from './../../components/SharedComponents/Dialog/Dialog';
import AssetGroupManageComponent from '../../components/AssetGroupManage/AssetGroupManage';
import * as actions from './actions.assetGroupManage';
import { getParamValue } from '../../helper-classes/utility-functions';
import AppLoader from '../../components/AppLoader';
import { AVAILABLE_ASSET_FILTER, SELECTED_ASSET_LIMIT } from './constants.assetGroupManage';

export type Props = {
    history: {
        push: Function,
        goBack: Function,
    },
    fetchAssets: Function,
    assets: Object,
    isUpdating: boolean,
    postAssets: Function,
    updateAssetGroup: Function,
    fetchSelectedAssets: Function,
    selectedAssets: Object,
    isSelectedResultLoading: boolean,
    isPostAssetLoading: boolean,
    fetchAssetDetailsFromGlobalId: Function,
    assetGroupDetails: Object,
    isAssetGroupDetailsFetching: Object,
    addedSuccessfully: boolean,
    isError: boolean,
    error: string,
    closeDialog: Function,
    showLoader: Function,
};

export type State = {
    assets: Array<any>,
    totalAssets: number,
    selectedAssets: Array<any>,
    clearAssets: boolean,
};
class AssetGroupManage extends Component<Props, State> {
    props: Props;
    initialLoad : boolean;
    totalAssetsLoaded: number;
    savedGroupName: string;
    button: Array<Object>;
    constructor(props) {
        super(props);
        this.state = {
            assets: [],
            totalAssets: 0,
            selectedAssets: [],
            clearAssets: false,
        };
        this.initialLoad = true;
        this.totalAssetsLoaded = 0;
        this.savedGroupName = '';
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
        }];
    }

    componentDidMount() {
        this.getAssetGroupDetails();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.assets && nextProps.assets.data
        && this.props.assets.data !== nextProps.assets.data) {
            let loadedAssets = this.state.assets;
            if (this.state.clearAssets) {
                loadedAssets = nextProps.assets.data;
            } else {
                loadedAssets = loadedAssets.concat(nextProps.assets.data);
            }
            this.totalAssetsLoaded = loadedAssets.length;
            this.setState({
                assets: loadedAssets,
                totalAssets: nextProps.assets.total,
                clearAssets: false,
            });
        }
        if (nextProps.selectedAssets && nextProps.selectedAssets.data
            && this.props.selectedAssets.data !== nextProps.selectedAssets.data) {
            const selectedAssetGroupData = nextProps.selectedAssets.data;
            this.setState({
                selectedAssets: selectedAssetGroupData || [],
            });
        }

        if (nextProps.assetGroupDetails && nextProps.assetGroupDetails.data
            && this.props.assetGroupDetails.data !== nextProps.assetGroupDetails.data) {
            const [assetGroupDetails] = nextProps.assetGroupDetails.data;
            if (getParamValue(this.props, 'groupId')) {
                this.selectedAssetGroup = {
                    id: assetGroupDetails.id,
                    name: assetGroupDetails.name,
                    globalId: assetGroupDetails.id,
                    parentGroupId: assetGroupDetails.parentId || '',
                };
                const params = {
                    selectedAssetGroup: [this.selectedAssetGroup],
                    selectedAssets: [],
                    selectedAssetFilters: [],
                };
                this.loadSelectedAssets(params);
            } else if (getParamValue(this.props, 'parentId')) {
                this.parentGroup = {
                    id: assetGroupDetails.id,
                    name: assetGroupDetails.name,
                };
            }
        }
    }

    selectedAssetGroup = {};
    parentGroup = {
        id: 0,
        name: '',
    };
    actionType = '';
    sortParams = {
        property: 'assetName',
        direction: 'ASC',
    };
    availbleAssetSearchText = '';

    getAssetGroupDetails = () => {
        if (getParamValue(this.props, 'groupId')) {
            this.actionType = 'edit';
            this.props.fetchAssetDetailsFromGlobalId(getParamValue(this.props, 'groupId'));
        } else {
            this.selectedAssetGroup = {};
            this.actionType = 'add';
        }

        if (getParamValue(this.props, 'parentId')) {
            this.props.fetchAssetDetailsFromGlobalId(getParamValue(this.props, 'parentId'));
        }
    }

    getLoadAssetFVSQuery = (offset, params) => {
        if (params.clearResult) this.setState({ assets: [] });

        if (offset === 0) this.setState({ clearAssets: true });
        const query = {
            sortParam: [[params.sort.orderBy, params.sort.order]],
            filterParam: AVAILABLE_ASSET_FILTER,
            advanceSearchParam: [],
            searchParams: [],
        };

        if (params.search) {
            query.searchParams = [params.search];
        } else delete query.searchParams;

        return query;
    }

    loadAssets = (offset, limit, params) => {
        const queryConfig = this.getLoadAssetFVSQuery(offset, params);
        this.props.fetchAssets(offset, limit, queryConfig);
    }

    selectedAssetFiltersFVSQuery = params => ({
        searchParams: [params.selectedAssetGroup[0].globalId], type: 'groupId', start: 0, limit: SELECTED_ASSET_LIMIT,
    })

    loadSelectedAssets = (params) => {
        this.props.fetchSelectedAssets(this.selectedAssetFiltersFVSQuery(params));
    }

    resetAssets = (params) => {
        this.setState({ selectedAssets: params.selectedAssets });
    }

    postAssetGroup = (requestBody, groupName) => {
        let isNewGroupName = true;
        if (this.actionType === 'edit' && this.selectedAssetGroup.name === groupName) {
            isNewGroupName = false;
        }
        this.props.postAssets(requestBody, groupName, isNewGroupName);
    }

    updateAssetGroup = (requestBody, groupName) => {
        let isNewGroupName = true;
        if (this.actionType === 'edit' && this.selectedAssetGroup.name === groupName) {
            isNewGroupName = false;
        }
        this.props.updateAssetGroup(requestBody, groupName, isNewGroupName);
    }

    getContent = () => {
        const {
            isError,
            addedSuccessfully,
            error,
        } = this.props;
        let content = '';
        if (this.actionType === 'edit') {
            if (isError) {
                content = `Edit Asset Group Error : ${error}`;
            } else if (addedSuccessfully) {
                content = 'Asset Group updated successfully';
            }
        } else if (isError) {
            content = `Add Asset Group Error : ${error}`;
        } else if (addedSuccessfully) {
            content = 'Asset Group added successfully';
        }
        return content;
    }

    handleClickOk = () => {
        const { addedSuccessfully } = this.props;
        this.props.closeDialog();
        if (addedSuccessfully) {
            this.props.history.goBack();
        }
    }

    getType = () => {
        const { isError, addedSuccessfully } = this.props;
        let type = '';
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
        }];
        if (addedSuccessfully) {
            type = 'success';
        } else if (isError) {
            type = 'error';
        }
        return type;
    }

    render() {
        const {
            addedSuccessfully,
            isError,
        } = this.props;
        return (
            <div className="app">
                {this.props.isPostAssetLoading ||
                this.props.isAssetGroupDetailsFetching || this.props.isSelectedResultLoading
                    ? <AppLoader type="fullScreen" /> : ''}
                <AssetGroupManageComponent
                    assets={this.state.assets}
                    totalAssets={this.state.totalAssets}
                    loadAssets={this.loadAssets}
                    resetAssets={this.resetAssets}
                    isAssetsListUpdating={this.props.isUpdating}
                    postAssetGroup={this.postAssetGroup}
                    updateAssetGroup={this.updateAssetGroup}
                    selectedAssets={this.state.selectedAssets}
                    actionType={this.actionType}
                    selectedAssetGroup={this.selectedAssetGroup}
                    isSelectedAssetstLoading={this.props.isSelectedResultLoading}
                    loadSelectedAssets={this.loadSelectedAssets}
                    parentGroup={this.parentGroup}
                    history={this.props.history}
                    isAssetGroupDetailsFetching={this.props.isAssetGroupDetailsFetching}
                    showLoader={this.props.showLoader}
                />
                <Dialog
                    open={addedSuccessfully || isError}
                    type={this.getType()}
                    customTitle=""
                    button={this.button}
                    content={this.getContent()}
                    size="lg"
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    assets: state.assetGroupManage.assets,
    selectedAssets: state.assetGroupManage.selectedAssets,
    isSelectedResultLoading: state.assetGroupManage.isSelectedResultLoading,
    assetPostResponse: state.assetGroupManage.assetPostResponse,
    isPostAssetLoading: state.assetGroupManage.isPostAssetLoading,
    assetGroupDetails: state.assetGroupManage.assetGroupDetails,
    isAssetGroupDetailsFetching: state.assetGroupManage.isAssetGroupDetailsFetching,
    isUpdating: state.assetGroupManage.isUpdating,
    error: state.assetGroupManage.error,
    isError: state.assetGroupManage.isError,
    addedSuccessfully: state.assetGroupManage.addedSuccessfully,
});
export default reduxConnect(AssetGroupManage, actions, mapStateToProps);
