/* @flow */
import {
    POST_ALERT_DATA,
    POST_ALERT_DATA_SUCCESS,
    POST_ALERT_DATA_ERROR,
    CLEAR_REDUCER,
} from './constants.stepers';

const initialState = {
    saveAlert: [],
    isLoading: false,
    isError: false,
};

type stateType = {
    saveAlert: Array<Object>,
    isLoading: boolean,
    isError: boolean,
};

type actionType = {
    type: string,
    payload: {
        saveAlert: {},
    },
};

export default (state: stateType = initialState, action: actionType) => {
    switch (action.type) {
    case POST_ALERT_DATA:
        return {
            ...state,
            isLoading: true,
            isError: false,
        };
    case POST_ALERT_DATA_SUCCESS:
        return {
            ...state,
            isLoading: false,
            saveAlert: action.payload.saveAlert,
            isError: false,
        };
    case POST_ALERT_DATA_ERROR:
        return {
            ...state,
            isLoading: false,
            isError: true,
        };
    case CLEAR_REDUCER:
        return {
            ...state,
            isError: false,
        };
    default:
        return state;
    }
};
