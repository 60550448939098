/* flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import MiniDrawer from '../../SideMenu/SideMenuDrawer';
import AlertLandingPageList from '../../../containers/Alerts/AlertLandingPage/AlertLandingPageList';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

type Props = {
    history: {
        push: Function,
    },
};

class AlertLandingPage extends Component<Props> {
    state = {};
    render() {
        return (
            <MiniDrawer redirectTo={this.props.history.push}>
                <div id="landing-page-container" style={{ margin: '0 18px 18px 18px' }}>
                    <AlertLandingPageList />
                </div>
            </MiniDrawer>
        );
    }
}

export default withStyles(styles)(AlertLandingPage);
