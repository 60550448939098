import { combineEpics } from 'redux-observable';
import { ADD_USERROLE, EDIT_USERROLE, DELETE_USERROLE, FETCH_USERROLE, FETCH_USERROLES } from './constants.userRole';
import {
    addUserRoleSuccess,
    addUserRoleError,
    editUserRoleSuccess,
    editUserRoleError,
    deleteUserRoleSuccess,
    deleteUserRoleError,
    fetchUserRoleSuccess,
    fetchUserRoleError,
    fetchUserRolesSuccess,
    fetchUserRolesError,
} from './actions.userRole';
import config from '../../../constants/Config';
import { getGrailsAppBasedURL, getAuthorizationHeader } from '../../../helper-classes/utility-functions';

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    deleteJSON: Function,
    put: Function,
    of: Function
}
export const addUserRole =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) => actions$
        .ofType(ADD_USERROLE)
        .distinctUntilChanged()
        .debounceTime(config.get('DEBOUNCE_TIME'))
        .mergeMap((action) => {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: getAuthorizationHeader(),
            };
            return postJSON(`${getGrailsAppBasedURL()}/rest/json/accountRole?includeDefaultRoles=true`, JSON.stringify(action.payload.requestBody), headers)
                .map((result) => {
                    if (result.response && result.response.success) {
                        return addUserRoleSuccess(result);
                    }
                    return addUserRoleError(result.response);
                })
                .catch(error => of(addUserRoleError(error.response || error)));
        });

export const editUserRole =
    (actions$: Function, store: Object, { put, of }: ObservarblesTypes) => actions$
        .ofType(EDIT_USERROLE)
        .distinctUntilChanged()
        .debounceTime(config.get('DEBOUNCE_TIME'))
        .mergeMap((action) => {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: getAuthorizationHeader(),
            };
            return put(`${getGrailsAppBasedURL()}/rest/json/accountRole/${action.payload.requestBody.id}`, JSON.stringify(action.payload.requestBody), headers)
                .map((result) => {
                    if (result.response && result.response.success) {
                        return editUserRoleSuccess(result);
                    }
                    return editUserRoleError(result.response);
                })
                .catch(error => of(editUserRoleError(error.response || error)));
        });


export const deleteUserRole =
    (actions$: Function, store: Object, { deleteJSON, of }: ObservarblesTypes) => actions$
        .ofType(DELETE_USERROLE)
        .distinctUntilChanged()
        .debounceTime(config.get('DEBOUNCE_TIME'))
        .mergeMap((action) => {
            const { id } = action.payload;
            const headers = {
                'Content-Type': 'application/json',
                Authorization: getAuthorizationHeader(),
            };
            return deleteJSON(`${getGrailsAppBasedURL()}/rest/json/accountRole/${id}?&filterOperator=or`, headers)
                .map((result) => {
                    if (result.response && result.response.success) {
                        return deleteUserRoleSuccess();
                    }
                    return deleteUserRoleError(result.response);
                })
                .catch(error => of(deleteUserRoleError(error.response || error)));
        });

export const getUserRole =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_USERROLE)
            .distinctUntilChanged()
            .debounceTime(1000)
            .mergeMap((action) => {
                const { id } = action.payload;
                const headers = {
                    Authorization: getAuthorizationHeader(),
                };
                const filter = `[{"property":"id","value":"${id}","comparison":"eq"}]`;
                const sort = '[{"property":"name","direction":"ASC"}]';
                const reqURL = `includeDefaultRoles=true&page=1&offset=0&max=1&sorts=${escape(sort)}&filter=${escape(filter)}`;
                return getJSON(`${getGrailsAppBasedURL()}/rest/json/accountRole?${reqURL}`, headers)
                    .map(result => fetchUserRoleSuccess(result.data[0]))
                    .catch(error => of(fetchUserRoleError(error)));
            });

export const getUserRoles =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_USERROLES)
            .distinctUntilChanged()
            .debounceTime(1000)
            .mergeMap(() => {
                const headers = {
                    Authorization: getAuthorizationHeader(),
                };
                const filter = '[{"property":"displayGroup","value":"none","comparison":"ne"},{"property":"system_id","value":"13","comparison":"eq"},{"property":"code","value":"MASTER_USER","comparison":"ne"},{"property":"code","value":"ELD_MANAGER","comparison":"ne"}]';
                const sort = '[{"property":"name","direction":"ASC"}]';
                const reqURL = `includeDefaultRoles=true&page=1&offset=0&max=50&sorts=${escape(sort)}&filter=${escape(filter)}`;
                return getJSON(`${getGrailsAppBasedURL()}/rest/json/accountRole?${reqURL}`, headers)
                    .map(result => fetchUserRolesSuccess(result.data))
                    .catch(error => of(fetchUserRolesError(error)));
            });

export default combineEpics(getUserRole, getUserRoles, addUserRole, editUserRole, deleteUserRole);
