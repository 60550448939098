/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_ALERT_HISTORY } from './constants.alertHistory';
import { fetchAlertHistoryError, fetchAlertHistorySuccess } from './actions.alertHistory';
import { convertDateTime, getGrailsAppBasedURL, getTimeFormatAsperUserSettting, getAuthorizationHeader } from '../../../helper-classes/utility-functions';

type ObservablesTypes = {
    getJSON: Function,
    of: Function,
};

const formatData = (data: any) => {
    if (!data && data.length <= 0) return [];

    const timeFormat = getTimeFormatAsperUserSettting() === '24h' ? 'MM/DD/YYYY H:mm' : 'MM/DD/YYYY h:mm A';
    return data.map((d) => {
        const alertHistory = Object.assign({}, d);
        if (d.alertDate) {
            alertHistory.alertDate = convertDateTime(d.alertDate, timeFormat);
        }
        return alertHistory;
    });
};

export const getAlertHistory = (
    actions$: Function,
    store: Object,
    { getJSON, of }: ObservablesTypes,
) =>
    actions$.ofType(FETCH_ALERT_HISTORY).mergeMap((action) => {
        const {
            pageNumber, pageSize, order, orderBy, alertHistoryFiltersToSend,
        } = action.payload;

        const sort = `[{"property":"${orderBy}","direction":"${order.toUpperCase()}"}]`;
        const sortEncoded = encodeURIComponent(sort);
        const filters = alertHistoryFiltersToSend;
        const filtersEncoded = encodeURIComponent(filters);
        let params = 'doCount= true';
        params += `&page=${pageNumber}&offset=${pageNumber * pageSize}&max=${pageSize}`;
        params += `&sorts=${sortEncoded}`;
        params += `&filters=${filtersEncoded}`;

        const headers = {
            Authorization: getAuthorizationHeader(),
        };
        return getJSON(
            `${getGrailsAppBasedURL()}/operation/json/deviceAlertRestService/get?${params}`,
            headers,
        )
            .map((result) => {
                const formatedData = formatData(result.data);
                return fetchAlertHistorySuccess(formatedData, result.total);
            })
            .catch(error => of(fetchAlertHistoryError(error)));
    });

export default combineEpics(getAlertHistory);
