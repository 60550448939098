import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export type Props = {
    classes: Object,
    pretext: string,
    options: Array<string>,
    onChange: Function,
    defaultSelection: string,
}

export type State = {
    currentSelected: string,
};

const styles = theme => ({
    root: {
        display: 'flex',
        margin: 0,
        paddingTop: theme.spacing(0.5),
    },
    pretext: {
        fontSize: '14px',
        opacity: 0.87,
        margin: `auto ${theme.spacing(2)}`,
    },
    label: {
        opacity: 0.54,
        fontSize: '14px',
    },
    group: {
        flexDirection: 'row',
    },
});

class RadioSelector extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            value: props.defaultSelection,
        };
    }

    handleChange = (event) => {
        const {
            value,
        } = event.target;

        this.setState({ value });
        this.props.onChange(value);
    };

    createOptions = () => {
        const {
            classes,
            options,
        } = this.props;

        const formOptions = [...options];
        return formOptions.map(option => <FormControlLabel key={option} classes={{ label: classes.label }} value={option} label={option} control={<Radio color="primary" />} />);
    }

    render() {
        const {
            value,
        } = this.state;

        const {
            classes,
            pretext,
        } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.pretext}>{pretext}</div>
                <FormControl component="fieldset">
                    <RadioGroup
                        className={classes.group}
                        value={value}
                        onChange={this.handleChange}
                    >
                        {this.createOptions()}
                    </RadioGroup>
                </FormControl>
            </div>
        );
    }
}


export default withStyles(styles)(RadioSelector);
