import React, { Component } from 'react';
import queryString from 'query-string';
import LookStepper from '../../../components/Dashboard/Steppers/LookStepper';
import MiniDrawer from '../../../components/SideMenu/SideMenuDrawer';
import { reduxConnect } from '../../../hoc';
import * as actionConfigureDashboard from '../../ConfigureDashboard/actions.configureDashboard';
import { LOOK_DETAILS } from './constants.looks';
import Dialog from '../../../components/SharedComponents/Dialog/Dialog';

type Props = {
    history: {
        push: Function,
    },
    location: {
        search: String,
    },
    fetchAddedLooks: Function,
    clearLookReducer: Function,
};

class LookSteps extends Component<Props> {
    state = {
        formValues: 'stuff',
        lookNameCheckInProgress: false,
        lookNameError: ' ',
        validationError: '',
        showDialog: false,
        assetCriteria: {
            assets: '',
            groups: '',
            tags: '',
            type: '',
        },
        isLookAddedSuccess: false,
        editLook: false,
        addedLookName: '',
    };

    UNSAFE_componentWillMount() {
        const { lookType, id } = queryString.parse(this.props.location.search);
        if (id) {
            this.props.fetchAddedLooks(
                0,
                1000,
                'desc',
                'dateCreated',
                '',
                true,
            );
            this.setState({
                editLook: true,
                addedLookName: '',
                lookNameError: '',
            });
        } else {
            this.setState({
                editLook: false,
                addedLookName: '',
                lookType,
            });
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.isLoading === false && this.state.lookNameCheckInProgress) {
            let lookNameError = '';
            if (newProps.lookNameList.data && newProps.lookNameList.data.length > 0) {
                lookNameError = LOOK_DETAILS.LOOK_NAME_UNIQUE;
            }
            this.setState({
                lookNameError,
                lookNameCheckInProgress: false,
            });
        }

        if (newProps.isLookAdded && newProps.isLookAdded !== this.state.isLookAddedSuccess) {
            if (newProps.isLookAdded) {
                this.props.clearLookReducer();
                this.setState({
                    showDialog: true,
                });
            }
        }
        if (this.state.editLook && newProps.isUpdating === false &&
            newProps.addedLooks.length > 0 && this.state.addedLookName === '') {
            const lookObject = newProps.addedLooks[0];
            const addedLookName = lookObject.name;
            this.setState({
                formValues: lookObject,
                addedLookName,
            }, () => {});
        }
    }

    handleLookTypeChange = (lookType) => {
        this.setState({
            lookType,
            lookNameCheckInProgress: false,
            lookNameError: ' ',
            validationError: '',
        });
    };

    setLookId = (typeId) => {
        this.setState({
            ...this.state.formValues,
            formValues: {
                ...this.state.formValues,
                typeId,
            },
        });
    };

    setLookType = (lookType) => {
        this.setState({
            lookType,
        }, () => {
            this.setFormValues();
        });
    };

    onCancel = () => {
        this.props.history.push('/configure-dashboard');
    };

    handleClose = () => {
        this.props.history.push('/configure-dashboard');
    };

    handleInputChange = (e) => {
        if (e !== undefined) {
            this.setState({
                ...this.state.formValues,
                formValues: {
                    ...this.state.formValues,
                    [e.target.name]: e.target.value,
                },
            });
        }
    };

    handleTextboxChange = name => (e) => {
        const { config } = this.state.formValues;
        config[name] = e.target.value;
        const validationError = '';
        this.setState(() => ({
            ...this.state.formValues,
            formValues: {
                ...this.state.formValues,
                config,
            },
            validationError,
        }));
    };

    onAssetCriteriaChanged = (assetsData) => {
        this.setState({
            assetCriteria: assetsData,
        });
    };

    render() {
        return (
            <MiniDrawer redirectTo={this.props.history.push}>
                <LookStepper
                    editLook={this.state.editLook}
                    onCancel={this.onCancel}
                    handleSubmit={this.handleSubmit}
                    handleInputChange={this.handleInputChange}
                    lookType={this.state.lookType}
                    formData={this.state.formValues}
                    handleCheckboxChange={this.handleCheckboxChange}
                    handleTextboxChange={this.handleTextboxChange}
                    handleLookTypeChange={this.handleLookTypeChange}
                    setLookId={this.setLookId}
                    setLookType={this.setLookType}
                    lookNameCheckInProgress={this.state.lookNameCheckInProgress}
                    lookNameError={this.state.lookNameError}
                    validationError={this.state.validationError}
                    onAssetCriteriaChanged={this.onAssetCriteriaChanged}
                    assetCriteria={this.state.assetCriteria}
                />
                {this.state.showDialog ? (
                    <div>
                        <Dialog
                            open={this.state.showDialog}
                            type="success"
                            customTitle="Module Saved"
                            button={[{
                                title: 'OK',
                                color: 'primary',
                                variant: 'contained',
                                handleClick: () => this.handleClose(),
                            }]}
                            content={(this.state.editAlert) ? 'Module Updated Successfully.' :
                                'Module Saved Successfully.'}
                            size="lg"
                        />
                    </div>
                ) : null}
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => ({
    addedLooks: {},
    isUpdating: {},
    ...state.lookNames,
});

const mapDispatchToProps = {
    ...actionConfigureDashboard,
};
export default reduxConnect(LookSteps, mapDispatchToProps, mapStateToProps);
