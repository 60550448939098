/* @flow */
import React, { Component } from 'react';
import moment from 'moment';
import withStyles from '@mui/styles/withStyles';
import { SvgIcon, Grid, Button, Divider, Select, MenuItem } from '@mui/material';
import { Info, CheckCircle } from '@mui/icons-material';
import { reduxConnect } from '../../../hoc';
import * as actions from './actions.sendCommand';
import * as notificationActions from '../../../components/SharedComponents/NotificationHandler/actions.appNotifications';
import { getPermissionValue, getTimeFormatAsperUserSettting } from '../../../helper-classes/utility-functions';
import analytics from '../../../analytics/index';

export type Props = {
    classes : Object,
    assetId: string,
    isOpen: Boolean,
    sendCmdToAsset: Function,
    handleClose: Function,
    notificationObj: Object,
    appNotificationHandler: Function,
    addCardNotification: Function,
    fetchAssetAllowedCmdList: Function,
    assetAllowedCommandList: Array<Object>,
    deviceGlobalId: string,
    assetName: string,
    history: {
        push: Function,
    },
};

export type State = {
    cmdToSend: string,
};

const themeStyles = () => ({
    selectDropIcon: { color: 'white' },
    topSpacing: { top: '355px !important' },
    cmdBarDivider: {
        backgroundColor: 'rgb(255, 255, 255, 0.15)',
        height: 2,
        margin: '0 15px 13px 15px',
    },
});

let isNotification = false;
const commandSentTitles = {
    locate: 'Locate',
    starterEnable: 'Enable Starter',
    starterDisable: 'Disable Starter',
    lockDoor: 'Lock',
    unlockDoor: 'Unlock',
};

class SendCommand extends Component<Props, State> {
    extraData: Object;
    commandList: Array<Object>;
    timeFormat: string;

    constructor(props: Props) {
        super(props);
        this.state = {
            cmdToSend: 'locate',
        };
        this.extraData = {};
        this.commandList = [];
        this.timeFormat = (getTimeFormatAsperUserSettting() === '24h') ? 'HH:mm' : 'hh:mm A';
    }

    UNSAFE_componentWillMount() {
        this.props.fetchAssetAllowedCmdList({ deviceGlobalId: this.props.deviceGlobalId });
        this.commandList = [
            {
                txt: 'Locate',
                val: 'locate',
                icon: 'locate',
                enabled: false,
            },
            {
                txt: 'Starter Enable',
                val: 'starterEnable',
                icon: 'starter',
                enabled: false,
            },
            {
                txt: 'Starter Disable',
                val: 'starterDisable',
                icon: 'starter',
                enabled: false,
            },
            {
                txt: 'Door Lock',
                val: 'lockDoor',
                icon: 'door',
                enabled: false,
            },
            {
                txt: 'Door Unlock',
                val: 'unlockDoor',
                icon: 'door',
                enabled: false,
            },
        ];
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        nextProps.assetAllowedCommandList.map((cmd) => {
            if (cmd.code === 'encodeDoorUnlock') {
                const i = this.commandList.findIndex(d => ((d.val === 'unlockDoor')));
                if (i > -1) this.commandList[i].enabled = true;
            } else if (cmd.code === 'doorLock') {
                const i = this.commandList.findIndex(d => ((d.val === 'lockDoor')));
                if (i > -1) this.commandList[i].enabled = true;
            } else {
                const i = this.commandList.findIndex(d => ((d.val === cmd.code)));
                if (i > -1) this.commandList[i].enabled = true;
            }
            return true;
        });

        const i = nextProps.notificationObj.findIndex(nItem => (nItem.type === 'snackbar') && nItem);
        if (i > -1) isNotification = nextProps.notificationObj[i].isOpen;
    }

    handleCmdNotificationClick =
        (commandSent: string, assetId: string, status: string) => {
            analytics.track('ASSET_SEND_COMMAND', {
                feature: 'FLEET_Asset',
                CommandSent: commandSent,
                CommandStatus: status,
                ClickedItemType: 'View Notification',
                AssetID: assetId,
            });
            this.props.history.push(`/home?assetId=${assetId}&zoom=true`);
        }

    handleCmdSelectChange = command => this.setState({ cmdToSend: command });

    handleNotificationChange = (newProps) => {
        const { extraData, overrideConfig } = newProps;
        if (extraData && Object.keys(extraData).length > 0) {
            this.extraData = { ...extraData };
            const { assetId, commandStatus } = extraData;
            const cmdStatus = commandStatus === 'Success';

            if (['Success', 'Failed', 'Timeout'].indexOf(commandStatus) > -1) {
                const command = this.commandList.find(c => (c.val === extraData.commandSent));
                let statusType = 'success';
                if (commandStatus === 'Failed') statusType = 'error';
                if (commandStatus === 'Timeout') statusType = 'info';

                analytics.track('ASSET_SEND_COMMAND', {
                    feature: 'FLEET_Asset',
                    CommandSent: extraData.commandSent,
                    CommandStatus: (commandStatus === 'Success') ? 'Completed' : commandStatus,
                    ReceivedOn: moment().format('YYYY-MM-DD HH-mm-ss'),
                    AssetID: assetId,
                });

                this.props.appNotificationHandler([{
                    type: 'snackbar',
                    isOpen: true,
                    message: this.getCmdNotificationMsg(cmdStatus, extraData.msg),
                    extraData: { commandSent: extraData.commandSent },
                    overrideConfig: {
                        key: overrideConfig.key,
                        autoHideDuration: 2000,
                        action: undefined,
                    },
                }]);

                this.props.addCardNotification({
                    title: `${commandSentTitles[extraData.commandSent]} - Asset ${this.props.assetName}`,
                    for: 'send-command',
                    id: extraData.assetId,
                    icon: command && command.icon,
                    status: (commandStatus === 'Success') ? 'Completed' : commandStatus,
                    statusType,
                    statusDate: moment().format(`MM/DD/YYYY ${this.timeFormat}`),
                    clickable: true,
                    onClick: clickedAssetId =>
                        this.handleCmdNotificationClick(
                            extraData.commandSent,
                            clickedAssetId, commandStatus,
                        ),
                });
            }
        }
    }

    handleCloseNotification = (e, reason, notificationObj) => {
        if (reason !== 'clickaway') {
            this.props.appNotificationHandler([{
                type: 'snackbar',
                isOpen: false,
                extraData: { commandSent: notificationObj.extraData.commandSent },
                overrideConfig: {
                    key: notificationObj.overrideConfig.key,
                    autoHideDuration: 2000,
                    action: undefined,
                },
            }]);
        }
    }

    handleSendCmdBtn = () => {
        const { assetId } = this.props;
        const { cmdToSend } = this.state;
        const message = 'Send command is in progress...';
        const commandStatus = 'Pending';
        const command = this.commandList.find(c => (c.val === cmdToSend));

        this.props.sendCmdToAsset({ command: cmdToSend, assetId });
        this.props.appNotificationHandler([{
            type: 'snackbar',
            message: this.getCmdNotificationMsg(false, message),
            isOpen: true,
            extraData: {
                assetId,
                commandStatus,
                commandSent: cmdToSend,
            },
            nConfig: {
                vertical: 'top',
                horizontal: 'right',
                onCloseNotification: this.handleCloseNotification,
                onDataChange: this.handleNotificationChange,
            },
            overrideConfig: {
                key: assetId,
                autoHideDuration: 2000,
                action: undefined,
            },
        }]);

        this.props.addCardNotification({
            title: `${commandSentTitles[cmdToSend]} - Asset ${this.props.assetName}`,
            for: 'send-command',
            id: assetId,
            icon: command && command.icon,
            status: 'Submitted',
            statusType: 'inprogress',
            statusDate: moment().format(`MM/DD/YYYY ${this.timeFormat}`),
            clickable: true,
            onClick: clickedAssetId =>
                this.handleCmdNotificationClick(cmdToSend, clickedAssetId, commandStatus),
        });

        this.props.handleClose();
        analytics.track('ASSET_SEND_COMMAND', {
            feature: 'FLEET_Asset',
            CommandSent: cmdToSend,
            SentOn: moment().format('YYYY-MM-DD HH-mm-ss'),
            AssetID: assetId,
        });
    }

    handleClose = () => this.setState({ cmdToSend: '' }, () => this.props.handleClose());

    getCmdNotificationMsg = (status, msg) => (
        <Grid container direction="row" alignItems="center" style={{ minWidth: 340 }}>
            <Grid item xs={1}>
                {status ? <CheckCircle style={{ marginTop: 6 }} />
                    : <Info fill="red" style={{ marginTop: 6 }} />}
            </Grid>
            <Grid item xs={11}><span>{msg}</span></Grid>
        </Grid>
    )

    render() {
        const { classes, isOpen } = this.props;
        let disableSendCmdBtn = getPermissionValue('Admin') !== 'Modify';
        const hasOneEnabledCommand = this.commandList.some(item => item.enabled);
        if (!hasOneEnabledCommand) {
            disableSendCmdBtn = true;
        }
        if (!disableSendCmdBtn && this.extraData && Object.keys(this.extraData).length > 0) {
            if (this.extraData.assetId === this.props.assetId) {
                if (isNotification) disableSendCmdBtn = true;
            }
        }

        return (
            <React.Fragment>
                { isOpen &&
                    <Grid item xs={12}>
                        <Divider className={classes.cmdBarDivider} />
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{ padding: '0px 5px 13px 20px' }}
                        >
                            <Grid item xs={6}>
                                <Select
                                    value={this.state.cmdToSend}
                                    onChange={e => this.handleCmdSelectChange(e.target.value)}
                                    displayEmpty
                                    classes={{ icon: classes.selectDropIcon }}
                                    style={{ color: 'white', width: 150 }}
                                    MenuProps={{ classes: { paper: classes.topSpacing } }}
                                >
                                    <MenuItem value="" disabled>Select Command</MenuItem>
                                    {this.commandList.map((cmd) => {
                                        if (cmd.enabled) {
                                            return (
                                                <MenuItem
                                                    key={cmd.val}
                                                    value={cmd.val}
                                                >{cmd.txt}
                                                </MenuItem>);
                                        }
                                        return false;
                                    })}
                                </Select>
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: 'right' }}>
                                <Button
                                    className={classes.sendButton}
                                    disabled={disableSendCmdBtn}
                                    onClick={() => this.handleSendCmdBtn()}
                                    color="primary"
                                    style={disableSendCmdBtn ? { backgroundColor: '#e0e0e0', color: '#000' } : {}}
                                    variant="contained"
                                >
                                    SEND
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button onClick={() => this.handleClose()}>
                                    <SvgIcon>
                                        <path fill="#ffffff" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                                    </SvgIcon>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    ...state.sendCommand,
    notificationObj: state.appNotifications.AppNotificationHandler,
});

const mapDispatchToProps = {
    ...actions,
    ...notificationActions,
};

export default
withStyles(themeStyles)(reduxConnect(SendCommand, mapDispatchToProps, mapStateToProps));
