/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Grow, MenuList, Paper, MenuItem, ListItemIcon, Checkbox, Typography, Tooltip, Grid, IconButton, Input, Collapse } from '@mui/material';
import { ArrowDropDown, Save, Clear, Visibility } from '@mui/icons-material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import analytics from '../../analytics/index';
import GetSvgIcon, { Star } from './../../util/svgImage_util';
import customStyles from '../../index.module.scss';
import Dialog from './../../components/SharedComponents/Dialog/Dialog';
import {
    deleteUserPrefernece,
    getLocalStorageValue,
    setLocalStorageItem,
    saveUserPreference,
    getUserPreference,
    getSnapRoute,
    isEmpty,
} from './../../helper-classes/utility-functions';
import { FLEET_LOCATE_BOOKMARK_KEY, FLEET_LOCATE_MAP_CONFIG_KEY } from './../constants.map';

type Props = {
    bookmarkClick: Function,
    classes : Object,
    handleClick: Function,
    manageClustering: Function,
    showAssetClusteringOption: boolean,
    showKml: Function,
    showLandmarkClusteringOption: boolean,
    snapRoutesCallback: Function,
    viewAssetLabel: boolean,
    viewLandmarkLabel: boolean,
    viewTraffic: boolean,
    mapInfo: Object,
    trackAnalytics: Function,
}

type State = {
    addBookmark: boolean,
    anchorEl: any,
    listItem: any,
    open: boolean,
    newBookmarkName: string,
    dialog: Object,
    bookmarkData: Array<Object>,
}

const styles = () => ({
    root: {
        cursor: 'pointer',
        outline: 'none',
    },
    viewText: {
        paddingLeft: 8,
    },
    iconCenter: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        padding: 9,
    },
    iconCenterView: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        padding: 6,
    },
    divider: {
        borderRight: 'solid 2px #fafafa',
    },
    viewTimeText: {
        paddingLeft: 7,
    },
    menuItem: {
        paddingTop: 9,
        paddingBottom: '9px !important',
        paddingLeft: 5,
        letterSpacing: '-0.4px',
    },
    kmlBorder: {
        border: 'solid 0.5px #979797',
        height: '1px',
        opacity: '0.4',
    },
    borderMargin: {
        marginTop: 8,
    },
    kmlMenuItem: {
        padding: '8px 38px !important',
        marginTop: 8,
        letterSpacing: '-0.4px',
    },
    listText: {
        fontSize: 16,
        fontWeight: 'normal',
        marginTop: -2,
        lineHeight: 1.5,
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 154,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    bookmarkContainer: {
        alignItems: 'center',
        padding: '8px 0px 4px 16px',
        outline: 'none',
        width: 230,
    },
    bookmarkItemContainer: {
        alignItems: 'center',
        padding: '8px 0px 4px 16px',
        outline: 'none',
        '&:hover': {
            backgroundColor: '#d8d8d8',
        },
    },
    bookmarkPaper: {
        maxHeight: 300,
        overflowY: 'scroll',
        boxShadow: 'none',
        borderRadius: 0,
        width: 230,
    },
    addBookmarkName: {
        cursor: 'default',
        outline: 'none',
    },
    bookmarkName: {
        cursor: 'pointer',
        outline: 'none',
    },
    iconFontSize: {
        fontSize: '18px !important',
    },
    addIconFontSize: {
        fontSize: '20px !important',
    },
    p8: {
        padding: 8,
    },
    listItemIcon: {
        marginRight: 5,
    },
    bookmarkOutline: {
        outline: 'none',
    },
    iconButton: {
        height: '36px',
    },
});

class CustomViewButton extends Component<Props, State> {
    showBookmarks: boolean;
    bookmarkView: boolean;
    constructor(props: Object) {
        super(props);
        const { viewTraffic, viewAssetLabel, viewLandmarkLabel } = props;
        this.state = {
            addBookmark: false,
            anchorEl: null,
            bookmarkData: [],
            dialog: this.initDialog(),
            listItem: [
                {
                    checked: (getLocalStorageValue('mapTrafficLayer') === 'true'),
                    key: 'mapTrafficLayer',
                    value: 'Traffic',
                    title: 'show map with traffic',
                    show: (viewTraffic === 'true'),
                },
                {
                    checked: (getLocalStorageValue('mapAssetLabel') !== 'false'),
                    key: 'mapAssetLabel',
                    value: 'Asset Labels',
                    title: 'show asset labels',
                    show: (viewAssetLabel === 'true'),
                },
                {
                    checked: (getLocalStorageValue('mapLandmarkLabel') !== 'false'),
                    key: 'mapLandmarkLabel',
                    value: 'Landmark Labels',
                    title: 'show landmark labels',
                    show: (viewLandmarkLabel === 'true'),
                },
                {
                    checked: (getLocalStorageValue('assetClustering') !== 'false'),
                    key: 'assetClustering',
                    value: 'Asset Clustering',
                    title: 'show as clustered assets',
                    show: (props.showAssetClusteringOption),
                },
                {
                    checked: (getLocalStorageValue('landmarkClustering') !== 'false'),
                    key: 'landmarkClustering',
                    value: 'Landmark Clustering',
                    title: 'show as clustered landmarks',
                    show: (props.showLandmarkClusteringOption),
                },
                {
                    checked: (props.snapRoutesCallback && typeof props.snapRoutesCallback === 'function' && getSnapRoute() === 'true'),
                    key: 'snapRoutes',
                    value: 'Snap Routes to Road',
                    title: 'Route lines are an estimation of the route driven',
                    show: (props.snapRoutesCallback && typeof props.snapRoutesCallback === 'function'),
                },
            ],
            newBookmarkName: '',
            open: false,
        };
        this.bookmarkView = (this.props.bookmarkClick && typeof this.props.bookmarkClick === 'function');
        this.showBookmarks = false;
    }

    UNSAFE_componentWillMount() {
        if (!getLocalStorageValue('mapConfigSaveMethod')) {
            this.setUserPreference();
        }

        if (this.bookmarkView) {
            this.getBookmarksLocalstoreData();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Object) {
        if (nextProps.showAssetClusteringOption !== this.props.showAssetClusteringOption) {
            const { listItem } = this.state;
            const assetClusteringIndex = listItem.findIndex(e => e.key === 'assetClustering');

            if (listItem[assetClusteringIndex]) {
                listItem[assetClusteringIndex].show = nextProps.showAssetClusteringOption;
            }
        }

        if (nextProps.showLandmarkClusteringOption !== this.props.showLandmarkClusteringOption) {
            const { listItem } = this.state;
            const landmarkClusteringIndex = listItem.findIndex(e => e.key === 'landmarkClustering');

            if (listItem[landmarkClusteringIndex]) {
                listItem[landmarkClusteringIndex].show = nextProps.showLandmarkClusteringOption;
            }
        }
    }

    setUserPreference = () => {
        const { listItem } = this.state;
        const trafficIndex = listItem.findIndex(e => e.key === 'mapTrafficLayer');
        const assetLableIndex = listItem.findIndex(e => e.key === 'mapAssetLabel');
        const landmarkLableIndex = listItem.findIndex(e => e.key === 'mapLandmarkLabel');
        const assetClusteringIndex = listItem.findIndex(e => e.key === 'assetClustering');
        const LandmarkClusteringIndex = listItem.findIndex(e => e.key === 'landmarkClustering');

        getUserPreference(FLEET_LOCATE_MAP_CONFIG_KEY).then((response) => {
            setLocalStorageItem('mapConfigSaveMethod', 'post');

            if (response && response.value) {
                const mapConfigValues = JSON.parse(response.value);

                if (mapConfigValues) {
                    setLocalStorageItem('mapConfigSaveMethod', 'put');
                    const {
                        mapTrafficLayer,
                        mapAssetLabel,
                        mapLandmarkLabel,
                        assetClustering,
                        landmarkClustering,
                    } = mapConfigValues;
                    setLocalStorageItem('mapTrafficLayer', mapTrafficLayer);
                    setLocalStorageItem('mapAssetLabel', mapAssetLabel);
                    setLocalStorageItem('mapLandmarkLabel', mapLandmarkLabel);
                    setLocalStorageItem('assetClustering', assetClustering);
                    setLocalStorageItem('landmarkClustering', landmarkClustering);
                    this.props.handleClick('mapTrafficLayer', mapTrafficLayer === 'true');
                    this.props.handleClick('mapAssetLabel', mapAssetLabel !== 'false');
                    this.props.handleClick('mapLandmarkLabel', mapLandmarkLabel !== 'false');
                    this.props.handleClick('assetClustering', assetClustering !== 'false');
                    this.props.handleClick('landmarkClustering', landmarkClustering !== 'false');

                    if (this.props.manageClustering) {
                        this.props.manageClustering(assetClustering !== 'false', landmarkClustering !== 'false');
                    }

                    if (listItem[trafficIndex]) {
                        listItem[trafficIndex].checked = mapTrafficLayer === 'true';
                    }

                    if (listItem[assetLableIndex]) {
                        listItem[assetLableIndex].checked = mapAssetLabel !== 'false';
                    }

                    if (listItem[landmarkLableIndex]) {
                        listItem[landmarkLableIndex].checked = mapLandmarkLabel !== 'false';
                    }

                    if (listItem[assetClusteringIndex]) {
                        listItem[assetClusteringIndex].checked = assetClustering !== 'false';
                    }

                    if (listItem[LandmarkClusteringIndex]) {
                        listItem[LandmarkClusteringIndex].checked = landmarkClustering !== 'false';
                    }
                    this.setState({ listItem });
                }
            }
        });
    }

    handleToggle = (event: Object, open: boolean) => {
        this.setState({
            anchorEl: event.currentTarget,
            open,
        });
    }

    handleMenuClick = (item: Object) => {
        const { listItem } = this.state;
        listItem.some((i) => {
            const newItem = i;

            if (i.key === item.key) {
                newItem.checked = !newItem.checked;
                setLocalStorageItem(item.key, `${newItem.checked}`);
                this.props.handleClick(newItem.key, newItem.checked);
                const configData = {
                    config: {
                        mapTrafficLayer: newItem.key === 'mapTrafficLayer' ? `${newItem.checked}` : getLocalStorageValue('mapTrafficLayer'),
                        mapAssetLabel: newItem.key === 'mapAssetLabel' ? `${newItem.checked}` : getLocalStorageValue('mapAssetLabel'),
                        mapLandmarkLabel: newItem.key === 'mapLandmarkLabel' ? `${newItem.checked}` : getLocalStorageValue('mapLandmarkLabel'),
                        mapAutoRefresh: getLocalStorageValue('mapAutoRefresh'),
                        assetClustering: newItem.key === 'assetClustering' ? `${newItem.checked}` : getLocalStorageValue('assetClustering') || 'true',
                        landmarkClustering: newItem.key === 'landmarkClustering' ? `${newItem.checked}` : getLocalStorageValue('landmarkClustering') || 'true',
                        snapRoutes: newItem.key === 'snapRoutes' ? `${newItem.checked}` : getLocalStorageValue('snapRoutes') || 'false',
                    },
                    method: getLocalStorageValue('mapConfigSaveMethod'),
                };

                if (i.key === 'assetClustering' || i.key === 'landmarkClustering') {
                    this.props.manageClustering(
                        configData.config.assetClustering,
                        configData.config.landmarkClustering,
                    );
                } else if (i.key === 'snapRoutes') {
                    this.props.snapRoutesCallback(configData.config.snapRoutes);
                }
                saveUserPreference(FLEET_LOCATE_MAP_CONFIG_KEY, configData);
                this.props.trackAnalytics({ [newItem.key]: newItem.checked });
                const params = {
                    feature: 'Map',
                    enabled: newItem.checked,
                };
                if (!this.props.snapRoutesCallback) {
                    if (newItem.value === 'Traffic') {
                        analytics.track('ENABLE_TRAFFIC_VIEW', params);
                    } else if (newItem.value === 'Asset Labels') {
                        analytics.track('ENABLE_ASSET_LABELS_VIEW', params);
                    } else if (newItem.value === 'Landmark Labels') {
                        analytics.track('ENABLE_LANDMARK_LABELS_VIEW', params);
                    }
                }
                return true;
            }
            return false;
        });
        this.setState({ listItem });
    }

    handleClose = (event: Object) => {
        const { anchorEl } = this.state;

        if (anchorEl.contains(event.target)) return;
        this.closeBookmarkPopper();
    }

    setBookmarkDataValue = (bookmarks: Object) => {
        if (bookmarks.content && bookmarks.content.length > 0) {
            const bookmarkData = [];
            bookmarks.content.forEach((b) => {
                if (b.value) bookmarkData.push(JSON.parse(b.value));
            });
            this.setState({ bookmarkData });
        }
    }

    getBookmarksApiData = async () => {
        let preference = {};
        preference = await getUserPreference('');
        if (preference && preference.content) {
            preference.content = preference.content.filter(b => b.key.indexOf(`${FLEET_LOCATE_BOOKMARK_KEY}_`) === 0);
            setLocalStorageItem('bookmarks', JSON.stringify(preference));
            this.setBookmarkDataValue(preference);
        }
    }

    getBookmarksLocalstoreData = () => {
        const bookmarks = JSON.parse(getLocalStorageValue('bookmarks') || '{"content": []}');

        if (bookmarks.content && bookmarks.content.length === 0) {
            this.getBookmarksApiData();
        } else this.setBookmarkDataValue(bookmarks);
    }

    showModal = (type: string, content: any, buttons: any = null) => {
        const { dialog } = this.state;
        dialog.show = true;
        dialog.buttons = buttons || [{
            title: 'OK', color: 'primary', variant: 'contained', handleClick: () => this.resetDialog(),
        }];
        dialog.type = type;
        dialog.content = content;
        this.closeBookmarkPopper();
        this.setState({ dialog });
    }

    saveBookmark = async () => {
        if (!isEmpty(this.props.mapInfo)) {
            const { center, zoom } = this.props.mapInfo;
            const data = {
                config: {
                    name: this.state.newBookmarkName,
                    zoomLevel: zoom,
                    lat: center.lat(),
                    lng: center.lng(),
                },
                method: 'post',
            };
            const response = await saveUserPreference(`${FLEET_LOCATE_BOOKMARK_KEY}_${this.state.newBookmarkName}`, data);

            if (response.value) {
                const params = {
                    feature: 'Map',
                    bookmarkName: this.state.newBookmarkName,
                };
                analytics.track('ADD_BOOKMARK', params);
                this.showModal('success', <span>Bookmark <b>{this.state.newBookmarkName}</b> added successfully</span>);
                this.getBookmarksApiData();
            } else if (response.code === 'PREFERENCE_ALREADY_EXISTS') {
                this.showModal('error', <span>Bookmark name <b>{this.state.newBookmarkName}</b> already exists</span>);
            } else this.showModal('error', <span>Something went wrong</span>);
        }
    }

    deleteBookmarkClick = (bookmark: Object) => {
        this.showModal(
            'confirm',
            <span>Are you sure you want to delete <b>{bookmark.name}</b> bookmark?</span>,
            [
                { title: 'Cancel', color: 'primary', handleClick: () => this.resetDialog() },
                {
                    title: 'OK',
                    color: 'primary',
                    variant: 'contained',
                    handleClick: () => {
                        this.deleteBookmark(bookmark);
                        this.resetDialog();
                    },
                },
            ],
        );
    }

    initDialog = () => ({
        show: false,
        content: '',
        type: '',
        buttons: [],
    })

    resetDialog = () => {
        this.setState({ dialog: this.initDialog() });
    }

    closeBookmarkPopper = () => {
        this.closeBookmark();
        this.setState({ open: false }, () => {
            this.showBookmarks = false;
        });
    }

    deleteBookmark = async (bookmark: Object) => {
        let { bookmarkData } = this.state;
        bookmarkData = bookmarkData.filter(b => b.name !== bookmark.name);
        this.setState({ bookmarkData });
        await deleteUserPrefernece(`${FLEET_LOCATE_BOOKMARK_KEY}_${bookmark.name}`);
        this.getBookmarksApiData();
        analytics.track('DELETE_BOOKMARK', { feature: 'Map' });
    }

    closeBookmark = () => this.setState({ addBookmark: false, newBookmarkName: '' })

    addBookmarkClickHandler = () => {
        if (this.state.bookmarkData.length >= 15) {
            this.showModal('error', <span>You have already saved the maximum allowed bookmarks: 15. Please remove a bookmark before trying to save a new one.</span>);
        } else {
            this.setState({ addBookmark: true });
        }
    }

    bookmarkListClick = (bookmark: Object) => {
        this.closeBookmarkPopper();
        this.props.bookmarkClick(bookmark);
        const params = {
            feature: 'Map',
            bookmarkName: bookmark.name,
        };
        analytics.track('SELECT_BOOKMARK', params);
    }

    getAddButton = () => {
        let buttons = [];
        const { classes } = this.props;

        if (this.state.addBookmark) {
            buttons = [
                <IconButton
                    className={classes.p8}
                    onClick={() => this.saveBookmark()}
                    key="save_bookmark"
                    disabled={this.state.newBookmarkName.length < 1}
                    size="large"
                >
                    <Tooltip title="Save Bookmark" disableFocusListener>
                        <Save color={this.state.newBookmarkName.length < 1 ? 'disabled' : 'primary'} />
                    </Tooltip>
                </IconButton>,
                <IconButton
                    className={classes.p8}
                    onClick={() => this.closeBookmark()}
                    key="close_bookmark"
                    size="large"
                >
                    <Tooltip title="Close" disableFocusListener>
                        <Clear />
                    </Tooltip>
                </IconButton>,
            ];
        } else {
            buttons = [
                <IconButton
                    className={`${classes.p8} ${classes.iconButton}`}
                    onClick={() => this.addBookmarkClickHandler()}
                    key="add_bookmark"
                    size="large"
                >
                    <Tooltip title="Add Bookmark" disableFocusListener>
                        <span>
                            <GetSvgIcon
                                type="add-kml"
                                opacity="0.8"
                                fillcolor="#000"
                                viewBox="-2 -1 24 24"
                                className={classes.addIconFontSize}
                            />
                        </span>
                    </Tooltip>
                </IconButton>,
            ];
        }
        return buttons;
    }

    handleChange = (event: Object) => {
        let { value = '' } = event.target;
        value = value.replace(/\s{2,}/, '').replace(/^\s+|\s+$/g, ' ');

        if (!value.trim() && !this.state.newBookmarkName) return;
        this.setState({ newBookmarkName: value });
    }

    handleBlur = () => {
        let { newBookmarkName } = this.state;
        newBookmarkName = newBookmarkName.trim();
        this.setState({ newBookmarkName });
    }

    showBookmarkView = (event: Object) => {
        if (!this.showBookmarks) {
            this.showBookmarks = true;

            if (this.state.bookmarkData.length === 0) {
                this.setState({ addBookmark: true });
            }
            this.handleToggle(event, true);
        } else this.closeBookmarkPopper();
    }

    getViewItems = () => {
        let listItems = [];
        const { classes } = this.props;
        listItems = this.state.listItem.map(item => (
            item.show &&
                <MenuItem
                    key={item.key}
                    className={classes.menuItem}
                    onClick={() => {
                        this.handleMenuClick(item);
                    }}
                    title={item.title}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <Checkbox
                            sx={{ padding: '0px 12px' }}
                            disableRipple
                            checked={item.checked}
                            color="primary"
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                        />
                    </ListItemIcon>
                    <Typography className={classes.listText} variant="inherit">
                        {item.value}
                    </Typography>
                </MenuItem>
        ));

        if (this.props.showKml && typeof this.props.showKml === 'function') {
            const kml = (
                <div key="Kml">
                    <div className={`${classes.kmlBorder} ${classes.borderMargin}`} />
                    <MenuItem
                        className={classes.kmlMenuItem}
                        onClick={() => {
                            analytics.track('MANAGE_KML_OVERLAYS', { feature: 'Map' });
                            this.props.showKml();
                        }}
                        title="Manage KML Overlays"
                    >
                        <Typography className={classes.listText} variant="inherit">
                            Manage KML Overlays
                        </Typography>
                    </MenuItem>
                </div>);
            listItems.push(kml);
        }
        return listItems;
    }

    getBookmarks = () => {
        const { classes } = this.props;

        return (
            <div className={classes.bookmarkOutline}>
                <Grid container className={classes.bookmarkContainer}>
                    <Grid item xs={(this.state.addBookmark) ? 6 : 9}>
                        <Typography variant="inherit" className={classes.listText}>
                            <Tooltip title="Add Bookmark" disableFocusListener>
                                <span className={classes.addBookmarkName}>
                                    Add Bookmark
                                </span>
                            </Tooltip>
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={(this.state.addBookmark) ? 6 : 3}
                        style={{ paddingLeft: this.state.addBookmark ? 18 : 8 }}
                    >
                        {this.getAddButton()}
                    </Grid>
                    <Collapse
                        in={this.state.addBookmark}
                    >
                        <Grid item xs={12} style={{ paddingTop: 18 }}>
                            {this.state.addBookmark && <Input
                                onKeyDown={e => { e.stopPropagation() }}
                                autoFocus={this.state.addBookmark}
                                color="primary"
                                placeholder="Name Bookmark"
                                inputProps={{
                                    style: { width: 198 },
                                }}
                                value={this.state.newBookmarkName}
                                onBlur={() => this.handleBlur()}
                                onChange={e => this.handleChange(e)}
                            />
                            }
                        </Grid>
                    </Collapse>
                </Grid>
                {this.state.bookmarkData.length > 0 && <div className={`${classes.kmlBorder} ${this.showBookmarks ? '' : classes.borderMargin}`} />}
                <Paper className={classes.bookmarkPaper}>
                    {this.state.bookmarkData.map(bookmark => (
                        <Grid
                            key={bookmark.name}
                            container
                            className={classes.bookmarkItemContainer}
                        >
                            <Grid
                                item
                                xs={9}
                                onClick={() => this.bookmarkListClick(bookmark)}
                                className={classes.bookmarkName}
                            >
                                <Typography variant="inherit" className={classes.listText}>
                                    <Tooltip title={bookmark.name || ''} disableFocusListener placement="bottom-start">
                                        <span>{bookmark.name}</span>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item xs={3} style={{ paddingLeft: 8 }}>
                                <IconButton
                                    className={`${classes.p8} ${classes.iconButton}`}
                                    onClick={() => this.deleteBookmarkClick(bookmark)}
                                    size="large"
                                >
                                    <Tooltip title="Delete Bookmark" disableFocusListener>
                                        <span>
                                            <GetSvgIcon
                                                type="delete-kml"
                                                fillcolor="#000"
                                                viewBox="0 -2 18 22"
                                                className={classes.addIconFontSize}
                                            />
                                        </span>
                                    </Tooltip>
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                </Paper>
            </div>
        );
    }

    getMenuList = () =>
        (
            <MenuList sx={{ maxHeight: 'unset' }} style={{ padding: this.showBookmarks ? 0 : '' }}>{this.showBookmarks ?
                this.getBookmarks() :
                this.getViewItems()
            }
            </MenuList>)

    render() {
        const { classes } = this.props;
        const { open, dialog } = this.state;

        return (
            <React.Fragment>
                {this.bookmarkView ?
                    <Grid container className={classes.root}>
                        <Grid
                            item
                            xs={6}
                            className={`${classes.iconCenter} ${classes.divider} ${customStyles['gm-view-button']}`}
                            aria-owns={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={e => this.showBookmarkView(e)}
                        >
                            <Tooltip title="Bookmarks">
                                <div>
                                    <Star className={classes.iconFontSize} />
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            className={`${classes.iconCenter} ${customStyles['gm-view-button']}`}
                            aria-owns={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={e => {
                                if (this.showBookmarks) {
                                    this.closeBookmark();
                                    this.showBookmarks = false;
                                    this.handleToggle(e, open);
                                } else {
                                    this.handleToggle(e, !open);
                                }
                            }}
                        >
                            <Tooltip title="View">
                                <Visibility className={classes.iconFontSize} />
                            </Tooltip>
                        </Grid>
                    </Grid> :
                    <Grid
                        container
                        className={`${customStyles['gm-view-button']} ${classes.root}`}
                        aria-owns={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={e => this.handleToggle(e, !open)}
                    >
                        <Grid item xs={6} className={`${classes.iconCenterView}`}>
                            <div className={classes.viewText} title="View">
                                View
                            </div>
                        </Grid>
                        <Grid item xs={6} className={`${classes.iconCenterView}`}>
                            <div className={classes.viewTimeText}>
                                <ArrowDropDown viewBox="0 -4 24 24" />
                            </div>
                        </Grid>
                    </Grid>
                }
                <Popper
                    open={open}
                    anchorEl={this.state.anchorEl}
                    transition
                    disablePortal
                    style={{ zIndex: 2 }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list-grow"
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={(e) => this.handleClose(e)}>
                                    {this.getMenuList()}
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                <Dialog
                    open={dialog.show}
                    type={dialog.type}
                    customTitle=""
                    button={dialog.buttons}
                    content={dialog.content}
                    size="lg"
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(CustomViewButton);
