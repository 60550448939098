/* @flow */
import React from 'react';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import customstyles from './Directions.module.scss';

type Props = {
    directionsDetails: {
        startLocation: string,
        endLocation: string,
        distance: string,
        duration: string,
        steps: any,
    },
}

const DirectionsAttributes = (props : Props) => {
    const { directionsDetails } = props;
    return (
        <ListItem button className={customstyles['list-item']}>
            <Grid container spacing={0}>
                <Grid item xs={10}>
                    <Grid container spacing={0} className={customstyles.directionDetailsGrid}>
                        <Grid item xs={9}>
                            <Typography className={customstyles['direction-header']} component="p">
                                <span style={{
                                    color: '#000000',
                                    fontSize: '20px',
                                }}
                                >
                                    Directions Details
                                </span>
                            </Typography>
                            <div style={{ marginTop: '16px' }}>
                                <Typography className={customstyles['direction-header']} component="p">
                                    <span style={{
                                        fontWeight: 'bold',
                                        color: '#000000',
                                        fontSize: '17px',
                                    }}
                                    >
                                        {directionsDetails.duration}
                                    </span>
                                    <span style={{
                                        color: '#999999',
                                        marginLeft: '4px',
                                        fontSize: '17px',
                                    }}
                                    >
                                     ({directionsDetails.distance})
                                    </span>
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ListItem>
    );
};

export default (DirectionsAttributes);
