/* @flow */
import React, { Component } from 'react';
import { Radio, Button, Tooltip, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './UserAccount.module.scss';
import AppLoader from '../../components/AppLoader';
import { removeSavedPage, isReseller, getCustomerContact, getLocalStorageValue } from '../../helper-classes/utility-functions';
import { clearDashcamLSObj } from '../../util/dashcam_util';
import CustomerSupport from '../CustomerSupport';

type Props = {
    logout: Function,
    updateLoader: Function,
    selectUserAccount: Function,
    isAccountLoader: boolean,
    userAccounts: Array<{
        id: string,
        name: string,
        loginEnabled: boolean,
    }>,
    userIdentity: {
        user: {
            firstName: string,
            lastName: string,
            email: string,
        }
    },
    redirectToSettings: Function,
    classes: any,
    clearNotifications: Function,
    unsubscribePollingJobs: Function,
};

type State = {
    selectedValue: string,
};

const style = {
    root: {
        color: '#010101',
        paddingTop: 18,
        paddingRight: 10,
        opacity: 0.6,
        '&$checked': {
            color: '#007aff',
            opacity: 1,
        },
    },
    colorPrimary: { color: '#010101' },
    checked: {},
    size: {
        width: 40,
        height: 40,
    },
    divider: {
        borderBottom: '1px solid #979797',
        clear: 'both',
        padding: 12,
        marginBottom: 14,
    },
    oneAccountDivider: {
        paddingTop: 14,
        clear: 'both',
    },
    sizeIcon: {
        fontSize: 20,
    },
    cs: {
        padding: '0px 0px 0px 16px',
    },
    csDivider: {
        borderBottom: '1px solid #979797',
        clear: 'both',
        padding: 12,
    },
};

export class UserAccount extends Component<Props, State> {
    getHeader: Function;
    username: string;
    email: ?string;
    selectedAccount: string;
    static defaultProps = {
        userIdentity: {
            user: {
                firstName: '',
                lastName: '',
                email: '',
            },
        },
    };

    constructor(props: Props) {
        super(props);
        this.selectedAccount = localStorage.getItem('selectedAccount') || '';
        this.state = {
            selectedValue: '',
        };
        this.getHeader = this.getHeader.bind(this);
    }

    selectAccount = (e: { id: string, value: string }, noApiCall: boolean) => {
        this.props.unsubscribePollingJobs();
        this.props.clearNotifications();
        clearDashcamLSObj();
        this.selectedAccount = e.id;
        this.setState({ selectedValue: e.value });
        localStorage.setItem('selectedAccount', this.selectedAccount);
        if (!noApiCall) {
            this.props.updateLoader();
            this.props.selectUserAccount(this.selectedAccount);
        }
        return true;
    };

    logoutHandler = () => {
        this.props.logout();
        this.props.unsubscribePollingJobs();
        this.props.clearNotifications();
    }

    getAccountName = (item: any) => {
        if (item) {
            return item.loginEnabled ? item.name : `${item.name} (Suspended)`;
        }
        return '';
    }
    getUserAccounts = () => {
        const { classes, isAccountLoader } = this.props;
        const results = this.props.userAccounts.length > 1 ? this.props.userAccounts.map(item => (
            <li key={item.id} id={item.name} style={{ padding: '4px 0px 4px 2px' }}>
                <label htmlFor={item.id} style={{ marginLeft: -14 }}>
                    <Radio
                        checked={this.state.selectedValue === item.name ||
                            this.selectedAccount === item.id}
                        value={item.name}
                        id={item.id}
                        className={styles['radio-check']}
                        classes={{
                            root: classes.root,
                            checked: classes.checked,
                        }}
                        disabled={!item.loginEnabled}
                    />
                    <Tooltip title={this.getAccountName(item)} placement="right-end">
                        <span className={styles['radio-label']}>
                            {this.getAccountName(item)}
                        </span>
                    </Tooltip>
                </label>
            </li>
        )) : isAccountLoader && <li align="center" style={{ clear: 'both' }}><AppLoader /></li>;

        if (this.props.userAccounts.length > 0 && this.state.selectedValue === '' && this.selectedAccount === '') {
            this.selectAccount(
                {
                    id: this.props.userAccounts[0].id,
                    value: this.props.userAccounts[0].name,
                },
                true,
            );
        }
        return results;
    };

    getHeader() {
        return (
            <React.Fragment>
                <div className={styles['user-accounts-popup-header']}>
                    <div id="user-icon" className={styles['user-accounts-popup-header-icon']}>
                        <span> {`${this.props.userIdentity.user.firstName.toUpperCase().charAt(0)}${this.props.userIdentity.user.lastName.toUpperCase().charAt(0)}`} </span>
                    </div>
                    <div id="user-details" className={styles['user-accounts-popup-header-name']}>
                        <Tooltip title={`${this.props.userIdentity.user.firstName} ${this.props.userIdentity.user.lastName}`}>
                            <h1>{`${this.props.userIdentity.user.firstName} ${this.props.userIdentity.user.lastName}`}</h1>
                        </Tooltip>
                        <Tooltip title={this.props.userIdentity.user.email}>
                            <span>{this.props.userIdentity.user.email}</span>
                        </Tooltip>
                    </div>
                </div>
                <div
                    className={this.props.userAccounts.length > 1 ?
                        this.props.classes.divider :
                        this.props.classes.oneAccountDivider
                    }
                />
            </React.Fragment>
        );
    }

    getCustomerSupportTemplate = () => {
        if (!isReseller()) {
            return (
                <React.Fragment>
                    <div className={this.props.classes.csDivider} />
                    <div className={this.props.classes.cs}> <CustomerSupport /> </div>
                </React.Fragment>
            );
        }
        return '';
    }

    render() {
        const selectedAccount = getLocalStorageValue('selectedAccount');
        const customerSupportDetails = getCustomerContact(selectedAccount);
        return (
            <div
                id="user-accounts-popup"
                className={`${styles['user-accounts-popup']} ${customerSupportDetails.increaseWidth ? styles.width330 : ''}`}
            >
                {this.getHeader()}
                <div className={styles['user-accounts-popup-body']} style={this.props.userAccounts.length > 1 ? {} : { paddingTop: 0 }}>
                    <ul id="user-associate_accounts" onChange={e => this.selectAccount(e.target, false)}>
                        {this.getUserAccounts()}
                    </ul>
                </div>
                <Grid container justifyContent="space-evenly" alignItems="center">
                    <Grid item>
                        <Button
                            color="primary"
                            id="logout"
                            data-qa="logout"
                            onClick={() => this.logoutHandler()}
                        >
                        Logout
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            id="my-account"
                            data-qa="my-account"
                            onClick={() => {
                                this.props.redirectToSettings();
                                removeSavedPage();
                            }}
                        >
                            My Account
                        </Button>
                    </Grid>
                </Grid>
                {this.getCustomerSupportTemplate()}
            </div>
        );
    }
}

export default withStyles(style)(UserAccount);
