/* @flow */

import {
    FETCH_ADDRESS_SUCCESS,
    FETCH_ADDRESS_ERROR,
    UPDATE_LOADER,
    FETCH_ADDRESS_WITH_LAT_LNG,
    FETCH_ADDRESS_WITH_LAT_LNG_SUCCESS,
    FETCH_ADDRESS_WITH_LAT_LNG_ERROR,
} from './constants.address';

export const initialState = {
    address: {},
    isLoader: false,
    error: '',
    assetId: '',
};

type stateType = {
    address: Object,
    isLoader: boolean,
    assetId: string,
    error: string,
}

type actionType = {
    type: string,
    payload: {
        response: Object,
        assetId: string,
        error: string,
        showLoader: boolean,
    }
}

export default (state: stateType = initialState, action: actionType) => {
    switch (action.type) {
    case FETCH_ADDRESS_SUCCESS:
        return {
            ...state,
            address: action.payload.response,
            isLoader: false,
        };
    case FETCH_ADDRESS_ERROR:
        return {
            ...state,
            isLoader: false,
        };
    case UPDATE_LOADER:
        return {
            ...state,
            isLoader: action.payload.showLoader,
        };
    case FETCH_ADDRESS_WITH_LAT_LNG:
        return {
            ...state,
            address: null,
            isLoader: true,
        };
    case FETCH_ADDRESS_WITH_LAT_LNG_SUCCESS:
        return {
            ...state,
            address: action.payload.response,
            isLoader: false,
        };
    case FETCH_ADDRESS_WITH_LAT_LNG_ERROR:
        return {
            ...state,
            error: action.payload.error,
            isLoader: false,
        };
    default:
        return state;
    }
};
