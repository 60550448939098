/* @flow */
/* eslint-disable */
import React, { Component } from 'react';
import { reduxConnect } from '../../../hoc';
import * as actions from './actions.landmarks';
import LandmarkArrivalDepartureAlert from '../../../components/Alerts/AlertTypes/LandmarkArrivalDeparture/LandmarkArrivalDepartureAlert';

type Props = {
    landmarkGroups: Array<{}>,
    landmarkList: Array<{}>,
    fetchAlertCreationLandmarkGroupsList: Function,
    fetchLandmarkList: Function,
    formData:{
        config:{
            alertArrival: Boolean,
            alertDeparture: Boolean,
            selectedLandmarkGroups: Array<{}>,
            selectedLandmarkFilters: Array<{}>,
        },
    },
    handleTextboxChange: Function,
    handleCheckboxChange: Function,
    clearLandmarkSelects: Function,
    isLoading: boolean,
};

type State = {
    allLandmarkGroups: Array<{}>,
    selectedLandmarkGroups: Array<{}>,
    selectedLandmarkFilters: Array<{}>,
};

class LandmarkArrivalDepartureAlertList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            allLandmarkGroups: [],
            selectedLandmarkGroups: this.props.formData.config.selectedLandmarkGroups,
            selectedLandmarkFilters: this.props.formData.config.selectedLandmarkFilters,
        };
    }

    // Fetching all landmark-groups/landmarks
    UNSAFE_componentWillMount() {
        this.props.fetchAlertCreationLandmarkGroupsList();
        const selectedLandmarkGroups = {
            selectedLandmarkGroups: this.props.formData.config.selectedLandmarkGroups ?
                this.props.formData.config.selectedLandmarkGroups : [],
        };
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        const { landmarkGroups, landmarkList } = nextprops;
        if (landmarkGroups && landmarkGroups.length > 0 &&
            this.state.allLandmarkGroups !== landmarkGroups) {
            this.setState({
                allLandmarkGroups: landmarkGroups,
            });
        }
    }
    // fetching landmarks from landmark-groups
    fetchLandmarksFromGroups = (landmarkGroupsList) => {
        const selectedLandmarkGroups = { selectedLandmarkGroups: [] };
        if (landmarkGroupsList.length > 0) {
            selectedLandmarkGroups.selectedLandmarkGroups = landmarkGroupsList;
        } else {
            selectedLandmarkGroups.selectedLandmarkGroups = [];
        }
    }

    handleTextboxChange = name => (e) => {
        if (name === 'groups') {
            this.setState({
                selectedLandmarkGroups: e.target.value,
            }, () => {
                this.fetchLandmarksFromGroups(this.state.selectedLandmarkGroups);
            });
        } else {
            this.state.selectedLandmarkFilters = e.target.value;
        }
        this.props.handleTextboxChange(name)(e);
    };

    render() {
        return (
            <LandmarkArrivalDepartureAlert
                allLandmarkGroups={this.state.allLandmarkGroups}
                formData={this.props.formData}
                handleTextboxChange={this.handleTextboxChange}
                handleCheckboxChange={this.props.handleCheckboxChange}
                clearLandmarkSelects={this.props.clearLandmarkSelects}
            />
        );
    }
}

const mapStateToProps = state => ({
    landmarkGroups: state.alertLandmarks.landmarkGroups,
    isLoading: state.alertLandmarks.isLoading,
});

export default reduxConnect(LandmarkArrivalDepartureAlertList, actions, mapStateToProps);
