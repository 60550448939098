const LandmarkGroupManageStyles = {
    container: {
        height: 'auto',
        minHeight: 400,
        widht: '100%',
    },
    header: {
        display: 'flex',
        padding: 12,
        alignItems: 'center',
        paddingBottom: 17,
    },
    selectionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        paddingRight: 12,
    },
    radioList: {
        paddingLeft: 12,
    },
    chipContainer: { minHeight: 48, paddingTop: 4 },
    title: { margin: 'auto 0' },
    column: {
        flex: 1,
        width: '47%',
        paddingLeft: 12,
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        maxWidth: '50%',
    },
    landmarksHeader: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        fontSize: 22,
        paddingTop: 6,
    },
    reset: {
        float: 'right',
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        fontSize: 14,
        fontWeight: 600,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 0.5,
        textAlign: 'right',
    },
    selectedLandmarksContainer: {
        display: 'flex',
        flex: 2,
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
    },
    icon: {
        fill: '#fff',
        backgroundColor:
        '#4a4a4a',
        borderRadius: '50%',
        opacity: '0.54',
        height: 18,
        width: 18,
    },
    selectedGroupContainer: {
        paddingTop: 12,
    },
    radio: {
        color: '#007CFF',
    },
    tableWrapper: {
        height: 330,
        overflowX: 'scroll',
        marginLeft: -20,
    },
    selectedWrapper: {
        overflowX: 'scroll',
        height: 330,
        maxHeight: '100%',
        marginTop: '7px',
    },
    loader: {
        transform: 'translate(-10%, 0%)',
        'text-align': 'center',
    },
    searchInput: {
        paddingTop: 0,
        paddingBottom: 0,
        width: '100%',
        position: 'relative',
    },
    customSelectContainer: {
        display: 'flex',
        width: '40%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        paddingRight: 12,
    },
    selectInternalContainer: {
        flex: 1,
        paddingLeft: 20,
        paddingBottom: 20,
        paddingTop: 10,
    },
    textField: {
        width: 328,
    },
    bootstrapRoot: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        backgroundColor: '#007bff',
        borderColor: '#007bff',
        '&:hover': {
            backgroundColor: '#0069d9',
            borderColor: '#0062cc',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
        marginLeft: 15,
    },
    parentGrpDropdown: {
        paddingLeft: 300,
        paddingTop: 13,
    },
};

export default LandmarkGroupManageStyles;
