/* @flow */
/* eslint-disable no-console */
import React, { Component } from 'react';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { isDashcamAuthenticated, getMediaSourceUrl, getDeviceMediaTokenData } from './../../../util/dashcam_util';
import { WEBRTC_CONFIG, LIVE_VIDEO_CONFIG } from './../../../containers/DashCam/constants.dashcam';

type Props = {
    imei: string,
    cameraId: number,
    handleTypeChange: Function,
    errorNotification: Function,
}

type State = {
    isPlaying: boolean,
}

class Webrtc extends Component<Props, State> {
    webrtcRef: Object;
    source: string;
    config: Object;
    restarted: boolean;
    mediaCoreDomain: string;
    componentUnmounted: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            isPlaying: LIVE_VIDEO_CONFIG.isPlaying,
        };
        this.webrtcRef = React.createRef();
        this.source = '';
        this.restarted = false;
        this.mediaCoreDomain = '';
        this.config = {
            ws_server: null,
            ws_port: null,
            ws_conn: null,
            default_peer_id: null,
            rtc_configuration: WEBRTC_CONFIG.rtc_configuration,
            default_constraints: WEBRTC_CONFIG.default_constraints,
            connect_attempts: 0,
            peer_connection: null,
            local_stream_promise: null,
            socket_connection_timer: null,
            peer_id: '',
        };
        this.componentUnmounted = false;
    }

    componentDidMount() {
        if (isDashcamAuthenticated()) this.connectMedia();
    }

    componentWillUnmount() {
        this.componentUnmounted = true;
        this.destroyVideoWebrtc();
    }

    connectMedia = () => {
        getDeviceMediaTokenData(this.props.imei, (error, data) => {
            if (error) return this.props.errorNotification(error);
            if (!data || !data.mediaAddress || !data.mediaToken) return '';

            return this.initVideo({
                mediaAddress: data.mediaAddress || '',
                mediaToken: data.mediaToken || '',
            });
        });
    }

    initVideo = (data: Object) => {
        this.source = getMediaSourceUrl('webrtc', {
            mediaAddress: data.mediaAddress,
            mediaToken: data.mediaToken,
            imei: this.props.imei,
            cameraId: this.props.cameraId,
        });

        if (!this.state.isPlaying) return;

        if (LIVE_VIDEO_CONFIG.isPlaying && this.getVideoRef()) {
            this.getVideoRef().startTimer();
        }
        this.createVideoWebrtc();
    }

    getLocation = (href: string) => {
        const l = document.createElement('a');
        l.href = href;
        return l;
    };

    createVideoWebrtc = () => {
        if (this.componentUnmounted) return;
        this.destroyVideoWebrtc();
        if (!this.source) return;
        this.mediaCoreDomain = this.getLocation(this.source).hostname;
        this.config.socket_connection_timer = null;
        this.restarted = false;
        this.websocketServerConnect();
    }

    getOurId = () => Math.floor((Math.random() * (1000000 - 10)) + 10).toString();

    resetState = () => this.config.ws_conn.close();

    handleIncomingError = (error: string) => {
        this.setError(`ERROR: ${error}`);
        this.resetState();
    }

    setError = (text: string) => console.error(text);

    getVideoRef = () => this.webrtcRef.current;

    getVideoElement = () => this.getVideoRef() && this.getVideoRef().getVideoElement();

    resetVideo = () => {
        console.log('Reset Video');
        // Reset the video element and stop showing the last received frame
        const videoElement = this.getVideoElement();
        if (videoElement) {
            videoElement.pause();
            videoElement.src = '';
            videoElement.load();
        }
    }

    onLocalDescription = (desc: Object) => {
        console.log(`Got local description: ${JSON.stringify(desc)}`);

        this.config.peer_connection.setLocalDescription(desc).then(() => {
            console.log('Sending SDP answer');

            this.config.ws_conn
                .send(JSON.stringify({ sdp: this.config.peer_connection.localDescription }));
        });
    }

    onIncomingSDP = (sdp: Object) => {
        this.config.peer_connection.setRemoteDescription(sdp).then(() => {
            console.log('Remote SDP set');

            if (sdp.type !== 'offer') return;
            console.log('Got SDP offer');

            this.config.local_stream_promise.then(() => {
                console.log('Got local stream, creating answer');
                if (LIVE_VIDEO_CONFIG.isPlaying && this.getVideoRef()) {
                    setTimeout(() => this.getVideoRef().startTimer(), 3000);
                }
                this.config.peer_connection.createAnswer()
                    .then(this.onLocalDescription).catch(this.setError);
            }).catch(this.setError);
        }).catch(this.setError);
    }

    onIncomingICE = (ice: Object) => {
        // $FlowFixMe
        const candidate = new RTCIceCandidate(ice);
        this.config.peer_connection.addIceCandidate(candidate).catch(this.setError);
    }

    httpGetAnswer = (text: string) => console.log(`httpGetAnswer(): ${text}`);

    sendMessageToStartCall = () => {
        const theUrl = this.source.replace('#PEERID#', this.config.peer_id.toString());

        // making the https get call
        this.httpGetAsync(theUrl, this.httpGetAnswer);
    }

    httpGetAsync = (theUrl: string, callback: Function) => {
        const xmlHttp = new XMLHttpRequest();

        xmlHttp.onreadystatechange = () => {
            console.log(xmlHttp.readyState);

            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) callback(xmlHttp.responseText);
            else if (xmlHttp.readyState === 4) {
                if (this.config.socket_connection_timer) {
                    clearTimeout(this.config.socket_connection_timer);
                }

                if (!this.restarted) {
                    this.restartWebrtcVideo();
                }
            }
        };
        xmlHttp.open('GET', theUrl, true);
        xmlHttp.send(null);
    }

    onServerMessage = (event: Object) => {
        let msg = null;

        if (event.data.startsWith('ERROR')) {
            this.handleIncomingError(event.data);

            return;
        }

        if (event.data.startsWith('HELLO')) {
            const [id] = [event.data.split(' ')[1]];
            this.config.peer_id = id;
            console.log('Registered with server, waiting for call', this.config.peer_id);
            this.sendMessageToStartCall();

            return;
        }

        // Handle incoming JSON SDP and ICE messages
        try {
            msg = JSON.parse(event.data);
        } catch (e) {
            if (e instanceof SyntaxError) {
                this.handleIncomingError(`Error parsing incoming JSON: ${event.data}`);
            } else {
                this.handleIncomingError(`Unknown error parsing response: ${event.data}`);
            }

            return;
        }

        // Incoming JSON signals the beginning of a call
        if (!this.config.peer_connection) this.createCall(msg);

        if (msg.sdp) {
            this.onIncomingSDP(msg.sdp);
        } else if (msg.ice) {
            this.onIncomingICE(msg.ice);
        } else this.handleIncomingError(`Unknown incoming JSON: ${msg}`);
    }

    onServerClose = (event: Object) => {
        console.log('Server close');
        console.log('Disconnected from server');
        this.resetVideo();

        if (this.config.peer_connection) {
            try {
                this.config.peer_connection.oniceconnectionstatechange = () => {};
                this.config.peer_connection.close();
                this.config.peer_connection = null;
            } finally {
                this.config.peer_connection = null;
            }
        }

        if (event.reason !== 'stop') {
            if (this.config.socket_connection_timer) {
                clearTimeout(this.config.socket_connection_timer);
            }

            if (!this.restarted) {
                this.restartWebrtcVideo();
            }
        }
    }

    onServerError = (event: Object) => {
        console.log('Unable to connect to server, did you add an exception for the certificate?', event);

        // Retry after 3 seconds
        if (this.config.socket_connection_timer) {
            clearTimeout(this.config.socket_connection_timer);
        }

        // socket_connection_timer=window.setTimeout(websocketServerConnect, 3000);
        if (!this.restarted && this.state.isPlaying) {
            this.restartWebrtcVideo();
        }
    }

    getLocalStream = () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            return navigator.mediaDevices.getUserMedia(this.config.default_constraints);
        }
        this.errorUserMediaHandler();

        return new Promise((resolve, reject) => {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                return resolve();
            }

            return reject();
        });
    }

    websocketServerConnect = () => {
        this.config.connect_attempts += 1;
        this.config.peer_id = this.config.default_peer_id || this.getOurId();
        this.config.ws_port = this.config.ws_port || '8443';

        if (window.location.protocol.startsWith('file')) {
            this.config.ws_server = this.config.ws_server || '0.0.0.0';
        } else if (window.location.protocol.startsWith('http')) {
            this.config.ws_server = this.config.ws_server || window.location.hostname;
        } else {
            throw new Error(`Don't know how to connect to the signalling server with uri ${window.location}`);
        }
        this.config.ws_server = this.mediaCoreDomain;
        const wsUrl = `wss://${this.config.ws_server}:${this.config.ws_port}`;
        console.log(`Connecting to server ${wsUrl}`);
        this.config.ws_conn = new WebSocket(wsUrl);

        /* When connected, immediately register with the server */
        this.config.ws_conn.addEventListener('open', () => {
            this.config.ws_conn.send(`HELLO ${this.config.peer_id}`);
            console.log('Registering with server');
            this.config.connect_attempts = 0;
        });

        this.config.ws_conn.addEventListener('error', this.onServerError);

        this.config.ws_conn.addEventListener('message', this.onServerMessage);

        this.config.ws_conn.addEventListener('close', this.onServerClose);
    }

    onRemoteStreamAdded = (event: Object) => {
        const videoTracks = event.stream.getVideoTracks();
        const audioTracks = event.stream.getAudioTracks();

        if (videoTracks.length > 0) {
            console.log(`Incoming stream: ${videoTracks.length} video tracks and ${audioTracks.length}  audio tracks`);
            if (this.getVideoElement()) {
                this.getVideoElement().srcObject = event.stream;
            }
        } else {
            this.handleIncomingError('Stream with unknown tracks added, resetting');
        }
    }

    errorUserMediaHandler = () => this.setError("Browser doesn't support getUserMedia!");

    createCall = (msg: Object) => {
        this.config.connect_attempts = 0;

        console.log('Creating RTCPeerConnection');

        // $FlowFixMe
        this.config.peer_connection = new RTCPeerConnection(this.config.rtc_configuration);
        this.config.peer_connection.onaddstream = this.onRemoteStreamAdded;

        /* Send our video/audio to the other peer */
        const browserInfo = this.getBrowserInfo();

        if (browserInfo.name.toLowerCase() === 'safari') {
            this.config.local_stream_promise = this.getLocalStream().then((stream) => {
                console.log('Adding local stream');
                this.config.peer_connection.addStream(stream);
                return stream;
            }).catch(this.setError);
        } else {
            this.config.local_stream_promise = new Promise(resolve => resolve());
        }

        if (!msg.sdp) {
            console.log("WARNING: First message wasn't an SDP message!?");
        }

        this.config.peer_connection.onicecandidate = (event: Object) => {
            // We have a candidate, send it to the remote party with the
            // same uuid

            if (event.candidate == null) {
                console.log('ICE Candidate was null, done');
                return;
            }
            this.config.ws_conn.send(JSON.stringify({ ice: event.candidate }));
        };

        this.config.peer_connection.oniceconnectionstatechange = () => {
            if (this.config.peer_connection && this.config.peer_connection.iceConnectionState) {
                if (this.config.peer_connection.iceConnectionState === 'failed') {
                    this.config.peer_connection.oniceconnectionstatechange = () => {};
                    if (!this.restarted) {
                        this.restartWebrtcVideo();
                    }
                }
            }
        };
        console.log('Created peer connection for call, waiting for SDP');
    }

    destroyVideoWebrtc = () => {
        console.log('Destroy Webrtc Video');
        this.resetVideo();

        if (this.config.socket_connection_timer) {
            clearTimeout(this.config.socket_connection_timer);
        }

        if (this.config.ws_conn && this.config.ws_conn.close) {
            this.config.ws_conn.close(4000, 'stop');
        }

        if (this.config.peer_connection) {
            this.config.peer_connection.oniceconnectionstatechange = () => {};
            this.config.peer_connection.close();
            this.config.peer_connection = null;
        }
    }

    restartWebrtcVideo = () => {
        this.restarted = true;
        console.log('Restart Webrtc Video');

        setTimeout(() => {
            if (this.state.isPlaying) this.createVideoWebrtc();
        }, 3000);
    }

    getBrowserInfo = () => {
        const ua = navigator.userAgent;
        let tem = [];
        let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        let NameBrowser = null;
        let VersionBrowser = null;

        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            NameBrowser = 'IE';
            [VersionBrowser] = [(tem[1] || '')];
        }

        if (M[1] === 'Chrome') {
            tem = ua.match(/\bOPR|Edge\/(\d+)/);

            if (tem === null) { // Chrome
                [NameBrowser] = [M[1]];
                [VersionBrowser] = [M[2]];
            } else if (tem && tem[0].indexOf('Edge') > -1) { // Edge
                NameBrowser = 'Edge';
                [VersionBrowser] = [tem[1]];
            } else {
                [NameBrowser] = [tem ? tem[0] : ''];
                if (tem && tem[1] !== undefined) {
                    [VersionBrowser] = [tem[1]];
                } else if (NameBrowser === 'OPR' && tem) { // Opera
                    // $FlowFixMe
                    VersionBrowser = tem.input.substring(tem.input.indexOf('OPR/') + 4);
                }
            } // else
        } else { // Firefox
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            tem = ua.match(/version\/(\d+)/i);
            if (tem) {
                M.splice(1, 1, tem[1]);
            }
            [NameBrowser] = [M[0]];
            [VersionBrowser] = [M[1]];
        }

        // Look for OS-Name
        const firstBracket = ua.indexOf('(');
        const secondBracket = ua.indexOf(')');
        const NameOS = ua.substring(firstBracket + 1, secondBracket);
        let isDesktop = false;

        ['linux', 'windows', 'mac'].forEach((os) => {
            if (NameOS.toLowerCase().includes(os.toLowerCase())) {
                isDesktop = true;
            }
        });

        ['android', 'iphone'].forEach((os) => {
            if (NameOS.toLowerCase().includes(os.toLowerCase())) {
                isDesktop = false;
            }
        });

        return {
            name: NameBrowser,
            version: VersionBrowser,
            os: NameOS,
            isDesktop,
        };
    }

    // future purpose
    // eslint-disable-next-line no-unused-vars
    playPause = (isPlaying: boolean, isAutoPaused: boolean = false) => {
        if (isPlaying) {
            this.destroyVideoWebrtc();
        } else this.createVideoWebrtc();
        this.setState({ isPlaying: !isPlaying });
    }

    handleTypeChange = (type: string) => {
        if (type !== 'webRtc') this.props.handleTypeChange(type);
    }

    render() {
        return (
            <VideoPlayer
                playPause={isPlaying => this.playPause(isPlaying)}
                isPlaying={this.state.isPlaying}
                ref={this.webrtcRef}
                handleTypeChange={type => this.handleTypeChange(type)}
                type="webRtc"
            />
        );
    }
}

export default Webrtc;
