/* @flow */
import { combineEpics } from 'redux-observable';
import { GRAILS_FETCH_ASSETS, GRAILS_POST_ASSETS, GRAILS_FETCH_SELECTED_ASSETS, FETCH_ASSET_GROUP_DETAILS_GLOBALID, DELETE_ASSET_GROUP_GLOBALID, ASSET_AND_SELECTED_ASSET } from './constants.assetGroupManage';
import * as actions from './actions.assetGroupManage';
import { getAuthorizationHeader, getGrailsAppBasedURL, getUserToken } from '../../helper-classes/utility-functions';
import config from '../../constants/Config';

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    of: Function,
    deleteJSON: Function,
}

function getAssetListUrl(action) {
    const {
        queryConfig,
        offset,
        limit,
    } = action.payload;
    const randVal = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}/operation/json/deviceLocationRestService/get`;
    url = `${url}?_dc=${randVal}&offset=${offset}&max=${limit}&assetsOnly=true`;
    const urlConfig = {
        assetSearchText: queryConfig.searchText || null,
        landmarkSearchText: null,
        assetSearchTags: null,
        landmarkSearchTags: null,
        notInAssetGroup: queryConfig.notInAssetGroup,
        notInLandmarkGroup: false,
        selectedAssetFilters: queryConfig.selectedAssetFilters,
        selectedLandmarkFilters: [],
        selectedAssetGroups: queryConfig.selectedAssetGroups || [],
        selectedLandmarkGroups: [],
        selectedReportFilters: [],
        selectedAlertFilters: [],
        selectedMaintenanceFilters: [],
        queryFields: [
            'name',
            'serialNumber',
            'assetGroupName',
            'assetName',
            'assetMake',
            'assetVin',
            'assetYear',
            'assetModel',
            'assetTypeName',
            'assetDescription',
            'address',
            'city',
            'state',
            'zip',
            'landmarkName',
            'stockNumber',
            'assetTags',
        ],
    };
    return `${url}&config=${encodeURIComponent(JSON.stringify(urlConfig))}
        &sorts=${encodeURIComponent(JSON.stringify(queryConfig.sort))}`;
}

const assetData = {
    header: {},
    action: {},
    selectedAssets: {},
    assets: {},
};
export const fetchAssets =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(GRAILS_FETCH_ASSETS)
            .debounceTime(1000)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };
                const { queryConfig, offset, limit } = action.payload;
                const body = {
                    ...queryConfig,
                    limit,
                    start: offset,
                };
                return postJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/assets`, body, headers);
            })
            .map((result) => {
                const formattedResult = result.response;
                return actions.fetchAssetsSuccess(formattedResult);
            })
            .catch(error => of(actions.fetchAssetsError(error)));

export const fetchAssetAndSelectedAssets =
        (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
            actions$
                .ofType(ASSET_AND_SELECTED_ASSET)
                .mergeMap((action) => {
                    const headers = {
                        Authorization: getAuthorizationHeader(),
                        'Content-Type': 'application/json',
                    };
                    const {
                        limit,
                        filter,
                    } = action.payload;
                    const url = getAssetListUrl({
                        payload: {
                            queryConfig: filter,
                            offset: 0,
                            limit,
                        },
                    });
                    assetData.action = action;
                    assetData.header = headers;
                    return getJSON(url, headers);
                })
                .mergeMap((result) => {
                    assetData.selectedAssets = result;
                    const {
                        assetFilter,
                        assetLimit,
                    } = assetData.action.payload;
                    const url = getAssetListUrl({
                        payload: {
                            queryConfig: assetFilter,
                            offset: 0,
                            limit: assetLimit,
                        },
                    });
                    return getJSON(url, assetData.header);
                    // return actions.fetchSelectedAssetsSuccess(formattedResult);
                })
                .map((result) => {
                    assetData.assets = result;
                    return actions.fetchAssetAndSelectedAssetSuccess(assetData);
                })
                .catch(error => of(actions.fetchAssetAndSelectedAssetError(error)));

export const fetchSelectedAssets =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(GRAILS_FETCH_SELECTED_ASSETS)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };
                const body = {
                    ...action.payload.queryConfig,
                };
                return postJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/assets`, body, headers);
            })
            .map((result) => {
                const formattedResult = result.response;

                return actions.fetchSelectedAssetsSuccess(formattedResult);
            })
            .catch(error => of(actions.fetchAssetsError(error)));

export const postAssets =
    (actions$: Function, store: Object, { getJSON, postJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(GRAILS_POST_ASSETS)
            .distinctUntilChanged()
            .debounceTime(config.get('DEBOUNCE_TIME'))
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };
                const grailsHeaders = {
                    'Content-Type': 'application/json',
                    Authorization: getAuthorizationHeader(),
                };
                const {
                    requestBody,
                    groupName,
                    isNewGroupName,
                    update,
                    secondCallData,
                } = action.payload;
                const url = `${getGrailsAppBasedURL()}/tree/updateAssets.json?_dc=${new Date().getTime()}`;
                const agUrl = `${config.get('FLEET_VIEW_SERVICES_URL')}/assetgroups?search=${encodeURIComponent(groupName)}&type=exactMatchSearch`;
                return getJSON(agUrl, headers).mergeMap((result) => {
                    if ((isNewGroupName && result.count > 0) ||
                        (!isNewGroupName && result.count > 1)) {
                        return of(actions.fetchAssetsError('Asset Group name must be unique.'));
                    }
                    return postJSON(url, requestBody, grailsHeaders)
                        .map((response) => {
                            if (update) {
                                return actions
                                    .updateAssetGroupSuccess(response.response, {
                                        secondCallData,
                                        groupName,
                                        isNewGroupName,
                                    });
                            }
                            return actions.postAssetGroupSuccess(response.response);
                        })
                        .catch(error => of(actions.fetchAssetsError(error)));
                }).catch(error => of(actions.fetchAssetsError(error)));
            });

export const fetchAssetDetailsFromGlobalId =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_ASSET_GROUP_DETAILS_GLOBALID)
            .debounceTime(1000)
            .mergeMap((action) => {
                const { globalId } = action.payload;
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };
                const url = `${config.get('FLEET_VIEW_SERVICES_URL')}/assetgroups/${globalId}`;
                return getJSON(url, headers);
            })
            .map((result) => {
                const formattedResult = result;
                return actions.fetchAssetDetailsFromGlobalIdSuccess(formattedResult);
            })
            .catch(error => of(actions.fetchAssetsError(error)));
export const deleteAssetGroup =
    (actions$: Function, store: Object, { deleteJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(DELETE_ASSET_GROUP_GLOBALID)
            .distinctUntilChanged()
            .mergeMap((action) => {
                const { globalId } = action.payload;
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };
                const url = `${config.get('PLATFORM_API_BASE_URL')}/groups/${globalId}`;
                return deleteJSON(url, headers)
                    .map((response) => {
                        const deleteResponse = {
                            success: false,
                            msg: null,
                        };
                        if (response && (response.status >= 200 && response.status <= 299)) {
                            deleteResponse.success = true;
                            deleteResponse.msg = null;
                        }
                        return actions.deleteAssetGroupSuccess(deleteResponse);
                    })
                    .catch(error => of(actions.fetchAssetsError(error)));
            });
export default combineEpics(
    fetchAssets,
    postAssets,
    fetchSelectedAssets,
    fetchAssetDetailsFromGlobalId,
    deleteAssetGroup,
    fetchAssetAndSelectedAssets,
);
