/* @flow */
import React, { Component } from 'react';
import { reduxConnect } from '../../../hoc';
import Dialog from '../../../components/SharedComponents/Dialog/Dialog';
import LandmarkGroupManageComponent from '../../../components/LandmarkGroupManage/LandmarkGroupManage';
import * as actions from './actions.landmarkGroupManage';
import { getParamValue } from '../../../helper-classes/utility-functions';
import AppLoader from '../../../components/AppLoader';
import { AVAILABLE_LANDMARK_FILTER, SELECTED_LANDMARK_LIMIT } from './constants.landmarkGroupManage';

export type Props = {
    history: {
        push: Function,
        goBack: Function,
    },
    fetchLandmarks: Function,
    landmarks: Object,
    isUpdating: boolean,
    postLandmarkGroup: Function,
    updateLandmarkGroup: Function,
    fetchSelectedLandmarks: Function,
    selectedLandmarks: Object,
    isSelectedResultLoading: boolean,
    isPostLandmarkGroupLoading: boolean,
    fetchLandmarkGroupDetailFromGlobalid: Function,
    landmarkGroupDetail: Object,
    isFetchingLandmarkGroupDetails: boolean,
    addedSuccessfully: boolean,
    isError: boolean,
    error: string,
    closeDialog: Function,
    showLoader: Function,
};

export type State = {
    landmarks: Array<any>,
    totalLandmarks: number,
    selectedLandmarks: Array<any>,
    clearLandmarks: boolean,
};
class LandmarkGroupManage extends Component<Props, State> {
    props: Props;
    initialLoad: boolean;
    totalLandmarksLoaded: number;
    button: Array<Object>;
    constructor(props) {
        super(props);
        this.state = {
            landmarks: [],
            totalLandmarks: 0,
            selectedLandmarks: [],
            clearLandmarks: false,
        };
        this.initialLoad = true;
        this.totalLandmarksLoaded = 0;
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
        }];
    }
    componentDidMount() {
        this.getLandmarkGroupData();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.landmarks && nextProps.landmarks.data
        && this.props.landmarks.data !== nextProps.landmarks.data) {
            let loadedLandmarks = this.state.landmarks;
            if (this.state.clearLandmarks) {
                loadedLandmarks = nextProps.landmarks.data;
            } else {
                loadedLandmarks = loadedLandmarks.concat(nextProps.landmarks.data);
            }
            this.totalLandmarksLoaded = loadedLandmarks.length;
            this.setState({
                landmarks: loadedLandmarks,
                totalLandmarks: nextProps.landmarks.total,
                clearLandmarks: false,
            });
        }

        if (nextProps.selectedLandmarks && nextProps.selectedLandmarks.data
            && this.props.selectedLandmarks.data !== nextProps.selectedLandmarks.data) {
            const selectedLandmarksData = nextProps.selectedLandmarks.data;
            this.setState({
                selectedLandmarks: selectedLandmarksData || [],
            });
        }
        if (nextProps.landmarkGroupDetail && nextProps.landmarkGroupDetail.data
            && this.props.landmarkGroupDetail.data !== nextProps.landmarkGroupDetail.data) {
            const [landmarksGroupDetails] = nextProps.landmarkGroupDetail.data;
            if (getParamValue(this.props, 'groupId')) {
                this.selectedLandmarkGroup = {
                    id: landmarksGroupDetails.id,
                    name: landmarksGroupDetails.name,
                    globalId: landmarksGroupDetails.id,
                    parentGroupId: landmarksGroupDetails.parentId || '',
                };
                const params = {
                    selectedLandmarkGroup: [this.selectedLandmarkGroup],
                    selectedLandmarks: [],
                    selectedLandmarkFilters: [],
                    sort: {
                        order: 'asc',
                        orderBy: 'name',
                    },
                };
                this.loadSelectedLandmarks(params);
            } else if (getParamValue(this.props, 'parentId')) {
                this.parentGroup = {
                    id: landmarksGroupDetails.id,
                    name: landmarksGroupDetails.name,
                };
            }
        }
    }

    getLandmarkGroupData = () => {
        if (getParamValue(this.props, 'groupId')) {
            this.actionType = 'edit';
            this.props.fetchLandmarkGroupDetailFromGlobalid(getParamValue(this.props, 'groupId'));
        } else {
            this.selectedLandmarkGroup = {};
            this.actionType = 'add';
        }
        if (getParamValue(this.props, 'parentId')) {
            this.props.fetchLandmarkGroupDetailFromGlobalid(getParamValue(this.props, 'parentId'));
        }
    }

    selectedLandmarkGroup = {};
    parentGroup = {
        id: 0,
        name: '',
    };
    actionType = '';
    sortParams = {
        property: 'name',
        direction: 'ASC',
    };
    selectedSortParams = {
        property: 'name',
        direction: 'ASC',
    }
    availableSearchText = '';

    getLandmarkFVSQuery = (offset, params) => {
        if (params.clearResult) this.setState({ landmarks: [] });

        if (offset === 0) this.setState({ clearLandmarks: true });
        let sortParam = [['name', params.sort.order]];
        if (params.sort.orderBy.toLowerCase() === 'address') {
            sortParam = [['address', params.sort.order], ['city', params.sort.order], ['state', params.sort.order], ['zipCode', params.sort.order]];
        }
        const query = {
            sortParam,
            filterParam: AVAILABLE_LANDMARK_FILTER,
            advanceSearchParam: [],
            searchParams: [],
        };

        if (params.search) {
            query.searchParams = [params.search];
        } else delete query.searchParams;

        return query;
    }

    loadLandmarks = (offset, limit, params) => {
        const queryConfig = this.getLandmarkFVSQuery(offset, params);
        this.props.fetchLandmarks(offset, limit, queryConfig);
    }

    selectedLandmarksFiltersFVSQuery = (params: any) => ({
        searchParams: [params.selectedLandmarkGroup[0].globalId], type: 'groupId', start: 0, limit: SELECTED_LANDMARK_LIMIT,
    })

    loadSelectedLandmarks = (params) => {
        this.props.fetchSelectedLandmarks(this.selectedLandmarksFiltersFVSQuery(params));
    }

    resetLandmark = (params) => {
        this.setState({ selectedLandmarks: params.selectedLandmarks });
    }

    postLandmarkGroup = (requestBody, groupName) => {
        let isNewGroupName = true;
        if (this.actionType === 'edit' && this.selectedLandmarkGroup.name === groupName) {
            isNewGroupName = false;
        }
        this.props.postLandmarkGroup(requestBody, groupName, isNewGroupName);
        // this.setState({ showPostMessage: true });
    }

    updateLandmarkGroup = (requestBody, groupName) => {
        let isNewGroupName = true;
        if (this.actionType === 'edit' && this.selectedLandmarkGroup.name === groupName) {
            isNewGroupName = false;
        }
        this.props.updateLandmarkGroup(requestBody, groupName, isNewGroupName);
    }

    getContent = () => {
        const {
            isError,
            addedSuccessfully,
            error,
        } = this.props;
        let content = '';
        if (this.actionType === 'edit') {
            if (isError) {
                content = `Edit Landmark Group Error : ${error}`;
            } else if (addedSuccessfully) {
                content = 'Landmark Group updated successfully';
            }
        } else if (isError) {
            content = `Add Landmark Group Error : ${error}`;
        } else if (addedSuccessfully) {
            content = 'Landmark Group added successfully';
        }
        return content;
    }

    handleClickOk = () => {
        const { addedSuccessfully } = this.props;
        this.props.closeDialog();
        if (addedSuccessfully) {
            this.props.history.goBack();
        }
    }

    getType = () => {
        const { isError, addedSuccessfully } = this.props;
        let type = '';
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
        }];
        if (addedSuccessfully) {
            type = 'success';
        } else if (isError) {
            type = 'error';
        }
        return type;
    }

    render() {
        const {
            addedSuccessfully,
            isError,
        } = this.props;
        return (
            <div className="app">
                {this.props.isPostLandmarkGroupLoading ||
                this.props.isFetchingLandmarkGroupDetails || this.props.isSelectedResultLoading
                    ? <AppLoader type="fullScreen" /> : ''}
                <LandmarkGroupManageComponent
                    landmarks={this.state.landmarks}
                    totalLandmarks={this.state.totalLandmarks}
                    loadLandmarks={this.loadLandmarks}
                    isLandmarksListUpdating={this.props.isUpdating}
                    postLandmarkGroup={this.postLandmarkGroup}
                    updateLandmarkGroup={this.updateLandmarkGroup}
                    selectedLandmarks={this.state.selectedLandmarks}
                    actionType={this.actionType}
                    resetLandmark={this.resetLandmark}
                    selectedLandmarkGroup={this.selectedLandmarkGroup}
                    isSelectedLandmarkstLoading={this.props.isSelectedResultLoading}
                    loadSelectedLandmarks={this.loadSelectedLandmarks}
                    parentGroup={this.parentGroup}
                    history={this.props.history}
                    isFetchingLandmarkGroupDetails={this.props.isFetchingLandmarkGroupDetails}
                    showLoader={this.props.showLoader}
                />
                <Dialog
                    open={addedSuccessfully || isError}
                    type={this.getType()}
                    customTitle=""
                    button={this.button}
                    content={this.getContent()}
                    size="lg"
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    landmarks: state.landmarkGroupManage.landmarks,
    selectedLandmarks: state.landmarkGroupManage.selectedLandmarks,
    isSelectedResultLoading: state.landmarkGroupManage.isSelectedResultLoading,
    isPostLandmarkGroupLoading: state.landmarkGroupManage.isPostLandmarkGroupLoading,
    landmarkGroupDetail: state.landmarkGroupManage.landmarkGroupDetail,
    isFetchingLandmarkGroupDetails: state.landmarkGroupManage.isFetchingLandmarkGroupDetails,
    isUpdating: state.landmarkGroupManage.isUpdating,
    error: state.landmarkGroupManage.error,
    isError: state.landmarkGroupManage.isError,
    addedSuccessfully: state.landmarkGroupManage.addedSuccessfully,
});
export default reduxConnect(LandmarkGroupManage, actions, mapStateToProps);
