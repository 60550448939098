/* @flow */
import React, { Component } from 'react';
import queryString from 'query-string';
import { reduxConnect } from '../../../hoc';
import * as actions from './actions.landmark';
import AddLandmark from '../../../components/AddLandmark/AddLandmark';
import { getParamValue } from './../../../helper-classes/utility-functions';
import AppLoader from '../../../components/AppLoader';
import Dialog from './../../../components/SharedComponents/Dialog/Dialog';
import analytics from '../../../analytics';

export type Props = {
    history: {
        push: Function,
        goBack: Function,
    },
    addLandmark: Function,
    getlandmarkGroups: Function,
    landmarkGroup: Object,
    fetchLandmark: Function,
    updateLoader: Function,
    landmark: Function,
    updateLandmark: Function,
    deleteLandmark: Function,
    loading: boolean,
    isUpdating: boolean,
    addedSuccessfully: boolean,
    isDeleted: boolean,
    isError: boolean,
    error: Object,
    closeDialog: Function,
    geoAddress: Object,
    location: {
        search: boolean,
    },
    match: any,
};

export type State = {
    isDelete: boolean,
    id: string;
};

class Landmark extends Component<Props, State> {
    isEdit: boolean;
    button: Array<Object>;
    isGeoAddress: boolean;
    id: string;

    constructor(props) {
        super(props);
        const values = queryString.parse(this.props.location.search);
        this.isGeoAddress = values.isGeoAddress;
        this.state = {
            isDelete: false,
            id: '',
        };
        this.isEdit = false;
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
        }];
        this.id = getParamValue(this.props, 'id');
    }

    componentDidMount() {
        this.loadPageData();
    }

    componentDidUpdate() {
        if (this.props.match.params.id && this.props.match.params.id !== this.id) {
            this.loadPageData();
        }
    }

    loadPageData = () => {
        const id = getParamValue(this.props, 'id');
        this.id = id;
        if (id) {
            this.isEdit = true;
            this.props.updateLoader(true);
            this.props.fetchLandmark(id);
        }
    }

    createLandmark = (data) => {
        this.props.updateLoader(true);
        this.props.addLandmark(data);
    }

    updateLandmark = (data) => {
        this.props.updateLoader(true);
        this.props.updateLandmark(data);
    }

    fetchLandmarkGroupsOptions = () => {
        this.props.getlandmarkGroups();
    }

    deleteLandmark = (id) => {
        this.setState({ isDelete: true, id });
    }

    formatError = (content, error) => {
        let newContent = content;
        if (error && error.errors) {
            newContent = (
                <div>
                    {newContent}
                    <ul>
                        { Object.keys(error.errors).map(d =>
                            <li key={d}>{d}: {error.errors[d]}</li>)
                        }
                    </ul>
                </div>
            );
        } else if (error) newContent = `${newContent} ${error.message || error.msg}`;
        else newContent = `${newContent} Something went wrong`;

        return newContent;
    }

    getContent = () => {
        const {
            isError,
            addedSuccessfully,
            error,
            isDeleted,
        } = this.props;
        let content = '';

        if (this.isEdit) {
            if (isError) {
                content = (isDeleted) ? 'Delete Landmark Error :' : 'Edit Landmark Error :';
                content = this.formatError(content, error);
            } else if (addedSuccessfully) {
                content = (isDeleted) ? 'Landmark deleted successfully' : 'Landmark edited successfully';
                if (isDeleted) {
                    analytics.track('DELETE_LANDMARK', { feature: 'FLEET_Landmark' });
                }
            }
        } else if (isError) {
            content = 'Add Landmark Error :';
            content = this.formatError(content, error);
        } else if (addedSuccessfully) {
            content = 'Landmark added successfully';
        }

        if (this.state.isDelete) content = 'Are you sure you want to delete the landmark?';
        return content;
    }

    handleClickOk = () => {
        this.props.closeDialog();

        if (this.props.addedSuccessfully) {
            if (this.props.isDeleted) this.props.history.push('/landmarks');
            else this.props.history.goBack();
        }

        if (this.state.isDelete) {
            this.props.updateLoader(true);
            this.props.deleteLandmark(this.state.id);
            this.setState({ isDelete: false });
        }
    }

    getType = () => {
        let type = '';
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
        }];

        if (this.props.addedSuccessfully) type = 'success';
        else if (this.props.isError) type = 'error';
        else if (this.state.isDelete) {
            type = 'confirm';
            this.button.splice(0, 0, { title: 'Cancel', color: 'primary', handleClick: () => this.setState({ isDelete: false }) });
        }

        return type;
    }

    render() {
        return (
            <React.Fragment>
                {this.props.loading && <AppLoader type="fullScreen" />}
                <AddLandmark
                    createLandmark={data => this.createLandmark(data)}
                    fetchLandmarkGroupsOptions={() => this.fetchLandmarkGroupsOptions()}
                    landmarkGroup={this.props.landmarkGroup}
                    landmark={this.props.landmark}
                    updateLandmark={data => this.updateLandmark(data)}
                    deleteLandmark={id => this.deleteLandmark(id)}
                    isUpdating={this.props.isUpdating}
                    isEdit={this.isEdit}
                    geoAddress={this.props.geoAddress}
                    isGeoAddress={this.isGeoAddress}
                />
                <Dialog
                    open={this.props.addedSuccessfully || this.props.isError || this.state.isDelete}
                    type={this.getType()}
                    customTitle=""
                    button={this.button}
                    content={this.getContent()}
                    size="lg"
                />
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => ({
    landmarkGroup: state.addLandmark.landmarkGroup,
    isUpdating: state.addLandmark.isUpdating,
    loading: state.addLandmark.loading,
    landmark: state.addLandmark.landmark,
    error: state.addLandmark.error,
    isError: state.addLandmark.isError,
    addedSuccessfully: state.addLandmark.addedSuccessfully,
    isDeleted: state.addLandmark.isDeleted,
    geoAddress: state.address.address,
});

export default reduxConnect(Landmark, actions, mapStateToProps);
