/* @flow */
import React, { Component } from 'react';
import Snackbar from '@mui/material/Snackbar';
import jwtDecode from 'jwt-decode';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import store from '../constants/Store';
import { logout } from './Header/actions.globalsearch';
import config from '../constants/Config';
import { getUserToken } from '../helper-classes/utility-functions';

type Props = {
    vertical: ? string,
    horizontal: ? string,
};
export type State = {
    counter: number,
    showBefor: number,
    open: boolean,
};

class SessionValidator extends Component<Props, State> {
    tick: Function;
    getTime: Function;
    secondsToTime: Function;
    timer: any;
    logoutMessage: string;
    constructor() {
        super();
        this.state = {
            counter: 0,
            showBefor: config.get('SESSION_EXPIRY_VALIDATION_IN_MINS') || 5,
            open: false,
        };
        this.tick = this.tick.bind(this);
        this.getTime = this.getTime.bind(this);
        this.timer = null;
        this.logoutMessage = 'Your session is expired';
    }

    componentDidMount() {
        this.secondsToTime = this.secondsToTime.bind(this);
        const expTime = this.getTime();
        if (!expTime.isError && expTime.expTime > 0) {
            setTimeout(() => {
                this.startTimer(expTime.expTime);
            }, expTime.startTime * 1000);
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    startTimer(time: number) {
        this.setState({ counter: time, open: true });
        this.timer = setInterval(this.tick, 1000);
    }

    getTime() {
        const token = getUserToken();
        const obj = { isError: false, expTime: 0, startTime: 0 };
        try {
            const decoded = jwtDecode(token);
            const currentTimeInSec = Math.ceil((new Date().getTime()) / 1000);
            const expTime = decoded.exp;
            if (currentTimeInSec < expTime) {
                if ((expTime - currentTimeInSec) <= this.state.showBefor * 60) {
                    obj.expTime = (expTime - currentTimeInSec) + 0;
                    obj.startTime = 1;
                } else {
                    obj.expTime = this.state.showBefor * 60;
                    obj.startTime = (expTime - currentTimeInSec -
                        (this.state.showBefor * 60)) + 0;
                }
            } else {
                // TODO LOGOUT here
                store.dispatch(logout({ message: this.logoutMessage, needToShow: true }));
                obj.expTime = 0;
                obj.startTime = 0;
            }
            return obj;
        } catch (err) {
            obj.isError = true;
            return obj;
        }
    }

    secondsToTime = (secs: number) => {
        if (!secs) return '';
        const divisorForMinutes = secs % (60 * 60);
        const minutes = Math.floor(divisorForMinutes / 60);
        const divisorForSeconds = divisorForMinutes % 60;
        const seconds = Math.ceil(divisorForSeconds);
        const time = `${minutes < 10 ? 0 : ''}${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
        return `Your session expires in ${time} minutes`;
    }

    tick() {
        if (this.state.counter > 0) {
            this.setState({
                counter: (this.state.counter - 1),
            });
        } else {
            this.setState({
                open: false,
            });
            clearInterval(this.timer);
            store.dispatch(logout({ message: this.logoutMessage, needToShow: true }));
        }
    }

    handleClose = (event: any, reason: string) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false });
    };

    render() {
        const {
            vertical = 'bottom',
            horizontal = 'left',
        } = this.props;
        const { open } = this.state;
        const message = this.secondsToTime(this.state.counter) || 'Your session is expired';
        return (
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={this.handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{message}</span>}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={this.handleClose}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        );
    }
}

export default SessionValidator;
