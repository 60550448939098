/* @flow */

export const FETCH_USERS_LIST: string = 'FETCH_USERS_LIST';
export const FETCH_USERS_LIST_SUCCESS: string = 'FETCH_USERS_LIST_SUCCESS';
export const FETCH_USERS_LIST_ERROR: string = 'FETCH_USERS_LIST_ERROR';
export const RESET_USERS_LIST: string = 'RESET_USERS_LIST';
export const USER_LIST_PREF_KEY: string = 'fleetLocate.userList';
export type UserListTypeAction = {
    type: string;
    payload?: Object;
}
