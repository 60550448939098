/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_ASSETS_DETAILS, FETCH_ASSETS_TRIPS, FETCH_ASSETS_EVENTS } from './constants.assets';
import { fetchAssetsDetailsSuccess, fetchAssetsDetailsError, fetchTripsSuccess, fetchAssetsEventsSuccess, fetchAssetsEventsError, fetchTripsError } from './actions.assets';
import config from '../../constants/Config';
import { convertUnitValueAsPerUserSetting, getAppToken } from '../../helper-classes/utility-functions';

type ObservarblesTypes = {
    getJSON: Function,
    of: Function,
};

const getSpeed = (data: any) => {
    if (data && data.status && data.status.toLowerCase() === 'stopped') {
        return 0;
    }
    return (data.speed) ? Math.round(data.speed) : '';
};

const formatData = data => ({
    assetId: data.id || '',
    assetName: data.name || '',
    status: data.status || '',
    deviceSerialNumber: data.deviceSerialNumber || '',
    deviceModelCode: data.deviceModelCode || '',
    locationLastReported: data.locationLastReported || '',
    statusStartDate: data.statusStartDate || '',
    statusDurationInSeconds: data.statusDurationInSeconds || '',
    statusDisplayDate: data.statusDisplayDate || '',
    lat: (data.lastLocation) ? data.lastLocation.y : 0,
    lng: (data.lastLocation) ? data.lastLocation.x : 0,
    address: data.address || '',
    city: data.city || '',
    assetType: data.type || '',
    typeName: data.typeName || '',
    state: data.stateOrProvince || '',
    zipCode: data.zipCode || '',
    landmarkId: data.landmarkId || '0',
    landmarkName: data.landmarkName || '',
    assetStatus: {
        heading: data.direction || '',
        speed: getSpeed(data),
        lastEvent: data.lastEvent || '',
        lastEventDate: data.lastEventDate || '',
    },
    assetDetails: {
        assetGroupName: data.assetGroupName || '',
        driver: data.driverName || '',
        tags: data.tags || '',
        engineHours: data.engineHours > 0 ?
            data.engineHours.toFixed(1) :
            data.engineHours,
        batteryStatus: data.batteryStatus || '',
        extVolt: (typeof data.extVolt !== 'undefined' && data.extVolt >= 0) ? data.extVolt : null,
        intVolt: data.intVolt || '',
        latitude: data.latitude || '',
        longitude: data.longitude || '',
        make: data.make || '',
        model: data.model || '',
        vin: data.vin || '',
        year: data.year || '',
        odometer: convertUnitValueAsPerUserSetting(parseFloat(data.odometer || 0)).toFixed(1),
        oilLifeRemainingStatus: data.oilLifeRemainingStatus || '',
        seatBeltStatus: data.seatBeltStatus,
        fuelLevelStatus: data.fuelLevelStatus || '',
        typeName: data.typeName || '',
        data,
        details: '',
    },
    assetFeatures: {
        seatBeltEnabled: data.hasSeatBelt,
        oilLifeEnabled: data.hasOilLife,
        tirePressureEnabled: data.hasTirePressure,
        fuelLevelEnabled: data.hasFuelLevel,
        fridgeStatusEnabled: data.hasFridgeStatus,
    },
    reportingStatus: data.reportingStatus,
});

export const getAssets =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_ASSETS_DETAILS)
            .mergeMap((action) => {
                const storeData = store.getState();
                const headers = {
                    'X-Nspire-UserToken': storeData.userSession.userToken,
                };
                const { id } = action.payload;
                return getJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/assets/${id}?cb=${new Date().getTime()}`, headers).mergeMap((res) => {
                    let assetData = {};
                    if (res.data.length > 0) {
                        assetData = formatData(res.data[0]);
                    }
                    return of(fetchAssetsDetailsSuccess(assetData));
                }).catch(error => of(fetchAssetsDetailsError(error)));
            });

/**
 * Function to get all trips
 * @param {*} actions$
 * @param {*} store
 * @param {*} param2
 */
export const getTrips =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_ASSETS_TRIPS)
            .mergeMap((action) => {
                const storeData = store.getState();
                const headers = {
                    'X-Nspire-UserToken': storeData.userSession.userToken,
                };
                const { id, paramsUrl, initFlag } = action.payload;
                return getJSON(`${config.get('TRIPS_API_BASE_URL')}/v1/assets/${id}/trips/${paramsUrl}`, headers)
                    .mergeMap(res => of(fetchTripsSuccess(
                        res,
                        (initFlag &&
                            res &&
                            res.trips &&
                            res.trips.length === 0
                        ),
                        (initFlag &&
                            res &&
                            res.tripEvents &&
                            res.tripEvents.content &&
                            res.tripEvents.content.length === 0
                        ),
                    ))).catch(error => of(fetchTripsError(
                        error,
                        initFlag,
                    )));
            });

/**
 * Function to get all asset events
 * @param {*} actions$
 * @param {*} store
 * @param {*} param2
 */
export const getAssetEvents =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_ASSETS_EVENTS)
            .mergeMap((action) => {
                const appToken = getAppToken();
                const storeData = store.getState();
                const headers = {
                    'X-Nspire-UserToken': storeData.userSession.userToken,
                    'X-Nspire-AppToken': appToken,
                };
                const { id, paramsUrl } = action.payload;
                return getJSON(`${config.get('PLATFORM_API_BASE_URL')}/assets/${id}/events${paramsUrl}`, headers)
                    .mergeMap(res => of(fetchAssetsEventsSuccess(res)))
                    .catch(error => of(fetchAssetsEventsError(error)));
            });

export default combineEpics(getAssets, getTrips, getAssetEvents);
