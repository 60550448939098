/* @flow */
import { FETCH_DEVICES_LIST_ERROR, FETCH_DEVICES_LIST_SUCCESS, FETCH_DEVICES_LIST, RESET_DEVICES_LIST } from './constants.deviceList';

const initialState = {
    devices: [],
    isUpdating: false,
};

type stateType = {
    devices: Array<Object>,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        devices:{},
        totalDevices: number
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_DEVICES_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case FETCH_DEVICES_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            devices: action.payload.devices,
            totalDevices: action.payload.totalDevices,
        };
    case FETCH_DEVICES_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    case RESET_DEVICES_LIST:
        return {
            ...state,
            isUpdating: false,
            devices: [],
            totalDevices: 0,
        };
    default:
        return state;
    }
};
