/* @flow */
export const SHOW_SPUD_BAR: string = 'show_spud_bar';
export const FETCH_ACCOUNT_SETTINGS: string = 'FETCH_ACCOUNT_SETTINGS';
export const FETCH_ACCOUNT_SETTINGS_SUCCESS: string = 'FETCH_ACCOUNT_SETTINGS_SUCCESS';
export const FETCH_ACCOUNT_SETTINGS_ERROR: string = 'FETCH_ACCOUNT_SETTINGS_ERROR';

export type SpudNameTypeAction = {
    type: string,
    payload?: Object,
};
