/* @flow */

import {
    FETCH_ADDED_LOOKS,
    FETCH_ADDED_LOOKS_SUCCESS,
    FETCH_ADDED_LOOKS_ERROR,
    FETCH_ADDED_LOOKS_LIST,
    DELETE_ADDED_LOOKS,
    DELETE_ADDED_LOOKS_SUCCESS,
    DELETE_ADDED_LOOKS_FAILURE,
} from './constants.configureDashboard';
import type { AddedLooksTypeAction } from './constants.configureDashboard';

export const fetchAddedLooks = (editLook: Boolean): AddedLooksTypeAction => ({
    type: FETCH_ADDED_LOOKS,
    payload: { editLook },
});

export const fetchAddedLooksSuccess = (
    addedLooks: any,
    totalRecords: number,
): AddedLooksTypeAction => ({
    type: FETCH_ADDED_LOOKS_SUCCESS,
    payload: { addedLooks, totalRecords },
});

export const resetTable = (): AddedLooksTypeAction => ({
    type: FETCH_ADDED_LOOKS_LIST,
});

export const fetchAddedLooksError = (error: string): AddedLooksTypeAction => ({
    type: FETCH_ADDED_LOOKS_ERROR,
    payload: { error },
});

export const deleteAddedLook = (id: number): AddedLooksTypeAction => ({
    type: DELETE_ADDED_LOOKS,
    payload: { id },
});

export const deleteAddedLooksSuccess = (response : {}): AddedLooksTypeAction => ({
    type: DELETE_ADDED_LOOKS_SUCCESS,
    payload: { response },
});

export const deleteAddedLooksError = (response: {}): AddedLooksTypeAction => ({
    type: DELETE_ADDED_LOOKS_FAILURE,
    payload: { response },
});
