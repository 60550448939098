/* @flow */

import {
    FETCH_RECIPIENT_LIST_ADMIN,
    FETCH_RECIPIENT_LIST_SUCCESS_ADMIN,
    FETCH_RECIPIENT_LIST_ERROR_ADMIN,
    FETCH_RECIPIENT_ALERTS,
    FETCH_RECIPIENT_ALERTS_SUCCESS,
    FETCH_RECIPIENT_ALERTS_FAILURE,
    DELETE_RECIPIENT_ALERTS,
    DELETE_RECIPIENT_ALERTS_SUCCESS,
    DELETE_RECIPIENT_ALERTS_FAILURE,
    RESET_RECIPIENT_ALERT_LIST,
} from './constants.recipentList';
import type {
    RecipientListTypeAction,
    AlertListTypeAction,
    DeleteAlertTypeAction,
} from './constants.recipentList';

export const fetchRecipientList = (recipientKey: string): RecipientListTypeAction => ({
    type: FETCH_RECIPIENT_LIST_ADMIN,
    payload: { recipientKey },
});

export const fetchRecipientListSuccess = (recipients: {}): RecipientListTypeAction => ({
    type: FETCH_RECIPIENT_LIST_SUCCESS_ADMIN,
    payload: { recipients },
});

export const fetchRecipientListError = (error: string): RecipientListTypeAction => ({
    type: FETCH_RECIPIENT_LIST_ERROR_ADMIN,
    payload: { error },
});

export const returnEmptyRecipients = (): RecipientListTypeAction => ({
    type: FETCH_RECIPIENT_LIST_SUCCESS_ADMIN,
    payload: {},
});

export const deleteAlert = (alert: Object, recipient: string): DeleteAlertTypeAction => ({
    type: DELETE_RECIPIENT_ALERTS,
    payload: {
        alert,
        recipient,
    },
});

export const deleteAlertSuccess = (response: {}): DeleteAlertTypeAction => ({
    type: DELETE_RECIPIENT_ALERTS_SUCCESS,
    payload: { response },
});

export const deleteAlertError = (response: {}): DeleteAlertTypeAction => ({
    type: DELETE_RECIPIENT_ALERTS_FAILURE,
    payload: { response },
});

export const fetchRecipientAlerts = (
    recipient: string,
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
): RecipientListTypeAction => ({
    type: FETCH_RECIPIENT_ALERTS,
    payload: {
        recipient,
        pageNumber,
        pageSize,
        order,
        orderBy,
    },
});

export const fetchRecipientAlertsSuccess =
    (alerts: any, totalAlerts: number): AlertListTypeAction => ({
        type: FETCH_RECIPIENT_ALERTS_SUCCESS,
        payload: {
            alerts,
            totalAlerts,
        },
    });

export const fetchRecipientAlertsError = (error: string): AlertListTypeAction => ({
    type: FETCH_RECIPIENT_ALERTS_FAILURE,
    payload: { error },
});

export const resetTable =
    (): AlertListTypeAction => ({
        type: RESET_RECIPIENT_ALERT_LIST,
    });
