/* @flow */
export const FETCH_RECIPIENT_LIST: string = 'FETCH_RECIPIENT_LIST';
export const FETCH_RECIPIENT_LIST_SUCCESS: string = 'FETCH_RECIPIENT_LIST_SUCCESS';
export const FETCH_RECIPIENT_LIST_ERROR: string = 'FETCH_RECIPIENT_LIST_ERROR';
export const RECIPIENT_LIST_NO_DATA_FOUND: string = 'Recipient Data Not Found';
export const POST_RECIPIENT_ALERT_DATA_SUCCESS: string = 'POST_RECIPIENT_ALERT_DATA_SUCCESS';
export const POST_RECIPIENT_ALERT_DATA_ERROR: string = 'POST_RECIPIENT_ALERT_DATA_ERROR';
export const CLEAR_REDUCER: string = 'CLEAR_REDUCER';
export type RecipientListTypeAction = {
    type: string,
    payload?: Object,
};
