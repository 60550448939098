/* @flow */
import { FETCH_DRIVERS_LIST_ERROR, FETCH_DRIVERS_LIST_SUCCESS, FETCH_DRIVERS_LIST, RESET_DRIVERS_LIST } from './constants.driverList';

const initialState = {
    drivers: [],
    isUpdating: false,
};

type stateType = {
    drivers: Array<Object>,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        drivers:{},
        totalDrivers: number
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_DRIVERS_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case FETCH_DRIVERS_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            drivers: action.payload.drivers,
            totalDrivers: action.payload.totalDrivers,
        };
    case FETCH_DRIVERS_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    case RESET_DRIVERS_LIST:
        return {
            ...state,
            isUpdating: false,
            drivers: [],
            totalDrivers: 0,
        };
    default:
        return state;
    }
};
