/* @flow */
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import './index.module.scss';
import App from './App';
import analytics from './analytics';
import { unregister } from './registerServiceWorker';

analytics.init();

const theme = createTheme({
    palette: {
        // globally applied theme colors
        primary: { main: '#007aff' },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    '&:before': {
                        borderBottom: '1px solid #d3d7db',
                    },
                    '&:after': {
                        borderBottomColor: '#007aff',
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    lineHeight: '1.5em',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#b0b8bf',
                    '&.Mui-focused': {
                        color: '#007aff !important',
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: -14,
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    height: 'calc(6.4vh)',
                    minHeight: '50px',
                    '&$selected': {
                        backgroundColor: 'rgba(0, 122, 255, 0.1) !important',
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    backgroundColor: '#fff',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    boxShadow: 'rgba(224, 224, 224, 1) 2px 2px 3px 0',
                    fontSize: '0.75rem',
                    color: 'rgba(0, 0, 0, 0.54)',
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                selectLabel: {
                    fontSize: '0.75rem',
                    color: 'rgba(0, 0, 0, 0.54)',
                },
                select: {
                    fontSize: '0.75rem',
                    color: 'rgba(0, 0, 0, 0.54)',
                },
                displayedRows: {
                    fontSize: '0.75rem',
                    color: 'rgba(0, 0, 0, 0.54)',
                },
            },
        },
        TablePaginationInput: {
            styleOverrides: {
                iconButton: {
                    fontSize: '0.75rem',
                    color: 'rgba(0, 0, 0, 0.54)',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    // boxShadow: window.location.pathname.match(/^\/iframe/i) ?
                    // 'none !important' : 'initial',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                popper: {
                    maxWidth: 300,
                    wordWrap: 'break-word',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    paddingTop: 11,
                    '&.Mui-selected': {
                        backgroundColor: 'rgba(0, 0, 0, 0.14)',
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    backgroundColor: '#E0E0E0',
                    cursor: 'default !important',
                },
                deletable: {
                    '&:focus': {
                        backgroundColor: '#E0E0E0',
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '12px',
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    padding: '12px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.87);',
                },
                body1: {
                    fontSize: '0.875rem',
                    letterSpacing: '0.01071em',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    padding: '0px 16px',
                },
            },
        },
        MuiButton: {
            defaultProps: {
                color: 'inherit',
            },
            styleOverrides: {
                root: {
                    padding: '8px 16px',
                    lineHeight: 1.5,
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard',
                MenuProps: {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    maxHeight: 250,
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    padding: 24,
                },
            },
        },
    },
});

ReactDOM.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}><App /></ThemeProvider>
    </StyledEngineProvider>,
    document.getElementById('root'),
);
unregister();
