/* @flow */
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './NotFound.module.scss';

/* Here props.setMaxHeight + 18 ,
were 17 is the added wrt to the height
of the <ItemsShowing /> component */
const NotFound = (props: { setMaxHeight: number, listPage?: string }) => (
    <div id="no-results-found" className={styles['right-dropdown-inner']} style={{ minHeight: props.setMaxHeight + 18 }}>
        <div className={styles['right-dropdown-inner']}>
            <div className={styles.heading}>Sorry, no results were found.</div>
            <div className={styles.line} />
            <div className={styles['sub-heading']}>Search suggestions: </div>
            <ul className={styles.content}>
                <li>Check for typos.</li>
                <li>Try another parameter</li>
                { props.listPage &&
                <li>Use sorting and filters in the <Link to={props.listPage}>list view</Link>
                    &nbsp;to find what you’re looking for.
                </li>
                }
            </ul>
        </div>
    </div>);

NotFound.defaultProps = {
    listPage: '',
};

export default NotFound;
