/* @flow */

import {
    FETCH_ASSETGROUP, FETCH_ASSETGROUP_SUCCESS, FETCH_ASSETGROUP_ERROR, UPDATE_ASSETGROUP_LOADER,
    SEARCH_ASSETGROUP, SEARCH_ASSETGROUP_SUCCESS, SEARCH_ASSETGROUP_ERROR,
} from './constants.assetgroup';
import type { AssetGroupTypeAction } from './constants.assetgroup';

export const fetchAssetGroup = (id: string, pageNumber: {}): AssetGroupTypeAction => ({
    type: FETCH_ASSETGROUP,
    payload: { id, pageNumber },
});

export const fetchAssetGroupSuccess = (response: {}): AssetGroupTypeAction => ({
    type: FETCH_ASSETGROUP_SUCCESS,
    payload: { response },
});

export const fetchAssetGroupError = (error: string): AssetGroupTypeAction => ({
    type: FETCH_ASSETGROUP_ERROR,
    payload: { error },
});

export const updateLoader = (isLoader: boolean): AssetGroupTypeAction => ({
    type: UPDATE_ASSETGROUP_LOADER,
    payload: { isLoader },
});

export const searchAssetGroup =
    (id: string, search: {}, pageNumber: {}, filters: {}): AssetGroupTypeAction => ({
        type: SEARCH_ASSETGROUP,
        payload: {
            search,
            pageNumber,
            id,
            filters,
        },
    });

export const searchAssetGroupSuccess = (response: {}): AssetGroupTypeAction => ({
    type: SEARCH_ASSETGROUP_SUCCESS,
    payload: { response },
});

export const searchAssetGroupError = (error: string): AssetGroupTypeAction => ({
    type: SEARCH_ASSETGROUP_ERROR,
    payload: { error },
});
