import React, { Component } from 'react';
import NativeSelect from '@mui/material/NativeSelect';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import styles from './AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../containers/Alerts/constants.alerts';
import { getAlertID, getAlertType } from '../../../util/mapAlertTypes';
import RightPanel from './RightPanel';
import AppLoader from '../../AppLoader';

type Props = {
    onAlertSelect: Function,
    newAlertType: String,
    alertType: String,
    alertNames: any,
    formData: any,
    onAlertNameChange: Function,
    handleTextboxChange: Function,
    handleCheckboxChange: Function,
    handleAlertTypeChange: Function,
    setAlertId: Function,
    setAlertType: Function,
    typeId: Number,
    alertNameError: String,
    validationError: String,
    clearLandmarkSelects: Function,
    alertNameCheckInProgress: any,
};

class StepOneAlertSelect extends Component<Props> {
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.alertNames.length !== 0) {
            // Get the Alert Type
            let alertType = nextProps.newAlertType;
            if (alertType === '') {
                ({ alertType } = this.props);
            }
            if ((alertType === undefined || alertType === null || alertType === '') &&
            (this.props.formData && this.props.formData.alertTypeId)) {
                alertType = getAlertType(nextProps.alertNames, this.props.formData.alertTypeId);
                nextProps.setAlertType(alertType);
            } else {
                // Get the ID of the Alert Type
                const alertID = getAlertID(nextProps.alertNames, alertType);

                if (nextProps.formData && nextProps.formData.typeId !== alertID) {
                    // If Alert Type has changed
                    if (alertType !== this.props.alertType) {
                        nextProps.handleAlertTypeChange(alertType, alertID);
                    } else {
                        // To set just the ID: Used when coming from Landing page
                        nextProps.setAlertId(alertID);
                    }
                }
            }
        }
    }

    render() {
        const { alertType, alertNames } = this.props;
        alertNames.sort((a, b) => a.name.localeCompare(b.name));
        return (
            <ImageList cellHeight={463} cols={2} className={styles.imageList}>
                <ImageListItem cols={1} className={styles.imageListItem}>
                    <div className={styles['step-content']}>
                        <div>
                            {alertNames.length === 0 ? (
                                <NativeSelect>
                                    <option value="">{ALERT_DETAILS.LOADING}</option>
                                </NativeSelect>
                            ) : (
                                <NativeSelect
                                    value={alertType}
                                    name="alertTypeName"
                                    id="AlertType"
                                    onChange={this.props.onAlertSelect}
                                    disableUnderline
                                    disabled={this.props.formData &&
                                        this.props.formData.id && this.props.formData.id !== 0}
                                >
                                    {alertNames.map(e => (
                                        <option key={e.code} value={e.code}>
                                            {e.name}
                                        </option>
                                    ))}
                                </NativeSelect>
                            )}
                        </div>
                        <div className={styles['div-pad']}>
                            <TextField
                                id="AlertName"
                                label={ALERT_DETAILS.ALERT_NAME}
                                placeholder={ALERT_DETAILS.ALERT_NAME}
                                className={styles['text-wid']}
                                name="name"
                                onChange={this.props.onAlertNameChange}
                                value={this.props.formData ? this.props.formData.name : ''}
                                autoComplete="off"
                            />
                            <div className={styles['load-pos']}>
                                { this.props.alertNameCheckInProgress === true ? (
                                    <AppLoader
                                        type="clip"
                                        loaderStyle={{
                                            position: 'absolute',
                                            // left: '100%',
                                            // top: '0%',
                                        }}
                                    />
                                ) : null}
                            </div>
                            <FormHelperText style={{ color: 'red' }}>
                                {this.props.alertNameError}
                                {(this.props.alertNameError === '' ? '' : <React.Fragment><br /> <br /></React.Fragment>)}
                            </FormHelperText>
                            <br />
                        </div>
                        <div />
                    </div>
                </ImageListItem>
                <ImageListItem cols={1} className={styles.imageListItem}>
                    <div className={styles['.step-content']}>
                        {(this.props.formData && alertType && !this.props.formData.configMap) ? (
                            <RightPanel
                                alertType={alertType}
                                formData={this.props.formData}
                                handleTextboxChange={this.props.handleTextboxChange}
                                handleCheckboxChange={this.props.handleCheckboxChange}
                                clearLandmarkSelects={this.props.clearLandmarkSelects}
                                validationError={this.props.validationError}
                            />
                        ) : ALERT_DETAILS.LOADING
                        }
                    </div>
                </ImageListItem>
            </ImageList>
        );
    }
}

export default StepOneAlertSelect;
