/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* @flow */
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { TableCell, TableRow, Tooltip, IconButton, Checkbox } from '@mui/material';
import { ChevronRight, ExpandMore, Visibility } from '@mui/icons-material';
import customStyles from './ListComponent.module.scss';
import editIcon from '../../../assets/icons/assets_List/edit.svg';
import deleteIcon from '../../../assets/icons/assets_List/ic-delete.svg';
import addIcon from '../../../assets/icons/assets_List/ic-more-vert.svg';

export type Props = {
    data: any,
    classes: any,
    rowClasses: string,
    listHeaders: any,
    handleClick: Function,
    handleDoubleClick: Function,
    isSelected: boolean,
    handleLinkClick: Function,
    expandRow: Function,
    iconStyle: any,
    ischeckBox: boolean,
    isEditable: boolean,
    isDeletable: boolean,
    isAddButton: boolean,
    addButtonTooltip: string,
    isGroup: boolean,
    rowActions: Object,
    addDelete: boolean,
    editTitle: string,
    userPermission: string,
    selectSingleRow: Function,
}

const styles = () => ({
    root: { margin: 15, width: '98%', marginLeft: 10 },
    checkbox: { color: '#4a4a4a', opacity: '0.54' },
    checked: { color: '#007aff !important', opacity: 1 },
    tableWrapper: {
        'overflow-x': 'scroll',
        height: '73.6vh',
        maxHeight: '100%',
    },
    icon: {
        fill: '#fff !Important',
        backgroundColor: '#4a4a4a',
        borderRadius: '50%',
        opacity: '0.54',
        height: '18px !Important',
        width: '18px !Important',
    },
});

class ListTableRow extends React.Component<Props, {}> {
    renderCellData = (h, n) => {
        const keys = Object.keys(h);
        let cellData = n[keys[0]];

        if (keys.indexOf('customMarkup') >= 0 && typeof h.customMarkup === 'function') {
            const customRenderResData = h.customMarkup(n);
            if (React.isValidElement(customRenderResData)) return customRenderResData;

            return (
                <Tooltip title={customRenderResData || ''} disableFocusListener>
                    <span>{customRenderResData}</span>
                </Tooltip>
            );
        }

        // regex to replace multiple spaces, tabs, newlines, etc from a single string
        if (typeof cellData === 'string') cellData = cellData.replace(/[\s]{2,}/g, ' ').trim();

        return cellData && (typeof cellData === 'string' || typeof cellData === 'number') ? (<Tooltip title={`${cellData}` || ''} disableFocusListener><span>{cellData}</span></Tooltip>) : <span title={`${cellData}` || ''}>{cellData}</span>;
    }

    getCellStyle = (data, header, index) => {
        if (header && header.style) return header.style;
        if (data.children && data.children.length > 0) return {};
        return (index === 0 && this.props.isGroup) ?
            { marginLeft: (this.props.iconStyle.marginLeft || 0) + 55 } : {};
    }

    getIcon = (id, children, expanded) => {
        let icon = '';
        const { iconStyle, classes, expandRow } = this.props;

        if (children && children.length > 0) {
            icon = (
                <Tooltip title="Expand">
                    <IconButton
                        aria-label="Expand"
                        style={{ ...iconStyle, marginRight: 8 }}
                        onClick={() => expandRow(id, true)}
                        size="large"
                    >
                        <ChevronRight className={classes.icon} />
                    </IconButton>
                </Tooltip>
            );

            if (expanded) {
                icon = (
                    <Tooltip title="Collapse" >
                        <IconButton
                            aria-label="Collapse"
                            style={{ ...iconStyle, marginRight: 8 }}
                            onClick={() => expandRow(id, false)}
                            size="large"
                        >
                            <ExpandMore className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                );
            }
        }
        return icon;
    }

    handleDoubleClick = (type, id) => {
        this.props.handleDoubleClick(type, id);
    }

    actionClick = (id, data, action) => {
        action.clickAction(data.id, data);
    }

    getActionElement = (options) => {
        const { editTitle, data } = options;
        let title = !options.addDelete && editTitle && editTitle.replace(/s/g, '').length > 0 ? editTitle : 'Edit';
        let showEdit = true;
        if (data.notEditable) {
            title = 'View';
            showEdit = false;
        }

        return (
            <Tooltip title={title || ''}>
                {showEdit ?
                    <img src={editIcon} alt="Edit" onClick={e => this.handleDoubleClick(e.target.checked, data.id)} />
                    :
                    <Visibility onClick={e => this.handleDoubleClick(e.target.checked, data.id)} />
                }
            </Tooltip>
        );
    }

    render() {
        const {
            data, isSelected, handleLinkClick, editTitle, addDelete,
            userPermission, addButtonTooltip, rowActions, rowClasses,
        } = this.props;

        return (
            <TableRow
                key={data.id}
                id={data.id}
                hover
                role="checkbox"
                aria-checked={isSelected}
                tabIndex={-1}
                selected={isSelected}
                className={`${rowClasses}`}
                onClick={() => this.props.selectSingleRow(data.id)}
            >
                {this.props.ischeckBox && userPermission === 'Modify' &&
                    <TableCell padding="checkbox" className={customStyles.checkboxWrapper}>
                        <Checkbox
                            checked={isSelected}
                            id="list-row-checkbox"
                            color="primary"
                            onClick={e => this.props.handleClick(e.target.checked, data.id)}
                        />
                    </TableCell>
                }
                {this.props.isEditable && userPermission === 'Modify' &&
                    <TableCell padding="checkbox" className={customStyles.checkboxWrapper}>
                        <span style={{ paddingLeft: 16, cursor: 'pointer' }}>
                            {this.getActionElement({ addDelete, editTitle, data })}
                        </span>

                        {/* condition is checked for Delete option form Saved Alert page */}
                        {this.props.addDelete && userPermission === 'Modify' ?
                            <span style={{ paddingLeft: 26, paddingRight: 16, cursor: 'pointer' }}>
                                <Tooltip title="Delete" disableFocusListener>
                                    <img src={deleteIcon} alt="Delete" onClick={() => { this.handleDoubleClick('DELETE', data.id); }} />
                                </Tooltip>
                            </span> :
                            null
                        }
                    </TableCell>
                }
                {this.props.isDeletable && userPermission === 'Modify' &&
                    <TableCell padding="checkbox" onClick={() => this.handleDoubleClick('DELETE', data.id)}>
                        <span style={{ paddingLeft: 16, paddingRight: 16, cursor: 'pointer' }}>
                            <Tooltip title="Delete" disableFocusListener>
                                <img src={deleteIcon} alt="Delete" />
                            </Tooltip>
                        </span>
                    </TableCell>
                }
                {this.props.isAddButton && userPermission === 'Modify' &&
                    <TableCell padding="checkbox" onClick={e => this.handleDoubleClick(e.target.checked, data.id)}>
                        <span style={{ paddingLeft: 16, cursor: 'pointer' }}>
                            <Tooltip title={addButtonTooltip || 'Add'} disableFocusListener>
                                <img src={addIcon} alt={addButtonTooltip || 'Add'} />
                            </Tooltip>
                        </span>
                    </TableCell>
                }
                {this.props.listHeaders.map((h, i) => (
                    <React.Fragment key={Object.keys(h)[0]}>
                        {h.show &&
                            <TableCell id={Object.keys(h)[0]}>
                                {i === 0 && this.getIcon(data.id, data.children, data.expanded)}
                                {h.isLink && this.renderCellData(h, data) !== '' ?
                                    <span style={this.getCellStyle(data, h, i)}>
                                        <button
                                            onClick={() => handleLinkClick(h.path, h.keyName, data)}
                                            className={customStyles['link-button']}
                                        >
                                            {this.renderCellData(h, data)}
                                        </button>
                                    </span>
                                    :
                                    <span
                                        style={this.getCellStyle(data, h, i)}
                                        className={customStyles['cell-span']}
                                    >
                                        {this.renderCellData(h, data)}
                                    </span>
                                }
                            </TableCell>
                        }
                    </React.Fragment>
                ))}
                {rowActions &&
                    <TableCell>
                        {userPermission === 'Modify' && rowActions.map((action) => {
                            let showActionButton = true;
                            if (action.eleToCheck && action.conditionTocheck) {
                                showActionButton = action.conditionTocheck(data[action.eleToCheck]);
                            }

                            return showActionButton && (
                                <span key={action.label}>
                                    <Tooltip title={action.label || ''} disableFocusListener>
                                        <IconButton
                                            aria-label={action.label}
                                            onClick={() => this.actionClick(data.id, data, action)}
                                            size="large"
                                        >
                                            <img src={action.icon} alt={action.label} />
                                        </IconButton>
                                    </Tooltip>
                                </span>
                            );
                        })}
                    </TableCell>
                }
            </TableRow>
        );
    }
}

export default withStyles(styles)(ListTableRow);
