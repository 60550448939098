/* @flow */
import React, { Component } from 'react';
import { Circle, Polygon, Rectangle, OverlayView, Polyline, DirectionsRenderer } from 'react-google-maps';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { mapColor } from '../constants.map';
import mapStyles from '../MapsWrapper.module.scss';
import CustomCircle from './Circle';
import { CustomRectangle } from './Rectangle';
import CustomPolygon from './Polygon';
import MapCluster from '../../components/MapCluster/MapCluster';
import { getLocalStorageValue, isEmpty, isPairedAsset } from '../../helper-classes/utility-functions';
import GetSvgIcon, { getArrowIcon, getTripStopIcon, getTripStartIcon } from '../../util/svgImage_util';
import { getImage, getAssetIconFromType, getAssetColorFromStatus, getAssetLabelColor } from '../../util/trip_util';
import { getAssetIconType, getLandmarkIconType } from '../../util/map_utils';
import CustomOverlayView from './CustomOverlayView';
import analytics from '../../analytics';
import config from '../../constants/Config';

type Props = {
    marker: Object,
    google: Object,
    findClusterExplodePoint: Function,
    showClusterInfo: Function,
    openAddressWindow: any,
    closeAddressWindow: any,
    mapRef: any,
    CircleRef: any,
    editableCircleRef: any,
    editableRectangleRef: any,
    editablePolygonRef: any,
    showAssetLabel: boolean,
    showLandmarkLabel: boolean,
    zoomLevel: number,
    moduleName: string,
    tripIconClick: Function,
    tripInfoBox: Object,
}

const markerStyle = {
    background: {
        background: mapColor.icon,
        borderRadius: '100%',
        float: 'center',
        width: 44,
        height: 44,
        cursor: 'pointer',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    },
    minibackground: {
        background: mapColor.icon,
        borderRadius: '100%',
        width: 22,
        height: 22,
        cursor: 'pointer',
        marginLeft: -11,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    clusterBackground: {
        background: mapColor.icon,
        borderRadius: '100%',
        float: 'center',
        width: 32,
        height: 32,
        cursor: 'pointer',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    },
    img: {
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        left: '50%',
        top: '50%',
        display: 'flex',
        justifyContent: 'center',
        overFlow: 'hidden',
        overflowX: 'hidden',
        overflow: 'hidden',
    },
    imgMini: {
        width: 12,
        height: 12,
    },
    overlayView: {
        background: 'red',
        border: '1px solid #ccc',
        width: 25,
        height: 25,
    },
};

const IGNITION_ON = 'IGN_ON';
const IGNITION_OFF = 'IGN_OFF';

class MapShapeHandler extends Component<Props> {
    clickedCluster: string;
    tripStartEvent: string;
    tripStopEvent: string;

    constructor(props: Props) {
        super(props);
        this.clickedCluster = '';
        this.tripStartEvent = IGNITION_ON;
        this.tripStopEvent = IGNITION_OFF;
    }

    shouldComponentUpdate(nextProps: Props) {
        // below code is required to avoid cyclic instance error while using JSON.stringify
        // because of html markups/react node
        const nextmarkers = { ...nextProps.marker, panel: '' };
        const currentmarkers = { ...this.props.marker, panel: '' };
        let isReRenderRequired = true;

        if (this.props.google) {
            if ((nextProps.showLandmarkLabel === this.props.showLandmarkLabel)
            && (nextProps.showAssetLabel === this.props.showAssetLabel)
            && (JSON.stringify(nextmarkers || '{}') === JSON.stringify(currentmarkers || '{}'))) {
                isReRenderRequired = false;
            }

            if (!isReRenderRequired && (nextProps.zoomLevel !== this.props.zoomLevel)) {
                isReRenderRequired = true;
            }
        }

        return isReRenderRequired;
    }

    isIgnOff = (event: Object) => this.tripStopEvent === event.eventTypeCode

    isIgnOn = (event: Object) => this.tripStartEvent === event.eventTypeCode

    getZoomIcon = (marker: Object) => {
        let icon = '';
        const { zoomEvent: ze } = marker;
        if (ze) {
            if (this.isIgnOff(ze)) {
                icon = getTripStopIcon('large');
            } else if (this.isIgnOn(ze)) {
                icon = getTripStartIcon('large');
            } else {
                icon = isEmpty(ze.movementSegment) ? getArrowIcon(0, 'selected', ze.tripColor, 0) :
                    getArrowIcon(ze.movementSegment.heading || 0, 'selected', ze.tripColor, ze.movementSegment.speed || 0);
                if (this.props.tripInfoBox) {
                    icon = (
                        <div
                            onClick={() => this.props.tripIconClick()}
                            tabIndex={0}
                            onKeyDown={() => {}}
                            role="button"
                        >
                            {icon}
                        </div>
                    );
                }
            }
        }
        return icon;
    }

    getDirectionStyle = (type: string) => {
        let style = { viewBox: '0 -1 22 30', right: -20, top: -37 };

        switch (type) {
        case 'E': style = { viewBox: '0 -1 22 30', right: -20, top: -37 };
            break;

        case 'W': style = {
            transform: 'rotate(180deg)', right: 1, viewBox: '1 0 22 30', top: -40,
        };
            break;

        case 'S': style = {
            right: -8, transform: 'rotate(90deg)', top: -31, viewBox: '0 0 20 30',
        };
            break;

        case 'N': style = {
            viewBox: '0 0 20 30', right: -12, transform: 'rotate(-90deg)', top: -48,
        };
            break;

        case 'SE': style = {
            right: -17, top: -32, transform: 'rotate(45deg)', viewBox: '1 0 20 30',
        };
            break;

        case 'NE': style = {
            right: -20, top: -44, transform: 'rotate(-45deg)', viewBox: '1 1 20 30',
        };
            break;

        case 'SW': style = {
            right: 0, top: -36, transform: 'rotate(135deg)', viewBox: '0 3 20 30',
        };
            break;

        case 'NW': style = {
            right: -4, top: -47, transform: 'rotate(-135deg)', viewBox: '0 0 20 30',
        };
            break;

        default: break;
        }

        return style;
    };

    getClusterIconSvgImage = (
        imageType: string, backgroundProp: Object,
        style: Object, zoomBasedIconsAllowed: boolean = false,
    ) => {
        const { zoomLevel } = this.props;

        // FOR ZOOM RANGE => 15, 16, 17, 18, 19, 20, 21 (this is default as well)
        let iconContainerStyle = { margin: '-37px 0 0 -15px', width: 40, height: 46 };
        let backgroundType = 'background_image';
        let imageStyle = {
            position: 'absolute',
            left: 5,
            top: -16,
            transform: 'translate(-50%, -50%)',
        };
        let additionalStyle = {};

        if (zoomBasedIconsAllowed && (zoomLevel < 15)) {
            if (zoomLevel < 9) {
            // FOR ZOOM RANGE => 3, 4, 5, 6, 7, 8
                iconContainerStyle = { margin: '-8px 0px 16px -3px', width: 10, height: 10 };
                backgroundType = 'background_image_zoomedOut';
                imageStyle = {};
            } else {
            // FOR ZOOM RANGE => 9, 10, 11, 12, 13, 14
                iconContainerStyle = { margin: '-12px 0px 0px -11px', width: 21, height: 21 };
                backgroundType = 'background_image_middleZoom';
                imageStyle = { position: 'absolute', left: -1, transform: 'translate(-50%, -50%)' };
                additionalStyle = { top: -2, width: 15, height: 11 };
            }
        }

        if (style) imageStyle = { ...imageStyle, ...style };
        imageStyle = { ...imageStyle, ...additionalStyle };

        let directionStyle = {};
        const showDirectionArrow = backgroundProp && backgroundProp.arrowDirection &&
        ((zoomBasedIconsAllowed && (zoomLevel > 14)) || !zoomBasedIconsAllowed);

        if (showDirectionArrow) {
            directionStyle = this.getDirectionStyle(backgroundProp.arrowDirection);
        }

        return (
            <div style={iconContainerStyle}>
                <GetSvgIcon
                    type={backgroundProp.backgroundShapeType || backgroundType}
                    fillcolor={backgroundProp.backgroundColor}
                />
                {((zoomLevel > 8) || !zoomBasedIconsAllowed) && <GetSvgIcon
                    type={imageType}
                    style={imageStyle}
                    fillcolor="#fff"
                />}
                {
                    (showDirectionArrow) &&
                    <ChevronRightIcon viewBox={directionStyle.viewBox} className={mapStyles['direction-arrow']} style={{ ...directionStyle }} />
                }
            </div>
        );
    }

    getOverlayAssetIcon = (d: Object, zoomBasedIconsAllowed: boolean) => {
        const image = getAssetIconFromType(d.type);
        const color = getAssetColorFromStatus(d);
        const labelColor = getAssetLabelColor(d, color);
        return this.getMarkerIcon(
            image.image,
            { shape: { ...d, showHaloEffect: true }, id: d.id, markerType: d.markerType || '' },
            {
                color,
                labelColor,
                size: 'large',
                style: { ...image.style },
                direction: d.direction,
                onClick: (d.redirectTo && d.id && isPairedAsset(d)) ? () => d.redirectTo(`/home?assetId=${d.id}`) : null,
            },
            zoomBasedIconsAllowed,
        );
    };

    getOverlayLandmarkIcon = (d: Object, zoomBasedIconsAllowed: boolean) => {
        const image = d.imageSize === 'large' ? getImage('location_cluster_Large') : getImage('location_cluster');
        const { group } = mapColor.asset;
        const landmarkData = { ...d };
        const color = d.color ? d.color : group[d.getColorNumber || 0];
        landmarkData.shape = d.shapeData;
        return (
            <React.Fragment key={`lmGroupIcon-${landmarkData.id}`}>
                {this.getLMShapeAndIcon(landmarkData)}
                {this.getMarkerIcon(
                    image,
                    { shape: d, id: landmarkData.id, markerType: d.markerType || '' },
                    {
                        color,
                        labelColor: color,
                        size: 'large',
                        backgroundShapeType: d.backgroundShapeType || '',
                        style: d.style || {},
                        onClick: (d.redirectTo && d.id) ? () => d.redirectTo(`/home?landmarkId=${d.id}`) : null,
                    },
                    zoomBasedIconsAllowed,
                )}
            </React.Fragment>
        );
    };

    isZoomBasedIconsAllowed = () =>
        // specify url pathname where zoom based asset/lm icons are not applicable
        !((['nearest-landmark', 'nearest-asset', 'marker-icon']).some(p => this.props.moduleName.includes(p)))


    getAssetLMLabelContainerClassName = () => {
        const { zoomLevel } = this.props;

        // FOR ZOOM RANGE => 15, 16, 17, 18, 19, 20, 21 (this is default as well)
        let labelContainerClassName = mapStyles.labelContainer;

        if (this.isZoomBasedIconsAllowed() && (zoomLevel < 15)) {
            // FOR ZOOM RANGE => 3, 4, 5, 6, 7, 8
            if (zoomLevel < 9) labelContainerClassName += ` ${mapStyles.zoomedOut}`;
            else labelContainerClassName += ` ${mapStyles.middleZoom}`;
            // ABOVE IS FOR ZOOM RANGE => 9, 10, 11, 12, 13, 14
        }

        return labelContainerClassName;
    }

    /**
     * @param {string} url image url or base 64 image which needs to be shown on marker
     * @param {Object} marker marker data with lat and lng value
     * @param {Object} markerProps color for the marker if need then pass by,
     * @param {boolean} zoomBasedIconsAllowed if current url is allowed to show zoom based icons
     * @returns custom marker image
     */
    getMarkerIcon = (
        url: string, marker: Object, markerProps: Object,
        zoomBasedIconsAllowed: boolean = false,
    ) => {
        if (!marker) return '';
        let { lat = 0, lng = 0 } = marker;
        if (marker.shape && marker.shape.lat && marker.shape.lng) {
            ({ lat, lng } = marker.shape);
        }
        if (lat === 0 && lng === 0) return '';

        const image = <img src={url} style={markerStyle.imgMini} alt="icon" title={markerProps.title} />;
        const showAssetLabel = (getLocalStorageValue('mapAssetLabel') !== 'false');
        const showLandmarkLabel = (getLocalStorageValue('mapLandmarkLabel') !== 'false');
        const markerName = (marker.shape && marker.shape.name);
        const markerKey = this.getUniqueShapeKey(marker.shape || {}, 'markergroup', 'id');
        const isOnClick = (markerProps.onClick && typeof markerProps.onClick === 'function');
        const assetId = (marker.shape.showHaloEffect && markerProps.color) ? `${marker.id}-${markerProps.color.substr(1, markerProps.color.length)}` : '';

        return (
            <CustomOverlayView
                key={`marker-icon-${markerKey}`}
                position={{ lat, lng }}
                mapPaneName={(OverlayView) ? OverlayView.OVERLAY_MOUSE_TARGET : ''}
            >
                <React.Fragment>
                    {assetId && <div id={assetId} />}
                    <div
                        tabIndex={0}
                        onClick={() => {
                            if (isOnClick) {
                                markerProps.onClick();
                            }
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        style={markerProps.size === 'large' ? {
                            cursor: isOnClick ? 'pointer' : 'auto',
                        } : {
                            ...markerStyle.minibackground,
                            background: markerProps.color,
                            width: markerProps.width,
                            height: markerProps.height,
                            cursor: isOnClick ? 'pointer' : 'auto',
                        }}
                        className={mapStyles.overlayPos_4}
                    >
                        {markerProps.size === 'small' ? image : this.getMarkerSvgIcon(url, marker, markerProps, zoomBasedIconsAllowed)}
                        {(markerName && ((this.props.moduleName.includes('asset-group') && showAssetLabel)
                            || (this.props.moduleName.includes('landmark-group') && showLandmarkLabel)
                            || (marker.markerType === 'asset' && showAssetLabel)
                            || (marker.markerType === 'landmark' && showLandmarkLabel))) &&
                            <div
                                style={{ backgroundColor: markerProps.labelColor }}
                                className={this.getAssetLMLabelContainerClassName()}
                            >
                                <span className={mapStyles.label}>
                                    {markerName || ''}
                                </span>
                            </div>
                        }
                    </div>
                </React.Fragment>
            </CustomOverlayView>
        );
    }

    getPolyLine = (newPolyPaths: Array<any>, directionOptions: Object) => (
        <Polyline
            paths={newPolyPaths}
            options={{
                path: newPolyPaths,
                ...directionOptions,
            }}
        />
    )

    /**
     * @param {Object} marker marker data with lat and lng value
     * @returns custom group marker images
     */
    getGroupMarkerIcon = (marker: Object) => {
        const zoomBasedIconsAllowed = this.isZoomBasedIconsAllowed();

        if (!marker && !marker.shape && !marker.shape.data && !marker.shape.data.length < 1) return '';
        if (marker.shape.id === 'asset' && marker.shape.data) {
            return marker.shape.data.map(m => this.getOverlayAssetIcon(m, zoomBasedIconsAllowed));
        } else if (marker.shape.id === 'landmark') {
            return marker.shape.data.map(m =>
                this.getOverlayLandmarkIcon(m, zoomBasedIconsAllowed));
        } else if (marker.shape.id === 'multiTypeMarkers' && marker.shape.data) {
            return marker.shape.data.map(m => ((m.type === 'asset') ?
                this.getOverlayAssetIcon(m, zoomBasedIconsAllowed) :
                this.getOverlayLandmarkIcon(m, zoomBasedIconsAllowed)
            ));
        }
        return '';
    }

    getLandmarksIcon = (marker: Object) => {
        const zoomBasedIconsAllowed = this.isZoomBasedIconsAllowed();

        return marker.landmarks.map(m => (
            this.getOverlayLandmarkIcon(m, zoomBasedIconsAllowed)
        ));
    };

    getLMIconType = (lmMarkerObj: Object = {}) => {
        let lmIconType = lmMarkerObj.landmarkIcon || (lmMarkerObj.attributes
            && (lmMarkerObj.attributes.lfLandmarkIcon || lmMarkerObj.attributes.att_0cm61));

        if (!lmIconType) lmIconType = 'default';

        return lmIconType;
    }

    getMarkerSvgIcon = (
        url: string, marker: Object, markerProps: Object,
        zoomBasedIconsAllowed: boolean = false,
    ) => {
        if ((marker && marker.shape && marker.shape.attributes) || marker.attributes) {
            const obj = (marker.shape && marker.shape.attributes) ? marker.shape : marker;
            return obj.typeCode ?
                this.getClusterIconSvgImage(
                    obj.attributes.icon
                        ? getAssetIconType(obj.attributes.icon)
                        : getAssetIconType(obj.typeCode),
                    {
                        backgroundColor: markerProps.color,
                        backgroundShapeType: markerProps.backgroundShapeType,
                        arrowDirection: markerProps.direction,
                    },
                    { ...markerProps.style }, zoomBasedIconsAllowed,
                ) :
                this.getClusterIconSvgImage(
                    getLandmarkIconType(this.getLMIconType(obj)),
                    {
                        backgroundColor: markerProps.color,
                        backgroundShapeType: markerProps.backgroundShapeType,
                    },
                    { width: 14, height: 18, top: -17 }, zoomBasedIconsAllowed,
                );
        }

        return (
            <div style={{ margin: '-40px 0 0 -17px', width: 35, height: 38 }}>
                <GetSvgIcon type={markerProps.backgroundShapeType || 'background_image'} fillcolor={markerProps.color} />
                <img
                    src={url}
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: -22,
                        transform: 'translate(-50%, -50%)',
                        ...markerProps.style,
                    }}
                    alt="location"
                />
            </div>
        );
    }

    getShapeType = (markerObj: Object) => ((markerObj.shape && markerObj.shape.type) ? markerObj.shape.type.toLowerCase() : '');

    getUniqueShapeKey = (markerObj: Object, shapeType: string = '', preferred: string = '') => {
        let shapeKey = markerObj.landmarkId || markerObj.assetId || markerObj.id;
        if (preferred) shapeKey = markerObj[preferred];
        else shapeKey = markerObj.landmarkId || markerObj.assetId || markerObj.id;

        if (!shapeKey) {
            if (['editablerectangle', 'editablepolygon', 'editablecircle'].includes(shapeType)) {
                shapeKey = `${markerObj.shape.points.lat}-${markerObj.shape.points.lng}`;
            } else shapeKey = `${markerObj.lat}-${markerObj.lng}`;
        }

        return shapeKey;
    }

    getLMshapeOption = (markerObj: Object = {}) => {
        const shapeOptions = {
            strokeColor: markerObj.landmarkLineColor
                || (markerObj.attributes && markerObj.attributes.lineColor),
            fillColor: markerObj.landmarkFillColor
                || (markerObj.attributes && markerObj.attributes.fillColor),
            fillOpacity: markerObj.landmarkFillDensity
                || (markerObj.attributes && markerObj.attributes.fillDensity),
        };

        shapeOptions.strokeColor = (shapeOptions.strokeColor) ? `#${shapeOptions.strokeColor}` : mapColor.landmark.strokeColor;
        shapeOptions.fillColor = (shapeOptions.fillColor) ? `#${shapeOptions.fillColor}` : mapColor.landmark.fillColor;
        shapeOptions.fillOpacity = shapeOptions.fillOpacity || mapColor.landmark.fillOpacity;

        return shapeOptions;
    }

    getLMShapeAndIcon = (lmMarkerData: any) => {
        const {
            google, openAddressWindow, closeAddressWindow, CircleRef,
        } = this.props;
        const shapeType = this.getShapeType(lmMarkerData);
        const shapeKey = this.getUniqueShapeKey(lmMarkerData, shapeType);
        const shapeOptions = this.getLMshapeOption(lmMarkerData);

        switch (shapeType) {
        case 'circle': {
            const { lat = 0, lng = 0, radius } = lmMarkerData.shape;
            if (lat === 0 && lng === 0) return '';

            return (
                <Circle
                    key={shapeKey}
                    ref={CircleRef}
                    defaultCenter={{ lat, lng }}
                    clickable
                    defaultRadius={radius}
                    options={shapeOptions}
                    onRightClick={openAddressWindow}
                    onClick={closeAddressWindow}
                />
            );
        }
        case 'polygon': {
            return (
                <Polygon
                    key={shapeKey}
                    paths={lmMarkerData.shape.points}
                    clickable
                    options={shapeOptions}
                    onRightClick={openAddressWindow}
                    onClick={closeAddressWindow}
                />
            );
        }
        case 'rectangle': {
            return (
                <Rectangle
                    key={shapeKey}
                    bounds={
                        new google.maps.LatLngBounds(
                            new google.maps.LatLng(
                                lmMarkerData.shape.point1.lat,
                                lmMarkerData.shape.point1.lng,
                            ),
                            new google.maps.LatLng(
                                lmMarkerData.shape.point2.lat,
                                lmMarkerData.shape.point2.lng,
                            ),
                        )
                    }
                    options={shapeOptions}
                    onRightClick={openAddressWindow}
                    onClick={closeAddressWindow}
                    clickable
                />
            );
        }
        default: return '';
        }
    }

    getEventIcon = (e: Object) => {
        if (!(this.isIgnOff(e) || this.isIgnOn(e) || !e.tripId)) {
            return (
                <CustomOverlayView
                    key={e.id}
                    position={{
                        lat: e.locationSegment.lat,
                        lng: e.locationSegment.lng,
                    }}
                    mapPaneName={(OverlayView) ? OverlayView.OVERLAY_MOUSE_TARGET : ''}
                    zIndex={e.tripIndex}
                >
                    <div
                        className={mapStyles.arrowIcon}
                        onClick={() => this.props.tripIconClick(e)}
                        tabIndex={0}
                        onKeyDown={() => {}}
                        role="button"
                    >
                        {isEmpty(e.movementSegment) ? getArrowIcon(0, '', e.tripColor, 0) : getArrowIcon(e.movementSegment.heading || 0, '', e.tripColor, e.movementSegment.speed || 0)}
                    </div>
                </CustomOverlayView>
            );
        }
        return '';
    };

    selectEventByIndex = (index: number) => (index % 8 === 0);

    getTripCommonIcon = (marker: Object) => (
        <React.Fragment>
            {marker.startPoint && marker.startPoint.latitude && marker.startPoint.longitude
                && !marker.hideIcon && (
                <CustomOverlayView
                    position={{
                        lat: marker.startPoint.latitude,
                        lng: marker.startPoint.longitude,
                    }}
                    mapPaneName={(OverlayView) ? OverlayView.OVERLAY_MOUSE_TARGET : ''}
                >
                    <div className={mapStyles.startIcon}>
                        {getTripStartIcon('small')}
                    </div>
                </CustomOverlayView>
            )}
            {!marker.hideIcon && marker.events.filter(event => this.isIgnOff(event)).map(event => (
                <CustomOverlayView
                    key={event.id}
                    position={{
                        lat: event.locationSegment.lat,
                        lng: event.locationSegment.lng,
                    }}
                    mapPaneName={(OverlayView) ? OverlayView.OVERLAY_MOUSE_TARGET : ''}
                >
                    <div className={mapStyles.stopIcon}>
                        {getTripStopIcon('small')}
                    </div>
                </CustomOverlayView>
            ))}
            {marker.zoomEvent && marker.zoomEvent.locationSegment &&
                <CustomOverlayView
                    position={{
                        lat: marker.zoomEvent.locationSegment.lat,
                        lng: marker.zoomEvent.locationSegment.lng,
                    }}
                    mapPaneName={(OverlayView) ? OverlayView.OVERLAY_MOUSE_TARGET : ''}
                >
                    <div className={mapStyles.arrowIconAnim}>
                        {this.getZoomIcon(marker)}
                    </div>
                </CustomOverlayView>
            }
        </React.Fragment>
    );

    resetClickedCluster = () => { this.clickedCluster = ''; }

    directionFitBound = (marker: Object, mapRef: Object) => {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend({
            lat: Number(marker.startPoint.lat()),
            lng: Number(marker.startPoint.lng()),
        });
        bounds.extend({
            lat: Number(marker.endPoint.lat()),
            lng: Number(marker.endPoint.lng()),
        });
        if (typeof mapRef.fitBounds === 'function') mapRef.fitBounds(bounds);
    }

    render() {
        const {
            marker, google, mapRef, editableCircleRef, editableRectangleRef, editablePolygonRef,
        } = this.props;
        const shapeType = this.getShapeType(marker);
        // we set Trip start/end event, which is used to get the map icon
        this.tripStartEvent = marker ? marker.tripStartEvent : IGNITION_ON;
        this.tripStopEvent = marker ? marker.tripStopEvent : IGNITION_OFF;
        const { shape } = marker;
        const eventCount = marker.events ? marker.events.length : 0;
        const isEventBeyondThreshold = eventCount > config.get('FLEET_TRIP_ICON_THRESHOLD_VALUE');
        const showAssetLabel = (getLocalStorageValue('mapAssetLabel') !== 'false');
        const showLandmarkLabel = (getLocalStorageValue('mapLandmarkLabel') !== 'false');
        const bounds = new window.google.maps.LatLngBounds();
        const labelContainerClassName = this.getAssetLMLabelContainerClassName();
        const zoomBasedIconsAllowed = this.isZoomBasedIconsAllowed();

        return (
            <React.Fragment>
                {(shapeType === 'markergroup') && this.getGroupMarkerIcon(marker)}
                {(shapeType === 'nearestassetsgroup') && this.getGroupMarkerIcon(marker)}
                {(shapeType === 'polyline') &&
                <React.Fragment>
                    {shape.data.map((t) => {
                        const wayPoints = [];
                        const icons = [];
                        t.events.forEach((e, index) => {
                            wayPoints.push({
                                lat: e.locationSegment.lat,
                                lng: e.locationSegment.lng,
                            });
                            if (!isEventBeyondThreshold || this.selectEventByIndex(index)) {
                                icons.push(this.getEventIcon(e));
                            }
                        });
                        return (
                            <React.Fragment key={t.id}>
                                {this.getPolyLine(wayPoints, t.directionOptions.polylineOptions)}
                                {icons}
                            </React.Fragment>
                        );
                    })}
                    {this.getTripCommonIcon(marker)}
                    {marker.landmarks && this.getLandmarksIcon(marker)}
                </React.Fragment>
                }
                {(shapeType === 'marker') &&
                <React.Fragment>
                    {this.getMarkerIcon(getImage('location_cluster'), marker, {
                        color: mapColor.icon,
                        size: 'large',
                        labelColor: mapColor.icon,
                    }, marker.resizeIcon && zoomBasedIconsAllowed)}
                    {marker.landmarks && this.getLandmarksIcon(marker)}
                </React.Fragment>
                }
                {(shapeType === 'editablecircle') &&
                <CustomCircle
                    key={this.getUniqueShapeKey(marker, shapeType)}
                    map={mapRef}
                    center={shape.points}
                    ref={editableCircleRef}
                    circle={shape.props}
                    google={google}
                    getCircleValue={shape.getCircleValue}
                    updateShape={(key, value, center) => shape.updateShape(key, value, center)}
                />
                }
                {(shapeType === 'editablerectangle') &&
                <CustomRectangle
                    key={this.getUniqueShapeKey(marker, shapeType)}
                    map={mapRef}
                    center={shape.points}
                    ref={editableRectangleRef}
                    rectangle={shape.props}
                    google={google}
                    getRectangleValue={shape.getRectangleValue}
                    updateShape={(key, value, center) => shape.updateShape(key, value, center)}
                />
                }
                {(shapeType === 'editablepolygon') &&
                <CustomPolygon
                    key={this.getUniqueShapeKey(marker, shapeType)}
                    map={mapRef}
                    center={shape.points}
                    ref={editablePolygonRef}
                    poly={shape.props}
                    google={google}
                    getPolygonValue={shape.getPolygonValue}
                    updateShape={(key, value, center) => shape.updateShape(key, value, center)}
                />
                }
                {(shapeType === 'cluster') && marker.data && marker.data.landmarkClusters &&
                    marker.data.landmarkClusters.map((landmarkCluster, i) => {
                        const lat = landmarkCluster.point.y || 0;
                        const lng = landmarkCluster.point.x || 0;
                        if (this.clickedCluster === 'landmark') bounds.extend({ lat, lng });

                        return (
                            <CustomOverlayView
                                key={`landmark-cluster-icon-${lat}-${lng}-${lat + i}`}
                                position={{ lat, lng }}
                                mapPaneName={(OverlayView) ? OverlayView.OVERLAY_MOUSE_TARGET : ''}
                            >
                                <div
                                    tabIndex={0}
                                    onClick={() => this.props.findClusterExplodePoint(landmarkCluster, 'landmarks')}
                                    onKeyDown={() => {}}
                                    role="button"
                                    className={`${mapStyles.landmarkClusters} ${mapStyles.overlayPos_5}`}
                                >
                                    <MapCluster key={`landmark-map-cluster-${lat}-${lng}-${lat + i}`} clusterData={landmarkCluster} type="landmarkCluster" />
                                </div>
                            </CustomOverlayView>
                        );
                    })
                }
                {(shapeType === 'cluster') && marker.data && marker.data.landmarks &&
                    marker.data.landmarks.map((landmark, i) => {
                        const IconColor = mapColor.cluster.landmarkColor;
                        const lat = (landmark && landmark.point) ? landmark.point.y : 0;
                        const lng = (landmark && landmark.point) ? landmark.point.x : 0;
                        const zIndexModifier = 150;

                        return (
                            <React.Fragment key={`landmark-marker-container-${landmark.landmarkId}`}>
                                {this.getLMShapeAndIcon(landmark)}
                                <CustomOverlayView
                                    key={`landmark-marker-${landmark.landmarkId}`}
                                    position={{ lat, lng }}
                                    mapPaneName={(OverlayView) ? OverlayView.OVERLAY_MOUSE_TARGET : ''}
                                    zIndex={i + zIndexModifier}
                                >
                                    <div
                                        tabIndex={0}
                                        onClick={() => {
                                            this.props.showClusterInfo('landmark', landmark.landmarkId);
                                            const params = {
                                                feature: 'Map',
                                                landmarkName: landmark.landmarkName,
                                            };
                                            analytics.track('SELECT_LANDMARK_FROM_CLUSTER', params);
                                        }}
                                        onKeyDown={() => {}}
                                        role="button"
                                        className={mapStyles.overlayPos_2}
                                    >
                                        {this.getClusterIconSvgImage(
                                            getLandmarkIconType(this.getLMIconType(landmark)),
                                            { backgroundColor: IconColor },
                                            { width: 14, height: 18, top: -17 },
                                            zoomBasedIconsAllowed,
                                        )}

                                        {showLandmarkLabel &&
                                        <div
                                            style={{ backgroundColor: IconColor }}
                                            className={labelContainerClassName}
                                        >
                                            <span className={mapStyles.label}>
                                                {landmark.landmarkName}
                                            </span>
                                        </div>
                                        }
                                    </div>
                                </CustomOverlayView>
                            </React.Fragment>);
                    })
                }
                {(shapeType === 'cluster') && marker.data && marker.data.assetClusters
                    && marker.data.assetClusters.map((assetCluster, i) => {
                        const lat = (assetCluster.point) ? assetCluster.point.y : 0;
                        const lng = (assetCluster.point) ? assetCluster.point.x : 0;
                        if (this.clickedCluster === 'asset') bounds.extend({ lat, lng });

                        return (
                            <CustomOverlayView
                                key={`asset-cluster-icon-${lat}-${lng}-${lat + i}`}
                                position={{ lat, lng }}
                                mapPaneName={(OverlayView) ? OverlayView.OVERLAY_MOUSE_TARGET : ''}
                            >
                                <div
                                    tabIndex={0}
                                    onClick={() => this.props.findClusterExplodePoint(assetCluster, 'assets')}
                                    onKeyDown={() => {}}
                                    role="button"
                                    className={`${mapStyles.assetClusters} ${mapStyles.overlayPos_3}`}
                                >
                                    <MapCluster key={`asset-map-cluster-${lat}-${lng}-${lat + i}`} clusterData={assetCluster} type="assetCluster" />
                                </div>
                            </CustomOverlayView>
                        );
                    })
                }
                {(shapeType === 'cluster') && marker.data && marker.data.assets &&
                    marker.data.assets.map((asset, i) => {
                        const image = getAssetIconFromType(asset.assetType);
                        const color = getAssetColorFromStatus(asset, false);
                        const labelColor = asset.assetLabelColor || color;
                        const assetId = (color) ? `${asset.assetId}-${color.substr(1, color.length)}` : `${asset.assetId}`;
                        let lat = 0;
                        let lng = 0;
                        let keyType = 1;
                        const zIndexModifier = 150
                            + ((marker.data.landmarks) ? marker.data.landmarks.length : 0);

                        if (asset.point) {
                            lat = asset.point.y;
                            lng = asset.point.x;
                        } else if (asset.lastLocation) {
                            lat = asset.lastLocation.y;
                            lng = asset.lastLocation.x;
                            keyType = 2;
                        }

                        return (
                            <CustomOverlayView
                                key={`asset-marker-${asset.assetId}-${keyType}`}
                                position={{ lat, lng }}
                                mapPaneName={(OverlayView) ? OverlayView.OVERLAY_MOUSE_TARGET : ''}
                                zIndex={i + zIndexModifier}
                            >
                                <React.Fragment>
                                    <div id={assetId} />
                                    <div
                                        tabIndex={0}
                                        onClick={() => {
                                            this.props.showClusterInfo('asset', asset.assetId);
                                            const params = {
                                                feature: 'Map',
                                                assetName: asset.assetName,
                                            };
                                            analytics.track('SELECT_ASSET_FROM_CLUSTER', params);
                                        }}
                                        onKeyDown={() => {}}
                                        role="button"
                                        className={mapStyles.overlayPos_1}
                                    >
                                        {this.getClusterIconSvgImage(
                                            asset.assetIcon ? getAssetIconType(asset.assetIcon)
                                                : getAssetIconType(asset.assetType),
                                            {
                                                backgroundColor: color,
                                                arrowDirection: asset.direction,
                                            },
                                            { ...image.style }, zoomBasedIconsAllowed,
                                        )}
                                        {showAssetLabel &&
                                        <div
                                            style={{ backgroundColor: labelColor }}
                                            className={labelContainerClassName}
                                        >
                                            <span className={mapStyles.label}>
                                                {asset.assetName}
                                            </span>
                                        </div>
                                        }
                                    </div>
                                </React.Fragment>
                            </CustomOverlayView>);
                    })
                }
                {(shapeType === 'cluster') && marker.boundaries &&
                    marker.boundaries.map(landmark => this.getMarkerIcon(getImage('location_cluster'), {
                        lat: landmark.lat,
                        lng: landmark.lng,
                    }, { color: mapColor.cluster.landmarkColor, size: 'large' }, zoomBasedIconsAllowed))
                }
                {(shapeType === 'cluster') && (this.clickedCluster !== '') && this.resetClickedCluster()}
                {(shapeType === 'nearestassetlandmark') &&
                <React.Fragment>
                    {(marker.directions && marker.directions.status) ?
                        <div>
                            {marker.landmark && this.getLMShapeAndIcon(marker.landmark)}
                            <DirectionsRenderer
                                directions={marker.directions}
                                options={{
                                    polylineOptions: { strokeColor: '#007aff', strokeWeight: 5 },
                                    suppressMarkers: true,
                                }}
                                alternatives="true"
                            />
                            {marker.landmark && marker.landmark.markerLat &&
                            this.getMarkerIcon(
                                getImage('location_cluster'),
                                {
                                    lat: marker.landmark.markerLat,
                                    lng: marker.landmark.markerLng,
                                    attributes: {
                                        lfLandmarkIcon: marker.landmark.icon,
                                    },
                                    shape: {
                                        id: marker.landmark.id,
                                        name: marker.landmark.name,
                                    },
                                    markerType: 'landmark',
                                },
                                {
                                    color: mapColor.icon,
                                    labelColor: mapColor.icon,
                                    size: 'large',
                                    onClick: marker.landmark.id ? () => marker.redirectTo(`/home?landmarkId=${marker.landmark.id}`) : null,
                                },
                                zoomBasedIconsAllowed,
                            )}
                            {marker.asset && marker.asset.lat &&
                            this.getMarkerIcon(
                                shape.location[0].image.image,
                                {
                                    lat: marker.asset.lat,
                                    lng: marker.asset.lng,
                                    typeCode: marker.asset.assetType,
                                    attributes: {
                                        icon: marker.asset.icon,
                                    },
                                    shape: {
                                        id: marker.asset.assetId,
                                        name: marker.asset.assetName,
                                    },
                                    markerType: 'asset',
                                },
                                {
                                    color: shape.location[0].color,
                                    labelColor: shape.location[0].labelColor,
                                    size: 'large',
                                    direction: marker.asset.direction,
                                    onClick: isPairedAsset(marker.asset) ? () => marker.redirectTo(`/home?assetId=${marker.asset.assetId}`) : null,
                                },
                                zoomBasedIconsAllowed,
                            )}
                        </div>
                        :
                        <DirectionsRenderer />
                    }
                </React.Fragment>
                }
                {(shapeType === 'dispatchdirection') && marker.startPoint && marker.startPoint.lat
                    && marker.endPoint && marker.endPoint.lat && marker.direction
                    && this.directionFitBound(marker, mapRef)
                }
                {((shapeType === 'dispatchdirection') && (marker.direction)) ?
                    <DirectionsRenderer
                        directions={marker.direction}
                        panel={marker.panel}
                        options={{
                            polylineOptions: {
                                strokeColor: '#007aff',
                                strokeWeight: 8,
                            },
                        }}
                    />
                    :
                    <DirectionsRenderer />
                }
                {(shapeType === 'batch_directions') &&
                <div>
                    {(marker.directions && marker.directions.length > 0) &&
                    marker.directions.map((direction, index) => (
                        <DirectionsRenderer
                            key={`direction${index + 1}`}
                            directions={direction}
                            options={direction.pwaMapRenderOptions}
                        />
                    ))}
                    {
                        marker.events.map((e, index) => {
                            if (!isEventBeyondThreshold ||
                                this.selectEventByIndex(index)) {
                                return this.getEventIcon(e);
                            }
                            return null;
                        })
                    }
                    {shape.data.map((t) => {
                        const wayPoints = [];
                        t.events.forEach((e) => {
                            wayPoints.push({
                                lat: e.locationSegment.lat,
                                lng: e.locationSegment.lng,
                            });
                        });
                        return (
                            <div key={t.id}>
                                {this.getPolyLine(wayPoints, t.directionOptions.polylineOptions)}
                            </div>
                        );
                    })}
                    {this.getTripCommonIcon(marker)}
                    {marker.landmarks && this.getLandmarksIcon(marker)}
                </div>
                }
            </React.Fragment>
        );
    }
}

export default MapShapeHandler;
