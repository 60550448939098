/* @flow */
import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
// rxJs observables
import { ajax } from 'rxjs/observable/dom/ajax';
import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs/Observable';
// rxJS operators
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/retry';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/distinctUntilChanged';

import reducers from '../reducers';
import epics from '../epics';
import analyticMiddleware from '../analytics/analyticMiddleware';
import { getLocalStorageValue, setLocalStorageItem, lmLogout, redirectToAuthService } from './../helper-classes/utility-functions';

const fetchPredictions = (autocompleteService, value) => Observable.create((observer) => {
    if (autocompleteService.getPlacePredictions) {
        autocompleteService.getPlacePredictions({ input: value }, (predictions, status) => {
            if (status === 'OK') {
                observer.next(predictions);
            } else {
                observer.error(`ERROR: Found Empty Predictions: ${status}`);
            }
        });
    } else {
        observer.error('ERROR: Found Empty Predictions');
    }
});

const fetchLocationByPlaceId = (geocoder, value) => Observable.create((observer) => {
    geocoder.geocode({ placeId: value }, (predictions, status) => {
        if (status === 'OK') {
            observer.next(predictions);
        } else {
            observer.error(`ERROR: Found Empty Place : ${status}`);
        }
    });
});

const fetchDirections = (
    directionService,
    travelMode,
    unitSystem,
    startPoint,
    endPoint,
) => Observable.create((observer) => {
    directionService.route({
        origin: startPoint,
        destination: endPoint,
        travelMode,
        unitSystem,
        drivingOptions: {
            departureTime: new Date(),
            trafficModel: 'pessimistic',
        },
    }, (result, status) => {
        if (status === 'OK') {
            observer.next(result);
        } else {
            observer.next('null');
        }
    });
});

const fetchGeoCodeAddress = (geocoder, latlng) => Observable.create((observer) => {
    setTimeout(() => observer.next({ formatted_address: '', location: latlng }), 100);
});

const fetchAddressWithLatLng = (
    geocoder,
    lat,
    lng,
) => Observable.create((observer) => {
    geocoder.geocode({
        location: { lat, lng },
    }, (results, status) => {
        if (status === 'OK' && results[0]) {
            observer.next(results[0]);
        } else {
            observer.next('null');
        }
    });
});


const responseHandler =
    (responseStream: any, options: any = {}) => Observable.create((observer) => {
        responseStream.subscribe(
            res => observer.next(res),
            (err) => {
                const { bypassAuthError = false } = options;
                if (!bypassAuthError && err.status === 401 && window.location.pathname !== '/login') {
                    const isIframe = window.location.pathname.match(/^\/iframe/i);
                    const REMEMBER_ME = getLocalStorageValue('REMEMBER_ME');
                    const impersonated = getLocalStorageValue('fleet-impersonated') || '';
                    lmLogout();

                    if (!isIframe) {
                        localStorage.clear();
                    }
                    if (REMEMBER_ME) {
                        setLocalStorageItem('REMEMBER_ME', REMEMBER_ME);
                    }
                    if (impersonated) {
                        setLocalStorageItem('fleet-impersonated', impersonated);
                    }
                    setLocalStorageItem('sessionExpired', 'true');
                    if (isIframe) {
                        window.location.href = '/error-page/invalid-session';
                    } else if (window.location.pathname !== '/auth/tokenLogin') {
                        redirectToAuthService({ isError: true, errorCode: 'ACCESS_DENIED' });
                    }
                }
                return observer.error(err);
            },
        );
    });

const getWithCredentials = (url) => responseHandler(Observable.merge(fetch(url, { credentials: 'include' })));

const getJSON = (url, header, options) => responseHandler(ajax.getJSON(url, header), options);
const postJSON = (url, body, header, options) =>
    responseHandler(ajax.post(url, body, header), options);
const putJSON = (...requestData) => responseHandler(ajax.put(...requestData));
const getData = (...requestData) => responseHandler(ajax.get(...requestData));
const deleteJSON = (...requestData) => responseHandler(ajax.delete(...requestData));

const epicMiddleware = createEpicMiddleware(epics, {
    dependencies: {
        getJSON,
        fetchPredictions,
        fetchLocationByPlaceId,
        fetchGeoCodeAddress,
        fetchDirections,
        fetchAddressWithLatLng,
        postJSON,
        post: postJSON,
        put: putJSON,
        get: getData,
        deleteJSON,
        of,
        Observable,
        getWithCredentials,
    },
});

const store = createStore(reducers, applyMiddleware(analyticMiddleware, epicMiddleware));

export default store;
