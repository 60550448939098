/* @flow */
import Cookies from 'js-cookie';
import * as c from './constants.appNotifications';
import store from '../../../constants/Store';
import {
    getUserToken, getCurrentAccountUserId, convertDateTime,
    getTimeFormatAsperUserSettting, getLocalStorageValue,
    getPermissionValue,
} from '../../../helper-classes/utility-functions';
import type { AppNotificationHandlerAction } from './constants.appNotifications';

export const appNotificationHandler = (data: Array<Object>): AppNotificationHandlerAction => ({
    type: c.APP_NOTIFICATION_HANDLER,
    payload: { data },
});

export const addCardNotification = (...data: Array<Object>): AppNotificationHandlerAction => ({
    type: c.ADD_CARD_NOTIFICATION,
    payload: { data },
});

export const removeCardNotification = (...data: Array<Object>): AppNotificationHandlerAction => ({
    type: c.REMOVE_CARD_NOTIFICATION,
    payload: { data },
});

export const clearNotification = (): AppNotificationHandlerAction => ({
    type: c.CLEAR_NOTIFICATION,
});

export const notificationPollingHandler = (data: Object = {}): AppNotificationHandlerAction => ({
    type: c.NOTIFICATION_PULL_HANDLER,
    payload: { ...data },
});

const checkImportPermission = () => ((getPermissionValue('Admin') !== 'None') && (getPermissionValue('Admin') !== 'View'));

export const fetchInProgressImportJobs = (historyObj: Object): AppNotificationHandlerAction => {
    const isImportPermitted = checkImportPermission();
    if (isImportPermitted) {
        return ({
            type: c.FETCH_IN_PROGRESS_IMPORT_JOBS,
            payload: { historyObj },
        });
    }
    return ({ type: c.NOTIFICATION_PULL_HANDLER });
};

export const checkInProgressImportJobStatus = (data: Object): AppNotificationHandlerAction => ({
    type: c.FETCH_IN_PROGRESS_IMPORT_JOBS_STATUS,
    payload: { ...data },
});

export const checkImportJobStatusHandler = (data: Object) =>
    store.dispatch(checkInProgressImportJobStatus(data));

export const addNewInProgressImportJob =
(data: Array<Object>, historyObj: Object): AppNotificationHandlerAction => {
    const isImportPermitted = checkImportPermission();
    if (isImportPermitted) {
        return ({
            type: c.ADD_NEW_IN_PROGRESS_IMPORT_JOB,
            payload: { data, historyObj },
        });
    }
    return ({ type: c.NOTIFICATION_PULL_HANDLER });
};

export const removeInProgressImportJob =
(data: Array<Object>, historyObj: Object): AppNotificationHandlerAction => {
    const isImportPermitted = checkImportPermission();
    if (isImportPermitted) {
        return ({
            type: c.REMOVE_IN_PROGRESS_IMPORT_JOB,
            payload: { data, historyObj },
        });
    }
    return ({ type: c.NOTIFICATION_PULL_HANDLER });
};

export const startJobStatusPolling =
(historyObj: Object): AppNotificationHandlerAction => {
    const isImportPermitted = checkImportPermission();
    if (isImportPermitted) {
        return ({
            type: c.NOTIFICATION_POLLING,
            payload: { historyObj },
        });
    }
    return ({ type: c.NOTIFICATION_PULL_HANDLER });
};

const snackbarNotificationData = {
    type: 'snackbar',
    isOpen: true,
    overrideConfig: {
        key: 'import-job-notification',
        autoHideDuration: 3000,
        action: undefined,
    },
};

const handleCloseNotification = (e, reason, notificationObj) => {
    if (reason !== 'clickaway' && notificationObj.overrideConfig && notificationObj.overrideConfig.key === 'import-job-notification') {
        store.dispatch(appNotificationHandler([{
            ...snackbarNotificationData,
            isOpen: false,
        }]));
    }
};

export const unsubscribePollingJobs = (): AppNotificationHandlerAction => ({
    type: c.UNSUBSCRIBER_POLLING_JOBS,
});

export const importJobsCookieDataHandler = (
    newImportJobs: Array<any> = [],
    action: string = c.IMPORT_ACTION_ADD,
    historyObj: Object = {},
    resCode: number = 0,
) => {
    const IMPORT_JOBS_COOKIES_HANDLER = `ijcdh_${getCurrentAccountUserId()}`; // import jobs cookie variable name
    const importJobIDsToUpdate = Cookies.getJSON(IMPORT_JOBS_COOKIES_HANDLER) || [];
    const restartStatusCheckAPI = (importJobIDsToUpdate.length === 0);
    let updateCookieData = false;

    if (action === c.IMPORT_ACTION_GET) return importJobIDsToUpdate;
    if ([c.IMPORT_ACTION_ADD, c.IMPORT_ACTION_UPDATE].includes(action)) newImportJobs.reverse();

    if ((newImportJobs.length > 0) && (getUserToken() !== '')) {
        newImportJobs.map((importJob) => {
            const jobIndex = importJobIDsToUpdate.indexOf(importJob.jobId);
            const storeData = store.getState();
            const notificationObj = storeData.appNotifications.AppNotificationHandler;
            const i = notificationObj.findIndex(nItem => nItem && (nItem.type === 'nCard'));
            let cardNotificationAry = [];
            if (i > -1) cardNotificationAry = notificationObj[i].message;

            let status = '';
            let statusType = '';

            switch (importJob.status) {
            case 'CANCELLED':
                status = 'Cancelled';
                statusType = (importJob.subStatus === 'COMPLETED') ? 'completed' : 'cancelled';
                break;
            case 'INPROGRESS':
                status = 'Submitted';
                statusType = 'inprogress';
                break;
            case 'COMPLETED':
                status = (importJob.failedRecords > 0) ? `${importJob.failedRecords} Error${importJob.failedRecords > 1 ? 's' : ''}` : 'Completed';
                statusType = (importJob.failedRecords > 0) ? 'error' : 'success';
                break;
            case 'PROCESSING_STARTED':
                status = 'Submitted';
                statusType = 'inprogress';
                break;
            default:
                status = importJob.status && importJob.status.toLowerCase();
                statusType = 'info';
                break;
            }

            const jobMsgIndex = cardNotificationAry.findIndex(jnItem => jnItem &&
                (jnItem.id === importJob.jobId) && (jnItem.status === status));

            const closedNotificationAry = JSON.parse(getLocalStorageValue('CCNDAry') || '[]');
            const closedNotificationIndex = closedNotificationAry.findIndex(cnItem => cnItem &&
                (cnItem.id === importJob.jobId) && (cnItem.status === status));

            if ([c.IMPORT_ACTION_ADD,
                c.IMPORT_ACTION_ADDNEW,
                c.IMPORT_ACTION_UPDATE,
                c.IMPORT_ACTION_REMOVE]
                .includes(action) && jobMsgIndex === -1 && closedNotificationIndex === -1) {
                let entityType = '';
                const entityTypeOriginal = importJob.entityType;

                switch (entityTypeOriginal) {
                case 'USER':
                    entityType = 'user';
                    break;
                case 'LANDMARK_ADDRESS':
                case 'LANDMARK_LAT_LNG':
                case 'LANDMARK_POLYGON':
                    entityType = 'landmark';
                    break;
                case 'ASSET':
                    entityType = 'asset';
                    break;
                case 'DRIVER':
                    entityType = 'driver';
                    break;
                default:
                    entityType = '';
                    break;
                }

                if (action === c.IMPORT_ACTION_REMOVE) {
                    store.dispatch(removeCardNotification({
                        nCardIDToRemove: importJob.jobId,
                        titleToRemove: `Import - ${entityType}`,
                        statusToRemove: 'Submitted',
                        skipDate: true,
                    }));
                } else if (status !== 'Cancelled') {
                    const jobNotificationData = {
                        title: `Import - ${entityType}`,
                        for: 'import-job',
                        id: importJob.jobId,
                        icon: entityType,
                        status,
                        statusType,
                        statusDate: convertDateTime(status === 'Submitted' ? importJob.dateCreated : importJob.lastUpdated, `MM/DD/YYYY ${(getTimeFormatAsperUserSettting() === '24h') ? 'HH:mm' : 'hh:mm A'}`),
                        clickable: true,
                        onClick: (clickedJobId) => {
                            if (getPermissionValue('Admin') === 'None') {
                                return;
                            }

                            let redirectPath = '/import/history';
                            if (['inprogress', 'success'].includes(statusType)) {
                                if (redirectPath !== historyObj.location.pathname) {
                                    historyObj.push(redirectPath, { statusType });
                                } else historyObj.replace(redirectPath, { statusType });
                            } else {
                                redirectPath = `/import/${entityType}s/${clickedJobId}`;
                                if (redirectPath !== historyObj.location.pathname) {
                                    historyObj.push(redirectPath);
                                } else historyObj.replace(redirectPath);
                            }
                        },
                    };
                    store.dispatch(addCardNotification(jobNotificationData));
                }

                if ([c.IMPORT_ACTION_ADD, c.IMPORT_ACTION_ADDNEW].includes(action) && statusType === 'inprogress' && jobIndex === -1) {
                    importJobIDsToUpdate.push(importJob.jobId);
                    updateCookieData = true;

                    if (restartStatusCheckAPI && importJobIDsToUpdate.length > 0) {
                        store.dispatch(startJobStatusPolling(historyObj));
                    }

                    if (action === c.IMPORT_ACTION_ADDNEW) {
                        store.dispatch(appNotificationHandler([{
                            ...snackbarNotificationData,
                            message: `${entityType.toUpperCase()} import job submitted successfully.`,
                            nConfig: {
                                vertical: 'top',
                                horizontal: 'right',
                                onCloseNotification: handleCloseNotification,
                            },
                        }]));
                    }
                } else if (action === c.IMPORT_ACTION_REMOVE) {
                    importJobIDsToUpdate.splice(jobIndex, 1);
                    updateCookieData = true;

                    let notificationMsg = '';
                    if (statusType === 'cancelled') {
                        notificationMsg = `${entityType.toUpperCase()} import job of '${importJob.fileName}' is cancelled.`;
                    } else if (statusType === 'completed') {
                        notificationMsg = `${entityType.toUpperCase()} import job of '${importJob.fileName}' can't be cancelled, as its status is already COMPLETED.`;
                    }

                    store.dispatch(appNotificationHandler([{
                        ...snackbarNotificationData,
                        message: notificationMsg,
                        nConfig: {
                            vertical: 'top',
                            horizontal: 'right',
                            onCloseNotification: handleCloseNotification,
                        },
                    }]));
                } else if ((statusType !== 'inprogress') && (status !== 'Cancelled')) {
                    importJobIDsToUpdate.splice(jobIndex, 1);
                    updateCookieData = true;
                    store.dispatch(appNotificationHandler([{
                        ...snackbarNotificationData,
                        message: `${entityType.toUpperCase()} import job completed ${(statusType === 'success') ? 'successfully.' : `with ${status && status.toLowerCase()}.`}`,
                        nConfig: {
                            vertical: 'top',
                            horizontal: 'right',
                            onCloseNotification: handleCloseNotification,
                        },
                    }]));
                }
            }
            return true;
        });
    } else if ((action === c.IMPORT_ACTION_UPDATE)
        && (importJobIDsToUpdate.length > 0) && (resCode === 200)) {
        importJobIDsToUpdate.splice(0);
        updateCookieData = true;
    }

    if (updateCookieData) {
        Cookies.set(
            IMPORT_JOBS_COOKIES_HANDLER,
            JSON.stringify(importJobIDsToUpdate), { expires: 14 }, // expires time in days
        );
    }

    return store.dispatch(notificationPollingHandler());
};

export const importJobStatusHandler = (historyObj: Object, resCode: number, ...data: Array<any>) =>
    importJobsCookieDataHandler(data[0] || [], c.IMPORT_ACTION_UPDATE, historyObj, resCode);
