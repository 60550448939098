/* @flow */
import React, { Component } from 'react';

import MiniDrawer from '../../../components/SideMenu/SideMenuDrawer';
import DiagnosticList from './DiagnosticList';

export type Props = {
    history: {
        push: Function,
    },
};

class DiagnosticListContainer extends Component<Props, {}> {
    state = {};
    render() {
        return (
            <MiniDrawer redirectTo={this.props.history.push}>
                <DiagnosticList />
            </MiniDrawer>
        );
    }
}

export default DiagnosticListContainer;
