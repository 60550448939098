/* @flow */
import React from 'react';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.globalsearch';
import UserAccount from '../UserAccount/UserAccount';
import styles from './Header.module.scss';
import SessionValidator from '../SessionValidator';
import SpudNotification from '../BannerNotification/SpudNotification';
import { getDefaultLandingPage } from '../../helper-classes/utility-functions';
import * as notificationActions from '../../components/SharedComponents/NotificationHandler/actions.appNotifications';

type Props = {
    logout: Function,
    userIdentity: Function;
    redirectToBack: Function;
    updateTime: any;
    globalSearchResults: {
        userIdentity: {
            user: {
                firstName: string, lastName: string,
            },
        },
    },
    disableBackButton: boolean,
    clearNotification: Function,
    unsubscribePollingJobs: Function,
};

type State = {
    showPopup: boolean,
}

class Header extends React.Component<Props, State> {
    static defaultProps = {
        logout: () => { },
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            showPopup: false,
        };
    }

    componentDidMount() {
        document.addEventListener('click', (e: Event) => this.closePopup(e));
        this.props.userIdentity();
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (nextProps.updateTime !== this.props.updateTime) {
            this.props.userIdentity();
        }
    }

    closePopup = (e: any) => {
        const userPopup = document.getElementById('user-popup');
        const userAccountButton = document.getElementById('account_settings');

        if (this.state.showPopup && userPopup && !userPopup.contains(e.target)
            && userAccountButton && !userAccountButton.contains(e.target)) {
            this.setState({ showPopup: false });
        }
    }

    logoutRequest = () => this.props.logout();

    getUserData = () => this.setState({
        showPopup: !this.state.showPopup,
    });

    clearNotifications = () => this.props.clearNotification();

    unsubscribePollingJobs = () => this.props.unsubscribePollingJobs();

    render() {
        const { userIdentity } = this.props.globalSearchResults;
        return (
            <div className={styles['plain-header']}>
                {(localStorage.getItem('selectedAccount') && getDefaultLandingPage() !== '/settings') &&
                    <button
                        id="back-button"
                        className={styles['back-button']}
                        onClick={() => this.props.redirectToBack()}
                        disabled={this.props.disableBackButton}
                    >
                        <i className={styles['back-icon']} />
                        <span>
                            Back
                        </span>
                    </button>
                }
                <button
                    id="account_settings"
                    onClick={() => this.getUserData()}
                    className={styles.account_settings}
                >
                    <i className={styles['user-icon']} />
                    <span>
                        {userIdentity.user && `${userIdentity.user.firstName} ${userIdentity.user.lastName}`}
                    </span>
                </button>
                {this.state.showPopup &&
                    <UserAccount
                        logout={this.logoutRequest}
                        userIdentity={userIdentity}
                        clearNotifications={this.clearNotifications}
                        unsubscribePollingJobs={this.unsubscribePollingJobs}
                    />
                }
                <SpudNotification />
                <SessionValidator horizontal="left" vertical="bottom" />
            </div>
        );
    }
}

/**
 * Since there is a reference issue, so doing the destructing.
 * @param state
 * @returns {{ globalSearchResults: {} }}
 */
const mapStateToProps = state => ({
    globalSearchResults: state.globalSearch,
    updateTime: state.settings.updateTime,
});

const mapDispatchToProps = {
    ...actions,
    ...notificationActions,
};

export default reduxConnect(Header, mapDispatchToProps, mapStateToProps);
