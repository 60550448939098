/* @flow */
import React, { Component } from 'react';
import queryString from 'query-string';
import withStyles from '@mui/styles/withStyles';
import { reduxConnect } from '../../hoc';
import SideMenuDrawer from '../../components/SideMenu/SideMenuDrawer';
import driverStyles from './Driver.module.scss';
import DriverSettings from '../../components/Driver/Settings';
import * as actions from './Settings/actions.driverSettings';
import Dialog from '../../components/SharedComponents/Dialog/Dialog';
import AppLoader from '../../components/AppLoader';
import { getLocalStorageValue } from '../../helper-classes/utility-functions';
import { DefaultSettings } from './Settings/constants.driverSettings';

export type Props = {
    history: {
        push: Function,
    },
    location: any,
    driverSettings: Object,
    addDriverSettings: Function,
    fetchDriverSettings: Function,
    updateDriverSettings: Function,
    responseStatus: boolean,
    responseType: string,
    responseData: Object,
    closeDialog: Function,
    updateLoader: Function,
    loading: boolean,
};

export type State = {
    page: string,
    openDialog: boolean,
    actionType: string,
};

class Driver extends Component<Props, State> {
    id: string;
    button: any;
    isDefault: boolean;
    iframeUserToken: string;
    isIframe: boolean;
    constructor(props) {
        super(props);
        this.state = {
            page: this.props.location.pathname,
            openDialog: false,
            actionType: 'get',
        };
        this.id = '';
        this.button = [];
        this.isDefault = false;
        this.isIframe = this.props.location.pathname.match(/^\/iframe/i);
    }

    UNSAFE_componentWillMount() {
        const queryParams = queryString.parse(this.props.location.search);
        const iframeUserToken = localStorage.getItem('iframeUserToken');
        if (queryParams.apiToken) {
            localStorage.setItem('iframeUserToken', queryParams.apiToken);
            this.iframeUserToken = queryParams.apiToken;
        } else if (iframeUserToken && iframeUserToken !== null) {
            this.iframeUserToken = iframeUserToken;
        }
    }

    componentDidMount() {
        const { page } = this.state;
        if (page === '/driver-setup' || page === '/iframe/driver-setup') {
            this.props.updateLoader(true);
            this.id = getLocalStorageValue('currentAccountGlobalId') || '';
            const dUrlConfig = {
                id: getLocalStorageValue('currentAccountGlobalId') || '',
                name: 'default', // for future uses
            };
            this.props.fetchDriverSettings(dUrlConfig);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { page } = this.state;
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.setState({ page: nextProps.location.pathname });
        }

        if (page === '/driver-setup' || page === '/iframe/driver-setup') {
            if (!nextProps.loading) {
                if (nextProps.responseType === 'get') {
                    if (Object.keys(nextProps.driverSettings).length > 0) {
                        this.setState({ actionType: 'edit' });
                    } else {
                        this.setState({ actionType: 'add' }, () => {
                            if (nextProps.responseStatus) {
                                this.isDefault = true;
                                this.handleSubmitSettings(DefaultSettings);
                            }
                        });
                    }
                }

                if ((['get', 'add', 'update'].includes(nextProps.responseType) && !nextProps.responseStatus) || (['add', 'update'].includes(nextProps.responseType) && nextProps.responseStatus)) {
                    if ((!this.isDefault) || (nextProps.responseType === 'add' && !nextProps.responseStatus)) {
                        this.setState({ openDialog: true });
                    } else {
                        this.setState({ actionType: 'edit' });
                    }
                }
            }
        }
    }

    formatError = (content, error) => {
        let newContent = content;
        if (error && Object.keys(error).length > 0) {
            let errors = error.message || error.msg || error.error.msg
            || error.error.message || error.error;

            if (errors !== undefined) {
                if (['ajax error 0', 'ajax error 404', 'ajax error 405'].includes(errors)) {
                    errors = 'Bad URI or Missing Resource?';
                }
                errors = Object.setPrototypeOf(errors.split(','), Object.prototype);
            } else {
                ({ errors } = error.errors);
            }

            if (errors.length > 1) {
                newContent = (
                    <div>
                        {newContent}
                        <ul>
                            {
                                Object.keys(errors).map(d =>
                                    (<li
                                        key={d}
                                        // eslint-disable-next-line
                                        dangerouslySetInnerHTML={{ __html: errors[d] }}
                                    />))
                            }
                        </ul>
                    </div>
                );
            } else {
                newContent = (
                    (<span
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{ __html: newContent + ' ' + errors[0] }}
                    />)
                );
            }
        } else {
            newContent = `${newContent} We're sorry we are currently experiencing some technical issues.`;
        }
        return newContent;
    }

    getDialogContent = () => {
        const { responseStatus, responseData } = this.props;
        const { actionType } = this.state;
        let content = 'Are you sure you want to delete this driver\'s record?';

        if (actionType === 'add') {
            content = 'Driver settings added successfully.';
            if (!responseStatus) {
                content = 'Details:';
                content = this.formatError(content, responseData);
            }
        } else if (actionType === 'edit') {
            content = 'Driver settings updated successfully.';
            if (!responseStatus) {
                content = 'Details:';
                content = this.formatError(content, responseData);
            }
        }
        return content;
    }

    getDialogType = () => {
        const { responseStatus } = this.props;
        const { actionType } = this.state;
        let type = 'confirm';
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleDialogConfirm(),
        }];

        if (actionType !== 'get') {
            type = responseStatus ? 'success' : 'error';
        } else {
            this.button.splice(0, 0, { title: 'Cancel', color: 'primary', handleClick: () => this.setState({ openDialog: false }) });
        }
        return type;
    };

    handleDialogConfirm = () => {
        const {
            responseType,
            responseStatus,
            closeDialog,
            // history,
        } = this.props;
        const { actionType, openDialog } = this.state;
        closeDialog();

        if (actionType === 'add' || actionType === 'edit') {
            if (responseType === 'get' && !responseStatus && openDialog) {
                this.setState({ openDialog: false });
            } else if (responseStatus && responseType === 'add') {
                this.setState({ openDialog: false, actionType: 'edit' });
            } else {
                this.setState({ openDialog: false });
            }
        }
    }

    handleSubmitSettings = (settings, isAdvanceScoring) => {
        const { actionType } = this.state;
        this.props.updateLoader(true);
        const settingsToSend = (actionType === 'edit') ? Object.assign({}, this.props.driverSettings) : {};
        settingsToSend.settings = settings;
        settingsToSend.advancedScoring = isAdvanceScoring;
        settingsToSend.name = 'default';

        if (actionType === 'edit') {
            settingsToSend.updatedBy = settingsToSend.createdBy;
            this.props.updateDriverSettings(settingsToSend);
        } else if (actionType === 'add') {
            this.props.addDriverSettings(settingsToSend);
        }
    }

    render() {
        let getTemplate = '';
        const { page, openDialog } = this.state;
        const { loading, driverSettings } = this.props;

        if (page === '/driver-setup' || page === '/iframe/driver-setup') {
            getTemplate = (
                <React.Fragment>
                    {loading &&
                        <AppLoader type="fullScreen" loaderStyle={{ left: '40% !important', top: '55% !important' }} />
                    }
                    <DriverSettings
                        driverSettings={driverSettings}
                        submitSettings={this.handleSubmitSettings}
                        iframeUserToken={this.iframeUserToken}
                    />
                    <Dialog
                        open={openDialog}
                        type={this.getDialogType()}
                        customTitle=""
                        button={this.button}
                        content={this.getDialogContent()}
                        size="lg"
                    />
                </React.Fragment>);
        }

        return (
            this.iframeUserToken && this.isIframe ?
                getTemplate
                :
                <SideMenuDrawer
                    redirectTo={this.props.history.push}
                >
                    {getTemplate}
                </SideMenuDrawer>
        );
    }
}

const mapStateToProps = state => state.driverSettings;
export default withStyles(
    driverStyles,
    { withTheme: true },
)(reduxConnect(Driver, actions, mapStateToProps));
