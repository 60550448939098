/* @flow */
import {
    FETCH_SAVED_ALERTS,
    FETCH_SAVED_ALERTS_SUCCESS,
    FETCH_SAVED_ALERTS_ERROR,
    FETCH_SAVED_ALERTS_LIST,
    DELETE_SAVED_ALERTS,
    DELETE_SAVED_ALERTS_SUCCESS,
    DELETE_SAVED_ALERTS_FAILURE,
} from './constants.savedAlerts';

const initialState = {
    savedAlerts: [],
    isUpdating: false,
    isAlertDeleted: false,
};

type stateType = {
    savedAlerts: Array<Object>,
    isUpdating: boolean,
};
type actionType = {
    type: string,
    payload: {
        savedAlerts: {},
        totalRecords: number,
    },
};

export default (state: stateType = initialState, action: actionType) => {
    switch (action.type) {
    case FETCH_SAVED_ALERTS:
        return {
            ...state,
            isUpdating: true,
            isAlertDeleted: false,
        };
    case FETCH_SAVED_ALERTS_LIST:
        return {
            ...state,
            isUpdating: false,
            savedAlerts: [],
            totalRecords: 0,
        };
    case FETCH_SAVED_ALERTS_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            savedAlerts: action.payload.savedAlerts,
            totalRecords: action.payload.totalRecords,
        };
    case FETCH_SAVED_ALERTS_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    case DELETE_SAVED_ALERTS:
        return {
            ...state,
            isLoading: true,
            isAlertDeleted: false,
        };
    case DELETE_SAVED_ALERTS_SUCCESS:
        return {
            ...state,
            isLoading: false,
            isAlertDeleted: true,
            response: action.payload,
        };
    case DELETE_SAVED_ALERTS_FAILURE:
        return {
            ...state,
            isLoading: false,
            response: action.payload,
        };
    default:
        return state;
    }
};
