
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { getLocalStorageValue, isAcceptedTermsAndConditions, getDefaultLandingPageURLWithSlash } from '../helper-classes/utility-functions';

const PrivateRoute = ({
    component: Component,
    isAuthenticated,
    show,
    componentProps,
    ...rest
}) => (
    <Route
        {...rest}
        render={(props) => {
            if (!getLocalStorageValue('fleet-impersonated') && !isAcceptedTermsAndConditions() && props.location && props.location.pathname && props.location.pathname !== '/terms-condition') {
                return <Redirect to={{ pathname: '/terms-condition', state: { from: props.location } }} />;
            } else if (props.location.pathname === '/terms-condition' && (isAcceptedTermsAndConditions() || getLocalStorageValue('fleet-impersonated'))) {
                return (<Redirect
                    to={{
                        pathname: getDefaultLandingPageURLWithSlash(),
                        state: { from: props.location },
                    }}
                />);
            }
            let component = <Redirect to={{ pathname: '/settings', state: { from: props.location } }} />;
            if (!isAuthenticated) {
                component = <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />;
            } else if (isAuthenticated && show !== 'None') {
                component = <Component {...componentProps} {...props} />;
            }
            return component;
        }}
    />
);

PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    location: PropTypes.shape(),
    show: PropTypes.string,
    componentProps: PropTypes.shape(),
};

PrivateRoute.defaultProps = {
    isAuthenticated: false,
    location: {},
    componentProps: {},
    show: 'None',
};

export default PrivateRoute;
