/* @flow */
import { LOOK } from '../containers/Looks/AddLook/constants.looks';

function getLookTypeObject(LOOK_TYPE) {
    return {
        code: LOOK_TYPE.CODE,
        name: LOOK_TYPE.NAME,
        description: LOOK_TYPE.DESCRIPTION,
        imageLocation: LOOK_TYPE.IMAGE_LOCATION,
        idPostfix: LOOK_TYPE.ID_POSTFIX,
    };
}

export const mapLookTypeToCategory = (lookNames: {}) => {
    const LookTypeList = [];
    const Looks = [];

    if (lookNames != null) {
        Object.keys(lookNames).forEach((key) => {
            const code = lookNames[key];
            switch (code) {
            case LOOK.HARD_BRAKING_LOOK.CODE:
                Looks[0] = getLookTypeObject(LOOK.HARD_BRAKING_LOOK);
                break;
            case LOOK.HARD_ACCELERATION_LOOK.CODE:
                Looks[1] = getLookTypeObject(LOOK.HARD_ACCELERATION_LOOK);
                break;
            case LOOK.IDLING_LOOK.CODE:
                Looks[2] = getLookTypeObject(LOOK.IDLING_LOOK);
                break;
            case LOOK.DISTANCE_TRAVELED_LOOK.CODE:
                Looks[3] = getLookTypeObject(LOOK.DISTANCE_TRAVELED_LOOK);
                break;
            case LOOK.SPEED_THRESHOLD_LOOK.CODE:
                Looks[4] = getLookTypeObject(LOOK.SPEED_THRESHOLD_LOOK);
                break;
            case LOOK.POSTED_SPEED_LOOK.CODE:
                Looks[5] = getLookTypeObject(LOOK.POSTED_SPEED_LOOK);
                break;
            default:
                break;
            }
        });
    }

    if (Looks.length > 0) {
        LookTypeList.push({
            lookCategory: LOOK.CATEGORY.LOOKS,
            lookCategoryID: LOOK.CATEGORY.LOOKS_ID,
            lookTypes: Looks,
        });
    }
    return LookTypeList;
};

export const filterLooks = (lookNames: {}) => {
    const filteredLookList = [];

    if (lookNames != null) {
        Object.keys(lookNames).forEach((key) => {
            const { code, id } = lookNames[key];
            let name = null;
            switch (code) {
            case LOOK.HARD_BRAKING_ACCELERATION_LOOK.CODE:
                name = LOOK.HARD_BRAKING_ACCELERATION_LOOK.NAME;
                break;
            default:
                break;
            }
            if (name != null) {
                filteredLookList.push({
                    code,
                    id,
                    name,
                });
            }
        });
    }
    return filteredLookList;
};

export const getLookID = (lookList: [], lookType: String) => {
    let lookID = 0;
    const lookObject = lookList.find(lookObj => (lookObj.code === lookType));
    if (lookObject !== undefined && lookObject !== null) {
        lookID = lookObject.id;
    }
    return lookID;
};

export const getLookType = (lookList: [], lookId: String) => {
    let lookType = '';
    const lookObject = lookList.find(lookObj => (lookObj.id === lookId));
    if (lookObject !== undefined && lookObject !== null) {
        lookType = lookObject.code;
    }
    return lookType;
};

export const filterLooksCode = (data: any) => {
    if (!data && data.length <= 0) return [];

    return data.map((d) => {
        const lookAdded = Object.assign({}, d);
        return lookAdded;
    });
};
