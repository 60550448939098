import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import styles from '../../Steppers/AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';

type Props = {
    formData:{
        config:{
            realert: Boolean,
            realertMinutes: Number,
        },
    },
    handleCheckboxChange: Function,
    handleTextboxChange: Function,
};

class OddHoursAlert extends React.Component<Props> {
    state = { };
    render() {
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    <div className={styles['step-rightside']}>
                        <form noValidate autoComplete="off">
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            id="realert"
                                            name="realert"
                                            checked={
                                                this.props.formData.config.realert === true
                                            }
                                            onChange={(e) => {
                                                this.props.handleCheckboxChange('realert')(e);
                                                if (!e.target.checked) {
                                                    this.props
                                                        .handleTextboxChange('realertMinutes')({
                                                            target: {
                                                                value: ALERT_DETAILS.VALUE.OHM_DEF,
                                                            },
                                                        });
                                                }
                                            }}
                                        />
                                    }
                                    label={ALERT_DETAILS.LABEL.ODD_HOURS_EVENTS}
                                />
                            </div>
                            <div>
                                <TextField
                                    margin="normal"
                                    className={styles['text-wid']}
                                    id="realertMinutes"
                                    name="realertMinutes"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: ALERT_DETAILS.VALUE.OHM_MIN,
                                            max: ALERT_DETAILS.VALUE.OHM_MAX,
                                        },
                                    }}
                                    disabled={!this.props.formData.config.realert}
                                    value={this.props.formData.config.realertMinutes}
                                    onChange={this.props.handleTextboxChange('realertMinutes')}
                                /> <span className={styles['unit-Name']}>{ALERT_DETAILS.LABEL.ODD_HOURS_MINUTES}</span>
                            </div>
                            <div>
                                {ALERT_DETAILS.LABEL.ODD_HOURS_FREQUENCY}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default OddHoursAlert;
