import * as constants from './constants.driverSettings';
import type { DriverSettingsTypeAction } from './constants.driverSettings';

export const addDriverSettings = (data: Object): DriverSettingsTypeAction => ({
    type: constants.ADD_DRIVER_SETTINGS,
    payload: { ...data },
});

export const addDriverSettingsSuccess = (response: Object): DriverSettingsTypeAction => ({
    type: constants.ADD_DRIVER_SETTINGS_SUCCESS,
    payload: { response },
});

export const addDriverSettingsError = (error: Object): DriverSettingsTypeAction => ({
    type: constants.ADD_DRIVER_SETTINGS_ERROR,
    payload: { error },
});

export const fetchDriverSettings = (data: Object): DriverSettingsTypeAction => ({
    type: constants.FETCH_DRIVER_SETTINGS,
    payload: { ...data },
});

export const fetchDriverSettingsSuccess = (response: Object): DriverSettingsTypeAction => ({
    type: constants.FETCH_DRIVER_SETTINGS_SUCCESS,
    payload: { response },
});

export const fetchDriverSettingsError = (error: Object): DriverSettingsTypeAction => ({
    type: constants.FETCH_DRIVER_SETTINGS_ERROR,
    payload: { error },
});

export const updateDriverSettings = (data: Object): DriverSettingsTypeAction => ({
    type: constants.UPDATE_DRIVER_SETTINGS,
    payload: { ...data },
});

export const updateDriverSettingsSuccess = (response: Object): DriverSettingsTypeAction => ({
    type: constants.UPDATE_DRIVER_SETTINGS_SUCCESS,
    payload: { response },
});

export const updateDriverSettingsError = (error: Object): DriverSettingsTypeAction => ({
    type: constants.UPDATE_DRIVER_SETTINGS_ERROR,
    payload: { error },
});

export const closeDialog = () => ({
    type: constants.CLOSE_DRIVER_SETTINGS_DIALOG,
    payload: {},
});

export const updateLoader = (loading: boolean) => ({
    type: constants.UPDATE_DRIVER_SETTINGS_LOADER,
    payload: { loading },
});
