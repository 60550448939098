/* @flow */

export const FETCH_LANDMARKGROUP: string = 'FETCH_LANDMARKGROUP';
export const FETCH_LANDMARKGROUP_SUCCESS: string = 'FETCH_LANDMARKGROUP_SUCCESS';
export const FETCH_LANDMARKGROUP_ERROR: string = 'FETCH_LANDMARKGROUP_ERROR';
export const SEARCH_LANDMARKGROUP: string = 'SEARCH_LANDMARKGROUP';
export const SEARCH_LANDMARKGROUP_SUCCESS: string = 'SEARCH_LANDMARKGROUP_SUCCESS';
export const SEARCH_LANDMARKGROUP_ERROR: string = 'SEARCH_LANDMARKGROUP_ERROR';
export const UPDATE_LANDMARKGROUP_LOADER: string = 'UPDATE_LANDMARKGROUP_LOADER';
export const PAGE_SIZE: number = 50;
export const filterData = [{
    key: 'Vehicles Present',
    keyType: 'range',
    value: [{
        type: 'TextValidator',
        rangeData: {
            maxLength: 4, min: 0, max: 9999, compare: 'lesser', compareWith: 'fltrRanMax',
        },
        name: 'fltrRanMin',
        label: '[Min]',
    }, {
        type: 'TextValidator',
        rangeData: {
            maxLength: 5, min: 1, max: 10000, compare: 'greater', compareWith: 'fltrRanMin',
        },
        name: 'fltrRanMax',
        label: '[Max]',
    }],
}];

export type LandmarkGroupTypeAction = {
    type: string;
    payload?: Object;
}

