import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styles from '../../Steppers//AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';

type Props = {
    formData:{
        config:{
            alertBraking: Boolean,
            alertAcceleration: Boolean,
        },
    },
    handleCheckboxChange: Function,
};

class HardBrakingAccelerationAlert extends React.Component<Props> {
    state = {};
    render() {
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    <div className={styles['step-rightside']}>
                        <form noValidate autoComplete="off">
                            <div>
                                <FormControlLabel
                                    id="alertBraking"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            id="alertBrakingCB"
                                            name="alertBraking"
                                            value="alertBraking"
                                            checked={
                                                this.props.formData.config.alertBraking === true
                                            }
                                            onChange={
                                                this.props.handleCheckboxChange('alertBraking')
                                            }
                                        />
                                    }
                                    label={ALERT_DETAILS.LABEL.HARD_BREAKING_EVENT}
                                />
                            </div>
                            <div className={styles['div-pad']}>
                                <FormControlLabel
                                    id="alertAcceleration"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            id="alertAccelerationCB"
                                            name="alertAcceleration"
                                            checked={
                                                this.props.formData.config.alertAcceleration ===
                                                true
                                            }
                                            onChange={
                                                this.props
                                                    .handleCheckboxChange('alertAcceleration')
                                            }
                                        />
                                    }
                                    label={ALERT_DETAILS.LABEL.HARD_ACCELERATION_EVENT}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default HardBrakingAccelerationAlert;
