/* @flow */
import React, { Component } from 'react';
import { reduxConnect } from '../../../hoc';
import * as actions from './actions.landmarks';
import MapsWrapper from '../../MapsWrapper';
import styles from './Landmarks.module.scss';
import LandmarkDetails from './LandmarkDetails';
import MiniDrawer from '../../../components/SideMenu/SideMenuDrawer';
import { getParamValue } from '../../../helper-classes/utility-functions';
import AppLoader from '../../../components/AppLoader';
import analytics from '../../../analytics';

export type Props = {
    fetchLandmarks: Function,
    isLoader: boolean,
    landmarks: {
        landmarkDetails: Object
    },
    location: {
        pathname: string,
    },
    history: {
        push: Function,
    },
};

export type State = {
    id: string,
};

class Landmarks extends Component<Props, State> {
    isNearestAsset: boolean;
    zoomLevel: number;
    mapCenter: any;
    state = {
        id: getParamValue(this.props, 'id') || '',
    };
    componentDidUpdate() {
        const paramId = getParamValue(this.props, 'id');
        const { id } = this.state;
        if (paramId !== id) {
            this.updateState(paramId);
        }
    }

    updateState = (id) => {
        this.setState({ id });
    }

    redirectTo = (action) => {
        const redirectURL = action.split('/');
        this.props.history.push(action);
        analytics.track('LANDMARK_DETAIL_TAB_CLICK', { ClickedItem: `${redirectURL[2]} ${redirectURL[1]}`, id: redirectURL[3] });
    }

    refreshData = (zoomLevel, mapCenter) => {
        this.zoomLevel = zoomLevel;
        this.mapCenter = mapCenter;
        this.props.fetchLandmarks(this.state.id, false);
    }

    render() {
        const { landmarks, isLoader } = this.props;
        const { id } = this.state;
        const options = {};
        let coordinates = {};
        if (landmarks && landmarks.landmarkDetails) {
            coordinates = {
                lat: landmarks.landmarkDetails.lat,
                lng: landmarks.landmarkDetails.lng,
            };
        }
        if (this.zoomLevel) {
            options.zoom = this.zoomLevel;
        }
        if (this.mapCenter) {
            coordinates.lat = this.mapCenter.lat();
            coordinates.lng = this.mapCenter.lng();
        }
        return (
            <MiniDrawer redirectTo={this.props.history.push}>
                {isLoader ? <AppLoader type="fullScreen" /> : ''}
                <div key="landmark-map-container" className={styles.mapWrapper}>
                    <MapsWrapper
                        key="landmark-map"
                        coordinates={coordinates}
                        markers={landmarks.landmarkDetails}
                        refreshData={this.refreshData}
                        {...options}
                    />
                </div>
                <div className={styles.container} >
                    <LandmarkDetails
                        key={id}
                        id={id}
                        redirectTo={this.redirectTo}
                        refreshLandmarkPage={() => {}}
                    />
                </div>
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => state.landmarks;

export default reduxConnect(Landmarks, actions, mapStateToProps);
