/* eslint-disable no-dupe-keys */
/* eslint no-underscore-dangle: ["error", { "allow": ["__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__"] }] */
import React from 'react';
import { IconButton, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import AppAutoSuggestion from '../AutoSuggestion/AutoSuggestion';

export type Props = {
    classes: Object,
    rowsPerPage: number,
    count: number,
    onChangePage: Function,
    page: number,
    minValue: number,
    disabled: boolean,
}

export type State = {
    newPageNumber: number,
}

const styles = () => ({
    input: {
        textAlign: 'center',
    },
    container: {
        width: '250px',
        minWidth: '250px',
        flexBasis: 'auto',
    },
    iconButton: {
        margin: '0 4px 0 4px',
    },
});

// $FlowFixMe
class TablePaginationInput extends React.Component<Props, State> {
    totalPage: number;
    autoSuffentionRef: any;
    constructor(props) {
        super(props);
        this.totalPage = Math.ceil(this.props.count / this.props.rowsPerPage);
        this.state = {
            newPageNumber: (this.totalPage === 0) ? 0 : +this.props.page + 1,
        };
        this.autoSuffentionRef = null;
    }

    onChangeHandle = (event) => {
        let currentValue = event.target.value;
        const { minValue } = this.props;
        if (currentValue < minValue) {
            currentValue = minValue;
        }
        if (currentValue > this.totalPage) {
            currentValue = this.totalPage;
        }
        this.setState({ newPageNumber: currentValue });
        return currentValue;
    }

    handleBackButtonClick = (event) => {
        this.props.onChangePage(event, this.props.page - 1);
    }

    handleNextButtonClick = (event) => {
        this.props.onChangePage(event, this.props.page + 1);
    }

    onSuggestionClick = (event, value) => {
        if (!(+value)) return;
        this.setState({ newPageNumber: +value }, () => {
            this.props.onChangePage(event, this.state.newPageNumber - 1);
        });
    }

    getSuggestion = (value) => {
        const suggestion = [];
        for (let i = 1; i <= this.totalPage; i += 1) {
            if (`${i}`.indexOf(value) === 0) {
                suggestion.push({ label: `${i}` });
            }
            if (suggestion.length === 5) {
                break;
            }
        }
        return suggestion;
    }

    onKeyUp = (event) => {
        const suggestionValue = this.autoSuffentionRef.getRenderedSuggestion();
        if ((event.which === 13 || event.keyCode === 13) && suggestionValue.length > 0) {
            this.onSuggestionClick(event, suggestionValue[0].label);
        }
        const currentValue = event.target.value;
        if (currentValue > this.totalPage) {
            this.autoSuffentionRef.updateInputBox(`${this.totalPage}`);
        }
        return currentValue;
    }

    onKeyPress = (event) => {
        const key = event.which ? event.which : event.keyCode;
        if (!((key >= 48 && key <= 57) || key === 8 || key === 13)) {
            event.preventDefault();
        }
    }

    render() {
        const {
            page,
            count,
            rowsPerPage,
            classes,
            disabled,
        } = this.props;
        return (
            <Grid container className={classes.container}>
                <Grid item xs={1} />
                <Grid item xs={2}>
                    <IconButton
                        color="inherit"
                        disabled={page === 0 || disabled}
                        className={classes.iconButton}
                        onClick={this.handleBackButtonClick}
                        size="large"
                    >
                        <KeyboardArrowLeft />
                    </IconButton>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={2} style={{ margin: '6px 0 0' }}>
                    <AppAutoSuggestion
                        value={`${this.state.newPageNumber}`}
                        onSuggestionClick={this.onSuggestionClick}
                        getSuggestion={value => this.getSuggestion(value)}
                        inputProps={{
                            onKeyUp: this.onKeyUp,
                            onKeyPress: this.onKeyPress,
                            customClasses: classes.input,
                        }}
                        suggestionWidth="auto"
                        onRef={(ref) => { this.autoSuffentionRef = ref; }}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={2} style={{ margin: '16px 0 0 16px', fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.54)' }}>
                    <span>of {this.totalPage}</span>
                </Grid>
                <Grid item xs={2}>
                    <IconButton
                        color="inherit"
                        className={classes.iconButton}
                        disabled={(page >= Math.ceil(count / rowsPerPage) - 1) || disabled}
                        onClick={this.handleNextButtonClick}
                        size="large"
                    >
                        <KeyboardArrowRight />
                    </IconButton>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(TablePaginationInput);

