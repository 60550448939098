/* @flow */
import { combineEpics } from 'redux-observable';
import { DISPATCH_FETCH_ASSETS_DETAILS, DISPATCH_SEND_DIRECTION } from './constants.dispatchAction';
import { fetchDispatchAssetsDetailsSuccess, fetchDispatchAssetsDetailsError, sendDirectionSuccess, sendDirectionError } from './actions.dispatchAction';
import config from '../../../constants/Config';
import {
    getGrailsAppBasedURL,
    getAuthorizationHeader,
    getUserToken,
} from '../../../helper-classes/utility-functions';

type ObservarblesTypes = {
    getJSON: Function,
    of: Function,
    postJSON: Function,
};

const foundDataInStore = (storeData, id, of) => {
    let dataFound = false;
    let dataPosition = 0;
    dataFound = storeData.globalSearch.assets.data.some((d, index) => {
        if (d.id === id) {
            dataFound = true;
            dataPosition = index;
        }
        return dataFound;
    });
    return (!dataFound) ? null : of({
        data: [storeData
            .globalSearch.assets.data[dataPosition]],
    });
};

const assetDetails = {
    id: '',
    assetName: '',
    longitude: 0,
    latitude: 0,
    driverId: '',
    driverEmail: '',
    driverPhone: '',
};

export const getAssets =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(DISPATCH_FETCH_ASSETS_DETAILS)
            .mergeMap((action) => {
                const storeData = store.getState();
                const headers = {
                    'X-Nspire-UserToken': storeData.userSession.userToken,
                };
                const { id } = action.payload;
                let assetDataFromStore = (storeData.globalSearch
                && storeData.globalSearch.assets
                && storeData.globalSearch.assets.data
                && storeData.globalSearch.assets.data.length > 0)
                    ? foundDataInStore(store.getState(), id, of) : null;
                if (!assetDataFromStore) {
                    assetDataFromStore = getJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/assets/${id}`, headers);
                }
                return assetDataFromStore.mergeMap((res) => {
                    if (res.data.length > 0) {
                        const assetData = res.data[0];
                        assetDetails.id = assetData.id;
                        assetDetails.assetName = assetData.name || '';
                        assetDetails.latitude = assetData.lastLocation &&
                        assetData.lastLocation.y ? assetData.lastLocation.y : 0;
                        assetDetails.longitude = assetData.lastLocation &&
                        assetData.lastLocation.x ? assetData.lastLocation.x : 0;
                        assetDetails.driverId = assetData.driverId || '0';
                    }
                    return getJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/drivers/${assetDetails.driverId}`, headers);
                }).map((result) => {
                    if (result && result.count > 0) {
                        const driver = result.data[0];
                        assetDetails.driverEmail = driver.email;
                        assetDetails.driverPhone = driver.phoneNumber;
                    } else {
                        assetDetails.driverEmail = '';
                        assetDetails.driverPhone = '';
                    }
                    return fetchDispatchAssetsDetailsSuccess(assetDetails);
                })
                    .catch(error => of(fetchDispatchAssetsDetailsError(error)));
            });

export function getLandmarks(offset: number, limit: number, query: string) {
    const options = {
        headers: {
            'X-Nspire-UserToken': getUserToken() || '',
        },
    };
    let url = `${config.get('FLEET_VIEW_SERVICES_URL')}/landmarks`;
    if (offset !== null && offset !== ' ' && typeof offset === 'number') {
        url = `${url}?start=${offset}&limit=${limit}`;
        if (query !== null && query !== ' ' && query.length >= 3) {
            url = `${url}&search=${query}`;
        }
    }
    return fetch(url, options).then(response => response.json());
}

export const sendDirection =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) => actions$
        .ofType(DISPATCH_SEND_DIRECTION)
        .distinctUntilChanged()
        .debounceTime(config.get('DEBOUNCE_TIME'))
        .mergeMap((action) => {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: getAuthorizationHeader(),
            };
            return postJSON(`${getGrailsAppBasedURL()}/rest/json/emailMessage`, action.payload.requestBody, headers)
                .map((result) => {
                    if (result.response && result.response.success) {
                        return sendDirectionSuccess('Directions Send Successfully');
                    }
                    return sendDirectionError(result.response);
                }).catch(error => of(sendDirectionError(error)));
        });

export default combineEpics(getAssets, sendDirection);
