/* @flow */

import {
    FETCH_LANDMARKGROUP,
    FETCH_LANDMARKGROUP_SUCCESS,
    FETCH_LANDMARKGROUP_ERROR,
    SEARCH_LANDMARKGROUP,
    SEARCH_LANDMARKGROUP_SUCCESS,
    SEARCH_LANDMARKGROUP_ERROR,
    UPDATE_LANDMARKGROUP_LOADER,
} from './constants.landmarkGroup';
import type { LandmarkGroupTypeAction } from './constants.landmarkGroup';

export const fetchLandmarkGroup = (id: string, pageNumber: {}): LandmarkGroupTypeAction => ({
    type: FETCH_LANDMARKGROUP,
    payload: { id, pageNumber },
});

export const fetchLandmarkGroupSuccess = (response: {}): LandmarkGroupTypeAction => ({
    type: FETCH_LANDMARKGROUP_SUCCESS,
    payload: { response },
});

export const fetchLandmarkGroupError = (error: string): LandmarkGroupTypeAction => ({
    type: FETCH_LANDMARKGROUP_ERROR,
    payload: { error },
});

export const updateLoader = (isLoader: boolean): LandmarkGroupTypeAction => ({
    type: UPDATE_LANDMARKGROUP_LOADER,
    payload: { isLoader },
});

export const searchLandmarkGroup =
    (id: string, search: {}, pageNumber: {}, filters: {}): LandmarkGroupTypeAction => ({
        type: SEARCH_LANDMARKGROUP,
        payload: {
            search, pageNumber, id, filters,
        },
    });

export const searchLandmarkGroupSuccess = (response: {}): LandmarkGroupTypeAction => ({
    type: SEARCH_LANDMARKGROUP_SUCCESS,
    payload: { response },
});

export const searchLandmarkGroupError = (error: string): LandmarkGroupTypeAction => ({
    type: SEARCH_LANDMARKGROUP_ERROR,
    payload: { error },
});
