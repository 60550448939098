/* @flow */
import { FETCH_USER_ROLES_LIST_ERROR, FETCH_USER_ROLES_LIST_SUCCESS, FETCH_USER_ROLES_LIST, RESET_USER_ROLES_LIST } from './constants.userRoleList';

const initialState = {
    userRoles: [],
    isUpdating: false,
};

type stateType = {
    userRoles: Array<Object>,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        userRoles:{},
        totalUserRoles: number
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_USER_ROLES_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case FETCH_USER_ROLES_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            userRoles: action.payload.userRoles,
            totalUserRoles: action.payload.totalUserRoles,
        };
    case FETCH_USER_ROLES_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    case RESET_USER_ROLES_LIST:
        return {
            ...state,
            isUpdating: false,
            userRoles: [],
            totalUserRoles: 0,
        };
    default:
        return state;
    }
};
