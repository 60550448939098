/* @flow */
import {
    FETCH_LANDMARK_LIST,
    FETCH_LANDMARK_LIST_SUCCESS,
    FETCH_LANDMARK_LIST_ERROR,
    FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST_ERROR,
    FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST_SUCCESS,
    FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST,
} from './constants.landmarks';

const initialState = {
    landmarkList: [],
    isLoading: false,
    landmarkGroups: [],
    isUpdating: false,
};

type stateType = {
    landmarkList: Array<Object>,
    isLoading: boolean,
    landmarkGroups: Array<Object>,
    isUpdating: boolean,
};

type actionType = {
    type: string,
    payload :{
        landmarkList: {},
        landmarkGroups:[],
        totalLandmarkGroups: number,
        totalLandmarkList: number,
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_LANDMARK_LIST:
        return {
            ...state,
            isLoading: true,
        };
    case FETCH_LANDMARK_LIST_SUCCESS:
        return {
            ...state,
            isLoading: false,
            landmarkList: action.payload.landmarkList,
        };
    case FETCH_LANDMARK_LIST_ERROR:
        return {
            ...state,
        };
    case FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            landmarkGroups: action.payload.landmarkGroups,
        };
    case FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    default:
        return state;
    }
};
