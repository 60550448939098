/* @flow */
import {
    FETCH_NEAREST_ASSET,
    FETCH_NEAREST_LANDMARK,
    FETCH_NEAREST_ASSET_LANDMARK_SUCCESS,
    FETCH_NEAREST_ASSET_LANDMARK_ERROR,
} from './constants.nearestAssetLandmark';

export const initialState = {
    landmarks: {},
    isLoader: false,
};

type stateType = {
    landmarks: {},
    isLoader: false,
}
type actionType = {
    type: string,
    payload :{
        response:{},
        assetDetails: {},
        directions: {},
        directionsDetails: {},
        isLoader: boolean,
        showLoader: boolean,
        landmarks: {},
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_NEAREST_ASSET:
    case FETCH_NEAREST_LANDMARK:
        return {
            ...state,
            isLoader: true,
            assetDetails: {},
            directions: {},
            directionsDetails: {},
            landmarks: {},
        };
    case FETCH_NEAREST_ASSET_LANDMARK_SUCCESS:
        return {
            ...state,
            landmarks: action.payload.landmarks,
            assetDetails: action.payload.assetDetails,
            directions: action.payload.directions,
            directionsDetails: action.payload.directionsDetails,
            isLoader: false,
        };
    case FETCH_NEAREST_ASSET_LANDMARK_ERROR:
        return {
            ...state,
            isLoader: false,
            directionsDetails: {},
            assetDetails: {},
            landmarks: {},
            directions: {},
        };
    default:
        return state;
    }
};
