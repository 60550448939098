/* @flow */
import { combineEpics } from 'redux-observable';
import { GRAILS_FETCH_LANDMARKS, GRAILS_POST_LANDMARK_GROUP, GRAILS_FETCH_SELECTED_LANDMARKS, FETCH_LANDMARK_GROUP_DETAILS_GLOBALID, DELETE_LANDMARK_GROUP_GLOBALID, LANDMARK_AND_SELECTED_LANDMARK } from './constants.landmarkGroupManage';
import * as actions from './actions.landmarkGroupManage';
import { getAuthorizationHeader, getGrailsAppBasedURL, getUserToken } from '../../../helper-classes/utility-functions';
import config from '../../../constants/Config';

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    of: Function,
    deleteJSON: Function,
}

const landmarkData = {
    header: {},
    action: {},
    selectedLandmarks: {},
    landmarks: {},
};

function getLandmarksListUrl(action) {
    const {
        queryConfig,
        offset,
        limit,
    } = action.payload;
    const randVal = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}/operation/json/landmarkLocationRestService/get`;
    url = `${url}?_dc=${randVal}&offset=${offset}&max=${limit}&assetsOnly=true&doCount=true`;
    const urlConfig = {
        assetSearchText: null,
        landmarkSearchText: queryConfig.searchText || null,
        assetSearchTags: null,
        landmarkSearchTags: null,
        notInAssetGroup: false,
        notInLandmarkGroup: queryConfig.notInLandmarkGroup,
        selectedAssetFilters: [],
        selectedLandmarkFilters: queryConfig.selectedLandmarkFilters,
        selectedAssetGroups: [],
        selectedLandmarkGroups: queryConfig.selectedLandmarkGroups || [],
        selectedReportFilters: [],
        selectedAlertFilters: [],
        selectedMaintenanceFilters: [],
        queryFields: [
            'name',
            'serialNumber',
            'assetGroupName',
            'assetName',
            'assetMake',
            'assetVin',
            'assetYear',
            'assetModel',
            'assetTypeName',
            'assetDescription',
            'address',
            'city',
            'state',
            'zip',
            'landmarkName',
            'stockNumber',
            'assetTags',
        ],
    };
    return `${url}&config=${encodeURIComponent(JSON.stringify(urlConfig))}
        &sorts=${encodeURIComponent(JSON.stringify(queryConfig.sort))}`;
}

export const fetchLandmarks =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(GRAILS_FETCH_LANDMARKS)
            .debounceTime(1000)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };
                const { queryConfig, offset, limit } = action.payload;
                const body = {
                    ...queryConfig,
                    limit,
                    start: offset,
                };
                return postJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/landmarks`, body, headers);
            })
            .map((result) => {
                const formattedResult = result.response;
                return actions.fetchLandmarksSuccess(formattedResult);
            })
            .catch(error => of(actions.fetchLandmarksError(error)));

export const fetchSelectedLandmarks =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(GRAILS_FETCH_SELECTED_LANDMARKS)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };
                const body = {
                    ...action.payload.queryConfig,
                };
                return postJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/landmarks`, body, headers);
            })
            .map((result) => {
                const formattedResult = result.response;
                return actions.fetchSelectedLandmarksSuccess(formattedResult);
            })
            .catch(error => of(actions.fetchLandmarksError(error)));

export const postLandmarkGroup =
    (actions$: Function, store: Object, { getJSON, postJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(GRAILS_POST_LANDMARK_GROUP)
            .distinctUntilChanged()
            .debounceTime(config.get('DEBOUNCE_TIME'))
            .mergeMap((action) => {
                const grailsHeaders = {
                    'Content-Type': 'application/json',
                    Authorization: getAuthorizationHeader(),
                }
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };
                const {
                    requestBody,
                    groupName,
                    isNewGroupName,
                    update,
                    secondCallData,
                } = action.payload;
                const url = `${getGrailsAppBasedURL()}/tree/updateLandmarks.json?_dc=${new Date().getTime()}`;
                const lgUrl = `${config.get('FLEET_VIEW_SERVICES_URL')}/landmarkgroups?search=${encodeURIComponent(groupName)}&type=exactMatchSearch`;
                return getJSON(lgUrl, headers).mergeMap((result) => {
                    if ((isNewGroupName && result.count > 0) ||
                        (!isNewGroupName && result.count > 1)) {
                        return of(actions.fetchLandmarksError('Landmark Group name must be unique.'));
                    }
                    return postJSON(url, requestBody, grailsHeaders)
                        .map((response) => {
                            if (update) {
                                return actions
                                    .updateLandmarkGroupSuccess(response.response, {
                                        secondCallData,
                                        isNewGroupName,
                                        groupName,
                                    });
                            }
                            return actions.postLandmarkGroupSuccess(response.response);
                        })
                        .catch(error => of(actions.fetchLandmarksError(error)));
                }).catch(error => of(actions.fetchLandmarksError(error)));
            });

export const fetchLandmarkGroupDetailFromGlobalid =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_LANDMARK_GROUP_DETAILS_GLOBALID)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };
                const { globalId } = action.payload;
                const url = `${config.get('FLEET_VIEW_SERVICES_URL')}/landmarkgroups/${globalId}`;
                return getJSON(url, headers);
            })
            .map((result) => {
                const formattedResult = result;
                return actions.fetchLandmarkGroupDetailFromGlobalidSuccess(formattedResult);
            })
            .catch(error => of(actions.fetchLandmarksError(error)));
export const deleteLandmarkGroupGlobalId =
    (actions$: Function, store: Object, { deleteJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(DELETE_LANDMARK_GROUP_GLOBALID)
            .distinctUntilChanged()
            .mergeMap((action) => {
                const { globalId } = action.payload;
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };
                const url = `${config.get('PLATFORM_API_BASE_URL')}/geozoneGroups/${globalId}`;
                return deleteJSON(url, headers)
                    .map((response) => {
                        const deleteResponse = {
                            success: false,
                            msg: null,
                        };
                        if (response && (response.status >= 200 && response.status <= 299)) {
                            deleteResponse.success = true;
                            deleteResponse.msg = null;
                        }
                        return actions.deleteLandmarkGroupGlobalIdSuccess(deleteResponse);
                    })
                    .catch(error => of(actions.fetchLandmarksError(error)));
            });

export const fetchLandmarkAndSelectedLandmark =
            (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
                actions$
                    .ofType(LANDMARK_AND_SELECTED_LANDMARK)
                    .mergeMap((action) => {
                        const headers = {
                            Authorization: getAuthorizationHeader(),
                            'Content-Type': 'application/json',
                        };
                        landmarkData.action = action;
                        landmarkData.header = headers;
                        const {
                            limit,
                            filter,
                        } = action.payload;
                        const url = getLandmarksListUrl({
                            payload: {
                                queryConfig: filter,
                                offset: 0,
                                limit,
                            },
                        });
                        return getJSON(url, headers);
                    })
                    .mergeMap((result) => {
                        landmarkData.selectedLandmarks = result;
                        const {
                            landmarkFilter,
                            landmarkLimit,
                        } = landmarkData.action.payload;
                        const url = getLandmarksListUrl({
                            payload: {
                                queryConfig: landmarkFilter,
                                offset: 0,
                                limit: landmarkLimit,
                            },
                        });
                        return getJSON(url, landmarkData.header);
                        // return actions.fetchSelectedAssetsSuccess(formattedResult);
                    })
                    .map((result) => {
                        landmarkData.landmarks = result;
                        return actions.fetchLandmarkAndSelectedLandmarkSuccess(landmarkData);
                    })
                    .catch(error => of(actions.fetchLandmarkAndSelectedLandmarkError(error)));

export default combineEpics(
    fetchLandmarks,
    postLandmarkGroup,
    fetchSelectedLandmarks,
    fetchLandmarkGroupDetailFromGlobalid,
    deleteLandmarkGroupGlobalId,
    fetchLandmarkAndSelectedLandmark,
);
