/* @flow */

import React from 'react';
import { TableHead, TableCell, TableRow, TableSortLabel, Checkbox, Tooltip, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Resizable from 're-resizable';
import { arrayMove } from '../../../helper-classes/utility-functions';
import customStyles from './ListComponent.module.scss';

export type Props = {
    classes: any,
    expandRow: Function,
    enableColumnRearrange: boolean,
    iconStyle: any,
    isExpanded: boolean,
    numSelected: number,
    onSelectAllClick: Function,
    onRequestSort: Function,
    rearrangeColumns: Function,
    order: any,
    orderBy: any,
    rowCount: number,
    tableData: {
        listData: Array<{
            id: number,
            keyName: string,
            isLink: boolean,
            path: string,
        }>,
        listHeaders: Array<{
            id: number,
            disableSort: boolean,
            keyName: string,
            isLink: boolean,
            path: string,
            show: boolean,
            displayUnit: string,
        }>,
        ischeckBox: boolean,
        isDeletable:boolean,
        isEditable: boolean,
        isAddButton: boolean,
        isGroup: boolean,
        totalRecords: number,
    },
    resizableHeader: boolean,
    rowActions: Object,
    userPermission: string,
};
export type State = {
    items: any,
};

const styles = () => ({
    checkbox: {
        color: '#4a4a4a',
        opacity: '0.54',
    },
    checked: {
        color: '#007aff !important',
        opacity: 1,
    },
    icon: {
        fill: '#fff !Important',
        backgroundColor: '#4a4a4a',
        borderRadius: '50%',
        opacity: '0.54',
        height: '18px !Important',
        width: '18px !Important',
    },
    dragHelper: {
        boxShadow: 'rgba(224, 224, 224, 1) 0px 4px 3px -2px',
        borderBottom: 'none',
        '&> div': {
            background: '#ccc',
            borderRadius: '3%',
            margin: '10px 0 0 0',
            paddingLeft: '10px',
        },
    },
    overrideClass: {
        '& *': {
            cursor: 'grabbing !important',
        },
    },
    root: {
        '&:hover': {
            color: '#000',
        },
    },
});

class TableHeaderComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            items: props.tableData.listHeaders,
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        this.rows = nextProps.tableData.listHeaders;
        this.setState({
            items: this.rows,
        });
    }

    rows: any = [];

    createSortHandler = (property: any) => (event: any) => {
        this.props.onRequestSort(event, property);
    };

    onSortEnd = ({ oldIndex, newIndex }, e) => {
        if (e.target.closest('thead', this.props.classes.overrideClass)) {
            this.removeTableHeadClass(e.target.closest('thead'));
        }
        this.setState(({ items }) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }));

        this.props.rearrangeColumns(this.state.items);
    };

    resizeEnd = (index, width) => {
        if (typeof this.props.rearrangeColumns === 'function') {
            const newItem = this.state.items;
            newItem[index].width = width;
            this.props.rearrangeColumns(newItem);
        }
    }

    onSortMove = (e) => {
        if (e.target.closest('thead')) {
            this.addTableHeadClass(e.target.closest('thead'), this.props.classes.overrideClass);
        }
    }

    getIcon = (id, isExpanded) => {
        let icon =
            (
                <Tooltip title="Expand">
                    <IconButton
                        aria-label="Expand"
                        style={{ ...this.props.iconStyle, marginRight: 8 }}
                        onClick={() => this.props.expandRow(id, true)}
                        size="large"
                    >
                        <ChevronRight
                            className={this.props.classes.icon}
                        />
                    </IconButton>
                </Tooltip>
            );
        if (isExpanded) {
            icon =
            (
                <Tooltip title="Collapse" >
                    <IconButton
                        aria-label="Collapse"
                        style={{ ...this.props.iconStyle, marginRight: 8 }}
                        onClick={() => this.props.expandRow(id, false)}
                        size="large"
                    >
                        <ExpandMore
                            className={this.props.classes.icon}
                        />
                    </IconButton>
                </Tooltip>
            );
        }
        return icon;
    }

    addTableHeadClass = (thead, classToAdd) => {
        const theadTemp = thead;
        if (theadTemp && theadTemp.className.indexOf(classToAdd) === -1) {
            theadTemp.className += ` ${classToAdd}`;
        }
    }

    removeTableHeadClass = (thead, classToremove) => {
        const theadTemp = thead;
        if (theadTemp && theadTemp.className.indexOf(classToremove) > -1) {
            theadTemp.className = theadTemp.className.replace(classToremove, '');
        }
    }

    render() {
        const {
            classes,
            enableColumnRearrange,
            isExpanded,
            numSelected,
            onSelectAllClick,
            order,
            orderBy,
            rowCount,
            userPermission,
        } = this.props;
        const {
            isGroup, ischeckBox, isEditable, isAddButton, isDeletable,
        } = this.props.tableData;
        let { resizableHeader } = this.props;

        this.rows = this.props.tableData.listHeaders;
        if (!resizableHeader) {
            resizableHeader = false;
        }

        const SortableItem = SortableElement(({ value, index }) => {
            const row = value;
            return (
                <TableCell
                    key={Math.random()}
                    align="left"
                    style={{
                        textTransform: 'capitalize',
                        width: `${(index === this.rows.length - 1) ? 'auto' : `${(95 / (this.rows.length + 1))}%`}`,
                        cursor: enableColumnRearrange ? 'grab' : 'default',
                    }}
                    padding="normal"
                    id={`header${index}`}
                    sortDirection={
                        (Object.keys(row).map(key => row[key])
                            .filter(v => v === orderBy).length > 0) ?
                            order :
                            false
                    }
                >
                    <Resizable
                        defaultSize={{ width: row.width ? row.width : 'auto', height: 'auto' }}
                        enable={{
                            top: false,
                            right: resizableHeader,
                            bottom: false,
                            left: false,
                            topRight: false,
                            bottomRight: false,
                            bottomLeft: false,
                            topLeft: false,
                        }}
                        className={
                            resizableHeader ? customStyles['resizable-column'] : ''
                        }
                        onResizeStop={(e, dir, ref) => {
                            this.resizeEnd(index, ref.style.width);
                        }}
                    >
                        {(index === 0 && isGroup) && this.getIcon('all', isExpanded)}
                        {this.props.onRequestSort && !row.disableSort ?
                            <TableSortLabel
                                active={(Object.keys(row)[0] === orderBy)}
                                direction={order}
                                onClick={
                                    this.createSortHandler(Object.keys(row)[0])
                                }
                                classes={{
                                    root: classes.root,
                                }}
                            >
                                {row[Object.keys(row)[0]]}
                                {row.displayUnit && <span style={{ textTransform: 'none' }}>&nbsp;({row.displayUnit})</span>}
                            </TableSortLabel>
                            :
                            <span>
                                {row[Object.keys(row)[0]]}
                                {row.displayUnit && <span style={{ textTransform: 'none' }}>&nbsp;({row.displayUnit})</span>}
                            </span>
                        }
                    </Resizable>
                </TableCell>
            );
        });
        const SortableList = SortableContainer(({ items }) => (
            <TableHead id="table-header">
                <TableRow id="table-header-row">
                    {
                        ischeckBox && userPermission === 'Modify' &&
                        <TableCell padding="checkbox" className={customStyles.checkboxWrapper}>
                            {!isGroup &&
                                <Checkbox
                                    indeterminate={numSelected > 0 && numSelected < rowCount}
                                    checked={(numSelected === rowCount && numSelected > 0)}
                                    onChange={onSelectAllClick}
                                    color="primary"
                                    classes={{
                                        checked: classes.checked,
                                        root: numSelected > 0 && numSelected < rowCount
                                            ? classes.checked : classes.checkbox,
                                    }}
                                />
                            }
                        </TableCell>
                    }
                    {
                        userPermission === 'Modify' && (isEditable || isAddButton || isDeletable) &&
                        <TableCell padding="checkbox">
                            <span style={{ paddingLeft: 10 }}>
                                Actions
                            </span>
                        </TableCell>
                    }
                    {items.map((row, index) => (
                        row.show &&
                        <SortableItem
                            key={Math.random()}
                            index={index}
                            value={row}
                            disabled={!enableColumnRearrange || !this.props.rearrangeColumns || typeof this.props.rearrangeColumns !== 'function'}
                        />
                    ), this)}
                    {this.props.rowActions && userPermission === 'Modify' &&
                        <TableCell>
                            Action
                        </TableCell>
                    }
                </TableRow>
            </TableHead>
        ));
        return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} lockAxis="x" axis="x" lockToContainerEdges useDragHandle={false} distance={10} hideSortableGhost={false} shouldCancelStart={e => !e.target.hasChildNodes()} helperClass={classes.dragHelper} onSortMove={this.onSortMove} />;
    }
}

export default withStyles(styles)(TableHeaderComponent);
