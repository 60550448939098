import React from 'react';
import customstyles from './AlertLandingPage.module.scss';

type Props = {
    dataitemid: {
        imageLocation: String,
        description: String,
        idPostfix: String,
    },
};

const ImageWrapper = (props:Props) => (
    <div className={customstyles['overlay-image']}>
        {/* Display the image here */}
        <img id={'img'.concat(props.dataitemid.idPostfix)} src={props.dataitemid.imageLocation} className={customstyles.image} alt="" />
        <div className={customstyles.hover}>
            <div id={'hvr'.concat(props.dataitemid.idPostfix)} className={customstyles.text}>
                {/* Display the description here */}
                {props.dataitemid.description}
            </div>
        </div>
    </div>
);

export default ImageWrapper;
