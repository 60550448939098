/* @flow */
import React from 'react';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { downloadFile } from './../../../helper-classes/utility-functions';
import AssetImportFormComponent from './AssetImportFormComponent';
import LandmarkAddressImportFormComponent from './LandmarkAddressImportFormComponent';
import LandmarkLatLngImportFormComponent from './LandmarkLatLngImportFormComponent';
import LandmarkPolygonImportFormComponent from './LandmarkPolygonImportFormComponent';
import UserImportFormComponent from './UserImportFormComponent';
import DriverImportFormComponent from './DriverImportFormComponent';
import config from './../../../constants/Config';

export type Props = {
    classes: Object,
    importType: string,
    updateLoader: Function,
};

const styles = () => ({
    root: { margin: 15, marginLeft: 10, zIndex: 1 },
    top: {
        fontSize: '12px',
    },
    header: {
        margin: 0,
        fontSize: '20px',
    },
});

class ImportFormComponent extends React.PureComponent<Props> {
    static defaultProps = {
        importType: 'Assets',
    };

    downloadTemplate = (type, fileName) => {
        this.props.updateLoader(true);
        downloadFile(
            `${config.get('FLEET_IMPORT_SERVICES_URL')}/importtemplates/${type}`,
            fileName,
            { type: 'csv' },
            () => this.props.updateLoader(false),
        );
    }

    getImportForm = (type) => {
        let importForm = null;
        switch (type) {
        case 'Assets': importForm = <AssetImportFormComponent downloadTemplate={this.downloadTemplate} />;
            break;
        case 'Address': importForm = <LandmarkAddressImportFormComponent downloadTemplate={this.downloadTemplate} />;
            break;
        case 'LatLng': importForm = <LandmarkLatLngImportFormComponent downloadTemplate={this.downloadTemplate} />;
            break;
        case 'Polygon': importForm = <LandmarkPolygonImportFormComponent downloadTemplate={this.downloadTemplate} />;
            break;
        case 'Drivers': importForm = <DriverImportFormComponent downloadTemplate={this.downloadTemplate} />;
            break;
        case 'Users': importForm = <UserImportFormComponent downloadTemplate={this.downloadTemplate} />;
            break;
        default: break;
        }
        return importForm;
    }

    render() {
        const { classes, importType } = this.props;
        return (
            <Grid container className={classes.top}>
                <Grid item xs={12}>
                    {this.getImportForm(importType)}
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ImportFormComponent);
