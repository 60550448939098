/* @flow */
import React from 'react';
import { Grid, Button } from '@mui/material';
import customStyles from './ImportComponent.module.scss';
import { LANDMARK_ADDRESS, LANDMARK_ADDRESS_TEMPLATE_NAME } from './constants.import';

export type Props = {
    downloadTemplate: Function,
};

class LandmarkAddressImportFormComponent extends React.PureComponent<Props> {
    render() {
        return (
            <Grid container justifyContent="flex-start" spacing={1} style={{ marginBottom: 150 }}>
                <Grid item xs={12} style={{ margin: '18px 0' }}>
                    <h2 className={customStyles.header}>Address</h2>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={() => this.props.downloadTemplate(LANDMARK_ADDRESS, LANDMARK_ADDRESS_TEMPLATE_NAME)}>Download Template</Button>
                </Grid>
                <Grid item xs={12} style={{ margin: '18px 0' }}>
                    <div>
                        <p>Step 1: Download template</p>
                        <p>Step 2: Check the below requirements. Headings must follow order
                            in template. Commas are not allowed in the data.
                            Column headers are mandatory and may not be moved.
                            If you wish to delete a field value from landmark,
                            enter the value DELETE and it will be deleted.
                            If the value is left empty, no change will occur.
                        </p>
                        <p>Step 3: Add rows of data and save csv</p>
                        <p>Step 4: Select csv file and upload</p>
                    </div>
                    <div className={customStyles.highlight}>
                        <div className={customStyles.subHeader}>
                            Importing an Address .CSV
                        </div>
                        Import files must have
                        the same header name rows as the import template shows.
                    </div>
                    <div>
                        <ul>
                            <li>
                                Column A
                                &nbsp;: Landmark Name - Required
                            </li>
                            <li>
                                Column B
                                &nbsp;: Landmark Group - Optional
                            </li>
                            <li>
                                Column C
                                &nbsp;: Comments (up to 50 characters) - Optional
                            </li>
                            <li>
                                Column D&nbsp;&nbsp;: Phone Number
                                (enter without commas, enter as 10 digit value) - Optional
                            </li>
                            <li>
                                Column E&nbsp;&nbsp;: Fill color Color in HEX - optional
                                (user can provide color to ensure LM is automatically colored
                                on import)
                            </li>
                            <li>
                                Column F&nbsp;&nbsp;: Line color Color in HEX - optional
                                (user can provide color to ensure LM is automatically colored
                                on import)
                            </li>
                            <li>
                                Column G&nbsp;&nbsp;: Transparency as % for fill color
                                - optional (user can provide transparency in percentage number to
                                ensure LM is automatically given transparency for fill color
                                on import)
                            </li>
                            <li>
                                Column H&nbsp;&nbsp;: Landmark Icon
                                (if not provided, will default to Diamond White) - Optional
                            </li>
                            <li>
                                Column I
                                &nbsp;: Import Type (SETBYADDRESS required for each row) - Required
                            </li>
                            <li>
                                Column J&nbsp;&nbsp;: Street Address - Required
                            </li>
                            <li>
                                Column K&nbsp;&nbsp;&nbsp;&nbsp;: City - Required
                            </li>
                            <li>
                                Column L&nbsp;&nbsp;: State (in abbreviation format) - Required
                            </li>
                            <li>
                                Column M&nbsp;&nbsp;: Zip Code (enter without commas) - Optional
                            </li>
                            <li>
                                Column N&nbsp;&nbsp;: Radius in meters
                                (enter without commas, enter up to a 5 digit value) - Required
                            </li>
                        </ul>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default LandmarkAddressImportFormComponent;
