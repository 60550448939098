/* @flow */
import React, { Component } from 'react';
import { reduxConnect } from '../../../hoc';
import AppLoader from '../../../components/AppLoader';
import LookCategory from '../../../components/Dashboard/LookLandingPage/LookCategory';
import { mapLookTypeToCategory } from '../../../util/mapLookTypes';

export type Props = {
    isLoading: boolean,
    backAction: Function,
};
export type State = {};

class LookLandingPageList extends Component<Props, State> {
    componentDidMount() {
    }
    filterLookCategories = () => {
        const LookTypeList = mapLookTypeToCategory({
            look1: 'localFleetHardBrakingLook',
            look2: 'localFleetHardAccelerationLook',
            look3: 'localFleetIdlingLook',
            look4: 'localFleetDistanceTraveledLook',
            look5: 'localFleetSpeedThresholdLook',
            look6: 'localFleetPostedSpeedLook',
        });
        // eslint-disable-next-line
        return LookTypeList.map(el => <LookCategory backAction={this.props.backAction} data={el} key={el.lookCategory} />);
    };
    render() {
        return this.props.isLoading === undefined ? (
            <div style={{ padding: '15px' }}>{this.filterLookCategories()}</div>
        ) : (
            <AppLoader
                loaderStyle={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    'text-align': 'center',
                }}
            />
        );
    }
}

export default reduxConnect(LookLandingPageList);
