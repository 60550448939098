import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import styles from '../../Steppers//AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';

const styless = () => ({
    column: {
        float: 'left',
        width: 33.33,
        padding: 10,
        height: 300,
    },
    row: {
        content: '',
        display: 'table',
        clear: 'both',
    },
});

type Props = {
    formData: {
        config: {
            input1Active: Boolean,
            input1Inactive: Boolean,
            input2Active: Boolean,
            input2Inactive: Boolean,
            input3Active: Boolean,
            input3Inactive: Boolean,
            input4Active: Boolean,
            input4Inactive: Boolean,
        },
    },
    handleCheckboxChange: Function,
};

class InputAlert extends React.Component<Props> {
    state = {};

    render() {
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    <div className={styles['step-rightside']}>
                        <form noValidate autoComplete="off">
                            <div className={styless.row}>{ALERT_DETAILS.LABEL.INPUT_EVENT}:</div>
                            <Grid container spacing={3} className={styles['div-pad']}>
                                <Grid item xs={3}>
                                    <div className={styles['inn-pad']} />
                                    <div className={styles['in-pad']}>{ALERT_DETAILS.LABEL.INPUT_1}</div>
                                    <div className={styles['in-pad']}>{ALERT_DETAILS.LABEL.INPUT_2}</div>
                                    <div className={styles['in-pad']}>{ALERT_DETAILS.LABEL.INPUT_3}</div>
                                    <div className={styles['in-pad']}>{ALERT_DETAILS.LABEL.INPUT_4}</div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div>{ALERT_DETAILS.LABEL.INPUT_ACTIVE}</div>
                                    <Checkbox
                                        onChange={this.props.handleCheckboxChange('input1Active')}
                                        color="primary"
                                        id="input1Active"
                                        name="input1Active"
                                        value="input1Active"
                                        checked={
                                            this.props.formData.config.input1Active === true
                                        }
                                    />
                                    <br />

                                    <Checkbox
                                        onChange={this.props.handleCheckboxChange('input2Active')}
                                        color="primary"
                                        id="input2Active"
                                        name="input2Active"
                                        value="input2Active"
                                        checked={
                                            this.props.formData.config.input2Active === true
                                        }
                                    />

                                    <br />

                                    <Checkbox
                                        color="primary"
                                        id="input3Active"
                                        name="input3Active"
                                        value="input3Active"
                                        checked={
                                            this.props.formData.config.input3Active === true
                                        }
                                        onChange={this.props.handleCheckboxChange('input3Active')}
                                    />

                                    <br />

                                    <Checkbox
                                        color="primary"
                                        id="input4Active"
                                        name="input4Active"
                                        value="input4Active"
                                        checked={
                                            this.props.formData.config.input4Active === true
                                        }
                                        onChange={this.props.handleCheckboxChange('input4Active')}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <div>{ALERT_DETAILS.LABEL.INPUT_INACTIVE}</div>

                                    <Checkbox
                                        color="primary"
                                        id="input1Inactive"
                                        name="input1Inactive"
                                        value="input1Inactive"
                                        checked={
                                            this.props.formData.config.input1Inactive === true
                                        }
                                        onChange={this.props.handleCheckboxChange('input1Inactive')}
                                    />

                                    <br />

                                    <Checkbox
                                        color="primary"
                                        id="input2Inactive"
                                        name="input2Inactive"
                                        value="input2Inactive"
                                        checked={
                                            this.props.formData.config.input2Inactive === true
                                        }
                                        onChange={this.props.handleCheckboxChange('input2Inactive')}
                                    />

                                    <br />

                                    <Checkbox
                                        color="primary"
                                        id="input3Inactive"
                                        name="input3Inactive"
                                        value="input3Inactive"
                                        checked={
                                            this.props.formData.config.input3Inactive === true
                                        }
                                        onChange={this.props.handleCheckboxChange('input3Inactive')}
                                    />

                                    <br />

                                    <Checkbox
                                        color="primary"
                                        id="input4Inactive"
                                        name="input4Inactive"
                                        value="input4Inactive"
                                        checked={
                                            this.props.formData.config.input4Inactive === true
                                        }
                                        onChange={this.props.handleCheckboxChange('input4Inactive')}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default InputAlert;
