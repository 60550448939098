/* @flow */
import { ASSET_SEARCH, ASSET_SEARCH_ERROR, ASSET_SEARCH_SUCCESS } from './constants.assetSearch';

export const defaultValues = {
    data: [],
    loader: false,
    count: 0,
    total: 0,
};
export const initialState = {
    assets: {
        ...defaultValues,
    },
    query: '',
};


type stateType = {
    assets: {
        data: [],
        loader: boolean,
        count: number,
        total: number,
    },
    query: string,
};

type actionType = {
    type: string,
    payload :{
        data: [],
        count: number,
        total: number,
        query: string,
    },
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case ASSET_SEARCH: {
        let newdata;
        let newcount;
        let newtotal;
        if (!state.query.includes(action.payload.query) &&
            !action.payload.query.includes(state.query)) {
            newdata = [];
            newcount = 0;
            newtotal = 0;
        }
        return {
            ...state,
            query: action.payload.query,
            data: newdata,
            count: newcount,
            total: newtotal,
        };
    }
    case ASSET_SEARCH_SUCCESS: {
        let newdata;
        let newcount;
        let newtotal;
        if (state.query === action.payload.query) {
            newdata = action.payload.data;
            newcount = action.payload.count;
            newtotal = action.payload.total;
        }
        return {
            ...state,
            data: newdata,
            count: newcount,
            total: newtotal,
        };
    }
    case ASSET_SEARCH_ERROR: {
        const assets = {
            ...state.assets,
            ...defaultValues,
        };
        return {
            ...state,
            assets,
        };
    }
    default:
        return state;
    }
};
