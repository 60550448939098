/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_SAVED_ALERTS, DELETE_SAVED_ALERTS } from './constants.savedAlerts';
import {
    fetchSavedAlertsError,
    fetchSavedAlertsSuccess,
    deleteSavedAlertSuccess,
    deleteSavedAlertError,
} from './actions.savedAlerts';
import {
    convertDateTime,
    getTimeFormatAsperUserSettting,
    hasFeature,
    getGrailsAppBasedURL,
    getAuthorizationHeader,
} from '../../../helper-classes/utility-functions';

type ObservablesTypes = {
    getJSON: Function,
    deleteJSON: Function,
    of: Function,
};

const formatData = (data: any) => {
    if (!data && data.length <= 0) return [];

    const timeFormat = getTimeFormatAsperUserSettting() === '24h' ? 'MM/DD/YYYY H:mm' : 'MM/DD/YYYY h:mm A';
    return data.map((d) => {
        const savedAlerts = Object.assign({}, d);
        if (d.dateCreated) {
            savedAlerts.dateCreated = convertDateTime(d.dateCreated, timeFormat);
        }
        if (d.recipients) {
            savedAlerts.recipients = d.recipients.join(', ');
        }
        return savedAlerts;
    });
};

export const deleteSavedAlertAPI = (
    actions$: Function,
    store: Object,
    { deleteJSON, of }: ObservablesTypes,
) =>
    actions$.ofType(DELETE_SAVED_ALERTS)
        .mergeMap((action) => {
            const headers = {
                Authorization: getAuthorizationHeader(),
                'Content-Type': 'application/x-www-form-urlencoded',
            };

            return deleteJSON(
                `${getGrailsAppBasedURL()}/rest/json/alertSpec/${action.payload.id}`,
                headers,
            )
                .map((result) => {
                    if (result.response && result.response.success) {
                        return deleteSavedAlertSuccess(result.response);
                    }
                    return deleteSavedAlertError(result.response);
                })
                .catch(error => of(deleteSavedAlertError(error)));
        });

export const getSavedAlerts = (
    actions$: Function,
    store: Object,
    { getJSON, of }: ObservablesTypes,
) =>
    actions$.ofType(FETCH_SAVED_ALERTS).mergeMap((action) => {
        const {
            pageNumber, pageSize, order, orderBy, savedAlertsFiltersToSend, editAlert,
        } = action.payload;

        const sort = `[{"property":"${orderBy}","direction":"${order.toUpperCase()}"}]`;
        const sortEncoded = encodeURIComponent(sort);
        const filters = savedAlertsFiltersToSend;
        let params = 'doCount= true';
        params += `&page=${pageNumber}&offset=${pageNumber * pageSize}&max=${pageSize}`;
        params += `&sorts=${sortEncoded}`;
        params += `&filters=${filters}`;

        const headers = {
            Authorization: getAuthorizationHeader(),
        };

        return getJSON(
            `${getGrailsAppBasedURL()}/rest/json/alertSpec?${params}`,
            headers,
        )
            .map((result) => {
                let alertData = result.data;
                if (!hasFeature('fleetMaintenanceDtc')) {
                    alertData = result.data.filter(alert => alert.alertTypeId !== 199);
                }
                const formatedData = editAlert ? alertData : formatData(alertData);
                return fetchSavedAlertsSuccess(formatedData, result.total);
            })
            .catch(error => of(fetchSavedAlertsError(error)));
    });

export default combineEpics(getSavedAlerts, deleteSavedAlertAPI);
