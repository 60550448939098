/* @flow */

import { FETCH_SCOREVEHICLE_LIST, FETCH_SCOREVEHICLE_LIST_SUCCESS, FETCH_SCOREVEHICLE_LIST_ERROR, RESET_SCOREVEHICLE_LIST } from './constants.scoreVehicleList';
import type { ScoreVehicleListTypeAction } from './constants.scoreVehicleList';

export const fetchVehicleCardsList = (
    id: string,
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filter: string,
):
    ScoreVehicleListTypeAction => ({
    type: FETCH_SCOREVEHICLE_LIST,
    payload: {
        id,
        pageNumber,
        pageSize,
        order,
        orderBy,
        filter,
    },
});
export const fetchScoreVehicleListSuccess =
    (scorevehicle: any): ScoreVehicleListTypeAction => ({
        type: FETCH_SCOREVEHICLE_LIST_SUCCESS,
        payload: { response: scorevehicle },
    });

export const fetchScoreVehicleListError = (error: string): ScoreVehicleListTypeAction => ({
    type: FETCH_SCOREVEHICLE_LIST_ERROR,
    payload: { error },
});

export const resetTable =
    (): ScoreVehicleListTypeAction => ({
        type: RESET_SCOREVEHICLE_LIST,
    });
