import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Input } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import styles from '../../Steppers//AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';

const minVoltage = 9;
const maxVoltage = 26;
const step = 0.1;

type Props = {
    formData: {
        config: {
            alertIntLowBattery: Boolean,
        },
    },
    handleCheckboxChange: Function,
    handleTextboxChange: Function,
};

type State = {
    extVoltThreshold: number,
}

class LowAssetBatteryAlert extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            extVoltThreshold: this.props.formData.config.extVoltThreshold,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.formData.config.extVoltThreshold !== this.state.extVoltThreshold) {
            this.updateExternalVoltage(nextProps.extVoltThreshold, false);
        }
    }

    updateExternalVoltage = (value, doUpdate) => {
        if (value >= minVoltage && value <= maxVoltage) {
            this.setState({ extVoltThreshold: value });

            if (doUpdate) {
                this.props.handleTextboxChange('extVoltThreshold')({
                    target: { value },
                });
            }
        }
    }

    render() {
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    <div className={styles['step-rightside']}>
                        <form noValidate autoComplete="off">
                            <div>
                                {ALERT_DETAILS.LABEL.LOW_ASSET_BATTERY}
                                &nbsp;&nbsp;
                                <Input
                                    type="number"
                                    value={this.state.extVoltThreshold}
                                    onChange={(e) =>
                                        this.updateExternalVoltage(e.target.value, true)}
                                    inputProps={{ step, max: maxVoltage, min: minVoltage }}
                                    sx={{
                                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                            opacity: 1,
                                        },
                                    }}
                                />
                                &nbsp;&nbsp;
                                volts or lower.
                                <br />
                                <br />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            id="alertIntLowBattery"
                                            name="alertIntLowBattery"
                                            value="alertIntLowBattery"
                                            checked={
                                                this.props.formData.config
                                                    .alertIntLowBattery === true
                                            }
                                            onChange={
                                                this.props
                                                    .handleCheckboxChange('alertIntLowBattery')
                                            }
                                        />
                                    }
                                    label={<span style={{ whiteSpace: 'break-spaces' }}>{ALERT_DETAILS.LABEL.LOW_ASSET_BATTERY_INTERNAL_DEVICE}</span>}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default LowAssetBatteryAlert;
