/* @flow */

import React from 'react';
import Parser from 'html-react-parser';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import customstyles from './Directions.module.scss';

type Props = {
    steps: Object,
}

const DirectionsStepsDetails = (props : Props) => {
    const { steps } = props;
    return (
        <ListItem className={`${customstyles['list-item']} ${customstyles.directionStepsList}`}>
            <Grid container spacing={1}>
                <Grid item xs={9} className={customstyles.gridItemDirections}>
                    <div className={customstyles['direction-desc']}>
                        {Parser(steps.text)}
                    </div>
                </Grid>
                <Grid item xs={3} className={customstyles.gridItemDirections}>
                    <div>
                        <Typography paragraph className={customstyles['direction-distance-time']} align="right" component="p">
                            {steps.duration}
                        </Typography>
                        <Typography className={customstyles['direction-distance-time']} align="right" >
                            {steps.distance}
                        </Typography>
                    </div>
                </Grid>
                <div className={customstyles['list-border']} />
            </Grid>
        </ListItem>
    );
};

export default (DirectionsStepsDetails);
