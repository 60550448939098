/* @flow */

export const FETCH_USER: string = 'FETCH_USER';
export const FETCH_USER_SUCCESS: string = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR: string = 'FETCH_USER_ERROR';
export const FETCH_USERROLES: string = 'FETCH_USERROLES';
export const FETCH_USERROLES_SUCCESS: string = 'FETCH_USERROLES_SUCCESS';
export const FETCH_USERROLES_ERROR: string = 'FETCH_USERROLES_ERROR';
export const FETCH_USERACCESS: string = 'FETCH_USERACCESS';
export const FETCH_USERACCESS_SUCCESS: string = 'FETCH_USERACCESS_SUCCESS';
export const FETCH_USERACCESS_ERROR: string = 'FETCH_USERACCESS_ERROR';
export const ADD_USER: string = 'ADD_USER';
export const ADD_EDIT_USER_SUCCESS: string = 'ADD_EDIT_USER_SUCCESS';
export const ADD_USER_ERROR: string = 'ADD_USER_ERROR';
export const EDIT_USER: string = 'EDIT_USER';
export const EDIT_USER_ERROR: string = 'EDIT_USER_ERROR';
export const DELETE_USER: string = 'DELETE_USER';
export const DELETE_USER_SUCCESS: string = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR: string = 'DELETE_USER_ERROR';
export const CLOSE_USER_DIALOG: string = 'CLOSE_USER_DIALOG';
export const UPDATE_USER_LOADER: string = 'UPDATE_USER_LOADER';
export const SEND_INVITATION: string = 'SEND_INVITATION';
export const SEND_INVITATION_SUCCESS: string = 'SEND_INVITATION_SUCCESS';
export const SEND_INVITATION_ERROR: string = 'SEND_INVITATION_ERROR';
export const FULL_ACCESS = 'Full Access';

export type UserTypeAction = {
    type: string;
    payload?: {};
}
