/* @flow */
import React, { Component } from 'react';
import customStyles from './DashcamList.module.scss';
import List from '../../SharedComponents/List/List';
import { getRefreshChip } from '../../../util/map_utils';
import AppLoader from '../../AppLoader';
import DashcamCard from './DashcamCard';
import { isEmpty } from '../../../helper-classes/utility-functions';

export type Props = {
    handleFilters: Object,
    handleRecordingClick: Function,
    showRefreshButton: boolean,
    refreshDashcamListData: Function,
    isUpdating: boolean,
    loadNextPage: Function,
    redirectTo: Function,
    devices: Object,
    trackAnalytics: Function,
    showLoader: Function,
};

export type State = {
};

class DashcamList extends Component<Props, State> {
    constructor(props: Object) {
        super(props);
        this.state = {
        };
    }

    getLoader = () => (<AppLoader
        loaderStyle={{
            'padding-top': '10px',
            'padding-bottom': '10px',
            'text-align': 'center',
            display: 'block',
        }}
    />)

    getDashcamCardContent = (dashcam: Object) => {
        if (!isEmpty(dashcam)) {
            return (
                <DashcamCard
                    dashcam={dashcam}
                    appliedFiltersObj={this.props.handleFilters.appliedFilters}
                    updateFilters={this.props.handleFilters.updateFilters}
                    key={dashcam.imei}
                    redirectTo={() => {}}
                    handleRecordingClick={this.props.handleRecordingClick}
                    trackAnalytics={this.props.trackAnalytics}
                    showLoader={this.props.showLoader}
                />
            );
        }
        return '';
    }

    render() {
        const { isUpdating, devices } = this.props;

        return (
            <React.Fragment>
                {!isUpdating && !isEmpty(devices) && devices.currentPage >= 0 ?
                    <div className={customStyles.available_dashcams}>
                        {this.props.showRefreshButton
                            && getRefreshChip(this.props.refreshDashcamListData)}
                        <div className={customStyles.available_dashcams_list}>
                            <List
                                data={devices.data || []}
                                height={81}
                                currentPage={devices.currentPage}
                                totalNumberofPage={devices.totalPage}
                                pagination={{
                                    isPagination: true,
                                    pageChanged: this.props.loadNextPage,
                                }}
                                getListItem={this.getDashcamCardContent}
                                showMessage
                            />
                        </div>
                    </div>
                    :
                    isUpdating && this.getLoader()
                }
            </React.Fragment>
        );
    }
}

export default DashcamList;
