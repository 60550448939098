import React from 'react';
import { Grid, Tooltip } from '@mui/material';
import style from './CustomerSupport.module.scss';
import customerSupport from '../assets/icons/customer.svg';
import { getCustomerContact, getLocalStorageValue } from '../helper-classes/utility-functions';

const CustomerSupport = () => {
    const selectedAccount = getLocalStorageValue('selectedAccount');
    const customerSupportDetails = getCustomerContact(selectedAccount);
    const { email, phone, increaseWidth } = customerSupportDetails;

    return (
        <div>
            <Grid container className={style.cs}>
                <Grid item xs={2} className={style.borderPadding}>
                    <img src={customerSupport} alt="customer" />
                </Grid>
                <Grid item xs={10} className={style.csPadding}>
                    <div className={style.csText}>
                        Customer Support
                    </div>
                    <div className={style.csEmail}>Email:</div>
                    <Tooltip title={email}>
                        <p className={`${style.csEmailBox} ${!selectedAccount ? style.csEmailWidth : ''}`}>
                            <a
                                href={`mailto:${email}`}
                                className={`${style.csEmailText} ${increaseWidth ? style.csEmailTextPadding0 : ''}`}
                                target="_blank"
                            >
                                {email}
                            </a>
                        </p>
                    </Tooltip>
                    <br />
                    <div className={style.csEmail}>Phone:</div>
                    <span className={style.csNumber}>{phone}</span>
                </Grid>
            </Grid>
        </div>
    );
};

export default CustomerSupport;
