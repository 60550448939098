/* @flow */
import React from 'react';
import { Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import customStyles from './ClusterFilter.module.scss';
import movingIcons from '../../assets/icons/cluster/moving.svg';
import pauseIcons from '../../assets/icons/cluster/pause.svg';
import stoppedIcon from '../../assets/icons/cluster/stopped.svg';
import landmarkInIcon from '../../assets/icons/cluster/landmarkin.svg';
import landmarkOutIcon from '../../assets/icons/cluster/landmarkout.svg';
import AppLoader from '../AppLoader';
import analytics from '../../analytics/index';

export type Props = {
    filterPanelItemOnClick: Function,
    assetPointsCount: Object,
    loadingCluster: boolean,
    appliedFilters: Object,
    disableFilters: Function,
};

export type State = {
    selectedAssetStatuses: any,
    landmarkStatus: string,
    isDisableFilters: boolean,
};

const getLandmarkStatus = (value) => {
    const landmarkStatus = (value === 'true') ? 'landmark_in' : '';
    return (!landmarkStatus && value === 'false') ? 'landmark_out' : landmarkStatus;
};

class ClusterFilter extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            selectedAssetStatuses: (this.props.appliedFilters && this.props.appliedFilters.asset_status) ? this.props.appliedFilters.asset_status.split(',') || [] : [],
            landmarkStatus: (this.props.appliedFilters && this.props.appliedFilters.asset_landmark) ? getLandmarkStatus(this.props.appliedFilters.asset_landmark) : '',
            isDisableFilters: false,
        };
    }

    componentDidMount() {
        if (this.props.disableFilters && typeof this.props.disableFilters === 'function') {
            this.props.disableFilters(this.disableFilters);
        }
    }

    availableFilters = [
        {
            value: 'moving',
            title: 'Moving',
            type: 'asset_status',
            icon: movingIcons,
        },
        {
            value: 'idle',
            title: 'Idling',
            type: 'asset_status',
            icon: pauseIcons,
        },
        {
            value: 'stopped',
            title: 'Stopped',
            type: 'asset_status',
            icon: stoppedIcon,
        },
        {
            value: 'landmark_in',
            title: 'Inside Landmark',
            type: 'landmark_status',
            icon: landmarkInIcon,
        },
        {
            value: 'landmark_out',
            title: 'Outside Landmark',
            type: 'landmark_status',
            icon: landmarkOutIcon,
        },
    ];

    filterPanelItemOnClick = (status, type) => {
        const { selectedAssetStatuses } = this.state;
        let { landmarkStatus } = this.state;

        if (type === 'asset_status') {
            if (selectedAssetStatuses.indexOf(status) >= 0) {
                selectedAssetStatuses.splice(selectedAssetStatuses.indexOf(status), 1);
            } else selectedAssetStatuses.push(status);
            this.setState({ selectedAssetStatuses });
        } else if (type === 'landmark_status') {
            if (this.state.landmarkStatus === status) {
                landmarkStatus = '';
            } else landmarkStatus = status;
            this.setState({ landmarkStatus });
        }

        let filter = '';
        if (selectedAssetStatuses) {
            filter = `&asset_status=${selectedAssetStatuses.join(',')}`;
        }

        if (landmarkStatus) {
            if (landmarkStatus === 'landmark_in') {
                filter = `${filter}&asset_landmark=true`;
            } else if (landmarkStatus === 'landmark_out') {
                filter = `${filter}&asset_landmark=false`;
            }
        }
        this.props.filterPanelItemOnClick(filter);

        // Analytics for status bar icons
        if (type === 'asset_status') {
            const params = {
                feature: 'Map',
                selected: filter.indexOf(status) > -1,
            };
            switch (status) {
            case 'idle':
                analytics.track('SHOW_IDLING', params);
                break;
            case 'moving':
                analytics.track('SHOW_MOVING', params);
                break;
            case 'stopped':
                analytics.track('SHOW_STOPPED', params);
                break;
            default:
                break;
            }
        } else if (type === 'landmark_status') {
            switch (status) {
            case 'landmark_in':
                analytics.track('INSIDE_LANDMARK', {
                    feature: 'Map',
                    selected: filter.indexOf('landmark=true') > -1,
                });
                break;
            case 'landmark_out':
                analytics.track('OUTSIDE_LANDMARK', {
                    feature: 'Map',
                    selected: filter.indexOf('landmark=false') > -1,
                });
                break;
            default:
                break;
            }
        }
    }

    isFilterSelected = (filter, type) => {
        if (type === 'asset_status') {
            if (this.state.selectedAssetStatuses.indexOf(filter) >= 0) {
                return true;
            }
        } else if (type === 'landmark_status') {
            if (this.state.landmarkStatus === filter) {
                return true;
            }
        }
        return false;
    }

    getCount = (filter) => {
        if (filter === 'moving') {
            return this.props.assetPointsCount.movingCount;
        } else if (filter === 'idle') {
            return this.props.assetPointsCount.idleCount;
        } else if (filter === 'stopped') {
            return this.props.assetPointsCount.stoppedCount;
        } else if (filter === 'landmark_in') {
            return this.props.assetPointsCount.inLandmarkCount;
        } else if (filter === 'landmark_out') {
            return this.props.assetPointsCount.outLandmarkCount;
        }
        return 0;
    }

    disableFilters = (isDisableFilters) => {
        this.setState({ isDisableFilters });
    }

    render() {
        return (
            <div className={customStyles['map-top-info']}>
                {this.state.isDisableFilters && <div className={customStyles['disable-filter']} /> }
                { this.props.loadingCluster &&
                    <div className={customStyles.loader}>
                        <AppLoader type="bar" height={4} width={512} />
                    </div>
                }
                <div style={{ paddingLeft: 6 }} />
                {this.availableFilters.map(filter => (
                    <Tooltip key={filter.value} title={filter.title || ''} disableFocusListener>
                        <div
                            key={filter.value}
                            className={
                                this.isFilterSelected(filter.value, filter.type) ?
                                    `${customStyles['selected-filter']} ${customStyles['info-icon-container']}` : `${customStyles['info-icon-container']}`
                            }
                            tabIndex={0}
                            onClick={() => this.filterPanelItemOnClick(filter.value, filter.type)}
                            onKeyDown={() => {}}
                            role="button"
                        >
                            <div className={customStyles['info-icon']}>
                                <img src={filter.icon} alt={filter.title || ''} />
                                <span className={customStyles['info-icon-text']}>{this.getCount(filter.value)}</span>
                            </div>
                        </div>
                    </Tooltip>
                ))}
                <div style={{ paddingRight: 4 }} />
            </div>
        );
    }
}

export default withStyles(customStyles)(ClusterFilter);
