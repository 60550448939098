
/* @flow */
import React, { Component } from 'react';
import { Circle } from 'react-google-maps';
import CustomMarker from './CustomMarker';
import { mapColor } from './../constants.map';

const shapeOption = {
    fillColor: mapColor.landmark.fillColor,
    strokeColor: mapColor.landmark.strokeColor,
};

type Props = {
    getCircleValue: Function,
    updateShape: Function,
    circle: Object,
    center: Object,
}

type State = {
    isDragStart: boolean,
}
class CustomCircle extends Component<Props, State> {
    circle: any;
    CircleRef: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            isDragStart: false,
        };
        this.CircleRef = React.createRef();
    }

    componentDidMount() {
        const { getCircleValue } = this.props;
        if (getCircleValue && typeof getCircleValue === 'function') getCircleValue(this.getCircleValue);
    }

    getCircleValue = () => {
        const bounds = this.CircleRef.current.getBounds();
        const north = bounds.getNorthEast().lat() || 0;
        const east = bounds.getNorthEast().lng() || 0;
        const south = bounds.getSouthWest().lat() || 0;
        const west = bounds.getSouthWest().lng() || 0;
        const center = this.CircleRef.current.getCenter();

        let radius = this.CircleRef.current.getRadius();
        radius = (radius && radius > 1) ? radius : 1;

        return {
            center,
            radius,
            bounds: {
                north, south, east, west,
            },
        };
    }

    updateCircleBound = () => {
        const { radius, bounds, center } = this.getCircleValue();
        this.setState({ isDragStart: false });
        this.props.updateShape('editableCircle', { radius, bounds }, { lat: center.lat(), lng: center.lng() });
    }

    render() {
        const { isDragStart } = this.state;
        const { circle, center } = this.props;
        const opitions = {
            strokeColor: (circle && circle.lineColorValue)
                ? circle.lineColorValue : shapeOption.strokeColor,
            strokeOpacity: 0.8,
            strokeWeight: (circle && circle.lineWidth >= 0)
                ? circle.lineWidth : 2,
            fillColor: (circle && circle.fillColorValue)
                ? circle.fillColorValue : shapeOption.fillColor,
            fillOpacity: (circle && circle.transparency >= 0)
                ? ((100 - circle.transparency) / 100) : 0.35,
        };

        return (
            <React.Fragment>
                <Circle
                    center={center}
                    radius={circle.radius || 500}
                    options={opitions}
                    ref={this.CircleRef}
                    editable
                    draggable
                    clickable
                    onDragStart={() => this.setState({ isDragStart: true })}
                    onDragEnd={() => this.updateCircleBound()}
                    onRadiusChanged={() => this.updateCircleBound()}
                    onCenterChanged={() => { if (!isDragStart) this.updateCircleBound(); }}
                />
                {!isDragStart &&
                    <CustomMarker
                        lat={center.lat || 0}
                        lng={center.lng || 0}
                        imageType={circle.iconValue}
                        iconColor={circle.iconColorValue}
                    />
                }
            </React.Fragment>
        );
    }
}

export default CustomCircle;
