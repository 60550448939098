/* @flow */
/* eslint radix: ["error","as-needed"] */

import { combineEpics } from 'redux-observable';
import {
    FETCH_RECIPIENT_LIST_ADMIN,
    FETCH_RECIPIENT_ALERTS,
    DELETE_RECIPIENT_ALERTS,
} from './constants.recipentList';
import {
    fetchRecipientListSuccess,
    fetchRecipientListError,
    fetchRecipientAlertsError,
    fetchRecipientAlertsSuccess,
    deleteAlertSuccess,
    deleteAlertError,
} from './actions.recipent';
import {
    convertTimeTo24HoursFormat,
    getTimeFormatAsperUserSettting,
    getGrailsAppBasedURL,
    getAuthorizationHeader,
} from '../../helper-classes/utility-functions';

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    deleteJSON: Function,
    of: Function,
};

const getRecipients = (
    actions$: Function,
    store: Object,
    { getJSON, of }: ObservarblesTypes,
) =>
    actions$.ofType(FETCH_RECIPIENT_LIST_ADMIN)
        .mergeMap((action) => {
            const querytext = (action.payload.recipientKey !== undefined) ? action.payload.recipientKey.replace('#', '%23') : '';
            const headers = {
                Authorization: getAuthorizationHeader(),
            };
            return getJSON(
                `${getGrailsAppBasedURL()}/recipients/searchRecipients?query=${querytext}`,
                headers,
            )
                .map(result => fetchRecipientListSuccess(result.data))
                .catch(error => of(fetchRecipientListError(error)));
        });


const deleteAlert = (
    actions$: Function,
    store: Object,
    { of }: ObservarblesTypes,
) =>
    actions$.ofType(DELETE_RECIPIENT_ALERTS)
        .mergeMap((action) => {
            const recipient = (action.payload.recipient !== undefined) ? action.payload.recipient.replace('#', '%23') : '';
            const headers = {
                'Content-Type': 'application/json',
                Authorization: getAuthorizationHeader(),
            };
            const { alert } = action.payload;
            return fetch(
                `${getGrailsAppBasedURL()}/recipients/removeRecipientFromReportAlert?_dc=${new Date().getTime()}&recipient=${recipient}`,
                {
                    method: 'DELETE',
                    headers,
                    body: JSON.stringify(alert),
                },
            )
                .then(result => result.json())
                .then(result => deleteAlertSuccess(result))
                .catch(error => of(deleteAlertError(error)));
        });

const formatData = (data: any) => {
    if (!data && data.length <= 0) return [];

    return data.map((d) => {
        const savedRecipients = Object.assign({}, d);
        if (d.savedScheduled) {
            if (d.savedScheduled !== '' && d.savedScheduled !== 'Saved' && getTimeFormatAsperUserSettting() === '24h') {
                const savedScheduled = d.savedScheduled.split('at ');
                savedRecipients.savedScheduled = `${savedScheduled[0]}at ${convertTimeTo24HoursFormat(savedScheduled[1])}`;
            }
        }
        return savedRecipients;
    });
};

const formatSortingString = (orderBy: string, order: string) => (order && orderBy
    ? escape(`[{"property":"${orderBy}","direction":"${order}"}]`)
    : escape('[{"property":"type","direction":"ASC"}]'));

export const fetchRecipientAlerts =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_RECIPIENT_ALERTS)
            .mergeMap((action) => {
                const {
                    recipient,
                    pageNumber,
                    pageSize,
                    order,
                    orderBy,
                } = action.payload;
                const offset = (parseInt(pageNumber) * parseInt(pageSize));
                const headers = {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: getAuthorizationHeader(),
                };
                const randVal = new Date().getTime();
                // Sorting Parameter creation
                const sorts = formatSortingString(orderBy, order);
                const reqURL = `recipient=${recipient}&_dc=${randVal}&page=${pageNumber}&start=${offset}&limit=${pageSize}&sort=${sorts}`;
                return getJSON(`${getGrailsAppBasedURL()}/recipients/searchReportAlert?${reqURL}`, headers)
                    .map((result) => {
                        const formatedData = formatData(result.data);
                        return fetchRecipientAlertsSuccess(formatedData, result.total);
                    })
                    .catch(error => of(fetchRecipientAlertsError(error)));
            });

export default combineEpics(getRecipients, fetchRecipientAlerts, deleteAlert);
