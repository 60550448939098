/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_DRIVERS_LIST } from './constants.driverList';
import { fetchDriversListError, fetchDriversListSuccess } from './actions.driverList';
import { getUserToken } from '../../helper-classes/utility-functions';
import config from '../../constants/Config';

type ObservarblesTypes = {
    postJSON: Function,
    of: Function
}

const formatData = (data: any) => {
    if (!data || data.length <= 0) return [];
    return data.map((d) => {
        const drivers = Object.assign({}, d);
        drivers.firstName = `${(d.firstName) ? d.firstName : ''}${(d.lastName) ? ' ' : ''}${(d.lastName) ? d.lastName : ''}`;
        return drivers;
    });
};

export const getRequestPayload = (payload: Object) => {
    const {
        pageNumber,
        pageSize,
        order,
        orderBy,
        filter = {},
    } = payload;
    const data = {
        sortParam: order && orderBy ? [[orderBy, order]] : undefined,
        start: pageNumber * pageSize,
        limit: pageSize,
        filterParam: [],
        searchParams: [''],
    };

    if (filter.driverFiltersToSend && filter.driverFiltersToSend.length > 0) {
        data.filterParam = filter.driverFiltersToSend;
    } else {
        delete data.filterParam;
    }

    if (filter.search && filter.search.length > 0 && typeof filter.search === 'string') {
        data.searchParams = [filter.search.replace(/[\s]{2,}/gi, ' ').trim()];
    } else {
        delete data.searchParams;
    }
    return data;
};

export const getDrivers =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_DRIVERS_LIST)
            .distinctUntilChanged()
            .debounceTime(config.get('DEBOUNCE_TIME'))
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };
                return postJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/drivers`, getRequestPayload(action.payload), headers)
                    .map((result) => {
                        const formatedData = formatData(result.response.data);
                        return fetchDriversListSuccess(formatedData, result.response.total);
                    })
                    .catch(error => of(fetchDriversListError(error)));
            });

export const getURL = () => `${config.get('FLEET_VIEW_SERVICES_URL')}/drivers/export`;


export default combineEpics(getDrivers);
