/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid, Card } from '@mui/material';
import TripsTimeline from '../../components/Common/TripsTimeline';
import customstyles from './style.module.scss';
import {
    convertDateTime,
    timeDrivenFormatter,
    getCorrectDistanceRounded,
    getDistanceString,
    getTimeFormatAsperUserSettting,
} from '../../helper-classes/utility-functions';

const styles = () => ({
    card: {
        marginBottom: 10,
        marginLeft: 8,
        marginRight: 6,
        marginTop: 6,
    },
    cardSelected: {
        marginBottom: 10,
        marginLeft: 2,
        marginRight: 6,
        marginTop: 6,
        borderLeft: '6px solid #007aff',
    },
    tripHeader: {
        backgroundColor: '#f5f5f5',
    },
    unauthHeader: {
        backgroundColor: '#fc0207',
    },
    tripTitleContainer: {
        paddingLeft: 8,
    },
    unauthTitleContainer: {
        paddingLeft: 8,
    },
    unauthTinyContainer: {
        paddingTop: 4,
    },
    logoContainer: {
        paddingLeft: 16,
        paddingTop: 16,
    },
});

export type Props = {
    assetId: string,
    tripsData: Array<Object>,
    classes: Object,
    goToTripDetail: Function,
    dateSelected: string,
    setSelectedTrips: Function,
};

export type State = {
    selectedTrips: Array<Object>,
};

class TripsListComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedTrips: [],
        };
    }

    getLogo = (tripType) => {
        switch (tripType) {
        case 'UNAUTHORIZED_INPROCESS':
            return <span className={customstyles['unauthorized-icon']} />;
        case 'UNAUTHORIZED_COMPLETED':
            return <span className={customstyles['unauthorized-icon']} />;
        case 'INPROCESS':
            return <span className={customstyles['waypoint-icon']} />;
        case 'STOPPED':
            return <span className={customstyles['stopped-icon']} />;
        case 'COMPLETED':
            return <span className={customstyles['waypoint-icon']} />;
        default:
            return '';
        }
    }

    selectTrip = (trip, e) => {
        if (e.target.className.includes('back-icon')) {
            // do not select/unselect trip card when arrow button to TripsDetail is clicked
            return;
        }
        const { selectedTrips } = this.state;
        const { setSelectedTrips } = this.props;
        const index = selectedTrips.indexOf(trip.id);
        if (index !== -1) {
            selectedTrips.splice(index, 1);
            this.setState({ selectedTrips });
            setSelectedTrips(selectedTrips);
        } else if (trip.type === 'INPROCESS' || trip.type === 'COMPLETED') {
            selectedTrips.push(trip.id);
            this.setState({ selectedTrips });
            setSelectedTrips(selectedTrips);
        }
    }

    getFilteredTripsIndex = (trip) => {
        const filteredTrips = this.props.tripsData.filter(t => t.type === 'COMPLETED' || t.type === 'INPROCESS');
        return filteredTrips.findIndex(i => i.id === trip.id);
    };

    renderTripCard = (trip) => {
        const { classes, dateSelected } = this.props;
        const index = this.getFilteredTripsIndex(trip);
        return (
            <React.Fragment>
                <Grid
                    container
                    id="trip-header"
                    alignItems="center"
                    className={
                        (trip.type === 'UNAUTHORIZED_INPROCESS' || trip.type === 'UNAUTHORIZED_COMPLETED') ?
                            classes.unauthHeader : classes.tripHeader
                    }
                >
                    <Grid item className={classes.logoContainer}>
                        {this.getLogo(trip.type)}
                    </Grid>
                    <Grid
                        item
                        xs={9}
                        className={
                            (trip.type === 'UNAUTHORIZED_INPROCESS' || trip.type === 'UNAUTHORIZED_COMPLETED') ?
                                classes.unauthTitleContainer : classes.tripTitleContainer
                        }
                    >
                        <Grid container alignItems="center">
                            <Grid
                                item
                                xs={12}
                                className={
                                    (trip.type === 'UNAUTHORIZED_INPROCESS' || trip.type === 'UNAUTHORIZED_COMPLETED') ?
                                        customstyles.unauthTitle : customstyles.tripTitle
                                }
                            >
                                {convertDateTime(trip.startDate, (getTimeFormatAsperUserSettting() === '12h') ? 'MMM D, YYYY hh:mm A' : 'MMM D, YYYY H:mm')}
                            </Grid>
                            {this.renderTripCardHeader(trip)}
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        {trip.type === 'INPROCESS' || trip.type === 'COMPLETED' ?
                            <span
                                className={customstyles['back-icon']}
                                onClick={() => this.props.goToTripDetail(index, dateSelected)}
                                role="button"
                                tabIndex="0"
                                onKeyDown={() => {}}
                            />
                            :
                            ''}
                    </Grid>
                </Grid>
                <Grid container id="trip-body">
                    <TripsTimeline trip={trip} />
                </Grid>
            </React.Fragment>
        );
    }

    renderTripCardHeader = (trip) => {
        if (trip.type === 'INPROCESS') {
            return (
                <Grid item xs={12} className={customstyles.tripSubTitle} style={{ color: '#f5a623' }}>
                    In Progress
                </Grid>
            );
        } else if (trip.type === 'COMPLETED') {
            return (
                <Grid
                    item
                    xs={12}
                    className={customstyles.tripSubTitle}
                >
                    {trip.totalDuration !== undefined && <span>{timeDrivenFormatter(trip.totalDuration)}</span>}{' | '}
                    {trip.movingDuration !== undefined && <span style={{ color: '#7ed321' }}>{timeDrivenFormatter(trip.movingDuration)} Moving</span>}{' | '}
                    {trip.idleDuration !== undefined && <span style={{ color: '#f5a623' }}>{timeDrivenFormatter(trip.idleDuration)} Idle</span>}{' | '}
                    {trip.distanceTravelled !== undefined && <span>{getCorrectDistanceRounded(trip.distanceTravelled / 1000, 'km')} {getDistanceString()}</span>}{' | '}
                    {trip.alertCount !== undefined &&
                        <span>{trip.alertCount} Alerts</span>
                    }
                </Grid>
            );
        } else if (trip.type === 'UNAUTHORIZED_INPROCESS' || trip.type === 'UNAUTHORIZED_COMPLETED') {
            return (
                <Grid
                    item
                    xs={12}
                    className={customstyles.unauthSubTitle}
                >
                    {trip.totalDuration !== undefined && <span>{timeDrivenFormatter(trip.totalDuration)}</span>}{' | '}
                    {<span>Unauthorized Movement</span>}
                </Grid>
            );
        }
        return (
            <Grid
                item
                xs={12}
                className={customstyles.tripSubTitle}
            >
                {trip.totalDuration && <span style={{ color: '#fc0207' }}>{`${timeDrivenFormatter(trip.totalDuration)} Stopped`}</span>}
            </Grid>
        );
    }

    render() {
        const { selectedTrips } = this.state;
        const {
            tripsData,
            classes,
            dateSelected,
        } = this.props;
        const tripList = (tripsData.map((trip, index) => (
            <div
                onClick={e => this.selectTrip(trip, e)}
                data-itemid={this.props.assetId}
                className={customstyles['trip-card-wrapper']}
                key={trip.id}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
            >
                <Card
                    className={selectedTrips.indexOf(trip.id) !== -1 ?
                        classes.cardSelected : classes.card}
                    id={`trip-${index}`}
                    onDoubleClick={() =>
                        this.props.goToTripDetail(this.getFilteredTripsIndex(trip), dateSelected)}
                >
                    {this.renderTripCard(trip)}
                </Card>
            </div>)));
        return tripList;
    }
}

export default withStyles(styles, { withTheme: true })(TripsListComponent);
