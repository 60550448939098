/* @flow */
import { InfoWindow } from 'react-google-maps';
import React, { Component } from 'react';
import { Typography, Tooltip } from '@mui/material/';
import withStyles from '@mui/styles/withStyles';
import { reduxConnect } from '../../../hoc';
import * as actions from './../../Address/actions.address';
import DetailActions from './DetailActions';
import { getAddressLine1, getAddressLine2 } from './../../../helper-classes/utility-functions';

export type Props = {
    classes: Object,
    addressParameter: Function,
    fetchAddressWithLatLng: Function,
    history: {
        push: Function,
    },
    assetId: string,
    place: {
        address: Object,
    },
}

export type State = {
   lat: number,
   lng: number,
   addressLine1: string,
   addressLine2: string,
   show: boolean,
};

const customStyles = () => ({
    timelineMarker: {
        height: '1px',
        border: 'solid .6px grey',
        opacity: '0.21',
        position: 'relative',
        top: '10px',
    },
    addressLine1: {
        height: '24px',
        fontSize: '16px !important',
        lineHeight: '1.5',
        color: 'rgba(0, 0, 0, 0.87)',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    addressLine2: {
        height: '20px',
        fontSize: '14px !important',
        lineHeight: '1.43',
        color: 'rgba(0, 0, 0, 0.54)',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
});

class AddressWindow extends Component<Props, State> {
    isNearestAsset: boolean;

    constructor(props) {
        super(props);
        this.state = {
            lat: 0,
            lng: 0,
            addressLine1: '',
            addressLine2: '',
            show: false,
        };
        this.isNearestAsset = false;
    }

    componentDidMount() {
        if (this.props.addressParameter && typeof this.props.addressParameter === 'function') {
            this.props.addressParameter(this.addressParameter);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.assetId && this.isNearestAsset) {
            this.isNearestAsset = false;
            this.props.history.push(`/home?assetId=${nextProps.assetId}`);
        }

        if (nextProps.place && nextProps.place.address) {
            this.setState({
                addressLine1: getAddressLine1(nextProps.place.address),
                addressLine2: getAddressLine2(nextProps.place.address),
            });
        }
    }

    addressParameter = (lat, lng, show) => {
        if (show) this.props.fetchAddressWithLatLng(lat, lng);
        this.setState({
            addressLine1: '', addressLine2: '', lat, lng, show,
        });
    }

    redirectTo = () => this.props.history.push('/landmarks/add?isGeoAddress=true');

    nearestAsset = () => {
        if (this.state.show) {
            this.isNearestAsset = true;
            this.setState({ show: false });
            this.props.history.push(`/nearest-assets?lat=${this.state.lat}&lng=${this.state.lng}`);
        }
    }

    render() {
        const {
            lat, lng, addressLine1, addressLine2,
        } = this.state;
        const { classes } = this.props;

        return (
            <React.Fragment>
                {this.state.show && (addressLine1.length > 0 || addressLine2.length > 0) &&
                    <InfoWindow
                        id="mapRightClickMenu"
                        key="mapRightClickMenu"
                        position={{ lat, lng }}
                        options={{
                            maxWidth: 280,
                            maxHeight: 154,
                        }}
                        onDomReady={() => {
                            const mainContainer = document.querySelector('.gm-style-iw');

                            if (mainContainer) {
                                const innerContainer = mainContainer.querySelector('button:nth-child(2)');
                                mainContainer.style.paddingLeft = '18px';

                                if (innerContainer) {
                                    innerContainer.style.display = 'none';
                                    mainContainer.removeChild(innerContainer);
                                }
                            }
                        }}
                    >
                        <div>
                            {addressLine1 &&
                            <Tooltip title={addressLine1}>
                                <Typography className={classes.addressLine1}>
                                    {addressLine1}
                                </Typography>
                            </Tooltip>
                            }
                            <Tooltip title={addressLine2}>
                                <Typography className={
                                    addressLine1 ? classes.addressLine2 : classes.addressLine1
                                }
                                >
                                    {addressLine2}
                                </Typography>
                            </Tooltip>
                            <div className={classes.timelineMarker} />
                            <DetailActions
                                redirectTo={this.redirectTo}
                                nearestAsset={this.nearestAsset}
                            />
                        </div>
                    </InfoWindow>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    assetId: state.address.assetId,
    place: state.address.address,
});

export default withStyles(customStyles)(reduxConnect(AddressWindow, actions, mapStateToProps));
