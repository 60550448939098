/* eslint-disable react/prop-types */
/* eslint-disable  */
import moment from 'moment-timezone';
import startImage from '../assets/icons/trips/start.svg';
import shapeImage from '../assets/icons/assets/shape.svg';
import temperature from '../assets/icons/trips/temperature-filled.svg';
import TruckImageBlue from '../assets/icons/assets/truckblue.svg';
import TruckImage from '../assets/icons/assets/truck.svg';
import TruckImageCluster from '../assets/icons/cluster/truckImage.svg';
import AssetImageCluster from '../assets/icons/cluster/asset.svg';
import locationImageCluster from '../assets/icons/cluster/location_cluster.svg';
import locationImageClusterLarge from '../assets/icons/cluster/location_cluster_large.svg';
import ExcavatorImageCluster from '../assets/icons/cluster/excavator.svg';
import TrailerImageCluster from '../assets/icons/cluster/trailer.svg';
import stopImage from '../assets/icons/trips/stop.svg';
import speedImage from '../assets/icons/trips/speed.svg';
import oddHours from '../assets/icons/trips/oddhours.svg';
import goodBattery from '../assets/icons/alerts/battery_good.svg';
import goodBatteryNew from '../assets/icons/alerts/battery_good_new.svg';
import excellentCellular from '../assets/icons/alerts/cellular_excellent.svg';
import goodCellular from '../assets/icons/alerts/cellular_good.svg';
import goodGps from '../assets/icons/alerts/gps_good.svg';
import locationImage from './../assets/icons/addresses/shape.png';
import assetImage from './../assets/icons/assets/assets.png';
import equipmentAssetImage from './../assets/icons/assets/equipment.svg';
import trailerAssetImage from './../assets/icons/assets/trailer.svg';
import enterFilled from './../assets/icons/trips/enter-filled.svg';
import calendarIcon from './../assets/icons/trips/icon-white.svg';
import vehicleAssetImage from './../assets/icons/assets/vehicle.svg';
import ResetToLandmarks from './../assets/icons/Reset-to-Landmarks.svg';
import ResetToVehicles from './../assets/icons/Reset-to-Vehicles.svg';
import IdelPar from './../assets/icons/clock-filled.svg';
import Input from './../assets/icons/input.svg';
import batteryWarning from '../assets/icons/alerts/battery_warning.svg';
import batteryWarningNew from '../assets/icons/alerts/battery_warning_new.svg';
import fairCellular from '../assets/icons/alerts/cellular_fair.svg';
import fairGps from '../assets/icons/alerts/gps_fair.svg';
import batteryLow from '../assets/icons/alerts/battery_low.svg';
import batteryLowNew from '../assets/icons/alerts/battery_low_new.svg';
import badCellular from '../assets/icons/alerts/cellular_bad.svg';
import badGps from '../assets/icons/alerts/gps_bad.svg';
import batteryNotReporting from '../assets/icons/alerts/battery_not_reporting.svg';
import batteryNotReportingNew from '../assets/icons/alerts/battery_not_reporting_new.svg';
import satelliteExcellent from '../assets/icons/alerts/satellite_excellent.svg';
import satelliteGood from '../assets/icons/alerts/satellite_good.svg';
import satelliteFair from '../assets/icons/alerts/satellite_fair.svg';
import satellitePoor from '../assets/icons/alerts/satellite_poor.svg';
import satelliteNone from '../assets/icons/alerts/satellite_none.svg';
import satelliteGoodNew from '../assets/icons/alerts/satellite_good_new.svg';
import satelliteFairNew from '../assets/icons/alerts/satellite_fair_new.svg';
import satellitePoorNew from '../assets/icons/alerts/satellite_poor_new.svg';
import satelliteNoneNew from '../assets/icons/alerts/satellite_none_new.svg';


import { getAssetIconType } from './map_utils';
// import { nest } from 'recompose';

import airport from '../assets/icons/landmarks/airport.svg';
import gasStation from '../assets/icons/landmarks/gas-station.svg';
import highRise from '../assets/icons/landmarks/high-rise.svg';
import house from '../assets/icons/landmarks/house.svg';
import office1 from '../assets/icons/landmarks/office.svg';
import office2 from '../assets/icons/landmarks/office-2.svg';
import office3 from '../assets/icons/landmarks/office-3.svg';
import oilTower from '../assets/icons/landmarks/oil-tower.svg';
import diamondBlue from '../assets/icons/landmarks/diamond-blue.svg';
import diamondPurple from '../assets/icons/landmarks/diamond-purple.svg';
import diamondWhite from '../assets/icons/landmarks/diamond-white.svg';
import diamondYellow from '../assets/icons/landmarks/diamond-yellow.svg';
import squareBlue from '../assets/icons/landmarks/square-blue.svg';
import assetIconBackground from '../assets/icons/assets/asset-icon-background.png';
import squarePurple from '../assets/icons/landmarks/square-purple.svg';
import squareWhite from '../assets/icons/landmarks/square-white.svg';
import squareYellow from '../assets/icons/landmarks/square-yellow.svg';
import starBlue from '../assets/icons/landmarks/star-blue.svg';
import starPurple from '../assets/icons/landmarks/star-purple.svg';
import starWhite from '../assets/icons/landmarks/star-white.svg';
import starYellow from '../assets/icons/landmarks/star-yellow.svg';
import seatBelt from '../assets/icons/alerts/seat_belt.svg';
import engineOil from '../assets/icons/alerts/engine_oil.svg';
import speedLimit from '../assets/icons/alerts/speed_limit.svg';
import tirePressure from '../assets/icons/alerts/tire_pressure.svg';
import fueltank from '../assets/icons/alerts/fueltank.svg';
import boatGreen from '../assets/icons/assetIcons/boat-green.png';
import busGreen from '../assets/icons/assetIcons/bus-green.png';
import carGreen from '../assets/icons/assetIcons/car-green.png';
import cementTruckGreen from '../assets/icons/assetIcons/cement-truck-green.png';
import dumpTruckGreen from '../assets/icons/assetIcons/dump-truck-green.png';
import excavatorGreen from '../assets/icons/assetIcons/excavator-green.png';
import flatBedGreen from '../assets/icons/assetIcons/flat-bed-green.png';
import generatorGreen from '../assets/icons/assetIcons/generator-green.png';
import motorcycleGreen from '../assets/icons/assetIcons/motorcycle-green.png';
import snowmobileGreen from '../assets/icons/assetIcons/snowmobile-green.png';
import tractorGreen from '../assets/icons/assetIcons/tractor-green.png';
import trailerGreen from '../assets/icons/assetIcons/trailer-green.png';
import truckGreen from '../assets/icons/assetIcons/truck-green.png';
import vanGreen from '../assets/icons/assetIcons/van-green.png';
import { getAppUserSettings, isPairedAsset } from '../helper-classes/utility-functions';
import maintenance from '../assets/icons/trips/maintenance.svg';
import hardBraking from '../assets/icons/alerts/brake_warning.svg';
import ruler from '../assets/icons/alerts/ruler.svg';
import powerDisconnected from './../assets/icons/trips/disconnected-filled.svg';

import iconPurple from './../assets/icons/cluster/icon_purple.svg';
import iconGreen from './../assets/icons/cluster/icon_green.svg';
import iconOrange from './../assets/icons/cluster/icon_orange.svg';
import iconRed from './../assets/icons/cluster/icon_red.svg';
import stopFlag from './../assets/icons/trips/stopFlag.svg';

import { mapColor } from '../containers/constants.map';

const MAXIMUM_EVENT_BUCKETS = 21;
export function getImage(imageName) {
    switch (imageName) {
    case 'IGN_ON': return (
        startImage
    );
    case 'location': return locationImage;
    case 'location_cluster': return locationImageCluster;
    case 'location_cluster_Large': return locationImageClusterLarge;
    case 'asset_icon_backround': return assetIconBackground;
    case 'asset': return assetImage;
    case 'equipment': return equipmentAssetImage;
    case 'trailer': return trailerAssetImage;
    case 'vehicle': return vehicleAssetImage;
    case 'resetToLandmarks': return ResetToLandmarks;
    case 'resetToVehicles': return ResetToVehicles;
    case 'TEMPERATURE_LOW': return temperature;
    case 'shape': return shapeImage;
    case 'truckImageBlue': return TruckImageBlue;
    case 'IGN_OFF': return stopImage;
    case 'STOP': return stopImage;
    case 'SPEED_THRESHOLD': return speedImage;
    case 'airport': return airport
    case 'gas-station': return gasStation;
    case 'high-rise': return highRise;
    case 'house': return house;
    case 'office-1': return office1;
    case 'office-2': return office2;
    case 'office-3': return office3;
    case 'oil-tower': return oilTower;
    case 'diamond-blue': return diamondBlue;
    case 'diamond-purple': return diamondPurple;
    case 'diamond-white': return diamondWhite;
    case 'diamond-yellow': return diamondYellow;
    case 'square-blue': return squareBlue;
    case 'square-purple': return squarePurple;
    case 'square-yellow': return squareYellow;
    case 'square-white': return squareWhite;
    case 'star-blue': return starBlue;
    case 'star-purple': return starPurple;
    case 'star-white': return starWhite;
    case 'star-yellow': return starYellow;
    case 'boat-green': return boatGreen;
    case 'bus-green': return busGreen;
    case 'car-green': return carGreen;
    case 'cement-truck-green': return cementTruckGreen;
    case 'dump-truck-green': return dumpTruckGreen;
    case 'excavator-green': return excavatorGreen;
    case 'flat-bed-green': return flatBedGreen;
    case 'generator-green': return generatorGreen;
    case 'motorcycle-green': return motorcycleGreen;
    case 'snowmobile-green': return snowmobileGreen;
    case 'tractor-green': return tractorGreen;
    case 'trailer-green': return trailerGreen;
    case 'truck-green': return truckGreen;
    case 'van-green': return vanGreen;
    case 'ARRIVAL_R_DEPARTURE': return enterFilled;
    case 'calendarIcon': return calendarIcon;
    case 'stopFlag': return stopFlag;
    case 'HOURS_SPEND': return oddHours;
    case 'ODD_HOURS': return oddHours;
    case 'truck': return TruckImage;
    case 'Vehicle-Light Duty': return TruckImage;
    case 'Movement': return TruckImage;
    case 'goodBattery': return goodBattery;
    case 'goodBatteryNew': return goodBatteryNew;
    case 'goodCellular': return goodCellular;
    case 'excellentCellular': return excellentCellular;
    case 'goodGps': return goodGps;
    case 'batteryWarning': return batteryWarning;
    case 'batteryWarningNew': return batteryWarningNew;
    case 'fairCellular': return fairCellular;
    case 'fairGps': return fairGps;
    case 'batteryLow': return batteryLow;
    case 'batteryLowNew': return batteryLowNew;
    case 'badCellular': return badCellular;
    case 'badGps': return badGps;
    case 'batteryNotReporting': return batteryNotReporting;
    case 'batteryNotReportingNew': return batteryNotReportingNew;
    case 'MOVE_PER': return startImage;
    case 'IDLE_PER': return IdelPar;
    case 'INPUT': return Input;
    case 'satelliteGood': return satelliteGood;
    case 'satelliteExcellent': return satelliteExcellent;
    case 'satelliteFair': return satelliteFair;
    case 'satellitePoor': return satellitePoor;
    case 'satelliteNone': return satelliteNone;
    case 'satelliteGoodNew': return satelliteGoodNew;
    case 'satelliteFairNew': return satelliteFairNew;
    case 'satellitePoorNew': return satellitePoorNew;
    case 'satelliteNoneNew': return satelliteNoneNew;
    case 'MOVE_STOP': return stopImage;
    case 'AUTO_LOC': return locationImage;
    case 'iconPurple': return iconPurple;
    case 'iconGreen': return iconGreen;
    case 'iconOrange': return iconOrange;
    case 'iconRed': return iconRed;
    default: return '';
    }
}

export function getName(name) {
    switch (name) {
    case 'IGN_ON': return (
        'Start'
    );
    case 'IGN_ON_EVT': return (
        'Ignition On'
    );
    case 'STOP': return (
        'Stop'
    );
    case 'IDLE_PER': return (
        'Idle'
    );
    case 'MOVE_PER': return (
        'Movement'
    );
    case 'SPEED_THRESHOLD': return (
        'Speed Threshold'
    );
    case 'TEMPERATURE_LOW': return (
        'Tempreture Alert'
    );
    case 'IGN_OFF': return (
        'Stop'
    );
    case 'IGN_OFF_EVT': return (
        'Ignition Off'
    );
    case 'ODD_HOURS': return (
        'Odd Hours'
    );
    case 'UNAUTHORIZED_MOVEMENT': return (
        'Unauthorized Movement'
    );
    case 'HARD_BREAKING': return (
        'Hard Breaking'
    );
    case 'TIME_PERIOD': return (
        'Time Period'
    );
    case 'DISTANCE': return (
        'Distance'
    );
    case 'ARRIVAL_DEPARTURE': return (
        'Arrival & Departure'
    );
    case 'INPUT': return (
        'Input'
    );
    case 'ARRIVAL_R_DEPARTURE': return (
        'Arrival & Departure'
    );
    case 'AUTO_LOC': return (
        'Auto Locate'
    );
    case 'DECEL': return (
        'Deceleration'
    );
    case 'ACCEL': return (
        'Acceleration'
    );
    case 'ADMIN_LOCATE': return (
        'Locate'
    );
    case 'DOOR_LOCK': return (
        'Door Lock'
    );
    case 'DOOR_UNLOCK': return (
        'Door Unlock'
    );
    case 'EXT_PWR_LOW': return (
        'Low Power'
    );
    case 'EXT_PWR_OFF': return (
        'Power Disconnected'
    );
    case 'EXT_PWR_ON': return (
        'Power Connected'
    );
    case 'FRIDGE_OFF': return (
        'Fridge Off'
    );
    case 'FRIDGE_ON': return (
        'Fridge On'
    );
    case 'GEO_ENTER': return (
        'Geofence Enter'
    );
    case 'GEO_EXIT': return (
        'Geofence Exit'
    );
    case 'IDLE_START': return (
        'Idle Start'
    );
    case 'IDLE_STOP': return (
        'Idle Stop'
    );
    case 'INPUT_HIGH': return (
        'Input High'
    );
    case 'INPUT_LOW': return (
        'Input Low'
    );
    case 'INPUT_TOGGLE': return (
        'Input Toggle'
    );
    case 'INT_BAT_LOW': return (
        'Low Internal Battery'
    );
    case 'MAINTENANCE': return (
        'Maintenance'
    );
    case 'MOTION': return (
        'Motion Detected'
    );
    case 'MOVE_START': return (
        'Movement Start'
    );
    case 'MOVE_STOP': return (
        'Movement Stop'
    );
    case 'ODM_TRIP': return (
        'Odometer Trip'
    );
    case 'ODOMETER_READING': return (
        'Odometer Reading'
    );
    case 'POWER_SAVE_ON': return (
        'Power Save On'
    );
    case 'PWR_UP': return (
        'Power Up'
    );
    case 'SEATBELT_OFF': return (
        'Seat Belt Unlatched'
    );
    case 'SEATBELT_ON': return (
        'Seat Belt Latched'
    );
    case 'SENSOR_CARGO': return (
        'Cargo Update'
    );
    case 'SENSOR_CARGO_STATUS': return (
        'Sensor Cargo Status'
    );
    case 'SLEEP_ENTER': return (
        'Sleep On'
    );
    case 'SLEEP_EXIT': return (
        'Sleep Off'
    );
    case 'SPEED': return (
        'Speed Update'
    );
    case 'STARTER_DIS': return (
        'Starter Disabled'
    );
    case 'STARTER_ENA': return (
        'Starter Enabled'
    );
    case 'TAMPER': return (
        'Tamper Alert'
    );
    case 'TEMPERATURE': return (
        'Temperature'
    );
    case 'UNAUTH_MOV': return (
        'Unauthorized Movement'
    );
    case 'USER_LOC': return (
        'User Locate'
    );

    default: return name;
    }
}

export function getAbrivationName(name) {
    switch (name) {
    case 'IGN_ON': return (
        'Start'
    );
    case 'STOP': return (
        'Stop.'
    );
    case 'IDLE_PER': return (
        'Idle Par .'
    );
    case 'MOVE_PER': return (
        'Movement'
    );
    case 'SPEED_THRESHOLD': return (
        'Speed'
    );
    case 'TEMPERATURE_LOW': return (
        'Tempreture Alert .'
    );
    case 'IGN_OFF': return (
        'Stop'
    );
    case 'ODD_HOURS': return (
        'Odd Hours .'
    );
    case 'UNAUTHORIZED_MOVEMENT': return (
        'Odd Hours .'
    );
    case 'HARD_BREAKING': return (
        'Hard Breaking .'
    );
    case 'TIME_PERIOD': return (
        'Hard Breaking .'
    );
    case 'DISTANCE': return (
        'Distance .'
    );
    case 'ARRIVAL_DEPARTURE': return (
        'Distance'
    );
    case 'INPUT': return (
        'Input .'
    );
    case 'ARRIVAL_R_DEPARTURE': return (
        'Arrival & Departure .'
    );
    default: return 'Undefined';
    }
}
export function mapZoomFunction(largeIcon, id) {
    const selectedWay = largeIcon.map(dataC => (
        {
            id: dataC.id,
            alertId: dataC.id,
            tripId: dataC.tripId,
            durationInMins: dataC.durationInMins,
            distance: dataC.distance,
            landMark: dataC.landMark,
            heading: dataC.heading,
            voltage: dataC.voltage,
            rssi: dataC.rssi,
            satellite: dataC.satellite,
            type: dataC.type,
            alertCount: dataC.alertCount,
            alertValue: dataC.alertValue,
            typeDesc: dataC.typeDesc,
            asset: dataC.asset,
            details: dataC.details,
            operator: dataC.operator,
            size: dataC.id === id ? 'large' : 'small',
            lat: dataC.lat,
            lng: dataC.lng,
            location: dataC.location,
            date: dataC.date,
            minit: dataC.date.substr(11, 5),
            status: 'Moving',
        }
    ));
    return selectedWay;
}
export function selectWayPoint(wayPoint) {
    const selectedWay = wayPoint.map((trip, key) => (

        {
            id: wayPoint[key].id || '',
            tripId: wayPoint[key].tripId || '',
            alerts: wayPoint[key].alerts || '',
            durationInMins: wayPoint[key].durationInMins || 0,
            distance: wayPoint[key].distance || 0,
            landMark: wayPoint[key].landMark || '',
            heading: wayPoint[key].heading,
            voltage: wayPoint[key].voltage,
            rssi: wayPoint[key].rssi,
            satellite: wayPoint[key].satellite,
            type: key === 0 || key === wayPoint.length ?
                wayPoint[key].type : trip.type,
            asset: wayPoint[key].asset,
            operator: wayPoint[key].operator || '',
            lat: wayPoint[key].location !== 'undefined' ? wayPoint[key].location.latitude : 0,
            lng: wayPoint[key].location !== 'undefined' ? wayPoint[key].location.longitude : 0,
            location: wayPoint[key].location,
            date: wayPoint[key].date,
            status: 'Moving',
            size: 'small',
            total: wayPoint[key].total || 0,
        }
    ));
    return selectedWay;
}
export function selectMapValue(alerts, data) {
    const finalAray = [];
    if (data.length !== 0) {
        finalAray.push(data[0]);
    }

    alerts.forEach((trip, key) => {
        if (alerts[key].length > 0) {
            trip.forEach((d) => {
                finalAray.push(d);
            });
        }
    });
    return finalAray;
}
const colourArray = ['#1f3a93', '#9b59b6', '#e91e63', '#eb974e', '#d50000'] || [];
let id = 0;
const resetId = 0;
export function ramdomColor() {
    const selectId = id > 4 ? resetId : id;
    id += 1;
    id = id > 4 ? resetId : id;
    return colourArray[selectId];
}
export function getColor(colorCode) {
    switch (colorCode) {
    case 'Idle': return (
        '#f57c00'
    );
    case 'Input': return (
        '#4caf50'
    );
    case 'Vehicle-Light Duty': return (
        '#2196f3'
    );
    case 'Arrival/Departure': return (
        '#00bcd4'
    );
    case 'IGN_ON': return (
        '#009688'
    );
    case 'IGN_OFF': return (
        '#bf360c'
    );
    case 'Maintenance Engine Hours': return (
        '#4caf50'
    );
    case 'HOURS_SPEND': return (
        '#8bc34a'
    );
    case 'Speed Threshold': return (
        '#1f3a93'
    );
    case 'STOP': return (
        '#bf360c'
    );
    case 'Unauthorized Movement': return (
        '#d50000'
    );

    case 'Hard Braking/Acceleration': return (
        '#e91e63'
    );

    case 'TEMPERATURE_LOW': return (
        '#9c27b0'
    );

    case 'Low Battery': return (
        '#8bc34a'
    );

    case 'SPEED_THRESHOLD': return (
        '#1f3a93'
    );
    case 'IDLE_PER': return (
        '#8bc34a'
    );
    case 'ODD_HOURS': return (
        '#e91e63'
    );
    case 'ARRIVAL_R_DEPARTURE': return (
        '#2196f3'
    );
    case 'INPUT': return (
        '#e91e63'
    );
    default: return ramdomColor();
    }
}

export function calculateMiles(data) {
    if (!data) {
        return 0;
    }
    return parseFloat(data * 0.000621371192).toFixed(2);
}

export const calculateTimeInHrs = (timeInMin = '') => {
    let timeInHrs = '';
    if ((timeInMin || 0) > 60) {
        timeInHrs = `${Math.floor((timeInMin || 0) / 60)} hr ${(timeInMin || 0) % 60} min`;
    } else {
        timeInHrs = `${(timeInMin || 0)} min`;
    }
    return timeInHrs;
} 

export function processWayPoints(events, trip) {
    const durationInMins = trip.durationInMins;
    const bucketSlots = durationInMins / 21; //204
    const startDate = events.length > 0 ? moment(events[0].date) : '';
    const finalArray = new Array(21).fill({});
    let prev = '';
    events.forEach((event, index) => {
        if (index > 0) {
            const date = moment(event.date);
            const timeDifference = moment.duration(date.diff(startDate));
            const minutes = Math.ceil(timeDifference.asMinutes() / bucketSlots);
            finalArray[minutes] = event;
        }
        prev = event.date;
    });
    const array = [];
    // finalArray.push(events[events.length - 1]); coomented for future ref
    // finalArray.unshift(events[0]);
    finalArray.forEach((a) => {
        if(a && a.lat) {
            array.push(a);
        }
    });
    return array;
}
export function getAlertProperties(alert, type = false) {
    let code = '';
    if (type) {
        code = type;
    }else if (alert.alertTypeCode) {
        code = alert.alertTypeCode;
    } else if (alert.alert_Type_Code) { //temporary fix will remove later
        code = alert.alert_Type_Code;
    }
    switch (code) {
        case 'localFleetLandmarkArrivalDepartureAlertAlert': return (
            {
                name: 'Landmark Arrival & Departure',
                icon: enterFilled,
                type: 'ARRIVAL_R_DEPARTURE',
                code,
                color: '#00bcd4',
                value: alert.alertData && alert.alertData.direction ?
                    alert.alertData.direction : '',
                unit: '',
                style: {
                    left: -3,
                    top: -25,
                    width: 18,
                }
            }
        );
        case 'localFleetLandmarkArrivalDepartureDriverAlert': return (
            {
                name: 'Driver Arrival & Departure',
                icon: enterFilled,
                type: 'ARRIVAL_R_DEPARTURE_DRIVER',
                code,
                color: '#00bcd4',
                value: alert.alertData && alert.alertData.direction ?
                    alert.alertData.direction : '',
                unit: '',
                style: {
                    left: -3,
                    top: -25,
                    width: 18,
                }
            }
        );
        case 'localFleetMaintenanceAlertDistanceAlert': return (
            {
                name: 'Driver Arrival & Departure',
                icon: enterFilled,
                type: 'DISTANCE',
                code,
                color: '#1f3a93',
                value: alert.alertData && alert.alertData.direction ?
                    alert.alertData.direction : '',
                unit: '',
                style: {
                    left: -3,
                    top: -25,
                    width: 18,
                }
            }
        );
        case 'localFleetMaintenanceAlertEngineHoursAlert': return (
            {
                name: 'Engine Hours',
                icon: engineOil,
                type: 'ENGINE_HOURS',
                code,
                color: '#1f3a93',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 20,
                }
            }
        );
        case 'localFleetEngineOilLifeAlert': return (
            {
                name: 'Engine Oil',
                icon: engineOil,
                type: 'ENGINE_OIL',
                code,
                color: '#a2b4c3',
                value: alert.alertData && alert.alertData.engineOilLife ?
                    alert.alertData.engineOilLife : '',
                unit: '%',
                style: {
                    left: -2,
                    top: -25,
                    width: 20,
                }
            }
        );
        case 'localFleetFuelTankAlert': return (
            {
                name: 'Fuel Tank',
                icon: fueltank,
                type: 'FUEL_TANK',
                code,
                color: '#e17ddd',
                value: alert.alertData && alert.alertData.fuelLevel ?
                    alert.alertData.fuelLevel : '',
                unit: '%',
                style: {
                    left: -2,
                    top: -25,
                    width: 18,
                }
            }
        );
        case 'localFleetHardBrakingAccelerationAlert': return (
            {
                name: 'Hard Braking',
                icon: hardBraking,
                type: 'HARD_BRAKING',
                code,
                color: '#9c27b0',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 20,
                }
            }
        );
        case 'localFleetHardBrakingAccelerationDriverAlert': return (
            {
                name: 'Hard Braking Driver',
                icon: hardBraking,
                type: 'HARD_BRAKING_DRIVER',
                code,
                color: '#9c27b0',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 20,
                }
            }
        );
        case 'localFleetIdleAlertAlert': return (
            {
                name: 'Idle',
                icon: IdelPar,
                type: 'IDLE',
                code,
                color: '#f57c00',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 18,
                }
            }
        );
        case 'localFleetIdleDriverAlert': return (
            {
                name: 'Idle Driver',
                icon: IdelPar,
                type: 'IDLE_DRIVER',
                code,
                color: '#f57c00',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 18,
                }
            }
        );
        case 'localFleetInputAlert': return (
            {
                name: 'Input',
                icon: Input,
                type: 'INPUT',
                code,
                color: '#2196f3',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 18,
                }
            }
        );
        case 'localFleetInputDriverAlert': return (
            {
                name: 'Input Driver',
                icon: Input,
                type: 'INPUT_DRIVER',
                code,
                color: '#2196f3',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 18,
                }
            }
        );
        case 'localFleetLowBatteryAlert': return (
            {
                name: 'Low Battery',
                icon: batteryLow,
                type: 'LOW_BATTERY',
                code,
                color: '#6d4c41',
                value: alert.alertData && alert.alertData.voltageValue ?
                    alert.alertData.voltageValue : '',
                unit: 'v',
                style: {
                    left: -2,
                    top: -25,
                    width: 18,
                }
            }
        );
        case 'maintenanceAlert': return (
            {
                name: 'Maintenance',
                icon: maintenance,
                type: 'MAINTENANCE',
                code,
                color: '#009688',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 16,
                }
            }
        );
        case 'localFleetMaintenanceAlertDistanceAlert': return (
            {
                name: 'Maintenance Distance',
                icon: maintenance,
                type: 'MAINTENANCE_DISTANCE',
                code,
                color: '#009688',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 16,
                }
            }
        );
        case 'localFleetOddHoursAlertAlert': return (
            {
                name: 'Odd Hours',
                icon: oddHours,
                type: 'ODD_HOURS',
                code,
                color: '#3f51b5',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 16,
                }
            }
        );
        case 'localFleetOddHoursDriverAlert': return (
            {
                name: 'Odd Hours Driver',
                icon: oddHours,
                type: 'ODD_HOURS_DRIVER',
                code,
                color: '#3f51b5',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 16,
                }
            }
        );
        case 'localFleetStopAlertAlert': return (
            {
                name: 'Stop',
                icon: stopImage,
                type: 'STOP',
                code,
                color: '#f44336',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 18,
                }
            }
        );
        case 'localFleetStopDriverAlert': return (
            {
                name: 'Stop Driver',
                icon: stopImage,
                type: 'STOP_DRIVER',
                code,
                color: '#f44336',
                style: {
                    left: -2,
                    top: -25,
                    width: 18,
                }
            }
        );
        case 'localFleetSeatbeltAlert': return (
            {
                name: 'Seat Belt',
                icon: seatBelt,
                type: 'SEAT_BELT',
                code,
                color: '#7f4dff',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 16,
                }
            }
        );
        case 'localFleetSpeedThresholdAlertAlert': return (
            {
                name: 'Speed Threshold',
                icon: speedImage,
                type: 'SPEED_THRESHOLD',
                code,
                color: '#ff5722',
                value: alert.alertData && alert.alertData.speedThreshold ?
                    alert.alertData.speedThreshold : '',
                unit: 'MPH',
                style: {
                    left: -2,
                    top: -25,
                    width: 18,
                }
            }
        );
        case 'localFleetSpeedThresholdDriverAlert': return (
            {
                name: 'Speed Threshold Driver',
                icon: speedImage,
                type: 'SPEED_THRESHOLD_DRIVER',
                code,
                color: '#ff5722',
                value: alert.alertData && alert.alertData.speedThreshold ?
                    alert.alertData.speedThreshold : '',
                unit: 'MPH',
                style: {
                    left: -2,
                    top: -25,
                    width: 18,
                }
            }
        );
        case 'localFleetSpeedLimitAlertAlert': return (
            {
                name: 'Speed Limit',
                icon: speedLimit,
                type: 'SPEED_LIMIT',
                code,
                color: '#fbc02d',
                value: alert.alertData && alert.alertData.zoneSpeed ?
                    alert.alertData.zoneSpeed : '',
                unit: 'MPH',
                style: {
                    left: -2,
                    top: -25,
                    width: 20,
                }
            }
        );
        case 'localFleetSpeedLimitDriverAlert': return (
            {
                name: 'Speed Limit Driver',
                icon: speedLimit,
                type: 'SPEED_LIMIT_DRIVER',
                code,
                color: '#fbc02d',
                value: alert.alertData && alert.alertData.zoneSpeed ?
                    alert.alertData.zoneSpeed : '',
                unit: 'MPH',
                style: {
                    left: -2,
                    top: -25,
                    width: 20,
                }
            }
        );
        case 'localFleetTemperatureAlertAlert': return (
            {
                name: 'Temperature',
                icon: temperature,
                type: 'TEMPERATURE',
                code,
                color: '#673ab7',
                value: '',
                unit: '(ºF)',
                style: {
                    left: -2,
                    top: -25,
                    width: 11,
                }
            }
        );
        case 'localFleetMaintenanceAlertTimePeriodAlert': return (
            {
                name: 'Time period',
                icon: ruler,
                type: 'TIME_PERIOD',
                code,
                color: '#4caf50',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 19,
                }
            }
        );
        case 'localFleetTirePressureAlert': return (
            {
                name: 'Tire Pressure',
                icon: tirePressure,
                type: 'TIRE_PRESSURE',
                code,
                color: '#4caf50',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 18,
                }
            }
        );
        case 'localFleetPowerReconnectAlert': return (
            {
                name: 'Power Reconnect',
                icon: powerDisconnected,
                type: code,
                code,
                color: '#2196f3',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 16,
                }
            }
        );
        case 'localFleetPowerDisconnectAlert': return (
            {
                name: 'Power Disconnect',
                icon: powerDisconnected,
                type: code,
                code,
                color: '#2196f3',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 16,
                }
            }
        );
        case 'devicePowerDisconnectAlert': return (
            {
                name: 'Power Disconnect',
                icon: powerDisconnected,
                type: code,
                code,
                color: '#2196f3',
                value: '',
                unit: '',
                style: {
                    left: -2,
                    top: -25,
                    width: 16,
                }
            }
        );
        default: return {
            name: code,
            icon: oddHours,
            type: code,
            code,
            color: '',
            value: '',
            unit: '',
            style: {
                left: -2,
                top: -25,
                width: 15,
            }
        };
    }
}

export const getAssetIconFromType = (type) => {
    const imageData = { image: '', style: {} };

    switch (getAssetIconType(type)) {
    case 'heavy': {
        imageData.image = TruckImageCluster;
        imageData.style = { width: 19 };
        break;
    }
    case 'equipment': {
        imageData.image = ExcavatorImageCluster;
        imageData.style = { width: 22, top: -16 };
        break;
    }
    case 'trailer': {
        imageData.image = TrailerImageCluster;
        imageData.style = { width: 19, top: -16 };
        break;
    }
    case 'medium': {
        imageData.image = AssetImageCluster;
        imageData.style = { width: 19, top: -16 };
        break;
    }
    default: {
        imageData.image = AssetImageCluster;
        imageData.style = { width: 22 };
        break;
    } // light & default avatar
    }
    return imageData;
};

export function getAssetNameFromType(type) {
    if (type) {
        // this calculation is as per some assumption based on current api response
        const parsedType = type.split('.').slice(-1)[0];
        if (parsedType === 'heavy') {
            return 'Heavy';
        } else if (parsedType === 'equipment') {
            return 'Equipment';
        } else if (parsedType === 'trailer') {
            return 'Trailer';
        }
    }
    return 'Light'; // stopImage; // light & default avatar
}

export const getAssetColorFromStatus = (asset, checkPaired = true) => {
    const status = (asset && asset.status) ? asset.status.toLowerCase() : '';
    let color = mapColor.cluster.stopped;
    if (!isPairedAsset(asset) && checkPaired) {
        color = mapColor.cluster.notreporting;
    } else if (asset.stoppedCount || status === 'stopped') {
        color = mapColor.cluster.stopped;
    } else if (asset.idleCount || status === 'idle') {
        color = mapColor.cluster.idle;
    } else if (asset.movingCount || status === 'moving') {
        color = mapColor.cluster.moving;
    }

    return color;
};

export const getAssetLabelColor = (d,color) => {
    return d.attributes && d.attributes.labelColor ? d.attributes.labelColor : color;
}

export const getAssetColorFromEventTypeCode = (eventTypeCode) => {
    let color = mapColor.cluster.notreporting;
    if (eventTypeCode === 'STOPPED') {
        color = mapColor.cluster.stopped;
    } else if (eventTypeCode === 'IDLE_PER') {
        color = mapColor.cluster.idle;
    } else if (eventTypeCode === 'MOVE_PER') {
        color = mapColor.cluster.moving;
    }
    return color;
};

export function getConvertedStartDate(date, type = 'start') {
    const tFormat = "YYYY-MM-DDTHH:mm:ss";
    let timeZone  = moment.tz.guess();
    const userSettings = getAppUserSettings();
    if (userSettings.userTz) {
        if (userSettings.userTz !== '') {
            timeZone = userSettings.userTz;
        }
    }

    let tempDateTime = moment(date).tz(timeZone);

    if (type === 'start') {
        tempDateTime = tempDateTime.startOf('date');
    } else if (type === 'end') {
        tempDateTime = tempDateTime.endOf('date');
    }

    return moment.utc(tempDateTime).format(tFormat);
}

export function getConvertedEndDate(date) {
    return getConvertedStartDate(date, 'end');    
}

export function getConvertedStartDateValue(date) {
    const tFormat = "YYYY-MM-DDTHH:mm:ss";
    let timeZone = moment.tz.guess();
    const userSettings = getAppUserSettings();
    if (userSettings.userTz) {
        if (userSettings.userTz !== '') {
            timeZone = userSettings.userTz;
        }
    }

    let tempDateTime = moment(date).format(tFormat);
    
    tempDateTime = moment.tz(tempDateTime, timeZone);
    return moment.utc(tempDateTime).format(tFormat);
}

export function getConvertedEndDateValue(date) {
    return getConvertedStartDateValue(date);
}

export function getDateRangeDropdownValue(value) {
    let date = '';
    if (value === '7') {
        date = 'Last 7 Days';
    } else if (value === '30') {
        date = 'Last 30 Days';
    } else if (value === '396') {
        date = 'Last 13 Months';
    } else if (value === 'lastMonth') {
        date = 'Last Month';
    } else if (value === '90') {
        date = 'Last 3 Months';
    } else if (value === '365') {
        date = 'Last Year';
    }
    return date;
}
