/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { Button, Divider, FormGroup, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { reduxConnect } from '../../../hoc/index';
import * as actions from './actions.savedAlerts';
import * as alertTypeActions from '../AlertLandingPage/actions.landingPage';
import MiniDrawer from '../../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../../components/SharedComponents/ListComponent/ListComponent';
import Dialog from '../../../components/SharedComponents/Dialog/Dialog';
import { filterAlerts, filterAlertsCode } from '../../../util/mapAlertTypes';
import AlertSteps from './../Steppers/AlertSteps';
import { hasFeature } from '../../../helper-classes/utility-functions';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    rearrangeColumns,
    isUpdatePreference,
} from '../../../helper-classes/listUtil';
import { SAVED_ALERT_LIST_PREF_KEY } from './constants.savedAlerts';
import AppLoader from '../../../components/AppLoader';
import analytics from '../../../analytics';

const themeStyles = () => ({
    button: { margin: '0 10px' },
    hide: { display: 'none' },
});

export type Props = {
    isUpdating: boolean,
    fetchSavedAlerts: Function,
    resetTable: Function,
    classes: Object,
    history: {
        push: Function,
    },
    deleteSavedAlert: Function,
    alertTypes: any,
    fetchAlertNameList: Function,
    match: {
        path: any,
    },
};

export type State = {
    tableData: {
        listHeaders: Array<{}>,
        listData: Array<{}>,
        totalRecords: number,
    },
    rowsPerPage: number,
    pageNumber: number,
    order: string,
    orderBy: string,
    savedAlertsFiltersToSend: any,
    selectedAlert: string,
    dialogActionType: string,
    openDialog: boolean,
    filterAlertType: any,
    isAddEdit: boolean,
    showListing: boolean,
};

class SavedAlerts extends Component<Props, State> {
    unitText: string;
    button: Array<Object>;
    id: string;
    addUrl: string;
    listUrl: string;
    addUrl: string;
    userPreferences: any;
    constructor(props: Props) {
        super(props);
        let isAddEdit = false;
        this.addUrl = '/alert-types/create-alert';
        this.listUrl = '/saved-alerts';
        if (this.props.match.path === this.addUrl) isAddEdit = true;

        this.state = {
            tableData: {
                listHeaders: this.getListHeader(),
                ischeckBox: false,
                isEditable: true,
                addDelete: true,
                listData: [],
                totalRecords: 0,
            },
            rowsPerPage: 1000,
            pageNumber: 0,
            order: 'desc',
            orderBy: 'dateCreated',
            savedAlertsFiltersToSend: this.initialFilter(),
            selectedAlert: '',
            dialogActionType: '',
            openDialog: false,
            filterAlertType: '',
            isAddEdit,
            showListing: false,
        };
        this.button = [];
    }

    UNSAFE_componentWillMount() {
        if (!this.state.isAddEdit) getUserPreference(this, SAVED_ALERT_LIST_PREF_KEY);
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        const { SavedAlertsList } = nextprops;
        if (SavedAlertsList !== undefined) {
            let { tableData } = this.state;
            if (SavedAlertsList.length > 0) {
                const filteredSavedAlert = filterAlertsCode(SavedAlertsList);
                tableData.listData = filteredSavedAlert;
                tableData.totalRecords = 1000;
                tableData = this.sortData(this.state.order, this.state.orderBy, tableData);
                this.setState({ tableData });
            } else if (!this.state.isAddEdit) {
                tableData.listData = [];
                tableData.totalRecords = 0;
                this.setState({ tableData });
            }

            if (nextprops.isAlertDeleted === true) {
                this.setState({
                    dialogActionType: 'deleted',
                    openDialog: true,
                });
                this.props.fetchSavedAlerts(
                    this.state.pageNumber,
                    this.state.rowsPerPage,
                    this.state.order,
                    this.state.orderBy,
                    this.state.savedAlertsFiltersToSend,
                    false,
                );
                analytics.track('DELETE_ALERT', { feature: 'Alerts' });
            }
        }
    }

    componentDidUpdate() {
        let { isAddEdit } = this.state;
        if (isAddEdit && this.props.match.path === this.listUrl) {
            isAddEdit = false;
            this.updateEditState(isAddEdit);
            getUserPreference(this, SAVED_ALERT_LIST_PREF_KEY);
        } else if (!isAddEdit &&
            this.props.match.path === this.addUrl) {
            isAddEdit = true;
            this.updateEditState(isAddEdit);
        }
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            { name: 'Alert Name', ...getColumnPreferences('name', preferences) },
            { alertTypeName: 'Alert Type ', ...getColumnPreferences('alertTypeName', preferences), disableSort: true },
            { dateCreated: 'Date Created', ...getColumnPreferences('dateCreated', preferences) },
            { recipients: 'Recipients', ...getColumnPreferences('recipients', preferences), disableSort: true },
        ];
        if (preferences) return getReArrangedTableHeaders(columns);
        return columns;
    }

    initialFilter = () => {
        const savedAlertsFiltersToSend = [{
            property: 'active',
            value: true,
            type: 'Boolean',
        }, {
            property: 'alertType.code',
            value: 'maintenanceAlert',
            comparison: 'ne',
        }];

        if (!hasFeature('fleetMaintenanceDtc')) {
            savedAlertsFiltersToSend.push({
                property: 'alertType.code',
                value: 'maintenanceDtcAlert',
                comparison: 'ne',
            });
        }
        return encodeURIComponent(JSON.stringify(savedAlertsFiltersToSend));
    };

    updateEditState = isAddEdit => this.setState({ isAddEdit, showListing: false });

    // this function will be called from listUtils
    getData = () => {
        this.props.fetchSavedAlerts(
            this.state.pageNumber,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.state.savedAlertsFiltersToSend,
            false,
        );
        this.props.fetchAlertNameList();
    }

    isOverdue = (x: number) => {
        if (x < 0) return <font color="red">{x}</font>;
        return x;
    };

    isTimeOverdue = (time: string) => {
        if (time && time.charAt(0) === '-') {
            return <font color="red">{time}</font>;
        }
        return time;
    };

    clickRow = (n: any, type: string) => {
        if (type === undefined) {
            this.setState({ selectedAlert: n.id });
            this.props.history.push(`${this.addUrl}?alertID=${n.id}`);
        } else {
            this.setState({
                selectedAlert: n.id,
                dialogActionType: 'delete',
                openDialog: true,
            });
        }
    };

    updateState = (order, orderBy) => this.setState({
        order,
        orderBy,
    }, () => {
        saveUserPreference(this, SAVED_ALERT_LIST_PREF_KEY);
    });

    sortData = (order, orderBy, tableData) => {
        if (orderBy === 'dateCreated') return tableData;
        tableData.listData.sort((a, b) => {
            if (order === 'desc') {
                if (a[orderBy] < b[orderBy]) return -1;
                else if (a[orderBy] > b[orderBy]) return 1;
                return 0;
            }

            if (b[orderBy] < a[orderBy]) return -1;
            else if (b[orderBy] > a[orderBy]) return 1;
            return 0;
        });
        return tableData;
    }

    sortTableColumn = (order, orderBy) => {
        this.updateState(order, orderBy);
        if (orderBy === 'dateCreated') {
            this.props.fetchSavedAlerts(
                this.state.pageNumber,
                this.state.rowsPerPage,
                order,
                orderBy,
                this.state.savedAlertsFiltersToSend,
                false,
            );
        } else {
            const tableData = this.sortData(order, orderBy, this.state.tableData);
            this.setState({ tableData });
        }
        const params = {
            feature: 'Alerts',
            sortColumn: orderBy,
        };
        analytics.track('SAVED_ALERTS_SORT', params);
    };

    loadNextPage = (rows, value, order, orderBy) => {
        const updateUserPref = isUpdatePreference(this, { rowsPerPage: value, order, orderBy });
        this.setState({
            pageNumber: 0,
            rowsPerPage: 1000,
            order,
            orderBy,
        }, () => {
            if (updateUserPref) saveUserPreference(this, SAVED_ALERT_LIST_PREF_KEY);
        });

        this.props.fetchSavedAlerts(
            this.state.pageNumber,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.state.savedAlertsFiltersToSend,
            false,
        );
    };

    handleDeleteConfirm = () => {
        this.setState({
            dialogActionType: '',
            openDialog: false,
        });
        this.props.deleteSavedAlert(this.state.selectedAlert);
    };

    getDialogContent = () => {
        const { dialogActionType } = this.state;
        let content = '';
        if (dialogActionType === 'delete') {
            content = 'Are you sure you want to delete?';
        } else if (dialogActionType === 'deleted') {
            content = 'Alert deleted successfully.';
        }
        return content;
    };

    getDialogType = () => {
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleDeleteConfirm(),
        }];
        const { dialogActionType } = this.state;
        let type = '';
        if (dialogActionType === 'delete') {
            type = 'confirm';
            this.button.splice(0, 0, { title: 'Cancel', color: 'primary', handleClick: () => this.setState({ dialogActionType: '', openDialog: false }) });
        } else if (dialogActionType === 'deleted') {
            type = 'success';
            this.button.splice(0, 0, { title: ' ', color: 'primary', handleClick: () => this.setState({ dialogActionType: '', openDialog: false }) });
        }
        return type;
    };

    handleFilterChange = e => this.setState({ filterAlertType: e.target.value }, () => {
        this.applyAlertHistoryFilters();
    });

    applyAlertHistoryFilters = () => {
        const alertType = parseInt(this.state.filterAlertType, 10);
        if (alertType !== undefined) {
            if (alertType !== 'null') {
                const filterv = [{
                    property: 'active',
                    value: true,
                    type: 'Boolean',
                }, {
                    type: 'Long',
                    property: 'alertType.id',
                    value: alertType,
                }];
                const savedAlertsFiltersToSend = encodeURIComponent(JSON.stringify(filterv));
                this.setState({ savedAlertsFiltersToSend });
                this.props.fetchSavedAlerts(
                    this.state.pageNumber,
                    this.state.rowsPerPage,
                    this.state.order,
                    this.state.orderBy,
                    savedAlertsFiltersToSend,
                    false,
                );
            }
        }
        analytics.track('SAVED_ALERTS_FILTER', { feature: 'Alerts' });
    };

    rearrangeColumns = listHeaders => rearrangeColumns(
        this, listHeaders,
        { prefKey: SAVED_ALERT_LIST_PREF_KEY },
    );

    clearFilter = () => {
        const savedAlertsFiltersToSend = this.initialFilter();
        this.setState({
            savedAlertsFiltersToSend,
            filterAlertType: '',
        });
        this.props.fetchSavedAlerts(
            this.state.pageNumber,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            savedAlertsFiltersToSend,
            false,
        );
    };

    renderAlertFilters = () => {
        const styles = {
            button: { flex: 'auto', opacity: '.87', color: '#007aff' },
            subheading: {
                fontSize: 15,
                opacity: '.87',
                padding: 20,
            },
            title: {
                flex: 'auto',
                fontSize: '20px',
                opacity: '.87',
                padding: 20,
            },
        };

        return (
            <div style={{ padding: 20 }}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" id="filterTitle" style={styles.title}>Filters</Typography>
                    <Button
                        color="primary"
                        style={styles.button}
                        onClick={() => this.clearFilter()}
                    >
                    CLEAR
                    </Button>
                </div>
                <Divider />
                <FormGroup row key={Math.random()}>
                    <Typography variant="body1" id="status" style={styles.subheading}>Alert Type</Typography>
                    <div className="filtercheckboxlist" >
                        {this.props.alertTypes === undefined ? (<div>Loading...</div>) : (
                            <div>
                                <RadioGroup
                                    aria-label="AlertTypes"
                                    name="alerttypes"
                                    style={{ width: '100%', marginLeft: '45px' }}
                                    value={this.state.filterAlertType}
                                    onChange={this.handleFilterChange}
                                >
                                    {
                                        /* eslint-disable */
                                            filterAlerts(this.props.alertTypes).sort((a, b) =>
                                                a.name.localeCompare(b.name)).map(e => (
                                                <FormControlLabel
                                                    key={e.id}
                                                    value={e.id.toString()}
                                                    control={<Radio color="primary" />}
                                                    label={e.name}
                                                />
                                            ))
                                        /* eslint-enable */
                                    }
                                </RadioGroup>
                            </div>
                        )}
                    </div>
                </FormGroup>
            </div>
        );
    }

    render() {
        const { openDialog, isAddEdit } = this.state;
        const { classes } = this.props;

        return (
            <MiniDrawer redirectTo={this.props.history.push}>
                {isAddEdit && <AlertSteps />}
                <div className={(!isAddEdit) ? '' : classes.hide}>
                    {this.state.showListing ?
                        <ListComponent
                            redirectTo={this.props.history.push}
                            tableData={this.state.tableData}
                            loadNextPage={this.loadNextPage}
                            FiltersToSend={this.state.savedAlertsFiltersToSend}
                            resetTable={this.props.resetTable}
                            isUpdating={this.props.isUpdating}
                            sortTableColumn={this.sortTableColumn}
                            tableTitle="Saved Alerts"
                            tableButtons={['Filter List']}
                            clickRow={this.clickRow}
                            listTypeContextAction={this.renderAlertFilters}
                            resizableHeader
                            showSearch={false}
                            hidePagination
                            rearrangeColumns={this.rearrangeColumns}
                            order={this.state.order}
                            orderBy={this.state.orderBy}
                            rowsPerPage={this.state.rowsPerPage}
                        />
                        :
                        <div style={{ height: '100%' }}>
                            <AppLoader type="fullScreen" />
                        </div>
                    }
                </div>
                <Dialog
                    open={openDialog}
                    type={this.getDialogType()}
                    customTitle=""
                    button={this.button}
                    content={this.getDialogContent()}
                    size="lg"
                />
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => ({
    SavedAlertsList: state.savedAlertsList.savedAlerts,
    totalRecords: state.savedAlertsList.totalRecords,
    isUpdating: state.savedAlertsList.isUpdating,
    alertTypes: state.alertNames.alertNames.data,
    isAlertDeleted: state.savedAlertsList.isAlertDeleted,
});
const mapDispatchToProps = {
    ...actions,
    ...alertTypeActions,
};

export default withStyles(themeStyles)(reduxConnect(
    SavedAlerts,
    mapDispatchToProps,
    mapStateToProps,
));
