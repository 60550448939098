import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function GetSvgIcon(props) {
    switch (props.type) {
    case 'airport': return (
        <SvgIcon {...props}>
            <path fill={props.fillcolor} d="M5.488 20.344c-1.087.52-1.877.588-2.372.204-.42-.325-.479-.923-.177-1.794C.736 17.594-.228 16.583.045 15.72c.291-.918 1.883-1.001 4.774-.25a33.1 33.1 0 0 1 .045-.063 581.67 581.67 0 0 1 5.64-6.494L1.872 6.187C.883 5.644.64 5.024 1.143 4.327c.503-.696 2.737-.838 6.701-.426L9.51 2.397a1.274 1.274 0 0 1 1.717.01l.156.144a.963.963 0 0 1 .065 1.35l3.253.386c1.195-1.265 2.057-2.104 2.584-2.52C19.516.015 21.398-.501 22.43.526c1.032 1.028.625 3.115-.925 5.078-.324.41-.983 1.118-1.979 2.124l.552 3.577a.963.963 0 0 1 1.351.018l.15.151c.463.468.492 1.212.069 1.716l-1.445 1.717c.55 3.948.486 6.185-.192 6.712-.678.527-1.306.305-1.884-.664l-3.124-8.808a850.816 850.816 0 0 1-6.416 6.069c1.02 2.745 1.093 4.309.22 4.69-.83.362-1.936-.491-3.32-2.56zM19.003 2.707c.487-.235.86-.203 1.201.086.316.268.364.608.118 1.14a.425.425 0 1 0 .772.359c.397-.86.291-1.617-.34-2.153-.608-.514-1.341-.578-2.119-.203a.429.429 0 0 0-.2.57.424.424 0 0 0 .568.2z" />
        </SvgIcon>
    );
    case 'gas-station': return (
        <SvgIcon {...props}>
            <g fill={props.fillcolor} fillRule="nonzero">
                <path d="M.8 26h16.4a.8.8 0 0 1 .8.8v1.027H0V26.8a.8.8 0 0 1 .8-.8zM1 24.727V.815C1 .365 1.411 0 1.919 0H16.08c.508 0 .919.365.919.815v23.906c0 .001-5.333.003-16 .006zm2.673-13.898a.4.4 0 0 0-.4.4v3.87c0 .22.18.4.4.4h1.891a.4.4 0 0 0 .4-.4v-3.87a.4.4 0 0 0-.4-.4H3.673zm4.475 0a.4.4 0 0 0-.4.4v3.87c0 .22.179.4.4.4h1.89a.4.4 0 0 0 .4-.4v-3.87a.4.4 0 0 0-.4-.4h-1.89zm4.743 0a.4.4 0 0 0-.4.4v3.87c0 .22.18.4.4.4h1.891a.4.4 0 0 0 .4-.4v-3.87a.4.4 0 0 0-.4-.4h-1.89zm-8.901-9a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-10z" />
                <path strokeWidth=".25" d="M15.916 17.861l-.04-.543.115-.134c1.947-.136 3.109.239 3.47 1.183.13.336.13.604.016 1.86-.033.368-.05.58-.061.801-.05.94.033 1.54.265 1.9.27.417.79.514 1.69.259 2.232-.635 2.185-4.67-.272-11.978l.08-.159.532-.17.156.08c2.65 7.879 2.603 12.173-.272 12.99-1.233.35-2.115.15-2.598-.596-.34-.527-.45-1.249-.39-2.367.013-.232.03-.453.064-.83.094-1.047.1-1.337.033-1.51-.204-.534-1.09-.78-2.655-.67l-.133-.116z" />
                <path d="M18.423 6.506l-.793-.584c-.094-.069-.126-.221-.072-.34l.103-.227-1.495-1.448V3.03l1.794 1.665.087-.193c.054-.119.174-.16.267-.09l1.08.793c.296-.27.71-.246.987.083.082.097.147.216.19.348l1.365 4.202c.165.508-.024 1.091-.424 1.302a.677.677 0 0 1-.085.038l-1.563.567c-.356.13-.734-.077-.906-.493L17.77 8.38c-.155-.375-.108-.834.119-1.144l.534-.731zm1.776 1.98c-.442.05-.717.092-.824.127-.062.02-.383.277-.254.705.064.211.29.631.68 1.259l.952-.058-.554-2.032z" />
            </g>
        </SvgIcon>
    );
    case 'high-rise': return (
        <SvgIcon {...props}>
            <g fill={props.fillcolor} fillRule="nonzero">
                <path d="M10 30.954V27a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v3.954H1a1 1 0 0 1-1-1V1.753a1 1 0 0 1 1-1h13.456a1 1 0 0 1 1 1v28.2a1 1 0 0 1-1 1H10zM3 8.214h9a1 1 0 0 0 0-2H3a1 1 0 1 0 0 2zm0 5h9a1 1 0 1 0 0-2H3a1 1 0 0 0 0 2zm0 5h9a1 1 0 1 0 0-2H3a1 1 0 0 0 0 2zm0 5h9a1 1 0 1 0 0-2H3a1 1 0 0 0 0 2zM18.117.981l3.813.45a1 1 0 0 1 .883.993v27.132a1 1 0 0 1-1 1H18a1 1 0 0 1-1-1V1.975A1 1 0 0 1 18.117.98zM19 8.213h2a1 1 0 0 0 0-2h-2a1 1 0 0 0 0 2zm0 5h2a1 1 0 1 0 0-2h-2a1 1 0 0 0 0 2zm0 5h2a1 1 0 1 0 0-2h-2a1 1 0 0 0 0 2zm0 5h2a1 1 0 1 0 0-2h-2a1 1 0 0 0 0 2z" />
            </g>
        </SvgIcon>
    );
    case 'house': return (
        <SvgIcon {...props} viewBox="1 0 24 24">
            <g fill={props.fillcolor} fillRule="nonzero" transform="translate(-1 -5)">
                <path d="M17 28v-7a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v7H5a2 2 0 0 1-2-2V16a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-6zm3-9a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-2zM6 19a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H6z" />
                <path d="M1.352 14.461v-.247a2 2 0 0 1 .935-1.693L13.44 5.503a2 2 0 0 1 2.135.004l11.066 7.013a2 2 0 0 1 .93 1.69v.251c0 .747-.606 1.352-1.353 1.352H2.704a1.352 1.352 0 0 1-1.352-1.352z" />
                <rect width="4" height="6" x="19" y="6" rx="1" />
            </g>
        </SvgIcon>
    );
    case 'office-1': return (
        <SvgIcon {...props}>
            <g fill={props.fillcolor} fillRule="nonzero">
                <path d="M14.481 24v-4.923a1 1 0 0 0-1-1H9.52a1 1 0 0 0-1 1V24H0V4a2 2 0 0 1 2-2h19a2 2 0 0 1 2 2v20h-8.519zM3.414 7.196h16.598c.596 0 1.078-.38 1.078-.847 0-.467-.482-.846-1.078-.846H3.414c-.595 0-1.078.379-1.078.846 0 .468.483.847 1.078.847zm0 4.23h16.598c.596 0 1.078-.379 1.078-.846 0-.467-.482-.846-1.078-.846H3.414c-.595 0-1.078.379-1.078.846 0 .467.483.846 1.078.846zm0 4.231h16.598c.596 0 1.078-.379 1.078-.846 0-.467-.482-.846-1.078-.846H3.414c-.595 0-1.078.379-1.078.846 0 .467.483.846 1.078.846z" />
                <rect width="9" height="4" x="7" rx="1" />
            </g>
        </SvgIcon>
    );
    case 'office-2': return (
        <SvgIcon {...props} viewBox="0.5 -2 26 26">
            <g fill={props.fillcolor} fillRule="nonzero">
                <path d="M2 0h9a2 2 0 0 1 2 2v20H0V2a2 2 0 0 1 2-2zm1.414 5.196h6.598c.596 0 1.078-.38 1.078-.847 0-.467-.482-.846-1.078-.846H3.414c-.595 0-1.078.379-1.078.846 0 .468.483.847 1.078.847zm0 4.23h6.598c.596 0 1.078-.379 1.078-.846 0-.467-.482-.846-1.078-.846H3.414c-.595 0-1.078.379-1.078.846 0 .467.483.846 1.078.846zm0 4.231h6.598c.596 0 1.078-.379 1.078-.846 0-.467-.482-.846-1.078-.846H3.414c-.595 0-1.078.379-1.078.846 0 .467.483.846 1.078.846zM23.481 22v-4.923a1 1 0 0 0-1-1H18.52a1 1 0 0 0-1 1V22H14V9.426h13V22h-3.519zM27 7.734H14V7a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v.734zm-9.586 5.923h6.598c.596 0 1.078-.379 1.078-.846 0-.467-.482-.846-1.078-.846h-6.598c-.595 0-1.078.379-1.078.846 0 .467.483.846 1.078.846z" />
            </g>
        </SvgIcon>
    );
    case 'office-3': return (
        <SvgIcon {...props} viewBox="-0.5 0 24 24">
            <g fill={props.fillcolor} fillRule="nonzero">
                <path d="M14.481 26v-4.923a1 1 0 0 0-1-1H9.52a1 1 0 0 0-1 1V26H0V11a2 2 0 0 1 2-2h19a2 2 0 0 1 2 2v15h-8.519zM3.414 13.426h16.598c.596 0 1.078-.379 1.078-.846 0-.467-.482-.846-1.078-.846H3.414c-.595 0-1.078.379-1.078.846 0 .467.483.846 1.078.846zm0 4.231h16.598c.596 0 1.078-.379 1.078-.846 0-.467-.482-.846-1.078-.846H3.414c-.595 0-1.078.379-1.078.846 0 .467.483.846 1.078.846z" />
                <path d="M17 2.734H5.991V2a2 2 0 0 1 2-2H15a2 2 0 0 1 2 2v.734zm0 1.692V11H5.991V4.426H17zM9.414 8.657h4.598c.596 0 1.078-.379 1.078-.846 0-.467-.482-.846-1.078-.846H9.414c-.595 0-1.078.379-1.078.846 0 .467.483.846 1.078.846z" />
            </g>
        </SvgIcon>
    );
    case 'oil-tower': return (
        <SvgIcon {...props} viewBox="-2 0 24 24">
            <g fill={props.fillcolor} fillRule="nonzero" stroke="rgba(0, 0, 0, 0.5)" strokeLinecap="round" strokeLinejoin="round">
                <path strokeWidth="2" d="M6.028 6L1 27M14 6l5 21M10 1v28.13" />
                <path d="M5 13l5.011 1.264L15.523 13" />
                <path strokeWidth="4" d="M5 6.275l5.011 1.78 5.512-1.78-5.512-2.522z" />
                <path strokeWidth="2" d="M6 3.759L9.81 5 14 3.759 9.81 2z" />
                <path d="M4 18l5.715 1.264L16 18M3 23.344l6.667 1.263L17 23.344" />
            </g>
        </SvgIcon>
    );
    case 'diamond-blue':
    case 'diamond-purple':
    case 'diamond-white':
    case 'diamond-yellow': return (
        <SvgIcon {...props} viewBox="0 0 26 26">
            <path fill={props.fillcolor} fillRule="nonzero" stroke="#000" strokeOpacity=".35" d="M13 .98L25.02 13 13 25.02.98 13z" />
        </SvgIcon>
    );
    case 'square-blue':
    case 'square-purple':
    case 'square-white':
    case 'square-yellow': return (
        <SvgIcon {...props} viewBox="0 0 16 16" width={16} height={16}>
            <path fill={props.fillcolor} fillRule="nonzero" stroke="#000" strokeOpacity=".35" d="M.5.5h17v17H.5z" />
        </SvgIcon>
    );
    case 'star-blue':
    case 'star-purple':
    case 'star-white':
    case 'star-yellow': return (
        <SvgIcon {...props} viewBox="0 0 16 16" width={16} height={16}>
            <path fill={props.fillcolor} fillRule="nonzero" stroke="#000" strokeOpacity=".346" d="M11.299 13.441l-.822-4.832 3.482-3.425-4.81-.706L7 .085 4.851 4.478l-4.81.706 3.482 3.425-.822 4.832 4.3-2.281 4.298 2.281z" />
        </SvgIcon>
    );
    case 'oil-life': return (
        <SvgIcon {...props} viewBox="0 0 30 16">
            <path fill={props.fillcolor} fillRule="nonzero" d="M11 0a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4zM1 2a1 1 0 0 0-1 1v5a1 1 0 0 0 .684.95L5 10.388V14a2 2 0 0 0 2 2h13c.644 0 1.21-.309 1.576-.781l.004.008.057-.082.033-.05L28 6h1l1-2h-2.482c-.34 0-.674.087-.971.252L22 6.777V6a2 2 0 0 0-2-2H7c-.279 0-.544.057-.785.16L3.6 2.2A1 1 0 0 0 3 2H1zm1 2h.666l2.357 1.768C5.014 5.845 5 5.92 5 6v2.277L2 7.28V4zm26.5 6S27 12.672 27 13.5a1.5 1.5 0 0 0 3 0c0-.828-1.5-3.5-1.5-3.5z" />
        </SvgIcon>
    );
    case 'seatbelt': return (
        <SvgIcon {...props} viewBox="0 0 22 24">
            <path fill={props.fillcolor} fillRule="nonzero" d="M11.955 0c-2.381 0-4.32 2.153-4.32 4.8s1.939 4.8 4.32 4.8c2.383 0 4.32-2.153 4.32-4.8S14.338 0 11.955 0zM1.89 3.51L0 5.04 17.805 24h3.18L1.89 3.51zm10.065 6.57c-.829 0-1.916.024-2.58.045L21.24 22.86c-.174-1.898-.718-7.564-.99-8.955-.289-1.472-1.34-2.216-2.115-2.64-1.564-.857-3.3-1.185-6.18-1.185zm-6.96 1.68a3.797 3.797 0 0 0-1.335 2.13c-.428 1.828-1.026 9.268-1.05 9.585L2.58 24h13.905L4.995 11.76z" />
        </SvgIcon>
    );
    case 'tire-pressure': return (
        <SvgIcon {...props} viewBox="0 0 16 16">
            <path fill={props.fillcolor} fillRule="nonzero" d="M5.56 0C2.841 0 .76 3.519.76 8s2.081 8 4.8 8h1.2c-.728-.119-1.403-.5-2-1.075a5.215 5.215 0 0 1-.425-.475l.425.475.4-.375c.778.912 1.744 1.45 2.8 1.45h1.2c-1.016-.163-1.94-.834-2.675-1.85l1.2.475C8.451 15.491 9.369 16 10.36 16c2.64 0 4.8-3.6 4.8-8S13 0 10.36 0C9.144 0 8.032.769 7.185 2.025l-.45-.475C7.425.71 8.251.147 9.16 0h-1.2c-.844 0-1.622.35-2.3.95-.012.012-.038.012-.05.025L4.435 1.4C5.104.638 5.901.138 6.76 0h-1.2zm4.8 2.4c.881 0 2.4 2.16 2.4 5.6 0 3.44-1.519 5.6-2.4 5.6s-2.4-2.16-2.4-5.6c0-3.44 1.519-5.6 2.4-5.6zm-4.325.15l.525.575c-.203.438-.378.922-.525 1.425l-.625-.525c.181-.528.378-1.031.625-1.475zm-1.725.2c-.138.266-.26.553-.375.85l-.975.55c.122-.372.269-.716.425-1.05l.925-.35zm-.875 2.5A13.303 13.303 0 0 0 3.21 7l-.825.55c.022-.631.072-1.244.175-1.825l.875-.475zm1.625.025l.7.575a12.372 12.372 0 0 0-.175 1.7l-.8-.5c.044-.619.153-1.21.275-1.775zM3.185 8.45c.019.588.062 1.156.15 1.7l-.675.575a12.614 12.614 0 0 1-.275-1.775l.8-.5zm1.6.025l.825.55c.04.528.1 1.034.2 1.525l-.875-.375c-.09-.54-.128-1.116-.15-1.7zm-1.15 3c.219.784.497 1.484.85 2.1l-.5.45c-.397-.578-.712-1.26-.975-2.025l.625-.525zm1.65.15l.925.4c.11.306.244.597.375.875l-.65-.25-.325-.15a11.053 11.053 0 0 1-.325-.875z" />
        </SvgIcon>
    );
    case 'fuel-level': return (
        <SvgIcon {...props} viewBox="0 0 24 22">
            <path fill={props.fillcolor} fillRule="nonzero" d="M2.892 0C1.832 0 .973.859.973 1.919v17.27a.96.96 0 1 0 0 1.919h13.432a.96.96 0 1 0 0-1.919v-3.838h2.879v2.399a3.373 3.373 0 0 0 3.358 3.358.96.96 0 0 0 .347-.071C22.663 20.853 24 19.47 24 17.75V9.595a.96.96 0 0 0-.281-.679L19.88 5.078a.96.96 0 0 0-.146-.12L16.857 3.04a.96.96 0 1 0-1.065 1.596l2.451 1.634v2.365c0 1.06.859 1.919 1.92 1.919h1.918v7.196c0 .807-.632 1.44-1.44 1.44-.806 0-1.438-.633-1.438-1.44V15.35c0-1.048-.87-1.919-1.92-1.919h-2.878V1.92C14.405.859 13.547 0 12.487 0H2.892zm0 1.919h9.595v5.757H2.892V1.919z" />
        </SvgIcon>
    );
    case 'asset': return (
        <SvgIcon {...props} viewBox="-1 -4 24 24">
            <path fill={props.fillcolor} d="M.886.727a.799.799 0 0 0-.795.796v11.136c0 .437.359.796.795.796h1.932a2.728 2.728 0 0 0 5.455 0h2.84a.799.799 0 0 0 .796-.796V1.523a.799.799 0 0 0-.795-.796H.886zm12.529 1.818a.598.598 0 0 0-.597.597v9.716a.59.59 0 0 0 .37.54.612.612 0 0 0 .227.057h1.221a2.728 2.728 0 0 0 5.455 0h1.221a.55.55 0 0 0 .228-.057.585.585 0 0 0 .312-.313.55.55 0 0 0 .057-.227V8.17a.57.57 0 0 0-.114-.312l-3.352-5.057a.57.57 0 0 0-.483-.256h-4.545zm2.13 1.819h2.302L20.26 8h-4.716V4.364zm-10 7.727a1.364 1.364 0 1 1 0 2.728 1.364 1.364 0 0 1 0-2.728zm11.819 0a1.364 1.364 0 1 1-.001 2.728 1.364 1.364 0 0 1 0-2.728z" />
        </SvgIcon>
    );
    case 'driver': return (
        <SvgIcon {...props} width={23} height={23} viewBox="0 0 23 23">
            <g fill="none" fillRule="nonzero">
                <path fill={props.fillcolor} d="M9.001 11.25c1.422 0 2.704-.712 3.615-1.854 1.485.37 2.537.99 3.281 1.804a6.501 6.501 0 0 0-4.54 8.428L0 19.627v-.475c.01-4.752.236-8.675 5.338-9.816.915 1.177 2.217 1.914 3.663 1.914z" />
                <ellipse fill={props.fillcolor} cx="9.001" cy="5.25" rx="4.5" ry="5.25" />
                <g stroke={props.fillcolor} transform="translate(12.001 12)">
                    <ellipse cx="5.225" cy="5.362" strokeWidth="1.26" rx="4.595" ry="4.732" />
                    <path fill={props.fillcolor} strokeWidth=".51" d="M1.743 2.944L5.215 4.67 4.72 6 1.434 3.55z" />
                    <path fill={props.fillcolor} strokeWidth=".51" d="M5.568 9.71l.306-4.472L4.474 5l.415 4.674z" />
                    <path fill={props.fillcolor} strokeWidth=".51" d="M8.655 2.944L5.005 4.67 5.5 6l3.464-2.45z" />
                </g>
            </g>
        </SvgIcon>
    );
    case 'user': return (
        <SvgIcon {...props} width={18} height={20} viewBox="0 0 18 20">
            <path fill={props.fillcolor} fillRule="nonzero" d="M9.001 11.25c1.421 0 2.704-.712 3.615-1.854 4.848 1.21 5.075 5.068 5.086 9.728v.503H0v-.475c.01-4.751.236-8.675 5.337-9.816.916 1.177 2.218 1.914 3.664 1.914z" />
            <ellipse fill={props.fillcolor} xmlns="http://www.w3.org/2000/svg" cx="9.001" cy="5.25" rx="4.5" ry="5.25" />
        </SvgIcon>
    );
    case 'landmark': return (
        <SvgIcon {...props} width={23} height={22} viewBox="0 0 23 22">
            <defs>
                <path id="a" fill={props.fillcolor} d="M7.218 14.748l.163.236c-2.843.44-4.785 1.283-4.785 1.866 0 .778 3.466 2.022 7.916 2.148l1.17 1.302 1.208-1.343c4.007-.254 7.014-1.382 7.014-2.107 0-.528-1.595-1.27-4.01-1.733l.253-.369c.45-.666.863-1.325 1.234-1.974 3.077.77 5.119 2.172 5.119 4.076 0 2.995-5.056 4.75-11.25 4.75S0 19.845 0 16.85c0-2.058 2.385-3.53 5.887-4.25.395.706.842 1.423 1.331 2.148z" />
            </defs>
            <g fill="#FFF" fillRule="evenodd">
                <use fillRule="nonzero" xlinkHref="#a" />
                <path fill={props.fillcolor} fillRule="nonzero" d="M2.596 17.1c0 .778 3.856 2.045 8.654 2.045 3.942 0 7.247-.855 8.302-1.592l1.861 1.63c-1.81 1.55-5.69 2.417-10.163 2.417C5.056 21.6 0 19.937 0 17.1zm19.904 0c0 .066-.003.131-.008.196l-.223-.196z" />
                <path fill={props.fillcolor} d="M11.25 0c3.26 0 5.85 2.65 5.85 5.985 0 4.446-5.85 11.115-5.85 11.115S5.4 10.431 5.4 5.985C5.4 2.65 7.99 0 11.25 0zm0 3.848c-1.17 0-2.09.94-2.09 2.137s.92 2.138 2.09 2.138 2.09-.941 2.09-2.138-.92-2.138-2.09-2.138z" />
            </g>
        </SvgIcon>
    );
    case 'sort-asc': return (
        <SvgIcon {...props} viewBox="-3 -3 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M-3-3h24v24H-3z" />
                <path fill="#000" fillRule="nonzero" d="M8.1 13.5a.9.9 0 0 1-.9-.9V5.4H3.6L9 0l5.4 5.4h-3.6v7.2a.9.9 0 0 1-.9.9H8.1zM0 16.2V18h18v-1.8H0z" opacity=".54" />
            </g>
        </SvgIcon>
    );
    case 'sort-asc-disabled': return (
        <SvgIcon {...props} viewBox="-3 -3 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M-3-3h24v24H-3z" />
                <path fill="rgba(0, 0, 0, 0.26)" fillRule="nonzero" d="M8.1 13.5a.9.9 0 0 1-.9-.9V5.4H3.6L9 0l5.4 5.4h-3.6v7.2a.9.9 0 0 1-.9.9H8.1zM0 16.2V18h18v-1.8H0z" opacity=".54" />
            </g>
        </SvgIcon>
    );
    case 'sort-desc': return (
        <SvgIcon {...props} viewBox="-3 -3 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M-3-3h24v24H-3z" />
                <path fill="#000" fillRule="nonzero" d="M8.1 0a.9.9 0 0 0-.9.9v7.2H3.6L9 13.5l5.4-5.4h-3.6V.9a.9.9 0 0 0-.9-.9H8.1zM0 16.2V18h18v-1.8H0z" opacity=".54" />
            </g>
        </SvgIcon>
    );
    case 'sort-desc-disabled': return (
        <SvgIcon {...props} viewBox="-3 -3 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M-3-3h24v24H-3z" />
                <path fill="rgba(0, 0, 0, 0.26)" fillRule="nonzero" d="M8.1 0a.9.9 0 0 0-.9.9v7.2H3.6L9 13.5l5.4-5.4h-3.6V.9a.9.9 0 0 0-.9-.9H8.1zM0 16.2V18h18v-1.8H0z" opacity=".54" />
            </g>
        </SvgIcon>
    );
    case 'background_image_zoomedOut': return (
        <svg width="10" height="10" viewBox="0 0 10 10">
            <defs>
                <filter id="background_image_zoomedOut" width="316.7%" height="316.7%" x="-108.3%" y="-108.3%" filterUnits="objectBoundingBox">
                    <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1" />
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.336593094 0" />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g fill={props.fillcolor} fillRule="evenodd" transform="translate(1 1)" filter="url(#background_image_zoomedOut)">
                <circle cx="3" cy="3" r="3" />
            </g>
        </svg>
    );
    case 'background_image_middleZoom': return (
        <svg width="21" height="21" viewBox="0 0 21 21">
            <defs>
                <filter id="background_image_middleZoom" width="192.9%" height="192.9%" x="-46.4%" y="-46.4%" filterUnits="objectBoundingBox">
                    <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1" />
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.336593094 0" />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g fill={props.fillcolor} fillRule="evenodd" transform="translate(1 1)" filter="url(#background_image_middleZoom)">
                <circle cx="9" cy="9" r="9" />
            </g>
        </svg>
    );
    case 'background_image': return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="46" viewBox="0 0 40 46">
            <defs>
                <filter x="-21.7%" y="-18.1%" width="143.3%" height="136.1%" filterUnits="objectBoundingBox" id="2oafoq2isa">
                    <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.336593094 0" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g filter="url(#2oafoq2isa)" transform="translate(5 5)" fill={props.fillcolor} fillRule="nonzero">
                <path d="M15 36a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0-36c8.284 0 15 6.716 15 15 0 7.103-4.937 13.053-11.566 14.605-.892.346-1.532.714-1.922 1.104-.468.468-.972 1.365-1.512 2.691l-.115-.278c-.497-1.174-.962-1.978-1.397-2.413-.39-.39-1.03-.758-1.922-1.104C4.936 28.053 0 22.103 0 15 0 6.716 6.716 0 15 0z" />
            </g>
        </svg>
    );
    case 'background_circle_image': return (
        <svg width="48" height="49" viewBox="0 0 48 49">
            <defs>
                <filter id="background_circle_image" width="160%" height="158.5%" x="-30%" y="-29.3%" filterUnits="objectBoundingBox">
                    <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd" filter="url(#background_circle_image)" transform="translate(4 2)">
                <circle cx="20" cy="20.25" r="20" fill="#FF5252" />
            </g>
        </svg>
    );
    case 'locationIcon': return (
        <SvgIcon {...props} viewBox="-1 0 24 24">
            <defs>
                <filter id="locationIcon_filter" width="160%" height="158.5%" x="-30%" y="-29.3%" filterUnits="objectBoundingBox">
                    <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <path fill={props.fillcolor} fillRule="evenodd" d="M20 11c-3.87 0-7 3.13-7 7 0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z" transform="translate(-9 -9)" />
        </SvgIcon>
    );
    case 'resizeLandmark': return (
        <SvgIcon {...props} viewBox="0 0 52 52">
            <g fill={props.fillcolor} fillRule="evenodd">
                <g fill={props.fillcolor} fillRule="nonzero">
                    <path d="M27.153 13c-.557 0-1.012.45-1.012 1v.5h1.012V14h.507v-1h-.507zm1.52 0v1h1.012v-1h-1.012zm2.025 0v1h1.013v-1h-1.013zm2.026 0v1h1.013v-1h-1.013zm2.025 0v1h.507v.5h1.013V14c0-.55-.455-1-1.013-1h-.507zm-8.608 2.5v1h1.012v-1h-1.012zm9.115 0v1h1.013v-1h-1.013zm-6.077.5v1h2.326l-2.548 2.5h-2.816V22c0 .55.455 1 1.012 1h2.532v-2.781l2.532-2.516V20h1.013v-4H29.18zm-3.038 1.5v1h1.012v-1h-1.012zm9.115 0v1h1.013v-1h-1.013zm0 2v1h1.013v-1h-1.013zm0 2v.5h-.507v1h.507c.558 0 1.013-.45 1.013-1v-.5h-1.013zm-4.558.5v1h1.013v-1h-1.013zm2.026 0v1h1.013v-1h-1.013z" />
                    <path d="M25.128 15.676V22c0 1.105.907 2 2.025 2h1.815c-1.118 5.579-6.3 11.568-6.546 11.85a.459.459 0 0 1-.332.15.442.442 0 0 1-.333-.15C21.482 35.539 15 28.175 15 22c0-3.859 3.181-7 7.09-7a7.12 7.12 0 0 1 3.038.676zm-3.038 4.136c-1.468 0-2.66 1.176-2.66 2.626 0 1.449 1.192 2.625 2.66 2.625 1.467 0 2.658-1.176 2.658-2.625 0-1.45-1.19-2.625-2.658-2.625z" />
                </g>
            </g>
        </SvgIcon>
    );
    case 'resizeAsset': return (
        <SvgIcon {...props} viewBox="0 0 52 52">
            <g fill={props.fillcolor} fillRule="evenodd">
                <path d="M35.179 24.042v8.435c0 .69-.635 1.321-1.331 1.321h-1.816A2.654 2.654 0 0 1 29.412 36a2.654 2.654 0 0 1-2.62-2.202H22.69a1.26 1.26 0 0 1-1.261-1.252V20.988c0-.705.56-1.28 1.247-1.28h5.754v2.334c0 1.104.9 2 2.012 2h4.738zM20.54 22.79v11.007C20.328 35.045 19.196 36 17.879 36a2.655 2.655 0 0 1-2.62-2.202h-.928c-.697 0-1.331-.63-1.331-1.32v-4.239c0-.885.643-1.79.72-1.898l1.844-2.45c.423-.505 1.087-1.1 1.872-1.1h3.105zm-2.218 2.201h-2.481l-1.414 1.871c-.151.212-.54.86-.54 1.376v.276h4.435c.443 0 .887-.44.887-.881v-1.761c0-.487-.444-.88-.887-.88zm11.09 6.605c-.98 0-1.775.79-1.775 1.761 0 .972.795 1.761 1.774 1.761.98 0 1.774-.79 1.774-1.76 0-.973-.795-1.762-1.774-1.762zm-11.534 0c-.979 0-1.774.79-1.774 1.761 0 .972.795 1.761 1.774 1.761.98 0 1.775-.79 1.775-1.76 0-.973-.796-1.762-1.775-1.762z" />
                <path d="M30.4 13c-.555 0-1.007.45-1.007 1v.5h1.006V14h.503v-1H30.4zm1.508 0v1h1.007v-1h-1.007zm2.013 0v1h1.006v-1h-1.006zm2.012 0v1h1.006v-1h-1.006zm2.013 0v1h.503v.5h1.006V14c0-.55-.452-1-1.006-1h-.503zm-8.553 2.5v1h1.006v-1h-1.006zm9.056 0v1h1.006v-1h-1.006zm-6.038.5v1h2.312l-2.532 2.5h-2.798V22c0 .55.452 1 1.006 1h2.516v-2.781l2.515-2.516V20h1.006v-4h-4.025zm-3.018 1.5v1h1.006v-1h-1.006zm9.056 0v1h1.006v-1h-1.006zm0 2v1h1.006v-1h-1.006zm0 2v.5h-.503v1h.503c.554 0 1.006-.45 1.006-1v-.5h-1.006zm-4.528.5v1h1.006v-1h-1.006zm2.012 0v1h1.006v-1h-1.006z" />
            </g>
        </SvgIcon>
    );
    case 'refresh': return (
        <SvgIcon {...props} viewBox="-3 -3 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M-4-4h24v24H-4z" />
                <path fill={props.fillcolor || '#000'} fillRule="nonzero" d="M8 0C3.58 0 .01 3.58.01 8S3.58 16 8 16c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0 1 8 14c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L9 7h7V0l-2.35 2.35A7.958 7.958 0 0 0 8 0z" opacity={props.opacity || '.54'} />
            </g>
        </SvgIcon>
    );
    case 'print': return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M-4-4h24v24H-4z" />
                <path fill={props.fillcolor} fillRule="nonzero" d="M18,3H6V7H18M19,12A1,1 0 0,1 18,11A1,1 0 0,1 19,10A1,1 0 0,1 20,11A1,1 0 0,1 19,12M16,19H8V14H16M19,8H5A3,3 0 0,0 2,11V17H6V21H18V17H22V11A3,3 0 0,0 19,8Z" opacity={props.opacity} />
            </g>
        </SvgIcon>
    );
    case 'add': return (
        <SvgIcon {...props} viewBox="-3 -3 24 24">
            <g fill="none" fillRule="evenodd">
                <path fill="#000" d="M9 0a9 9 0 0 0-9 9 9 9 0 0 0 9 9 9 9 0 0 0 9-9 9 9 0 0 0-9-9zm4.5 9.9H9.9v3.6H8.1V9.9H4.5V8.1h3.6V4.5h1.8v3.6h3.6v1.8z" opacity=".54" />
                <path d="M-3-3h24v24H-3z" />
            </g>
        </SvgIcon>
    );
    case 'cancel': return (
        <SvgIcon {...props} viewBox="-3 -3 24 24">
            <g fill="none" fillRule="evenodd">
                <path fill="#FFF" d="M15.583 1.933L14.067.417 8 6.483 1.933.417.417 1.933 6.483 8 .417 14.067l1.516 1.516L8 9.517l6.067 6.066 1.516-1.516L9.517 8z" opacity=".54" />
                <path d="M-3-3h24v24H-3z" />
            </g>
        </SvgIcon>
    );
    case 'back': return (
        <SvgIcon {...props} viewBox="-3 -3 24 24">
            <g fill="none" fillRule="evenodd">
                <path fill="#FFFFFF" d="M4.508 8.82h14.32v2.36H4.508l7.153 7.152L9.994 20 0 10.006.006 10 0 9.994 9.994 0l1.667 1.668L4.508 8.82z" />
                <path d="M-3-3h24v24H-3z" />
            </g>
        </SvgIcon>
    );
    case 'left-arrow': return (
        <SvgIcon {...props} viewBox="0 0 9 13" width={16} height={props.height}>
            <path fill="#FFF" fillRule="evenodd" d="M6.364 0L0 6.364l6.364 6.364z" opacity={props.opacity} />
        </SvgIcon>
    );
    case 'right-arrow': return (
        <SvgIcon {...props} viewBox="0 0 9 13">
            <path fill="#FFF" fillRule="evenodd" d="M.364 0l6.364 6.364-6.364 6.364z" opacity={props.opacity} />
        </SvgIcon>
    );
    case 'boat': return (
        <SvgIcon {...props} viewBox="0 0 18 11">
            <g fill="#FFF" fillRule="evenodd" transform="matrix(-1 0 0 1 16 1)">
                <path fillRule="nonzero" d="M14 5.757c-2.188-.13-3.93-.283-5.005-.458-.653-.107-1.078-.222-1.76-.453l-.3-.102a12.59 12.59 0 0 0-1.804-.487c-1.167-.23-2.775-.319-4.834-.272-.13-.39-.229-.84-.297-1.35h1.644c.455 0 1.28.068 2.477.206l2.611-1.674h3.892l.456 3.65c.232.103.694.182 1.385.238.691.056 1.203.056 1.535 0v.702zm-.045.509A1.06 1.06 0 0 1 12.937 7H2.95C2.11 6.34 1.447 5.74.96 5.2A2.94 2.94 0 0 1 .5 4.49c1.903-.036 3.382.05 4.431.256a11.681 11.681 0 0 1 1.682.455l.298.102c.728.247 1.2.375 1.916.491 1.122.183 2.896.34 5.129.472zM6.1 2.644h1.2a.502.502 0 0 0 .51-.495.502.502 0 0 0-.51-.495h-.352a.816.816 0 0 0-.445.132l-.558.363a.266.266 0 0 0-.11.303.277.277 0 0 0 .265.192zM8.8 1.62c-.3 0-.544.237-.544.529 0 .292.244.529.544.529h1.076c.14 0 .272-.058.364-.159a.467.467 0 0 0 .121-.37l-.007-.06a.539.539 0 0 0-.54-.469H8.8z" />
                <path d="M12.641 2.394a.197.197 0 0 1 .136.245l-.77 2.607a.203.203 0 0 1-.248.138.197.197 0 0 1-.136-.245l.77-2.608a.203.203 0 0 1 .248-.137zM13.144 3.554a.197.197 0 0 1 .135.245l-.438 1.486a.203.203 0 0 1-.249.138.197.197 0 0 1-.135-.245l.438-1.487a.203.203 0 0 1 .249-.137z" />
                <path fillRule="nonzero" d="M8.165 0h1.757l.478.389H7.6L8.165 0zM8.8.389h.4l.248.778H8.53L8.8.389z" />
            </g>
        </SvgIcon>
    );
    case 'bus': return (
        <SvgIcon {...props} viewBox="0 0 18 14">
            <g fill="#FFF" fillRule="nonzero" transform="matrix(-1 0 0 1 16 1)">
                <path d="M.729 4.7c.218 0 1.74-.004 1.898-.158l1.178-1.15a.88.88 0 0 0 .263-.63v-.588a.86.86 0 0 0-.849-.87H.886l.02-.287A.694.694 0 0 1 1.59.363h11.37c.38 0 .687.316.687.704v4.437a.297.297 0 0 0-.15-.04h-.34a.303.303 0 0 0-.273.153.318.318 0 0 0 0 .318.303.303 0 0 0 .273.152h.34a.297.297 0 0 0 .15-.04v.98a.696.696 0 0 1-.687.705H1.18a.68.68 0 0 1-.502-.224.715.715 0 0 1-.183-.53l.065-.943c.05.034.108.052.168.052h.791c.164-.006.164-.617 0-.623H.728c-.045 0-.09.01-.13.03l.13-.793zm4.786-3.396a.86.86 0 0 0-.848.87v.435c0 .48.38.87.848.87h.849a.86.86 0 0 0 .848-.87v-.435a.86.86 0 0 0-.848-.87h-.849zm2.97 0a.86.86 0 0 0-.849.87v.435c0 .48.38.87.849.87h.848a.86.86 0 0 0 .849-.87v-.435a.86.86 0 0 0-.849-.87h-.848zm2.97 0a.86.86 0 0 0-.849.87v.435c0 .48.38.87.849.87h.848a.86.86 0 0 0 .849-.87v-.435a.86.86 0 0 0-.849-.87h-.848z" />
                <path d="M3.5 9a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm0 1a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm7-1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm0 1a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM.386 6.957l.887.012v.773H.386A.39.39 0 0 1 0 7.347c0-.105.04-.205.113-.278a.376.376 0 0 1 .273-.112zm13.236 0h-1.319l.387.774h.932A.383.383 0 0 0 14 7.344a.383.383 0 0 0-.378-.387z" />
            </g>
        </SvgIcon>
    );
    case 'light':
    case 'car': return (
        <SvgIcon {...props} viewBox="0 0 18 12">
            <g fill="#FFF" fillRule="evenodd" transform="matrix(-1 0 0 1 16 1)">
                <path d="M8.779 5.803h-7.54a.69.69 0 0 1-.52-.235.679.679 0 0 1-.16-.544l.119-.817a.327.327 0 0 0 .05.004h.792a.303.303 0 0 0 .293-.302.303.303 0 0 0-.293-.302H.765l.1-.679a.686.686 0 0 1 .68-.584h11.416c.38 0 .688.305.688.682v.62a.304.304 0 0 0-.15-.039h-.34a.305.305 0 0 0-.274.148.3.3 0 0 0 0 .308.305.305 0 0 0 .273.148h.341a.304.304 0 0 0 .15-.04v.95a.685.685 0 0 1-.688.682H8.779z" />
                <path fillRule="nonzero" d="M3.336 7.053a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm0 1a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm7-1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm0 1a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm-9.95-3l.887.011v.75H.386A.385.385 0 0 1 0 5.43a.376.376 0 0 1 .386-.377zm13.236 0h-1.319l.387.75h.932A.377.377 0 0 0 14 5.428a.377.377 0 0 0-.378-.375zM5.197 0h5.233c.361 0 .683.227.802.565l.59 1.685c.09.257.049.542-.11.764a.85.85 0 0 1-.692.354H4.242a.85.85 0 0 1-.732-.417.837.837 0 0 1-.007-.837L4.458.429A.85.85 0 0 1 5.196 0h.001zM4.54 1.868a.418.418 0 0 0 .023.407.425.425 0 0 0 .36.198h2.26a.423.423 0 0 0 .425-.421v-.789a.423.423 0 0 0-.424-.42H5.307a.425.425 0 0 0-.382.237l-.385.788zm6.167 0l-.385-.788A.425.425 0 0 0 9.94.842H8.912a.423.423 0 0 0-.424.421v.789c0 .232.19.42.424.42h1.413a.425.425 0 0 0 .36-.197.418.418 0 0 0 .022-.407z" />
            </g>
        </SvgIcon>
    );
    case 'cement-truck': return (
        <SvgIcon {...props} viewBox="0 0 18 14">
            <g fill="#FFF" fillRule="evenodd" transform="matrix(-1 0 0 1 16 1)">
                <path d="M.756 8.083A.746.746 0 0 1 0 7.347v-.444c0-.407.338-.736.756-.736h12.101c.418 0 .756.33.756.736v.444a.746.746 0 0 1-.756.736H.756z" />
                <path fillRule="nonzero" d="M2.514 9.233c.32 0 .58-.257.58-.575a.578.578 0 0 0-.58-.575c-.32 0-.58.258-.58.575 0 .318.26.575.58.575zm0 .767c-.748 0-1.353-.6-1.353-1.342 0-.74.605-1.341 1.353-1.341s1.354.6 1.354 1.341S3.262 10 2.514 10zm4.642-.767c.32 0 .58-.257.58-.575a.578.578 0 0 0-.58-.575c-.32 0-.58.258-.58.575 0 .318.26.575.58.575zm0 .767c-.748 0-1.353-.6-1.353-1.342 0-.74.605-1.341 1.353-1.341.747 0 1.354.6 1.354 1.341S7.903 10 7.156 10zm3.868-.767c.32 0 .58-.257.58-.575a.578.578 0 0 0-.58-.575c-.32 0-.58.258-.58.575 0 .318.26.575.58.575zm0 .767c-.748 0-1.354-.6-1.354-1.342 0-.74.606-1.341 1.354-1.341.748 0 1.354.6 1.354 1.341S11.772 10 11.024 10zM.02 6.167h.944a.194.194 0 0 0 .197-.192.194.194 0 0 0-.197-.192H.02v-.92c0-.09.012-.181.035-.269l.371-1.438c.127-.49.58-.835 1.1-.835h1.14c.417 0 .756.33.756.736v3.53L.02 7.243V6.167zm.826-2.919L.544 4.526a.361.361 0 0 0 .086.328c.082.09.203.134.324.121l1.19-.13a.372.372 0 0 0 .336-.365V3.33a.363.363 0 0 0-.11-.26.383.383 0 0 0-.268-.108h-.888a.376.376 0 0 0-.369.286h.001zm3.359 1.484a.29.29 0 0 1 .263.028.274.274 0 0 1 .124.228V6.5h-.774V5.285c0-.244.154-.463.387-.553zm7.17-.084l.042-.026a.256.256 0 0 1 .256-.01c.081.043.132.126.131.215v1.636h-.774V5.267c0-.25.13-.483.346-.618zm-6.752-1.36a.721.721 0 0 1 .125-.652l1.18-1.503A.757.757 0 0 1 6.37.86L8.448.425a.775.775 0 0 1 .324.002l3.688.808a.745.745 0 0 1 .577.583l.099.514a.726.726 0 0 1-.252.696l-2.796 2.326a.764.764 0 0 1-.34.161l-1.969.39a.775.775 0 0 1-.457-.049l-1.9-.822a.743.743 0 0 1-.42-.468l-.379-1.278zM8.937.934a.215.215 0 0 0-.17.254l.782 3.584a.216.216 0 0 0 .147.164c.076.024.16.007.218-.046a.211.211 0 0 0 .066-.207l-.782-3.584a.215.215 0 0 0-.095-.136.225.225 0 0 0-.166-.03zm-2.334.454a.215.215 0 0 0-.17.254l.783 3.585a.216.216 0 0 0 .147.163c.076.025.16.007.218-.045a.211.211 0 0 0 .066-.208l-.782-3.584a.215.215 0 0 0-.096-.135.225.225 0 0 0-.166-.03z" />
                <path d="M4.11 3.647l.747-.209a.39.39 0 0 1 .479.265.379.379 0 0 1-.262.472l-.747.21a.39.39 0 0 1-.479-.266.379.379 0 0 1 .262-.472z" />
            </g>
        </SvgIcon>
    );
    case 'dump-truck': return (
        <SvgIcon {...props} viewBox="0 0 18 13">
            <g fill="#FFF" fillRule="nonzero" transform="matrix(-1 0 0 1 16 1)">
                <path d="M.903.646h2.71-2.71zM4.476.255l.584.802h8.488a.44.44 0 0 1 .452.428v.53a.835.835 0 0 1-.261.602L11.55 4.714H4.146V1.49L3.505.865h-2.15A.443.443 0 0 1 .903.432c0-.238.203-.432.452-.432h2.71c.178 0 .339.1.411.255zm1.62 1.456a.22.22 0 0 0-.225.214v1.73a.22.22 0 0 0 .226.215.22.22 0 0 0 .226-.214v-1.73a.22.22 0 0 0-.226-.215zm2.259 0a.22.22 0 0 0-.226.214v1.73a.22.22 0 0 0 .226.215.22.22 0 0 0 .226-.214v-1.73a.22.22 0 0 0-.226-.215zm2.258 0a.22.22 0 0 0-.226.214v1.73a.22.22 0 0 0 .226.215.22.22 0 0 0 .226-.214v-1.73a.22.22 0 0 0-.226-.215zM.732 7.193C.328 7.193 0 6.882 0 6.498V6.08c0-.383.327-.694.732-.694h11.71c.405 0 .732.31.732.694v.418c0 .384-.328.695-.732.695H.732z" />
                <path d="M2.433 8.277c.31 0 .562-.243.562-.542 0-.3-.252-.542-.562-.542a.552.552 0 0 0-.562.542c0 .3.252.542.562.542zm0 .723c-.724 0-1.31-.567-1.31-1.265 0-.699.586-1.265 1.31-1.265.724 0 1.31.566 1.31 1.265C3.743 8.433 3.156 9 2.433 9zm4.492-.723c.31 0 .562-.243.562-.542 0-.3-.252-.542-.562-.542a.552.552 0 0 0-.561.542c0 .3.251.542.561.542zm0 .723c-.723 0-1.31-.567-1.31-1.265 0-.699.587-1.265 1.31-1.265.724 0 1.31.566 1.31 1.265C8.235 8.433 7.65 9 6.925 9zm3.743-.723c.31 0 .562-.243.562-.542 0-.3-.251-.542-.562-.542a.552.552 0 0 0-.56.542c0 .3.25.542.56.542zm0 .723c-.723 0-1.31-.567-1.31-1.265 0-.699.587-1.265 1.31-1.265.724 0 1.31.566 1.31 1.265 0 .698-.586 1.265-1.31 1.265zM.02 5.386h.914c.105 0 .19-.081.19-.181 0-.1-.085-.18-.19-.18H.019v-.868c0-.086.012-.171.034-.254l.36-1.356c.122-.463.56-.787 1.063-.787H2.58c.404 0 .731.31.731.694v3.329L.02 6.4V5.386zm.8-2.752L.527 3.839a.335.335 0 0 0 .083.309.377.377 0 0 0 .314.114l1.151-.122a.354.354 0 0 0 .325-.345V2.712a.338.338 0 0 0-.107-.246.376.376 0 0 0-.258-.102h-.86a.362.362 0 0 0-.357.27z" />
            </g>
        </SvgIcon>
    );
    case 'equipment':
    case 'excavator': return (
        <SvgIcon {...props} viewBox="0 0 18 16">
            <g fill="#FFF" fillRule="evenodd" transform="matrix(-1 0 0 1 16 1)">
                <path d="M4.651 5.5h8.542c.295 0 .534.245.534.548v1.039a.541.541 0 0 1-.534.548H4.651a.541.541 0 0 1-.533-.548V6.048c0-.303.238-.548.533-.548z" />
                <path fillRule="nonzero" d="M9.694 1.692h2.784c.295 0 .534.246.534.549v4.438a.541.541 0 0 1-.534.548H7.534A.541.541 0 0 1 7 6.68V4.46c0-1.529 1.206-2.768 2.694-2.768zm-1.87 3.19h2.241V2.599c-.72-.08-1.293.093-1.716.519-.423.426-.598 1.014-.525 1.764zm2.882-2.283V4.87h1.647V3.022a.418.418 0 0 0-.412-.423h-1.235z" />
                <path fillRule="nonzero" stroke="#FFF" strokeWidth=".648" d="M5.386 8.53c-.627 0-1.135.522-1.135 1.167 0 .644.508 1.166 1.135 1.166h7.072c.627 0 1.136-.522 1.136-1.166 0-.645-.509-1.167-1.136-1.167H5.386zm.346 2.24c-.603-.002-1.09-.506-1.089-1.126a1.138 1.138 0 0 1 .318-.794c.204-.212.481-.33.771-.332.29.001.567.12.772.331.204.212.318.497.317.795.002.62-.486 1.124-1.089 1.125zm3.294 0c-.603-.002-1.09-.506-1.089-1.126a1.138 1.138 0 0 1 .318-.794c.204-.212.481-.33.771-.332.29.001.568.12.772.331.204.212.318.497.317.795.002.62-.486 1.124-1.089 1.125zm2.965 0c-.603-.002-1.09-.506-1.09-1.126a1.138 1.138 0 0 1 .318-.794c.205-.212.482-.33.772-.332.29.001.567.12.771.331.205.212.319.497.318.795.002.62-.486 1.124-1.089 1.125z" />
                <path d="M4.558.538a.407.407 0 0 1 .507.28l1.367 4.9a.419.419 0 0 1-.285.513.407.407 0 0 1-.506-.28l-1.367-4.9a.419.419 0 0 1 .284-.513z" />
                <path d="M5.072.625a.423.423 0 0 1-.146.569l-2.86 1.715a.392.392 0 0 1-.305.043.403.403 0 0 1-.245-.193.423.423 0 0 1 .147-.568L4.522.476a.392.392 0 0 1 .305-.043.403.403 0 0 1 .245.192z" />
                <path d="M1.486 2.285C.367 3.008.014 3.869.427 4.866c.413.997 1.327 1.303 2.742.917l-.276-.57-.352.02-.807-3.05-.248.102z" />
            </g>
        </SvgIcon>
    );
    case 'generator': return (
        <SvgIcon {...props} viewBox="0 0 18 14">
            <g fill="#FFF" fillRule="evenodd" transform="matrix(-1 0 0 1 16 1)">
                <path d="M2.713 7.992a.777.777 0 0 1-.782-.772v-.464c0-.426.35-.772.782-.772h10.105c.432 0 .782.346.782.772v.464c0 .427-.35.772-.782.772H2.713z" />
                <path fillRule="nonzero" d="M6.92 9.197a.601.601 0 1 0-.002-1.203.601.601 0 0 0 .002 1.203zm0 .803c-.774 0-1.4-.63-1.4-1.406 0-.776.626-1.405 1.4-1.405.773 0 1.4.63 1.4 1.405C8.32 9.37 7.693 10 6.92 10zm4.001-.803c.332 0 .6-.27.6-.603a.601.601 0 1 0-.6.603zm0 .803c-.773 0-1.4-.63-1.4-1.406 0-.776.627-1.405 1.4-1.405.774 0 1.4.63 1.4 1.405 0 .776-.626 1.406-1.4 1.406zM.966 7.143h.482V8.81a.24.24 0 0 1-.241.238.24.24 0 0 1-.241-.238V7.143z" />
                <path fillRule="nonzero" d="M.432 7.143h2.943a.433.433 0 0 1 .386.208c.08.134.08.3 0 .434a.433.433 0 0 1-.386.209H.432a.428.428 0 0 1-.418-.426c0-.23.185-.417.418-.425zM3.862 0h8.69c.533 0 .965.426.965.952v3.334a.959.959 0 0 1-.965.952h-8.69a.959.959 0 0 1-.965-.952V.952c0-.526.432-.952.965-.952zM8.76.397L6.565 2.86h1.139l-.736 1.996L9.139 2.28H8.19L8.76.397z" />
            </g>
        </SvgIcon>
    );
    case 'motorcycle': return (
        <SvgIcon {...props} viewBox="0 0 19 15">
            <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 16 2)">
                <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".75" d="M1.01 4.805c.84-.245 1.602-.087 2.285.474.598.49.938 1.163 1.02 2.018" />
                <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".75" d="M1.609 7.44C3.425 5.107 4.442 3.25 4.66 1.875 4.879.497 5.656-.011 6.989.347" />
                <path fill="#FFF" d="M3 1.5h.5a1 1 0 1 1 0 2H3a.5.5 0 0 1-.5-.5V2a.5.5 0 0 1 .5-.5z" />
                <path fill="#FFF" d="M2.916 3.001c1.595 1.343 2.392 2.478 2.392 3.406 0 1.393 1.29 1.742 2.465 1.583 1.174-.158.921-2.657 1.19-2.884.268-.226 1.324-1.406 2.832-1.201.572.077.983.19 1.233.339.289.171.65.162.929-.023.006-.841-.714-1.262-2.162-1.262-1.447 0-2.24.177-2.376.531H8.064c-.012-.457-.377-.8-1.092-1.031-.716-.231-1.678-.373-2.885-.426l-1.171.968z" />
                <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".75" d="M9.041 5.309L12 7.376l-4.802.287" />
                <circle cx="1.5" cy="7.5" r="1.5" stroke="#FFF" />
                <circle cx="12" cy="7.5" r="1.5" stroke="#FFF" />
            </g>
        </SvgIcon>
    );
    case 'flat-bed': return (
        <SvgIcon {...props} viewBox="-3 0 20 5">
            <g fill="none" fillRule="evenodd">
                <g fill="#FFF">
                    <path d="M9.61 2.31H8.88a1.66 1.66 0 0 0-1.564-1.117 1.66 1.66 0 0 0-1.563 1.116H.729A.729.729 0 0 1 0 1.58v-.403A.73.73 0 0 1 .729.449h12.484a.73.73 0 0 1 .729.73v.402a.73.73 0 0 1-.73.729h-.204c-.246-.65-.914-1.117-1.698-1.117-.785 0-1.452.467-1.7 1.117z" />
                    <path d="M7.315 3.425c.305 0 .553-.25.553-.557a.555.555 0 0 0-.553-.558.555.555 0 0 0-.553.558.555.555 0 0 0 .553.558zm0 .745a1.296 1.296 0 0 1-1.289-1.302c0-.72.577-1.302 1.289-1.302s1.29.582 1.29 1.302c0 .719-.578 1.302-1.29 1.302zm3.974-.787c.306 0 .553-.25.553-.558a.555.555 0 0 0-.553-.558.556.556 0 0 0-.552.558.555.555 0 0 0 .553.558zm0 .744c-.711 0-1.289-.583-1.289-1.302 0-.72.578-1.303 1.29-1.303.712 0 1.289.584 1.289 1.303S12 4.127 11.288 4.127z" fillRule="nonzero" />
                </g>
                <path stroke="#FFF" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" d="M2 1.25v2.446" />
            </g>
        </SvgIcon>
    );
    case 'snowmobile': return (
        <SvgIcon {...props} viewBox="0 0 19 13">
            <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 16 1)">
                <g fill="#FFF">
                    <path d="M6.183.396h.36l-1.4 1.636a.718.718 0 0 0 .208 1.101l1.43.767h6.528c.258 0 .468.209.468.466v.906c0 .258-.21.466-.468.466H.776a.469.469 0 0 1-.463-.533c.1-.684.63-1.397 1.594-2.138 1.192-.916 1.74-.704 2.254-1.144A73.8 73.8 0 0 1 5.9.495c.08-.064.18-.099.282-.099z" />
                    <path fillRule="nonzero" stroke="#FFF" strokeWidth=".5" d="M6.995 6.567c-.556 0-1.007.45-1.007 1.004v.003c0 .554.451 1.004 1.007 1.004h5.657c.556 0 1.007-.45 1.007-1.004V7.57c0-.555-.45-1.004-1.007-1.004H6.995zM7.2 8.495c-.501 0-.903-.435-.903-.968 0-.532.402-.967.903-.967s.903.435.903.967-.402.968-.903.968zm2.71 0c-.501 0-.903-.435-.903-.968 0-.532.402-.967.903-.967.502 0 .904.435.904.967s-.402.968-.904.968zm2.439 0c-.5 0-.903-.435-.903-.968 0-.532.402-.967.903-.967s.903.435.903.967-.402.968-.903.968z" />
                </g>
                <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".723" d="M2.669 6.304l-.863 1.522M0 7.2l.798.9h3.718" />
                <path fill="#FFF" d="M5.42 2.54l1.257.61h6.42v-.9a.45.45 0 0 0-.452-.45h-2.218c-.498 0-.878.247-1.14.74H5.419z" />
            </g>
        </SvgIcon>
    );
    case 'tractor': return (
        <SvgIcon {...props} viewBox="0 0 18 12">
            <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 16 1)">
                <g fill="#FFF">
                    <path d="M2.123 6.512a.56.56 0 0 1-.401-.171.592.592 0 0 1-.166-.413v-.321c0-.322.254-.583.567-.583h11.264c.314 0 .567.26.567.583v.321a.575.575 0 0 1-.567.584H2.123z" />
                    <path fillRule="nonzero" d="M3.418 7.405c.238 0 .43-.2.43-.447a.45.45 0 0 0-.125-.315.426.426 0 0 0-.305-.131c-.237 0-.43.2-.43.446a.45.45 0 0 0 .125.315c.08.084.19.131.305.132zm0 .595c-.554 0-1.003-.466-1.003-1.042 0-.575.45-1.042 1.003-1.042.554 0 1.003.467 1.003 1.042C4.42 7.534 3.97 8 3.418 8zm3.438-.595c.237 0 .43-.2.43-.447a.45.45 0 0 0-.125-.315.426.426 0 0 0-.305-.131c-.237 0-.43.2-.43.446a.45.45 0 0 0 .125.315c.08.084.19.131.305.132zm0 .595c-.554 0-1.002-.466-1.002-1.042 0-.575.448-1.042 1.002-1.042s1.003.467 1.003 1.042C7.86 7.534 7.41 8 6.856 8zm5.036-.63c.237 0 .43-.2.43-.446a.45.45 0 0 0-.125-.315.426.426 0 0 0-.305-.131c-.237 0-.43.2-.43.446a.45.45 0 0 0 .125.315c.08.084.19.13.305.131zm0 .596c-.554 0-1.003-.466-1.003-1.042 0-.575.45-1.042 1.003-1.042.554 0 1.002.467 1.002 1.042 0 .576-.448 1.042-1.002 1.042z" />
                    <path fillRule="nonzero" d="M.353 5.024h1.022c.08 0 .145-.067.145-.15a.147.147 0 0 0-.145-.148H.373a.859.859 0 0 1 .78-.691c.152-.01.262-.053.331-.13.121-.135.257-.532.407-1.192a.856.856 0 0 1 .828-.676h1.917V5.35l-2.604.508.19.642H.549a.197.197 0 0 1-.194-.2V5.024zM2.26 2.742l-.294.99a.298.298 0 0 0 .05.268.28.28 0 0 0 .245.107l1.333-.115a.288.288 0 0 0 .26-.29v-.875a.296.296 0 0 0-.083-.206.28.28 0 0 0-.2-.086H2.53a.285.285 0 0 0-.27.207z" />
                </g>
                <path fill="#FFF" fillRule="nonzero" d="M6.533 0H13.3c.387 0 .7.322.7.72v2.96a.71.71 0 0 1-.7.72H6.533a.69.69 0 0 1-.495-.21.73.73 0 0 1-.205-.51V.72a.71.71 0 0 1 .7-.72zM7 1.039V3.36c0 .132.087.239.194.239.108 0 .195-.107.195-.239V1.04C7.389.907 7.302.8 7.194.8 7.087.8 7 .907 7 1.039zm1.556 0V3.36c0 .132.087.239.194.239.107 0 .194-.107.194-.239V1.04C8.944.907 8.857.8 8.75.8c-.107 0-.194.107-.194.239zm1.944 0V3.36c0 .132.087.239.194.239.108 0 .195-.107.195-.239V1.04c0-.132-.087-.239-.195-.239-.107 0-.194.107-.194.239zm1.556 0V3.36c0 .132.087.239.194.239.107 0 .194-.107.194-.239V1.04c0-.132-.087-.239-.194-.239-.107 0-.194.107-.194.239z" />
                <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".525" d="M4.278.4l.778.948V5.2" />
            </g>
        </SvgIcon>
    );
    case 'trailer': return (
        <SvgIcon {...props} viewBox="0 0 18 15">
            <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 16 1)">
                <g fill="#FFF">
                    <path d="M9.61 8.705H.73a.713.713 0 0 1-.516-.224.783.783 0 0 1-.213-.54v-.422c0-.421.327-.763.729-.763h12.484c.402 0 .729.342.729.763v.422c0 .422-.327.764-.73.764H9.611z" />
                    <path fillRule="nonzero" d="M7.315 9.874c.305 0 .553-.262.553-.584a.596.596 0 0 0-.16-.413.543.543 0 0 0-.393-.172c-.305 0-.553.262-.553.585 0 .154.057.302.161.412.104.11.245.172.392.172zm0 .78c-.712 0-1.289-.611-1.289-1.364 0-.753.577-1.364 1.289-1.364s1.29.61 1.29 1.364c0 .753-.578 1.364-1.29 1.364zm3.974-.824c.306 0 .553-.262.553-.585a.596.596 0 0 0-.16-.412.543.543 0 0 0-.393-.173c-.304 0-.552.262-.552.585 0 .155.057.303.16.412.104.11.245.172.393.173zm0 .78c-.711 0-1.289-.612-1.289-1.365 0-.754.578-1.364 1.29-1.364.712 0 1.289.61 1.289 1.364 0 .753-.578 1.364-1.29 1.364z" />
                </g>
                <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".8" d="M2 7.595v2.562" />
                <path fill="#FFF" fillRule="nonzero" d="M.9.178h12.2c.239 0 .468.1.636.276a.966.966 0 0 1 .264.667v3.876c0 .52-.403.943-.9.943H.9a.88.88 0 0 1-.636-.276A.966.966 0 0 1 0 4.997V1.121C0 .601.403.178.9.178zM8 1.538v3.04c0 .173.112.313.25.313s.25-.14.25-.312v-3.04c0-.173-.112-.314-.25-.314s-.25.14-.25.313zm2 0v3.04c0 .173.112.313.25.313s.25-.14.25-.312v-3.04c0-.173-.112-.314-.25-.314s-.25.14-.25.313zm2 0v3.04c0 .173.112.313.25.313s.25-.14.25-.312v-3.04c0-.173-.112-.314-.25-.314s-.25.14-.25.313zm-10 0v3.04c0 .173.112.313.25.313s.25-.14.25-.312v-3.04c0-.173-.112-.314-.25-.314s-.25.14-.25.313zm2 0v3.04c0 .173.112.313.25.313s.25-.14.25-.312v-3.04c0-.173-.112-.314-.25-.314s-.25.14-.25.313zm2 0v3.04c0 .173.112.313.25.313s.25-.14.25-.312v-3.04c0-.173-.112-.314-.25-.314s-.25.14-.25.313z" />
            </g>
        </SvgIcon>
    );
    case 'heavy':
    case 'truck': return (
        <SvgIcon {...props} viewBox="0 0 18 11">
            <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 16 1)">
                <path fill="#FFF" d="M1.135 5.078a.645.645 0 0 1-.476-.206.576.576 0 0 1-.147-.476l.11-.715c.014.002.03.003.046.003h.725a.271.271 0 0 0 .269-.264.271.271 0 0 0-.269-.264H.702l.09-.594a.62.62 0 0 1 .624-.511h11.632c.348 0 .63.267.63.597v.542a.29.29 0 0 0-.137-.034h-.313a.283.283 0 0 0-.25.13.252.252 0 0 0 0 .27.283.283 0 0 0 .25.128h.313a.29.29 0 0 0 .137-.034v.831c0 .33-.282.597-.63.597H1.135z" />
                <path fill="#FFF" fillRule="nonzero" d="M3.48 6a.483.483 0 0 0 .492-.473.483.483 0 0 0-.491-.474.483.483 0 0 0-.492.474c0 .261.22.474.492.474zm0 .633c-.633 0-1.147-.495-1.147-1.107 0-.61.514-1.105 1.148-1.105.633 0 1.147.495 1.147 1.106 0 .61-.514 1.106-1.147 1.106zm7-.632a.483.483 0 0 0 .492-.474.483.483 0 0 0-.491-.474.483.483 0 0 0-.492.474c0 .261.22.474.492.474zm0 .632c-.633 0-1.147-.495-1.147-1.107 0-.61.514-1.105 1.148-1.105.633 0 1.147.495 1.147 1.106 0 .61-.514 1.106-1.147 1.106zM.355 4.42l.813.01v.656H.354A.345.345 0 0 1 0 4.752c0-.089.037-.174.104-.236a.36.36 0 0 1 .25-.095zm13.3 0h-1.21l.355.657h.854A.338.338 0 0 0 14 4.749a.338.338 0 0 0-.347-.328zM4.764 0h1.828a.8.8 0 0 1 .558.223.716.716 0 0 1 .22.534L7.327 2.23c-.01.4-.356.718-.777.717H3.889a.787.787 0 0 1-.671-.364.703.703 0 0 1-.007-.734L4.086.376A.786.786 0 0 1 4.763 0zM4.23 1.3a.352.352 0 0 0 .01.363c.07.111.196.179.332.18h1.65a.38.38 0 0 0 .388-.37v-.368a.38.38 0 0 0-.389-.368H4.781a.392.392 0 0 0-.343.195l-.207.369z" />
            </g>
        </SvgIcon>
    );
    case 'medium':
    case 'van': return (
        <SvgIcon {...props} viewBox="0 0 18 11">
            <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 16 1) scale(0.4)">
                <path fill="#FFF" d="M6.133 5.567a2 2 0 0 1 .51-.953l3.846-4A2 2 0 0 1 11.931 0h21.37a2 2 0 0 1 2 2.054l-.136 4.99c.004.047.006.094.006.143v1.472a.713.713 0 0 0-.352-.092h-.803a.717.717 0 1 0 0 1.433h.803a.713.713 0 0 0 .352-.092v2.255a1.62 1.62 0 0 1-1.62 1.62h-2.244a4.476 4.476 0 0 0-8.614 0h-9.386a4.476 4.476 0 0 0-8.614 0H2.919a1.62 1.62 0 0 1-1.603-1.852l.282-1.94a.766.766 0 0 0 .119.009h1.866a.717.717 0 0 0 0-1.433H1.804l.234-1.613a1.62 1.62 0 0 1 1.604-1.387h2.491zM9.502 4.37A1 1 0 0 0 10.279 6H15a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-3.099a1 1 0 0 0-.776.37l-1.623 2z" />
                <path fill="#FFF" d="M8.95 16.288c.698 0 1.264-.576 1.264-1.287 0-.71-.566-1.286-1.264-1.286-.698 0-1.264.576-1.264 1.286 0 .71.566 1.287 1.264 1.287zm0 1.715C7.32 18.003 6 16.659 6 15c0-1.656 1.32-3 2.95-3 1.63 0 2.95 1.344 2.95 3.001 0 1.658-1.32 3.002-2.95 3.002zm18-1.715c.698 0 1.264-.576 1.264-1.287 0-.71-.566-1.286-1.264-1.286-.698 0-1.264.576-1.264 1.286 0 .71.566 1.287 1.264 1.287zm0 1.715C25.32 18.003 24 16.659 24 15c0-1.656 1.32-3 2.95-3 1.63 0 2.95 1.344 2.95 3.001 0 1.658-1.32 3.002-2.95 3.002zM.91 12l2.09.028v1.779H.91a.91.91 0 0 1-.91-.91A.897.897 0 0 1 .91 12zm34.2 0H32l.912 1.782h2.197a.891.891 0 0 0 0-1.782h.001z" />
            </g>
        </SvgIcon>
    );
    case 'close': return (
        <SvgIcon {...props}>
            <g fill="none" fillRule="evenodd">
                <path fill="#FFF" d="M14 1.4L12.6 0 7 5.6 1.4 0 0 1.4 5.6 7 0 12.6 1.4 14 7 8.4l5.6 5.6 1.4-1.4L8.4 7z" />
                <path d="M19 19H-5V-5h24z" />
            </g>
        </SvgIcon>
    );
    case 'add-kml': return (
        <SvgIcon {...props}>
            <path fill={props.fillcolor || '#FFF'} fillRule="evenodd" d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zm4 11h-3v3H9v-3H6V9h3V6h2v3h3v2z" opacity={props.opacity || '1'} />
        </SvgIcon>
    );
    case 'view-kml': return (
        <SvgIcon {...props}>
            <path fill={props.fillcolor} fillRule="nonzero" d="M0 0v7l2.781-2.781 3 3L7.22 5.78l-3-3L7 0H0zm11 0l2.781 2.781-3 3L12.22 7.22l3-3L18 7V0h-7zM5.781 10.781l-3 3L0 11v7h7l-2.781-2.781 3-3L5.78 10.78zm6.438 0L10.78 12.22l3 3L11 18h7v-7l-2.781 2.781-3-3z" opacity=".632" />
        </SvgIcon>
    );
    case 'delete-kml': return (
        <SvgIcon {...props}>
            <path fill={props.fillcolor || '#000'} fillRule="nonzero" d="M7.043 0a.783.783 0 0 0-.793.783H1.565a.783.783 0 1 0 0 1.565h14.087a.783.783 0 1 0 0-1.565h-4.685A.783.783 0 0 0 10.174 0h-3.13zM1.565 3.913v12.522c0 .865.7 1.565 1.565 1.565h10.957c.865 0 1.565-.7 1.565-1.565V3.913H1.565z" opacity=".632" />
        </SvgIcon>
    );
    case 'delete': return (
        <SvgIcon width="14" height="18" viewBox="0 0 14 18" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M-5-3h24v24H-5z" />
                <path fill={props.fillcolor || '#000'} d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V4H1v12zM14 1h-3.5l-1-1h-5l-1 1H0v2h14V1z" opacity=".54" />
            </g>
        </SvgIcon>
    );
    case 'notifications': return (
        <SvgIcon {...props}>
            <path fill={props.fillcolor} d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z" />
        </SvgIcon>
    );
    case 'inprogress': return (
        <SvgIcon {...props}>
            <g fill="none" fillRule="nonzero" stroke="#007AFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                <path d="M1 11c0 5.523 4.477 10 10 10s10-4.477 10-10S16.523 1 11 1M7.116 1.469c-.73.345-1.45.8-2.139 1.353m-2.31 2.7c-.272.319-.534.656-.783 1.01" />
            </g>
        </SvgIcon>
    );
    case 'error': return (
        <SvgIcon {...props}>
            <path fill={props.fillcolor} d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
        </SvgIcon>
    );
    case 'info': return (
        <SvgIcon {...props}>
            <path fill={props.fillcolor} d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
        </SvgIcon>
    );
    case 'success': return (
        <SvgIcon {...props}>
            <path fill={props.fillcolor} d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
        </SvgIcon>
    );
    case 'list': return (
        <SvgIcon {...props}>
            <path fill={props.fillcolor} d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM7 19h14v-2H7v2zm0-6h14v-2H7v2zm0-8v2h14V5H7z" />
        </SvgIcon>
    );
    case 'locate': return (
        <SvgIcon {...props} width={22} height={22} viewBox="0 0 22 22">
            <path fill={props.fillcolor} fillRule="nonzero" d="M10 0v2.059A8.945 8.945 0 0 0 2.059 10H0v2h2.059A8.945 8.945 0 0 0 10 19.941V22h2v-2.059A8.945 8.945 0 0 0 19.941 12H22v-2h-2.059A8.945 8.945 0 0 0 12 2.059V0h-2zm1 4c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7zM8.707 7.293L7.293 8.707 9.586 11l-2.293 2.293 1.414 1.414L11 12.414l2.293 2.293 1.414-1.414L12.414 11l2.293-2.293-1.414-1.414L11 9.586 8.707 7.293z" />
        </SvgIcon>
    );
    case 'starter': return (
        <SvgIcon {...props} width={18} height={18} viewBox="0 0 18 18">
            <path fill={props.fillcolor} fillRule="nonzero" d="M13.248 0l-1.42 1.41 4.358 4.387 1.418-1.408-1.479-1.49 1.479-1.489L16.186 0l-1.47 1.48L13.249 0zM9.615 2.195L8.197 3.604l5.81 5.851 1.419-1.41-5.81-5.85zm-4.34 1.91a.996.996 0 0 0-.709.286L1.662 7.314a1 1 0 0 0 0 1.409l.752.76-.752.757a1.002 1.002 0 0 0 0 1.41l3.627 3.653c-.817.604-1.94.54-2.684-.21a2.093 2.093 0 0 1-.607-1.378L0 13.82c.051.973.45 1.94 1.188 2.682a4.082 4.082 0 0 0 5.775 0l.465-.455-.002-.002c.003-.003.008-.004.012-.008l.742-.748.744.748a.996.996 0 0 0 1.418 0l2.904-2.926a1.002 1.002 0 0 0 0-1.41l-7.262-7.31a.996.996 0 0 0-.709-.286z" />
        </SvgIcon>
    );
    case 'door': return (
        <SvgIcon {...props} width={18} height={17} viewBox="0 0 18 17">
            <path fill={props.fillcolor} fillRule="nonzero" d="M17.99 2.42c0-1.34-1.06-2.42-2.36-2.42H7.84c-.8 0-1.58.33-2.14.89L.58 6.09c-.37.37-.58.87-.58 1.4v7.09C0 15.92 1.06 17 2.36 17h13.28c1.3 0 2.36-1.08 2.36-2.42l-.01-12.16zM14 12H9v-2h5v2zM2.49 7l4.63-4.7c.19-.19.45-.3.72-.3h7.79c.2 0 .36.19.36.42L16 7H2.49z" />
        </SvgIcon>
    );
    case 'assetImport': return (
        <SvgIcon {...props} width={126} height={126} viewBox="0 0 126 126">
            <g fill="none" fillRule="evenodd">
                <circle cx="62.82" cy="62.82" r="62.82" fill={props.fillcolor} />
                <path fill="#FFF" fillRule="nonzero" d="M30.625 39C29.184 39 28 40.137 28 41.522v35.309c0 1.385 1.184 2.522 2.625 2.522H37C37 84.127 41.031 88 46 88s9-3.873 9-8.647h9.375c1.441 0 2.625-1.137 2.625-2.522V41.522C67 40.137 65.816 39 64.375 39h-33.75zm41.344 6C70.89 45 70 45.84 70 46.881V77.52c0 .772.516 1.422 1.219 1.702.234.09.48.179.75.179H76c0 4.748 4.031 8.6 9 8.6s9-3.852 9-8.6h4.031c.27 0 .516-.078.75-.18a1.89 1.89 0 0 0 1.031-.985c.106-.224.188-.459.188-.716V62.737c0-.369-.164-.671-.375-.985L88.562 45.806C88.2 45.291 87.626 45 86.97 45h-15zM79 50h7.807L95 62H79V50zM46.5 75a4.501 4.501 0 0 1 0 9 4.501 4.501 0 0 1 0-9zm39 0a4.501 4.501 0 0 1 0 9 4.501 4.501 0 0 1 0-9z" />
            </g>
        </SvgIcon>
    );
    case 'addressImport': return (
        <SvgIcon {...props} width={126} height={126} viewBox="0 0 126 126">
            <g fill="none" fillRule="evenodd">
                <circle cx="63" cy="63" r="63" fill={props.fillcolor} />
                <path fill={props.contrastfillcolor ? props.contrastfillcolor : '#FFF'} fillRule="nonzero" d="M52.21 74.206l.45.68c-7.956 1.273-13.39 3.709-13.39 5.39 0 2.25 9.705 5.842 22.164 6.208l3.278 3.76 3.381-3.88c11.22-.734 19.638-3.993 19.638-6.087 0-1.525-4.465-3.668-11.225-5.004.237-.353.473-.71.707-1.067a92.278 92.278 0 0 0 3.454-5.703C89.284 70.727 95 74.779 95 80.277 95 88.93 80.844 94 63.5 94S32 88.93 32 80.277C32 74.334 38.678 70.08 48.48 68a90.414 90.414 0 0 0 3.73 6.206z" />
                <path fill={props.contrastfillcolor ? props.contrastfillcolor : '#FFF'} fillRule="nonzero" d="M39.27 80c0 2.246 10.795 5.91 24.23 5.91 11.038 0 20.294-2.473 23.246-4.601l5.211 4.707C86.887 90.496 76.025 93 63.5 93 46.156 93 32 88.197 32 80zM95 80c0 .19-.008.378-.023.565L94.352 80z" />
                <path fill={props.contrastfillcolor ? props.contrastfillcolor : '#FFF'} d="M64 32c9.471 0 17 7.595 17 17.15C81 61.89 64 81 64 81S47 61.89 47 49.15C47 39.595 54.529 32 64 32zm0 11.025c-3.4 0-6.071 2.695-6.071 6.125S60.6 55.275 64 55.275s6.071-2.695 6.071-6.125S67.4 43.025 64 43.025z" />
            </g>
        </SvgIcon>
    );
    case 'latLngImport': return (
        <SvgIcon {...props} width={126} height={126} viewBox="0 0 126 126">
            <g fill="none" fillRule="evenodd">
                <circle cx="62.82" cy="62.82" r="62.82" fill={props.fillcolor} />
                <circle cx="63.5" cy="62.5" r="41.5" fillRule="nonzero" stroke={props.contrastfillcolor ? props.contrastfillcolor : '#FFF'} strokeWidth="6" />
                <g fillRule="nonzero" stroke={props.contrastfillcolor ? props.contrastfillcolor : '#FFF'} strokeWidth="4">
                    <path d="M63.136 21C50.358 21 40 39.58 40 62.5S50.358 104 63.136 104" />
                    <path d="M62 21c12.778 0 23.136 18.58 23.136 41.5S74.778 104 62 104M63 22v83.408" />
                </g>
                <path fillRule="nonzero" stroke={props.contrastfillcolor ? props.contrastfillcolor : '#FFF'} strokeWidth="4" d="M23.639 60.795h81.94" />
            </g>
        </SvgIcon>
    );
    case 'polygonImport': return (
        <SvgIcon {...props} width={126} height={126} viewBox="0 0 126 126">
            <g fill="none" fillRule="evenodd">
                <circle cx="63" cy="63" r="63" fill={props.fillcolor ? props.fillcolor : '#007AFF'} />
                <g fillRule="nonzero" stroke={props.contrastfillcolor ? props.contrastfillcolor : '#FFF'} transform="translate(25 23)">
                    <path strokeWidth="4" d="M38.5 7L71 30.682 58.586 69H18.414L6 30.682z" />
                    <circle cx="39.284" cy="7.284" r="7.284" fill={props.contrastfillcolor ? props.contrastfillcolor : '#FFF'} />
                    <circle cx="18.284" cy="69.284" r="7.284" fill={props.contrastfillcolor ? props.contrastfillcolor : '#FFF'} />
                    <circle cx="58.284" cy="69.284" r="7.284" fill={props.contrastfillcolor ? props.contrastfillcolor : '#FFF'} />
                    <circle cx="71.284" cy="30.284" r="7.284" fill={props.contrastfillcolor ? props.contrastfillcolor : '#FFF'} />
                    <circle cx="7.284" cy="30.284" r="7.284" fill={props.contrastfillcolor ? props.contrastfillcolor : '#FFF'} />
                </g>
            </g>
        </SvgIcon>
    );
    case 'driverImport': return (
        <SvgIcon {...props} width={126} height={126} viewBox="0 0 126 126">
            <g fill="none" fillRule="evenodd">
                <circle cx="62.82" cy="62.82" r="62.82" fill="#007AFF" />
                <g transform="translate(24 21)">
                    <path fill="#FFF" fillRule="nonzero" d="M29.5 30c28.914 0 29.493 15.504 29.504 34.866v.557l-59.008-.001C-.001 46 .303 30.32 28.624 30.005z" />
                    <ellipse cx="30" cy="17.5" fill="#FFF" fillRule="nonzero" stroke="#007AFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" rx="17" ry="19.5" />
                    <g transform="translate(36 36)">
                        <ellipse cx="23.5" cy="24" fill="#007AFF" rx="23.5" ry="24" />
                        <g fillRule="nonzero" stroke="#FFF" transform="translate(4 4)">
                            <ellipse cx="19" cy="19.5" strokeWidth="4.2" rx="16.9" ry="17.4" />
                            <path fill="#FFF" strokeWidth="1.7" d="M6.338 10.706l10.61 4.841-1.796 4.842-9.936-7.48zM20.247 35.31l1.112-11.61-5.091-.864 1.51 12.344zM31.473 10.706l-10.61 4.841 1.797 4.842 9.935-7.48z" />
                        </g>
                    </g>
                </g>
                <ellipse cx="82.588" cy="80.735" fill="#007AFF" fillRule="nonzero" stroke="#FFF" strokeWidth="3.1" rx="4.038" ry="4.185" />
            </g>
        </SvgIcon>
    );
    case 'userImport': return (
        <SvgIcon {...props} width={126} height={126} viewBox="0 0 126 126">
            <g fill="none" fillRule="evenodd">
                <circle cx="62.82" cy="62.82" r="62.82" fill="#007AFF" />
                <g fill="#FFF" fillRule="nonzero" transform="translate(33 26)">
                    <path d="M29.5 30c28.914 0 29.493 15.504 29.504 34.866v.557l-59.008-.001C-.001 46 .303 30.32 28.624 30.005z" />
                    <ellipse cx="30" cy="17.5" stroke="#007AFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" rx="17" ry="19.5" />
                </g>
            </g>
        </SvgIcon>
    );
    case 'download': return (
        <SvgIcon {...props} width={18} height={18}>
            <g fill="none" fillRule="evenodd">
                <path d="M-3-3h24v24H-3z" />
                <path fill={props.fillcolor} fillRule="nonzero" d="M8.1 0a.9.9 0 0 0-.9.9v7.2H3.6L9 13.5l5.4-5.4h-3.6V.9a.9.9 0 0 0-.9-.9H8.1zM0 16.2V18h18v-1.8H0z" />
            </g>
        </SvgIcon>
    );
    case 'downloadLink': return (
        <SvgIcon {...props} width={20} height={16}>
            <path fill={props.fillcolor} fillRule="nonzero" d="M18 2h-8L8 0H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8 11L6 9h3V6h2v3h3l-4 4z" opacity=".54" />
        </SvgIcon>
    );
    case 'clear': return (
        <SvgIcon {...props} width="18" height="19" viewBox="0 0 18 19">
            <path fill={props.fillcolor} fillRule="evenodd" d="M9 .777c-4.95 0-9 4.05-9 9s4.05 9 9 9 9-4.05 9-9-4.05-9-9-9zm4.5 12.24l-1.26 1.26L9 11.037l-3.24 3.24-1.26-1.26 3.24-3.24-3.24-3.24 1.26-1.26L9 8.517l3.24-3.24 1.26 1.26-3.24 3.24 3.24 3.24z" />
        </SvgIcon>
    );
    case 'dashcamIcon': return (
        <SvgIcon width="22" height="2" viewBox="0 0 22 2" {...props} >
            <path fill={props.fillcolor} d="M937.636 157c-.902 0-1.636.746-1.636 1.664v8.32c0 .917.734 1.663 1.636 1.663h10.637c.902 0 1.636-.746 1.636-1.664v-2.429l4.091 3.328v-10.117l-4.09 3.328v-2.43c0-.917-.735-1.663-1.637-1.663h-10.637z" transform="translate(-936 -157)" />
        </SvgIcon>
    );
    case 'dashcamSearch': return (
        <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18">
            <path xmlns="http://www.w3.org/2000/svg" fill="#FFF" fillRule="evenodd" d="M6.662 0c3.68 0 6.663 3.036 6.663 6.781 0 1.645-.576 3.154-1.533 4.328.148-.003.266.001.266.001.293 0 .666.16.861.355l4.938 4.938c.19.19.196.51.002.703l-.748.748c-.196.196-.509.193-.704-.002l-4.937-4.937c-.19-.19-.352-.57-.35-.838 0 0-.003-.112.001-.257-1.18 1.083-2.744 1.743-4.459 1.743C2.982 13.563 0 10.526 0 6.78S2.983 0 6.662 0zm0 2.087c-2.547 0-4.612 2.101-4.612 4.694s2.065 4.695 4.612 4.695c2.548 0 4.613-2.102 4.613-4.695 0-2.593-2.065-4.694-4.613-4.694z" />
        </SvgIcon>
    );
    case 'offlineDashcam': return (
        <SvgIcon width="22" height="20" viewBox="0 0 27 22" {...props} >
            <title>no-camera</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Map---Asset-Details-Copy" transform="translate(-1088.000000, -60.000000)" fill="#CCCCCC" fillRule="nonzero">
                    <g id="no-camera" transform="translate(1088.000000, 60.000000)">
                        <path d="M2.707031,0 L1.292969,1.414062 L2.585938,2.707031 L2,2.707031 C0.898438,2.707031 0,3.605469 0,4.707031 L0,14.707031 C0,15.808593 0.898438,16.707031 2,16.707031 L15,16.707031 C15.445312,16.707031 15.855469,16.554687 16.1875,16.308593 L19.292969,19.414062 L20.707031,18 L2.707031,0 Z M8.304688,2.707031 L18.914062,13.316406 L22,15.785156 L22,3.628906 L17,7.628906 L17,4.707031 C17,3.605469 16.101562,2.707031 15,2.707031 L8.304688,2.707031 Z" id="Shape" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
    case 'offlineDashcamDark': return (
        <SvgIcon width="22" height="20" viewBox="0 0 27 22" {...props} >
            <title>no-camera dark</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.671564276">
                <g id="Map---Asset-Details-Copy" transform="translate(-1049.000000, -21.000000)" fill="#000000" fillRule="nonzero">
                    <g id="no-camera-dark" transform="translate(1049.000000, 21.000000)">
                        <path d="M2.707031,0 L1.292969,1.414062 L2.585938,2.707031 L2,2.707031 C0.898438,2.707031 0,3.605469 0,4.707031 L0,14.707031 C0,15.808593 0.898438,16.707031 2,16.707031 L15,16.707031 C15.445312,16.707031 15.855469,16.554687 16.1875,16.308593 L19.292969,19.414062 L20.707031,18 L2.707031,0 Z M8.304688,2.707031 L18.914062,13.316406 L22,15.785156 L22,3.628906 L17,7.628906 L17,4.707031 C17,3.605469 16.101562,2.707031 15,2.707031 L8.304688,2.707031 Z" id="Shape" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
    case 'dashcamBookmark': return (
        <SvgIcon {...props} width="16" height="20" viewBox="0 0 16 20">
            <path xmlns="http://www.w3.org/2000/svg" fill="#FFF" d="M2 0c-.138 0-.272.014-.402.041C.686.227 0 1.033 0 2v18l8-3 8 3V2c0-.137-.014-.272-.041-.402C15.799.816 15.184.2 14.402.04 14.272.014 14.138 0 14 0H2z" />
        </SvgIcon>
    );
    case 'dashcamEvents': return (
        <SvgIcon {...props} width="22" height="18" viewBox="0 0 22 18">
            <path xmlns="http://www.w3.org/2000/svg" fill="#000" opacity=".626" fillRule="nonzero" d="M21.239 15.451L12.442.816C12.135.305 11.596 0 11 0S9.865.305 9.559.815L.761 15.451c-.312.519-.32 1.168-.022 1.695.297.527.858.854 1.464.854h17.595c.605 0 1.167-.327 1.464-.854.298-.527.289-1.176-.023-1.695zM12 15h-2v-2h2v2zm0-4h-2V6h2v5z" transform="translate(-1177 -153) translate(1177 153)" />
        </SvgIcon>
    );
    case 'dashcamLiveView': return (
        <SvgIcon {...props} width="22" height="16" viewBox="0 0 22 16">
            <path xmlns="http://www.w3.org/2000/svg" fill={props.fillcolor} d="M11 0C3 0 0 8 0 8s3 8 11 8 11-8 11-8-3-8-11-8zm0 3c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm0 2C9.343 5 8 6.343 8 8s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z" />
        </SvgIcon>
    );
    case 'dashcamEdit': return (
        <SvgIcon {...props} width="19" height="19" viewBox="0 0 19 19">
            <path xmlns="http://www.w3.org/2000/svg" fill="#000" opacity=".626" fillRule="nonzero" d="M15.414 0c-.256 0-.512.097-.707.293L13 2l4 4 1.707-1.707c.391-.391.391-1.024 0-1.414L16.121.293C15.926.097 15.67 0 15.414 0zM11.5 3.5L0 15v4h4L15.5 7.5l-4-4z" />
        </SvgIcon>
    );
    case 'filter': return (
        <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
            <path xmlns="http://www.w3.org/2000/svg" fill={props.fillcolor} d="M0 0v2h18V0H0zm2 4l5 6v8h4v-8l5-6H2z" transform="translate(-1245 -63) translate(1245 63)" />
        </SvgIcon>
    );
    case 'dashcamEvent_powerOn': return (
        <SvgIcon {...props} width="48" height="49" viewBox="0 0 48 49">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-291 -651) translate(291 651.429)">
                        <circle cx="24" cy="24" r="24" fill="#FF83B6" />
                        <g fill="#FFF" fillRule="nonzero">
                            <path d="M17.152.352L12.6 4.903l-2.152-2.151-1.696 1.696.951.952-2.648 2.648c-1.404 1.404-1.404 3.687 0 5.091l1.054 1.055-7.757 7.758 1.696 1.696 7.758-7.757 1.055 1.054c.702.702 1.622 1.053 2.545 1.053.922 0 1.844-.35 2.546-1.053l2.648-2.648.952.951 1.696-1.696-2.151-2.152 4.551-4.552-1.696-1.696L17.4 9.703 14.297 6.6l4.551-4.552L17.152.352z" transform="translate(12 12)" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );

    case 'dashcamEvent_powerOff': return (
        <SvgIcon {...props} width="48" height="49" viewBox="0 0 48 49">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-291 -715) translate(291 715.714)">
                        <circle cx="24" cy="24" r="24" fill="#A4C265" />
                        <g fill="#FFF" fillRule="nonzero">
                            <path d="M1.8.113l6.595 6.595L9.703 5.4l-.951-.952 1.696-1.696L12.6 4.903 17.152.352l1.696 1.696L14.297 6.6 17.4 9.703l4.552-4.551 1.696 1.696-4.551 4.552 2.151 2.152-1.696 1.696-.952-.951-1.309 1.307 6.596 6.596-1.687 1.688-6.603-6.632c-.048.037-.097.073-.147.107L6.637 8.55c.03-.044.061-.088.094-.13L.112 1.8 1.8.112zm4.287 11.262l1.6 1.6 4.688 4.65.295.297c-.664-.137-1.295-.463-1.81-.977l-1.054-1.054-7.758 7.757-1.696-1.696 7.757-7.758-1.054-1.055c-.452-.451-.758-.993-.919-1.568l-.05-.196z" transform="translate(12 12)" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
    case 'dashcamEvent_powerDisconnect': return (
        <SvgIcon {...props} width="48" height="48" viewBox="0 0 48 48">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-291 -780) translate(291 780)">
                        <circle cx="24" cy="24" r="24" fill="#37C1BA" />
                        <g fill="#FFF" fillRule="nonzero">
                            <path d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zM9.9 2.2h2.2V11H9.9V2.2zM11 19.8c-4.852 0-8.8-3.948-8.8-8.8 0-3.685 2.28-6.843 5.5-8.152v2.445C5.731 6.436 4.4 8.563 4.4 11c0 3.64 2.96 6.6 6.6 6.6 3.64 0 6.6-2.96 6.6-6.6 0-2.437-1.331-4.564-3.3-5.707V2.848c3.22 1.309 5.5 4.467 5.5 8.152 0 4.852-3.948 8.8-8.8 8.8z" transform="translate(13 13)" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
    case 'dashcamEvent_buttonPressed': return (
        <SvgIcon {...props} width="48" height="48" viewBox="0 0 48 48">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-291 -330) translate(291 330)">
                        <circle cx="24" cy="24" r="24" fill="#66C6FB" />
                        <g fill="#FFF" fillRule="nonzero">
                            <path d="M8.571 0C5.735 0 3.43 2.307 3.43 5.143c0 1.518.665 2.88 1.714 3.824V5.143c0-1.891 1.538-3.429 3.428-3.429C10.462 1.714 12 3.252 12 5.143v3.824c1.05-.942 1.714-2.305 1.714-3.824C13.714 2.307 11.408 0 8.571 0zm-.107 3.432c-.897.055-1.607.8-1.607 1.71V18c-2.187-1.113-3.081-1.714-4.965-1.714-.857 0-1.892.38-1.892 1.347V18l4.286 2.571 2.878 2.302c.912.73 2.044 1.127 3.212 1.127h5.91c1.893 0 3.428-1.535 3.428-3.429V14.143c0-.678-.529-1.262-1.207-1.284-.36-.012-.625.058-.822.164-.307.164-.688.025-.872-.27-.244-.391-.664-.753-1.384-.753-.429 0-.76.121-1.007.275-.305.19-.69.055-.865-.26-.243-.436-.7-.872-1.557-.872-.532 0-.915.175-1.182.385-.216.17-.532 0-.532-.275V5.235c0-.854-.584-1.646-1.427-1.783-.134-.022-.267-.028-.395-.02z" transform="translate(14 12)" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
    case 'dashcamEvent_vibration': return (
        <SvgIcon {...props} width="48" height="49" viewBox="0 0 48 49">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-291 -458) translate(291 458.571)">
                        <circle cx="24" cy="24" r="24" fill="#936922" />
                        <g fill="#FFF" fillRule="nonzero">
                            <path d="M14.95 0c-.1-.003-.197.006-.3.025L5.975 1.7c-.822.16-1.384.978-1.225 1.8l2.8 14.45c.16.822.978 1.384 1.8 1.225l8.675-1.675c.825-.16 1.384-.975 1.225-1.8l-2.8-14.45C16.31.531 15.66.012 14.95 0zm-.075 1.6l2.8 14.325-8.55 1.675-2.8-14.325 8.55-1.675zm4.9 2.025l-1.4.275 1.65 8.625 1.425-.275-1.675-8.625zm3.3 1.6L21.65 5.5l.85 4.325 1.4-.275-.825-4.325zm-19.1 1.45L2.55 6.95l1.675 8.625 1.4-.275-1.65-8.625zM1.5 9.375L.1 9.65l.825 4.325L2.35 13.7 1.5 9.375zM14.275 13.6l-2.9.55.3 1.575 2.9-.55-.3-1.575z" transform="translate(12 14)" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
    case 'dashcamEvent_distractedDriving': return (
        <SvgIcon {...props} width="48" height="49" viewBox="0 0 48 49">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-291 -522) translate(291 522.857)">
                        <circle cx="24" cy="24" r="24" fill="#5F4B8B" />
                        <g fill="#FFF" fillRule="nonzero">
                            <path d="M1.366.234L.234 1.366l22.4 22.4 1.132-1.132-4.82-4.82c3.276-2.219 4.893-5.32 4.915-5.362.09-.134.139-.29.139-.452 0-.18-.061-.356-.173-.497C23.743 11.37 19.135 4 12 4c-2.024 0-3.84.6-5.412 1.456L1.365.234zM12 6.4c3.093 0 5.6 2.507 5.6 5.6 0 1.258-.424 2.41-1.125 3.344l-2.314-2.314c.15-.312.239-.66.239-1.03 0-1.326-1.074-2.4-2.4-2.4-.37 0-.718.088-1.03.24L8.656 7.524C9.59 6.824 10.742 6.4 12 6.4zm-8.04.86c-2.377 2-3.74 4.17-3.785 4.242C.062 11.643 0 11.819 0 12c0 .155.045.306.13.436 0 .002.002.003.003.005C.147 12.469 4.015 20 12 20c1.48 0 2.816-.261 4.016-.683l-2.07-2.072c-.608.226-1.261.355-1.946.355-3.093 0-5.6-2.507-5.6-5.6 0-.685.13-1.338.355-1.945L3.96 7.26z" transform="translate(12 12)" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
    case 'dashcamEvent_tamper': return (
        <SvgIcon {...props} width="48" height="49" viewBox="0 0 48 49">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-291 -587) translate(291 587.143)">
                        <circle cx="24" cy="24" r="24" fill="#7384DF" />
                        <g fill="#FFF" fillRule="nonzero">
                            <path d="M12 0c-.768 0-1.425.468-1.704 1.134L.278 18.413v.002c-.181.29-.277.627-.278.97 0 1.02.827 1.846 1.846 1.846.043 0 .087-.002.13-.006l.004.006h20.04l.004-.007c.043.003.087.006.13.007 1.02 0 1.846-.827 1.846-1.846 0-.344-.097-.68-.278-.972l-.014-.025-.002-.002L13.704 1.134C13.425.468 12.768 0 12 0zm-1.12 7.716h2.24l-.186 5.975h-1.868l-.186-5.975zm1.124 7.801c.758 0 1.213.409 1.213 1.106 0 .684-.455 1.09-1.213 1.09-.765 0-1.223-.406-1.223-1.09 0-.697.457-1.106 1.223-1.106z" transform="translate(12 10)" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
    case 'dashcamEvent_hardCornering': return (
        <SvgIcon {...props} width="48" height="49" viewBox="0 0 48 49">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-291 -394) translate(291 394.286)">
                        <circle cx="24" cy="24" r="24" fill="#FB9E66" />
                        <g fill="#FFF" fillRule="nonzero">
                            <path d="M1.333 0C.597 0 0 .597 0 1.333c0 .737.597 1.334 1.333 1.334.737 0 1.334-.597 1.334-1.334C2.667.597 2.07 0 1.333 0zm4 0C4.597 0 4 .597 4 1.333c0 .737.597 1.334 1.333 1.334.737 0 1.334-.597 1.334-1.334C6.667.597 6.07 0 5.333 0zm4 0C8.597 0 8 .597 8 1.333c0 .737.597 1.334 1.333 1.334.737 0 1.334-.597 1.334-1.334C10.667.597 10.07 0 9.333 0zm4 0c-.48-.007-.928.246-1.17.661-.243.415-.243.93 0 1.345.242.415.69.667 1.17.66 4.434 0 8 3.567 8 8-.006.482.246.929.661 1.171.416.243.93.243 1.345 0 .415-.242.668-.69.661-1.17C24 4.79 19.209 0 13.333 0zm9.334 13.333c-.737 0-1.334.597-1.334 1.334 0 .736.597 1.333 1.334 1.333.736 0 1.333-.597 1.333-1.333 0-.737-.597-1.334-1.333-1.334zm0 4c-.737 0-1.334.597-1.334 1.334 0 .736.597 1.333 1.334 1.333.736 0 1.333-.597 1.333-1.333 0-.737-.597-1.334-1.333-1.334zm0 4c-.737 0-1.334.597-1.334 1.334 0 .736.597 1.333 1.334 1.333.736 0 1.333-.597 1.333-1.333 0-.737-.597-1.334-1.333-1.334z" transform="translate(12 12)" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );

    case 'dashcamEvent_driverUnbelted': return (
        <SvgIcon {...props} width="48" height="48" viewBox="0 0 48 48">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-291 -973) translate(291 973)">
                        <circle cx="24" cy="24" r="24" fill="#C066D9" />
                        <g fill="#FFF" fillRule="nonzero">
                            <path d="M12 .002c-4.242 0-6 2.721-6 6 0 1.104.527 2.213.527 2.213-.212.122-.56.51-.472 1.197.164 1.283.72 1.61 1.074 1.637C7.264 12.246 8.55 13.777 9 14v2c-.078.235-.211.43-.367.611L20 24h4c0-7-8-5-9-8v-2c.45-.223 1.736-1.754 1.871-2.951.354-.027.91-.354 1.074-1.637.088-.689-.26-1.075-.472-1.197 0 0 .527-1.002.527-2.213 0-2.428-.953-4.5-3-4.5 0 0-.711-1.5-3-1.5zm-3.367 16.61L0 11v2.584l6.455 4.195c.922-.293 1.706-.626 2.178-1.168zm-2.178 1.167C3.768 18.631 0 19.196 0 24h16.025l-9.57-6.22z" transform="translate(12 11)" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );

    case 'dashcamEvent_cellPhoneUse': return (
        <SvgIcon {...props} width="48" height="48" viewBox="0 0 48 48">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-291 -1037) translate(291 1037)">
                        <circle cx="24" cy="24" r="24" fill="#EDAA39" />
                        <path fill="#FFF" fillRule="nonzero" d="M13.366 12.234l-1.132 1.132 22.4 22.4 1.132-1.132L31.2 30.07V14.4c0-.884-.716-1.6-1.6-1.6H18.4c-.884 0-1.6.716-1.6 1.6v1.269l-3.434-3.435zM22.8 13.6h1.6c.22 0 .4.18.4.4 0 .22-.18.4-.4.4h-1.6c-.22 0-.4-.18-.4-.4 0-.22.18-.4.4-.4zm3.2 0c.22 0 .4.18.4.4 0 .22-.18.4-.4.4-.22 0-.4-.18-.4-.4 0-.22.18-.4.4-.4zm-7.6.8h2.4l.331.331c.3.3.707.469 1.131.469h3.476c.424 0 .83-.169 1.13-.469l.332-.331h2.4v14.069l-11.2-11.2V14.4zm-1.6 5.76V33.6c0 .884.716 1.6 1.6 1.6h11.2c.607 0 1.128-.341 1.398-.84l-1.398-1.4v.64H18.4V21.76l-1.6-1.6z" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );

    case 'dashcamEvent_foodDrink': return (
        <SvgIcon {...props} width="48" height="49" viewBox="0 0 48 49">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-291 -908) translate(291 908.429)">
                        <circle cx="24" cy="24" r="24" fill="#4A4A4A" fillOpacity=".83" />
                        <g fill="#FFF" fillRule="nonzero">
                            <path d="M1.366.234L.234 1.366l22.4 22.4 1.132-1.132-6.333-6.332 4.167-1.604C21.6 7.906 16.094 2.4 9.302 2.4L7.698 6.567 1.366.234zM15.2 9.6c1.326 0 2.4 1.074 2.4 2.4 0 1.222-.917 2.22-2.098 2.37l-2.672-2.672c.15-1.181 1.148-2.098 2.37-2.098zm-8.744.198L2.473 20.153c-.33.86.514 1.704 1.374 1.374l10.355-3.983-7.746-7.746zM7.2 14.4c.884 0 1.6.716 1.6 1.6 0 .884-.716 1.6-1.6 1.6-.884 0-1.6-.716-1.6-1.6 0-.884.716-1.6 1.6-1.6z" transform="translate(12 11.571)" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );

    case 'dashcamEvent_smoking': return (
        <SvgIcon {...props} width="48" height="49" viewBox="0 0 48 49">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-291 -844) translate(291 844.143)">
                        <circle cx="24" cy="24" r="24" fill="#7384DF" />
                        <path fill="#FFF" fillRule="nonzero" d="M22 12.857c0 .548-.043 1.651.418 2.787.461 1.136 1.529 2.28 3.328 2.752 1.153.303 2.35.594 3.145 1.002.794.408 1.109.716 1.109 1.46h2c0-1.543-1.055-2.653-2.195-3.24-1.14-.585-2.443-.866-3.551-1.157-1.283-.337-1.714-.907-1.983-1.569-.268-.661-.271-1.44-.271-2.035h-2zm5 0v2c.9 0 1.988.153 2.756.508.768.355 1.18.754 1.248 1.576.044.518.476.916.996.916.667 0 1.06.268 1.418.805.358.536.582 1.362.582 2.195h2c0-1.167-.276-2.341-.918-3.305-.517-.775-1.384-1.29-2.379-1.505-.369-1.148-1.142-2.052-2.11-2.498-1.18-.546-2.493-.692-3.593-.692zM15.707 15.15l-1.414 1.414 5.293 5.293H12v6h13.586l6.707 6.707 1.414-1.414-5.293-5.293h.008l-2-2-2-2-2-2h-.008l-6.707-6.707zm9.541 6.707l2 2 2 2 .752.752v-4.752h-4.752zm6.752 0v6h4v-6h-4z" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );

    case 'dashcamEvent-eventVideo': return (
        <SvgIcon {...props} width="20" height="18" viewBox="0 0 20 18">
            <g fill="none" fillRule="evenodd">
                <g fill="#4A4A4A" fillRule="nonzero">
                    <path d="M1262 179.375l-.77.77-2.163 2.162-.24.241-3.173-3.173h2.115v-4.23c0-.293.237-.53.53-.53h1.057c.03 0 .058.003.087.007l.048.01.04.013.04.016.04.02c.01.006.022.012.032.02.014.008.027.018.04.028l.032.028.029.028.027.032.029.04.02.033.02.04.015.04.012.039c.012.043.018.088.018.135v4.231H1262zM1259.308 165c1.01 0 1.84.782 1.917 1.773l.006.15-.001 11.49h-.385l.001-3.269c0-.823-.667-1.49-1.49-1.49h-1.058l-.136.006c-.759.068-1.354.707-1.354 1.484l-.001 3.27h-3.475l3.894 3.893h-13.303c-1.01 0-1.84-.782-1.917-1.772l-.006-.15v-13.462c0-1.01.782-1.84 1.773-1.917l.15-.006h15.385zm-13.462 13.462h-1.923v1.923h1.923v-1.923zm0-3.847h-1.923v1.923h1.923v-1.923zm0-3.846h-1.923v1.923h1.923v-1.923zm13.462 0h-1.923v1.923h1.923v-1.923zm-13.462-3.846h-1.922v1.923h1.922v-1.923zm13.462 0h-1.923v1.923h1.923v-1.923z" transform="translate(-1242 -165)" />
                </g>
            </g>
        </SvgIcon>
    );

    case 'dashcamEvent_obstruction': return (
        <SvgIcon {...props} width="48" height="49" viewBox="0 0 48 49">
            <g fill="none" fillRule="evenodd">
                <circle fill="#44D7B6" cx="24" cy="24" r="24" />
                <path d="M12 15v5.217h7.304V15H12zm8.348 0v5.217h7.304V15h-7.304zm8.348 0v5.217H36V15h-7.304zM12 21.26v5.218h3.13v-5.217H12zm4.174 0v5.218h7.304v-5.217h-7.304zm8.348 0v5.218h7.304v-5.217h-7.304zm8.348 0v5.218H36v-5.217h-3.13zM12 27.523v5.217h7.304v-5.217H12zm8.348 0v5.217h7.304v-5.217h-7.304zm8.348 0v5.217H36v-5.217h-7.304z" fill="#FFF" fillRule="nonzero" />
            </g>
        </SvgIcon>
    );

    case 'dashcamEvent_tailgating': return (
        <SvgIcon {...props} width="48" height="49" viewBox="0 0 48 49">
            <g fill="none" fillRule="evenodd">
                <circle fill="#1C9278" cx="24" cy="24" r="24" />
                <path d="M30.563 27.35c1.35 0 2.444 1.04 2.444 2.325-.001 1.283-1.095 2.324-2.444 2.325-1.35 0-2.444-1.041-2.444-2.325 0-1.285 1.094-2.326 2.444-2.326zm-16.064 0c1.35 0 2.444 1.04 2.444 2.325-.001 1.283-1.095 2.324-2.444 2.325-1.35 0-2.444-1.041-2.444-2.325 0-1.285 1.094-2.326 2.444-2.326zm-2.39-9c.828-.006 1.6.397 2.04 1.065l2.361 3.58a.508.508 0 0 0 .226.185l2.724 1.183c.594.26.973.828.968 1.45v2.016c0 .121-.014.24-.04.354h.28c.367 0 .666.283.666.634a.647.647 0 0 1-.59.63l-.077.004H19a.702.702 0 0 1-.138-.014l-.08.001h-.808c-.138-1.728-1.653-3.064-3.475-3.064s-3.337 1.336-3.475 3.064H9V18.35h3.11zm26.892 0v11.088h-4.963c-.138-1.728-1.652-3.064-3.475-3.064-1.822 0-3.337 1.336-3.475 3.064h-.442c-.923 0-1.645-.73-1.645-1.61v-1.765c-.005-.715.494-1.345 1.219-1.537l5.074-1.321a.513.513 0 0 0 .273-.178l3.036-3.784a2.453 2.453 0 0 1 1.913-.892h2.485zM20 16c.341 0 .623.245.662.56l.004.074v5.97c0 .35-.298.634-.666.634a.656.656 0 0 1-.663-.56l-.004-.074v-5.97c0-.35.298-.634.667-.634zm5.333 0c.342 0 .624.245.662.56l.005.074v5.97c0 .35-.299.634-.667.634a.656.656 0 0 1-.662-.56l-.005-.074v-5.97c0-.35.299-.634.667-.634zm-1.667 2.985c.368 0 .667.284.667.634a.647.647 0 0 1-.589.63l-.078.005h-2c-.368 0-.666-.284-.666-.635 0-.325.257-.593.588-.63l.078-.004h2z" fill="#FFF" fillRule="nonzero" />
            </g>
        </SvgIcon>
    );

    case 'dashcamEvent_hardAcceleration': return (
        <SvgIcon {...props} width="48" height="49" viewBox="0 0 48 49">
            <g fill="none" fillRule="evenodd">
                <circle fill="#BD6A08" cx="24" cy="24" r="24" />
                <path d="M24 12c-6.614 0-12 5.518-12 12.294 0 2.16.557 4.284 1.608 6.147.193.35.551.559.943.559H33.45c.392 0 .75-.21.943-.559A12.495 12.495 0 0 0 36 24.294C36 17.518 30.614 12 24 12zm3.75 4.47c.602 0 1.09.501 1.09 1.118 0 .617-.488 1.118-1.09 1.118-.602 0-1.09-.5-1.09-1.118 0-.617.488-1.117 1.09-1.117zM24 15.354c.602 0 1.09.5 1.09 1.118 0 .617-.488 1.117-1.09 1.117-.602 0-1.09-.5-1.09-1.117s.488-1.118 1.09-1.118zm-8.727 8.941c0-.617.488-1.118 1.09-1.118.603 0 1.092.501 1.092 1.118 0 .617-.49 1.118-1.091 1.118-.603 0-1.091-.5-1.091-1.118zm2.182 5.03c-.603 0-1.091-.501-1.091-1.118 0-.617.488-1.118 1.09-1.118.603 0 1.091.5 1.091 1.118 0 .617-.488 1.118-1.09 1.118zm0-7.824c-.603 0-1.091-.5-1.091-1.118 0-.617.488-1.117 1.09-1.117.603 0 1.091.5 1.091 1.117s-.488 1.118-1.09 1.118zm2.727-2.794c-.602 0-1.091-.5-1.091-1.118 0-.617.489-1.117 1.09-1.117.603 0 1.092.5 1.092 1.117s-.489 1.118-1.091 1.118zM24 26.529c-1.205 0-2.182-1-2.182-2.235 0-1.234.977-2.235 2.182-2.235.398 0 .767.116 1.09.308l5.5-3.219 1.08 1.939-5.488 3.219c-.006 1.228-.983 2.223-2.182 2.223zm6.545 3.109c-.602 0-1.09-.5-1.09-1.118 0-.617.488-1.117 1.09-1.117.603 0 1.091.5 1.091 1.117s-.488 1.118-1.09 1.118zm1.091-4.226c-.602 0-1.09-.5-1.09-1.118 0-.617.488-1.118 1.09-1.118.603 0 1.091.501 1.091 1.118 0 .617-.488 1.118-1.09 1.118z" fill="#FFF" fillRule="nonzero" />
            </g>
        </SvgIcon>
    );

    case 'dashcamEvent_hardBrake': return (
        <SvgIcon {...props} width="48" height="49" viewBox="0 0 48 49">
            <g fill="none" fillRule="evenodd">
                <circle fill="#D34A05" cx="24" cy="24" r="24" />
                <path d="M24.5 10v6.855a8.162 8.162 0 0 1 6.645 6.645H38c-.127-1.216-.717-5.512-4.352-9.148-3.636-3.635-7.932-4.225-9.148-4.352zm-1.35 2.7C16.456 12.7 11 18.156 11 24.85S16.456 37 23.15 37 35.3 31.544 35.3 24.85h-5.4a6.752 6.752 0 0 0-6.75-6.75v-5.4zm0 8.1a4.042 4.042 0 0 1 4.05 4.05 4.042 4.042 0 0 1-4.05 4.05 4.042 4.042 0 0 1-4.05-4.05 4.042 4.042 0 0 1 4.05-4.05zm0 2.7a1.35 1.35 0 1 0 0 2.7 1.35 1.35 0 0 0 0-2.7z" fill="#FFF" fillRule="nonzero" />
            </g>
        </SvgIcon>
    );


    case 'dashcamEvent_unauthorizeMovement': return (
        <SvgIcon {...props} width="48" height="49" viewBox="0 0 48 49">
            <g fill="none" fillRule="evenodd">
                <circle fill="#1D448E" cx="24" cy="24" r="24" />
                <path d="M16.364 13c-.603 0-1.091.498-1.091 1.111v1.667a.537.537 0 0 1-.546.555.537.537 0 0 1-.545-.555H12c0 1.522 1.233 2.778 2.727 2.778 1.438 0 2.574-1.187 2.665-2.622l4.176 5.955h-8.477c-.602 0-1.091.498-1.091 1.111v5.556c0 .613.489 1.11 1.09 1.11h1.149C14.506 31.548 16.079 33 18 33c1.92 0 3.494-1.453 3.761-3.333h4.478C26.506 31.547 28.079 33 30 33c1.92 0 3.494-1.453 3.761-3.333h1.148c.602 0 1.091-.498 1.091-1.111v-7.778c0-.295-.114-.579-.318-.787l-4.364-4.445a1.082 1.082 0 0 0-.773-.324H24c-.602 0-1.09.498-1.09 1.111v3.64l-4.57-6.51a1.087 1.087 0 0 0-.885-.463h-1.091zm8.727 4.444h5.006l3.721 3.791v.654h-8.727v-4.445zm-7.091 10c.903 0 1.636.747 1.636 1.667s-.733 1.667-1.636 1.667c-.903 0-1.636-.747-1.636-1.667s.733-1.667 1.636-1.667zm12 0c.903 0 1.636.747 1.636 1.667s-.733 1.667-1.636 1.667c-.903 0-1.636-.747-1.636-1.667s.733-1.667 1.636-1.667z" fill="#FFF" fillRule="nonzero" />
            </g>
        </SvgIcon>
    );

    case 'dashcamEvent_devicePowerDisconnect': return (
        <SvgIcon {...props} width="48" height="48" viewBox="0 0 48 48">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-291 -780) translate(291 780)">
                        <circle cx="24" cy="24" r="24" fill="#FF5722" />
                        <g fill="#FFF" fillRule="nonzero">
                            <path d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zM9.9 2.2h2.2V11H9.9V2.2zM11 19.8c-4.852 0-8.8-3.948-8.8-8.8 0-3.685 2.28-6.843 5.5-8.152v2.445C5.731 6.436 4.4 8.563 4.4 11c0 3.64 2.96 6.6 6.6 6.6 3.64 0 6.6-2.96 6.6-6.6 0-2.437-1.331-4.564-3.3-5.707V2.848c3.22 1.309 5.5 4.467 5.5 8.152 0 4.852-3.948 8.8-8.8 8.8z" transform="translate(13 13)" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );

    case 'dashcamEvent_collision': return (
        <SvgIcon {...props} width="48" height="49" viewBox="0 0 48 49">
            <g fill="none" fillRule="evenodd">
                <circle fill="#9C2525" cx="24" cy="24" r="24" />
                <path d="m28.711 23.632.01-.026c.611-1.59 1.128-2.906 1.258-3.163.334-.642 1.315-.977 1.962-1.197l.052-.02c.01-.006.997-.43 4.007-.47V17.71c-3.052.041-4.184.454-4.414.554-.809.277-2.014.69-2.536 1.704-.35.685-2.666 6.874-2.932 7.574l-.031.09v6.8c0 .862.704 1.568 1.565 1.568h2.087c.861 0 1.565-.706 1.565-1.568v-1.046H36v-7.84h-5.74c-.886 0-1.512-.21-1.72-.523-.246-.361.015-1.004.14-1.318l.031-.073zm.506 4.004h2.087c.866 0 1.566.7 1.566 1.568 0 .868-.7 1.569-1.566 1.569-.876 0-1.142-.225-2.045-.743-.605-.34-1.085-.663-1.085-1.348 0-.575.47-1.046 1.043-1.046zm-3.75-1.322c.098-1.291-1.467.277-1.467.277s-.083-1.872-.522-2.091c-1.043-.523-2.316 1.077-2.316 1.077l-2.38-3.168a2.605 2.605 0 0 0-2.086-1.046H12v12.023h6.83A3.135 3.135 0 0 0 21.913 36a3.135 3.135 0 0 0 3.084-2.614h.046v-5.943l.068-.178.032-.089c.083-.214.313-.815.339-.894-.005-.005-.01.006-.016.032zm-3.554 8.64a2.082 2.082 0 0 1-2.087-2.09c0-1.16.929-2.091 2.087-2.091 1.158 0 2.087.93 2.087 2.09 0 1.161-.929 2.092-2.087 2.092zm5.586-15.006-2.24-2.244 1.719-1.721a.523.523 0 0 0-.472-.882l-2.334.467-1.368-1.37a.52.52 0 0 0-.864.204l-.313.939-3.611-2.262a.522.522 0 0 0-.72.72l2.42 3.879-1.35 1.804a.523.523 0 0 0 .417.836h2.255l.912 2.285a.523.523 0 0 0 .97 0l.89-2.232 3.247.465a.524.524 0 0 0 .442-.887z" fill="#FFF" fillRule="nonzero" />
            </g>
        </SvgIcon>
    );

    case 'dashcamEvent-eventSnapshot': return (
        <SvgIcon {...props} width="20" height="18" viewBox="0 0 20 18">
            <g fill="none" fillRule="evenodd">
                <g fill="#4A4A4A" fillRule="nonzero">
                    <path d="M1259.356 174.615c.292 0 .529.237.529.53v4.23H1262l-3.173 3.173-.549-.548-2.624-2.625h2.115v-4.231c0-.26.187-.475.434-.52l.095-.009zM1260 166c1.055 0 1.918.815 1.995 1.85l.005.15v10.375h-1.116v-3.23c0-.845-.684-1.53-1.528-1.53h-1.058l-.14.007c-.778.07-1.389.725-1.389 1.522v.881l-2.269-3.024-3.5 4.509-2.501-3.009L1245 179h8.864l3 3H1244c-1.055 0-1.918-.815-1.995-1.85L1242 180v-12c0-1.055.815-1.918 1.85-1.995l.15-.005h16zm-10 4c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z" transform="translate(-1242 -166)" />
                </g>
            </g>
        </SvgIcon>
    );

    case 'dashcamEventSettings': return (
        <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18">
            <g fill="none" fillRule="evenodd" opacity=".67">
                <g fill="#000000" fillRule="nonzero">
                    <g>
                        <path d="M18.425 12.9c0-.27.091-.63.091-.9s0-.63-.091-.9l1.891-1.35c.18-.091.27-.359.092-.63l-1.8-3.15c-.092-.27-.362-.361-.542-.27l-2.158.991c-.45-.362-1.083-.72-1.621-.9l-.27-2.341c0-.27-.18-.45-.45-.45h-3.6c-.271 0-.45.18-.45.45L9.245 5.7c-.63.27-1.171.541-1.621.9l-2.159-.9c-.18-.091-.45 0-.541.18l-1.8 3.15c-.18.27-.18.54.091.629L5.108 11.1c0 .27-.092.63-.092.9s0 .63.092.9l-1.892 1.35c-.18.091-.27.359-.091.63l1.8 3.15c.091.27.362.361.541.27l2.159-.991c.45.362 1.083.72 1.62.9l.271 2.341c0 .27.18.45.45.45h3.6c.27 0 .45-.18.45-.45l.27-2.341c.63-.268 1.172-.538 1.622-.9l2.158.991c.18.091.45 0 .542-.18l1.8-3.15c.088-.179.088-.45-.092-.629L18.425 12.9zm-6.659 2.7c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z" transform="translate(-27 -413) translate(24 410)" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );

    default: return (
        <SvgIcon {...props} viewBox="-1 0 24 24">
            <defs>
                <filter id="defult_filter" width="160%" height="158.5%" x="-30%" y="-29.3%" filterUnits="objectBoundingBox">
                    <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <path fill={props.fillcolor} fillRule="evenodd" d="M20 11c-3.87 0-7 3.13-7 7 0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z" filter="url(#defult_filter)" transform="translate(-9 -9)" />
        </SvgIcon>
    );
    }
}

export const getArrowIcon = (heading: number = 0, type: string, tripColor: string = 'rgb(158, 158, 158)', speed: number = 0) => {
    if (speed === 0) {
        return (
            <SvgIcon viewBox="0 0 32 32" style={{ height: type === 'selected' ? 'auto' : '20px' }}>
                <circle cx="37" cy="58" r="9" fill={tripColor} fillRule="evenodd" stroke="#FFF" strokeWidth={type === 'selected' ? 3 : 1.6} transform="translate(-21 -42)" />
            </SvgIcon>
        );
    }

    return (
        <SvgIcon viewBox="0 0 32 32" style={{ transform: `rotate(${heading}deg)`, height: type === 'selected' ? 'auto' : '20px' }}>
            <defs>
                <linearGradient id="prefix__b" x1="50%" x2="50%" y1="0%" y2="99.021%">
                    <stop offset="0%" stopOpacity="0" />
                    <stop offset="80%" stopOpacity=".02" />
                    <stop offset="100%" stopOpacity=".04" />
                </linearGradient>
                <linearGradient id="prefix__c" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity=".12" />
                    <stop offset="20%" stopColor="#FFF" stopOpacity=".06" />
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0" />
                </linearGradient>
                <path id="prefix__a" d="M49 99L59.5 75 70 99 59.5 95.889z" />
            </defs>
            <g fill="none" fillRule="evenodd" transform="translate(-46 -71)">
                <use fill={tripColor} xlinkHref="#prefix__a" />
                <path stroke="url(#prefix__b)" strokeLinejoin="square" strokeWidth=".5" d="M49.445 98.608l9.984-2.959h.142l9.984 2.959L59.5 75.624 49.445 98.608z" />
                <path stroke="url(#prefix__c)" strokeLinejoin="square" strokeWidth=".5" d="M49.445 98.608l9.984-2.959h.142l9.984 2.959L59.5 75.624 49.445 98.608z" />
                <use stroke="#FFF" strokeWidth={type === 'selected' ? 3 : 1.6} xlinkHref="#prefix__a" />
            </g>
        </SvgIcon>
    );
};

export const getTripStopIcon = (size) => {
    const icon = size === 'small' ?
        (
            <SvgIcon viewBox="0 0 25 25">
                <defs>
                    <filter id="stopIconFilter" width="213.3%" height="213.3%" x="-56.7%" y="-43.3%" filterUnits="objectBoundingBox">
                        <feMorphology in="SourceAlpha" operator="dilate" radius="1.5" result="shadowSpreadOuter1" />
                        <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
                        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.301409528 0" />
                    </filter>
                    <circle id="stopIconCircle" cx="7.5" cy="7.5" r="7.5" />
                </defs>
                <g fill="none" fillRule="evenodd" transform="translate(5 3)">
                    <use fill="#000" filter="url(#stopIconFilter)" xlinkHref="#stopIconCircle" />
                    <use fill="#FFF" stroke="#FC0207" strokeWidth="3" xlinkHref="#stopIconCircle" />
                    <g fill="#000" fillRule="nonzero">
                        <path d="M0 0v9h.875V5.85h5.25c.482 0 .875-.404.875-.9V.9c0-.496-.393-.9-.875-.9H0zm2.188.9H3.5v1.35h1.313V.9h1.312v1.35H4.812V3.6h1.313v1.35H4.812V3.6H3.5v1.35H2.187V3.6H.875V2.25h1.313V.9zm0 1.35V3.6H3.5V2.25H2.187z" transform="translate(4 3)" />
                    </g>
                </g>
            </SvgIcon>
        ) :
        (
            <SvgIcon style={{ width: '46px', height: '58px', margin: '-12px' }} viewBox="0 0 46 58">
                <defs>
                    <linearGradient id="stopLinearGradient1" x1="50%" x2="50%" y1="0%" y2="99.021%">
                        <stop offset="0%" stopOpacity="0" />
                        <stop offset="80%" stopOpacity=".02" />
                        <stop offset="100%" stopOpacity=".04" />
                    </linearGradient>
                    <linearGradient id="stopLinearGradient2" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0%" stopColor="#FFF" stopOpacity=".12" />
                        <stop offset="20%" stopColor="#FFF" stopOpacity=".06" />
                        <stop offset="100%" stopColor="#FFF" stopOpacity="0" />
                    </linearGradient>
                    <filter id="stopFilter1" width="318.2%" height="372.7%" x="-109.1%" y="-109.1%" filterUnits="objectBoundingBox">
                        <feOffset dy="12" in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6" />
                        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.237601902 0" />
                        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
                        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="6" />
                        <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2" />
                        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1" />
                            <feMergeNode in="shadowMatrixOuter2" />
                        </feMerge>
                    </filter>
                    <circle id="stopCircle" cx="11" cy="11" r="11" />
                </defs>
                <g fill="none" fillRule="evenodd" transform="translate(12 12)">
                    <use fill="#000" filter="url(#stopFilter1)" xlinkHref="#stopCircle" />
                    <use fill="#FFF" xlinkHref="#stopCircle" />
                    <circle cx="11" cy="11" r="10.75" stroke="url(#stopLinearGradient1)" strokeLinejoin="square" strokeWidth=".5" />
                    <circle cx="11" cy="11" r="10.75" stroke="url(#stopLinearGradient2)" strokeLinejoin="square" strokeWidth=".5" />
                    <use stroke="#FC0207" strokeWidth="3" xlinkHref="#stopCircle" />
                    <g fill="#000" fillRule="nonzero">
                        <path d="M0 0v13.2h1.283V8.58h7.7c.707 0 1.284-.593 1.284-1.32V1.32C10.267.593 9.69 0 8.983 0H0zm3.208 1.32h1.925V3.3h1.925V1.32h1.925V3.3H7.058v1.98h1.925v1.98H7.058V5.28H5.133v1.98H3.208V5.28H1.283V3.3h1.925V1.32zm0 1.98v1.98h1.925V3.3H3.208z" transform="translate(5.867 4.4)" />
                    </g>
                </g>
            </SvgIcon>
        );
    return icon;
};

export const getTripStartIcon = (size) => {
    const icon = size === 'small' ?
        (
            <SvgIcon viewBox="0 0 25 25">
                <defs>
                    <filter id="startFilter" width="213.3%" height="213.3%" x="-56.7%" y="-43.3%" filterUnits="objectBoundingBox">
                        <feMorphology in="SourceAlpha" operator="dilate" radius="1.5" result="shadowSpreadOuter1" />
                        <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
                        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.299825175 0" />
                    </filter>
                    <circle id="startCircle" cx="7.5" cy="7.5" r="7.5" />
                </defs>
                <g fill="none" fillRule="evenodd" transform="translate(5 3)">
                    <use fill="#000" filter="url(#startFilter)" xlinkHref="#startCircle" />
                    <use fill="#FFF" stroke="#7ED321" strokeWidth="3" xlinkHref="#startCircle" />
                    <g fill="#000" fillRule="nonzero">
                        <path d="M0 0v9h.875V5.85h5.25c.482 0 .875-.404.875-.9V.9c0-.496-.393-.9-.875-.9H0zm2.188.9H3.5v1.35h1.313V.9h1.312v1.35H4.812V3.6h1.313v1.35H4.812V3.6H3.5v1.35H2.187V3.6H.875V2.25h1.313V.9zm0 1.35V3.6H3.5V2.25H2.187z" transform="translate(4 3)" />
                    </g>
                </g>
            </SvgIcon>
        ) :
        (
            <SvgIcon style={{ width: '46px', height: '58px', margin: '-12px' }} viewBox="0 0 46 58">
                <defs>
                    <linearGradient id="startLinearGradient1" x1="50%" x2="50%" y1="0%" y2="99.021%">
                        <stop offset="0%" stopOpacity="0" />
                        <stop offset="80%" stopOpacity=".02" />
                        <stop offset="100%" stopOpacity=".04" />
                    </linearGradient>
                    <linearGradient id="startLinearGradient2" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0%" stopColor="#FFF" stopOpacity=".12" />
                        <stop offset="20%" stopColor="#FFF" stopOpacity=".06" />
                        <stop offset="100%" stopColor="#FFF" stopOpacity="0" />
                    </linearGradient>
                    <filter id="startFilter" width="318.2%" height="372.7%" x="-109.1%" y="-109.1%" filterUnits="objectBoundingBox">
                        <feOffset dy="12" in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6" />
                        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.237601902 0" />
                        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
                        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="6" />
                        <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2" />
                        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1" />
                            <feMergeNode in="shadowMatrixOuter2" />
                        </feMerge>
                    </filter>
                    <circle id="startCicle" cx="11" cy="11" r="11" />
                </defs>
                <g fill="none" fillRule="evenodd" transform="translate(12 12)">
                    <use fill="#000" filter="url(#startFilter)" xlinkHref="#startCicle" />
                    <use fill="#FFF" xlinkHref="#startCicle" />
                    <circle cx="11" cy="11" r="10.75" stroke="url(#startLinearGradient1)" strokeLinejoin="square" strokeWidth=".5" />
                    <circle cx="11" cy="11" r="10.75" stroke="url(#startLinearGradien2)" strokeLinejoin="square" strokeWidth=".5" />
                    <use stroke="#7ED321" strokeWidth="3" xlinkHref="#startCicle" />
                    <g fill="#000" fillRule="nonzero">
                        <path d="M0 0v13.2h1.283V8.58h7.7c.707 0 1.284-.593 1.284-1.32V1.32C10.267.593 9.69 0 8.983 0H0zm3.208 1.32h1.925V3.3h1.925V1.32h1.925V3.3H7.058v1.98h1.925v1.98H7.058V5.28H5.133v1.98H3.208V5.28H1.283V3.3h1.925V1.32zm0 1.98v1.98h1.925V3.3H3.208z" transform="translate(5.867 4.4)" />
                    </g>
                </g>
            </SvgIcon>
        );
    return icon;
};

export const Star = (props: Object) => (
    <SvgIcon width="18" height="17" viewBox="0 0 18 17" {...props}>
        <path fill={props.color || '#000'} fillRule="nonzero" d="M9.492.922l1.848 5.472 5.775.067a.519.519 0 0 1 .304.935l-4.633 3.448 1.72 5.514a.519.519 0 0 1-.795.577L9 13.595l-4.712 3.34a.52.52 0 0 1-.795-.578l1.72-5.513L.58 7.395a.519.519 0 0 1 .305-.935l5.774-.067L8.508.921a.52.52 0 0 1 .984 0z" />
    </SvgIcon>
);

export const AssetNonReporting = (props: Object) => (
    <SvgIcon width="17" height="16" viewBox="0 0 17 16" {...props}>
        <g fill="none" fillRule="evenodd">
            <ellipse cx="8.5" cy="8" fill="#FFF" rx="8.5" ry="8" />
            <circle cx="8.5" cy="8" r="5.75" stroke="#6D7278" strokeWidth="1.5" />
            <path stroke="#6D7278" strokeWidth="1.5" d="M4.154 3.603L13.112 12.561" />
        </g>
    </SvgIcon>
);

export const Setting = (props: Object) => (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
        <g fill="none" fillRule="evenodd">
            <g fill={props.fillcolor || '#FFF'} fillRule="nonzero">
                <g>
                    <path d="M6.101 0l-.4 2.019c-.669.248-1.286.595-1.829 1.033l-1.973-.67L0 5.618l1.579 1.349c-.056.337-.092.68-.092 1.034 0 .353.036.697.092 1.034L0 10.383l1.899 3.234 1.973-.669c.543.438 1.16.785 1.829 1.033L6.1 16H9.9l.4-2.019c.669-.248 1.286-.595 1.829-1.033l1.973.67L16 10.382l-1.579-1.349c.056-.337.092-.68.092-1.034 0-.353-.036-.697-.092-1.034L16 5.617l-1.899-3.234-1.973.669c-.543-.438-1.16-.785-1.829-1.033L9.9 0H6.1zM8 4.8c1.798 0 3.257 1.433 3.257 3.2 0 1.767-1.459 3.2-3.257 3.2S4.743 9.767 4.743 8c0-1.767 1.459-3.2 3.257-3.2z" transform="translate(-777 -338) translate(777 338)" />
                </g>
            </g>
        </g>
    </SvgIcon>
);

export const Download = (props: Object) => (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
        <g fill="none" fillRule="evenodd">
            <g fill="#FFF" fillRule="nonzero">
                <g>
                    <path d="M7.2 0c-.442 0-.8.358-.8.8v6.4H3.2L8 12l4.8-4.8H9.6V.8c0-.442-.358-.8-.8-.8H7.2zM0 14.4V16h16v-1.6H0z" transform="translate(-777 -403) translate(777 403)" />
                </g>
            </g>
        </g>
    </SvgIcon>
);

export const FullScreen = (props: Object) => (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
        <g fill="none" fillRule="evenodd">
            <g fill="#FFF" fillRule="nonzero">
                <path d="M810.778 338c-.972 0-1.778.806-1.778 1.778v3.555h1.778v-3.555h3.555V338h-3.555zm8.889 0v1.778h3.555v3.555H825v-3.555c0-.972-.806-1.778-1.778-1.778h-3.555zM809 348.667v3.555c0 .972.806 1.778 1.778 1.778h3.555v-1.778h-3.555v-3.555H809zm14.222 0v3.555h-3.555V354h3.555c.972 0 1.778-.806 1.778-1.778v-3.555h-1.778z" transform="translate(-809 -338)" />
            </g>
        </g>
    </SvgIcon>
);

export const Play = (props: Object) => (
    <SvgIcon width="95" height="95" viewBox="0 0 95 95" {...props}>
        <g fill="none" fillRule="evenodd" opacity=".63">
            <g fill="#FFF" fillRule="nonzero">
                <path d="M591.444 184h-73.888c-5.822 0-10.556 4.734-10.556 10.556v73.888c0 5.822 4.734 10.556 10.556 10.556h73.888c5.822 0 10.556-4.734 10.556-10.556v-73.888c0-5.822-4.734-10.556-10.556-10.556zm-15.833 47.5l-36.944 21.111V210.39L575.61 231.5z" transform="translate(-507 -184)" />
            </g>
        </g>
    </SvgIcon>
);

export const PauseButton = (props: Object) => (
    <SvgIcon width="95" height="95" viewBox="0 0 95 95" {...props}>
        <g fill="none" fillRule="evenodd" opacity=".63">
            <g fill="#FFF" fillRule="nonzero">
                <g>
                    <path d="M83.69 0H11.31C5.067 0 0 5.067 0 11.31v72.38C0 89.933 5.067 95 11.31 95h72.38C89.933 95 95 89.933 95 83.69V11.31C95 5.067 89.933 0 83.69 0zM30 65V29h11v36H30zm24 0V29h11v36H54z" transform="translate(-394 -182) translate(394 182)" />
                </g>
            </g>
        </g>
    </SvgIcon>
);

export const Delete = (props: Object) => (
    <SvgIcon width="17" height="20" viewBox="0 0 17 20" {...props}>
        <g fill="none" fillRule="evenodd">
            <g fill={props.fillColor || '#007AFF'} fillRule="nonzero">
                <path d="M580.667 87l-1 1h-5v2h16v-2h-5l-1-1h-4zm-5 5v13c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V92h-14zm3 2h2v11h-2V94zm6 0h2v11h-2V94z" transform="translate(-574 -87)" />
            </g>
        </g>
    </SvgIcon>
);

export const ReCalibration = (props: Object) => (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" {...props}>
        <path d="M7.2 0a2.697 2.697 0 0 0-2.535 1.799H0v1.8h4.665A2.699 2.699 0 0 0 7.199 5.4c1.489 0 2.7-1.211 2.7-2.7C9.9 1.212 8.688 0 7.2 0zm4.5 1.799v1.8H18V1.8h-6.3zm0 4.5a2.696 2.696 0 0 0-2.535 1.802H0v1.798h9.165A2.696 2.696 0 0 0 11.7 11.7c1.489 0 2.7-1.212 2.7-2.7 0-1.488-1.211-2.7-2.7-2.7zm4.501 1.802v1.798H18V8.101h-1.799zM4.499 12.6a2.699 2.699 0 0 0-2.535 1.8H0v1.801h1.964A2.697 2.697 0 0 0 4.5 18c1.489 0 2.7-1.212 2.7-2.7 0-1.489-1.211-2.7-2.7-2.7zM9 14.4v1.801h9v-1.8H9z" fill="#000" fillRule="nonzero" opacity=".626" />
    </SvgIcon>
);

export default GetSvgIcon;
