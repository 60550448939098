/* @flow */

import React, { Component } from 'react';
import moment from 'moment';
import { Popover, Tooltip } from '@mui/material';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.scorecardsList';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../components/SharedComponents/ListComponent/ListComponent';
import MapsWrapper from '../MapsWrapper';
import {
    getParamValue,
    downloadFile,
    convertDateTime,
    getHeaderKeys,
    getUserSettingTimeZone,
    getDistanceStringLong,
    getTimeFormatAsperUserSettting,
    getISODateTrunc,
    getSpeedUnitString,
    roundOffUnitValueAsPerUserSetting,
    getData,
} from '../../helper-classes/utility-functions';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    showHideColumns,
    rearrangeColumns, isUpdatePreference,
} from '../../helper-classes/listUtil';
import { getTitle } from './../../helper-classes/utility-react-functions';
import { getURL } from './epics.scorecardsList';
import AppLoader from '../../components/AppLoader';
import config, { RECORDS_PER_PAGE } from '../../constants/Config';
import { INFRACTION_CENTRIC_SCORECARD_PREF_KEY } from './constants.scorecardsList';
import styles from './ScoreCardList.module.scss';
import analytics from '../../analytics';

export type Props = {
    isUpdating: boolean,
    listType: string,
    fetchScoreCardsList: Function,
    resetTable: Function,
    history: {
        push: Function,
    },
    location: any,
};

export type State = {
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{
            violationBegin: string,
        }>,
        totalRecords: number,
    },
    landmark: Object,
    order: string,
    orderBy: string,
    showDropdown: false,
    loading: boolean,
    rowsPerPage: number,
    pageNumber: number,
    openExport: boolean,
    selectedOption: Object,
    showListing: boolean,
    isPopoverOpen: boolean,
    anchorEl: EventTarget | null,
};

class ScorecardsList extends Component<Props, State> {
    isInitialRender: boolean;
    iframeUserToken: string;
    isIframe: boolean;
    userPreferences: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            showListing: false,
            tableData: {
                ischeckBox: false,
                isGroup: false,
                listHeaders: this.getListHeader(),
                listData: [],
                totalRecords: 0,
            },
            landmark: {
                name: '',
                lat: 0,
                lng: 0,
            },
            order: 'asc',
            orderBy: 'violationType',
            selectedOption: {
                lable: 'CSV',
                value: 'csv',
                key: 'CSV',
            },
            loading: false,
            openExport: false,
            showDropdown: false,
            rowsPerPage: RECORDS_PER_PAGE,
            pageNumber: 0,
            isPopoverOpen: false,
            anchorEl: null,
        };
        this.isInitialRender = false;
        this.isIframe = this.props.location.pathname.match(/^\/iframe/i);
        this.userPreferences = null;
    }

    UNSAFE_componentWillMount() {
        getUserPreference(this, INFRACTION_CENTRIC_SCORECARD_PREF_KEY);
        const iframeUsertoken = localStorage.getItem('iframeUserToken');
        if (iframeUsertoken) {
            this.iframeUserToken = iframeUsertoken;
        }
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (this.props.listType !== nextprops.listType) {
            if (nextprops.listType === 'ScoreCards') {
                this.props.fetchScoreCardsList(
                    this.state.pageNumber,
                    this.state.rowsPerPage,
                    this.state.order,
                    this.state.orderBy,
                    {
                        tripId: getParamValue(this.props, 'id'),
                        startDate: getISODateTrunc(getParamValue(this.props, 'startDate')),
                        endDate: getISODateTrunc(getParamValue(this.props, 'endDate')),
                    },
                );
            }
        }
        if (nextprops.scorecards.length > 0) {
            const { scorecards, totalscorecard } = nextprops;
            const { tableData } = this.state;
            tableData.listData = scorecards;
            tableData.totalRecords = totalscorecard;
            this.setState({ tableData });
        } else {
            const { tableData } = this.state;
            tableData.listData = [];
            tableData.totalRecords = 0;
            this.setState({ tableData });
        }
    }

    getData = () => {
        const tripId = getParamValue(this.props, 'id');
        const filters = {
            tripId,
            startDate: getISODateTrunc(getParamValue(this.props, 'startDate')),
            endDate: getISODateTrunc(getParamValue(this.props, 'endDate')),
        };

        this.props.fetchScoreCardsList(
            this.state.pageNumber,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            filters,
        );
        this.setState({ showListing: true });
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            {
                violationType: 'Infraction',
                ...getColumnPreferences('violationType', preferences),
                showInDropDown: false,
                customMarkup: data => (data.violationType !== null ? this.getInfractionNameFromType(data.violationType) : 'N/A'),
            },
            {
                violationBegin: 'Date',
                ...getColumnPreferences('violationBegin', preferences),
                showInDropDown: getColumnPreferences('violationBegin', preferences),
                customMarkup: data => (data.violationBegin !== null ? convertDateTime(data.violationBegin, (getTimeFormatAsperUserSettting() === '12h') ? 'MMM D, YYYY h:mm A' : 'MMM D, YYYY H:mm') : 'N/A'),
                style: {
                    minWidth: 140,
                },
            },
            {
                speed: <React.Fragment>Speed (<span style={{ textTransform: 'lowercase' }}>{getSpeedUnitString()}</span>)</React.Fragment>,
                reportColumnTitle: `Speed (${getSpeedUnitString()})`,
                ...getColumnPreferences('speed', preferences),
                showInDropDown: getColumnPreferences('speed', preferences),
                customMarkup: (d) => {
                    let { speed } = d;
                    if ((!speed && speed !== 0) || (d.violationType && ['Idle Time', 'Hard Braking', 'Hard Acceleration'].indexOf(this.getInfractionNameFromType(d.violationType)) > -1)) {
                        speed = 'N/A';
                    } else {
                        speed = roundOffUnitValueAsPerUserSetting(parseFloat(speed));
                    }

                    return speed;
                },
            },
            {
                postedSpeed: <React.Fragment>Posted Speed (<span style={{ textTransform: 'lowercase' }}>{getSpeedUnitString()}</span>)</React.Fragment>,
                reportColumnTitle: `Posted Speed (${getSpeedUnitString()})`,
                ...getColumnPreferences('postedSpeed', preferences),
                showInDropDown: getColumnPreferences('postedSpeed', preferences),
                customMarkup: data => (data.postedSpeed && data.postedSpeed > 0 ? roundOffUnitValueAsPerUserSetting(data.postedSpeed) : 'N/A'),
            },
            {
                idlingPeriod: <React.Fragment>Idle Period (<span style={{ textTransform: 'lowercase' }}>min</span>)</React.Fragment>,
                reportColumnTitle: 'Idle Period (min)',
                ...getColumnPreferences('idlingPeriod', preferences),
                showInDropDown: getColumnPreferences('idlingPeriod', preferences),
                customMarkup: data => (data.idlingPeriod !== null ? Math.floor(data.idlingPeriod / 60) : 'N/A'),
            },
            {
                violationAddrBegin: 'Location',
                ...getColumnPreferences('violationAddrBegin', preferences),
                showInDropDown: getColumnPreferences('violationAddrBegin', preferences),
                customMarkup: data => this.getLandmarkTemplate(data),
            }];

        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    sortTableColumn = (order, orderBy, page) => {
        this.props.fetchScoreCardsList(
            page.pageNumber,
            page.rowsPerPage,
            order,
            orderBy,
            {
                tripId: getParamValue(this.props, 'id'),
                startDate: getISODateTrunc(getParamValue(this.props, 'startDate')),
                endDate: getISODateTrunc(getParamValue(this.props, 'endDate')),
            },
        );
        this.setState({
            order,
            orderBy,
            rowsPerPage: page.rowsPerPage,
            pageNumber: page.pageNumber,
        }, () => {
            saveUserPreference(this, INFRACTION_CENTRIC_SCORECARD_PREF_KEY);
        });
        const params = {
            feature: 'DriverScorecard',
            sortColumn: orderBy,
            viewBy: 'Infraction',
        };
        analytics.track('DRIVER_SCORECARD_SORT', params);
    }

    loadNextPage = (rows, value, order, orderBy) => {
        const updateUserPref = isUpdatePreference(this, { value, order, orderBy });
        this.setState({
            pageNumber: rows,
            rowsPerPage: value,
            order,
            orderBy,
        }, () => {
            if (updateUserPref) {
                saveUserPreference(this, INFRACTION_CENTRIC_SCORECARD_PREF_KEY);
            }
        });
        this.props.fetchScoreCardsList(
            rows, value, order, orderBy,
            {
                tripId: getParamValue(this.props, 'id'),
                startDate: getISODateTrunc(getParamValue(this.props, 'startDate')),
                endDate: getISODateTrunc(getParamValue(this.props, 'endDate')),
            },
        );
    }

    handlePopoverClick = (d, event) => {
        let template = `${d.violationAddrBegin || ''}, ${d.violationCityBegin || ''}, ${d.violationStateBegin || ''}, ${d.violationZipBegin || ''}`;
        template = template.split(', ').filter(Boolean);
        template = template.join(', ');
        this.setState({
            isPopoverOpen: !this.state.isPopoverOpen,
            anchorEl: event.currentTarget,
            name: template,
        });
        const landmarkId = d.landmark.globalId;
        this.getLandmarkData(landmarkId);
    };

    getLandmarkData = (landmarkId: string) => {
        getData(`${config.get('FLEET_VIEW_SERVICES_URL')}/landmarks/${landmarkId}`).then((result: any) => {
            if (result && result.data) {
                const landmark = result.data[0] || {};
                this.setState({
                    landmark: {
                        ...landmark,
                        lat: landmark.marker ? landmark.marker.y : 0,
                        lng: landmark.marker ? landmark.marker.x : 0,
                        name: landmark.name ? landmark.name : '',
                    },
                });
            }
        }).catch((error) => {
            // eslint-disable-next-line
            console.error('Error fetching landmark data:', error);
        });
    }

    dialogHandleClick = (type, value) => {
        if (type === 'show') {
            this.setState({ selectedOption: value });
        } else if (type === 'download') {
            this.openExport(false);
            const {
                tableData,
                order,
                orderBy,
                selectedOption,
            } = this.state;
            const params = {
                feature: 'DriverScorecard',
                exportType: value.value,
            };
            analytics.track('INFRACTION_DOWNLOAD', params);
            const url = getURL({
                pageNumber: 0,
                pageSize: tableData.totalRecords,
                order,
                orderBy,
                filters: {
                    tripId: getParamValue(this.props, 'id'),
                    startDate: getISODateTrunc(getParamValue(this.props, 'startDate')),
                    endDate: getISODateTrunc(getParamValue(this.props, 'endDate')),
                },
            });

            this.setState({ loading: true });
            const header = getHeaderKeys(tableData.listHeaders);
            let timeFormat = getTimeFormatAsperUserSettting();
            timeFormat = (timeFormat === '24h') ? '24' : '12';
            downloadFile(
                `${url}&timeZone=${getUserSettingTimeZone()}&unitMeasurement=${getDistanceStringLong()}&reportType=${selectedOption.value}&reportColumns=${header.join(',')}&timeFormat=${timeFormat}`,
                `Trips ${moment().format('YYYY-MM-DD HH-mm-ss')}.${selectedOption.value}`,
                { type: selectedOption.value },
                () => this.setState({ loading: false }),
            );
        } else {
            this.openExport(false);
        }
    }

    openExport = (openExport) => {
        this.setState({ openExport });
    }

    listTypeContextAction = (type) => {
        switch (type) {
        case 'export': this.openExport(true);
            break;
        default: break;
        }
    }

    getInfractionNameFromType = (type) => {
        switch (type) {
        case 'HARD_ACCEL': return 'Hard Acceleration';
        case 'SEAT_BELT': return 'Seatbelt';
        case 'HARD_BRAKE': return 'Hard Braking';
        case type.indexOf('EPS') > -1 && `${type}`: return 'Posted Speed';
        case type.indexOf('EST') > -1 && `${type}`: return 'Speed Threshold';
        case type.indexOf('IDLE') > -1 && `${type}`: return 'Idle Time';
        default: return type;
        }
    }

    getLandmarkTemplate = (d) => {
        let template = `${d.violationAddrBegin || ''}, ${d.violationCityBegin || ''}, ${d.violationStateBegin || ''}, ${d.violationZipBegin || ''}`;
        template = template.split(', ').filter(Boolean);
        template = template.join(', ');

        if (d.landmark.globalId && !this.iframeUserToken) {
            template = (
                <React.Fragment>
                    <Tooltip title={template} disableFocusListener>
                        <span
                            role="button"
                            onClick={event => this.handlePopoverClick(d, event)}
                            onKeyDown={() => {}}
                            tabIndex={0}
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        >
                            {d.landmark.name}
                        </span>
                    </Tooltip>
                    <Popover
                        open={this.state.isPopoverOpen}
                        anchorEl={this.state.anchorEl}
                        onClose={() => this.setState({ isPopoverOpen: false })}
                        PaperProps={{
                            style: {
                                boxShadow: 'none',
                            },
                        }}
                    >
                        <div className={styles.map}>
                            <div
                                className={styles.mapCloseButton}
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => {}}
                                onClick={() => this.setState({ isPopoverOpen: false })}
                            >
                              X
                            </div>
                            <MapsWrapper
                                key="lm-map-for-shape"
                                zoom={15}
                                coordinates={
                                    {
                                        lat: this.state.landmark.lat,
                                        lng: this.state.landmark.lng,
                                    }
                                }
                                moduleName="marker-icon"
                                hideViewButton
                                hideRightClick
                                markers={{
                                    shape: {
                                        type: 'marker',
                                        name: this.state.name,
                                        lat: this.state.landmark.lat,
                                        lng: this.state.landmark.lng,
                                        attributes: {},
                                    },
                                    markerType: 'landmark',
                                }}
                                height="384px"

                            />
                        </div>
                    </Popover>
                </React.Fragment>
            );
        }
        return template;
    }

    getTableTitle = () => {
        if (this.props && getParamValue(this.props, 'startDate')) {
            const startTime = `${convertDateTime(getParamValue(this.props, 'startDate'), (getTimeFormatAsperUserSettting() === '12h') ? 'MMM DD, hh:mm A' : 'MMM DD, HH:mm')}`;
            if (getParamValue(this.props, 'endDate')) {
                const endTime = `${convertDateTime(getParamValue(this.props, 'endDate'), (getTimeFormatAsperUserSettting() === '12h') ? 'MMM DD, hh:mm A' : 'MMM DD, HH:mm')}`;
                return `${startTime} - ${endTime}`;
            }
            return `${startTime} - #}`;
        }
        return '#';
    }

    getListTemplate = (tableData, selectedOption, openExport, isIframe) => (
        this.state.showListing ?
            <ListComponent
                redirectTo={this.props.history.push}
                tableData={tableData}
                loadNextPage={this.loadNextPage}
                isUpdating={this.props.isUpdating}
                rearrangeColumns={this.rearrangeColumns}
                resetTable={this.props.resetTable}
                showHideColumn={this.showHideColumn}
                sortTableColumn={this.sortTableColumn}
                listTypeContextAction={this.listTypeContextAction}
                tableSubTitle={this.getTableTitle()}
                showDropdown={this.state.showDropdown}
                dialogboxInfo={{
                    title: (
                        <span>
                            Export data
                            as {selectedOption.lable} for {getTitle(this.getTableTitle())}
                        </span>
                    ),
                    dialogHandleClick: this.dialogHandleClick,
                    showDialog: openExport,
                    selectedOption,
                }}
                showDialogboxInfo={`Importing CSV data for ${tableData.listData.length > 0 ? convertDateTime(tableData.listData[0].violationBegin, (getTimeFormatAsperUserSettting() === '12h') ? 'MM/DD/YY h:mm A' : 'MM/DD/YY H:mm') : '#'}`}
                tableButtons={['Export']}
                showToolbarBackButton
                resizableHeader
                language={{
                    emptyTable: 'This trip does not contain any infraction selected by the user.',
                }}
                showSearch={false}
                isIframe={isIframe}
                rowsPerPage={this.state.rowsPerPage}
                order={this.state.order}
                orderBy={this.state.orderBy}
            />
            :
            <div style={{ height: '100%' }}>
                <AppLoader type="fullScreen" />
            </div>
    );

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: INFRACTION_CENTRIC_SCORECARD_PREF_KEY };
        rearrangeColumns(this, listHeaders, options);
    }

    showHideColumn = (header) => {
        const options = { prefKey: INFRACTION_CENTRIC_SCORECARD_PREF_KEY };
        showHideColumns(this, header, options);
        let colName = '';
        if (header.reportColumnTitle) {
            colName = header.reportColumnTitle;
        } else if (header.violationBegin) {
            colName = 'Date';
        } else if (header.violationAddrBegin) {
            colName = 'Location';
        }
        const params = {
            feature: 'DriverScorecard',
            selectedColumn: colName,
            showColumn: header.show,
        };
        analytics.track('HIDE_SHOW_INFRACTION_COLUMN', params);
    }

    render() {
        const {
            tableData,
            selectedOption,
            openExport,
            loading,
        } = this.state;

        return (
            <React.Fragment>
                {loading && <AppLoader type="fullScreen" />}
                <React.Fragment>
                    {this.isIframe ?
                        this.getListTemplate(tableData, selectedOption, openExport, true)
                        :
                        <MiniDrawer
                            redirectTo={this.props.history.push}
                        >
                            {this.getListTemplate(tableData, selectedOption, openExport, false)}
                        </MiniDrawer>
                    }
                </React.Fragment>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => ({
    scorecards: state.scorecardsList.scorecards,
    totalscorecard: state.scorecardsList.totalScorecards,
    isUpdating: state.scorecardsList.isUpdating,
});

const mapDispatchToProps = {
    ...actions,
};

export default reduxConnect(ScorecardsList, mapDispatchToProps, mapStateToProps);
