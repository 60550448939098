import { getAppUserSettings, isEmpty } from '../../../helper-classes/utility-functions';

export default function fromJson() {
    const userSettings = getAppUserSettings();
    if (isEmpty(userSettings)) {
        return {};
    }
    return ({
        accountId: userSettings.accountId,
        accountLabel: userSettings.accountName,
        accountUserId: userSettings.accountUserId,
        userTz: userSettings.userTz,
        userUnits: userSettings.userUnits,
    });
}

