/* @flow */
import { combineEpics } from 'redux-observable';
import {
    ADD_USER,
    EDIT_USER,
    DELETE_USER,
    FETCH_USER,
    FETCH_USERROLES,
    FETCH_USERACCESS,
    FULL_ACCESS,
} from './constants.user';
import {
    addEditUserSuccess,
    addUserError,
    editUserError,
    deleteUserSuccess,
    deleteUserError,
    fetchUserSuccess,
    fetchUserError,
    fetchUserRolesSuccess,
    fetchUserRolesError,
    fetchUserAccessSuccess,
    fetchUserAccessError,
} from './actions.user';
import config from '../../../constants/Config';
import {
    getAppToken,
    getGrailsAppBasedURL,
    getAuthorizationHeader,
    getUserToken,
} from '../../../helper-classes/utility-functions';

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    deleteJSON: Function,
    put: Function,
    of: Function
}

export const sendInvitation = (data: any) => {
    const appToken = getAppToken();
    const headers = {
        'X-Nspire-AppToken': appToken,
        'Content-Type': 'application/json',
        'X-Nspire-UserToken': getUserToken(),
    };
    const newData = { userName: data.username };
    return data.postJSON(`${config.get('FLEET_SERVICES_URL')}/api/approach-user-request`, newData, headers)
        .flatMap(() => [addEditUserSuccess(data.msg)]);
};

export const addUser =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) => actions$
        .ofType(ADD_USER)
        .distinctUntilChanged()
        .debounceTime(config.get('DEBOUNCE_TIME'))
        .mergeMap((action) => {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: getAuthorizationHeader(),
            };
            return postJSON(`${getGrailsAppBasedURL()}/rest/json/accountUser?noEmail=true`, JSON.stringify(action.payload.requestBody), headers)
                .mergeMap((result) => {
                    if (result.response && result.response.success) {
                        const data = {
                            postJSON,
                            username: result.response.data.username,
                            email: result.response.data.email,
                            msg: result.response.msg,
                        };
                        return sendInvitation(data);
                    }
                    return [addUserError(result.response)];
                })
                .catch(error => of(addUserError(error.response || error)));
        });

export const editUser =
    (actions$: Function, store: Object, { put, of, postJSON }: ObservarblesTypes) => actions$
        .ofType(EDIT_USER)
        .distinctUntilChanged()
        .debounceTime(config.get('DEBOUNCE_TIME'))
        .mergeMap((action) => {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: getAuthorizationHeader(),
            };
            const { resendInvitation } = action.payload.requestBody;
            return put(`${getGrailsAppBasedURL()}/rest/json/accountUser/${action.payload.requestBody.id}?noEmail=true`, JSON.stringify(action.payload.requestBody), headers)
                .mergeMap((result) => {
                    if (result.response && result.response.success) {
                        if (resendInvitation) {
                            const data = {
                                postJSON,
                                username: result.response.data.username,
                                email: result.response.data.email,
                                msg: result.response.msg,
                            };
                            return sendInvitation(data);
                        }
                        return [addEditUserSuccess(result.response.msg)];
                    }
                    return [editUserError(result.response)];
                })
                .catch(error => of(editUserError(error.response || error)));
        });

export const getUser =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_USER)
            .distinctUntilChanged()
            .debounceTime(1000)
            .mergeMap((action) => {
                const { id } = action.payload;
                const headers = {
                    'X-Nspire-AppToken': getAppToken(),
                    Authorization: getAuthorizationHeader(),
                };
                return getJSON(`${getGrailsAppBasedURL()}/rest/json/accountUser/${id}`, headers)
                    .map(result => fetchUserSuccess(result.data))
                    .catch(error => of(fetchUserError(error)));
            });

export const getUserRoles =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_USERROLES)
            .distinctUntilChanged()
            .debounceTime(1000)
            .mergeMap(() => {
                const headers = {
                    Authorization: getAuthorizationHeader(),
                };
                const filter = '[{"value":"MASTER_USER","property":"code","comparison":"ne"},{"value":"ELD_MANAGER","property":"code","comparison":"ne"}]';
                const sort = '[{"property":"name","direction":"ASC"}]';
                const reqURL = `includeDefaultRoles=true&page=1&offset=0&max=50&sorts=${escape(sort)}&filter=${escape(filter)}`;
                return getJSON(`${getGrailsAppBasedURL()}/rest/json/accountRole?${reqURL}`, headers)
                    .map(result => fetchUserRolesSuccess(result.data))
                    .catch(error => of(fetchUserRolesError(error)));
            });

export const moveArrayValueToIndex = (data: any, value: any, toIndex: any) => {
    let fromIndex = -1;
    data.some((obj, i) => {
        if (obj.name === value) {
            fromIndex = i;
            return true;
        } return false;
    });
    if (fromIndex !== -1) {
        const element = data[fromIndex];
        data.splice(fromIndex, 1);
        data.splice(toIndex, 0, element);
    }
    return data;
};

export const getUserAccess =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_USERACCESS)
            .distinctUntilChanged()
            .debounceTime(1000)
            .mergeMap(() => {
                const headers = {
                    Authorization: getAuthorizationHeader(),
                };
                const sort = '[{"property":"name","direction":"ASC"}]';
                const reqURL = `page=1&offset=0&max=500&sorts=${escape(sort)}`;
                return getJSON(`${getGrailsAppBasedURL()}/rest/json/universe?${reqURL}`, headers)
                    .map(result => fetchUserAccessSuccess(result.data ?
                        moveArrayValueToIndex(result.data, FULL_ACCESS, 0) : null))
                    .catch(error => of(fetchUserAccessError(error)));
            });

export const deleteUser =
    (actions$: Function, store: Object, { deleteJSON, of }: ObservarblesTypes) => actions$
        .ofType(DELETE_USER)
        .distinctUntilChanged()
        .debounceTime(config.get('DEBOUNCE_TIME'))
        .mergeMap((action) => {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: getAuthorizationHeader(),
            };
            const { id } = action.payload;
            return deleteJSON(`${getGrailsAppBasedURL()}/rest/json/accountUser/${id}`, headers)
                .map((result) => {
                    if (result.response && result.response.success) {
                        return deleteUserSuccess(result.response);
                    }
                    return deleteUserError(result.response);
                })
                .catch(error => of(deleteUserError(error.response || error)));
        });


export default combineEpics(
    getUser, getUserRoles, getUserAccess, addUser,
    editUser, deleteUser,
);
