/* @flow */
import React, { Component } from 'react';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.nearestAssetLandmark';
import MapsWrapper from '../MapsWrapper';
import styles from './NearestAssetLandmark.module.scss';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import { getParamValue } from '../../helper-classes/utility-functions';
import AppLoader from '../../components/AppLoader';
import RightDrawer from '../../components/SharedComponents/RightDrawer/RightDrawer';
import { getAssetIconFromType, getAssetColorFromStatus } from '../../util/trip_util';
import NearestAssetLandmarkDetails from '../../components/SharedComponents/DirectionsComponent/NearestAssetLandmarkDetails';

export type Props = {
    fetchNearestLandmark : Function,
    fetchNearestAsset : Function,
    assetDetails : Object,
    directions: Object,
    directionsDetails: Object,
    isLoader: boolean,
    landmarks: Object,
    history: {
        goBack: Function,
        push: Function,
    },
    location: Object,
};

export type State = {
};

class NearestAssetLandmark extends Component<Props, State> {
    assetLandmarkId: string;
    pathName: string;

    componentDidMount() {
        const pathName = this.props.location.pathname;
        this.assetLandmarkId = getParamValue(this.props, 'id');

        if (pathName.indexOf('nearest-landmark') > 0) {
            this.pathName = 'nearest-landmark';
            this.props.fetchNearestLandmark(this.assetLandmarkId);
        } else if (pathName.indexOf('nearest-asset') > 0) {
            this.pathName = 'nearest-asset';
            this.props.fetchNearestAsset(this.assetLandmarkId);
        }
    }

    componentDidUpdate() {
        const paramId = getParamValue(this.props, 'id');
        if (paramId !== this.assetLandmarkId) {
            if (this.pathName === 'nearest-landmark') {
                this.props.fetchNearestLandmark(paramId);
            } else if (this.pathName === 'nearest-asset') {
                this.props.fetchNearestAsset(paramId);
            }
        }
        this.assetLandmarkId = paramId;
    }

    getIcons = () => {
        const { assetDetails } = this.props;
        let icons = [];
        if (assetDetails && (Object.keys(assetDetails).length > 0) && assetDetails.assetId) {
            const color = getAssetColorFromStatus(assetDetails);
            const labelColor = assetDetails.labelColor || color;
            icons = [{
                color,
                labelColor,
                image: { ...getAssetIconFromType(assetDetails.assetType) },
            }];
        }
        return icons;
    }

    render() {
        let coordinates = {};
        const {
            landmarks, isLoader, assetDetails, directions, directionsDetails, history,
        } = this.props;

        if (assetDetails && Object.keys(assetDetails).length > 0) {
            coordinates = {
                lat: assetDetails.latitude,
                lng: assetDetails.longitude,
            };
        }

        return (
            <MiniDrawer redirectTo={history.push}>
                {isLoader ? <AppLoader type="fullScreen" /> : ''}
                <div key="nearest-asset-lm-map-container" className={styles.mapWrapper}>
                    <MapsWrapper
                        key="nearest-asset-lm-map"
                        coordinates={coordinates}
                        markers={{
                            shape: {
                                type: 'nearestassetlandmark',
                                location: this.getIcons(),
                            },
                            directions,
                            landmark: landmarks,
                            asset: assetDetails,
                            redirectTo: history.push,
                        }}
                        moduleName={this.pathName}
                    />
                </div>
                {!isLoader && landmarks && (Object.keys(landmarks).length > 0) && landmarks.id &&
                <div className={styles.container}>
                    <RightDrawer showDrawer>
                        <NearestAssetLandmarkDetails
                            pathName={this.pathName}
                            landmarksData={landmarks}
                            assetData={assetDetails}
                            directionsDetails={directionsDetails}
                            history={history}
                            assetToLandmark={false}
                        />
                    </RightDrawer>
                </div>
                }
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => state.nearestAssetLandmark;

export default reduxConnect(NearestAssetLandmark, actions, mapStateToProps);
