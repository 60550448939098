import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styles from '../../Steppers//AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';

class DevicePowerDisconnectAlert extends React.Component {
    state = {};

    render() {
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    <div className={styles['step-rightside']}>
                        <form noValidate autoComplete="off">
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value="checkedB"
                                            color="primary"
                                            checked
                                            disabled
                                            id="powerdisconnect"
                                            name="powerdisconnect"
                                        />
                                    }
                                    label={ALERT_DETAILS.LABEL.POWER_DISCONNECT}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default DevicePowerDisconnectAlert;
