/* @flow */

import {
    FETCH_SCORECARDS,
    FETCH_SCORECARDS_ERROR,
    FETCH_SCORECARDS_SUCCESS,
    FETCH_LANDMARKGROUPS,
    FETCH_LANDMARKGROUPS_ERROR,
    FETCH_LANDMARKGROUPS_SUCCESS,
    FETCH_CLUSTERPOINTS,
    FETCH_CLUSTERPOINTS_SUCCESS,
    FETCH_CLUSTERPOINTS_ERROR,
    GET_CLUSTER_EXPLODE_SUCCESS,
} from './constants.home';
import type { HomeTypeAction } from './constants.home';

export const fetchScoreCards = (): HomeTypeAction => ({
    type: FETCH_SCORECARDS,
});

export const fetchScoreCardsSuccess =
    (landmarks: string, totalLandmarks : number): HomeTypeAction => ({
        type: FETCH_SCORECARDS_SUCCESS,
        payload: { landmarks, totalLandmarks },
    });

export const fetchScoreCardsError = (error: string): HomeTypeAction => ({
    type: FETCH_SCORECARDS_ERROR,
    payload: { error },
});

export const fetchLandmarkGroups = (): HomeTypeAction => ({
    type: FETCH_LANDMARKGROUPS,
});

export const fetchLandmarkGroupsSuccess =
    (landmarkGroups: string, totalLandmarkGroups : number): HomeTypeAction => ({
        type: FETCH_LANDMARKGROUPS_SUCCESS,
        payload: { landmarkGroups, totalLandmarkGroups },
    });

export const fetchLandmarkGroupsError = (error: string): HomeTypeAction => ({
    type: FETCH_LANDMARKGROUPS_ERROR,
    payload: { error },
});

export const fetchClusterPoints = (
    top: string,
    left: string,
    bottom: string,
    right: string,
    filter: string,
    type: string,
): HomeTypeAction => ({
    type: FETCH_CLUSTERPOINTS,
    payload: {
        top,
        left,
        bottom,
        right,
        filter,
        type,
    },
});

export const fetchClusterPointsSuccess = (
    assetPoints: string,
    landmarkPoints : string,
    assetPointsCount: any,
    landmarkPointsCount: any,
): HomeTypeAction => ({
    type: FETCH_CLUSTERPOINTS_SUCCESS,
    payload: {
        assetPoints,
        landmarkPoints,
        assetPointsCount,
        landmarkPointsCount,
    },
});

export const fetchClusterPointsError = (error: string): HomeTypeAction => ({
    type: FETCH_CLUSTERPOINTS_ERROR,
    payload: { error },
});

export const getClusterExplodeSuccess = (
    assetPoints: string,
    landmarkPoints : string,
    assetPointsCount: any,
    landmarkPointsCount: any,
): HomeTypeAction => ({
    type: GET_CLUSTER_EXPLODE_SUCCESS,
    payload: {
        assetPoints,
        landmarkPoints,
        assetPointsCount,
        landmarkPointsCount,
    },
});
