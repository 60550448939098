/* @flow */
/* eslint-disable max-len,no-nested-ternary */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import MomentUtils from '@date-io/moment';
import { getDistanceString } from '../../../helper-classes/utility-functions';

export type Props = {
    classes: any,
    editMode: boolean,
    onServiceIntervalChanged: Function,
    selectedPlan: Array<{}>,
    serviceInterval: {},
};

export type State = {
    distanceInterval: string,
    firstServiceOdometer: string,
    timePeriodInterval: string,
    firstServiceDate: any,
    engineHoursInterval: string,
    firstServiceEngineHours: string,
    distanceChecked: boolean,
    timePeriodChecked: boolean,
    engineHoursChecked: boolean,
    autoResetChecked: boolean,
    dateErrorMessage: String,
};

const styles = () => ({
    search: {
        position: 'relative',
        marginLeft: 0,
        width: '95%',
        'max-width': '350px',
    },
    centerText: {
        'text-align': 'center',
        // display: 'inline-block',
    },
    plansDrop: {
        position: 'relative',
        margin: 0,
        width: '95%',
        'max-width': '350px',
    },
    firstCol: {
        'border-right': '1px solid #979797',
    },
    container: {
        display: 'flex',
    },
    searchIcon: {
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: '0px',
        color: '#767676',
    },
    helpIconDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#767676',
    },
    helpIcon: {
        'font-size': '20px !Important',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#767676',
        paddingLeft: 4,
    },
    blueIcon: {
        'font-size': '20px',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#007aff !important',
    },
    checkBox: {
        color: '#007aff !important',
        paddingLeft: '0px',
    },
    uncheckedCheckBox: {
        color: '#9E9E9E !important',
        paddingLeft: '0px',
    },
    main: {
        margin: '0px',
    },
    items: {
        'margin-bottom': '12px',
    },
    textField: {
        'max-width': '350px',
        width: '86%',
        paddingTop: '0px',
        marginBottom: '20px',
        marginTop: '0px',
    },
    datePickerBox: {
        'max-width': '350px',
        width: '86%',
        paddingTop: '0px',
        marginBottom: '20px',
        marginTop: '0px',
        display: 'inline-flex',
    },
    title: {
        'font-size': '20px',
        'white-space': 'nowrap',
    },
    subtitle: {
        'font-size': '15px',
        'white-space': 'nowrap',
    },
});

const IntervalTableCell = tableProps => (
    <TableCell {...tableProps} style={{ border: 'none' }} />
);

class ServiceInterval extends Component<Props, State> {
    constructor(props) {
        super(props);
        if (props.editMode && props.selectedPlan) {
            this.state = {
                distanceInterval: props.selectedPlan.distanceInterval,
                firstServiceOdometer: props.selectedPlan.firstServiceOdometer,
                timePeriodInterval: props.selectedPlan.timePeriodInterval,
                firstServiceDate: props.selectedPlan.firstServiceDate,
                engineHoursInterval: props.selectedPlan.engineHoursInterval,
                firstServiceEngineHours: props.selectedPlan.firstServiceEngineHours,
                distanceChecked: props.selectedPlan.distanceInterval,
                timePeriodChecked: props.selectedPlan.timePeriodInterval,
                engineHoursChecked: props.selectedPlan.engineHoursInterval,
                autoResetChecked: props.selectedPlan.autoResetStatus,
            };
        } else if (props.serviceInterval) {
            this.state = { ...props.serviceInterval, dateErrorMessage: '' };
        } else {
            this.state = {
                distanceInterval: '',
                firstServiceOdometer: '',
                timePeriodInterval: '',
                firstServiceDate: null,
                engineHoursInterval: '',
                firstServiceEngineHours: '',
                distanceChecked: false,
                timePeriodChecked: false,
                engineHoursChecked: false,
                autoResetChecked: false,
                dateErrorMessage: '',
            };
        }
        // create datepicker ref so you can open the calendar via button
        // this.dpRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state !== prevState) {
            this.props.onServiceIntervalChanged(this.state);
        }
    }

    resetFirstServiceDate = () => {
        if (moment(this.state.firstServiceDate).isValid()) {
            this.setState({
                firstServiceDate: moment(this.state.firstServiceDate),
                dateErrorMessage: '',
            });
        }
    }

    handleChange = prop => (event) => {
        let { distanceChecked, engineHoursChecked, timePeriodChecked } = this.state;
        if (prop === 'distanceInterval') {
            distanceChecked = event.target.value && event.target.value > 0;
            this.resetFirstServiceDate();
        }
        if (prop === 'engineHoursInterval') {
            engineHoursChecked = event.target.value && event.target.value > 0;
            this.resetFirstServiceDate();
        }
        if (prop === 'timePeriodInterval') {
            timePeriodChecked = event.target.value && event.target.value > 0;
            this.resetFirstServiceDate();
        }
        this.setState({
            [prop]: event.target.value,
            distanceChecked,
            engineHoursChecked,
            timePeriodChecked,
        });
    }

    handleDateChange = (date) => {
        if (!date) {
            this.setState({ dateErrorMessage: '' });
        } else if (!moment(date).isValid()) {
            this.setState({ dateErrorMessage: 'invalidDate' });
        } else if (moment(date).isBefore(moment(), 'd')) {
            this.setState({ dateErrorMessage: 'disablePast' });
        } else {
            this.setState({ dateErrorMessage: '' });
            this.setState({ firstServiceDate: date });
        }
    }

    handleCheckChange = name => (event) => {
        this.setState({ [name]: event.target.checked });
    }
    onDatePickerOpen = () => {
        if (this.state.firstServiceDate == null) { this.setState({ firstServiceDate: moment() }) }
    }

    getTimePeriodDateErrorMessage = () => {
        switch (this.state.dateErrorMessage) {
        case 'disablePast': {
            return 'Date should not be in past';
        }
        case 'invalidDate': {
            return 'Invalid Date format';
        }
        default: {
            return '';
        }
        }
    }

    render() {
        const { classes } = this.props;
        let formattedDate = this.state.firstServiceDate;
        if (formattedDate) {
            formattedDate = moment(formattedDate).format('MM/DD/YYYY');
        }
        return (
            <div>
                <Table>
                    <TableBody>
                        <TableRow>
                            {/* work around to overcome upstream important padding styles on first child. */}
                            <TableCell style={{ display: 'none' }} />
                            <IntervalTableCell>
                                <Typography className={classes.title}>
                                    Service Interval
                                    <Tooltip title="When multiple criteria are selected, a reminder is issued only for the first criteria to reach its interval limit.">
                                        <HelpIcon className={classes.helpIcon} />
                                    </Tooltip>
                                </Typography>
                            </IntervalTableCell>
                            <IntervalTableCell>
                                Interval
                            </IntervalTableCell>
                            <IntervalTableCell className={classes.centerText}>
                                <Grid container spacing={0}>
                                    <Grid item xs={7}>
                                        First Service At
                                        <Tooltip title="Optional: Enter a value at which you would like to have the alert notification sent for the first time.">
                                            <HelpIcon className={classes.helpIcon} style={{ verticalAlign: 'bottom' }} />
                                        </Tooltip>
                                        <br />
                                        (Optional)
                                    </Grid>
                                </Grid>
                            </IntervalTableCell>
                        </TableRow>
                        <TableRow>
                            {/* work around to overcome upstream important padding styles on first child. */}
                            <TableCell style={{ display: 'none' }} />
                            <IntervalTableCell>
                                {!this.props.editMode ?
                                    <Checkbox
                                        checked={this.state.distanceChecked}
                                        onChange={this.handleCheckChange('distanceChecked')}
                                        value="distanceChecked"
                                        className={this.state.distanceChecked ? classes.checkBox : classes.uncheckedCheckBox}
                                    />
                                    : ''}
                                Distance ({getDistanceString()})
                            </IntervalTableCell>
                            <IntervalTableCell>
                                {!this.props.editMode ?
                                    <TextField
                                        id="distanceInterval"
                                        type="number"
                                        className={classes.textField}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        value={this.state.distanceInterval ? this.state.distanceInterval : null}
                                        onChange={this.handleChange('distanceInterval')}
                                        margin="normal"
                                    />
                                    :
                                    (this.state.distanceInterval ? this.state.distanceInterval : '— —')}
                            </IntervalTableCell>
                            <IntervalTableCell>
                                {!this.props.editMode ?
                                    <TextField
                                        id="firstServiceOdometer"
                                        type="number"
                                        className={classes.textField}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        value={this.state.firstServiceOdometer ? this.state.firstServiceOdometer : null}
                                        onChange={(event) => {
                                            this.handleChange('firstServiceOdometer')(event);
                                            this.resetFirstServiceDate();
                                        }}
                                        margin="normal"
                                    />
                                    :
                                    (this.state.firstServiceOdometer ? this.state.firstServiceOdometer : '— —')}
                            </IntervalTableCell>
                        </TableRow>
                        <TableRow>
                            {/* work around to overcome upstream important padding styles on first child. */}
                            <TableCell style={{ display: 'none' }} />
                            <IntervalTableCell>
                                {!this.props.editMode ?
                                    <Checkbox
                                        checked={this.state.timePeriodChecked}
                                        onChange={this.handleCheckChange('timePeriodChecked')}
                                        value="timePeriodChecked"
                                        className={this.state.timePeriodChecked ? classes.checkBox : classes.uncheckedCheckBox}
                                    />
                                    : ''}
                                Time Period (mo)
                            </IntervalTableCell>
                            <IntervalTableCell>
                                {!this.props.editMode ?
                                    <TextField
                                        id="timePeriodInterval"
                                        type="number"
                                        className={classes.textField}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        value={this.state.timePeriodInterval ? this.state.timePeriodInterval : null}
                                        onChange={this.handleChange('timePeriodInterval')}
                                        margin="normal"
                                    />
                                    :
                                    (this.state.timePeriodInterval ? this.state.timePeriodInterval : '— —')}
                            </IntervalTableCell>
                            <IntervalTableCell>
                                {!this.props.editMode ?
                                    <form className={classes.container} noValidate>
                                        <div className={classes.datePickerBox}>
                                            <LocalizationProvider dateAdapter={MomentUtils}>
                                                <DatePicker
                                                    placeholder="mm/dd/yyyy"
                                                    sx={{ '& .MuiPickersToolbar-penIconButton': { display: 'none' } }}
                                                    renderInput={params => <TextField
                                                        {...params}
                                                        helperText={this.getTimePeriodDateErrorMessage()}
                                                        error={!!this.state.dateErrorMessage}
                                                    />}
                                                    onOpen={this.onDatePickerOpen}
                                                    value={this.state.firstServiceDate}
                                                    onChange={this.handleDateChange}
                                                    format="MM/DD/YYYY"
                                                    disablePast
                                                    showToolbar
                                                    onError={(error) => this.setState({ dateErrorMessage: error })}
                                                    mask="__/__/____"
                                                    variant="inline"
                                                    id="date-picker-inline"
                                                    closeOnSelect
                                                    PopperProps={{
                                                        placement: 'bottom-end',
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </form>
                                    :
                                    (formattedDate || '— —')}
                            </IntervalTableCell>
                        </TableRow>
                        <TableRow>
                            {/* work around to overcome upstream important padding styles on first child. */}
                            <TableCell style={{ display: 'none' }} />
                            <IntervalTableCell>
                                {!this.props.editMode ?
                                    <Checkbox
                                        checked={this.state.engineHoursChecked}
                                        onChange={this.handleCheckChange('engineHoursChecked')}
                                        value="engineHoursChecked"
                                        className={this.state.engineHoursChecked ? classes.checkBox : classes.uncheckedCheckBox}
                                    />
                                    : ''}
                                Engine Hours (hrs)
                            </IntervalTableCell>
                            <IntervalTableCell>
                                {!this.props.editMode ?
                                    <TextField
                                        id="engineHoursInterval"
                                        type="number"
                                        className={classes.textField}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        value={this.state.engineHoursInterval ? this.state.engineHoursInterval : null}
                                        onChange={this.handleChange('engineHoursInterval')}
                                        margin="normal"
                                    />
                                    :
                                    (this.state.engineHoursInterval ? this.state.engineHoursInterval : '— —')}
                            </IntervalTableCell>
                            <IntervalTableCell>
                                {!this.props.editMode ?
                                    <TextField
                                        id="firstServiceEngineHours"
                                        type="number"
                                        className={classes.textField}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        value={this.state.firstServiceEngineHours ? this.state.firstServiceEngineHours : null}
                                        onChange={(event) => {
                                            this.handleChange('firstServiceEngineHours')(event);
                                            this.resetFirstServiceDate();
                                        }}
                                        margin="normal"
                                    />
                                    :
                                    (this.state.firstServiceEngineHours ? this.state.firstServiceEngineHours : '— —')}
                            </IntervalTableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Grid item style={{ paddingTop: '30px' }}>
                    <Checkbox
                        checked={this.state.autoResetChecked}
                        onChange={(event) => {
                            this.handleCheckChange('autoResetChecked')(event);
                            this.resetFirstServiceDate();
                        }}
                        value="autoResetChecked"
                        className={this.state.autoResetChecked ? classes.checkBox : classes.uncheckedCheckBox}
                    />
                    Auto Reset Service
                    <Tooltip title="When checked, a service interval having reached its limits is reset to start a new interval even if no service record is added.">
                        <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(ServiceInterval);
