/* @flow */

export const FETCH_LANDMARKS_LIST: string = 'FETCH_LANDMARKS_LIST';
export const FETCH_LANDMARKS_LIST_SUCCESS: string = 'FETCH_LANDMARKS_LIST_SUCCESS';
export const FETCH_LANDMARKS_LIST_ERROR: string = 'FETCH_LANDMARKS_LIST_ERROR';
export const RESET_LANDMARKS_LIST: string = 'RESET_LANDMARKS_LIST';
export const LANDMARK_LIST_PREF_KEY: string = 'fleetLocate.landmarkList';
export type LandmarksListTypeAction = {
    type: string;
    payload?: Object;
}
