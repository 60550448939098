/* @flow */
import * as c from './constants.sendCommand';

type stateType = {
    assetSendCmdDetails: {
        status: boolean,
        data: {
            msg: string,
            status?: string,
        },
    },
    assetAllowedCommandList: Array<Object>,
}

type actionType = {
    type: string,
    payload :{
        response:{
            status?: string,
        },
        error: {
            response: Object,
        },
    }
}

export const initialState = {
    assetSendCmdDetails: {
        status: false,
        data: {
            msg: '',
            status: 'Pending',
        },
    },
    assetAllowedCommandList: [{}],
};

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case c.SEND_COMMAND_ERROR:
        return {
            ...state,
            assetSendCmdDetails: {
                status: false,
                data: {
                    ...action.payload.error,
                    msg: 'Unable to send command to the asset',
                },
            },
        };
    case c.FETCH_ASSET_ALLOWED_CMDS_SUCCESS:
        return {
            ...state,
            assetAllowedCommandList: action.payload.response,
        };
    default:
        return state;
    }
};
