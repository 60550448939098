/* @flow */
import React from 'react';
import classNames from 'classnames';
import Parser from 'html-react-parser';
import { SnackbarContent, Button, Snackbar } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import * as actions from './actions.spudNotification';
import { reduxConnect } from '../../hoc';
import { SHOW_SPUD_BAR } from './constants.spudNotification';
import { getLocalStorageValue, setLocalStorageItem, updateAccountSettings, handleSpudNotification } from './../../helper-classes/utility-functions';

export type Props = {
    fetchAccountSettings: Function,
    classes: Object,
    accountSettings: any,
};

export type State = {
    open: boolean,
}

const styles = ({
    bottomPadding: {
        bottom: 10,
    },
    notificationColor: {
        backgroundColor: '#343e48',
    },
    okButtonColor: {
        color: '#007AFF',
    },
});

class SpudNotification extends React.Component <Props, State> {
    spudNotificationText: string;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this.spudNotificationText = '';
    }

    UNSAFE_componentWillMount() {
        if (getLocalStorageValue(SHOW_SPUD_BAR) === 'false') this.setState({ open: false });
        else {
            this.spudNotificationText = handleSpudNotification('get');
            if (this.spudNotificationText !== '') this.setState({ open: true });
        }
    }

    componentDidMount() {
        this.props.fetchAccountSettings();
        window.onbeforeunload = () => {
            setLocalStorageItem(
                SHOW_SPUD_BAR,
                getLocalStorageValue(SHOW_SPUD_BAR) || (this.state.open ? 'true' : 'false'),
            );
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.spudNotificationText !== '' && getLocalStorageValue(SHOW_SPUD_BAR) !== 'false') this.setState({ open: true });
        else this.setState({ open: false });

        if (this.props.accountSettings !== nextProps.accountSettings) {
            updateAccountSettings(nextProps.accountSettings);
        }
    }

    handleClick = () => this.setState({ open: true });

    handleClose = (e, reason) => {
        if (reason === 'clickaway') return;
        setLocalStorageItem(SHOW_SPUD_BAR, 'false');
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={this.state.open}
                onClose={this.handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                className={classes.bottomPadding}
            >
                <SnackbarContent
                    message={Parser(this.spudNotificationText)}
                    className={classNames(classes.notificationColor)}
                    action={[<Button key="undo" className={classes.okButtonColor} onClick={this.handleClose}>OK</Button>]}
                />
            </Snackbar>
        );
    }
}

const mapStateToProps = state => ({
    accountSettings: state.spudMessage.accountSettings,
});

export default withStyles(styles)(reduxConnect(SpudNotification, actions, mapStateToProps));
