/* @flow */
import { combineEpics } from 'redux-observable';
import {
    GLOBAL_SEARCH,
    USER_IDENTITY,
    recordsPerPage,
    NEAREST_ASSET_LANDMARK,
} from './contants.globalsearch';
import {
    globalSearchSuccess,
    globalSearchError,
    userIdentitySuccess,
    userIdentityError,
    fetchNearestAssetLandmarkError,
    fetchNearestAssetLandmarkSuccess,
} from './actions.globalsearch';
import config from '../../constants/Config';
import { isEmpty, getPermissionValue, getAppToken } from '../../helper-classes/utility-functions';

type ObservarblesTypes = {
    getJSON: Function,
    fetchPredictions: Function,
    of: Function,
    fetchLocationByPlaceId: Function
}

let assetsAndOthers = {};

const formatData = (data) => {
    const {
        content: {
            assets, drivers, landmarks,
        },
    } = data;
    if (getPermissionValue('Assets') === 'None') {
        assets.data = [];
        assets.count = 0;
        assets.total = 0;
    } else if (assets && assets.data && assets.data.length > recordsPerPage) {
        assets.data = assets.data.splice(0, recordsPerPage);
    }
    if (getPermissionValue('Drivers') === 'None') {
        drivers.data = [];
        drivers.count = 0;
        drivers.total = 0;
    } else if (drivers && drivers.data && drivers.data.length > recordsPerPage) {
        drivers.data = drivers.data.splice(0, recordsPerPage);
    }
    if (getPermissionValue('Landmarks') === 'None') {
        landmarks.data = [];
        landmarks.count = 0;
        landmarks.total = 0;
    } else if (landmarks && landmarks.data && landmarks.data.length > recordsPerPage) {
        landmarks.data = landmarks.data.splice(0, recordsPerPage);
    }

    return { assets, drivers, landmarks };
};

const place = {
    latitude: 0,
    longitude: 0,
};

export const epicGlobalSearch = (
    actions$: Function,
    store: Object,
    { getJSON, of, fetchPredictions }: ObservarblesTypes,
) =>
    actions$
        .ofType(GLOBAL_SEARCH)
        .distinctUntilChanged()
        .debounceTime(config.get('DEBOUNCE_TIME'))
        .mergeMap((action) => {
            const appToken = getAppToken();
            const headers = {
                'X-Nspire-UserToken': store.getState().userSession.userToken,
                'X-Nspire-AppToken': appToken,
                'Content-Type': 'application/json',
            };// TODO, below is the dummy API for mocking the response,
            // `${config.get('FLEET_VIEW_SERVICES_URL')}/search/?search=tes&start=0&size=10`
            const { query, page } = action.payload;

            const autocompleteService = window.googleMaps &&
                window.googleMaps.places &&
                window.googleMaps.places.AutocompleteService ? new window.googleMaps.places.AutocompleteService() : '';

            return getJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/search?search=${query}&start=${page * recordsPerPage}&limit=${recordsPerPage}`, headers)
                .mergeMap((res) => {
                    assetsAndOthers = formatData(res);
                    return fetchPredictions(autocompleteService, query);
                })
                .map((googlePlacesRes) => {
                    const {
                        assets, drivers, landmarks,
                    } = assetsAndOthers;
                    return globalSearchSuccess({
                        addresses: googlePlacesRes,
                        asset: assets,
                        driver: drivers,
                        landmark: landmarks,
                    });
                })
                .catch((error) => {
                    // Though You get the Error from Google Places Prediction API,
                    // The other Data should be available
                    if (!isEmpty(assetsAndOthers)) {
                        const {
                            assets, drivers, landmarks,
                        } = assetsAndOthers;
                        return of(globalSearchSuccess({
                            addresses: [],
                            asset: assets,
                            driver: drivers,
                            landmark: landmarks,
                        }));
                    }
                    return of(globalSearchError(error, {}));
                });
        });

export const userIdentity =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(USER_IDENTITY)
            .mergeMap(() => {
                const appToken = getAppToken();
                const headers = {
                    'X-Nspire-UserToken': store.getState().userSession.userToken,
                    'X-Nspire-AppToken': appToken,
                };
                const timeStamp = (new Date()).getTime();
                return getJSON(`${config.get('PLATFORM_API_BASE_URL')}/identity?date=${timeStamp}`, headers)
                    .map(result => userIdentitySuccess(result))
                    .catch(error => of(userIdentityError(error)));
            });

export const nearestAssetOrLandmark =
    (actions$: Function, store: Object, { getJSON, of, fetchLocationByPlaceId }:
ObservarblesTypes) =>
        actions$
            .ofType(NEAREST_ASSET_LANDMARK)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': store.getState().userSession.userToken,
                };
                const geocoder = window.googleMaps &&
                window.googleMaps.Geocoder ? new window.googleMaps.Geocoder() : '';
                return fetchLocationByPlaceId(geocoder, action.payload.placeId)
                    .mergeMap((results) => {
                        if (results && results.length > 0) {
                            place.latitude = results[0].geometry.location.lat();
                            place.longitude = results[0].geometry.location.lng();
                        }
                        return getJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/${action.payload.searchType}/nearest?lat=${place.latitude.toString()}&lng=${place.longitude.toString()}`, headers);
                    })
                    .map(result => fetchNearestAssetLandmarkSuccess(result.count > 0 ? result.data[0].id : 'NA'))
                    .catch(error => of(fetchNearestAssetLandmarkError(error)));
            });


export default combineEpics(epicGlobalSearch, userIdentity, nearestAssetOrLandmark);
