/* @flow */
import React, { Component } from 'react';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.settings';
import Header from '../Header/SimpleHeader';
import styles from './Settings.module.scss';
import UserSettings from '../../components/Settings/Settings';
import AppLoader from '../../components/AppLoader';
import { getDefaultLandingPage, checkUserSettings } from '../../helper-classes/utility-functions';
import EditAccountPopup from '../../components/SideMenu/EditAccountPopup';

export type Props = {
    user: Object,
    settings: Object,
    buttonClicked: boolean,
    updateAccountDetails: boolean,
    updateAppSettings: boolean,
    updatePreferences: boolean,
    accountDetail: Function,
    saveAccountDetail: Function,
    saveAppSettings: Function,
    savePreferences: Function,
    updateUserLoader: Function,
    accountDetailByIdentity: Function,
    getUserSettings: Function,
    isLoader: boolean,
    history: {
        goBack: Function,
    },
};

export type State = {
    disableBackButton: boolean,
};

class Settings extends Component<Props, State> {
    height: number;
    static defaultProps = {
        settings: {},
    };

    constructor(props) {
        super(props);
        this.state = {
            disableBackButton: false,
        };
    }

    componentDidMount() {
        this.props.updateUserLoader(true);
        this.props.accountDetailByIdentity();
        this.props.getUserSettings();
        if (!localStorage.getItem('selectedAccount') && getDefaultLandingPage() !== '/settings') {
            this.props.history.goBack();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.settings && nextProps.updateAppSettings) {
            this.savePreferences(nextProps.settings);
        }
    }

    getAccountData = (id: string) => this.props.accountDetail(id);

    saveAccountDetail = (data: Object, id: string) => this.props.saveAccountDetail(data, id);

    saveAppSettings = (data: Object) => this.props.saveAppSettings(data);

    savePreferences = (data: Object) => this.props.savePreferences(data);

    disableBackButton = disable => this.setState({ disableBackButton: disable });

    render() {
        return (
            <div className="app">
                {checkUserSettings() &&
                <EditAccountPopup />
                }
                {this.props.isLoader ? <AppLoader type="fullScreen" /> : ''}
                <Header
                    key={new Date().getTime()}
                    redirectToBack={this.props.history.goBack}
                    disableBackButton={this.state.disableBackButton}
                />
                <div className={styles.container} >
                    <UserSettings
                        accountDetail={this.props.user}
                        appSettings={this.props.settings}
                        saveAccountDetail={this.saveAccountDetail}
                        saveAppSettings={this.saveAppSettings}
                        savePreferences={this.savePreferences}
                        buttonClicked={this.props.buttonClicked}
                        updateAccountDetails={this.props.updateAccountDetails}
                        updateAppSettings={this.props.updateAppSettings}
                        updatePreferences={this.props.updatePreferences}
                        disableBackButton={this.disableBackButton}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings.settings,
    fetchAccountDetails: state.settings.fetchAccountDetails,
    updateAccountDetails: state.settings.updateAccountDetails,
    updateAppSettings: state.settings.updateAppSettings,
    updatePreferences: state.settings.updatePreferences,
    buttonClicked: state.settings.buttonClicked,
    user: state.settings.userAccountDetailsByIdentity,
    isLoader: state.globalSearch.isLoader,
});

export default reduxConnect(Settings, actions, mapStateToProps);
