/* eslint-disable */
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { Divider } from '@mui/material';
import { ALERT, ALERT_SCHEDULE } from '../../../containers/Alerts/constants.alerts';
import { convertTime12to24, convertTime24to12, scheduleLabel, validate } from '../../../util/alert_utils';
import formJson from '../../../containers/Alerts/Steppers/formJson';
import styles from './AlertStepper.module.scss';

const daysForAlertSchedule = ALERT_SCHEDULE.ALERT_SCHEDULE_TIME.DAYS_FOR_ALERT_SCHEDULE;
const timeForAlertSchedule = ALERT_SCHEDULE.ALERT_SCHEDULE_TIME.TIME_FOR_ALERT_SCHEDULE;

type Props = {
    formData: {
        alertSchedulesJSON: any,
    },
    alertType: String,
    scheduleUnChecked: Boolean,
    uncheckSchedule: Function,
    getScheduleJson: Function,
};

class AlertScheduler extends React.Component<Props> {
    state = {
        selectedStartDay: ALERT_SCHEDULE.ALERT_SCHEDULE_TIME.START_DAY,
        selectedStartTime: ALERT_SCHEDULE.ALERT_SCHEDULE_TIME.START_TIME,
        selectedEndDay: ALERT_SCHEDULE.ALERT_SCHEDULE_TIME.END_DAY,
        selectedEndTime: ALERT_SCHEDULE.ALERT_SCHEDULE_TIME.END_TIME,
        chipData: [],
        scheduleJsonArray: [],
    };

    UNSAFE_componentWillMount() {
        if (this.props.alertType === ALERT.ODD_HOURS_ALERT.CODE) {
            this.props.uncheckSchedule(false);
        }
        if (Object.keys(this.props.formData.alertSchedulesJSON).length > 0) {
            const scheduleJsonArray = JSON.parse(this.props.formData.alertSchedulesJSON);
            const chipData = [];
            Object.keys(scheduleJsonArray).forEach((key) => {
                const {
                    validStartDay: StartD,
                    validStartTime: StartT,
                    validEndDay: EndD,
                    validEndTime: EndT,
                } = scheduleJsonArray[key];
                const label = scheduleLabel(
                    StartD,
                    convertTime24to12(StartT),
                    EndD,
                    convertTime24to12(EndT),
                );
                const chipObject = {
                    key: parseInt(key, 10),
                    label,
                };
                chipData.push(chipObject);
            });

            this.setState({
                scheduleJsonArray,
                chipData,
            });
        }
    }
    // Delete alert schedule data
    handleDelete = data => () => {
        this.setState((state) => {
            const chipData = [...state.chipData];
            const chipToDelete = chipData.indexOf(data);
            chipData.splice(chipToDelete, 1);
            this.handleSheduleJsonOnDelete(data);
            return { chipData };
        });
    };

    handleSheduleJsonOnDelete = (data) => {
        let scheduleJsonArray = this.state.scheduleJsonArray.filter((object) => {
            const {
                validStartDay: StartD,
                validStartTime: StartT,
                validEndDay: EndD,
                validEndTime: EndT,
            } = object;
            const label = scheduleLabel(
                StartD,
                convertTime24to12(StartT),
                EndD,
                convertTime24to12(EndT),
            );
            return (label !== data.label);
        });

        scheduleJsonArray = scheduleJsonArray.map(d => {
            let newData = d;
            newData.timezone = formJson().userTz;
            return newData;
            }
        )

        this.state.scheduleJsonArray = scheduleJsonArray;
        this.handleSheduleJsonUpdate(scheduleJsonArray);
    };

    handleSheduleJsonUpdate = (scheduleJsonArray) => {
        const scheduleJson = JSON.stringify(scheduleJsonArray);
        this.props.getScheduleJson(scheduleJson);
    };

    // Enable-Disable schedule functionality
    handleCheckboxChange = () => {
        this.props.uncheckSchedule(!this.props.scheduleUnChecked);
        if (!this.props.scheduleUnChecked) {
            this.props.getScheduleJson({});
            this.setState({
                chipData: [],
                selectedStartDay: ALERT_SCHEDULE.ALERT_SCHEDULE_TIME.START_DAY,
                selectedStartTime: ALERT_SCHEDULE.ALERT_SCHEDULE_TIME.START_TIME,
                selectedEndDay: ALERT_SCHEDULE.ALERT_SCHEDULE_TIME.END_DAY,
                selectedEndTime: ALERT_SCHEDULE.ALERT_SCHEDULE_TIME.END_TIME,
            });
        }
    };

    // Update state on select change
    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    // Update alertScheduleData on click of add button
    onAlertScheduleAdd = () => {
        let uniqueChip = true;

        const {
            selectedStartDay: StartD,
            selectedStartTime: StartT,
            selectedEndDay: EndD,
            selectedEndTime: EndT,
        } = this.state;
        const label = scheduleLabel(StartD, StartT, EndD, EndT);
        if (this.state.chipData.length > 0) {
            const chipMatchingGivenLabel = this.state.chipData
                .filter(chipObject => chipObject.label === label);
            if (chipMatchingGivenLabel.length > 0) {
                uniqueChip = false;
            }
        }
        if (uniqueChip) {
            let key = 0;
            if (this.state.chipData.length > 0) {
                for (let i = 0; i <= this.state.chipData.length; i += 1) {
                    const chipMatchingGivenKey = this.state.chipData
                        .filter(chipObject => chipObject.key === i);
                    if (chipMatchingGivenKey.length === 0) {
                        key = i;
                        break;
                    }
                }
            }
            const chipObject = {
                key,
                label,
            };
            const scheduleJsonObject = {
                validStartDay: '',
                validEndDay: '',
                validStartTime: '',
                validEndTime: '',
                timezone: formJson().userTz,
            };
            scheduleJsonObject.validStartDay = this.state.selectedStartDay;
            scheduleJsonObject.validEndDay = this.state.selectedEndDay;
            scheduleJsonObject.validStartTime = convertTime12to24(this.state.selectedStartTime);
            scheduleJsonObject.validEndTime = convertTime12to24(this.state.selectedEndTime);
            this.state.scheduleJsonArray.push(scheduleJsonObject);
            this.state.chipData.push(chipObject);
            this.setState({
                chipData: this.state.chipData,
                selectedStartDay: ALERT_SCHEDULE.ALERT_SCHEDULE_TIME.START_DAY,
                selectedStartTime: ALERT_SCHEDULE.ALERT_SCHEDULE_TIME.START_TIME,
                selectedEndDay: ALERT_SCHEDULE.ALERT_SCHEDULE_TIME.END_DAY,
                selectedEndTime: ALERT_SCHEDULE.ALERT_SCHEDULE_TIME.END_TIME,
                scheduleJsonArray: this.state.scheduleJsonArray,
            }, () => {
                this.handleSheduleJsonUpdate(this.state.scheduleJsonArray);
            });
            const myJSON = JSON.stringify(this.state.scheduleJsonArray);
            this.props.getScheduleJson(myJSON);
        }
    };

    displayDateTimeSelectBox = (typeSE, DAY_LABEL, TIME_LABEL, selectedDay, selectedTime) => {
        const typeC = typeSE.charAt(0).toUpperCase() + typeSE.slice(1);
        return (
            <div>
                <FormControl>
                    <InputLabel htmlFor="age-helper" disabled={this.props.scheduleUnChecked} className={styles['alertSchedule-inputlabel']}>
                        {DAY_LABEL}
                    </InputLabel>
                    <Select
                        sx={{ '& .MuiSvgIcon-root': { top: 'calc(50% - 8px)' } }}
                        className={styles['droppdown-wid']}
                        value={selectedDay}
                        onChange={this.handleChange(`selected${typeC}Day`)}
                        disabled={this.props.scheduleUnChecked}
                        input={
                            <Input name={`${typeSE}-day`} id={`${typeSE}-day`} disableUnderline />
                        }
                    >
                        {
                            daysForAlertSchedule.map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <div className={styles['in-padd']}>
                    <FormControl>
                        <InputLabel htmlFor="age-helper" disabled={this.props.scheduleUnChecked} className={styles['alertSchedule-inputlabel']}>
                            {TIME_LABEL}
                        </InputLabel>
                        <Select
                            sx={{ '& .MuiSvgIcon-root': { top: 'calc(50% - 8px)' } }}
                            className={styles['droppdown-wid']}
                            value={selectedTime}
                            onChange={this.handleChange(`selected${typeC}Time`)}
                            disabled={this.props.scheduleUnChecked}
                            input={
                                <Input
                                    name={`${typeSE}-time`}
                                    id={`${typeSE}-time`}
                                    disableUnderline
                                />
                            }
                        >
                            {
                                timeForAlertSchedule.map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </div>
        );
    };

    render() {
        const {
            selectedStartDay: StartD,
            selectedStartTime: StartT,
            selectedEndDay: EndD,
            selectedEndTime: EndT,
        } = this.state;
        const validationErrors = validate(StartD, StartT, EndD, EndT);
        const isDisabled = Object.keys(validationErrors).some(x => validationErrors[x]);
        const errorMessage = Object.values(validationErrors)[0] ?
            Object.values(validationErrors)[0] : '';

        return (
            <div className={styles['step-content']}>
                <form>
                    <div>
                        <FormControlLabel
                            className={styles['Alert-Schedule-Checkbox']}
                            control={
                                <Checkbox
                                    id="alertScheduleCB"
                                    disabled={this.props.alertType === ALERT.ODD_HOURS_ALERT.CODE}
                                    checked={!this.props.scheduleUnChecked}
                                    value={(!this.props.scheduleUnChecked).toString()}
                                    color="primary"
                                    onChange={this.handleCheckboxChange}
                                />
                            }
                            label={ALERT_SCHEDULE.LABEL.CREATE_ALERT_SCHEDULE}
                        />
                    </div>
                    <div className={styles['schedule-container']}>
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                {
                                    this.displayDateTimeSelectBox(
                                        'start',
                                        ALERT_SCHEDULE.LABEL.START_DAY,
                                        ALERT_SCHEDULE.LABEL.START_TIME,
                                        this.state.selectedStartDay,
                                        this.state.selectedStartTime,
                                    )
                                }
                            </Grid>

                            <Grid item xs={3}>
                                <div>
                                    {
                                        this.displayDateTimeSelectBox(
                                            'end',
                                            ALERT_SCHEDULE.LABEL.END_DAY,
                                            ALERT_SCHEDULE.LABEL.END_TIME,
                                            this.state.selectedEndDay,
                                            this.state.selectedEndTime,
                                        )
                                    }
                                    <FormHelperText style={{ color: 'red' }}>
                                        {
                                            this.props.scheduleUnChecked ? '' : errorMessage
                                        }
                                    </FormHelperText>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={styles['selectb-padding']}>
                                    <Button
                                        id="scheduleAdd"
                                        variant="contained"
                                        color="primary"
                                        disabled={this.props.scheduleUnChecked || isDisabled}
                                        onClick={this.onAlertScheduleAdd}
                                    >
                                        {ALERT_SCHEDULE.LABEL.ADD}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <br />
                    <Divider />
                    <br />
                    <div className={styles['chip-container']}>
                        {this.state.chipData.map(data => (
                            <Chip
                                className={styles['chip-spacing']}
                                key={data.key}
                                label={data.label}
                                onDelete={this.handleDelete(data)}
                            />
                        ))}
                    </div>
                </form>
            </div>
        );
    }
}

export default AlertScheduler;
