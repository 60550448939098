/* @flow */
import React, { Component } from 'react';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.kml';
import styles from './kml.module.scss';
import KmlDetailsContainer from './kmlDetailsContainer';

export type Props = {
    refreshKmlPage: Function,
    fetchKml: Function,
    kmls: any,
    kmlLoader: boolean,
    kmlPreference: any,
    updateKmlPreferenceUrls: Function,
    zoomToKmlLayer: Function,
    deleteKml: Function,
    isError: boolean,
    isDeleted: boolean,
    isDeleteError: boolean,
    error: any,
    closeDialog: Function,
    addKml: Function,
    addedSuccessfully: boolean,
    kmlPreferenceMethod: string,
    isKmlListError: boolean,
    addedKml: any,
    showBackButton: boolean,
    handleKmlBackBtnClick: Function,
};

export type State = {
    recordsPerPage: number,
    currentPage: number,
    showKmlRefeshChip: boolean,
};

class KmlDetails extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            recordsPerPage: 1000,
            currentPage: 0,
            showKmlRefeshChip: false,
        };
    }

    componentDidMount() {
        const { currentPage, recordsPerPage } = this.state;
        this.props.fetchKml({
            showLoader: true,
            fatchKml: true,
            recordsPerPage,
            currentPage,
        });
        this.props.refreshKmlPage(this.getPageData);
    }

    getPageData = (showChip) => {
        if (showChip && !this.state.showKmlRefeshChip) {
            this.setState({ showKmlRefeshChip: showChip });
        }
    }

    getKmlListData = (page, showLoader) => {
        this.setState({ currentPage: page.currentPage }, () => {
            this.props.fetchKml({
                showLoader,
                fatchKml: showLoader,
                recordsPerPage: this.state.recordsPerPage,
                currentPage: this.state.currentPage,
            });
        });
    }

    refreshKmlListData = (refresh) => {
        if (refresh) {
            this.getKmlListData({ currentPage: 0 }, true);
        }
        this.setState({ showKmlRefeshChip: false });
    }

    pageChanged = page => this.getKmlListData(page, false);

    render() {
        return (
            <div className={styles.container} >
                <KmlDetailsContainer
                    showKmlRefeshChip={this.state.showKmlRefeshChip}
                    refreshKmlListData={this.refreshKmlListData}
                    kmlLoader={this.props.kmlLoader}
                    isError={this.props.isError}
                    isKmlListError={this.props.isKmlListError}
                    isDeleted={this.props.isDeleted}
                    isDeleteError={this.props.isDeleteError}
                    response={this.props.kmls}
                    pageChanged={this.pageChanged}
                    kmlPreference={this.props.kmlPreference}
                    updateKmlPreferenceUrls={this.props.updateKmlPreferenceUrls}
                    zoomToKmlLayer={this.props.zoomToKmlLayer}
                    deleteKml={id => this.props.deleteKml(id)}
                    error={this.props.error}
                    closeDialog={this.props.closeDialog}
                    addKml={this.props.addKml}
                    addedKml={this.props.addedKml}
                    addedSuccessfully={this.props.addedSuccessfully}
                    kmlPreferenceMethod={this.props.kmlPreferenceMethod}
                    showBackButton={this.props.showBackButton}
                    handleKmlBackBtnClick={this.props.handleKmlBackBtnClick}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    kmls: state.Kml.kmls,
    kmlLoader: state.Kml.kmlLoader,
    isError: state.Kml.isError,
    isDeleted: state.Kml.isDeleted,
    isDeleteError: state.Kml.isDeleteError,
    error: state.Kml.error,
    isKmlListError: state.Kml.isKmlListError,
    addedSuccessfully: state.Kml.addedSuccessfully,
    addedKml: state.Kml.addedKml,
});

export default reduxConnect(KmlDetails, actions, mapStateToProps);
