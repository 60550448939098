/* @flow */
export const APP_NOTIFICATION_HANDLER: string = 'APP_NOTIFICATION_HANDLER';
export const ADD_CARD_NOTIFICATION: string = 'ADD_CARD_NOTIFICATION';
export const REMOVE_CARD_NOTIFICATION: string = 'REMOVE_CARD_NOTIFICATION';
export const CLEAR_NOTIFICATION: string = 'CLEAR_NOTIFICATION';
export const FETCH_IN_PROGRESS_IMPORT_JOBS: string = 'FETCH_IN_PROGRESS_IMPORT_JOBS';
export const FETCH_IN_PROGRESS_IMPORT_JOBS_STATUS: string = 'FETCH_IN_PROGRESS_IMPORT_JOBS_STATUS';
export const ADD_NEW_IN_PROGRESS_IMPORT_JOB: string = 'ADD_NEW_IN_PROGRESS_IMPORT_JOB';
export const REMOVE_IN_PROGRESS_IMPORT_JOB: string = 'REMOVE_IN_PROGRESS_IMPORT_JOB';
export const NOTIFICATION_PULL_HANDLER: string = 'NOTIFICATION_PULL_HANDLER';
export const NOTIFICATION_POLLING: string = 'NOTIFICATION_POLLING';
export const IMPORT_JOB_POLLING_SUBSCRIBER: string = 'IMPORT_JOB_POLLING_SUBSCRIBER';
export const UNSUBSCRIBER_POLLING_JOBS: string = 'UNSUBSCRIBER_POLLING_JOBS';
export const IMPORT_JOB_SUBSCRIBER_SS_NAME: string = 'ijSubs';
export const IMPORT_ACTION_GET: string = 'get';
export const IMPORT_ACTION_ADD: string = 'add';
export const IMPORT_ACTION_ADDNEW: string = 'addNew';
export const IMPORT_ACTION_UPDATE: string = 'update';
export const IMPORT_ACTION_REMOVE: string = 'remove';

export type AppNotificationHandlerAction = {
    type: string;
    payload?: Object;
}
