/* @flow */
import moment from 'moment';
import { combineEpics } from 'redux-observable';
import { FETCH_MAINTENANCE_DIAGNOSTICS_LIST } from './constants.diagnosticList';
import { fetchDiagnosticListSuccess, fetchDiagnosticListError } from './actions.diagnosticList';
import { getUserToken } from '../../../helper-classes/utility-functions';
import config from '../../../constants/Config';
import MaintenanceFilterHelper from '../../../components/Maintenance/MaintenanceFilterHelper';

type ObservablesTypes = {
    getJSON: Function,
    of: Function
}

export const getDiagnostics =
(actions$: Function, store: Object, { getJSON, of }: ObservablesTypes) =>
    actions$
        .ofType(FETCH_MAINTENANCE_DIAGNOSTICS_LIST)
        .mergeMap((action) => {
            const {
                order,
                orderBy,
                pageNumber,
                pageSize,
                filtersToSend,
                limit,
            } = action.payload;
            const { dateRangeOption } = MaintenanceFilterHelper;
            const headers = {
                'X-Nspire-UserToken': getUserToken(),
            };
            let startDate = null;
            let endDate = null;
            const serviceURL = `${config.get('MAINTENANCE_SERVICES_URL')}/diagnosticStatus`;
            const recordsToGet = (limit > 0 && (pageNumber * pageSize) + pageSize > limit) ?
                (limit - (pageNumber * pageSize)) : pageSize;
            let params = `offset=${pageNumber * pageSize}&limit=${recordsToGet}`;
            if (order && orderBy) {
                params += `&sort=${orderBy}:${order.toUpperCase()}`;
            }
            if (filtersToSend && filtersToSend.length > 0) {
                const hasDateRange = filtersToSend.filter(filter => (
                    filter.property === 'filterDate' && filter.value === dateRangeOption.id
                )).length > 0;
                filtersToSend.forEach((filter) => {
                    const { data } = filter;
                    if (hasDateRange && data.parentProperty === 'filterDate') {
                        if (filter.property === 'startDate') {
                            startDate = moment(filter.value).startOf('day').utc().toISOString();
                        } else if (hasDateRange && filter.property === 'endDate') {
                            endDate = moment(filter.value).endOf('day').utc().toISOString();
                        }
                    } else if (filter.property === 'filterDate') {
                        if (data.getStartDate && data.getEndDate) {
                            startDate = moment.utc(data.getStartDate()).toISOString();
                            endDate = moment.utc(data.getEndDate()).toISOString();
                        }
                    } else {
                        params += `&${filter.property}=${filter.value}`;
                    }
                });
            }
            if (startDate) {
                params += `&startDate=${startDate}`;
            }
            if (endDate) {
                params += `&endDate=${endDate}`;
            }

            return getJSON(`${serviceURL}?${params}`, headers)
                .map(result => fetchDiagnosticListSuccess(result.content, result.total))
                .catch(error => of(fetchDiagnosticListError(error)));
        });

export default combineEpics(getDiagnostics);
