/* @flow */
import React, { Component } from 'react';
import { reduxConnect } from '../../../hoc';
import * as actions from './actions.user';
import AppLoader from '../../../components/AppLoader';
import AddUser from '../../../components/AddUser/AddUser';
import { getParamValue } from '../../../helper-classes/utility-functions';
import Dialog from './../../../components/SharedComponents/Dialog/Dialog';
import analytics from '../../../analytics/index';

export type Props = {
    history: {
        push: Function,
    },
    createUser: Function,
    editUser: Function,
    deleteUser: Function,
    fetchUser: Function,
    fetchUserRoles: Function,
    fetchUserAccess: Function,
    addUserError: Function,
    updateLoader: Function,
    closeDialog: Function,
    loading: boolean,
    isUpdating: boolean,
    success: boolean,
    successMsg: string,
    isError: boolean,
    error: Object,
    user: Object,
    userRoles: Array<{
        id: number,
        name: string,
    }>,
    userAccess: Array<{
        id: number,
        name: string,
    }>,
    match: any,
};

export type State = {
    isDelete: boolean,
    id: string;
};

class User extends Component<Props, State> {
    isEdit: boolean;
    isDeleted: boolean;
    button: Array<Object>;
    securityUniversesJSONDisplay: string;
    simplifiedPermissions: any;
    id: string;

    constructor(props) {
        super(props);
        this.state = {
            isDelete: false,
            id: '',
        };
        this.isEdit = false;
        this.isDeleted = false;
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
        }];
        this.securityUniversesJSONDisplay = '';
        this.simplifiedPermissions = [];
        this.id = getParamValue(this.props, 'id');
    }
    componentDidMount() {
        this.loadPageData();
    }

    componentDidUpdate() {
        if (this.props.match.params.id && this.props.match.params.id !== this.id) {
            this.loadPageData();
        }
    }

    loadPageData = () => {
        const id = getParamValue(this.props, 'id');
        this.isDeleted = false;
        this.id = id;
        if (id) {
            this.isEdit = true;
            this.props.updateLoader(true);
            this.props.fetchUser(id);
        }
        this.props.fetchUserRoles();
        this.props.fetchUserAccess();
    }

    createUser = (data) => {
        this.props.updateLoader(true);
        if (!this.isUserHavingFullAccess(data) && this.isEldManager(data)) {
            this.props.addUserError({
                msg: 'The User Role Compliance Section permission requires, the Full Access option for User Access.' +
                    'Please select Full Access option from User Access.',
            });
        } else {
            this.props.createUser(data);
        }
        const params = {
            feature: 'Admin',
            firstName: data.firstName,
            lastName: data.lastName,
            timeZone: data.tz,
            unitMeasure: data.units,
        };
        analytics.track('ADD_USER', params);
    }

    editUser = (data) => {
        this.props.updateLoader(true);
        if (!this.isUserHavingFullAccess(data) && this.isEldManager(data)) {
            this.props.addUserError({
                msg: 'The User Role Compliance Section permission requires, the Full Access option for User Access.' +
                    'Please select Full Access option from User Access.',
            });
        } else {
            this.props.editUser(data);
        }
        const params = {
            feature: 'Admin',
            firstName: data.firstName,
            lastName: data.lastName,
            timeZone: data.tz,
            unitMeasure: data.units,
            resendInvitation: data.resendInvitation,
        };
        analytics.track('EDIT_USER', params);
    }

    deleteUser = (id) => {
        this.setState({ isDelete: true, id });
    }

    isUserHavingFullAccess = (data) => {
        this.securityUniversesJSONDisplay = data.securityUniversesJSONDisplay;
        if (this.securityUniversesJSONDisplay.search('"name":Full Access') > 0) {
            return true;
        }
        return false;
    }

    isEldManager = (data) => {
        let isELDManager = false;
        this.simplifiedPermissions = data.roleJsonDisplay;
        if (this.simplifiedPermissions.length > 0) {
            this.simplifiedPermissions.map((r) => {
                if (r.code === 'localFleet-eld' && r.value !== 'None') {
                    isELDManager = true;
                }
                return '';
            });
        }
        return isELDManager;
    }

    formatError = (content, error) => {
        let newContent = content;
        if (error && error.errors) {
            newContent =
                (
                    <div>
                        {newContent}
                        <ul>
                            {
                                Object.keys(error.errors).map((d) => {
                                    let errorMessage = error.errors[d];
                                    if (typeof errorMessage === 'string' && errorMessage.toLowerCase().indexOf('is not a valid e-mail') > -1) {
                                        errorMessage = 'Invalid email address';
                                    }
                                    return <li key={d}>{d}: {errorMessage}</li>;
                                })
                            }
                        </ul>
                    </div>
                );
        } else if (error && error.message) {
            newContent = `${newContent} ${error.message}`;
        } else if (error && error.msg) {
            newContent = `${newContent} ${error.msg}`;
        } else {
            newContent = `${newContent} Something went wrong`;
        }
        return newContent;
    }

    handleClickOk = () => {
        const { success } = this.props;
        const { isDelete, id } = this.state;
        this.props.closeDialog();
        this.isDeleted = false;
        if (success) {
            this.props.history.push('/users');
        }
        if (isDelete) {
            this.props.updateLoader(true);
            this.props.deleteUser(id);
            this.setState({ isDelete: false });
            this.isDeleted = true;
        }
    }

    getType = () => {
        const { isError, success } = this.props;
        const { isDelete } = this.state;
        let type = '';
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
        }];

        if (success) {
            type = 'success';
        } else if (isError) {
            type = 'error';
        } else if (isDelete) {
            type = 'confirm';
            this.button.splice(0, 0, { title: 'Cancel', color: 'primary', handleClick: () => this.setState({ isDelete: false }) });
        }
        return type;
    }

    getContent = () => {
        let content = 'Are you sure you want to delete the user?';
        const {
            isError, success, error, successMsg,
        } = this.props;

        if (isError) {
            if (this.isEdit) {
                content = this.isDeleted ? 'Delete User Error :' : 'Edit User Error :';
            } else content = 'Add User Error:';
            content = this.formatError(content, error);
        } else if (success) {
            if (!this.isEdit) content = successMsg || 'User Added Successfully';
            else if (this.isEdit) {
                if (this.isDeleted) {
                    content = 'User Deleted Successfully ';
                    analytics.track('DELETE_USER', { feature: 'Admin' });
                } else content = successMsg || 'User Updated Successfully';
            }
        }
        return content;
    }

    render() {
        const {
            user,
            userRoles,
            userAccess,
            loading,
            isUpdating,
            success,
            isError,
        } = this.props;
        const { isDelete } = this.state;

        return (
            <React.Fragment>
                { (loading || this.props.isUpdating) && <AppLoader type="fullScreen" /> }
                <AddUser
                    createUser={data => this.createUser(data)}
                    editUser={data => this.editUser(data)}
                    deleteUser={id => this.deleteUser(id)}
                    user={user}
                    userRoles={userRoles}
                    userAccess={userAccess}
                    isUpdating={isUpdating}
                    isEdit={this.isEdit}
                />
                <Dialog
                    open={success || isError || isDelete}
                    type={this.getType()}
                    customTitle=""
                    button={this.button}
                    content={this.getContent()}
                    size="sm"
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isUpdating: state.addUser.isUpdating,
    loading: state.addUser.loading,
    user: state.addUser.user,
    userRoles: state.addUser.userRoles,
    userAccess: state.addUser.userAccess,
    error: state.addUser.error,
    isError: state.addUser.isError,
    success: state.addUser.success,
    successMsg: state.addUser.successMsg,
});

export default reduxConnect(User, actions, mapStateToProps);
