/* @flow */
import * as landmarkGroupConstants from './constants.landmarkGroupManage';

export const initialState = {
    landmarks: [],
    isUpdating: false,
    selectedLandmarks: [],
    isSelectedResultLoading: false,
    isPostLandmarkGroupLoading: false,
    landmarkGroupPostResponse: {},
    isFetchingLandmarkGroupDetails: false,
    landmarkGroupDetail: {},
    isLandmarkGroupDeleting: false,
    deleteResponse: {},
    isError: false,
    addedSuccessfully: false,
};

type stateType = {
    landmarks: Array<Object>,
    isUpdating: boolean,
    selectedLandmarks: Array<Object>,
    isSelectedResultLoading: boolean,
    isPostLandmarkGroupLoading: boolean,
    landmarkGroupPostResponse: Object,
    isFetchingLandmarkGroupDetails: boolean,
    landmarkGroupDetail: Object,
    isLandmarkGroupDeleting: boolean,
    deleteResponse: Object,
    isError: boolean,
    addedSuccessfully: boolean,
}
type actionType = {
    type: string,
    payload :{
        landmarks:Array<Object>,
        isUpdating: boolean,
        selectedLandmarks: Array<Object>,
        isSelectedResultLoading: boolean,
        isPostLandmarkGroupLoading: boolean,
        postResponse: Object,
        isFetchingLandmarkGroupDetails: boolean,
        landmarkGroupDetail: Object,
        isLandmarkGroupDeleting: boolean,
        deleteResponse: Object,
        error: string,
        showLoader: boolean,
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case landmarkGroupConstants.GRAILS_FETCH_LANDMARKS:
        return {
            ...state,
            isUpdating: true,
            isError: false,
            addedSuccessfully: false,
        };
    case landmarkGroupConstants.GRAILS_FETCH_LANDMARKS_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            landmarks: action.payload.landmarks,
            isError: false,
            addedSuccessfully: false,
        };
    case landmarkGroupConstants.GRAILS_FETCH_LANDMARKS_ERROR:
        return {
            ...state,
            isUpdating: false,
            isPostLandmarkGroupLoading: false,
            error: action.payload.error,
            isError: true,
            addedSuccessfully: false,
        };
    case landmarkGroupConstants.SHOW_LOADER:
        return {
            ...state,
            isPostLandmarkGroupLoading: action.payload.showLoader,
        };
    case landmarkGroupConstants.GRAILS_POST_LANDMARK_GROUP:
        return {
            ...state,
            isPostLandmarkGroupLoading: true,
            isError: false,
            addedSuccessfully: false,
        };
    case landmarkGroupConstants.GRAILS_FETCH_SELECTED_LANDMARKS:
        return {
            ...state,
            isSelectedResultLoading: true,
            isError: false,
            addedSuccessfully: false,
        };
    case landmarkGroupConstants.GRAILS_FETCH_SELECTED_LANDMARKS_SUCCESS:
        return {
            ...state,
            isSelectedResultLoading: false,
            selectedLandmarks: action.payload.selectedLandmarks,
            isError: false,
            addedSuccessfully: false,
        };
    case landmarkGroupConstants.GRAILS_POST_LANDMARK_GROUP_SUCCESS:
        return {
            ...state,
            landmarkGroupPostResponse: action.payload.postResponse,
            isPostLandmarkGroupLoading: false,
            isError: false,
            addedSuccessfully: true,
        };
    case landmarkGroupConstants.FETCH_LANDMARK_GROUP_DETAILS_GLOBALID:
        return {
            ...state,
            isFetchingLandmarkGroupDetails: true,
            isError: false,
            addedSuccessfully: false,
        };
    case landmarkGroupConstants.FETCH_LANDMARK_GROUP_DETAILS_GLOBALID_SUCCESS:
        return {
            ...state,
            isFetchingLandmarkGroupDetails: false,
            landmarkGroupDetail: action.payload.landmarkGroupDetail,
            isError: false,
            addedSuccessfully: false,
        };
    case landmarkGroupConstants.DELETE_LANDMARK_GROUP_GLOBALID:
        return {
            ...state,
            isLandmarkGroupDeleting: true,
            isError: false,
            addedSuccessfully: false,
        };
    case landmarkGroupConstants.DELETE_LANDMARK_GROUP_GLOBALID_SUCCESS:
        return {
            ...state,
            isLandmarkGroupDeleting: false,
            deleteResponse: action.payload.deleteResponse,
            isError: false,
            isDeleted: true,
            addedSuccessfully: false,
        };
    case landmarkGroupConstants.LANDMARK_AND_SELECTED_LANDMARK:
        return {
            ...state,
            isSelectedResultLoading: true,
            isError: false,
            addedSuccessfully: false,
        };
    case landmarkGroupConstants.GRAILS_FETCHLANDMARK_AND_SELECTED_LANDMARKS_SUCCESS:
        return {
            ...state,
            isSelectedResultLoading: false,
            selectedLandmarks: action.payload.selectedLandmarks,
            landmarks: action.payload.landmarks,
            isError: false,
            addedSuccessfully: false,
        };
    case landmarkGroupConstants.GRAILS_FETCHLANDMARK_AND_SELECTED_LANDMARKS_ERROR:
        return {
            ...state,
            isUpdating: false,
            isSelectedResultLoading: false,
            isError: false,
            addedSuccessfully: false,
        };
    case landmarkGroupConstants.CLOSE_DIALOG:
        return {
            ...state,
            isUpdating: false,
            isSelectedResultLoading: false,
            addedSuccessfully: false,
            isError: false,
            error: '',
        };
    default:
        return state;
    }
};
