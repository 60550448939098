/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Close from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import withStyles from '@mui/styles/withStyles';
import { Input, InputAdornment, Tooltip } from '@mui/material';
import searchIcon from '../../../assets/icons/search.svg';
import { SEARCH_CHAR_LIMIT } from '../../../constants/Config';


function renderSuggestion(suggestion, { query, isHighlighted }) {
    let field = suggestion.email;
    if (field === 'undefined' || field === undefined || field === '') {
        field = suggestion.mobileNumber;
    }
    const matches = match(field, query);
    const parts = parse(field, matches);

    return (
        <MenuItem selected={isHighlighted} component="div" key={Math.random()}>
            <div>
                {parts.map((part, index) => (part.highlight ? (
                    <span key={`${part.text}${String(index)}`} style={{ fontWeight: 500 }}>
                        {part.text}
                    </span>
                ) : (
                    <strong key={`${part.text}${String(index)}`} style={{ fontWeight: 300 }}>
                        {part.text}
                    </strong>
                )))}
            </div>
        </MenuItem>
    );
}

function getSuggestionValue(suggestion) {
    let suggestionField = suggestion.email;
    if (suggestionField === 'undefined' || suggestionField === undefined || suggestionField === '') {
        suggestionField = suggestion.mobileNumber;
    }
    return suggestionField;
}

const styles = theme => ({
    container: {
        display: 'inline-block',
        marginLeft: 20,
        width: 360,
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 2,
        marginTop: theme.spacing(),
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    inputText: {
        fontWeight: 500,
    },
});

export type Props = {
    value: string,
    selection: string,
    suggestions: Array<Object>,
    onSuggestionClick: Function,
    onSuggestionSearch: Function,
    renderInputComponent: Function,
    placeholder: String,
    closeSearch: Function,
    clearSearch: Function,
}

export type State = {
    focus: true,
    value: string,
    selection: string,
    suggestions: Array<Object>,
}

class ToolbarAutosuggest extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            value: '',
            focus: true,
            suggestions: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.onBlurHandler = this.onBlurHandler.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        this.setState({ suggestions: nextProps.suggestions });
    }

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleSuggestionsFetchSelected = (event, {
        suggestionValue,
    }) => {
        this.props.onSuggestionClick(suggestionValue);
    };

    clearSearch = () => {
        this.props.clearSearch();
        this.setState({
            value: '',
            focus: true,
            suggestions: [],
        });
    };

    onBlurHandler() {
        if (this.state.value.length <= 0) {
            this.props.closeSearch();
        }
    }

    renderInputComponent = (inputProps) => {
        const {
            inputRef = () => {
            }, ref, ...other
        } = inputProps;


        return (
            <Input
                fullWidth
                autoFocus={this.state.focus}
                key={Math.random()}
                value={this.state.value}
                className={this.props.classes.inputText}
                endAdornment={
                    <InputAdornment position="end">
                        {(this.state.value.length > 0) ?
                            <Tooltip key="close" title="Clear">
                                <Close onClick={() => this.clearSearch()} sx={{ color: '#000000' }}/>
                            </Tooltip> :
                            <Tooltip key="search" title="Search">
                                <img
                                    src={searchIcon}
                                    alt="search"
                                />
                            </Tooltip>
                        }
                    </InputAdornment>}
                InputProps={{
                    inputRef: (node) => {
                        ref(node);
                        inputRef(node);
                    },
                }}
                {...other}
            />
        );
    };

    getSuggestions = () => (this.state.value.length >= 3 ? this.state.suggestions : []);

    handleChange = name => (event, { newValue }) => {
        let value = newValue;
        if (newValue.length > SEARCH_CHAR_LIMIT) {
            value = newValue.substring(0, SEARCH_CHAR_LIMIT);
        }
        if (this.state[name] !== value) {
            this.setState({
                [name]: value,
                suggestions: [],
            });
            this.props.onSuggestionSearch(value);
        }
    };

    handleSuggestionsFetchRequested = ({ value }) => {
        this.handleSuggestionsClearRequested();
        this.setState({
            suggestions: value.length >= 3 ? this.getSuggestions(value) : [],
        });
    };

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (this.getSuggestions().length > 0) {
                let field = this.getSuggestions()[0].email;
                if (field === 'undefined' || field === undefined || field === '') {
                    field = this.getSuggestions()[0].mobileNumber;
                }
                this.setState({
                    value: field,
                });
                this.props.onSuggestionClick(field);
            }
        }
    };


    render() {
        const {
            value,
        } = this.state;

        const {
            classes,
        } = this.props;
        const inputProps = {
            placeholder: this.props.placeholder,
            value,
            onChange: this.handleChange('value'),
            onBlur: this.onBlurHandler,
            onKeyPress: this.handleKeyPress,
        };

        return (
            <Autosuggest
                autoFocus
                style={{ width: 329 }}
                suggestions={this.state.suggestions}
                onSuggestionSelected={this.handleSuggestionsFetchSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                renderInputComponent={this.renderInputComponent}
                focusInputOnSuggestionClick
                theme={{
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                renderSuggestionsContainer={options => (
                    <Paper {...options.containerProps} square>
                        {options.children}
                    </Paper>

                )}
            />
        );
    }
}

ToolbarAutosuggest.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ToolbarAutosuggest);
