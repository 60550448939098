/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid, IconButton, Tooltip } from '@mui/material';
import GetSvgIcon from '../../../util/svgImage_util';
import customStyles from './LogsList.module.scss';
import { downloadFile, getTimeFormatAsperUserSettting, convertDateTime } from '../../../helper-classes/utility-functions';
import config from '../../../constants/Config';

export type Props = {
    type: string,
    reportHeaderData: Object,
    jobId: string,
    showLoader: Function,
};


export type State = {
    type: string,
    reportStatus: any,
};

class ReportDetailsHeader extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = this.initState();
    }

    initState = () => ({
        type: this.props.type,
        reportStatus: [{
            text: 'Total Line Items',
            value: 'total',
            border: true,
        }, {
            text: 'Line Items Successfuly Imported',
            value: 'success',
            border: true,
        }, {
            text: 'Line Items Failed to Import',
            value: 'failed',
            border: true,
        }, {
            text: 'Line Items Cancelled to Import',
            value: 'cancelled',
            border: false,
        }],
    })

    downloadReport = () => {
        this.props.showLoader(true);
        const { reportHeaderData: { fileName }, jobId } = this.props;
        const fileUrl = `${config.get('FLEET_IMPORT_SERVICES_URL')}/importjobs/${jobId}/download?name=${fileName}`;
        downloadFile(fileUrl, fileName, { type: 'csv' }, () => this.props.showLoader(false));
    }

    downloadFailedReport = () => {
        this.props.showLoader(true);
        const { reportHeaderData: { fileName }, jobId } = this.props;
        const fileUrl = `${config.get('FLEET_IMPORT_SERVICES_URL')}/importjobs/${jobId}/logs/download`;
        downloadFile(fileUrl, `Failed-${fileName}`, { type: 'csv' }, () => this.props.showLoader(false));
    }

    render() {
        const { fileName, dateCreated } = this.props.reportHeaderData;
        const timeFormat = (getTimeFormatAsperUserSettting() === '24h') ? 'HH:mm MMM DD, YYYY' : 'hh:mm A MMM DD, YYYY';
        const formatedData = dateCreated ? `- Submitted ${convertDateTime(dateCreated, timeFormat)}` : '';

        return (
            <div className={customStyles.container}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                >
                    <Grid item xs={8} className={customStyles.title}>
                        <div>{`${this.state.type} Import ${formatedData}`}</div>
                    </Grid>
                    <Grid item xs={3} className={customStyles.fileDownloadLinkGrid}>
                        <Tooltip title={fileName || ''}>
                            <div
                                onClick={() => this.downloadReport()}
                                onKeyDown={() => {}}
                                role="link"
                                tabIndex="-1"
                            >
                                {fileName}
                            </div>
                        </Tooltip>
                        {fileName &&
                        <div
                            onClick={() => this.downloadReport()}
                            style={{ padding: '0 0 0 8px', minWidth: 22 }}
                            onKeyDown={() => {}}
                            role="link"
                            tabIndex="-1"
                        >
                            <Tooltip title="Download">
                                <span>
                                    <GetSvgIcon fillcolor="#000" type="downloadLink" />
                                </span>
                            </Tooltip>
                        </div>
                        }
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                >
                    {this.state.reportStatus.map(d => (
                        <Grid key={d.text} item xs={3} className={customStyles.backgroudColor}>
                            <div className={d.border ? customStyles.border : ''}>
                                <div className={customStyles.reportCountText}>
                                    {d.text}
                                </div>
                                <div className={customStyles.reportCount}>
                                    {this.props.reportHeaderData[d.value] || 0}
                                </div>
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={12} className={customStyles.failedItem}>
                        <div className={customStyles.failedItemText}>
                            Failed Line Items
                        </div>
                        {(this.props.reportHeaderData &&
                            this.props.reportHeaderData.failed > 0) &&
                            <div style={{ alignSelf: 'center', padding: '0 4px' }}>
                                <Tooltip title="Download">
                                    <IconButton onClick={() => this.downloadFailedReport()} size="large">
                                        <GetSvgIcon fillcolor="#FFF" type="download" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles({}, { withTheme: true })(ReportDetailsHeader);

