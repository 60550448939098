const AssetCriteriaStyles = {
    container: {
        height: 'auto',
        minHeight: 400,
        widht: '100%',
        overflowY: 'auto',
    },
    header: {
        display: 'flex',
        paddingLeft: 12,
        alignItems: 'center',
    },
    headerTitle: {
        fontSize: 22,
    },
    selectionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        paddingRight: 12,
    },
    radioList: {
        paddingLeft: 12,
    },
    chipContainer: {
        minHeight: 48,
        paddingTop: 4,
        overflowY: 'scroll',
    },
    title: { margin: 'auto 0' },
    column: {
        flex: 1,
        width: '50%',
        paddingLeft: 12,
        maxWidth: '50%',
    },
    assetsHeader: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        fontSize: 22,
        height: 40,
    },
    reset: {
        float: 'right',
        fontSize: 14,
        color: '#007CFF',
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        textTransform: 'uppercase',
    },
    selectedAssetsContainer: {
        display: 'flex',
        flex: 2,
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        paddingTop: 12,
    },
    icon: {
        marginLeft: 12,
    },
    selectedGroupContainer: {
        paddingTop: 22,
    },
    radio: {
        color: '#007CFF',
    },
    gray: {
        color: '#9E9E9E',
    },
    tableWrapper: {
        height: 200,
        overflowX: 'scroll',
    },
    resultsTable: {
        maxHeight: 200,
        overflowY: 'scroll',
    },
    selectedWrapper: {
        overflowX: 'scroll',
        height: 200,
        maxHeight: '100%',
    },
    loader: {
        float: 'right',
        transform: 'translate(-10%, 0%)',
        'text-align': 'center',
    },
    searchInput: {
        paddingTop: 0,
        paddingBottom: 0,
        width: '100%',
        position: 'relative',
    },
    customSelectContainer: {
        display: 'flex',
        width: '100%',
        maxWidth: '750px',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        paddingRight: 12,
    },
    selectInternalContainer: {
        flex: 1,
        paddingLeft: 20,
        paddingBottom: 20,
        paddingTop: 10,
    },
    totalText: {
        color: '#A9A9A9',
        fontSize: 12,
    },
    detailSection: {
        textTransform: 'capitalize',
        fontSize: 20,
        display: 'flex',
        width: '100%',
        paddingLeft: 12,
        paddingTop: 12,
        paddingBottom: 12,
    },
    detailItemSection: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        paddingLeft: 12,
        flexWrap: 'wrap',
    },
    assetContainer: {
        position: 'relative',
        left: '40%',
        top: '8%',
    },
    assetSection: {
        position: 'relative',
        top: '12%',
    },
};

export default AssetCriteriaStyles;
