/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import InputAdornment from '@mui/material/InputAdornment';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import SearchBox from '../../SharedComponents/SearchBox';

import customstyles from './SearchHeader.module.scss';

export type Props = {
    title: string,
    headerName: string,
    subHeader: string,
    toggleFilterDrawer: Function,
    handleChange: Function,
    toggleFilterSearchHeader: Function,
    filtersSelected: boolean,
    searchedString: string,
};

const styles = () => ({
    card: {
        backgroundColor: '#999b9e',
    },
});

export type State = {
    isDrawerTitle: boolean,
    autoFocus: boolean,
};

class SearchHeader extends Component<Props, State> {
    constructor(props) {
        super(props);
        let isSearch = true;
        if (this.props.searchedString && this.props.searchedString.length > 0) {
            isSearch = false;
        }
        this.state = {
            isDrawerTitle: isSearch,
            autoFocus: true,
        };
    }

    toggleFilterDrawer(show) {
        this.props.toggleFilterDrawer(show);
    }

    toggleFilterSearchHeader(isDrawerTitle = false) {
        this.props.toggleFilterSearchHeader(!isDrawerTitle);
        this.setState({
            isDrawerTitle,
        });
    }

    render() {
        const { headerName, subHeader, title = '' } = this.props;
        const { isDrawerTitle, autoFocus } = this.state;
        return (
            <div className={customstyles['search-header']}>
                <Card raised={false} className={customstyles.card}>
                    <CardContent className={customstyles['card-content-header']}>
                        {isDrawerTitle &&
                            <div>
                                <Typography id="asset-group-header" variant="h5" className={customstyles['asset-group-header']} component="h2">
                                    {headerName}
                                    <button
                                        onClick={() => this.toggleFilterDrawer(true)}
                                        onKeyDown={() => {}}
                                        id="filter-btn"
                                        className={customstyles[this.props.filtersSelected ? 'filter-icon-blue' : 'filter-icon-white']}
                                    />
                                    <button
                                        onClick={() => this.toggleFilterSearchHeader(false)}
                                        onKeyDown={() => {}}
                                        id="search-btn"
                                        className={customstyles['search-icon-white']}
                                    />
                                </Typography>
                                <Typography id="asset-group-count" className={customstyles['sub-header']} paragraph component="p" variant="h5">
                                    {subHeader}
                                </Typography>
                            </div>
                        }
                        {!isDrawerTitle &&
                            <div className={customstyles['search-box-container']}>
                                <div className={customstyles['search-box']}>
                                    <SearchBox
                                        id="search-box"
                                        autoFocus={autoFocus}
                                        placeholder={(title) ? `Search for an ${title}` : ''}
                                        className={customstyles['asset-search-input']}
                                        onChange={
                                            e =>
                                                this.props.handleChange(e.target.value)
                                        }
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i id="search-icon-start" className={customstyles['search-icon']} />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i id="cancel-search-icon" className={customstyles['cancel-search']} onClick={() => this.toggleFilterSearchHeader(true)} onKeyDown={() => {}} role="button" tabIndex="-1" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        fieldtype="TextField"
                                    />
                                </div>
                                <Typography id="asset-group-count" className={customstyles['sub-search-count']} paragraph component="p" variant="h5">
                                    {subHeader}
                                </Typography>
                            </div>
                        }
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SearchHeader);
