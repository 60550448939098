/* @flow */
import React, { Component } from 'react';
import { formatAddress, isEmpty } from '../../../helper-classes/utility-functions';
import RightDrawer from '../../SharedComponents/RightDrawer/RightDrawer';
import AssetList from '../List/AssetsList';
import NearestAssetListHeader from '../Nearest/NearestAssetListHeader';
import customStyles from '../List/AssetList.module.scss';
import MapsWrapper from '../../../containers/MapsWrapper';
import { getAddressFromCoordinates } from '../../../util/map_utils';
import { getNearestAssets } from '../../../containers/Maintenance/helper-classes/common-services';
import { RECORDS_PER_PAGE } from '../../../constants/Config';
import { getShapeData } from './../../../containers/NearestAssetLandmark/epics.nearestAssetLandmark';
import { mapColor } from './../../../containers/constants.map';

export type Props = {
    history: {
        push: Function,
        goBack: Function,
    },
    landmark: Object,
};

export type State = {
    assetData: Object,
    page: number,
    isUpdating: boolean,
    landmarkData: Object,
};

class NearestAssetsList extends Component<Props, State> {
    showHideMapAssetListRefreshChip: Function;
    constructor(props: Object) {
        super(props);
        this.state = this.getInitialState();
        this.showHideMapAssetListRefreshChip = () => { };
    }

    getInitialState = () => ({
        assetData: {
            data: [],
            totalPages: 0,
            count: 0,
        },
        page: 0,
        isUpdating: true,
        landmarkData: this.props.landmark,
    });


    componentDidMount() {
        if (!this.props.landmark.landmarkId) {
            this.getAssets();
        }
    }

    componentDidUpdate = () => {
        if (this.state.landmarkData.lat !== this.props.landmark.lat) {
            this.initializeComponent();
        }
    }

    initializeComponent = () => {
        this.setState(this.getInitialState, () => {
            this.getAssets();
            this.getAddressFromCoordinates();
        });
    }

    getAssets = () => {
        this.setState({ isUpdating: true });
        const params = {
            lat: this.props.landmark.lat,
            lng: this.props.landmark.lng,
            start: this.state.page * RECORDS_PER_PAGE,
            perPage: RECORDS_PER_PAGE,
        };

        getNearestAssets(params).then((nearestAssets: Object) => {
            const assetData = {
                data: this.state.assetData.data.concat(nearestAssets.data),
                totalPages: Math.ceil(nearestAssets.total / RECORDS_PER_PAGE),
                currentPage: this.state.page,
                total: nearestAssets.total,
            };
            this.setState({ assetData, isUpdating: false });
        });
    }

    loadNextPage = (page: number) => {
        if (!this.state.isUpdating) {
            this.setState({ page }, () => {
                this.getAssets();
            });
        }
    }

    goToNearestAssetDetail = (asset: Object) => {
        if (this.props.landmark.landmarkId) {
            this.props.history.push(`/landmarks/${this.props.landmark.landmarkId}/nearest-assets/${asset.id}`);
        } else {
            this.props.history.push(`/nearest-assets/${asset.id}?lat=${this.props.landmark.lat}&lng=${this.props.landmark.lng}`);
        }
    }

    getAddressFromCoordinates = () => {
        if (!this.props.landmark.landmarkId) {
            getAddressFromCoordinates(this.props.landmark, this.setAddress);
        }
    }

    setAddress = (data: Object) => {
        if (!isEmpty(data)) {
            const address = formatAddress(data);
            const addressLine = [address.street, address.region, address.city, address.country].filter(s => s).join(', ');
            this.setState({
                landmarkData: {
                    name: address.home,
                    address: addressLine,
                    lat: parseFloat(this.props.landmark.lat),
                    lng: parseFloat(this.props.landmark.lng),
                },
            });
        }
    }

    processMarkers = (items: Object) => {
        const filteredMarker = items.map((d) => {
            const newData = d;
            newData.latitude = d.lastLocation.y || 0;
            newData.longitude = d.lastLocation.x || 0;
            newData.lat = d.lastLocation.y || 0;
            newData.lng = d.lastLocation.x || 0;
            newData.type = 'asset';
            newData.markerType = 'asset';
            newData.redirectTo = this.props.history.push;
            return newData;
        });
        return filteredMarker;
    }

    mapRefreshDataCallback = () => this.showHideMapAssetListRefreshChip(true);

    resetListData = () => this.setState({
        assetData: {
            data: [],
            totalPages: 0,
            count: 0,
        },
        page: 0,
        isUpdating: true,
    }, () => this.getAssets());

    render() {
        const { assetData, isUpdating } = this.state;
        const assetMarkerData = assetData.data.length > 0 ?
            this.processMarkers(assetData.data) : [];
        const landmarkMarkerData = {
            ...this.props.landmark,
            latitude: parseFloat(this.props.landmark.lat),
            longitude: parseFloat(this.props.landmark.lng),
            lat: parseFloat(this.props.landmark.lat),
            lng: parseFloat(this.props.landmark.lng),
            redirectTo: this.props.history.push,
        };

        if (this.props.landmark.landmarkId) {
            landmarkMarkerData.shapeData = getShapeData(this.props.landmark);
            landmarkMarkerData.markerType = 'landmark';
            landmarkMarkerData.color = mapColor.cluster.landmarkColor;
        } else {
            landmarkMarkerData.type = 'landmark';
            landmarkMarkerData.color = mapColor.icon;
            landmarkMarkerData.backgroundShapeType = 'background_circle_image';
            landmarkMarkerData.style = { left: '7px', top: '-17px' };
            landmarkMarkerData.imageSize = 'large';
        }

        const markerData = (isUpdating && assetData.data.length < 1) ? [] : [landmarkMarkerData];
        const shape = {
            type: 'NearestAssetsGroup',
            id: 'multiTypeMarkers',
            key: 'multiTypeMarkers',
            data: [...assetMarkerData, ...markerData],
        };

        return (
            <React.Fragment>
                <MapsWrapper
                    coordinates={{
                        lat: parseFloat(this.props.landmark.lat),
                        lng: parseFloat(this.props.landmark.lng),
                    }}
                    isLoaded={this.getAddressFromCoordinates}
                    markers={{ shape }}
                    refreshData={this.mapRefreshDataCallback}
                    moduleName="nearest-asset"
                />
                <RightDrawer showDrawer>
                    <div className={customStyles.container}>
                        <NearestAssetListHeader
                            landmarkData={this.state.landmarkData}
                            {...this.props}
                        />
                        {assetData.total > 0 &&
                            <div className={customStyles.asset_count_text}>
                                Nearest Assets ({assetData.total})
                            </div>
                        }
                        <AssetList
                            assets={assetData}
                            getAssets={this.getAssets}
                            handleClick={this.goToNearestAssetDetail}
                            isUpdating={isUpdating}
                            loadNextPage={this.loadNextPage}
                            resetListData={() => this.resetListData()}
                            redirectTo={(e, url) => this.props.history.push(url)}
                            showHideMapAssetListRefreshChip={(f) => {
                                this.showHideMapAssetListRefreshChip = f;
                            }}
                        />
                    </div>
                </RightDrawer>
            </React.Fragment>
        );
    }
}

export default NearestAssetsList;
