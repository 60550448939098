import React from 'react';
import Radio from '@mui/material/Radio';
import { Chip, Divider, Table, TableBody, IconButton, Tooltip, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import withStyles from '@mui/styles/withStyles';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import AsyncSelectWrapper from '../AsyncSelectWrapper';
import LandmarkCriteriaSearchList from './LandmarkCriteriaSearchList';
import ListTableRow from '../ListComponent/ListTableRow';
import { getLandmarkGroups } from '../../../containers/Maintenance/helper-classes/common-services';
import AppLoader from '../../AppLoader';
import styles from './LandmarkCriteriaStyles';
import dropdownNested from '../../../assets/icons/dropdown-nested.svg';

type Props = {
    landmarkCriteria: { landmarks: Array<{}>, groups: Array<{}>, tags: Array<{}>, type: string },
    onLandmarkCriteriaChanged: Function,
    detailsOnlyMode: boolean,
    landmarkFilter?: any,
    hideAllLandmarksType: boolean,
};
type State = {
    selectedTab: string,
    landmarkCriteria: { landmarks: Array<{}>, groups: Array<{}>, tags: Array<{}>, type: string },
    expandedGroups: [],
    openDialog: boolean,
    landmarkLoading: boolean,
};

const AllLandmarksType = 'all-landmarks';
const GroupsTagsType = 'groups-tags';
const CustomType = 'custom';

class LandmarkCriteria extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        const criteria = props.landmarkCriteria || {};
        criteria.landmarks = criteria.landmarks || [];
        criteria.groups = criteria.groups || [];
        criteria.tags = criteria.tags || [];
        this.state = {
            landmarkCriteria: criteria,
            selectedTab: criteria.type || AllLandmarksType,
            expandedGroups: [],
            openDialog: false,
            landmarkLoading: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps:Object) {
        const criteria = nextProps.landmarkCriteria || {};
        criteria.landmarks = criteria.landmarks || [];
        criteria.groups = criteria.groups || [];
        criteria.tags = criteria.tags || [];
        this.setState({
            landmarkCriteria: criteria,
            selectedTab: criteria.type || AllLandmarksType,
            expandedGroups: [],
        });
    }

    getLandmarkCriteria = () => {
        const { landmarkCriteria, selectedTab } = this.state;
        landmarkCriteria.type = selectedTab;
        return landmarkCriteria;
    }

    onLandmarkCriteriaChanged = () => {
        const { onLandmarkCriteriaChanged } = this.props;
        if (onLandmarkCriteriaChanged) {
            onLandmarkCriteriaChanged(this.getLandmarkCriteria());
        }
    }

    labelForType = type => (type === 'groups' ? 'name' : 'tag')

    isGroupExpanded = (group) => {
        let expanded = false;
        this.state.expandedGroups.forEach((g) => {
            if (g.id === group.id) {
                expanded = true;
            }
        });
        return expanded;
    }

    expandGroup = (group, expand) => {
        let { expandedGroups } = this.state;
        if (expand) {
            expandedGroups.push(group);
        } else {
            expandedGroups = expandedGroups.filter(g => g.id !== group.id);
        }
        this.setState({ expandedGroups });
    }

    loadOptions = (type, inputValue, label) =>
        new Promise((resolve, reject) => {
            const loadCriteria = type === 'groups' ? getLandmarkGroups : [];
            loadCriteria().then((response) => {
                if (response && response.data) {
                    let optionData = response.data.map(datum => ({
                        value: datum.id,
                        label: datum[label],
                        data: datum,
                    }));
                    if (inputValue) {
                        optionData = optionData.filter(option => (
                            option.label.toLowerCase().includes(inputValue.toLowerCase())
                        ));
                    }
                    resolve(optionData);
                } else {
                    reject();
                }
            });
        });

    selectTab = (e) => {
        this.setState({
            landmarkCriteria: { landmarks: [], groups: [], tags: [] },
            selectedTab: e.id,
        }, () => {
            this.onLandmarkCriteriaChanged();
        });
    }

    renderRadio = (section, name) => {
        const checked = this.state.selectedTab === section;
        return (
            <label htmlFor={section}>
                <Radio
                    checked={checked}
                    value={name}
                    id={section}
                    style={checked ? styles.radio : styles.gray}
                />
                <span >{name}</span>
            </label>
        );
    }

    handleDelete = (section, id) => {
        const { landmarkCriteria } = this.state;
        landmarkCriteria[section] = landmarkCriteria[section].filter(item => item.id !== id);
        this.setState({ landmarkCriteria }, () => {
            this.onLandmarkCriteriaChanged();
        });
    }

    onCriteriaSelected = (type, selected) => {
        const { landmarkCriteria } = this.state;
        if (landmarkCriteria[type].filter(item => item.id === selected.data.id).length === 0) {
            landmarkCriteria[type].push(selected.data);
            this.setState({ landmarkCriteria }, () => {
                this.onLandmarkCriteriaChanged();
            });
        }
    }

    renderSelect = (type, title, disableChips) => {
        const { landmarkCriteria } = this.state;
        const typeLabel = this.labelForType(type);
        return (
            <AsyncSelectWrapper
                key={`${type}_${disableChips}`}
                handleDelete={item => this.handleDelete(type, item.data.id)}
                selectedOptions={landmarkCriteria[type].map(item => ({
                    value: item.id,
                    label: item[typeLabel],
                    data: item,
                }))}
                loadOptions={inputValue => this.loadOptions(type, inputValue, typeLabel)}
                onItemSelected={selected => this.onCriteriaSelected(type, selected)}
                title={title}
                disableChips={disableChips}
                disablePaging
                styles={disableChips ? { container: styles.selectInternalContainer } : { select: { width: '20%' } }}
                selectStyles={{
                    control: () => ({
                        display: 'flex',
                        border: 'none',
                        boxShadow: 'none !important',
                    }),
                    option: (s, { data }) => {
                        const obj = data.data || {};
                        let newStyle = { ...s };
                        if (obj.nestLevel) {
                            newStyle = {
                                ...newStyle,
                                marginLeft: 8 + (obj.nestLevel * 16),
                            };
                        }
                        if (obj.childNodes && obj.childNodes.length > 0) {
                            newStyle = {
                                ...newStyle,
                                marginLeft: (newStyle.marginLeft || 0) + 6,
                                paddingLeft: 18,
                                backgroundImage: `url(${dropdownNested})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'left center',
                            };
                        }
                        return newStyle;
                    },
                }}
                menuPlacement="bottom"
            />
        );
    }

    resetSelectedLandmarks = () => (this.getSelectedLandmarksSection() > [] ?
        this.setState({ openDialog: true }) : this.setState({ openDialog: false }));

    selectLandmarks = (landmarksToAdd) => {
        const { landmarkCriteria } = this.state;
        const existingLandmarks = landmarkCriteria.landmarks.map(landmark => landmark.landmarkId);
        landmarksToAdd.forEach((landmarkToAdd) => {
            const filter = existingLandmarks.filter(id => id === landmarkToAdd.landmarkId);
            if (filter.length === 0) {
                landmarkCriteria.landmarks.push(landmarkToAdd);
            }
        });
        setTimeout(() => {
            this.setState({ landmarkLoading: false, landmarkCriteria }, () => {
                this.onLandmarkCriteriaChanged();
            });
        }, 100);
    }

    onRemoveLandmarks = (landmarks) => {
        const { landmarkCriteria } = this.state;
        const landmarksToRemove = landmarks.map(landmark => landmark.landmarkId);
        landmarkCriteria.landmarks = landmarkCriteria.landmarks.filter(a => (
            !landmarksToRemove.includes(a.landmarkId)
        ));
        setTimeout(() => {
            this.setState({ landmarkLoading: false, landmarkCriteria }, () => {
                this.onLandmarkCriteriaChanged();
            });
        }, 100);
    }

    getChevronForGroup = (group) => {
        const expanded = this.isGroupExpanded(group);
        return (
            <Tooltip title={expanded ? 'Collapse' : 'Expand'} style={{ marginLeft: 12 }} disableFocusListener disableTouchListener interactive>
                <IconButton
                    aria-label={expanded ? 'Collapse' : 'Expand'}
                    style={styles.icon}
                    onClick={() => this.expandGroup(group, !expanded)}
                    size="large"
                >
                    {expanded ? <ExpandMore /> : <ChevronRight />}
                </IconButton>
            </Tooltip>
        );
    }

    getSelectedLandmarksSection = () => {
        const { landmarkCriteria } = this.state;
        const { landmarks: selectedLandmarks } = landmarkCriteria;
        const notInGroup = { name: 'Not in Group', id: -1 };
        const groups = [];
        const landmarkMap = {};
        if (selectedLandmarks) {
            selectedLandmarks.forEach((landmark) => {
                const group = {
                    id: landmark.landmarkGroupId || notInGroup.id,
                    name: landmark.landmarkGroupName || notInGroup.name,
                };
                if (groups.filter(g => g.id === group.id).length === 0) {
                    groups.push(group);
                }
                if (!landmarkMap[group.id]) {
                    landmarkMap[group.id] = [];
                }
                landmarkMap[group.id].push(landmark);
            });
        }
        const groupSections = groups.map(group => (
            <div style={styles.selectedGroupContainer} key={`groupSelections_${group.id}`}>
                {group.name}
                {this.getChevronForGroup(group)}
                {this.isGroupExpanded(group) ? this.getLandmarksTable(landmarkMap[group.id]) : null}
            </div>
        ));
        return groupSections;
    }

    getLandmarksTable = landmarks => (
        <Table className={styles['results-table']}>
            <TableBody>
                {landmarks.map(row => (
                    <ListTableRow
                        data={row}
                        key={Math.random()}
                        listHeaders={[
                            { name: 'Landmark', show: true },
                            { address: 'Address', show: true },
                        ]}
                        ischeckBox
                        handleClick={() => this.onRemoveLandmarks([row])}
                        handleDoubleClick={() => {}}
                        isSelected
                        index={landmarks.length || 0}
                        selectSingleRow={() => {}}
                    />
                ))}
            </TableBody>
        </Table>
    );

    renderCustomCriteriaSection = () => {
        const { landmarkCriteria } = this.state;
        const chips = [];
        ['groups'].forEach((type) => {
            this.state.landmarkCriteria[type].forEach((item) => {
                chips.push(<Chip
                    style={{ margin: 8 }}
                    key={item.id}
                    label={item[this.labelForType(type)]}
                    onDelete={() => this.handleDelete(type, item.id)}
                />);
            });
        });
        return (
            <div >
                <div style={styles.customSelectContainer}>
                    {this.renderSelect('groups', 'Filter by Groups', true)}
                </div>
                <Divider />
                {chips.length > 0 &&
                    <div style={styles.chipContainer}>
                        {chips}
                        <Divider />
                    </div>
                }
                <div style={styles.selectedLandmarksContainer}>
                    <div style={styles.column}>
                        <LandmarkCriteriaSearchList
                            searchGroups={landmarkCriteria.groups}
                            selectedLandmarks={landmarkCriteria.landmarks}
                            landmarkFilter={this.props.landmarkFilter}
                            onLandmarksSelected={(selected, landmarks) => {
                                if (selected) {
                                    this.setState({ landmarkLoading: true }, () => {
                                        this.selectLandmarks(landmarks);
                                    });
                                } else {
                                    this.setState({ landmarkLoading: true }, () => {
                                        this.onRemoveLandmarks(landmarks);
                                    });
                                }
                            }}
                        />
                    </div>
                    <div style={styles.column}>
                        <div style={styles.landmarksHeader}>
                            <span style={{ width: '100%' }}>Selected Landmarks</span>
                            <button style={styles.reset} onClick={this.resetSelectedLandmarks} >
                                RESET
                            </button>
                        </div>
                        { /* eslint-disable */}
                        {(!this.state.landmarkLoading) ?
                            <div style={this.getSelectedLandmarksSection() > [] ?
                                styles.selectedWrapper : Object.assign({}, styles.selectedWrapper, styles.landmarkSection)}>
                                {this.getSelectedLandmarksSection() > [] ?
                                    this.getSelectedLandmarksSection() :
                                    <span style={styles.landmarkContainer}>
                                        No Landmarks Data
                                </span>}
                            </div>
                            :
                            this.getLoadingIndicator()
                        }
                    </div>
                </div>
            </div>
        );
    }

    renderDetailSection = (section, showAll) => {
        let itemEls = [];
        if (showAll) {
            itemEls = 'All';
        } else {
            const items = this.props.landmarkCriteria[section];
            itemEls = !items ? [] : items.map(item => (
                <Chip
                    style={{ margin: 8 }}
                    key={`${section}_${item}`}
                    label={item}
                    deletable={false}
                />
            ));
        }
        return (
            <div>
                <div style={styles.detailSection}>
                    {section}
                </div>
                <div style={styles.detailItemSection}>
                    {itemEls}
                </div>
            </div>
        );
    }

    getLoadingIndicator = () => {
        const loaderStyle = Object.assign({}, styles.loader, { float: 'center'});
       return <AppLoader loaderStyle={loaderStyle} height={30} width={3} />
    }

    handleClose = () => {
        this.setState({ openDialog: false });
    }
    confirmClearAll = () => {
        const { landmarkCriteria } = this.state;
        this.setState({ landmarkCriteria: { landmarks: [], groups: landmarkCriteria.groups, tags: landmarkCriteria.tags } }, () => {
            this.onLandmarkCriteriaChanged();
        });
        this.setState({ openDialog: false });
    }

    render() {
        if (this.props.detailsOnlyMode) {
            const { tags, landmarks, groups } = this.props.landmarkCriteria;
            const hasTags = (tags && tags.length > 0);
            const hasLandmarks = (landmarks && landmarks.length > 0);
            const hasGroups = (groups && groups.length > 0);
            const showAll = (!hasTags && !hasLandmarks && !hasGroups);
            return (
                <div style={{ overflow: 'scroll', height: 'inherit' }}>
                    {this.renderDetailSection('groups', showAll)}
                    {this.renderDetailSection('landmarks', showAll)}
                </div>
            );
        }
        let section = null;
        if (this.state.selectedTab === 'groups-tags') {
            section = (
                <div style={{ width: '100%' }}>
                    {this.renderSelect('groups', 'Select Groups')}
                </div>
            );
        } else if (this.state.selectedTab === 'custom') {
            section = this.renderCustomCriteriaSection();
        }
        return (
            <div style={styles.container}>
                <div style={styles.header}>
                    <span style={styles.headerTitle}>Landmark Criteria</span>
                    <ul style={styles.radioList} onChange={e => this.selectTab(e.target, false)}>
                        {!this.props.hideAllLandmarksType && this.renderRadio(AllLandmarksType, 'All Landmarks')}
                        {this.renderRadio(GroupsTagsType, 'Select Groups')}
                        {this.renderRadio(CustomType, 'Custom Select')}
                    </ul>
                </div>
                {section}
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{ fontSize: '16px' }}>
                            Are you sure you want to clear all landmarks?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ p: 1.25, m: 1 }}>
                        <Button onClick={this.handleClose}>
                            No
                        </Button>
                        <Button onClick={this.confirmClearAll} color="primary" variant="contained" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

LandmarkCriteria.defaultProps = {
    landmarkCriteria: { landmarks: [], groups: [] },
    detailsOnlyMode: false,
    landmarkFilter: '',
    hideAllLandmarksType: false,
};

export default withStyles(styles)(LandmarkCriteria);
