/* @flow */

import {
    USER_DATA,
    USER_DATA_SUCCESS,
    USER_DATA_ERROR,
    SET_ACCOUNT_SUCCESS,
    SET_ACCOUNT_ERROR,
    UA_GRAILS_SIGIN_ACCOUNT,
    USER_ACCOUNT_AUTH,
    UPDATE_ACCOUNT_LOADER,
    UPDATE_ACCOUNTS_LOADER,
} from './constants.userAccount';

export const userData = () => ({
    type: USER_DATA,
});

export const userDataSucces = (response: any) => ({
    type: USER_DATA_SUCCESS,
    payload: { response },
});

export const userDataError = (error: any) => ({
    type: USER_DATA_ERROR,
    payload: { error },
});

export const authUserAccount = (id: string) => ({
    type: USER_ACCOUNT_AUTH,
    payload: { id },
});

export const selectUserAccount = (id: string) => ({
    type: USER_ACCOUNT_AUTH,
    payload: { id },
});

export const updateLoader = () => ({
    type: UPDATE_ACCOUNT_LOADER,
});

export const updateAccountLoader = () => ({
    type: UPDATE_ACCOUNTS_LOADER,
});

export const setAccountSuccess = (response: any) => ({
    type: SET_ACCOUNT_SUCCESS,
    payload: { token: response.token },
});

export const setAccountError = (error: any) => ({
    type: SET_ACCOUNT_ERROR,
    payload: { error },
    meta: {
        analytics: {
            type: SET_ACCOUNT_ERROR,
        },
    },
});

export const grailsSigninAccount = (accountUser: string, id: any) => ({
    type: UA_GRAILS_SIGIN_ACCOUNT,
    payload: { accountUser, id },
});
