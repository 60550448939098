/* @flow */
import React from 'react';
import { Tooltip } from '@mui/material';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.globalsearch';
import { recordsPerPage } from './contants.globalsearch';
import GlobalSearch from '../../components/GlobalSearch/GlobalSearch';
import UserAccount from '../UserAccount/UserAccount';
import styles from './Header.module.scss';
import SpudNotification from '../BannerNotification/SpudNotification';
import SessionValidator from '../SessionValidator';
import AppNotifications from '../../components/SharedComponents/NotificationHandler/AppNotifications';
import GetSvgIcon from '../../util/svgImage_util';
import { isEmpty, getPermissionValue } from './../../helper-classes/utility-functions';
import { fetchLocationByPlaceId } from '../../util/map_utils';
import * as notificationActions from '../../components/SharedComponents/NotificationHandler/actions.appNotifications';
import type { typeAddresses } from './types';

type Props = {
    logout: Function,
    userIdentity: Function;
    globalSearch: Function,
    updateSearchText: Function,
    updateTab: Function,
    openMenu: [],
    closeMenu: [],
    isStateOpen: boolean,
    globalSearchResults: {
        addresses: typeAddresses,
        userIdentity: {
            user: {
                firstName: string,
                lastName: string,
            },
        },
        query: string,
        page: number,
        tab: number,
    },
    history: {
        push: Function,
    },
    fetchNearestAssetLandmark: Function,
    assetOrLandmarkId: string,
    notificationObj: Object,
    appNotificationHandler: Function,
    clearNotification: Function,
    startJobStatusPolling: Function,
    unsubscribePollingJobs: Function,
};

type State = {
    showPopup: boolean,
    searchData: Object,
    searchResults: Object,
}
const snackbarNotificationObj = {
    type: 'snackbar',
    isOpen: false,
    overrideConfig: {
        key: 'header-notification',
        autoHideDuration: 3000,
        action: undefined,
    },
};
class Header extends React.Component<Props, State> {
    searchRes: Object;
    nearestType: string;
    isNotificationPanelOpen: boolean;

    static defaultProps = {
        logout: () => {},
        globalSearch: () => {},
        globalSearchResults: {
            addresses: {
                data: [],
                loader: false,
                category: '',
            },
            query: '',
            page: 0,
            tab: 0,
        },
        userIdentity: {
            user: {
                firstName: '',
                lastName: '',
                email: '',
            },
        },
        assetOrLandmarkId: '',
    };

    constructor(props: Props) {
        super(props);
        const { query, tab, page } = this.props.globalSearchResults;
        const searchData = { query, tab, page };
        this.state = {
            showPopup: false,
            searchData,
            searchResults: {},
        };
        this.searchRes = {};
        this.nearestType = '';
        this.isNotificationPanelOpen = false;
    }

    componentDidMount() {
        this.searchRes.canLoadMore = false;
        this.checkTabAndUpdate();
        document.addEventListener('click', (e: Event) => this.closePopup(e));
        this.props.userIdentity();
        this.props.startJobStatusPolling(this.props.history);
        this.props.appNotificationHandler([{
            ...snackbarNotificationObj,
            ...{
                nConfig: {
                    style: { backgroundColor: 'rgb(49, 49, 49)', color: '#fff' },
                    onCloseNotification: this.closeSnackBarNotification,
                },
            },
        }]);
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        const res = nextProps.globalSearchResults;
        if (res.query !== this.searchRes.query) {
            this.searchRes = {
                addresses: {
                    data: [],
                    loader: false,
                    category: '',
                    total: 0,
                    page: 0,
                    isloading: false,
                },
                assets: {
                    data: [],
                    loader: false,
                    category: '',
                    total: 0,
                    page: 0,
                    isloading: false,
                },
                landmarks: {
                    data: [],
                    loader: false,
                    category: '',
                    total: 0,
                    page: 0,
                    isloading: false,
                },
                drivers: {
                    data: [],
                    loader: false,
                    total: 0,
                    category: '',
                    page: 0,
                    isloading: false,
                },
                canLoadMore: false,
                query: '',
                page: 0,
                tab: 0,
            };
        }

        if (res.tab === 0 && this.searchRes.assets && this.searchRes.assets.data.length > 0) {
            if (this.searchRes.assets.data.length < this.searchRes.assets.total &&
                this.searchRes.tab === res.tab && this.searchRes.canLoadMore &&
                this.searchRes.assets.data.filter(item => res.assets.data[0] &&
                    item.id === res.assets.data[0].id).length === 0) {
                this.searchRes.assets.data = this.searchRes.assets.data.concat(res.assets.data);
                this.searchRes.assets.isLoading = res.assets.isLoading;
                this.searchRes.assets.page =
                    Math.ceil(this.searchRes.assets.data.length / recordsPerPage) - 1;
            }
            this.searchRes.tab = res.tab;
        } else if (res.tab === 1 &&
            this.searchRes.landmarks.data.length > 0) {
            if (this.searchRes.landmarks.data.length < this.searchRes.landmarks.total &&
                res.tab === this.searchRes.tab && this.searchRes.canLoadMore &&
                this.searchRes.landmarks.data.filter(item => res.landmarks.data[0] &&
                    item.id === res.landmarks.data[0].id).length === 0) {
                this.searchRes.landmarks.data = this.searchRes.landmarks.data
                    .concat(res.landmarks.data);
                this.searchRes.landmarks.isLoading = res.landmarks.isLoading;
                this.searchRes.landmarks.page =
                    Math.ceil(this.searchRes.landmarks.data.length / recordsPerPage) - 1;
            }
            this.searchRes.tab = res.tab;
        } else if (res.tab === 2 && this.searchRes.drivers.data.length > 0) {
            if (this.searchRes.drivers.data.length < this.searchRes.drivers.total &&
                res.tab === this.searchRes.tab && this.searchRes.canLoadMore &&
                this.searchRes.drivers.data.filter(item => res.drivers.data[0] &&
                    item.id === res.drivers.data[0].id).length === 0) {
                this.searchRes.drivers.data = this.searchRes.drivers.data.concat(res.drivers.data);
                this.searchRes.drivers.isLoading = res.drivers.isLoading;
                this.searchRes.tab = res.tab;
                this.searchRes.drivers.page =
                    Math.ceil(this.searchRes.drivers.data.length / recordsPerPage) - 1;
            }
            this.searchRes.tab = res.tab;
        } else if (res.tab === 3 && this.searchRes.addresses.data.length > 0) {
            if (this.searchRes.addresses.data.length < this.searchRes.addresses.total &&
                res.tab === this.searchRes.tab && this.searchRes.canLoadMore &&
                this.searchRes.addresses.data.filter(item => res.addresses.data[0] &&
                    item.id === res.addresses.data[0].id).length === 0) {
                this.searchRes.addresses.data = this.searchRes.addresses.data
                    .concat(res.addresses.data);
                this.searchRes.addresses.isLoading = res.addresses.isLoading;
                this.searchRes.tab = res.tab;
                this.searchRes.addresses.page =
                    Math.ceil(this.searchRes.addresses.data.length / recordsPerPage) - 1;
            }
            this.searchRes.tab = res.tab;
        } else {
            this.searchRes = res;
        }

        if (nextProps.assetOrLandmarkId && this.nearestType) {
            if (this.nearestType === 'landmarks') {
                this.nearestType = '';
                if (nextProps.assetOrLandmarkId && nextProps.assetOrLandmarkId !== 'NA') {
                    this.props.history.push(`/home?landmarkId=${nextProps.assetOrLandmarkId}`);
                } else {
                    this.showSnackBar('No nearest landmarks found');
                }
            }
        }
        this.setState({ searchResults: this.searchRes });
    }

    checkTabAndUpdate = () => {
        let tab = 3;
        if (getPermissionValue('Assets') !== 'None') {
            tab = 0;
        } else if (getPermissionValue('Landmarks') !== 'None') {
            tab = 1;
        } else if (getPermissionValue('Drivers') !== 'None') {
            tab = 2;
        }
        this.props.updateTab(tab);
    }

    showSnackBar = (message) => {
        this.props.appNotificationHandler([{
            ...snackbarNotificationObj,
            ...{
                isOpen: true,
                message,
            },
        }]);
    }

    /**
     * closeSnackBarNotification internally calls props.appNotificationHandler
     * to auto close snack bar notification
     * */
    closeSnackBarNotification = (e, reason) => {
        if (reason !== 'clickaway') {
            this.props.appNotificationHandler([{
                ...snackbarNotificationObj,
            }]);
        }
    }

    closePopup = (e: any) => {
        const userPopup = document.getElementById('user-popup');
        const userAccountButton = document.getElementById('account_settings');
        if (this.state.showPopup &&
            userPopup && !userPopup.contains(e.target) &&
            userAccountButton &&
            !userAccountButton.contains(e.target)) {
            this.setState({ showPopup: false });
        }
    }

    logoutRequest = () => this.props.logout();

    openNotificationPanel = () => {
        this.isNotificationPanelOpen = !this.isNotificationPanelOpen;
        this.props.appNotificationHandler([{
            type: 'nCard',
            isOpen: this.isNotificationPanelOpen,
        }]);
    }

    getUserData = () => this.setState({
        showPopup: !this.state.showPopup,
    });

    handleGlobalSearch = (query: string, page: number) => {
        this.searchRes.canLoadMore = true;
        this.props.globalSearch(query, page);
    }

    updateSearchText = (query: string) => this.props.updateSearchText(query);

    updateTab = (tab: number) => this.props.updateTab(tab);

    redirectToNearest = (type, placeId) => {
        this.nearestType = type;
        if (type === 'assets') {
            fetchLocationByPlaceId(placeId, (result) => {
                if (!isEmpty(result)) this.props.history.push(`/nearest-assets?lat=${result.geometry.location.lat()}&lng=${result.geometry.location.lng()}`);
            });
        } else {
            this.props.fetchNearestAssetLandmark(this.nearestType, placeId);
        }
    }

    clearNotifications = () => this.props.clearNotification();

    unsubscribePollingJobs = () => this.props.unsubscribePollingJobs();

    clickAwayNotification = (e) => {
        if (e.target === document.querySelector('#app-notifications > svg > path')
        || e.target === document.querySelector('#app-notifications > svg')
        || e.target === document.querySelector('#app-notifications')) {
            return false;
        }

        this.isNotificationPanelOpen = false;
        this.props.appNotificationHandler([{
            type: 'nCard',
            isOpen: this.isNotificationPanelOpen,
        }]);
        return this.isNotificationPanelOpen;
    }

    render() {
        const { globalSearchResults } = this.props;
        const i = this.props.notificationObj.findIndex(nItem => (nItem.type === 'nCard') && nItem);
        let notificationCount = 0;
        if (i > -1) notificationCount = this.props.notificationObj[i].count;
        if (notificationCount > 9) notificationCount = '9+';

        return (
            <div>
                <GlobalSearch
                    globalSearch={this.handleGlobalSearch}
                    updateSearchText={this.updateSearchText}
                    searchData={this.state.searchResults}
                    openMenu={this.props.openMenu}
                    closeMenu={this.props.closeMenu}
                    isStateOpen={this.props.isStateOpen}
                    redirectTo={this.props.history.push}
                    selectedTab={this.updateTab}
                    showResult={this.state.searchData}
                    redirectToNearest={this.redirectToNearest}
                />
                <div className={styles.header_btn_container}>
                    <Tooltip title="Open notifications" disableFocusListener>
                        <button
                            id="app-notifications"
                            onClick={() => this.openNotificationPanel()}
                            className={styles.app_notifications}
                        >
                            <GetSvgIcon type="notifications" fillcolor="#fff" />
                            {(notificationCount !== 0) &&
                            <React.Fragment>
                                <svg className={styles.notification_icon_circle}>
                                    <circle cx={5} cy={5} r={5} fill="#DF4344" />
                                </svg>
                                <span className={styles.notification_icon_circle_txt}>
                                    {notificationCount}
                                </span>
                            </React.Fragment>
                            }
                        </button>
                    </Tooltip>
                    <Tooltip
                        title={globalSearchResults.userIdentity.user &&
                        `${globalSearchResults.userIdentity.user.firstName}
                        ${globalSearchResults.userIdentity.user.lastName}`}
                    >
                        <button
                            id="account_settings"
                            onClick={() => this.getUserData()}
                            className={styles.account_settings}
                        >
                            <i className={styles['user-icon']} />
                            <span>
                                {globalSearchResults.userIdentity.user &&
                            `${globalSearchResults.userIdentity.user.firstName} ${globalSearchResults.userIdentity.user.lastName}`}
                            </span>
                        </button>
                    </Tooltip>
                </div>
                {this.state.showPopup &&
                <UserAccount
                    logout={this.logoutRequest}
                    userIdentity={this.props.globalSearchResults.userIdentity}
                    clearNotifications={this.clearNotifications}
                    unsubscribePollingJobs={this.unsubscribePollingJobs}
                />
                }
                <SessionValidator horizontal="left" vertical="bottom" />
                <SpudNotification />
                <AppNotifications clickAwayNotification={this.clickAwayNotification} />
            </div>
        );
    }
}

/**
 * Since there is a reference issue, so doing the destructing.
 * @param state
 * @returns {{globalSearchResults: {}}}
 */
const mapStateToProps = state => ({
    globalSearchResults: state.globalSearch,
    assetOrLandmarkId: state.globalSearch.assetOrLandmarkId,
    notificationObj: state.appNotifications.AppNotificationHandler,
});

const mapDispatchToProps = {
    ...actions,
    ...notificationActions,
};

export default reduxConnect(Header, mapDispatchToProps, mapStateToProps);
