/* @flow */
import {
    FETCH_USER,
    FETCH_USER_SUCCESS,
    FETCH_USER_ERROR,
    FETCH_USERROLES,
    FETCH_USERROLES_SUCCESS,
    FETCH_USERROLES_ERROR,
    FETCH_USERACCESS,
    FETCH_USERACCESS_SUCCESS,
    FETCH_USERACCESS_ERROR,
    ADD_EDIT_USER_SUCCESS,
    ADD_USER_ERROR,
    EDIT_USER_ERROR,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    UPDATE_USER_LOADER,
    CLOSE_USER_DIALOG,
    SEND_INVITATION_SUCCESS,
    SEND_INVITATION_ERROR,
} from './constants.user';

const initialState = {
    user: {},
    userRoles: {},
    userAccess: {},
    isUpdating: false,
    isError: false,
    success: false,
    successMsg: '',
    error: {},
};

type stateType = {
    user: Object,
    userRoles: Object,
    userAccess: Object,
    isUpdating: boolean,
    isError: boolean,
    success: boolean,
    successMsg: string,
}
type actionType = {
    type: string,
    payload :{
        user:{},
        userRoles:{},
        userAccess: {},
        loading: boolean,
        error: Object,
        successMsg: string,
    },
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_USER:
        return {
            ...state,
            user: {},
            isError: false,
            isUpdating: true,
            loading: true,
        };
    case FETCH_USERROLES:
    case FETCH_USERACCESS:
        return {
            ...state,
            isError: false,
            isUpdating: true,
        };
    case FETCH_USER_SUCCESS:
        return {
            ...state,
            loading: false,
            isError: false,
            user: action.payload.user,
        };
    case FETCH_USER_ERROR:
        return {
            ...state,
            loading: false,
            isError: true,
            user: {},
        };
    case FETCH_USERROLES_ERROR:
        return {
            ...state,
            userRoles: [],
            loading: false,
            isUpdating: false,
            isError: true,
        };
    case FETCH_USERACCESS_ERROR:
        return {
            ...state,
            userAccess: [],
            loading: false,
            isUpdating: false,
            isError: true,
        };
    case FETCH_USERROLES_SUCCESS:
        return {
            ...state,
            isError: false,
            isUpdating: false,
            userRoles: action.payload.userRoles,
        };
    case FETCH_USERACCESS_SUCCESS:
        return {
            ...state,
            isError: false,
            isUpdating: false,
            userAccess: action.payload.userAccess,
        };
    case ADD_USER_ERROR:
    case EDIT_USER_ERROR:
    case DELETE_USER_ERROR:
        return {
            ...state,
            loading: false,
            isUpdating: false,
            isError: true,
            error: action.payload.error,
            success: false,
            successMsg: '',
        };
    case ADD_EDIT_USER_SUCCESS:
    case DELETE_USER_SUCCESS:
        return {
            ...state,
            isError: false,
            loading: false,
            isUpdating: false,
            success: true,
            successMsg: action.payload.successMsg,
        };
    case UPDATE_USER_LOADER:
        return {
            ...state,
            loading: action.payload.loading,
        };
    case CLOSE_USER_DIALOG:
        return {
            ...state,
            loading: false,
            success: false,
            isError: false,
            error: '',
            successMsg: '',
        };
    case SEND_INVITATION_SUCCESS:
        return {
            ...state,
            loading: false,
            isError: false,
        };
    case SEND_INVITATION_ERROR:
        return {
            ...state,
            loading: false,
            isError: true,
            success: false,
            error: { message: 'Failed to send invitation' },
        };
    default:
        return state;
    }
};
