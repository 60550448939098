/* @flow */
import { FETCH_LANDMARKGROUPS_LIST_ERROR, FETCH_LANDMARKGROUPS_LIST_SUCCESS, FETCH_LANDMARKGROUPS_LIST, RESET_LANDMARKGROUPS_LIST } from './constants.landmarkGroupsList';

const initialState = {
    landmarkGroups: [],
    isUpdating: false,
};

type stateType = {
    landmarkGroups: Array<Object>,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        landmarkGroups:{},
        totalLandmarkGroups: number
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_LANDMARKGROUPS_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case RESET_LANDMARKGROUPS_LIST:
        return {
            ...state,
            isUpdating: false,
            landmarkGroups: [],
            totalLandmarkGroups: 0,
        };
    case FETCH_LANDMARKGROUPS_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            landmarkGroups: action.payload.landmarkGroups,
            totalLandmarkGroups: action.payload.totalLandmarkGroups,
        };
    case FETCH_LANDMARKGROUPS_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    default:
        return state;
    }
};
