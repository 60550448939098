/* @flow */
import React from 'react';
import { Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { getTimeInMinutes } from '../../../util/dashcam_util';
import customStyles from './SeekBar.module.scss';
import { convertDateTime } from '../../../helper-classes/utility-functions';

const sliderConfig = {
    min: 0, max: 1440, step: 1, width: 526,
};
const marginModifier = sliderConfig.width / sliderConfig.max;

export type Props = {
    selectedCameraId: number | typeof undefined,
    intervals: Array<Object>,
    timeSelected: {
        start: number,
        startTimeStamp: number,
        timeToDisplay: string,
    },
    handleSliderMovement: Function,
    checkRecordingAvailibity: Function,
};

const SeekBar = (props: Props) => {
    let isRecordingAvailable = false;

    const handleSliderMovement = (e: Object, selectedStartTime: number) => {
        isRecordingAvailable = false;
        props.handleSliderMovement(e, selectedStartTime);
    };

    return (
        <Typography component="div" key={props.selectedCameraId} className={customStyles.recording_slots_container}>
            <div className={customStyles.slots_container} style={{ width: sliderConfig.width }}>
                {props.intervals.map((i) => {
                    const start = getTimeInMinutes(convertDateTime(i.start, 'H:mm'));
                    const end = getTimeInMinutes(convertDateTime(i.end, 'H:mm'));

                    if (!isRecordingAvailable && (props.timeSelected.start >= start)
                    && (props.timeSelected.start <= end)) {
                        isRecordingAvailable = true;
                    }

                    return (<hr
                        className={customStyles.slots}
                        key={i.start}
                        style={{
                            left: (marginModifier * start),
                            width: (marginModifier * (end - start)),
                        }}
                    />);
                })}
                {props.checkRecordingAvailibity(isRecordingAvailable)}
                <span
                    className={customStyles.thumb_tracker_line}
                    style={{ left: (marginModifier * props.timeSelected.start) }}
                >
                    <span>{props.timeSelected.timeToDisplay}</span>
                </span>
            </div>
            <Slider
                id="recording_slots_slider"
                classes={{
                    root: customStyles.sliderBaseContainer,
                    trackBefore: customStyles.trackBeforeDiv,
                    thumbWrapper: customStyles.thumbContainerDiv,
                    thumb: customStyles.thumbBtn,
                    activated: customStyles.activatedThumbBtn,
                    trackAfter: customStyles.trackAfterDiv,
                }}
                min={sliderConfig.min}
                max={sliderConfig.max - 1}
                value={props.timeSelected.start}
                step={sliderConfig.step}
                onChange={(e, val) => handleSliderMovement(e, val)}
            />
        </Typography>
    );
};

export default SeekBar;
