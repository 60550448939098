/* @flow */
import React, { Component } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { VisibilityOff } from '@mui/icons-material';
import Popper from '@mui/material/Popper';
import { Checkbox, Grow, ListItemIcon, MenuList } from '@mui/material';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export type Props = {
    classes: any,
    openColumn: boolean,
    listHeaders: any,
    showHideColumn: Function,
    iconColor: string,
}

export type State = {
    openColumn: boolean,
}

class ShowHideColumnComponent extends Component <Props, State> {
    anchorElColumn: any;
    static defaultProps = {
        iconColor: '',
    };
    constructor(props: Props) {
        super(props);
        this.anchorElColumn = null;
        this.state = {
            openColumn: this.props.openColumn,
        };
    }

    handleToggleColumn = (event: any) => {
        this.anchorElColumn = event.currentTarget;
        this.setState(state => ({ openColumn: !state.openColumn }));
    };

    handleCloseColumn = (event: any) => {
        if (this.anchorElColumn.contains(event.target)) {
            return;
        }
        this.setState({ openColumn: false });
    };

    handleMenuClick = (header: any) => {
        if (this.props.showHideColumn && typeof this.props.showHideColumn === 'function') {
            this.props.showHideColumn(header);
        }
    }

    getMenuItem = () => {
        const { listHeaders, classes } = this.props;
        if (!listHeaders) return '';
        let disableId = '';
        const selectedHeader = listHeaders.filter(d => d.show);
        if (selectedHeader && selectedHeader.length === 1) {
            const [disableHeader] = Object.keys(selectedHeader[0]);
            disableId = disableHeader;
        }
        return (
            <MenuList sx={{ maxHeight: 'unset' }}>
                {listHeaders.map((header) => {
                    const keys = Object.keys(header);
                    let value = '';
                    if (Object.prototype.hasOwnProperty.call(header, 'showInDropDown') && !header.showInDropDown) {
                        return null;
                    }
                    if (typeof header[keys[0]] === 'string') {
                        value = header[keys[0]];
                    } else {
                        value = header[keys[1]];
                    }
                    return (
                        <MenuItem
                            key={keys[0]}
                            className={classes.menuItem}
                            onClick={() => {
                                this.handleMenuClick(header);
                            }}
                            disabled={disableId === keys[0]}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    sx={{ padding: '0px 12px' }}
                                    disableRipple
                                    checked={header.show}
                                    color="primary"
                                />
                            </ListItemIcon>
                            <Typography variant="inherit">
                                {value}
                            </Typography>
                        </MenuItem>
                    );
                })}
            </MenuList>
        );
    };

    render() {
        const { classes } = this.props;
        const { openColumn } = this.state;
        return (
            <React.Fragment>
                <div className={classes.actions} id="header-dropDown">
                    <Tooltip title="Show or Hide Columns" disableFocusListener>
                        <IconButton
                            aria-label="Show or Hide Columns"
                            aria-owns={openColumn ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleToggleColumn}
                            size="large"
                        >
                            <VisibilityOff style={{ color: this.props.iconColor }} />
                        </IconButton>
                    </Tooltip>
                </div>
                <Popper
                    open={openColumn}
                    anchorEl={this.anchorElColumn}
                    transition
                    disablePortal
                    style={{ zIndex: 1 }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list-grow"
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper style={{ maxHeight: 300, overflow: 'auto' }}>
                                <ClickAwayListener
                                    onClickAway={(e) => {
                                        this.handleCloseColumn(e);
                                    }}
                                    disableReactTree
                                >
                                    <div>{this.getMenuItem()}</div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </React.Fragment>
        );
    }
}

export default ShowHideColumnComponent;
