/* @flow */
import React from 'react';
import customStyles from './MapCluster.module.scss';
import assetClusterIcon from '../../assets/icons/cluster/asset_cluster.svg';
import landmarkClusterIcon from '../../assets/icons/cluster/landmark_cluster.svg';

export type Props = {
    clusterData: Object,
    type: string,
};

let position = 0;
let elementArray = [];
const statusColors = {
    moving: '#7ed321',
    idle: '#f5a623',
    stopped: '#fc0207',
};

function pushClusterRotation(status: string, statusCount: number, totalAssets: number) {
    const rotation = (statusCount / totalAssets) * 360;
    if (rotation > 180) {
        elementArray.push({ rotation: 180, position, color: statusColors[status] });
        elementArray.push({
            rotation: rotation - 180,
            position: position + 180,
            color: statusColors[status],
        });
    } else elementArray.push({ rotation, position, color: statusColors[status] });
    position += rotation;
}

function getClusterHtmlContent(assetCluster) {
    position = 0;
    elementArray = [];
    const {
        count, movingCount, idleCount, stoppedCount,
    } = assetCluster;

    if (movingCount) pushClusterRotation('moving', movingCount, count);
    if (idleCount) pushClusterRotation('idle', idleCount, count);
    if (stoppedCount) pushClusterRotation('stopped', stoppedCount, count);

    return (
        <div className={customStyles.pieContainer}>
            {elementArray.map(element => (
                <div
                    key={element.position}
                    className={`${customStyles.hold}`}
                    style={{ transform: `rotate(${element.position}deg)` }}
                >
                    <div
                        className={`${customStyles.pie}`}
                        style={{ transform: `rotate(${element.rotation}deg)`, backgroundColor: `${element.color}` }}
                    />
                </div>
            ))}
            <div style={{ position: 'absolute', padding: '3px 0 0 3px' }}>
                <img src={assetClusterIcon} alt="asset_cluster_image" />
                <div className={customStyles.content}>
                    {count}
                </div>
            </div>
        </div>
    );
}

const MapCluster = (props: Props) => (
    props.type === 'assetCluster' ?
        getClusterHtmlContent(props.clusterData || {})
        :
        <div className={customStyles.pieContainer}>
            <div style={{ position: 'absolute', padding: '4px 0 0 4px' }}>
                <img src={landmarkClusterIcon} alt="landmark_cluster_image" />
                <div className={customStyles.landmark_content}>
                    {props.clusterData.count}
                </div>
            </div>
        </div>
);
export default (MapCluster);
