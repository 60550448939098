/* @flow... */
import React from 'react';

import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import ExternalSite from '../../components/ExternalSite/ExternalSite';
import { getUserToken, getComplianceBasedURL } from '../../helper-classes/utility-functions';

export type Props = {
    history: {
        push: Function,
    },
    iframeURL: string,
};

const Compliance = (props: Props) => {
    const token = getUserToken();
    const { iframeURL } = props;
    return (
        <MiniDrawer
            redirectTo={props.history.push}
        >
            <ExternalSite url={`${getComplianceBasedURL()}/spireon/${iframeURL}?token=${token}`} />
        </MiniDrawer>
    );
};

export default Compliance;
