/* flow */
import React, { Component } from 'react';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.address';
import MapsWrapper from '../MapsWrapper';
import styles from './Address.module.scss';
import AddressDetails from '../../components/AddressDetails/AddressDetails';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import AppLoader from '../../components/AppLoader';
import { getData, getParamValue } from '../../helper-classes/utility-functions';
import RightDrawer from '../../components/SharedComponents/RightDrawer/RightDrawer';
import * as notificationActions from '../../components/SharedComponents/NotificationHandler/actions.appNotifications';
import config from '../../constants/Config';
import { processLandmarkMarkers } from '../Landmark/LandmarkDetails/epics.landmarks';


const snackbarNotificationObj = {
    type: 'snackbar',
    isOpen: false,
    overrideConfig: {
        key: 'header-notification',
        autoHideDuration: 3000,
        action: undefined,
    },
};

export type Props = {
    fetchAddress: Function,
    appNotificationHandler: Function,
    address: {
        address : string,
        latitude: number,
        longitude: number,
        landmarkName: string,
        isPresent : boolean,
        landmarkId: string,
        subAddress: string,
        isLoader: boolean,
    },
    updateLoader: Function,
    history: {
        push: Function,
    },
    assetId: string,
};

export type State = {
    landmarks: any,
    hideLandmarks: boolean
};

class Address extends Component<Props, State> {
    height: number;
    placeId: string;
    isNearestAsset: boolean;
    latitude: number;
    longitude: number;

    constructor(props) {
        super(props);
        this.state = {
            landmarks: [],
            hideLandmarks: false,
        };
    }

    componentDidMount() {
        this.props.updateLoader(true);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.assetId && this.isNearestAsset) {
            this.isNearestAsset = false;
            this.props.history.push(`/home?assetId=${nextProps.assetId}`);
        } else if (!nextProps.assetId && this.isNearestAsset && !nextProps.address.isLoader) {
            this.props.appNotificationHandler([{
                ...snackbarNotificationObj,
                ...{
                    isOpen: true,
                    message: 'No nearest assets found',
                },
            }]);
        }
    }

    componentDidUpdate() {
        const placeId = getParamValue(this.props, 'placeId');
        if (this.placeId && placeId !== this.placeId) {
            this.props.fetchAddress(placeId);
            this.placeId = placeId;
        }

        const { address } = this.props.address;
        const lat = address && address.latitude ? Number(address.latitude) : 0;
        const lng = address && address.longitude ? Number(address.longitude) : 0;

        if (this.latitude !== lat || this.longitude !== lng) {
            this.getLandmarks(lat, lng);
            this.latitude = lat;
            this.longitude = lng;
        }
    }

    isLoaded = () => {
        this.props.fetchAddress(getParamValue(this.props, 'placeId'));
        this.placeId = getParamValue(this.props, 'placeId');
    }

    /**
     * Setting ative tab
     */
    redirectTo = action => this.props.history.push(action);

    nearestAsset = () => {
        const { address } = this.props.address;
        if (address && address.latitude && address.longitude) {
            this.isNearestAsset = true;
            this.props.history.push(`/nearest-assets?lat=${address.latitude}&lng=${address.longitude}`);
        }
    }


    getLandmarks = (lat, lng) => {
        if (lat && lng) {
            getData(`${config.get('FLEET_VIEW_SERVICES_URL')}/landmarks/nearest?cb=${new Date().getTime()}&lat=${lat}&lng=${lng}&limit=200`)
                .then((response:any) => {
                    if (response.data) {
                        this.setState({
                            landmarks: processLandmarkMarkers(
                                response.data,
                                this.props.history.push,
                            ),
                        });
                    }
                })
                .catch((err) => {
                // eslint-disable-next-line no-console
                    console.error(err);
                });
        }
    };

    showHideLandmarks = (isHideLandmark) => {
        this.setState({ hideLandmarks: isHideLandmark });
    };

    render() {
        const { address, isLoader } = this.props.address;
        const lat = address && address.latitude ? Number(address.latitude) : 0;
        const lng = address && address.longitude ? Number(address.longitude) : 0;

        const markerOptions = {
            shape: {
                type: 'marker',
                lat,
                lng,
                attributes: {},
            },
            markerType: 'landmark',
            lat,
            lng,
            resizeIcon: false,
            landmarks: (!this.state.hideLandmarks && this.state.landmarks)
                ? this.state.landmarks : [],
        };
        return (
            <MiniDrawer redirectTo={this.props.history.push}>
                {isLoader ? <AppLoader type="fullScreen" /> : ''}
                <div key="address-map-container">
                    <MapsWrapper
                        key="address-map"
                        coordinates={{ lat, lng }}
                        isLoaded={this.isLoaded}
                        markers={markerOptions}
                        hideLandmark={hideLM => this.showHideLandmarks(hideLM)}
                        zoom={15}
                    />
                </div>
                <div className={styles.container}>
                    <RightDrawer showDrawer isAddress>
                        <AddressDetails
                            addressData={address}
                            redirectTo={this.redirectTo}
                            nearestAsset={this.nearestAsset}
                            isLoader={isLoader}
                        />
                    </RightDrawer>
                </div>
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => ({
    address: state.address,
    assetId: state.address.assetId,
    isLoader: state.address.isLoader,
});

const mapDispathToProps = {
    ...actions,
    ...notificationActions,
};

export default reduxConnect(Address, mapDispathToProps, mapStateToProps);
