export const FETCH_MAINTENANCE_DIAGNOSTICS_LIST: string = 'FETCH_MAINTENANCE_DIAGNOSTICS_LIST';
export const FETCH_MAINTENANCE_DIAGNOSTICS_LIST_SUCCESS: string = 'FETCH_MAINTENANCE_DIAGNOSTICS_LIST_SUCCESS';
export const FETCH_MAINTENANCE_DIAGNOSTICS_LIST_ERROR: string = 'FETCH_MAINTENANCE_DIAGNOSTICS_LIST_ERROR';
export const RESET_MAINTENANCE_DIAGNOSTICS_LIST: string = 'RESET_MAINTENANCE_DIAGNOSTICS_LIST';
export const DIAGNOSTICS_LIST_PREF_KEY: string = 'fleetLoate.diagnosticList';
export const DIAGNOSTICS_LIST_ASSET_EDIT_PREF_KEY: string = 'fleetLocate.diagnosticListAssetEdit';
export const NO_DATA_FOUND: string = 'No Data Found';
export type DiagnosticListTypeAction = {
    type: string;
    payload ? : Object;
};

export const ACTIVE: string = 'Active';
export const CLEARED: string = 'Cleared';
export const ALL: string = 'All';
