/* flow */
import React, { Component } from 'react';
import { Grid, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import customstyles from './LookLandingPage.module.scss';
import LookType from './LookType';
import backArrow from '../../../assets/icons/back-arrow-black.svg';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

type Props = {
    data: {
        lookCategory: String,
        lookCategoryID: String,
        lookTypes: Object,
    },
    backAction: Function,
};

class LookCategory extends Component<Props> {
    state = {};
    render() {
        return (
            <div>
                <div id={this.props.data.lookCategoryID} className={customstyles['look-catname']}>
                    <span className={customstyles['back-button']}>
                        <IconButton onClick={this.props.backAction} size="large">
                            <img src={backArrow} alt="backArrow" />
                        </IconButton>
                    </span>
                    Select Module Type
                </div>
                <Grid container spacing={3}>
                    { this.props.data.lookTypes.map(el => <LookType data={el} key={el.code} />) }
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(LookCategory);
