/* @flow */
import { FETCH_RECORDS_LIST_ERROR, FETCH_RECORDS_LIST_SUCCESS, FETCH_RECORDS_LIST, RESET_RECORDS_LIST } from './constants.recordList';

const initialState = {
    records: [],
    isUpdating: false,
};

type stateType = {
    records: Array<Object>,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        records:{},
        totalRecords: number
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_RECORDS_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case RESET_RECORDS_LIST:
        return {
            ...state,
            isUpdating: false,
            records: [],
            totalRecords: 0,
        };
    case FETCH_RECORDS_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            records: action.payload.records,
            totalRecords: action.payload.totalRecords,
        };
    case FETCH_RECORDS_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    default:
        return state;
    }
};
