/* @flow */

import { FETCH_MAINTENANCE_ALERTS_LIST, FETCH_MAINTENANCE_ALERTS_LIST_SUCCESS, FETCH_MAINTENANCE_ALERTS_LIST_ERROR, RESET_MAINTENANCE_ALERTS_LIST } from './constants.alertList';
import type { AlertListTypeAction } from './constants.alertList';

export const fetchAlertList = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filtersToSend: Array<{}>,
): AlertListTypeAction => ({
    type: FETCH_MAINTENANCE_ALERTS_LIST,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
        filtersToSend,
    },
});

export const fetchAlertListSuccess =
    (alerts: string, totalAlerts: number): AlertListTypeAction => ({
        type: FETCH_MAINTENANCE_ALERTS_LIST_SUCCESS,
        payload: { alerts, totalAlerts },
    });

export const resetTable =
    (): AlertListTypeAction => ({
        type: RESET_MAINTENANCE_ALERTS_LIST,
    });

export const fetchAlertListError = (error: string): AlertListTypeAction => ({
    type: FETCH_MAINTENANCE_ALERTS_LIST_ERROR,
    payload: { error },
});
