/* @flow */
// eslint-disable-next-line no-unused-vars
import { of as rxOf } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { combineEpics } from 'redux-observable';
import { SEND_COMMAND, MAX_NUMBER_OF_CALLS, CALL_INTERVAL_IN_MS, FETCH_ASSET_ALLOWED_CMDS } from './constants.sendCommand';
import * as actions from './actions.sendCommand';
import config from '../../../constants/Config';
import { getAuthorizationHeader, getGrailsAppBasedURL, getUserToken } from '../../../helper-classes/utility-functions';

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    of: Function,
};

const getHeaders = () => ({
    'X-Nspire-UserToken': getUserToken(),
    'Content-Type': 'application/json',
});

const checkCommandStatus = (commandSent, cmdObj, getJSON, of) => {
    let callCount = 0;
    const checkCommandObs$ = Observable.interval(CALL_INTERVAL_IN_MS).take(MAX_NUMBER_OF_CALLS)
        .mergeMap((i) => {
            callCount = i;
            return getJSON(`${config.get('PLATFORM_API_BASE_URL')}/commandRequests/${cmdObj.response.id}`, getHeaders(), { bypassAuthError: true });
        }).mergeMap(result => of(result))
        .catch(error => of(error));

    const checkCommandSubs$ = checkCommandObs$.subscribe((response) => {
        if (callCount === (MAX_NUMBER_OF_CALLS - 1) && response.status === 'Sent') {
            response.status = 'Timeout';
        } else if ((typeof (response.status) === 'number' && response.status !== 200) || (response.status !== 'Sent')) {
            /*
                check for api call/network errors
                OR
                Sent is pending, so in case of Success/Failed
                this call must stop
             */
            checkCommandSubs$.unsubscribe();
        }
        return actions.cmdStatusHandler(commandSent, response);
    });
};

export const sendCommand =
(actions$: Function, store: Object, { postJSON, getJSON, of }: ObservarblesTypes) =>
    actions$
        .ofType(SEND_COMMAND)
        .mergeMap(action => postJSON(`${config.get('PLATFORM_API_BASE_URL')}/commandRequests`, action.payload, getHeaders(), { bypassAuthError: true })
            .mergeMap((result) => {
                if (result.status === 201 && result.response.id) {
                    checkCommandStatus(action.payload.command, result, getJSON, of);
                    return of(actions.sendCmdToAssetSuccess(result));
                }
                return of(actions.sendCmdToAssetError(
                    action.payload.command,
                    result.response, action.payload.assetId,
                ));
            })
            .catch(error => of(actions.sendCmdToAssetError(
                action.payload.command,
                error, action.payload.assetId,
            ))));

export const getAssetAllowedCmdList =
(actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
    actions$
        .ofType(FETCH_ASSET_ALLOWED_CMDS)
        .mergeMap((action) => {
            const grailsHeaders = {
                'Content-Type': 'application/json',
                Authorization: getAuthorizationHeader(),
            };
            const { deviceGlobalId } = action.payload;
            let params = 'page=1&offset=0&max=100';
            params += `&_dc=${new Date().getTime()}&deviceGlobalId=${deviceGlobalId}`;
            return getJSON(`${getGrailsAppBasedURL()}/operation/json/paidCommandsService/getPaidCommands?${params}`, grailsHeaders);
        })
        .map((result) => {
            if (result.success) {
                return actions.fetchAssetAllowedCmdListSuccess(result.data);
            }
            return actions.fetchAssetAllowedCmdListError(result);
        })
        .catch(error => of(actions.fetchAssetAllowedCmdListError(error)));

export default combineEpics(sendCommand, getAssetAllowedCmdList);
