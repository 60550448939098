/* @flow */
import queryString from 'query-string';
import {
    formatData,
    getDistanceInUnitType,
    getUserToken,
    getGrailsAppBasedURL,
    getAuthorizationHeader,
} from '../../../helper-classes/utility-functions';
import config from '../../../constants/Config';

type ObservablesTypes = { getJSON: Function }

const getOptionalHeaders = () => ({
    cTypeOnly: {
        method: 'POST',
        headers: {
            'X-Nspire-UserToken': getUserToken(),
            'Content-Type': 'application/json',
        },
        body: '',
    },
    grails: {
        method: 'GET',
        headers: {
            'X-Nspire-UserToken': getUserToken(),
            'Content-Type': 'application/json',
        },
    },
    grailsWithData: {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthorizationHeader(),
        },
        body: '',
    },
    nspireOnlyHeader: { 'X-Nspire-UserToken': getUserToken() },
    nspireToken: {
        headers: { 'X-Nspire-UserToken': getUserToken() },
    },
    grailsNspireToken: {
        headers: { Authorization: getAuthorizationHeader() },
    },
    NspireWithAccept: {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            Accept: '*/*',
            'Accept-Encoding': 'gzip, deflate',
            'Accept-Language': 'en-US,en;q=0.9',
            Connection: 'keep-alive',
            Authorization: getAuthorizationHeader(),
        },
    },
    NspireWithAcceptWithoutUTF: {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: '*/*',
            'Accept-Encoding': 'gzip, deflate',
            'Accept-Language': 'en-US,en;q=0.9',
            Connection: 'keep-alive',
            Authorization: getAuthorizationHeader(),
        },
    },
});

const getRandomVal = () => new Date().getTime();
const convertDistanceIfNeeded = (data, param) => {
    if (data[param]) {
        // eslint-disable-next-line no-param-reassign
        data[param] = getDistanceInUnitType(data[param], 'english');
    }
};

export function getAssetNames(data: any, { getJSON }: ObservablesTypes) {
    const emptyFunc = () => [];
    if (!data && data.length <= 0) emptyFunc();
    return getJSON(`${config.get('PLATFORM_API_BASE_URL')}/assets?${data}`, getOptionalHeaders().nspireOnlyHeader)
        .map((result) => {
            const formattedData = formatData(result.content);
            const assetNames = {};
            formattedData.forEach((rec) => {
                const { id, name } = rec;
                assetNames[id] = name;
            });
            return assetNames;
        });
}

export function getAssetDetails(assetId: any) {
    const url = `${config.get('PLATFORM_API_BASE_URL')}/assets/${assetId}`;
    return fetch(url, getOptionalHeaders().nspireToken).then(response => response.json())
        .then((response) => { response.status = 200; return response; });
}

export function getAssetName(assetId: any) {
    const url = `${config.get('PLATFORM_API_BASE_URL')}/assets/${assetId}`;
    return fetch(url, getOptionalHeaders().nspireToken).then((response) => {
        if (response.ok) return response.json();
        return {};
    });
}

export function getPlatformAssets(data: any) {
    if (!data && data.length <= 0) return [];

    const url = `${config.get('PLATFORM_API_BASE_URL')}/assets?${data}&limit=100000`;
    return fetch(url, getOptionalHeaders().nspireToken).then((response) => {
        if (response.ok) return response.json();
        return {};
    });
}

export function getFVSAssets(assetIds: any) {
    if (!assetIds && assetIds.length <= 0) return [];

    const data = {
        searchParams: assetIds,
        type: 'ids',
    };
    const url = `${config.get('FLEET_VIEW_SERVICES_URL')}/assets`;
    const options = { ...getOptionalHeaders().cTypeOnly };
    options.body = JSON.stringify(data);
    return fetch(url, options).then(response => response.json()).then((response) => {
        if (response.data) return response.data;
        return {};
    });
}

export function getAssetsGrails(
    offset: number, limit: number, query: string, groups: any,
    assetFilter?: any, tags?: any, returnQuery?: boolean,
) {
    let url = `${getGrailsAppBasedURL()}/operation/json/deviceLocationRestService/get`;
    url = `${url}?_dc=${getRandomVal()}&offset=${offset}&max=${limit}&assetsOnly=true`;
    const queryConfig = {};

    if (query) queryConfig.assetSearchText = query;
    if (groups) {
        queryConfig.selectedAssetGroups = groups.map(group => ({ id: group.id, name: group.name }));
    }
    if (tags) {
        queryConfig.assetSearchTags = tags.map(tag => `public:${tag.name}`).join(',');
    }

    url = `${url}&config=${encodeURIComponent(JSON.stringify(queryConfig))}`;
    const sorts = encodeURIComponent(JSON.stringify([{ property: 'assetName', direction: 'ASC' }]));
    url = `${url}&sorts=${sorts}`;

    // setting of filter query from Alert Type
    if (assetFilter) url = `${url}&filter=${encodeURIComponent(JSON.stringify(assetFilter))}`;

    return fetch(url, getOptionalHeaders().grailsNspireToken)
        .then(response => (returnQuery ? { query, response } : response.json()));
}

export function getAssets(
    offset: number, limit: number, query: any, groups: any,
    assetFilter?: any, tags?: any, returnQuery?: boolean, type?: any,
) {
    const data = {
        sortParam: undefined,
        start: offset,
        limit,
        filterParam: [],
        searchParams: [''],
        advanceSearchParam: [],
        type: undefined,
    };

    if (query && typeof query === 'object') {
        data.advanceSearchParam = query;
    }

    if (query && typeof query === 'string' && query.length > 0) {
        data.searchParams = [query.replace(/[\s]{2,}/gi, ' ').trim()];
    } else delete data.searchParams;

    if (type && typeof type === 'string' && type.length > 0) {
        data.type = type;
    } else delete data.type;

    if (groups && groups.length) {
        const selectedAssetGroups = groups.map(group => group.name).join(',');
        data.advanceSearchParam.push(['assetGroupName', selectedAssetGroups]);
        data.type = 'exactMatchSearch';
    }

    if (tags && tags.length) {
        const assetSearchTags = tags.map(tag => tag.name).join(',');
        data.advanceSearchParam.push(['tags', assetSearchTags]);
        data.type = 'exactMatchSearch';
    }

    if (assetFilter && assetFilter.length) {
        data.filterParam = assetFilter;
    } else delete data.filterParam;

    const url = `${config.get('FLEET_VIEW_SERVICES_URL')}/assets`;
    const options = { ...getOptionalHeaders().cTypeOnly };
    options.body = JSON.stringify(data);

    return fetch(url, options)
        .then(response => (returnQuery ? { query, response } : response.json()));
}

export function getLandmarks(
    offset: number, limit: number, query: string,
    groups: any, landmarkFilter?: any,
) {
    let url = `${getGrailsAppBasedURL()}/operation/json/landmarkLocationRestService/get`;
    url = `${url}?_dc=${getRandomVal()}&offset=${offset}&max=${limit}&landmarksOnly=true`;
    const queryConfig = {};

    if (query) queryConfig.landmarkSearchText = query;
    if (groups) {
        queryConfig.selectedLandmarkGroups = groups
            .map(group => ({ id: group.id, name: group.name }));
    }

    if (landmarkFilter) {
        queryConfig.selectedLandmarkFilters = [{
            id: 'landmarkId', serverType: 'Integer', property: 'landmarkId', value: (landmarkFilter && landmarkFilter.length > 0 && landmarkFilter[0].value) ? landmarkFilter[0].value : landmarkFilter, operator: 'in',
        }];
    }

    url = `${url}&config=${encodeURIComponent(JSON.stringify(queryConfig))}`;
    return fetch(url, getOptionalHeaders().grailsNspireToken).then((response) => {
        if (response.ok) return response.json();
        return {};
    });
}

export function getAssetBySerialNumber(
    offset: number, limit: number, query: string,
    groups: any, assetFilter?: any,
) {
    let url = `${getGrailsAppBasedURL()}/operation/json/deviceLocationRestService/get`;
    url = `${url}?_dc=${getRandomVal()}&offset=${offset}&max=${limit}&assetsOnly=true`;
    const queryConfig = {};

    if (query) queryConfig.assetSearchText = query;
    if (groups) {
        queryConfig.selectedAssetGroups = groups.map(group => ({ id: group.id, name: group.name }));
    }

    // setting of filter query from Alert Type
    if (assetFilter) {
        if (Array.isArray(assetFilter)) {
            queryConfig.selectedAssetFilters = [{
                id: 'serialNumber', serverType: 'String', property: 'serialNumber', value: assetFilter, operator: 'in',
            }];
        } else {
            url = `${url}&filter=${encodeURIComponent(JSON.stringify(assetFilter))}`;
        }
    }

    url = `${url}&config=${encodeURIComponent(JSON.stringify(queryConfig))}`;
    const sorts = encodeURIComponent(JSON.stringify([{ property: 'assetName', direction: 'ASC' }]));
    url = `${url}&sorts=${sorts}`;
    return fetch(url, getOptionalHeaders().grailsNspireToken).then((response) => {
        if (response.ok) return response.json();
        return {};
    });
}

export function getAssetByAssetIds(
    offset: number, limit: number, query: string,
    groups: any, assetFilter?: any,
) {
    let url = `${getGrailsAppBasedURL()}/operation/json/deviceLocationRestService/get`;
    url = `${url}?_dc=${getRandomVal()}&offset=${offset}&max=${limit}&assetsOnly=true`;
    const queryConfig = {};

    if (query) queryConfig.assetSearchText = query;
    if (groups) {
        queryConfig.selectedAssetGroups = groups.map(group => ({ id: group.id, name: group.name }));
    }

    // setting of filter query from Alert Type
    if (assetFilter) {
        if (Array.isArray(assetFilter)) {
            queryConfig.selectedAssetFilters = [{
                id: 'assetId', serverType: 'Long', property: 'assetId', value: assetFilter, operator: 'in',
            }];
        } else {
            url = `${url}&filter=${encodeURIComponent(JSON.stringify(assetFilter))}`;
        }
    }

    url = `${url}&config=${encodeURIComponent(JSON.stringify(queryConfig))}`;
    const sorts = encodeURIComponent(JSON.stringify([{ property: 'assetName', direction: 'ASC' }]));
    url = `${url}&sorts=${sorts}`;
    return fetch(url, getOptionalHeaders().grailsNspireToken).then((response) => {
        if (response.ok) return response.json();
        return {};
    });
}

export function getServicePlans(offset: number, limit: number, assetId: string) {
    let url = `${config.get('MAINTENANCE_SERVICES_URL')}/servicePlans?offset=${offset}&limit=${limit}`;
    if (assetId) url += `&assetId=${assetId}`;
    return fetch(url, getOptionalHeaders().nspireToken).then((response) => {
        if (response.ok) return response.json();
        return {};
    });
}

export function getGrailsServicePlan(id: string) {
    const filters = encodeURIComponent(JSON.stringify([{ property: 'servicePlanId', value: id }]));
    let url = `${getGrailsAppBasedURL()}/maintenance/servicePlans`;
    url = `${url}?_dc=${getRandomVal()}&filters=${filters}`;
    return fetch(url, getOptionalHeaders().grailsNspireToken).then((response) => {
        if (response.ok) return response.json();
        return {};
    });
}

//
// Asset Groups
//
export const flattenGroups = (data: any) => {
    const groups = [];
    if (data) {
        const addCandidate = (candidate, nestLevel) => {
            groups.push({
                id: candidate.id,
                name: candidate.name,
                nestLevel,
                childNodes: candidate.children ? candidate.children.map(c => c.name) : [],
                children: candidate.children,
                tag: undefined,
            });
        };

        const addChildren = (children, nestLevel) => {
            const arr1 = Object.keys(children).map(key => children[key]);
            arr1.forEach((child) => {
                addCandidate(child, nestLevel);
                if (child.children) addChildren(child.children, nestLevel + 1);
            });
        };

        if (data.length > 0) {
            data.forEach((candidate) => {
                addCandidate(candidate, 0);
                if (candidate.children) addChildren(candidate.children, 1);
            });
        }
    }
    return groups;
};

export function getAssetGroups() {
    const sort = encodeURIComponent('[{"property":"leaf","direction":"ASC"}]');
    let url = `${getGrailsAppBasedURL()}/tree/viewAssetGroups.json?`;
    url = `${url}?_dc=${getRandomVal()}&sort=${sort}`;

    return fetch(url, getOptionalHeaders().grailsNspireToken)
        .then(response => response.json()).then((response) => {
            const groups = flattenGroups(response.children);
            return { data: groups, total: groups.length };
        });
}

export function getAssetGroupsGlobalId(offset: number, limit: number, query: string) {
    let url = `${config.get('FLEET_VIEW_SERVICES_URL')}/assetgroups?type=parentChildList&start=${offset || 0}&limit=${limit || 50}`;

    if (query) {
        url = `${config.get('FLEET_VIEW_SERVICES_URL')}/assetgroups?type=preLimitSearch&start=${offset || 0}&limit=${limit || 50}&search=${query}`;
    }

    return fetch(url, getOptionalHeaders().nspireToken)
        .then(response => response.json()).then((response) => {
            const groups = flattenGroups(response.data);
            return { data: groups, total: response.total, count: response.count };
        });
}

//
// Landmark Groups
//
export function getLandmarkGroups() {
    const sort = encodeURIComponent('[{"property":"leaf","direction":"ASC"}]');
    let url = `${getGrailsAppBasedURL()}/tree/viewLandmarkGroups.json?`;
    url = `${url}?_dc=${getRandomVal()}&sort=${sort}`;

    return fetch(url, getOptionalHeaders().grailsNspireToken)
        .then(response => response.json()).then((response) => {
            const groups = flattenGroups(response.children);
            return { data: groups, total: groups.length };
        });
}

//
// Asset Tags
//
export function getAssetTags() {
    const userId = localStorage.getItem('currentAccountUserId') || '';
    const filters = encodeURIComponent(`[{"property":"accountId","type":"Long","value":"${userId}","operator":"eq"}]`);
    let url = `${getGrailsAppBasedURL()}/operation/json/tagService/get`;

    url = `${url}?_dc=${getRandomVal()}&filters=${filters}&offset=0&max=500`;
    return fetch(url, getOptionalHeaders().grailsNspireToken).then((response) => {
        if (response.ok) return response.json();
        return { data: [], total: 0 };
    });
}

export function saveDistributionList(data: any) {
    const options = {
        ...getOptionalHeaders().NspireWithAcceptWithoutUTF,
        method: 'POST',
        body: queryString.stringify({
            recipients: data.recipients,
            servicePlanName: data.name,
        }),
    };

    options.headers = {
        ...options.headers,
    };

    return fetch(`${getGrailsAppBasedURL()}/recipients/servicePlan`, options)
        .then((response) => {
            if (response.ok) return response.json();
            return {};
        });
}

export function getDistributionList(planId: string) {
    const filters = encodeURIComponent(JSON.stringify([{
        property: 'servicePlanId',
        value: planId,
    }]));

    return fetch(`${getGrailsAppBasedURL()}/maintenance/servicePlans?_dc=${getRandomVal()}&page=1&start=0&limit=1&filters=${filters}`, getOptionalHeaders().NspireWithAccept)
        .then(response => response.json()).then((response) => {
            if (response && response.success) {
                const plan = response.data[0];
                const listId = plan && plan.distributionLists ? plan.distributionLists[0] : null;
                if (listId) {
                    return fetch(`${getGrailsAppBasedURL()}/recipients/distributionList/${listId}?_dc=${getRandomVal()}&page=1&start=0&limit=25`, getOptionalHeaders().NspireWithAccept)
                        .then(res => res.json()).then(res => res.data);
                }
            }
            return {};
        });
}

export function editServicePlan(data: any) {
    convertDistanceIfNeeded(data, 'firstServiceOdometer');
    const options = { ...getOptionalHeaders().cTypeOnly };
    options.method = 'PUT';
    options.body = JSON.stringify(data);

    return fetch(`${config.get('MAINTENANCE_SERVICES_URL')}/servicePlans/${encodeURIComponent(data.id)}`, options)
        .then((response) => {
            if (response.ok) return response.json();
            else if (response.ok === false) return response.json();
            return {};
        });
}

export function addServicePlan(data: any) {
    convertDistanceIfNeeded(data, 'distanceInterval');
    convertDistanceIfNeeded(data, 'firstServiceOdometer');
    const options = { ...getOptionalHeaders().grailsWithData };
    options.body = JSON.stringify(data);

    return fetch(`${getGrailsAppBasedURL()}/maintenance/servicePlans?dc=${getRandomVal()}&filters=`, options)
        .then((response) => {
            if (response.ok) return response.json();
            else if (response.ok === false) return response.json();
            return {};
        });
}

export function addServicePlanWithGlobalId(data: any) {
    convertDistanceIfNeeded(data, 'distanceInterval');
    convertDistanceIfNeeded(data, 'firstServiceOdometer');
    const options = { ...getOptionalHeaders().cTypeOnly };
    options.body = JSON.stringify(data);
    options.method = 'POST';
    return fetch(`${config.get('MAINTENANCE_SERVICES_URL')}/servicePlans`, options)
        .then((response) => {
            if (response.ok) return response.json();
            else if (response.ok === false) return response.json();
            return {};
        });
}

export function deleteServicePlan(data: any) {
    const options = { ...getOptionalHeaders().cTypeOnly };
    options.method = 'DELETE';
    options.body = JSON.stringify(data);

    return fetch(`${config.get('MAINTENANCE_SERVICES_URL')}/servicePlans/${encodeURIComponent(data.id)}`, options);
}

export function addServiceRecord(data: any) {
    convertDistanceIfNeeded(data, 'odometer');
    const options = { ...getOptionalHeaders().cTypeOnly };
    options.body = JSON.stringify(data);

    return fetch(`${config.get('MAINTENANCE_SERVICES_URL')}/serviceRecords`, options)
        .then(response => response.json()).then((response) => {
            if (response.code) {
                // This is possibly error send an error message back
                response.status = 400;
                response.error = response.message;
                return response;
            }
            response.status = 200;
            return response;
        });
}

export function updateServiceRecord(data: any) {
    convertDistanceIfNeeded(data, 'odometer');
    const options = { ...getOptionalHeaders().cTypeOnly };
    options.method = 'PUT';
    options.body = JSON.stringify(data);

    return fetch(`${config.get('MAINTENANCE_SERVICES_URL')}/serviceRecords/${encodeURIComponent(data.id)}`, options)
        .then(response => response.json()).then((response) => {
            if (response.code) {
                // This is possibly error send an error message back
                response.status = 400;
                response.error = response.message;
                return response;
            }
            response.status = 200;
            return response;
        });
}

export function deleteServiceRecord(data: any) {
    const options = { ...getOptionalHeaders().cTypeOnly };
    options.method = 'DELETE';
    options.body = JSON.stringify(data);

    return fetch(`${config.get('MAINTENANCE_SERVICES_URL')}/serviceRecords/${encodeURIComponent(data.id)}`, options);
}

export function addUserAccess(data: any) {
    const options = { ...getOptionalHeaders().grailsWithData };
    options.body = JSON.stringify(data);

    return fetch(`${getGrailsAppBasedURL()}/rest/json/universe?_dc=${getRandomVal()}`, options)
        .then(response => response.json())
        .catch((error) => {
            throw new Error(error);
        });
}

export function updateUserAccess(data: any) {
    const options = { ...getOptionalHeaders().grailsWithData };
    options.method = 'PUT';
    options.body = JSON.stringify(data);

    return fetch(`${getGrailsAppBasedURL()}/rest/json/universe/${data.id}?_dc=${getRandomVal()}`, options)
        .then(response => response.json())
        .catch((error) => {
            throw new Error(error);
        });
}

export function deleteUserAccess(id: any, data: any) {
    const options = { ...getOptionalHeaders().grailsWithData };
    options.method = 'DELETE';
    options.body = JSON.stringify(data);

    return fetch(`${getGrailsAppBasedURL()}/rest/json/universe/${id}?_dc=${getRandomVal()}`, options)
        .then(response => response.json())
        .catch((error) => {
            throw new Error(error);
        });
}

export function getUserAccess(id: any) {
    return fetch(`${getGrailsAppBasedURL()}/rest/json/universe?id=${id}&_dc=${getRandomVal()}`, getOptionalHeaders().grailsNspireToken)
        .then((response) => {
            if (response.ok) return response.json();
            return {};
        });
}

export function getOverdueCount() {
    const options = { ...getOptionalHeaders().nspireToken };
    options.method = 'GET';

    return fetch(`${config.get('MAINTENANCE_SERVICES_URL')}/serviceStatus?overdueOnly=true`, options)
        .then((response) => {
            if (response.ok) return response.json();
            return {};
        });
}

export function getNearestAssets(params: Object) {
    const url = `${config.get('FLEET_VIEW_SERVICES_URL')}/assets/nearest?lat=${params.lat}&lng=${params.lng}&nearest_assets_in_radius=true&start=${params.start}&limit=${params.perPage}`;
    return fetch(url, { ...getOptionalHeaders().grails })
        .then(response => response.json()).then(response => response || {});
}

export function fetchLandmarkList(offset: number, selectedLandmarkGroupsParam: any) {
    const sort = encodeURIComponent('[{"property":"name","direction":"ASC"}]');
    const selectedLMGroups = { selectedLandmarkGroups: selectedLandmarkGroupsParam };
    const queryConfig = encodeURIComponent(JSON.stringify(selectedLMGroups));
    const url = `${getGrailsAppBasedURL()}/operation/json/landmarkLocationRestService/get?config=${queryConfig}&sorts=${sort}&offset=${offset}&doCount=true`;
    let landmarkList = [];

    const option = getOptionalHeaders().grailsWithData;
    option.method = 'GET';
    delete option.body;

    return fetch(url, option)
        .then(response => response.json())
        .then((data) => {
            const items = data;
            landmarkList = items.data.map(item => ({
                id: item.landmarkId,
                name: item.name,
                nestLevel: 0,
                parentGroupId: item.landmarkGroupId,
            }));
            return { landmarkList, data };
        });
}
