/* @flow */
import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Avatar } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';
import customStyles from './Assets.module.scss';
import AppLoader from '../../AppLoader';
import {
    removeSavedPage,
    getAssetStatusStartDate,
    setCustomAssetStatusStartDate,
    getPermissionValue,
    isAssetNonReporting,
    getTimeStr,
    getTimeDiff,
    getNonReportingToolTip,
} from '../../../helper-classes/utility-functions';
import analytics from '../../../analytics';
import { getAssetIconType } from '../../../util/map_utils';
import GetSvgIcon, { AssetNonReporting } from '../../../util/svgImage_util';
import type { typeAssetsPrediction } from '../../../containers/Header/types';

const handleAssetsAnalytics = (e) => {
    const params = {
        feature: 'UniversalSearch',
    };
    if (e.type === 'map' && e.assets) {
        analytics.track('ASSET_MAP', params);
    } else if (e.type === 'Edit') {
        analytics.track('ASSET_EDIT', params);
    } else {
        analytics.track('ASSET_HISTORY', params);
    }
};

const Assets = (predictions: typeAssetsPrediction) => {
    const style = { width: 496, height: predictions.setMaxHeight };

    const items = (predictions.predictions).map((item, i) => {
        const isNonReporting = isAssetNonReporting(item);
        let lastReporting = '';
        let assetStatus = item.status || '';
        assetStatus = assetStatus.toUpperCase();

        if (isNonReporting) {
            lastReporting = item.lastEventDate ? `last report ${getTimeStr(getTimeDiff(item.lastEventDate, new Date()))} ago` : 'never reported';
            lastReporting = ` (${lastReporting})`;
        } else lastReporting = setCustomAssetStatusStartDate(getAssetStatusStartDate(item));

        return (
            <ListItem
                key={Math.random()}
                data-itemid={item.id}
                data-name={item.name}
            >
                {isNonReporting &&
                    <React.Fragment>
                        <AssetNonReporting className={customStyles.tooltip} />
                        <span className={customStyles.tooltipText}>
                            {getNonReportingToolTip(item)}
                        </span>
                    </React.Fragment>
                }
                <Avatar className={customStyles.oval}>
                    <GetSvgIcon
                        type={(item.attributes && item.attributes.icon)
                            ? getAssetIconType(item.attributes.icon)
                            : getAssetIconType(item.typeCode)}
                        data-itemid={item.id}
                        data-name={item.name}
                        className={customStyles.icon}
                    />
                </Avatar>
                <ListItemText
                    data-itemid={item.id}
                    data-name={item.name}
                    className={customStyles['list-items']}
                    primary={
                        <span
                            data-name={item.name}
                            id="main-address"
                            className={customStyles['main-address']}
                            alt={item.name}
                            title={item.name && item.deviceSerialNumber ? `${item.name} - ${item.deviceSerialNumber}` : item.name}
                        >
                            <span data-name={item.name} className={customStyles['main-address-header']}>{item.name}</span>
                            <span data-name={item.name} className={customStyles['main-address-sub-header']}>{item.deviceSerialNumber && ` - ${item.deviceSerialNumber}`}</span>
                        </span>
                    }
                    secondary={
                        <span
                            id="sub-address"
                            data-name={item.name}
                            className={customStyles['sub-address']}
                            title={`${assetStatus}${lastReporting}`}
                        >
                            <i
                                data-name={item.name}
                                className={customStyles[(assetStatus) ? assetStatus.toLowerCase() : 'notreporting']}
                            />
                            <span data-name={item.name} className={customStyles.captilize}>
                                {assetStatus}
                            </span>
                            {lastReporting}
                        </span>
                    }
                />
                <div
                    id="list-icon-container"
                    data-itemid={item.id}
                    data-name={item.name}
                    className={`${customStyles['list-icon-container']}`}
                >
                    <ListItemIcon className={customStyles.listIconItem}>
                        {item.deviceId ?
                            <div
                                data-itemid={item.id}
                                data-name={item.name}
                                data-type="History"
                                title="History"
                                role="link"
                                tabIndex="-1"
                                id={`asset-history-search-${i}`}
                                onClick={() => {
                                    removeSavedPage();
                                    predictions.redirectTo(`/assets/${item.id}/trips`);
                                }}
                                onKeyUp={() => { }}
                            >
                                <i
                                    data-itemid={item.id}
                                    data-type="History"
                                    data-name={item.name}
                                    className={customStyles.history}
                                />
                                <span
                                    data-itemid={item.id}
                                    data-type="map"
                                    data-name={item.name}
                                    className={customStyles['icon-span-text']}
                                >History
                                </span>
                            </div>
                            :
                            <div className={customStyles.disabled} title="Disabled">
                                <i
                                    data-itemid={item.id}
                                    data-type="History"
                                    data-name={item.name}
                                    className={customStyles.history}
                                />
                                <span
                                    data-itemid={item.id}
                                    data-type="map"
                                    data-name={item.name}
                                    className={customStyles['icon-span-text-disabled']}
                                >History
                                </span>
                            </div>
                        }
                    </ListItemIcon>
                    {getPermissionValue('Assets') === 'Modify' &&
                    <ListItemIcon className={customStyles.listIconItem}>
                        <div
                            data-itemid={item.id}
                            data-type="Edit"
                            title="Edit"
                            data-name={item.name}
                            role="link"
                            tabIndex="-1"
                            id={`asset-profile-search-${i}`}
                            onClick={() => {
                                removeSavedPage();
                                predictions.redirectTo(`/assets/edit/${item.id}`);
                            }}
                            onKeyUp={() => { }}
                        >
                            <i
                                data-itemid={item.id}
                                data-type="Edit"
                                data-name={item.name}
                                className={customStyles.edit}
                            />
                            <span
                                data-itemid={item.id}
                                data-type="Edit"
                                data-name={item.name}
                                className={customStyles['icon-span-text']}
                            >Edit
                            </span>
                        </div>
                    </ListItemIcon>
                    }
                    <ListItemIcon className={customStyles.listIconItem}>
                        {item.deviceId ?
                            <div
                                data-itemid={item.id}
                                data-type="map"
                                data-name={item.name}
                                data-assets={JSON.stringify(item)}
                                title="Map"
                                id={`map-option-${i}`}
                                role="link"
                                tabIndex="-1"
                                onClick={() => {
                                    removeSavedPage();
                                    predictions.redirectTo(`/home?assetId=${item.id}&zoom=true`);
                                }}
                                onKeyUp={() => { }}
                            >
                                <i
                                    data-itemid={item.id}
                                    data-type="map"
                                    data-name={item.name}
                                    data-assets={JSON.stringify(item)}
                                    className={customStyles['map-icon-right']}
                                />
                                <span
                                    data-itemid={item.id}
                                    data-type="map"
                                    data-name={item.name}
                                    data-assets={JSON.stringify(item)}
                                    className={customStyles['icon-span-text']}
                                >Map
                                </span>
                            </div>
                            :
                            <div className={customStyles.disabled} title="Disabled">
                                <i
                                    data-itemid={item.id}
                                    data-type="map"
                                    data-name={item.name}
                                    data-assets={JSON.stringify(item)}
                                    className={customStyles['map-icon-right']}
                                />
                                <span
                                    data-itemid={item.id}
                                    data-type="map"
                                    data-name={item.name}
                                    data-assets={JSON.stringify(item)}
                                    className={customStyles['icon-span-text-disabled']}
                                >Map
                                </span>
                            </div>
                        }
                    </ListItemIcon>
                </div>
            </ListItem>);
    });

    return (
        <List
            id="assets-dropdown"
            className={`${customStyles['address-block']}`}
            onClick={e => handleAssetsAnalytics(e.target.dataset)}
            style={{ maxHeight: predictions.setMaxHeight }}
        >
            <Scrollbars style={style} key="Assets" onScroll={predictions.onScroll}>
                {items}
                { predictions.isLoading ?
                    <div style={{ padding: 10, textAlign: 'center' }}>
                        <AppLoader
                            type="scale"
                            height={18}
                            width={2}
                            margin="1px"
                        />
                    </div>
                    : ''
                }
            </Scrollbars>
        </List>
    );
};


export default Assets;
