/* @flow */
import React, { Component } from 'react';
import customStyles from './AssetList.module.scss';
import List from '../../SharedComponents/List/List';
import AppLoader from '../../../components/AppLoader';
import AssetCard from '../List/AssetCard';
import { getRefreshChip } from '../../../util/map_utils';

export type Props = {
    assets: Object,
    isUpdating: boolean,
    loadNextPage: Function,
    handleClick: Function,
    redirectTo: Function,
    resetListData: Function,
    showHideMapAssetListRefreshChip: Function,
};

export type State = {
    showRefreshButton: boolean,
};

class AssetList extends Component<Props, State> {
    constructor(props: Object) {
        super(props);
        this.state = {
            showRefreshButton: false,
        };
    }

    UNSAFE_componentWillMount() {
        this.props.showHideMapAssetListRefreshChip(this.handleShowHideRefreshBtn);
    }

    getLoader = () => (<AppLoader
        loaderStyle={{
            'padding-top': '10px',
            'padding-bottom': '10px',
            'text-align': 'center',
            display: 'block',
        }}
    />)

    getAssetCardContent = (asset: Object) => {
        if (asset) {
            return (
                <AssetCard
                    asset={asset}
                    checkPaired
                    handleClick={this.props.handleClick}
                    key={asset.id}
                    redirectTo={this.props.redirectTo}
                    showDistance
                    showSpeed={false}
                />
            );
        }
        return '';
    }

    handleShowHideRefreshBtn = (showChip: boolean = false) => {
        if (showChip !== this.state.showRefreshButton) {
            this.setState({ showRefreshButton: showChip });
        }
    }

    refreshAssetListData = (isRefresh: boolean = false) => {
        if (isRefresh) this.props.resetListData();
        this.handleShowHideRefreshBtn(false);
    }

    render() {
        const { assets, isUpdating } = this.props;

        return (
            <React.Fragment>
                {assets && assets.currentPage >= 0 ?
                    <div className={customStyles.available_assets}>
                        {this.state.showRefreshButton
                                && getRefreshChip(this.refreshAssetListData)}
                        <div className={customStyles.available_assets_list}>
                            <List
                                data={assets.data || []}
                                height={81}
                                currentPage={assets.currentPage}
                                totalNumberofPage={assets.totalPages}
                                pagination={{
                                    isPagination: true,
                                    pageChanged: this.props.loadNextPage,
                                }}
                                getListItem={this.getAssetCardContent}
                                showMessage
                            />
                        </div>
                    </div>
                    :
                    isUpdating && this.getLoader()
                }
            </React.Fragment>
        );
    }
}

export default AssetList;
