/* @flow */
import {
    FETCH_ALERT_NAME_LIST,
    FETCH_ALERT_NAME_LIST_SUCCESS,
    FETCH_ALERT_NAME_LIST_ERROR,
} from './constants.alertNameCheck';

import type { AlertNameListTypeAction } from './constants.alertNameCheck';

export const fetchAlertList = (alertName: string, alertTypeId: string):
    AlertNameListTypeAction => ({
    type: FETCH_ALERT_NAME_LIST,
    payload: { alertName, alertTypeId },
});

export const fetchAlertListSuccess = (alertNameList: {}): AlertNameListTypeAction => ({
    type: FETCH_ALERT_NAME_LIST_SUCCESS,
    payload: { alertNameList },
});

export const fetchAlertListError = (error: string): AlertNameListTypeAction => ({
    type: FETCH_ALERT_NAME_LIST_ERROR,
    payload: { error },
});
