import React, { Component } from 'react';
import queryString from 'query-string';
import AlertStepper from '../../../components/Alerts/Steppers/AlertStepper';
import { reduxConnect } from '../../../hoc';
import * as actionStepper from './actions.stepers';
import * as actionAlertNameCheck from './AlertNameCheck/actions.alertNameCheck';
import * as actionSavedAlerts from '../SavedAlerts/actions.savedAlerts';
import * as actionAlertRecipient from '../../Alerts/AlertRecipient/actions.alertRecipient';
import { formJsonPayload, requestJsonPayload, canSaveAlert, formJsonPayloadEdit } from './getRequestPayload';
import { ALERT, ALERT_DETAILS } from '../constants.alerts';
import { filterAlerts, getAlertType } from '../../../util/mapAlertTypes';
import Dialog from '../../../components/SharedComponents/Dialog/Dialog';
import { getAssetByAssetIds } from '../../Maintenance/helper-classes/common-services';

type Props = {
    history: {
        push: Function,
    },
    postAlertData: Function,
    location: {
        search: String,
    },
    alertNames: {
        data: {}
    },
    fetchAlertList: Function,
    fetchSavedAlerts: Function,
    clearAlertReducer: Function,
    match: any,
    isError: boolean,
};

class AlertSteps extends Component<Props> {
    id: string;
    constructor(props) {
        super(props);
        this.state = {
            formValues: formJsonPayload,
            recipientValue: {
                recipients: '',
            },
            alertNameCheckInProgress: false,
            alertNameError: ' ',
            validationError: '',
            open: false,
            showDialog: false,
            assetCriteria: {
                assets: '',
                groups: '',
                tags: '',
                type: '',
            },
            isAlertSavedSuccess: false,
            savedAlerts: {},
            editAlert: false,
            savedAlertName: '',
        };
        const { alertID } = queryString.parse(this.props.location.search);
        this.id = alertID;
    }

    UNSAFE_componentWillMount() {
        this.loadPageData();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.isLoading === false && this.state.alertNameCheckInProgress) {
            let alertNameError = '';
            if (newProps.alertNameList.data !== undefined) {
                if (newProps.alertNameList.data.length !== 0) {
                    alertNameError = ALERT_DETAILS.ALERT_NAME_UNIQUE;
                }
            }
            this.setState({
                alertNameError,
                alertNameCheckInProgress: false,
            });
        }
        // Redirect to Save Alert page
        if (newProps.isAlertSaved || newProps.isError) {
            this.setState({
                showDialog: true,
                open: true,
            });
        }
        if (this.state.editAlert && newProps.isUpdating === false &&
            newProps.savedAlerts.length > 0 && this.state.savedAlertName === '') {
            const alertObject = newProps.savedAlerts[0];
            const savedAlertName = alertObject.name;
            this.setState({
                formValues: alertObject,
                savedAlertName,
            }, () => {
                const alertNames = filterAlerts(this.props.alertNames.data);
                const alertType = getAlertType(alertNames, alertObject.alertTypeId);
                this.setAlertType(alertType);
            });
        }
    }

    componentDidUpdate() {
        if (this.props.match.params.id && this.props.match.params.id !== this.id) {
            this.loadPageData();
        }
    }

    loadPageData = () => {
        const { alertType, alertID } = queryString.parse(this.props.location.search);
        this.id = alertID;
        if (alertID) {
            this.props.fetchSavedAlerts(
                0,
                1000,
                'desc',
                'dateCreated',
                `%5B%7B%22property%22%3A%22active%22%2C%22value%22%3Atrue%2C%22type%22%3A%22Boolean%22%7D%2C%7B%22property%22%3A%22id%22%2C%22value%22%3A%22${alertID}%22%7D%5D`,
                true,
            );
            this.setState({
                editAlert: true,
                savedAlertName: '',
                alertNameError: '',
                formValues: undefined,
            });
        } else {
            this.setState({
                editAlert: false,
                savedAlertName: '',
                alertType,
                formValues: formJsonPayload(alertType, 0),
            });
        }
    }

    getScheduleJson = (scheduleData) => {
        this.setState({
            ...this.state.formValues,
            formValues: {
                ...this.state.formValues,
                alertSchedulesJSON: scheduleData,
            },
        });
    };

    getRecipientJson = (recipientData) => {
        this.setState({
            ...this.state.formValues,
            recipientValue: {
                recipients: recipientData,
            },
        });
    };

    handleAlertTypeChange = (alertType, typeId) => {
        this.setState({
            alertType,
            formValues: formJsonPayload(alertType, typeId),
            alertNameCheckInProgress: false,
            alertNameError: ' ',
            validationError: '',
        });
    };

    setAlertId = (typeId) => {
        this.setState({
            ...this.state.formValues,
            formValues: {
                ...this.state.formValues,
                typeId,
            },
        });
    };

    setAlertType = (alertType) => {
        this.setState({
            alertType,
        }, () => {
            this.setFormValues();
        });
    }

    loadSelectedOptions = configs =>
        new Promise((resolve, reject) => {
            if (configs.selectedAssetFilters && configs.selectedAssetFilters.length > 0) {
                const filter = configs.selectedAssetFilters.map(asset => asset.value) || [];
                const assetGroups = filter === [] ? configs.selectedAssetGroups : [];
                getAssetByAssetIds(0, 100, '', assetGroups, filter).then((response) => {
                    if (response && response.data) {
                        resolve(response);
                    } else {
                        reject();
                    }
                });
            }
        });

    setFormValues = () => {
        if (this.state.formValues && this.state.formValues.configMap && this.state.alertType &&
            (this.state.formValues.alertType === undefined || this.state.formValues.alertType === '')) {
            const {
                jsonPayload,
                assetCriteria,
                recipientArray,
            } = formJsonPayloadEdit(this.state.formValues, this.state.alertType);

            this.loadSelectedOptions(jsonPayload.config).then((response) => {
                if (response.data.length > 0) {
                    assetCriteria.assets = response.data;
                }
            });

            this.setState({
                formValues: jsonPayload,
                assetCriteria,
                recipientValue: {
                    recipients: recipientArray,
                },
            });
        }
    };

    onCancel = () => {
        if (this.state.editAlert) {
            this.props.history.push('/saved-alerts');
        } else {
            this.props.history.push('/alert-types');
        }
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.clearAlertReducer();
        this.props.history.push('/saved-alerts');
    };

    handleSubmit = () => {
        const formValues = requestJsonPayload(
            this.state.alertType,
            this.state.formValues,
            this.state.assetCriteria,
        );
        this.setState({
            formValues,
        }, () => {
            this.props.postAlertData(this.state);
        });
    };

    handleInputChange = (e) => {
        if (e !== undefined) {
            this.setState({
                ...this.state.formValues,
                formValues: {
                    ...this.state.formValues,
                    [e.target.name]: e.target.value,
                },
            });
        }
    };

    handleCheckboxChange = name => (e) => {
        const { config } = this.state.formValues;
        config[name] = e.target.checked;
        const validationError = canSaveAlert(this.state.alertType, config);
        this.setState(() => ({
            ...this.state.formValues,
            formValues: {
                ...this.state.formValues,
                config,
            },
            validationError,
        }));
    };

    handleTextboxChange = name => (e) => {
        const { config } = this.state.formValues;
        config[name] = e.target.value;
        const validationError = canSaveAlert(this.state.alertType, config);
        this.setState(() => ({
            ...this.state.formValues,
            formValues: {
                ...this.state.formValues,
                config,
            },
            validationError,
        }));
    };

    // Asset Criteria
    onAssetCriteriaChanged = assetsData => this.setState({
        assetCriteria: assetsData,
    });

    // Triggers on every searchField text change
    onAlertNameChange = (e) => {
        const alertName = e.target.value.replace(/[^\w\s]/gi, '');
        let alertNameCheckInProgress = true;
        let alertNameError = '';
        if (alertName.trim() === '') {
            alertNameError = ALERT_DETAILS.ALERT_NAME_REQUIRED;
            alertNameCheckInProgress = false;
        } else if (this.state.editAlert &&
            alertName.toUpperCase() === this.state.savedAlertName.toUpperCase()) {
            alertNameError = '';
            alertNameCheckInProgress = false;
        }
        let { validationError } = this.state;
        if (
            validationError === '' &&
            (this.state.alertType === ALERT.INPUT_ALERT.CODE ||
            this.state.alertType === ALERT.TIRE_PRESSURE_ALERT.CODE)
        ) {
            const { config } = this.state.formValues;
            validationError = canSaveAlert(this.state.alertType, config);
        }

        this.setState({
            ...this.state.formValues,
            formValues: {
                ...this.state.formValues,
                name: alertName,
            },
            alertNameError,
            alertNameCheckInProgress,
            validationError,
        });
        if (alertNameCheckInProgress) {
            this.props.fetchAlertList(alertName.trim(), this.state.formValues.typeId);
        }
    };

    clearLandmarkSelects = (remainingLandmarks) => {
        const { config } = this.state.formValues;
        config.selectedLandmarkFilters = remainingLandmarks;
        this.setState(() => ({
            ...this.state.formValues,
            formValues: {
                ...this.state.formValues,
                config,
            },
        }));
    };

    getType = () => {
        const { isError } = this.props;
        let type = 'success';
        if (isError) {
            type = 'error';
        }
        return type;
    }

    getContent = () => {
        let content = 'Alert Saved Successfully.';
        if (this.props.isError) {
            content = 'Data could not be Saved.';
        } else if (this.state.editAlert) {
            content = 'Alert Updated Successfully.';
        }
        return content;
    }

    render() {
        return (
            <React.Fragment>
                {(this.state.formValues) ? (
                    <AlertStepper
                        editAlert={this.state.editAlert}
                        onCancel={this.onCancel}
                        handleSubmit={this.handleSubmit}
                        handleInputChange={this.handleInputChange}
                        alertType={this.state.alertType}
                        formData={this.state.formValues}
                        onAlertNameChange={this.onAlertNameChange}
                        handleCheckboxChange={this.handleCheckboxChange}
                        handleTextboxChange={this.handleTextboxChange}
                        getScheduleJson={this.getScheduleJson}
                        handleAlertTypeChange={this.handleAlertTypeChange}
                        setAlertId={this.setAlertId}
                        setAlertType={this.setAlertType}
                        alertNameCheckInProgress={this.state.alertNameCheckInProgress}
                        alertNameError={this.state.alertNameError}
                        validationError={this.state.validationError}
                        getRecipientJson={this.getRecipientJson}
                        recipientData={this.state.recipientValue.recipients}
                        clearLandmarkSelects={this.clearLandmarkSelects}
                        onAssetCriteriaChanged={this.onAssetCriteriaChanged}
                        assetCriteria={this.state.assetCriteria}
                    />
                ) : ''}

                {this.state.showDialog ? (
                    <div>
                        <Dialog
                            open={this.state.showDialog}
                            type={this.getType()}
                            button={[{
                                title: 'OK',
                                color: 'primary',
                                variant: 'contained',
                                handleClick: () => this.handleClose(),
                            }]}
                            content={(this.getContent())}
                            size="lg"
                        />
                    </div>
                ) : null}
            </React.Fragment>
        );
    }
}
// export default AlertSteps;
const mapStateToProps = state => ({
    saveRecipientData: state.recipientList.saveRecipientData,
    alertNameList: state.alertNameCheck.alertNameList,
    isLoading: state.alertNameCheck.isLoading,
    isAlertSaved: state.recipientList.isAlertSaved,
    savedAlerts: state.savedAlertsList.savedAlerts,
    isUpdating: state.savedAlertsList.isUpdating,
    isError: state.saveAlert.isError,
    ...state.alertNames,
});

const mapDispatchToProps = {
    ...actionStepper,
    ...actionAlertNameCheck,
    ...actionSavedAlerts,
    ...actionAlertRecipient,
};
export default reduxConnect(AlertSteps, mapDispatchToProps, mapStateToProps);
