/* @flow */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Typography, Button, SvgIcon, Grid } from '@mui/material';
import { getPermissionValue } from '../../helper-classes/utility-functions';
import SendCommand from '../../containers/Assets/SendCommand/SendCommand';

export type Props = {
    classes : Object,
    setActiveTab: Function,
    assetId: string,
    redirectTo : Function,
    deviceInfo: Object,
    deviceGlobalId: string,
    assetName: string,
}

export type State = {
    showCmdWindow: boolean,
}

const styles = theme => ({
    button: { margin: theme.spacing() },
    label: { flexDirection: 'column' },
    extendedIcon: { marginRight: theme.spacing() },
    assetActionBtn: { margin: 8, padding: '8px 0', flexDirection: 'column' },
    sendButton: { width: 88 },
    root: {
        backgroundColor: '#343e48',
        minHeight: 90,
    },
    icon: {
        width: 24,
        height: 17,
        objectFit: 'contain',
    },
    buttonLabel: {
        fontSize: 14,
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.4px',
        marginTop: 5,
        textAlign: 'center',
        color: '#ffffff',
        textTransform: 'none',
    },
    sendActionIcon: {
        msTransform: 'rotate(-40deg)',
        MozTransform: 'rotate(-40deg)',
        WebkitTransform: 'rotate(-40deg)',
        OTransform: 'rotate(-40deg)',
    },
    gridItem4: { paddingRight: 70 },
    gridItem3: { paddingLeft: 17 },
});

class DetailActions extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showCmdWindow: false,
        };
    }

    handleCmdBtnClick = (isOpen = false) => {
        if (isOpen !== this.state.showCmdWindow) this.setState({ showCmdWindow: isOpen });
    }

    render() {
        const {
            classes, setActiveTab, redirectTo, assetId,
        } = this.props;
        const showSendCmdBtn = (getPermissionValue('Assets') === 'Modify' &&
            !(this.props.deviceInfo.typeCode === 'ford') &&
            !((this.props.deviceInfo.typeCode || '').toLowerCase() === 'telemetryreport' && (this.props.deviceInfo.modelCode || '').toLowerCase() === 'derivepartnerbyod'));
        const gridItemClass = (showSendCmdBtn ? classes.gridItem4 : classes.gridItem3);
        const gridWidth = (showSendCmdBtn ? 3 : 4);

        return (
            <Grid
                id="menu-actions"
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.root}
                spacing={0}
            >
                <Grid item xs={gridWidth} className={gridItemClass}>
                    <Button
                        id="action-show-history"
                        onClick={() => setActiveTab('trips')}
                        classes={{ root: classes.assetActionBtn, label: classes.label }}
                    >
                        <SvgIcon style={{ fontSize: 22 }}>
                            <path fill="#ffffff" d="M13.5,8H12V13L16.28,15.54L17,14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3" />
                        </SvgIcon>
                        <Typography className={classes.buttonLabel}>Show</Typography>
                        <Typography className={classes.buttonLabel}>History</Typography>
                    </Button>
                </Grid>
                <Grid item xs={gridWidth} className={gridItemClass}>
                    <Button
                        id="action-nearest-landmark"
                        onClick={() => redirectTo(`/assets/${assetId}/nearest-landmark`)}
                        classes={{ root: classes.assetActionBtn, label: classes.label }}
                    >
                        <SvgIcon>
                            <path fill="#ffffff" d="M12 0c3.96 0 7.2 3.24 7.2 7.2 0 6.122-7.2 13.312-7.2 13.312S4.8 13.44 4.8 7.2C4.8 3.24 8.04 0 12 0zm-.072 3a3.928 3.928 0 1 0 0 7.857 3.928 3.928 0 0 0 0-7.857zm2.096 4.5H12.5v1.524h-1.143V7.5H9.833V6.357h1.524V4.833H12.5v1.524h1.524V7.5zm-8.287 8.25c-1.514.314-2.784.698-3.787 1.238-.502.267-.952.571-1.312.975-.361.403-.638.989-.638 1.612 0 .984.638 1.702 1.35 2.212.712.511 1.608.882 2.663 1.2C6.127 23.62 8.906 24 12 24c3.094 0 5.873-.38 7.987-1.012 1.055-.32 1.95-.69 2.663-1.2.712-.511 1.35-1.229 1.35-2.213 0-1.252-.933-2.063-1.95-2.625-1.017-.563-2.32-.942-3.863-1.2l-.375 2.363c1.341.225 2.41.567 3.076.937.445.244.609.45.674.525-.037.042-.112.127-.3.263-.374.267-1.054.595-1.95.862-1.785.534-4.406.9-7.312.9-2.906 0-5.527-.366-7.313-.9-.895-.267-1.575-.595-1.95-.862a1.659 1.659 0 0 1-.3-.263c.09-.098.3-.267.638-.45.675-.36 1.819-.727 3.188-1.012l-.526-2.363z" />
                        </SvgIcon>
                        <Typography className={classes.buttonLabel}>Nearest</Typography>
                        <Typography className={classes.buttonLabel}>Landmark</Typography>
                    </Button>
                </Grid>
                <Grid item xs={gridWidth} className={gridItemClass}>
                    <Button
                        id="action-dispatch-vehicle"
                        classes={{ root: classes.assetActionBtn, label: classes.label }}
                        onClick={() => redirectTo(`/assets/${assetId}/dispatch-action`)}
                    >
                        <SvgIcon>
                            <path fill="#ffffff" d="M1 5H0V4h6V3H0V2h7V1H0V0h13.125c.48 0 .875.395.875.875v12.25c0 .48-.395.875-.875.875H10a3 3 0 0 1-6 0H1.875A.879.879 0 0 1 1 13.125V6h4V5H1zm6 7.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM15.656 2a.658.658 0 0 0-.656.656v10.688c0 .27.172.496.406.594.078.03.16.062.25.062H17a3 3 0 0 0 6 0h1.344c.09 0 .172-.027.25-.063a.644.644 0 0 0 .343-.343.606.606 0 0 0 .063-.25V8.188c0-.13-.055-.235-.125-.344L21.187 2.28a.627.627 0 0 0-.53-.281h-5zM18 4h2.531l2.657 4H18V4zm2 8.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z" />
                        </SvgIcon>
                        <Typography className={classes.buttonLabel}>Dispatch</Typography>
                        <Typography className={classes.buttonLabel}>Vehicle</Typography>
                    </Button>
                </Grid>
                { showSendCmdBtn &&
                <React.Fragment>
                    <Grid item xs={gridWidth} className={gridItemClass}>
                        <Button
                            id="action-send-command"
                            onClick={() => this.handleCmdBtnClick(true)}
                            classes={{ root: classes.assetActionBtn, label: classes.label }}
                        >
                            <SvgIcon className={classes.sendActionIcon}>
                                <path fill="#ffffff" d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
                            </SvgIcon>
                            <Typography className={classes.buttonLabel}>Send</Typography>
                            <Typography className={classes.buttonLabel}>Command</Typography>
                        </Button>
                    </Grid>
                    <SendCommand
                        assetId={assetId}
                        deviceGlobalId={this.props.deviceGlobalId}
                        assetName={this.props.assetName}
                        handleClose={this.handleCmdBtnClick}
                        isOpen={this.state.showCmdWindow}
                    />
                </React.Fragment> }
            </Grid>
        );
    }
}

DetailActions.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(DetailActions);
