/* @flow */
import { showCompliance, getLocalStorageValue, updateAppUserSettings, clearCookieOnLogout, lmLogout } from '../../helper-classes/utility-functions';

import {
    FETCH_LOGGED_IN_USER_ERROR,
    FETCH_LOGGED_IN_USER_SUCCESS,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    GRAILS_SSO_SIGNIN_ERROR,
    UPDATE_WARNING_MESSAGE,
    AUTH_SERVICE_LOGIN_ERROR_HANDLER,
} from './contants.login';

const getSavedItem: Function = (): ?string => global.localStorage.getItem('userToken');


const userToken = getSavedItem() || '';
const initialState = {
    loggedIn: userToken !== '',
    userToken,
    user: {
        firstName: '',
        lastName: '',
    },
    hasAppsettings: false,
    userSettingsUpdated: false,
    showResellerTerms: false,
};

type stateType = {
    loggedIn: boolean,
    userToken: string,
    user: {
        firstName: string,
        lastName: string,
    },
    hasAppsettings: boolean,
    userSettingsUpdated: boolean,
    showResellerTerms: boolean,
};

type actionType = {
    type: string,
    payload :{
        token:string,
        user: Object,
        username: string,
        email: string,
        userSettings: Object,
        alert: Object,
        error: string,
        isAccountSuspended: boolean,
        redirectToForgotPassword: boolean,
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case LOGIN_SUCCESS:
        return {
            ...state,
            loggingIn: false,
            loggedIn: true,
            showResellerTerms: true,
            userToken: localStorage.getItem('userToken'),
            isAccountSuspended: false,
            redirectToForgotPassword: false,
        };
    case LOGIN_ERROR:
        localStorage.clear();
        clearCookieOnLogout();
        return {
            ...state,
            loggingIn: false,
            loggedIn: false,
            userToken: null,
            isAccountSuspended: action.payload.isAccountSuspended,
            redirectToForgotPassword: action.payload.redirectToForgotPassword,
            error: action.payload.error,
        };
    case LOGOUT: {
        const hasComplianceFlag = showCompliance();
        let warning = { message: '', needToShow: false };
        if (action.payload && action.payload.alert && action.payload.alert.needToShow) {
            warning = action.payload.alert;
        }
        const REMEMBER_ME = localStorage.getItem('REMEMBER_ME');
        const impersonated = getLocalStorageValue('fleet-impersonated') || '';
        localStorage.clear();
        if (REMEMBER_ME) localStorage.setItem('REMEMBER_ME', REMEMBER_ME);
        if (impersonated) localStorage.setItem('fleet-impersonated', impersonated);
        clearCookieOnLogout();
        lmLogout();

        return {
            ...initialState,
            loggedIn: false,
            userToken: '',
            hasCompliance: hasComplianceFlag,
            warning,
        };
    }
    case FETCH_LOGGED_IN_USER_SUCCESS: {
        const { userSettings } = action.payload;
        updateAppUserSettings(userSettings);
        return {
            ...state,
            hasAppsettings: userSettings['app.settings'] && userSettings.userTz && userSettings['app.settings'].value.timeDisplay !== '',
            userSettingsUpdated: true,
        };
    }
    case FETCH_LOGGED_IN_USER_ERROR:
        return {
            ...state,
        };
    case GRAILS_SSO_SIGNIN_ERROR:
        return {
            ...state,
            loggingIn: false,
            loggedIn: false,
            userToken: null,
            error: action.payload.error,
        };
    case AUTH_SERVICE_LOGIN_ERROR_HANDLER:
        return {
            ...state,
            loggingIn: false,
            loggedIn: false,
            userToken: null,
            error: action.payload.error,
        };
    case UPDATE_WARNING_MESSAGE: {
        return {
            ...state,
            warning: { message: '', needToShow: false },
        };
    }
    default:
        return state;
    }
};
