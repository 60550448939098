/* @flow */
import React, { Component } from 'react';
import { reduxConnect } from '../../../hoc';
import * as actions from './actions.userRole';
import AppLoader from '../../../components/AppLoader';
import AddUserRole from '../../../components/AddUserRole/AddUserRole';
import { getParamValue } from '../../../helper-classes/utility-functions';
import Dialog from './../../../components/SharedComponents/Dialog/Dialog';
import analytics from '../../../analytics';

export type Props = {
    history: {
        push: Function,
    },
    addUserRole: Function,
    editUserRole: Function,
    deleteUserRole: Function,
    fetchUserRole: Function,
    fetchUserRoles: Function,
    updateLoader: Function,
    closeDialog: Function,
    loading: boolean,
    isUpdating: boolean,
    success: boolean,
    isError: boolean,
    error: Object,
    userRole: Object,
    userRoles: Array<{
        id: number,
        name: string,
    }>,
    match: any,
};

export type State = {
    isDelete: boolean,
    id: string;
};

class UserRole extends Component<Props, State> {
    isEdit: boolean;
    isDeleted: boolean;
    button: Array<Object>;
    id: string;

    constructor(props) {
        super(props);
        this.state = {
            isDelete: false,
            id: '',
        };
        this.isEdit = false;
        this.isDeleted = false;
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
        }];
        this.id = getParamValue(this.props, 'id');
    }

    componentDidMount() {
        this.loadPageData();
    }

    componentDidUpdate() {
        if (this.props.match.params.id && this.props.match.params.id !== this.id) {
            this.loadPageData();
        }
    }

    loadPageData = () => {
        const id = getParamValue(this.props, 'id');
        this.isDeleted = false;
        this.id = id;
        if (id) {
            this.isEdit = true;
            this.props.updateLoader(true);
            this.props.fetchUserRole(id);
        }
        this.props.fetchUserRoles();
    }

    createUserRole = (data) => {
        this.props.updateLoader(true);
        this.props.addUserRole(data);
    }

    updateUserRole = (data) => {
        this.props.updateLoader(true);
        this.props.editUserRole(data);
    }

    deleteUserRole = (id) => {
        this.setState({ isDelete: true, id });
    }

    formatError = (content, error) => {
        let newContent = content;
        if (error && error.errors) {
            newContent = (
                <div>
                    {newContent}
                    <ul>
                        {Object.keys(error.errors).map(d =>
                            <li key={d}>{d}: {error.errors[d]}</li>)
                        }
                    </ul>
                </div>
            );
        } else if (error && error.message) {
            newContent = `${newContent} ${error.message}`;
        } else if (error && error.msg) {
            newContent = `${newContent} ${error.msg}`;
        } else {
            newContent = `${newContent} Something went wrong`;
        }
        return newContent;
    }

    handleClickOk = () => {
        const { isDelete, id } = this.state;
        this.props.closeDialog();
        this.isDeleted = false;
        if (this.props.success) {
            this.props.history.push('/user-roles');
        }
        if (isDelete) {
            this.props.updateLoader(true);
            this.props.deleteUserRole(id);
            this.setState({ isDelete: false });
            this.isDeleted = true;
        }
    }

    getType = () => {
        let type = '';
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
        }];

        if (this.props.success) {
            type = 'success';
        } else if (this.props.isError) {
            type = 'error';
        } else if (this.state.isDelete) {
            type = 'confirm';
            this.button.splice(0, 0, { title: 'Cancel', color: 'primary', handleClick: () => this.setState({ isDelete: false }) });
        }
        return type;
    }

    getContent = () => {
        let content = 'Are you sure you want to delete the user role?';
        const { isError, success, error } = this.props;

        if (isError) {
            if (this.isEdit) {
                content = this.isDeleted ? 'Delete User Role Error :' : 'Edit User Role Error :';
            } else content = 'Add User Role Error:';
            content = this.formatError(content, error);
        } else if (success) {
            if (!this.isEdit) content = 'User role added successfully';
            else if (this.isEdit) {
                if (this.isDeleted) {
                    content = 'User role deleted successfully ';
                    analytics.track('DELETE_USER_ROLE', { feature: 'Admin' });
                } else content = 'User role updated successfully ';
            }
        }
        return content;
    }

    render() {
        const { isUpdating } = this.props;
        return (
            <React.Fragment>
                {(this.props.loading || isUpdating) && <AppLoader type="fullScreen" />}
                <AddUserRole
                    createUserRole={data => this.createUserRole(data)}
                    updateUserRole={data => this.updateUserRole(data)}
                    deleteUserRole={id => this.deleteUserRole(id)}
                    userRole={this.props.userRole}
                    userRoles={this.props.userRoles}
                    isUpdating={isUpdating}
                    isEdit={this.isEdit}
                />
                <Dialog
                    open={this.props.success || this.props.isError || this.state.isDelete}
                    type={this.getType()}
                    customTitle=""
                    button={this.button}
                    content={this.getContent()}
                    size="sm"
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isUpdating: state.addUserRole.isUpdating,
    loading: state.addUserRole.loading,
    userRole: state.addUserRole.userRole,
    userRoles: state.addUserRole.userRoles,
    error: state.addUserRole.error,
    isError: state.addUserRole.isError,
    success: state.addUserRole.success,
});

export default reduxConnect(UserRole, actions, mapStateToProps);
