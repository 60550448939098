/* eslint-disable */
import React, { Component } from 'react';
import { reduxConnect } from '../../hoc';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import ExternalSite from '../../components/ExternalSite/ExternalSite';
import { isReseller, getUserToken } from '../../helper-classes/utility-functions';
import config from '../../constants/Config';

export type Props = {
    history: {
        push: Function,
        replace: Function,
    },
};

export type State = {
    url: string,
    updateSelectedTab: Function,
    selectedTab: string,
};

const loaderStyle = { position: 'absolute !important', top: '50% !important', left: '40vw !important' };

class LMDashCam extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            url: '',
            updateSelectedTab: this.updateSelectedTab.bind(this),
            selectedTab: this.getSelectedTabValue(props),
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        prevState.updateSelectedTab({ ...nextProps }, prevState);
        return null;
    }

    componentDidMount() {
        this.updateSelectedTab(this.props, this.state);
    }

    getSelectedTabValue = (props) => {
        let { selectedTab } = props.history.location.state || { selectedTab: 'home' };
        if (props.history.location.state && props.history.location.state.selectedTab.indexOf('lm-') > -1) {
            selectedTab = selectedTab.split('lm-')[1];
        }
        return selectedTab;
    }

    updateSelectedTab = (props, state) => {
        const selectedTab = this.getSelectedTabValue(props);
        if (props.history.location.state &&
            state.url !== props.history.location.state.selectedTab) {
            const iframe = document.getElementById('lm-dashcam');
            if (iframe) {
                iframe.contentWindow.postMessage({ 
                    "eventType": "navigation",
                    "path": selectedTab
                }, '*');
            }
            this.setState({ url: props.history.location.state.selectedTab });
        } else if (!props.history.location.state && !this.state.url) {
            this.setState({ url : 'lm-home' });
        }
    }

    getURL = () => (isReseller() ? config.get("RESELLER_LM_DASHCAM_URL") : config.get("LM_DASHCAM_URL"));

    render() {
        return (
            <MiniDrawer redirectTo={this.props.history.push} selectedTab={this.state.url}>
                <ExternalSite id="lm-dashcam" url={`${this.getURL()}/sso?access_token=${getUserToken()}&redirect_path=${this.state.selectedTab}`} allowFullScreen />;
            </MiniDrawer >
        );
    }
}

export default reduxConnect(LMDashCam);
