/* @flow */

import * as assetGroupConstants from './constants.assetGroupManage';
import type { AssetGroupManageAction } from './constants.assetGroupManage';

export const fetchAssets = (
    offset: number,
    limit: number,
    queryConfig: string,
): AssetGroupManageAction => ({
    type: assetGroupConstants.GRAILS_FETCH_ASSETS,
    payload: {
        offset,
        limit,
        queryConfig,
    },
});
export const fetchAssetsSuccess =
    (assets: any): AssetGroupManageAction => ({
        type: assetGroupConstants.GRAILS_FETCH_ASSETS_SUCCESS,
        payload: { assets },
    });

export const fetchAssetsError = (error: string): AssetGroupManageAction => ({
    type: assetGroupConstants.GRAILS_FETCH_ASSETS_ERROR,
    payload: { error },
});
export const postAssets = (
    requestBody: Object,
    groupName: string,
    isNewGroupName: boolean,
): AssetGroupManageAction => ({
    type: assetGroupConstants.GRAILS_POST_ASSETS,
    payload: {
        requestBody,
        groupName,
        isNewGroupName,
    },
});
export const updateAssetGroup = (
    requestBody: Object,
    groupName: string,
    isNewGroupName: boolean,
): AssetGroupManageAction => ({
    type: assetGroupConstants.GRAILS_POST_ASSETS,
    payload: {
        requestBody: requestBody.firstCallData,
        update: true,
        secondCallData: requestBody.secondCallData,
        groupName,
        isNewGroupName,
    },
});
export const showLoader = (showLoaderFlag: boolean) => ({
    type: assetGroupConstants.SHOW_LOADER,
    payload: {
        showLoader: showLoaderFlag,
    },
});
export const updateAssetGroupSuccess = (response: any, data: any) => ({
    type: assetGroupConstants.GRAILS_POST_ASSETS,
    payload: {
        requestBody: data.secondCallData,
        groupName: data.groupName,
        isNewGroupName: data.isNewGroupName,
    },
});
export const fetchSelectedAssets = (queryConfig: Object): AssetGroupManageAction => ({
    type: assetGroupConstants.GRAILS_FETCH_SELECTED_ASSETS,
    payload: {
        queryConfig,
    },
});
export const fetchSelectedAssetsSuccess =
    (selectedAssets: any): AssetGroupManageAction => ({
        type: assetGroupConstants.GRAILS_FETCH_SELECTED_ASSETS_SUCCESS,
        payload: { selectedAssets },
    });
export const postAssetGroupSuccess =
    (postResponse: any): AssetGroupManageAction => ({
        type: assetGroupConstants.GRAILS_POST_ASSETS_GROUP_SUCCESS,
        payload: { postResponse },
    });
export const fetchAssetDetailsFromGlobalId =
    (globalId: string): AssetGroupManageAction => ({
        type: assetGroupConstants.FETCH_ASSET_GROUP_DETAILS_GLOBALID,
        payload: {
            globalId,
        },
    });
export const fetchAssetDetailsFromGlobalIdSuccess =
    (assetGroupDetails: any): AssetGroupManageAction => ({
        type: assetGroupConstants.FETCH_ASSET_GROUP_DETAILS_GLOBALID_SUCCESS,
        payload: { assetGroupDetails },
    });
export const deleteAssetGroup =
    (globalId: string): AssetGroupManageAction => ({
        type: assetGroupConstants.DELETE_ASSET_GROUP_GLOBALID,
        payload: {
            globalId,
        },
    });
export const deleteAssetGroupSuccess =
    (assetGroupDeleteResponse: any): AssetGroupManageAction => ({
        type: assetGroupConstants.DELETE_ASSET_GROUP_GLOBALID_SUCCESS,
        payload: { assetGroupDeleteResponse },
    });

export const fetchAssetsAndSelectedAssets = (data: any) => ({
    type: assetGroupConstants.ASSET_AND_SELECTED_ASSET,
    payload: data,
});

export const fetchAssetAndSelectedAssetSuccess =
    (response: any): AssetGroupManageAction => ({
        type: assetGroupConstants.GRAILS_FETCHASSETS_AND_SELECTED_ASSETS_SUCCESS,
        payload: { ...response },
    });

export const fetchAssetAndSelectedAssetError =
    (error: any): AssetGroupManageAction => ({
        type: assetGroupConstants.GRAILS_FETCHASSETS_AND_SELECTED_ASSETS_ERROR,
        payload: { error },
    });

export const closeDialog = () => ({
    type: assetGroupConstants.CLOSE_DIALOG,
    payload: {},
});
