/* @flow */
import moment from 'moment';
import { combineEpics } from 'redux-observable';
import { FETCH_MAINTENANCE_STATUS } from './constants.maintenanceStatus';
import { fetchMaintenanceStatusError, fetchMaintenanceStatusSuccess } from './actions.maintenanceStatus';
import { daysDifference, formatData } from '../../../helper-classes/utility-functions';
import config from '../../../constants/Config';

type ObservablesTypes = {
    getJSON: Function,
    of: Function
}

function isOverdue(x) {
    return x != null && typeof x === 'number' && x < 0;
}

function getTimeDifference(time: string) {
    return time ? daysDifference(time, moment.utc().toISOString()) : null;
}

export const getMaintenance =
    (actions$: Function, store: Object, { getJSON, of }: ObservablesTypes) =>
        actions$
            .ofType(FETCH_MAINTENANCE_STATUS)
            .mergeMap((action) => {
                const {
                    order,
                    orderBy,
                    pageNumber,
                    pageSize,
                    filtersToSend,
                } = action.payload;
                const headers = {
                    'X-Nspire-UserToken': store.getState().userSession.userToken,
                };
                const serviceURL = `${config.get('MAINTENANCE_SERVICES_URL')}/serviceStatus`;
                let params = `offset=${pageNumber * pageSize}&limit=${pageSize}`;
                if (order && orderBy) {
                    params += `&sort=${orderBy}:${order.toUpperCase()}`;
                }
                if (filtersToSend && filtersToSend.length > 0) {
                    filtersToSend.forEach((filter) => {
                        params += `&${filter.property}=${filter.value}`;
                    });
                }
                return getJSON(`${serviceURL}?${params}`, headers)
                    .map((result) => {
                        const formattedData = formatData(result.content);
                        formattedData.forEach((rec) => {
                            /* eslint no-param-reassign: 0 */
                            rec.remainingTimePeriod = getTimeDifference(rec.endDate);
                            rec.isEngineHoursOverdue = isOverdue(rec.remainingEngineHours);
                            rec.isDistanceOverdue = isOverdue(rec.remainingOdometer);
                            rec.isTimeOverdue = isOverdue(rec.remainingTimePeriod);
                            rec.isOverdue =
                                rec.isDistanceOverdue
                                || rec.isEngineHoursOverdue
                                || rec.isTimeOverdue;
                        });

                        const finalData =
                            fetchMaintenanceStatusSuccess(formattedData, result.total);
                        return finalData;
                    })
                    .catch(error => of(fetchMaintenanceStatusError(error)));
            });

export default combineEpics(getMaintenance);
