/* @flow */

import {
    FETCH_RECORDS_LIST, FETCH_RECORDS_LIST_SUCCESS, FETCH_RECORDS_LIST_ERROR,
    RESET_RECORDS_LIST,
} from './constants.recordList';
import type { RecordListTypeAction } from './constants.recordList';

export const fetchRecordList = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filtersToSend: Array<{}>,
): RecordListTypeAction => ({
    type: FETCH_RECORDS_LIST,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
        filtersToSend,
    },
});

export const fetchRecordListSuccess =
    (records: any, totalRecords: number): RecordListTypeAction => ({
        type: FETCH_RECORDS_LIST_SUCCESS,
        payload: { records, totalRecords },
    });

export const resetTable =
    (): RecordListTypeAction => ({
        type: RESET_RECORDS_LIST,
    });

export const fetchRecordListError = (error: string): RecordListTypeAction => ({
    type: FETCH_RECORDS_LIST_ERROR,
    payload: { error },
});

