/* @flow */
import React, { Component } from 'react';
import queryString from 'query-string';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import NearestAssetsList from '../../components/Assets/Nearest/NearestAssetsList';
import { getParamValue, getData } from '../../helper-classes/utility-functions';
import config from '../../constants/Config';

export type Props = {
    location: Object,
    history: {
        push: Function,
        goBack: Function,
    },
    classes: Object,
};

export type State = {
    landmarkId: string,
    landmark: Object,
};

class NearestAssets extends Component<Props, State> {
    constructor(props: Object) {
        super(props);
        const query = queryString.parse(this.props.location.search);
        query.landmarkId = getParamValue(this.props, 'id') || '';
        this.state = this.getInitialState(query);
    }

    getInitialState = (query: Object) => ({
        landmarkId: query.landmarkId,
        landmark: {
            lat: query.lat,
            lng: query.lng,
            name: '',
            address: '',
        },
    });

    componentDidMount() {
        if (this.state.landmarkId) {
            this.getLandmarkData(this.state.landmarkId);
        }
    }

    componentDidUpdate() {
        const query = queryString.parse(this.props.location.search);
        const landmarkId = getParamValue(this.props, 'id') || '';

        if (query.lat !== this.state.landmark.lat && !landmarkId) {
            this.initializeComponent(query);
        } else if (landmarkId && this.state.landmarkId !== landmarkId) {
            this.getLandmarkData(landmarkId);
        }
    }

    getLandmarkData = (landmarkId: string) => {
        getData(`${config.get('FLEET_VIEW_SERVICES_URL')}/landmarks/${landmarkId}`).then((result: any) => {
            if (result && result.data) {
                const landmark = result.data[0] || {};
                this.setState({
                    landmarkId,
                    landmark: {
                        ...landmark,
                        name: landmark.name || '',
                        address: landmark.fullAddress || '',
                        lat: landmark.marker ? landmark.marker.y : 0,
                        lng: landmark.marker ? landmark.marker.x : 0,
                    },
                });
            }
        }).catch();
    }

    initializeComponent = (query: Object) => this.setState(this.getInitialState(query));

    render() {
        return (
            <MiniDrawer redirectTo={this.props.history.push}>
                <NearestAssetsList
                    landmark={{
                        ...this.state.landmark,
                        lat: parseFloat(this.state.landmark.lat) || 0,
                        lng: parseFloat(this.state.landmark.lng) || 0,
                        landmarkId: this.state.landmarkId,
                    }}
                    {...this.props}
                />
            </MiniDrawer>
        );
    }
}

export default NearestAssets;

