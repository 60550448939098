/* @flow */
import React from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { reduxConnect } from '../../hoc';
import * as actions from '../Login/actions.login';
import AppLoader from '../../components/AppLoader';
import { getDefaultLandingPageURLWithSlash, redirectToAuthService } from '../../helper-classes/utility-functions';
import * as notificationActions from '../../components/SharedComponents/NotificationHandler/actions.appNotifications';

type Props = {
    userSession: {
        loggedIn?: boolean,
        loggingIn: boolean,
        isAccountSuspended: boolean,
    },
    location: {
        search: string,
        pathname: string,
        key: string,
        state: Object,
    },
    history: {
        action: string,
        push: Function,
    },
    error: string,
    fetchInProgressImportJobs: Function,
};

type State = {
    hideAppLoader: boolean,
};

class AuthLogin extends React.Component<Props, State> {
    static defaultProps = {
        userSession: {
            loggedIn: false,
            error: '',
        },
    }

    constructor(props) {
        super(props);
        this.state = {
            hideAppLoader: false,
        }
    }

    componentDidMount() {
        const queryParams = queryString.parse(this.props.location.search);
        const { loggedIn } = this.props.userSession;

        if (queryParams && queryParams.apiToken && !loggedIn) {
            this.props.authServiceLoginHandler(queryParams.apiToken);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            loggedIn,
            loggingIn,
            isAccountSuspended,
        } = nextProps.userSession;
        if (!loggingIn && !loggedIn) {
            redirectToAuthService({ isError: true, errorCode: isAccountSuspended ? 'ACCOUNT_SUSPENDED_LOGIN_ERROR': 'UNABLE_TO_LOGIN' });
        }
    }

    render() {
        const showLoader = !(this.props.loggedIn || this.state.hideAppLoader);
        const { loggedIn } = this.props.userSession;

        if (loggedIn) {
            this.props.fetchInProgressImportJobs(this.props.history);
            if (this.props.location && this.props.location.search && this.props.location.search.indexOf('ref=') !== -1) {
                const searchData = queryString.parse(this.props.location.search);
                let refData = searchData.ref || searchData['?ref'];

                if (typeof refData === 'string' && refData.charAt(0) !== '?') {
                    refData = `?${refData}`;
                }
                this.props.history.push(`/reports${refData}`);
                return '';
            }

            return <Redirect to={getDefaultLandingPageURLWithSlash()} />;
        }

        return (
            <>
                {showLoader ? <AppLoader type="fullScreen" /> : null}
            </>
        )
    }
}

const mapStateToProps = state => ({
    userSession: state.userSession,
    error: state.error,
});

const mapDispatchToProps = {
    ...actions,
    ...notificationActions,
};

export default reduxConnect(AuthLogin, mapDispatchToProps, mapStateToProps);
