/* @flow */
import React from 'react';
import { Card, CardHeader, Typography, Tooltip } from '@mui/material';
import GetSvgIcon from '../../../util/svgImage_util';
import { getDashcamColorFromStatus, isDashcamSelected, getDashcamLiveViewColor } from '../../../util/dashcam_util';
import LiveViewCamerasContent from './LiveViewCamerasContent';
import customStyles from './DashcamCard.module.scss';
import { isEmpty } from '../../../helper-classes/utility-functions';
import analytics from '../../../analytics/index';
import {
    DASHCAM_ANALYTICS,
    DASHCAM_LIVE_VIEW,
    DASHCAM_EDIT,
    DASHCAM_EVENT_ICON_CLICK,
} from './../../../containers/DashCam/constants.dashcam';
import { getDeviceSetting } from './../../../containers/DashCam/helper-classes/dashcamServices';

export type Props = {
    dashcam: Object,
    appliedFiltersObj?: Object,
    updateFilters: Function,
    handleRecordingClick: Function,
    trackAnalytics: Function,
    showLoader: Function,
};

const DashcamCard = (props: Props) => {
    const { dashcam, appliedFiltersObj = {} } = props;
    const dashcamIconColor = getDashcamColorFromStatus(dashcam);
    const isSelected = isDashcamSelected(
        dashcam,
        !isEmpty(appliedFiltersObj) ? appliedFiltersObj.selectedDashcamsForLiveView : [],
    );
    const cardHeight = (45 + (isSelected ? (45 * (dashcam.cameras.length)) : 0));
    const recordingIconTop = (17 + (isSelected ? (22 * (dashcam.cameras.length)) : 0));
    const dashcamLiveViewIconColor = getDashcamLiveViewColor(dashcam, isSelected);

    const handleEventsClick = (event: Object) => {
        event.preventDefault();
        event.stopPropagation();
        props.updateFilters('selectedDashcamForEventsView', dashcam, false, false);
        props.trackAnalytics(DASHCAM_EVENT_ICON_CLICK);
    };

    const updateFiltersForLiveView = (
        selectedDashcam: Object,
        inCabinCameraRecordSetting: boolean,
        currentlySelected: boolean,
    ) => {
        props.updateFilters('selectedDashcamsForLiveView', {
            name: selectedDashcam.name || selectedDashcam.imei,
            imei: selectedDashcam.imei,
            cameraIds: [],
            isCabinRecording: inCabinCameraRecordSetting,
        }, false, currentlySelected);
    };

    const handleDashcamLiveViewClick = (
        event: Object,
        selectedDashcam: Object,
        currentlySelected: boolean,
    ) => {
        event.preventDefault();
        event.stopPropagation();
        if ((selectedDashcam.status === 'online') && (selectedDashcam.cameras.length > 0)) {
            analytics.track(
                DASHCAM_ANALYTICS,
                {
                    eventName: DASHCAM_LIVE_VIEW,
                    feature: DASHCAM_ANALYTICS,
                },
            );
            // Call device-config API
            props.showLoader(true);
            getDeviceSetting(selectedDashcam.imei).then((response: any) => {
                props.showLoader(false);
                if (response.apiStatus === 200 && response.data) {
                    updateFiltersForLiveView(
                        selectedDashcam,
                        response.data.inCabinCameraRecording.value,
                        currentlySelected,
                    );
                } else {
                    updateFiltersForLiveView(selectedDashcam, false, currentlySelected);
                }
            });
        }
    };

    const handleInnerLiveViewClick = (
        event: Object,
        camObj: Object,
        selectedCameraID: number,
        currentlySelected: boolean,
        isInCabinRecording: boolean,
    ) => {
        event.preventDefault();
        event.stopPropagation();
        if (selectedCameraID === 2 && !isInCabinRecording) return;
        if ((camObj.status === 'online') && (camObj.cameras.length > 0)) {
            props.updateFilters(
                'selectedDashcamsForLiveView',
                {
                    name: camObj.name || camObj.imei,
                    imei: camObj.imei,
                    isCabinRecording: isInCabinRecording,
                    cameraIds: [selectedCameraID],
                },
                true, currentlySelected,
            );
        }
    };

    const handleEditClick = (event: Object) => {
        event.preventDefault();
        event.stopPropagation();
        props.updateFilters('selectedDashcamsForEditView', dashcam, false, false);
    };

    return (
        <div
            className={customStyles.dashcam_card_holder}
            style={{ height: cardHeight }}
            onKeyDown={() => { }}
            role="button"
            tabIndex={0}
            id={dashcam.imei}
        >
            <Card className={customStyles.dashcamCard}>
                <CardHeader
                    className={customStyles.dashcamCardHeader}
                    onClick={() => {
                        props.handleRecordingClick(dashcam);
                        props.updateFilters('selectedDashcamForRecordingsView', dashcam, false, false);
                    }}
                    avatar={
                        <Tooltip
                            title="Recordings"
                            disableFocusListener
                            className={customStyles.dashcam_recording_icon_container}
                            style={{ top: `-${recordingIconTop}px` }}
                        >
                            <span>
                                <GetSvgIcon
                                    type={dashcam.status === 'offline' ? 'offlineDashcamDark' : 'dashcamIcon'}
                                    fillcolor={dashcamIconColor}
                                    viewBox={dashcam.status === 'offline' ? '0 0 28 8' : '0 0 22 2'}
                                    className={customStyles.dashcam_recording_icon}
                                />
                            </span>
                        </Tooltip>
                    }
                    title={
                        <div style={{ height: cardHeight }}>
                            <button className={customStyles.dashcam_name_btn}>
                                <Tooltip title={dashcam.name || ''} disableFocusListener>
                                    <Typography noWrap className={customStyles.dashcam_name} style={(dashcam.noData) && { paddingTop: 9 }} component="h5" variant="h5">
                                        {dashcam.name || ''}
                                    </Typography>
                                </Tooltip>
                            </button>
                            <div className={customStyles.dashcam_action_btn_container}>
                                <Tooltip title="Events" disableFocusListener>
                                    <span>
                                        <GetSvgIcon
                                            type="dashcamEvents"
                                            data-dashcam-imei={dashcam.imei}
                                            onClick={e =>
                                                handleEventsClick(e)
                                            }
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title="Live View" disableFocusListener>
                                    <span>
                                        <GetSvgIcon
                                            type="dashcamLiveView"
                                            data-dashcam-imei={dashcam.imei}
                                            fillcolor={dashcamLiveViewIconColor}
                                            onClick={(e) => {
                                                handleDashcamLiveViewClick(e, dashcam, isSelected);
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                                <Tooltip title="Edit Dashcam" disableFocusListener>
                                    <span>
                                        <GetSvgIcon
                                            type="dashcamEdit"
                                            data-dashcam-imei={dashcam.imei}
                                            onClick={(e) => {
                                                analytics.track(
                                                    DASHCAM_ANALYTICS,
                                                    {
                                                        eventName: DASHCAM_EDIT,
                                                        feature: DASHCAM_ANALYTICS,
                                                    },
                                                );
                                                handleEditClick(e);
                                            }
                                            }
                                        />
                                    </span>
                                </Tooltip>
                            </div>
                            {isSelected && (dashcam.cameras.length > 0) &&
                                <LiveViewCamerasContent
                                    dashcam={dashcam}
                                    appliedFiltersObj={appliedFiltersObj}
                                    handleInnerLiveViewClick={handleInnerLiveViewClick}
                                />
                            }
                        </div>
                    }
                />
            </Card>
        </div>
    );
};

DashcamCard.defaultProps = {
    appliedFiltersObj: {},
};

export default DashcamCard;
