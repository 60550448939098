/* @flow */
import { FETCH_SCOREDRIVER_LIST_ERROR, FETCH_SCOREDRIVER_LIST_SUCCESS, FETCH_SCOREDRIVER_LIST, RESET_SCOREDRIVER_LIST } from './constants.scoredriverList';

const initialState = {
    scoredriver: [],
    totaldrivercards: 0,
    isUpdating: false,
};

type stateType = {
    scoredriver: Array<Object>,
    totaldrivercards: number,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        response: {
            content:[],
            total: number
        }
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_SCOREDRIVER_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case FETCH_SCOREDRIVER_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            scoredriver: action.payload.response.content,
            totaldrivercards: action.payload.response.total,
        };
    case FETCH_SCOREDRIVER_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    case RESET_SCOREDRIVER_LIST:
        return {
            ...state,
            isUpdating: false,
            scoredriver: [],
            totaldrivercards: 0,
        };
    default:
        return state;
    }
};
