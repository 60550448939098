/* @flow */
import { OverlayView } from 'react-google-maps';
import { OVERLAY_VIEW } from 'react-google-maps/lib/constants';

class CustomOverlayView extends OverlayView {
    componentDidUpdate(prevProp: Object, prevState: Object) {
        if (this.containerElement) super.componentDidUpdate(prevProp, prevState);
    }
    onRemove() {
        if (this.containerElement) super.onRemove();
    }
    show() {
        if (this.containerElement) super.show();
    }
    draw() {
        const mapPanes = this.state[OVERLAY_VIEW].getPanes();
        if (mapPanes && this.containerElement) {
            if (this.props.zIndex) this.containerElement.style.zIndex = this.props.zIndex;
            super.draw();
        }
    }
}

export default CustomOverlayView;
