/* @flow */
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid, Button, AppBar, Tabs, Tab, IconButton } from '@mui/material';
import { headerStyle } from './constants.addEditAsset';
import backArrow from '../../assets/icons/back-arrow-white.svg';
import { getPermissionValue } from '../../helper-classes/utility-functions';
import extStyles from './AddEditAsset.module.scss';

export type Props = {
    classes : Object,
    cancelClick: Function,
    saveClick: Function,
    deleteClick: Function,
    tabClick: Function,
    titleText: string,
    editMode: boolean,
    updateVisible: boolean,
    installationVisible: boolean,
    inputVisible: boolean,
    fuelCardVisible: boolean,
    ELDVisible: boolean,
    safetyVisible: boolean,
    maintenanceVisible: boolean,
    tabValue : string,
};

const styles = () => (headerStyle);

class AddEditHeader extends React.Component<Props> {
    handleTabChange = (e, tabValue) => {
        this.props.tabClick(tabValue);
    };

    renderDeleteButton = classes => (
        <Button className={classes.headerButton} onClick={() => this.props.deleteClick()} color="primary" variant="contained">
            Delete
        </Button>
    )

    render() {
        const {
            classes,
            updateVisible,
            installationVisible,
            inputVisible,
            fuelCardVisible,
            ELDVisible,
            safetyVisible,
            maintenanceVisible,
        } = this.props;

        return (
            <Grid item xs={12} className={classes.header}>
                <Grid container alignItems="center" spacing={2} className={classes.headerTop}>
                    <Grid item xs={6} sx={{ margin: '0px' }}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item style={{ padding: '8px 8px 8px 10px' }}>
                                <IconButton
                                    aria-label="Back"
                                    onClick={() => this.props.cancelClick()}
                                    data-qa="back_btn"
                                    size="large"
                                >
                                    <img src={backArrow} alt="back" />
                                </IconButton>
                            </Grid>
                            <Grid item className={extStyles.gridItemPadding}>
                                <p className={classes.headerText} data-qa="header_title">{this.props.titleText}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    {getPermissionValue('Assets') === 'Modify' &&
                    <Grid item xs={6} className={extStyles.gridItemPadding} >
                        <Grid container alignItems="center" justifyContent="flex-end" style={{ paddingRight: 10 }} data-qa="btn_container">
                            {this.props.editMode ? this.renderDeleteButton(classes) : null}
                            <Button className={classes.headerButton} onClick={() => this.props.saveClick()} color="primary" variant="contained">
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                    }
                </Grid>
                <AppBar position="static" color="default">
                    <Tabs
                        sx={{ '& .MuiTab-root': { maxWidth: 160, fontSize: '0.8125rem', padding: '6px 24px' }, '& .Mui-selected': { color: 'white !Important' } }}
                        value={this.props.tabValue}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        className={classes.tabsTop}
                        style={{ overflow: 'auto' }}
                        data-qa="tab_container"
                    >
                        <Tab value="info" data-qa="info_tab" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="INFO" />
                        <Tab value="updates" data-qa="updates_tab" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="UPDATES" disabled={updateVisible} style={{ display: updateVisible ? 'none' : '' }} />
                        <Tab value="associations" data-qa="associations_tab" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="ASSOCIATIONS" />
                        <Tab value="installation" data-qa="installation_tab" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="INSTALLATION" disabled={installationVisible} style={{ display: installationVisible ? 'none' : '' }} />
                        <Tab value="input" data-qa="input_tab" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="INPUTS" disabled={inputVisible} style={{ display: inputVisible ? 'none' : '' }} />
                        <Tab value="fuel" data-qa="fuel_tab" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="FUEL CARD" disabled={fuelCardVisible} style={{ display: fuelCardVisible ? 'none' : '' }} />
                        <Tab value="compliance" data-qa="compliance_tab" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="COMPLIANCE" disabled={ELDVisible} style={{ display: ELDVisible ? 'none' : '' }} />
                        <Tab value="safety" data-qa="safety_tab" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="SAFETY" disabled={safetyVisible} style={{ display: safetyVisible ? 'none' : '' }} />
                        <Tab value="maintenance" data-qa="maintenance_tab" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="MAINTENANCE" disabled={!maintenanceVisible} style={{ display: !maintenanceVisible ? 'none' : '' }} />
                    </Tabs>
                </AppBar>
            </Grid>
        );
    }
}

export default withStyles(styles)(AddEditHeader);
