/* @flow */

export const FETCH_ALERT_HISTORY: string = 'FETCH_ALERT_HISTORY';
export const FETCH_ALERT_HISTORY_SUCCESS: string = 'FETCH_ALERT_HISTORY_SUCCESS';
export const FETCH_ALERT_HISTORY_ERROR: string = 'FETCH_ALERT_HISTORY_ERROR';
export const RESET_ALERT_HISTORY_LIST: string = 'RESET_ALERT_HISTORY_LIST';
export const ALERT_HISTORY_LIST_PREF_KEY: string = 'fleetLocate.alertHistoryList';
export type AlertHistoryTypeAction = {
    type: string,
    payload?: Object,
};
