/* @flow */

export const FETCH_ADDED_LOOKS: string = 'FETCH_ADDED_LOOKS';
export const FETCH_ADDED_LOOKS_SUCCESS: string = 'FETCH_ADDED_LOOKS_SUCCESS';
export const FETCH_ADDED_LOOKS_ERROR: string = 'FETCH_ADDED_LOOKS_ERROR';
export const FETCH_ADDED_LOOKS_LIST: string = 'FETCH_ADDED_LOOKS_LIST';

export const DELETE_ADDED_LOOKS: string = 'DELETE_ADDED_LOOKS';
export const DELETE_ADDED_LOOKS_SUCCESS: string = 'DELETE_ADDED_LOOKS_SUCCESS';
export const DELETE_ADDED_LOOKS_FAILURE: string = 'DELETE_ADDED_LOOKS_FAILURE';

export const NO_DATA_FOUND: string = 'No Data Found';
export type AddedLooksTypeAction = {
    type: string,
    payload?: Object,
};
