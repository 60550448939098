/* @flow */
import { FETCH_TRIPS_DETAILS, FETCH_TRIPS_DETAILS_SUCCESS, FETCH_TRIPS_DETAILS_ERROR, FETCH_TRIPS_DIRECTION_ERROR, FETCH_TRIPS_DIRECTION, FETCH_TRIPS_DIRECTION_SUCCESS } from './constants.trips';

export const initialState = {
    tripsDetails: [],
    tripsDirection: [],
    isLoader: false,
    tripsData: [],
};

type stateType = {
    tripsDetails: [],
    isLoader: false,
    tripsDirection: [],
}
type actionType = {
    type: string,
    payload :{
        response:{},
        isLoader: boolean,
        tripsDirection: [],
        tripsDetails: [],
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_TRIPS_DETAILS_SUCCESS:
        return {
            ...state,
            tripsDetails: action.payload.response,
            isLoader: false,
        };
    case FETCH_TRIPS_DETAILS_ERROR:
        return {
            ...state,
            isLoader: false,
        };
    case FETCH_TRIPS_DETAILS:
        return {
            ...state,
            isLoader: true,
        };
    case FETCH_TRIPS_DIRECTION_SUCCESS:
        return {
            ...state,
            tripsDirection: action.payload.response,
            isLoader: false,
        };
    case FETCH_TRIPS_DIRECTION_ERROR:
        return {
            ...state,
            isLoader: false,
        };
    case FETCH_TRIPS_DIRECTION:
        return {
            ...state,
            isLoader: true,
        };
    default:
        return state;
    }
};
