import React from 'react';
import Grid from '@mui/material/Grid';
import customstyles from './AlertLandingPage.module.scss';
import AlertType from './AlertType';

type Props = {
    data: {
        alertCategory: String,
        alertCategoryID: String,
        alertTypes: Object,
    },
};

function filterAlertTypes(props:Props) {
    return props.data.alertTypes.map(el => <AlertType data={el} key={el.code} />);
}

const AlertCategory = (props:Props) => (
    <div>
        <div id={props.data.alertCategoryID} className={customstyles['alert-catname']}>
            {props.data.alertCategory}
        </div>
        <Grid container spacing={3} className={customstyles.gridContainer}>
            {filterAlertTypes(props)}
        </Grid>
    </div>
);

export default AlertCategory;
