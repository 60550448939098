/* @flow */
import React, { Component } from 'react';
import {
    Button,
    Dialog,
    TableSortLabel,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
} from '@mui/material';
import styles from './LandmarkGroupManageStyles';
import AppLoader from '../AppLoader';
import customStyles from './LandmarkGroupManage.module.scss';
import { formatLandmarkAddress } from './../../helper-classes/utility-functions';
import { MIN_SEARCH_LENGTH } from './../../constants/Config';

type Props = {
    selectedLandmarks: Array<{}>,
    onLandmarksSelected: Function,
    landmarks: Array<{}>,
    totalLandmarks: number,
    loadLandmarks: Function,
    isLandmarksListUpdating: boolean,
    addBulkLandmarks: Function,
    isFetchingLandmarkGroupDetails: boolean,
};
type State = {
    tableData: {
        listData: Array<{}>,
        totalRecords: number,
    },
    search: string,
    allSelected: boolean,
    openDialog: boolean,
    orderBy: string,
    order: string;
};

class LandmarkGroupList extends Component<Props, State> {
    limit: number;
    queryConfig: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            tableData: {
                listData: [],
                totalRecords: 0,
            },
            search: '',
            allSelected: false,
            openDialog: false,
            orderBy: 'name',
            order: 'asc',
        };
        this.limit = 50;
    }

    componentDidMount() {
        this.loadNextPage(this.queryConfig, false);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Object) {
        const tableData = {
            listData: nextProps.landmarks,
            totalRecords: nextProps.totalLandmarks,
            selectedLandmarks: nextProps.selectedLandmarks,
        };
        this.setState({ tableData });
    }

    loadNextPage = (query: string, resetPage: boolean) => {
        const { tableData, search } = this.state;
        let listData = tableData.listData ? tableData.listData : [];
        let offset = 0;

        if ((query === search || !query) && !resetPage) {
            offset = listData.length;
        } else {
            listData = [];
        }

        const params = {
            selectedLandmarks: [],
            search: this.state.search,
            sort: {
                orderBy: this.state.orderBy,
                order: this.state.order,
            },
            clearResult: false,
        };
        this.props.loadLandmarks(offset, this.limit, params);
    };

    isSelected = (id: string) => {
        let selected = false;
        const { selectedLandmarks } = this.props;
        if (selectedLandmarks) {
            selectedLandmarks.forEach((landmark: Object) => {
                if (landmark.id === id) {
                    selected = true;
                }
            });
        }
        return selected;
    }

    onTableScroll = (e: any) => {
        const { tableData } = this.state;

        if (e.target.scrollTop / e.target.scrollHeight > 0.7) {
            if (tableData.listData.length % this.limit === 0
                && !this.props.isLandmarksListUpdating && this.canLoadNextPage()) {
                this.loadNextPage(this.queryConfig, false);
            }
        }
    }

    headersArray = () => ['Name', 'Address']

    listHeaders = () => [
        { landmarkName: this.headersArray()[0], show: true },
        { serialNumber: this.headersArray()[1], show: true },
    ]

    handleSearchChange = (e: any) => {
        const search = (e != null && e.target != null) ? e.target.value : e;
        this.setState({ search });
        const params = {
            selectedLandmarks: [],
            search,
            sort: {
                orderBy: this.state.orderBy,
                order: this.state.order,
            },
            clearResult: true,
        };

        if (search.length >= MIN_SEARCH_LENGTH || search.length === 0) {
            this.props.loadLandmarks(0, this.limit, params);
        }
    }

    onSelectAllClicked = () => this.setState({ openDialog: true });

    handleClose = () => this.setState({ openDialog: false });

    confirmSelectAll = () => {
        this.props.addBulkLandmarks(this.state.tableData.listData);
        this.setState({ openDialog: false });
    }

    canLoadNextPage = () => {
        const { tableData } = this.state;
        const totalLoaded = tableData.listData ? tableData.listData.length : 0;
        const totalAvailable = tableData.totalRecords || 0;
        return totalAvailable > totalLoaded || totalLoaded === 0;
    }

    getLoadingIndicator = () => (this.canLoadNextPage() ? <AppLoader loaderStyle={styles.loader} height={30} width={3} /> : '')

    handleItemSelect = (row: Object, index: number) => (event: any) => {
        this.props.onLandmarksSelected(event.target.checked, row, index);
    }

    createSortHandler = (item: string) => {
        const order = (this.state.order === 'asc') ? 'desc' : 'asc';
        this.setState({
            orderBy: item,
            order,
        });

        const params = {
            selectedLandmarks: [],
            search: this.state.search,
            sort: {
                orderBy: item,
                order,
            },
            clearResult: true,
        };
        this.props.loadLandmarks(0, this.limit, params);
    }

    render() {
        const {
            allSelected,
            tableData,
            order,
            orderBy,
        } = this.state;
        const totalLoaded = tableData.listData ? tableData.listData.length : '0';
        const totalAvailable = tableData.totalRecords || '0';
        return (
            <div className={customStyles['available-container']}>
                <div style={styles.landmarksHeader}>
                    <span className={customStyles['available-heading-content']} >Available Landmarks </span>
                    <span className={customStyles['landmark-search-wrapper']}>
                        <input
                            type="text"
                            name="search"
                            className={customStyles['landmark-search-box']}
                            autoComplete="off"
                            onChange={this.handleSearchChange}
                        />
                    </span>
                </div>
                <div className={customStyles['available-page-info']}>Showing {totalLoaded} of {totalAvailable} </div>
                <div style={{ flex: 1 }}>
                    <div style={styles.tableWrapper} onScroll={this.onTableScroll} >
                        <table>
                            <thead>
                                <tr className={customStyles['table-tr']}>
                                    <th className={customStyles['table-th']}>
                                        <Checkbox
                                            checked={allSelected}
                                            color="primary"
                                            onChange={
                                                tableData && tableData.listData &&
                                                tableData.listData.length > 0 ?
                                                    this.onSelectAllClicked
                                                    :
                                                    () => {}
                                            }
                                            style={{ padding: 0 }}
                                        />
                                    </th>
                                    {this.headersArray().map(header => (
                                        <th className={customStyles['table-th']} key={header}>
                                            <span className={customStyles['available-table-header']}>
                                                <TableSortLabel
                                                    active={orderBy === header}
                                                    direction={order}
                                                    onClick={() => {
                                                        this.createSortHandler(header);
                                                    }}
                                                >
                                                    {header}
                                                </TableSortLabel>
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.listData ?
                                    tableData.listData.map((row: Object, index: number) =>
                                        (
                                            <tr className={customStyles['table-tr']} key={row.id}>
                                                <td className={customStyles['checkbox-td']} >
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={this.handleItemSelect(row, index)}
                                                        checked={this.isSelected(row.id)}
                                                        style={{ padding: 0 }}
                                                    />
                                                </td>
                                                <td>{row.name}</td>
                                                <td>{formatLandmarkAddress(row)}</td>
                                            </tr>))
                                    : null
                                }
                            </tbody>
                        </table>
                        {this.props.isLandmarksListUpdating &&
                        !this.props.isFetchingLandmarkGroupDetails
                            ? this.getLoadingIndicator() : null}
                    </div>
                </div>
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{ fontSize: '16px' }}>
                            Are you sure you want to add all landmarks?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ p: 1.25 }}>
                        <Button onClick={this.handleClose} color="primary">
                            No
                        </Button>
                        <Button onClick={this.confirmSelectAll} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default LandmarkGroupList;
