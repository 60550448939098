/* flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import MiniDrawer from '../../SideMenu/SideMenuDrawer';
import LookLandingPageList from '../../../containers/Looks/LookLandingPage/LookLandingPageList';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

type Props = {
    history: {
        push: Function,
    },
};

class LookLandingPage extends Component<Props> {
    state = {};

    handleBackAction = () => {
        this.props.history.push('./configure-dashboard');
    };

    render() {
        return (
            <MiniDrawer redirectTo={this.props.history.push}>
                <div>
                    <LookLandingPageList backAction={this.handleBackAction} />
                </div>
            </MiniDrawer>
        );
    }
}

export default withStyles(styles)(LookLandingPage);
