/* @flow */
import {
    FETCH_RECIPIENT_LIST,
    FETCH_RECIPIENT_LIST_SUCCESS,
    FETCH_RECIPIENT_LIST_ERROR,
    POST_RECIPIENT_ALERT_DATA_SUCCESS,
    POST_RECIPIENT_ALERT_DATA_ERROR,
    CLEAR_REDUCER,
} from './constants.alertRecipient';

const initialState = {
    recipientList: [],
    saveRecipientData: [],
    isLoading: false,
    isAlertSaved: false,
};

type stateType = {
    recipientList: Array<Object>,
    saveRecipientData: Array<Object>,
    isLoading: boolean,
    isAlertSaved: boolean,
};

type actionType = {
    type: string,
    payload: {
        recipientList: {},
        saveRecipientData: {},
    },
};

export default (state: stateType = initialState, action: actionType) => {
    switch (action.type) {
    case FETCH_RECIPIENT_LIST:
        return {
            ...state,
            isLoading: true,
        };
    case FETCH_RECIPIENT_LIST_SUCCESS:
        return {
            ...state,
            isLoading: false,
            recipientList: action.payload.recipientList,
        };
    case FETCH_RECIPIENT_LIST_ERROR:
        return {
            ...state,
        };
    case POST_RECIPIENT_ALERT_DATA_SUCCESS:
        return {
            ...state,
            isLoading: false,
            isAlertSaved: true,
            saveRecipientData: action.payload,
        };
    case POST_RECIPIENT_ALERT_DATA_ERROR:
        return {
            ...state,
        };
    case CLEAR_REDUCER:
        return {
            ...state,
            isAlertSaved: false,
        };
    default:
        return state;
    }
};
