/* @flow */

import config, { FVS_MAX_DATA_LIMIT, LANDMARK_GROUP_DB_ID_MAX_DATA_LIMIT } from './../../constants/Config';
import { getGrailsData, postData, postGrailsData } from './../datasource';
import { flattenGroups } from './../assets/assetGroup';
import { getGrailsAppBasedURL } from '../../helper-classes/utility-functions';

export function getLandmarkGroupsFVS() {
    const url = `${config.get('FLEET_VIEW_SERVICES_URL')}/landmarkgroups`;
    const body = {
        limit: FVS_MAX_DATA_LIMIT,
        sortParam: [['name', 'asc']],
        start: 0,
        type: 'parentChildList',
    };
    return postData(url, body)
        .then(response => response.json()).then((response) => {
            const groups = flattenGroups(response.data);
            return { data: groups, total: groups.length };
        });
}

export const getLandmarkGroupById = (globalId: any) => {
    const url = `${getGrailsAppBasedURL()}/operation/json/localFleetLandmarkGroupService/getLandmarkGroups?globalId=${globalId}`;
    return getGrailsData(url)
        .then(response => response.json()).then(response => ({ data: response.data || {} }));
};

const series = async (data) => {
    let results = [];
    let isError = false;
    const url = `${getGrailsAppBasedURL()}/operation/json/localFleetLandmarkService/postLandmarks`;
    for (let i = 0; i <= data.length - 1; i += 1) {
        try {
            // eslint-disable-next-line no-await-in-loop
            const result = await postGrailsData(url, { globalIds: data[i] });
            const res = result.json();
            // eslint-disable-next-line no-await-in-loop
            const landmarkGroupsData = await res;
            const ids = landmarkGroupsData.landmarks.map(d => d.id);
            results.push(...ids);
        } catch (error) {
            results = [];
            isError = true;
            break;
        }
    }
    return { data: results, isError };
};

export const getSelectedLandmarkDbId = (globalIds: Array<string>) => {
    const result = new Array(Math.ceil(globalIds.length / LANDMARK_GROUP_DB_ID_MAX_DATA_LIMIT))
        .fill()
        .map(() => globalIds.splice(0, LANDMARK_GROUP_DB_ID_MAX_DATA_LIMIT));
    return series(result);
};
