/* @flow */
import { FETCH_ASSETS_GROUP_LIST_ERROR, FETCH_ASSETS_GROUP_LIST_SUCCESS, FETCH_ASSETS_GROUP_LIST, RESET_ASSETS_GROUP_LIST } from './constants.assetGroupList';

const initialState = {
    assetGroups: [],
    isUpdating: false,
};

type stateType = {
    assetGroups: Array<Object>,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        assetGroups:{},
        totalAssetsGroups: number
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_ASSETS_GROUP_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case FETCH_ASSETS_GROUP_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            assetGroups: action.payload.assetGroups,
            totalAssetsGroups: action.payload.totalAssetsGroups,
        };
    case FETCH_ASSETS_GROUP_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    case RESET_ASSETS_GROUP_LIST:
        return {
            ...state,
            isUpdating: false,
            assetGroups: [],
            totalAssetsGroups: 0,
        };
    default:
        return state;
    }
};
