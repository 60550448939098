/* @flow */
import React, { Component } from 'react';
import { ArrowBack } from '@mui/icons-material';
import {
    Paper,
    Typography,
    Toolbar,
    IconButton,
    TextField,
    Button,
} from '@mui/material';
import customStyles from './EditDashcam.module.scss';
import { getDeviceTelemetry, createAssetDashcam, deleteDashCam, getAssets, getAssetByDashcamIdentifier } from './../../../containers/DashCam/helper-classes/dashcamServices';
import { convertDateTime, getTimeFormatAsperUserSettting, removeMultipleSpacesBetweenChars } from './../../../helper-classes/utility-functions';
import { Setting, Delete } from './../../../util/svgImage_util';
import { formatBytes } from './../../../util/dashcam_util';
import DashcamSetting from './EditDashcamSetting';
import Dialog from './../../../components/SharedComponents/Dialog/Dialog';
import AsyncSelectWrapper from './../../../components/SharedComponents/AsyncSelectWrapper';
import analytics from '../../../analytics/index';
import { DASHCAM_ANALYTICS, DELETE_DASHCAM, DASHCAM_SETTING } from '../../../containers/DashCam/constants.dashcam';

export type Props = {
    selectedDevice: Object,
    showNotification: Function,
    updateDevice: Function,
    deleteDashcam: Function,
    historyBack: Function,
    updateLoader: Function,
};

export type State = {
    dashcam: Array<Object>,
    showSetting: boolean,
    isError: boolean,
    showDialog: boolean,
    checkedDeviceTelemetry: boolean,
    isDeviceTelemetry: boolean,
    dashcamStatus: string,
    selectedAsset: any,
    showSelectWrapper: boolean,
};

const defaultDataArrayParam = 'content';
const defaultDataCountParam = 'count';
const defaultDataTotalParam = 'total';
const defaultDatumLabelParam = 'name';
const defaultDatumValueParam = 'id';
const defaultPerPage = 25;

class EditDashcam extends Component<Props, State> {
    constructor(props: Object) {
        super(props);
        this.state = {
            dashcam: this.initDashcamState(this.props.selectedDevice),
            showSetting: false,
            isError: false,
            showDialog: false,
            checkedDeviceTelemetry: false,
            isDeviceTelemetry: false,
            dashcamStatus: this.props.selectedDevice.status,
            selectedAsset: null,
            showSelectWrapper: true,
        };
    }

    componentDidMount() {
        this.getAsset(this.props.selectedDevice.imei);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.props.selectedDevice &&
            this.props.selectedDevice.imei &&
            nextProps.selectedDevice &&
            (this.props.selectedDevice.imei !== nextProps.selectedDevice.imei)) {
            this.getAsset(nextProps.selectedDevice.imei);
            this.setState({
                dashcam: this.initDashcamState(nextProps.selectedDevice),
                showSetting: false,
                dashcamStatus: nextProps.selectedDevice.status,
            });
        }
    }

    initDashcamState = (selectedDevice: Object) => ([
        {
            key: 'name',
            value: selectedDevice.name,
            label: 'Name',
            disabled: true,
            show: true,
        },
        {
            key: 'imei',
            value: selectedDevice.imei,
            label: 'IMEI',
            disabled: true,
            show: true,
        },
        {
            key: 'firmwareVersion',
            value: '',
            label: 'Firmware',
            disabled: true,
            show: false,
        },
        {
            key: 'deviceModel',
            value: '',
            label: 'Model',
            disabled: true,
            show: false,
        },
        {
            key: 'lastSeenOnline',
            value: '',
            label: 'Last Online',
            disabled: true,
            show: false,
        },
        {
            key: 'mobileConnection',
            value: '',
            label: 'Mobile Connection',
            disabled: true,
            show: false,
        },
        {
            key: 'calibrationCompleted',
            value: '',
            label: 'Calibration',
            disabled: true,
            show: false,
        },
        {
            key: 'sdCardCapacityBytes',
            value: '',
            label: 'SD Card',
            disabled: true,
            show: false,
        },
    ])

    showLoader = (showLoader: boolean) => this.props.updateLoader(showLoader);

    showDialog = (showDialog: boolean) => this.setState({ showDialog });

    getDeviceTelemetry = (imei: string) => {
        if (this.state.dashcamStatus === 'offline') {
            this.showLoader(false);
            this.setState({ checkedDeviceTelemetry: true });
            return;
        }

        getDeviceTelemetry({ imei }).then((response: any) => {
            this.showLoader(false);
            this.setState({ checkedDeviceTelemetry: true });
            if (response.status === 200 && response.data) {
                if (imei === this.props.selectedDevice.imei) {
                    this.updateDashcamFields(response.data);
                }
            }
        });
    }

    getAsset = (dashcamIdentifier: string) => {
        this.showLoader(true);
        getAssetByDashcamIdentifier(dashcamIdentifier).then((res) => {
            this.getDeviceTelemetry(dashcamIdentifier);
            this.setState({ showSelectWrapper: res.status === 404 });
        });
    }

    updateDashcamFields = (data: Object) => {
        let { dashcam } = this.state;
        dashcam = dashcam.map((d) => {
            const newData = d;

            switch (newData.key) {
            case 'mobileConnection': newData.value = `${data.simOperator} ${data.simNetworkType}`;
                newData.show = true;
                break;
            case 'calibrationCompleted': newData.value = data[d.key] ? 'Yes' : 'No';
                newData.show = true;
                break;
            case 'lastSeenOnline': newData.value = convertDateTime(data.lastSeenOnline, getTimeFormatAsperUserSettting() === '12h'
                ? 'MMM DD, YYYY h:mm A' : 'MMM DD, YYYY HH:mm');
                newData.show = true;
                break;
            case 'firmwareVersion': newData.value = data.firmwareVersion;
                newData.show = true;
                break;
            case 'sdCardCapacityBytes': newData.value = `${formatBytes(data.sdCardCapacityBytes)} GB Total / ${formatBytes(data.sdCardFreeBytes)} Free Space`;
                newData.show = true;
                break;
            case 'deviceModel': newData.value = data.deviceModel;
                newData.show = true;
                break;
            default: break;
            }

            return newData;
        });
        this.setState({ dashcam, isDeviceTelemetry: true });
    }

    handleChange = (value: string) => {
        let { dashcam } = this.state;
        const newValue = value ? removeMultipleSpacesBetweenChars(value) : '';
        if (newValue) this.setState({ isError: false });

        dashcam = dashcam.map((d) => {
            const newData = d;
            if (d.key === 'name') newData.value = newValue;
            return newData;
        });

        this.setState({ dashcam });
    }

    updateDashcam = () => {
        const { selectedAsset } = this.state;

        if (!selectedAsset) {
            this.setState({ isError: true });
            return;
        }
        let dashcamName = selectedAsset.label;
        dashcamName = dashcamName ? dashcamName.trim() : '';

        this.setState({ isError: false });
        const assetId = selectedAsset.value ? selectedAsset.value : '';
        const requestData = {
            dashcamIdentifier: this.props.selectedDevice.imei,
            name: dashcamName,
            assetId,
        };
        this.showLoader(true);
        createAssetDashcam(requestData).then((response: any) => {
            this.showLoader(false);

            if (response.status === 200 || response.status === 201) {
                this.props.showNotification('Device updated successfully.');
                this.props.updateDevice({ ...requestData, imei: this.props.selectedDevice.imei });
                this.props.historyBack(true);
            } else this.props.showNotification(response.message);
        });
    }

    deleteDashcam = () => {
        this.showDialog(false);
        const requestData = { imei: this.props.selectedDevice.imei };
        this.showLoader(true);
        analytics.track(
            DASHCAM_ANALYTICS,
            {
                eventName: DELETE_DASHCAM,
                feature: DASHCAM_ANALYTICS,
            },
        );
        deleteDashCam(requestData).then((response: any) => {
            this.showLoader(false);

            if (response.status === 200) {
                this.props.showNotification('Dashcam device deleted successfully.');
                this.props.deleteDashcam(requestData);
                this.props.historyBack();
            } else this.props.showNotification(response.message);
        });
    }

    showSetting = () => {
        this.setState({ showSetting: true }, () => {
            analytics.track(
                DASHCAM_ANALYTICS,
                {
                    eventName: DASHCAM_SETTING,
                    feature: DASHCAM_ANALYTICS,
                },
            );
        });
    }

    showDashcam = () => this.setState({ showSetting: false });

    filterOptions = (filter: any, inputValue: any, loadedOptions: any) => (
        new Promise((resolve, reject) => {
            const perPage = filter.perPage || defaultPerPage;
            filter.filterOptions(loadedOptions.length, perPage, inputValue).then((response) => {
                const count = response[filter.dataCountParam || defaultDataCountParam];
                const total = response[filter.dataTotalParam || defaultDataTotalParam];
                const hasMore = loadedOptions.length + count < total;

                if (response) {
                    const responseData = response[filter.dataArrayParam || defaultDataArrayParam];
                    if (responseData && Array.isArray(responseData)) {
                        const optionData = responseData.map(datum => ({
                            value: datum[filter.datumValueParam || defaultDatumValueParam],
                            label: datum[filter.datumLabelParam || defaultDatumLabelParam],
                            data: datum,
                        }));
                        resolve({
                            options: optionData,
                            hasMore,
                        });
                    } else {
                        reject();
                    }
                } else {
                    reject();
                }
            });
        }));

    render() {
        const assetFilter = {
            filterOptions: getAssets,
            selectedOptions: [],
            filterType: 'select',
            filterTitle: 'Asset',
            selectPlaceholder: 'All Assets',
            property: 'assetId',
            dataArrayParam: 'data',
            datumValueParam: 'id',
            datumLabelParam: 'name',
            dataCountParam: 'count',
            propertyType: 'string',
            disableSearch: false,
        };
        const selectStyles = {
            control: () => ({
                display: 'flex',
                'border-bottom': '1px solid #d3d7db',
                boxShadow: 'none !important',
            }),
            valueContainer: styles => ({ ...styles, paddingLeft: '0px' }),
        };
        const { showSelectWrapper } = this.state;
        return (
            <div className={customStyles.mainContainer}>
                {this.state.showSetting ?
                    <DashcamSetting
                        imei={this.props.selectedDevice.imei}
                        dashcamName={this.props.selectedDevice.name}
                        showNotification={this.props.showNotification}
                        showDashcam={this.showDashcam}
                        showLoader={this.showLoader}
                    /> :
                    <Paper className={customStyles.paper}>
                        <Toolbar className={customStyles.toolbar}>
                            <span className={customStyles.backButton}>
                                <IconButton onClick={() => this.props.historyBack()} size="large">
                                    <ArrowBack />
                                </IconButton>
                            </span>
                            <div className={customStyles.editTitle}>
                                <Typography variant="h6" color="inherit" className={customStyles.title}>
                                    <span>Edit Dashcam</span>
                                </Typography>
                                <div>
                                    <IconButton
                                        className={customStyles.iconButton}
                                        onClick={() => this.showSetting()}
                                        size="large"
                                    >
                                        <Setting fillcolor="#007aff" className={customStyles.icon} />
                                    </IconButton>
                                    <IconButton
                                        disabled={!showSelectWrapper}
                                        onClick={() => this.showDialog(true)}
                                        className={!showSelectWrapper ? customStyles.disabled : ''}
                                        size="large"
                                    >
                                        <Delete fillColor={!showSelectWrapper ? 'rgb(229, 229, 229)' : ''} className={customStyles.icon} />
                                    </IconButton>
                                </div>
                            </div>
                        </Toolbar>
                        {this.state.checkedDeviceTelemetry &&
                            <div className={customStyles.textWrap}>
                                {this.state.dashcam.map((d, index) => (d.show ? (
                                    <div key={d.key} className={index !== 0 ? customStyles.text : ''}>
                                        {index === 0 && showSelectWrapper ?
                                            <div>
                                                <div
                                                    className={customStyles.name}
                                                >
                                                    <span className={customStyles.fsize}>
                                                        Name*
                                                    </span>
                                                </div>
                                                <AsyncSelectWrapper
                                                    loadOptions={(inputValue, loadedOptions) =>
                                                        this.filterOptions(
                                                            assetFilter,
                                                            inputValue,
                                                            loadedOptions,
                                                        )
                                                    }
                                                    onItemSelected={(selected) => {
                                                        this.setState({ selectedAsset: selected });
                                                    }}
                                                    selectedOptions={this.state.selectedAsset}
                                                    title="Select Asset"
                                                    disableChips
                                                    showSelection
                                                    hideDivider
                                                    selectStyles={selectStyles}
                                                    styles={{ container: { flex: 1, width: 327 } }}
                                                />
                                            </div> :
                                            <TextField
                                                error={(d.key === 'name' && this.state.isError)}
                                                label={d.key === 'name' ? `${d.label}*` : d.label}
                                                disabled={d.disabled}
                                                className={customStyles.textField}
                                                name={d.key}
                                                value={d.value || ''}
                                                onChange={e => this.handleChange(e.target.value)}
                                                margin="none"
                                                autoComplete="none"
                                                helperText={(d.key === 'name' && this.state.isError) && <span className={customStyles.error}>this field is required</span>}
                                            />
                                        }
                                    </div>) : null))
                                }
                                <div className={customStyles.buttonContainer} style={this.state.isDeviceTelemetry ? {} : { padding: '100px 40px 20px' }}>
                                    <Button
                                        className={`${customStyles.button} ${customStyles.cancelButton}`}
                                        onClick={() => this.props.historyBack()}
                                    >
                                        CANCEL
                                    </Button>
                                    <Button
                                        className={customStyles.button}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.updateDashcam()}
                                    >
                                        SAVE
                                    </Button>
                                </div>
                            </div>
                        }
                        <Dialog
                            open={this.state.showDialog}
                            type="confirm"
                            button={[
                                {
                                    title: 'Cancel',
                                    color: 'primary',
                                    handleClick: () => this.showDialog(false),
                                },
                                {
                                    title: 'OK',
                                    color: 'primary',
                                    variant: 'contained',
                                    handleClick: () => this.deleteDashcam(),
                                },
                            ]}
                            content="Are you sure you want to delete this device?"
                            size="lg"
                        />
                    </Paper>
                }
            </div>
        );
    }
}

export default EditDashcam;
