/* @flow */

export const FETCH_SCOREVEHICLE_LIST: string = 'FETCH_SCOREVEHICLE_LIST';
export const FETCH_SCOREVEHICLE_LIST_SUCCESS: string = 'FETCH_SCOREVEHICLE_LIST_SUCCESS';
export const FETCH_SCOREVEHICLE_LIST_ERROR: string = 'FETCH_SCOREVEHICLE_LIST_ERROR';
export const RESET_SCOREVEHICLE_LIST: string = 'RESET_SCOREVEHICLE_LIST';
export const VEHICLE_CENTRIC_SCORECARD_PREF_KEY: string = 'fleetLocate.vehicleCentricScorecard';
export const DRIVER_VEHICLE_CENTRIC_SCORECARD_PREF_KEY: string = 'fleetLocate.driverVehicleCentricScorecard';

export type ScoreVehicleListTypeAction = {
    type: string;
    payload?: Object;
}
