import React, { Component } from 'react';
import { reduxConnect } from '../../../hoc';
import * as actions from '../AlertLandingPage/actions.landingPage';
import { filterAlerts } from '../../../util/mapAlertTypes';
import StepOneAlertSelect from '../../../components/Alerts/Steppers/StepOneAlertSelect';

type Props = {
    fetchAlertNameList: Function,
    alertNames: any,
    history: any,
    alertType: String,
    formData: any,
    onAlertNameChange: Function,
    handleCheckboxChange: Function,
    handleTextboxChange: Function,
    handleAlertTypeChange: Function,
    setAlertId: Function,
    setAlertType: Function,
    alertNameError: String,
    validationError: String,
    clearLandmarkSelects: Function,
    alertNameCheckInProgress:any,
};

class AlertSelect extends Component<Props> {
    state = {
        newAlertType: '',
    };
    componentDidMount() {
        setTimeout(() => {
            this.props.fetchAlertNameList();
        }, 1000);
    }

    onAlertSelect = (event) => {
        this.setState({
            newAlertType: event.target.value,
        });
        this.props.history.push(`?alertType=${event.target.value}`);
    };
    render() {
        const alertNames = filterAlerts(this.props.alertNames.data);
        return (
            <StepOneAlertSelect
                alertNames={alertNames}
                onAlertSelect={this.onAlertSelect}
                newAlertType={this.state.newAlertType}
                alertType={this.props.alertType}
                formData={this.props.formData}
                onAlertNameChange={this.props.onAlertNameChange}
                handleCheckboxChange={this.props.handleCheckboxChange}
                handleTextboxChange={this.props.handleTextboxChange}
                handleAlertTypeChange={this.props.handleAlertTypeChange}
                setAlertId={this.props.setAlertId}
                setAlertType={this.props.setAlertType}
                alertNameCheckInProgress={this.props.alertNameCheckInProgress}
                alertNameError={this.props.alertNameError}
                validationError={this.props.validationError}
                clearLandmarkSelects={this.props.clearLandmarkSelects}
            />
        );
    }
}

const mapStateToProps = state => ({
    alertNames: state.alertNames.alertNames,
});
export default reduxConnect(AlertSelect, actions, mapStateToProps);
