/* @flow */

export const FETCH_SCORECARDS: string = 'FETCH_SCORECARDS';
export const FETCH_SCORECARDS_SUCCESS: string = 'FETCH_SCORECARDS_SUCCESS';
export const FETCH_SCORECARDS_ERROR: string = 'FETCH_SCORECARDS_ERROR';
export const FETCH_LANDMARKGROUPS: string = 'FETCH_LANDMARKGROUPS';
export const FETCH_LANDMARKGROUPS_SUCCESS: string = 'FETCH_LANDMARKGROUPS_SUCCESS';
export const FETCH_LANDMARKGROUPS_ERROR: string = 'FETCH_LANDMARKGROUPS_ERROR';
export const FETCH_CLUSTERPOINTS: string = 'FETCH_CLUSTERPOINTS';
export const FETCH_CLUSTERPOINTS_SUCCESS: string = 'FETCH_CLUSTERPOINTS_SUCCESS';
export const FETCH_CLUSTERPOINTS_ERROR: string = 'FETCH_CLUSTERPOINTS_ERROR';
export const GET_CLUSTER_EXPLODE_SUCCESS: string = 'GET_CLUSTER_EXPLODE_SUCCESS';
export const KML_MAP_PREF_KEY: string = 'fleetLocate.mapKml';
export const MAP_ZOOM_AND_CENTER_SESSION_NAME: string = 'map_zoom_and_bound_details';
export const LM_MAP_ZOOM_AND_CENTER_MODIFIED: string = 'lm_address_or_lat_lng_change';

export type HomeTypeAction = {
    type: string;
    payload?: Object;
}
