/* @flow */
import React, { Component } from 'react';
import { Checkbox, FormControlLabel, Button, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import MapsWrapper from '../../containers/MapsWrapper';
import assetStyles from '../../containers/Assets/Assets.module.scss';
import EventsList from '../Events/EventsList';
import style from './PrintEventsList.module.scss';
import { getUserPreference } from '../../helper-classes/utility-functions';
import { TRIP_EVENTS_PREF_KEY } from '../../containers/Assets/constants.assets';
import getListHeader from '../Events/EventsListUtil';

const styles = () => ({
    textContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
        maxWidth: 75,
    },
    satIcon: {
        width: 12,
        height: 16,
        marginBottom: -4,
    },
    tirePressure: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export type Props = {
    classes: Object,
    coordinates: any,
    markerOptions: Object,
    zoomEvent: Object,
    events: Array<Object>,
    options: Object,
    isLoader: Boolean,
    assetFeatures: Object,
    deviceModelCode: string,
}

export type State = {
    showMap: boolean,
    showEventsList: boolean,
    listHeaders: Array<Object>,
}

class PrintEventsList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showMap: true,
            showEventsList: true,
            listHeaders: [],
        };
    }

    componentDidMount() {
        this.setTripEventsPreference();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.assetFeatures && this.props.assetFeatures !== nextProps.assetFeatures) {
            this.setTripEventsPreference();
        }
    }

    setTripEventsPreference = () => {
        getUserPreference(TRIP_EVENTS_PREF_KEY).then((response) => {
            const userPreference = response && response.value ? response.value : null;
            const listHeaders = getListHeader(
                { value: userPreference },
                this.props.classes,
                this.props.assetFeatures,
                this.props.deviceModelCode,
            );
            this.setState({ listHeaders });
        });
    };

    handleChange = (name: string) => (event: any) => {
        this.setState({ ...this.state, [name]: event.target.checked });
    };

    render() {
        return (
            <div className={style.screen}>
                <div className={style.menu}>
                    Print Options
                    <div>
                        <FormControlLabel
                            control={<Checkbox
                                checked={this.state.showMap}
                                onChange={this.handleChange('showMap')}
                                value="showMap"
                                color="primary"
                            />}
                            label="Include Map *"
                        />
                    </div>
                    <div>
                        <FormControlLabel
                            control={<Checkbox
                                checked={this.state.showEventsList}
                                onChange={this.handleChange('showEventsList')}
                                value="showEventsList"
                                color="primary"
                            />}
                            label="Include Event History *"
                        />
                    </div>
                    <div className={style.button}>
                        <Button
                            onClick={() => window.print()}
                            variant="contained"
                            color="primary"
                            style={{ marginRight: '20px' }}
                        >
                            Print
                        </Button>
                        <Typography style={{ lineHeight: '40px' }}>
                            * In order to print all images and icons,
                            enable background images in your print dialog
                        </Typography>
                    </div>
                </div>
                {
                    this.state.showMap &&
                    <div key="print-events-map-container" className={`${assetStyles.mapWrapper} ${style.map}`} style={{ width: '1000px', marginBottom: '10px' }}>
                        <MapsWrapper
                            key="print-events-map"
                            coordinates={this.props.coordinates}
                            markers={this.props.markerOptions}
                            options={this.props.options}
                            height="400px"
                        />
                    </div>
                }
                {
                    this.state.showEventsList &&
                    <div>
                        <Typography variant="h6">
                            Detail View
                        </Typography>
                        <EventsList
                            events={this.props.events}
                            zoomEvent={this.props.zoomEvent}
                            isPrint
                            hidePagination
                            listHeaders={this.state.listHeaders}
                            isLoader={this.props.isLoader}
                            totalEventRows={this.props.events ? this.props.events.length : 0}
                        />
                    </div>
                }
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(PrintEventsList);
