import React from 'react';
import { renderToString } from 'react-dom/server';
import Fab from '@mui/material/Fab';
import { compose, defaultProps, withProps, withStateHandlers, lifecycle } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, TrafficLayer, KmlLayer } from 'react-google-maps';
import { MAP } from 'react-google-maps/lib/constants';
import CustomButton from './MapShapes/MapCustomButton';
import CustomViewButton from './MapShapes/MapCustomViewButton';
import MapRightClickMenu from './MapShapes/RightClickMenu/AddressWindow';
import MapShapeHandler from './MapShapes/MapShapeHandler';
import CustomInfoWindow from './MapShapes/CustomInfoWindow';
import customStyles from '../index.module.scss';
import config from '../constants/Config';
import { getCurrentPosition, getLocalStorageValue } from '../helper-classes/utility-functions';
import { coordinate, RESIZE_TO_ASSETS, RESIZE_TO_LANDMARKS } from './constants.map';
import { getImage } from '../util/trip_util';
import GetSvgIcon from '../util/svgImage_util';
import mapStyles from './MapsWrapper.module.scss';

const height = 'calc(100vh - 46px)';
let coordinates = coordinate;
let updateTrafficLayerButton = true;
let isLiveTraffic = false;
let showLandmark = false;
let showAsset = false;
const getInitKmlInfoWindow = () => ({
    url: '',
    infoWindow: null,
});
let kmlInfoWindow = getInitKmlInfoWindow();
let isHideLandmarks = false;
let isHideAssets = false;
let resizeAsset = false;
let resizeLandmark = false;
let setAddressWindowParameter = null;
getCurrentPosition()
    .then((response) => {
        const { latitude, longitude } = response.coords;
        coordinates = {
            lat: latitude,
            lng: longitude,
        };
    }).catch(e => e);

const GOOGLE_MAPS_API_KEY = config.get('GOOGLE_MAPS_API_KEY');
const showMapRefreshButton = config.get('SHOW_MAP_REFRESH_BUTTON');
const CircleRef = React.createRef();
const editableCircleRef = React.createRef();
const editableRectangleRef = React.createRef();
const editablePolygonRef = React.createRef();
let mapRef = {};
const kmlRef = React.createRef();
let customControlRendered = false;
let prevProps = {};
let preLoadedKMLCount = 0;
let currentMapZoomLevel = 3;
let isFitBoundRequired = true;

const openAddressWindow = (event: Object) => {
    if (setAddressWindowParameter && typeof setAddressWindowParameter === 'function') {
        setAddressWindowParameter(
            event.latLng.lat(),
            event.latLng.lng(),
            true,
        );
    }
    return true;
};

const closeAddressWindow = () => {
    if (setAddressWindowParameter && typeof setAddressWindowParameter === 'function') {
        setAddressWindowParameter(0, 0, false);
    }
    if (kmlInfoWindow && kmlInfoWindow.infoWindow) {
        kmlInfoWindow.infoWindow.close();
        getInitKmlInfoWindow();
    }
    return true;
};

const getCurrentMapZoomLevel = () => ((mapRef && (typeof mapRef.getZoom === 'function')) ? mapRef.getZoom() : 3);

const getCurrentMapCenter = () => ((mapRef && (typeof mapRef.getCenter === 'function')) ? mapRef.getCenter() : {});

const MapsWrapper = compose(
    defaultProps({
        height: undefined,
    }),
    withProps(prop => ({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places&callback=initMap`,
        loadingElement: <div id="loadingElement" style={{ height: (prop.height) ? prop.height : height }} />,
        containerElement: <div id="containerElement" style={{ height: (prop.height) ? prop.height : height }} />,
        mapElement: <div id="mapElement" style={{ height: (prop.height) ? prop.height : height }} />,

        /**
         * @param {ref} map map ref
         * @param {Object} props props of the current map
         * zoomToMarker is user to zoom to the map based on the type of the shape
         * using map.fitbounds()
         * for `markergroup` if props.markers.shape.key is there then adding zoom out button
         * to the map based on `${key}` value right now supporting landmark and asset
         * MAP this key is provided by plugin only.
         */
        zooomToMarkes: (map, nextProps) => {
            let props = nextProps;
            if (!props) props = prevProps;
            else prevProps = { ...props };

            if (window.google && map && props.isGoogleLoaded && props.markers) {
                if (typeof props.markers === 'object' && props.markers.shape) {
                    const type = (props.markers.shape && props.markers.shape.type) ? props.markers.shape.type.toLowerCase() : '';

                    switch (type) {
                    case 'markergroup': {
                        if (props.markers.shape.data
                            && props.markers.shape.data.length > 0 && props.markers.shape.data[0].latitude !== '' && props.markers.shape.data[0].latitude !== undefined) {
                            const bounds = new window.google.maps.LatLngBounds();
                            props.markers.shape.data.forEach((d) => {
                                if ((d.latitude || d.latitude === 0) && (d.longitude || d.longitude === 0) && typeof d.longitude === 'number' && typeof d.latitude === 'number') {
                                    bounds.extend({ lat: d.latitude, lng: d.longitude });
                                }
                            });

                            if (((props.zoom || 0) <= 3) && isFitBoundRequired) {
                                map.fitBounds(bounds);
                                isFitBoundRequired = false;
                            }

                            const { key } = props.markers.shape;
                            if (key) {
                                props.addGroupResizeIcon(map, key, props);
                            }
                        }
                        break;
                    }
                    case 'nearestassetsgroup': {
                        if (props.markers.shape.data
                            && props.markers.shape.data.length > 0 && props.markers.shape.data[0].latitude !== '' && props.markers.shape.data[0].latitude !== undefined) {
                            const bounds = new window.google.maps.LatLngBounds();
                            props.markers.shape.data.forEach((d) => {
                                if ((d.latitude || d.latitude === 0) && (d.longitude || d.longitude === 0) && typeof d.longitude === 'number' && typeof d.latitude === 'number') {
                                    bounds.extend({ lat: d.latitude, lng: d.longitude });
                                }
                            });
                            map.fitBounds(bounds);
                            map.context[MAP]
                                .setZoom(map.getZoom() - 1);
                            const { key } = props.markers.shape;
                            if (key) {
                                props.addGroupResizeIcon(map, key, props);
                            }
                        }
                        break;
                    }
                    case 'nearestassetlandmark': {
                        if (props.markers.asset &&
                            props.markers.asset.lat &&
                            props.markers.asset.lng &&
                            props.markers.landmark.markerLat &&
                            props.markers.landmark.markerLng) {
                            const bounds = new window.google.maps.LatLngBounds();
                            const { lat, lng } = props.markers.asset;
                            bounds.extend({ lat: parseFloat(lat), lng: parseFloat(lng) });
                            bounds.extend({
                                lat: parseFloat(props.markers.landmark.markerLat),
                                lng: parseFloat(props.markers.landmark.markerLng),
                            });
                            map.fitBounds(bounds);
                        }
                        break;
                    }
                    case 'marker': {
                        if (props.markers.shape.lat && props.markers.shape.lng &&
                            typeof props.markers.shape.lat === 'number' && typeof props.markers.shape.lng === 'number' &&
                            !props.zoom) {
                            const bounds = new window.google.maps.LatLngBounds();
                            const { lat, lng } = props.markers.shape;
                            bounds.extend({ lat, lng });
                            map.fitBounds(bounds);
                        }
                        props.addLandmarkShowHideButton(map, props);
                        break;
                    }
                    case 'batch_directions': {
                        const { events } = props.markers;
                        if (props.markers.zoomEvent && props.markers.zoomEvent.locationSegment) {
                            const { lat, lng } = props.markers.zoomEvent.locationSegment;
                            const latLng = new window.google.maps.LatLng(lat, lng);
                            map.panTo(latLng);
                        } else if (events && events.length > 0) {
                            const bounds = new window.google.maps.LatLngBounds();
                            for (let i = 0; i < events.length; i += 1) {
                                bounds.extend({
                                    lat: events[i].locationSegment.lat,
                                    lng: events[i].locationSegment.lng,
                                });
                            }
                            map.fitBounds(bounds);
                        }
                        props.addLandmarkShowHideButton(map, props);
                        break;
                    }
                    case 'polygon': {
                        if (props.markers.zoomEvent && props.markers.zoomEvent.locationSegment) {
                            const { lat, lng } = props.markers.zoomEvent.locationSegment;
                            const latLng = new window.google.maps.LatLng(lat, lng);
                            map.panTo(latLng);
                        } else if (props.markers.shape.points &&
                            Array.isArray(props.markers.shape.points) && !props.zoom) {
                            const bounds = new window.google.maps.LatLngBounds();
                            props.markers.shape.points.forEach((d) => {
                                if (d.lat && d.lng && typeof d.lng === 'number' && typeof d.lat === 'number') {
                                    bounds.extend({ lat: d.lat, lng: d.lng });
                                }
                            });
                            map.fitBounds(bounds);
                        }
                        break;
                    }
                    case 'rectangle': {
                        const { shape } = props.markers;
                        if (shape.point1 && shape.point2
                            && shape.point1.lat && shape.point1.lng &&
                            typeof shape.point1.lat === 'number' && typeof shape.point1.lng === 'number'
                            && shape.point2.lat && shape.point2.lng &&
                            typeof shape.point2.lat === 'number' && typeof shape.point2.lng === 'number' &&
                            !props.zoom) {
                            const lat = new window.google.maps.LatLng(
                                shape.point1.lat,
                                shape.point1.lng,
                            );
                            const lng = new window.google.maps.LatLng(
                                shape.point2.lat,
                                shape.point2.lng,
                            );
                            const bounds = new window.google.maps.LatLngBounds(lat, lng);
                            map.fitBounds(bounds);
                        }
                        break;
                    }
                    case 'circle': {
                        if (CircleRef.current && CircleRef.current.getBounds() && !props.zoom) {
                            const bounds = CircleRef.current.getBounds();
                            if (bounds && Object.keys(bounds).length > 0) {
                                map.fitBounds(bounds);
                            }
                        }
                        break;
                    }
                    case 'editablecircle': {
                        if (editableCircleRef.current &&
                            editableCircleRef.current.CircleRef &&
                            editableCircleRef.current.CircleRef.current &&
                            typeof editableCircleRef.current.CircleRef.current.getBounds === 'function' &&
                            editableCircleRef.current.CircleRef.current.getBounds()) {
                            const bounds =
                            editableCircleRef.current.CircleRef.current.getBounds();
                            if (bounds && Object.keys(bounds).length > 0) {
                                map.fitBounds(bounds);
                            }
                        }
                        break;
                    }
                    case 'editablerectangle': {
                        if (editableRectangleRef.current &&
                            editableRectangleRef.current.RectangleRef &&
                            editableRectangleRef.current.RectangleRef.current &&
                            typeof editableRectangleRef.current.RectangleRef.current.getBounds === 'function' &&
                            editableRectangleRef.current.RectangleRef.current.getBounds()) {
                            const bounds =
                            editableRectangleRef.current.RectangleRef.current.getBounds();
                            if (bounds && Object.keys(bounds).length > 0) {
                                map.fitBounds(bounds);
                            }
                        }
                        break;
                    }
                    case 'editablepolygon': {
                        if (editablePolygonRef.current &&
                            editablePolygonRef.current.PolygonRef &&
                            editablePolygonRef.current.PolygonRef.current &&
                            typeof editablePolygonRef.current.PolygonRef.current.getPath === 'function' &&
                            editablePolygonRef.current.PolygonRef.current.getPath()) {
                            const bounds = new window.google.maps.LatLngBounds();
                            const paths =
                            editablePolygonRef.current.PolygonRef.current.getPath();
                            for (let i = 0; i < paths.getLength(); i += 1) {
                                const xy = paths.getAt(i);
                                bounds.extend({ lat: xy.lat(), lng: xy.lng() });
                            }
                            map.fitBounds(bounds);
                        }
                        break;
                    }
                    case 'polyline': {
                        const { shape } = props.markers;
                        if (props.markers.zoomEvent && props.markers.zoomEvent.locationSegment) {
                            const { lat, lng } = props.markers.zoomEvent.locationSegment;
                            const latLng = new window.google.maps.LatLng(lat, lng);
                            map.panTo(latLng);
                        } else if (shape.data &&
                            Array.isArray(shape.data) &&
                            shape.data.length > 0) {
                            const bounds = new window.google.maps.LatLngBounds();
                            shape.data.forEach((d) => {
                                d.events.forEach((e) => {
                                    if (e.locationSegment &&
                                        e.locationSegment.lng &&
                                        e.locationSegment.lat &&
                                        typeof e.locationSegment.lng === 'number' &&
                                        typeof e.locationSegment.lat === 'number') {
                                        bounds.extend({
                                            lat: e.locationSegment.lat,
                                            lng: e.locationSegment.lng,
                                        });
                                    }
                                });
                            });
                            map.fitBounds(bounds);
                        }
                        props.addLandmarkShowHideButton(map, props);
                        break;
                    }
                    case 'cluster': {
                        if (!showLandmark && props.appliedFilters) {
                            if (props.appliedFilters.hide_landmarks && props.appliedFilters.hide_landmarks === 'true') {
                                isHideLandmarks = true;
                            }
                            if (props.appliedFilters.hide_assets && props.appliedFilters.hide_assets === 'true') {
                                isHideAssets = true;
                            }
                        }
                        const resizeLandmarkIcon = <GetSvgIcon type="resizeLandmark" fillcolor="#007aff" style={{ height: '44px', width: '44px' }} />;
                        if (!resizeLandmark) {
                            resizeLandmark = true;
                            const resizeLandmarkCtrlDiv = document.createElement('resizeLandmarkDiv');
                            resizeLandmarkCtrlDiv.style.padding = '9px';
                            resizeLandmarkCtrlDiv.title = RESIZE_TO_LANDMARKS;
                            const resizeLandmarkDiv = <Fab id="resize-landmark" className={mapStyles.customControlIconStyle} size="small">{resizeLandmarkIcon}</Fab>;
                            resizeLandmarkCtrlDiv.innerHTML = renderToString(resizeLandmarkDiv);
                            map.context[MAP]
                                .controls[window.google.maps.ControlPosition.LEFT_BOTTOM]
                                .push(resizeLandmarkCtrlDiv);
                        } else {
                            const resizeLandmarkElement = document.getElementById('resize-landmark');
                            if (resizeLandmarkElement) {
                                resizeLandmarkElement.disabled = false;
                                resizeLandmarkElement.onclick = () => {
                                    if (typeof props.resizeLandmark === 'function' && props.resizeLandmark) {
                                        props.resizeLandmark();
                                    }
                                    resizeLandmarkElement.disabled = true;
                                };
                                const iconString = renderToString(resizeLandmarkIcon);
                                resizeLandmarkElement.innerHTML = iconString;
                            }
                        }
                        const resizeAssetIcon = <GetSvgIcon type="resizeAsset" fillcolor="#007aff" style={{ height: '42px', width: '42px' }} />;
                        if (!resizeAsset) {
                            resizeAsset = true;
                            const resizeAssetCtrlDiv = document.createElement('resizeAsset');
                            resizeAssetCtrlDiv.style.padding = '9px';
                            resizeAssetCtrlDiv.title = RESIZE_TO_ASSETS;
                            const resizeAssetDiv = <Fab id="resize-asset" className={mapStyles.customControlIconStyle} size="small">{resizeAssetIcon}</Fab>;
                            resizeAssetCtrlDiv.innerHTML = renderToString(resizeAssetDiv);
                            map.context[MAP]
                                .controls[window.google.maps.ControlPosition.LEFT_BOTTOM]
                                .push(resizeAssetCtrlDiv);
                        } else {
                            const resizeAssetElement = document.getElementById('resize-asset');
                            if (resizeAssetElement) {
                                resizeAssetElement.disabled = false;
                                resizeAssetElement.onclick = () => {
                                    if (typeof props.resizeAsset === 'function' && props.resizeAsset) {
                                        props.resizeAsset();
                                    }
                                    resizeAssetElement.disabled = true;
                                };
                                const resizeAssetIconnString = renderToString(resizeAssetIcon);
                                resizeAssetElement.innerHTML = resizeAssetIconnString;
                            }
                        }
                        props.addLandmarkShowHideButton(map, props);
                        const assetIcon = <GetSvgIcon type="asset" fillcolor={(isHideAssets) ? 'rgba(0, 0, 0, 0.54)' : '#007aff'} />;
                        if (!showAsset) {
                            showAsset = true;
                            const assetCtrlDiv = document.createElement('div');
                            assetCtrlDiv.style.padding = '9px';
                            assetCtrlDiv.title = 'Hide Assets';
                            assetCtrlDiv.onclick = () => {
                                isHideAssets = !isHideAssets;
                                if (typeof props.hideAsset === 'function' && props.hideAsset) {
                                    props.hideAsset(isHideAssets);
                                }
                            };
                            const assetDiv = <Fab id="show-asset" className={mapStyles.customControlIconStyle} size="small">{assetIcon}</Fab>;
                            assetCtrlDiv.innerHTML = renderToString(assetDiv);
                            map.context[MAP]
                                .controls[window.google.maps.ControlPosition.LEFT_BOTTOM]
                                .push(assetCtrlDiv);
                        } else {
                            const assetElement = document.getElementById('show-asset');
                            if (assetElement) {
                                const assetIconString = renderToString(assetIcon);
                                assetElement.innerHTML = assetIconString;
                                assetElement.parentElement.title = isHideAssets ? 'Show Assets' : 'Hide Assets';
                            }
                        }
                        break;
                    }
                    default: break;
                    }
                }
            }
        },
        addLandmarkShowHideButton: (map, props) => {
            const icon = <GetSvgIcon type="locationIcon" fillcolor={(isHideLandmarks) ? 'rgba(0, 0, 0, 0.54)' : '#007aff'} />;
            if (!showLandmark) {
                showLandmark = true;
                const controlDiv = document.createElement('div');
                controlDiv.style.padding = '9px';
                controlDiv.title = 'Hide Landmarks';
                controlDiv.onclick = () => {
                    isHideLandmarks = !isHideLandmarks;
                    if (typeof props.hideLandmark === 'function' && props.hideLandmark) {
                        props.hideLandmark(isHideLandmarks);
                    }
                };
                const div = <Fab id="show-landmark" className={mapStyles.customControlIconStyle} size="small">{icon}</Fab>;
                controlDiv.innerHTML = renderToString(div);
                map.context[MAP]
                    .controls[window.google.maps.ControlPosition.LEFT_BOTTOM]
                    .push(controlDiv);
            } else {
                const tafficElement = document.getElementById('show-landmark');
                if (tafficElement) {
                    const iconString = renderToString(icon);
                    tafficElement.innerHTML = iconString;
                    tafficElement.parentElement.title = isHideLandmarks ? 'Show Landmarks' : 'Hide Landmarks';
                }
            }
        },
        addTrafficDiv: (map) => {
            if (map && updateTrafficLayerButton) {
                updateTrafficLayerButton = false;
                if (!isLiveTraffic) {
                    isLiveTraffic = true;
                    const mapTypeControlDivView = document.querySelector('#maptype-control-view');
                    const mapTypeControlDiv = document.querySelector('#maptype-control');
                    const controlPosition = mapTypeControlDiv ?
                        window.google.maps.ControlPosition.TOP_LEFT :
                        window.google.maps.ControlPosition.LEFT_TOP;
                    map.context[MAP]
                        .controls[controlPosition]
                        .push(mapTypeControlDivView);
                    if (mapTypeControlDiv) {
                        map.context[MAP]
                            .controls[
                                window.google.maps.ControlPosition.TOP_LEFT
                            ]
                            .push(mapTypeControlDiv);
                    }
                }
            }
        },
        addGroupResizeIcon: (map, key, props) => {
            const iconImage = (key === 'landmark') ? getImage('resetToLandmarks') : getImage('resetToVehicles');
            const controlDiv = document.createElement('div');
            controlDiv.style.padding = '8px';
            controlDiv.title = (key === 'landmark') ? RESIZE_TO_LANDMARKS : RESIZE_TO_ASSETS;
            controlDiv.onclick = () => {
                prevProps.zoom = 0;
                isFitBoundRequired = true;
                props.zooomToMarkes(map);
            };
            const div = <Fab className={mapStyles.customControlIconStyle} size="small" aria-label="Add"><img src={iconImage} alt={`ResetTo${key}`} /></Fab>;
            controlDiv.innerHTML = renderToString(div);
            if (!customControlRendered) {
                customControlRendered = true;
                map.context[MAP]
                    .controls[
                        window.google.maps.ControlPosition.LEFT_BOTTOM
                    ]
                    .push(controlDiv);
            }
        },
        getModeType: (marker) => {
            const TransitMode = (marker.shape && marker.shape.mode) ? marker.shape.mode.toUpperCase() : '';
            return TransitMode;
        },
        /**
         * @param {Object} latlng lat and lng value to focus on map
         * @returns if latlng is not there then will return current location
         * if current location is not there then return { lat: 33.705511, lng: -117.840672 };
         */
        getCoordinates(latlng) {
            let location = coordinates;
            if (latlng && (latlng.lat || latlng.lng)) {
                location = latlng;
                location.lat = location.lat || 0;
                location.lng = location.lng || 0;
            }
            return location;
        },
        refreshMapData(props) {
            if (mapRef) props.refreshData(mapRef.getZoom(), mapRef.getCenter());
        },
    })),
    /**
     * @param {Object} props props value
     * update props value with googleMap is loaded and with google instance
     * onTilesLoaded will invoke when tiles are start loading
     * @prop {Function} isloaded() callback once map is loaded for parent component
     */
    withStateHandlers((props: any) => {
        const { isLoaded, boundaryChanged, onZoomChanged } = props;
        return {
            isGoogleLoaded: false,
            googleMap: null,
            rightClickEnable: false,
            showTrafficLayer: getLocalStorageValue('mapTrafficLayer') === 'true',
            isLoaded,
            boundaryChanged,
            onZoomChanged,
            showLandmarkLabel: getLocalStorageValue('mapLandmarkLabel') !== 'false',
            showAssetLabel: getLocalStorageValue('mapAssetLabel') !== 'false',
        };
    }, {
        onTilesLoaded: (props: any) => () => {
            if (props && props.boundaryChanged) props.boundaryChanged();
            if (props && props.isLoaded && !props.isGoogleLoaded) {
                props.isLoaded(window.google);
            }
            return {
                isGoogleLoaded: true,
                googleMap: window.google,
            };
        },
        openAddressWindow: () => event => openAddressWindow(event),
        closeAddressWindow: () => () => closeAddressWindow(),
        onZoomChanged: (props: any) => () => {
            if (props && typeof props.onZoomChanged === 'function' && props.isGoogleLoaded) {
                props.onZoomChanged({
                    zoomLevel: getCurrentMapZoomLevel(),
                    lat: getCurrentMapCenter().lat(),
                    lng: getCurrentMapCenter().lng(),
                });
            }
        },
        addTrafficLayer: () => (key, flag, updateTrafficLayer) => {
            updateTrafficLayerButton = updateTrafficLayer;
            switch (key) {
            case 'mapAssetLabel': return { showAssetLabel: flag };
            case 'mapLandmarkLabel': return { showLandmarkLabel: flag };
            case 'mapTrafficLayer': return { showTrafficLayer: flag };
            default: return '';
            }
        },
    }),
    lifecycle({
        UNSAFE_componentWillReceiveProps(props) {
            this.setState({
                zoomToMarkers: (map) => {
                    if (map && props.getBoundaries) props.getBoundaries(map);
                    mapRef = map;
                    props.addTrafficDiv(map, props);
                    props.zooomToMarkes(map, props);
                },
                currentMapZoomLevel: getCurrentMapZoomLevel(),
            });

            if (kmlInfoWindow.infoWindow &&
                kmlInfoWindow.url &&
                props.kmlPreferenceUrls
            ) {
                const isUrlPresent = props.kmlPreferenceUrls.some(d => d === kmlInfoWindow.url);
                if (!isUrlPresent) {
                    kmlInfoWindow.infoWindow.close();
                    getInitKmlInfoWindow();
                }
            }
        },
        UNSAFE_componentWillMount() {
            isFitBoundRequired = true;
            customControlRendered = false;
            updateTrafficLayerButton = true;
            isLiveTraffic = false;
            showLandmark = false;
            showAsset = false;
            isHideLandmarks = false;
            isHideAssets = false;
            resizeLandmark = false;
            setAddressWindowParameter = null;
            kmlInfoWindow = getInitKmlInfoWindow();
            resizeAsset = false;
            prevProps = {};
            localStorage.removeItem('polyline');
        },
        shouldComponentUpdate(nextProps, nextState) {
            // below code is required to avoid cyclic instance error while using JSON.stringify
            // because of html markups/react node
            const nextmarkers = { ...nextProps.markers, panel: '' };
            const currentmarkers = { ...this.props.markers, panel: '' };
            let isReRenderRequired = true;

            if (this.props.isGoogleLoaded) {
                if ((nextProps.showTrafficLayer === this.props.showTrafficLayer)
                && (nextProps.showLandmarkLabel === this.props.showLandmarkLabel)
                && (nextProps.showAssetLabel === this.props.showAssetLabel)
                && (JSON.stringify(nextmarkers || '{}') === JSON.stringify(currentmarkers || '{}'))) {
                    isReRenderRequired = false;
                }

                const { kmlPreferenceUrls, tripInfoBox } = nextProps;
                if (!isReRenderRequired && (JSON.stringify(kmlPreferenceUrls || '[]') === JSON.stringify(this.props.kmlPreferenceUrls || '[]'))) {
                    if (kmlPreferenceUrls) {
                        if (kmlPreferenceUrls.length === preLoadedKMLCount) {
                            isReRenderRequired = false;
                            if (nextProps.zoomToKmlLayer !== this.props.zoomToKmlLayer) {
                                isReRenderRequired = true;
                            }
                        } else {
                            preLoadedKMLCount = (kmlPreferenceUrls && kmlPreferenceUrls.length);
                            isReRenderRequired = true;
                        }
                    }
                } else {
                    preLoadedKMLCount = (kmlPreferenceUrls && kmlPreferenceUrls.length);
                    isReRenderRequired = true;
                }

                if (!isReRenderRequired && tripInfoBox &&
                        (tripInfoBox.id !== this.props.tripInfoBox.id)) {
                    isReRenderRequired = true;
                }

                if (!isReRenderRequired && currentMapZoomLevel !== nextState.currentMapZoomLevel) {
                    ({ currentMapZoomLevel } = nextState);
                    isReRenderRequired = true;
                }

                if (!isReRenderRequired && (JSON.stringify(nextProps.appliedFilters)
                    === JSON.stringify(this.props.appliedFilters))) {
                    isReRenderRequired = true;
                }
            }
            return isReRenderRequired;
        },
    }),
    withScriptjs,
    withGoogleMap,
)(props => (
    <React.Fragment>
        <div style={{ display: 'none' }}>
            {!props.hideViewButton &&
                <div
                    id="maptype-control-view"
                    className={`
                    ${mapStyles['maptype-control-custom']}
                    ${(props.refreshData && typeof props.refreshData === 'function' && showMapRefreshButton) ? mapStyles['custom-view-control'] : mapStyles['hidden-custom-view-control']}
                    `}
                >
                    <CustomViewButton
                        handleClick={(key, flag) => props.addTrafficLayer(key, flag, true)}
                        viewTraffic="true" // Default value is set as true, as of not there is no screen where it should not come under view.
                        viewAssetLabel={props.viewAssetLabel}
                        viewLandmarkLabel={props.viewLandmarkLabel}
                        manageClustering={props.manageClustering}
                        showKml={props.showKml}
                        showAssetClusteringOption={props.showAssetClusteringOption}
                        showLandmarkClusteringOption={props.showLandmarkClusteringOption}
                        snapRoutesCallback={props.snapRoutesCallback}
                        bookmarkClick={props.bookmarkClick}
                        mapInfo={{
                            center: getCurrentMapCenter(),
                            zoom: getCurrentMapZoomLevel(),
                        }}
                        trackAnalytics={(...d) => {
                            if (props.trackAnalytics && typeof props.trackAnalytics === 'function') {
                                props.trackAnalytics(...d);
                            }
                        }}
                    />
                </div>
            }
            {props.refreshData && typeof props.refreshData === 'function' && showMapRefreshButton &&
            <div id="maptype-control" className={`${mapStyles.controls} ${mapStyles['maptype-control']} ${customStyles['gm-view-button']}`}>
                <CustomButton
                    refreshData={() => props.refreshMapData(props)}
                    resetRefreshTimer={props.resetRefreshTimer}
                    trackAnalytics={(...d) => {
                        if (props.trackAnalytics && typeof props.trackAnalytics === 'function') {
                            props.trackAnalytics(...d);
                        }
                    }}
                />
            </div>
            }
        </div>
        <div style={{ display: 'none' }}>
            {!props.hideRightClick &&
                <MapRightClickMenu
                    addressParameter={(addressParameter) => {
                        setAddressWindowParameter = addressParameter;
                    }}
                />
            }
        </div>
        <GoogleMap
            defaultZoom={15}
            key="main-google-map"
            ref={props.zoomToMarkers}
            defaultCenter={coordinates}
            zoom={props.zoom ? props.zoom : 3}
            travelMode={props.markers ? props.getModeType(props.markers) : 'DRIVING'}
            center={props.getCoordinates(props.coordinates)}
            onTilesLoaded={props.onTilesLoaded}
            onZoomChanged={props.onZoomChanged}
            options={(window.google) ? {
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: window.google.maps.ControlPosition.LEFT_TOP,
                },
                fullscreenControl: false,
                fullscreenControlOptions: {
                    position: window.google.maps.ControlPosition.TOP_LEFT,
                },
                zoomControlOptions: {
                    position: window.google.maps.ControlPosition.LEFT_BOTTOM,
                },
                rotateControl: false,
                streetViewControlOptions: {
                    position: window.google.maps.ControlPosition.LEFT_BOTTOM,
                },
                minZoom: 3,
                maxZoom: 21,
            } : {}}
            onRightClick={props.openAddressWindow}
            onClick={props.closeAddressWindow}
        >
            {((props.showTrafficLayer && !props.hideViewButton)
                || props.defaultTrafficLayerView) && <TrafficLayer autoUpdate />}

            {(props.isGoogleLoaded && props.markers) &&
                <MapShapeHandler
                    marker={props.markers}
                    google={props.googleMap}
                    mapRef={mapRef}
                    CircleRef={CircleRef}
                    editableCircleRef={editableCircleRef}
                    editableRectangleRef={editableRectangleRef}
                    editablePolygonRef={editablePolygonRef}
                    openAddressWindow={props.openAddressWindow}
                    closeAddressWindow={props.closeAddressWindow}
                    showAssetLabel={props.showAssetLabel}
                    showLandmarkLabel={props.showLandmarkLabel}
                    showClusterInfo={props.showClusterInfo}
                    findClusterExplodePoint={props.findClusterExplodePoint}
                    zoomLevel={currentMapZoomLevel}
                    moduleName={props.moduleName || ''}
                    tripIconClick={props.tripIconClick}
                    tripInfoBox={props.tripInfoBox}
                />
            }

            {(props.kmlPreferenceUrls && props.kmlPreferenceUrls.length > 0) &&
            props.kmlPreferenceUrls.map((d) => {
                let { infoWindow } = kmlInfoWindow;
                return (
                    <KmlLayer
                        key={d}
                        url={d}
                        options={{ preserveViewport: true, suppressInfoWindows: true }}
                        onClick={(e) => {
                            if (mapRef && e.featureData) {
                                if (infoWindow) infoWindow.close();
                                else infoWindow = new props.googleMap.maps.InfoWindow();

                                kmlInfoWindow.url = d;
                                infoWindow.setContent(e.featureData.infoWindowHtml);
                                infoWindow.setPosition(e.latLng);
                                infoWindow.open(mapRef.context[MAP]);
                            }
                        }}
                    />
                );
            })}

            {(props.zoomToKmlLayer &&
                <KmlLayer
                    key={`zoomed-kml-${props.zoomToKmlLayer}`}
                    ref={kmlRef}
                    url={props.zoomToKmlLayer}
                    options={{ preserveViewport: true, suppressInfoWindows: true }}
                    onDefaultViewportChanged={() => {
                        if (mapRef && typeof mapRef.fitBounds === 'function') mapRef.fitBounds(kmlRef.current.getDefaultViewport());
                    }}
                />
            )}
            {(props.tripInfoBox && props.tripInfoBox.locationSegment) &&
                <CustomInfoWindow tripInfoBox={props.tripInfoBox} />
            }
        </GoogleMap>
    </React.Fragment>
));
export default MapsWrapper;
