/* @flow */
import React from 'react';
import { Grid, Button } from '@mui/material';
import customStyles from './ImportComponent.module.scss';
import { DRIVER, DRIVER_TEMPLATE_NAME } from './constants.import';
import { hasFeature } from '../../../helper-classes/utility-functions';

export type Props = {
    downloadTemplate: Function,
};

class DriverImportFormComponent extends React.PureComponent<Props> {
    render() {
        const needInputValidation = hasFeature('fleet.Eld');
        return (
            <Grid container justifyContent="flex-start" spacing={1} style={{ marginBottom: 96 }}>
                <Grid item xs={12} style={{ margin: '18px 0' }}>
                    <h2 className={customStyles.header}>Drivers</h2>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={() => this.props.downloadTemplate(DRIVER, DRIVER_TEMPLATE_NAME)}>Download Template</Button>
                </Grid>
                <Grid item xs={12} style={{ margin: '18px 0' }}>
                    <div className={customStyles.highlight}>
                        <div className={customStyles.subHeader}>
                            Importing a Driver .CSV
                        </div>
                        Please read the following instructions. It is important that
                        the import file has the columns required and optional in order
                        from left to right as shown in the sample import file
                    </div>
                    <div>
                        A header column is required as shown in the sample file.
                        Drivers are identified by their First and Last Name.
                        {/*  TODO needed for phase 2 import feature */}
                        {/* If a
                        driver pre-exists then the values will be updated with
                        following rules: if the value is DELETE then it will be
                        deleted, if the value is left blank then the old value is
                        retained, else the value gets updated with the new value. */}
                        The following columns are permitted.
                        <div className={customStyles.subHeader}>Required:</div>
                        <ul>
                            <li>
                                First Name: First name of the user (max 30 chars)
                            </li>
                            <li>
                                Last Name: Last name of the user (max 30 chars)
                            </li>
                            { needInputValidation &&
                            <React.Fragment>
                                <li>
                                    Phone: Phone Number (enter as 10-16 digit value)
                                </li>
                                <li>
                                    Email: Email address for the user
                                    (must be a valid email format)
                                </li>
                                <li>
                                    License Number: Driver&apos;s license number
                                    (max 20 chars)
                                </li>
                                <li>
                                    License State (max 50 chars)
                                </li>
                            </React.Fragment>
                            }
                        </ul>
                        <div className={customStyles.subHeader}>
                            Optional (may be left empty):
                        </div>
                        { needInputValidation ?
                            <ul>
                                <li>
                                    Comments: Up to 250 chars
                                </li>
                                <li>
                                    Enable Scorecard Mobile Access:
                                    <span className={customStyles.subHeader}>
                                     &nbsp;Advanced bundle only.
                                    </span>
                                    &nbsp;Send the user an invitation with instructions on how to
                                    log in to the Driver Scorecard mobile application?
                                    (Yes and No values accepted, defaults to No if left blank)
                                </li>
                            </ul>
                            :
                            <ul>
                                <li>
                                    Phone: Phone Number (enter as 10-16 digit value)
                                </li>
                                <li>
                                    Email: Email address for the user
                                    (must be a valid email format)
                                </li>
                                <li>
                                    Comments: Up to 250 chars
                                </li>
                                <li>
                                    License Number: Driver&apos;s license number
                                    (max 20 chars)
                                </li>
                                <li>
                                    License State (max 50 chars)
                                </li>
                                <li>
                                    Enable Scorecard Mobile Access:
                                    <span className={customStyles.subHeader}>
                                     &nbsp;Advanced bundle only.
                                    </span>
                                    &nbsp;Send the user an invitation with instructions on how to
                                    log in to the Driver Scorecard mobile application?
                                    (Yes and No values accepted, defaults to No if left blank)
                                </li>
                            </ul>
                        }
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default DriverImportFormComponent;
