/* @flow */

import { FETCH_SCOREDRIVER_LIST, FETCH_SCOREDRIVER_LIST_SUCCESS, FETCH_SCOREDRIVER_LIST_ERROR, RESET_SCOREDRIVER_LIST } from './constants.scoredriverList';
import type { ScoreDriverListTypeAction } from './constants.scoredriverList';

export const fetchDriverCardsList = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filter: string,
): ScoreDriverListTypeAction => ({
    type: FETCH_SCOREDRIVER_LIST,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
        filter,
    },
});
export const fetchScoreDriverListSuccess =
    (scoredriver: any): ScoreDriverListTypeAction => ({
        type: FETCH_SCOREDRIVER_LIST_SUCCESS,
        payload: { response: scoredriver },
    });

export const fetchScoreDriverListError = (error: string): ScoreDriverListTypeAction => ({
    type: FETCH_SCOREDRIVER_LIST_ERROR,
    payload: { error },
});

export const resetTable =
    (): ScoreDriverListTypeAction => ({
        type: RESET_SCOREDRIVER_LIST,
    });
