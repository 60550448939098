/* @flow... */
import React, { Component } from 'react';
import Iframe from 'react-iframe';
import AppLoader from '../AppLoader';

type Props = {
    url: string,
    title: string,
    delay: number,
    style: any,
    id: string,
    allowFullScreen?: boolean,
}

type State = {
    iframeLoading: boolean,
}

class ExternalSite extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            iframeLoading: true,
        };
        this.iframeLoaded = this.iframeLoaded.bind(this);
    }

    iframeLoaded() {
        // Timeout for adding a spinner delay to hide the old UI map flashing
        setTimeout(() => {
            this.setState({ iframeLoading: false });
        }, this.props.delay || 0);
    }

    render() {
        const iframeStyle = this.state.iframeLoading ? { visibility: 'hidden' } : { visibility: 'visible' };
        const style = this.props.style ? this.props.style : { width: '100%', height: '100%' };
        const allowFullScreen = this.props.allowFullScreen || false;

        return (
            <div id="external-site" style={style}>
                {this.state.iframeLoading && <AppLoader
                    loaderStyle={{
                        'padding-top': '200px',
                        'text-align': 'center',
                    }}
                />}
                <Iframe
                    title={this.props.title}
                    styles={iframeStyle}
                    width="100%"
                    height="100%"
                    url={this.props.url || ''}
                    position="initial"
                    onLoad={this.iframeLoaded}
                    id={this.props.id}
                    allowFullScreen={allowFullScreen}
                />
            </div>
        );
    }
}

export default ExternalSite;
