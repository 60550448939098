import { combineEpics } from 'redux-observable';
import querystring from 'query-string';
import * as constants from './constants.addDriver';
import * as actions from './actions.addDriver';
import config from '../../constants/Config';
import { getAuthorizationHeader, getGrailsAppBasedURL, getUserToken } from '../../helper-classes/utility-functions';

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    put: Function,
    deleteJSON: Function,
    of: Function,
}

function getPostedData(action) {
    const postedData = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        phone: action.payload.phone,
        keyFobId: action.payload.keyFobId,
        email: action.payload.email,
        licenseNumber: action.payload.licenseNumber,
        licenseState: action.payload.licenseState,
        comments: action.payload.comments,
    };

    if (action.payload.isMobile) { // creating driver with mobile or enabling for the first time
        postedData.appAccess = {
            DRIVER_SCORECARD: true,
        };
        postedData.userRef = {
            username: action.payload.email,
        };
    }

    if (action.type === constants.UPDATE_DRIVER) { // updating any driver
        postedData.id = action.payload.id;
        if (action.payload.hadMobileAccess) { // has an associated user (had mobile at one point)
            postedData.appAccess = {
                DRIVER_SCORECARD: action.payload.isMobile,
            };
            postedData.userRef = {
                id: action.payload.userId,
                username: action.payload.username,
            };
        }
    }

    return postedData;
}

function getDriverApiUrl(action) {
    const { payload } = action;
    const dc = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}`
    const fleetServicesUrl = config.get('FLEET_SERVICES_URL');
    let platformApiUrl = config.get('PLATFORM_API_BASE_URL');
    let urlConfig = {};
    switch (action.type) {
    case constants.FETCH_LICENSE_STATES:
        urlConfig = {
            query: payload.query || '',
            page: payload.page || 1,
            start: payload.start || 0,
            limit: payload.limit || 100,
        };
        url = `${url}/rest/json/referenceState?_dc=${dc}`;
        return `${url}&${querystring.stringify(urlConfig)}`;
    case constants.FETCH_UNASSIGNED_KEY_FOBS:
        urlConfig = {
            query: payload.query || '',
            page: payload.page || 1,
            offset: payload.offset || 0,
            max: payload.max || 50,
        };
        platformApiUrl = `${platformApiUrl}/keyFobs?assigned=false&_dc=${dc}`;
        return `${platformApiUrl}&${querystring.stringify(urlConfig)}`;
    case constants.ADD_DRIVER:
        return `${fleetServicesUrl}/operators`;
    case constants.FETCH_DRIVER:
    case constants.UPDATE_DRIVER:
    case constants.DELETE_DRIVER:
        return `${fleetServicesUrl}/operators/${payload.id}`;
    case constants.RESEND_INVITE:
        return `${fleetServicesUrl}/operators/${payload.id}/resetPassword`;
    default:
        return '';
    }
}

export const getLicenseStates =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(constants.FETCH_LICENSE_STATES)
            .mergeMap((action) => {
                const headers = {
                    'Content-Type': 'application/json',
                    Authorization: getAuthorizationHeader(),
                };
                return getJSON(getDriverApiUrl(action), headers);
            })
            .map((result) => {
                if (result.success) {
                    return actions.fetchLicenseStatesSuccess(result.data);
                }
                return actions.fetchLicenseStatesError(result);
            })
            .catch(error => of(actions.fetchLicenseStatesError(error)));

export const getUnassignedKeyFobs =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(constants.FETCH_UNASSIGNED_KEY_FOBS)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };
                return getJSON(getDriverApiUrl(action), headers);
            })
            .map((result) => {
                if (result.success) {
                    return actions.fetchUnassignedKeyFobsSuccess(result.data);
                }
                return actions.fetchUnassignedKeyFobsError(result);
            })
            .catch(error => of(actions.fetchUnassignedKeyFobsError(error)));

export const saveDriver = (data: any) => {
    const headers = {
        'X-Nspire-UserToken': getUserToken(),
        'Content-Type': 'application/json',
    };
    return data.postJSON(getDriverApiUrl(data.action), getPostedData(data.action), headers)
        .map((result) => {
            if (result.status === 201) {
                return actions.addDriverSuccess(result.response.data);
            }
            return actions.addDriverError(result.response);
        })
        .catch(error => data.of(actions.addDriverError(error)));
};

export const getDriver =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(constants.FETCH_DRIVER)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };
                return getJSON(getDriverApiUrl(action), headers);
            })
            .map((result) => {
                if (result.id) {
                    return actions.fetchDriverSuccess(result);
                }
                return actions.fetchDriverError(result);
            })
            .catch(error => of(actions.fetchDriverError(error)));

export const editDriver = (data: any) => {
    const headers = {
        'X-Nspire-UserToken': getUserToken(),
        'Content-Type': 'application/json',
    };
    return data.put(getDriverApiUrl(data.action), getPostedData(data.action), headers)
        .map((result) => {
            if (result.status === 200) {
                return actions.updateDriverSuccess(result.response.data);
            }
            return actions.updateDriverError(result.response);
        })
        .catch(error => data.of(actions.updateDriverError(error)));
};

export const createKeyFob = (data: any) => {
    const headers = {
        'X-Nspire-UserToken': getUserToken(),
        'Content-Type': 'application/json',
    };
    return data.postJSON(
        `${config.get('PLATFORM_API_BASE_URL')}/keyFobs`,
        { serialNumber: data.action.payload.newKeyFobLabel },
        headers,
    )
        .mergeMap((result) => {
            if (result.status === 200) {
                const newData = { ...data };
                newData.action.payload.keyFobId = result.response.id;
                if (newData.action.type === constants.ADD_DRIVER) {
                    return saveDriver(newData);
                }
                return editDriver(newData);
            }
            return actions.createKeyFobError({ response: { message: 'Error creating key fob' } });
        })
        .catch(() => data.of(actions.createKeyFobError({ response: { message: 'Error creating key fob' } })));
};

export const handleEditDriver =
    (actions$: Function, store: Object, { put, of, postJSON }: ObservarblesTypes) =>
        actions$
            .ofType(constants.UPDATE_DRIVER)
            .mergeMap((action) => {
                const data = {
                    put,
                    postJSON,
                    of,
                    action,
                };
                if (action.payload.newKeyFobLabel) {
                    return createKeyFob(data);
                }
                return editDriver(data);
            });

export const handleSaveDriver =
    (actions$: Function, store: Object, { of, postJSON }: ObservarblesTypes) =>
        actions$
            .ofType(constants.ADD_DRIVER)
            .mergeMap((action) => {
                const data = {
                    postJSON,
                    of,
                    action,
                };
                if (action.payload.newKeyFobLabel) {
                    return createKeyFob(data);
                }
                return saveDriver(data);
            });

export const removeDriver =
    (actions$: Function, store: Object, { deleteJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(constants.DELETE_DRIVER)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };

                return deleteJSON(getDriverApiUrl(action), headers)
                    .map((result) => {
                        if (result.status === 204) {
                            return actions.deleteDriverSuccess();
                        }
                        return actions.deleteDriverError(result.response);
                    })
                    .catch(error => of(actions.deleteDriverError(error)));
            });

export const resendInvite =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(constants.RESEND_INVITE)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };

                return postJSON(getDriverApiUrl(action), getPostedData(action), headers)
                    .map((result) => {
                        if (result.status === 204) {
                            return actions.resendInviteSuccess();
                        }
                        return actions.resendInviteError(result.response);
                    })
                    .catch(error => of(actions.resendInviteError(error)));
            });

export default combineEpics(
    getLicenseStates,
    handleEditDriver,
    getUnassignedKeyFobs,
    handleSaveDriver,
    getDriver,
    removeDriver,
    resendInvite,
);
