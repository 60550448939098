import * as constants from './constants.driverSettings';

type stateType = {
    driverSettings: Object,
    responseType: string,
    responseStatus: boolean,
    responseData: Object,
}

const initialState = {
    driverSettings: {},
    responseType: '',
    responseStatus: false,
    responseData: {},
};

type actionType = {
    type: string,
    payload :{
        loading: boolean,
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case constants.ADD_DRIVER_SETTINGS_SUCCESS:
        return {
            ...state,
            driverSettings: action.payload.response,
            responseType: 'add',
            responseStatus: true,
            loading: false,
        };
    case constants.ADD_DRIVER_SETTINGS_ERROR:
        return {
            ...state,
            responseType: 'add',
            responseStatus: false,
            responseData: action.payload.error,
            loading: false,
        };
    case constants.FETCH_DRIVER_SETTINGS_SUCCESS:
        return {
            ...state,
            driverSettings: action.payload.response,
            responseType: 'get',
            responseStatus: true,
            loading: false,
        };
    case constants.FETCH_DRIVER_SETTINGS_ERROR:
        return {
            ...state,
            driverSettings: {},
            responseType: 'get',
            responseStatus: false,
            responseData: action.payload,
            loading: false,
        };
    case constants.UPDATE_DRIVER_SETTINGS_SUCCESS:
        return {
            ...state,
            driverSettings: action.payload.response.response,
            responseType: 'update',
            responseStatus: true,
            loading: false,
        };
    case constants.UPDATE_DRIVER_SETTINGS_ERROR:
        return {
            ...state,
            driverSettings: {},
            responseType: 'update',
            responseStatus: false,
            responseData: action.payload,
            loading: false,
        };
    case constants.UPDATE_DRIVER_SETTINGS_LOADER:
        return {
            ...state,
            loading: action.payload.loading,
        };
    default:
        return state;
    }
};
