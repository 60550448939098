import React, { Component } from 'react';
import { Button, Table, TableBody, TableHead, TableRow, TableCell, IconButton, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import { debounce } from 'throttle-debounce';
import ListTableRow from '../ListComponent/ListTableRow';
import styles from './AssetCriteriaStyles';
import AppLoader from '../../AppLoader';
import searchIcon from '../../../assets/icons/search.svg';
import { getAssets, getAssetsGrails } from '../../../containers/Maintenance/helper-classes/common-services';
import SearchBox from '../SearchBox';
import { MIN_SEARCH_LENGTH } from '../../../constants/Config';

/* eslint-disable */
type Props = {
    searchGroups: Array<{}>,
    searchTags: Array<{}>,
    selectedAssets: Array<{}>,
    // eslint-disable-next-line react/no-unused-prop-types
    assetSearchResults: [],
    onAssetsSelected: Function,
    assetFilter: any,
    isGrailsAPIReq: boolean,
    isAlert: boolean,
};
type State = {
    tableData: {
        listData: Array<{}>,
        totalRecords: number,
    },
    search: string,
    isUpdating: boolean,
    searchGroups: Array<{}>,
    searchTags: Array<{}>,
    allSelected: boolean,
    openDialog: boolean,
};

class AssetCriteriaSearchList extends Component<Props, State> {
    searchBoxRef: Input;

    constructor(props: Props) {
        super(props);
        let searchGroups = [];
        if (props.searchGroups) {
            searchGroups = props.searchGroups.map(group => ({ id: group.id, name: group.name }));
        }
        let searchTags = [];
        if (props.searchTags) {
            searchTags = props.searchTags.map(tag => ({ id: tag.id, name: tag.tag }));
        }
        this.state = {
            tableData: {
                listData: [],
                totalRecords: 0,
            },
            search: '',
            isUpdating: false,
            selectedAssets: props.selectedAssets,
            searchGroups,
            searchTags,
            allSelected: false,
            openDialog: false,
        };
        this.debounceFetch = debounce(200, () => this.loadNextPage(this.state.search, true));
    }

    componentDidMount() {
        this.loadNextPage();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            searchGroups: currentGroups,
            searchTags: currentTags,
        } = this.state;
        const shouldUpdateAssets = this.filterChanged(currentGroups, nextProps.searchGroups)
            || this.filterChanged(currentTags, nextProps.searchTags);
        let newGroups = [];
        if (nextProps.searchGroups) {
            newGroups = nextProps.searchGroups.map(group => ({ id: group.id, name: group.name }));
        }
        let newTags = [];
        if (nextProps.searchTags) {
            newTags = nextProps.searchTags.map(tag => ({ id: tag.id, name: tag.tag }));
        }
        const newState = {
            searchGroups: newGroups,
            searchTags: newTags,
            selectedAssets: nextProps.selectedAssets,
            allSelected: false,
        };
        this.setState(newState, () => {
            if (shouldUpdateAssets) {
                this.loadNextPage(this.state.search, true);
            }
        });
    }

    filterChanged = (original, updated) => {
        if (!original) {
            return !updated || updated.length > 0;
        } else if (!updated) {
            return original.length > 0;
        }
        const originalNormalized = original.map(u => u.id);
        const updatedNormalized = updated.map(u => u.id);
        const diff = originalNormalized.filter(x => !updatedNormalized.includes(x))
            .concat(updatedNormalized.filter(x => !originalNormalized.includes(x)));
        return diff.length > 0;
    }

    loadNextPage(query, resetPage) {
        const {
            tableData,
            search,
            searchGroups,
            searchTags,
        } = this.state;
        const { assetFilter } = this.props;
        let listData = tableData.listData ? tableData.listData : [];
        let offset = 0;
        if ((query === search || !query) && !resetPage) {
            offset = listData.length;
        } else {
            listData = [];
        }
        if (this.props.isGrailsAPIReq) {
            getAssetsGrails(offset, 50, query, searchGroups, assetFilter, searchTags, true)
                .then((responseWithQuery) => {
                    // dont return old searches
                    if (responseWithQuery.query === this.state.search) {
                        return responseWithQuery.response.json();
                    }
                }).then((response) => {
                if (response && response.data) {
                    // remove 'public:' from tags
                    response.data = response.data.map(datum => {
                        const tags = datum['assetTags'];
                        if (tags) {
                            datum['assetTags'] = tags.split(",").map(tag => {
                                return tag.replace('public:', '');
                            }).join(', ');
                        }

                        return datum;
                    });

                    this.setState({
                        tableData: {
                            listData: listData.concat(response.data),
                            totalRecords: response.total,
                        },
                        page: this.state.page + 1,
                        isUpdating: false,
                        allSelected: false,
                    });
                } else {
                    this.setState({isUpdating: false});
                }
            });
        } else {
            getAssets(offset, 50, query, searchGroups, assetFilter, searchTags, true)
                .then((responseWithQuery) => {
                    // dont return old searches
                    if (responseWithQuery.query === this.state.search) {
                        return responseWithQuery.response.json();
                    }
                }).then((response) => {
                if (response && response.data) {

                    this.setState({
                        tableData: {
                            listData: listData.concat(response.data),
                            totalRecords: response.total,
                        },
                        page: this.state.page + 1,
                        isUpdating: false,
                        allSelected: false,
                    });
                } else {
                    this.setState({isUpdating: false});
                }
            });
        }
        const newState = { isUpdating: true, search: query };
        if (offset === 0) {
            newState.tableData = { listData: [], totalRecords: 0 };
        }
        this.setState(newState);
    }

    isSelected = (id) => {
        let selected = false;
        if (this.state.selectedAssets) {
            this.state.selectedAssets.forEach((asset) => {
                if (asset.assetGlobalId === id || asset.assetId === id || asset.id === id) {
                    selected = true;
                }
            });
        }
        return selected;
    }
    onTableScroll = (e) => {
        const { tableData, isUpdating } = this.state;
        if (e.target.scrollTop / e.target.scrollHeight > 0.7) {
            if (tableData.totalRecords > tableData.listData.length && !isUpdating) {
                this.loadNextPage();
            }
        }
    }
    // setting from Alert Type for custom-select option in assest-criteria
    headersArray = () => {
        if (this.props.isAlert) {
            return ['Asset', 'Serial Number'];
        }
        return ['Asset', 'Serial Number', 'Group', 'Tags'];
    }

    listHeaders = () => {
        // setting from Alert Type for custom-select option in assest-criteria
        if (this.props.isAlert) {
            return ([
                { assetName: this.headersArray()[0], show: true },
                { serialNumber: this.headersArray()[1], show: true },
            ]);
        }
        if (this.props.isGrailsAPIReq) {
            return ([
                { assetName: this.headersArray()[0], show: true },
                { serialNumber: this.headersArray()[1], show: true },
                { assetGroupName: this.headersArray()[2], show: true },
                { assetTags: this.headersArray()[3], show: true },
            ]);
        }
        return ([
            { name: this.headersArray()[0], show: true },
            { deviceSerialNumber: this.headersArray()[1], show: true },
            { assetGroupName: this.headersArray()[2], show: true },
            { tags: this.headersArray()[3], show: true },
        ]);
    }

    handleSearchChange = (e) => {
        const search = (e != null && e.target != null) ? e.target.value : e;
        this.setState({ search });
        if (search.length >= MIN_SEARCH_LENGTH || search.length === 0) {
            this.debounceFetch();
        }
    }

    onSelectAllClicked = () => {
        this.setState({ openDialog: true });
    }

    handleClose = () => {
        this.setState({ openDialog: false });
    }

    confirmSelectAll = () => {
        this.props.onAssetsSelected(!this.state.allSelected, this.state.tableData.listData);
        this.setState({ openDialog: false, allSelected: !this.state.allSelected });
    }

    getLoadingIndicator = () => (<AppLoader loaderStyle={styles.loader} height={30} width={3} />)

    render() {
        const { allSelected, isUpdating, tableData } = this.state;
        const totalLoaded = tableData.listData ? tableData.listData.length : '0';
        const totalAvailable = tableData.totalRecords || '0';
        return (
            <div >
                <div style={styles.assetsHeader}>
                    <span style={styles.headerTitle} >Available Assets </span>
                    <Tooltip title="Search">
                        <IconButton
                            aria-label="Search"
                            onClick={() => this.searchBoxRef.focus()}
                            size="large"
                        >
                            <img src={searchIcon} alt="search" />
                        </IconButton>
                    </Tooltip>
                    <SearchBox
                        data-qa="search-element"
                        inputRef={(input) => {
                            this.searchBoxRef = input;
                            return this.searchBoxRef;
                        }}
                        type="text"
                        style={{ flex: 1 }}
                        disableUnderline
                        onChange={this.handleSearchChange}
                    />
                    {isUpdating ? this.getLoadingIndicator() : null}
                </div>
                <span style={styles.totalText}>Showing {totalLoaded} of {totalAvailable} </span>
                <div style={{ flex: 1 }}>
                    <div style={styles.tableWrapper} onScroll={this.onTableScroll} >
                        <Table style={styles.resultsTable}>
                            <TableHead >
                                <TableRow >
                                    <TableCell sx={{ zIndex: 2 }} >
                                        <Checkbox
                                            checked={allSelected}
                                            onChange={this.onSelectAllClicked}
                                        />
                                    </TableCell>
                                    {this.headersArray().map(header => (
                                        <TableCell
                                            key={Math.random()}
                                            style={{ textTransform: 'capitalize' }}
                                            padding="normal"
                                            sx={{ zIndex: 2 }}
                                        >
                                            <span>
                                                {header}
                                            </span>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.listData ?
                                    tableData.listData.map((row) => {
                                        const isSelected =  this.props.isGrailsAPIReq ? this.isSelected(row.assetId) : this.isSelected(row.id)
                                        return (<ListTableRow
                                            data={row}
                                            key={Math.random()}
                                            listHeaders={this.listHeaders()}
                                            ischeckBox
                                            isSelected={isSelected}
                                            handleClick={(event) => {
                                                this.props.onAssetsSelected(event, [row]);
                                            }}
                                            handleDoubleClick={() => {}}
                                            index={this.state.tableData.listData.length || 0}
                                            userPermission="Modify"
                                            selectSingleRow={() => {}}
                                        />);
                                    })
                                    : null
                                }
                            </TableBody>
                        </Table>
                    </div>
                </div>
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{ fontSize: '16px' }}>
                            Are you sure you want to add all assets?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ p: 1.25, m: 1 }}>
                        <Button onClick={this.handleClose}>
                            No
                        </Button>
                        <Button onClick={this.confirmSelectAll} color="primary" variant="contained" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
export default AssetCriteriaSearchList;
