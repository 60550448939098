/* @flow */

export const USER_DATA: string = 'GET_USER_DATA';
export const USER_DATA_SUCCESS: string = 'USER_DATA_SUCCESS';
export const USER_DATA_ERROR: string = 'USER_DATA_ERROR';
export const USER_ACCOUNT_AUTH: string = 'USER_ACCOUNT_AUTH';
export const SET_ACCOUNT_SUCCESS: string = 'SET_ACCOUNT_SUCCESS';
export const SET_ACCOUNT_ERROR: string = 'SET_ACCOUNT_ERROR';
export const UPDATE_ACCOUNT_LOADER: string = 'UPDATE_ACCOUNT_LOADER';
export const UPDATE_ACCOUNTS_LOADER: string = 'UPDATE_ACCOUNTS_LOADER';
export const UA_GRAILS_SIGIN_ACCOUNT: string = 'UA_GRAILS_SIGIN_ACCOUNT';
export const UA_GRAILS_GET_USERSETTINGS: string = 'UA_GRAILS_GET_USERSETTINGS';
