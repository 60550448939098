/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_CLUSTERPOINTS } from './constants.home';
import {
    fetchClusterPointsSuccess,
    fetchClusterPointsError,
    getClusterExplodeSuccess,
} from './actions.home';
import config from '../../constants/Config';

type ObservarblesTypes = {
    getJSON: Function,
    of: Function
}

const formatData = (data: Object) => {
    const landmarkData = data;
    if (data.box) {
        landmarkData.landmarkShape = 'rectangle';
        landmarkData.landmarkBox = data.box;
    } else if (data.circle) {
        landmarkData.landmarkShape = 'circle';
        landmarkData.landmarkCircle = data.circle;
    } else if (data.polygon) {
        landmarkData.landmarkShape = 'polygon';
        landmarkData.landmarkPolygon = data.polygon;
    }
    landmarkData.point = data.marker;
    landmarkData.landmarkId = data.id;
    landmarkData.landmarkIcon = data.attributes ? data.attributes.lfLandmarkIcon : '';
    landmarkData.landmarkName = data.name || '';
    return landmarkData;
};

const formatAssetData = (data: Object) => {
    const assetData = data;
    assetData.assetName = data.name || '';
    assetData.assetId = data.id || '';
    assetData.assetType = data.type || '';
    assetData.assetIcon = data.attributes && data.attributes.icon ? data.attributes.icon : '';
    assetData.assetLabelColor = data.attributes && data.attributes.labelColor ? data.attributes.labelColor : '';
    return assetData;
};

export const getClusterPoints =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_CLUSTERPOINTS)
            .mergeMap((action) => {
                const {
                    top, left, bottom, right, filter, type,
                } = action.payload;

                const headers = {
                    'X-Nspire-UserToken': store.getState().userSession.userToken,
                };

                let url = `${config.get('FLEET_VIEW_SERVICES_URL')}/search/clustering?cb=${new Date().getTime()}`;

                if (top !== null && left !== null && bottom !== null && right !== null
                    && top > bottom && left < right) {
                    url = `${url}&top=${top}&left=${left}&bottom=${bottom}&right=${right}`;
                } else url = `${url}`;

                if (filter) url = `${url}${filter}`;
                return getJSON(url, headers).map((result) => {
                    if (result.content.landmarks &&
                            result.content.landmarks.data &&
                            result.content.landmarks.data.length > 0 &&
                            result.content.landmarkPoints &&
                            result.content.landmarkPoints.data
                    ) {
                        const landmarkData = formatData(result.content.landmarks.data.pop());
                        result.content.landmarkPoints.data.push(landmarkData);
                    }
                    if (result.content.assets &&
                            result.content.assets.data &&
                            result.content.assets.data.length > 0 &&
                            result.content.assetPoints &&
                            result.content.assetPoints.data
                    ) {
                        const assetData = formatAssetData(result.content.assets.data.pop());
                        result.content.assetPoints.data.push(assetData);
                    }

                    if (type === 'auto') {
                        return getClusterExplodeSuccess(
                            result.content.assetPoints,
                            result.content.landmarkPoints,
                            result.content.assetPointsCount,
                            result.content.landmarkPointsCount,
                        );
                    }

                    return fetchClusterPointsSuccess(
                        result.content.assetPoints,
                        result.content.landmarkPoints,
                        result.content.assetPointsCount,
                        result.content.landmarkPointsCount,
                    );
                })
                    .catch(error => of(fetchClusterPointsError(error)));
            });

export default combineEpics(getClusterPoints);

