/* @flow */

export const FETCH_SERVICE_PLANS_LIST: string = 'FETCH_SERVICE_PLANS_LIST';
export const FETCH_SERVICE_PLANS_LIST_SUCCESS: string = 'FETCH_SERVICE_PLANS_LIST_SUCCESS';
export const FETCH_SERVICE_PLANS_LIST_ERROR: string = 'FETCH_SERVICE_PLANS_LIST_ERROR';
export const RESET_SERVICE_PLANS_LIST: string = 'RESET_SERVICE_PLANS_LIST';
export const RESET_SERVICE_PLANS: string = 'RESET_SERVICE_PLANS';
export const SERVICE_PLAN_LIST_PREF_KEY: string = 'fleetLocate.servicePlans';
export type PlanListTypeAction = {
    type: string;
    payload?: Object;
}
