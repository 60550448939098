/* @flow */
import * as assetGroupConstants from './constants.assetGroupManage';

export const initialState = {
    assets: [],
    isUpdating: false,
    selectedAssets: [],
    isSelectedResultLoading: false,
    isPostAssetLoading: false,
    assetPostResponse: {},
    assetGroupDetails: {},
    assetGroupDeleteResponse: {},
    isAssetGroupDeleteing: false,
    isAssetGroupDetailsFetching: false,
    isError: false,
    addedSuccessfully: false,
    showLoader: false,
};

type stateType = {
    assets: Array<Object>,
    isUpdating: boolean,
    selectedAssets: Array<Object>,
    isSelectedResultLoading: boolean,
    isPostAssetLoading: boolean,
    assetPostResponse: Object,
    assetGroupDetails: Object,
    assetGroupDeleteResponse: Object,
    isAssetGroupDeleteing: boolean,
    isAssetGroupDetailsFetching: boolean,
    showLoader: boolean,
}
type actionType = {
    type: string,
    payload :{
        assets:Array<Object>,
        isUpdating: boolean,
        selectedAssets: Array<Object>,
        isSelectedResultLoading: boolean,
        isPostAssetLoading: boolean,
        postResponse: Object,
        assetGroupDetails: Object,
        assetGroupDeleteResponse: Object,
        isAssetGroupDeleteing: boolean,
        isAssetGroupDetailsFetching: boolean,
        error: string,
        showLoader: boolean,
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case assetGroupConstants.GRAILS_FETCH_ASSETS:
        return {
            ...state,
            isUpdating: true,
            isError: false,
            addedSuccessfully: false,
        };
    case assetGroupConstants.GRAILS_FETCH_ASSETS_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            assets: action.payload.assets,
            isError: false,
            addedSuccessfully: false,
        };
    case assetGroupConstants.GRAILS_FETCH_ASSETS_ERROR:
        return {
            ...state,
            isUpdating: false,
            isPostAssetLoading: false,
            error: action.payload.error,
            isError: true,
            addedSuccessfully: false,
        };
    case assetGroupConstants.SHOW_LOADER: return {
        ...state,
        isPostAssetLoading: action.payload.showLoader,
    };
    case assetGroupConstants.GRAILS_POST_ASSETS:
        return {
            ...state,
            isPostAssetLoading: true,
            isError: false,
            addedSuccessfully: false,
        };
    case assetGroupConstants.GRAILS_FETCH_SELECTED_ASSETS:
        return {
            ...state,
            isSelectedResultLoading: true,
            isError: false,
            addedSuccessfully: false,
        };
    case assetGroupConstants.GRAILS_FETCH_SELECTED_ASSETS_SUCCESS:
        return {
            ...state,
            isSelectedResultLoading: false,
            selectedAssets: action.payload.selectedAssets,
            isError: false,
            addedSuccessfully: false,
        };
    case assetGroupConstants.GRAILS_POST_ASSETS_GROUP_SUCCESS:
        return {
            ...state,
            assetPostResponse: action.payload.postResponse,
            isPostAssetLoading: false,
            isError: false,
            addedSuccessfully: true,
        };
    case assetGroupConstants.FETCH_ASSET_GROUP_DETAILS_GLOBALID:
        return {
            ...state,
            isAssetGroupDetailsFetching: true,
            isError: false,
            addedSuccessfully: false,
        };
    case assetGroupConstants.FETCH_ASSET_GROUP_DETAILS_GLOBALID_SUCCESS:
        return {
            ...state,
            isAssetGroupDetailsFetching: false,
            assetGroupDetails: action.payload.assetGroupDetails,
            isError: false,
            addedSuccessfully: false,
        };
    case assetGroupConstants.DELETE_ASSET_GROUP_GLOBALID:
        return {
            ...state,
            isAssetGroupDeleteing: true,
            isError: false,
            addedSuccessfully: false,
        };
    case assetGroupConstants.DELETE_ASSET_GROUP_GLOBALID_SUCCESS:
        return {
            ...state,
            assetGroupDeleteResponse: action.payload.assetGroupDeleteResponse,
            isAssetGroupDeleteing: false,
            isError: false,
            addedSuccessfully: false,
        };
    case assetGroupConstants.ASSET_AND_SELECTED_ASSET:
        return {
            ...state,
            isSelectedResultLoading: true,
            isError: false,
            addedSuccessfully: false,
        };
    case assetGroupConstants.GRAILS_FETCHASSETS_AND_SELECTED_ASSETS_SUCCESS:
        return {
            ...state,
            isSelectedResultLoading: false,
            selectedAssets: action.payload.selectedAssets,
            assets: action.payload.assets,
            isError: false,
            addedSuccessfully: false,
        };
    case assetGroupConstants.GRAILS_FETCHASSETS_AND_SELECTED_ASSETS_ERROR:
        return {
            ...state,
            isUpdating: false,
            isSelectedResultLoading: false,
            isError: false,
            addedSuccessfully: false,
        };
    case assetGroupConstants.CLOSE_DIALOG:
        return {
            ...state,
            isUpdating: false,
            isSelectedResultLoading: false,
            addedSuccessfully: false,
            isError: false,
            error: '',
        };
    default:
        return state;
    }
};
