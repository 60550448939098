/* @flow */
import React from 'react';
import { Card, CardHeader, Typography, Tooltip, Grid } from '@mui/material';
import GetSvgIcon from '../../../util/svgImage_util';
import customStyles from './EventCard.module.scss';
import { convertDateTime, getTimeFormatAsperUserSettting } from '../../../helper-classes/utility-functions';
import { EVENT_TYPE_MAPPING } from './constants';
import analytics from '../../../analytics/index';
import { DASHCAM_ANALYTICS, DASHCAM_EVENT_VIDEO, DASHCAM_EVENT_SNAPSHOT, DATE_RANGE, DASHCAM_EVENT_VIEW } from '../../../containers/DashCam/constants.dashcam';

export type Props = {
    event: Object,
    setEventVideoPlayerEvent: Function,
    isAllDashcam: boolean,
    trackAnalytics: Function,
};

const EventCard = (props: Props) => {
    const { event, isAllDashcam } = props;
    const eventDateFormat = getTimeFormatAsperUserSettting() === '12h' ? 'hh:mm:ss A MMM DD YYYY' : 'HH:mm:ss MMM DD YYYY';
    const eventName = EVENT_TYPE_MAPPING[event.eventType].name;
    const dashcamName = isAllDashcam ? (event.dashcamName || event.imei) : '';
    const eventTypeIcon = EVENT_TYPE_MAPPING[event.eventType].icon;

    const trackAnalytics = (eventType: string) => {
        analytics.track(
            DASHCAM_ANALYTICS,
            {
                eventName: eventType,
                feature: DASHCAM_ANALYTICS,
            },
        );
    };

    const handleEventVideoClick = (jsEvent: Object) => {
        jsEvent.preventDefault();
        jsEvent.stopPropagation();
        props.setEventVideoPlayerEvent(event);
    };

    return (
        <div
            className={customStyles.event_card_holder}
            id={event.id}
        >
            <Card className={customStyles.eventCard}>
                <CardHeader
                    className={customStyles.eventCardHeader}
                    title={
                        <div>
                            <div className={customStyles.event_name_btn}>
                                <Grid container justifyContent="flex-start" alignItems="center">
                                    <Grid item xs={2} style={isAllDashcam ? { display: 'flex' } : {}}>
                                        <Tooltip
                                            title={eventName}
                                            disableFocusListener
                                        >
                                            <span>
                                                <GetSvgIcon
                                                    type={eventTypeIcon}
                                                    className={customStyles.event_icon}
                                                />
                                            </span>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={8} className={customStyles.event_name_container}>
                                        {dashcamName &&
                                        <Tooltip title={dashcamName} disableFocusListener>
                                            <Typography noWrap className={customStyles.event_name} component="h5" variant="h5">
                                                {dashcamName}
                                            </Typography>
                                        </Tooltip>
                                        }
                                        <Tooltip title={eventName} disableFocusListener>
                                            <Typography noWrap className={`${dashcamName ? customStyles.sub_header : ''} ${customStyles.event_name}`} component="h5" variant="h5">
                                                {eventName}
                                            </Typography>
                                        </Tooltip>
                                        <Typography
                                            noWrap
                                            variant="body1"
                                            color="textSecondary"
                                            className={customStyles.event_date}
                                        >
                                            {event.time &&
                                                convertDateTime(event.time, eventDateFormat)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {(event.files && event.files.length > 0 && event.files.find(fileElem => fileElem.fileType === 'video')) &&
                                            <Tooltip
                                                title="View Video"
                                                disableFocusListener
                                                className={customStyles.event_video_icon}
                                            >
                                                <span>
                                                    <GetSvgIcon
                                                        type="dashcamEvent-eventVideo"
                                                        onClick={(e) => {
                                                            trackAnalytics(DASHCAM_EVENT_VIDEO);
                                                            handleEventVideoClick(e);
                                                            props.trackAnalytics(DASHCAM_EVENT_VIEW, DATE_RANGE, '', 'Video');
                                                        }}
                                                    />
                                                </span>
                                            </Tooltip>
                                        }
                                        {(event.files && event.files.length > 0 && event.files.find(fileElem => fileElem.fileType === 'snapshot')) &&
                                            <Tooltip
                                                title="View Snapshot"
                                                disableFocusListener
                                                className={customStyles.event_video_icon}
                                            >
                                                <span>
                                                    <GetSvgIcon
                                                        type="dashcamEvent-eventSnapshot"
                                                        onClick={(e) => {
                                                            trackAnalytics(DASHCAM_EVENT_SNAPSHOT);
                                                            handleEventVideoClick(e);
                                                            props.trackAnalytics(DASHCAM_EVENT_VIEW, DATE_RANGE, '', 'Snapshot');
                                                        }}
                                                    />
                                                </span>
                                            </Tooltip>
                                        }

                                    </Grid>
                                </Grid>

                            </div>
                        </div>
                    }
                />
            </Card>
        </div>
    );
};

export default EventCard;
