/* @flow */

export const FETCH_RECORDS_LIST: string = 'FETCH_RECORDS_LIST';
export const FETCH_RECORDS_LIST_SUCCESS: string = 'FETCH_RECORDS_LIST_SUCCESS';
export const FETCH_RECORDS_LIST_ERROR: string = 'FETCH_RECORDS_LIST_ERROR';
export const RESET_RECORDS_LIST: string = 'RESET_RECORDS_LIST';
export const RECORD_LIST_PREF_KEY: string = 'fleetLocate.recordList';
export const RECORD_LIST_ASSET_EDIT_PREF_KEY: string = 'fleetLocate.recordListAssetEdit';
export const SAVE_BUTTON_CLICK_TIMEOUT : number = 2000;
export type RecordListTypeAction = {
    type: string;
    payload?: Object;
}
