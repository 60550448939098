/**
* @example Sample Usage:
*   <PhoneNumber
        handleChange={e => this.handleChange('phoneNumber', e)}
        errorText="Please Enter A Vaild Phone Number"
        value={phoneNumber}
    />
    @prop handleChange callback function will invoke only for a valid number
    @prop errorText can customize error msg by passing from parent component
    Please don't remove defult value
    @prop value for by defult value to show in the text field
*/


import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import MaskedInput from 'react-text-mask';
import {
    FormControl,
    Input,
    FormHelperText,
} from '@mui/material';


type Props = {
    classes: Object,
    handleChange: Function,
    errorText: string,
    margin: string,
    value: string,
}

type State = {
    phoneNumber: string,
    isPhoneNumberError: boolean,
}

const styles = () => ({
    textField: {
        width: '100%',
        marginTop: 0,
        whiteSpace: 'nowrap',
    },
});

class PhoneNumber extends Component<Props, State> {
    inputRef: any;
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: this.props.value || '',
            isPhoneNumberError: this.validatePhoneNumber(this.props.value, false),
        };
        this.inputRef = React.createRef();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.phoneNumber) {
            this.setState({
                phoneNumber: nextProps.value,
                isPhoneNumberError: this.validatePhoneNumber(nextProps.value, false),
            });
        }
    }

    TextMaskCustom = (props: any) => {
        const {
            ...other
        } = props;
        if (other.inputRef) {
            delete other.inputRef;
        }
        const mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        return (
            <MaskedInput
                {...other}
                mask={mask}
                placeholderChar={'\u2000'}
            />
        );
    }

    validatePhoneNumber = (phoneNumber, updateState) => {
        let validPhoneNumber = false;
        if (phoneNumber) {
            if (typeof phoneNumber === 'string' && phoneNumber.length > 0) {
                // eslint-disable-next-line
                validPhoneNumber = !phoneNumber.match(/^[+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g);
            }
        }
        if (updateState) {
            this.setState({ isPhoneNumberError: validPhoneNumber });
            if (!validPhoneNumber) {
                this.handleChange(phoneNumber, true);
            }
        }
        return validPhoneNumber;
    }

    handleChange = (phoneNumber, updateNumber) => {
        let newPhoneNumber = phoneNumber;
        if (!phoneNumber) newPhoneNumber = '';
        this.setState({ phoneNumber: newPhoneNumber });
        const { isPhoneNumberError } = this.state;
        if (!isPhoneNumberError || !newPhoneNumber || updateNumber) {
            newPhoneNumber = newPhoneNumber.replace(/[^\d]*/g, '');
            if (newPhoneNumber && newPhoneNumber.length >= 10) {
                this.props.handleChange(newPhoneNumber);
            } else if (!newPhoneNumber) {
                this.props.handleChange('');
            }
        }
    }

    render() {
        const { phoneNumber, isPhoneNumberError } = this.state;
        const {
            classes,
            errorText = 'Please Enter A Vaild Phone Number',
            margin = 'none',
        } = this.props;
        return (
            <FormControl style={{ width: '100%' }}>
                <Input
                    label="Phone Number"
                    name="phoneNumber"
                    value={phoneNumber}
                    className={classes.textField}
                    margin={margin}
                    placeholder="(555) 555-5555"
                    onKeyUp={() => this.validatePhoneNumber(phoneNumber, true)}
                    inputComponent={this.TextMaskCustom}
                    onChange={e => this.handleChange(e.target.value, false)}
                    error={isPhoneNumberError}
                />
                {isPhoneNumberError &&
                    <FormHelperText id="component-error-text" error>{errorText}</FormHelperText>
                }
            </FormControl>
        );
    }
}

export default withStyles(styles, { withTheme: true })(PhoneNumber);
