import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import styles from '../../Steppers//AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';

type Props = {
    formData: {
        config: {
            realert: Boolean,
            realertDays: Number,
        },
    },
    handleTextboxChange: Function,
    handleCheckboxChange: Function,
};

class EngineOilLifeAlert extends React.Component<Props> {
    state = {};

    render() {
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    <div className={styles['step-rightside']}>
                        <form noValidate autoComplete="off">
                            <div>
                                <div>{ALERT_DETAILS.LABEL.ENGINE_OIL_LIFE_20}</div>

                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                id="realert"
                                                name="realert"
                                                value="realert"
                                                checked={
                                                    this.props.formData.config.realert === true
                                                }
                                                onChange={(e) => {
                                                    this.props.handleCheckboxChange('realert')(e);
                                                    if (!e.target.checked) {
                                                        this.props
                                                            .handleTextboxChange('realertDays')({
                                                                target: {
                                                                    value:
                                                                        ALERT_DETAILS.VALUE.EO_DEF,
                                                                },
                                                            });
                                                    }
                                                }}
                                            />
                                        }
                                        label={ALERT_DETAILS.LABEL.ENGINE_OIL_LIFE_REALERT}
                                    />
                                    <div className={styles['left-pad']}>
                                        <TextField
                                            required
                                            className={styles['text-wid']}
                                            id="realertDays"
                                            name="realertDays"
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    min: ALERT_DETAILS.VALUE.EO_MIN,
                                                    max: ALERT_DETAILS.VALUE.EO_MAX,
                                                },
                                            }}
                                            disabled={!this.props.formData.config.realert}
                                            value={this.props.formData.config.realertDays}
                                            onChange={this.props.handleTextboxChange('realertDays')}
                                        />
                                        <span className={styles['unit-Name-style']}>{ALERT_DETAILS.LABEL.ENGINE_OIL_LIFE_DAYS}</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default EngineOilLifeAlert;
