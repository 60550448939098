import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import { NavLink } from 'react-router-dom';
import ImageWrapper from './AlertIcons';
import customstyles from './AlertLandingPage.module.scss';

type Props = {
    data: Object,
};

class AlertType extends React.Component<Props> {
    props: Props;
    state = {};
    render() {
        return (
            <Grid item id={'div'.concat(this.props.data.idPostfix)} className={customstyles['col-md-5ths']}>
                <NavLink
                    exact
                    to={`/alert-types/create-alert?alertType=${this.props.data.code}`}
                    style={{ textDecoration: 'none' }}
                >
                    <Card className={customstyles['alertimg-container']}>
                        <div>
                            <ImageWrapper dataitemid={this.props.data} />
                        </div>
                        <CardActions id={'crd'.concat(this.props.data.idPostfix)} className={customstyles['alert-action']}>
                            <p id={'p'.concat(this.props.data.idPostfix)} className={customstyles['alert-name']}>{this.props.data.name}</p>
                        </CardActions>
                    </Card>
                </NavLink>
            </Grid>
        );
    }
}
export default AlertType;
