/* @flow */
import { FETCH_MAINTENANCE_DIAGNOSTICS_LIST, FETCH_MAINTENANCE_DIAGNOSTICS_LIST_SUCCESS, FETCH_MAINTENANCE_DIAGNOSTICS_LIST_ERROR, RESET_MAINTENANCE_DIAGNOSTICS_LIST } from './constants.diagnosticList';
import type { DiagnosticListTypeAction } from './constants.diagnosticList';

export const fetchDiagnosticList = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filtersToSend: Array<{}>,
    limit: number,
): DiagnosticListTypeAction => ({
    type: FETCH_MAINTENANCE_DIAGNOSTICS_LIST,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
        filtersToSend,
        limit,
    },
});

export const fetchDiagnosticListSuccess =
    (records: string, totalRecords: number): DiagnosticListTypeAction => ({
        type: FETCH_MAINTENANCE_DIAGNOSTICS_LIST_SUCCESS,
        payload: { records, totalRecords },
    });

export const fetchDiagnosticListError =
    (error: string): DiagnosticListTypeAction => ({
        type: FETCH_MAINTENANCE_DIAGNOSTICS_LIST_ERROR,
        payload: { error },
    });

export const resetTable =
    (): DiagnosticListTypeAction => ({
        type: RESET_MAINTENANCE_DIAGNOSTICS_LIST,
    });

