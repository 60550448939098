/* @flow */
import React, { Component } from 'react';
import MapsWrapper from '../../containers/MapsWrapper';
import RightDrawer from '../SharedComponents/RightDrawer/RightDrawer';
import NearestAssetLandmarkDetails from '../SharedComponents/DirectionsComponent/NearestAssetLandmarkDetails';
import { formatAddress, isEmpty } from '../../helper-classes/utility-functions';
import { getAssetColorFromStatus, getAssetIconFromType } from '../../util/trip_util';
import AppLoader from '../AppLoader';
import { getAddressFromCoordinates, getDirectionFromCoordinates } from '../../util/map_utils';

export type Props = {
    history: Object,
    assetData: Object;
    landmark: Object,
    landmarkId: string,
};

export type State = {
    directions: Object,
    directionsDetails: Object,
    isUpdating: boolean,
    landmarkData: Object,
};

class Direction extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            directions: {},
            directionsDetails: {},
            isUpdating: true,
            landmarkData: {},
        };
    }

    setDirectionDetails = () => {
        this.setState({ isUpdating: true });
        const { landmark } = this.props;
        this.getAddressFromCoordinates(landmark);
        const origin = {
            lat: this.props.assetData.latitude,
            lng: this.props.assetData.longitude,
        };
        const destination = {
            lat: landmark.lat,
            lng: landmark.lng,
        };
        const coordinates = { origin, destination };
        getDirectionFromCoordinates(coordinates, this.setDirection);
    }

    setDirection = (data: Object) => {
        if (!isEmpty(data)) {
            const directionsDetails = this.getDirectionsDetails(data.routes[0].legs[0]);
            this.setState({ directions: data, directionsDetails, isUpdating: false });
        } else {
            this.setState({ directions: data, isUpdating: false });
        }
    }

    getAddressFromCoordinates = (landmark: Object) => {
        if (this.props.landmarkId) {
            this.setState({ landmarkData: landmark });
        } else {
            getAddressFromCoordinates(landmark, this.setAddress);
        }
    }

    setAddress = (data: Object) => {
        if (!isEmpty(data)) {
            const address = formatAddress(data);
            const addressLine = [address.street, address.region, address.city, address.country].filter(s => s).join(', ');
            this.setState({
                landmarkData: {
                    addressLine1: address.home,
                    addressLine2: addressLine,
                },
            });
        }
    }

    getDirectionsDetails = (routesInfo: Object) => ({
        startLocation: routesInfo.start_address,
        endLocation: routesInfo.end_address,
        duration: routesInfo.duration.text || '',
        distance: routesInfo.distance.text || '',
        steps: this.getStepsDetails(routesInfo.steps),
    });

    getStepsDetails = (stepsInfo: Object) => {
        const stepsDetails = [];
        let i = 0;
        stepsInfo.forEach((step) => {
            stepsDetails.push({
                distance: step.distance.text || '',
                duration: step.duration.text || '',
                text: step.instructions ? `${i += 1}. ${step.instructions}` : '',
            });
        });
        return stepsDetails;
    };

    getIcons = () => {
        let icons = [];
        if (!isEmpty(this.props.assetData)) {
            const color = getAssetColorFromStatus(this.props.assetData);
            const labelColor = this.props.assetData.labelColor || color;
            icons = [{
                color,
                labelColor,
                image: { ...getAssetIconFromType(this.props.assetData.assetType) },
            }];
        }
        return icons;
    }

    render() {
        const { assetData, landmark } = this.props;
        const { landmarkData } = this.state;

        return (
            <React.Fragment>
                {assetData &&
                    <React.Fragment>
                        <MapsWrapper
                            coordinates={{
                                lat: landmark.lat,
                                lng: landmark.lng,
                            }}
                            markers={{
                                shape: {
                                    type: 'nearestassetlandmark',
                                    location: this.getIcons(),
                                },
                                directions: this.state.directions,
                                landmark: {
                                    markerLat: landmark.lat,
                                    markerLng: landmark.lng,
                                    ...landmarkData,
                                },
                                asset: assetData,
                                redirectTo: this.props.history.push,
                            }}
                            isLoaded={this.setDirectionDetails}
                            moduleName="nearest-asset"
                        />
                        {!this.state.isUpdating &&
                            <RightDrawer showDrawer>
                                <NearestAssetLandmarkDetails
                                    pathName="nearest-asset"
                                    landmarksData={{
                                        address: landmarkData.addressLine2,
                                        id: '',
                                        asset: '',
                                        name: landmarkData.addressLine1,
                                        ...landmarkData,
                                    }}
                                    assetData={assetData}
                                    directionsDetails={this.state.directionsDetails}
                                    history={this.props.history}
                                    assetToLandmark
                                />
                            </RightDrawer>
                        }
                    </React.Fragment>
                }
                {this.state.isUpdating &&
                    <AppLoader type="fullScreen" />
                }
            </React.Fragment>
        );
    }
}

export default Direction;
