/* @flow */
/* eslint-disable */
import { combineEpics } from 'redux-observable';
import { FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST } from './constants.landmarks';
import { fetchAlertCreationLandmarkGroupsListError, fetchAlertCreationLandmarkGroupsListSuccess } from './actions.landmarks';
import { getAuthorizationHeader, getGrailsAppBasedURL } from "../../../helper-classes/utility-functions";

type GetObservarblesTypes = {
    getJSON: Function,
    of: Function,
};

const flattenGroups = (response) => {
    const groups = [];
    if (response && response.children) {
        const addCandidate = (candidate, nestLevel) => {
            const nodes = candidate.children ? candidate.children.map(c => c.name) : [];
            groups.push({
                id: candidate.id,
                name: candidate.name,
                nestLevel,
                childNodes: nodes,
            });
        };
        const addChildren = (children, nestLevel) => {
            children.forEach((child) => {
                addCandidate(child, nestLevel);
                if (child.children) {
                    addChildren(child.children, nestLevel + 1);
                }
            });
        };
        response.children.forEach((candidate) => {
            addCandidate(candidate, 0);
            if (candidate.children) {
                addChildren(candidate.children, 1);
            }
        });
    }
    return groups;
};

const getHeaders = () => {
    const headers = {
        Authorization: getAuthorizationHeader(),
    };
    return headers;
};

export const fetchLandmarkGroupList = (
    actions$: Function,
    store: Object,
    { getJSON, of }: GetObservarblesTypes,
) =>
    actions$
        .ofType(FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST)
        .mergeMap(() => {
            const headers = getHeaders();
            return getJSON(
                `${getGrailsAppBasedURL()}/tree/viewLandmarkGroups.json`,
                headers,
            )
                .map(result => fetchAlertCreationLandmarkGroupsListSuccess(flattenGroups(result)))
                .catch(error => of(fetchAlertCreationLandmarkGroupsListError(error)));
        });

export default combineEpics(fetchLandmarkGroupList);
