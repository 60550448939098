/* @flow */

export const FETCH_ASSETGROUP: string = 'FETCH_ASSETGROUP';
export const FETCH_ASSETGROUP_SUCCESS: string = 'FETCH_ASSETGROUP_SUCCESS';
export const FETCH_ASSETGROUP_ERROR: string = 'FETCH_ASSETGROUP_ERROR';
export const SEARCH_ASSETGROUP: string = 'SEARCH_ASSETGROUP';
export const SEARCH_ASSETGROUP_SUCCESS: string = 'SEARCH_ASSETGROUP_SUCCESS';
export const SEARCH_ASSETGROUP_ERROR: string = 'SEARCH_ASSETGROUP_ERROR';
export const PLEASE_ENTER_ATLEAST_THREE_CHARACTERS: string = 'Please enter at least 3 characters';
export const NO_RESULTS_FOUND: string = 'No Results Found';
export const UPDATE_ASSETGROUP_LOADER: string = 'UPDATE_ASSETGROUP_LOADER';
export const PAGE_SIZE: number = 50;
export type AssetGroupTypeAction = {
    type: string;
    payload?: Object;
}
export const filterData = [{
    key: 'status',
    title: 'Status',
    value: [{
        type: 'Checkbox',
        value: 'moving',
        checked: false,
        label: 'Moving',
    }, {
        type: 'Checkbox',
        value: 'stopped',
        checked: false,
        label: 'Stopped',
    },
    {
        type: 'Checkbox',
        value: 'idle',
        checked: false,
        label: 'Idle',
    }],
}, {
    key: 'reportingStatus',
    title: 'Reporting Status',
    value: [{
        type: 'Checkbox',
        value: 'reporting',
        checked: false,
        label: 'Reporting',
    }, {
        type: 'Checkbox',
        value: 'non-reporting',
        checked: false,
        label: 'Non-Reporting',
    }],
}, {
    key: 'landmarkName',
    title: 'Landmark',
    value: [{
        type: 'Checkbox',
        value: 'yes',
        checked: false,
        label: 'In Landmark',
    }, {
        type: 'Checkbox',
        value: 'no',
        checked: false,
        label: 'Not in Landmark',
    }],
}];
