/* @flow */
import { FETCH_MAINTENANCE_ALERTS_LIST, FETCH_MAINTENANCE_ALERTS_LIST_SUCCESS, FETCH_MAINTENANCE_ALERTS_LIST_ERROR, RESET_MAINTENANCE_ALERTS_LIST } from './constants.alertList';

const initialState = {
    alerts: [],
    isUpdating: false,
};

type stateType = {
    alerts: Array<Object>,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        alerts:{},
        totalAlerts: number
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_MAINTENANCE_ALERTS_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case RESET_MAINTENANCE_ALERTS_LIST:
        return {
            ...state,
            isUpdating: false,
            alerts: [],
            totalAlerts: 0,
        };
    case FETCH_MAINTENANCE_ALERTS_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            alerts: action.payload.alerts,
            totalAlerts: action.payload.totalAlerts,
        };
    case FETCH_MAINTENANCE_ALERTS_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    default:
        return state;
    }
};
