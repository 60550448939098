/* @flow */
import React, { Component } from 'react';
import { ScaleLoader } from 'react-spinners';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import * as Constants from '../../containers/Settings/constants.settings';
import styles from './UserAccount.module.scss';
import { isValidEmail, isValidPhone, getAppUserSettings, updateAppUserSettings } from '../../helper-classes/utility-functions';


type Props = {
    accountDetail: {
        firstName: string,
        username: string,
        lastName: string,
        email: string,
        id: string,
        phone: string,
    },
    buttonClicked: boolean,
    updateAccountDetails: any,
    saveAccountDetail: Function,
    disableBackButton: Function,
};
type State = {
    displayMsg: string,
    displayMsgClass: string,
    buttonClicked: boolean,
    firstName: string,
    username: string,
    lastName: string,
    email: string,
    phone: string,
};

class AccountDetails extends Component<Props, State> {
    firstNameRef: any;
    lastNameRef: any;
    emailRef: any;
    phoneRef: any;
    handleChange: Function;
    resetData: Function;
    constructor(props: Props) {
        super(props);
        this.state = {
            displayMsg: '',
            displayMsgClass: 'display-msg-error',
            buttonClicked: false,
            firstName: this.props.accountDetail.firstName,
            username: this.props.accountDetail.username,
            lastName: this.props.accountDetail.lastName,
            email: this.props.accountDetail.email,
            phone: this.props.accountDetail.phone,
        };
        this.handleChange = this.handleChange.bind(this);
        this.resetData = this.resetData.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { updateAccountDetails, buttonClicked } = nextProps;
        if (updateAccountDetails === true) {
            this.updateLocalStorage();
            this.setState({ displayMsg: Constants.UPDATED_SUCCESSFULLY, buttonClicked, displayMsgClass: 'display-msg-success' });
            this.hideErrorMessage();
            this.props.disableBackButton(false);
        } else if (updateAccountDetails === false) {
            this.setState({ displayMsg: Constants.FAILED_TO_UPDATE_DATA, buttonClicked });
            this.props.disableBackButton(false);
        }
    }

    updateLocalStorage() {
        this.props.accountDetail.firstName = this.state.firstName;
        this.props.accountDetail.lastName = this.state.lastName;
        this.props.accountDetail.email = this.state.email;
        this.props.accountDetail.phone = this.state.phone;
        const userSettings = getAppUserSettings();
        userSettings.firstName = this.props.accountDetail.firstName;
        userSettings.lastName = this.props.accountDetail.lastName;
        userSettings.fName = this.props.accountDetail.firstName;
        userSettings.lName = this.props.accountDetail.lastName;
        userSettings.email = this.props.accountDetail.email;
        userSettings.phone = this.props.accountDetail.phone;
        updateAppUserSettings(userSettings);
    }

    handleChange(key: string, value: string) {
        this.setState({ [key]: value, displayMsg: '' });
    }

    saveData = () => {
        const {
            firstName, lastName, email, phone,
        } = this.state;
        if (firstName.length === 0) {
            this.setState({
                displayMsg: Constants.PLEASE_ENTER_FIRSTNAME,
            });
        } else if (lastName.length === 0) {
            this.setState({
                displayMsg: Constants.PLEASE_ENTER_LASTNAME,
            });
        } else if (!(isValidEmail(email))) {
            this.setState({
                displayMsg: Constants.PLEASE_ENTER_VALID_EMAIL,
            });
        } else if (phone.length > 0 && !(isValidPhone(phone))) {
            this.setState({
                displayMsg: Constants.PLEASE_ENTER_VALID_PHONE,
            });
        } else if (phone === this.props.accountDetail.phone &&
            firstName === this.props.accountDetail.firstName &&
            lastName === this.props.accountDetail.lastName &&
            email === this.props.accountDetail.email
        ) {
            this.setState({
                displayMsg: Constants.NOTHING_TO_UPDATE,
                displayMsgClass: 'display-msg-error',
            });
        } else {
            this.setState({
                buttonClicked: true,
            });
            this.props.disableBackButton(true);
            this.props.saveAccountDetail({
                firstName,
                lastName,
                email,
                phone,
            }, this.props.accountDetail.id);
        }
    };

    hideErrorMessage() {
        setTimeout(() => {
            this.setState({ displayMsg: '', displayMsgClass: 'display-msg-error' });
        }, 10000);
    }

    resetData() {
        this.setState({
            displayMsg: '',
            buttonClicked: false,
            firstName: this.props.accountDetail.firstName,
            username: this.props.accountDetail.username,
            lastName: this.props.accountDetail.lastName,
            email: this.props.accountDetail.email,
            phone: this.props.accountDetail.phone,
            displayMsgClass: 'display-msg-error',
        });
    }

    render() {
        const {
            displayMsg,
            buttonClicked,
            displayMsgClass,
        } = this.state;
        const displayMsgCls = (!displayMsgClass ? 'display-msg-error' : displayMsgClass);
        return (
            <div id="account-details" className={styles['user-accounts-details']}>
                <div id="settings-container" className={styles['settings-body']}>
                    <div>
                        <Tooltip title={this.state.username} placement="top">
                            <h1 id="user-name-settings" className={styles['settings-heading']}>{this.state.username}</h1>
                        </Tooltip>
                    </div>
                    <Divider />
                    <div className={styles['settings-bottom-container']}>
                        <div className={styles['settings-input']} >
                            <div>
                                <span>
                                    First Name
                                </span>
                                <input
                                    type="text"
                                    ref={(input) => {
                                        this.firstNameRef = input;
                                        return this.firstNameRef;
                                    }}
                                    value={this.state.firstName}
                                    id="firstName"
                                    onChange={e => this.handleChange('firstName', e.target.value)}
                                />
                            </div>
                            <div>
                                <span>
                                    Last Name
                                </span>
                                <input
                                    type="text"
                                    id="lastName"
                                    ref={(input) => {
                                        this.lastNameRef = input;
                                        return this.lastNameRef;
                                    }}
                                    value={this.state.lastName}
                                    onChange={e => this.handleChange('lastName', e.target.value)}
                                />
                            </div>
                            <div>
                                <span>
                                    Email
                                </span>
                                <input
                                    type="text"
                                    id="email"
                                    ref={(input) => {
                                        this.emailRef = input;
                                        return this.emailRef;
                                    }}
                                    value={this.state.email}
                                    onChange={e => this.handleChange('email', e.target.value)}
                                />
                            </div>
                            <div>
                                <span>
                                    Phone
                                </span>
                                <input
                                    type="text"
                                    id="phone"
                                    ref={(input) => {
                                        this.phoneRef = input;
                                        return this.phoneRef;
                                    }}
                                    value={this.state.phone}
                                    onChange={e => this.handleChange('phone', e.target.value)}
                                />
                            </div>
                        </div>
                        <div id="result-message" className={styles['display-msg']}>
                            {displayMsg &&
                                <div>
                                    <i id="display-icon" className={styles['display-icon']} />
                                    <span id="display-message" data-qa="displayMessage" className={styles[displayMsgCls]}>{displayMsg}</span>
                                </div>
                            }
                        </div>
                        <div className={styles['settings-footer']} >
                            <Button
                                type="button"
                                id="cancel"
                                data-qa="cancel"
                                className={styles['button-cancel']}
                                onClick={() => this.resetData()}
                                disabled={buttonClicked}
                            >
                                <span className={`${styles['cancel-button-text']}`}>Reset</span>
                            </Button>
                            <Button
                                type="button"
                                id="save-changes"
                                data-qa="save-changes"
                                className={styles['button-save-changes']}
                                onClick={() => this.saveData()}
                                disabled={buttonClicked}
                            >
                                {
                                    buttonClicked ?
                                        <div className={`${styles['sweet-loading']}`}>
                                            <ScaleLoader
                                                color="#ffffff"
                                                height={18}
                                            />
                                        </div> :
                                        <span className={`${styles['save-button-text']}`}>Save Changes</span>
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountDetails;
