/* @flow */
import { combineEpics } from 'redux-observable';
import { POST_ALERT_DATA } from './constants.stepers';
import { postAlertDataSuccess, postAlertDataError } from './actions.stepers';
import {
    postRecipientAlertDataSuccess,
    postRecipientAlertDataError,
} from '../AlertRecipient/actions.alertRecipient';
import {
    getAppToken,
    getAuthorizationHeader,
    getGrailsAppBasedURL,
} from '../../../helper-classes/utility-functions';

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    of: Function,
    put: Function,
};

export const postRecipientAlert = (data: any) => {
    const newData = data;
    const requestPayload = { recipients: JSON.stringify(newData.recipients.recipients) };
    const appToken = getAppToken();
    const headers = {
        'X-Nspire-AppToken': appToken,
        Authorization: getAuthorizationHeader(),
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    return newData
        .postJSON(
            `${getGrailsAppBasedURL()}/recipients/alertSpec/${
                newData.response.data.id
            }`,
            requestPayload,
            headers,
        )
        .map(result => postRecipientAlertDataSuccess(result))
        .catch((error) => {
            newData.of(postRecipientAlertDataError(error));
        });
};

export const postFormAlert = (data: any) => {
    if (data.saveAlert && data.saveAlert.id) {
        return data
            .put(
                `${getGrailsAppBasedURL()}/rest/json/alertSpec/${data.saveAlert.id}`,
                data.saveAlert,
                data.headers,
            )
            .mergeMap((result) => {
                const newData = { ...data, ...result };
                return postRecipientAlert(newData);
            })
            .catch(error => data.of(postAlertDataError(error)));
    }

    return data
        .postJSON(
            `${getGrailsAppBasedURL()}/rest/json/alertSpec`,
            data.saveAlert,
            data.headers,
        )
        .mergeMap((result) => {
            const newData = { ...data, ...result };
            postAlertDataSuccess(data);
            return postRecipientAlert(newData);
        })
        .catch(error => data.of(postAlertDataError(error)));
};

export const postAlertDataAPI = (
    actions$: Function,
    store: Object,
    {
        getJSON,
        of,
        postJSON,
        put,
    }: ObservarblesTypes,
) =>
    actions$.ofType(POST_ALERT_DATA).mergeMap((action) => {
        const appToken = getAppToken();
        const headers = {
            'X-Nspire-AppToken': appToken,
            Authorization: getAuthorizationHeader(),
            'Content-Type': 'application/json',
        };
        const data = {
            headers,
            getJSON,
            of,
            appToken,
            saveAlert: action.payload.saveAlert.formValues,
            recipients: action.payload.saveAlert.recipientValue,
            postJSON,
            put,
        };
        return postFormAlert(data);
    });

export default combineEpics(postAlertDataAPI);
