import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styles from '../../Steppers//AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';

class ExternalPowerOnAlert extends React.Component {
    state = {};

    render() {
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    <div className={styles['step-rightside']}>
                        <form noValidate autoComplete="off">
                            <div>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value="checkedB"
                                                color="primary"
                                                id="powerreconnect"
                                                name="powerreconnect"
                                                checked
                                                disabled
                                            />
                                        }
                                        label={ALERT_DETAILS.LABEL.POWER_RECONNECT}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default ExternalPowerOnAlert;
