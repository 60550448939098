/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_ACCOUNT_SETTINGS } from './constants.spudNotification';
import {
    fetchAccountSettingsSuccess,
    fetchAccountSettingsError,
} from './actions.spudNotification';
import config from '../../constants/Config';
import { getUserToken } from '../../helper-classes/utility-functions';

type ObservarblesTypes = {
    getJSON: Function,
    of: Function,
}

export const fetchAccountSettings =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_ACCOUNT_SETTINGS)
            .mergeMap(() => {
                const userToken = getUserToken();
                const headers = {
                    'X-Nspire-UserToken': userToken,
                };
                return getJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/accountSettings`, headers)
                    .map(result => fetchAccountSettingsSuccess(result))
                    .catch(error => of(fetchAccountSettingsError(error)));
            });

export default combineEpics(fetchAccountSettings);
