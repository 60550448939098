/* @flow */
import React, { Component } from 'react';
import { ScaleLoader } from 'react-spinners';
import Divider from '@mui/material/Divider';
// import Input from '@mui/material/Input';
import { Button, Select, MenuItem } from '@mui/material';
import styles from './UserAccount.module.scss';
import * as Constants from '../../containers/Settings/constants.settings';
import { getAppUserSettings, updateAppUserSettings, getDefaultPages, getTimeZones, getUnitsOfMeasurments, getTimeDisplays } from '../../helper-classes/utility-functions';

type Props = {
    appSettings: {
        defaultPage: string,
        tzDST: any,
        tzOffset: any,
        tz: string,
        userTz: string,
        userUnits: string,
        unitOfMeasure: string,
        timeDisplay: string,
        message: string,
    },
    buttonClicked: boolean,
    updatePreferences: any,
    saveAppSettings: Function,
    disableBackButton: Function,
};
type State = {
    buttonClicked: boolean,
    defaultPage: string,
    tz: string,
    tzDST: any,
    tzOffset: any,
    userTz: string,
    userUnits: string,
    unitOfMeasure: string,
    timeDisplay: string,
    displayMsg: string,
    displayMsgClass: string,
};

class AppSettings extends Component<Props, State> {
    defaultPageRef: any;
    timeZoneRef: any;
    unitOfMeasureRef: any;
    timeDisplayRef: any;
    handleChange: Function;
    handleTimeZoneChange: Function;
    resetData: Function;
    timeZoneData: Array<Object>;
    unitsOfMeasurmentData: Array<Object>;
    timeDisplayData: Array<Object>;

    constructor(props: Props) {
        super(props);
        this.state = {
            buttonClicked: false,
            defaultPage: this.props.appSettings.defaultPage || Constants.APP_DEFAULT_PAGE,
            tzDST: this.props.appSettings.tzDST,
            tzOffset: this.props.appSettings.tzOffset,
            tz: this.props.appSettings.tz,
            userTz: this.props.appSettings.userTz,
            userUnits: this.props.appSettings.userUnits,
            unitOfMeasure: '',
            timeDisplay: this.props.appSettings.timeDisplay,
            displayMsg: '',
            displayMsgClass: 'display-msg-error',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleTimeZoneChange = this.handleTimeZoneChange.bind(this);
        this.resetData = this.resetData.bind(this);
        this.unitsOfMeasurmentData = getUnitsOfMeasurments();
        this.timeDisplayData = getTimeDisplays();
        this.timeZoneData = getTimeZones();
    }

    UNSAFE_componentWillMount() {
        const userUnits = this.state.userUnits ? this.state.userUnits :
            getAppUserSettings().userUnits;
        let unitOfMeasure = '';
        if (this.unitsOfMeasurmentData) {
            const filteredValue = this.unitsOfMeasurmentData
                .filter(unit => unit.value === userUnits);
            if (filteredValue[0] && filteredValue[0].title) {
                unitOfMeasure = filteredValue[0].title;
                this.setState({ unitOfMeasure });
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { updatePreferences, buttonClicked } = nextProps;
        if (updatePreferences === true) {
            this.updateLocalStorage();
            this.setState({
                displayMsg: Constants.UPDATED_SUCCESSFULLY,
                buttonClicked,
                displayMsgClass: 'display-msg-success',
            });
            this.hideDisplayMessage();
            this.props.disableBackButton(false);
        } else if (updatePreferences === false) {
            this.setState({
                displayMsg: Constants.FAILED_TO_UPDATE_DATA,
                buttonClicked,
            });
            this.props.disableBackButton(false);
            // this.hideDisplayMessage();
        }
    }

    updateLocalStorage() {
        this.props.appSettings.defaultPage = this.state.defaultPage;
        this.props.appSettings.tzDST = this.state.tzDST;
        this.props.appSettings.tzOffset = this.state.tzOffset;
        this.props.appSettings.unitOfMeasure = this.state.unitOfMeasure;
        this.props.appSettings.timeDisplay = this.state.timeDisplay;
        this.props.appSettings.tz = this.state.tz;
        this.props.appSettings.userTz = this.state.userTz;
        this.props.appSettings.userUnits = this.state.userUnits;
        const userSettings = getAppUserSettings();
        if (!userSettings['app.settings']) {
            userSettings['app.settings'] = {};
        }
        if (!userSettings['app.settings'].value) {
            userSettings['app.settings'].value = {};
        }
        userSettings['app.settings'].value.defaultPage = this.props.appSettings.defaultPage;
        userSettings['app.settings'].value.tzOffset = this.props.appSettings.tzOffset;
        userSettings['app.settings'].value.tzDST = this.props.appSettings.tzDST;
        userSettings['app.settings'].value.tz = this.props.appSettings.tz;
        userSettings.userTz = this.props.appSettings.userTz;
        userSettings.userUnits = this.props.appSettings.userUnits;
        userSettings['app.settings'].value.units = this.unitsOfMeasurmentData.filter(unit => unit.value === this.state.userUnits)[0].value;
        userSettings['app.settings'].value.timeDisplay = this.props.appSettings.timeDisplay;
        updateAppUserSettings(userSettings);
    }

    setTimeZoneValue() {
        // check for tz key value, if tz valid (should be in given reference data)
        // then returning the tz value
        // logic commented for a bug raised, there the tz value is not updating in api response.
        // calculating the timezone by using only dst and offset
        if (this.state.userTz && this.state.userTz !== 'undefined' && this.state.userTz !== 'null') {
            let tz = '';
            this.timeZoneData.some((timeZone) => {
                if (timeZone.value === this.state.userTz) {
                    tz = timeZone.value;
                    return true;
                }
                return false;
            });
            if (tz) {
                return tz;
            }
        }
        return this.timeZoneData[0].value;
    }

    /**
     *  hide the display message after few seconds
     */
    hideDisplayMessage() {
        setTimeout(() => {
            this.setState({
                displayMsg: '',
            });
        }, 10000);
    }

    handleChange(key: string, value: string) {
        this.hideDisplayMessage();
        this.setState({
            [key]: value,
            displayMsg: '',
        });
    }

    saveData = () => {
        const {
            defaultPage, tz, userUnits, timeDisplay, tzOffset, tzDST, userTz,
        } = this.state;
        if (defaultPage === this.props.appSettings.defaultPage &&
            userTz === this.props.appSettings.userTz &&
            userUnits === this.props.appSettings.userUnits &&
            timeDisplay === this.props.appSettings.timeDisplay
        ) {
            this.setState({
                displayMsg: Constants.NOTHING_TO_UPDATE,
                displayMsgClass: 'display-msg-error',
                buttonClicked: false,
            });
            // this.hideDisplayMessage();
        } else {
            this.setState({
                buttonClicked: true,
                displayMsg: '',
            });
            this.props.disableBackButton(true);
            this.props.saveAppSettings({
                defaultPage,
                tz,
                tzDST,
                tzOffset,
                userTz,
                userUnits,
                timeDisplay,
            });
        }
    };

    resetData() {
        this.setState({
            buttonClicked: false,
            defaultPage: this.props.appSettings.defaultPage,
            tz: this.props.appSettings.userTz,
            tzDST: this.props.appSettings.tzDST,
            tzOffset: this.props.appSettings.tzOffset,
            userUnits: this.props.appSettings.userUnits,
            unitOfMeasure: this.props.appSettings.unitOfMeasure,
            timeDisplay: this.props.appSettings.timeDisplay,
            displayMsg: '',
            displayMsgClass: 'display-msg-error',
        });
    }

    handleTimeZoneChange(value: string) {
        this.timeZoneData.filter((timezone) => {
            if (timezone.value === value) {
                this.handleChange('tz', timezone.value);
                this.handleChange('tzDST', timezone.dst);
                this.handleChange('tzOffset', timezone.offset);
                this.handleChange('userTz', timezone.value);
            }
            return true;
        });
    }

    setUnits = () => {
        let unitValue = '';
        this.unitsOfMeasurmentData.some((unit) => {
            if (unit.value === this.state.userUnits) {
                unitValue = unit.value;
                return true;
            }
            return false;
        });
        if (!unitValue) {
            unitValue = this.unitsOfMeasurmentData[0].value;
        }
        return unitValue;
    };

    setTimeDisplay = () => {
        let displayValue = '';
        this.timeDisplayData.some((timedisplay) => {
            if (timedisplay.value === this.state.timeDisplay) {
                displayValue = timedisplay.value;
                return true;
            }
            return false;
        });
        if (!displayValue) {
            displayValue = this.timeDisplayData[0].value;
        }
        return displayValue;
    };

    render() {
        const {
            buttonClicked,
            displayMsg,
            displayMsgClass,
        } = this.state;
        const disableUnderline = true;
        const displayMsgCls = (!displayMsgClass ? 'display-msg-error' : displayMsgClass);
        return (
            <div id="account-details" className={styles['user-accounts-details']}>
                <div className={styles['settings-body']}>
                    <div>
                        <h1
                            id="user-name-settings"
                            className={styles['settings-heading']}
                        >Application Settings
                        </h1>
                    </div>
                    <Divider />
                    <div id="app-settings-container" className={styles['settings-input']}>
                        <div className={styles['settings-bottom-container']}>
                            <div>
                                <span>
                                    Default Page On Login
                                </span>
                                <Select
                                    disableUnderline={disableUnderline}
                                    type="text"
                                    ref={(input) => {
                                        this.defaultPageRef = input;
                                        return this.defaultPageRef;
                                    }}
                                    value={this.state.defaultPage ? this.state.defaultPage : 'map'}
                                    id="defaultPage"
                                    className={styles['settings-input-select']}
                                    classes={{
                                        select: styles['app-settings-select'],
                                    }}
                                    onChange={e => this.handleChange('defaultPage', e.target.value)}
                                >
                                    {getDefaultPages().map(page => (
                                        <MenuItem value={page.value} key={page.title}>
                                            {page.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <span>
                                    Time Zone
                                </span>
                                <Select
                                    disableUnderline={disableUnderline}
                                    type="text"
                                    ref={(input) => {
                                        this.timeZoneRef = input;
                                        return this.timeZoneRef;
                                    }}
                                    value={this.setTimeZoneValue()}
                                    id="timeZone"
                                    className={styles['settings-input-select']}
                                    classes={{
                                        select: styles['app-settings-select'],
                                    }}
                                    onChange={e => this.handleChange('userTz', e.target.value)}
                                >
                                    {this.timeZoneData.map(timeZone => (
                                        <MenuItem value={timeZone.value} key={timeZone.name}>
                                            {timeZone.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <span>
                                    Units Of Measurement
                                </span>
                                <Select
                                    disableUnderline={disableUnderline}
                                    type="text"
                                    ref={(input) => {
                                        this.unitOfMeasureRef = input;
                                        return this.unitOfMeasureRef;
                                    }}
                                    value={this.setUnits()}
                                    id="unitOfMeasure"
                                    className={styles['settings-input-select']}
                                    classes={{
                                        select: styles['app-settings-select'],
                                    }}
                                    onChange={e => this.handleChange('userUnits', e.target.value)}
                                >
                                    {this.unitsOfMeasurmentData.map(unit => (
                                        <MenuItem value={unit.value} key={unit.title}>
                                            {unit.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <span>
                                    Time Display
                                </span>
                                <Select
                                    disableUnderline={disableUnderline}
                                    type="text"
                                    ref={(input) => {
                                        this.timeDisplayRef = input;
                                        return this.timeDisplayRef;
                                    }}
                                    value={this.setTimeDisplay()}
                                    id="timeDisplay"
                                    className={styles['settings-input-select']}
                                    classes={{
                                        select: styles['app-settings-select'],
                                    }}
                                    onChange={e => this.handleChange('timeDisplay', e.target.value)}
                                >
                                    {this.timeDisplayData.map(timeDisplay => (
                                        <MenuItem value={timeDisplay.value} key={timeDisplay.title}>
                                            {timeDisplay.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div id="result-message" className={styles['display-msg']}>
                                {displayMsg &&
                                <div>
                                    <i id="display-icon" className={styles['display-icon']} />
                                    <span
                                        id="display-message"
                                        data-qa="displayMessage"
                                        className={styles[displayMsgCls]}
                                    >{displayMsg}
                                    </span>
                                </div>
                                }
                            </div>
                            <div className={styles['settings-footer']}>
                                <Button
                                    type="button"
                                    id="reset"
                                    data-qa="reset"
                                    className={styles['button-cancel']}
                                    onClick={() => this.resetData()}
                                    disabled={buttonClicked}
                                >
                                    <span className={`${styles['cancel-button-text']}`}>Reset</span>
                                </Button>
                                <Button
                                    type="button"
                                    id="save-changes"
                                    data-qa="save-changes"
                                    className={styles['button-save-changes']}
                                    onClick={() => this.saveData()}
                                    disabled={buttonClicked}
                                >
                                    {
                                        buttonClicked ?
                                            <div className={`${styles['sweet-loading']}`}>
                                                <ScaleLoader
                                                    color="#ffffff"
                                                    height={18}
                                                />
                                            </div> :
                                            <span className={`${styles['save-button-text']}`}>Save Changes</span>
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AppSettings;
