import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import RecordList from '../Maintenance/ServiceRecords/RecordList';
import DiagnosticList from '../Maintenance/Diagnostics/DiagnosticList';
import { getDistanceString, hasFeature } from '../../helper-classes/utility-functions';

export type Props = {
    classes: any,
    assetId: string,
}

const overrideStyle = {
    paper: { boxShadow: 'none', width: '100%', margin: '0' },
    tableWrapper: { height: 'auto', maxHeight: '73.6vh' },
    table: { minWidth: 0 },
};

const linkColor = '#007aff';

class MaintenanceCard extends React.Component<Props, {}> {
    serviceHistoryListHeaders = [
        { servicePlanName: 'Service Plan Name', show: true, disableSort: true },
        { serviceDate: 'Service Date', show: true },
        {
            odometer: 'Odometer',
            displayUnit: getDistanceString(),
            show: true,
            disableSort: true,
        },
        {
            engineHours: 'Engine Hours',
            displayUnit: 'hrs',
            show: true,
            disableSort: true,
        },
        { cost: 'Cost', show: true },
        { notes: 'Notes', show: true, disableSort: true },
    ];

    render() {
        const { classes, assetId } = this.props;
        const hideColumns = [{ key: 'assetName', value: 'Asset' }];
        return (
            <Paper>
                <Typography
                    className={classes.cardTitle}
                    style={{ marginTop: '10px', padding: '13px 26px 0px 26px' }}
                >Maintenance
                </Typography>
                {assetId &&
                <RecordList
                    tableTitle="Service History"
                    isEditable={false}
                    assetId={assetId}
                    customListHeaders={this.serviceHistoryListHeaders}
                    overrideStyle={overrideStyle}
                    noDataMessage={<NoServiceRecordsMessage />}
                    page="asset-edit"
                />
                }
                {assetId && hasFeature('fleetMaintenanceDtc') &&
                <div style={{ height: '1px', backgroundColor: 'rgba(224, 224, 224, 1)' }} />
                }
                {assetId && hasFeature('fleetMaintenanceDtc') &&
                <DiagnosticList
                    isEditable={false}
                    assetId={assetId}
                    overrideStyle={overrideStyle}
                    noDataMessage={<NoDiagnosticCodesMessage />}
                    hideColumns={hideColumns}
                    page="asset-edit"
                />}
            </Paper>
        );
    }
}

const NoServiceRecordsMessage = () => (
    <div style={{ paddingTop: '1.5vh', padding: '13px 26px 26px 26px' }}>
        <Typography variant="h6">Service History</Typography>
        <p>
            Your assets service history will be shown here after
            it has been logged in the maintenance module.
        </p>
        <p>
            If you have not yet set up a service plan for your
            asset you can go to the <Link style={{ color: linkColor, textDecoration: 'none' }} to="/plans">Service Plan</Link> section
            of the maintenance module to set one up.
        </p>
    </div>
);

const NoDiagnosticCodesMessage = () => (
    <div style={{ paddingTop: '1.5vh', padding: '13px 26px 26px 26px' }}>
        <Typography variant="h6">Diagnostics Codes</Typography>
        <p>
            If your asset experiences diagnostics troubleshooting codes (DTCs),
             they will be shown here.
        </p>
        <p>
            You can also see DTCs for any of your assets
             in the <Link style={{ color: linkColor, textDecoration: 'none' }} to="/diagnostics">Diagnostics</Link> section
             of the maintenance module.
        </p>
    </div>
);

export default MaintenanceCard;
