/* @flow */
export const SEND_COMMAND: string = 'SEND_COMMAND';
export const SEND_COMMAND_SUCCESS: string = 'SEND_COMMAND_SUCCESS';
export const SEND_COMMAND_ERROR: string = 'SEND_COMMAND_ERROR';
export const FETCH_ASSET_ALLOWED_CMDS: string = 'FETCH_ASSET_ALLOWED_CMDS';
export const FETCH_ASSET_ALLOWED_CMDS_SUCCESS: string = 'FETCH_ASSET_ALLOWED_CMDS_SUCCESS';
export const FETCH_ASSET_ALLOWED_CMDS_ERROR: string = 'FETCH_ASSET_ALLOWED_CMDS_ERROR';
export const COMMAND_STATUS_ERROR: string = 'COMMAND_STATUS_ERROR';
export const MAX_NUMBER_OF_CALLS: number = 10;
export const CALL_INTERVAL_IN_MS: number = 10000;
export const SUPPORTED_COMMANDS: Array<string> = ['locate', 'starterEnable', 'starterDisable', 'doorLock', 'encodeDoorUnlock'];
export type AssetSendCmdAction = {
    type: string;
    payload?: Object;
}
