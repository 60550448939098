/* @flow */

import { FETCH_LANDMARKS_LIST, FETCH_LANDMARKS_LIST_SUCCESS, FETCH_LANDMARKS_LIST_ERROR, RESET_LANDMARKS_LIST } from './constants.landmarksList';
import type { LandmarksListTypeAction } from './constants.landmarksList';

export const fetchLandmarksList = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filter: Object,
): LandmarksListTypeAction => ({
    type: FETCH_LANDMARKS_LIST,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
        filter,
    },
});

export const fetchLandmarksListSuccess =
    (landmarks: any, totalLandmarks: number): LandmarksListTypeAction => ({
        type: FETCH_LANDMARKS_LIST_SUCCESS,
        payload: { landmarks, totalLandmarks },
    });

export const fetchLandmarksListError = (error: string): LandmarksListTypeAction => ({
    type: FETCH_LANDMARKS_LIST_ERROR,
    payload: { error },
});

export const resetTable =
    (): LandmarksListTypeAction => ({
        type: RESET_LANDMARKS_LIST,
    });
