/* @flow */
import { combineEpics } from 'redux-observable';
import { ASSET_SEARCH } from './constants.assetSearch';
import { assetSearchSuccess, assetSearchError } from './actions.assetSearch';
import config from '../../../constants/Config';
import { getAppToken } from '../../../helper-classes/utility-functions';

type ObservarblesTypes = {
    postJSON: Function,
    of: Function,
}

export const assetSearch = (
    actions$: Function,
    store: Object,
    { postJSON, of }: ObservarblesTypes,
) =>
    actions$
        .ofType(ASSET_SEARCH)
        .distinctUntilChanged()
        .debounceTime(config.get('DEBOUNCE_TIME'))
        .mergeMap((action) => {
            const appToken = getAppToken();
            const headers = {
                'X-Nspire-UserToken': store.getState().userSession.userToken,
                'X-Nspire-AppToken': appToken,
                'Content-Type': 'application/json',
            };
            const { query, page, rowsPerPage } = action.payload;
            const data = {
                start: page * rowsPerPage,
                limit: rowsPerPage,
                filterParam: [['deviceId', 'exists:yes']],
                searchParams: [''],
            };
            if (query && query.length > 0 && typeof query === 'string') {
                data.searchParams = [query.replace(/[\s]{2,}/gi, ' ').trim()];
            } else {
                delete data.searchParams;
            }
            return postJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/assets`, data, headers)
                .map((res) => {
                    const r = res.response;
                    return assetSearchSuccess(r.data, r.count, r.total, query);
                })
                .catch(error => of(assetSearchError(error, {})));
        });

export default combineEpics(assetSearch);
