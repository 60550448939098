/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_RECIPIENT_LIST } from './constants.alertRecipient';
import {
    fetchRecipientListSuccess,
    fetchRecipientListError,
} from './actions.alertRecipient';
import { getAuthorizationHeader, getGrailsAppBasedURL } from '../../../helper-classes/utility-functions';

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    of: Function,
};

export const fetchRecipientListAPI = (
    actions$: Function,
    store: Object,
    { getJSON, of }: ObservarblesTypes,
) =>
    actions$.ofType(FETCH_RECIPIENT_LIST).mergeMap((action) => {
        const querytext = (action.payload.recipientKey !== undefined) ? action.payload.recipientKey.replace('#', '%23') : '';
        const headers = {
            Authorization: getAuthorizationHeader(),
        };
        return getJSON(
            `${getGrailsAppBasedURL()}/recipients/searchRecipients?query=${querytext}`,
            headers,
        )
            .map(result => fetchRecipientListSuccess(result))
            .catch(error => of(fetchRecipientListError(error)));
    });

export default combineEpics(fetchRecipientListAPI);
