/* @flow */
import React from 'react';
import Paper from '@mui/material/Paper';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.login';
import styles from './Login.module.scss';
import { getAppUserSettings, isEmpty } from '../../helper-classes/utility-functions';
import { APP_DEFAULT_PAGE } from '../../containers/Settings/constants.settings';
import AppLoader from '../../components/AppLoader';

type Props = {
    match:{
        path: string,
    },
    location: {
        search: string,
    },
    grailsSSOLogin: Function,
    error:{
        message: string,
        response:{
            error:string,
        }
    }
};

type State = {
    needToShowError: boolean,
    errorMessage: string,
};

class ImpersonationLogin extends React.Component<Props, State> {
    redirectUrl: Object;
    static defaultProps = {
        userSession: {
            loggedIn: false,
            loggingIn: false,
        },
    }

    constructor(props:Props) {
        super(props);
        this.state = {
            errorMessage: '',
            needToShowError: false,
        };
        this.redirectUrl = { from: { pathname: `/${APP_DEFAULT_PAGE}` } };
    }

    componentDidMount() {
        let error = '';
        if (this.props.match.path === '/auth/tokenLogin/') {
            const queryParams = queryString.parse(this.props.location.search);
            if (queryParams &&
                queryParams.authToken &&
                queryParams.authUsername &&
                queryParams.customerService &&
                queryParams.apiToken) {
                this.props.grailsSSOLogin(
                    queryParams.authToken,
                    queryParams.authUsername,
                    queryParams.customerService,
                    queryParams.apiToken,
                );
            } else {
                error = 'Invalid SSO Login Request';
                if (!queryParams.authToken) {
                    error = `${error}, Auth Token`;
                }
                if (!queryParams.authUsername) {
                    error = `${error}, User Name`;
                }
                if (!queryParams.customerService) {
                    error = `${error}, Customer Service`;
                }
                if (!queryParams.apiToken) {
                    error = `${error}, Api Token`;
                }
                error = `${error} is required.`;
                this.updateErrorMessageState(true, error);
            }
        }
    }

    updateErrorMessageState(needToShow, message) {
        this.setState({
            needToShowError: needToShow,
            errorMessage: message,
        });
    }

    render() {
        const { error } = this.props;
        const { needToShowError, errorMessage } = this.state;
        const defaultString = error || needToShowError ? 'Error Message' : 'Please Wait ......';
        const errorMessageValue = error && error.response && error.response.error ?
            error.response.error : errorMessage;
        const userSettings = getAppUserSettings();
        if (!isEmpty(userSettings)) {
            if (userSettings['app.settings']) {
                if (userSettings['app.settings'].value) {
                    if (userSettings['app.settings'].value.defaultPage !== '') {
                        this.redirectUrl = { from: { pathname: `/${userSettings['app.settings'].value.defaultPage}` } };
                    }
                }
            }
            return <Redirect to={this.redirectUrl.from} />;
        }

        return (
            <div className={`${styles['ssotoken-loading']}`}>
                <Paper elevation={6} className={`${styles['ssotoken-loading-container']}`}>
                    <Typography component="p">
                        { defaultString }
                    </Typography>
                    { errorMessageValue &&
                    <Typography className={styles.error} component="p">
                        { errorMessageValue }
                    </Typography>
                    }
                    {!needToShowError && !error &&
                        <div className={`${styles['ssotoken-loader']}`}>
                            <AppLoader
                                loaderStyle={styles.loader}
                                height={20}
                                width={3}
                            />
                        </div>
                    }
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userSession: state.userSession,
    error: state.userSession.error,
});

export default reduxConnect(ImpersonationLogin, actions, mapStateToProps);
