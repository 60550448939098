/* @flow... */
import React from 'react';

import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import ExternalSite from '../../components/ExternalSite/ExternalSite';
import { getGrailsAppBasedURL, getCookie } from '../../helper-classes/utility-functions';
import * as loginAction from '../Login/actions.login';
import { reduxConnect } from '../../hoc';
import { NSPIRE_USER_TOKEN_COOKIE_NAME } from '../Login/contants.login';

export type Props = {
    location: Object,
    history: {
        push: Function,
    },
    logout: Function,
};

export type State = {
};

class Reports extends React.Component<Props, State> {
    componentDidMount() {
    }

    getFleetLocateGrailsReportsLink = () => {
        if (!getCookie(NSPIRE_USER_TOKEN_COOKIE_NAME)) {
            return this.props.logout();
        }
        if (this.props.location && this.props.location.search.indexOf('?section=reports') !== -1) {
            return `${getGrailsAppBasedURL()}${this.props.location.search}&embed=1#3`;
        }
        return `${getGrailsAppBasedURL()}?embed=1#3`;
    }

    // eslint-disable-next-line react/sort-comp
    render() {
        return (
            <MiniDrawer
                redirectTo={this.props.history.push}
            >
                <ExternalSite url={`${this.getFleetLocateGrailsReportsLink()}`} delay={1500} />
            </MiniDrawer>
        );
    }
}

const mapDispatchToProps = {
    ...loginAction,
};

export default (reduxConnect(Reports, mapDispatchToProps));
