/* @flow */
import { getIframeUserToken, updateAppUserSettings, setLocalStorageItem } from './utility-functions';
import config from '../constants/Config';

export function getUserPreference(currentPageScope: any, key: string) {
    const $this = currentPageScope;
    const options = {
        headers: {
            'X-Nspire-UserToken': getIframeUserToken() || '',
        },
    };

    const p1 = new Promise((resolve) => {
        const url = `${config.get('PLATFORM_API_BASE_URL')}/preferences/fleetLocate/${key}`;
        resolve(fetch(url, options).then((response) => {
            if (response.ok) {
                return response.json();
            }
            return null;
        }));
    });

    // This is needed for analytics implementation on iframe pages.
    // This API call will not be fired for normal pages but only once for first iframe page
    const timeStamp = (new Date()).getTime();
    const p2 = new Promise((resolve) => {
        if ($this.isIframe) {
            resolve(fetch(`${config.get('PLATFORM_API_BASE_URL')}/identity?date=${timeStamp}`, options).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return { user: {}, account: {} };
            }));
        }
        resolve({ user: {}, account: {} });
    });

    Promise.all([p1, p2]).then((res) => {
        if (res[1] && res[1].user.id) {
            const { user = {}, account = {} } = res[1];
            const userSettings = {
                accountId: account.id || '',
                accountName: account.name || '',
                username: user.username,
                userId: user.id,
                email: user.email,
            };
            setLocalStorageItem('email', user.email || '');
            setLocalStorageItem('userid', user.id || '');
            setLocalStorageItem('username', user.username || '');
            setLocalStorageItem('currentAccountGlobalId', account.id);
            setLocalStorageItem('currentAccountName', account.name);
            updateAppUserSettings(userSettings);
        }

        if (res[0] && res[0].value) {
            const prefRes = res[0];
            $this.userPreferences = prefRes;
            let { rowsPerPage, order, orderBy } = $this.state;
            const savedData = JSON.parse(res[0].value);
            const columns = savedData.columns ? savedData.columns : [];
            const sortParam = columns.find(column => column.sort !== null && column.show === true);

            if (sortParam) {
                order = sortParam.sort;
                orderBy = sortParam.keys;
            }

            rowsPerPage = savedData.rowsPerPage ? savedData.rowsPerPage : rowsPerPage;
            const stateValuesToSet = {
                tableData: {
                    ...$this.state.tableData,
                    listHeaders: $this.getListHeader($this.props),
                },
                rowsPerPage,
                order,
                orderBy,
                showListing: true,
            };
            $this.setState(stateValuesToSet, () => $this.getData());
        } else {
            $this.setState({ showListing: true });
            $this.getData();
        }
    }).catch(() => {
        $this.setState({ showListing: true });
        $this.getData();
    }).finally(() => {
        if ($this.updateTrackAnalytics && typeof $this.updateTrackAnalytics === 'function') {
            $this.updateTrackAnalytics();
        }
    });
}

export const buildUserPrefernecesBody = (data: any, filter: Object) => {
    if (!data && !Array.isArray(data)) return {};
    const pageObject = {};
    pageObject.rowsPerPage = filter.rowsPerPage;
    pageObject.columns = data.map((d) => {
        const keys = Object.keys(d)[0];
        const sort = filter.orderBy === keys ? filter.order : null;
        return {
            keys,
            sort,
            width: d.width ? d.width : 0,
            show: d.show,
        };
    });
    return pageObject;
};

export function saveUserPreference(currentPageScope: any, key: string) {
    const $this = currentPageScope;
    let method = 'post';
    if ($this.userPreferences) {
        method = 'put';
    }
    const headers = $this.state.tableData.listHeaders;
    const filters = {
        order: $this.state.order || '',
        orderBy: $this.state.orderBy || '',
        rowsPerPage: $this.state.rowsPerPage || '',
    };
    const jsonValue = buildUserPrefernecesBody(headers, filters);
    const requestBody = {
        namespace: 'fleetLocate',
        key,
        value: JSON.stringify(jsonValue),
    };
    const options = {
        method,
        body: JSON.stringify(requestBody),
        headers: {
            'X-Nspire-UserToken': getIframeUserToken() || '',
            'Content-Type': 'application/json',
        },
    };
    let url = `${config.get('PLATFORM_API_BASE_URL')}/preferences`;
    if (method === 'put') {
        url = `${config.get('PLATFORM_API_BASE_URL')}/preferences/fleetLocate/${key}`;
    }
    fetch(url, options).then(response => response.json()).then((res) => {
        if (res && res.value) {
            $this.userPreferences = res;
        }
    });
}

export const getColumnPreferences = (key: string, preferences: any, options: any = null) => {
    if (preferences && preferences.columns) {
        const prefer = {};
        const foundColumn = preferences.columns.some((column, index) => {
            if (key === column.keys) {
                prefer.index = index;
                prefer.width = column.width;
                prefer.show = column.show;
                // will make the column remains hidden, if the
                // device feature for this column is off.
                if (options && options.show !== undefined) {
                    prefer.show = options.show;
                }
                return true;
            }
            return false;
        });
        if (!foundColumn) {
            // condition checking newly added columns which are not in the preferences
            prefer.index = preferences.columns.length;
            prefer.show = true;
            prefer.width = 0;
            if (options && options.show !== undefined) {
                prefer.show = options.show;
            }
        }
        if (prefer) {
            return prefer;
        }
    }
    let showColumn = true;
    if (options && options.show !== undefined) {
        showColumn = options.show;
    }
    return {
        show: showColumn,
        width: 0,
    };
};

export const getReArrangedTableHeaders = (columns: any) =>
    columns.sort((a, b) => a.index - b.index);

export const getParsedPreferencesValue = (preferences: any) => {
    if (preferences && preferences.value) {
        return JSON.parse(preferences.value);
    }
    return null;
};

export const showHideColumns = ($this: any, header: any, options: any) => {
    const { tableData } = $this.state;
    tableData.listHeaders.some((h) => {
        const newH = h;
        if (h[Object.keys(h)[0]] === header[Object.keys(header)[0]]) {
            newH.show = !header.show;
            return true;
        }
        return false;
    });
    $this.setState({
        tableData,
    }, () => {
        saveUserPreference($this, options.prefKey);
    });
};

export const rearrangeColumns = ($this: any, listHeaders: any, options: any) => {
    const { tableData } = $this.state;
    tableData.listHeaders = listHeaders;
    $this.setState({
        tableData,
    }, () => {
        saveUserPreference($this, options.prefKey);
    });
};

export const isUpdatePreference = ($this: any, options: Object) => {
    if (options.order !== $this.state.order ||
        options.orderBy !== $this.state.orderBy ||
        options.rowsPerPage !== $this.state.rowsPerPage) {
        return true;
    }
    return false;
};
