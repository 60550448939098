/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import { Tooltip, Grid, Switch, Typography, Paper, Select, MenuItem, TextField, DialogActions, Button } from '@mui/material';
import MomentUtils from '@date-io/moment';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import Checkbox from '@mui/material/Checkbox';
import customstyles from './style.module.scss';
import AppLoader from '../../components/AppLoader';
import { getConvertedStartDate, getConvertedEndDate, getAlertProperties } from '../../util/trip_util';
import {
    convertDateTime,
    getTimeFormatAsperUserSettting,
    manageLocStateData,
    saveUserPreference,
    getUserPreference,
    isEmpty,
    getLocalStorageValue,
} from '../../helper-classes/utility-functions';
import analytics from '../../analytics/index';
import GetSvgIcon from '../../util/svgImage_util';
import EventsList from '../Events/EventsList';
import TripsListComponent from './TripsListComponent';
import { FETCH_TRIPS_LIMIT, TRIP_EVENTS_PREF_KEY, TRIPS_VIEW_PREF_KEY, EVENT_HISTORY, EVENT_DRAWER_WIDTH, TRIP_DRAWER_WIDTH } from '../../containers/Assets/constants.assets';
import ShowHideColumnComponent from '../SharedComponents/ListComponent/ShowHideColumnComponent';
import getListHeader from '../Events/EventsListUtil';
import { showHideColumns } from '../../helper-classes/listUtil';

const styles = theme => ({
    minWidth: {
        width: 50,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        height: 'auto',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        height: 'auto',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        [theme.breakpoints.up('sm')]: {
            width: 60,
        },
    },
    root: {
        flexGrow: 1,
    },
    itemsContainer: {
        height: 'calc(100vh - 200px)',
        overflow: 'auto',
        boxShadow: 'none',
    },
    control: {
        padding: theme.spacing(2),
    },
    tripFooter: {
        height: 'auto',
    },
    timelineRoot: {
        width: '90%',
        marginLeft: '25%',
    },
    line: {
        borderLeft: '2px dashed rgb(15, 14, 14)',
    },
    left: {
        marginLeft: '-50%',
    },
    right: {
        marginLeft: '30%',
    },
    label: {
        fill: 'red',
    },
    distanceDetails: {
        paddingLeft: 7,
        float: 'left',
        paddingTop: 17,
        paddingBottom: 23,
    },
    footerAlerts: {
        width: '80%',
        paddingLeft: 10,
        float: 'left',
        color: 'rgba(146, 136, 136, 0.87)',
        fontWeight: 500,
        fontSize: 15,
        whiteSpace: 'normal',
    },
    bounds: {
        height: '48px',
        display: 'flex',
        backgroundColor: '#343e48',
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
    },
    historicalTrips: {
        border: 'none',
        flex: 'auto',
        outline: 'none',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '1.14',
        textAlign: 'center',
        display: 'block',
        color: 'white',
        padding: '16px',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    recentEvents: {
        width: '140px',
        border: 'none',
        flex: 'auto',
        outline: 'none',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '1.14',
        textAlign: 'center',
        color: '#ffffff',
        display: 'block',
        padding: '16px',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    time: {
        textAlign: 'right',
        fontSize: '10px',
        letterSpacing: '0.1px',
        color: 'rgba(0, 0, 0, 0.5)',
        marginTop: '5px',
        marginRight: '10px',
    },
    speed: {
        textAlign: 'right',
        fontSize: '10px',
        letterSpacing: '0.1px',
        color: '#d50000',
        marginTop: '5px',
        marginRight: '10px',
    },
    temperature: {
        textAlign: 'right',
        fontFamily: 'Roboto',
        fontSize: '10px',
        letterSpacing: '0.1px',
        color: 'rgba(0, 0, 0, 0.5)',
        marginTop: '5px',
        marginRight: '10px',
    },
    address: {
        width: '160px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: '15px',
        fontSize: '14px',
        lineHeight: '1.36',
        letterSpacing: '0.1px',
        color: 'rgba(0, 0, 0, 0.5)',
    },
    battery: {
        width: '162px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '14px',
        lineHeight: '1.36',
        letterSpacing: '0.1px',
        color: 'rgba(0, 0, 0, 0.5)',
    },
    lineNew: {
        width: '287.8px',
        height: '1px',
    },
    middle: {
        float: 'left',
        width: '60%',
        height: '80px',
        borderBottom: '1px solid #cccccc',
        marginTop: '13px',
    },
    last: {
        float: 'left',
        width: '21%',
        height: '80px',
        borderBottom: '1px solid #cccccc',
        marginTop: '13px',
    },
    todayYesterday: {
        textAlign: 'left',
        margin: '22px 0px 10px 8px',
        fontSize: '12px',
        fontWeight: '500',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    imgIcon: {
        objectFit: 'contain',
        opacity: '0.83',
        marginTop: '18px',
        marginLeft: '22px',
    },
    evType: {
        width: '162px',
        height: '19px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.2px',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    horizontal: {
        height: '1px',
        opacity: '0.15',
        backgroundColor: '#ffffff',
    },
    textContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
        maxWidth: 75,
    },
    satIcon: {
        width: 12,
        height: 16,
        marginBottom: -4,
    },
    tirePressure: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

const customMenuItemStyles = {
    height: '39px',
    paddingTop: '0px',
    '&.MuiMenuItem-root': {
        letterSpacing: 'unset',
    },
};

export type Props = {
    classes: Object,
    loadTrips: Function,
    assetName: string,
    serialNumber: string,
    isTripLoader: boolean,
    applyFilter: Function,
    fromDate: string,
    toDate: string,
    isEventLoader: boolean,
    assetId: string,
    setDrawerWidth: Function,
    trips: Object,
    goToTripDetail: Function,
    tripView: boolean,
    handleRefresh: Function,
    dateSelected: string,
    setDateSelected: Function,
    loadMapDirectionsFromTrips: Function,
    setZoomEvent: Function,
    zoomEvent: Object,
    datePicked: Date,
    setDatePicked: Function,
    printPage: Function,
    assetFeatures: Object,
    history: {
        push: Function,
        goBack: Function,
        location: {
            state: Object,
        }
    },
    isTripsFetchError: boolean,
    mapPageEventCallback: Function,
    deviceModelCode: string,
    isMapRefreshed: boolean,
};

export type State = {
    fromDate: any,
    toDate: any,
    toDateEvent: any,
    tabIndex: number,
    isDateChanged: boolean,
    selectedEvent: Object,
    tripsData: Array<Object>,
    eventsData: Object,
    tripView: boolean,
    selectedTrips: Array<Object>, // value from trips dropdown
    setPreferences: boolean,
    openColumn: boolean,
    tableData: Object,
    isSameDay: boolean,
    setEvents: boolean,
    setTrips: boolean,
    showDatePicker: boolean,
};

class Trips extends Component<Props, State> {
    toggleFilterDrawer: Function;
    locStateData: any;
    userPreferences: any;
    last50Event: boolean;
    constructor(props: Props) {
        super(props);
        this.locStateData = manageLocStateData(this.props.history.location.state);
        this.state = {
            fromDate: this.props.fromDate,
            toDate: this.props.toDate,
            toDateEvent: (this.locStateData && this.locStateData.toDateEvent) || null,
            isDateChanged: (this.locStateData && this.locStateData.isDateChanged) || false,
            tabIndex: (this.locStateData && this.locStateData.tabIndex) || 0,
            selectedEvent: (this.locStateData && this.locStateData.selectedEvent) || {
                id: '',
            },
            tripsData: (this.locStateData && this.locStateData.tripsData)
                || [],
            eventsData: (this.locStateData && this.locStateData.eventsData)
                || { content: [], count: 0 },
            tripView: this.props.tripView,
            selectedTrips: [],
            setPreferences: false,
            openColumn: false,
            tableData: { listHeaders: {}, userPreferences: null },
            isSameDay: false,
            setEvents: false,
            setTrips: false,
            showDatePicker: false,
        };
        this.last50Event = false;
        this.setDateReceived = this.setDateReceived.bind(this);
    }

    componentDidMount = () => {
        if (Object.keys(this.locStateData).length > 0) {
            manageLocStateData(this.props.history.location.state, 'delete');
        } else if (!this.props.isTripLoader && !this.props.isEventLoader) {
            this.props.loadTrips(true, true);
        }
        this.setTripEventsPreference();
        this.setPreference();
        if (this.props.mapPageEventCallback && typeof this.props.mapPageEventCallback === 'function') {
            this.props.mapPageEventCallback(this.mapPageEventCallback);
        }
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        const { tripsData, eventsData } = this.state;
        const { trips, tripEvents } = nextprops.trips;

        if (this.props.assetId !== nextprops.assetId) {
            this.setState({ isDateChanged: false });
        }
        if ((trips !== undefined && trips.length > 0)) {
            if (JSON.stringify(trips) !== JSON.stringify(tripsData)) {
                this.setState({
                    tripsData: trips,
                    selectedTrips: [],
                    setTrips: true,
                });
            } else {
                this.setState({
                    setTrips: true,
                });
            }
        } else if (!trips ||
            (trips && trips.length === 0)) {
            this.setState({
                setTrips: true,
                tripsData: [],
            });
        }
        if ((tripEvents !== undefined)) {
            if (JSON.stringify(tripEvents) !== JSON.stringify(eventsData)
                || tripEvents.content.length === 0) {
                this.setState({
                    eventsData: tripEvents,
                    selectedTrips: [],
                    setEvents: true,
                });
                if (!tripEvents.count &&
                    this.props.dateSelected === 'TODAY' &&
                    !this.state.isDateChanged) {
                    this.handleDateChange({ target: { value: 'LAST50' } });
                }
            } else {
                this.setState({
                    setEvents: true,
                });
            }
        } else if (this.props.isTripsFetchError && this.props.dateSelected === 'TODAY' && !this.state.isDateChanged) {
            this.handleDateChange({ target: { value: 'LAST50' } });
        }
        if (!this.props.assetFeatures && this.props.assetFeatures !== nextprops.assetFeatures) {
            this.setTripEventsPreference();
        }
    }

    mapPageEventCallback = (eventTitle) => {
        let title = '';
        if (eventTitle && Object.keys(eventTitle)[0] === 'snapRoutes') {
            title = 'ENABLE_SNAP_ROUTES';
        } else if (eventTitle && Object.keys(eventTitle)[0] === 'mapTrafficLayer') {
            title = 'ENABLE_TRAFFIC';
        } else if (eventTitle && Object.keys(eventTitle)[0] === 'mapAutoRefresh') {
            title = 'MAP_AUTO_REFRESH';
        }
        this.trackTripData({
            tripsSelection: this.getTripsSelection(this.state.selectedTrips),
            dateRange: this.props.dateSelected,
            eventName: this.state.tripView,
            eventTitle: title,
        });
    }

    trackTripData = (data: Object) => {
        const trackObj = {
            tripsSelection: data.tripsSelection,
            dateRange: data.dateRange === 'LAST50' ? 'LAST 50' : data.dateRange,
            feature: EVENT_HISTORY,
            eventName: data.eventName ? 'Trips View' : 'Event History',
            mapAutoRefresh: getLocalStorageValue('mapAutoRefresh') || false,
            mapTrafficLayer: getLocalStorageValue('mapTrafficLayer') || false,
            snapRoutes: getLocalStorageValue('snapRoutes') || false,
            pageRefresh: false,
            selectedColumn: false,
            showColumn: false,
        };

        if (data.isTripDetail) {
            trackObj.eventName = 'Trips Details';
        }
        if (data.pageRefresh) {
            trackObj.pageRefresh = data.pageRefresh;
        } else {
            delete trackObj.pageRefresh;
        }

        if (data.selectedColumn) {
            trackObj.selectedColumn = data.selectedColumn;
            trackObj.showColumn = data.showColumn;
        } else {
            delete trackObj.selectedColumn;
            delete trackObj.showColumn;
        }
        analytics.track(data.eventTitle, trackObj);
    }

    setPreference = () => {
        getUserPreference(TRIPS_VIEW_PREF_KEY).then((response) => {
            if (response && response.value) {
                this.userPreferences = JSON.parse(response.value);
                this.setState({ tripView: this.userPreferences.showTrips });
            }
            this.setState({ setPreferences: true }, () => {
                this.trackTripData({
                    tripsSelection: 'Default',
                    dateRange: this.props.dateSelected,
                    eventName: this.state.tripView,
                    eventTitle: 'EVENT_HISTORY',
                });
            });
        });
    }

    setTripEventsPreference = () => {
        getUserPreference(TRIP_EVENTS_PREF_KEY).then((response) => {
            const userPreferences = response && response.value ? response.value : null;
            const listHeaders = getListHeader(
                { value: userPreferences },
                this.props.classes,
                this.props.assetFeatures,
                this.props.deviceModelCode,
            );
            this.setState({ tableData: { listHeaders, userPreferences: response } });
        });
    };

    openDatePicker(showDatePicker: boolean) {
        this.setState({ showDatePicker });
    }

    setDateReceived = (date: Date) => {
        this.initEventData();
        const isSameDay = moment(date).isSame(moment(), 'day');
        const startDate = getConvertedStartDate(date);
        const endDate = getConvertedEndDate(date);
        this.props.applyFilter(startDate, endDate, FETCH_TRIPS_LIMIT);
        this.props.setDateSelected(isSameDay ? 'TODAY' : 'CALENDAR');
        this.props.setDatePicked(date);
        this.props.setZoomEvent();
        this.setState({
            fromDate: startDate,
            toDate: endDate,
            selectedTrips: [],
            isSameDay,
            showDatePicker: false,
        }, () => {
            this.setState({ setPreferences: true }, () => {
                this.trackTripData({
                    tripsSelection: 'Default',
                    dateRange: isSameDay ? 'TODAY' : 'CALENDAR',
                    eventName: this.state.tripView,
                    eventTitle: 'SELECT_TIME_RANGE',
                });
            });
        });
    }

    handleDateChange = (e: any) => {
        let startDate;
        let endDate;
        let limit = FETCH_TRIPS_LIMIT;

        switch (e.target.value) {
        case 'TODAY':
            startDate = getConvertedStartDate(Date.now());
            endDate = getConvertedEndDate(Date.now());
            break;
        case 'YESTERDAY':
            startDate = getConvertedStartDate(moment().subtract(1, 'days'));
            endDate = getConvertedEndDate(moment().subtract(1, 'days'));
            break;
        case 'LAST50':
            limit = 50;
            break;
        case 'CALENDAR':
            this.openDatePicker(true);
            return;
        default:
            startDate = getConvertedStartDate(Date.now());
            endDate = getConvertedEndDate(Date.now());
            break;
        }
        this.initEventData();
        this.props.setDateSelected(e.target.value);
        this.props.applyFilter(startDate, endDate, limit, e.target.value);
        this.props.setDatePicked(new Date());
        this.props.setZoomEvent();
        this.setState({
            fromDate: startDate,
            toDate: endDate,
            selectedTrips: [],
            isDateChanged: true,
            isSameDay: false,
        }, () => {
            if (!e.target.name) {
                this.last50Event = true;
                if (this.state.setPreferences) {
                    this.trackTripData({
                        tripsSelection: 'Default',
                        dateRange: 'LAST50',
                        eventName: this.state.tripView,
                        eventTitle: 'SELECT_TIME_RANGE',
                    });
                }
            } else {
                this.trackTripData({
                    tripsSelection: 'Default',
                    dateRange: e.target.value,
                    eventName: this.state.tripView,
                    eventTitle: 'SELECT_TIME_RANGE',
                });
            }
        });
    }

    handleSelectTrips = (e) => {
        const selectedTrips = e.target.value;
        this.setSelectedTrips(selectedTrips);
        this.trackTripData({
            tripsSelection: this.getTripsSelection(selectedTrips),
            dateRange: this.props.dateSelected,
            eventName: this.state.tripView,
            eventTitle: 'SELECT_TRIP',
        });
    };

    getTripsSelection = (selectedTrips) => {
        let tripsSelection = 'Default';

        if (selectedTrips && selectedTrips.length > 0) {
            tripsSelection = selectedTrips.length === 1 ? 'SingleTrip' : 'MultiTrips';
        }
        return tripsSelection;
    }

    setSelectedTrips = (selectedTrips) => {
        this.setState({ selectedTrips }, () => {});
        this.props.setZoomEvent();
        this.props.loadMapDirectionsFromTrips(selectedTrips);
    };

    goToAlertDetails = (trip, index) => {
        const { fromDate, toDate, assetName } = this.props;
        localStorage.setItem('tripsAndEventsHistory', JSON.stringify(this.state));
        this.props.history.push(`/trips/${trip.id}/${trip.assetGlobalId}/${index}/${fromDate}/${toDate}/${assetName}`, { fromLocationState: 'tripsAndEventsHistory' });
        analytics.track('TRIPS_LIST_HISTORICAL_TRIPS_CLICK', { ClickedTrip: index + 1, TripId: trip.id });
    }

    goBackToPrevPage = () => {
        if (this.props.assetId) {
            // TODO - remove later once we fix URL sharing issue caused by below line
            // this.props.history.push(`/home?assetId=${this.props.assetId}&zoom=true`);

            // this will cause URL sharing issue and manual pasting of URL issue
            this.props.history.goBack();
        }
    }

    getTime = (durationInMins) => {
        const hours = Math.floor(durationInMins / 60);
        const minutes = durationInMins % 60;
        let response = '';
        if (hours > 0) {
            response += `${hours} hr`;
        }
        if (minutes > 0) {
            response += `${minutes} min`;
        }
        return response;
    }

    getAlerts = (alerts) => {
        const responseArray = [];
        alerts.forEach((alert) => {
            const splitAlert = alert.split(':');
            const key = splitAlert[0].trim();
            const value = splitAlert[1].trim();
            const alertName = getAlertProperties(false, key).name;
            let resp = alertName;
            if (value > 1) {
                resp = `${resp} (${value})`;
            }
            responseArray.push(resp);
        });
        return responseArray.join(', ');
    }

    getTabView = () => {
        const {
            classes, isTripLoader, isMapRefreshed,
        } = this.props;
        const headers = this.state.tableData.listHeaders;
        if (!isEmpty(headers)) {
            if (!this.state.tripView && this.state.setEvents) {
                return (
                    <div>
                        {isMapRefreshed ? <AppLoader type="fullScreen" fullScreenStyle={{ width: EVENT_DRAWER_WIDTH }} /> : null}
                        {this.getEvent()}
                    </div>
                );
            } else if (this.state.tripView && this.state.setTrips && !isTripLoader) {
                return (
                    <Paper
                        className={classes.itemsContainer}
                        id="trips-list"
                    >
                        {this.getTrips()}
                    </Paper>
                );
            }
        }
        return <div className={customstyles['trips-loader']}><AppLoader /></div>;
    }

    getEvent = () => {
        const { eventsData, selectedTrips } = this.state;
        const { isMapRefreshed } = this.props;

        if (!eventsData || !eventsData.content || eventsData.content.length === 0) {
            return (
                <div className={customstyles['no-trips']}>
                    {
                        <span>No Events available for the selected asset</span>
                    }
                </div>);
        }

        let selectedTripsEvents = eventsData.content;
        if (selectedTrips.length > 0) {
            selectedTripsEvents = eventsData.content.filter(event =>
                selectedTrips.includes(event.tripId));
        }
        return (<EventsList
            events={selectedTripsEvents}
            setZoomEvent={this.props.setZoomEvent}
            zoomEvent={this.props.zoomEvent}
            listHeaders={this.state.tableData.listHeaders}
            userPreferences={this.state.tableData.userPreferences}
            isMapRefreshed={isMapRefreshed}
        />);
    };

    goToTripDetail = (index, dateSelected) => {
        this.props.goToTripDetail(index, dateSelected);
        this.trackTripData({
            tripsSelection: 'SingleTrip',
            dateRange: dateSelected,
            eventName: this.state.tripView,
            isTripDetail: true,
            eventTitle: 'NAVIGATE_TRIP_DETAILS',
        });
    }

    getTrips = () => {
        const { tripsData } = this.state;
        let list = '';

        if (tripsData && tripsData.length > 0) {
            return (<TripsListComponent
                tripsData={tripsData}
                redirectTo={this.props.history.push}
                goToTripDetail={this.goToTripDetail}
                dateSelected={this.props.dateSelected}
                setSelectedTrips={this.setSelectedTrips}
            />);
        }
        list = (
            <div className={customstyles['no-trips']}>
                {
                    <span>No trips available for the selected asset</span>
                }
            </div>);

        return list;
    }

    getTabStyle = (tab) => {
        let style = {
            color: 'rgba(255, 255, 255, 0.7)',
        };
        if (this.state.tabIndex === tab) {
            style = {
                color: 'white',
                borderBottom: '2px solid  #007aff',
            };
        }
        return style;
    }

    initEventData = () => this.setState({
        eventsData: { content: [], count: 0 },
        setEvents: false,
    });

    handleRefresh = () => {
        this.initEventData();
        this.props.handleRefresh(
            this.state.fromDate,
            this.state.toDate,
        );
        this.trackTripData({
            tripsSelection: 'Default',
            dateRange: this.props.dateSelected,
            eventName: this.state.tripView,
            pageRefresh: true,
            eventTitle: 'REFRESH',
        });
    }

    handleTripViewChange = (event) => {
        this.setState({ tripView: event.target.checked, tabIndex: event.target.checked ? 1 : 0 });
        this.setSelectedTrips([]);
        this.trackTripData({
            tripsSelection: this.getTripsSelection([]),
            dateRange: this.props.dateSelected,
            eventName: !this.state.tripView,
            eventTitle: 'TRIPS_VIEW',
        });
        this.props.setZoomEvent();
        this.props.setDrawerWidth(event.target.checked ? TRIP_DRAWER_WIDTH : EVENT_DRAWER_WIDTH);
        const configData = {
            config: {
                showTrips: event.target.checked,
            },
            method: this.userPreferences ? 'put' : 'post',
        };
        saveUserPreference(TRIPS_VIEW_PREF_KEY, configData).then((response) => {
            if (response && response.value) {
                this.userPreferences = JSON.parse(response.value);
            }
        });
    }

    getDropDownValue = () => {
        const cnt = this.state.selectedTrips.length;
        return cnt === 0 ? 'Select a Trip' : `${cnt} Trip(s) selected`;
    };

    showHideColumn = (header) => {
        const options = { prefKey: TRIP_EVENTS_PREF_KEY };
        showHideColumns(this, header, options);
        this.trackTripData({
            tripsSelection: this.getTripsSelection(this.state.selectedTrips),
            dateRange: this.props.dateSelected,
            eventName: this.state.tripView,
            eventTitle: 'HIDE_SHOW_COLUMN',
            selectedColumn: Object.values(header)[0],
            showColumn: header.show,
        });
    };

    render() {
        const {
            tripView,
            selectedTrips,
            tripsData,
            openColumn,
            isSameDay,
            showDatePicker,
        } = this.state;
        const {
            classes, assetName, serialNumber, dateSelected, datePicked, setDatePicked,
        } = this.props;
        const filteredTrips = tripsData.filter(trip => trip.type === 'COMPLETED' || trip.type === 'INPROCESS');
        return this.state.setPreferences &&
            <div className="app">
                <div className={customstyles.container}>
                    <Grid container justifyContent="flex-start" alignItems="center" id="header-card" className={customstyles.card}>
                        <Grid item xs={10}>
                            <Grid container justifyContent="flex-start" alignItems="center">
                                <Grid item>
                                    <button
                                        className={customstyles['back-button']}
                                        onClick={() => this.goBackToPrevPage()}
                                    >
                                        <i className={customstyles['back-icon']} />
                                    </button>
                                </Grid>
                                <Grid item className={customstyles['trip-head-wrapper']}>
                                    <Typography variant="h5" className={`${customstyles['asset-header']} ${customstyles.typography}`} component="div" id="Asset-Name">
                                        <Tooltip title={assetName || ''} >
                                            <span>
                                                Event History
                                            </span>
                                        </Tooltip>
                                    </Typography>
                                    <Typography id="sub-header" className={`${customstyles['sub-header']} ${customstyles.typography}`} component="div">
                                        {assetName} | {serialNumber}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justifyContent="flex-end" spacing={1} className={customstyles['icon-holder']}>
                                <Grid item>
                                    {!tripView &&
                                        <button onClick={() => {
                                            this.props.printPage();
                                            this.trackTripData({
                                                tripsSelection:
                                                    this.getTripsSelection(
                                                        this.state.selectedTrips,
                                                    ),
                                                dateRange: this.props.dateSelected,
                                                eventName: this.state.tripView,
                                                eventTitle: 'PRINT_EVENT_HISTORY',
                                            });
                                        }}
                                        >
                                            <GetSvgIcon type="print" fillcolor="#fff" opacity={1} />
                                        </button>
                                    }
                                    <button onClick={() => {
                                        this.setTripEventsPreference();
                                        this.handleRefresh();
                                    }}
                                    >
                                        <GetSvgIcon type="refresh" fillcolor="#fff" opacity={1} />
                                    </button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.horizontal} />
                        </Grid>
                        <Grid container justifyContent="flex-start" alignItems="center" sx={{ height: '48px' }} >
                            <Grid item xs={3} className={customstyles['sub-header-container']}>
                                <span className={customstyles['sub-header-2']}>
                                    Trips View
                                </span>
                                <Switch
                                    checked={tripView}
                                    onChange={this.handleTripViewChange}
                                    color="primary"
                                />
                            </Grid>
                            {!tripView ?
                                <Grid item xs={5} className={customstyles['date-container']}>
                                    <Select
                                        sx={{ '& .MuiInputBase-input': { WebkitTextFillColor: 'unset !Important' } }}
                                        disabled={filteredTrips.length === 0}
                                        disableUnderline
                                        className={customstyles['date-picker']}
                                        value={selectedTrips}
                                        onChange={this.handleSelectTrips}
                                        style={{ width: 280 }}
                                        multiple
                                        renderValue={this.getDropDownValue}
                                        displayEmpty
                                    >
                                        {filteredTrips && filteredTrips.length > 0 ?
                                            filteredTrips.map(trip => (
                                                <MenuItem
                                                    key={trip.id}
                                                    value={trip.id}
                                                    sx={{ ...customMenuItemStyles }}
                                                >
                                                    <Checkbox color="primary" checked={selectedTrips.indexOf(trip.id) > -1} />{convertDateTime(trip.startDate, (getTimeFormatAsperUserSettting() === '12h') ? 'MM/DD/YY h:mm A' : 'MM/DD/YY H:mm')} to {trip.endDate !== null ? convertDateTime(trip.endDate, (getTimeFormatAsperUserSettting() === '12h') ? 'MM/DD/YY h:mm A' : 'MM/DD/YY H:mm') : 'In Progress'}
                                                </MenuItem>)) : ''
                                        };
                                    </Select>
                                </Grid> : <Grid item xs={5} />
                            }
                            <Grid item xs={!tripView ? 3 : 4} className={customstyles['date-container']}>
                                <Select
                                    sx={{ '.MuiSelect-select': { paddingRight: '32px !Important' } }}
                                    disableUnderline
                                    className={customstyles['date-picker']}
                                    value={isSameDay ? 'CALENDAR' : dateSelected}
                                    onChange={this.handleDateChange}
                                    name="dateRange"
                                >
                                    <MenuItem value="LAST50">Last 50 Events</MenuItem>
                                    <MenuItem value="TODAY">Today</MenuItem>
                                    <MenuItem value="YESTERDAY">Yesterday</MenuItem>
                                    <MenuItem value="CALENDAR" onClick={() => this.openDatePicker(true)}>Calendar</MenuItem>
                                </Select>
                                <div style={{ display: 'none' }}>
                                    <LocalizationProvider dateAdapter={MomentUtils}>
                                        <MobileDatePicker
                                            onChange={setDatePicked}
                                            components={{
                                                ActionBar: () => (
                                                    <DialogActions>
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                this.openDatePicker(false)
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                this.setDateReceived(datePicked)
                                                            }}
                                                        >
                                                            Ok
                                                        </Button>
                                                    </DialogActions>
                                                ),
                                            }}
                                            DialogProps={{
                                                sx: { '.MuiPickersToolbar-penIconButton': { display: 'none' } },
                                                onBackdropClick: () => {
                                                    this.openDatePicker(false)
                                                },
                                            }}
                                            toolbarTitle=""
                                            disableFuture
                                            defaultValue={datePicked}
                                            value={datePicked}
                                            id="picked-date"
                                            format="MM/DD/YYYY"
                                            renderInput={params => <TextField {...params} />}
                                            mask="__/__/____"
                                            className={classes.dateField}
                                            open={showDatePicker}
                                            minDate={moment().subtract(6, 'months')}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            {!tripView &&
                                <Grid item xs={1}>
                                    <ShowHideColumnComponent
                                        classes={classes}
                                        openColumn={openColumn}
                                        listHeaders={this.state.tableData.listHeaders}
                                        showHideColumn={this.showHideColumn}
                                        iconColor="#FFF"
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            {this.getTabView()}
                        </Grid>
                    </Grid>
                </div>
            </div>;
    }
}

export default withStyles(styles, { withTheme: true })(Trips);
