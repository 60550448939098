/* @flow */

import {
    FETCH_NEAREST_ASSET,
    FETCH_NEAREST_LANDMARK,
    FETCH_NEAREST_ASSET_LANDMARK_SUCCESS,
    FETCH_NEAREST_ASSET_LANDMARK_ERROR,
} from './constants.nearestAssetLandmark';
import type { NearestAssetLandmarkTypeAction } from './constants.nearestAssetLandmark';

export const fetchNearestAsset = (id: string): NearestAssetLandmarkTypeAction => ({
    type: FETCH_NEAREST_ASSET,
    payload: { id },
});
export const fetchNearestLandmark = (id: string): NearestAssetLandmarkTypeAction => ({
    type: FETCH_NEAREST_LANDMARK,
    payload: { id },
});
export const fetchNearestAssetLandmarkSuccess = (
    directions: {},
    landmarks: {},
    assetDetails: {},
    directionsDetails: {},
): NearestAssetLandmarkTypeAction => ({
    type: FETCH_NEAREST_ASSET_LANDMARK_SUCCESS,
    payload: {
        directions,
        landmarks,
        assetDetails,
        directionsDetails,
    },
});
export const fetchNearestAssetLandmarkError = (error: string): NearestAssetLandmarkTypeAction => ({
    type: FETCH_NEAREST_ASSET_LANDMARK_ERROR,
    payload: { error },
});
