/* @flow */
import {
    POST_ALERT_DATA,
    POST_ALERT_DATA_SUCCESS,
    POST_ALERT_DATA_ERROR,
} from './constants.stepers';

import type { AlerTDATATypeAction } from './constants.stepers';

export const postAlertData = (saveAlert: {}): AlerTDATATypeAction => ({
    type: POST_ALERT_DATA,
    payload: { saveAlert },
});

export const postAlertDataSuccess = (saveAlert: {}): AlerTDATATypeAction => ({
    type: POST_ALERT_DATA_SUCCESS,
    payload: { saveAlert },
});

export const postAlertDataError = (error: string): AlerTDATATypeAction => ({
    type: POST_ALERT_DATA_ERROR,
    payload: { error },
});

export const clearAlertReducer = (): AlerTDATATypeAction => ({
    type: 'CLEAR_REDUCER',
});
