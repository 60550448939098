/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_NEAREST_ASSET, FETCH_NEAREST_LANDMARK } from './constants.nearestAssetLandmark';
import { fetchNearestAssetLandmarkSuccess, fetchNearestAssetLandmarkError } from './actions.nearestAssetLandmark';
import config from '../../constants/Config';
import { getDistanceString, getUserToken } from '../../helper-classes/utility-functions';

type ObservarblesTypes = {
    getJSON: Function,
    of: Function,
    fetchDirections: Function,
};

export const getShapeData = (data: Object) => {
    const type = (data.shape) ? data.shape.toLowerCase() : '';
    switch (type) {
    case 'rectangle': return {
        type: 'RECTANGLE', point1: { lat: data.box.first.y, lng: data.box.first.x }, point2: { lat: data.box.second.y, lng: data.box.second.x },
    };
    case 'circle': return {
        type: 'CIRCLE', radius: data.circle.radius.value || 400, lat: parseFloat(data.circle.center.y), lng: parseFloat(data.circle.center.x),
    };
    case 'polygon': return {
        type: 'POLYGON', points: data.polygon.points.map(d => ({ lat: d.y, lng: d.x })),
    };
    default: return '';
    }
};

const getSpeed = (data: any) => {
    if (data && data.status && data.status.toLowerCase() === 'stopped') {
        return 0;
    }
    return (data.speed) ? Math.round(data.speed) : '0';
};

const getStepsDetails = (stepsInfo) => {
    const stepsDetails = [];
    let i = 0;
    stepsInfo.forEach((step) => {
        stepsDetails.push({
            distance: step.distance.text || '',
            duration: step.duration.text || '',
            text: step.instructions ? `${i += 1}. ${step.instructions}` : '',
        });
    });
    return stepsDetails;
};

const getDirectionsDetails = routesInfo => ({
    startLocation: routesInfo.start_address,
    endLocation: routesInfo.end_address,
    duration: routesInfo.duration.text || '',
    distance: routesInfo.distance.text || '',
    steps: getStepsDetails(routesInfo.steps),
});

export const formatAssetData = (data: Object) => ({
    assetId: data.id || '',
    assetName: data.name || '',
    status: data.status || '',
    deviceSerialNumber: data.deviceSerialNumber || '',
    locationLastReported: data.locationLastReported || '',
    statusStartDate: data.statusStartDate || '',
    statusDurationInSeconds: data.statusDurationInSeconds || '',
    statusDisplayDate: data.statusDisplayDate || '',
    lastEventDate: data.lastEventDate || '',
    reportingStatus: data.reportingStatus || '',
    lat: (data.lastLocation) ? data.lastLocation.y : 0,
    lng: (data.lastLocation) ? data.lastLocation.x : 0,
    latitude: (data.latitude) ? parseFloat(data.latitude) : 0,
    longitude: (data.longitude) ? parseFloat(data.longitude) : 0,
    address: `${data.address || ''}${(data.address) ? ', ' : ''}${data.city || ''}${(data.city) ? ', ' : ''}${data.state || ''} ${data.zipCode || ''}`,
    assetType: data.type || '',
    typeName: data.typeName || '',
    direction: data.direction || '',
    assetStatus: {
        heading: data.direction || '',
        speed: getSpeed(data),
        lastEvent: data.lastEvent || '',
        lastEventDate: data.lastEventDate || '',
    },
    assetDetails: {
        assetGroupName: data.assetGroupName || '',
        driver: data.driverName || '',
        tags: data.tags || '',
        details: '',
    },
    icon: `${(data.attributes && data.attributes.icon) ? data.attributes.icon : ''}`,
    labelColor: `${(data.attributes && data.attributes.labelColor) ? data.attributes.labelColor : ''}`,
});

const formatLandmarkData = data => ({
    id: data.id || '',
    name: data.name || '',
    asset: `${data.totalAssets} Assets` || '',
    address: `${data.address || ''}${(data.address) ? ', ' : ''}${data.city || ''}${(data.city) ? ', ' : ''}${data.state || ''} ${data.zipCode || ''}`,
    shape: getShapeData(data),
    markerLat: data.markerLat || 0,
    markerLng: data.markerLng || 0,
    icon: `${(data.attributes) ? (data.attributes.lfLandmarkIcon || data.attributes.att_0cm61 || '') : ''}`,
    attributes: data.attributes,
});

const getUnitSystem = () => {
    if (window.googleMaps && window.googleMaps.UnitSystem) {
        if (getDistanceString() === 'mi') {
            return window.googleMaps.UnitSystem.IMPERIAL;
        }
        return window.googleMaps.UnitSystem.METRIC;
    }
    return '';
};

let landmarkDetails = {};
let directionsDetails = {};
let assetDetails = {};
export const getNearestLandmark =
    (actions$: Function, store: Object, { getJSON, of, fetchDirections }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_NEAREST_LANDMARK)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken() || '',
                };
                landmarkDetails = {};
                directionsDetails = {};
                assetDetails = {};
                return getJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/assets/${action.payload.id}`, headers)
                    .mergeMap((assetResults) => {
                        if (assetResults.count > 0) {
                            assetDetails = formatAssetData(assetResults.data[0]);
                        }
                        return getJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/landmarks/nearest?lat=${assetDetails.lat}&lng=${assetDetails.lng}`, headers)
                            .mergeMap((nearestlandmarkresult) => {
                                landmarkDetails = nearestlandmarkresult.count > 0
                                    ? nearestlandmarkresult.data[0]
                                    : {};
                                const directionsService = window.googleMaps &&
                            window.googleMaps.DirectionsService ?
                                    new window.googleMaps.DirectionsService() : null;
                                const directionMode = window.googleMaps && window.googleMaps.TravelMode ? window.googleMaps.TravelMode.DRIVING : '';
                                const endPoint = new window.googleMaps.LatLng(
                                    landmarkDetails.markerLat,
                                    landmarkDetails.markerLng,
                                );
                                const startPoint = new window.googleMaps.LatLng(
                                    assetDetails.lat,
                                    assetDetails.lng,
                                );
                                landmarkDetails = formatLandmarkData(landmarkDetails);
                                let result = {};
                                if (directionsService !== null) {
                                    result = fetchDirections(
                                        directionsService,
                                        directionMode,
                                        getUnitSystem(),
                                        startPoint,
                                        endPoint,
                                    );
                                }
                                return result;
                            });
                    }).mergeMap((result) => {
                        const directions = result;
                        if (directions &&
                            directions.routes && directions.routes.length > 0 &&
                            directions.routes[0].legs && directions.routes[0].legs.length > 0) {
                            directionsDetails = getDirectionsDetails(directions.routes[0].legs[0]);
                        } else {
                            directionsDetails = {};
                        }
                        return of(fetchNearestAssetLandmarkSuccess(
                            result,
                            landmarkDetails,
                            assetDetails,
                            directionsDetails,
                        ));
                    }).catch(error => of(fetchNearestAssetLandmarkError(error)));
            });

export const getNearestAsset =
    (actions$: Function, store: Object, { getJSON, of, fetchDirections }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_NEAREST_ASSET)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken() || '',
                };
                landmarkDetails = {};
                directionsDetails = {};
                assetDetails = {};
                return getJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/landmarks/${action.payload.id}`, headers)
                    .mergeMap((landmarkResults) => {
                        if (landmarkResults.count > 0) {
                            landmarkDetails = formatLandmarkData(landmarkResults.data[0]);
                        }
                        return getJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/assets/nearest?lat=${landmarkDetails.markerLat}&lng=${landmarkDetails.markerLng}`, headers)
                            .mergeMap((nearestassetresult) => {
                                assetDetails = nearestassetresult.count > 0
                                    ? nearestassetresult.data[0]
                                    : {};
                                const directionsService = window.googleMaps &&
                                window.googleMaps.DirectionsService ?
                                    new window.googleMaps.DirectionsService() : null;
                                const directionMode = window.googleMaps && window.googleMaps.TravelMode ? window.googleMaps.TravelMode.DRIVING : '';
                                let endPoint = {};
                                let startPoint = {};
                                if (window.googleMaps && window.googleMaps.LatLng) {
                                    endPoint = new window.googleMaps.LatLng(
                                        assetDetails.latitude,
                                        assetDetails.longitude,
                                    );
                                    startPoint = new window.googleMaps.LatLng(
                                        landmarkDetails.markerLat,
                                        landmarkDetails.markerLng,
                                    );
                                }
                                assetDetails = formatAssetData(assetDetails);
                                let result = {};
                                if (directionsService !== null) {
                                    result = fetchDirections(
                                        directionsService,
                                        directionMode,
                                        getUnitSystem(),
                                        startPoint,
                                        endPoint,
                                    );
                                }
                                return result;
                            });
                    }).mergeMap((result) => {
                        const directions = result;
                        if (directions &&
                            directions.routes && directions.routes.length > 0 &&
                            directions.routes[0].legs && directions.routes[0].legs.length > 0) {
                            directionsDetails = getDirectionsDetails(directions.routes[0].legs[0]);
                        } else {
                            directionsDetails = {};
                        }
                        return of(fetchNearestAssetLandmarkSuccess(
                            result,
                            landmarkDetails,
                            assetDetails,
                            directionsDetails,
                        ));
                    }).catch(error => of(fetchNearestAssetLandmarkError(error)));
            });

export default combineEpics(getNearestLandmark, getNearestAsset);
