/* @flow */

import { FETCH_DRIVERS_LIST, FETCH_DRIVERS_LIST_SUCCESS, FETCH_DRIVERS_LIST_ERROR, RESET_DRIVERS_LIST } from './constants.driverList';
import type { DriverListTypeAction } from './constants.driverList';

export const fetchDriversList = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filter: string,
): DriverListTypeAction => ({
    type: FETCH_DRIVERS_LIST,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
        filter,
    },
});

export const fetchDriversListSuccess =
    (drivers: any, totalDrivers: number): DriverListTypeAction => ({
        type: FETCH_DRIVERS_LIST_SUCCESS,
        payload: { drivers, totalDrivers },
    });

export const fetchDriversListError = (error: string): DriverListTypeAction => ({
    type: FETCH_DRIVERS_LIST_ERROR,
    payload: { error },
});

export const resetTable =
    (): DriverListTypeAction => ({
        type: RESET_DRIVERS_LIST,
    });
