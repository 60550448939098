/* @flow */
import moment from 'moment';
import { combineEpics } from 'redux-observable';
import { FETCH_RECORDS_LIST } from './constants.recordList';
import { fetchRecordListError, fetchRecordListSuccess } from './actions.recordList';
import { getUserToken } from '../../../helper-classes/utility-functions';
import config from '../../../constants/Config';
import MaintenanceFilterHelper from '../../../components/Maintenance/MaintenanceFilterHelper';

type ObservablesTypes = {
    getJSON: Function,
    of: Function
}


export const getServiceRecordsUrl = (payload: Object, isPaginate: boolean = true) => {
    const {
        order,
        orderBy,
        pageNumber,
        pageSize,
        filtersToSend,
    } = payload;
    const { dateRangeOption } = MaintenanceFilterHelper;
    let startDate = null;
    let endDate = null;
    const serviceURL = `${config.get('MAINTENANCE_SERVICES_URL')}/serviceRecords`;
    let params = isPaginate ? `offset=${pageNumber * pageSize}&limit=${pageSize}` : '';

    if (order && orderBy) {
        params += `&sort=${orderBy}:${order.toUpperCase()}`;
    }

    if (filtersToSend && filtersToSend.length > 0) {
        const hasDateRange = filtersToSend.filter(filter => (
            filter.property === 'filterDate' && filter.value === dateRangeOption.id
        )).length > 0;
        filtersToSend.forEach((filter) => {
            const { data } = filter;

            if (hasDateRange && data.parentProperty === 'filterDate') {
                if (filter.property === 'startDate') {
                    startDate = moment(filter.value).startOf('day').utc().toISOString();
                } else if (hasDateRange && filter.property === 'endDate') {
                    endDate = moment(filter.value).endOf('day').utc().toISOString();
                }
            } else if (filter.property === 'filterDate') {
                if (data.getStartDate && data.getEndDate) {
                    startDate = moment.utc(data.getStartDate()).toISOString();
                    endDate = moment.utc(data.getEndDate()).toISOString();
                }
            } else {
                params += `&${filter.property}=${filter.value}`;
            }
        });
    }

    if (startDate) {
        params += `&startDate=${startDate}`;
    }

    if (endDate) {
        params += `&endDate=${endDate}`;
    }

    return `${serviceURL}?${params}`;
};

export const getRecords = (actions$: Function, store: Object, { getJSON, of }: ObservablesTypes) =>
    actions$
        .ofType(FETCH_RECORDS_LIST)
        .mergeMap((action) => {
            const url = getServiceRecordsUrl(action.payload);
            const headers = {
                'X-Nspire-UserToken': getUserToken(),
            };

            return getJSON(url, headers)
                .map(result => fetchRecordListSuccess(result.content, result.total))
                .catch(error => of(fetchRecordListError(error)));
        });

export default combineEpics(getRecords);
