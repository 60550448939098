/* @flow */

import React, { Component } from 'react';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.scoreVehicleList';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../components/SharedComponents/ListComponent/ListComponent';
import {
    getParamValue,
    getLocalStorageValue,
    mngClmnBasedonSettings,
    getDriverDropDownDates,
    downloadFile,
    getHeaderKeys,
    getUserSettingTimeZone,
    getDistanceStringLong,
    getTimeFormatAsperUserSettting,
    getMilesDrivenOrScore,
    getDistanceString,
    getSpeedUnitString,
    getScorecardSettingsText,
    saveUserPreferenceForDriver,
} from '../../helper-classes/utility-functions';
import {
    calculateTimeInHrs,
    getConvertedStartDateValue,
    getConvertedEndDateValue,
    getDateRangeDropdownValue,
} from '../../util/trip_util';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    showHideColumns,
    rearrangeColumns, isUpdatePreference,
} from '../../helper-classes/listUtil';
import { getTitle, getSplittedHeader } from './../../helper-classes/utility-react-functions';
import { getURL } from './epics.scoreVehicleList';
import AppLoader from '../../components/AppLoader';
import * as settingActions from '../Driver/Settings/actions.driverSettings';
import { RECORDS_PER_PAGE } from '../../constants/Config';
import analytics from '../../analytics/index';
import { VEHICLE_CENTRIC_SCORECARD_PREF_KEY, DRIVER_VEHICLE_CENTRIC_SCORECARD_PREF_KEY } from './constants.scoreVehicleList';
import { SCORECARD_PREF_KEY } from '../ScoreCardDriverList/constants.scoredriverList';

export type Props = {
    isUpdating: boolean,
    listType: string,
    fetchVehicleCardsList: Function,
    resetTable: Function,
    history: {
        push: Function,
    },
    location: any,
    driverSettings: Object,
    fetchDriverSettings: Function,
};

export type State = {
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{ operatorFirstName: string, operatorLastName: string}>,
        totalRecords: number,
    },
    order: string,
    orderBy: string,
    rowsPerPage: number,
    pageNumber: number,
    openExport: boolean,
    loading: boolean,
    selectedOption: Object,
    showListing: boolean,
};


class ScoreVehicleList extends Component<Props, State> {
    id: string;
    search: string;
    selectedDropDown: string;
    dateFilter: Object;
    isInitialRender: boolean;
    iframeUserToken: any;
    prependUrl: string;
    isIframe: boolean;
    userPreferences: any;
    userPreferenceKey: string;
    constructor(props: Props) {
        super(props);
        this.id = getParamValue(this.props, 'driverId');
        this.userPreferenceKey = this.needToShow() ? VEHICLE_CENTRIC_SCORECARD_PREF_KEY :
            DRIVER_VEHICLE_CENTRIC_SCORECARD_PREF_KEY;
        this.state = {
            showListing: false,
            openExport: false,
            tableData: {
                listHeaders: this.getListHeader(this.props.driverSettings),
                listData: [],
                totalRecords: 0,
                ischeckBox: false,
                isGroup: false,
            },
            order: 'asc',
            orderBy: 'assetName',
            rowsPerPage: RECORDS_PER_PAGE,
            pageNumber: 0,
            loading: false,
            selectedOption: {
                lable: 'CSV',
                value: 'csv',
                key: 'CSV',
            },
        };
        this.search = '';
        this.selectedDropDown = localStorage.getItem('driverFilter') || '30';
        const driverCustomDateFilter = JSON.parse(localStorage.getItem('driverCustomDateFilter') || '{"endDate":null,"startDate":null}');
        this.dateFilter = getDriverDropDownDates(this.selectedDropDown);
        if (this.selectedDropDown === 'custom') {
            this.dateFilter = driverCustomDateFilter;
        }
        this.dateFilter.startDate = getConvertedStartDateValue(this.dateFilter.startDate);
        this.dateFilter.endDate = getConvertedEndDateValue(this.dateFilter.endDate);
        this.isInitialRender = false;
        this.isIframe = this.props.location.pathname.match(/^\/iframe/i);
        this.userPreferences = null;
    }

    UNSAFE_componentWillMount() {
        getUserPreference(this, this.userPreferenceKey);
        this.prependUrl = '';
        const iframeUsertoken = localStorage.getItem('iframeUserToken');
        if (this.isIframe) {
            this.iframeUserToken = iframeUsertoken;
            this.prependUrl = '/iframe';
        }
        if (Object.keys(this.props.driverSettings).length === 0) {
            const dUrlConfig = {
                id: getLocalStorageValue('currentAccountGlobalId') || '',
                name: 'default', // for future uses
            };
            this.props.fetchDriverSettings(dUrlConfig);
        }
    }

    componentDidMount() {
        const { pageNumber, rowsPerPage } = this.state;
        this.props.fetchVehicleCardsList(
            this.id,
            pageNumber,
            rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.getFilterData(),
        );
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (JSON.stringify(this.props.driverSettings)
            !== JSON.stringify(nextprops.driverSettings)) {
            const { tableData } = this.state;
            tableData.listHeaders = this.getListHeader(nextprops.driverSettings);
            this.setState({ tableData });
        }

        if (this.props.listType !== nextprops.listType) {
            if (nextprops.listType === 'ScoreDriver') {
                const { pageNumber, rowsPerPage } = this.state;
                this.props.fetchVehicleCardsList(
                    this.id,
                    pageNumber,
                    rowsPerPage,
                    this.state.order,
                    this.state.orderBy,
                    this.getFilterData(),
                );
            }
        }
        if (nextprops.scorevehicle.length > 0) {
            const { scorevehicle, totalvehiclecards } = nextprops;
            const { tableData } = this.state;
            tableData.listData = scorevehicle;
            tableData.totalRecords = totalvehiclecards;
            this.setState({ tableData });
        } else {
            const { tableData } = this.state;
            tableData.listData = [];
            tableData.totalRecords = 0;
            this.setState({ tableData });
        }
    }
    componentDidUpdate() {
        if (getParamValue(this.props, 'driverId') !== this.id) {
            const { pageNumber, rowsPerPage } = this.state;
            this.id = getParamValue(this.props, 'driverId');
            this.props.fetchVehicleCardsList(
                this.id,
                pageNumber,
                rowsPerPage,
                this.state.order,
                this.state.orderBy,
                this.getFilterData(),
            );
        }
    }

    getData = () => {
        const { pageNumber, rowsPerPage } = this.state;
        this.props.fetchVehicleCardsList(
            this.id,
            pageNumber,
            rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.getFilterData(),
        );
        this.setState({ showListing: true });
    }

    getListHeader = (propsData) => {
        let driverSettings = {};
        if (Object.keys(propsData).length > 0 && !propsData.settings) {
            driverSettings = { ...propsData.driverSettings };
        } else {
            driverSettings = propsData;
        }
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [{
            assetName: <React.Fragment>Vehicle(<span style={{ textTransform: 'lowercase' }}>s</span>)</React.Fragment>,
            reportColumnTitle: 'Vehicle(s)',
            ...getColumnPreferences('assetName', preferences),
            showInDropDown: false,
            customMarkup: (data) => {
                const link = (
                    <Tooltip title={data.assetName} disableFocusListener>
                        <Link
                            to={(this.id) ? `${this.prependUrl}/driver-scorecard/trips/${data.assetGlobalId}/${this.id}` : `${this.prependUrl}/driver-scorecard/trips/${data.assetGlobalId}`}
                            style={{ color: 'inherit' }}
                            onClick={() => {
                                analytics.track('VEHICLE_LINK', { feature: 'DriverScorecard' });
                            }}
                        >
                            {data.assetName}
                        </Link>
                    </Tooltip>
                );
                return link;
            },
        },
        {
            ...getSplittedHeader(`Miles driven (${getDistanceString()})`, '', 'milesDriven'),
            ...getColumnPreferences('milesDriven', preferences),
            showInDropDown: getColumnPreferences('milesDriven', preferences),
            customMarkup: d => getMilesDrivenOrScore(d, 'milesDriven'),
        },
        {
            driveTime: 'Drive Time',
            ...getColumnPreferences('driveTime', preferences),
            showInDropDown: getColumnPreferences('driveTime', preferences),
            customMarkup: d => (d.driveTime !== null ? calculateTimeInHrs(d.driveTime) : 'N/A'),
        },
        {
            score: 'Score',
            ...getColumnPreferences('score', preferences),
            showInDropDown: getColumnPreferences('score', preferences),
            customMarkup: d => getMilesDrivenOrScore(d, 'score'),
        },
        {
            ...getSplittedHeader(
                `Speed Threshold (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-speed-threshold', 'est71To80')})`,
                'est71To80',
            ),
            ...getColumnPreferences('est71To80', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'est71To80') : false }),
            showInDropDown: false,
            customMarkup: data => (data.est71To80 !== null ? data.est71To80 : 'N/A'),
        },
        {
            ...getSplittedHeader(
                `Speed Threshold (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-speed-threshold', 'est81To90')})`,
                'est81To90',
            ),
            ...getColumnPreferences('est81To90', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'est81To90') : false }),
            showInDropDown: false,
            customMarkup: data => (data.est81To90 !== null ? data.est81To90 : 'N/A'),
        },
        {
            ...getSplittedHeader(
                `Speed Threshold (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-speed-threshold', 'estGt90')})`,
                'estGt90',
            ),
            ...getColumnPreferences('estGt90', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'estGt90') : false }),
            showInDropDown: false,
            customMarkup: data => (data.estGt90 !== null ? data.estGt90 : 'N/A'),
        },
        {
            ...getSplittedHeader(
                `Posted Speed (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-posted-speed', 'eps10To20')})`,
                'eps10To20',
            ),
            ...getColumnPreferences('eps10To20', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'eps10To20') : false }),
            showInDropDown: false,
            customMarkup: data => (data.eps10To20 !== null ? data.eps10To20 : 'N/A'),
        },
        {
            ...getSplittedHeader(
                `Posted Speed (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-posted-speed', 'eps21To30')})`,
                'eps21To30',
            ),
            ...getColumnPreferences('eps21To30', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'eps21To30') : false }),
            showInDropDown: false,
            customMarkup: data => (data.eps21To30 !== null ? data.eps21To30 : 'N/A'),
        },
        {
            ...getSplittedHeader(
                `Posted Speed (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-posted-speed', 'epsGt30')})`,
                'epsGt30',
            ),
            ...getColumnPreferences('epsGt30', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'epsGt30') : false }),
            showInDropDown: false,
            customMarkup: data => (data.epsGt30 !== null ? data.epsGt30 : 'N/A'),
        },
        {
            ...getSplittedHeader('Idle Time (min)', `(${getScorecardSettingsText('idling', 'idle6To18')})`, 'idle6To18'),
            ...getColumnPreferences('idle6To18', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'idle6To18') : false }),
            showInDropDown: false,
            customMarkup: data => (data.idle6To18 !== null ? data.idle6To18 : 'N/A'),
        },
        {
            ...getSplittedHeader('Idle Time (min)', `(${getScorecardSettingsText('idling', 'idle19To30')})`, 'idle18To30'),
            ...getColumnPreferences('idle18To30', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'idle19To30') : false }),
            showInDropDown: false,
            customMarkup: data => (data.idle18To30 !== null ? data.idle18To30 : 'N/A'),
        },
        {
            ...getSplittedHeader('Idle Time (min)', `(${getScorecardSettingsText('idling', 'idleGt30')})`, 'idleGt30'),
            ...getColumnPreferences('idleGt30', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'idleGt30') : false }),
            showInDropDown: false,
            customMarkup: data => (data.idleGt30 !== null ? data.idleGt30 : 'N/A'),
        },
        {
            hardBrake: 'Hard Braking ',
            ...getColumnPreferences('hardBrake', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'hardBrake') : false }),
            showInDropDown: false,
            customMarkup: data => (data.hardBrake !== null ? data.hardBrake : 'N/A'),
        },
        {
            ...getSplittedHeader('Hard Acceleration', '', 'hardAccel'),
            ...getColumnPreferences('hardAccel', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'hardAccel') : false }),
            showInDropDown: false,
            customMarkup: data => (data.hardAccel !== null ? data.hardAccel : 'N/A'),
        },
        {
            seatBelt: 'Seat Belt',
            ...getColumnPreferences('seatBelt', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'seatBelt') : false }),
            showInDropDown: false,
            customMarkup: data => (data.seatBelt !== null ? data.seatBelt : 'N/A'),
        }];

        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    getFilterData = () => ({
        search: this.search || '',
        dateFilter: this.dateFilter || {},
    })

    sortTableColumn = (order, orderBy, page) => {
        this.props.fetchVehicleCardsList(
            this.id,
            page.pageNumber,
            page.rowsPerPage,
            order,
            orderBy,
            this.getFilterData(),
        );
        this.setState({
            order,
            orderBy,
            rowsPerPage: page.rowsPerPage,
            pageNumber: page.pageNumber,
        }, () => {
            saveUserPreference(this, this.userPreferenceKey);
        });
        const params = {
            feature: 'DriverScorecard',
            sortColumn: orderBy,
            viewBy: 'Vehicle',
        };
        analytics.track('DRIVER_SCORECARD_SORT', params);
    }

    loadNextPage = (
        rows,
        value,
        order,
        orderBy,
    ) => {
        const updateUserPref = isUpdatePreference(this, { rowsPerPage: value, order, orderBy });
        this.setState({
            pageNumber: rows,
            rowsPerPage: value,
            order,
            orderBy,
        }, () => {
            if (updateUserPref) {
                saveUserPreference(this, this.userPreferenceKey);
            }
        });
        this.props.fetchVehicleCardsList(
            this.id,
            rows,
            value,
            order, orderBy,
            this.getFilterData(),
        );
    }

    ListSearch = (order, orderBy, filter) => {
        this.search = filter.search || '';
        this.props.fetchVehicleCardsList(
            this.id,
            filter.pageNumber,
            filter.rowsPerPage,
            order, orderBy,
            this.getFilterData(),
        );
        this.setState({
            order,
            orderBy,
            rowsPerPage: filter.rowsPerPage,
            pageNumber: filter.pageNumber,
        }, () => {
            saveUserPreference(this, this.userPreferenceKey);
        });
    }

    handleChange = (value, filterValue) => {
        this.selectedDropDown = value;
        this.dateFilter = filterValue;
        this.dateFilter.startDate = getConvertedStartDateValue(filterValue.startDate);
        this.dateFilter.endDate = getConvertedEndDateValue(filterValue.endDate);
        localStorage.setItem('driverFilter', value);
        this.setState({ pageNumber: 0 });
        const {
            rowsPerPage,
            order,
            orderBy,
        } = this.state;
        this.props.fetchVehicleCardsList(
            this.id,
            0,
            rowsPerPage,
            order, orderBy,
            this.getFilterData(),
        );
        const params = {
            feature: 'DriverScorecard',
            range: getDateRangeDropdownValue(value) || value,
        };
        analytics.track('VEHICLE_REPORTING_RANGE', params);
    }

    onChangeMenu = (value) => {
        if (value.key && value.key === 'driver') {
            const url = `${this.prependUrl}/driver-scorecard`;
            saveUserPreferenceForDriver(SCORECARD_PREF_KEY, 'driver-scorecard');
            this.props.history.push(`${url}`, {
                fromMenuChange: true,
            });
            const params = {
                feature: 'DriverScorecard',
                viewType: value.key,
            };
            analytics.track('VIEW_FILTER', params);
        }
    }

    needToShow = () => !this.id;

    getDriverName = () => {
        let name = 'Driver Scorecard';
        if (this.state.tableData &&
            this.state.tableData.listData &&
            this.state.tableData.listData.length > 0) {
            const vehicle = this.state.tableData.listData[0];
            name = `${vehicle.operatorFirstName} ${vehicle.operatorLastName}`;
        }
        return name;
    }

    dialogHandleClick = (type, value) => {
        if (type === 'show') {
            this.setState({ selectedOption: value });
        } else if (type === 'download') {
            this.openExport(false);
            const {
                tableData,
                order,
                orderBy,
                selectedOption,
            } = this.state;
            const params = {
                feature: 'DriverScorecard',
                exportType: value.value,
            };
            analytics.track('VEHICLE_DOWNLOAD', params);
            const url = getURL({
                id: this.id,
                pageNumber: 0,
                pageSize: tableData.totalRecords,
                order,
                orderBy,
                filter: this.getFilterData(),
            });
            this.setState({ loading: true });
            const header = getHeaderKeys(tableData.listHeaders);
            let timeFormat = getTimeFormatAsperUserSettting();
            timeFormat = (timeFormat === '24h') ? '24' : '12';
            downloadFile(
                `${url}&timeZone=${getUserSettingTimeZone()}&unitMeasurement=${getDistanceStringLong()}&reportType=${selectedOption.value}&reportColumns=${header.join(',')}&timeFormat=${timeFormat}`,
                `${this.needToShow() ? 'Vehicle Scorecard' : `${this.getDriverName()}-vehicles`} ${moment().format('YYYY-MM-DD HH-mm-ss')}.${selectedOption.value}`,
                { type: selectedOption.value },
                () => this.setState({ loading: false }),
            );
        } else {
            this.openExport(false);
        }
    }

    openExport = (openExport) => {
        this.setState({ openExport });
    }

    listTypeContextAction = (type) => {
        switch (type) {
        case 'export': this.openExport(true);
            break;
        default: break;
        }
    }

    getListTemplate = (selectedOption, openExport, isIframe) => (
        this.state.showListing ?
            <ListComponent
                redirectTo={this.props.history.push}
                tableData={this.state.tableData}
                loadNextPage={this.loadNextPage}
                orderBy={this.state.orderBy}
                order={this.state.order}
                isUpdating={this.props.isUpdating}
                rearrangeColumns={this.rearrangeColumns}
                resetTable={this.props.resetTable}
                showHideColumn={this.showHideColumn}
                sortTableColumn={this.sortTableColumn}
                headerDropDown={{
                    showDropDown: true,
                    onChange: this.handleChange,
                    selectedValue: this.selectedDropDown,
                }}
                dialogboxInfo={{
                    title: <span>Export {this.needToShow() ? '' : 'Vehicle'} data as {selectedOption.lable} for {this.needToShow() ? 'Vehicle Scorecard' : getTitle(this.getDriverName())}</span>,
                    dialogHandleClick: this.dialogHandleClick,
                    showDialog: openExport,
                    selectedOption,
                }}
                tableMenu={{
                    showTableMenu: this.needToShow(),
                    title: 'Menu',
                    options: [
                        {
                            key: 'vehicle',
                            lable: 'View by Vehicle',
                        },
                        {
                            key: 'driver',
                            lable: 'View by Driver',
                        },
                    ],
                    selected: 'vehicle',
                    onChange: this.onChangeMenu,
                }}
                tableTitle={this.needToShow() ? 'Vehicle Scorecard' : this.getDriverName()}
                tableButtons={['Export']}
                listTypeContextAction={this.listTypeContextAction}
                resizableHeader
                showSearch
                showToolbarBackButton={!this.needToShow()}
                ListSearch={this.ListSearch}
                isIframe={isIframe}
                rowsPerPage={this.state.rowsPerPage}
            />
            :
            <div style={{ height: '100%' }}>
                <AppLoader type="fullScreen" />
            </div>
    );

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: this.userPreferenceKey };
        rearrangeColumns(this, listHeaders, options);
    }

    showHideColumn = (header) => {
        const options = { prefKey: this.userPreferenceKey };
        showHideColumns(this, header, options);
        let colName = '';
        if (header.reportColumnTitle) {
            colName = header.reportColumnTitle;
        } else if (header.driveTime) {
            colName = 'Drive Time';
        } else if (header.score) {
            colName = 'Score';
        }
        const params = {
            feature: 'DriverScorecard',
            selectedColumn: colName,
            showColumn: header.show,
        };
        analytics.track('HIDE_SHOW_VEHICLE_COLUMN', params);
    }

    render() {
        const { selectedOption, openExport, loading } = this.state;
        return (
            <React.Fragment>
                {loading && <AppLoader type="fullScreen" />}
                <React.Fragment>
                    {this.isIframe ?
                        this.getListTemplate(selectedOption, openExport, true)
                        :
                        <MiniDrawer
                            redirectTo={this.props.history.push}
                        >
                            {this.getListTemplate(selectedOption, openExport, false)}
                        </MiniDrawer>
                    }
                </React.Fragment>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    driverSettings: state.driverSettings.driverSettings,
    scorevehicle: state.scoreVehicleList.scorevehicle,
    totalvehiclecards: state.scoreVehicleList.totalvehiclecards,
    isUpdating: state.scoreVehicleList.isUpdating,
});

const mapDispatchToProps = {
    ...actions,
    ...settingActions,
};

export default reduxConnect(ScoreVehicleList, mapDispatchToProps, mapStateToProps);
