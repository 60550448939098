/**
 * Error Messages and temporary data used in .spec files
 */
exports.CONSTANTS = {
    FORGOT_PASSWORD_FORM: {
        CREDENTIALS: {
            USER_NAME: 'test@gmail.com',
            SUBMITTED: 'true',
            ERROR: 'error',
        },
        EXPECT: {
            EMAIL_USERNAME_NOT_FOUND: 'Email/Username not found.',
            PLEASE_ENTER_EMAIL_OR_USERNAME: 'Please Enter Email or Username.',
            YOU_WILL_RECEIVE_EMAIL_WITH_LINK_TO_RESET_YOUR_PASSWORD:
                'You will receive an email with a link to reset your password.',
            ITS_TIME_TO_CHANGE_YOUR_PASSWORD: 'It\'s time to change your password.',
        },
    },
    GLOBAL_SEARCH: {
        SEE_ALL_RESULTS_THRESHOLD: 15,
        SEE_ALL_RESULTS_MIN: 10,
        VEHICLE_ASSIGNED: false,
        ENTER_THREE_CHARECTERS: 'Please enter at least 3 characters to perform the search.',
        FOCUSED_CLASSNAME: 'focused',
    },
    LOGINFORM: {
        PASSWORD: 'password',
        CREDENTIALS: {
            USER_NAME: 'tjbussfl',
            PASSWORD: '123456',
            EMAIL: 'tjbussfl@spireon.com',
        },
        EXPECT: {
            UNABLE_TO_LOGIN: 'Invalid credentials.',
            FORGOT_PASSWORD: 'Forgot Password?',
            PLEASE_ENTER_USERNAME: 'Please enter the username',
            PLEASE_ENTER_PASSWORD: 'Please enter the password',
        },
    },
    RESET_PASSWORD_FORM: {
        RESET_PASSWORD_LINK_EXPIRED:
            'Password Reset Link Has Expired, Please Try To Reset Your Password Again',
        PASSWORD_DO_NOT_MATCH: 'Passwords Do Not Match',
        PLEASE_ENTER_PASSWORD: 'Please Enter the Password',
        PLEASE_ENTER_CONFIRM_PASSWORD: 'Please Enter the Confirmation Password',
    },
    CONSOLE_ANALYTICS: {
        LOGS_INIT_CALLS: 'ANALYTICS - Init',
        LOGS_TRACK_CALLS: {
            EVENT_NAME: 'eventName',
            VALUE: { foo: 'bar' },
            EXPECT: 'ANALYTICS - Track: eventName, {"foo":"bar"}',
        },
        LOGS_TRACK_CALLS_WITHOUT_EXTRADATA: {
            EVENT_NAME: 'eventName',
            EXPECT: 'ANALYTICS - Track: eventName, undefined',
        },
        LOGS_SETUSER_CALLS: {
            VALUE: { foo: 'bar' },
            EXPECT: 'ANALYTICS - setUser: {"foo":"bar"}',
        },
        LOGS_CLEARUSER_CALLS: 'ANALYTICS - clearUser',
    },
    ANALYTICS: {
        EVENT_NAME: 'eventName',
        EXTRA_DATA: {
            EVENT_NAME: 'extraData',
            FOO: 'bar',
        },
        IDENTITY: {
            FOO: 'bar',
        },
        INFO: 'info',
    },
    CUSTOMER_SUPPORT_BRAND_LIST: ['Sprint Commercial'],
    CUSTOMER_SUPPORT_DETAILS: {
        fleetSupport: {
            email: 'fleet_support@spireon.com',
            phone: '877.819.0015',
        },
        'Sprint Commercial': {
            email: 'fleetaccountsupport@spireon.com',
            phone: '888.546.9810',
            increaseWidth: true,
        },
    },
    DRIVER_SCORECARD: '/driver-scorecard',
    COOKIE_EXPIRATION_TIME_IN_DAYS: 1,

    MINIMUM_SUPPORTED_EDGE_VERSION: 90,
    MINIMUM_SUPPORTED_CHROME_VERSION: 80,
    MINIMUM_SUPPORTED_FIREFOX_VERSION: 80,
    MINIMUM_SUPPORTED_SAFARI_VERSION: 10,
    AUTH_ERROR_CODES: {
        SESSION_EXPIRED: 'FLEET_SESSION_EXPIRED',
        ACCESS_DENIED: 'ACCESS_DENIED',
        ACCOUNT_SUSPENDED_LOGIN_ERROR: 'FLEET_ACCOUNT_SUSPENDED_LOGIN_ERROR',
        UNABLE_TO_LOGIN: 'FLEET_UNABLE_TO_LOGIN',
    },
};
