/* eslint-disable */
import moment from 'moment';
import { ALERT_SCHEDULE } from '../containers/Alerts/constants.alerts';

function santizeNumber(numToSantitize) {
    return numToSantitize.replace(/[^\d]/g, '').replace(/^.*(\d{10})$/, '$1');
}

export const validEmailOrPhoneNumber = (emailOrPhoneNumber) => {
    // Check
    // Mobile number pattern: 5555555555, 555-555-5555, (555)555-5555
    // AND Valid email
    return (
        validateMobilePhoneNumber(emailOrPhoneNumber) || validateEmail(emailOrPhoneNumber)
    );
};

const validateMobilePhoneNumber = phoneNumber => {
    return phoneNumber.match(/^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/);
}

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export const validatePhoneNumber = (phoneNumber) => {
    return /^\d+$/.test(santizeNumber(phoneNumber));
};

export const scheduleLabel = (StartD, StartT, EndD, EndT) => {
    return `${StartD} ${StartT} - ${EndD} ${EndT}`;
};

export const recipientLabel = (recipientInputValue) => {
    const targSanitized = santizeNumber(recipientInputValue);
    return `(${targSanitized.slice(0, 3)})${targSanitized.slice(3, 6)}-${targSanitized.slice(6, 10)}`;
};

export const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
        hours = '00';
    }

    if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
};

export const convertTime24to12 = (time24h) => {
    let modifier = 'AM';
    let [hours, minutes] = time24h.split(':');
    hours = parseInt(hours, 10);

    if (hours > 12) {
        hours = hours - 12;
        modifier = 'PM';
    } else if (hours === 12) {
        modifier = 'PM';
    } else if (hours === 0) {
        hours = 12;
    }

    return `${hours}:${minutes} ${modifier}`;
};

export const validate = (StartD, StartT, EndD, EndT) => {
    const beginningTime = moment(StartT, 'h:mma');
    const endTime = moment(EndT, 'h:mma');
    const errors = {};
    // Start day
    if (StartD === '') {
        errors['start-day'] = ALERT_SCHEDULE.ALERT_SCHEDULE_ERRORS.START_DAY_VALUE_EMPTY;
        return {
            Invalid: '',
            error: true,
        };
    }
    // Start time
    if (StartT === '') {
        errors['start-time'] = ALERT_SCHEDULE.ALERT_SCHEDULE_ERRORS.START_TIME_VALUE_EMPTY;
        return {
            Invalid: '',
            error: true,
        };
    }
    // End Day
    if (EndD === '') {
        errors['end-day'] = ALERT_SCHEDULE.ALERT_SCHEDULE_ERRORS.END_TIME_VALUE_EMPTY;
        return {
            Invalid: '',
            error: true,
        };
    }
    // End time
    if (EndT === '') {
        errors['end-time'] = ALERT_SCHEDULE.ALERT_SCHEDULE_ERRORS.END_DAY_VALUE_EMPTY;
        return {
            Invalid: '',
            error: true,
        };
    }
    // Time comparision validation
    if (StartD !== '' && StartT !== '' && EndD !== '' && EndT !== '') {
        if (StartD === EndD && beginningTime.isSameOrAfter(endTime)) {
            return {
                Invalid: ALERT_SCHEDULE.ALERT_SCHEDULE_ERRORS.END_TIME_LESSTHAN_START_TIME,
                error: true,
            };
        }
        return false;
    }
    return true;
};
export const validateDate = (StartD, EndD) => {
    // check for null and undefined
    let one = '';
    let two = '';
    if (StartD !== '' && EndD !== '') {
        if (StartD !== undefined) {
            const valueDate = new Date(StartD);
            one = valueDate.toISOString().slice(0, 10);
        } else {
            const date = new Date();
            const valueDate = new Date(date.setDate(date.getDate() - 7));
            one = valueDate.toISOString().slice(0, 10);
        }
        if (EndD !== undefined) {
            const valueDateTwo = new Date(EndD);
            two = valueDateTwo.toISOString().slice(0, 10);
        } else {
            const datetwo = new Date();
            const valueDateTwo = new Date(datetwo.setDate(datetwo.getDate()));
            two = valueDateTwo.toISOString().slice(0, 10);
        }
        const DateFrom = moment(one);
        const DateTo = moment(two);
        // Month difference
        const months = parseInt(DateTo.diff(DateFrom, 'months'), 10);

        if (DateFrom.isAfter(DateTo)) {
            return {
                Invalid: ' Date To should be greater than Date From',
                error: true,
            };
        }
        if (months > 3) {
            return {
                Invalid: 'Please select a date range that does not exceed 3 months',
                error: true,
            };
        }
        return false;
    }
    return false;
};
