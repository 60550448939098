const AddServiceRecordStyle = {
    servicePlanSelectContainer: {
        paddingRight: 0,
        paddingBottom: 0,
        paddingTop: 7,
        flex: '1 1 0',
        shadowOffset: { width: 0, height: 1 },
        shadowColor: '#666',
    },
    servicePlanIndicatorsContainer: {
        display: 'flex',
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 0,
        paddingLeft: 0,
        width: 20,
        height: 20,
    },
    root: {
        margin: '15px',
        marginLeft: '10px',
        'z-index': 1,
    },
    radio: {
        paddingLeft: 0,
        '&$checked': {
            color: '#007CFF',
        },
    },
    checked: {},
};

export default AddServiceRecordStyle;
