/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_ALERT_NAME } from './constants.landingPage';
import { fetchAlertNameSuccess, fetchAlertNameError } from './actions.landingPage';
import config from '../../../constants/Config';
import settingFilters from './landingPageSetting';
import {
    getAuthorizationHeader,
    getDeviceFeatures,
    getGrailsAppBasedURL,
    updateAccountSettings,
} from './../../../helper-classes/utility-functions';

type ObservarblesTypes = {
    getJSON: Function,
    of: Function,
};

const getUrl = () => {
    let url = '';
    const responseJson = getDeviceFeatures();
    const sort = encodeURIComponent('[{"property":"name","direction":"ASC"}]');
    const filterValues = settingFilters(responseJson);
    const randVal = new Date().getTime();
    const filters = encodeURIComponent(JSON.stringify(filterValues));
    url = `${getGrailsAppBasedURL()}/rest/json/alertType?_dc=${randVal}
    &sorts=${sort}&page=1&offset=0&max=50&filter=${filters}`;
    return url;
};

export const fetchAlertNameListAPI = (
    actions$: Function,
    store: Object,
    { getJSON, of }: ObservarblesTypes,
) =>
    actions$.ofType(FETCH_ALERT_NAME).mergeMap(() => {
        const headers = {
            Authorization: getAuthorizationHeader(),
        };
        // To get the latest Account settings, we are calling
        // accountSettings before alertType api call
        const randVal = new Date().getTime();
        return getJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/accountSettings?_dc=${randVal}`, headers)
            .mergeMap((accountSettings) => {
                updateAccountSettings(accountSettings);
                return getJSON(getUrl(), headers);
            })
            .map(result => fetchAlertNameSuccess(result))
            .catch(error => of(fetchAlertNameError(error)));
    });
export default combineEpics(fetchAlertNameListAPI);
