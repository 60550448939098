/* @flow */
import * as c from './constants.sendCommand';
import { appNotificationHandler } from '../../../components/SharedComponents/NotificationHandler/actions.appNotifications';
import type { AssetSendCmdAction } from './constants.sendCommand';
import store from '../../../constants/Store';
import { getUserToken } from '../../../helper-classes/utility-functions';

export const cmdStatusHandler = (commandSent: string, response: Object) => {
    if (getUserToken() !== '') {
        const dataForNotification = {
            type: 'snackbar',
            extraData: {
                msg: 'Send command is in progress...',
                assetId: response.assetId,
                commandStatus: response.status,
                commandSent,
            },
            overrideConfig: {
                key: response.assetId,
                autoHideDuration: 2000,
                action: undefined,
            },
        };

        if (typeof (response.status) === 'number' && response.status !== 200) {
            dataForNotification.extraData.commandStatus = 'Failed';
            dataForNotification.extraData.msg = (response.response && response.response.message) || 'Unable to send command to the asset';
        } else if (c.SUPPORTED_COMMANDS.indexOf(response.command) > -1) {
            if (response.status === 'Timeout') {
                dataForNotification.extraData.msg = 'Timeout: Unable to fetch command status';
            } else if (response.status === 'Failed') {
                dataForNotification.extraData.msg = 'Failed to send command to the asset';
            } else if (response.status === 'Success') {
                dataForNotification.extraData.msg = 'Command sent to the asset successfully';
            }
        } else {
            dataForNotification.extraData.commandStatus = 'Failed';
            dataForNotification.extraData.msg = 'This is not a valid command';
        }

        store.dispatch(appNotificationHandler([dataForNotification]));
    }
};

export const cmdStatusHandlerError = (error: Object): AssetSendCmdAction => ({
    type: c.COMMAND_STATUS_ERROR,
    payload: { error },
});

export const sendCmdToAsset = (data: Object): AssetSendCmdAction => ({
    type: c.SEND_COMMAND,
    payload: { ...data },
});

export const sendCmdToAssetSuccess = (res: Object) : AssetSendCmdAction => ({
    type: c.SEND_COMMAND_SUCCESS,
    payload: { ...res.response },
});

export const sendCmdToAssetError = (commandSent: string, error: Object, assetId: string) => {
    const dataForNotification = {
        type: 'snackbar',
        extraData: {
            msg: (error.response && error.response.message) || 'Unable to send command to the asset',
            assetId,
            commandStatus: 'Failed',
            commandSent,
        },
        overrideConfig: {
            key: assetId,
            autoHideDuration: 2000,
            action: undefined,
        },
    };

    store.dispatch(appNotificationHandler([dataForNotification]));
};

export const fetchAssetAllowedCmdList = (data: Object): AssetSendCmdAction => ({
    type: c.FETCH_ASSET_ALLOWED_CMDS,
    payload: { ...data },
});

export const fetchAssetAllowedCmdListSuccess = (response: Object): AssetSendCmdAction => ({
    type: c.FETCH_ASSET_ALLOWED_CMDS_SUCCESS,
    payload: { response },
});

export const fetchAssetAllowedCmdListError = (error: Object): AssetSendCmdAction => ({
    type: c.FETCH_ASSET_ALLOWED_CMDS_ERROR,
    payload: { error },
});
