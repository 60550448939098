/* @flow */

export const FETCH_LANDMARKS: string = 'FETCH_LANDMARKS';
export const FETCH_LANDMARKS_SUCCESS: string = 'FETCH_LANDMARKS_SUCCESS';
export const FETCH_LANDMARKS_ERROR: string = 'FETCH_LANDMARKS_ERROR';
export const FETCH_NEAREST_ASSET: string = 'FETCH_NEAREST_ASSET';
export const FETCH_NEAREST_ASSET_SUCCESS: string = 'FETCH_NEAREST_ASSET_SUCCESS';
export const FETCH_NEAREST_ASSET_ERROR: string = 'FETCH_NEAREST_ASSET_ERROR';
export const FETCH_ASSET_IN_LANDMARK: string = 'FETCH_ASSET_IN_LANDMARK';
export const FETCH_ASSET_IN_LANDMARK_SUCCESS: string = 'FETCH_ASSET_IN_LANDMARK_SUCCESS';
export const FETCH_ASSET_IN_LANDMARK_ERROR: string = 'FETCH_ASSET_IN_LANDMARK_ERROR';
export type LandmarkTypeAction = {
    type: string;
    payload?: Object;
}
