/* @flow */

import { getUserToken, getAuthorizationHeader } from '../helper-classes/utility-functions';

export const getData = (url: string, option: Object = {}) => {
    const options = {
        method: 'GET',
        headers: {
            'X-Nspire-UserToken': getUserToken(),
            'Content-Type': 'application/json',
        },
        ...option,
    };
    return fetch(url, options);
};

export const postData = (url: string, body: Object) => {
    const options = {
        method: 'POST',
        headers: {
            'X-Nspire-UserToken': getUserToken(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };
    return fetch(url, options);
};

export const getGrailsData = (url: string, option: Object = {}) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthorizationHeader(),
        },
        ...option,
    };
    return fetch(url, options);
};

export const postGrailsData = (url: string, body: Object) => {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthorizationHeader(),
        },
        body: JSON.stringify(body),
    };
    return fetch(url, options);
};
