/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import ListComponent from '../../components/SharedComponents/ListComponent/ListComponent';
import { rearrangeColumns, saveUserPreference, getParsedPreferencesValue } from '../../helper-classes/listUtil';
import { TRIP_EVENTS_PREF_KEY } from '../../containers/Assets/constants.assets';
import { sortEvents } from './EventsListUtil';
import customStyle from './../../components/SharedComponents/ListComponent/ListComponent.module.scss';

const ROWS_PER_PAGE = 50;

export type Props = {
    events: Array<{}>,
    setZoomEvent: Function,
    zoomEvent: Object,
    listHeaders: Array<{show: boolean}>,
    isPrint: boolean,
    hidePagination: boolean,
    overrideStyle: any,
    isLoader: Boolean,
    userPreferences: any,
    tripId: string,
    totalEventRows: number,
    isMapRefreshed: boolean,
};

export type State = {
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{}>,
        totalRecords: number,
        ischeckBox: boolean,
        isGroup: boolean,
    },
    events: Array<{}>,
    rowsPerPage: number,
    order: string,
    orderBy: string,
    currentPage: number,
};

class EventsList extends Component<Props, State> {
    userPreferences: any;
    listComponentStyle: Object;
    constructor(props: Props) {
        super(props);
        let rows = (this.props.hidePagination && this.props.totalEventRows) ?
            this.props.totalEventRows : ROWS_PER_PAGE;
        if (props.userPreferences && props.userPreferences.value) {
            const pref = JSON.parse(props.userPreferences.value);
            if (pref && pref.rowsPerPage && !Number.isNaN(pref.rowsPerPage)) {
                rows = pref.rowsPerPage;
            }
        }
        this.state = {
            tableData: {
                listHeaders: this.props.listHeaders,
                listData: (props.events && props.events.length > 0)
                    ? props.events.slice(0, rows) : [],
                totalRecords: (props.events && props.events.length > 0) ? props.events.length : 0,
                ischeckBox: false,
                isGroup: false,
            },
            events: (props.events && props.events.length > 0) ? props.events : [],
            rowsPerPage: rows,
            order: 'asc',
            orderBy: 'eventTime',
            currentPage: 0,
        };
        this.listComponentStyle = {
            paper: {
                margin: 0,
                width: '100%',
            },
            tableWrapper: {
                height: this.props.isPrint ? 'max-content' : 'calc(100vh - 226px)',
            },
            ...this.props.overrideStyle,
        };
    }

    componentDidMount() {
        this.updateUserPreferenceValue(this.props.userPreferences);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const tableData = { ...this.state.tableData };
        let { rowsPerPage } = this.state;
        const { currentPage, order, orderBy } = this.state;

        if (this.props.hidePagination && (nextProps.totalEventRows !== this.props.totalEventRows)) {
            rowsPerPage = nextProps.totalEventRows;
            this.setState({ rowsPerPage });
        }

        if (JSON.stringify(nextProps.events) !== JSON.stringify(this.props.events)) {
            let page = 0;
            if (this.props.isMapRefreshed) {
                page = currentPage;
            }
            tableData.listData = sortEvents(nextProps.events, order, orderBy)
                .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage);
            tableData.totalRecords = nextProps.events.length;
            this.setState({ tableData, events: nextProps.events });
        }

        if (nextProps.listHeaders !== this.props.listHeaders) {
            const newTableData = this.state.tableData;
            newTableData.listHeaders = nextProps.listHeaders;
            this.setState({ tableData: newTableData });
        }

        if (nextProps.userPreferences !== this.props.userPreferences) {
            this.updateUserPreferenceValue(nextProps.userPreferences);
        }
    }

    updateUserPreferenceValue = (userPreferences: string) => {
        this.userPreferences = userPreferences;
        const parsedUserPreference = getParsedPreferencesValue(userPreferences);

        if (parsedUserPreference &&
            parsedUserPreference.rowsPerPage) {
            this.setState({ rowsPerPage: parsedUserPreference.rowsPerPage });
        }
    }

    loadNextPage = (
        page,
        rowsPerPage,
    ) => {
        let updatePref = false;
        if (this.state.rowsPerPage !== rowsPerPage) {
            updatePref = true;
        }
        const { tableData, events } = this.state;

        tableData.listData = events.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage);

        this.setState({
            tableData,
            rowsPerPage,
            currentPage: page,
        }, () => {
            if (updatePref) {
                saveUserPreference(this, TRIP_EVENTS_PREF_KEY);
            }
        });
    }

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: TRIP_EVENTS_PREF_KEY };
        rearrangeColumns(this, listHeaders, options);
    }

    sortTableColumn = (order, orderBy, page) => {
        const { tableData, events } = this.state;

        tableData.listData = sortEvents(events, order, orderBy);
        this.loadNextPage(page.pageNumber, page.rowsPerPage);

        this.setState({
            tableData,
            order,
            orderBy,
        });
    };


    render() {
        return (
            <ListComponent
                tableData={this.state.tableData}
                tableButtons={[]}
                tableClasses={{ rowClasses: customStyle['trip-list-row'] }}
                key={this.props.tripId}
                resizableHeader
                showSearch={false}
                overrideStyle={this.listComponentStyle}
                hideToolbar
                rearrangeColumns={this.rearrangeColumns}
                loadNextPage={this.loadNextPage}
                sortTableColumn={this.sortTableColumn}
                order={this.state.order}
                orderBy={this.state.orderBy}
                rowsPerPage={this.state.rowsPerPage}
                setSelectedRow={this.props.setZoomEvent}
                selectedRow={this.props.zoomEvent}
                hidePagination={this.props.hidePagination}
                isUpdating={this.props.isLoader}
                rowsPerPageOptions={[10, 20, 30, 50, 100, 300]}
            />
        );
    }
}

export default withStyles({}, { withTheme: true })(EventsList);
