import moment from 'moment';
import { getAssets, getAssetsGrails, getServicePlans, getAssetGroupsGlobalId } from '../../containers/Maintenance/helper-classes/common-services';
import dropdownNested from '../../assets/icons/dropdown-nested.svg';

const dateFormat = 'YYYY-MM-DDTHH:mm:ss';
const dateRangeType = 'date_range';
const dateRangeOption = {
    id: 100,
    name: 'Date Range',
    type: dateRangeType,
};
const weekRangeOption = {
    id: 5,
    name: 'Last 7 days',
    getStartDate: () => (
        moment().startOf('day').utc().subtract(7, 'days')
            .toISOString()
    ),
    getEndDate: () => moment().endOf('day').utc().subtract(1, 'days')
        .toISOString(),
};
const allRangeOption = {
    id: 1,
    name: 'All Dates',
    getStartDate: () => moment('1970-01-01').startOf('day').toISOString(),
    getEndDate: () => moment().endOf('day').utc().toISOString(),
};

const dateFilterOptions = {
    total: 6,
    count: 6,
    content: [
        allRangeOption,
        {
            id: 2,
            name: 'Today',
            getStartDate: () => moment().startOf('day').utc().toISOString(),
            getEndDate: () => moment().endOf('day').utc().toISOString(),
        },
        {
            id: 3,
            name: 'Yesterday',
            getStartDate: () => moment().startOf('day').utc().subtract(1, 'days')
                .toISOString(),
            getEndDate: () => moment().endOf('day').utc().subtract(1, 'days')
                .toISOString(),
        },
        {
            id: 4,
            name: 'Last 3 days',
            getStartDate: () => moment().startOf('day').utc().subtract(3, 'days')
                .toISOString(),
            getEndDate: () => moment().endOf('day').utc().subtract(1, 'days')
                .toISOString(),
        },
        weekRangeOption,
        dateRangeOption,
    ],
};

const diagnosticDateFilterOptions = {
    total: 2,
    count: 2,
    content: [
        allRangeOption,
        dateRangeOption,
    ],
};

const getDateOptions = () => new Promise((resolve) => { resolve(dateFilterOptions); });
const getDiagnosticDateOptions =
    () => new Promise((resolve) => { resolve(diagnosticDateFilterOptions); });

const startDateFilter = {
    filterType: 'date',
    filterTitle: 'Date From',
    maxProperty: 'endDate',
    property: 'startDate',
    propertyType: 'Date',
    disableSearch: true,
    defaultValue: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    parentProperty: 'filterDate',
};

const endDateFilter = {
    filterType: 'date',
    filterTitle: 'Date To',
    property: 'endDate',
    minProperty: 'startDate',
    propertyType: 'Date',
    disableSearch: true,
    defaultValue: moment().format('YYYY-MM-DD'),
    parentProperty: 'filterDate',
};

const assetFilter = {
    filterOptions: getAssetsGrails,
    selectedOptions: [],
    filterType: 'select',
    filterTitle: 'Asset',
    selectPlaceholder: 'All Assets',
    property: 'assetId',
    dataArrayParam: 'data',
    datumValueParam: 'assetId',
    datumLabelParam: 'assetName',
    dataCountParam: 'max',
    propertyType: 'Long',
    disableSearch: false,
};

const assetFilterWithGlobalId = {
    filterOptions: getAssets,
    selectedOptions: [],
    filterType: 'select',
    filterTitle: 'Asset',
    selectPlaceholder: 'All Assets',
    property: 'assetId',
    dataArrayParam: 'data',
    datumValueParam: 'id',
    datumLabelParam: 'name',
    dataCountParam: 'count',
    propertyType: 'string',
    disableSearch: false,
};

const assetGroupFilter = {
    filterOptions: getAssetGroupsGlobalId,
    selectedOptions: [],
    filterType: 'select',
    filterTitle: 'Asset Group',
    selectPlaceholder: 'All Groups',
    property: 'assetGroupId',
    dataArrayParam: 'data',
    datumValueParam: 'id',
    datumLabelParam: 'name',
    dataCountParam: 'max',
    propertyType: 'Long',
    isMulti: false,
    disableSearch: true,
    selectStyles: {
        option: (s, { data }) => {
            const obj = data.data || {};
            let newStyle = { ...s };
            if (obj.nestLevel) {
                newStyle = {
                    ...newStyle,
                    marginLeft: 8 + (obj.nestLevel * 16),
                };
            }
            if (obj.childNodes && obj.childNodes.length > 0) {
                newStyle = {
                    ...newStyle,
                    marginLeft: (newStyle.marginLeft || 0) + 6,
                    paddingLeft: 18,
                    backgroundImage: `url(${dropdownNested})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left center',
                };
            }
            return newStyle;
        },
    },
};

const MaintenanceFilterHelper = {
    dateFilterOptions,
    weekRangeOption,
    allRangeOption,
    dateRangeOption,
    dateRangeType,
    dateFormat,
    dateRangeSelected: (selectedFilters) => {
        let dateRangeSelected = false;
        selectedFilters.forEach((filter) => {
            if (filter.property === 'filterDate' && filter.data.id === dateRangeOption.id) {
                dateRangeSelected = true;
            }
        });
        return dateRangeSelected;
    },
    servicePlanFilter: {
        filterOptions: getServicePlans,
        selectedOptions: [],
        filterType: 'select',
        filterTitle: 'Plan Name',
        selectPlaceholder: 'All Plans',
        property: 'servicePlanId',
        propertyType: 'string',
        disableSearch: true,
        isMulti: false,
        disableChips: false,
    },
    assetFilter,
    assetFilterWithGlobalId,
    assetGroupFilter: {
        filterOptions: getAssetGroupsGlobalId,
        selectedOptions: [],
        filterType: 'select',
        filterTitle: 'Asset Group',
        selectPlaceholder: 'All Asset Groups',
        property: 'assetGroupId',
        dataArrayParam: 'data',
        datumValueParam: 'id',
        datumLabelParam: 'name',
        dataCountParam: 'max',
        propertyType: 'Long',
        isMulti: false,
        disableSearch: true,
        disableChips: true,
        selectStyles: {
            option: (s, { data }) => {
                const obj = data.data || {};
                let newStyle = { ...s };
                if (obj.nestLevel) {
                    newStyle = {
                        ...newStyle,
                        marginLeft: 8 + (obj.nestLevel * 16),
                    };
                }
                if (obj.childNodes && obj.childNodes.length > 0) {
                    newStyle = {
                        ...newStyle,
                        marginLeft: (newStyle.marginLeft || 0) + 6,
                        paddingLeft: 18,
                        backgroundImage: `url(${dropdownNested})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'left center',
                    };
                }
                return newStyle;
            },
        },
    },
    assetGroupFilterWithGlobalId: {
        ...assetGroupFilter,
        filterOptions: getAssetGroupsGlobalId,
        dataCountParam: 'count',
        disableSearch: false,
    },
    dateRangeFilter: {
        filterOptions: getDateOptions,
        selectedOptions: [],
        filterType: 'select',
        filterTitle: 'Date Range *',
        property: 'filterDate',
        propertyType: 'Long',
        isMulti: false,
        disableSearch: true,
        subgroups: [
            { filter: startDateFilter, parent: dateRangeOption.id },
            { filter: endDateFilter, parent: dateRangeOption.id },
        ],
        disableChips: true,
        showSelection: true,
    },
    diagnosticDateRangeFilter: {
        filterOptions: getDiagnosticDateOptions,
        selectedOptions: [],
        filterType: 'select',
        filterTitle: 'Date Range *',
        property: 'filterDate',
        propertyType: 'Long',
        isMulti: false,
        disableSearch: true,
        subgroups: [
            { filter: startDateFilter, parent: dateRangeOption.id },
            { filter: endDateFilter, parent: dateRangeOption.id },
        ],
        disableChips: true,
        showSelection: true,
    },
    startDateFilter,
    endDateFilter,
    alertListPreselects: [
        {
            label: weekRangeOption.name,
            value: weekRangeOption.id,
            data: weekRangeOption,
            property: 'filterDate',
        },
    ],
    recordListPreselects: [
        {
            label: allRangeOption.name,
            value: allRangeOption.id,
            data: allRangeOption,
            property: 'filterDate',
        },
    ],
    diagnosticListPreselects: [
        {
            label: allRangeOption.name,
            value: allRangeOption.id,
            data: allRangeOption,
            property: 'filterDate',
        },
    ],
};
export default MaintenanceFilterHelper;
