/* @flow */

import { FETCH_ASSETS_LIST, FETCH_ASSETS_LIST_SUCCESS, FETCH_ASSETS_LIST_ERROR, RESET_ASSETS_LIST } from './constants.assetList';
import type { AssetListTypeAction } from './constants.assetList';

export const fetchAssetsList = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filter: Object,
): AssetListTypeAction => ({
    type: FETCH_ASSETS_LIST,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
        filter,
    },
});

export const fetchAssetsListSuccess =
    (assets: any, totalAssets: number): AssetListTypeAction => ({
        type: FETCH_ASSETS_LIST_SUCCESS,
        payload: { assets, totalAssets },
    });

export const resetTable =
    (): AssetListTypeAction => ({
        type: RESET_ASSETS_LIST,
    });

export const fetchAssetsListError = (error: string): AssetListTypeAction => ({
    type: FETCH_ASSETS_LIST_ERROR,
    payload: { error },
});
