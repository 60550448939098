/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_SERVICE_PLANS_LIST } from './constants.planList';
import { fetchPlanListSuccess, fetchPlanListError } from './actions.planList';
import config from '../../../constants/Config';

type ObservablesTypes = {
    getJSON: Function,
    of: Function
}

export const getPlans = (actions$: Function, store: Object, { getJSON, of }: ObservablesTypes) =>
    actions$
        .ofType(FETCH_SERVICE_PLANS_LIST)
        .mergeMap((action) => {
            const {
                order,
                orderBy,
                pageNumber,
                pageSize,
                filtersToSend,
            } = action.payload;
            const headers = {
                'X-Nspire-UserToken': store.getState().userSession.userToken,
            };
            const serviceURL = `${config.get('MAINTENANCE_SERVICES_URL')}/servicePlans`;
            let params = `offset=${pageNumber * pageSize}&limit=${pageSize}`;
            if (order && orderBy) {
                params += `&sort=${orderBy}:${order.toUpperCase()}`;
            }
            let servicePlanId = null;
            if (filtersToSend && filtersToSend.length > 0) {
                filtersToSend.forEach((filter) => {
                    if (filter.property === 'servicePlanId') {
                        servicePlanId = filter.value;
                    } else {
                        params += `&${filter.property}=${filter.value}`;
                    }
                });
            }
            if (servicePlanId) {
                return getJSON(`${serviceURL}/${servicePlanId}?`, headers)
                    .map((result) => {
                        const results = [];
                        results.push(result);
                        return fetchPlanListSuccess(results, 1);
                    })
                    .catch(error => of(fetchPlanListError(error)));
            }
            return getJSON(`${serviceURL}?${params}`, headers)
                .map(result => fetchPlanListSuccess(result.content, result.total))
                .catch(error => of(fetchPlanListError(error)));
        });

export default combineEpics(getPlans);
