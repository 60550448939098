/* @flow */

export const FETCH_TRIPS_DETAILS: string = 'FETCH_TRIPS_DETAILS';
export const FETCH_TRIPS_DETAILS_SUCCESS: string = 'FETCH_TRIPS_DETAILS_SUCCESS';
export const FETCH_TRIPS_DETAILS_ERROR: string = 'FETCH_TRIPS_DETAILS_ERROR';
export const FETCH_TRIPS_DIRECTION: string = 'FETCH_TRIPS_DIRECTION';
export const FETCH_TRIPS_DIRECTION_SUCCESS: string = 'FETCH_TRIPS_DIRECTION_SUCCESS';
export const FETCH_TRIPS_DIRECTION_ERROR: string = 'FETCH_TRIPS_DIRECTION_ERROR';
export const FETCH_TRIPS_LIMIT: number = 10;
export type TripTypeAction = {
    type: string;
payload?: Object;
}
