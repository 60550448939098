export const consoleAnalyticsFactory = console => ({
    init: () => console.log('ANALYTICS - Init'),

    track: (eventName, extraData) => console.log(`ANALYTICS - Track: ${eventName}, ${JSON.stringify(extraData)}`),

    setUser: identity => console.log(`ANALYTICS - setUser: ${JSON.stringify(identity)}`),

    clearUser: () => console.log('ANALYTICS - clearUser'),

});

export default consoleAnalyticsFactory(window.console);
