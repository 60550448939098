import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { getPermissionValue } from '../../helper-classes/utility-functions';

type Props = {
    classes : Object,
    redirectTo : Function,
    landmarkId: string,
}

const styles = theme => ({
    button: {
        margin: theme.spacing(),
        flexDirection: 'column',
    },
    label: {
        flexDirection: 'column',
        color: 'inherit',
    },
    buttonLabel: {
        fontSize: '14px',
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.4px',
        marginTop: '5px',
        textAlign: 'center',
        color: '#ffffff',
        textTransform: 'none',
    },
    icon: {
        width: '24px',
        height: '17px',
        objectFit: 'contain',
    },
    extendedIcon: {
        marginRight: theme.spacing(),
    },
    root: {
        backgroundColor: '#343e48',
        minHeight: '90px',
    },
});

function DetailActions(props: Props) {
    const { classes, redirectTo, landmarkId } = props;
    return (
        <Grid id="menu-actions" container className={classes.root} spacing={0}>
            {/* <Grid item xs={4}>
                <Button classes={{ root: classes.button, label: classes.label }}>
                    <SvgIcon style={{ fontSize: 22 }}>
                        <path fill="#ffffff" d="M13.5,8H12V13L16.28,15.54L17,
                        14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,
                        20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3" />
                    </SvgIcon>
                    <Typography className={classes.buttonLabel}>
                        Show
                    </Typography>
                    <Typography className={classes.buttonLabel}>
                        History
                    </Typography>
                </Button>
            </Grid> */}
            <Grid item xs={6} style={{ paddingLeft: '10%' }}>

                <Button
                    onClick={() => redirectTo(`/landmarks/${landmarkId}/nearest-assets`)}
                    classes={{ root: classes.button, label: classes.label }}
                    disabled={!landmarkId}
                >
                    <SvgIcon>
                        <path fill="#ffffff" d="M3,13.5L2.25,12H7.5L6.9,10.5H2L1.25,9H9.05L8.45,7.5H1.11L0.25,6H4A2,2 0 0,1 6,4H18V8H21L24,12V17H22A3,3 0 0,1 19,20A3,3 0 0,1 16,17H12A3,3 0 0,1 9,20A3,3 0 0,1 6,17H4V13.5H3M19,18.5A1.5,1.5 0 0,0 20.5,17A1.5,1.5 0 0,0 19,15.5A1.5,1.5 0 0,0 17.5,17A1.5,1.5 0 0,0 19,18.5M20.5,9.5H18V12H22.46L20.5,9.5M9,18.5A1.5,1.5 0 0,0 10.5,17A1.5,1.5 0 0,0 9,15.5A1.5,1.5 0 0,0 7.5,17A1.5,1.5 0 0,0 9,18.5Z" />
                    </SvgIcon>
                    <Typography className={classes.buttonLabel}>
                        Nearest
                    </Typography>
                    <Typography className={classes.buttonLabel}>
                        Assets
                    </Typography>
                </Button>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: '10%' }}>

                <Button
                    onClick={() => redirectTo(`/landmarks/edit/${landmarkId}`, 'editLandmark')}
                    classes={{ root: classes.button, label: classes.label }}
                    disabled={getPermissionValue('Landmarks') !== 'Modify' || !landmarkId}
                >
                    <SvgIcon>
                        <path fill="#ffffff" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                    </SvgIcon>
                    <Typography className={classes.buttonLabel}>
                        Edit
                    </Typography>
                    <Typography className={classes.buttonLabel}>
                        Landmark
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    );
}

DetailActions.propTypes = {
    // classes: PropTypes.object.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(DetailActions);
