/* @flow */
import {
    FETCH_ACCOUNT_SETTINGS,
    FETCH_ACCOUNT_SETTINGS_SUCCESS,
    FETCH_ACCOUNT_SETTINGS_ERROR,
} from './constants.spudNotification';
import type { SpudNameTypeAction } from './constants.spudNotification';

export const fetchAccountSettings = (): SpudNameTypeAction => ({
    type: FETCH_ACCOUNT_SETTINGS,
});

export const fetchAccountSettingsSuccess = (accountSettings: string):
SpudNameTypeAction => ({
    type: FETCH_ACCOUNT_SETTINGS_SUCCESS,
    payload: { accountSettings },
});

export const fetchAccountSettingsError = (error: string): SpudNameTypeAction => ({
    type: FETCH_ACCOUNT_SETTINGS_ERROR,
    payload: { error },
});
