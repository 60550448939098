/* @flow */
import React, { Component } from 'react';
import queryString from 'query-string';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.scoredriverList';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../components/SharedComponents/ListComponent/ListComponent';
import {
    getDriverDropDownDates,
    getLocalStorageValue,
    mngClmnBasedonSettings,
    downloadFile,
    getHeaderKeys,
    getUserSettingTimeZone,
    getDistanceStringLong,
    getTimeFormatAsperUserSettting,
    getMilesDrivenOrScore,
    getDistanceString,
    getSpeedUnitString,
    getScorecardSettingsText,
    saveUserPreferenceForDriver,
    getUserPreference as getIframeUserPreference,
} from '../../helper-classes/utility-functions';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    showHideColumns,
    rearrangeColumns, isUpdatePreference,
} from '../../helper-classes/listUtil';
import AppLoader from '../../components/AppLoader';
import {
    calculateTimeInHrs,
    getConvertedStartDateValue,
    getConvertedEndDateValue,
    getDateRangeDropdownValue,
} from '../../util/trip_util';
import { getURL } from './epics.scoredriverList';
import * as settingActions from '../Driver/Settings/actions.driverSettings';
import { getSplittedHeader } from './../../helper-classes/utility-react-functions';
import { RECORDS_PER_PAGE } from '../../constants/Config';
import analytics from '../../analytics/index';
import { DRIVER_CENTRIC_SCORECARD_PREF_KEY, SCORECARD_PREF_KEY } from './constants.scoredriverList';

export type Props = {
    isUpdating: boolean,
    listType: string,
    fetchDriverCardsList: Function,
    resetTable: Function,
    history: {
        push: Function,
    },
    driverSettings: Object,
    fetchDriverSettings: Function,
    location: any,
};

export type State = {
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{}>,
        totalRecords: number,
    },
    order: string,
    loading: boolean,
    orderBy: string,
    rowsPerPage: number,
    pageNumber: number,
    openExport: boolean,
    selectedOption: Object,
    showListing: boolean,
};


class ScoreDriverList extends Component<Props, State> {
    search: string;
    selectedDropDown: string;
    dateFilter: Object;
    isInitialRender: boolean;
    iframeUserToken: string;
    isIframe: boolean;
    userPreferences: any;
    isInitialLoad: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            showListing: false,
            openExport: false,
            tableData: {
                ischeckBox: false,
                isGroup: false,
                listHeaders: this.getListHeader(this.props.driverSettings),
                listData: [],
                totalRecords: 0,
            },
            order: 'asc',
            orderBy: 'operatorFirstName',
            rowsPerPage: RECORDS_PER_PAGE,
            pageNumber: 0,
            selectedOption: {
                lable: 'CSV',
                value: 'csv',
                key: 'CSV',
            },
            loading: false,
        };
        this.search = '';
        this.selectedDropDown = getLocalStorageValue('driverFilter') || '30';
        const driverCustomDateFilter = JSON.parse(getLocalStorageValue('driverCustomDateFilter') || '{"endDate":null,"startDate":null}');
        this.dateFilter = getDriverDropDownDates(this.selectedDropDown);
        if (this.selectedDropDown === 'custom') {
            this.dateFilter = driverCustomDateFilter;
        }
        this.dateFilter.startDate = getConvertedStartDateValue(this.dateFilter.startDate);
        this.dateFilter.endDate = getConvertedEndDateValue(this.dateFilter.endDate);
        this.isInitialRender = false;
        this.isIframe = this.props.location.pathname.match(/^\/iframe/i);
        this.userPreferences = null;
        this.isInitialLoad = true;
    }

    UNSAFE_componentWillMount() {
        const queryParams = queryString.parse(this.props.location.search);
        const iframeUserToken = localStorage.getItem('iframeUserToken');
        if (queryParams.apiToken) {
            localStorage.setItem('iframeUserToken', queryParams.apiToken);
            this.iframeUserToken = queryParams.apiToken;
        } else if (iframeUserToken && iframeUserToken !== null) {
            this.iframeUserToken = iframeUserToken;
        }
        getUserPreference(this, DRIVER_CENTRIC_SCORECARD_PREF_KEY);
        const fromMenuChange = this.props.location.state ?
            this.props.location.state.fromMenuChange : false;
        if (!fromMenuChange && (window.location.href.toLowerCase().indexOf('iframe/driver-scorecard/vehicles') > -1
            || window.location.href.toLowerCase().indexOf('iframe/driver-scorecard') > -1)) {
            let scorecardIframeUrl = '/iframe/driver-scorecard/vehicles';
            getIframeUserPreference(
                SCORECARD_PREF_KEY,
                queryParams.apiToken,
            ).then((response) => {
                const driverPreference =
                (response && response.value) ? response.value : null;
                if (driverPreference
                    && (driverPreference).replace(/"/g, '') === 'driver-scorecard') {
                    scorecardIframeUrl = '/iframe/driver-scorecard';
                }
                return this.props.history.push(`${scorecardIframeUrl}${this.props.location.search}`);
            });
        }
        if (Object.keys(this.props.driverSettings).length === 0) {
            const dUrlConfig = {
                id: getLocalStorageValue('currentAccountGlobalId') || '',
                name: 'default', // for future uses
            };
            this.props.fetchDriverSettings(dUrlConfig);
        }
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (JSON.stringify(this.props.driverSettings)
            !== JSON.stringify(nextprops.driverSettings) || this.isInitialLoad) {
            const { tableData } = this.state;
            tableData.listHeaders = this.getListHeader(nextprops.driverSettings);
            this.setState({ tableData });
            this.isInitialLoad = false;
        }

        if (this.props.listType !== nextprops.listType) {
            if (nextprops.listType === 'ScoreDriver') {
                const { pageNumber, rowsPerPage } = this.state;
                this.props.fetchDriverCardsList(
                    pageNumber,
                    rowsPerPage,
                    this.state.order,
                    this.state.orderBy,
                    this.getFilterData(),
                );
            }
        }
        const { tableData } = this.state;
        if (nextprops.scoredriver.length > 0) {
            const { scoredriver, totaldrivercards } = nextprops;
            tableData.listData = scoredriver.map(d => ({ ...d, id: d.operatorGlobalId }));
            tableData.totalRecords = totaldrivercards;
        } else {
            tableData.listData = [];
            tableData.totalRecords = 0;
        }
        this.setState({ tableData });
    }

    getData = () => {
        const { pageNumber, rowsPerPage } = this.state;
        this.props.fetchDriverCardsList(
            pageNumber,
            rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.getFilterData(),
        );
        this.setState({ showListing: true });
    }

    getListHeader = (propsData) => {
        let driverSettings = {};
        if (Object.keys(propsData).length > 0 && !propsData.settings) {
            driverSettings = { ...propsData.driverSettings };
        } else {
            driverSettings = propsData;
        }
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [{
            operatorFirstName: <React.Fragment>Driver</React.Fragment>,
            reportColumnTitle: 'Driver First Name',
            ...getColumnPreferences('operatorFirstName', preferences),
            showInDropDown: false,
            customMarkup: (data) => {
                const name = (`${data.operatorFirstName} ` || '') + (data.operatorLastName || '');
                let prependUrl = '';
                if (this.isIframe) {
                    prependUrl = '/iframe';
                }
                const link = (
                    <Tooltip title={name} disableFocusListener>
                        <Link
                            to={`${prependUrl}/driver-scorecard/driver/${data.operatorGlobalId}`}
                            style={{ color: 'inherit' }}
                            onClick={() => {
                                analytics.track('DRIVER_LINK', { feature: 'DriverScorecard' });
                            }}
                        >
                            {name}
                        </Link>
                    </Tooltip>
                );
                return link;
            },
        },
        {
            ...getSplittedHeader(`Miles driven (${getDistanceString()})`, '', 'milesDriven'),
            ...getColumnPreferences('milesDriven', preferences),
            showInDropDown: getColumnPreferences('score', preferences),
            customMarkup: d => getMilesDrivenOrScore(d, 'milesDriven'),
        },
        {
            driveTime: 'Drive Time',
            ...getColumnPreferences('driveTime', preferences),
            showInDropDown: getColumnPreferences('driveTime', preferences),
            customMarkup: d => (d.driveTime !== null ? calculateTimeInHrs(d.driveTime) : 'N/A'),
        },
        {
            score: 'Score',
            ...getColumnPreferences('score', preferences),
            showInDropDown: getColumnPreferences('score', preferences),
            customMarkup: d => getMilesDrivenOrScore(d, 'score'),
        },
        {
            ...getSplittedHeader(
                `Speed Threshold (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-speed-threshold', 'est71To80')})`,
                'est71To80',
            ),
            ...getColumnPreferences('est71To80', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'est71To80') : false }),
            showInDropDown: false,
            customMarkup: data => (data.est71To80 !== null ? data.est71To80 : 'N/A'),
        },
        {
            ...getSplittedHeader(
                `Speed Threshold (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-speed-threshold', 'est81To90')})`,
                'est81To90',
            ),
            ...getColumnPreferences('est81To90', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'est81To90') : false }),
            showInDropDown: false,
            customMarkup: data => (data.est81To90 !== null ? data.est81To90 : 'N/A'),
        },
        {
            ...getSplittedHeader(
                `Speed Threshold (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-speed-threshold', 'estGt90')})`,
                'estGt90',
            ),
            ...getColumnPreferences('estGt90', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'estGt90') : false }),
            showInDropDown: false,
            customMarkup: data => (data.estGt90 !== null ? data.estGt90 : 'N/A'),
        },
        {
            ...getSplittedHeader(
                `Posted Speed (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-posted-speed', 'eps10To20')})`,
                'eps10To20',
            ),
            ...getColumnPreferences('eps10To20', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'eps10To20') : false }),
            showInDropDown: false,
            customMarkup: data => (data.eps10To20 !== null ? data.eps10To20 : 'N/A'),
        },
        {
            ...getSplittedHeader(
                `Posted Speed (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-posted-speed', 'eps21To30')})`,
                'eps21To30',
            ),
            ...getColumnPreferences('eps21To30', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'eps21To30') : false }),
            showInDropDown: false,
            customMarkup: data => (data.eps21To30 !== null ? data.eps21To30 : 'N/A'),
        },
        {
            ...getSplittedHeader(
                `Posted Speed (${getSpeedUnitString()})`,
                `(${getScorecardSettingsText('exceed-posted-speed', 'epsGt30')})`,
                'epsGt30',
            ),
            ...getColumnPreferences('epsGt30', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'epsGt30') : false }),
            showInDropDown: false,
            customMarkup: data => (data.epsGt30 !== null ? data.epsGt30 : 'N/A'),
        },
        {
            ...getSplittedHeader('Idle Time (min)', `(${getScorecardSettingsText('idling', 'idle6To18')})`, 'idle6To18'),
            ...getColumnPreferences('idle6To18', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'idle6To18') : false }),
            showInDropDown: false,
            customMarkup: data => (data.idle6To18 !== null ? data.idle6To18 : 'N/A'),
        },
        {
            ...getSplittedHeader('Idle Time (min)', `(${getScorecardSettingsText('idling', 'idle19To30')})`, 'idle18To30'),
            ...getColumnPreferences('idle18To30', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'idle19To30') : false }),
            showInDropDown: false,
            customMarkup: data => (data.idle18To30 !== null ? data.idle18To30 : 'N/A'),
        },
        {
            ...getSplittedHeader('Idle Time (min)', `(${getScorecardSettingsText('idling', 'idleGt30')})`, 'idleGt30'),
            ...getColumnPreferences('idleGt30', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'idleGt30') : false }),
            showInDropDown: false,
            customMarkup: data => (data.idleGt30 !== null ? data.idleGt30 : 'N/A'),
        },
        {
            hardBrake: 'Hard Braking ',
            ...getColumnPreferences('hardBrake', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'hardBrake') : false }),
            showInDropDown: false,
            customMarkup: data => (data.hardBrake !== null ? data.hardBrake : 'N/A'),
        },
        {
            ...getSplittedHeader('Hard Acceleration', '', 'hardAccel'),
            ...getColumnPreferences('hardAccel', preferences, { show: driverSettings ? mngClmnBasedonSettings(driverSettings, 'hardAccel') : false }),
            showInDropDown: false,
            customMarkup: data => (data.hardAccel !== null ? data.hardAccel : 'N/A'),
        }];
        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    getFilterData = () => ({
        search: this.search || '',
        dateFilter: this.dateFilter || {},
    })

    sortTableColumn = (order, orderBy, page) => {
        this.props.fetchDriverCardsList(
            page.pageNumber,
            page.rowsPerPage,
            order,
            orderBy,
            this.getFilterData(),
        );
        this.setState({
            order,
            orderBy,
            rowsPerPage: page.rowsPerPage,
            pageNumber: page.pageNumber,
        }, () => {
            saveUserPreference(this, DRIVER_CENTRIC_SCORECARD_PREF_KEY);
        });
        const params = {
            feature: 'DriverScorecard',
            sortColumn: orderBy,
            viewBy: 'Driver',
        };
        analytics.track('DRIVER_SCORECARD_SORT', params);
    }

    ListSearch = (order, orderBy, filter) => {
        this.search = filter.search || '';
        this.props.fetchDriverCardsList(
            filter.pageNumber,
            filter.rowsPerPage,
            order, orderBy,
            this.getFilterData(),
        );
        this.setState({
            order,
            orderBy,
            rowsPerPage: filter.rowsPerPage,
            pageNumber: filter.pageNumber,
        }, () => {
            saveUserPreference(this, DRIVER_CENTRIC_SCORECARD_PREF_KEY);
        });
    }

    loadNextPage = (
        rows,
        value,
        order,
        orderBy,
    ) => {
        const updateUserPref = isUpdatePreference(this, { rowsPerPage: value, order, orderBy });
        this.setState({
            pageNumber: rows,
            rowsPerPage: value,
            order,
            orderBy,
        }, () => {
            if (updateUserPref) {
                saveUserPreference(this, DRIVER_CENTRIC_SCORECARD_PREF_KEY);
            }
        });
        this.props.fetchDriverCardsList(
            rows,
            value,
            order, orderBy,
            this.getFilterData(),
        );
    }

    handleChange = (value, filterValue) => {
        this.selectedDropDown = value;
        this.dateFilter = filterValue;
        this.dateFilter.startDate = getConvertedStartDateValue(filterValue.startDate);
        this.dateFilter.endDate = getConvertedEndDateValue(filterValue.endDate);
        localStorage.setItem('driverFilter', value);
        this.setState({ pageNumber: 0 });
        const {
            rowsPerPage,
            order,
            orderBy,
        } = this.state;
        this.props.fetchDriverCardsList(
            0,
            rowsPerPage,
            order, orderBy,
            this.getFilterData(),
        );
        const params = {
            feature: 'DriverScorecard',
            range: getDateRangeDropdownValue(value) || value,
        };
        analytics.track('DRIVER_REPORTING_RANGE', params);
    }

    onChangeMenu = (value) => {
        if (value.key && value.key === 'vehicle') {
            const url = this.iframeUserToken && this.isIframe ? '/iframe/driver-scorecard/vehicles' : '/driver-scorecard/vehicles';
            saveUserPreferenceForDriver(SCORECARD_PREF_KEY, 'driver-scorecard/vehicles');
            this.props.history.push(url);
            const params = {
                feature: 'DriverScorecard',
                viewType: value.key,
            };
            analytics.track('VIEW_FILTER', params);
        }
    }

    dialogHandleClick = (type, value) => {
        if (type === 'show') {
            this.setState({ selectedOption: value });
        } else if (type === 'download') {
            this.openExport(false);
            const {
                tableData,
                order,
                orderBy,
                selectedOption,
            } = this.state;
            const params = {
                feature: 'DriverScorecard',
                exportType: value.value,
            };
            analytics.track('DRIVER_DOWNLOAD', params);
            const url = getURL({
                pageNumber: 0,
                pageSize: tableData.totalRecords,
                order,
                orderBy,
                filter: this.getFilterData(),
            });
            this.setState({ loading: true });
            const header = getHeaderKeys(tableData.listHeaders);
            header.splice(1, 0, 'operatorLastName%7CDriver Last Name');
            let timeFormat = getTimeFormatAsperUserSettting();
            timeFormat = (timeFormat === '24h') ? '24' : '12';
            downloadFile(
                `${url}&timeZone=${getUserSettingTimeZone()}&unitMeasurement=${getDistanceStringLong()}&reportType=${selectedOption.value}&reportColumns=${header.join(',')}&timeFormat=${timeFormat}`,
                `Driver Score Card ${moment().format('YYYY-MM-DD HH-mm-ss')}.${selectedOption.value}`,
                { type: selectedOption.value },
                () => this.setState({ loading: false }),
            );
        } else {
            this.openExport(false);
        }
    }

    openExport = (openExport) => {
        this.setState({ openExport });
    }

    listTypeContextAction = (type) => {
        switch (type) {
        case 'export': this.openExport(true);
            break;
        default: break;
        }
    }

    getListTemplate = (selectedOption, openExport, isIframe) => (
        this.state.showListing ?
            <ListComponent
                redirectTo={this.props.history.push}
                tableData={this.state.tableData}
                loadNextPage={this.loadNextPage}
                orderBy={this.state.orderBy}
                order={this.state.order}
                isUpdating={this.props.isUpdating}
                rearrangeColumns={this.rearrangeColumns}
                resetTable={this.props.resetTable}
                showHideColumn={this.showHideColumn}
                sortTableColumn={this.sortTableColumn}
                headerDropDown={{
                    showDropDown: true,
                    onChange: this.handleChange,
                    selectedValue: this.selectedDropDown,
                }}
                dialogboxInfo={{
                    title: `Export data as ${selectedOption.lable} for Drive Scorecard`,
                    dialogHandleClick: this.dialogHandleClick,
                    showDialog: openExport,
                    selectedOption,
                }}
                tableTitle="Driver Scorecard"
                tableButtons={['Export']}
                tableMenu={{
                    showTableMenu: true,
                    title: 'Menu',
                    options: [
                        {
                            key: 'vehicle',
                            lable: 'View by Vehicle',
                        },
                        {
                            key: 'driver',
                            lable: 'View by Driver',
                        },
                    ],
                    selected: 'driver',
                    onChange: this.onChangeMenu,
                }}
                listTypeContextAction={this.listTypeContextAction}
                resizableHeader
                showSearch
                ListSearch={this.ListSearch}
                language={{
                    emptyTable: 'There are no drivers in this account, please switch to the vehicle-centric view.',
                }}
                isIframe={isIframe}
                rowsPerPage={this.state.rowsPerPage}
            />
            :
            <div style={{ height: '100%' }}>
                <AppLoader type="fullScreen" />
            </div>
    );

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: DRIVER_CENTRIC_SCORECARD_PREF_KEY };
        rearrangeColumns(this, listHeaders, options);
    }

    showHideColumn = (header) => {
        const options = { prefKey: DRIVER_CENTRIC_SCORECARD_PREF_KEY };
        showHideColumns(this, header, options);
        let colName = '';
        if (header.reportColumnTitle) {
            colName = header.reportColumnTitle;
        } else if (header.driveTime) {
            colName = 'Drive Time';
        } else if (header.score) {
            colName = 'Score';
        }
        const params = {
            feature: 'DriverScorecard',
            selectedColumn: colName,
            showColumn: header.show,
        };
        analytics.track('HIDE_SHOW_DRIVER_COLUMN', params);
    }

    render() {
        const { selectedOption, openExport } = this.state;
        return (
            <React.Fragment>
                {this.state.loading && <AppLoader type="fullScreen" />}
                <React.Fragment>
                    {this.isIframe ?
                        this.getListTemplate(selectedOption, openExport, true)
                        :
                        <MiniDrawer redirectTo={this.props.history.push}>
                            {this.getListTemplate(selectedOption, openExport, false)}
                        </MiniDrawer>
                    }
                </React.Fragment>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    driverSettings: state.driverSettings.driverSettings,
    scoredriver: state.scoredriverList.scoredriver,
    totaldrivercards: state.scoredriverList.totaldrivercards,
    isUpdating: state.scoredriverList.isUpdating,
});

const mapDispatchToProps = {
    ...actions,
    ...settingActions,
};

export default reduxConnect(ScoreDriverList, mapDispatchToProps, mapStateToProps);
