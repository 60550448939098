import React from 'react';
import customstyles from './LookLandingPage.module.scss';

type Props = {
    dataitemid: {
        imageLocation: String,
        description: String,
        idPostfix: String,
    },
};

const ImageWrapper = (props:Props) => (
    <div className={customstyles['overlay-image']}>
        <img src={props.dataitemid.imageLocation} className={customstyles.image} alt="" />
        <div className={customstyles.hover}>
            <div className={customstyles.text}>
                {props.dataitemid.description}
            </div>
        </div>
    </div>
);

export default ImageWrapper;
