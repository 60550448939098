/* eslint jsx-a11y/no-noninteractive-element-interactions: 0 */
/* @flow */
import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import { Scrollbars } from 'react-custom-scrollbars';
import AppLoader from '../../AppLoader';
import styles from './Addresses.module.scss';
import type { typeAddressesPrediction } from '../../../containers/Header/types';
import analytics from '../../../analytics';
import { getPermissionValue, removeSavedPage } from '../../../helper-classes/utility-functions';

const handleAddressesAnalytics = (e) => {
    const params = {
        feature: 'UniversalSearch',
    };
    if (e.type === 'LM_Nearest') {
        analytics.track('ADDRESS_NEAREST_LANDMARK', params);
    } else if (e.type === 'Assets_Nearest') {
        analytics.track('ADDRESS_NEAREST_ASSET', params);
    } else if (e.type === 'Map') {
        analytics.track('ADDRESS_MAP', params);
    }
};

const Addresses = (predictions: typeAddressesPrediction) => {
    const style = { width: 496, height: predictions.setMaxHeight };
    const items = (predictions.predictions).map((item, i) => (
        <ListItem
            key={item.id}
            data-itemid={item.id}
        >
            <Avatar className={styles.oval}>
                <i
                    data-itemid={item.id}
                    className={styles.icon}
                />
            </Avatar>
            <ListItemText
                data-itemid={item.id}
                disableTypography
                className={styles['list-items']}
                primary={
                    <div
                        id="main-address"
                        data-itemid={item.id}
                        data-name={item.structured_formatting &&
                        item.structured_formatting.main_text}
                        className={styles['main-address']}
                        alt={item.structured_formatting && item.structured_formatting.main_text}
                        title={item.structured_formatting && item.structured_formatting.main_text}
                    >
                        {item.structured_formatting && item.structured_formatting.main_text}
                    </div>
                }
                secondary={
                    <span
                        id="sub-address"
                        className={styles['sub-address']}
                        data-itemid={item.id}
                        data-name={item.structured_formatting &&
                        item.structured_formatting.secondary_text}
                        alt={item.structured_formatting &&
                        item.structured_formatting.secondary_text}
                        title={item.structured_formatting &&
                        item.structured_formatting.secondary_text}
                    >
                        {item.structured_formatting &&
                    item.structured_formatting.secondary_text}
                    </span>
                }
            />


            <div
                id="list-icon-container"
                data-itemid={item.id}
                data-name={item.structured_formatting &&
                item.structured_formatting.main_text}
                data-type="Nearest"
                className={`${styles['list-icon-container']}`}
            >
                {getPermissionValue('Landmarks') !== 'None' &&
                <ListItemIcon className={styles.listIconItem}>
                    <div
                        data-name={item.structured_formatting &&
                        item.structured_formatting.main_text}
                        data-type="LM_Nearest"
                        role="link"
                        tabIndex="-1"
                        onClick={() => {
                            removeSavedPage();
                            predictions.redirectToNearest('landmarks', `${item.place_id}`);
                        }}
                        onKeyUp={() => {
                        }}
                    >
                        <i
                            data-itemid={item.id}
                            data-name={item.structured_formatting &&
                            item.structured_formatting.main_text}
                            data-type="LM_Nearest"
                            className={styles['nearest-landmark']}
                        />
                        <span
                            data-itemid={item.id}
                            data-type="LM_Nearest"
                            className={styles['icon-span-text']}
                        >Nearest
                        </span>
                    </div>
                </ListItemIcon>
                }
                {getPermissionValue('Assets') !== 'None' &&
                <ListItemIcon className={styles.listIconItem}>
                    <div
                        data-itemid={item.id}
                        data-name={item.structured_formatting &&
                        item.structured_formatting.main_text}
                        role="link"
                        tabIndex="-2"
                        data-type="Assets_Nearest"
                        onClick={() => {
                            removeSavedPage();
                            predictions.redirectToNearest('assets', `${item.place_id}`);
                        }}
                        onKeyUp={() => {
                        }}
                    >
                        <i
                            data-name={item.structured_formatting &&
                            item.structured_formatting.main_text}
                            data-type="Assets_Nearest"
                            data-itemid={item.id}
                            className={styles['assets-icon-right']}
                        />
                        <span
                            data-itemid={item.id}
                            data-name={item.structured_formatting &&
                            item.structured_formatting.main_text}
                            data-type="Assets_Nearest"
                            className={styles['icon-span-text']}
                        >Nearest
                        </span>
                    </div>
                </ListItemIcon>
                }
                <ListItemIcon className={styles.listIconItem}>
                    <div
                        data-itemid={item.id}
                        data-name={item.structured_formatting &&
                        item.structured_formatting.main_text}
                        data-address={JSON.stringify(item)}
                        data-type="Map"
                        id={`map-option-${i}`}
                        role="link"
                        tabIndex="-1"
                        onClick={() => {
                            removeSavedPage();
                            predictions.redirectTo(`/address/${item.place_id}`);
                        }}
                        onKeyUp={() => {
                        }}
                    >
                        <i
                            data-itemid={item.id}
                            data-name={item.structured_formatting &&
                            item.structured_formatting.main_text}
                            data-address={JSON.stringify(item)}
                            data-type="Map"
                            className={styles['map-icon-right']}
                        />
                        <span
                            data-itemid={item.id}
                            data-name={item.structured_formatting &&
                            item.structured_formatting.main_text}
                            data-address={JSON.stringify(item)}
                            data-type="Map"
                            className={styles['icon-span-text']}
                        >Map
                        </span>
                    </div>
                </ListItemIcon>
            </div>
        </ListItem>));
    return (


        <List
            id="address-dropdown"
            className={`${styles['address-block']}`}
            onClick={e => handleAddressesAnalytics(e.target.dataset)}
            style={{ maxHeight: predictions.setMaxHeight }}
        >
            <Scrollbars style={style} key="Addresses" onScroll={predictions.onScroll}>
                {items}
                { predictions.isLoading ?
                    <div style={{ padding: 10, textAlign: 'center' }}>
                        <AppLoader
                            type="scale"
                            height={18}
                            width={2}
                            margin="1px"
                        />
                    </div>
                    : ''
                }
            </Scrollbars>
        </List>);
};


export default Addresses;
