import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import {
    Paper,
    FormControl,
    FormGroup,
    Divider,
    Grid,
    Button,
    Toolbar,
    Typography,
    Select,
    IconButton,
    MenuItem,
    InputLabel,
    Checkbox,
    FormControlLabel,
    TextField,
} from '@mui/material';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import * as actions from './actions.addDriver';
import { reduxConnect } from '../../hoc';
import Dialog from '../../components/SharedComponents/Dialog/Dialog';
import AppLoader from '../../components/AppLoader';
import backIcon from '../../assets/icons/back.png';
import {
    getPermissionValue,
    driverKeyFob,
    hasFeature,
} from '../../helper-classes/utility-functions';
import analytics from '../../analytics/index';

const styles = () => ({
    formControl: {
        padding: '0 16px 16px',
        width: '90%',
    },
    app: {
        paddingTop: 16,
        paddingLeft: 17,
        paddingRight: 23,
    },
    toolbar: {
        backgroundColor: '#343e48',
        color: '#ffffff',
    },
    titleWrap: {
        lineHeight: 2,
    },
    button: {
        margin: '0 10px',
    },
    primaryButton: {
        '&:hover': {
            backgroundColor: '#007aff',
        },
        backgroundColor: '#007aff',
        color: '#ffffff',
    },
    error: {
        color: '#f44336',
        margin: '8px 0 0 0',
        fontSize: '0.75rem',
        lineHeight: '1em',
    },
    inputLable: {
        fontSize: '.75rem',
        position: 'relative',
        transform: 'none',
        zIndex: 1,
    },
    clearButton: {
        width: 70,
        height: 36,
        marginTop: 9,
    },
    backButton: {
        verticalAlign: 'middle',
        paddingRight: 10,
        marginLeft: -12,
    },
    requiredField: {
        fontSize: 14,
        opacity: 0.87,
        whiteSpace: 'nowrap',
    },
    divider: {
        borderRadius: 2,
    },
    sideBySide: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '100%',
        marginTop: 0,
        whiteSpace: 'nowrap',
    },
    ddlFC: {
        width: '100%',
    },
    validatorContainer: {
        width: '68.5%',
        marginRight: 19,
    },
    dropdown: {
        flex: 'auto',
        minWidth: 152,
        margin: '0 20px 0 0',
        whiteSpace: 'nowrap',
    },
    mainGrid: {
        padding: '70px 40px',
    },
    mobileAccessLabel: {
        color: '#b0b8bf',
    },
    headerPullRight: {
        marginLeft: 'auto',
    },
});

export type Props = {
    history: {
        push: Function,
    },
    classes: Object,
    match: Object,
    licenseStates: Array,
    fetchLicenseStates: Function,
    addDriver: Function,
    fetchDriver: Function,
    updateDriver: Function,
    deleteDriver: Function,
    responseStatus: boolean,
    responseType: string,
    responseData: Object,
    closeDialog: Function,
    updateLoader: Function,
    driveInitProp: Function,
    loading: boolean,
    resendInvite: Function,
}

export type State = {
    userInfo: {
        firstName: string,
        lastName: string,
        phone: string,
        email: string,
        licenseNumber: string,
        licenseState: string,
        comments: string,
        userId: string,
        username: string,
    },
    keyFobValue: any,
    unassignedKeyFobs: Array,
    isFormSubmitted: boolean,
    openDialog: boolean,
    actionType: string,
    userInfoLoaded: boolean,
    isKeyFobSerialDropdownVisible: boolean,
    keyFobDropdownkey: string,
    isValidKeyFob: boolean,
    isMobile: boolean,
};

class AddDriver extends Component<Props, State> {
    id: any;
    ddlStyles: any;
    loadedWithMobileAccess: boolean; // fetch driver returns mobile access
    hadMobileAccess: boolean; // driver already has an associated user
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                licenseNumber: '',
                licenseState: '',
                comments: '',
                userId: '',
                username: '',
            },
            isFormSubmitted: false,
            openDialog: false,
            actionType: 'add',
            userInfoLoaded: false,
            isKeyFobSerialDropdownVisible: hasFeature('fleetDriverId'),
            keyFobValue: null,
            unassignedKeyFobs: [],
            keyFobDropdownkey: Math.random(),
            isValidKeyFob: true,
            isMobile: false,
        };
        this.id = '';
        this.button = [];
        this.loadedWithMobileAccess = false;
        this.hadMobileAccess = false;
        this.ddlStyles = {
            control: style => ({
                ...style,
                display: 'flex',
                width: 'auto',
                minWidth: '152px',
                borderWidth: '0 0 1px 0',
                borderRadius: 0,
                borderColor: 'rgb(211, 215, 219)',
                boxShadow: '0 0 0 0',
            }),
            indicatorSeparator: style => ({
                ...style,
                display: 'none',
            }),
            placeholder: style => ({
                ...style,
                color: '#b0b8bf',
            }),
            valueContainer: style => ({
                ...style,
                padding: 0,
            }),
            input: style => ({
                ...style,
                width: 'auto',
            }),
            container: style => ({
                ...style,
                width: 'calc(100% - 32%)',
                margin: '0 20px 9px 0',
                whiteSpace: 'nowrap',
            }),
        };
        props.driveInitProp();
    }

    UNSAFE_componentWillMount() {
        if (this.state.actionType === 'add') {
            this.loadDropdowns();
        }

        ValidatorForm.addValidationRule('isEmpty', (value) => {
            if (typeof value === 'string' && value.replace(/\s/gi, '') === '') {
                return false;
            } else if (typeof value === 'number' && value === 0) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isPhone', (value) => {
            if (typeof value === 'string' && value.length > 0) {
                // eslint-disable-next-line
                return value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,10}$/g);
            }
            return true;
        });
    }

    componentDidMount() {
        this.loadPageData();
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        const { actionType, userInfoLoaded } = this.state;

        this.handleKeyFobSerialVisibility();
        if (!nextprops.loading) {
            if ((actionType === 'edit') && !userInfoLoaded && Object.keys(nextprops.driverData).length > 0) {
                this.loadDropdowns(nextprops.driverData.id);
                this.setState({
                    userInfo: {
                        id: nextprops.driverData.id,
                        firstName: nextprops.driverData.firstName || '',
                        lastName: nextprops.driverData.lastName || '',
                        phone: nextprops.driverData.phone || '',
                        email: nextprops.driverData.email || '',
                        licenseNumber: nextprops.driverData.licenseNumber || '',
                        licenseState: nextprops.driverData.licenseState || '',
                        comments: nextprops.driverData.comments || '',
                        userId: (nextprops.driverData.userRef && nextprops.driverData.userRef.id) || '',
                        username: (nextprops.driverData.userRef && nextprops.driverData.userRef.username) || '',
                    },
                    keyFobValue: nextprops.driverData.keyFobRef ? { value: `${nextprops.driverData.keyFobRef.id}` || '', label: nextprops.driverData.keyFobRef.serialNumber } : null,
                    keyFobDropdownkey: Math.random(),
                    userInfoLoaded: true,
                    isMobile: (nextprops.driverData.appAccess &&
                        nextprops.driverData.appAccess.DRIVER_SCORECARD) || false,
                });
                this.loadedWithMobileAccess = nextprops.driverData.appAccess &&
                    nextprops.driverData.appAccess.DRIVER_SCORECARD;

                this.hadMobileAccess = this.loadedWithMobileAccess ||
                    (nextprops.driverData.appAccess &&
                        Object.keys(nextprops.driverData.appAccess).length === 0);
            }

            if ((['get', 'add', 'update', 'delete', 'resendInvite', 'keyFob'].includes(nextprops.responseType) && !nextprops.responseStatus) || (['add', 'update', 'delete', 'resendInvite'].includes(nextprops.responseType) && nextprops.responseStatus)) {
                this.setState({ openDialog: true, isFormSubmitted: false });
            }
        }
    }

    componentDidUpdate() {
        if (this.props.match.params.id && this.props.match.params.id !== this.id) {
            this.loadPageData();
        }
    }

    handleKeyFobSerialVisibility = () => {
        const visible = hasFeature('fleetDriverId');
        if (this.state.isKeyFobSerialDropdownVisible !== visible) {
            this.setState({ isKeyFobSerialDropdownVisible: visible });
        }
    }

    loadPageData = () => {
        this.props.updateLoader(true);
        this.setState({ userInfoLoaded: false, test: true });
        if (this.props.match.path.search('edit') > 0) {
            this.state.actionType = 'edit';
            this.id = this.props.match.params.id;
            const dUrlConfig = {
                id: this.props.match.params.id,
            };
            this.props.fetchDriver(dUrlConfig);
        }
    }

    loadDropdowns = () => {
        const lsUrlConfig = {
            query: '',
            page: 1,
            start: 0,
            limit: 100,
        };
        this.props.fetchLicenseStates(lsUrlConfig);
    }

    handleChange = (e) => {
        e.preventDefault();
        const newState = Object.assign({}, this.state);
        newState.userInfo[e.target.name] = e.target.value;
        this.setState(newState);
    };

    clearSelection = (e, eleName) => {
        e.preventDefault();
        const newState = Object.assign({}, this.state);
        if (eleName === 'assignedFob') {
            newState.keyFobValue = null;
            newState.isValidKeyFob = true;
        } else {
            newState.userInfo[eleName] = '';
        }
        this.setState(newState);
    }

    formatError = (content, error) => {
        let newContent = content;
        if (error && Object.keys(error).length > 0) {
            if ((error.response && (error.response.data || error.response.message)) ||
                (error.error && error.error.response && error.error.response.data)) {
                let message = '';
                if (error.response && error.response.data) {
                    message = error.response.data;
                } else if (error.response && error.response.message) {
                    message = [error.response.message];
                } else if (error.error.response && error.error.response.data) {
                    message = error.error.response.data;
                }

                newContent = (
                    <div>
                        {newContent}
                        <ul>
                            {
                                Object.values(message).map(d =>
                                    (<li
                                        // eslint-disable-next-line
                                        dangerouslySetInnerHTML={{ __html: d }}
                                    />))
                            }
                        </ul>
                    </div>
                );
            } else if (error.error && error.error.response && error.error.response.message) {
                newContent = (<span
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{__html: newContent + ' ' + error.error.response.message}}
                />);
            }
        } else {
            newContent = `${newContent} We're sorry we are currently experiencing some technical issues.`;
        }
        return newContent;
    }

    getDialogContent = () => {
        const { responseType, responseStatus, responseData } = this.props;
        const { actionType } = this.state;
        const hasMobileFeature = hasFeature('fleet.ProTier');
        let content = hasMobileFeature ? 'Are you sure you want to delete this driver\'s record? If a mobile username has been setup for this driver, then you will not be able to reuse that username' :
            'Are you sure you want to delete this driver\'s record?';

        if (actionType === 'edit' && responseType === 'resendInvite') {
            content = 'Resend invite successfully.';
            if (!responseStatus) {
                content = 'Details:';
                content = this.formatError(content, responseData);
            }
        } else if (actionType === 'add') {
            content = 'Driver details added successfully.';
            if (!responseStatus) {
                content = 'Details:';
                content = this.formatError(content, responseData);
            }
        } else if (actionType === 'edit') {
            content = 'Driver details updated successfully.';
            if (!responseStatus) {
                content = 'Details:';
                content = this.formatError(content, responseData);
            }
        } else if (actionType === 'delete' && responseType === 'delete') {
            content = 'Driver details deleted successfully.';
            if (!responseStatus) {
                content = 'Details:';
                content = this.formatError(content, responseData);
            }
        }
        return content;
    }

    getDialogType = () => {
        const { responseType, responseStatus } = this.props;
        const { actionType } = this.state;
        let type = 'confirm';
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleDialogConfirm(),
        }];

        if (actionType !== 'delete' || responseType === 'delete') {
            type = responseStatus ? 'success' : 'error';
        } else {
            this.button.splice(0, 0, { title: 'Cancel', color: 'primary', handleClick: () => this.setState({ openDialog: false, actionType: 'edit' }) });
        }
        return type;
    };

    handleDialogConfirm = () => {
        const {
            responseType,
            responseStatus,
            closeDialog,
            history,
            updateLoader,
            match,
            deleteDriver,
        } = this.props;
        const { actionType, openDialog } = this.state;
        closeDialog();

        if ((responseType === 'resendInvite' || responseType === 'keyFob') && actionType === 'edit') {
            this.setState(({ openDialog: false }));
        } else if ((actionType === 'add' || actionType === 'edit') || (actionType === 'delete' && responseType === 'delete')) {
            if (responseStatus || (responseType === 'get' && !responseStatus && openDialog)) {
                history.push('/drivers');
            } else {
                this.setState({ openDialog: false });
            }
        } else if (actionType === 'delete' && openDialog) {
            updateLoader(true);
            const ddUrlConfig = {
                id: match.params.id,
            };
            deleteDriver(ddUrlConfig);
            this.setState({ openDialog: false });
        }
    }

    handleInputChange(e) {
        const newState = Object.assign({}, this.state);
        if (e.target.value.length >= 0) {
            newState.userInfo[e.target.name] = e.target.value.trimStart();
        }
        this.setState(newState);
    }

    handleSubmit() {
        const {
            actionType,
            userInfo,
            keyFobValue,
            isMobile,
        } = this.state;
        if (keyFobValue && keyFobValue.label) {
            if (!keyFobValue.value) {
                userInfo.newKeyFobLabel = keyFobValue.label;
            } else {
                userInfo.keyFobId = keyFobValue.value;
            }
            const isValid = this.isHexadecimal(keyFobValue.label);
            if (!isValid) {
                this.setState({ isValidKeyFob: false });
                return false;
            }
        }
        this.props.updateLoader(true);

        if (!this.state.isFormSubmitted) {
            userInfo.isMobile = isMobile;
            const params = {
                feature: 'FLEET_Drivers',
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
            };
            if (actionType === 'edit') {
                userInfo.hadMobileAccess = this.hadMobileAccess;
                this.props.updateDriver(userInfo);
                analytics.track('EDIT_DRIVER', params);
            } else if (actionType === 'add') {
                userInfo.hadMobileAccess = false;
                this.props.addDriver(userInfo);
                analytics.track('ADD_DRIVER', params);
            }
        }

        this.setState({ isFormSubmitted: true });
        return true;
    }

    handleDelete() {
        const { actionType, userInfo } = this.state;
        if (actionType === 'edit' || actionType === 'delete') {
            this.setState({ actionType: 'delete', openDialog: true });
        }
        const params = {
            feature: 'FLEET_Drivers',
            id: userInfo.id,
        };
        analytics.track('DELETE_DRIVER', params);
    }

    promiseOptions = inputValue => new Promise((resolve, reject) => {
        const { userInfoLoaded } = this.state;
        const ukfUrlConfig = {
            query: inputValue || '',
            page: 1,
            offset: 0,
            max: 100,
        };
        const { unassignedKeyFobs } = this.state;
        if (inputValue && inputValue.length < 3
            && unassignedKeyFobs && unassignedKeyFobs.length > 0) {
            const data = unassignedKeyFobs.filter(d =>
                d.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
            return resolve(data);
        }
        if (this.props.match.params.id && !userInfoLoaded) {
            return reject();
        }

        return driverKeyFob(ukfUrlConfig).then((res: Response) => {
            let data = null;
            if (res.content) {
                data = res.content.map(d => ({ label: d.serialNumber, value: `${d.id}`, ...d }));
            }
            this.setState({ unassignedKeyFobs: data });
            return resolve(data);
        }, () => {
            reject();
        });
    });

    onSelectChange = (selectedValue) => {
        let isValidKeyFob = true;
        if (selectedValue && selectedValue.label &&
            !this.isHexadecimal(selectedValue.label)) {
            isValidKeyFob = false;
        }
        const value = selectedValue;
        if (!selectedValue.id) {
            value.value = null;
        }
        this.setState({ keyFobValue: value, isValidKeyFob });
    }

    isHexadecimal = (str) => {
        const regexp = /^[0-9a-f]+$/gi;
        return regexp.test(str);
    }

    handleCheckboxChange = () => {
        this.setState({
            isMobile: !this.state.isMobile,
        });
    };

    render() {
        const {
            classes, licenseStates, history, loading,
        } = this.props;
        const {
            isFormSubmitted,
            userInfo,
            openDialog,
            actionType,
            userInfoLoaded,
            keyFobValue,
            keyFobDropdownkey,
            isValidKeyFob,
            isMobile,
        } = this.state;
        const hasMobileAccessFeature = hasFeature('fleet.ProTier');
        const needInputValidation = hasFeature('fleet.Eld');

        return (
            <React.Fragment>
                {(loading || (actionType === 'edit' && !userInfoLoaded)) &&
                    <AppLoader type="fullScreen" />
                }
                <div className={classes.app}>
                    <Paper>
                        <Toolbar className={classes.toolbar}>
                            <span
                                className={classes.backButton}
                            >
                                <IconButton onClick={() => { history.push('/drivers'); }} size="large">
                                    <img src={backIcon} alt="backArrow" />
                                </IconButton>
                            </span>
                            <Typography variant="h6" color="inherit" className={classes.titleWrap}>
                                <span>{(actionType === 'add') ? 'Add' : 'Edit'} Driver</span>
                            </Typography>
                            { hasMobileAccessFeature &&
                                <span className={classes.headerPullRight}>
                                    <Typography color="inherit" className={classes.titleWrap}>
                                        A welcome email will be sent automatically
                                        when a driver is enabled for mobile access
                                    </Typography>
                                </span>
                            }
                        </Toolbar>
                        <ValidatorForm autoComplete="off" className={classes.form} onSubmit={() => this.handleSubmit()}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="stretch"
                                        className={classes.mainGrid}
                                    >
                                        <Grid item xs={5}>
                                            <TextValidator
                                                id="firstName"
                                                label="First Name*"
                                                name="firstName"
                                                placeholder="First Name"
                                                value={userInfo.firstName}
                                                className={classes.textField}
                                                validators={['isEmpty', 'minStringLength:2', 'maxStringLength:30']}
                                                onChange={e => this.handleInputChange(e)}
                                                margin="normal"
                                                errorMessages={['This field is required', 'Enter 2 or more characters', 'Only 30 characters are allowed']}
                                            />
                                            <TextValidator
                                                id="lastName"
                                                label="Last Name*"
                                                name="lastName"
                                                value={userInfo.lastName}
                                                className={classes.textField}
                                                placeholder="Last Name"
                                                validators={['isEmpty', 'minStringLength:2', 'maxStringLength:30']}
                                                onChange={e => this.handleInputChange(e)}
                                                errorMessages={['This field is required', 'Enter 2 or more characters', 'Only 30 characters are allowed']}
                                                margin="normal"
                                                autoComplete="none"
                                            />
                                            { needInputValidation ?
                                                <TextValidator
                                                    id="licenseNumber"
                                                    label="License Number*"
                                                    name="licenseNumber"
                                                    placeholder="License Number"
                                                    className={classes.textField}
                                                    value={userInfo.licenseNumber}
                                                    onChange={e => this.handleInputChange(e)}
                                                    margin="normal"
                                                    validators={['required', 'isEmpty']}
                                                    errorMessages={['This field is required', 'License number should not be empty']}
                                                    autoComplete="none"
                                                /> :
                                                <TextField
                                                    id="licenseNumber"
                                                    label="License Number"
                                                    name="licenseNumber"
                                                    placeholder="License Number"
                                                    className={classes.textField}
                                                    value={userInfo.licenseNumber}
                                                    onChange={e => this.handleInputChange(e)}
                                                    margin="normal"
                                                    autoComplete="none"
                                                />
                                            }
                                            { needInputValidation ?
                                                <TextValidator
                                                    id="phone"
                                                    name="phone"
                                                    label="Phone Number*"
                                                    className={classes.textField}
                                                    value={userInfo.phone}
                                                    placeholder="(555) 555-5555"
                                                    validators={['required', 'isEmpty', 'isPhone']}
                                                    onChange={e => this.handleInputChange(e)}
                                                    margin="normal"
                                                    errorMessages={['This field is required', 'Phone number should not be empty', 'Please enter a valid phone number']}
                                                    autoComplete="none"
                                                /> :
                                                <TextField
                                                    id="phone"
                                                    name="phone"
                                                    label="Phone Number"
                                                    className={classes.textField}
                                                    value={userInfo.phone}
                                                    placeholder="(555) 555-5555"
                                                    onChange={e => this.handleInputChange(e)}
                                                    margin="normal"
                                                    autoComplete="none"
                                                />
                                            }
                                            { needInputValidation || isMobile ?
                                                <TextValidator
                                                    id="email"
                                                    label="Email*"
                                                    name="email"
                                                    placeholder="Email"
                                                    className={classes.textField}
                                                    value={userInfo.email}
                                                    onChange={e => this.handleInputChange(e)}
                                                    margin="normal"
                                                    validators={['required', 'isEmpty', 'isEmail']}
                                                    errorMessages={['This field is required', 'Email address should not be empty', 'Please enter a valid email address']}
                                                    autoComplete="none"
                                                /> :
                                                <TextField
                                                    id="email"
                                                    label="Email"
                                                    name="email"
                                                    placeholder="Email"
                                                    className={classes.textField}
                                                    value={userInfo.email}
                                                    onChange={e => this.handleInputChange(e)}
                                                    margin="normal"
                                                    autoComplete="none"
                                                />
                                            }
                                            <TextValidator
                                                id="comments"
                                                label="Comments"
                                                name="comments"
                                                placeholder="Comments"
                                                multiline
                                                rows="2"
                                                className={classes.textField}
                                                value={userInfo.comments}
                                                onChange={e => this.handleInputChange(e)}
                                                validators={['maxStringLength:4000']}
                                                errorMessages={['Only 4000 characters are allowed']}
                                                margin="normal"
                                                autoComplete="none"
                                            />
                                            { hasMobileAccessFeature &&
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={isMobile}
                                                            onChange={this.handleCheckboxChange}
                                                            color="primary"
                                                        />
                                                    }
                                                    key="enableMobileAccess"
                                                    label="Enable Scorecard Mobile Access"
                                                    classes={{ label: classes.mobileAccessLabel }}
                                                />
                                            }
                                            { hasMobileAccessFeature && isMobile &&
                                                <Grid item>
                                                    <TextValidator
                                                        sx={{ '& .MuiInput-root:before': { borderBottom: '1px dotted rgba(0, 0, 0, 0.42)' } }}
                                                        id="loginEmail"
                                                        label="Login Email"
                                                        name="loginEmail"
                                                        placeholder="Login Email"
                                                        className={classes.textField}
                                                        value={(actionType === 'edit' && this.hadMobileAccess) ? userInfo.username : userInfo.email}
                                                        margin="normal"
                                                        autoComplete="none"
                                                        disabled
                                                    />
                                                </Grid>
                                            }
                                            { hasMobileAccessFeature && isMobile &&
                                            this.loadedWithMobileAccess &&
                                                <Grid item>
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        className={classes.primaryButton}
                                                        onClick={() =>
                                                            this.props.resendInvite(userInfo)}
                                                    >Resend Invite
                                                    </Button>
                                                </Grid>
                                            }
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="space-between"
                                                alignItems="stretch"
                                                spacing={4}
                                            >
                                                {this.state.isKeyFobSerialDropdownVisible &&
                                                <React.Fragment >
                                                    <Grid item xs={12}>
                                                        <FormControl className={classes.ddlFC}>
                                                            <div>
                                                                <InputLabel
                                                                    htmlFor="Key Fob Serial"
                                                                    disableAnimation
                                                                    className={classes.inputLable}
                                                                >
                                                                    {keyFobValue && keyFobValue.label ? 'Key Fob Serial' : ''}
                                                                </InputLabel>
                                                                <div className={classes.sideBySide} style={{ display: 'flex' }}>
                                                                    <AsyncCreatableSelect
                                                                        key={keyFobDropdownkey}
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        loadOptions={
                                                                            this.promiseOptions
                                                                        }
                                                                        createOptionPosition="first"
                                                                        backspaceRemovesValue={
                                                                            false
                                                                        }
                                                                        onChange={
                                                                            this.onSelectChange
                                                                        }
                                                                        placeholder="Key Fob Serial"
                                                                        styles={this.ddlStyles}
                                                                        value={keyFobValue}
                                                                    />
                                                                    <div style={{ flex: 'auto' }}>
                                                                        <Button
                                                                            color="primary"
                                                                            variant="contained"
                                                                            style={{
                                                                                width: 72,
                                                                                height: 36,
                                                                            }}
                                                                            className={`${classes.primaryButton}`}
                                                                            onClick={e => this.clearSelection(e, 'assignedFob')}
                                                                        > Clear
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                {!isValidKeyFob &&
                                                                <span className={classes.error}>
                                                                    Please enter a valid
                                                                    key fob serial
                                                                </span>}
                                                            </div>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} />
                                                    <Grid item xs={12} />
                                                </React.Fragment >
                                                }
                                                <Grid item xs={12}>
                                                    <FormControl className={classes.ddlFC}>
                                                        <div className={classes.sideBySide}>
                                                            {needInputValidation ?
                                                                <SelectValidator
                                                                    containerProps={{
                                                                        className: classes
                                                                            .validatorContainer,
                                                                    }}
                                                                    fullWidth
                                                                    value={userInfo
                                                                        .licenseState}
                                                                    onChange={this.handleChange}
                                                                    className={classes.dropdown}
                                                                    name="licenseState"
                                                                    id="licenseStateId"
                                                                    label="License State*"
                                                                    validators={['required', 'isEmpty']}
                                                                    errorMessages={['This field is required', 'License state should not be empty']}
                                                                >
                                                                    <MenuItem value="" disabled>[Select State]</MenuItem>
                                                                    {
                                                                        licenseStates.map(o => (
                                                                            <MenuItem
                                                                                key={o.id}
                                                                                value={o
                                                                                    .shortCode}
                                                                            >{o.shortCode}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </SelectValidator>:
                                                                <React.Fragment>
                                                                    <InputLabel>
                                                                        License State
                                                                    </InputLabel>
                                                                    <Select
                                                                        value={
                                                                            userInfo.licenseState
                                                                        }
                                                                        onChange={this.handleChange}
                                                                        className={classes.dropdown}
                                                                        name="licenseState"
                                                                        id="licenseStateId"
                                                                        label="License State"
                                                                    >
                                                                        <MenuItem value="" disabled>[Select State]</MenuItem>
                                                                        {
                                                                            licenseStates.map(o => (
                                                                                <MenuItem
                                                                                    key={o.id}
                                                                                    value={
                                                                                        o.shortCode
                                                                                    }
                                                                                >{o.shortCode}
                                                                                </MenuItem>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </React.Fragment>
                                                            }
                                                            <Button
                                                                color="primary"
                                                                variant="contained"
                                                                className={`${classes.primaryButton} ${classes.clearButton}`}
                                                                onClick={e => this.clearSelection(e, 'licenseState')}
                                                            > Clear
                                                            </Button>
                                                        </div>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </FormControl>
                            <Divider className={classes.divider} />
                            <Toolbar>
                                <Grid container>
                                    <div
                                        className={classes.requiredField}
                                    >
                                        * Required Field
                                    </div>
                                    { hasMobileAccessFeature &&
                                        <div
                                            className={classes.requiredField}
                                            style={{ marginLeft: '20px' }}
                                        >
                                            † Valid email is required for mobile access
                                        </div>
                                    }
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Button className={classes.button} onClick={() => history.push('/drivers')}>
                                            Cancel
                                        </Button>
                                        {(actionType === 'edit' || actionType === 'delete') &&
                                        getPermissionValue('Admin') === 'Modify' &&
                                        <Button
                                            className={classes.button}
                                            onClick={() => this.handleDelete()}
                                        >
                                            Delete
                                        </Button>}
                                        { getPermissionValue('Admin') === 'Modify' &&
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className={classes.primaryButton}
                                            type="submit"
                                            disabled={isFormSubmitted}
                                        >Save
                                        </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </ValidatorForm>
                    </Paper>
                </div>
                <Dialog
                    open={openDialog}
                    type={this.getDialogType()}
                    customTitle=""
                    button={this.button}
                    content={this.getDialogContent()}
                    maxWidth="sm"
                />
            </React.Fragment >
        );
    }
}
const mapStateToProps = state => state.addDriver;
export default withStyles(
    styles,
    { withTheme: true },
)(reduxConnect(AddDriver, actions, mapStateToProps));
