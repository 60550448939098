/* @flow */

import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import { Button, Divider, Grid, FormGroup, FormControlLabel, Checkbox, Tooltip, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.driverList';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../components/SharedComponents/ListComponent/ListComponent';
import ImportComponent from '../../components/SharedComponents/ImportComponent/ImportComponent';
import {
    normalizePhoneNumber,
    getParamValue,
    hasFeature,
    getPermissionValue,
    getHeaderKeysForPost,
    getUserSettingTimeZone,
    getDistanceStringLong,
    getTimeFormatAsperUserSettting,
    downloadFile,
} from '../../helper-classes/utility-functions';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    showHideColumns,
    rearrangeColumns,
    isUpdatePreference,
} from '../../helper-classes/listUtil';
import AddDriver from './../AddDriver/addDriver';
import AppLoader from '../../components/AppLoader';
import { RECORDS_PER_PAGE } from '../../constants/Config';
import analytics from '../../analytics/index';
import { DRIVER_LIST_PREF_KEY } from './constants.driverList';
import { getRequestPayload, getURL } from './epics.driverList';

export type Props = {
    classes : Object,
    isUpdating: boolean,
    fetchDriversList: Function,
    resetTable: Function,
    location: Object,
    history: {
        push: Function,
    },
    match: {
        path: any,
    },
    location: Object,
};

export type State = {
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{}>,
        totalRecords: number,
        ischeckBox: boolean,
        isGroup: boolean,
        isEditable: boolean,
    },
    rowsPerPage: number,
    pageNumber: number,
    order: string,
    orderBy: string,
    openImport: boolean,
    openExport: boolean,
    driverFilters: Object,
    selectedOption: Object,
    isAddEdit: boolean,
    isKeyFobSerialColVisible: boolean,
    showListing: boolean,
    loading: boolean,
};

const themeStyles = () => ({
    checked: {
        color: '#007aff',
    },
    label: {
        color: '#696969',
    },
    hide: {
        display: 'none',
    },
});

class DriverList extends Component<Props, State> {
    driverFiltersToSend: any;
    search: string;
    driverFilterList : Object;
    isInitialRender: boolean;
    localStorage: Storage;
    id: string;
    addUrl: string;
    listUrl: string;
    editUrl: string;
    userPreferences: any;
    constructor(props) {
        super(props);
        let isAddEdit = false;
        this.addUrl = '/drivers/add';
        this.listUrl = '/drivers';
        this.editUrl = '/drivers/edit/:id';
        if (getParamValue(this.props, 'id') || this.props.match.path === this.addUrl) {
            isAddEdit = true;
        }
        this.state = {
            tableData: {
                listHeaders: this.getListHeader(),
                listData: [],
                totalRecords: 0,
                ischeckBox: false,
                isGroup: false,
                isEditable: true,
            },
            rowsPerPage: RECORDS_PER_PAGE,
            pageNumber: 0,
            order: 'asc',
            orderBy: 'firstName',
            openImport: false,
            openExport: false,
            driverFilters: {
                landmark: {},
                assetLinked: {},
            },
            selectedOption: {
                lable: 'CSV',
                value: 'csv',
                key: 'CSV',
            },
            isAddEdit,
            isKeyFobSerialColVisible: hasFeature('fleetDriverId'),
            showListing: false,
            loading: false,
        };
        this.search = '';
        this.driverFiltersToSend = [];
        this.driverFilterList = {
            landmark: {},
            assetLinked: {},
        };
        this.isInitialRender = false;
        this.userPreferences = null;
    }

    UNSAFE_componentWillMount() {
        if (!this.state.isAddEdit) {
            getUserPreference(this, DRIVER_LIST_PREF_KEY);
        }
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        this.handleKeyFobSerialVisibility();
        if (nextprops.driver.length > 0) {
            const { driver, totalDriver } = nextprops;
            const { tableData } = this.state;
            const formattedDriverList = driver.map((item => ({
                ...item,
                phoneNumber: item.phoneNumber ?
                    normalizePhoneNumber(item.phoneNumber) : item.phoneNumber,
            })));
            tableData.listData = formattedDriverList;
            tableData.totalRecords = totalDriver;
            this.setState({ tableData });
        } else if (!this.state.isAddEdit) {
            const { tableData } = this.state;
            tableData.listData = [];
            tableData.totalRecords = 0;
            this.setState({ tableData });
        }
    }

    componentDidUpdate() {
        let { isAddEdit } = this.state;
        if (isAddEdit && this.props.match.path === this.listUrl) {
            isAddEdit = false;
            getUserPreference(this, DRIVER_LIST_PREF_KEY);
            this.updateEditState(isAddEdit);
        } else if (!isAddEdit &&
            (this.props.match.path === this.editUrl ||
            this.props.match.path === this.addUrl)) {
            isAddEdit = true;
            this.updateEditState(isAddEdit);
        }
    }

    getData = () => {
        this.props.fetchDriversList(
            this.state.pageNumber, this.state.rowsPerPage,
            this.state.order, this.state.orderBy, this.getFilterData(),
        );
        this.setState({ showListing: true });
    }

    updateEditState = (isAddEdit) => {
        this.setState({ isAddEdit });
    }


    handleKeyFobSerialVisibility = () => {
        const visible = hasFeature('fleetDriverId');
        if (this.state.isKeyFobSerialColVisible !== visible) {
            this.setState({ isKeyFobSerialColVisible: visible });
        }
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            { firstName: 'Driver Name', ...getColumnPreferences('firstName', preferences) },
            { phoneNumber: 'Phone', ...getColumnPreferences('phoneNumber', preferences) },
            {
                fobSerialNumber: 'Key Fob Serial',
                ...getColumnPreferences(
                    'fobSerialNumber',
                    preferences,
                    { show: hasFeature('fleetDriverId') },
                ),
                showInDropDown: false,
            },
            { email: 'Email', ...getColumnPreferences('email', preferences) },
            {
                assetName: 'Asset Assigned',
                ...getColumnPreferences('assetName', preferences),
                customMarkup: (d) => {
                    if (d.assetName && d.assetId) {
                        return (
                            getPermissionValue('Assets') !== 'None' ?
                                <Tooltip title={d.assetName || ''} disableFocusListener>
                                    <Link
                                        component={RouterLink}
                                        color="inherit"
                                        to={`/home?assetId=${d.assetId}&zoom=true`}
                                    >
                                        {d.assetName}
                                    </Link>
                                    {/* <Link
                                        to={`/home?assetId=${d.assetId}&zoom=true`}
                                        style={{ color: 'inherit' }}
                                    >
                                        {d.assetName}
                                    </Link> */}
                                </Tooltip>
                                : d.assetName
                        );
                    }
                    return '';
                },
            },
            { licenceNumber: 'License Number', ...getColumnPreferences('licenceNumber', preferences) },
            { licenceState: 'License State', ...getColumnPreferences('licenceState', preferences) },
            // { assetLandmarkName: 'Landmark', show: true },
            { comments: 'Comments', ...getColumnPreferences('comments', preferences) },
        ];
        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    updateState = (order, orderBy, rowsPerPage, pageNumber) => {
        const updateUserPref = isUpdatePreference(this, { rowsPerPage, order, orderBy });
        this.setState({
            order,
            orderBy,
            rowsPerPage,
            pageNumber,
        }, () => {
            if (updateUserPref) {
                saveUserPreference(this, DRIVER_LIST_PREF_KEY);
            }
        });
    }

    loadNextPage = (
        rows,
        value,
        order,
        orderBy,
    ) => {
        this.updateState(order, orderBy, value, rows);
        this.props.fetchDriversList(
            rows,
            value,
            order,
            orderBy,
            this.getFilterData(),
        );
    }

    sortTableColumn = (order, orderBy, page) => {
        this.props.fetchDriversList(
            page.pageNumber,
            page.rowsPerPage,
            order, orderBy,
            this.getFilterData(),
        );
        this.updateState(order, orderBy, page.rowsPerPage, page.pageNumber);
        const params = {
            feature: 'FLEET_Drivers',
            sortColumn: orderBy,
        };
        analytics.track('DRIVERS_SORT', params);
    }

    ListSearch = (order, orderBy, filter) => {
        this.search = filter.search || '';
        this.props.fetchDriversList(
            filter.pageNumber,
            filter.rowsPerPage,
            order, orderBy,
            this.getFilterData(),
        );
        this.updateState(order, orderBy, filter.rowsPerPage, filter.pageNumber);
    }

    applyExistingFilters = () => {
        this.driverFiltersToSend = this.getFilterParams(this.driverFilterList);
        this.props.fetchDriversList(
            0,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.getFilterData(),
        );
    }

    applyDriverFilters = (event) => {
        const targetElement = event && event.target;
        // Commented for Future Use
        /* if (targetElement.id.match(/landmark-yes|landmark-no/gi)) {
            this.driverFilterList.landmark[targetElement.value] = targetElement.checked;
        } else */
        if (targetElement.id.match(/asset-yes|asset-no/gi)) {
            this.driverFilterList.assetLinked[targetElement.value] = targetElement.checked;
        }
        this.driverFiltersToSend = this.getFilterParams(this.driverFilterList);
        this.setState({
            driverFilters: this.driverFilterList,
            pageNumber: 0,
        }, () => this.props.fetchDriversList(
            0,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.getFilterData(),
        ));
    }

    getFilterData = () => ({
        driverFiltersToSend: this.driverFiltersToSend,
        search: this.search || '',
    })

    clearFilters = () => {
        if (this.state.driverFilters.landmark && this.state.driverFilters.assetLinked) {
            const landmarkDriverFilterList = Object.keys(this.state.driverFilters.landmark)
                .map(item => (this.state.driverFilters.landmark[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);
            const assetLinkedDriverFilterList = Object.keys(this.state.driverFilters.assetLinked)
                .map(item => (this.state.driverFilters.assetLinked[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);
            this.driverFilterList.landmark.yes = false;
            this.driverFilterList.landmark.no = false;
            this.driverFilterList.assetLinked.yes = false;
            this.driverFilterList.assetLinked.no = false;

            this.setState({ driverFilters: this.driverFilterList, pageNumber: 0 });
            this.driverFiltersToSend = this.getFilterParams(this.driverFilterList);
            if (landmarkDriverFilterList.length > 0 || assetLinkedDriverFilterList.length > 0) {
                this.props.fetchDriversList(
                    this.state.pageNumber,
                    this.state.rowsPerPage,
                    this.state.order,
                    this.state.orderBy,
                    this.getFilterData(),
                );
            }
        }
    }

    refreshList = (page) => {
        this.props.fetchDriversList(
            page.page,
            page.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            { search: this.search },
            this.getFilterData(),
        );
    }

    listTypeContextAction = (action, page = { page: 0, rowsPerPage: RECORDS_PER_PAGE }) => {
        if (action === 'refresh') {
            this.refreshList(page);
            this.applyExistingFilters();
        } else if (action === 'filter') {
            return this.renderDriverFilters();
        } else if (action === 'add') {
            this.props.history.push(`${this.props.location.pathname}/add`);
        } else if (action === 'import') {
            this.openImport(true);
            analytics.track('IMPORT', { feature: 'FLEET_Drivers' });
        } else if (action === 'export') {
            this.openExport(true);
            analytics.track('EXPORT', { feature: 'FLEET_Drivers' });
        }
        return true;
    }

    openExport = openExport => this.setState({ openExport });
    openImport = openImport => this.setState({ openImport });
    closeImport = () => {
        this.setState({ openImport: false });
    }

    getFilterParams = (driverFilters) => {
        const landmarkDriverFilterList = Object.keys(driverFilters.landmark)
            .map(item => (driverFilters.landmark[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);
        const assetLinkedDriverFilterList = Object.keys(driverFilters.assetLinked)
            .map(item => (driverFilters.assetLinked[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);

        this.driverFiltersToSend = [];

        if (landmarkDriverFilterList.length > 0) {
            this.driverFiltersToSend.push(['assetLandmarkName', `exists:${landmarkDriverFilterList.join(',')}`]);
        }
        if (assetLinkedDriverFilterList.length > 0) {
            this.driverFiltersToSend.push(['assetName', `exists:${assetLinkedDriverFilterList.join(',')}`]);
        }
        return this.driverFiltersToSend;
    }

    renderDriverFilters = () => {
        const { classes } = this.props;
        const styles = {
            button: { flex: 'auto', opacity: '.87', color: '#007aff' },
            subheading: {
                fontSize: 15,
                opacity: '.87',
                padding: '22px 20px',
            },
            title: {
                flex: 'auto',
                fontSize: '20px',
                opacity: '.87',
                padding: 20,
            },
            filterCheckboxChecked: {
                color: '#007aff',
                marginLeft: '30px',
            },
            filterCheckboxUnchecked: {
                color: '#696969',
                marginLeft: '30px',
            },
        };

        return (
            <div style={{ padding: 20, flexDirection: 'column' }}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" id="filterTitle" style={styles.title}>Filters</Typography>
                    <Button
                        color="primary"
                        style={styles.button}
                        onClick={this.clearFilters}
                    >
                        CLEAR
                    </Button>
                </div>
                {/* Commented for Future Use
                <Divider />
                <Grid item xs={7} key={Math.random()}>
                    <FormGroup row key={Math.random()}>
                        <Typography variant="body1" id="landmark" style={styles.subheading}>
                            Driver
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key={Math.random()}
                                    id="landmark-yes"
                                    value="yes"
                                    style={this.driverFilterList.landmark.yes ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key={Math.random()}
                            label="In Landmark"
                            checked={this.driverFilterList.landmark.yes}
                            onChange={this.applyDriverFilters}
                            classes={
                                { label: `${this.driverFilterList.landmark.yes ?
                                  classes.checked :
                                  classes.label}` }
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key={Math.random()}
                                    id="landmark-no"
                                    value="no"
                                    style={this.driverFilterList.landmark.no ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key={Math.random()}
                            label="Not In Landmark"
                            checked={this.driverFilterList.landmark.no}
                            onChange={this.applyDriverFilters}
                            classes={
                            { label: `${this.driverFilterList.landmark.no ?
                              classes.checked :
                              classes.label}` }
                            }
                        />
                    </FormGroup>
                </Grid> */}
                <Divider />
                <Grid item xs={7}>
                    <FormGroup>
                        <Typography variant="body1" id="assetLinked" style={styles.subheading}>Asset Association</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value="yes"
                                    id="asset-yes"
                                    style={this.driverFilterList.assetLinked.yes ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            label="Linked"
                            checked={this.driverFilterList.assetLinked.yes}
                            onChange={this.applyDriverFilters}
                            classes={{ label: `${this.driverFilterList.assetLinked.yes ? classes.checked : classes.label}` }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value="no"
                                    id="asset-no"
                                    style={this.driverFilterList.assetLinked.no ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            label="Unlinked"
                            checked={this.driverFilterList.assetLinked.no}
                            onChange={this.applyDriverFilters}
                            classes={{ label: `${this.driverFilterList.assetLinked.no ? classes.checked : classes.label}` }}
                        />
                    </FormGroup>
                </Grid>
                <Divider />
            </div>
        );
    }

    dialogHandleClick = (type, value) => {
        if (type === 'show') {
            this.setState({ selectedOption: value });
        } else if (type === 'download') {
            const {
                tableData,
                selectedOption,
                order,
                orderBy,
            } = this.state;
            const filter = this.getFilterData();
            this.openExport(false);
            this.setState({ loading: true });
            const requestPayLoad = getRequestPayload({
                pageNumber: 0,
                pageSize: tableData.totalRecords,
                order,
                orderBy,
                filter,
            });
            const reportRequestPayload = {
                ...requestPayLoad,
                reportColumns: getHeaderKeysForPost(tableData.listHeaders),
                timeZone: getUserSettingTimeZone(),
                unitMeasurement: getDistanceStringLong(),
                timeFormat: getTimeFormatAsperUserSettting(),
            };
            downloadFile(
                getURL(),
                `Drivers ${moment().format('YYYY-MM-DD HH-mm-ss')}.${selectedOption.value}`,
                { type: selectedOption.value },
                () => this.setState({ loading: false }),
                reportRequestPayload,
            );
        } else {
            this.openExport(false);
        }
    }

    clickRow = (n:any) => {
        this.props.history.push(`/drivers/edit/${n.id}`);
    };

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: DRIVER_LIST_PREF_KEY };
        rearrangeColumns(this, listHeaders, options);
    }

    showHideColumn = (header) => {
        const options = { prefKey: DRIVER_LIST_PREF_KEY };
        showHideColumns(this, header, options);
    }

    updateLoader = (loading) => {
        this.setState({ loading });
    }

    render() {
        const { isAddEdit, openImport, loading } = this.state;
        const { classes } = this.props;
        return (
            <React.Fragment>
                {loading && <AppLoader type="fullScreen" />}
                <MiniDrawer
                    redirectTo={this.props.history.push}
                >
                    {isAddEdit &&
                        <AddDriver />
                    }
                    <div className={(!isAddEdit && !openImport) ? '' : classes.hide}>
                        {this.state.showListing ?
                            <ListComponent
                                redirectTo={this.props.history.push}
                                tableData={this.state.tableData}
                                loadNextPage={this.loadNextPage}
                                rearrangeColumns={this.rearrangeColumns}
                                resetTable={this.props.resetTable}
                                isUpdating={this.props.isUpdating}
                                showHideColumn={this.showHideColumn}
                                sortTableColumn={this.sortTableColumn}
                                listTypeContextAction={this.listTypeContextAction}
                                clickRow={this.clickRow}
                                tableTitle="Drivers"
                                filtersApplied={this.driverFiltersToSend.length > 0}
                                ListSearch={this.ListSearch}
                                tableButtons={['Import', 'Export', 'Refresh', 'Filter List', /* 'Delete', 'Edit', */ 'Add Items']}
                                resizableHeader
                                pathName={this.props.location.pathname}
                                dialogboxInfo={{
                                    title: `Export data as ${this.state.selectedOption.lable} for Driver list`,
                                    dialogHandleClick: this.dialogHandleClick,
                                    showDialog: this.state.openExport,
                                    selectedOption: this.state.selectedOption,
                                }}
                                order={this.state.order}
                                orderBy={this.state.orderBy}
                                openImport={this.openImport}
                                pageNumber={this.state.pageNumber}
                                rowsPerPage={this.state.rowsPerPage}
                            />
                            :
                            <div style={{ height: '100%' }}>
                                <AppLoader type="fullScreen" />
                            </div>
                        }
                    </div>
                    {openImport &&
                        <ImportComponent closeImport={this.closeImport} updateLoader={this.updateLoader} importType="Drivers" />
                    }
                </MiniDrawer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    driver: state.driverList.drivers,
    totalDriver: state.driverList.totalDrivers,
    isUpdating: state.driverList.isUpdating,
    orderBy: state.orderBy,
});

export default withStyles(themeStyles)(reduxConnect(DriverList, actions, mapStateToProps));
