/* @flow */

import { FETCH_TRIPS_DETAILS, FETCH_TRIPS_DETAILS_SUCCESS, FETCH_TRIPS_DETAILS_ERROR, FETCH_TRIPS_DIRECTION_ERROR, FETCH_TRIPS_DIRECTION, FETCH_TRIPS_DIRECTION_SUCCESS } from './constants.trips';
import type { TripTypeAction } from './constants.trips';

export const fetchTripsDetails = (id: string, params: string): TripTypeAction => ({
    type: FETCH_TRIPS_DETAILS,
    payload: { id, params },
});

export const fetchTripsDetailsSuccess = (response: {}): TripTypeAction => ({
    type: FETCH_TRIPS_DETAILS_SUCCESS,
    payload: { response },
});
// etchTripsDetailsSuccess
export const fetchTripsDetailsError = (error: string): TripTypeAction => ({
    type: FETCH_TRIPS_DETAILS_ERROR,
    payload: { error },
});

export const fetchTripsDirection = (id: string, limit: number, offset: number): TripTypeAction => ({
    type: FETCH_TRIPS_DIRECTION,
    payload: { id, limit, offset },
});

export const fetchTripsDirectionSuccess = (response: {}): TripTypeAction => ({
    type: FETCH_TRIPS_DIRECTION_SUCCESS,
    payload: { response },

});

export const fetchTripsDirectionError = (error: string): TripTypeAction => ({
    type: FETCH_TRIPS_DIRECTION_ERROR,
    payload: { error },
});

