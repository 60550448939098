/* @flow */

export const FETCH_KMLS: string = 'FETCH_KMLS';
export const FETCH_KMLS_SUCCESS: string = 'FETCH_KMLS_SUCCESS';
export const FETCH_KMLS_ERROR: string = 'FETCH_KMLS_ERROR';
export const DELETE_KMLS: string = 'DELETE_KMLS';
export const DELETE_KMLS_SUCCESS: string = 'DELETE_KMLS_SUCCESS';
export const DELETE_KMLS_ERROR: string = 'DELETE_KMLS_ERROR';
export const CLOSE_KMLS_DIALOG: string = 'CLOSE_KMLS_DIALOG';
export const ADD_KML: string = 'ADD_KML';
export const ADD_KML_SUCCESS: string = 'ADD_KML_SUCCESS';
export const ADD_KML_ERROR: string = 'ADD_KML_ERROR';
export const ERROR_KML_FETCHING: string = 'Error fetching KMLs. Try again later';
export const NO_DATA: string = 'KML Data not found.';
export const DELETE_KMLS_ERROR_MSG: string = 'Delete KML Error :';
export const ADD_KMLS_ERROR_MSG: string = 'Add KML Error :';

export const SOMETHING_WENT_WRONG: string = 'Something went wrong';
export const ADD_KMLS_SUCCESSFULLY_MSG: string = 'KML added successfully';
export const DELETE_KMLS_SUCCESSFULLY_MSG: string = 'KML deleted successfully';
export const DELETE_KMLS_MSG: string = 'Are you sure you want to delete the KML?';

export const kmlListHeight: number = 56;
export type KmlTypeAction = {
    type: string;
    payload?: Object;
}
