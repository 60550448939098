/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Card, CardContent, Typography, Tooltip } from '@mui/material';
import customStyles from './LandmarkDetails.module.scss';
import AssetInLandmark from './AssetInLandmark';
import DetailActions from './DetailActions';

export type Props = {
    redirectTo: Function,
    landmarksData: {
        id: string,
        type: string,
        landmarkName: string,
        asset: string,
        address: string,
        phone: string,
        group: string,
        landmarkDetails: {
            name: string,
            address: string,
            latitude: string,
            longitude: string
        },
        customeAttributes: {
            inState: string,
            repairs: string,
            towable: string,
            contact: string,
        }
    },
    assetInLandmark: Object,
    assetLoader: boolean,
    pageChanged: Function,
    refreshAssetListData: Function,
    showAssetRefeshChip: boolean,
    showBackButton: boolean,
};

export type State = {
};

class LandmarkDetails extends Component<Props, State> {
    toggleFilterDrawer: Function;
    props: Props;
    state = {};

    render() {
        const {
            landmarksData,
            assetLoader,
            assetInLandmark,
            pageChanged,
            refreshAssetListData,
            showAssetRefeshChip,
        } = this.props;
        const assetCount = assetInLandmark && assetInLandmark.total ? assetInLandmark.total : 0;
        return (
            <div className="app">
                {landmarksData ?
                    <div id="asset-details-container" className={customStyles.container}>
                        <Card raised={false} id="header-card" className={customStyles.card}>
                            <CardContent className={customStyles['card-content']}>
                                <div style={{ display: 'flex' }}>
                                    {this.props.showBackButton &&
                                    <div>
                                        <button
                                            className={customStyles.back_button}
                                            onClick={() => window.history.back()}
                                        >
                                            <i className={customStyles.back_icon} />
                                        </button>
                                    </div>
                                    }
                                    <div style={{ flex: 'auto' }}>
                                        <Tooltip title={this.props.landmarksData.landmarkName || ''} placement="bottom-start">
                                            <Typography variant="h5" className={customStyles['landmark-header']} component="h2" id="Landmark-Name">
                                                {this.props.landmarksData.landmarkName}
                                            </Typography>
                                        </Tooltip>
                                        <Typography id="sub-header" className={customStyles['sub-header']} paragraph component="p">
                                            {assetCount} Assets
                                        </Typography>
                                    </div>
                                </div>
                                <div className={customStyles['card-content-bottom']}>
                                    <Tooltip title={this.props.landmarksData.address || ''} placement="bottom-end">
                                        <Typography id="address" className={customStyles.address} component="p">
                                            {this.props.landmarksData.address}
                                        </Typography>
                                    </Tooltip>
                                    <Typography id="phone" className={customStyles.phone} paragraph component="p">
                                        {this.props.landmarksData.phone}
                                    </Typography>
                                    <Tooltip title={this.props.landmarksData.group || ''} placement="bottom-end">
                                        <Typography id="group" className={customStyles['group-name']} paragraph align="right" component="p">
                                            {this.props.landmarksData.group}
                                        </Typography>
                                    </Tooltip>
                                </div>
                            </CardContent>
                        </Card>
                        <DetailActions
                            redirectTo={this.props.redirectTo}
                            landmarkId={this.props.landmarksData.id}
                        />
                        <AssetInLandmark
                            assetLoader={assetLoader}
                            assetInLandmark={assetInLandmark}
                            pageChanged={pageChanged}
                            showAssetRefeshChip={showAssetRefeshChip}
                            refreshAssetListData={refreshAssetListData}
                            redirectTo={this.props.redirectTo}
                            landmarkId={landmarksData.id}
                        />
                    </div>
                    :
                    <div>Loading data...</div>
                }
            </div>

        );
    }
}

export default withStyles({}, { withTheme: true })(LandmarkDetails);
