/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Tooltip } from '@mui/material';
import { reduxConnect } from '../../../hoc';
import * as actions from './actions.maintenanceStatus';
import MiniDrawer from '../../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../../components/SharedComponents/ListComponent/ListComponent';
import MaintenanceFilterForm from '../../../components/Maintenance/MaintenanceFilterForm';
import MaintenanceFilterHelper from '../../../components/Maintenance/MaintenanceFilterHelper';
import AddServiceRecord from '../ServiceRecords/AddServiceRecord';
import {
    convertDateInGivenFormat,
    getEngineHoursToDisplay,
    getDistanceToDisplay,
    getDistanceString,
} from '../../../helper-classes/utility-functions';
import { getSplittedHeader } from '../../../helper-classes/utility-react-functions';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    showHideColumns,
    rearrangeColumns,
    isUpdatePreference,
} from '../../../helper-classes/listUtil';
import customStyles from './MaintenanceStatus.module.scss';
import { RECORDS_PER_PAGE } from '../../../constants/Config';
import AppLoader from '../../../components/AppLoader';
import { MANINTENANACE_STATUS_LIST_PREF_KEY } from './constants.maintenanceStatus';
import analytics from '../../../analytics';

export type Props = {
    classes: any,
    isUpdating: boolean,
    fetchMaintenanceStatus: Function,
    resetTable: Function,
    history: {
        push: Function,
    },
};

export type State = {
    tableData: {
        listHeaders: any,
        listData: Array<{}>,
        totalRecords: number,
    },
    rowsPerPage: number,
    isAddEditShown: boolean,
    selectedRecord: {
        assetId: string,
        assetName: string,
        planId: string,
        engineHours: number,
        odometer: number,
        resetStatus: boolean,
    },
    selectedFilters: Array<{
        id: string,
        property: string,
        data: {
            id: string
        },
    }>,
    showListing: boolean,
    loading: boolean,
};

const styles = () => ({
    hide: {
        display: 'none !important',
    },
    show: {
        display: 'block !important',
    },
});

class MaintenanceStatus extends Component<Props, State> {
    userPreferences: any;
    order: string;
    orderBy: string;
    constructor(props: Props) {
        super(props);
        this.state = {
            tableData: {
                ischeckBox: false,
                isAddButton: true,
                addButtonTooltip: 'Add Service Record',
                isGroup: false,
                listHeaders: this.getListHeader(),
                listData: [],
                totalRecords: 0,
            },
            isAddEditShown: false,
            selectedRecord: {
                assetId: '',
                assetName: '',
                planId: '',
                engineHours: 0,
                odometer: 0,
                resetStatus: true,
            },
            rowsPerPage: RECORDS_PER_PAGE,
            selectedFilters: [],
            showListing: false,
            loading: false,
        };
        this.order = 'desc';
        this.orderBy = 'overdue';
    }

    UNSAFE_componentWillMount() {
        getUserPreference(this, MANINTENANACE_STATUS_LIST_PREF_KEY);
    }

    componentDidMount() {
        document.addEventListener('updateOverdueStatuses', () => {
            setTimeout(() => {
                this.refreshPage();
            }, 1500);
        });
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (nextprops.statuses && nextprops.statuses.length) {
            const { statuses, totalRecords } = nextprops;
            const { tableData } = this.state;
            tableData.totalRecords = totalRecords;
            tableData.listData = this.formatStatusList(statuses);
            this.setState({
                tableData,
            });
        } else {
            const { tableData } = this.state;
            tableData.listData = [];
            tableData.totalRecords = 0;
            this.setState({
                tableData,
            });
        }
    }

    getData = () => {
        this.props.fetchMaintenanceStatus(
            0,
            this.state.rowsPerPage,
            this.order,
            this.orderBy,
            this.state.selectedFilters,
        );
        this.setState({ showListing: true });
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const fullDistanceUnit = getDistanceString() === 'km' ? 'kms' : 'miles';
        const columns = [
            {
                ...getSplittedHeader('Asset', '', 'assetName'),
                ...getColumnPreferences('assetName', preferences),
                disableSort: true,
                customMarkup: item => (item.isOverdue ?
                    <Tooltip title={item.assetRef.name || ''}>
                        <span className={customStyles.overdue}>{item.assetRef.name}</span>
                    </Tooltip> :
                    item.assetRef.name),
            },
            {
                ...getSplittedHeader(`Current Odometer (${getDistanceString()})`, '', 'currentOdometer'),
                ...getColumnPreferences('currentOdometer', preferences),
                disableSort: true,
            },
            {
                ...getSplittedHeader('Current Engine Hours (hrs)', '', 'currentEngineHours'),
                ...getColumnPreferences('currentEngineHours', preferences),
                disableSort: true,
            },
            { ...getSplittedHeader('Service Plan Name', '', 'servicePlanName'), ...getColumnPreferences('servicePlanName', preferences), disableSort: true },
            {
                ...getSplittedHeader(`Distance Remaining (${getDistanceString()})`, '', 'remainingOdometer'),
                ...getColumnPreferences('remainingOdometer', preferences),
                customMarkup: (item) => {
                    if (item.isDistanceOverdue) {
                        const remainingOdometerText = `${getDistanceToDisplay(item.remainingOdometer)} ${fullDistanceUnit} overdue`;
                        return (
                            <Tooltip title={remainingOdometerText}>
                                <span className={customStyles.overdue}>
                                    {remainingOdometerText}
                                </span>
                            </Tooltip>
                        );
                    }
                    return (item.remainingOdometer !== null ? getDistanceToDisplay(item.remainingOdometer) : '');
                },
            },
            { ...getSplittedHeader(`Next Service at (Distance in ${getDistanceString()})`, '', 'endOdometer'), ...getColumnPreferences('endOdometer', preferences), disableSort: true },
            {
                ...getSplittedHeader('Time Remaining (days)', '', 'remainingTimePeriod'),
                ...getColumnPreferences('remainingTimePeriod', preferences),
                customMarkup: (item) => {
                    if (item.isTimeOverdue) {
                        const remainingTimePeriodText = `${Math.abs(item.remainingTimePeriod)} days overdue`;
                        return (
                            <Tooltip title={remainingTimePeriodText}>
                                <span className={customStyles.overdue}>
                                    {remainingTimePeriodText}
                                </span>
                            </Tooltip>
                        );
                    }
                    return this.getRemainingTimeToDisplay(item.remainingTimePeriod);
                },
            },
            { ...getSplittedHeader('Next Service on (Time Period)', '', 'endDate'), ...getColumnPreferences('endDate', preferences), disableSort: true },
            {
                ...getSplittedHeader('Engine Hours Remaining (hrs)', '', 'remainingEngineHours'),
                ...getColumnPreferences('remainingEngineHours', preferences),
                customMarkup: (item) => {
                    if (item.isEngineHoursOverdue) {
                        const remainingEngineHoursText = `${getEngineHoursToDisplay(item.remainingEngineHours)} hours overdue`;
                        return (
                            <Tooltip title={remainingEngineHoursText}>
                                <span className={customStyles.overdue}>
                                    {remainingEngineHoursText}
                                </span>
                            </Tooltip>
                        );
                    }
                    return (item.remainingEngineHours !== null ? getEngineHoursToDisplay(item.remainingEngineHours) : '');
                },
            },
            { ...getSplittedHeader('Next Service at (Engine Hours)', '', 'endEngineHours'), ...getColumnPreferences('endEngineHours', preferences), disableSort: true },
        ];
        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    formatStatusList = (statuses: any) => {
        const formattedStatusList = statuses.map((item => ({
            ...item,
            nameOfAsset: item.assetRef.name, // Work around for asset name
            assetName: item.assetRef.name,
            remainingTimePeriod: item.remainingTimePeriod,
            remainingOdometer: item.remainingOdometer,
            remainingEngineHours: item.remainingEngineHours,
            currentOdometer: getDistanceToDisplay(item.currentOdometer),
            endOdometer: getDistanceToDisplay(item.endOdometer),
            currentEngineHours: getEngineHoursToDisplay(item.currentEngineHours),
            endDate: item.endDate ? convertDateInGivenFormat(item.endDate, 'MMM D, YYYY') : '',
            endEngineHours: getEngineHoursToDisplay(item.endEngineHours),
        })));
        return formattedStatusList;
    }

    refreshPage = () => {
        this.forceUpdate();
    };

    isOverdue = (x: number) => {
        if (x < 0) {
            return <font color="red">{x}</font>;
        }
        return x;
    };

    isTimeOverdue = (time: string) => {
        if (time && time.charAt(0) === '-') {
            return <font color="red">{time}</font>;
        }
        return time;
    };

    getRemainingTimeToDisplay = (remainingTime: number) => {
        if (remainingTime !== null) {
            return remainingTime < 1 && remainingTime > 0 ? '< 1' : remainingTime;
        }
        return '';
    };

    clickRow = (n: any) => {
        this.setState({
            selectedRecord: {
                assetId: n.assetId,
                assetName: n.nameOfAsset,
                planId: n.servicePlanId,
                engineHours: n.currentEngineHours,
                odometer: n.currentOdometer,
                resetStatus: true,
            },
            isAddEditShown: true,
        });
    };

    closeAddEdit = () => {
        this.setState({ isAddEditShown: false });
    };
    sortTableColumn = (order, orderBy, page) => {
        this.setState({ rowsPerPage: page.rowsPerPage }, () => {
            saveUserPreference(this, MANINTENANACE_STATUS_LIST_PREF_KEY);
        });
        this.props.fetchMaintenanceStatus(
            page.pageNumber,
            page.rowsPerPage,
            order, orderBy,
            this.state.selectedFilters,
        );
        const params = {
            feature: 'Maintenance',
            sortColumn: orderBy,
        };
        analytics.track('STATUS_SORT', params);
    };

    onFiltersSelected = (selectedFilters) => {
        this.setState({ selectedFilters });
        this.props.fetchMaintenanceStatus(
            0,
            this.state.rowsPerPage,
            this.order,
            this.orderBy,
            selectedFilters,
        );
        let assets = true;
        let groups = true;
        let plans = true;
        selectedFilters.forEach((x) => {
            if (x.property === 'assetId') {
                assets = false;
            } if (x.property === 'assetGroupId') {
                groups = false;
            } if (x.property === 'servicePlanId') {
                plans = false;
            }
        });
        const params = {
            feature: 'Maintenance',
            allAssets: assets,
            allAssetGroups: groups,
            allPlans: plans,
        };
        analytics.track('STATUS_FILTER', params);
    }

    filterComponent = () => {
        const filterOptions = [];
        filterOptions.push(MaintenanceFilterHelper.assetGroupFilterWithGlobalId);
        filterOptions.push(MaintenanceFilterHelper.assetFilterWithGlobalId);
        filterOptions.push(MaintenanceFilterHelper.servicePlanFilter);
        return (
            <MaintenanceFilterForm
                filters={filterOptions}
                selectedFilters={this.state.selectedFilters}
                onFilterSelected={this.onFiltersSelected}
            />
        );
    };

    showLoader = (loading: boolean) => this.setState({ loading });

    getAddEditModal = () => {
        const addEditModal = this.state.isAddEditShown ?
            (<AddServiceRecord
                windowTitle="Create New Service Record"
                isShown={this.state.isAddEditShown}
                closeAddEdit={this.closeAddEdit}
                assetId={this.state.selectedRecord.assetId}
                servicePlanId={this.state.selectedRecord.planId}
                assetName={this.state.selectedRecord.assetName}
                engineHours={this.state.selectedRecord.engineHours}
                odometer={this.state.selectedRecord.odometer}
                resetStatus={this.state.selectedRecord.resetStatus}
                prepopulated
                showLoader={this.showLoader}
            />)
            :
            null;
        return addEditModal;
    };

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: MANINTENANACE_STATUS_LIST_PREF_KEY };
        rearrangeColumns(this, listHeaders, options);
    }

    showHideColumn = (header) => {
        const options = { prefKey: MANINTENANACE_STATUS_LIST_PREF_KEY };
        showHideColumns(this, header, options);
    }

    render() {
        const { classes } = this.props;
        return (
            <MiniDrawer
                redirectTo={this.props.history.push}
            >
                {this.state.loading && <AppLoader type="fullScreen" />}
                {this.getAddEditModal()}
                <div className={this.state.isAddEditShown ? classes.hide : classes.show}>
                    {this.state.showListing ?
                        <ListComponent
                            redirectTo={this.props.history.push}
                            tableData={this.state.tableData}
                            loadNextPage={(page, rowsPerPage, order, orderBy) => {
                                const updateUserPref = isUpdatePreference(this, { rowsPerPage });
                                this.setState({ rowsPerPage }, () => {
                                    if (updateUserPref) {
                                        saveUserPreference(
                                            this,
                                            MANINTENANACE_STATUS_LIST_PREF_KEY,
                                        );
                                    }
                                });
                                this.props.fetchMaintenanceStatus(
                                    page,
                                    rowsPerPage,
                                    order,
                                    orderBy,
                                    this.state.selectedFilters,
                                );
                            }}
                            rearrangeColumns={this.rearrangeColumns}
                            resetTable={this.props.resetTable}
                            isUpdating={this.props.isUpdating}
                            showHideColumn={this.showHideColumn}
                            sortTableColumn={this.sortTableColumn}
                            tableTitle="Maintenance Status"
                            filtersApplied={this.state.selectedFilters.length > 0}
                            tableButtons={['Filter List']}
                            clickRow={this.clickRow}
                            order={this.order}
                            orderBy={this.orderBy}
                            listTypeContextAction={this.filterComponent}
                            showSearch={false}
                            resizableHeader
                            rowsPerPage={this.state.rowsPerPage}
                        />
                        :
                        <div style={{ height: '100%' }}>
                            <AppLoader type="fullScreen" />
                        </div>
                    }
                </div>
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => ({
    statuses: state.maintenanceStatus.statuses,
    totalRecords: state.maintenanceStatus.totalRecords,
    isUpdating: state.maintenanceStatus.isUpdating,
});

export default withStyles(styles)(reduxConnect(MaintenanceStatus, actions, mapStateToProps));
