/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { reduxConnect } from '../../hoc/index';
import * as actions from './actions.configureDashboard';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../components/SharedComponents/ListComponent/ListComponent';
import Dialog from '../../components/SharedComponents/Dialog/Dialog';
import AppLoader from '../../components/AppLoader';
import { getUserUnit } from './../../helper-classes/utility-functions';
import analytics from '../../analytics/index';

const themeStyles = () => ({
    button: {
        margin: '0 10px',
    },
});

type Props = {
    fetchAddedLooks: Function,
    resetTable: Function,
    history: {
        push: Function,
    },
    deleteAddedLook: Function,
};
export type State = {
    tableData: {
        listHeaders: Array<{}>,
        listData: Array<{}>,
        totalRecords: number,
    },
    selectedLook: string,
    dialogActionType: string,
    openDialog: boolean,
    isUpdating: boolean,
    isDeleting: boolean,
    userMetricPreference: string,
};

class ConfigureDashboard extends Component<Props, State> {
    unitText: string;
    button: Array<Object>;

    constructor(props: Props) {
        super(props);
        let userid = localStorage.getItem('currentAccountUserId');
        if (!userid) {
            userid = '';
        }

        this.state = {
            tableData: {
                listHeaders: [],
                listData: [],
                totalRecords: 0,
            },
            selectedLook: '',
            dialogActionType: '',
            openDialog: false,
            isUpdating: true,
            isDeleting: false,
            userMetricPreference: getUserUnit(),
        };
        this.button = [];
    }

    componentDidMount() {
        this.props.fetchAddedLooks(false);
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        const listHeaders = [
            { lookId: 'LookID', show: false },
            { lookModule: 'Module Name', show: 'true', disableSort: true },
            { lookSubtitle: 'Module', show: 'true', disableSort: true },
            { lookTimePeriod: 'Time Period', show: 'true', disableSort: true },
            { lookScope: 'Scope', show: 'true', disableSort: true },
            { lookVisualizationType: 'Options', show: 'true', disableSort: true },
        ];

        if (nextprops.ConfigureDashboard.addedLooks !== undefined) {
            this.setState({ isUpdating: nextprops.ConfigureDashboard.isUpdating });
            if (nextprops.ConfigureDashboard.addedLooks.length > 0) {
                const listData = this.formattedListData(nextprops.ConfigureDashboard.addedLooks);
                this.setState({
                    tableData: {
                        ischeckBox: false,
                        isEditable: true,
                        addDelete: true,
                        listHeaders,
                        listData,
                        totalRecords: 1000,
                    },
                });
            } else {
                this.setState({
                    tableData: {
                        ischeckBox: false,
                        isEditable: false,
                        addDelete: false,
                        listHeaders,
                        listData: [],
                        totalRecords: 0,
                    },
                });
            }
            if (nextprops.ConfigureDashboard.isLookDeleted === true) {
                this.setState({
                    dialogActionType: 'deleted',
                    openDialog: true,
                    isDeleting: false,
                });
                this.props.fetchAddedLooks(false);
            }
        }
    }

    formattedListData = (listData) => {
        const newList = listData;
        const { userMetricPreference } = this.state;
        newList.forEach((look) => {
            const newLook = look;
            let lookTimePeriod;
            try {
                lookTimePeriod = JSON.parse(newLook.lookTimePeriod);
            } catch (e) {
                lookTimePeriod = {};
            }
            newLook.lookScope = '';
            Object.keys(lookTimePeriod).forEach((key) => {
                if (key.search('date') !== -1) {
                    switch (lookTimePeriod[key]) {
                    case 'yesterday':
                        newLook.lookTimePeriod = 'Yesterday';
                        break;
                    case '7 days ago for 7 days':
                        newLook.lookTimePeriod = 'Last 7 Days';
                        break;
                    case '30 days ago for 30 days':
                        newLook.lookTimePeriod = 'Last 30 Days';
                        break;
                    case 'last month':
                        newLook.lookTimePeriod = 'Last Month';
                        break;
                    case '3 months ago for 3 months':
                        newLook.lookTimePeriod = 'Last 3 Months';
                        break;
                    case 'last quarter':
                        newLook.lookTimePeriod = 'Last Quarter';
                        break;
                    case 'last year':
                        newLook.lookTimePeriod = 'Last Year';
                        break;
                    case '13 months ago for 13 months':
                        newLook.lookTimePeriod = 'Last 13 Months';
                        break;
                    default:
                        newLook.lookTimePeriod = 'All Time';
                    }
                }

                if (newLook.lookVisualizationType === 'HeatMap') {
                    newLook.lookVisualizationType = 'Map';
                }
                if (!newLook.isFormatted) {
                    if (newLook.title.endsWith('true')) {
                        newLook.lookVisualizationType += ' (Detail)';
                    } else {
                        newLook.lookVisualizationType += ' (Aggregate)';
                    }
                    newLook.isFormatted = true;
                }

                if (key.search('violation_type') !== -1) {
                    let speedCriteria = '';
                    const isEnglish = userMetricPreference === 'english';
                    switch (lookTimePeriod[key]) {
                    case 'EST_56_DASH':
                        speedCriteria = `Greater than ${isEnglish ? '55 MPH' : '89 KPH'}`;
                        break;
                    case 'EST_61_DASH':
                        speedCriteria = `Greater than ${isEnglish ? '60 MPH' : '97 KPH'}`;
                        break;
                    case 'EST_66_DASH':
                        speedCriteria = `Greater than ${isEnglish ? '65 MPH' : '105 KPH'}`;
                        break;
                    case 'EST_71_DASH':
                        speedCriteria = `Greater than ${isEnglish ? '70 MPH' : '113 KPH'}`;
                        break;
                    case 'EST_76_DASH':
                        speedCriteria = `Greater than ${isEnglish ? '75 MPH' : '121 KPH'}`;
                        break;
                    case 'EST_81_DASH':
                        speedCriteria = `Greater than ${isEnglish ? '80 MPH' : '129 KPH'}`;
                        break;
                    case 'EPS_10_DASH':
                        speedCriteria = `${isEnglish ? '10 MPH' : '16 KPH'} Over Posted Speed`;
                        break;
                    case 'EPS_20_DASH':
                        speedCriteria = `${isEnglish ? '20 MPH' : '32 KPH'} Over Posted Speed`;
                        break;
                    case 'EPS_30_DASH':
                        speedCriteria = `${isEnglish ? '30 MPH' : '48 KPH'} Over Posted Speed`;
                        break;
                    default:
                    }
                    if (speedCriteria !== null && speedCriteria.trim().length > 0) {
                        newLook.lookVisualizationType = `${newLook.lookVisualizationType}, ${speedCriteria}`;
                    }
                }
                const cLookTimePeriod = lookTimePeriod[key].match(/,/g) || [];
                if (key.indexOf('asset_id') !== -1) {
                    if (newLook.lookScope.length !== 0) {
                        newLook.lookScope = `${newLook.lookScope}, `;
                    }
                    if (cLookTimePeriod.length === 0 && lookTimePeriod[key].length !== 0) {
                        newLook.lookScope = `${newLook.lookScope}1 Asset`;
                    } else {
                        newLook.lookScope = `${(cLookTimePeriod.length + 1)} Assets`;
                    }
                }
                if (key.indexOf('asset_group_id') !== -1) {
                    if (newLook.lookScope.length !== 0) {
                        newLook.lookScope = `${newLook.lookScope}, `;
                    }
                    if (cLookTimePeriod.length === 0 && lookTimePeriod[key].length !== 0) {
                        newLook.lookScope = `${newLook.lookScope}1 Group`;
                    } else {
                        newLook.lookScope = `${newLook.lookScope + (cLookTimePeriod.length + 1).toString()} Groups`;
                    }
                }
                if (key.indexOf('tags') !== -1) {
                    if (newLook.lookScope.length !== 0) {
                        newLook.lookScope = `${newLook.lookScope}, `;
                    }
                    if (cLookTimePeriod.length === 0 && lookTimePeriod[key].length !== 0) {
                        newLook.lookScope = `${newLook.lookScope}1 Tag`;
                    } else {
                        newLook.lookScope = `${newLook.lookScope + (cLookTimePeriod.length + 1).toString()} Tags`;
                    }
                }
            });
            if (newLook.lookScope.trim().length === 0) newLook.lookScope = 'Global';
            newList[look] = newLook;
        });
        return newList;
    };

    clickRow = (n: any, type: string) => {
        if (type === undefined) {
            this.setState({
                selectedLook: n.id,
            });
            this.props.history.push(`/configure-module?id=${n.id}`);
        } else {
            this.setState({
                selectedLook: n.id,
                dialogActionType: 'delete',
                openDialog: true,
            });
        }
    };

    listTypeContextAction = (action) => {
        if (action === 'add') {
            this.props.history.push('/module-types');
        }
        return true;
    };

    handleDeleteConfirm = () => {
        this.setState({
            dialogActionType: '',
            openDialog: false,
            isUpdating: true,
            isDeleting: true,
        });
        analytics.track('DELETE_MODULE', { feature: 'Dashboard' });
        this.props.deleteAddedLook(this.state.selectedLook);
    };

    getDialogContent = () => {
        const { dialogActionType } = this.state;
        let content = '';
        if (dialogActionType === 'delete') {
            content = 'Are you sure you want to delete?';
        } else {
            content = 'Module deleted successfully.';
        }
        return content;
    };

    getDialogType = () => {
        const { dialogActionType } = this.state;
        let type = '';
        if (dialogActionType === 'delete') {
            type = 'confirm';
            this.button = [
                {
                    title: 'CANCEL',
                    color: 'primary',
                    handleClick: () => this.setState({ dialogActionType: '', openDialog: false }),
                },
                {
                    title: 'Yes',
                    color: 'primary',
                    variant: 'contained',
                    handleClick: () => this.handleDeleteConfirm(),
                },
            ];
        }
        if (dialogActionType === 'deleted') {
            type = 'success';
            this.button = [{
                title: 'OK',
                color: 'primary',
                variant: 'contained',
                handleClick: () => this.setState({ dialogActionType: '', openDialog: false }),
            }];
        }
        return type;
    };

    handleBackClick = () => {
        this.props.history.push('./dashboard');
    };

    render() {
        const { openDialog } = this.state;
        return (
            <div>
                <MiniDrawer redirectTo={this.props.history.push}>
                    <ListComponent
                        redirectTo={this.props.history.push}
                        tableData={this.state.tableData}
                        resetTable={this.props.resetTable}
                        isUpdating={this.state.isUpdating}
                        tableTitle="Configure Dashboard"
                        permissionKey="Dashboard"
                        tableButtons={['Add Items']}
                        listTypeContextAction={this.listTypeContextAction}
                        clickRow={this.clickRow}
                        showSearch={false}
                        showToolbarBackButton
                        backClick={this.handleBackClick}
                        hidePagination
                        isEditable
                        addDelete
                    />
                    <Dialog
                        open={openDialog}
                        type={this.getDialogType()}
                        customTitle=""
                        button={this.button}
                        content={this.getDialogContent()}
                        size="lg"
                    />
                </MiniDrawer>
                {this.state.isDeleting &&
                <AppLoader
                    type="fullScreen"
                />
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    ...actions,
};

export default withStyles(themeStyles)(reduxConnect(
    ConfigureDashboard,
    mapDispatchToProps,
));
