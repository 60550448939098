import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import styles from '../../Steppers//AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';

type Props = {
    formData: {
        config: {
            fuelTankThreshold: Number,
            realert: String,
            realertMinutes: Number,
        },
    },
    handleTextboxChange: Function,
};

class FuelTankAlert extends React.Component<Props> {
    state = {};

    onChange

    render() {
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    <div className={styles['step-rightside']}>
                        <form noValidate autoComplete="off">
                            <div>
                                <div>{ALERT_DETAILS.LABEL.FUEL_TANK_BELOW}</div>
                                <div>
                                    <TextField
                                        required
                                        margin="normal"
                                        className={styles['text-wid']}
                                        id="fuelTankThreshold"
                                        name="fuelTankThreshold"
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: ALERT_DETAILS.VALUE.FT_MIN,
                                                max: ALERT_DETAILS.VALUE.FT_MAX,
                                            },
                                        }}
                                        value={this.props.formData.config.fuelTankThreshold}
                                        onKeyPress={(event) => {
                                            if (event.charCode === 46) {
                                                event.preventDefault();
                                                return false;
                                            }
                                            return true;
                                        }}
                                        onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (inputValue !== '') {
                                                inputValue = (Math.trunc(inputValue)).toString();
                                                if (inputValue.indexOf('.') !== -1) {
                                                    inputValue = inputValue.substring(1, inputValue.indexOf('.'));
                                                }
                                            }
                                            this.props.handleTextboxChange('fuelTankThreshold')({
                                                target: {
                                                    value: inputValue,
                                                },
                                            });
                                        }}
                                    />
                                    <span className={styles['unit-Name']}>%*</span>
                                </div>
                                <div>
                                    <RadioGroup
                                        aria-label="realertMe"
                                        className={styles['fuel-alertradio']}
                                        id="realertMe"
                                        name="realertMe"
                                        value={this.props.formData.config.realert}
                                        onChange={(e) => {
                                            this.props.handleTextboxChange('realert')(e);
                                            if (e.target.value === 'off') {
                                                this.props
                                                    .handleTextboxChange('realertMinutes')({
                                                        target: {
                                                            value: ALERT_DETAILS.VALUE.FT_RT_DEF,
                                                        },
                                                    });
                                            }
                                        }}
                                    >
                                        <FormControlLabel
                                            value="off"
                                            control={<Radio color="primary" />}
                                            label={ALERT_DETAILS.LABEL.FUEL_TANK_REALERT_OFF}
                                            labelPlacement="start"
                                        />
                                        <FormControlLabel
                                            value="on"
                                            control={<Radio color="primary" />}
                                            label={ALERT_DETAILS.LABEL.FUEL_TANK_REALERT_ON}
                                            labelPlacement="start"
                                        />
                                    </RadioGroup>
                                </div>
                                <div className={styles['left-pad']}>
                                    <TextField
                                        required
                                        margin="normal"
                                        className={styles['text-widfuel']}
                                        id="realertMinutes"
                                        name="realertMinutes"
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: ALERT_DETAILS.VALUE.FT_RT_MIN,
                                                max: ALERT_DETAILS.VALUE.FT_RT_MAX,
                                            },
                                        }}
                                        disabled={this.props.formData.config.realert === 'off'}
                                        value={this.props.formData.config.realertMinutes}
                                        onChange={this.props.handleTextboxChange('realertMinutes')}
                                    />
                                    <span className={styles['unit-Name']}>
                                        {ALERT_DETAILS.LABEL.FUEL_TANK_MINUTES}
                                    </span>
                                </div>
                                <div className={styles['left-pad']}>
                                    {ALERT_DETAILS.LABEL.FUEL_TANK_LEVEL_BELOW}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default FuelTankAlert;
