/* @flow */
import { USER_DATA_SUCCESS, USER_DATA_ERROR, SET_ACCOUNT_SUCCESS, SET_ACCOUNT_ERROR, UPDATE_ACCOUNT_LOADER, UPDATE_ACCOUNTS_LOADER } from './constants.userAccount';
import { setUserToken, isImpersonatedUser } from './../../helper-classes/utility-functions';

const initialState = {
    userAccounts: [],
    isLoader: false,
    isAccountLoader: false,
    changeAccount: {
        status: false,
    },
};

type stateType = {
    userAccounts: Array<Object>,
    isLoader: boolean,
    isAccountLoader: boolean,
}
type actionType = {
    type: string,
    payload :{
        response: Array<Object>,
        token: string,
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case USER_DATA_SUCCESS: {
        let apiAccounts = action.payload.response;
        if (isImpersonatedUser() === 'true') {
            apiAccounts = [action.payload.response[0]];
        }
        return {
            userAccounts: apiAccounts,
            isLoader: false,
            isAccountLoader: false,
            changeAccount: {
                status: false,
            },
        };
    }
    case USER_DATA_ERROR:
        return {
            ...state,
            isLoader: false,
            isAccountLoader: false,
            changeAccount: {
                status: false,
            },
        };
    case SET_ACCOUNT_SUCCESS:
        setUserToken(action.payload.token);
        return {
            ...state,
            isLoader: false,
            isAccountLoader: false,
            changeAccount: {
                status: true,
            },
        };
    case SET_ACCOUNT_ERROR:
        return {
            ...state,
            isLoader: false,
            isAccountLoader: false,
            changeAccount: {
                status: false,
            },
        };
    case UPDATE_ACCOUNT_LOADER:
        return {
            ...state,
            isLoader: true,
            changeAccount: {
                status: false,
            },
        };
    case UPDATE_ACCOUNTS_LOADER:
        return {
            ...state,
            isAccountLoader: true,
            changeAccount: {
                status: false,
            },
        };
    default:
        return state;
    }
};
