/* @flow */
import React, { Component } from 'react';
import queryString from 'query-string';
import { MAP } from 'react-google-maps/lib/constants';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.home';
import * as landmarkActions from './../Landmark/LandmarkDetails/actions.landmarks';
import analytics from '../../analytics/index';
import * as assetActions from '../Assets/actions.assets';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import MapsWrapper from '../MapsWrapper';
import customStyles from './Home.module.scss';
import ClusterFilter from '../../components/MapCluster/ClusterFilter';
import MapAssetList from '../../components/AssetList/MapAssetList';
import LandmarkDetails from './../Landmark/LandmarkDetails/LandmarkDetails';
import AssetDetails from '../../components/AssetDetails/AssetDetails';
import KmlDetails from './../Kml/kmlDetails';
import { apiUrl as kmlApiUrl, getHeader as kmlHeaders } from './../Kml/epics.kml';
import AppLoader from './../../components/AppLoader';
import {
    isEmpty,
    getPermissionValue,
    getDefaultLandingPage,
    setSessionStorageItem,
    getSessionStorageItem,
    removeSessionStorageItem,
    getUserPreference,
    saveUserPreference,
    getLocalStorageValue,
    getData,
} from '../../helper-classes/utility-functions';
import RightDrawer from '../../components/SharedComponents/RightDrawer/RightDrawer';
import config, { EXPLODE_LANDMARK_COUNT, MAX_PRECISION_VALUE, ZOOM_LEVEL_FOUR } from '../../constants/Config';
import { KML_MAP_PREF_KEY, MAP_ZOOM_AND_CENTER_SESSION_NAME, LM_MAP_ZOOM_AND_CENTER_MODIFIED } from './constants.home';
import * as notificationActions from '../../components/SharedComponents/NotificationHandler/actions.appNotifications';

const mapFilterKey = 'map_applied_filters';
const selectedItemKey = 'map_selected_cluster';
const snackbarNotificationObj = {
    type: 'snackbar',
    isOpen: false,
    overrideConfig: {
        key: 'home-page-filter-notification',
        autoHideDuration: 6000,
        action: undefined,
    },
};

export type Props = {
    history: {
        action: string,
        push: Function,
        replace: Function,
        location: {
            key: string,
            state: Object,
        },
        goBack: Function,
    },
    location: {
        pathname: string,
        search: string,
    },
    fetchClusterPoints: Function,
    assetPointsCount: Object,
    landmarkPointsCount: Object,
    assetPoints: Object,
    landmarkPoints: Object,
    fetchAssetsDetails: Function,
    assetDetails: Object,
    assetId: string,
    loadingCluster: boolean,
    assetLoading: boolean,
    kmlLoading: boolean,
    appNotificationHandler: Function,
};

export type State = {
    center: any,
    assetPointsCount: Object,
    showFilterPanel: boolean,
    hideLandmark: boolean,
    clusterType: string,
    showLandmarkInfo: boolean,
    showAssetInfo: boolean,
    hideAssets: boolean,
    drawerVisible: boolean,
    clusterID: string,
    showKml: boolean,
    isMapLoaded: boolean,
    showBackButton: boolean,
    coordinates: Object,
    markers: Object,
    assetClustering: boolean,
    landmarkClustering: boolean,
    totalLandmarks: number,
    showAssetClusteringOption: boolean,
    showLandmarkClusteringOption: boolean,
    kmlPreferenceUrls: any,
    kmlPreferenceMethod: string,
    zoomToKmlLayer: string;
};

type Ref = { current: any | HTMLDivElement, handleShowHideDrawer?: any | Function };

class Home extends Component<Props, State> {
    drawer: Ref;
    precision: number;
    map: Object;
    newPage: boolean;
    cluster: any;
    explodeClusterType: string;
    selectedItem: string;
    zoomToShape: boolean;
    zoomLevel: number;
    mapCenter: any;
    hashPoints: any;
    previousBoundPoint: Object;
    appliedFilters: any;
    mapCenterFilter: any;
    refreshLandmarkPage: Function;
    refreshKmlPage: Function;
    showHideMapAssetListRefreshChip: Function;
    isFitbound: boolean;
    initialLoad: boolean;
    resizeCalled: boolean;
    isResizeAsset: boolean;
    isResizeLandmark: boolean;
    explode: boolean;
    isAssetLandmarkSelected: boolean;
    isRightDrawerOpen: boolean;
    comingFromZoomedAsset: boolean;
    latLngBoundsToResetMap: Object;
    disableFilters: Function;

    static defaultProps = {
        assets: [],
    }

    constructor(props) {
        super(props);
        this.drawer = { ...React.createRef(), handleShowHideDrawer: undefined };
        this.state = {
            markers: {
                shape: { type: 'cluster' },
                data: [],
                boundaries: [],
                zoom: 0,
            },
            coordinates: {
                lat: 36.778259, // this is hardcoded to set us location by default
                lng: -119.417931,
            },
            center: '',
            assetPointsCount: {
                movingCount: 0,
                idleCount: 0,
                stoppedCount: 0,
                inLandmarkCount: 0,
                outLandmarkCount: 0,
                count: 0,
            },
            showFilterPanel: false,
            hideLandmark: false,
            clusterType: 'asset-list',
            showLandmarkInfo: false,
            showAssetInfo: false,
            hideAssets: false,
            drawerVisible: true,
            clusterID: '',
            showKml: false,
            isMapLoaded: false,
            showBackButton: true,
            assetClustering: true,
            landmarkClustering: true,
            totalLandmarks: 0,
            showAssetClusteringOption: false,
            showLandmarkClusteringOption: false,
            kmlPreferenceUrls: [],
            kmlPreferenceMethod: 'post',
            zoomToKmlLayer: '',
            isRightDrawerOpen: true,
        };

        this.refreshLandmarkPage = () => { };
        this.refreshKmlPage = () => { };
        this.disableFilters = () => { };
        this.showHideMapAssetListRefreshChip = () => { };
        this.precision = 2;
        this.map = {};
        this.newPage = true;
        this.getInitClusterData();
        this.explode = false;
        this.isAssetLandmarkSelected = false;
        this.isRightDrawerOpen = true;
        this.comingFromZoomedAsset = false;
        this.latLngBoundsToResetMap = { };
    }

    UNSAFE_componentWillMount() {
        if (getPermissionValue('Home') === 'None') {
            this.props.history.push(getDefaultLandingPage());
        }

        /**
         * after page load we are checking if any storage value for item and zoomlevel.
         * If value is there then updating local varable and removing from store.
         * For initial load can check this.isLoaded() function when page is refreshed.
         * */
        this.retainPreAppliedFilters();

        /**
         * initialDrawerSelector will decide, which drawer to open after reload/refresh
         * or in case of url sharing or redirected from other page cases
         * */
        this.initialDrawerSelector();

        // to stop multiple call in componentDidUpdate zoom param is removed from url on first load
        this.removeQueryParam();

        // initialize global snack bar notification handler
        this.props.appNotificationHandler([{
            ...snackbarNotificationObj,
            ...{
                nConfig: {
                    style: { backgroundColor: 'rgb(49, 49, 49)', color: '#fff' },
                    onCloseNotification: this.closeSnackBarNotification,
                },
            },
        }]);
    }

    componentDidMount() {
        this.updateFilterState();
        getUserPreference(KML_MAP_PREF_KEY).then((response) => {
            const kmlPreference = response && response.value ? response.value : null;
            let { kmlPreferenceMethod } = this.state;
            let kmlParsedData = [];
            if (kmlPreference) {
                kmlPreferenceMethod = 'put';
                kmlParsedData = JSON.parse(kmlPreference);
            }
            if (!isEmpty(kmlParsedData) && Array.isArray(kmlParsedData)) {
                getData(`${kmlApiUrl}?cb=${new Date().getTime()}`, { headers: kmlHeaders() }).then((res) => {
                    if (res && res.content) {
                        const newPreferenceValue =
                            kmlParsedData.filter(d => res.content.some(rD => d === rD.url));
                        this.updateKmlPreferenceUrls(newPreferenceValue, kmlPreferenceMethod);
                        saveUserPreference(KML_MAP_PREF_KEY, {
                            config: newPreferenceValue,
                            method: 'put',
                        });
                    } else {
                        this.updateKmlPreferenceUrls(kmlParsedData, kmlPreferenceMethod);
                    }
                }).catch(() => {
                    this.updateKmlPreferenceUrls(kmlParsedData, kmlPreferenceMethod);
                });
            } else this.updateKmlPreferenceUrls(kmlParsedData, kmlPreferenceMethod);
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { assetPoints, landmarkPoints } = nextProps;
        if ((assetPoints && (this.props.assetPoints !== assetPoints)) ||
            (landmarkPoints && (this.props.landmarkPoints !== landmarkPoints))) {
            const { assetPointsCount, landmarkPointsCount } = nextProps;
            this.processClusterData(assetPoints, landmarkPoints);

            if (assetPointsCount && Object.keys(assetPointsCount).length > 0) {
                this.updateCount(assetPointsCount);
                if (!this.state.showAssetClusteringOption && assetPointsCount.count > 0
                    && assetPointsCount.count <= config.get('EXPLODE_ASSET_COUNT')) {
                    this.setState({ showAssetClusteringOption: true });
                }
            }

            if (landmarkPointsCount && 'count' in landmarkPointsCount) {
                this.setState({ totalLandmarks: landmarkPointsCount.count });
                if (!this.state.showLandmarkClusteringOption && landmarkPointsCount.count > 0
                    && landmarkPointsCount.count <= EXPLODE_LANDMARK_COUNT) {
                    this.setState({ showLandmarkClusteringOption: true });
                }
            }
        }
    }

    componentDidUpdate() {
        this.updatePageData();
    }

    componentWillUnmount() {
        /**
         * since this a global notification handler - before leaving component
         * we should hide any snack bar notification which is displaying over the map
         * */
        this.props.appNotificationHandler([{
            ...snackbarNotificationObj,
            ...{
                isOpen: false,
            },
        }]);
    }

    removeQueryParam = () => {
        const queryParams = queryString.parse(this.props.location.search);
        if (queryParams.zoom) delete queryParams.zoom;
        const newQueryParams = queryString.stringify(queryParams);
        this.props.history.replace(`?${newQueryParams}`);
    }

    getInitClusterData = () => {
        this.resetHashPointValue();
        this.initialLoad = false;
        this.isFitbound = true;
        this.mapCenter = '';
        this.mapCenterFilter = null;
        this.zoomLevel = 0;
        this.zoomToShape = false;
        this.explodeClusterType = '';
        this.resizeCalled = false;
        this.isResizeAsset = false;
        this.isResizeLandmark = false;
    }

    filterGetterAndSetter = (action: string = 'set', data?: any) => {
        const failureResponse = (action === 'set') ? false : {};
        if (action === 'set') {
            // all data validations must handlled here
            const supportedSetters = ['update', 'reset', 'resetSelectedClusterFilter', 'clusterResize', 'filterPanelItem'];
            if (data && data.type && typeof (data.type) === 'string' && supportedSetters.includes(data.type)) {
                // DO NOT UPDATE : below assignment represent reset case
                let appliedFiltersObj = {};

                // setter type and it's filterData validations
                if (data.type === 'filterPanelItem') {
                    if (data.filterData && typeof (data.filterData) === 'string') {
                        appliedFiltersObj = queryString.parse(data.filterData);
                    } else return failureResponse;
                } else if (['update', 'clusterResize', 'resetSelectedClusterFilter'].includes(data.type)) {
                    appliedFiltersObj = queryString.parse(this.appliedFilters);
                }

                // right drawer state handling in case of retain filters
                if (appliedFiltersObj.isRightDrawerOpen) {
                    const isRightDrawerOpen = appliedFiltersObj.isRightDrawerOpen === 'true';
                    this.saveRightDrawerState(isRightDrawerOpen, true);
                    this.handleShowHideDrawer(isRightDrawerOpen);
                    delete appliedFiltersObj.isRightDrawerOpen;
                }

                // default latLngBounds handling used for reset map bound
                if (appliedFiltersObj.latLngBoundsToResetMap) {
                    this.latLngBoundsToResetMap = JSON.parse(appliedFiltersObj.latLngBoundsToResetMap || '');
                    delete appliedFiltersObj.latLngBoundsToResetMap;
                }

                // resetCluster is used to remove selected asset/landmark from filters
                if (data.type === 'resetSelectedClusterFilter') {
                    delete appliedFiltersObj.selectedAsset;
                    delete appliedFiltersObj.selectedLandmark;
                }

                // get selected asset or landmark filters using URL params
                const urlParams = queryString.parse(this.props.location.search);
                if (urlParams.assetId) {
                    appliedFiltersObj.selectedAsset = `${urlParams.assetId}`;
                    delete appliedFiltersObj.selectedLandmark;
                } else if (urlParams.landmarkId) {
                    appliedFiltersObj.selectedLandmark = `${urlParams.landmarkId}`;
                    delete appliedFiltersObj.selectedAsset;
                }

                // asset hide/show and explode handling
                if (this.state.hideAssets) {
                    appliedFiltersObj.hide_assets = 'true';
                } else {
                    appliedFiltersObj.hide_assets = 'false';
                }

                // landmark hide/show and explode handling
                if (this.state.hideLandmark) {
                    appliedFiltersObj.hide_landmarks = 'true';
                } else {
                    appliedFiltersObj.hide_landmarks = 'false';
                }

                // asset cluster explode and assetClustering handling
                if ((!this.state.assetClustering || getLocalStorageValue('assetClustering') === 'false') && this.state.assetPointsCount.count && this.state.assetPointsCount.count <= config.get('EXPLODE_ASSET_COUNT') && !this.appliedFilters.match(/hide_assets=true/g)) {
                    appliedFiltersObj.explode_assets = 'true';
                } else if (getLocalStorageValue('assetClustering') === 'true' && this.explodeClusterType === '') {
                    delete appliedFiltersObj.explode_assets;
                }

                // landmark cluster explode and landmarkClustering handling
                if ((!this.state.landmarkClustering || getLocalStorageValue('landmarkClustering') === 'false') && this.state.totalLandmarks && this.state.totalLandmarks <= EXPLODE_LANDMARK_COUNT && !this.appliedFilters.match(/hide_landmarks=true/g)) {
                    appliedFiltersObj.explode_landmarks = 'true';
                } else if (getLocalStorageValue('landmarkClustering') === 'true' && this.explodeClusterType === '') {
                    delete appliedFiltersObj.explode_landmarks;
                }

                // handle cluster resize filters
                if (data.type === 'clusterResize') {
                    delete appliedFiltersObj.explode_assets;
                    delete appliedFiltersObj.explode_landmarks;
                }

                // finalizing filter data and updating it to variable
                this.appliedFilters = queryString.stringify(appliedFiltersObj, { arrayFormat: 'comma' }) || '';
                if (this.appliedFilters) this.appliedFilters = `&${this.appliedFilters.replace(/%2C|%2c/g, ',')}`;

                /**
                 * prepare filters to save in session storage also contains those filters
                 * which is not part of any API calls i.e. right drawer status etc.
                 * */
                delete appliedFiltersObj.selectedAsset;
                delete appliedFiltersObj.selectedLandmark;
                appliedFiltersObj.isRightDrawerOpen = this.isRightDrawerOpen;
                appliedFiltersObj.latLngBoundsToResetMap
                    = JSON.stringify(this.latLngBoundsToResetMap);

                let filtersToSave = queryString.stringify(appliedFiltersObj, { arrayFormat: 'comma' }) || '';
                if (filtersToSave) filtersToSave = `&${filtersToSave.replace(/%2C|%2c/g, ',')}`;
                setSessionStorageItem(mapFilterKey, filtersToSave);
            } else return failureResponse;
        } else if (action === 'get-url-params') {
            return this.appliedFilters;
        } else if (action === 'get-object') {
            return queryString.parse(this.appliedFilters);
        }

        return failureResponse;
    }

    /**
     * retainPreAppliedFilters this is used to retain previously applied filters
     * also map bound, drawer status etc.
     */
    retainPreAppliedFilters = () => {
        this.appliedFilters = getSessionStorageItem(mapFilterKey);
        this.updateFilterState();
        let mapFilter = getSessionStorageItem(MAP_ZOOM_AND_CENTER_SESSION_NAME);
        this.selectedItem = getSessionStorageItem(selectedItemKey);

        if (mapFilter) {
            mapFilter = JSON.parse(mapFilter);
            this.zoomLevel = mapFilter.zoom ? mapFilter.zoom : 0;
            this.mapCenterFilter = mapFilter.center ? mapFilter.center : null;
            this.isFitbound = false;
            this.hashPoints = mapFilter.hashPoints ? mapFilter.hashPoints : {};
        }
        removeSessionStorageItem(MAP_ZOOM_AND_CENTER_SESSION_NAME);
        removeSessionStorageItem(selectedItemKey);
    }

    /**
     * retainMapBound is used to retain initially loaded boundary
     * if both assets & landmarks are hidden on map
     * */
    retainMapBound = () => {
        if (this.map && !isEmpty(this.latLngBoundsToResetMap)
            && this.state.hideAssets && this.state.hideLandmark) {
            if (!isEmpty(this.map) && typeof this.map.fitBounds === 'function') {
                this.map.fitBounds(this.latLngBoundsToResetMap);
            }
        }
    }

    /**
     * saveRightDrawerState is to save current status of right drawer
     * currentState: boolean: this value comes from RightDrawer component and holds current status
     * */
    saveRightDrawerState = (currentState: boolean, isFilterRetainMode: boolean = false) => {
        this.isRightDrawerOpen = currentState;
        if (!isFilterRetainMode) this.filterGetterAndSetter('set', { type: 'update' });
    }

    /**
     * handleShowHideDrawer is used to show or hide right drawer using RightDrawer ref
     * */
    handleShowHideDrawer = (isOpen: boolean = true) => {
        if (this.drawer && typeof this.drawer.handleShowHideDrawer === 'function') {
            this.drawer.handleShowHideDrawer(isOpen);
        }
    }

    /**
     * initialDrawerSelector must be called only once after initial load/reload/refresh
     * this checks which drawer to open based on url params and filters
     * */
    initialDrawerSelector = () => {
        const urlParams = queryString.parse(this.props.location.search);
        let drawerToOpen = 'asset-list';

        if (urlParams.assetId) drawerToOpen = 'asset';
        else if (urlParams.landmarkId) drawerToOpen = 'landmark';

        this.setState({ clusterType: drawerToOpen });
    }

    updateFilterState = () => {
        let { coordinates, hideLandmark, hideAssets } = this.state;
        coordinates = this.mapCenterFilter ? this.mapCenterFilter : coordinates;

        const appliedFilters = this.filterGetterAndSetter('get-object');
        hideLandmark = (appliedFilters && appliedFilters.hide_landmarks === 'true');
        hideAssets = (appliedFilters && appliedFilters.hide_assets === 'true');

        this.setState({
            ...this.state, coordinates, hideLandmark, hideAssets,
        }, () => {
            this.filterGetterAndSetter('set', { type: 'update' });
        });
    }

    resetHashPointValue = () => {
        this.hashPoints = {};
        this.previousBoundPoint = {};
        this.cluster = {};
    }

    /**
     * checkFilterPanelVisibility is used to check whether we have to display filter panel or not
     * based of provided cluster type
     * clusterType: string: current cluster type page on map screen
     * */
    checkFilterPanelVisibility = (clusterType: string) => (['asset-list', 'kml'].includes(clusterType));

    setAssetAndLandmarkId = (clusterID: string, urlParams: Object, clusterType: string = 'asset-list') => {
        this.filterGetterAndSetter('set', { type: 'update' });
        this.setState({
            clusterID,
            showLandmarkInfo: (clusterType === 'landmark'),
            showAssetInfo: (clusterType === 'asset'),
            showFilterPanel: this.checkFilterPanelVisibility(clusterType),
            clusterType,
            showBackButton: (urlParams && urlParams.back) || true,
        }, () => {
            if (clusterType !== 'asset-list') {
                this.handleShowHideDrawer();
            }
        });
    }

    clearZoomAndLoadData = () => {
        this.mapCenterFilter = null;
        this.hashPoints = {};
        this.pageBasedData();
    }

    /**
     * updatePageData will be called once the page is loaded and
     * second time some action happen on the page and url got changed
     * checking urlParams.zoom is false then no need to zoom to shape
     * */
    updatePageData = () => {
        const urlParams = queryString.parse(this.props.location.search);
        const isLandmark = Object.prototype.hasOwnProperty.call(urlParams, 'landmarkId');
        const isAsset = Object.prototype.hasOwnProperty.call(urlParams, 'assetId');
        const { clusterID } = this.state;

        /**
         * handling for main menu item click if detail drawer is open
         * and single click on asset list item
         * */
        const locationStateData = { ...this.props.history.location.state };

        if (locationStateData && locationStateData.comingFromZoomedAsset) {
            /**
             * this specify that you either double clicked or redirected on detail drawer using URL
             * */
            this.comingFromZoomedAsset = locationStateData.comingFromZoomedAsset;
        }

        if (locationStateData && typeof (locationStateData.comingFromZoomedAsset) === 'undefined' && Object.keys(locationStateData).length > 0) {
            if (locationStateData.mainMenuItemClicked) {
                this.getInitClusterData();
                this.retainMapBound();
                this.filterGetterAndSetter('set', { type: 'resetSelectedClusterFilter' });
                this.filterGetterAndSetter('set', { type: 'clusterResize' });
                this.pageBasedData();
            } else if (isAsset) {
                if (locationStateData.assetDataForZoom) {
                    if (Object.keys(locationStateData.assetDataForZoom).length > 0) {
                        // this is single click case
                        this.clickToZoomAsset(locationStateData.assetDataForZoom, urlParams);
                    } else if (Object.keys(locationStateData.assetDataForZoom).length === 0) {
                        // this is double click case
                        this.clearZoomAndLoadData();
                    }
                }
            }
        } else if (urlParams.zoom === 'false') {
            this.pageBasedData();
        } else if (urlParams.zoom === 'true') {
            // checking urlParams.zoom is true then need to zoom to shape
            this.clearZoomAndLoadData();
        } else if (clusterID) {
            if ((isLandmark && clusterID !== urlParams.landmarkId) ||
                (isAsset && clusterID !== urlParams.assetId)
            ) {
                this.clearZoomAndLoadData();
            } else if (!isLandmark && !isAsset) {
                if (!this.isAssetLandmarkSelected) {
                    if (this.comingFromZoomedAsset && this.props.history.action === 'POP') {
                        this.retainMapBound();
                    }
                    this.filterGetterAndSetter('set', { type: 'resetSelectedClusterFilter' });
                    this.getInitClusterData();
                    this.pageBasedData();
                } else if (this.state.clusterType !== 'asset-list' && this.state.clusterType !== 'kml') {
                    this.setState({ clusterType: 'asset-list', showFilterPanel: true });
                }
            } else if (this.comingFromZoomedAsset && this.props.history.action === 'POP') {
                this.setAssetAndLandmarkId(urlParams.assetId, urlParams, 'asset-list');
                this.comingFromZoomedAsset = false;
            }
        } else if (!urlParams.zoom && !this.newPage) {
            if ((isLandmark && clusterID !== urlParams.landmarkId) ||
                (isAsset && clusterID !== urlParams.assetId)
            ) {
                this.clearZoomAndLoadData();
            }
        }
    }

    // retun landmark shape for the map
    getShapeData = (data: Object) => {
        const type = (data.landmarkShape) ? data.landmarkShape.toLowerCase() : '';
        switch (type) {
        case 'rectangle': {
            if (data.landmarkBox) {
                return {
                    type: 'RECTANGLE',
                    point1: { lat: data.landmarkBox.first.y, lng: data.landmarkBox.first.x },
                    point2: { lat: data.landmarkBox.second.y, lng: data.landmarkBox.second.x },
                };
            }
            return '';
        }
        case 'circle': {
            if (data.landmarkCircle && data.landmarkCircle.radius) {
                return {
                    type: 'CIRCLE',
                    radius: data.landmarkCircle.radius.value || 400,
                    lat: parseFloat(data.landmarkCircle.center.y),
                    lng: parseFloat(data.landmarkCircle.center.x),
                };
            }
            return '';
        }
        case 'polygon': {
            if (data.landmarkPolygon) {
                return {
                    type: 'POLYGON',
                    points: data.landmarkPolygon.points.map(d => ({ lat: d.y, lng: d.x })),
                };
            }
            return '';
        }
        default: return '';
        }
    };

    // update asset count on filter bar
    updateCount = (assetPointsData) => {
        let { assetPointsCount } = this.state;
        const {
            movingCount = 0,
            idleCount = 0,
            stoppedCount = 0,
            inLandmarkCount = 0,
            outLandmarkCount = 0,
            count = 0,
        } = assetPointsData;

        assetPointsCount = {
            movingCount,
            idleCount,
            stoppedCount,
            inLandmarkCount,
            outLandmarkCount,
            count,
        };
        this.setState({ assetPointsCount });
    }

    calculateAssetBounds = (assetPoints, boundary) => {
        if (!assetPoints) return boundary;
        const bounds = boundary;

        if (assetPoints.boundary) {
            bounds.topLeftLng = assetPoints.boundary.topLeft.x;
            bounds.topLeftLat = assetPoints.boundary.topLeft.y;
            bounds.bottomRightLng = assetPoints.boundary.bottomRight.x;
            bounds.bottomRightLat = assetPoints.boundary.bottomRight.y;
        } else if (assetPoints.data.length > 0) {
            const zoomBounds = new window.google.maps.LatLngBounds();
            assetPoints.data.forEach((data) => {
                if (data.point && data.point.y && data.point.x) {
                    zoomBounds.extend({
                        lng: data.point.x,
                        lat: data.point.y,
                    });
                }
            });
            bounds.zoomBounds = zoomBounds;
        }
        return bounds;
    }

    calculateLandmarkBounds = (landmarkPoints, boundary) => {
        if (!landmarkPoints) return boundary;
        const bounds = boundary;

        if (landmarkPoints.boundary) {
            if (bounds.topLeftLng === 0
                || bounds.topLeftLng > landmarkPoints.boundary.topLeft.x) {
                bounds.topLeftLng = landmarkPoints.boundary.topLeft.x;
            }
            if (bounds.topLeftLat === 0
                || bounds.topLeftLat < landmarkPoints.boundary.topLeft.y) {
                bounds.topLeftLat = landmarkPoints.boundary.topLeft.y;
            }
            if (bounds.bottomRightLng === 0
                || bounds.bottomRightLng < landmarkPoints.boundary.bottomRight.x) {
                bounds.bottomRightLng = landmarkPoints.boundary.bottomRight.x;
            }
            if (bounds.bottomRightLat === 0
                || bounds.bottomRightLat > landmarkPoints.boundary.bottomRight.y) {
                bounds.bottomRightLat = landmarkPoints.boundary.bottomRight.y;
            }
        } else if (landmarkPoints.data.length > 0) {
            const zoomBounds = this.fetchMapZoomBounds(boundary);
            landmarkPoints.data.forEach((data) => {
                if (data.point && data.point.y && data.point.x) {
                    zoomBounds.extend({
                        lng: data.point.x,
                        lat: data.point.y,
                    });
                }
            });
            bounds.zoomBounds = zoomBounds;
        }
        return bounds;
    }

    /**
     * process data for map page and update initialLoad value and will do fitbound
     * */
    calculateBoundary = (assetPoints, landmarkPoints) => {
        let boundary = {
            topLeftLat: 0,
            topLeftLng: 0,
            bottomRightLat: 0,
            bottomRightLng: 0,
            zoomBounds: {},
        };

        if (this.isResizeAsset) {
            boundary = this.calculateAssetBounds(assetPoints, boundary);
        } else if (this.isResizeLandmark) {
            boundary = this.calculateLandmarkBounds(landmarkPoints, boundary);
        } else {
            boundary = this.calculateAssetBounds(assetPoints, boundary);
            boundary = this.calculateLandmarkBounds(landmarkPoints, boundary);
        }

        if (boundary.topLeftLat !== 0 && boundary.topLeftLng !== 0
            && boundary.bottomRightLat !== 0 && boundary.bottomRightLng !== 0) {
            const zoomBounds = this.fetchMapZoomBounds(boundary);
            zoomBounds.extend({
                lng: boundary.topLeftLng,
                lat: boundary.topLeftLat,
            });
            zoomBounds.extend({
                lat: boundary.bottomRightLat,
                lng: boundary.bottomRightLng,
            });
            boundary.zoomBounds = zoomBounds;
        }
        return boundary;
    }

    fetchMapZoomBounds = (boundary) => ((Object.keys(boundary.zoomBounds).length === 0) ?
        new window.google.maps.LatLngBounds() : boundary.zoomBounds);

    processClusterData = (assetPoints, landmarkPoints) => {
        if (this.map && Object.keys(this.map).length > 0) {
            const assetClusters = [];
            const assets = [];
            const landmarks = [];
            const landmarkClusters = [];
            const bounds = new window.google.maps.LatLngBounds();

            if (assetPoints && assetPoints.data) {
                assetPoints.data.forEach((data) => {
                    if (data.count > 1) assetClusters.push(data);
                    else assets.push(data);

                    if (data.point && data.point.y && data.point.x) {
                        const lat = data.point.y;
                        const lng = data.point.x;
                        bounds.extend({ lat, lng });
                    }
                });
            }

            if (landmarkPoints && landmarkPoints.data) {
                landmarkPoints.data.forEach((data) => {
                    if (data.count > 1) {
                        landmarkClusters.push(data);
                    } else {
                        const landmark = data;
                        landmark.shape = this.getShapeData(data);
                        landmarks.push(landmark);
                    }

                    if (data.point && data.point.y && data.point.x) {
                        const lat = data.point.y;
                        const lng = data.point.x;
                        bounds.extend({ lat, lng });
                    }
                });
            }

            let calculateBoundary = {};
            if (this.isResizeAsset && assetPoints.count === 0) {
                this.validateFilterAndResize('resize', { clusterType: 'assets' });
            } else if (this.isResizeLandmark && landmarkPoints.count === 0) {
                this.validateFilterAndResize('resize', { clusterType: 'landmarks' });
            } else if (this.isResizeLandmark || this.isResizeAsset) {
                calculateBoundary = this.calculateBoundary(assetPoints, landmarkPoints);
                this.isFitbound = true;
                this.initialLoad = false;
                this.resizeCalled = true;
            } else if (!this.resizeCalled) {
                calculateBoundary = this.calculateBoundary(assetPoints, landmarkPoints);
            }

            if (!this.initialLoad && this.isFitbound && !isEmpty(calculateBoundary.zoomBounds)) {
                if (Object.keys(this.latLngBoundsToResetMap).length === 0) {
                    this.latLngBoundsToResetMap = calculateBoundary.zoomBounds;
                    this.filterGetterAndSetter('set', { type: 'update' });
                }

                if (!isEmpty(this.map) && typeof this.map.fitBounds === 'function') {
                    this.map.fitBounds(calculateBoundary.zoomBounds);
                }
            }

            const markers = {
                shape: { type: 'cluster' },
                data: {
                    assetClusters,
                    assets,
                    landmarkClusters,
                    landmarks,
                    topLeftLng: calculateBoundary.topLeftLng,
                    topLeftLat: calculateBoundary.topLeftLat,
                    bottomRightLat: calculateBoundary.bottomRightLat,
                    bottomRightLng: calculateBoundary.bottomRightLng,
                },
                boundaries: [],
                zoom: this.state.markers && this.state.markers.zoom,
            };

            this.setState({
                markers,
                showFilterPanel: this.checkFilterPanelVisibility(this.state.clusterType),
            });
            this.cluster = {};
            this.explodeClusterType = '';
            setTimeout(() => {
                this.initialLoad = true;
                this.resizeCalled = false;
            });
        } else {
            setTimeout(() => {
                this.processClusterData(assetPoints, landmarkPoints);
            }, 1000);
        }
    }

    getBoundary = (map) => {
        this.map = map;
        if (!this.state.isMapLoaded && this.state.clusterType === 'asset-list') {
            this.retainMapBound();
        }
    }

    /**
     * callback from map page when any bound change, tiles will be loaded on the map.
     * this function inturn will call api.
     * */
    boundaryChanged = () => {
        if (this.initialLoad) {
            this.filterGetterAndSetter('set', { type: 'update' });
            if (this.map && Object.keys(this.map).length > 0) {
                this.hashPoints = this.getCurrentMapBounds();
                this.getData();
            }
        }
    }

    /**
     * getData is used to get cluster data by making API call this.props.fetchClusterPoints
     * based on bound info from this.hashPoints
     * */
    getData = () => {
        this.explode = false;
        this.filterGetterAndSetter('set', { type: 'update' });
        if (this.hashPoints && this.hashPoints.top &&
            !this.isResizeLandmark && !this.isResizeAsset) {
            if (this.previousBoundPoint && (Object.keys(this.cluster).length === 0)) {
                this.previousBoundPoint.count = 0;
                this.previousBoundPoint.explodeClusterType = '';
            }

            this.props.fetchClusterPoints(
                this.hashPoints.top,
                this.hashPoints.left,
                this.hashPoints.bottom,
                this.hashPoints.right,
                this.appliedFilters,
                null,
                null,
            );
        } else {
            this.props.fetchClusterPoints(
                null,
                null,
                null,
                null,
                this.appliedFilters,
                null,
                null,
            );
        }
    }

    /**
     * moveToShape is used to zoom on cluster (asset/landmark) shape using lat-long
     * map.fitbound function is used for this purpose
     * */
    moveToShape = (markers, map) => {
        const type = (markers.shape && markers.shape.type) ? markers.shape.type.toLowerCase() : '';
        switch (type) {
        case 'polygon': {
            if (markers.shape.points &&
                    Array.isArray(markers.shape.points)) {
                const bounds = new window.google.maps.LatLngBounds();
                markers.shape.points.forEach((d) => {
                    if (d.lat && d.lng && typeof d.lng === 'number' && typeof d.lat === 'number') {
                        bounds.extend({ lat: d.lat, lng: d.lng });
                    }
                });
                map.fitBounds(bounds);
            }
            break;
        }
        case 'marker': {
            if (markers.shape.lat && markers.shape.lng &&
                    typeof markers.shape.lat === 'number' && typeof markers.shape.lng === 'number'
            ) {
                const bounds = new window.google.maps.LatLngBounds();
                const { lat, lng } = markers.shape;
                bounds.extend({ lat, lng });
                map.fitBounds(bounds);
            } else if (!markers.shape.lat && !markers.shape.lng) {
                const urlParams = queryString.parse(this.props.location.search);
                this.isResizeAsset = Object.prototype.hasOwnProperty.call(urlParams, 'assetId');
                this.isResizeLandmark = Object.prototype.hasOwnProperty.call(urlParams, 'landmarkId');
                this.resizeIconHandler();
            }
            break;
        }
        case 'rectangle': {
            const { shape } = markers;
            if (shape.point1 && shape.point2
                    && shape.point1.lat && shape.point1.lng &&
                    typeof shape.point1.lat === 'number' && typeof shape.point1.lng === 'number'
                    && shape.point2.lat && shape.point2.lng &&
                    typeof shape.point2.lat === 'number' && typeof shape.point2.lng === 'number'
            ) {
                const lat = new window.google.maps.LatLng(
                    shape.point1.lat,
                    shape.point1.lng,
                );
                const lng = new window.google.maps.LatLng(
                    shape.point2.lat,
                    shape.point2.lng,
                );
                const bounds = new window.google.maps.LatLngBounds(lat, lng);
                map.fitBounds(bounds);
            }
            break;
        }
        case 'circle': {
            if (markers.shape.lat && markers.shape.radius) {
                const centerSfo = new window.google.maps.LatLng(
                    markers.shape.lat,
                    markers.shape.lng,
                );
                const circle = new window.google.maps.Circle({
                    radius: markers.shape.radius,
                    center: centerSfo,
                });
                const bounds = circle.getBounds();
                if (bounds && Object.keys(bounds).length > 0) {
                    map.fitBounds(bounds);
                }
            }
            break;
        }
        default: break;
        }
        if (markers && markers.updateZoomLevel) {
            map.context[MAP].setZoom(map.getZoom() - markers.reduceZoomLevelBy);
        }
    }

    /**
     * landmarksDataLoadedCallback is a callback handler for landmark API
     * this function will be called by landmark component
     * */
    landmarksDataLoadedCallback = (landmarkData) => {
        if (!landmarkData.landmarkDetails) return;
        if ((!landmarkData.landmarkDetails.lat || !landmarkData.landmarkDetails.lng) &&
            !this.zoomLevel) {
            this.hashPoints = {};
            this.initialLoad = false;
            this.getData();
        } else if ((Object.keys(this.map).length > 0) && this.zoomToShape) {
            this.zoomToShape = false;
            this.moveToShape(landmarkData.landmarkDetails, this.map);
            this.initialLoad = true;
        }
    }

    /**
     * assetDataLoadedCallback is a callback handler for asset API
     * this function will be called by asset component
     * */
    assetDataLoadedCallback = (assetData: Object) => {
        const assetDataForMap = {
            shape: {
                type: 'marker',
                lat: assetData.lat,
                lng: assetData.lng,
                points: [],
                point1: {},
                point2: {},
            },
            reduceZoomLevelBy: ZOOM_LEVEL_FOUR,
            updateZoomLevel: true,
        };

        if ((!assetData.lat || !assetData.lng) && !this.zoomLevel) {
            this.hashPoints = {};
            this.initialLoad = false;
            this.getData();
        } else if ((Object.keys(this.map).length > 0) && this.zoomToShape) {
            this.initialLoad = true;
            this.zoomToShape = false;
            this.moveToShape(assetDataForMap, this.map);
            this.validateFilterAndResize('filter');
        }
    }

    /**
     * checkZoomToShape is used to check whether we need to zoom to the shape or not
     * later it update this.zoomlevel based on value of this.zoomToShape
     * */
    checkZoomToShape = (urlParams: Object, forceZoomToShape: boolean = false) => {
        if (this.zoomLevel && this.selectedItem) {
            this.zoomToShape = forceZoomToShape;
            this.getData();
        } else if (urlParams.zoom) {
            this.zoomToShape = (urlParams.zoom === 'true');
        } else {
            this.zoomToShape = true;
        }
    }

    /**
     * clickToZoomAsset is used to zoom to the asset clicked from assset list item
     * locationStateData: Object: This set from MapAssetList component when single clicked on item
     * urlParams: Object: All url parameters set by clicking and redirecting on home page
     * */
    clickToZoomAsset = (assetDataForZoom: Object, urlParams: Object) => {
        this.removeQueryParam();
        this.setAssetAndLandmarkId(urlParams.assetId, urlParams, 'asset-list');
        if (assetDataForZoom.lat === 0 && assetDataForZoom.lng === 0) {
            // asset not having valid data to zoom
            this.filterGetterAndSetter('set', { type: 'update' });
            this.openSnackBarNotification('This asset is not responding, so the system may not have location data to show.');
        } else {
            // everything is good - zoom to asset
            this.checkZoomToShape(urlParams);
            const assetMarkerDataForZoom = {
                shape: {
                    type: 'marker',
                    lat: assetDataForZoom.lat,
                    lng: assetDataForZoom.lng,
                    points: [],
                    point1: {},
                    point2: {},
                    radius: undefined,
                },
                updateZoomLevel: assetDataForZoom.updateZoomLevel,
                reduceZoomLevelBy: assetDataForZoom.reduceZoomLevelBy,
            };

            if ((Object.keys(this.map).length > 0) && this.zoomToShape) {
                this.initialLoad = true;
                this.zoomToShape = false;

                /**
                 * moveToShape will change map bound which result an
                 * MapWrapper automatic call to boundaryChanged function
                 * which is used to call getData to get new cluster info
                 * */
                this.moveToShape(assetMarkerDataForZoom, this.map);
                this.refreshData(this.map.getZoom(), this.map.getCenter(), false);
                this.validateFilterAndResize('filter');
            }
        }
    }

    /**
     * pageBasedData is used to decide when to call which function to get data or update UI
     * decision will be taken based on isLandmark/isAsset value or session cluster data
     * */
    pageBasedData = () => {
        const urlParams = queryString.parse(this.props.location.search);
        urlParams.zoom = urlParams.zoom ? urlParams.zoom : 'true';
        this.explode = (urlParams.zoom === 'true');
        this.removeQueryParam();
        const isLandmark = Object.prototype.hasOwnProperty.call(urlParams, 'landmarkId');
        const isAsset = Object.prototype.hasOwnProperty.call(urlParams, 'assetId');
        const selectedItemValue = (this.selectedItem) ? JSON.parse(this.selectedItem) : {};

        if (isLandmark) {
            this.checkZoomToShape(urlParams, (getSessionStorageItem(LM_MAP_ZOOM_AND_CENTER_MODIFIED) === 'true'));
            this.setAssetAndLandmarkId(selectedItemValue.clusterID || urlParams.landmarkId, urlParams, 'landmark');
        } else if (isAsset) {
            const assetId = selectedItemValue.clusterID || urlParams.assetId;
            this.checkZoomToShape(urlParams);
            this.setAssetAndLandmarkId(assetId, urlParams, 'asset');
            this.props.fetchAssetsDetails(assetId);
        } else {
            if (selectedItemValue.clusterID) {
                let type = (selectedItemValue.type === 'editAsset') ? 'asset' : '';
                type = (selectedItemValue.type === 'editLandmark') ? 'landmark' : 'asset-list';
                this.setAssetAndLandmarkId(selectedItemValue.clusterID, {}, type);
            } else {
                this.setAssetAndLandmarkId('', {}, 'asset-list');
            }
            this.getData();
        }

        this.selectedItem = '';
        this.newPage = false;
        setTimeout(() => this.validateFilterAndResize('filter'), 1000);
    }

    /**
     * init load of the page callback from map page once map is loaded
     * then this function will be invoke
     * */
    isLoaded = (map) => {
        this.map = map;
        this.setState({ isMapLoaded: true });
        this.pageBasedData();
    }

    filterPanelItemOnClick = (clickedFilter) => {
        if (!clickedFilter) this.filterGetterAndSetter('set', { type: 'update' });
        else this.filterGetterAndSetter('set', { type: 'filterPanelItem', filterData: clickedFilter });
        this.validateFilterAndResize('filter');
        this.hashPoints = this.getCurrentMapBounds();
        this.getData();
    }

    resizeIconHandler = () => {
        if (this.isResizeLandmark || this.isResizeAsset) {
            this.filterGetterAndSetter('set', { type: 'clusterResize' });
            this.resetHashPointValue();
            this.hashPoints = {};
            this.zoomLevel = 0;
            this.mapCenterFilter = null;
            this.getData();
        }
    }

    validateFilterAndResize = (type: string, extraData?: any) => {
        if (type === 'filter') {
            // check any enabled filter from filter panel or hide cluster filter
            const { hideLandmark, hideAssets } = this.state;
            const appliedFilters = this.filterGetterAndSetter('get-object');

            if (hideLandmark || hideAssets || (appliedFilters &&
                (appliedFilters.asset_status || appliedFilters.asset_landmark))) {
                this.openSnackBarNotification('Please clear the filters to view all the icons');
            }
        } else if (type === 'resize') {
            // check cluster points counts & hide cluster while resizing to cluster
            const message = (extraData && extraData.clusterType === 'assets') ? 'Assets are currently not visible on the map. Please enable assets.' : 'Landmarks are currently not visible on the map. Please enable landmarks.';
            this.openSnackBarNotification(message);
        } else if (type === 'assetPointsCount') {
            /**
             * check asset count based on status & in/out landmark
             * while applying filters from filter panel
             * */
            const clickedFilter = queryString.parse(extraData);
            if (clickedFilter.asset_landmark || clickedFilter.asset_status) {
                this.initialLoad = false;
                this.isFitbound = true;
                const assetStatus = clickedFilter.asset_status ? clickedFilter.asset_status.split(',') : [];
                let count = 0;
                assetStatus.forEach((d) => { count += this.state.assetPointsCount[`${d}Count`]; });

                if (count === 0 && clickedFilter.asset_landmark) {
                    let landmarkFilter = '';
                    if (clickedFilter.asset_landmark === 'true') {
                        landmarkFilter = 'inLandmarkCount';
                    } else if (clickedFilter.asset_landmark === 'false') {
                        landmarkFilter = 'outLandmarkCount';
                    }
                    if (landmarkFilter) count += this.state.assetPointsCount[landmarkFilter];
                }

                // call resize asset validation since no asset is available
                if (count === 0) this.validateFilterAndResize('resize', { clusterType: 'assets' });
            }
        }
    }

    /**
     * openSnackBarNotification internally calls props.appNotificationHandler
     * to open snack bar notification
     * */
    openSnackBarNotification = (message: string) => {
        this.props.appNotificationHandler([{
            ...snackbarNotificationObj,
            ...{
                isOpen: true,
                message,
            },
        }]);
    }

    /**
     * closeSnackBarNotification internally calls props.appNotificationHandler
     * to auto close snack bar notification
     * */
    closeSnackBarNotification = (e, reason, notificationObj) => {
        if (reason !== 'clickaway' && notificationObj.overrideConfig
            && notificationObj.overrideConfig.key === 'home-page-filter-notification') {
            this.props.appNotificationHandler([{
                ...snackbarNotificationObj,
            }]);
        }
    }

    getCurrentMapBounds = () => {
        const bounds = this.map.getBounds();
        const northEast = bounds.getNorthEast(); // LatLng of the north-east corner
        const southWest = bounds.getSouthWest();
        const northWest = new window.google.maps.LatLng(northEast.lat(), southWest.lng());
        const southEast = new window.google.maps.LatLng(southWest.lat(), northEast.lng());
        return {
            top: northWest.lat(),
            left: northWest.lng(),
            bottom: southEast.lat(),
            right: southEast.lng(),
            precision: this.precision,
        };
    }

    getCurrentHashPoints = () => {
        const prevPrecision = this.precision;
        this.precision = Math.ceil(this.map.getZoom() === 0 ? 2 : this.map.getZoom() / 2);

        if ((prevPrecision === this.precision && prevPrecision === (MAX_PRECISION_VALUE - 1))
            || (this.precision > MAX_PRECISION_VALUE)) {
            this.precision = MAX_PRECISION_VALUE;
        } else if (this.precision < 0) this.precision = 0;

        let result = {};
        if ((Object.keys(this.cluster).length > 0) && this.cluster.bounds) {
            result = {
                top: this.cluster.bounds.topLeft.y,
                left: this.cluster.bounds.topLeft.x,
                bottom: this.cluster.bounds.bottomRight.y,
                right: this.cluster.bounds.bottomRight.x,
                precision: this.precision,
            };
        } else result = this.getCurrentMapBounds();

        return result;
    }

    setCenter = center => this.setState({ center });

    showHideLandmark = (isHideLandmark) => {
        const dataToUpdate = {
            hideLandmark: isHideLandmark,
            clusterType: 'asset-list',
        };
        const params = {
            feature: 'Map',
            show: !isHideLandmark,
        };
        analytics.track('LANDMARK_ICON', params);
        if (isHideLandmark) {
            if (this.props.location.search.match(/landmarkId=/g)) {
                this.setState(dataToUpdate, () => {
                    this.props.history.replace('/home');
                });
            }
        } else if (this.state.clusterType === 'kml') dataToUpdate.clusterType = 'asset-list';
        else if (this.state.clusterType !== 'asset-list') {
            dataToUpdate.clusterType = 'landmark';
        }
        if (this.state.clusterType === 'asset') dataToUpdate.clusterType = 'asset';
        this.setState(dataToUpdate, () => this.filterPanelItemOnClick());
    }

    showHideAsset = (isHideAssets) => {
        const dataToUpdate = {
            hideAssets: isHideAssets,
            clusterType: 'asset-list',
        };
        const params = {
            feature: 'Map',
            show: !isHideAssets,
        };
        analytics.track('ASSET_ICON', params);
        if (isHideAssets) {
            if (this.props.location.search.match(/assetId=/g)) {
                this.setState(dataToUpdate, () => {
                    this.props.history.replace('/home');
                });
            }
        } else if (this.state.clusterType === 'kml') {
            dataToUpdate.clusterType = 'asset-list';
        } else if (this.state.clusterType !== 'asset-list') {
            dataToUpdate.clusterType = 'asset';
        }
        if (this.state.clusterType === 'landmark') dataToUpdate.clusterType = 'landmark';
        this.setState(dataToUpdate, () => this.filterPanelItemOnClick());
    }

    resizeLandmark = () => {
        const { hideLandmark } = this.state;
        this.isResizeLandmark = true;
        this.isResizeAsset = false;
        if (!hideLandmark) {
            if (this.isResizeLandmark) {
                analytics.track('RESIZE_LANDMARKS', { feature: 'Map' });
            }
            this.resizeIconHandler();
        } else {
            this.validateFilterAndResize('resize', { clusterType: 'landmarks' });
        }
    }

    resizeAsset = () => {
        const { hideAssets } = this.state;
        this.isResizeAsset = true;
        this.isResizeLandmark = false;
        if (!hideAssets) {
            if (this.isResizeAsset) {
                analytics.track('RESIZE_ASSETS', { feature: 'Map' });
            }
            this.resizeIconHandler();
        } else {
            this.validateFilterAndResize('resize', { clusterType: 'assets' });
        }
    }

    findClusterExplodePoint = (cluster, explodeClusterType) => {
        this.cluster = cluster;
        this.previousBoundPoint = {
            topLeftLng: cluster.bounds.topLeft.x,
            topLeftLat: cluster.bounds.topLeft.y,
            bottomRightLng: cluster.bounds.bottomRight.x,
            bottomRightLat: cluster.bounds.bottomRight.y,
            count: cluster.count,
            explodeClusterType,
        };
        this.explodeClusterType = explodeClusterType;

        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend({ lat: cluster.bounds.topLeft.y, lng: cluster.bounds.topLeft.x });
        bounds.extend({ lat: cluster.bounds.bottomRight.y, lng: cluster.bounds.bottomRight.x });

        if (!isEmpty(this.map) && typeof this.map.fitBounds === 'function') {
            this.map.fitBounds(bounds);
        }

        if (explodeClusterType === 'assets') {
            analytics.track('OPEN_ASSET_CLUSTER', { feature: 'Map' });
        } else {
            analytics.track('OPEN_LANDMARK_CLUSTER', { feature: 'Map' });
        }
    }

    onRouteChange = () => {
        const { markers } = this.state;
        markers.data = [];
        this.setState({ markers });
    }

    showClusterInfo = (clusterName, clusterID) => {
        this.isAssetLandmarkSelected = true;
        if (clusterName === 'landmark') {
            const url = `/home?landmarkId=${clusterID}&zoom=false`;
            this.props.history.push(url);
        } else if (clusterName === 'asset') {
            const url = `/home?assetId=${clusterID}&zoom=false`;
            this.props.history.push(url);
        }
        this.handleShowHideDrawer();
    }

    hideAssetInfo = () => this.setState({ showAssetInfo: false });

    hideLandmarkInfo = () => this.setState({ showLandmarkInfo: false });

    redirectTo = (action, clickedOn) => {
        const redirectURL = action.split('/');
        if (clickedOn === 'editAsset' || clickedOn === 'editLandmark') {
            if ((Object.keys(this.map).length > 0) && typeof this.map.getZoom === 'function') {
                const map = {
                    zoom: this.map.getZoom(),
                    center: this.map.getCenter(),
                    hashPoints: {},
                };

                if (this.hashPoints && this.hashPoints.top) {
                    map.hashPoints = this.hashPoints;
                }
                setSessionStorageItem(MAP_ZOOM_AND_CENTER_SESSION_NAME, JSON.stringify(map) || '');
            }

            setSessionStorageItem(
                selectedItemKey,
                JSON.stringify({ clusterID: this.state.clusterID, type: clickedOn }) || '',
            );
        }

        /**
         * need to zoom back to landmark.
         * when user clicks on asset in the landmark and
         * comes back by clicking back button on asset detail page
         * so added zoom into the url before going to next page.
         * */
        if (clickedOn === 'assetInLandmark') {
            const queryParams = queryString.parse(this.props.location.search);
            queryParams.zoom = true;
            const newQueryParams = queryString.stringify(queryParams);
            this.props.history.replace(`?${newQueryParams}`);
        }
        this.props.history.push(action);
        if (redirectURL[3] === 'nearest-assets') {
            analytics.track('NEAREST_ASSETS', { feature: 'Map' });
        }
    }

    /**
     * setActiveTab is used to set active tab
     * */
    setActiveTab = (tab) => {
        this.props.history.push(`/assets/${this.props.assetDetails.assetId}/${tab}`);
    }

    showKml = () => this.setState({ showKml: true, clusterType: 'kml', showFilterPanel: true });

    handleKmlBackBtnClick = () => {
        let stateDataToUpdate = { clusterType: 'asset-list' };
        if (this.comingFromZoomedAsset && this.props.history.action === 'REPLACE') {
            stateDataToUpdate = { ...stateDataToUpdate, showFilterPanel: true };
            this.comingFromZoomedAsset = false;
        }
        this.setState(stateDataToUpdate);
    }

    refreshData = (zoomLevel, mapCenter, isRefresh = true) => {
        this.zoomLevel = zoomLevel;
        this.mapCenter = mapCenter;
        this.hashPoints = this.getCurrentMapBounds();
        this.getData();

        if (this.state.drawerVisible && isRefresh) {
            if (this.state.clusterType === 'landmark' && this.state.showLandmarkInfo) {
                this.refreshLandmarkPage(true);
            } else if (this.state.clusterType === 'asset' && this.state.showAssetInfo) {
                this.props.fetchAssetsDetails(this.state.clusterID);
            } else if (this.state.clusterType === 'kml' && this.state.showKml) {
                this.refreshKmlPage(true);
            } else if (this.state.clusterType === 'asset-list') {
                this.showHideMapAssetListRefreshChip(true);
            }
        }
    }

    updateKmlPreferenceUrls = (kmlPreferenceUrls, kmlPreferenceMethodValue = 'put') => {
        let { kmlPreferenceMethod } = this.state;
        if (kmlPreferenceUrls) {
            kmlPreferenceMethod = kmlPreferenceMethodValue;
        }
        this.setState({ kmlPreferenceUrls: kmlPreferenceUrls || [], kmlPreferenceMethod });
    }

    updateZoomToKmlLayer = zoomToKmlLayer => this.setState({ zoomToKmlLayer });

    updateMapCenterWithZoomLevel = (mapProps: Object) => {
        if (mapProps.zoomLevel) {
            this.zoomLevel = mapProps.zoomLevel;
        }

        if (mapProps.lat || mapProps.lng) {
            this.mapCenter = {
                lat: () => mapProps.lat,
                lng: () => mapProps.lng,
            };
            this.setState({
                coordinates: {
                    lat: mapProps.lat,
                    lng: mapProps.lng,
                },
            });
        }
    }

    render() {
        const {
            coordinates, showBackButton, showFilterPanel, clusterType,
        } = this.state;

        if (this.mapCenter) {
            coordinates.lat = this.mapCenter.lat();
            coordinates.lng = this.mapCenter.lng();
        }

        if (!this.props.loadingCluster) {
            this.isResizeLandmark = false;
            this.isResizeAsset = false;
        }
        return (
            <React.Fragment>
                <MiniDrawer redirectTo={this.props.history.push}>
                    <div key="home-main-map-container" className={customStyles['home-content-wrapper']}>
                        <MapsWrapper
                            key="home-main-map"
                            markers={this.state.markers}
                            showKml={this.showKml}
                            refreshData={this.refreshData}
                            coordinates={coordinates}
                            getBoundaries={(boundary) => { this.getBoundary(boundary); }}
                            boundaryChanged={() => this.boundaryChanged()}
                            zoom={this.zoomLevel || 0}
                            setCenter={(center) => { this.setCenter(center); }}
                            center={this.state.center}
                            hideLandmark={flag => this.showHideLandmark(flag)}
                            hideAsset={flag => this.showHideAsset(flag)}
                            showClusterInfo={this.showClusterInfo}
                            findClusterExplodePoint={(cluster, explodeClusterType) =>
                                this.findClusterExplodePoint(
                                    cluster,
                                    explodeClusterType,
                                )
                            }
                            kmlPreferenceUrls={this.state.kmlPreferenceUrls}
                            zoomToKmlLayer={this.state.zoomToKmlLayer}
                            isLoaded={this.isLoaded}
                            appliedFilters={this.filterGetterAndSetter('get-object')}
                            resizeAsset={this.resizeAsset}
                            resizeLandmark={this.resizeLandmark}
                            viewTraffic="true"
                            viewAssetLabel="true"
                            viewLandmarkLabel="true"
                            showAssetClusteringOption={this.state.showAssetClusteringOption}
                            showLandmarkClusteringOption={this.state.showLandmarkClusteringOption}
                            manageClustering={(assetClustering, landmarkClustering) => {
                                this.setState({ assetClustering, landmarkClustering }, () => {
                                    this.getData();
                                });
                            }}
                            bookmarkClick={(bookmark: Object) =>
                                this.updateMapCenterWithZoomLevel(bookmark)}
                            onZoomChanged={(bookmark: Object) =>
                                this.updateMapCenterWithZoomLevel(bookmark)}
                        />
                        <div>
                            {showFilterPanel &&
                                <ClusterFilter
                                    filterPanelItemOnClick={this.filterPanelItemOnClick}
                                    assetPointsCount={this.state.assetPointsCount}
                                    loadingCluster={this.props.loadingCluster}
                                    appliedFilters={this.filterGetterAndSetter('get-object')}
                                    disableFilters={(f) => {
                                        this.disableFilters = f;
                                    }}
                                />
                            }
                            {this.state.drawerVisible &&
                                <RightDrawer
                                    innerRef={(instance) => { this.drawer = instance; }}
                                    withTopFilter={showFilterPanel}
                                    showDrawer={this.isRightDrawerOpen}
                                    toggleDrawer={this.saveRightDrawerState}
                                >
                                    {clusterType === 'asset-list' &&
                                        <div className="app">
                                            <MapAssetList
                                                appliedFiltersObj={this.filterGetterAndSetter('get-object')}
                                                showHideMapAssetListRefreshChip={(f) => {
                                                    this.showHideMapAssetListRefreshChip = f;
                                                }}
                                                disableFilters={this.disableFilters}
                                            />
                                        </div>
                                    }
                                    {clusterType === 'landmark' && this.state.showLandmarkInfo &&
                                        <div className="app">
                                            <LandmarkDetails
                                                key={this.state.clusterID}
                                                landmarkID={this.state.clusterID}
                                                redirectTo={this.redirectTo}
                                                refreshLandmarkPage={(f) => {
                                                    this.refreshLandmarkPage = f;
                                                }}
                                                landmarksDataLoadedCallback={
                                                    this.landmarksDataLoadedCallback
                                                }
                                                hideRightDrawer
                                                isMapLoaded={this.state.isMapLoaded}
                                                showBackButton={showBackButton}
                                            />
                                        </div>
                                    }
                                    {clusterType === 'asset' && this.state.showAssetInfo &&
                                        <div className="app">
                                            <AssetDetails
                                                assetsData={this.props.assetDetails}
                                                setActiveTab={this.setActiveTab}
                                                isLoader={this.props.assetLoading}
                                                assetDataLoadedCallback={
                                                    this.assetDataLoadedCallback
                                                }
                                                showBackButton={showBackButton}
                                                redirectTo={this.redirectTo}
                                                {...this.props}
                                            />
                                        </div>
                                    }
                                    {clusterType === 'kml' && this.state.showKml &&
                                        <div className="app">
                                            <KmlDetails
                                                refreshKmlPage={(f) => {
                                                    this.refreshKmlPage = f;
                                                }}
                                                kmlPreference={this.state.kmlPreferenceUrls || []}
                                                updateKmlPreferenceUrls={
                                                    this.updateKmlPreferenceUrls
                                                }
                                                kmlPreferenceMethod={this.state.kmlPreferenceMethod}
                                                zoomToKmlLayer={this.updateZoomToKmlLayer}
                                                showBackButton={showBackButton}
                                                handleKmlBackBtnClick={this.handleKmlBackBtnClick}
                                            />
                                        </div>
                                    }
                                </RightDrawer>
                            }
                        </div>
                    </div>
                </MiniDrawer>
                {this.props.kmlLoading && <AppLoader type="fullScreen" />}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    assetPoints: state.home.assetPoints,
    landmarkPoints: state.home.landmarkPoints,
    assetPointsCount: state.home.assetPointsCount,
    landmarkPointsCount: state.home.landmarkPointsCount,
    assetDetails: state.assets.assetsDetails,
    landmarkExplodePoints: state.home.landmarkExplodePoints,
    assetExplodePoints: state.home.assetExplodePoints,
    loadingCluster: state.home.loadingCluster,
    assetLoading: state.assets.isLoader,
    kmlLoading: state.Kml.kmlLoading,
});

const mapDispatchToProps = {
    ...actions,
    ...landmarkActions,
    ...assetActions,
    ...notificationActions,
};

export default reduxConnect(Home, mapDispatchToProps, mapStateToProps);
