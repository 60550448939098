import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';

import { Switch } from '@mui/material';

import { getLocalStorageValue, setLocalStorageItem, saveUserPreference, getUserPreference } from './../../helper-classes/utility-functions';
// import { MAP } from 'react-google-maps/lib/constants';
import config from './../../constants/Config';
import analytics from '../../analytics/index';

import AppOffline from './../../components/SharedComponents/Offline/Offline';

type Props = {
    refreshData: Function,
    classes : Object,
    resetRefreshTimer: boolean,
    trackAnalytics: Function,
}

type State = {
    counter: Number,
}

const styles = () => ({
    root: {
        marginLeft: -12,
        display: 'flex',
    },
    maptypeControlCustom: {
        fontFamily: 'Roboto',
        fontSize: '18px',
        fontWeight: '500',
        color: '#000000',
        display: 'contents',
    },
    mapTimer: {
        opacity: '0.63',
        color: '#000000',
        fontWeight: 'normal',
        fontFamily: 'Roboto',
        fontSize: 18,
    },
});

const refreshTimeInSec = config.get('MAP_REFRESH_TIME_IN_SEC');
const inactiveTimeInMinute = config.get('MAP_REFRESH_INACTIVE_TIME_IN_MINS');
class CustomButton extends Component<Props, State> {
    timerId: any;
    inactiveTimerID: any;
    constructor(props) {
        super(props);
        this.state = {
            counter: refreshTimeInSec,
            mapAutoRefresh: (getLocalStorageValue('mapAutoRefresh') !== 'false'),
            eventListener: ['mousemove', 'keydown', 'wheel', 'DOMMouseScroll', 'mousedown', 'touchstart', 'MSPointerDown', 'MSPointerMove'],
        };
    }

    UNSAFE_componentWillMount() {
        if (!getLocalStorageValue('mapConfigSaveMethod')) {
            this.setUserPreference();
        } else if (this.state.mapAutoRefresh) {
            this.startTimer();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.resetRefreshTimer && nextProps.resetRefreshTimer) {
            this.setState({ counter: refreshTimeInSec });
        }
    }

    componentWillUnmount() {
        this.clearTimerAndListener();
    }

    clearTimer = () => {
        if (this.timerId) {
            clearInterval(this.timerId);
            this.timerId = null;
        }
        if (this.inactiveTimerID) {
            clearTimeout(this.inactiveTimerID);
            this.inactiveTimerID = null;
        }
    }

    clearTimerAndListener = () => {
        this.clearTimer();
        const { eventListener } = this.state;
        eventListener.forEach((listener) => {
            window.removeEventListener(listener, this.resetTimer, false);
        });
    }

    setUserPreference = () => {
        getUserPreference('fleetLocate.mapConfig').then((response) => {
            setLocalStorageItem('mapConfigSaveMethod', 'post');
            if (response && response.value) {
                const mapConfigValues = JSON.parse(response.value);
                if (mapConfigValues) {
                    setLocalStorageItem('mapConfigSaveMethod', 'put');
                    const { mapAutoRefresh } = mapConfigValues;
                    setLocalStorageItem('mapAutoRefresh', mapAutoRefresh);
                    if (mapAutoRefresh === 'true') {
                        this.startTimer();
                        this.setState({ mapAutoRefresh: true });
                    } else {
                        this.setState({ mapAutoRefresh: false });
                    }
                }
            }
        });
    }

    startInactiveTimer = () => {
        this.inactiveTimerID = window.setTimeout(this.goInactive, 1000 * 60 * inactiveTimeInMinute);
    }

    resetTimer = () => {
        clearTimeout(this.inactiveTimerID);
        this.goActive();
    }

    goInactive = () => {
        this.WindowIsInactive = true;
    }

    goActive = () => {
        this.startInactiveTimer();
        this.WindowIsInactive = false;
    }

    startTimer = () => {
        if (!this.state.mapAutoRefresh) {
            this.clearTimer();
            return;
        }
        const { eventListener } = this.state;
        eventListener.forEach((listener) => {
            window.addEventListener(listener, this.resetTimer, false);
        });
        this.startInactiveTimer();
        this.timerId = setInterval(() => {
            const { counter } = this.state;
            if (counter > 0) {
                this.setState({ counter: counter - 1 });
            } else if (counter === 0 && !this.WindowIsInactive) {
                this.refreshData();
            }
        }, 1000);
    }

    refreshData = () => {
        this.props.refreshData();
        this.setState({ counter: refreshTimeInSec });
    }

    handleChange = (event) => {
        const configData = {
            config: {
                mapTrafficLayer: getLocalStorageValue('mapTrafficLayer'),
                mapAutoRefresh: `${event.target.checked}`,
            },
            method: getLocalStorageValue('mapConfigSaveMethod'),
        };
        const params = {
            feature: 'Map',
            autoRefresh: event.target.checked ? 'On' : 'Off',
        };

        if (event.nativeEvent && event.nativeEvent.view &&
            event.nativeEvent.view.location && event.nativeEvent.view.location.pathname === '/home') {
            analytics.track('MAP_AUTO_REFRESH', params);
        }
        saveUserPreference('fleetLocate.mapConfig', configData);
        this.setState({
            counter: refreshTimeInSec,
            mapAutoRefresh: event.target.checked,
        }, () => {
            if (this.state.mapAutoRefresh) {
                this.startTimer();
                setLocalStorageItem('mapAutoRefresh', 'true');
            } else {
                this.clearTimerAndListener();
                setLocalStorageItem('mapAutoRefresh', 'false');
            }
            this.props.trackAnalytics({ mapAutoRefresh: this.state.mapAutoRefresh });
        });
    }

    checkOnline = (online) => {
        if (online) {
            this.startTimer();
        } else {
            this.clearTimerAndListener();
        }
    }

    render() {
        const { classes } = this.props;
        const { mapAutoRefresh } = this.state;
        return (
            <React.Fragment>
                <AppOffline onChange={online => this.checkOnline(online)} />
                <div className={classes.root}>
                    <Switch
                        checked={mapAutoRefresh}
                        onChange={e => this.handleChange(e)}
                        color="primary"
                        name="mapAutoRefresh"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        sx={{
                            '&.MuiSwitch-root': {
                                padding: '12px 14px 12px 12px',
                                width: 60,
                            },
                            '& .MuiSwitch-thumb': {
                                marginLeft: '3px',
                            },
                            '& .Mui-checked': {
                                marginLeft: '-6px',
                            },
                        }}
                    />
                    <div
                        title="Map Auto-Refresh"
                    >
                        <span className={classes.maptypeControlCustom}>Map Auto-Refresh:  </span>
                        <span className={classes.mapTimer}>&nbsp;{(mapAutoRefresh) ? `${this.state.counter}sec` : 'Off'}</span>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(CustomButton);
