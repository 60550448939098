/* flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Stepper, Step, StepLabel, Button, Typography, Divider } from '@mui/material';
import styles from './AlertStepper.module.scss';
import AlertSelect from '../../../containers/Alerts/Steppers/AlertSelect';
import AlertAssestCriteria from '../../SharedComponents/AssetCriteria/AssetCriteria';
import AlertRecipientList from '../../../containers/Alerts/AlertRecipient/AlertRecipientList';
import AlertScheduler from './AlertScheduler';
import { ALERT, POWER_DISCONNECT_ASSET_FILTER, STEPS } from '../../../containers/Alerts/constants.alerts';
import AppLoader from '../../../components/AppLoader';
import { getPermissionValue } from '../../../helper-classes/utility-functions';
import analytics from '../../../analytics/index';

type Props = {
    editAlert: Boolean,
    alertType: String,
    formData: any,
    typeId: Number,
    alertNameCheckInProgress: Boolean,
    alertNameError: String,
    validationError: String,
    handleInputChange: Function,
    handleSubmit: Function,
    onCancel: Function,
    onAlertNameChange: Function,
    handleCheckboxChange: Function,
    handleTextboxChange: Function,
    handleAlertTypeChange: Function,
    setAlertId: Function,
    setAlertType: Function,
    getScheduleJson: Function,
    getRecipientJson: Function,
    recipientData: any,
    clearLandmarkSelects: Function,
    onAssetCriteriaChanged: Function,
    assetCriteria: any,
};

const stepstyles = theme => ({
    container: {
        width: '75%',
        height: 700,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    instructions: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(),
    },
});

function getSteps(alertType) {
    if (alertType === 'maintenanceDtcAlert') {
        return [STEPS.STEP1, STEPS.STEP2, STEPS.STEP3];
    }
    return [STEPS.STEP1, STEPS.STEP2, STEPS.STEP3, STEPS.STEP4];
}

class AlertStepper extends Component<Props> {
    state = {
        activeStep: 0,
        scheduleUnChecked: true,
    };

    handleInputChange = e => this.props.handleInputChange(e);

    handleAlertTypeChange = (alertType, typeId) => {
        this.setState({ scheduleUnChecked: true });
        this.props.handleAlertTypeChange(alertType, typeId);
    };

    handleSubmit = () => {
        this.props.handleSubmit();
        let assetCrit = '';
        const { type } = this.props.assetCriteria || {};
        if (type === 'groups-tags') {
            assetCrit = 'Select Groups/Tags';
        } else if (type === 'custom') {
            assetCrit = 'Custom Select';
        } else assetCrit = 'All Assets';
        const params = {
            feature: 'Alerts',
            alertType: this.props.alertType,
            alertName: this.props.formData.name,
            assetCriteria: assetCrit,
            alertSchedule: this.state.scheduleUnChecked ? 'false' : 'true',
        };
        if (this.props.editAlert) {
            analytics.track('EDIT_ALERT', params);
        } else analytics.track('ADD_ALERT', params);
    };

    handleNext = () => {
        const { activeStep } = this.state;
        this.setState({ activeStep: activeStep + 1 });
        if (this.state.activeStep === getSteps(this.props.alertType).length - 1) {
            this.handleSubmit();
        }
    };

    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({ activeStep: activeStep - 1 });
    };

    onAssetCriteriaChanged = assetsData => this.props.onAssetCriteriaChanged(assetsData);

    uncheckSchedule = status => this.setState({ scheduleUnChecked: status });

    getStepContent = (step) => {
        const filter = [];
        const { formData, alertType } = this.props;
        if (alertType === ALERT.DEVICE_POWER_DISCONNECT_ALERT.CODE) {
            filter.push(POWER_DISCONNECT_ASSET_FILTER);
        }
        switch (step) {
        case 0:
            return (
                <div className={styles['step-content']}>
                    <AlertSelect
                        alertType={alertType}
                        formData={formData}
                        onAlertNameChange={this.props.onAlertNameChange}
                        handleCheckboxChange={this.props.handleCheckboxChange}
                        handleTextboxChange={this.props.handleTextboxChange}
                        handleAlertTypeChange={this.handleAlertTypeChange}
                        setAlertId={this.props.setAlertId}
                        setAlertType={this.props.setAlertType}
                        alertNameError={this.props.alertNameError}
                        validationError={this.props.validationError}
                        typeId={this.props.typeId}
                        clearLandmarkSelects={this.props.clearLandmarkSelects}
                        alertNameCheckInProgress={this.props.alertNameCheckInProgress}
                    />
                </div>
            );
        case 1:
            return (
                <div className={styles['step-content']}>
                    <AlertAssestCriteria
                        onAssetCriteriaChanged={this.onAssetCriteriaChanged}
                        assetCriteria={this.props.assetCriteria}
                        assetFilter={filter}
                        isGrailsAPIReq
                        isAlert
                    />
                </div>
            );
        case 2:
            return (
                <div className={styles['step-content']}>
                    <AlertRecipientList
                        onChange={this.handleInputChange}
                        formData={formData}
                        recipientData={this.props.recipientData}
                        getRecipientJson={this.props.getRecipientJson}
                    />
                </div>
            );
        case 3:
            return (
                <div className={styles['step-content']}>
                    <AlertScheduler
                        scheduleUnChecked={this.state.scheduleUnChecked}
                        uncheckSchedule={this.uncheckSchedule}
                        getScheduleJson={this.props.getScheduleJson}
                        alertType={alertType}
                        formData={formData}
                    />
                </div>
            );
        default:
            return <div className={styles['step-content']} />;
        }
    };

    render() {
        const {
            formData, editAlert, onCancel, assetCriteria,
        } = this.props;
        const steps = getSteps(this.props.alertType);
        const { activeStep } = this.state;
        const schedulesPresent = formData && formData.alertSchedulesJSON
            && formData.alertSchedulesJSON.length > 2;

        if (editAlert && schedulesPresent) this.state.scheduleUnChecked = false;

        let buttonName = STEPS.NEXT;
        if (activeStep === steps.length - 1) {
            buttonName = editAlert ? STEPS.UPDATE : STEPS.SAVE;
        }

        const isNextBtnDisabled = getPermissionValue('Alerts') !== 'Modify' ||
        ((editAlert && formData.configMap !== undefined) ||
        (activeStep === 0 && (this.props.alertNameCheckInProgress || this.props.alertNameError !== '' || this.props.validationError !== '')) ||
        (activeStep === 1 && (assetCriteria.type === 'custom' && assetCriteria.assets.length === 0)) ||
        (activeStep === 1 && (assetCriteria.type === 'groups-tags' && assetCriteria.groups.length === 0 && assetCriteria.tags.length === 0)) ||
        (activeStep === 3 && (!this.state.scheduleUnChecked && !schedulesPresent)));

        return (
            <div>
                <div className={styles['step-container']}>
                    <div className={styles['step-header']}>
                        <button
                            id="backArrow"
                            className={styles['no-focus']}
                            style={{ textDecoration: 'none' }}
                            onClick={onCancel}
                        >
                            <i className={styles['back-icon']} />
                            <span>
                                &nbsp;&nbsp;
                                {editAlert ? STEPS.EDIT_ALERT : STEPS.ADD_ALERT}
                            </span>
                        </button>
                    </div>
                    <Stepper activeStep={activeStep}>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel id={label.replace(/\s+/g, '')}>
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Divider className={styles['divider-shadow']} />
                    <div>
                        {activeStep === steps.length ? (
                            <div>
                                <AppLoader
                                    loaderStyle={{
                                        position: 'absolute',
                                        left: '50%',
                                        top: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        'text-align': 'center',
                                    }}
                                />
                            </div>
                        ) : (
                            <div>
                                {this.getStepContent(activeStep)}
                                <div>
                                    <Divider className={styles['divider-shadow']} />
                                    {activeStep === 0 && (
                                        <div className={styles['step-req']}>
                                            {STEPS.REQ_FIELD}
                                        </div>
                                    )}
                                    <Typography
                                        component="div"
                                        align="right"
                                        className={styles['button-container']}
                                    >
                                        <Button
                                            id="cancelBtn"
                                            color="primary"
                                            onClick={onCancel}
                                            className={stepstyles.button}
                                        >
                                            {STEPS.CANCEL}
                                        </Button>
                                        {activeStep > 0 && (
                                            <Button
                                                id="backBtn"
                                                variant="contained"
                                                color="primary"
                                                disabled={activeStep <= 0}
                                                onClick={this.handleBack}
                                                className={styles['back-button']}
                                            >
                                                {STEPS.BACK}
                                            </Button>
                                        )}
                                        <Button
                                            id="nextBtn"
                                            variant="contained"
                                            color="primary"
                                            disabled={isNextBtnDisabled}
                                            onClick={this.handleNext}
                                        >
                                            {buttonName}
                                        </Button>
                                    </Typography>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(stepstyles)(AlertStepper);
