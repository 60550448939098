/* @flow */
import { FETCH_SCORECARDS_LIST_ERROR, FETCH_SCORECARDS_LIST_SUCCESS, FETCH_SCORECARDS_LIST, RESET_SCORECARDS_LIST } from './constants.scorecardsList';

const initialState = {
    scorecards: [],
    isUpdating: false,
};

type stateType = {
    scorecards: Array<Object>,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        scorecards:{},
        totalScorecards: number
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_SCORECARDS_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case FETCH_SCORECARDS_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            scorecards: action.payload.scorecards,
            totalScorecards: action.payload.totalScorecards,
        };
    case FETCH_SCORECARDS_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    case RESET_SCORECARDS_LIST:
        return {
            ...state,
            isUpdating: false,
            scorecards: [],
            totalScorecards: 0,
        };
    default:
        return state;
    }
};
