/* @flow */
import {
    FETCH_LANDMARKS,
    FETCH_LANDMARKS_SUCCESS,
    FETCH_LANDMARKS_ERROR,
    FETCH_ASSET_IN_LANDMARK,
    FETCH_ASSET_IN_LANDMARK_SUCCESS,
    FETCH_ASSET_IN_LANDMARK_ERROR,
} from './constants.landmarks';

export const initialState = {
    landmarks: {},
    isLoader: false,
    assetInLandmark: [],
    assetLoader: true,
};

type stateType = {
    landmarks: {},
    isLoader: false,
    assetInLandmark: Array<{}>,
}
type actionType = {
    type: string,
    payload :{
        response:{},
        isLoader: boolean,
        showLoader: boolean,
        landmarks: {},
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_LANDMARKS: {
        const assetInLandmark = action.payload.showLoader ? [] : state.assetInLandmark;
        return {
            ...state,
            isLoader: action.payload.showLoader,
            landmarks: {},
            assetInLandmark,
            assetDetails: {},
            directions: {},
            directionsDetails: {},
        };
    }
    case FETCH_LANDMARKS_SUCCESS:
        return {
            ...state,
            landmarks: action.payload.response,
            isLoader: false,
            assetDetails: {},
            directions: {},
            directionsDetails: {},
        };
    case FETCH_LANDMARKS_ERROR:
        return {
            ...state,
            isLoader: false,
            landmarks: {},
            assetDetails: {},
            directions: {},
            directionsDetails: {},
            assetLoader: false,
        };
    case FETCH_ASSET_IN_LANDMARK: {
        const assetInLandmark = action.payload.showLoader ? [] : state.assetInLandmark;
        return {
            ...state,
            assetLoader: action.payload.showLoader,
            assetInLandmark,
        };
    }
    case FETCH_ASSET_IN_LANDMARK_SUCCESS:
        return {
            ...state,
            assetInLandmark: action.payload.response || [],
            assetLoader: false,
            isLoader: false,
        };
    case FETCH_ASSET_IN_LANDMARK_ERROR:
        return {
            ...state,
            landmarks: {},
            assetInLandmark: [],
            assetLoader: false,
            isLoader: false,
        };
    default:
        return state;
    }
};
