import React from 'react';
import TextField from '@mui/material/TextField';
import styles from '../../Steppers/AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';
import {
    getRoundOffTemp,
    getTemperatureUnitString,
    revTempAsPerUserSettings,
} from '../../../../helper-classes/utility-functions';

type Props = {
    formData: {
        config: {
            criticalMinTemperature: Number,
            criticalMaxTemperature: Number,
            cautionMinTemperature: Number,
            cautionMaxTemperature: Number,
            rampTime: Number,
        },
    },
    handleTextboxChange: Function,
};

class TemperatureAlert extends React.Component<Props> {
    state = {
        unit: '',
    };
    UNSAFE_componentWillMount() {
        const unit = getTemperatureUnitString();
        this.setState({
            unit,
        });
    }

    render() {
        const { config } = this.props.formData;
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    <div className={styles['step-rightside']}>
                        <form noValidate autoComplete="off">
                            <div>
                                {ALERT_DETAILS.LABEL.TEMP_CRITICAL_MIN}
                                <div>
                                    <TextField
                                        required
                                        margin="normal"
                                        className={styles['text-wid']}
                                        id="criticalMinTemperature"
                                        name="criticalMinTemperature"
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: ALERT_DETAILS.VALUE.CRT_MIN_MIN,
                                                max: ALERT_DETAILS.VALUE.CRT_MIN_MAX,
                                            },
                                        }}
                                        value={getRoundOffTemp(config.criticalMinTemperature)}
                                        onChange={(e) => {
                                            this.props.handleTextboxChange('criticalMinTemperature')({
                                                target: {
                                                    value: e.target.value !== '' ? revTempAsPerUserSettings(e.target.value) : e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                    <span className={styles['unit-Name']}>{this.state.unit}*</span>
                                </div>
                                <div className={styles['div-pad']}>{ALERT_DETAILS.LABEL.TEMP_CRITICAL_MAX}</div>
                                <div>
                                    <TextField
                                        required
                                        margin="normal"
                                        className={styles['text-wid']}
                                        id="criticalMaxTemperature"
                                        name="criticalMaxTemperature"
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: ALERT_DETAILS.VALUE.CRT_MAX_MIN,
                                                max: ALERT_DETAILS.VALUE.CRT_MAX_MAX,
                                            },
                                        }}
                                        value={getRoundOffTemp(config.criticalMaxTemperature)}
                                        onChange={(e) => {
                                            this.props.handleTextboxChange('criticalMaxTemperature')({
                                                target: {
                                                    value: e.target.value !== '' ? revTempAsPerUserSettings(e.target.value) : e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                    <span className={styles['unit-Name']}>{this.state.unit}*</span>
                                </div>
                                <div className={styles['div-pad']}>{ALERT_DETAILS.LABEL.TEMP_CAUTION_MIN}</div>
                                <div>
                                    <TextField
                                        required
                                        margin="normal"
                                        className={styles['text-wid']}
                                        id="cautionMinTemperature"
                                        name="cautionMinTemperature"
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: ALERT_DETAILS.VALUE.CUT_MIN_MIN,
                                                max: ALERT_DETAILS.VALUE.CUT_MIN_MAX,
                                            },
                                        }}
                                        value={getRoundOffTemp(config.cautionMinTemperature)}
                                        onChange={(e) => {
                                            this.props.handleTextboxChange('cautionMinTemperature')({
                                                target: {
                                                    value: e.target.value !== '' ? revTempAsPerUserSettings(e.target.value) : e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                    <span className={styles['unit-Name']}>{this.state.unit}*</span>
                                </div>
                                <div className={styles['div-pad']}>{ALERT_DETAILS.LABEL.TEMP_CAUTION_MAX}</div>
                                <div>
                                    <TextField
                                        required
                                        margin="normal"
                                        className={styles['text-wid']}
                                        id="cautionMaxTemperature"
                                        name="cautionMaxTemperature"
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: ALERT_DETAILS.VALUE.CUT_MAX_MIN,
                                                max: ALERT_DETAILS.VALUE.CUT_MAX_MAX,
                                            },
                                        }}
                                        value={getRoundOffTemp(config.cautionMaxTemperature)}
                                        onChange={(e) => {
                                            this.props.handleTextboxChange('cautionMaxTemperature')({
                                                target: {
                                                    value: e.target.value !== '' ? revTempAsPerUserSettings(e.target.value) : e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                    <span className={styles['unit-Name']}>{this.state.unit}*</span>
                                </div>
                                <div className={styles['div-pad']}>{ALERT_DETAILS.LABEL.TEMP_HOLD_TIME}</div>
                                <div>
                                    <TextField
                                        required
                                        margin="normal"
                                        className={styles['text-wid']}
                                        id="rampTime"
                                        name="rampTime"
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: ALERT_DETAILS.VALUE.RT_MIN,
                                                max: ALERT_DETAILS.VALUE.RT_MAX,
                                            },
                                        }}
                                        value={config.rampTime}
                                        onChange={this.props.handleTextboxChange('rampTime')}
                                    />
                                    <span className={styles['unit-Name']}> {ALERT_DETAILS.LABEL.TEMP_MINUTES}*</span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default TemperatureAlert;
