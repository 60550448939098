import React from 'react';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { getAppUserSettings, getTimeZones, getUnitsOfMeasurments, getTimeDisplays, getDefaultPages } from '../../helper-classes/utility-functions';
import * as actions from '../../containers/Settings/actions.settings';
import * as loginAction from '../../containers/Login/actions.login';
import { reduxConnect } from '../../hoc';
import AppLoader from '../AppLoader';

const styles = () => ({
    textField: {
        marginLeft: 20,
        marginRight: 20,
        width: 350,
    },
    menu: {
        width: 350,
    },
    title: {
        padding: 15,
        fontWeight: 400,
    },
    content: {
        paddingLeft: 15,
        paddingBottom: 10,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
    instruction: {
        paddingLeft: 15,
        paddingBottom: 10,
    },
    instructionText: {
        fontSize: 16,
    },
});

export type Props = {
    classes: Object,
    saveAppSettings: Function,
    savePreferences: Function,
    updateAppSettings: boolean,
    updatePreferences: boolean,
    getUserSettings: Function,
    hasAppsettings: boolean,
};

export type State = {
    firstName: string,
    username: string,
    lastName: string,
    email: string,
    phone: string,
    defaultPage: string,
    userTz: string,
    userUnits: string,
    timeDisplay: string,
    showLoader: boolean,
};

class EditAccountPopup extends React.Component<Props, State> {
    preferencesSaved: boolean;
    appSettingsSaved: boolean;
    userName: string;
    constructor(props: Props) {
        super(props);
        const userSettings = getAppUserSettings();
        this.userName = userSettings.username;
        this.state = {
            firstName: userSettings.fName,
            lastName: userSettings.lName,
            email: userSettings.email,
            phone: userSettings.phone,
            defaultPage: '',
            userTz: userSettings.userTz,
            userUnits: userSettings.userUnits || 'miles',
            timeDisplay: '',
            showLoader: false,
        };
        this.preferencesSaved = false;
        this.appSettingsSaved = false;
    }
    componentDidMount() {
        ValidatorForm.addValidationRule('isValidPhoneNumber', (value) => {
            if (typeof value === 'string' && value.length > 0) {
                // eslint-disable-next-line
                return value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g);
            }
            return true;
        });
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.updatePreferences) {
            this.preferencesSaved = true;
        }
        if (nextProps.updateAppSettings) {
            this.appSettingsSaved = true;
        }
        if (this.appSettingsSaved && this.preferencesSaved) {
            this.appSettingsSaved = false;
            this.preferencesSaved = false;
            this.setState({ showLoader: false });
            this.props.getUserSettings();
        }
        if (nextProps.hasAppsettings) {
            window.location.reload();
        }
    }

    handleChange(key: string, value: string) {
        this.setState({ [key]: value });
    }

    saveAccount = () => {
        this.setState({ showLoader: true });
        this.props.saveAppSettings({
            defaultPage: this.state.defaultPage,
            tz: '',
            tzDST: 0,
            tzOffset: 0,
            userTz: this.state.userTz,
            userUnits: this.state.userUnits,
            timeDisplay: this.state.timeDisplay,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
        });
        this.props.savePreferences({
            defaultPage: this.state.defaultPage,
            tzDST: 0,
            tzOffset: 0,
            tz: '',
            units: this.state.userUnits,
            timeDisplay: this.state.timeDisplay,
        });
    }

    render() {
        const {
            classes,
        } = this.props;
        return (
            <Dialog
                open
                onClose={this.handleClose}
                aria-labelledby="responsive-dialog-title"
                disableEscapeKeyDown
                maxWidth={false}
                style={{ width: '100%', height: '100%' }}
            >
                {this.state.showLoader &&
                    <AppLoader
                        loaderStyle={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                        }}
                    />
                }
                <ValidatorForm
                    autoComplete="off"
                    onSubmit={() => { this.saveAccount(); }}
                >
                    <DialogTitle className={classes.title}>Initial Login</DialogTitle>
                    <DialogContent className={classes.instruction} >
                        <DialogContentText className={classes.instructionText}>
                            Welcome! Please fill out the below
                            information to access the application.
                        </DialogContentText>
                    </DialogContent>
                    <DialogTitle className={classes.title}>
                        Account Details - {this.userName}
                    </DialogTitle>
                    <DialogContent className={classes.content}>
                        <TextValidator
                            value={this.state.firstName}
                            label="First Name*"
                            className={classes.textField}
                            margin="normal"
                            onChange={e => this.handleChange('firstName', e.target.value)}
                            validators={['required']}
                            errorMessages={['Please enter first name']}
                            name="firstName"
                            autoComplete="none"
                        />
                        <TextValidator
                            value={this.state.lastName}
                            label="Last Name*"
                            className={classes.textField}
                            margin="normal"
                            onChange={e => this.handleChange('lastName', e.target.value)}
                            validators={['required']}
                            errorMessages={['Please enter last name']}
                            name="lastName"
                            autoComplete="none"
                        />
                        <TextValidator
                            value={this.state.email}
                            label="Email*"
                            className={classes.textField}
                            margin="normal"
                            onChange={e => this.handleChange('email', e.target.value)}
                            validators={['required', 'isEmail']}
                            errorMessages={['Please enter a valid email', 'Please enter a valid email']}
                            name="email"
                            autoComplete="none"
                        />
                        <TextValidator
                            value={this.state.phone}
                            label="Phone"
                            className={classes.textField}
                            margin="normal"
                            onChange={e => this.handleChange('phone', e.target.value)}
                            validators={['isValidPhoneNumber']}
                            errorMessages={['Please enter a valid phone number']}
                            name="phoneNumber"
                            autoComplete="none"
                        />
                    </DialogContent>
                    <DialogTitle className={classes.title}>Application Settings</DialogTitle>
                    <DialogContent className={classes.content}>
                        <SelectValidator
                            id="standard-select-currency"
                            label="Default Page"
                            className={classes.textField}
                            value={this.state.defaultPage}
                            onChange={e => this.handleChange('defaultPage', e.target.value)}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            name="defaultPage"
                        >
                            {getDefaultPages().map(page => (
                                <MenuItem value={page.value} key={page.title}>
                                    {page.title}
                                </MenuItem>
                            ))}
                        </SelectValidator>
                        <SelectValidator
                            id="standard-select-currency"
                            label="Units Of Measurement*"
                            className={classes.textField}
                            value={this.state.userUnits}
                            onChange={e => this.handleChange('userUnits', e.target.value)}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            name="userUnits"
                        >
                            {getUnitsOfMeasurments().map(unit => (
                                <MenuItem value={unit.value} key={unit.title}>
                                    {unit.title}
                                </MenuItem>
                            ))}
                        </SelectValidator>
                        <SelectValidator
                            id="standard-select-currency"
                            label="Time Zone*"
                            className={classes.textField}
                            value={this.state.userTz}
                            onChange={e => this.handleChange('userTz', e.target.value)}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            validators={['required']}
                            errorMessages={['Please select time zone']}
                            name="userTz"
                        >
                            {getTimeZones().map(timeZone => (
                                <MenuItem value={timeZone.value} key={timeZone.name}>
                                    {timeZone.name}
                                </MenuItem>
                            ))}
                        </SelectValidator>
                        <SelectValidator
                            id="standard-select-currency"
                            label="Time Display*"
                            className={classes.textField}
                            value={this.state.timeDisplay}
                            onChange={e => this.handleChange('timeDisplay', e.target.value)}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            validators={['required']}
                            errorMessages={['Please select time display']}
                            name="timeDisplay"
                        >
                            {getTimeDisplays().map(timeDisplay => (
                                <MenuItem value={timeDisplay.value} key={timeDisplay.title}>
                                    {timeDisplay.title}
                                </MenuItem>
                            ))}
                        </SelectValidator>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={this.state.showLoader}
                        >
                            SAVE
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        );
    }
}
const mapStateToProps = state => ({
    updateAppSettings: state.settings.updateAppSettings,
    isLoader: state.globalSearch.isLoader,
    updatePreferences: state.settings.updatePreferences,
    hasAppsettings: state.userSession.hasAppsettings,
});
const mapDispatchToProps = {
    ...actions,
    ...loginAction,
};
export default
withStyles(
    styles,
    { withTheme: true },
)(reduxConnect(EditAccountPopup, mapDispatchToProps, mapStateToProps));
