/* @flow */
export const ASSET_SEARCH: string = 'ASSET_SEARCH';
export const ASSET_SEARCH_ERROR: string = 'ASSET_SEARCH_ERROR';
export const ASSET_SEARCH_SUCCESS: string = 'ASSET_SEARCH_SUCCESS';

export type AssetSearchTypeAction = {
    type: string;
payload?: Object;
}

export const recordsPerPage = 10;

