/* @flow */

import { FETCH_SERVICE_PLANS_LIST, FETCH_SERVICE_PLANS_LIST_SUCCESS, FETCH_SERVICE_PLANS_LIST_ERROR, RESET_SERVICE_PLANS_LIST } from './constants.planList';
import type { PlanListTypeAction } from './constants.planList';

export const fetchPlanList = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filtersToSend: Array<{}>,
): PlanListTypeAction => ({
    type: FETCH_SERVICE_PLANS_LIST,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
        filtersToSend,
    },
});

export const fetchPlanListSuccess =
    (plans: Array<{}>, totalPlans: number): PlanListTypeAction => ({
        type: FETCH_SERVICE_PLANS_LIST_SUCCESS,
        payload: { plans, totalPlans },
    });

export const resetTable =
    (): PlanListTypeAction => ({
        type: RESET_SERVICE_PLANS_LIST,
    });

export const fetchPlanListError = (error: string): PlanListTypeAction => ({
    type: FETCH_SERVICE_PLANS_LIST_ERROR,
    payload: { error },
});
