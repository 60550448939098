/* @flow */

import * as constants from './constants.scoreCardTrips';
import type { DriverScorecardTripsAction } from './constants.scoreCardTrips';

export const fetchDriverScorecardTrips = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
    filters: Object,
): DriverScorecardTripsAction => ({
    type: constants.FETCH_DRIVER_SCORECARD_TRIPS,
    payload: {
        pageNumber, pageSize, order, orderBy, filters,
    },
});

export const fetchDriverScorecardTripsSuccess = (response: Object): DriverScorecardTripsAction => ({
    type: constants.FETCH_DRIVER_SCORECARD_TRIPS_SUCCESS,
    payload: { response },
});

export const fetchDriverScorecardTripsError = (error: Object): DriverScorecardTripsAction => ({
    type: constants.FETCH_DRIVER_SCORECARD_TRIPS_ERROR,
    payload: { error },
});

export const closeDialog = () => ({
    type: constants.CLOSE_DRIVER_SCORECARD_DIALOG,
    payload: {},
});

export const updateLoader = (loading: boolean) => ({
    type: constants.UPDATE_DRIVER_SCORECARD_LOADER,
    payload: { loading },
});

export const resetTable = (): DriverScorecardTripsAction => ({
    type: constants.RESET_DRIVER_SCORECARD_TRIPS,
});
