/* @flow */

import {
    FETCH_KMLS,
    FETCH_KMLS_SUCCESS,
    FETCH_KMLS_ERROR,
    DELETE_KMLS,
    DELETE_KMLS_SUCCESS,
    DELETE_KMLS_ERROR,
    CLOSE_KMLS_DIALOG,
    ADD_KML,
    ADD_KML_SUCCESS,
    ADD_KML_ERROR,
} from './constants.kml';
import type { KmlTypeAction } from './constants.kml';

export const fetchKml = (): KmlTypeAction => ({
    type: FETCH_KMLS,
    payload: {},
});

export const fetchKmlSuccess = (response: {}): KmlTypeAction => ({
    type: FETCH_KMLS_SUCCESS,
    payload: { response },
});

export const fetchKmlError = (error: string): KmlTypeAction => ({
    type: FETCH_KMLS_ERROR,
    payload: { error },
});

export const deleteKml = (id: string): KmlTypeAction => ({
    type: DELETE_KMLS,
    payload: { id },
});


export const deleteKmlSuccess = (response: {}): KmlTypeAction => ({
    type: DELETE_KMLS_SUCCESS,
    payload: { response },
});

export const deleteKmlError = (error: string): KmlTypeAction => ({
    type: DELETE_KMLS_ERROR,
    payload: { error },
});

export const closeDialog = (): KmlTypeAction => ({
    type: CLOSE_KMLS_DIALOG,
    payload: {},
});

export const addKml = (formData: any): KmlTypeAction => ({
    type: ADD_KML,
    payload: { formData },
});

export const addKmlSuccess = (response: any): KmlTypeAction => ({
    type: ADD_KML_SUCCESS,
    payload: { response },
});

export const addKmlError = (error: any): KmlTypeAction => ({
    type: ADD_KML_ERROR,
    payload: { error },
});
