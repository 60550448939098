/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Paper, Tooltip } from '@mui/material';
import { getParamValue, getData as getListData, isEmpty } from '../../../helper-classes/utility-functions';
import MiniDrawer from '../../../components/SideMenu/SideMenuDrawer';
import LogsListHeader from './LogsListHeader';
import ListComponent from '../../../components/SharedComponents/ListComponent/ListComponent';
import AppLoader from '../../../components/AppLoader';
import config, { RECORDS_PER_PAGE } from '../../../constants/Config';
import {
    isUpdatePreference,
    saveUserPreference,
    getUserPreference,
    getParsedPreferencesValue,
    getReArrangedTableHeaders,
    getColumnPreferences,
    rearrangeColumns,
} from './../../../helper-classes/listUtil';
import IMPORT_LOGS_ERROR_LIST from './constants.logsLIst';

const styles = () => ({
    app: {
        paddingTop: 17,
        paddingLeft: 17,
        paddingRight: 17,
        '& ul li > span': {
            fontSize: 13,
        },
    },
    root: {},
});

export type Props = {
    history: {
        action: string,
        push: Function,
        location: {
            key: string,
            state: Object,
        },
    },
    classes: Object,
    resetTable: Function,
};

export type State = {
    type: string,
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{}>,
        totalRecords: number,
    },
    reportHeaderData: Object,
    pageNumber: number,
    rowsPerPage: number,
    order: string,
    orderBy: string,
    jobId: string,
    loading: boolean,
    isUpdating: boolean,
};
class ImportReportDetails extends Component<Props, State> {
    userPreferences: any;
    locationKey: string;
    notificationCardClicked: boolean;

    constructor(props: Props) {
        super(props);
        this.state = this.initState(true);
        this.userPreferences = null;
        this.locationKey = '';
        this.notificationCardClicked = false;
    }

    UNSAFE_componentWillMount() {
        getUserPreference(this, IMPORT_LOGS_ERROR_LIST);
    }

    componentDidMount() {
    }

    componentDidUpdate() {
        const isValidRedirectHistoryAction = ['PUSH', 'REPLACE'].includes(this.props.history.action);
        if (isValidRedirectHistoryAction && this.props.history.location.key !== this.locationKey) {
            this.notificationCardClicked = true;
        }
        this.locationKey = this.props.history.location.key;

        if (isValidRedirectHistoryAction && this.notificationCardClicked) {
            this.getData(
                0, this.state.rowsPerPage, this.state.order,
                this.state.orderBy, true, true,
            );
            this.notificationCardClicked = false;
        }
    }

    initTableData = () => ({
        listData: [],
        totalRecords: 0,
        listHeaders: this.getListHeader(),
    })

    initState = isLoading => ({
        type: getParamValue(this.props, 'type'),
        jobId: getParamValue(this.props, 'jobId'),
        tableData: this.initTableData(),
        reportHeaderData: {
            cancelled: 0,
            failed: 0,
            pending: 0,
            success: 0,
            fileName: '',
            dateCreated: '',
            jobId: '',
        },
        pageNumber: 0,
        rowsPerPage: RECORDS_PER_PAGE,
        order: 'asc',
        orderBy: 'id',
        loading: isLoading,
        isUpdating: isLoading,
    })

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            {
                rowNumber: 'Row #',
                ...getColumnPreferences('rowNumber', preferences),
                disableSort: true,
            },
            {
                error: 'Error',
                ...getColumnPreferences('error', preferences),
                customMarkup: (data) => {
                    const error = data.error || { message: 'There is some problem with import' };
                    return error.message;
                },
                disableSort: true,
            },
            {
                errorDetail: 'Details',
                ...getColumnPreferences('errorDetail', preferences),
                customMarkup: this.getFormattedError,
                disableSort: true,
            },
        ];
        if (preferences) return getReArrangedTableHeaders(columns);
        return columns;
    }

    getFormattedError = (data = {}) => {
        const { error } = data;
        const errorKeys = Object.keys(error.data);
        if (!isEmpty(error.data)) {
            return (
                <ul>
                    {errorKeys.map((k, index) => (
                        <li key={k}>
                            {error.data[k].length === 1 ?
                                <Tooltip title={error.data[k] || ''}><span>{error.data[k]}</span></Tooltip> :
                                error.data[k].map((l, i) => ((
                                    <span key={`${errorKeys[index]}-${l}`}>
                                        {i === 0 ? errorKeys[index].replace(/([A-Z]+)/g, ' $1') : ''}
                                        <ul>
                                            <li key={l}>
                                                <Tooltip key={`tooltip-${l}`} title={l || ''}><span>{l}</span></Tooltip>
                                            </li>
                                        </ul>
                                    </span>
                                )))
                            }
                        </li>
                    ))}
                </ul>
            );
        }
        return '';
    }

    getData = (
        pageNumber: number = this.state.pageNumber,
        rowsPerPage: number = this.state.rowsPerPage || RECORDS_PER_PAGE,
        order: string = this.state.order,
        orderBy: string = this.state.orderBy,
        clearData: boolean = false,
        updatePreference: boolean = false,
    ) => {
        let stateToUpdate = {
            isUpdating: true, pageNumber, rowsPerPage, order, orderBy,
        };

        if (clearData) {
            const { tableData } = this.state;
            tableData.listData = [];
            stateToUpdate = { ...stateToUpdate, tableData };
        }

        this.setState(stateToUpdate, () => {
            if (updatePreference) saveUserPreference(this, IMPORT_LOGS_ERROR_LIST);
            const url = `${config.get('FLEET_IMPORT_SERVICES_URL')}/importjobs/${this.state.jobId}/logs?status=FAILED&start=${pageNumber}&limit=${rowsPerPage}&cb=${new Date().getTime()}`;

            getListData(url).then((res) => {
                if (res) {
                    const { tableData } = this.state;
                    tableData.listData = res.logs.map(d => ({ ...d, errorDetail: d.error }));
                    tableData.totalRecords = res.total || 0;

                    this.setState({
                        tableData,
                        loading: false,
                        isUpdating: false,
                        reportHeaderData: {
                            cancelled: res.job.cancelledRecords,
                            failed: res.job.failedRecords,
                            success: res.job.successRecords,
                            total: res.job.totalRecords,
                            fileName: res.job.fileName,
                            dateCreated: res.job.dateCreated,
                        },
                    });
                } else this.setState({ ...this.initState(false) });
            }).catch(() => this.setState({ ...this.initState(false) }));
        });
    }

    loadNextPage = (rows, value, order, orderBy) =>
        this.getData(
            rows, value, order, orderBy, true,
            isUpdatePreference(this, { rowsPerPage: value, order, orderBy }),
        );

    rearrangeColumns = listHeaders =>
        rearrangeColumns(this, listHeaders, { prefKey: IMPORT_LOGS_ERROR_LIST });

    sortTableColumn = (order, orderBy, page) =>
        this.getData(
            page.pageNumber, page.rowsPerPage, order, orderBy, true,
            isUpdatePreference(this, {
                order, orderBy, rowsPerPage: page.rowsPerPage, pageNumber: page.pageNumber,
            }),
        );

    handleClick = () => {
        window.history.back();
    }

    showLoader = loading => this.setState({ loading });

    render() {
        const { classes } = this.props;
        const { isUpdating, tableData } = this.state;

        return (
            <React.Fragment>
                {this.state.loading && !isUpdating && <AppLoader type="fullScreen" />}
                <MiniDrawer redirectTo={this.props.history.push}>
                    <div className={classes.app}>
                        <Paper>
                            <LogsListHeader
                                type={this.state.type}
                                reportHeaderData={this.state.reportHeaderData}
                                jobId={this.state.jobId}
                                showLoader={this.showLoader}
                            />
                        </Paper>
                        <ListComponent
                            tableData={{
                                listData: tableData.listData,
                                totalRecords: tableData.totalRecords,
                                ischeckBox: false,
                                isGroup: false,
                                isEditable: false,
                                listHeaders: tableData.listHeaders,
                            }}
                            rootClasses={classes.root}
                            loadNextPage={this.loadNextPage}
                            isUpdating={isUpdating}
                            resetTable={this.props.resetTable}
                            tableTitle=""
                            hideToolbar
                            sortTableColumn={this.sortTableColumn}
                            showSearch={false}
                            tableButtons={[]}
                            resizableHeader
                            rearrangeColumns={this.rearrangeColumns}
                            pageNumber={this.state.pageNumber}
                            rowsPerPage={this.state.rowsPerPage}
                            order={this.state.order}
                            orderBy={this.state.orderBy}
                            overrideStyle={{
                                tableWrapper: {
                                    height: '65vh',
                                },
                            }}
                            footerActionButton={[{
                                color: 'primary',
                                variant: 'contained',
                                onClick: this.handleClick,
                                text: 'Done',
                            }]}
                        />
                    </div>
                </MiniDrawer>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ImportReportDetails);
