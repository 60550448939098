
/* @flow */
import React, { Component } from 'react';
import { Rectangle } from 'react-google-maps';
import CustomMarker from './CustomMarker';
import { mapColor } from '../constants.map';

const shapeOption = {
    fillColor: mapColor.landmark.fillColor,
    strokeColor: mapColor.landmark.strokeColor,
};

type Props = {
    rectangle: Object,
    center: Object,
    updateShape: Function,
    google: any,
    getRectangleValue: Function,
};

type State = {
    isDragStart: boolean,
};

// Calculate radius (in meters).
const calculateRadius = (bounds: Object, google: Object) => {
    let radius = 0;
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();
    const northWest = new google.maps.LatLng(northEast.lat(), southWest.lng());
    const distanceBetweenCenterAndNEast =
        google.maps.geometry.spherical.computeDistanceBetween(bounds.getCenter(), northEast);
    const distanceBetweenNWestAndNEast =
        google.maps.geometry.spherical.computeDistanceBetween(northEast, northWest);
    const midPointDistance = (distanceBetweenNWestAndNEast / 2);
    const pythagoreanTheoremData =
        (distanceBetweenCenterAndNEast * distanceBetweenCenterAndNEast)
        -
        (midPointDistance * midPointDistance);
    radius = Math.sqrt(pythagoreanTheoremData);

    if (!radius) radius = distanceBetweenCenterAndNEast;
    return radius;
};
class CustomRectangle extends Component<Props, State> {
    rectangle: any;
    RectangleRef: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            isDragStart: false,
        };
        this.RectangleRef = React.createRef();
    }

    componentDidMount() {
        const { getRectangleValue } = this.props;
        if (getRectangleValue && typeof getRectangleValue === 'function') getRectangleValue(this.getRectangleValue);
    }

    getRectangleValue = () => {
        const bounds = this.RectangleRef.current.getBounds();
        const north = bounds.getNorthEast().lat();
        const east = bounds.getNorthEast().lng();
        const south = bounds.getSouthWest().lat();
        const west = bounds.getSouthWest().lng();
        const center = bounds.getCenter();
        let radius = 0;
        if (bounds && center) radius = calculateRadius(bounds, this.props.google);

        return {
            center,
            radius,
            bounds: {
                north, south, east, west,
            },
        };
    }

    updateRectangleBound = () => {
        const { radius, bounds, center } = this.getRectangleValue();
        this.setState({ isDragStart: false });
        this.props.updateShape('editableRectangle', { radius, bounds }, { lat: center.lat(), lng: center.lng() });
    }

    render() {
        const { isDragStart } = this.state;
        const { rectangle, center } = this.props;

        const opitions = {
            strokeColor: (rectangle && rectangle.lineColorValue)
                ? rectangle.lineColorValue : shapeOption.strokeColor,
            strokeOpacity: 0.8,
            strokeWeight: (rectangle && rectangle.lineWidth >= 0)
                ? rectangle.lineWidth : 2,
            fillColor: (rectangle && rectangle.fillColorValue)
                ? rectangle.fillColorValue : shapeOption.fillColor,
            fillOpacity: (rectangle && rectangle.transparency >= 0)
                ? ((100 - rectangle.transparency) / 100) : 0.35,
        };

        return (
            <React.Fragment>
                <Rectangle
                    bounds={rectangle.bounds}
                    options={opitions}
                    ref={this.RectangleRef}
                    editable
                    draggable
                    onDragStart={() => this.setState({ isDragStart: true })}
                    onDragEnd={() => this.updateRectangleBound()}
                    onBoundsChanged={() => { if (!isDragStart) this.updateRectangleBound(); }}
                />
                {!isDragStart &&
                    <CustomMarker
                        lat={center.lat || 0}
                        lng={center.lng || 0}
                        imageType={rectangle.iconValue}
                        iconColor={rectangle.iconColorValue}
                    />
                }
            </React.Fragment>
        );
    }
}

export { CustomRectangle, calculateRadius };
