/* @flow */
import React, { Component } from 'react';
import { PlayArrow, Pause, VolumeOff } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import customStyles from './VideoPlayer.module.scss';
import { LIVE_VIDEO_AUTO_PAUSE_DURATION, DISABLE_ICON_OPACITY, ICON_OPACITY } from './../../../constants/Config';
import { VIDEO_NORMAL_PLAYBACK_RATE } from '../../../containers/DashCam/constants.dashcam';
import { isWebRtcAllowed } from './../../../containers/DashCam/helper-classes/dashcamServices';
import { Setting, FullScreen, Play, PauseButton, Download } from '../../../util/svgImage_util';

export type Props = {
    recordingPlaybackUrl: string,
    playPause: Function,
    isPlaying: boolean,
    handleTypeChange: Function,
    type: string,
    width: string,
    playPauseTimer: boolean,
    download: boolean,
    downloadFileName: string,
    videoEnded:? Function,
    checkLoading:? boolean,
    playbackRate:? number,
    muted: boolean,
};

export type State = {
    isFullscreen: boolean,
    isPlaying: boolean,
    anchorEl: any,
    selectedType: string,
}

class VideoPlayer extends Component<Props, State> {
    videoRef: Object;
    videoTimer: any;
    static defaultProps = {
        recordingPlaybackUrl: '',
        handleTypeChange: () => { },
        type: 'video',
        width: '560px',
        playPauseTimer: true,
        download: false,
        muted: false,
        downloadFileName: '',
        videoEnded: () => { },
        checkLoading: false,
        playbackRate: VIDEO_NORMAL_PLAYBACK_RATE,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            isFullscreen: false,
            isPlaying: this.props.isPlaying,
            anchorEl: null,
            selectedType: this.props.type,
        };
        this.videoRef = React.createRef();
        this.videoTimer = null;
    }

    componentDidMount() {
        const video = this.getVideoElement();
        if (this.state.selectedType === 'video' && this.props.playPauseTimer && !this.props.checkLoading) this.startTimer();

        if (video) {
            video.removeAttribute('controls');
            video.setAttribute('muted', '');
            video.onfullscreenchange = this.handleFullscreenChange;
            video.onwebkitfullscreenchange = this.handleFullscreenChange;
            video.MSFullscreenChange = this.handleFullscreenChange;
            video.onmozfullscreenchange = this.handleFullscreenChange;

            if (this.props.videoEnded && typeof this.props.videoEnded === 'function') {
                video.addEventListener('ended', this.videoEnded);
            }

            if (this.props.checkLoading) {
                video.onplay = () => {
                    if (this.props.playPauseTimer &&
                        this.state.selectedType === 'video' && !this.state.isFullscreen) {
                        this.startTimer();
                    }
                    this.setState({ isPlaying: true });
                };
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.props.isPlaying !== nextProps.isPlaying) {
            this.setState({ isPlaying: nextProps.isPlaying });
        }

        if (this.props.playPauseTimer && (this.state.selectedType === 'video')
            && (nextProps.recordingPlaybackUrl !== this.props.recordingPlaybackUrl) &&
            !this.props.checkLoading) {
            this.startTimer();
        }
    }

    componentWillUnmount() {
        const video = this.getVideoElement();
        if (video) {
            video.onfullscreenchange = () => {};
            video.onwebkitfullscreenchange = () => {};
            video.MSFullscreenChange = () => {};
            video.onmozfullscreenchange = () => {};

            if (this.props.videoEnded && typeof this.props.videoEnded === 'function') {
                video.removeEventListener('ended', this.videoEnded);
            }
        }
    }

    handleFullscreenChange = (event: Object) => {
        const elem = event.target;
        const isFullscreen = this.getFullScreenElement() === elem;
        this.setState({ isFullscreen });
    }

    videoEnded = (e: Object) => {
        if (this.state.isFullscreen) this.toggleFullScreen(e);
        if (this.props.videoEnded) this.props.videoEnded();
    }

    clearTimer = () => {
        if (this.videoTimer) clearTimeout(this.videoTimer);
    }

    startTimer = () => {
        if (!this.props.playPauseTimer) return;
        this.clearTimer();
        this.videoTimer =
            setTimeout(() => {
                if (this.state.isFullscreen) this.toggleFullScreen({});
                this.props.playPause(true, true);
            }, LIVE_VIDEO_AUTO_PAUSE_DURATION);
    }

    isVideoLoading = () => {
        if (!this.props.checkLoading) return false;
        const video = this.getVideoElement();
        if (!video) return true;
        return video.networkState > 1;
    }

    playPause = (isPlaying: boolean) => {
        if (this.isVideoLoading()) return;
        this.setState({ isPlaying: !isPlaying });

        if (isPlaying) this.clearTimer();
        else if (this.props.type !== 'hls' || this.props.type !== 'webRtc') {
            this.startTimer();
        }

        this.props.playPause(isPlaying);
    }

    getVideoElement = () => this.videoRef.current;

    // $FlowFixMe
    getFullScreenElement = () => (document ? document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement : '');

    isFullscreen = () => (document && this.getFullScreenElement());

    exitFullscreen = () => {
        // $FlowFixMe
        if (document.exitFullscreen) {
            // $FlowFixMe
            document.exitFullscreen();

        // $FlowFixMe
        } else if (document.webkitExitFullscreen) {
            // $FlowFixMe
            document.webkitExitFullscreen();

            // $FlowFixMe
        } else if (document.msExitFullscreen) {
            // $FlowFixMe
            document.msExitFullscreen();
        }
    }

    toggleFullScreen = (e: Object) => {
        if (e.stopPropagation) e.stopPropagation();

        if (this.isFullscreen() && this.state.isFullscreen) {
            this.exitFullscreen();
            this.setState({ isFullscreen: false });
        } else {
            try {
                const elem = this.getVideoElement();

                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                } else if (elem.webkitEnterFullScreen !== undefined) {
                    elem.webkitEnterFullScreen(); // Safari
                }
            } finally {
                this.setState({ isFullscreen: true });
            }
        }
    }

    handleClick = (event: Object) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    onSelect = (value: string) => {
        this.setState({ selectedType: value });
        this.props.handleTypeChange(value);
        this.handleClose();
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    }

    getPlayPauseButton = () => (
        <div className={customStyles.icon}>
            {this.state.isPlaying ?
                <React.Fragment>
                    <PauseButton className={`${customStyles.playPauseIcon} ${customStyles.pauseIcon}`} />
                </React.Fragment> :
                <React.Fragment>
                    <Play className={customStyles.playPauseIcon} />
                    {this.props.playPauseTimer &&
                    <div className={`${customStyles.text}`}>
                        <p>{this.props.recordingPlaybackUrl ? 'Video' : 'Live video'} is automatically paused after 30 seconds.</p>
                        <p>Click to restart.</p>
                    </div>
                    }
                </React.Fragment>
            }
        </div>
    )

    handleFullscreenVideoPause = (e: any) => {
        e.stopPropagation();
    }

    getOpacity = () => {
        const { isPlaying } = this.state;
        let opacity = isPlaying ? ICON_OPACITY : DISABLE_ICON_OPACITY;
        if (this.isVideoLoading()) {
            opacity = DISABLE_ICON_OPACITY;
        }
        return opacity;
    }

    render() {
        const { isPlaying, anchorEl, selectedType } = this.state;
        const video = this.getVideoElement();

        if (video && this.props.playbackRate !== VIDEO_NORMAL_PLAYBACK_RATE) {
            video.playbackRate = this.props.playbackRate;
        }

        return (
            <div
                onClick={(e) => {
                    e.preventDefault();
                    return this.playPause(isPlaying);
                }}
                onKeyPress={() => { }}
                role="button"
                tabIndex="0"
                className={customStyles.videoContainer}
                style={{ width: this.props.width }}
            >
                <div className={customStyles.playPauseButton}>
                    {this.getPlayPauseButton()}
                </div>
                <video
                    disablepictureinpicture="true"
                    controlsList="nodownload"
                    webkit-playsinline="true"
                    playsInline="true"
                    autoPlay
                    src={this.props.recordingPlaybackUrl}
                    ref={this.videoRef}
                    className={`${customStyles.video} ${(this.props.isPlaying === true) ? customStyles.video_player_loader : ''}`}
                    controls="false"
                    onPause={this.handleFullscreenVideoPause}
                    onPlay={e => e.stopPropagation()}
                    muted={this.props.muted}
                >
                    <track kind="captions" />
                </video>
                <div
                    className={`${customStyles.videoControls}`}
                    id="video-controls"
                    onClick={e => e.stopPropagation()}
                    onKeyPress={() => { }}
                    role="button"
                    tabIndex="0"
                >
                    <div className={customStyles.bottomControls}>
                        <div className={customStyles.leftControls}>
                            {isPlaying ?
                                <Pause onClick={() => this.playPause(true)} /> :
                                <PlayArrow onClick={() => this.playPause(false)} />
                            }
                        </div>
                        <div className={customStyles.rightControls}>
                            {
                                (this.props.muted) &&
                                <button
                                    className={`${customStyles.button} ${customStyles.disabled}`}
                                    aria-controls="video-type"
                                >
                                    <VolumeOff
                                        opacity={DISABLE_ICON_OPACITY}
                                        className={customStyles.svg}
                                    />
                                </button>
                            }
                            {
                                (this.state.selectedType !== 'video') &&
                                <button className={customStyles.button} aria-controls="video-type" aria-haspopup="true" onClick={this.handleClick}>
                                    <Setting className={customStyles.svg} />
                                </button>
                            }
                            {
                                (this.props.download) &&
                                <a href={this.props.recordingPlaybackUrl} download={this.props.downloadFileName} className={customStyles.button} aria-controls="video-type" aria-haspopup="true">
                                    <Download className={customStyles.svg} />
                                </a>
                            }
                            <button
                                onClick={e => this.toggleFullScreen(e)}
                                className={`${customStyles.button} ${(!isPlaying || this.isVideoLoading()) ? customStyles.disabled : ''}`}
                                disabled={!isPlaying || this.isVideoLoading()}
                            >
                                <FullScreen
                                    opacity={this.getOpacity()}
                                    className={customStyles.svg}
                                />
                            </button>
                            {
                                (this.state.selectedType !== 'video') &&
                                <Menu
                                    id="video-type"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleClose}
                                    className={customStyles.menu}
                                    classes={{ paper: customStyles.paperWidth }}
                                >
                                    {isWebRtcAllowed() &&
                                    <MenuItem
                                        onClick={() => this.onSelect('webRtc')}
                                        className={`${(selectedType === 'webRtc' ? customStyles.selected : '')} ${customStyles.menuItem}`}
                                    >
                                        Web RTC
                                    </MenuItem>}
                                    <MenuItem
                                        onClick={() => this.onSelect('hls')}
                                        className={`${(selectedType === 'hls' ? customStyles.selected : '')} ${customStyles.menuItem}`}
                                    >
                                    HLS
                                    </MenuItem>
                                </Menu>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VideoPlayer;
