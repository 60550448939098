import fileDownload from 'js-file-download';
import React from 'react';
import { Button, IconButton, Popover, Tooltip } from '@mui/material';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import RefreshIcon from '@mui/icons-material/Refresh';
import backArrow from '../../assets/icons/back-arrow-black.svg';
import csvIcon from '../../assets/icons/csv-2.svg';
import pdfIcon from '../../assets/icons/PDF.svg';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import customStyles from './Dashboard.module.scss';
import ExternalSite from '../../components/ExternalSite/ExternalSite';
import { reduxConnect } from '../../hoc';
import config from '../../constants/Config';
import AppLoader from '../../components/AppLoader';
import analytics from '../../analytics/index';
import {
    getUserSettingTimeZone,
    getTimeFormatAsperUserSettting,
    getUserUnit,
    getAppToken,
    isReseller,
} from '../../helper-classes/utility-functions';

export type Props = {
    history: {
        push: Function,
    },
    userAuthToken: String,
};

export type State = {
    isDownloading: false,
    stateId: any,
    url: null,
    frameWidth: number,
    overlayColor: string,
    dashboardOriginalHeight: number,
    dashboardHeight: number,
    dashboardTop: string,
    selectedQuery: string,
    isEditMode: boolean,
    tileCount: number,
    selLook: number
};

class Dashboard extends React.Component<Props, State> {
    overlay: any;
    state = {
        isDownloading: false,
        url: null,
        overlayColor: '#FFFFFF',
        dashboardTop: '-85px',
        selectedQuery: String,
        isDetailMode: false,
        isEditMode: false,
        isDownloadMode: false,
        anchorEl: null,
    };

    componentDidMount() {
        this.overlay = document.createElement('div');
        this.overlay.style.position = 'absolute';
        this.overlay.style.top = 0;
        this.overlay.style.left = 0;
        this.overlay.style.width = '28%';
        this.overlay.style.height = '64px';
        this.overlay.style.backgroundColor = 'rgb(20, 99, 202)';
        window.addEventListener('message', this.handleIframeMessage);
        window.addEventListener('resize', this.positionOverlay);
        this.loadDashboardRef();
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleIframeMessage);
        window.removeEventListener('resize', this.positionOverlay);
    }

    positionOverlay = () => {
        const iframe = document.getElementById('lkDash');
        if (iframe) {
            iframe.onload = () => {
                this.setState({ overlayColor: '#FFFFFF' });
            };
        }
    };

    handleIframeMessage = (event) => {
        const iframe = document.getElementById('lkDash');
        if (document.getElementById('lkDash')) {
            if (event.source === document.getElementById('lkDash').contentWindow) {
                const data = JSON.parse(event.data);
                if (data.type === 'dashboard:run:start') {
                    this.setState({ isDetailMode: false });
                }
                if (data.type === 'drillmenu:click' || data.type === 'dashboard:tile:view') {
                    const selQuery = this.parseFilterArgs(data.url);
                    this.setState({
                        selLook: this.parseSelectedLookId(data.url),
                        selectedQuery: selQuery,
                    });
                }
                if (data.type === 'look:state:changed') {
                    this.setState({ isDetailMode: true });
                    const selQuery = this.parseFilterArgs(data.look.url);
                    if (selQuery.includes('query_timezone')) {
                        this.setState({
                            selLook: this.parseSelectedLookId(data.look.url),
                            selectedQuery: selQuery,
                        });
                    }
                }
                if (data.type === 'dashboard:edit:start' && !iframe.parentNode.contains(this.overlay)) {
                    this.setState({ dashboardTop: '-35px', isEditMode: true });
                    iframe.parentNode.appendChild(this.overlay);
                }
                if ((data.type === 'dashboard:edit:cancel' || data.type === 'dashboard:save:complete') && iframe.parentNode.contains(this.overlay)) {
                    this.setState({ dashboardTop: '-85px', isEditMode: false });
                    iframe.parentNode.removeChild(this.overlay);
                }
            }
        }
    };

    startEditMode = () => {
        const my_iframe = document.getElementById('lkDash');
        const my_request = JSON.stringify({
            type: 'dashboard:edit',
        });
        if (isReseller()) {
            my_iframe.contentWindow.postMessage(my_request, config.get('LOOKER_API_RESELLER_URL'));
        } else my_iframe.contentWindow.postMessage(my_request, config.get('LOOKER_API_URL'));
    }

    parseSelectedLookId = (urlRef) => {
        const firstVariable = 'looks/';
        const secondVariable = '?';
        return urlRef.substring(
            urlRef.indexOf(firstVariable) + firstVariable.length,
            urlRef.indexOf(secondVariable),
        );
    }

    openConfigDashboard = () => {
        analytics.track('CONFIGURE_DASHBOARD', { feature: 'Dashboard' });
        this.props.history.push('/configure-dashboard');
    };

    downloadDashboardPdfSuccess = (response) => {
        response.blob().then((buffer) => {
            fileDownload(buffer, 'dashboard.pdf');
        });
        this.setState({ isDownloading: false });
    };

    downloadDashboardCsvSuccess = (response) => {
        response.blob().then((buffer) => {
            fileDownload(buffer, 'dashboard.zip');
        });
        this.setState({ isDownloading: false });
    }

    loadDashboardRef = () => {
        this.setState({ url: null, overlayColor: '#FFFFFF', isDetailMode: false });
        fetch(
            `${config.get('FLEET_VIEW_SERVICES_URL')}/looker?tz=${getUserSettingTimeZone()}&time_display=${getTimeFormatAsperUserSettting()}&units_of_measurement=${getUserUnit()}&edit=${this.state.isEditMode}&embedDomain=${window.location.origin}`,
            {
                headers: {
                    'X-Nspire-UserToken': this.props.userAuthToken,
                    'X-Nspire-AppToken': getAppToken(),
                },
            },
        )
            .then(response => (response.json()))
            .then(json => this.handleLoadDashboardResponse(json))
            .then(() => { this.positionOverlay(); })
            .catch(() => {});
    };

    handleLoadDashboardResponse = (responseJson) => {
        if (responseJson.count > 0) {
            this.setState({ url: responseJson.url, tileCount: responseJson.count });
        } else {
            this.setState({ url: null, tileCount: 0 });
        }
    }

    downloadDashboardPdfError = () => {
        this.setState({ isDownloading: false });
    };

    downloadDashboardCsvError = () => {
        this.setState({ isDownloading: false });
    }

    downloadDashboardTileCsvError = () => {
        this.setState({ isDownloading: false });
    };

    getExternalSite() {
        if (this.state.tileCount === 0) {
            return (
                <div className={customStyles['no-content-dialog-container']}>
                    <div className={customStyles['no-content-dialog-panel']}>
                        <div>
                            No dashboard tiles are currently setup.
                        </div>
                        <div>
                            <p>You can access the dashboard setup using the
                                &nbsp;<span className={customStyles['emph-text']}>configure</span> button
                                in the top right corner of your screen.
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
        return (<ExternalSite
            id="lkDash"
            style={{
                width: this.state.isEditMode ? 'calc(100% + 8px)' : '100%',
                height: `${document.body.scrollHeight - 100}px`,
                backgroundColor: '#FFFFFF',
                overflow: 'hidden',
                margin: this.getMargin(),
            }}
            url={this.state.url}
        />);
    }

    getMargin() {
        if (!this.state.isEditMode) {
            return '37px 0px 0px 0px';
        }
        return `${this.state.frameWidth + 25}px`;
    }

    downloadDashboardTileCsvSuccess = (response) => {
        response.text().then((buffer) => {
            fileDownload(buffer, 'dashboard.csv');
        });
        this.setState({ isDownloading: false });
    };

    downloadDashboardDetailCsv = () => {
        const filterArgs = this.formatFilters(this.state.selectedQuery);
        const reqUrl = `${config.get('FLEET_VIEW_SERVICES_URL')}/dashboard-api/look/${this.state.selLook}/query/csv?${filterArgs}`;
        this.fetchWithTimeout(
            reqUrl,
            {
                headers: {
                    'X-Nspire-UserToken': this.props.userAuthToken,
                    'X-Nspire-AppToken': getAppToken(),
                },
            },
            this.downloadDashboardTileCsvSuccess,
            this.downloadDashboardTileCsvError,
        );
    }

    downloadDashBoardPdf = () => {
        this.setState({ isDownloading: true });
        const reqUrl = `${config.get('FLEET_VIEW_SERVICES_URL')}/dashboard-api/dashboard/pdf`;
        this.fetchWithTimeout(
            reqUrl,
            {
                headers: {
                    'X-Nspire-UserToken': this.props.userAuthToken,
                    'X-Nspire-AppToken': getAppToken(),
                },
            },
            this.downloadDashboardPdfSuccess,
            this.downloadDashboardPdfError,
        );
    };

    downloadDashBoardCsv = () => {
        this.setState({ isDownloading: true });
        const reqUrl = `${config.get('FLEET_VIEW_SERVICES_URL')}/dashboard-api/dashboard/csv`;
        this.fetchWithTimeout(
            reqUrl,
            {
                headers: {
                    'X-Nspire-UserToken': this.props.userAuthToken,
                    'X-Nspire-AppToken': getAppToken(),
                },
            },
            this.downloadDashboardCsvSuccess,
            this.downloadDashboardCsvError,
        );
    }

    fetchWithTimeout = (url, headers, successHandler, errorHandler) => {
        let didTimeOut = false;
        new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                didTimeOut = true;
                reject(new Error('Request timed out'));
            }, 120000);
            fetch(url, headers)
                .then((response) => {
                    clearTimeout(timeout);
                    if (!didTimeOut) {
                        resolve(response);
                    }
                })
                .catch((err) => {
                    if (didTimeOut) {
                        return;
                    }
                    reject(err);
                });
        })
            .then(response => (successHandler(response)))
            .catch(error => (errorHandler(error)));
    }

    parseFilterArgs = (urlRef) => {
        const index = urlRef.indexOf('?');
        return urlRef.substring(index + 1);
    }

    getActionIcon = () => {
        if (this.state.isDetailMode && !this.state.isEditMode) {
            return <img src={backArrow} alt="backArrow" />;
        }
        return <RefreshIcon />;
    }

    isDashboardContentLoaded = () => {
        if (this.state.url || this.state.url === null || this.state.url === 'no-content') {
            return false;
        }
        return true;
    }

    getRefreshTitlebarElement = () => (
        <Tooltip title={this.state.isDetailMode && !this.state.isEditMode ? 'Back' : 'Refresh'}>
            <IconButton
                id="navBtn"
                onClick={() => this.loadDashboardRef()}
                size="large"
            >
                { this.getActionIcon() }
            </IconButton>
        </Tooltip>
    );

    getEditDashboardElement = () => (
        <Button
            id="editBtn"
            variant="contained"
            color="primary"
            onClick={this.startEditMode}
            disabled={this.state.isEditMode || this.state.isDetailMode}
        >
            EDIT DASHBOARD
        </Button>
    );

    getDownloadTitlebarElement = () => (
        <div>
            <Tooltip title="Download">
                <IconButton
                    id="downloadBtn"
                    onClick={(event) => {
                        if (this.state.isDetailMode) {
                            this.setState({ isDownloading: true });
                            this.downloadDashboardDetailCsv();
                        } else {
                            this.setState({ isDownloadMode: true, anchorEl: event.currentTarget });
                        }
                    }}
                    size="large"
                >
                    <VerticalAlignBottomIcon />
                </IconButton>
            </Tooltip>
            <Popover
                id="downloadPopover"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                transformOrigin={{ vertical: 'center', horizontal: 'right' }}
                style={{ top: '41px' }}
                PaperProps={{ className: customStyles['download-popover-paper'] }}
                open={this.state.isDownloadMode}
                onClose={() => { this.setState({ isDownloadMode: false, anchorEl: null }); }}
            >
                <div>
                    Download as CSV
                    <IconButton
                        id="downloadCsv"
                        onClick={() => {
                            this.downloadDashBoardCsv();
                            this.setState({ isDownloadMode: false, anchorEl: null });
                        }}
                        size="large"
                    >
                        <div style={{ width: '30px', height: '30px', verticalAlign: 'middle' }}>
                            <img src={csvIcon} alt="csv-icon" style={{ width: '30px', height: '30px' }} />
                        </div>
                    </IconButton>
                </div>
                <div>
                    Download as PDF
                    <IconButton
                        id="downloadPdf"
                        onClick={() => {
                            this.downloadDashBoardPdf();
                            this.setState({ isDownloadMode: false, anchorEl: null });
                        }}
                        size="large"
                    >
                        <div style={{ width: '30px', height: '30px', verticalAlign: 'middle' }}>
                            <img src={pdfIcon} alt="pdf-icon" style={{ width: '30px', height: '30px' }} />
                        </div>
                    </IconButton>
                </div>
            </Popover>
        </div>
    );

    cleanValue = (str) => {
        let strToReturn = str;
        strToReturn = str.replace('%5B', '').replace('%5D', '').replace('%2C', ',').replace('%2F', '/')
            .replaceAll('%20', '+')
            .replaceAll('%22', '');
        return strToReturn;
    }

    splitNamePair = (val) => {
        const nameValuePairs = val.split('=');
        return nameValuePairs;
    }

    formatFilters = (rawFilters) => {
        const tokens = rawFilters.split('&f');
        let args = '';
        tokens.forEach((token) => {
            const nameValuePairs = this.cleanValue(token).split('=');
            args += `&${encodeURI(nameValuePairs[0].replace('[', '').replace(']', ''))}=${encodeURI(nameValuePairs[1])}`;
        });
        return args;
    }

    render() {
        return (
            <div>
                <MiniDrawer
                    redirectTo={this.props.history.push}
                >
                    <div
                        className={customStyles['dashboard-button-header']}
                    >
                        <div
                            style={{
                                float: 'right',
                                position: 'relative',
                                marginTop: '6px',
                            }}
                        >
                            <Button
                                id="configureBtn"
                                variant="contained"
                                color="primary"
                                onClick={this.openConfigDashboard}
                            >
                                CONFIGURE
                            </Button>
                        </div>

                        <div className={customStyles['edit-dashboard-button']}>
                            {this.getEditDashboardElement()}
                        </div>
                        <div style={{ float: 'right', position: 'relative', marginRight: '5px' }}>
                            { this.getDownloadTitlebarElement() }
                        </div>
                        <div style={{ float: 'right', position: 'relative', marginRight: '5px' }}>
                            { !this.state.isEditMode && this.getRefreshTitlebarElement()}
                        </div>
                        <div className={customStyles['clear-fix']} />
                    </div>
                    <div
                        className={customStyles['dashboard-content-wrapper']}
                        key={this.state.stateId}
                        style={{
                            width: `${this.state.frameWidth}px`,
                            height: `${document.body.scrollHeight - 45}px`,
                        }}
                    >
                        <div style={{
                            zIndex: 2,
                            backgroundColor: this.state.overlayColor,
                            height: '84px',
                            position: 'relative',
                            width: `${this.state.frameWidth}px`,
                            top: '5px',
                            left: '0px',
                            borderBottom: '1px solid #e4e5e6',
                        }}
                        />
                        <div style={{
                            zIndex: 1,
                            position: 'relative',
                            width: '100%',
                            top: this.state.dashboardTop,
                            left: this.state.isEditMode ? '-5px' : '0px',
                            height: '100%',
                            backgroundColor: '#FFFFFF',
                        }}
                        >
                            {
                                this.isDashboardContentLoaded() ?
                                    <AppLoader
                                        loaderStyle={{
                                            'padding-top': '40px',
                                            'text-align': 'center',
                                        }}
                                    /> : this.getExternalSite()
                            }
                        </div>
                    </div>
                </MiniDrawer>
                {this.state.isDownloading &&
                <AppLoader
                    type="fullScreen"
                />
                }
            </div>
        );
    }
}
const mapStateToProps = state => ({
    userAuthToken: state.userSession.userToken,
    url: state.url,
});

const mapDispatchToProps = {};

export default reduxConnect(Dashboard, mapDispatchToProps, mapStateToProps);
