/* @flow */

export const ADD_LANDMARK: string = 'ADD_LANDMARK';
export const ADD_LANDMARK_SUCCESS: string = 'ADD_LANDMARK_SUCCESS';
export const ADD_LANDMARK_ERROR: string = 'ADD_LANDMARK_ERROR';
export const FETCH_LANDMARK: string = 'Fetch_LANDMARK';
export const DELETE_LANDMARK: string = 'DELETE_LANDMARK';
export const DELETE_LANDMARK_SUCCESS: string = 'DELETE_LANDMARK_SUCCESS';
export const DELETE_LANDMARK_ERROR: string = 'DELETE_LANDMARK_ERROR';
export const UPDATE_LANDMARK: string = 'UPDATE_LANDMARK';
export const UPDATE_LANDMARK_SUCCESS: string = 'UPDATE_LANDMARK_SUCCESS';
export const UPDATE_LANDMARK_ERROR: string = 'UPDATE_LANDMARK_ERROR';
export const GET_LANDMARK_GROUPS: string = 'GET_LANDMARK_GROUPS';
export const GET_LANDMARK_GROUPS_SUCCESS: string = 'GET_LANDMARK_GROUPS_SUCCESS';
export const GET_LANDMARK_GROUPS_ERROR: string = 'GET_LANDMARK_GROUPS_ERROR';
export const FETCH_LANDMARK_SUCCESS: string = 'FETCH_LANDMARK_SUCCESS_EDIT';
export const FETCH_LANDMARK_ERROR: string = 'FETCH_LANDMARK_ERROR_EDIT';
export const UPDATE_LANDMARK_LOADER: string = 'UPDATE_LANDMARK_LOADER';
export const CLOSE_LANDMARK_EDIT_DIALOG: string = 'CLOSE_LANDMARK_EDIT_DIALOG';
export const MAX_LENGTH_PHONE_NUMBER: number = 10;
export const LANDMARK_DEFAULT_LINECOLOR: string = '#000000';
export const LANDMARK_DEFAULT_FILLCOLOR: string = '#FFFFFF';
export const LANDMARK_DEFAULT_RADIUS: number = 160;
export const LANDMARK_DEFAULT_LINE_WIDTH: number = 1;
export const LANDMARK_DEFAULT_TRANSPARENCY: number = 80;
export const LANDMARK_DEFAULT_ICONVALUE: string = 'diamond-white';
// This regex supports ASCII character 32 - 255. Any other character set will be invalidated.
export const LANDMARK_SUPPORTED_ASCII_CHARACTERS = new RegExp(/[^\w -ÿ€‚ƒ„…†‡ˆ‰Š‹ŒŽ‘’“”•–—˜™š›œžŸ]/g);

export type LandmarkTypeAction = {
    type: string;
    payload?: Object;
}

