import React, { Component } from 'react';
import { Offline } from 'react-detect-offline';

type Props = {
    children: any,
    polling: boolean,
};

// https://github.com/chrisbolin/react-detect-offline can check for more options/props

class AppOffline extends Component<Props, {}> {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const {
            children,
            polling = false,
            ...other
        } = this.props;
        return (
            <Offline polling={polling} {...other}>{this.props.children}</Offline>
        );
    }
}

export default AppOffline;

