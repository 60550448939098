/* @flow */

export const FETCH_MAINTENANCE_STATUS: string = 'FETCH_MAINTENANCE_STATUS';
export const FETCH_MAINTENANCE_STATUS_SUCCESS: string = 'FETCH_MAINTENANCE_STATUS_SUCCESS';
export const FETCH_MAINTENANCE_STATUS_ERROR: string = 'FETCH_MAINTENANCE_STATUS_ERROR';
export const RESET_MAINTENANCE_STATUS: string = 'RESET_MAINTENANCE_STATUS';
export const MANINTENANACE_STATUS_LIST_PREF_KEY: string = 'fleetLocate.maintenanceStatus';
export type MaintenanceStatusTypeAction = {
    type: string;
    payload?: Object;
}
