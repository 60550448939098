/* @flow */
import React from 'react';

const ItemsShowing = (values: {
    predictions: {total:number, data: any},
    countClass: string }) => {
    const { predictions, countClass } = values;

    return (
        <div className={countClass}>
            <span>Showing
                {predictions && predictions.total >= predictions.data.length ?
                    <span> {predictions.data.length} of {predictions.total}</span> :
                    <span> {predictions.data.length}</span>
                }
            </span>
        </div>
    );
};
export default ItemsShowing;
