/* @flow */
import { FETCH_MAINTENANCE_STATUS_ERROR, FETCH_MAINTENANCE_STATUS_SUCCESS, FETCH_MAINTENANCE_STATUS, RESET_MAINTENANCE_STATUS } from './constants.maintenanceStatus';

const initialState = {
    statuses: [],
    isUpdating: false,
};

type stateType = {
    statuses: Array<Object>,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        statuses:{},
        totalRecords: number
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_MAINTENANCE_STATUS:
        return {
            ...state,
            isUpdating: true,
        };
    case RESET_MAINTENANCE_STATUS:
        return {
            ...state,
            isUpdating: false,
            statuses: [],
            totalRecords: 0,
        };
    case FETCH_MAINTENANCE_STATUS_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            statuses: action.payload.statuses,
            totalRecords: action.payload.totalRecords,
        };
    case FETCH_MAINTENANCE_STATUS_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    default:
        return state;
    }
};
