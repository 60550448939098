/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_ADDRESS, FETCH_ADDRESS_WITH_LAT_LNG } from './constants.address';
import {
    fetchAddressSuccess,
    fetchAddressError,
    fetchAddressWithLatLngError,
    fetchAddressWithLatLngSuccess,
} from './actions.address';
import config from '../../constants/Config';
import { getUserToken, formatAddress } from '../../helper-classes/utility-functions';

type ObservarblesTypes = {
    postJSON: Function,
    getJSON: Function,
    of: Function,
    fetchLocationByPlaceId: Function,
    fetchAddressWithLatLng: Function,
}

export const getAddress =
    (actions$: Function, store: Object, { postJSON, of, fetchLocationByPlaceId }
        : ObservarblesTypes) =>
        actions$
            .ofType(FETCH_ADDRESS)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                    'Content-Type': 'application/json',
                };

                const place = {
                    address: {},
                    latitude: 0,
                    longitude: 0,
                    landmarkName: '',
                    isPresent: false,
                    subAddress: '',
                    landmarkId: '',
                };
                const geocoder = window.googleMaps &&
                window.googleMaps.Geocoder ? new window.googleMaps.Geocoder() : '';
                const { placeId } = action.payload;
                return fetchLocationByPlaceId(geocoder, placeId)
                    .mergeMap((results) => {
                        if (results && results.length > 0) {
                            const geoAddress = results[0];
                            place.address = formatAddress(results[0]);
                            place.latitude = geoAddress.geometry.location.lat();
                            place.longitude = geoAddress.geometry.location.lng();
                        }
                        const data = {
                            searchParams: [place.longitude.toString(), place.latitude.toString()],
                            type: 'geoSearch',
                        };
                        return postJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/landmarks`, data, headers);
                    })
                    .map((result) => {
                        if (result && result.response.data.length > 0) {
                            const landmark = result.response.data[0];
                            place.landmarkName = landmark.name;
                            place.address = landmark.address;
                            place.subAddress = `${landmark.city}${landmark.state || landmark.zipCode ? ',' : ''} ${landmark.state} ${landmark.zipCode}`;
                            place.isPresent = true;
                            place.landmarkId = landmark.id;
                        } else {
                            place.landmarkName = '';
                            place.isPresent = false;
                            place.landmarkId = '';
                        }
                        return fetchAddressSuccess(place);
                    })
                    .catch(error => of(fetchAddressError(error)));
            });

export const getAddressWithLatLng =
    (actions$: Function, store: Object, { of, fetchAddressWithLatLng }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_ADDRESS_WITH_LAT_LNG)
            .mergeMap((action) => {
                const geocoder = window.googleMaps &&
                window.googleMaps.Geocoder ? new window.googleMaps.Geocoder() : '';
                return fetchAddressWithLatLng(geocoder, action.payload.lat, action.payload.lng)
                    .map((result) => {
                        const place = {};
                        place.address = formatAddress(result);
                        place.latitude = action.payload.lat;
                        place.longitude = action.payload.lng;
                        return fetchAddressWithLatLngSuccess(place);
                    }).catch(error => of(fetchAddressWithLatLngError(error)));
            });

export default combineEpics(getAddress, getAddressWithLatLng);
