/* @flow */
import React from 'react';
import LiveVideoContainer from './LiveVideoContainer';

export type Props = {
    selectedDevice: Array<Object>,
    removeVideo: Function,
    showNotification: Function,
};

const LiveVideosList = (props: Props) => {
    const removeVideo = (imei: string, cameraId: string) => {
        props.removeVideo(imei, cameraId);
    };

    const errorNotification = (message: string) => props.showNotification(message);

    return (
        <div>
            {props.selectedDevice && props.selectedDevice.map(d => d.cameraIds.map(c => (
                <LiveVideoContainer
                    removeVideo={(imei, cameraId) => removeVideo(imei, cameraId)}
                    imei={d.imei}
                    cameraId={c}
                    name={d.name}
                    key={`${d.imei}-${c}`}
                    errorNotification={message => errorNotification(message)}
                />)))
            }
        </div>
    );
};

export default LiveVideosList;
