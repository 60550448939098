/* @flow */
import {
    FETCH_RECIPIENT_LIST,
    FETCH_RECIPIENT_LIST_SUCCESS,
    FETCH_RECIPIENT_LIST_ERROR,
    POST_RECIPIENT_ALERT_DATA_SUCCESS,
    POST_RECIPIENT_ALERT_DATA_ERROR,
    CLEAR_REDUCER,
} from './constants.alertRecipient';

import type { RecipientListTypeAction } from './constants.alertRecipient';

export const fetchRecipientList = (recipientKey: string): RecipientListTypeAction => ({
    type: FETCH_RECIPIENT_LIST,
    payload: { recipientKey },
});

export const fetchRecipientListSuccess = (recipientList: {}): RecipientListTypeAction => ({
    type: FETCH_RECIPIENT_LIST_SUCCESS,
    payload: { recipientList },
});

export const fetchRecipientListError = (error: string): RecipientListTypeAction => ({
    type: FETCH_RECIPIENT_LIST_ERROR,
    payload: { error },
});
export const postRecipientAlertDataSuccess = (saveRecipientData: {}): RecipientListTypeAction => ({
    type: POST_RECIPIENT_ALERT_DATA_SUCCESS,
    payload: { saveRecipientData },
});

export const postRecipientAlertDataError = (error: string): RecipientListTypeAction => ({
    type: POST_RECIPIENT_ALERT_DATA_ERROR,
    payload: { error },
});

export const clearAlertReducer = (): RecipientListTypeAction => ({
    type: CLEAR_REDUCER,
});
