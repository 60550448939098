/* @flow */
import {
    DISPATCH_FETCH_ASSETS_DETAILS,
    DISPATCH_FETCH_ASSETS_DETAILS_ERROR,
    DISPATCH_FETCH_ASSETS_DETAILS_SUCCESS,
    DISPATCH_SEND_DIRECTION,
    DISPATCH_SEND_DIRECTION_ERROR,
    DISPATCH_SEND_DIRECTION_SUCCESS,
    CLOSE_DIALOG,
} from './constants.dispatchAction';

type stateType = {
    isLoader: boolean,
}

type actionType = {
    type: string,
    payload :{
        response:{},
        successMsg: string,
        error: string,
        isEmailSend: boolean,
        isError: boolean,
    }
}

export const initialState = {
    assetsDetails: {
        lat: 0,
        lng: 0,
    },
    isLoader: false,
    direction: [],
    successMsg: '',
    error: '',
    isEmailSend: false,
    isError: false,
};

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case DISPATCH_FETCH_ASSETS_DETAILS_SUCCESS:
        return {
            ...state,
            assetsDetails: action.payload.response,
            isLoader: false,
        };
    case DISPATCH_FETCH_ASSETS_DETAILS_ERROR:
        return {
            ...state,
            isLoader: false,
            assetsDetails: {},
        };
    case DISPATCH_FETCH_ASSETS_DETAILS:
        return {
            ...state,
            isLoader: true,
            assetsDetails: {},
        };
    case DISPATCH_SEND_DIRECTION:
        return {
            ...state,
            isLoading: true,
            isEmailSend: false,
            isError: false,
        };
    case DISPATCH_SEND_DIRECTION_SUCCESS:
        return {
            ...state,
            successMsg: action.payload.successMsg,
            isLoading: false,
            isEmailSend: true,
        };
    case DISPATCH_SEND_DIRECTION_ERROR:
        return {
            ...state,
            error: action.payload.error,
            isLoading: false,
            isError: true,
        };
    case CLOSE_DIALOG:
        return {
            ...state,
            loading: false,
            isEmailSend: false,
            isError: false,
            error: '',
            successMsg: '',
        };
    default:
        return state;
    }
};
