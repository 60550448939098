/* @flow */
import {
    FETCH_LANDMARK_SUCCESS,
    FETCH_LANDMARK_ERROR,
    UPDATE_LANDMARK_SUCCESS,
    UPDATE_LANDMARK_ERROR,
    DELETE_LANDMARK_SUCCESS,
    DELETE_LANDMARK_ERROR,
    GET_LANDMARK_GROUPS,
    GET_LANDMARK_GROUPS_SUCCESS,
    GET_LANDMARK_GROUPS_ERROR,
    UPDATE_LANDMARK_LOADER,
    ADD_LANDMARK_SUCCESS,
    ADD_LANDMARK_ERROR,
    CLOSE_LANDMARK_EDIT_DIALOG,
} from './constants.landmark';

const initialState = {
    landmark: {},
    isUpdating: false,
    isError: false,
    addedSuccessfully: false,
    isDeleted: false,
    landmarkGroup: {
        data: [],
    },
    error: {},
};

type stateType = {
    landmark: Object,
    isUpdating: boolean,
    isError: boolean,
    addedSuccessfully: boolean,
    isDeleted: boolean,
    landmarkGroup: Object,
}
type actionType = {
    type: string,
    payload :{
        landmark: Object,
        landmarkGroup: Object,
        totalLandmarks: number,
        loading: boolean,
        error: Object,
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_LANDMARK_SUCCESS:
        return {
            ...state,
            loading: false,
            isError: false,
            isDeleted: false,
            landmark: action.payload.landmark,
        };
    case FETCH_LANDMARK_ERROR:
        return {
            ...state,
            loading: false,
            isError: true,
            isDeleted: false,
            landmark: {},
        };
    case UPDATE_LANDMARK_SUCCESS:
        return {
            ...state,
            isError: false,
            loading: false,
            isDeleted: false,
            addedSuccessfully: true,
        };
    case UPDATE_LANDMARK_ERROR:
        return {
            ...state,
            loading: false,
            isError: true,
            error: action.payload.error,
            addedSuccessfully: false,
        };
    case GET_LANDMARK_GROUPS:
        return {
            ...state,
            isError: false,
            isUpdating: true,
        };
    case GET_LANDMARK_GROUPS_ERROR:
        return {
            ...state,
            landmarkGroup: [],
            isError: true,
        };
    case GET_LANDMARK_GROUPS_SUCCESS:
        return {
            ...state,
            landmarkGroup: action.payload.landmarkGroup,
            isError: false,
            isUpdating: false,
        };
    case UPDATE_LANDMARK_LOADER:
        return {
            ...state,
            loading: action.payload.loading,
        };
    case ADD_LANDMARK_SUCCESS:
        return {
            ...state,
            loading: false,
            isDeleted: false,
            addedSuccessfully: true,
        };
    case ADD_LANDMARK_ERROR:
        return {
            ...state,
            loading: false,
            isError: true,
            isDeleted: false,
            error: action.payload.error,
            addedSuccessfully: false,
        };
    case DELETE_LANDMARK_SUCCESS:
        return {
            ...state,
            isError: false,
            addedSuccessfully: true,
            isDeleted: true,
        };
    case DELETE_LANDMARK_ERROR:
        return {
            ...state,
            isError: true,
            addedSuccessfully: false,
            error: action.payload.error,
            isDeleted: true,
        };
    case CLOSE_LANDMARK_EDIT_DIALOG:
        return {
            ...state,
            loading: false,
            addedSuccessfully: false,
            isDeleted: false,
            isError: false,
            error: '',
        };
    default:
        return state;
    }
};
