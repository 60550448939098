/* @flow */
export const FETCH_LANDMARK_LIST: string = 'FETCH_LANDMARK_LIST';
export const FETCH_LANDMARK_LIST_SUCCESS: string = 'FETCH_LANDMARK_LIST_SUCCESS';
export const FETCH_LANDMARK_LIST_ERROR: string = 'FETCH_LANDMARK_LIST_ERROR';
export const LANDMARK_LIST_NO_DATA_FOUND: string = 'Landmark list Data Not Found';
export const FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST: string = 'FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST';
export const FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST_SUCCESS: string = 'FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST_SUCCESS';
export const FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST_ERROR: string = 'FETCH_ALERT_CREATION_LANDMARKGROUPS_LIST_ERROR';
export const RESET_ALERT_CREATION_LANDMARKGROUPS_LIST: string = 'RESET_ALERT_CREATION_LANDMARKGROUPS_LIST';
export type LandmarksTypeAction = {
    type: string,
    payload?: Object,
};
