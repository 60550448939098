/* flow */
import React, { Component } from 'react';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import {
    Button,
    Card,
    CardActions,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    Select,
    Step,
    StepLabel,
    Stepper,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import queryString from 'query-string';
import styles from './LookStepper.module.scss';
import LookAssetCriteria from '../../SharedComponents/AssetCriteria/AssetCriteria';
import { STEPS } from '../../../containers/Looks/AddLook/constants.looks';
import AppLoader from '../../../components/AppLoader';
import config from '../../../constants/Config';
import { reduxConnect } from '../../../hoc';
import Dialog from '../../SharedComponents/Dialog/Dialog';
import { getAppToken, getUserUnit, getUserToken } from '../../../helper-classes/utility-functions';
import analytics from '../../../analytics/index';

type Props = {
    editLook: Boolean,
    history: {
        push: Function,
    },
    location: {
        search: any,
    },
    onCancel: Function,
};

type State = {
    selectedViz: string,
    activeStep: number,
    dateValue: string,
    assetCriteria: any,
    moduleNameValue: string,
    showDetails: boolean,
    openDialog: boolean,
    dialogActionType: string,
    tileTitle: string,
    userMetricPreference: string
}

const custTheme = createTheme({
    palette: {
        primary: { main: '#007bf9' },
        secondary: { main: '#11cb5f' },
    },
});

const stepStyles = theme => ({
    container: {
        width: '75%',
        height: '700px',
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    instructions: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 120,
    },
});

function getSteps() {
    return [STEPS.STEP1, STEPS.STEP2];
}

class LookStepper extends Component<Props, State> {
    state = {
        activeStep: 0,
        selectedViz: 'trendline',
        dateValue: '',
        speedValue: '',
        showDetails: false,
        tileTitle: null,
        userMetricPreference: getUserUnit(),
    };

    componentDidMount() {
        this.getModuleData();
    }

    vizTypes = [
        {
            code: 'trendline',
            name: 'Trendline',
            imgPath: '../images/looks/Trendline.svg',
            overlayText: 'A line chart visualization. Good for plotting data over time.',
        },
        {
            code: 'list',
            name: 'List',
            imgPath: '../images/looks/List.svg',
            overlayText: 'A list chart visualization. Shows data in table format.',
        },
        {
            code: 'heatmap',
            name: 'HeatMap',
            imgPath: '../images/looks/HeatMap.svg',
            overlayText: 'A map chart visualization. Populate data based on location.',
        },
    ];

    getModuleData = () => {
        const { id } = queryString.parse(this.props.location.search);
        if (id) {
            const userAuthToken = getUserToken();
            fetch(`${config.get('FLEET_VIEW_SERVICES_URL')}/dashboard-api/tiles/${id}`, {
                headers: {
                    'X-Nspire-UserToken': userAuthToken,
                    'Content-Type': 'application/json',
                    'X-Nspire-AppToken': getAppToken(),
                },
            })
                .then(response => response.json())
                .then((look) => {
                    this.setState({ tileTitle: look.title });
                    switch (look.look.query.vis_config.type) {
                    case 'looker_line':
                        this.setState({ selectedViz: 'trendline' });
                        break;
                    case 'table':
                        this.setState({ selectedViz: 'list' });
                        break;
                    case 'looker_map':
                        this.setState({ selectedViz: 'heatmap' });
                        break;
                    default:
                        this.setState({ selectedViz: 'trendline' });
                    }

                    this.setState({
                        moduleNameValue: look.look.title,
                        dateValue: this.getDateFilterValue(look.look.query.filters),
                        speedValue: this.getSpeedFilterValue(look.look.query.filters),
                        assetCriteria: this.filtersToAssetCriteria(look.look.query.filters),
                        showDetails: look.title.endsWith('true'),
                    });
                });
        }
    };

    getDateFilterValue = (filters) => {
        let dateValue = '';

        Object.keys(filters).forEach((key) => {
            if (key.indexOf('date') !== -1) {
                dateValue = filters[key];
            }
        });
        return dateValue;
    };

    getSpeedFilterValue = (filters) => {
        let speedFilter = '';
        Object.keys(filters).forEach((key) => {
            if (key.indexOf('violation_type') !== -1) {
                speedFilter = this.stripDoubleQuotes(filters[key]);
            }
        });
        return speedFilter;
    };

    filtersToAssetCriteria = (filters) => {
        const assetCriteria = {
            assets: [],
            groups: [],
            tags: [],
            type: '',
        };

        Object.keys(filters).forEach((key) => {
            if (key.indexOf('asset_global_id') !== -1) {
                filters[key].split(',')
                    .forEach((value) => {
                        assetCriteria.assets.push({ assetId: value });
                    });
            }
            if (key.indexOf('asset_group_id') !== -1) {
                filters[key].split(',')
                    .forEach((value) => {
                        assetCriteria.groups.push({ id: value });
                    });
            }
            if (key.indexOf('tag_value') !== -1) {
                filters[key].split(',')
                    .forEach((value) => {
                        assetCriteria.tags.push({ tag: value });
                    });
            }
        });
        if (assetCriteria.assets.length === 0 && assetCriteria.groups.length === 0
                && assetCriteria.tags.length === 0) {
            assetCriteria.type = 'all-assets';
        } else if (assetCriteria.assets.length === 0 && (assetCriteria.groups.length !== 0
            || assetCriteria.tags.length !== 0)) {
            assetCriteria.type = 'groups-tags';
        } else {
            assetCriteria.type = 'custom';
        }
        return assetCriteria;
    };

    handleSubmit = () => {
        let { lookType } = queryString.parse(this.props.location.search);
        const { id } = queryString.parse(this.props.location.search);
        switch (lookType) {
        case 'localFleetSpeedThresholdLook':
            lookType = 'speed_threshold';
            break;
        case 'localFleetPostedSpeedLook':
            lookType = 'posted_speed';
            break;
        case 'localFleetIdlingLook':
            lookType = 'idling';
            break;
        case 'localFleetDistanceTraveledLook':
            lookType = 'distance_traveled';
            break;
        case 'localFleetHardBrakingLook':
            lookType = 'hard_braking';
            break;
        case 'localFleetHardAccelerationLook':
            lookType = 'hard_acceleration';
            break;
        default:
            lookType = '';
        }

        if (lookType === '') {
            lookType = this.getLookTypeFromExistingTile();
        }
        const params = {
            feature: 'Dashboard',
            moduleType: lookType,
            moduleName: this.state.moduleNameValue,
        };
        if (this.props.editLook) {
            analytics.track('EDIT_MODULE', params);
        } else analytics.track('ADD_MODULE', params);
        const dashboardElementRequest = {
            lookTitle: this.state.moduleNameValue,
            showDetail: this.state.showDetails,
            mapLatitude: 74.12345,
            mapLongitude: -53.12345,
            mapZoomLevel: 2,
            visualizationType: this.state.selectedViz,
            titleRef: lookType,
            filters: [],
            mutableFilters: this.getWhitelistFilter(),
        };

        const filterCriteria = this.getFilterCriteria(this.state, lookType);
        dashboardElementRequest.filters = filterCriteria;

        if (id) {
            const reqUrl = `${config.get('FLEET_VIEW_SERVICES_URL')}/dashboard-api/dashboard/tiles/${id}`;
            fetch(reqUrl, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Nspire-UserToken': getUserToken(),
                    'X-Nspire-AppToken': getAppToken(),
                },
                body: JSON.stringify(dashboardElementRequest),
            })
                .then(
                    () => {
                        this.props.history.push('./dashboard');
                    },
                    () => {
                        this.setState({
                            dialogActionType: 'error',
                            openDialog: true,
                            activeStep: this.state.activeStep - 1,
                        });
                    },
                );
        } else {
            const reqUrl = `${config.get('FLEET_VIEW_SERVICES_URL')}/dashboard-api/dashboard/tiles`;
            fetch(reqUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Nspire-UserToken': getUserToken(),
                    'X-Nspire-AppToken': getAppToken(),
                },
                body: JSON.stringify(dashboardElementRequest),
            })
                .then(
                    () => {
                        this.props.history.push('./dashboard');
                    },
                    () => {
                        this.setState({
                            dialogActionType: 'error',
                            openDialog: true,
                            activeStep: this.state.activeStep - 1,
                        });
                    },
                );
        }
    };

    handleNext = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep + 1,
        });
        if (this.state.activeStep === getSteps().length - 1) {
            this.handleSubmit();
        }
    };
    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
        });
    };

    onAssetCriteriaChanged = (assetsData) => {
        this.setState({ assetCriteria: assetsData });
    };

    handleDateSelectChange = (event) => {
        this.setState({ dateValue: event.target.value });
    };

    handleSpeedSelectChange = (event) => {
        this.setState({ speedValue: event.target.value });
    };

    handleRadioClick = (rdo) => {
        this.setState({
            selectedViz: rdo,
            showDetails: (rdo === 'list'),
        });
    };

    getDateCriteria() {
        return (
            <span className={styles['criteria-block']}>
                <div className={styles['module-header-name']}>Date Criteria *</div>
                <form className={stepStyles.root}>
                    <FormControl className={stepStyles.formControl}>
                        <InputLabel sx={{ '&.MuiInputLabel-root': { left: '-14px', top: '8px' } }} htmlFor="viz-date-select">Select Date Range</InputLabel>
                        <Select
                            variant="standard"
                            value={this.state.dateValue}
                            onChange={this.handleDateSelectChange}
                            className={styles['module-select']}
                            inputProps={{
                                name: 'select',
                                id: 'viz-date-select',
                            }}
                        >
                            <MenuItem value="yesterday">Yesterday</MenuItem>
                            <MenuItem value="7 days ago for 7 days">Last 7 Days</MenuItem>
                            <MenuItem value="30 days ago for 30 days">Last 30 Days</MenuItem>
                            <MenuItem value="last month">Last Month</MenuItem>
                            <MenuItem value="3 months ago for 3 months">Last 3 Months</MenuItem>
                            <MenuItem value="last quarter">Last Quarter</MenuItem>
                            <MenuItem value="last year">Last Year</MenuItem>
                            <MenuItem value="13 months ago for 13 months">Last 13 Months</MenuItem>
                        </Select>
                    </FormControl>
                </form>
            </span>
        );
    }

    getNameCriteria() {
        return (
            <span className={styles['criteria-block']}>
                <form className={stepStyles.root}>
                    <FormControl className={stepStyles.formControl}>
                        <TextField
                            variant="standard"
                            label="Module Name"
                            className={styles['module-text']}
                            inputProps={{
                                name: 'text',
                                id: 'viz-module-name',
                            }}
                            value={this.state.moduleNameValue}
                            onChange={e => this.setState({ moduleNameValue: e.target.value })}
                        />
                    </FormControl>
                </form>
            </span>
        );
    }

    getLookTypeRef() {
        let { lookType } = queryString.parse(this.props.location.search);
        if (lookType === undefined || lookType === '') {
            if (this.state.tileTitle.includes('speed_threshold')) {
                lookType = 'localFleetSpeedThresholdLook';
            } else if (this.state.tileTitle.includes('posted_speed')) {
                lookType = 'localFleetPostedSpeedLook';
            }
        } else if (this.state.speedValue) {
            if (this.state.speedValue.search('EST') !== -1) {
                lookType = 'localFleetSpeedThresholdLook';
            }
            if (this.state.speedValue.search('EPS') !== -1) {
                lookType = 'localFleetPostedSpeedLook';
            }
        }
        return lookType;
    }

    getSettingsCritera() {
        switch (this.getLookTypeRef()) {
        case 'localFleetSpeedThresholdLook':
            return (
                <span className={styles['criteria-block']}>
                    <div className={styles['module-header-name']}>Settings *</div>
                    <form className={stepStyles.root}>
                        <FormControl className={stepStyles.formControl}>
                            <InputLabel sx={{ '&.MuiInputLabel-root': { left: '-14px', top: '8px' } }} htmlFor="viz-speed-select">Select Speed Threshold</InputLabel>
                            <Select
                                variant="standard"
                                value={this.state.speedValue}
                                onChange={this.handleSpeedSelectChange}
                                className={styles['module-select']}
                                inputProps={{
                                    name: 'select',
                                    id: 'viz-speed-select',
                                }}
                            >
                                <MenuItem value="EST_56_DASH">Greater than {this.state.userMetricPreference === 'english' ? '55 MPH' : '89 KPH'}</MenuItem>
                                <MenuItem value="EST_61_DASH">Greater than {this.state.userMetricPreference === 'english' ? '60 MPH' : '97 KPH'}</MenuItem>
                                <MenuItem value="EST_66_DASH">Greater than {this.state.userMetricPreference === 'english' ? '65 MPH' : '105 KPH'}</MenuItem>
                                <MenuItem value="EST_71_DASH">Greater than {this.state.userMetricPreference === 'english' ? '70 MPH' : '113 KPH'}</MenuItem>
                                <MenuItem value="EST_76_DASH">Greater than {this.state.userMetricPreference === 'english' ? '75 MPH' : '121 KPH'}</MenuItem>
                                <MenuItem value="EST_81_DASH">Greater than {this.state.userMetricPreference === 'english' ? '80 MPH' : '129 KPH'}</MenuItem>
                            </Select>
                        </FormControl>
                    </form>
                </span>
            );
        case 'localFleetPostedSpeedLook':
            return (
                <span className={styles['criteria-block']}>
                    <div className={styles['module-header-name']}>Settings *</div>
                    <form className={stepStyles.root}>
                        <FormControl className={stepStyles.formControl}>
                            <InputLabel sx={{ '&.MuiInputLabel-root': { left: '-14px', top: '8px' } }} htmlFor="viz-speed-select">Select Posted Speed Limit</InputLabel>
                            <Select
                                variant="standard"
                                value={this.state.speedValue}
                                onChange={this.handleSpeedSelectChange}
                                className={styles['module-select']}
                                inputProps={{
                                    name: 'select',
                                    id: 'viz-speed-select',
                                }}
                            >
                                <MenuItem value="EPS_10_DASH">{this.state.userMetricPreference === 'english' ? '10 MPH' : '16 KPH'} Over Posted Speed</MenuItem>
                                <MenuItem value="EPS_20_DASH">{this.state.userMetricPreference === 'english' ? '20 MPH' : '32 KPH'} Over Posted Speed</MenuItem>
                                <MenuItem value="EPS_30_DASH">{this.state.userMetricPreference === 'english' ? '30 MPH' : '48 KPH'} Over Posted Speed</MenuItem>
                            </Select>
                        </FormControl>
                    </form>
                </span>
            );
        default:
            return '';
        }
    }

    renderVizTypes() {
        return this.vizTypes.map((viz) => {
            const card = (
                <Card key={viz.code} className={styles['lookimg-container']}>
                    <Radio
                        name="look-viz-select"
                        checked={this.state.selectedViz === viz.code}
                        className={styles['module-viz-radio']}
                        color="primary"
                        onChange={() => this.handleRadioClick(viz.code)}
                    />
                    <div className={styles['overlay-image']}>
                        <img src={viz.imgPath} className={styles.image} alt="" />
                        <div className={styles.hover}>
                            <div style={{ lineHeight: '1.3' }} className={styles.text}>{viz.overlayText}</div>
                        </div>
                    </div>
                    <CardActions className={styles['look-action']}>
                        <p className={styles['look-name']}>{viz.name}</p>
                    </CardActions>
                </Card>
            );
            return card;
        });
    }

    getStepContent = (step) => {
        switch (step) {
        case 0:
            return (
                <div className={styles['step-content']}>
                    <LookAssetCriteria
                        onAssetCriteriaChanged={this.onAssetCriteriaChanged}
                        assetCriteria={this.state.assetCriteria}
                        assetFilter={[['deviceId', 'exists:yes']]}
                    />
                </div>
            );
        case 1:
            return (
                <div className={styles['step-content']}>
                    <div style={{ float: 'left', width: '576px' }}>
                        { this.getNameCriteria() }
                        <div className={styles['clear-fix']} />
                        { this.getDateCriteria() }
                        { this.getSettingsCritera() }
                        <div className={styles['clear-fix']} />
                        <div className={styles['module-header-name']}>Visualization Type</div>
                        <div className={styles['clear-fix']} />
                        <Grid container className={styles['col-md-5ths']}>
                            { this.renderVizTypes() }
                        </Grid>
                    </div>
                    <div style={{ float: 'left', width: '327px' }}>
                        <div style={{ float: 'right', width: '180px' }}>
                            <span className={styles['module-header-name']}>Show Detail?</span>
                            <div style={{ marginTop: '23px' }}>
                                <Switch checked={this.state.showDetails} color="primary" onClick={() => this.showDetailsChange()} />
                            </div>
                        </div>
                    </div>
                    <div className={styles['clear-fix']} />
                </div>
            );
        default:
            return <div className={styles['step-content']} />;
        }
    };

    showDetailsChange = () => {
        if (this.state.showDetails) {
            this.setState({ showDetails: false });
        } else {
            this.setState({ showDetails: true });
        }
    };

    getTagRefs = (tagRefs) => {
        const results = [];

        tagRefs.forEach((tag) => {
            results.push(tag.tag);
        });

        return results;
    };

    getAssetRefs = (assetRefs) => {
        const results = [];
        assetRefs.forEach((asset) => {
            if (asset.assetId != null && asset.assetId !== undefined) {
                results.push(asset.assetId);
            } else {
                results.push(asset.id);
            }
        });
        return results;
    };

    getAssetGroupRefs = (assetGroupRefs) => {
        const results = [];
        assetGroupRefs.forEach((assetGroup) => {
            results.push(assetGroup.id);
        });
        return results;
    };

    /**
     Given a reference to a selected 'look type', return the name of the look.
     This will be used in building the filter criteria passed to the API call.
     */
    getLookRef = (lookType) => {
        switch (lookType) {
        case 'distance_traveled':
            return 'trip_summaries_1';
        case 'hard_acceleration':
            return 'trip_violations_1';
        case 'hard_braking':
            return 'trip_violations_1';
        case 'idling':
            return 'idle_summaries_1';
        case 'posted_speed':
            return 'trip_violations_1';
        case 'speed_threshold':
            return 'trip_violations_1';
        default:
            return 'undefined';
        }
    };

    getLookAndAttrReference = (lookRef, criteriaType) => {
        if (criteriaType === 'tag') {
            return 'dn_asset_tags.tag_value';
        }
        const lookName = this.getLookRef(lookRef);
        if (criteriaType === 'asset') {
            return `${lookName}.asset_global_id`;
        }
        return `${lookName}.asset_group_id`;
    };

    getGeneralFilterCriteria = (currentStateRef, lookType) => {
        const filters = [];
        // FLINT-98
        const dateCriteria = {};
        switch (lookType) {
        case 'distance_traveled':
            dateCriteria['trip_summaries_1.trip_start_date'] = currentStateRef.dateValue;
            break;
        case 'speed_threshold':
            dateCriteria['trip_violations_1.violation_begin_date'] = currentStateRef.dateValue;
            break;
        case 'hard_acceleration':
            dateCriteria['trip_violations_1.violation_begin_date'] = currentStateRef.dateValue;
            break;
        case 'posted_speed':
            dateCriteria['trip_violations_1.violation_begin_date'] = currentStateRef.dateValue;
            break;
        case 'idling':
            dateCriteria['idle_summaries_1.idle_start_date'] = currentStateRef.dateValue;
            break;
        case 'hard_braking':
            dateCriteria['trip_violations_1.violation_begin_date'] = currentStateRef.dateValue;
            break;
        default:
        }

        filters.push(dateCriteria);

        if (lookType === 'posted_speed' || lookType === 'speed_threshold') {
            if (this.state.speedValue !== null && this.state.speedValue !== '') {
                const speedCriteria = {};
                speedCriteria['trip_violations_1.violation_type'] = currentStateRef.speedValue;
                filters.push(speedCriteria);
            }
        }

        return filters;
    };

    getFilterCriteriaForAssets = (currentStateRef, lookType) => {
        const filters = [];
        if (currentStateRef.assetCriteria) {
            const assetRefs = this.getAssetRefs(currentStateRef.assetCriteria.assets);
            const filterRef = this.getLookAndAttrReference(lookType, 'asset');
            assetRefs.forEach((assetCriteria) => {
                let filterExists = false;
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < filters.length; i++) {
                    if (filters[i][filterRef]) {
                        filters[i][filterRef] = `${filters[i][filterRef]},${assetCriteria}`;
                        filterExists = true;
                    }
                }
                if (filterExists === false) {
                    const newEntry = {};
                    newEntry[filterRef] = `${assetCriteria}`;
                    filters.push(newEntry);
                }
            });
        }
        return filters;
    };

    getFilterCriteriaForAssetGroups = (currentStateRef, lookType) => {
        const filters = [];
        if (currentStateRef.assetCriteria) {
            const assetGroupRefs = this.getAssetGroupRefs(currentStateRef.assetCriteria.groups);
            const filterRef = this.getLookAndAttrReference(lookType, 'assetGroup');
            assetGroupRefs.forEach((assetGroupCriteria) => {
                let filterExists = false;
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < filters.length; i++) {
                    if (filters[i][filterRef]) {
                        filters[i][filterRef] = `${filters[i][filterRef]},${assetGroupCriteria}`;
                        filterExists = true;
                    }
                }
                if (filterExists === false) {
                    const newEntry = {};
                    newEntry[filterRef] = `${assetGroupCriteria}`;
                    filters.push(newEntry);
                }
            });
        }
        return filters;
    };

    getFilterCriteriaForTags = (currentStateRef, lookType) => {
        const filters = [];
        if (currentStateRef.assetCriteria) {
            const tagRefs = this.getTagRefs(currentStateRef.assetCriteria.tags);
            const filterRef = this.getLookAndAttrReference(lookType, 'tag');

            tagRefs.forEach((tagCriteria) => {
                let filterExists = false;
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < filters.length; i++) {
                    if (filters[i][filterRef]) {
                        filters[i][filterRef] = `${filters[i][filterRef]},${tagCriteria}`;
                        filterExists = true;
                    }
                }
                if (filterExists === false) {
                    const newEntry = {};
                    newEntry[filterRef] = `${tagCriteria}`;
                    filters.push(newEntry);
                }
            });
        }
        return filters;
    };

    getFilterCriteria = (currentStateRef, lookType) => {
        let filters = [];
        let tmp = this.getFilterCriteriaForAssets(currentStateRef, lookType);
        filters = filters.concat(tmp);
        tmp = this.getFilterCriteriaForAssetGroups(currentStateRef, lookType);
        filters = filters.concat(tmp);
        tmp = this.getFilterCriteriaForTags(currentStateRef, lookType);
        filters = filters.concat(tmp);
        tmp = this.getGeneralFilterCriteria(currentStateRef, lookType);
        filters = filters.concat(tmp);
        return filters;
    };

    getDialogType = () => {
        const { dialogActionType } = this.state;
        let type = '';
        if (dialogActionType === 'error') {
            type = 'error';
            this.button = [
                {
                    title: 'OK',
                    color: 'primary',
                    handleClick: () => this.setState({ dialogActionType: '', openDialog: false }),
                },
            ];
        }
        return type;
    };

    getDialogContent = () => {
        const { dialogActionType } = this.state;
        let content = '';
        if (dialogActionType === 'error') {
            content = 'There was an issue creating this module. Please check your options and try again or contact customer service.';
        }
        return content;
    };

    /**
     * Retrieve a list of filters that may be updated by the UI: Only these filters will be
     * updated, and any filters that are not listed here will remain unchanged after an
     * add/update tile operation.
     */
    getWhitelistFilter = () => {
        const filters = ['idle_summaries_1.asset_group_id',
            'dn_asset_tags.tag_value',
            'idle_summaries_1.asset_global_id',
            'trip_summaries_1.asset_group_id',
            'trip_summaries_1.asset_global_id',
            'trip_violations_1.asset_group_id',
            'trip_violations_1.asset_global_id',
            'trip_summaries_1.trip_start_date',
            'idle_summaries_1.idle_start_date',
            'trip_violations_1.violation_begin_date'];
        return filters;
    }

    getLookTypeFromExistingTile = () => {
        const tileRef = this.state.tileTitle;
        if (tileRef.startsWith('distance_traveled')) {
            return 'distance_traveled';
        } else if (tileRef.startsWith('speed_threshold')) {
            return 'speed_threshold';
        } else if (tileRef.startsWith('hard_acceleration')) {
            return 'hard_acceleration';
        } else if (tileRef.startsWith('posted_speed')) {
            return 'posted_speed';
        } else if (tileRef.startsWith('idling')) {
            return 'idling';
        } else if (tileRef.startsWith('hard_braking')) {
            return 'hard_braking';
        }
        return null;
    }

    stripDoubleQuotes = (val) => {
        const safeVal = val.replace(/"/g, '');
        return safeVal;
    }

    isDisabled = (buttonName) => {
        if (buttonName === 'Next') {
            return false;
        }

        if (buttonName === 'Save' || buttonName === 'Update') {
            if (this.state.dateValue === null || this.state.dateValue === '') {
                return true;
            }
            const lookType = this.getLookTypeRef();
            if (lookType === 'localFleetSpeedThresholdLook' || lookType === 'localFleetPostedSpeedLook') {
                if (this.state.speedValue === null || this.state.speedValue === '') {
                    return true;
                }
            }
        }
        return false;
    }

    render() {
        const steps = getSteps();
        const { activeStep } = this.state;
        let buttonName = STEPS.NEXT;
        if (activeStep === steps.length - 1) {
            buttonName = (this.props.editLook) ? STEPS.UPDATE : STEPS.SAVE;
        }

        return (
            <div>
                <div className={styles['step-container']}>
                    <div className={styles['step-header']}>
                        <button
                            id="backArrow"
                            className={styles['no-focus']}
                            style={{ textDecoration: 'none' }}
                            onClick={this.props.onCancel}
                        >
                            <i className={styles['back-icon']} />
                            <span>
                                &nbsp;&nbsp;
                                {(this.props.editLook) ? STEPS.EDIT_LOOK : STEPS.ADD_LOOK}
                            </span>
                        </button>
                    </div>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={custTheme}>
                            <Stepper activeStep={activeStep} sx={{ padding: '24px' }}>
                                {
                                    steps.map(label => (
                                        <Step key={label}>
                                            <StepLabel id={label.replace(/\s+/g, '')}>
                                                {label}
                                            </StepLabel>
                                        </Step>
                                    ))
                                }
                            </Stepper>
                            <Divider className={styles['divider-shadow']} />
                            <div>
                                {
                                    activeStep === steps.length ? (
                                        <div>
                                            <AppLoader
                                                loaderStyle={{
                                                    position: 'absolute',
                                                    left: '50%',
                                                    top: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    'text-align': 'center',
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                this.getStepContent(activeStep)
                                            }
                                            <div>
                                                <Divider className={styles['divider-shadow']} />
                                                {this.state.activeStep === 0 && (
                                                    <div className={styles['step-req']}>
                                                        {STEPS.REQ_FIELD}
                                                    </div>
                                                )}
                                                <Typography
                                                    component="div"
                                                    align="right"
                                                    className={styles['button-container']}
                                                >
                                                    <Button
                                                        id="cancelBtn"
                                                        color="primary"
                                                        onClick={this.props.onCancel}
                                                        className={stepStyles.button}
                                                    >
                                                        {STEPS.CANCEL}
                                                    </Button>
                                                    {this.state.activeStep > 0 && (
                                                        <Button
                                                            id="backBtn"
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={activeStep <= 0}
                                                            onClick={this.handleBack}
                                                            className={styles['back-button']}
                                                        >
                                                            {STEPS.BACK}
                                                        </Button>
                                                    )}
                                                    <Button
                                                        id="nextBtn"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={this.isDisabled(buttonName)}
                                                        onClick={this.handleNext}
                                                    >
                                                        {buttonName}
                                                    </Button>
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </div>
                <Dialog
                    open={this.state.openDialog}
                    type={this.getDialogType()}
                    customTitle=""
                    button={this.button}
                    content={this.getDialogContent()}
                    size="lg"
                />
            </div>
        );
    }
}

export default withStyles(stepStyles)(reduxConnect(LookStepper));
