/* @flow */

import config, { FVS_MAX_DATA_LIMIT } from './../../constants/Config';
import { getAuthorizationHeader, getGrailsAppBasedURL, getUserToken } from '../../helper-classes/utility-functions';

export const flattenGroups = (data: any) => {
    const groups = [];
    if (data) {
        const addCandidate = (candidate, nestLevel) => {
            groups.push({
                id: candidate.id,
                name: candidate.name,
                nestLevel,
                childNodes: candidate.children ? candidate.children.map(c => c.name) : [],
                children: candidate.children,
                tag: undefined,
            });
        };

        const addChildren = (children, nestLevel) => {
            const arr1 = Object.keys(children).map(key => children[key]);
            arr1.forEach((child) => {
                addCandidate(child, nestLevel);
                if (child.children) addChildren(child.children, nestLevel + 1);
            });
        };

        if (data.length > 0) {
            data.forEach((candidate) => {
                addCandidate(candidate, 0);
                if (candidate.children) addChildren(candidate.children, 1);
            });
        }
    }
    return groups;
};

export function getAssetGroupsFVS() {
    const url = `${config.get('FLEET_VIEW_SERVICES_URL')}/assetgroups`;
    const options = {
        method: 'POST',
        headers: {
            'X-Nspire-UserToken': getUserToken(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            limit: FVS_MAX_DATA_LIMIT,
            sortParam: [['name', 'asc']],
            start: 0,
            type: 'parentChildList',
        }),
    };
    return fetch(url, options)
        .then(response => response.json()).then((response) => {
            const groups = flattenGroups(response.data);
            return { data: groups, total: groups.length };
        });
}

export const getAssetGroupById = (globalId: string) => {
    const url = `${getGrailsAppBasedURL()}/operation/json/localFleetAssetGroupService/getAssetGroups?globalId=${globalId}`;
    const options = {
        method: 'GET',
        headers: {
            Authorization: getAuthorizationHeader(),
            'Content-Type': 'application/json',
        },
    };
    return fetch(url, options)
        .then(response => response.json()).then(response => ({ data: response.data || {} }));
};
