/* @flow */
import React, { Component } from 'react';
import { Polygon } from 'react-google-maps';
import CustomMarker from './CustomMarker';
import { mapColor } from './../constants.map';
import { calculateRadius } from './Rectangle';

const shapeOption = {
    fillColor: mapColor.landmark.fillColor,
    strokeColor: mapColor.landmark.strokeColor,
};

type Props = {
    google: Object,
    poly: Object,
    center: Object,
    getPolygonValue: Function,
    updateShape: Function,
}

type State = {
    point: any;
    center: Object,
    isDragStart: boolean,
}

class CustomPolygon extends Component<Props, State> {
    poly: any;
    point: any;
    PolygonRef: any;
    timeOutClear: any;

    constructor(props: Props) {
        super(props);
        const { center } = this.props;
        this.state = {
            point: this.props.poly.points.map(d => new this.props.google.maps.LatLng(d[0], d[1])),
            isDragStart: false,
            center: { lat: center.lat, lng: center.lng },
        };
        this.PolygonRef = React.createRef();
    }

    componentDidMount() {
        const { getPolygonValue } = this.props;
        if (getPolygonValue && typeof getPolygonValue === 'function') getPolygonValue(this.getPolygonValue);
    }

    getPolygonValue = () => {
        const vertices = this.PolygonRef.current.getPath();
        const bounds = new this.props.google.maps.LatLngBounds();

        const newPoints = [];
        for (let i = 0; i < vertices.getLength(); i += 1) {
            const latlng = vertices.getAt(i);
            newPoints.push(latlng);
            bounds.extend(latlng);
        }

        const north = bounds.getNorthEast().lat();
        const east = bounds.getNorthEast().lng();
        const south = bounds.getSouthWest().lat();
        const west = bounds.getSouthWest().lng();
        const center = bounds.getCenter();

        let radius = 0;
        if (bounds && center) radius = calculateRadius(bounds, this.props.google);

        return {
            center,
            radius,
            points: newPoints,
            bounds: {
                north, south, east, west,
            },
        };
    }

    vertexClick = (e: Object) => {
        if (e.vertex || e.vertex === 0) {
            clearTimeout(this.timeOutClear);
            const vertices = this.PolygonRef.current.getPath();
            let bounds = new this.props.google.maps.LatLngBounds();

            const newPoints = [];
            for (let i = 0; i < vertices.getLength(); i += 1) {
                const latlng = vertices.getAt(i);
                newPoints.push(latlng);
            }

            let newPoint = newPoints.filter(d => !d.equals(e.latLng));
            newPoint.forEach(d => bounds.extend(d));
            if (newPoint.length < 2) {
                const lat = newPoint[0].lat();
                const lng = newPoint[0].lng();
                const north = lat + 0.0040;
                const south = lat - 0.0040;
                const east = lng + 0.0059;
                const west = lng - 0.0059;

                newPoint = [new this.props.google.maps.LatLng(south, west),
                    new this.props.google.maps.LatLng(south, east),
                    new this.props.google.maps.LatLng(north, east),
                    new this.props.google.maps.LatLng(north, west),
                ];

                bounds = new this.props.google.maps.LatLngBounds();
                bounds.extend(new this.props.google.maps.LatLng(south, west));
                bounds.extend(new this.props.google.maps.LatLng(south, east));
                bounds.extend(new this.props.google.maps.LatLng(north, east));
                bounds.extend(new this.props.google.maps.LatLng(north, west));
            }

            this.setState({
                point: newPoint,
                center: {
                    lat: bounds.getCenter().lat(),
                    lng: bounds.getCenter().lng(),
                },
            });
            this.updatePolygonPoint(newPoint, bounds.getCenter());
        }
    }

    updatePolygonPoint = (newPoint: Array<Object>, center: Object) => {
        const points = newPoint.map(d => [d.lat(), d.lng()]);
        this.props.updateShape('editablePolygon', { radius: 0, points }, { lat: center.lat(), lng: center.lng() });
    }

    calculateCenter = () => {
        const { points, center } = this.getPolygonValue();
        this.setState({ center: { lat: center.lat(), lng: center.lng() } });
        this.timeOutClear = setTimeout(() => this.updatePolygonPoint(points, center), 100);
    };

    render() {
        const { point, center, isDragStart } = this.state;
        const { poly } = this.props;
        const options = {
            strokeColor: (poly && poly.lineColorValue)
                ? poly.lineColorValue : shapeOption.strokeColor,
            strokeOpacity: 0.8,
            strokeWeight: (poly && poly.lineWidth >= 0)
                ? poly.lineWidth : 2,
            fillColor: (poly && poly.fillColorValue)
                ? poly.fillColorValue : shapeOption.fillColor,
            fillOpacity: (poly && poly.transparency >= 0)
                ? ((100 - poly.transparency) / 100) : 0.35,
        };

        return (
            <React.Fragment>
                <Polygon
                    paths={point}
                    ref={this.PolygonRef}
                    clickable
                    editable
                    draggable
                    options={options}
                    onClick={e => this.vertexClick(e)}
                    onMouseUp={() => this.calculateCenter()}
                    onDragStart={() => this.setState({ isDragStart: true })}
                    onDragEnd={() => {
                        this.calculateCenter();
                        this.setState({ isDragStart: false });
                    }}
                />
                {!isDragStart &&
                    <CustomMarker
                        lat={center.lat || 0}
                        lng={center.lng || 0}
                        imageType={poly.iconValue}
                        iconColor={poly.iconColorValue}
                    />
                }
            </React.Fragment>
        );
    }
}

export default CustomPolygon;

