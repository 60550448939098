import React, { Component } from 'react';
import Input from '@mui/material/Input';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import SearchIcon from '@mui/icons-material/Search';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import styles from './AssetSearch.module.scss';
import * as actions from './actions.assetSearch';
import { reduxConnect } from '../../../hoc';
import SearchBox from '../../SharedComponents/SearchBox';

type Props = {
    assetSearch: Function,
    // eslint-disable-next-line react/no-unused-prop-types
    assetSearchResults: [],
    handleRowClick: Function,
    handleClearField: Function,
};
type State = {
    tableData: {
        listData: Array<{}>,
        totalRecords: number,
    },
    search: string,
    displayMsg: string,
    textActive: string,
    showResults: boolean,
    rowsPerPage: number,
    page: number,
};

const minimumChar: number = 3;
class AssetSearch extends Component<Props, State> {
    searchBoxRef: Input;
    assetSearchHandler: Function;
    valueChange: Function;
    constructor(props: Props) {
        super(props);
        this.state = {
            tableData: {
                listData: [],
                totalRecords: 0,
            },
            search: '',
            displayMsg: '',
            textActive: '',
            showResults: false,
            page: 0,
            rowsPerPage: 10,
        };
        this.valueChange = this.valueChange.bind(this);
        this.assetSearchHandler = this.assetSearchHandler.bind(this);
    }

    componentDidMount() {
        this.updateInputBox(this.state.showResults);
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (nextprops.assetSearchResults.data) {
            this.setState({
                tableData: {
                    listData: nextprops.assetSearchResults.data,
                    totalRecords: nextprops.assetSearchResults.total,
                },
            });
        }
    }

    valueChange(key: string, event: HTMLInputElement) {
        const { search, displayMsg } = this.state;
        this.setState({
            [key]: event.value,
            displayMsg: (search.length >= (minimumChar - 1)) ? '' : displayMsg,
        }, () => {
            const { search: latestSearch } = this.state;
            if (latestSearch.length >= minimumChar) {
                this.loadNextPage(this.state.page, this.state.rowsPerPage);
                this.setState({ showResults: true });
            } else {
                this.setState({ showResults: false });
            }
            if (latestSearch.length === 0) this.props.handleClearField();
        });
    }

    updateInputBox(showResult: any) {
        if (showResult && showResult.query.length >= minimumChar) {
            this.setState({
                search: showResult.query,
            });
        }
    }

    handleRowClick(selectedRow) {
        this.setState({ search: selectedRow.name || selectedRow.id, showResults: false });
        this.props.handleRowClick(selectedRow);
    }

    assetSearchHandler(event: KeyboardEvent) {
        event.preventDefault();
        const { search } = this.state;
        let stateObj = { displayMsg: '', search: '' };
        if (event.keyCode === 13) {
            if (search.length >= minimumChar) {
                stateObj = {
                    displayMsg: '',
                    textActive: styles['txt-active'],
                    showSearchBox: true,
                };
            } else {
                stateObj = {
                    textActive: styles['txt-active'],
                    displayMsg: `Please enter at least ${minimumChar} characters to perform the search.`,
                    showSearchBox: false,
                };
            }
            this.setState(stateObj);
        }
    }

    loadNextPage(page: any, rowsPerPage: number) {
        this.props.assetSearch(this.state.search, page, rowsPerPage);
    }

    handleChangePage(event, page) {
        this.setState({ page });
        this.loadNextPage(page, this.state.rowsPerPage);
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    }

    render() {
        const {
            search,
            displayMsg,
            textActive,
        } = this.state;

        const classes = `${styles['search-bar']} ${textActive}`;
        return (
            <div className={styles['search-dropdown-holder']} >
                <div className={styles.searchIcon} >
                    <SearchIcon onClick={() => this.searchBoxRef.focus()} />
                </div>
                <SearchBox
                    id="search-box"
                    data-qa="search-element"
                    inputRef={(input) => {
                        this.searchBoxRef = input;
                        return this.searchBoxRef;
                    }}
                    type="text"
                    placeholder="Search for Asset"
                    endAdornment={
                        (displayMsg && (
                            <div id="search-box-error-container" className={styles['search-box-error-container']}>
                                <i className={styles['error-icon']} />
                                <div id="error-message" data-qa="error-message" className={styles['error-msg']}>{displayMsg}</div>
                            </div>
                        )
                        )}
                    className={classes}
                    disableUnderline
                    value={search}
                    onChange={e => this.valueChange('search', e.target)}
                    onKeyUp={this.assetSearchHandler}

                />
                { this.state.showResults ?
                    <Paper className={styles['results-window']}>
                        <div className={styles.tableWrapper}>
                            <Table className={styles['results-table']}>
                                <TableBody>
                                    {this.state.tableData.listData ?
                                        this.state.tableData.listData.map(row =>
                                            (
                                                <TableRow
                                                    id={row.id}
                                                    onClick={() => this.handleRowClick(row)}
                                                >
                                                    <TableCell>
                                                        {row.name ? row.name : row.id}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        : null
                                    }
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            component="div"
                            id="table-pagination"
                            count={this.state.tableData.totalRecords}
                            rowsPerPage={this.state.rowsPerPage}
                            rowsPerPageOptions={[10, 20, 30]}
                            page={this.state.page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onPageChange={(event, pageNumber) =>
                                this.handleChangePage(event, pageNumber)}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                    : null
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    assetSearchResults: state.assetSearch,
});

export default reduxConnect(AssetSearch, actions, mapStateToProps);
