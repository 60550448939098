/* @flow */
// add epics from all containers here
import { combineEpics } from 'redux-observable/lib/cjs/combineEpics';
import home from '../containers/Home/epics.home';
import ConfigureDashboard from '../containers/ConfigureDashboard/epics.configureDashboard';
import login from '../containers/Login/epics.login';
import globalSearch from '../containers/Header/epics.globalsearch';
import landmarks from '../containers/Landmark/LandmarkDetails/epics.landmarks';
import landmarkGroup from '../containers/Landmark/LandmarkGroup/epics.landmarkGroup';
import accountDetails from '../containers/UserAccount/epics.userAccount';
import settings from '../containers/Settings/epics.settings';
import assets from '../containers/Assets/epics.assets';
import trips from '../containers/Trips/epics.trips';
import assetGroup from '../containers/AssetGroup/epics.assetgroup';
import address from '../containers/Address/epics.address';
import alertlandingPage from '../containers/Alerts/AlertLandingPage/epics.landingPage';
import assetList from '../containers/AssetList/epics.assetList';
import AssetGroupList from '../containers/AssetGroupList/epics.assetGroupList';
import landmarksList from '../containers/Landmark/LandmarksList/epics.landmarksList';
import scoreCardsList from '../containers/ScoreCardList/epics.scorecardsList';
import scoreDriverList from '../containers/ScoreCardDriverList/epics.scoredriverList';
import scoreVehicleList from '../containers/ScoreCardVehicleList/epics.scoreVehicleList';
import landmarkGroupsList from '../containers/Landmark/LandmarkGroupsList/epics.landmarkGroupsList';
import spudMessage from '../containers/BannerNotification/epics.spudNotification';
import alertRecipient from '../containers/Alerts/AlertRecipient/epic.alertRecipient';
import planList from '../containers/Maintenance/ServicePlans/epics.planList';
import saveAlert from '../containers/Alerts/Steppers/epic.stepers';
import devicesList from '../containers/DeviceList/epics.deviceList';
import driverList from '../containers/DriverList/epics.driverList';
import addDriver from '../containers/AddDriver/epics.addDriver';
import recordList from '../containers/Maintenance/ServiceRecords/epics.recordList';
import maintenanceStatus from '../containers/Maintenance/MaintenanceStatus/epics.maintenanceStatus';
import alertList from '../containers/Maintenance/Alerts/epics.alertList';
import driverScorecardTrips from '../containers/Driver/ScoreCardTrips/epics.scoreCardTrips';
import alertHistoryList from '../containers/Alerts/AlertHistory/epics.alertHistory';
import assetGroupManage from '../containers/AssetGroupManage/epics.assetGroupManage';
import alertNameList from '../containers/Alerts/Steppers/AlertNameCheck/epics.alertNameCheck';
import userList from '../containers/UserList/epics.userList';
import userRoleList from '../containers/UserRoleList/epics.userRoleList';
import userAccessList from '../containers/UserAccessList/epics.userAccessList';
import alertLandmarks from '../containers/Alerts/AlertTypes/epic.landmarks';
import savedAlertsList from '../containers/Alerts/SavedAlerts/epics.savedAlerts';
import AddLandmark from '../containers/Landmark/AddLandmark/epics.landmark';
import dispatchAction from '../containers/Assets/DispatchAction/epics.dispatchAction';
import user from '../containers/User/AddUser/epics.user';
import { assetSearch } from '../components/SharedComponents/AssetSearch/epics.assetSearch';
import landmarkGroupManage from '../containers/Landmark/LandmarkGroupManage/epics.landmarkGroupManage';
import userRole from '../containers/UserRole/AddUserRole/epics.userRole';
import recipients from '../containers/recipient/epics.recipientList';
import driverSettings from '../containers/Driver/Settings/epics.driverSettings';
import nearestAssetLandmark from '../containers/NearestAssetLandmark/epics.nearestAssetLandmark';
import diagnosticList from '../containers/Maintenance/Diagnostics/epics.diagnosticList';
import SendCommand from '../containers/Assets/SendCommand/epics.sendCommand';
import kml from '../containers/Kml/epics.kml';
import AppNotifications from '../components/SharedComponents/NotificationHandler/epics.appNotifications';

export default combineEpics(
    home,
    ConfigureDashboard,
    login,
    globalSearch,
    landmarks,
    accountDetails,
    landmarkGroup,
    settings,
    assets,
    trips,
    assetGroup,
    address,
    alertlandingPage,
    assetList,
    AssetGroupList,
    landmarksList,
    landmarkGroupsList,
    spudMessage,
    alertRecipient,
    scoreCardsList,
    scoreDriverList,
    scoreVehicleList,
    planList,
    alertRecipient,
    saveAlert,
    devicesList,
    driverList,
    recordList,
    maintenanceStatus,
    alertList,
    driverScorecardTrips,
    alertHistoryList,
    addDriver,
    assetGroupManage,
    alertNameList,
    userList,
    userRoleList,
    userAccessList,
    alertLandmarks,
    savedAlertsList,
    userRole,
    user,
    assetSearch,
    landmarkGroupManage,
    recipients,
    AddLandmark,
    driverSettings,
    nearestAssetLandmark,
    diagnosticList,
    dispatchAction,
    SendCommand,
    kml,
    AppNotifications,
);
