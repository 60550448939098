/* eslint jsx-a11y/no-noninteractive-element-interactions: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid, no-alert */

/* @flow */
import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { Scrollbars } from 'react-custom-scrollbars';
import AppLoader from '../../AppLoader';
import styles from './LandMarks.module.scss';
import type { typeLandMarksPrediction } from '../../../containers/Header/types';
import analytics from '../../../analytics';
import { getPermissionValue, removeSavedPage } from '../../../helper-classes/utility-functions';
import GetSvgIcon from '../../../util/svgImage_util';
import { getLandmarkIconType } from '../../../util/map_utils';

const LandMarks = (predictions: typeLandMarksPrediction) => {
    const handleLandMarksAnalytics = (e) => {
        const params = {
            feature: 'UniversalSearch',
        };
        if (e.type === 'Map') {
            analytics.track('LANDMARK_MAP', params);
        } else if (e.type === 'Edit') {
            analytics.track('LANDMARK_EDIT', params);
        }
        // predictions.redirectTo('/landmarks'); // causing unwanted redirects
    };
    const formatSearchAddress = (data = {}) => {
        const address = [];
        if (data.city) address.push(data.city);
        if (data.state) address.push(data.state);
        return address.join(', ');
    };
    const style = { width: 496, height: predictions.setMaxHeight };
    const items = (predictions.predictions).map((item, i) => (
        <ListItem
            key={Math.random()}
            data-itemid={item.id}
            data-name={item.name}
            data-landmarks={JSON.stringify(item)}
        >
            <Avatar className={styles.oval}>
                <GetSvgIcon
                    type={item.attributes ? getLandmarkIconType(item.attributes.lfLandmarkIcon || item.attributes.att_0cm61) : 'default'}
                    data-itemid={item.id}
                    data-name={item.name}
                    className={styles.icon}
                />
            </Avatar>
            <ListItemText
                data-itemid={item.id}
                data-name={item.name}
                className={styles['list-items']}
                primary={
                    <span
                        id="main-address"
                        className={styles['main-address']}
                        data-name={item.name}
                        alt={`${item.name} - ${item.city}, ${item.state}`}
                        title={`${item.name} - ${item.city}, ${item.state}`}
                    >
                        <span data-itemid={item.id} data-name={item.name} className={styles['main-address-header']}>{item.name} {item.city || item.state ? '- ' : ''}</span>
                        <span data-itemid={item.id} data-name={item.name} className={styles['main-address-sub-header']}>{formatSearchAddress(item)}</span>
                    </span>
                }
                secondary={
                    <span
                        id="sub-address"
                        data-itemid={item.id}
                        data-name={item.name}
                        data-totalassets={item.totalAssets}
                        className={styles['sub-address']}
                    >
                        {item.totalAssets} Assets Present
                    </span>
                }
            />
            <div
                id="list-icon-container"
                data-itemid={item.id}
                data-name={item.name}
                className={`${styles['list-icon-container']}`}
            >
                {/* <div
                    data-itemid={item.id}
                    data-name={item.name}
                    data-type="Report"
                >
                    <i
                        data-itemid={item.id}
                        data-name={item.name}
                        data-type="Report"
                        className={styles.report}
                    />
                    <span
                        data-itemid={item.id}
                        data-name={item.name}
                        data-type="Report"
                        className={styles['icon-span-text']}
                    >Report
                    </span>
                </div> */}
                {getPermissionValue('Assets') === 'Modify' &&
                    <div
                        data-itemid={item.id}
                        data-name={item.name}
                        data-type="Edit"
                        role="link"
                        tabIndex="-1"
                        onClick={() => {
                            removeSavedPage();
                            predictions.redirectTo(`/landmarks/edit/${item.id}`);
                        }}
                        onKeyUp={() => {
                        }}
                    >
                        <i
                            data-itemid={item.id}
                            data-name={item.name}
                            data-type="Edit"
                            className={styles.edit}
                        />
                        <span
                            data-itemid={item.id}
                            data-name={item.name}
                            data-type="Edit"
                            className={styles['icon-span-text']}
                        >Edit
                        </span>
                    </div>
                }
                <div
                    data-itemid={item.id}
                    data-name={item.name}
                    data-landmarks={JSON.stringify(item)}
                    data-type="Map"
                    id={`map-option-${i}`}
                    role="link"
                    tabIndex="-1"
                    onClick={() => {
                        removeSavedPage();
                        predictions.redirectTo(`/home?landmarkId=${item.id}&zoom=true`);
                    }}
                    onKeyUp={() => {}}
                >
                    <i
                        data-itemid={item.id}
                        data-name={item.name}
                        data-type="Map"
                        data-landmarks={JSON.stringify(item)}
                        className={styles['map-icon-right']}
                    />
                    <span
                        data-itemid={item.id}
                        data-name={item.name}
                        data-type="Map"
                        data-landmarks={JSON.stringify(item)}
                        className={styles['icon-span-text']}
                    >Map
                    </span>
                </div>

            </div>
        </ListItem>));
    return (
        <List
            id="landmarks-dropdown"
            className={`${styles['address-block']} ${styles['scrollable-content']}`}
            onClick={e => handleLandMarksAnalytics(e.target.dataset)}
            style={{ maxHeight: predictions.setMaxHeight }}
        >
            <Scrollbars style={style} key="Landmarks" onScroll={predictions.onScroll}>
                {items}
                { predictions.isLoading ?
                    <div style={{ padding: 10, textAlign: 'center' }}>
                        <AppLoader
                            type="scale"
                            height={18}
                            width={2}
                            margin="1px"
                        />
                    </div>
                    : ''
                }
            </Scrollbars>
        </List>);
};


export default LandMarks;
