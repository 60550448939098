/* @flow */

export const FETCH_DEVICES_LIST: string = 'FETCH_DEVICES_LIST';
export const FETCH_DEVICES_LIST_SUCCESS: string = 'FETCH_DEVICES_LIST_SUCCESS';
export const FETCH_DEVICES_LIST_ERROR: string = 'FETCH_DEVICES_LIST_ERROR';
export const RESET_DEVICES_LIST: string = 'RESET_DEVICES_LIST';
export const DEVICE_LIST_PREF_KEY: string = 'fleetLocate.deviceList';
export type DeviceListTypeAction = {
    type: string;
    payload?: Object;
}
