/* @flow */

export const FETCH_LANDMARKGROUPS_LIST: string = 'FETCH_LANDMARKGROUPS_LIST';
export const FETCH_LANDMARKGROUPS_LIST_SUCCESS: string = 'FETCH_LANDMARKGROUPS_LIST_SUCCESS';
export const FETCH_LANDMARKGROUPS_LIST_ERROR: string = 'FETCH_LANDMARKGROUPS_LIST_ERROR';
export const RESET_LANDMARKGROUPS_LIST: string = 'RESET_LANDMARKGROUPS_LIST';
export const LANDMARK_GRP_LIST_PREF_KEY: string = 'fleetLocate.landmarkGroup';
export const PARENT_GROUP_DELETE: string = 'Groups with existing child groups cannot be deleted.';
export type LandmarkGroupsListTypeAction = {
    type: string;
    payload?: Object;
}
