/* @flow */
import React, { Component } from 'react';
import {
    Grid,
    Typography,
    Card,
    CardContent,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormControl,
    Divider,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import customstyles from './GroupFilters.module.scss';

type Props = {
    classes: Object,
    filterData: Object,
    appliedFilters: Object,
    handleChange: PropTypes.func,
    toggleFilterDrawer: PropTypes.func,
    labelPlacement: string,
    styleClasses: Object,
}

export type State = {
    fltrRanMin: string,
    fltrRanMax: string,
    isFormValid: boolean,
    filterType: string
};

let height = 0;
if (document.documentElement) {
    height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}
const styles = theme => ({
    root: {
        height: height - 348,
        width: '100%',
        overflow: 'auto',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
    },
    card: {
        backgroundColor: '#999b9e',
    },
    button: {
        margin: theme.spacing(),
    },
    labelSelected: {
        'font-size': '15px',
        color: '#007aff',
    },
    label: {
        'font-size': '15px',
    },
    labelPlacementStart: {
        justifyContent: 'space-between',
    },
});

class GroupFilters extends Component<Props, State> {
    landmarkFiltersToSend: any;

    constructor(props) {
        super(props);
        this.state = {
            fltrRanMin: '',
            fltrRanMax: '',
            isFormValid: false,
            filterType: 'checkbox',
        };
        this.landmarkFiltersToSend = [];
    }

    UNSAFE_componentWillMount() {
        const { appliedFilters } = this.props;
        if (appliedFilters !== undefined && appliedFilters.range !== undefined) {
            const fltrAry = appliedFilters.range.split(':');
            if (fltrAry.length > 2) {
                this.setState({
                    fltrRanMin: fltrAry[1],
                    fltrRanMax: fltrAry[2],
                    filterType: 'range',
                });
            }
        }

        ValidatorForm.addValidationRule('compare', (value, typeWithCompareState) => {
            const compareData = typeWithCompareState.split('-');
            if (typeof value === 'number') {
                if (this.state[compareData[1]] === '') {
                    return true;
                } else if (compareData[0] === 'lesser' && value < this.state[compareData[1]]) {
                    return true;
                } else if (compareData[0] === 'greater' && value > this.state[compareData[1]]) {
                    return true;
                }
            } else if (value === '' && this.state[compareData[1]] === '') {
                return true;
            }
            return false;
        });
    }

    handleFilterChanges(e) {
        let val = e.target.value.trimStart().replace(/[^0-9]/g, '');
        if (this.state[e.target.name] !== val) {
            val = parseInt(val, 10) ? parseInt(val, 10) : '';
            this.setState({
                [e.target.name]: val,
                isFormValid: false,
                filterType: 'range',
            }, () => this.getFilteredData());
        }
    }

    getFilteredData = () => {
        const { fltrRanMin, fltrRanMax } = this.state;

        if (fltrRanMin !== '' && fltrRanMax !== '' && fltrRanMax > fltrRanMin && parseInt(fltrRanMax, 10) <= 10000) {
            this.landmarkFiltersToSend = [];
            this.landmarkFiltersToSend.push(['totalAssets', `ran:${fltrRanMin}:${fltrRanMax}`]);
            this.setState({ isFormValid: true });
        }
    }

    handleBackBtnClick() {
        const { toggleFilterDrawer } = this.props;

        if (this.state.filterType === 'range') {
            const { fltrRanMin, fltrRanMax } = this.state;

            if (this.state.isFormValid) {
                this.props.handleChange('filtersToSend', this.landmarkFiltersToSend);
                toggleFilterDrawer(false);
            } else if ((fltrRanMin === '' && fltrRanMax === '') || (fltrRanMin !== '' && fltrRanMax !== '')) {
                toggleFilterDrawer(false);
            }
        } else toggleFilterDrawer(false);
    }

    handleClearBtnClick() {
        if (this.state.fltrRanMin !== '' || this.state.fltrRanMax !== '') {
            this.setState({
                fltrRanMin: '',
                fltrRanMax: '',
            }, () => {
                const { appliedFilters } = this.props;
                this.landmarkFiltersToSend = [];

                if (appliedFilters !== undefined && appliedFilters.range !== undefined) {
                    this.props.handleChange('clear', []);
                }
            });
        }
    }

    getChildren(key, data) {
        const {
            handleChange,
            classes,
            labelPlacement = '',
            styleClasses = {},
        } = this.props;
        const labelProp = {};

        if (labelPlacement) {
            labelProp.labelPlacement = labelPlacement;
        }

        return data.map((v, i) => {
            if (v.type === 'Checkbox') {
                const { value, label, checked } = v;
                let labelClass = styleClasses.label || classes.label;
                if (checked) labelClass = styleClasses.labelSelected || classes.labelSelected;

                return (
                    <FormGroup key={label}>
                        <FormControlLabel
                            className={customstyles['label-and-checkbox']}
                            classes={{
                                label: labelClass,
                                labelPlacementStart: labelPlacement ? classes.labelPlacementStart : '',
                            }}
                            label={label}
                            control={
                                <Checkbox
                                    className={checked ? customstyles['filter-checkbox-checked'] :
                                        customstyles['filter-checkbox-unchecked']}
                                    checked={checked}
                                    onChange={() => handleChange(key, value)}
                                    value={value}
                                />
                            }
                            {...labelProp}
                        />
                    </FormGroup>
                );
            } else if (key === 'range') {
                if (v.type === 'TextValidator') {
                    const rangeStyles = {
                        gridItem: {
                            height: 80,
                        },
                        centerGrid: {
                            textAlign: 'center',
                        },
                        textField: {
                            width: '100%',
                            margin: 0,
                            whiteSpace: 'nowrap',
                        },
                    };

                    return (
                        <React.Fragment key={v.name}>
                            <Grid item xs={5} style={rangeStyles.gridItem}>
                                <TextValidator
                                    id={v.name}
                                    name={v.name}
                                    label={v.label}
                                    style={rangeStyles.textField}
                                    margin="normal"
                                    value={this.state[v.name]}
                                    inputProps={{ maxLength: v.rangeData.maxLength }}
                                    validators={[`minNumber:${v.rangeData.min}`, `maxNumber:${v.rangeData.max}`, `compare:${v.rangeData.compare}-${v.rangeData.compareWith}`]}
                                    errorMessages={[`range is ${v.rangeData.min} to ${v.rangeData.max}`, `range is ${v.rangeData.min} to ${v.rangeData.max}`, `${(v.rangeData.compare === 'lesser') ? 'must be less than max' : 'must be greater than min'}`]}
                                    onChange={e => this.handleFilterChanges(e)}
                                />
                            </Grid>
                            {(i === 0) &&
                            <Grid item xs={2} style={rangeStyles.centerGrid}>to</Grid>
                            }
                        </React.Fragment>
                    );
                }
            }
            return true;
        });
    }

    getFilters = () => {
        const { filterData } = this.props;
        return filterData.map((d) => {
            const { key, value, title } = d;
            return (
                <React.Fragment key={`${new Date().toJSON().slice(0, 10).replace(/-/g, '')}${key}`}>
                    <Typography variant="body1" component="p" className={customstyles['filters-keys-font']}>{title}</Typography>
                    {(d.keyType === undefined) &&
                        <FormControl component="fieldset">
                            {this.getChildren(key, value)}
                        </FormControl>
                    }
                    {(d.keyType === 'range') &&
                        <ValidatorForm noValidate autoComplete="off" onSubmit={() => true}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                style={{ margin: 0, padding: '0 50px' }}
                            >
                                {this.getChildren(d.keyType, value)}
                            </Grid>
                        </ValidatorForm>
                    }
                </React.Fragment>
            );
        });
    };

    render() {
        return (
            <Card raised={false} id="filter-container" className={customstyles['filters-container']}>
                <CardContent className={customstyles['filters-card-container']}>
                    <Grid container spacing={0}>
                        <Grid item xs={9}>
                            <div className={customstyles['filters-title-container']}>
                                <button id="back-icon" className={customstyles['back-arrow-black']} onClick={() => this.handleBackBtnClick()} />
                                <Typography id="filter-title" className={customstyles['filters-title']} variant="body1" component="p">
                                    Filters
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div id="clear-button" className={customstyles['clear-button']}>
                                <Typography paragraph align="right" component="p">
                                    <button
                                        className={customstyles.button}
                                        onClick={() => (this.state.filterType === 'checkbox' ? this.props.handleChange('', '') : this.handleClearBtnClick())}
                                    >CLEAR
                                    </button>
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider />
                    <br />
                    {this.getFilters()}
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles, { withTheme: true })(GroupFilters);
