/* @flow */
import {
    FETCH_KMLS,
    FETCH_KMLS_SUCCESS,
    FETCH_KMLS_ERROR,
    DELETE_KMLS,
    DELETE_KMLS_SUCCESS,
    DELETE_KMLS_ERROR,
    CLOSE_KMLS_DIALOG,
    ADD_KML,
    ADD_KML_SUCCESS,
    ADD_KML_ERROR,
} from './constants.kml';

export const initialState = {
    kmls: [],
    kmlLoader: true,
    isDeleted: false,
    isDeleteError: false,
    isError: false,
    error: null,
    kmlLoading: false,
    addedSuccessfully: false,
    isKmlListError: false,
    addedKml: null,
};

type stateType = {
    kmls: Array<{}>,
    kmlLoader: boolean,
    isDeleted: boolean,
    isDeleteError: boolean,
    isError: boolean,
    error: any,
    kmlLoading: boolean,
    addedSuccessfully: boolean,
    isKmlListError: boolean,
    addedKml: any,
}
type actionType = {
    type: string,
    payload :{
        response:{},
        kmlLoader: boolean,
        kmls: [],
        error: any,
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_KMLS: {
        return {
            ...state,
            kmlLoader: true,
            isKmlListError: false,
            addedKml: null,
        };
    }
    case FETCH_KMLS_SUCCESS:
        return {
            ...state,
            kmls: action.payload.response,
            kmlLoader: false,
            isKmlListError: false,
            addedKml: null,
        };
    case FETCH_KMLS_ERROR:
        return {
            ...state,
            kmlLoader: false,
            isKmlListError: true,
            kmls: [],
            addedKml: null,
        };
    case DELETE_KMLS:
        return {
            ...state,
            isDeleted: true,
            isDeleteError: false,
            isError: false,
            error: null,
            kmlLoading: true,
            addedKml: null,
        };
    case DELETE_KMLS_SUCCESS:
        return {
            ...state,
            isDeleted: true,
            isDeleteError: false,
            isError: false,
            error: null,
            kmlLoading: false,
            addedKml: null,
        };
    case DELETE_KMLS_ERROR:
        return {
            ...state,
            isDeleted: false,
            isError: true,
            isDeleteError: true,
            error: action.payload.error,
            kmlLoading: false,
            addedKml: null,
        };
    case CLOSE_KMLS_DIALOG:
        return {
            ...state,
            isDeleted: false,
            isDeleteError: false,
            isError: false,
            addedSuccessfully: false,
            addedKml: null,
        };
    case ADD_KML:
        return {
            ...state,
            kmlLoading: true,
            isError: false,
            addedSuccessfully: false,
            addedKml: null,
        };
    case ADD_KML_SUCCESS:
        return {
            ...state,
            kmlLoading: false,
            isError: false,
            addedSuccessfully: true,
            addedKml: action.payload.response,
        };
    case ADD_KML_ERROR:
        return {
            ...state,
            kmlLoading: false,
            addedSuccessfully: false,
            isError: true,
            error: action.payload.error,
            addedKml: null,
        };

    default:
        return state;
    }
};
