/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import {
    Paper,
    Typography,
    Toolbar,
    IconButton,
    TextField,
    FormControl,
    FormGroup,
    Divider,
    Grid,
    Button,
    MenuItem,
    InputLabel,
} from '@mui/material';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import GetSvgIcon from '../../../util/svgImage_util';
import AppLoader from '../../AppLoader';
import MapsWrapper from '../../../containers/MapsWrapper';
import Dialog from '../../SharedComponents/Dialog/Dialog';
import AsyncSelectWrapper from '../../SharedComponents/AsyncSelectWrapper';
import { getLandmarks } from '../../../containers/Assets/DispatchAction/epics.dispatchAction';
import { DISPATCH_EMAIL_ID } from '../../../constants/Config';
import { getDistanceString } from '../../../helper-classes/utility-functions';

const styles = () => ({
    root: {
        padding: 16,
        display: 'flex',
    },
    toolbar: {
        backgroundColor: '#343e48',
        color: '#ffffff',
    },
    formControl: {
        padding: '0 16px 16px',
        width: '90%',
    },
    card: {
        backgroundColor: '#999b9e',
    },
    app: {
        paddingTop: '16px',
        paddingLeft: '17px',
        paddingRight: '23px',
        paddingBottom: '34px',
    },
    button: {
        margin: '0 10px',
    },
    clearButton: {
        color: '#007aff',
        margin: '0 10px',
    },
    saveButton: {
        '&:hover': {
            backgroundColor: '#007aff',
        },
        backgroundColor: '#007aff',
        color: '#ffffff',
    },
    backButton: {
        verticalAlign: 'middle',
        paddingRight: 10,
        marginLeft: -12,
    },
    requiredField: {
        fontSize: 14,
        opacity: 0.87,
        whiteSpace: 'nowrap',
    },
    titleWrap: {
        lineHeight: 2,
    },
    divider: {
        borderRadius: 2,
    },
    sideBySide: {
        display: 'flex',
        overflow: 'hidden',
    },
    textField: {
        width: '100%',
        marginTop: 0,
        whiteSpace: 'nowrap',
    },
    properties: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    propertyTextField: {
        flex: 'auto',
        margin: '0 16px 0 0',
        maxWidth: 170,
        minWidth: 126,
    },
    dropdownContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '12px 0',
        justifyContent: 'space-between',
    },
    dropdown: {
        flex: 'auto',
        minWidth: 152,
        margin: '0 20px 0 0',
        whiteSpace: 'nowrap',
    },
    label: {
        width: '90px',
        height: '24px',
        opacity: '0.87',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000000',
        fontSize: '20px',
    },
    rightPanel: {
        float: 'right',
        width: '360px',
        overflow: 'auto',
        marginTop: '-11px',
        maxHeight: 'calc(50vh + 100px)',
    },
    rightContainer: {
        flex: 'auto',
        justifyContent: 'right',
    },
    leftContainer: {
        justifyContent: 'left',
        paddingLeft: '10px',
        height: '610px',
    },
    mapContainer: {
        border: '1px solid rgba(0,0,0, .5)',
        margin: '20px 16px 20px 0',
        height: 'calc(50vh + 100px)',
        overflowY: 'hidden',
    },
    showDirection: {
        color: '#007aff',
        margin: '0 10px',
        backgroundColor: 'none',
        fontSize: 14,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 0.5,
        textAlign: 'center',
        '&:hover': {
            background: 'none',
        },
    },
    formFootr: {
        minHeight: 0,
    },
    actionButtonWrap: {
        paddingTop: 7,
    },
    errorLabel: {
        color: '#f44336',
    },
    errorText: {
        color: '#f44336',
        fontSize: '0.75rem',
        textAlign: 'left',
        marginTop: '8px',
    },
    stateTextValidator: {
        width: '100%',
    },
    zipTextValidator: {
        width: '40%',
    },
});

const landmarksFilter = {
    filterOptions: getLandmarks,
    selectedOptions: [],
    filterType: 'select',
    filterTitle: 'Landmark',
    property: 'landmark',
    label: 'landmarkName',
    dataArrayParam: 'data',
    datumValueParam: 'id',
    datumLabelParam: 'name',
    dataCountParam: 'max',
    propertyType: 'Long',
    disableSearch: false,
    showSelection: true,
    disableChips: true,
};
const defaultDataArrayParam = 'content';
const defaultDataCountParam = 'count';
const defaultDataTotalParam = 'total';
const defaultDatumLabelParam = 'name';
const defaultDatumValueParam = 'id';
const defaultPerPage = 50;

export type Props = {
    classes: Object,
    redirectTo : Function,
    assetsDetails : Object,
    sendDirection: Function,
    isLoading: boolean,
    isEmailSend: boolean,
    isError: boolean,
    closeDispatchDialog: Function,
};

export type State = {
    dispatchType: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    latitude: number,
    longitude: number,
    landmark: string,
    comments: string,
    email: string,
    direction: Object,
    isOpenDialog: boolean,
    landmarkName: string,
    startingPoint: Object,
    endPoint: Object,
    coordinates: Object,
    isAddressRequired: boolean,
};

class DispatchActionDetails extends Component<Props, State> {
    endPointLat: number;
    endPointLng: number;
    directionFound: boolean;
    urlText: string;
    isLoader: boolean;
    button: Array<Object>;

    constructor(props) {
        super(props);
        this.state = {
            dispatchType: 'address',
            address: '',
            city: '',
            state: '',
            zip: '',
            latitude: 0,
            longitude: 0,
            landmark: '',
            landmarkName: '',
            comments: '',
            email: '',
            direction: {
                routes: [],
            },
            isOpenDialog: false,
            startingPoint: {},
            endPoint: {},
            coordinates: {
                lat: 33.706689,
                lng: -117.83822572,
            },
            isAddressRequired: false,
        };
        this.endPointLat = 0;
        this.endPointLng = 0;
        this.directionFound = false;
        this.isLoader = false;
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
        }];
    }

    UNSAFE_componentWillMount() {
        const { dispatchType } = this.state;

        ValidatorForm.addValidationRule('isZip', (value) => {
            if (dispatchType === 'address' && typeof value === 'string') {
                return value.length === 0 ? true : value.match(/^[0-9]{5}(-?[0-9]{4})?$/g);
            }
            return true;
        });
        ValidatorForm.addValidationRule('isState', (value) => {
            if (dispatchType === 'address' && typeof value === 'string' && value.length > 0) {
                return value.match(/^[A-Z]{2}$/g);
            }
            return true;
        });
        ValidatorForm.addValidationRule('isLandmark', (value) => {
            if (dispatchType === 'landmark' && typeof value === 'number' && value === 0) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isEmpty', (value) => {
            if (typeof value === 'string' && value.replace(/\s/gi, '') === '') {
                return false;
            }
            return true;
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { email } = this.state;
        const { assetsDetails } = nextProps;
        if (email === '' && assetsDetails && assetsDetails.driverEmail) {
            this.setState({ email: assetsDetails.driverEmail });
        }
    }

    handleChange = (key, value) => {
        if (!key) return;
        let newValue = value;
        if (!value) newValue = '';
        if (key !== 'email' && key !== 'comments') {
            this.setState({
                direction: {
                    routes: [],
                },
                startingPoint: {},
                endPoint: {},
            });
            this.directionFound = false;
        }
        this.setState({ [key]: newValue });
    }

    handleChangeLatLng = (key, value) => {
        if (!key) return;
        this.setState({
            direction: {
                routes: [],
            },
            startingPoint: {},
            endPoint: {},
        });
        const newValue = (value) ? +value : 0;
        if (Number.isNaN(newValue)) return;
        this.directionFound = false;
        this.setState({ [key]: newValue });
    }

    clear = () => {
        this.setState({
            address: '',
            city: '',
            state: '',
            zip: '',
            latitude: 0,
            longitude: 0,
            landmark: '',
            landmarkName: '',
            comments: '',
            email: '',
            direction: {
                routes: [],
            },
            isOpenDialog: false,
            startingPoint: {},
            endPoint: {},
            coordinates: {
                lat: 33.706689,
                lng: -117.83822572,
            },
            isAddressRequired: false,
        });
        this.endPointLat = 0;
        this.endPointLng = 0;
        this.directionFound = false;
    }

    sendDirection = () => {
        const { assetsDetails } = this.props;
        const { direction, email } = this.state;
        const endLocation = this.directionFound ? direction.routes[0].legs[0].end_address : '';
        const requestBody = {
            emailAddresses: [email],
            message: this.getHTMLContent(),
            from: `Spireon Dispatch ${DISPATCH_EMAIL_ID}`,
            subject: `Dispatch instructions for ${assetsDetails.assetName}`,
            smsMessage: `Dispatch Address: ${endLocation}`,
            smsNumber: assetsDetails.driverPhone,
        };
        this.props.sendDirection(requestBody);
    }

    getHTMLContent() {
        const { direction } = this.state;
        const panel = document.getElementById('right-panel');
        let panelHtml = '';
        let currentLocation = '';
        let endLocation = '';
        let distance = '';
        let duration = '';
        let url = '';
        if (panel instanceof Element) {
            panelHtml = panel.innerHTML;
        }
        if (this.directionFound) {
            currentLocation = direction.routes[0].legs[0].start_address;
            endLocation = direction.routes[0].legs[0].end_address;
            distance = direction.routes[0].legs[0].distance.text;
            duration = direction.routes[0].legs[0].duration.text;
            url = `https://www.google.com/maps/dir/?api=1&travelmode=driving&${this.urlText}&sensor=false`;
        }
        panelHtml = panelHtml.replace(currentLocation, '');
        panelHtml = panelHtml.replace(endLocation, '');
        panelHtml = panelHtml.replace(distance, '');
        panelHtml = panelHtml.replace(duration, '');
        panelHtml = panelHtml.replace('About', '');
        panelHtml = panelHtml.replace('. </span>', '');
        const emailMessage = `<html><body><p>${this.state.comments}</p><h2>Current Location: ${currentLocation}</h2></br><h2>Destination: ${endLocation}</h2></br><h2>Total Distance: ${distance}</h2></br><h2>Total Duration: ${duration}</h2></br><h2>Directions: </h2></br><a href="${url}" target="_blank" title="Directions">${url}</a></br>${panelHtml}</br></body></html>`;
        return emailMessage;
    }

    showDirection = (assetsDetails) => {
        const { dispatchType, address } = this.state;
        let isAddressRequired = false;
        if (dispatchType === 'address' && address === '') {
            isAddressRequired = true;
        }
        if (!isAddressRequired) {
            this.directionFound = false;
            this.isLoader = true;
            if (window.google) {
                const { google } = window;
                const directionsService = new google.maps.DirectionsService();
                const startingPoint = new google.maps.LatLng(
                    Number(assetsDetails.latitude),
                    Number(assetsDetails.longitude),
                );
                if (this.state.dispatchType === 'address') {
                    const geocoder = new google.maps.Geocoder();
                    const fullAddress = `${this.state.address}, ${this.state.city}, ${this.state.state} ${this.state.zip}`;
                    geocoder.geocode({ address: fullAddress }, (results, status) => {
                        if (status === 'OK') {
                            const endPoint = new google.maps.LatLng(
                                results[0].geometry.location.lat(),
                                results[0].geometry.location.lng(),
                            );
                            this.setDirections(startingPoint, directionsService, google, endPoint);
                            this.urlText = `origin=${Number(assetsDetails.latitude)},${Number(assetsDetails.longitude)}&destination=${results[0].geometry.location.lat()},${results[0].geometry.location.lng()}`;
                        } else {
                            this.isLoader = false;
                            this.setState({ isOpenDialog: true });
                        }
                    });
                } else if (this.state.dispatchType === 'coordinates') {
                    const endPoint = new google.maps.LatLng(
                        this.state.latitude,
                        this.state.longitude,
                    );
                    this.setDirections(startingPoint, directionsService, google, endPoint);
                    this.urlText = `origin=${Number(assetsDetails.latitude)},${Number(assetsDetails.longitude)}&destination=${this.state.latitude},${this.state.longitude}`;
                } else if (this.state.dispatchType === 'landmark') {
                    const endPoint = new google.maps.LatLng(this.endPointLat, this.endPointLng);
                    this.setDirections(startingPoint, directionsService, google, endPoint);
                    this.urlText = `origin=${Number(assetsDetails.latitude)},${Number(assetsDetails.longitude)}&destination=${this.endPointLat},${this.endPointLng}`;
                }
            }
            this.setState({ isAddressRequired });
        } else {
            this.setState({ isAddressRequired });
        }
    }

    setDirections = (startingPoint, directionsService, google, endPoint) => {
        if (startingPoint) {
            const unitSystem = getDistanceString() === 'mi' ? google.maps.UnitSystem.IMPERIAL : google.maps.UnitSystem.METRIC;
            directionsService.route({
                origin: startingPoint,
                destination: endPoint,
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem,
            }, (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    this.directionFound = true;
                    this.isLoader = false;
                    this.setState({ direction: result });
                } else {
                    this.isLoader = false;
                    this.setState({ isOpenDialog: true });
                }
            });
            this.setState({ startingPoint, endPoint });
        }
    };

    getType = () => {
        const { isEmailSend, isError } = this.props;
        const {
            isOpenDialog,
        } = this.state;
        let type = '';
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
        }];
        if (isEmailSend) {
            type = 'success';
        } else if (isError) {
            type = 'error';
        } else if (isOpenDialog) {
            type = 'Direction Error';
        }
        return type;
    }

    getContent = () => {
        const {
            isError,
            isEmailSend,
        } = this.props;
        const {
            isOpenDialog,
        } = this.state;
        let content = '';
        if (isError) {
            content = 'Error: Directions not sent successfully';
        } else if (isEmailSend) {
            content = 'Directions sent successfully';
        } else if (isOpenDialog && !this.directionFound) {
            content = 'Direction Error: Unable to generate directions for the specified location. ';
        }

        return content;
    }

    handleClickOk = () => {
        const { isEmailSend } = this.props;
        this.props.closeDispatchDialog();
        this.setState({ isOpenDialog: false });
        if (isEmailSend) {
            this.props.redirectTo();
        }
    }

    onFilterSelected = (filter, selected) => {
        if (Array.isArray(selected) && selected.length === 0) {
            return;
        }
        this.setState({
            direction: {
                routes: [],
            },
        });
        this.directionFound = false;
        this.setState({
            [filter.property]: selected.value,
            [filter.label]: selected.label,
        });
        this.endPointLat = selected.data && selected.data.markerLat
            ? selected.data.markerLat : 0;
        this.endPointLng = selected.data && selected.data.markerLng
            ? selected.data.markerLng : 0;
    }

    filterOptions = (filter, inputValue, loadedOptions) => new Promise((resolve, reject) => {
        this.setState({
            direction: {
                routes: [],
            },
        });
        this.directionFound = false;
        if (inputValue.length >= 3 || inputValue.length === 0) {
            this.setState({
                [filter.property]: inputValue,
                [filter.label]: inputValue,
            });
            const perPage = defaultPerPage;
            filter.filterOptions(loadedOptions.length, perPage, inputValue).then((response) => {
                const count = response[defaultDataCountParam];
                const total = response[defaultDataTotalParam];
                const hasMore = loadedOptions.length + count < total;

                if (response) {
                    const responseData = response[filter.dataArrayParam || defaultDataArrayParam];
                    if (responseData) {
                        const optionData = responseData.map(datum => ({
                            value: datum[defaultDatumValueParam],
                            label: datum[defaultDatumLabelParam],
                            data: datum,
                        }));
                        resolve({
                            options: optionData,
                            hasMore,
                        });
                        if (responseData.length === 0) {
                            this.setState({
                                [filter.property]: '',
                                [filter.label]: '',
                            });
                        }
                    } else {
                        this.setState({
                            [filter.property]: '',
                            [filter.label]: '',
                        });
                        reject();
                    }
                } else {
                    this.setState({
                        [filter.property]: '',
                        [filter.label]: '',
                    });
                    reject();
                }
            });
        }
    });

    renderEditSelect = (filter, forceReloadOption = null) => {
        const selectStyles = {
            control: () => ({
                display: 'flex',
                width: '250px',
            }),
            selectLabel: {
                fontSize: '.75rem',
                position: 'relative',
                display: 'contents',
            },
        };

        let selectedVal = null;
        if (this.state[filter.property]) {
            selectedVal = {
                value: this.state[filter.property],
                label: this.state[filter.label],
            };
        }
        const asyncSelectContStyles = {
            container: {
                padding: 0,
                marginLeft: -10,
            },
        };
        return (
            <React.Fragment>
                <InputLabel
                    htmlFor={filter.filterTitle}
                    disableAnimation
                    style={selectStyles.selectLabel}
                >
                    {filter.filterTitle}
                </InputLabel>
                <AsyncSelectWrapper
                    loadOptions={(inputValue, loadedOptions) =>
                        this.filterOptions(filter, inputValue, loadedOptions)
                    }
                    onItemSelected={selected =>
                        this.onFilterSelected(filter, selected)
                    }
                    selectedOptions={selectedVal}
                    title={filter.filterTitle}
                    selectStyles={selectStyles}
                    disableSearch={filter.disableSearch}
                    disableChips={filter.disableChips}
                    showSelection={filter.showSelection}
                    inputProps={{ id: filter.filterTitle }}
                    key={JSON.stringify(forceReloadOption)}
                    styles={asyncSelectContStyles}
                />
            </React.Fragment>
        );
    }

    render() {
        const {
            classes,
            assetsDetails,
            isEmailSend,
            isError,
            isLoading,
        } = this.props;
        const {
            dispatchType = 'address',
            address = '',
            city = '',
            state = '',
            zip = '',
            latitude = 0,
            longitude = 0,
            comments = '',
            email = '',
            direction = {},
            isOpenDialog = false,
            isAddressRequired = false,
        } = this.state;
        const mapWidth = this.directionFound ? '55%' : '100%';
        return (
            <React.Fragment>
                {this.isLoader || isLoading ? <AppLoader type="fullScreen" /> : ''}
                <div className={classes.app}>
                    <Paper>
                        <Toolbar className={classes.toolbar}>
                            <span
                                className={classes.backButton}
                            >
                                <IconButton onClick={this.props.redirectTo} size="large">
                                    <GetSvgIcon type="back" />
                                </IconButton>
                            </span>
                            <Typography variant="h6" color="inherit" className={classes.titleWrap}>
                                <span>Dispatch {assetsDetails.assetName}</span>
                            </Typography>
                        </Toolbar>
                        <ValidatorForm
                            autoComplete="off"
                            onSubmit={() => { this.sendDirection(); }}
                        >
                            <Grid container spacing={3} style={{ paddingTop: '12px' }}>
                                <Grid item xs={4}>
                                    <span className={classes.label} style={{ paddingLeft: '20px' }}> Location </span>
                                    <FormControl component="fieldset" className={classes.formControl} >
                                        <div style={{ paddingTop: '20px' }}>
                                            <FormGroup>
                                                <TextField
                                                    value={dispatchType}
                                                    className={classes.textField}
                                                    select
                                                    name="dispatch"
                                                    label="Dispatch To"
                                                    required
                                                    onChange={e => this.handleChange('dispatchType', e.target.value)}
                                                >
                                                    <MenuItem value="address">Address</MenuItem>
                                                    <MenuItem value="coordinates">Coordinates</MenuItem>
                                                    <MenuItem value="landmark">Landmark</MenuItem>
                                                </TextField>
                                                {dispatchType === 'address' &&
                                                    <div>
                                                        <TextField
                                                            label={isAddressRequired && address === '' ? <span className={classes.errorLabel}>Address*</span> : 'Address*'}
                                                            name="Address"
                                                            value={address}
                                                            className={classes.textField}
                                                            margin="normal"
                                                            onChange={e => this.handleChange('address', e.target.value)}
                                                            autoComplete="none"
                                                        />
                                                        { isAddressRequired && address === '' &&
                                                        <div className={classes.errorText}>
                                                            this field is required
                                                        </div> }
                                                        <TextField
                                                            label="City"
                                                            name="City"
                                                            value={city}
                                                            className={classes.textField}
                                                            margin="normal"
                                                            onChange={e => this.handleChange('city', e.target.value)}
                                                            autoComplete="none"
                                                        />
                                                        <div className={classes.sideBySide}>
                                                            <TextValidator
                                                                label="State"
                                                                name="State"
                                                                value={state}
                                                                validators={['isState']}
                                                                errorMessages={['enter a valid state in CAPS']}
                                                                className={classes.textField}
                                                                margin="normal"
                                                                onChange={e => this.handleChange('state', e.target.value)}
                                                                autoComplete="none"
                                                                containerProps={{
                                                                    className:
                                                                    classes.stateTextValidator,
                                                                }}
                                                            />
                                                            <TextValidator
                                                                label="Zip"
                                                                name="Zip"
                                                                value={zip}
                                                                validators={['isZip']}
                                                                errorMessages={['enter a valid Zip']}
                                                                className={classes.textField}
                                                                margin="normal"
                                                                onChange={e => this.handleChange('zip', e.target.value)}
                                                                style={{ minWidth: '100px', marginLeft: '30px' }}
                                                                autoComplete="none"
                                                                containerProps={{
                                                                    className:
                                                                    classes.zipTextValidator,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {dispatchType === 'coordinates' &&
                                                    <div>
                                                        <TextField
                                                            label="Latitude*"
                                                            onChange={e => this.handleChangeLatLng('latitude', e.target.value)}
                                                            name="latitude"
                                                            value={latitude}
                                                            type="number"
                                                            inputProps={{ step: 0.001 }}
                                                            className={classes.textField}
                                                            margin="normal"
                                                        />
                                                        <TextField
                                                            label="Longitude*"
                                                            onChange={e => this.handleChangeLatLng('longitude', e.target.value)}
                                                            name="longitude"
                                                            value={longitude}
                                                            type="number"
                                                            inputProps={{ step: 0.001 }}
                                                            className={classes.textField}
                                                            margin="normal"
                                                        />
                                                    </div>
                                                }
                                                {dispatchType === 'landmark' &&
                                                    <div>
                                                        {this.renderEditSelect(landmarksFilter)}
                                                    </div>
                                                }
                                                <TextField
                                                    label="Comments"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    value={comments}
                                                    onChange={e => this.handleChange('comments', e.target.value)}
                                                />
                                                <TextValidator
                                                    label="Email"
                                                    name="Email"
                                                    value={email}
                                                    required
                                                    validators={['isEmpty', 'isEmail']}
                                                    errorMessages={['this field is required', 'please enter a valid email address']}
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onChange={e => this.handleChange('email', e.target.value)}
                                                />

                                            </FormGroup>
                                        </div>
                                    </FormControl>
                                </Grid>
                                <Grid item xs="auto" className={classes.rightContainer}>
                                    <span className={classes.label}> Directions </span>
                                    <Button
                                        className={classes.showDirection}
                                        onClick={() => { this.showDirection(assetsDetails); }}
                                    >
                                        Show Directions
                                    </Button>
                                    <div className={classes.mapContainer} key="dispatch-action-details-map-container">
                                        <div id="right-panel" className={classes.rightPanel} />
                                        <div style={{ width: mapWidth }}>
                                            <MapsWrapper
                                                key="dispatch-action-details-map"
                                                coordinates={this.state.coordinates}
                                                hideViewButton
                                                defaultTrafficLayerView
                                                height="calc(50vh + 100px)"
                                                markers={{
                                                    shape: { type: 'dispatchdirection' },
                                                    direction,
                                                    startPoint: this.state.startingPoint,
                                                    endPoint: this.state.endPoint,
                                                    panel: this.directionFound ? document.getElementById('right-panel') : null,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Toolbar>
                                <Grid item>
                                    <div
                                        className={classes.requiredField}
                                    >
                                    * Required Field
                                    </div>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    <Grid item className={classes.actionButtonWrap}>

                                        <Button
                                            className={classes.button}
                                            onClick={this.clear}
                                        >
                                            Clear
                                        </Button>
                                        <Button
                                            color="primary"
                                            className={classes.saveButton}
                                            variant="contained"
                                            type="submit"
                                            disabled={!this.directionFound}
                                        >
                                            Send Directions
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </ValidatorForm>
                        <Dialog
                            open={isEmailSend || isError || isOpenDialog}
                            type={this.getType()}
                            customTitle=""
                            button={this.button}
                            content={this.getContent()}
                            size="sm"
                        />
                    </Paper>
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(styles, { withTheme: true })(DispatchActionDetails);
