/* @flow */

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import customstyles from './LandmarkGroupDetails.module.scss';
import { mapColor } from '../../containers/constants.map';
import GetSvgIcon from './../../util/svgImage_util';
import { getLandmarkIconType } from './../../util/map_utils';
import { getPermissionValue } from '../../helper-classes/utility-functions';

let height = 0;

if (document.documentElement) {
    height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

type Props = {
    landmark: Object,
}

const styles = theme => ({
    root: {
        height: height - 348,
        width: '100%',
        overflow: 'auto',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
    },
});

function LandmarkListItem(props : Props) {
    const { landmark } = props;
    let lmIcon = landmark.attributes && (landmark.attributes.lfLandmarkIcon
        || landmark.attributes.att_0cm61);
    if (!lmIcon) lmIcon = 'default';
    return (
        <ListItem button={getPermissionValue('Landmarks') !== 'None'} id="landmark-list-item" className={customstyles['list-item']}>
            <Grid container spacing={0}>
                <Grid item xs={2}>
                    <Avatar id="landmark-avatar" className={customstyles['landmark-avatar']} style={{ backgroundColor: mapColor.landmark.group[landmark.getColorNumber || 0] }}>
                        <GetSvgIcon
                            type={getLandmarkIconType(lmIcon)}
                            style={{
                                height: 20,
                                width: 15,
                            }}
                        />
                    </Avatar>
                </Grid>
                <Grid item xs={10}>
                    <Grid container spacing={0}>
                        <Grid item xs={9}>
                            <div>
                                <Tooltip title={landmark.name} placement="top-start">
                                    <Typography id="landmark-name" className={customstyles['landmark-name']} component="p" noWrap>
                                        {landmark.name}
                                    </Typography>
                                </Tooltip>
                                <div className={customstyles['landmark-location']}>
                                    {landmark.address &&
                                        <Typography id="landmark-location" component="p" noWrap>
                                            {landmark.address}
                                        </Typography>
                                    }
                                    {!landmark.address &&
                                    <Typography id="landmark-location" component="p">
                                        -- --
                                    </Typography>
                                    }
                                    {landmark.city &&
                                    <Typography id="landmark-city" component="p" noWrap>
                                        {landmark.city}, {landmark.state}
                                    </Typography>
                                    }
                                    {!landmark.city &&
                                    <Typography id="landmark-city" component="p">
                                        -- --
                                    </Typography>
                                    }
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div>
                                <Typography id="asset-count" className={customstyles['landmark-location']} align="right" component="p">
                                    {landmark.totalAssets} vehicles
                                </Typography>
                                <div className={customstyles['landmark-location']}>
                                    <Typography id="landmark-shape" align="right" component="p" className={customstyles['landmark-location']}>
                                        {landmark.shape}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ListItem>
    );
}

export default withStyles(styles)(LandmarkListItem);
