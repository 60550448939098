/* @flow */
import React, { Component } from 'react';
import moment from 'moment';
import withStyles from '@mui/styles/withStyles';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.userList';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../components/SharedComponents/ListComponent/ListComponent';
import ImportComponent from '../../components/SharedComponents/ImportComponent/ImportComponent';
import { getURL, getRequestPayload } from './epics.userList';
import {
    normalizePhoneNumber,
    getParamValue,
    downloadFile,
    getHeaderKeysForPost,
    getUserSettingTimeZone,
    getDistanceStringLong,
    getTimeFormatAsperUserSettting,
    getAuthorizationHeader,
} from '../../helper-classes/utility-functions';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    isUpdatePreference,
} from '../../helper-classes/listUtil';
import { RECORDS_PER_PAGE } from '../../constants/Config';
import User from './../User/AddUser/User';
import AppLoader from '../../components/AppLoader';
import analytics from '../../analytics/index';
import { USER_LIST_PREF_KEY } from './constants.userList';

export type Props = {
    isUpdating: boolean,
    fetchUsersList: Function,
    classes: Object,
    resetTable: Function,
    location: Object,
    history: {
        push: Function,
    },
    match: {
        path: any,
    },
};

export type State = {
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{}>,
        totalRecords: number,
        ischeckBox: boolean,
        isGroup: boolean,
        isEditable: boolean,
    },
    rowsPerPage: number,
    pageNumber: number,
    order: string,
    orderBy: string,
    openExport: boolean,
    openImport: boolean,
    isAddEdit: boolean,
    showListing: boolean,
    loading: boolean,
    selectedOption: Object,
};

const themeStyles = () => ({
    checked: { color: '#007aff' },
    label: { color: '#696969' },
    hide: { display: 'none' },
});

class UserList extends Component<Props, State> {
    search: string;
    id: string;
    addUrl: string;
    listUrl: string;
    editUrl: string;
    addUrl: string;
    userPreferences: any;

    constructor(props: Props) {
        super(props);
        let isAddEdit = false;
        this.addUrl = '/users/add';
        this.listUrl = '/users';
        this.editUrl = '/users/edit/:id';
        if (getParamValue(this.props, 'id') || this.props.match.path === this.addUrl) {
            isAddEdit = true;
        }

        this.state = {
            tableData: {
                listHeaders: this.getListHeader(),
                isEditable: true,
                listData: [],
                totalRecords: 0,
                ischeckBox: false,
                isGroup: false,
            },
            rowsPerPage: RECORDS_PER_PAGE,
            pageNumber: 0,
            order: 'asc',
            orderBy: 'username',
            openExport: false,
            openImport: false,
            isAddEdit,
            showListing: false,
            loading: false,
            selectedOption: {
                label: 'CSV',
                value: 'csv',
                key: 'CSV',
            },
        };
        this.search = '';
        this.userPreferences = null;
    }

    UNSAFE_componentWillMount() {
        if (!this.state.isAddEdit) getUserPreference(this, USER_LIST_PREF_KEY);
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (nextprops.user.length > 0) {
            const { user, totalUser } = nextprops;
            const { tableData } = this.state;
            const formattedUserList = user.map((item => ({
                ...item,
                rolesDisplay: item.rolesDisplay ? item.rolesDisplay.split(',').filter(t => t !== 'ELD_MANAGER' && t !== 'MASTER_USER').sort().join(', ') : '',
                securityUniversesDisplay: item.securityUniversesDisplay ? item.securityUniversesDisplay.replace(/,/g, ', ') : '',
                phone: item.phone ?
                    normalizePhoneNumber(item.phone) : item.phone,
            })));
            tableData.listData = formattedUserList;
            tableData.totalRecords = totalUser;
            this.setState({ tableData });
        } else if (!this.state.isAddEdit) {
            const { tableData } = this.state;
            tableData.listData = [];
            tableData.totalRecords = 0;
            this.setState({ tableData });
        }
    }

    componentDidUpdate() {
        let { isAddEdit } = this.state;
        if (isAddEdit && this.props.match.path === this.listUrl) {
            isAddEdit = false;
            this.getData();
            this.updateEditState(isAddEdit);
        } else if (!isAddEdit &&
            (this.props.match.path === this.editUrl ||
            this.props.match.path === this.addUrl)) {
            isAddEdit = true;
            this.updateEditState(isAddEdit);
        }
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            { fullName: 'Name', ...getColumnPreferences('fullName', preferences) },
            { username: 'Username', ...getColumnPreferences('username', preferences) },
            { email: 'Email', ...getColumnPreferences('email', preferences) },
            { rolesDisplay: 'User Roles', ...getColumnPreferences('rolesDisplay', preferences) },
            { securityUniversesDisplay: 'User Access', disableSort: true, ...getColumnPreferences('securityUniversesDisplay', preferences) },
            { phone: 'Phone Number', ...getColumnPreferences('phone', preferences) },
            { comments: 'Comments', ...getColumnPreferences('comments', preferences) },
        ];

        if (preferences) return getReArrangedTableHeaders(columns);
        return columns;
    }

    updateEditState = isAddEdit => this.setState({ isAddEdit });

    getData = () => {
        this.props.fetchUsersList(
            this.state.pageNumber,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.getFilterData(),
        );
        this.setState({ showListing: true });
    }

    updateState = (order, orderBy, rowsPerPage, pageNumber) => {
        const updateUserPref = isUpdatePreference(this, { order, orderBy, rowsPerPage });
        this.setState({
            order,
            orderBy,
            rowsPerPage,
            pageNumber,
        }, () => {
            if (updateUserPref) saveUserPreference(this, USER_LIST_PREF_KEY);
        });
    }

    loadNextPage = (rows, value, order, orderBy) => {
        this.updateState(order, orderBy, value, rows);
        this.props.fetchUsersList(
            rows,
            value,
            order,
            orderBy,
            this.getFilterData(),
        );
    }

    sortTableColumn = (order, orderBy, page) => {
        this.props.fetchUsersList(
            page.pageNumber,
            page.rowsPerPage,
            order,
            orderBy,
            this.getFilterData(),
        );
        this.updateState(order, orderBy, page.rowsPerPage, page.pageNumber);
        const params = {
            feature: 'Admin',
            sortColumn: orderBy,
        };
        analytics.track('USER_SORT', params);
    }

    refreshList = page => this.props.fetchUsersList(
        page.page,
        page.rowsPerPage,
        this.state.order,
        this.state.orderBy,
        { search: this.search },
        this.getFilterData(),
    );

    listTypeContextAction = (action, page = { page: 0, rowsPerPage: RECORDS_PER_PAGE }) => {
        if (action === 'refresh') {
            this.refreshList(page);
        } else if (action === 'add') {
            this.props.history.push(`${this.props.location.pathname}/add`);
        } else if (action === 'import') {
            this.openImport(true);
            analytics.track('IMPORT', { feature: 'Admin' });
        } else if (action === 'export') {
            this.openExport(true);
            analytics.track('EXPORT', { feature: 'Admin' });
        }
        return true;
    }

    ListSearch = (order, orderBy, filter) => {
        this.search = filter.search || '';
        this.props.fetchUsersList(
            filter.pageNumber,
            filter.rowsPerPage,
            order, orderBy,
            this.getFilterData(),
        );
        this.updateState(order, orderBy, filter.rowsPerPage, filter.pageNumber);
    }

    getFilterData = () => ({
        search: this.search || '',
    })

    clickRow = (n: any) => this.props.history.push(`/users/edit/${n.id}`);

    rearrangeColumns = (listHeaders) => {
        const { tableData } = this.state;
        tableData.listHeaders = listHeaders;
        this.setState({ tableData }, () => {
            saveUserPreference(this, USER_LIST_PREF_KEY);
        });
    }

    showHideColumn = (header) => {
        const { tableData } = this.state;
        tableData.listHeaders.some((h) => {
            const newH = h;
            if (h[Object.keys(h)[0]] === header[Object.keys(header)[0]]) {
                newH.show = !header.show;
                return true;
            }
            return false;
        });

        this.setState({ tableData }, () => {
            saveUserPreference(this, USER_LIST_PREF_KEY);
        });
    }

    dialogHandleClick = (type, value) => {
        if (type === 'show') this.setState({ selectedOption: value });
        else if (type === 'download') {
            const {
                tableData, selectedOption, order, orderBy,
            } = this.state;
            this.openExport(false);
            this.setState({ loading: true });
            const filter = this.getFilterData();
            const requestPayLoad = getRequestPayload({
                order,
                orderBy,
                pageNumber: 0,
                pageSize: tableData.totalRecords,
                filter,
            });

            const reportRequestPayload = {
                ...requestPayLoad,
                reportColumns: getHeaderKeysForPost(tableData.listHeaders),
                timeZone: getUserSettingTimeZone(),
                unitMeasurement: getDistanceStringLong(),
                timeFormat: getTimeFormatAsperUserSettting(),
            };

            downloadFile(
                getURL(),
                `User ${moment().format('YYYY-MM-DD HH-mm-ss')}.${selectedOption.value}`,
                { type: selectedOption.value,
                    headers: { Authorization: getAuthorizationHeader() } },
                () => this.setState({ loading: false }),
                reportRequestPayload,
            );
        } else this.openExport(false);
    }

    openExport = openExport => this.setState({ openExport });
    openImport = openImport => this.setState({ openImport });

    closeImport = () => {
        this.setState({ openImport: false });
    }

    updateLoader = loading => this.setState({ loading });

    render() {
        const { isAddEdit, openImport, loading, selectedOption } = this.state;
        const { classes } = this.props;

        return (
            <React.Fragment>
                {loading && <AppLoader type="fullScreen" />}
                <MiniDrawer redirectTo={this.props.history.push} >
                    {isAddEdit && <User /> }
                    <div className={(!isAddEdit && !openImport) ? '' : classes.hide}>
                        {this.state.showListing ?
                            <ListComponent
                                redirectTo={this.props.history.push}
                                tableData={this.state.tableData}
                                loadNextPage={this.loadNextPage}
                                isUpdating={this.props.isUpdating}
                                rearrangeColumns={this.rearrangeColumns}
                                resetTable={this.props.resetTable}
                                showHideColumn={this.showHideColumn}
                                sortTableColumn={this.sortTableColumn}
                                clickRow={this.clickRow}
                                tableTitle="Users"
                                ListSearch={this.ListSearch}
                                listTypeContextAction={this.listTypeContextAction}
                                pathName={this.props.location.pathname}
                                tableButtons={['Import', 'Export', 'Refresh', 'Add Items']}
                                dialogboxInfo={{
                                    title: `Export data as ${selectedOption.label} for User list`,
                                    dialogHandleClick: this.dialogHandleClick,
                                    showDialog: this.state.openExport,
                                    selectedOption,
                                    options: [{
                                        lable: 'CSV',
                                        value: 'csv',
                                        key: 'CSV',
                                    }],
                                }}
                                resizableHeader
                                orderBy={this.state.orderBy}
                                order={this.state.order}
                                openImport={this.openImport}
                                rowsPerPage={this.state.rowsPerPage}
                            />
                            :
                            <div style={{ height: '100%' }}>
                                <AppLoader type="fullScreen" />
                            </div>
                        }
                    </div>
                    {openImport && <ImportComponent closeImport={this.closeImport} updateLoader={this.updateLoader} importType="Users" />}
                </MiniDrawer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user: state.userList.users,
    totalUser: state.userList.totalUsers,
    isUpdating: state.userList.isUpdating,
    orderBy: state.orderBy,
});

export default withStyles(themeStyles)(reduxConnect(UserList, actions, mapStateToProps));
