/* @flow */
import React, { Component } from 'react';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.userAccount';
import UserAccounts from '../../components/UserAccount/UserAccount';
import AppLoader from '../../components/AppLoader';
import { updateGlobalVariables } from '../../helper-classes/utility-functions';

export type Props = {
    userData: Function,
    logout: Function,
    updateLoader: Function,
    selectUserAccount: Function,
    updateAccountLoader: Function,
    UserAccounts: {
        userAccounts: [],
        isLoader: boolean,
        isAccountLoader: boolean,
        changeAccount: any,
    },
    userIdentity: {
        user: {
            firstName: string,
            lastName: string,
            email: string,
        }
    },
    history: Array<string>,
    location: {
        pathname: string,
    },
    clearNotifications: Function,
    unsubscribePollingJobs: Function,
};

export type State = {};

class UserAccount extends Component<Props, State> {
    height: number;
    static defaultProps = {
        userData: () => {
        },
        userIdentity: {
            user: {
                firstName: '',
                lastName: '',
                email: '',
            },
        },
    }

    componentDidMount() {
        if (this.props.UserAccounts.userAccounts.length === 0) {
            this.props.updateAccountLoader();
            this.props.userData();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.UserAccounts && nextProps.UserAccounts.changeAccount
            && nextProps.UserAccounts.changeAccount.status) {
            updateGlobalVariables();
            if (this.props.location.pathname !== '/settings') {
                this.props.history.push('/settings');
            } else {
                window.location.reload();
            }
        }
    }

    selectUserAccount = (id) => {
        this.props.selectUserAccount(id);
    }

    render() {
        return (
            <div className="app" id="user-popup">
                {this.props.UserAccounts.isLoader && <AppLoader type="fullScreen" />}
                <div>
                    <UserAccounts
                        userIdentity={this.props.userIdentity}
                        userAccounts={this.props.UserAccounts.userAccounts}
                        isAccountLoader={this.props.UserAccounts.isAccountLoader}
                        logout={this.props.logout}
                        isLoader={this.props.UserAccounts.isLoader}
                        updateLoader={this.props.updateLoader}
                        selectUserAccount={this.selectUserAccount}
                        redirectToSettings={() => { if (this.props.location.pathname !== '/settings') this.props.history.push('/settings'); }}
                        clearNotifications={this.props.clearNotifications}
                        unsubscribePollingJobs={this.props.unsubscribePollingJobs}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    UserAccounts: state.userAccounts,
});

export default reduxConnect(UserAccount, actions, mapStateToProps);
