/* @flow */

export const FETCH_SCOREDRIVER_LIST: string = 'FETCH_SCOREDRIVER_LIST';
export const FETCH_SCOREDRIVER_LIST_SUCCESS: string = 'FETCH_SCOREDRIVER_LIST_SUCCESS';
export const FETCH_SCOREDRIVER_LIST_ERROR: string = 'FETCH_SCOREDRIVER_LIST_ERROR';
export const RESET_SCOREDRIVER_LIST: string = 'RESET_SCOREDRIVER_LIST';
export const DRIVER_CENTRIC_SCORECARD_PREF_KEY: string = 'fleetLocate.driverCentricScorecard';
export const SCORECARD_PREF_KEY: string = 'fleetLocate.scorecard';
export type ScoreDriverListTypeAction = {
    type: string;
    payload?: Object;
}
