/* @flow */
import React, { Component } from 'react';
import queryString from 'query-string';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import NearestAssetDetail from '../../components/Assets/Nearest/NearestAssetDetail';
import { getParamValue } from '../../helper-classes/utility-functions';

export type Props = {
    location: Object,
    history: {
        push: Function,
    },
    classes: Object,
};

export type State = {
    location: Object,
    assetId: string,
    landmarkId: string,
};

class NearestAsset extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        const query = queryString.parse(this.props.location.search);
        const state = {
            assetId: getParamValue(this.props, 'assetId'),
            landmarkId: getParamValue(this.props, 'id'),
            location: {
                lat: query.lat,
                lng: query.lng,
            },
        };
        return state;
    }

    render() {
        return (
            <MiniDrawer redirectTo={this.props.history.push}>
                {this.state.assetId &&
                    <NearestAssetDetail
                        assetId={this.state.assetId}
                        landmarkId={this.state.landmarkId}
                        landmark={{
                            lat: parseFloat(this.state.location.lat) || 0,
                            lng: parseFloat(this.state.location.lng) || 0,
                        }}
                        {...this.props}
                    />
                }
            </MiniDrawer>
        );
    }
}

export default NearestAsset;

