/* @flow */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.assetGroupList';
import * as assetGroupActions from '../AssetGroupManage/actions.assetGroupManage';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../components/SharedComponents/ListComponent/ListComponent';
import deleteIcon from '../../assets/icons/assets_List/ic-delete.svg';
import editIcon from '../../assets/icons/assets_List/edit.svg';
import addIcon from '../../assets/icons/assets_List/more.svg';
import AppLoader from '../../components/AppLoader';
import { RECORDS_PER_PAGE } from '../../constants/Config';
import { getParamValue, getSavedPage, removeSavedPage, savePage, getValueFromObjectByKey } from '../../helper-classes/utility-functions';
import { getUserPreference, saveUserPreference, getColumnPreferences, getReArrangedTableHeaders, getParsedPreferencesValue, showHideColumns, rearrangeColumns, isUpdatePreference } from '../../helper-classes/listUtil';
import AssetGroupManage from '../AssetGroupManage/AssetGroupManage';
import analytics from '../../analytics/index';
import { ASSET_GRP_LIST_PREF_KEY } from './constants.assetGroupList';
import { PARENT_GROUP_DELETE } from './../Landmark/LandmarkGroupsList/constants.landmarkGroupsList';

export type Props = {
    fetchAssetGroupList: Function,
    isUpdating: boolean,
    classes : Object,
    listType: string,
    history: {
        push: Function,
    },
    match: {
        path: any,
    },
    resetTable: Function,
    location: any,
    deleteAssetGroup: Function,
    isAssetGroupDeleteing: boolean,
};

const themeStyles = () => ({
    hide: { display: 'none' },
});

export type State = {
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{}>,
        totalRecords: number,
    },
    order: 'asc',
    orderBy: 'name',
    pageNumber: number,
    rowsPerPage: number,
    deleteGroupDialog: {
        open: boolean,
        dialog: string,
        header: string,
        canDelete: boolean,
        data: Object,
    },
    showDeleteMessage: boolean,
    isAddEdit: boolean,
    showListing: boolean,
};

class AssetGroupList extends Component<Props, State> {
    search: string;
    pageOffset: number;
    id: string;
    addUrl: string;
    listUrl: string;
    editUrl: string;
    addSubgroupUrl: string;
    userPreferences: any;

    constructor(props: Props) {
        super(props);
        const pageData = this.initPage();
        this.state = pageData.state;
        this.search = pageData.search;
        this.userPreferences = pageData.userPreferences;
    }

    UNSAFE_componentWillMount() {
        if (!this.state.isAddEdit) getUserPreference(this, ASSET_GRP_LIST_PREF_KEY);
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (this.props.listType !== nextprops.listType) {
            if (nextprops.listType === 'Asset Groups') {
                this.pageOffset = 0;
                this.props.fetchAssetGroupList(this.state.pageNumber, this.state.rowsPerPage, this.state.order, this.state.orderBy, { search: this.search || '' });
            }
        }

        if (nextprops.assetGroups.length > 0) {
            const { assetGroups, totalAssetsGroups } = nextprops;
            const { tableData } = this.state;
            tableData.listData = assetGroups;
            tableData.totalRecords = totalAssetsGroups;
            this.setState({ tableData });
        } else if (!this.state.isAddEdit) {
            const { tableData } = this.state;
            tableData.listData = [];
            tableData.totalRecords = 0;
            this.setState({ tableData });
        }

        if (this.state.showDeleteMessage && nextprops.assetGroupDeleteResponse) {
            if (nextprops.assetGroupDeleteResponse.success === false) {
                this.setState({
                    deleteGroupDialog: {
                        canDelete: false,
                        data: {},
                        header: 'Error',
                        dialog: nextprops.assetGroupDeleteResponse.msg,
                        open: true,
                    },
                    showDeleteMessage: false,
                });
            } else if (nextprops.assetGroupDeleteResponse.success === true) {
                analytics.track('DELETE_ASSET_GROUP', { feature: 'FLEET_Asset_Group' });
                this.setState({
                    deleteGroupDialog: {
                        canDelete: false,
                        data: {},
                        header: 'Success',
                        dialog: 'Asset group deleted successfully',
                        open: true,
                    },
                    showDeleteMessage: false,
                });
                this.props.fetchAssetGroupList(this.state.pageNumber, this.state.rowsPerPage, this.state.order, this.state.orderBy, { search: this.search || '' });
            }
        }
    }

    componentDidUpdate() {
        let { isAddEdit } = this.state;
        if (isAddEdit && this.props.match.path === this.listUrl) {
            isAddEdit = false;
            if (getSavedPage()) {
                const pageData = this.initPage();
                this.updateSavedPageState(pageData.state);
                this.search = pageData.search;
            } else getUserPreference(this, ASSET_GRP_LIST_PREF_KEY);

            removeSavedPage();
            this.updateEditState(isAddEdit);
        } else if (!isAddEdit &&
            (this.props.match.path === this.editUrl ||
            this.props.match.path === this.addUrl ||
            this.props.match.path === this.addSubgroupUrl)) {
            isAddEdit = true;
            this.updateEditState(isAddEdit);
        }
    }

    initPage = () => {
        const savedPage = getSavedPage();
        let isAddEdit = false;
        this.addUrl = '/asset-groups/add';
        this.listUrl = '/asset-groups';
        this.editUrl = '/asset-groups/edit/:groupId';
        this.addSubgroupUrl = '/asset-groups/add/:parentId';

        if (getParamValue(this.props, 'groupId') || this.props.match.path === this.addUrl || this.props.match.path === this.addSubgroupUrl) {
            isAddEdit = true;
        } else removeSavedPage();

        return {
            state: {
                tableData: {
                    listData: [],
                    totalRecords: 0,
                    ischeckBox: false,
                    isGroup: true,
                    listHeaders: this.getListHeader(),
                },
                order: getValueFromObjectByKey(savedPage, 'order', 'asc'),
                orderBy: getValueFromObjectByKey(savedPage, 'orderBy', 'name'),
                pageNumber: getValueFromObjectByKey(savedPage, 'pageNumber', 0),
                rowsPerPage: getValueFromObjectByKey(savedPage, 'rowsPerPage', RECORDS_PER_PAGE),
                deleteGroupDialog: {
                    open: false,
                    dialog: '',
                    header: '',
                    canDelete: false,
                    data: {},
                },
                showDeleteMessage: false,
                isAddEdit,
                showListing: false,
            },
            search: getValueFromObjectByKey(savedPage, 'searchKey', ''),
            userPreferences: null,
        };
    }

    updateSavedPageState = (state) => {
        // call this funciton from component did update only to set the saved page
        this.setState({ ...state }, () => getUserPreference(this, ASSET_GRP_LIST_PREF_KEY));
    }

    getData = () => {
        this.pageOffset = 0;
        this.props.fetchAssetGroupList(this.state.pageNumber, this.state.rowsPerPage, this.state.order, this.state.orderBy, { search: this.search || '' });
        this.setState({ showListing: true });
    }

    updateEditState = isAddEdit => this.setState({ isAddEdit });

    updateState = (order, orderBy, pageNumber, rowsPerPage) => {
        const updateUserPref = isUpdatePreference(this, { rowsPerPage, order, orderBy });
        this.setState({
            order, orderBy, pageNumber, rowsPerPage,
        }, () => {
            if (updateUserPref) saveUserPreference(this, ASSET_GRP_LIST_PREF_KEY);
        });
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            {
                name: 'Name',
                ...getColumnPreferences('name', preferences),
                customMarkup: (d) => {
                    const link = (
                        d.directAssetsCount > 0 ? <Link to={`asset-groups/${d.id}`} style={{ color: 'inherit' }} onClick={() => this.setCustomBackUrl()}> {d.name} </Link> : d.name
                    );
                    return link;
                },
                keyName: 'id',
            },
            { directAssetsCount: 'Direct Assets', ...getColumnPreferences('directAssetsCount', preferences) },
            { indirectAssetsCount: 'Indirect Assets', ...getColumnPreferences('indirectAssetsCount', preferences), disableSort: true },
            { totalAssetsCount: 'Total Assets', ...getColumnPreferences('totalAssetsCount', preferences), disableSort: true },
        ];

        return (preferences) ? getReArrangedTableHeaders(columns) : columns;
    }

    setCustomBackUrl = () => {
        savePage({
            url: '/asset-groups',
            pageNumber: this.state.pageNumber,
            rowsPerPage: this.state.rowsPerPage,
            order: this.state.order,
            orderBy: this.state.orderBy,
            searchKey: this.search,
        });
        analytics.track('SELECT_ASSET_GROUP', { feature: 'FLEET_Asset_Group' });
    }

    sortTableColumn = (order, orderBy, page) => {
        this.pageOffset = page.pageNumber;
        this.props.fetchAssetGroupList(page.pageNumber, page.rowsPerPage, order, orderBy, { search: this.search || '' });
        this.updateState(order, orderBy, page.pageNumber, page.rowsPerPage);
        let sortCol = '';
        if (orderBy === 'indirectAssetsCount') {
            sortCol = 'Indirect Assets';
        } else if (orderBy === 'directAssetsCount') {
            sortCol = 'Direct Assets';
        } else if (orderBy === 'totalAssetsCount') {
            sortCol = 'Total Assets';
        }
        const params = {
            feature: 'FLEET_Asset_Group',
            sortColumn: sortCol === '' ? orderBy : sortCol,
        };
        analytics.track('ASSET_GROUP_SORT', params);
    }

    loadNextPage = (rows, value, order, orderBy) => {
        this.updateState(order, orderBy, rows, value);
        const filter = { search: this.search || '' };
        this.pageOffset = rows;
        this.props.fetchAssetGroupList(rows, value, order, orderBy, filter);
    }

    ListSearch = (order, orderBy, filter) => {
        const filters = { search: filter.search || '' };
        this.search = filters.search;
        this.pageOffset = filter.pageNumber;
        this.props.fetchAssetGroupList(
            filter.pageNumber,
            filter.rowsPerPage,
            order, orderBy,
            filters,
        );
        this.updateState(order, orderBy, filter.pageNumber, filter.rowsPerPage);
    }

    editAssetGroup = id => this.props.history.push(`/asset-groups/edit/${id}`);

    deleteAssetGroupConfirm = (id, data) => {
        const deleteData = { group: data, id };

        if (data.children && data.children.length > 0) {
            this.setState({
                deleteGroupDialog: {
                    canDelete: false,
                    data: {},
                    header: 'Error',
                    dialog: PARENT_GROUP_DELETE,
                    open: true,
                },
            });
        } else if (data.totalAssetsCount > 0) {
            this.setState({
                deleteGroupDialog: {
                    canDelete: false,
                    data: {},
                    header: 'Error',
                    dialog: 'Group with existing assets cannot be deleted.',
                    open: true,
                },
            });
        } else {
            this.setState({
                deleteGroupDialog: {
                    canDelete: true,
                    data: deleteData,
                    header: 'Delete Group',
                    dialog: 'Are you sure want to delete?',
                    open: true,
                },
            });
        }
    }

    addSubGroup = id => this.props.history.push(`/asset-groups/add/${id}`);

    closeDelteDialog = () => this.setState({
        deleteGroupDialog: {
            canDelete: false,
            data: {},
            header: '',
            dialog: '',
            open: false,
        },
    });

    deleteAssetGroup = id => this.setState({
        deleteGroupDialog: {
            canDelete: false,
            data: {},
            header: '',
            dialog: '',
            open: false,
        },
        showDeleteMessage: true,
    }, () => this.props.deleteAssetGroup(id));

    refreshList = page => this.props.fetchAssetGroupList(
        page.page,
        page.rowsPerPage,
        this.state.order,
        this.state.orderBy,
        { search: this.search },
    );

    listTypeContextAction = (action, page = { page: 0, rowsPerPage: RECORDS_PER_PAGE }) => {
        if (action === 'refresh') this.refreshList(page);
        else if (action === 'add') this.props.history.push(`${this.props.location.pathname}/add`);
        return true;
    }

    rearrangeColumns = listHeaders =>
        rearrangeColumns(this, listHeaders, { prefKey: ASSET_GRP_LIST_PREF_KEY });

    showHideColumn = header => showHideColumns(this, header, { prefKey: ASSET_GRP_LIST_PREF_KEY });

    render() {
        const { isAddEdit, deleteGroupDialog } = this.state;
        const { history } = this.props;

        return (
            <div>
                {this.props.isAssetGroupDeleteing ? <AppLoader type="fullScreen" /> : ''}
                <MiniDrawer redirectTo={history.push}>
                    {isAddEdit && <AssetGroupManage /> }
                    <div className={(!isAddEdit) ? '' : this.props.classes.hide}>
                        {this.state.showListing ?
                            <ListComponent
                                redirectTo={history.push}
                                tableData={this.state.tableData}
                                loadNextPage={this.loadNextPage}
                                isUpdating={this.props.isUpdating}
                                rearrangeColumns={this.rearrangeColumns}
                                resetTable={this.props.resetTable}
                                showHideColumn={this.showHideColumn}
                                sortTableColumn={this.sortTableColumn}
                                listTypeContextAction={this.listTypeContextAction}
                                tableTitle="Asset Groups"
                                ListSearch={this.ListSearch}
                                tableButtons={['Refresh', 'Add Items']}
                                pathName={this.props.location.pathname}
                                rowActions={[
                                    {
                                        label: 'Edit',
                                        clickAction: id => this.editAssetGroup(id),
                                        icon: editIcon,
                                    },
                                    {
                                        label: 'Delete',
                                        clickAction: (id, data) =>
                                            this.deleteAssetGroupConfirm(id, data),
                                        icon: deleteIcon,
                                    },
                                    {
                                        label: 'Add Subgroup',
                                        clickAction: id => this.addSubGroup(id),
                                        icon: addIcon,
                                    },
                                ]}
                                order={this.state.order}
                                orderBy={this.state.orderBy}
                                pageNumber={this.state.pageNumber}
                                rowsPerPage={this.state.rowsPerPage}
                                searchKey={this.search}
                            />
                            :
                            <div style={{ height: '100%' }}><AppLoader type="fullScreen" /></div>
                        }
                        <Dialog
                            open={deleteGroupDialog.open}
                            onClose={this.closeDelteDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{deleteGroupDialog.header}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {deleteGroupDialog.dialog}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                {deleteGroupDialog.canDelete ?
                                    <span>
                                        <Button onClick={this.closeDelteDialog} color="primary">No</Button>
                                        <Button onClick={() => { this.deleteAssetGroup(deleteGroupDialog.data.id); }} color="primary" autoFocus>
                                            Yes
                                        </Button>
                                    </span>
                                    :
                                    <Button onClick={this.closeDelteDialog} color="primary">Ok</Button>
                                }
                            </DialogActions>
                        </Dialog>
                    </div>
                </MiniDrawer>
            </div>
        );
    }
}

const mapDispatchToProps = {
    ...actions,
    ...assetGroupActions,
};

const mapStateToProps = state => ({
    assetGroups: state.assetGroupList.assetGroups,
    totalAssetsGroups: state.assetGroupList.totalAssetsGroups,
    isUpdating: state.assetGroupList.isUpdating,
    assetGroupDeleteResponse: state.assetGroupManage.assetGroupDeleteResponse,
    isAssetGroupDeleteing: state.assetGroupManage.isAssetGroupDeleteing,
});

export default
withStyles(themeStyles)(reduxConnect(AssetGroupList, mapDispatchToProps, mapStateToProps));
