/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_ASSETS_LIST } from './constants.assetList';
import { fetchAssetsListError, fetchAssetsListSuccess } from './actions.assetList';
import { getUserToken } from '../../helper-classes/utility-functions';
import config from '../../constants/Config';

type ObservarblesTypes = {
    postJSON: Function,
    of: Function
}

const formatData = (data: any) => {
    if (!data && data.length <= 0) return [];
    return data.map((d) => {
        const asset = Object.assign({}, d);
        if (d.attributes) {
            asset.attributes = JSON.stringify(d.attributes).replace(/\{|\}/g, '').replace(/,/g, ', ');
        }
        return asset;
    });
};

export const getHeader = () => {
    const headers = {
        'X-Nspire-UserToken': getUserToken(),
        'Content-Type': 'application/json',
    };
    return headers;
};

export const getRequestPayload = (payload: any) => {
    // this function is used many places please check once you modify this function
    const {
        order, orderBy, pageNumber, pageSize, filter = {},
    } = payload;
    const data = {
        sortParam: order && orderBy ? [[orderBy, order]] : undefined,
        start: pageNumber * pageSize,
        limit: pageSize,
        filterParam: [],
        searchParams: [''],
        advanceSearchParam: [],
    };

    /* this is sorting is applied because statusStartDate return date
    which is later on subtracted from current date so higher statusStartDate
    becomes lower; that's why added below condition
    */
    if (orderBy === 'statusStartDate') {
        data.sortParam = [[orderBy, order === 'desc' ? 'asc' : 'desc']];
    } else if (orderBy === 'location') {
        data.sortParam = [['city', order], ['stateOrProvince', order]];
    } else if (orderBy === 'locationOrLandmark') {
        data.sortParam = [['landmarkName', order], ['address', order]];
    }

    if (filter.assetFiltersToSend && filter.assetFiltersToSend.length > 0) {
        data.filterParam = filter.assetFiltersToSend;
    } else delete data.filterParam;

    if (filter.advanceSearchParam && filter.advanceSearchParam.length > 0) {
        data.advanceSearchParam = filter.advanceSearchParam;
    } else delete data.advanceSearchParam;

    if (filter.search && filter.search.length > 0 && typeof filter.search === 'string') {
        data.searchParams = [filter.search.replace(/[\s]{2,}/gi, ' ').trim()];
    } else delete data.searchParams;

    return data;
};

export const getURL = () => `${config.get('FLEET_VIEW_SERVICES_URL')}/assets`;

export const getAssets = (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) =>
    actions$
        .ofType(FETCH_ASSETS_LIST)
        .distinctUntilChanged()
        .debounceTime(config.get('DEBOUNCE_TIME'))
        .mergeMap((action) => {
            const {
                order, orderBy, pageNumber, pageSize, filter = {},
            } = action.payload;

            const data = getRequestPayload({
                order, orderBy, pageNumber, pageSize, filter,
            });

            return postJSON(getURL(), data, getHeader())
                .map((result) => {
                    const formatedData = formatData(result.response.data);
                    return fetchAssetsListSuccess(formatedData, result.response.total);
                })
                .catch(error => of(fetchAssetsListError(error)));
        });

export default combineEpics(getAssets);
