/* @flow */

import React, { Component } from 'react';
import { reduxConnect } from '../../../hoc/index';
import * as actions from './actions.diagnosticList';
import ListComponent from '../../../components/SharedComponents/ListComponent/ListComponent';
import MaintenanceFilterForm from '../../../components/Maintenance/MaintenanceFilterForm';
import MaintenanceFilterHelper from '../../../components/Maintenance/MaintenanceFilterHelper';
import { convertDateInGivenFormat } from '../../../helper-classes/utility-functions';
import { applyRadioSelectionFilter, applyPreselectedAssetIdFilter } from './diagnostic-helper-functions';
import backspaceIcon from '../../../assets/icons/assets_List/backspace.svg';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    isUpdatePreference,
    rearrangeColumns,
} from '../../../helper-classes/listUtil';
import { RECORDS_PER_PAGE } from '../../../constants/Config';
import { ACTIVE, CLEARED, ALL, DIAGNOSTICS_LIST_PREF_KEY, DIAGNOSTICS_LIST_ASSET_EDIT_PREF_KEY } from './constants.diagnosticList';
import AppLoader from '../../../components/AppLoader';
import analytics from '../../../analytics';

export type Props = {
    isUpdating: boolean,
    fetchDiagnosticList: Function,
    resetTable: Function,
    history: {
        push: Function,
    },
    overrideStyle: Object,
    isEditable: boolean,
    assetId: string,
    noDataMessage: any,
    hideColumns: Array<{ key: string, value: string }>,
    page: string,
};

export type State = {
    tableData: {
        listHeaders: Array<{}>,
        listData: Array<{}>,
        totalRecords: number,
    },
    order: string,
    orderBy: string,
    page: number,
    rowsPerPage: number,
    selectedFilters: Array<{
        id: string,
        property: string,
        data: {
            id: string
        },
    }>,
    radioSelectorOptions: Object,
    currentRadioSelection: string,
    hasDtcCodesToShow: boolean,
    limit: number,
    showListing: boolean,
};

class DiagnosticList extends Component<Props, State> {
    userPreferences: any;
    preferenceKey: string;
    static defaultProps = {
        isEditable: true,
    };
    constructor(props: Props) {
        super(props);
        let listHeaders = this.getListHeader();
        /*
        Hide columns by removing them from listHeaders where the unique key and value
        identifiers are present (Ex. status: 'Status')
        */
        if ('hideColumns' in props && props.hideColumns.length) {
            props.hideColumns.forEach((column) => {
                const { key, value } = column;
                listHeaders = listHeaders.reduce((acc, cur) => {
                    if (key in cur && cur[key] === value) {
                        return acc;
                    }
                    acc.push(cur);
                    return acc;
                }, []);
            });
        }
        this.state = {
            tableData: {
                // isEditable: props.isEditable,
                isEditable: false,
                editTitle: 'Clear Diagnostic Status',
                listHeaders,
                isGroup: false,
                listData: [],
                totalRecords: 0,
            },
            order: 'desc',
            orderBy: 'date',
            page: 0,
            rowsPerPage: RECORDS_PER_PAGE,
            selectedFilters: MaintenanceFilterHelper.diagnosticListPreselects,
            radioSelectorOptions: {
                pretext: 'Show:',
                options: [ACTIVE, CLEARED, ALL],
                defaultSelection: ALL,
                onChange: selection => this.onRadioFilterSelection(selection),
            },
            currentRadioSelection: ALL,
            hasDtcCodesToShow: false,
            limit: 0,
            showListing: false,
        };
        this.userPreferences = null;
        if (props.page && props.page === 'asset-edit') {
            this.preferenceKey = DIAGNOSTICS_LIST_ASSET_EDIT_PREF_KEY;
        } else {
            this.preferenceKey = DIAGNOSTICS_LIST_PREF_KEY;
        }
    }

    UNSAFE_componentWillMount() {
        getUserPreference(this, this.preferenceKey);
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (nextprops.assetId !== this.props.assetId) {
            this.setState({
                page: 0,
                selectedFilters: [],
                rowsPerPage: RECORDS_PER_PAGE,
                currentRadioSelection: 'ALL',
            });
        }
        if (nextprops.records && nextprops.records.length) {
            const { records, totalRecords } = nextprops;
            const { limit } = this.state;
            const diagnosticList = records.map((item => ({
                ...item,
                receivedDate: item.receivedDate ? convertDateInGivenFormat(item.receivedDate, 'MMM D, YYYY') : '',
                assetName: item.assetRef ? item.assetRef.name : '',
                status: item.active ? 'Active' : 'Cleared',
            })));
            const { tableData } = this.state;
            tableData.listData = diagnosticList;
            tableData.totalRecords = limit > 0 && totalRecords > limit ? limit : totalRecords;
            this.setState({
                tableData,
                hasDtcCodesToShow: (this.state.hasDtcCodesToShow) ? true : totalRecords > 0,
            });
        } else {
            const { tableData } = this.state;
            tableData.listData = [];
            tableData.totalRecords = 0;
            if (this.props.assetId !== nextprops.assetId) {
                this.setState({
                    tableData,
                    hasDtcCodesToShow: false,
                });
            } else {
                this.setState({ tableData });
            }
        }
    }

    componentDidUpdate(prevprops: any) {
        if (prevprops.assetId !== this.props.assetId) {
            const page = 0;
            const {
                rowsPerPage,
                order,
                orderBy,
                selectedFilters,
            } = this.state;
            this.getDiagnosticStatuses(page, rowsPerPage, order, orderBy, selectedFilters);
        }
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            { status: 'Status', ...getColumnPreferences('status', preferences), disableSort: true },
            { assetName: 'Asset', ...getColumnPreferences('assetName', preferences), sortId: 'assetName' },
            { code: 'DTC Code', ...getColumnPreferences('code', preferences), disableSort: true },
            { receivedDate: 'Date', ...getColumnPreferences('receivedDate', preferences), sortId: 'date' },
            {
                description: 'Description',
                ...getColumnPreferences('description', preferences),
                disableSort: true,
                style: {
                    maxWidth: 'calc(80vw - 550px)',
                },
            },
        ];
        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    getData = () => {
        const {
            page,
            rowsPerPage,
            order,
            orderBy,
            selectedFilters,
        } = this.state;
        this.getDiagnosticStatuses(page, rowsPerPage, order, orderBy, selectedFilters);
        this.setState({ showListing: true });
    }

    getDiagnosticStatuses(page, rowsPerPage, order, orderBy, selectedFilters) {
        const { currentRadioSelection, limit } = this.state;
        let newSelectedFilters =
            applyRadioSelectionFilter(selectedFilters, currentRadioSelection);
        newSelectedFilters =
            applyPreselectedAssetIdFilter(newSelectedFilters, this.props.assetId);
        this.setState({ selectedFilters: newSelectedFilters });
        this.props.fetchDiagnosticList(page, rowsPerPage, order, orderBy === 'receivedDate' ? 'date' : orderBy, newSelectedFilters, limit);
    }

    sortTableColumn = (order, orderBy, page) => {
        this.setState({ order, orderBy, rowsPerPage: page.rowsPerPage }, () => {
            saveUserPreference(this, this.preferenceKey);
        });
        this.getDiagnosticStatuses(
            page.pageNumber,
            page.rowsPerPage,
            order,
            orderBy,
            this.state.selectedFilters,
        );
        const params = {
            feature: 'Maintenance',
            sortColumn: orderBy,
        };
        analytics.track('DIAGNOSTICS_SORT', params);
    };

    onFiltersSelected = (newSelectedFilters: any) => {
        const {
            rowsPerPage, order, orderBy,
        } = this.state;
        const limitFilter = newSelectedFilters.find(filter => filter.data.limit);
        this.setState({
            limit: (limitFilter) ? limitFilter.data.limit : 0,
        });
        this.getDiagnosticStatuses(0, rowsPerPage, order, orderBy, newSelectedFilters);
        let assets = true;
        let date = '';
        newSelectedFilters.forEach((x) => {
            if (x.property === 'assetId') {
                assets = false;
            } if (x.property === 'filterDate') {
                date = x.label;
            }
        });
        const params = {
            feature: 'Maintenance',
            allAssets: assets,
            dateRange: date,
            diagnosticCode: this.state.currentRadioSelection,
        };
        analytics.track('DIAGNOSTICS_FILTER', params);
    };

    onRadioFilterSelection = (selection: string) => {
        this.setState(
            { currentRadioSelection: selection },
            () => this.onFiltersSelected(this.state.selectedFilters),
        );
    }

    filterComponent = () => {
        const { selectedFilters } = this.state;
        const dateRangeFilter = { ...MaintenanceFilterHelper.diagnosticDateRangeFilter };
        const dateRangeSelected = MaintenanceFilterHelper.dateRangeSelected(selectedFilters);
        dateRangeFilter.subgroupsHidden = !dateRangeSelected;
        dateRangeFilter.filterTitle = 'Date Activated';
        const filterOptions = [dateRangeFilter, MaintenanceFilterHelper.assetFilterWithGlobalId];
        return (
            <MaintenanceFilterForm
                filters={filterOptions}
                selectedFilters={this.state.selectedFilters}
                defaultSelections={[]}
                onFilterSelected={this.onFiltersSelected}
            />
        );
    }

    listTypeContextAction = (action: string) => {
        if (action === 'filter') {
            return this.filterComponent();
        }
        return true;
    }

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: this.preferenceKey };
        rearrangeColumns(this, listHeaders, options);
    }

    render() {
        const { noDataMessage } = this.props;
        const { hasDtcCodesToShow } = this.state;
        return (
            <React.Fragment>
                {this.state.showListing ?
                    <div>
                        {!this.props.isUpdating
                            && !hasDtcCodesToShow
                            && noDataMessage ?
                            noDataMessage :
                            <ListComponent
                                redirectTo={this.props.history.push}
                                tableData={this.state.tableData}
                                loadNextPage={(page, rowsPerPage, order, orderBy) => {
                                    const updateUserPref = isUpdatePreference(
                                        this,
                                        { rowsPerPage, order, orderBy },
                                    );
                                    this.setState({
                                        page, order, orderBy, rowsPerPage,
                                    }, () => {
                                        if (updateUserPref) {
                                            saveUserPreference(this, this.preferenceKey);
                                        }
                                    });
                                    this.getDiagnosticStatuses(
                                        page,
                                        rowsPerPage,
                                        order,
                                        orderBy,
                                        this.state.selectedFilters,
                                    );
                                }}
                                resetTable={this.props.resetTable}
                                isUpdating={this.props.isUpdating}
                                tableTitle="Diagnostic Codes"
                                tableButtons={this.props.isEditable ? ['Filter List'] : []}
                                filtersApplied={this.state.selectedFilters.length > 0}
                                sortTableColumn={this.sortTableColumn}
                                listTypeContextAction={this.listTypeContextAction}
                                showSearch={false}
                                radioSelectorOptions={this.state.radioSelectorOptions}
                                resizableHeader
                                overrideStyle={this.props.overrideStyle}
                                editIcon={backspaceIcon}
                                orderBy={this.state.orderBy}
                                order={this.state.order}
                                rowsPerPage={this.state.rowsPerPage}
                                rearrangeColumns={this.rearrangeColumns}
                            />
                        }
                    </div>
                    :
                    <div style={{ height: '100%' }}>
                        <AppLoader type="fullScreen" />
                    </div>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    records: state.diagnosticList.records,
    totalRecords: state.diagnosticList.totalRecords,
    isUpdating: state.diagnosticList.isUpdating,
});

export default reduxConnect(DiagnosticList, actions, mapStateToProps);
