/* @flow */
import {
    FETCH_LOGGED_IN_USER,
    FETCH_LOGGED_IN_USER_ERROR,
    FETCH_LOGGED_IN_USER_SUCCESS,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    USER_LOGIN_SUCCESS,
    LOGOUT,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    GRAILS_SSO_SIGNIN,
    GRAILS_SSO_SIGNIN_ERROR,
    ACCOUNT_SUSPENDED_LOGIN_ERROR_MSG,
    UNABLE_TO_LOGIN,
    UNABLE_TO_LOGIN_SSA_EXPIRED,
    RESET_PASSWORD,
    UPDATE_WARNING_MESSAGE,
    AUTH_SERVICE_LOGIN_HANDLER,
    AUTH_SERVICE_LOGIN_ERROR_HANDLER,
} from './contants.login';
import { getLocalStorageValue, removeAuthorizationKeyFromErrorObj } from '../../helper-classes/utility-functions';


export const loginSuccess = () => ({
    type: LOGIN_SUCCESS,
});

const getLoginErrorMsg = (redirectToForgotPassword: boolean, loginFailCounter: string) =>
    (redirectToForgotPassword && parseInt(loginFailCounter, 10) >= 3 ?
        RESET_PASSWORD : UNABLE_TO_LOGIN);

export const loginError = (
    error: string,
    failedUsername: string,
    step: string,
    isAccountSuspended: boolean,
    redirectToForgotPassword: boolean = false,
) => ({
    type: LOGIN_ERROR,
    meta: {
        analytics: {
            type: LOGIN_FAIL,
            payload: {
                feature: 'LoginPage',
                failedUsername,
                reason: isAccountSuspended ? ACCOUNT_SUSPENDED_LOGIN_ERROR_MSG :
                    getLoginErrorMsg(redirectToForgotPassword, getLocalStorageValue('LOGIN_FAIL_COUNTER') || '1'),
                step,
                error: removeAuthorizationKeyFromErrorObj(error),
                failedLoginAttempts: getLocalStorageValue('LOGIN_FAIL_COUNTER') || '1',
            },
        },
    },
    payload: { error, isAccountSuspended, redirectToForgotPassword },
});

export const loginSSAError = (
    error: string,
    failedUsername: string,
    step: string,
) => ({
    type: LOGIN_ERROR,
    meta: {
        analytics: {
            type: LOGIN_FAIL,
            payload: {
                feature: 'LoginPage',
                failedUsername,
                reason: UNABLE_TO_LOGIN_SSA_EXPIRED,
                step,
                error: removeAuthorizationKeyFromErrorObj(error),
                failedLoginAttempts: getLocalStorageValue('LOGIN_FAIL_COUNTER') || '1',
            },
        },
    },
    payload: { error },
});

export const logout = (alert: Object = { message: '', needToShow: false }) => ({
    type: LOGOUT,
    payload: { alert },
    meta: {
        analytics: {
            type: LOGOUT_SUCCESS,
        },
    },
});

export const fetchLoggedInUser = (token: string, username: string, password: string) => ({
    type: FETCH_LOGGED_IN_USER,
    payload: { token, username, password },
});

const getLoggedInUserSuccessPayload = (user, userSettings, analytics) => {
    const data = {
        type: FETCH_LOGGED_IN_USER_SUCCESS,
        payload: { user, userSettings },
        meta: {},
    };
    if (analytics) {
        data.meta = {
            analytics: {
                type: USER_LOGIN_SUCCESS,
                payload: {
                    feature: 'LoginPage',
                    username: user.username,
                    accountId: userSettings.accountId,
                    accountName: userSettings.accountName,
                    // LoginTime: (new Date()).toISOString(),
                },
            },
        };
    }
    return data;
};

export const fetchLoggedInUserSuccess = (user: any, userSettings: any, analytics: boolean) =>
    getLoggedInUserSuccessPayload(user, userSettings, analytics);

export const fetchLoggedInUserError = (error: string) => ({
    type: FETCH_LOGGED_IN_USER_ERROR,
    payload: { error },
});

export const handleUnauthorized = (error: string) => ({
    type: LOGOUT,
    payload: { error },
});

export const grailsSSOLogin = (
    authToken: string,
    authUserName: string,
    customerService: string,
    apiToken: string,
) => ({
    type: GRAILS_SSO_SIGNIN,
    payload: {
        authToken,
        authUserName,
        customerService,
        apiToken,
    },
});

export const ssoLoginError = (error: string, failedUsername: string, step: string) => ({
    type: GRAILS_SSO_SIGNIN_ERROR,
    meta: {
        analytics: {
            type: LOGIN_FAIL,
            payload: {
                failedUsername,
                reason: 'Unable to login',
                step,
                error,
            },
        },
    },
    payload: { error },
});

export const authServiceLoginHandler = (apiToken: string) => ({
    type: AUTH_SERVICE_LOGIN_HANDLER,
    payload: {
        apiToken,
    },
});

export const authServiceLoginError = (error: string, failedUsername: string, step: string) => ({
    type: AUTH_SERVICE_LOGIN_ERROR_HANDLER,
    meta: {
        analytics: {
            type: LOGIN_FAIL,
            payload: {
                failedUsername,
                reason: 'Unable to login',
                step,
                error,
            },
        },
    },
    payload: { error },
});

export const updateWarningMessage = () => ({
    type: UPDATE_WARNING_MESSAGE,
    payload: {},
});
