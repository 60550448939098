exports.LOOK = {
    CATEGORY: {
        LOOKS: 'Looks',
        LOOKS_ID: 'Looks',
    },
    HARD_BRAKING_LOOK: {
        CODE: 'localFleetHardBrakingLook',
        NAME: 'Hard Braking',
        DESCRIPTION: 'Selected asset(s) sudden braking.',
        IMAGE_LOCATION: './images/looks/HardBrakingAccelerationLook.svg',
        ID_POSTFIX: 'HardBreakingLook',
    },
    HARD_ACCELERATION_LOOK: {
        CODE: 'localFleetHardAccelerationLook',
        NAME: 'Hard Acceleration',
        DESCRIPTION: 'Selected asset(s) sudden acceleration.',
        IMAGE_LOCATION: './images/looks/HardBrakingAccelerationLook.svg',
        ID_POSTFIX: 'HardAccelerationLook',
    },
    IDLING_LOOK: {
        CODE: 'localFleetIdlingLook',
        NAME: 'Idling',
        DESCRIPTION: 'Selected asset(s) idling period.',
        IMAGE_LOCATION: './images/looks/IdleLook.svg',
        ID_POSTFIX: 'IdlingLook',
    },
    DISTANCE_TRAVELED_LOOK: {
        CODE: 'localFleetDistanceTraveledLook',
        NAME: 'Distance Traveled',
        DESCRIPTION: 'Selected asset(s) distance traveled.',
        IMAGE_LOCATION: './images/looks/DistanceTraveledLook.svg',
        ID_POSTFIX: 'DistanceTraveledLook',
    },
    SPEED_THRESHOLD_LOOK: {
        CODE: 'localFleetSpeedThresholdLook',
        NAME: 'Speed Threshold',
        DESCRIPTION: 'Selected asset(s) exceeds the user set maximum speed threshold.',
        IMAGE_LOCATION: './images/looks/SpeedThresholdLook.svg',
        ID_POSTFIX: 'SpeedThresholdLook',
    },
    POSTED_SPEED_LOOK: {
        CODE: 'localFleetPostedSpeedLook',
        NAME: 'Posted Speed',
        DESCRIPTION: 'Selected asset(s) exceeds the user set maximum posted speed threshold.',
        IMAGE_LOCATION: './images/looks/PostedSpeedLimitLook.svg',
        ID_POSTFIX: 'PostedSpeedLook',
    },
};

exports.STEPS = {
    STEP1: 'Asset Criteria',
    STEP2: 'Options',
    ADD_LOOK: 'Add Module',
    EDIT_LOOK: 'Edit Module',
    REQ_FIELD: '* Required Field',
    CANCEL: 'Cancel',
    BACK: 'Back',
    SAVE: 'Save',
    UPDATE: 'Update',
    NEXT: 'Next',
};

exports.LOOK_DETAILS = {
    LOADING: 'Loading...',
    PLEASE_SELECT: 'Please select',
    LOOK_NAME: 'Module Name',
    LOOK_NAME_UNIQUE: 'Module name must be unique',
    LOOK_NAME_REQUIRED: 'Module name is required',
    LABEL: {
        HARD_BREAKING_EVENT: 'Hard Braking Event Module',
        CLEAR: 'CLEAR',
        ADD: 'Add',
    },
    MESSAGES: {
        HARD_ACCELERATION_VALID: 'Please select at least 1 event',
        INPUT_VALID: 'At least one input status must be selected.',
    },
    VALUE: {
    },
};

exports.LOOK_FILTER = {
    FILTER: [
        {
            property: 'deviceModelName',
            operator: 'in',
            value: [
                'Calamp LMU1100',
                'Calamp LMU1220',
                'Calamp TTU1200',
                'Calamp TTU1220',
                'Calamp TTU1220 CDMA',
                'Calamp TTU1230HP',
                'Calamp TTU12x0',
                'Xirgo SnapTrack',
            ],
        },
    ],
    LOOKFLAG: true,
};

exports.LOOK_TYPES = [
    {
        code: 'trendline',
        name: 'Trendline',
        imgPath: '../images/looks/Trendline.svg',
        overlayText: 'A line chart visualization. Good for plotting data over time.',
    },
    {
        code: 'list',
        name: 'List',
        imgPath: '../images/looks/List.svg',
        overlayText: 'A list chart visualization. Shows data in table format.',
    },
    {
        code: 'heatmap',
        name: 'HeatMap',
        imgPath: '../images/looks/HeatMap.svg',
        overlayText: 'Plots data on a map, with data points more or less saturated aligned with volume.',
    },
];
