/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Paper, Chip, Grid } from '@mui/material';
import customStyles from './LandmarkGroupDetails.module.scss';
import SearchHeader from '../SharedComponents/SearchHeader/SearchHeader';
import GroupFilters from '../SharedComponents/GroupFilters/GroupFilters';
import List from '../SharedComponents/List/List';
import LandmarkListItem from './LandmarkListItem';
import { PAGE_SIZE } from '../../containers/Landmark/LandmarkGroup/constants.landmarkGroup';
import { getPermissionValue } from '../../helper-classes/utility-functions';
import { getRefreshChip } from '../../util/map_utils';

const minlength = 3;
const isPagination = true;

export type Props = {
    landmarkGroup: Object,
    filterData: Object,
    pageChanged: Function,
    isLoader: boolean,
    redirectTo: Function,
    setMarkerArray: Function,
    updateLoader: Function,
    clearListData: Function,
    showRefreshButton: boolean,
    refreshData: Function,
};

type State = {
    data: any,
    ShowFilterDrawer: boolean,
    isSearch: boolean,
    totalNumberofPage: number,
    page: number,
    lastSearch: string,
    filters: Object,
    appliedFilters: Object,
}

class LandmarkGroupDetails extends Component<Props, State> {
    isPageScroll: boolean;
    currentPage: number;
    filtersSelected: boolean;

    constructor() {
        super();
        this.state = {
            ShowFilterDrawer: false,
            isSearch: false,
            totalNumberofPage: 0,
            page: 0,
            data: [],
            lastSearch: '',
            filters: {
                filtersToSend: [],
            },
            appliedFilters: {},
        };
        this.isPageScroll = false;
        this.currentPage = 0;
        this.filtersSelected = false;
    }

    componentDidMount() {
        if (this.props.clearListData && typeof this.props.clearListData === 'function') {
            this.props.clearListData(this.initState);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { landmarkGroup } = nextProps;
        if (landmarkGroup && landmarkGroup.data) {
            const newState = this.state;
            const stateData = newState.data;
            // newState.page = this.currentPage;

            if (stateData && stateData.length > 0) {
                const temp = JSON.stringify(stateData);
                const tempData = landmarkGroup.data
                    .filter(e => temp.indexOf(JSON.stringify(e)) === -1);

                if (this.isPageScroll) {
                    if (tempData.length > 0) {
                        newState.page = this.currentPage;
                        newState.data = newState.data.concat(tempData);
                        if (stateData.length === landmarkGroup.data.total) {
                            this.isPageScroll = false;
                        }
                        this.setState(
                            newState,
                            () => this.props.setMarkerArray(newState.data),
                        );
                    }
                } else if (temp.indexOf(JSON.stringify(landmarkGroup.data)) === -1) {
                    newState.data = JSON.parse(JSON.stringify(landmarkGroup.data));
                    this.setState(
                        newState,
                        () => this.props.setMarkerArray(landmarkGroup.data),
                    );
                }
                this.calculatedPageCount(landmarkGroup);
            } else if ((this.props.isLoader && !nextProps.isLoader)) {
                newState.data = landmarkGroup.data;
                this.setState(newState);
                this.calculatedPageCount(landmarkGroup);
                this.props.setMarkerArray(landmarkGroup.data);
            }
        }
    }

    calculatedPageCount = (res: any) => {
        if (res.total && res.total > 0) {
            const totalNumberofPage = Math.ceil(res.total / PAGE_SIZE);
            if (this.state.totalNumberofPage !== totalNumberofPage) {
                this.setState({ totalNumberofPage });
            }
        }
    };

    initState = (lastSearch) => {
        this.setState({
            totalNumberofPage: 0,
            page: 0,
            data: [],
            lastSearch,
        });
        this.props.setMarkerArray([]);
        this.currentPage = 0;
    }

    handleFilterChange = (key, value) => {
        if (key !== '') {
            const { filters, appliedFilters } = this.state;
            filters.filtersToSend = value;

            if (value.length > 0 && value[0].length > 1) {
                const filterVal = filters.filtersToSend[0][1];
                appliedFilters.range = filterVal;
            }

            if (key === 'clear') {
                this.isPageScroll = false;
                this.filtersSelected = false;
                Object.keys(appliedFilters).forEach((k) => { delete appliedFilters[k]; });
                this.props.updateLoader(true);
            }

            this.setState({
                filters,
                appliedFilters,
            }, () => {
                this.props.pageChanged(
                    0, this.state.isSearch,
                    this.state.lastSearch, true, filters.filtersToSend,
                );
            });
        }
    }

    toggleFilterDrawer = (state) => {
        this.setState({
            ShowFilterDrawer: state,
            isSearch: state,
        });

        this.filtersSelected = false;
        const { appliedFilters } = this.state;
        if (appliedFilters !== undefined && appliedFilters.range !== undefined) {
            this.filtersSelected = true;
        }

        if (!state && (this.filtersSelected)) {
            this.initState('');
            this.props.updateLoader(true);
            this.props.pageChanged(
                0,
                this.state.isSearch,
                '',
                true,
                this.state.filters.filtersToSend,
            );
        }
    }

    toggleFilterSearchHeader = (open) => {
        this.setState({
            isSearch: open,
        });
        this.initState('');
        if (!open) {
            this.props.updateLoader(true);
            this.props.pageChanged(
                0,
                this.state.isSearch,
                '',
                true,
                this.state.filters.filtersToSend,
            );
        }
    }

    listItems = (data) => {
        let item = '';
        if (data) {
            item = (
                <div key={`${Math.random()}`} role="link" tabIndex={0} onKeyDown={() => {}} onClick={() => this.goToLandmarkDetails(data.id)}>
                    <LandmarkListItem landmark={data} key={`${Math.random()}`} />
                    <div className={customStyles['list-border']} />
                </div>
            );
        }
        return item;
    }

    goToLandmarkDetails = (id) => {
        if (getPermissionValue('Landmarks') !== 'None') {
            this.props.redirectTo(`/home?landmarkId=${id}&zoom=true`);
        }
        return true;
    }

    handleSearchChange = (e) => {
        this.setState({
            isSearch: true,
        }, () => {
            this.initState(e);
            if (e.length >= minlength) {
                this.props.updateLoader(true);
                this.props.pageChanged(0, true, e, true, this.state.filters.filtersToSend);
            } else {
                this.props.updateLoader(false);
            }
        });
    };

    getHeaderName() {
        return (this.props.landmarkGroup.data
            && this.props.landmarkGroup.data.length > 0
            && this.props.landmarkGroup.data[0]
            && this.props.landmarkGroup.data[0].landmarkGroupName)
            ? this.props.landmarkGroup.data[0].landmarkGroupName : ' ';
    }

    handlePageChange = (page) => {
        if (this.currentPage !== page) {
            this.isPageScroll = true;
            this.currentPage = page;
            this.props.pageChanged(
                page, this.state.isSearch,
                this.state.lastSearch, false, this.state.filters.filtersToSend,
            );
        }
    }

    getLandmarkTotal() {
        let count = 0;
        if (this.state.data.length > 0) {
            count = this.props.landmarkGroup.total || 0;
        }
        return count;
    }

    getHeader = () => (
        <SearchHeader headerName={this.getHeaderName()} subHeader={`Showing ${this.state.data.length} of ${this.getLandmarkTotal()}`} title="Landmark" toggleFilterDrawer={this.toggleFilterDrawer} toggleFilterSearchHeader={flag => this.toggleFilterSearchHeader(flag)} handleChange={this.handleSearchChange} filtersSelected={this.filtersSelected} />
    )

    getList = () => {
        const { isLoader, setMarkerArray } = this.props;

        const {
            isSearch,
            page,
            data,
            totalNumberofPage,
            lastSearch = '',
        } = this.state;

        return (
            <List
                data={data}
                isLoader={isLoader}
                pagination={{ isPagination, pageChanged: this.handlePageChange }}
                currentPage={page}
                totalNumberofPage={totalNumberofPage}
                isSearch={(isSearch && lastSearch.length <= minlength)}
                getListItem={this.listItems}
                setMarkerArray={setMarkerArray}
            />
        );
    }

    getFilterChips = () => {
        const { appliedFilters } = this.state;
        if (appliedFilters !== undefined && appliedFilters.range !== undefined) {
            const key = 'range';
            const fltrLbl = 'Total Vehicles';
            let fltrVal = appliedFilters.range.split(':');
            fltrVal = `${fltrVal[1]} to ${fltrVal[2]}`;
            return (
                <Grid container>
                    <Chip
                        className={customStyles['filter-chip']}
                        key={key}
                        label={`${fltrLbl} : ${fltrVal}`}
                        onDelete={() => this.handleFilterChange('clear', [])}
                    />
                </Grid>
            );
        }
        return null;
    };

    render() {
        const {
            filterData,
        } = this.props;
        const {
            ShowFilterDrawer,
            appliedFilters,
        } = this.state;

        return (
            <div className={customStyles.container}>
                {!ShowFilterDrawer ?
                    <div id="landmark-group-details">
                        {this.getHeader()}
                        {this.props.showRefreshButton && getRefreshChip(this.props.refreshData)}
                        {this.getFilterChips()}
                        {this.getList()}
                    </div> :
                    <Paper>
                        <GroupFilters
                            filterData={filterData}
                            appliedFilters={appliedFilters}
                            handleChange={this.handleFilterChange}
                            toggleFilterDrawer={flag => this.toggleFilterDrawer(flag)}
                        />
                    </Paper>
                }
            </div>
        );
    }
}

export default withStyles({}, { withTheme: true })(LandmarkGroupDetails);
