export const ADD_DRIVER_SETTINGS: string = 'ADD_DRIVER_SETTINGS';
export const ADD_DRIVER_SETTINGS_SUCCESS: string = 'ADD_DRIVER_SETTINGS_SUCCESS';
export const ADD_DRIVER_SETTINGS_ERROR: string = 'ADD_DRIVER_SETTINGS_ERROR';
export const FETCH_DRIVER_SETTINGS: string = 'FETCH_DRIVER_SETTINGS';
export const FETCH_DRIVER_SETTINGS_SUCCESS: string = 'FETCH_DRIVER_SETTINGS_SUCCESS';
export const FETCH_DRIVER_SETTINGS_ERROR: string = 'FETCH_DRIVER_SETTINGS_ERROR';
export const UPDATE_DRIVER_SETTINGS: string = 'UPDATE_DRIVER_SETTINGS';
export const UPDATE_DRIVER_SETTINGS_SUCCESS: string = 'UPDATE_DRIVER_SETTINGS_SUCCESS';
export const UPDATE_DRIVER_SETTINGS_ERROR: string = 'UPDATE_DRIVER_SETTINGS_ERROR';
export const CLOSE_DRIVER_SETTINGS_DIALOG: string = 'CLOSE_DRIVER_SETTINGS_DIALOG';
export const UPDATE_DRIVER_SETTINGS_LOADER: string = 'UPDATE_DRIVER_SETTINGS_LOADER';
export type DriverSettingsTypeAction = {
    type: string;
    payload?: Object;
}

export const DefaultSettings = {
    hardAccel: true,
    hardAccelWeightage: 2,
    hardBrake: true,
    hardBrakeWeightage: 2,
    seatBelt: false,
    seatBeltWeightage: 2,
    est71To80: false,
    est71To80Weightage: 2,
    est81To90: true,
    est81To90Weightage: 2,
    estGt90: true,
    estGt90Weightage: 2,
    eps10To20: false,
    eps10To20Weightage: 2,
    eps21To30: false,
    eps21To30Weightage: 2,
    epsGt30: false,
    epsGt30Weightage: 2,
    idle6To18: false,
    idle6To18Weightage: 2,
    idle19To30: false,
    idle19To30Weightage: 2,
    idleGt30: true,
    idleGt30Weightage: 2,
};
