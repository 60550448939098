/* @flow */
import { FETCH_LANDMARKGROUP, FETCH_LANDMARKGROUP_SUCCESS, FETCH_LANDMARKGROUP_ERROR, UPDATE_LANDMARKGROUP_LOADER, SEARCH_LANDMARKGROUP, SEARCH_LANDMARKGROUP_SUCCESS, SEARCH_LANDMARKGROUP_ERROR } from './constants.landmarkGroup';

export const initialState = {
    landmarkGroup: {},
    isUpdating: false,
    isLoader: false,
    isSearch: false,
};

type stateType = {
    landmarkGroup: {},
    isUpdating: boolean,
    isLoader: boolean,
    isSearch: boolean,
}

type actionType = {
    type: string,
    payload :{
        response:{},
        isLoader: boolean,
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_LANDMARKGROUP:
        return {
            ...state,
            isUpdating: true,
        };
    case FETCH_LANDMARKGROUP_SUCCESS:
        return {
            ...state,
            landmarkGroup: action.payload.response,
            isUpdating: false,
            isLoader: false,
            isSearch: false,
        };
    case UPDATE_LANDMARKGROUP_LOADER:
        return {
            ...state,
            isLoader: action.payload.isLoader,
        };
    case SEARCH_LANDMARKGROUP:
        return {
            ...state,
            isUpdating: true,
        };
    case SEARCH_LANDMARKGROUP_SUCCESS:
        return {
            ...state,
            landmarkGroup: action.payload.response,
            isUpdating: false,
            isLoader: false,
            isSearch: true,
        };
    case FETCH_LANDMARKGROUP_ERROR:
        return {
            ...state,
            isLoader: false,
            isUpdating: false,
        };
    case SEARCH_LANDMARKGROUP_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    default:
        return state;
    }
};
