/* @flow */
export const GLOBAL_SEARCH: string = 'GLOBAL_SEARCH';
export const GLOBAL_SEARCH_ERROR: string = 'GLOBAL_SEARCH_ERROR';
export const GLOBAL_SEARCH_SUCCESS: string = 'GLOBAL_SEARCH_SUCCESS';
export const USER_IDENTITY: string = 'USER_IDENTITY';
export const USER_IDENTITY_SUCCESS: string = 'USER_IDENTITY_SUCCESS';
export const USER_IDENTITY_ERROR: string = 'USER_IDENTITY_ERROR';
export const SEARCH_RESULT_DRIVER: string = 'SEARCH_RESULT_DRIVER';
export const SEARCH_RESULT_ADDRESS: string = 'SEARCH_RESULT_ADDRESS';
export const SEARCH_RESULT_ASSET: string = 'SEARCH_RESULT_ASSET';
export const SEARCH_RESULT_LM: string = 'SEARCH_RESULT_LM';
export const UPDATE_USER_IDENTITY_LOADER: string = 'UPDATE_USER_IDENTITY_LOADER';
export const GLOBAL_SEARCH_UPDATE_TAB: string = 'GLOBAL_SEARCH_UPDATE_TAB';
export const UPDATE_GLOBAL_SEARCH: string = 'CLEAR_GLOBAL_SEARCH';
export const NEAREST_ASSET_LANDMARK: string = 'NEAREST_ASSET_LANDMARK';
export const NEAREST_ASSET_LANDMARK_SUCCESS: string = 'NEAREST_ASSET_LANDMARK_SUCCESS';
export const NEAREST_ASSET_LANDMARK_ERROR: string = 'NEAREST_ASSET_LANDMARK_ERROR';

export const CategoryTypes = {
    ADDRESSES: 'addresses',
    ASSETS: 'assets',
    LANDMARKS: 'landmarks',
    DRIVERS: 'drivers',
};

export const recordsPerPage = 50;

export const LeftMenuItems = [
    {
        id: 1,
        icon: 'truck',
        text: 'Assets',
        count: 0,
        category: 'assets',
        show: true,
    },
    {
        id: 2,
        icon: 'landmarks',
        text: 'Landmarks',
        count: 0,
        category: 'landmarks',
        show: true,
    },
    {
        id: 3,
        icon: 'shape',
        text: 'Drivers',
        count: 0,
        category: 'drivers',
        show: true,
    },
    {
        id: 4,
        icon: 'pointers',
        text: 'Addresses',
        count: 0,
        category: 'addresses',
        show: true,
    },
];

