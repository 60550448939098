/* @flow */

export const FETCH_ASSETS_DETAILS: string = 'FETCH_ASSETS_DETAILS';
export const FETCH_ASSETS_DETAILS_SUCCESS: string = 'FETCH_ASSETS_DETAILS_SUCCESS';
export const FETCH_ASSETS_DETAILS_ERROR: string = 'FETCH_ASSETS_DETAILS_ERROR';
export const FETCH_ASSETS_TRIPS: string = 'FETCH_ASSETS_TRIPS';
export const FETCH_ASSETS_TRIPS_SUCCESS: string = 'FETCH_ASSETS_TRIPS_SUCCESS';
export const UPDATE_TRIPS_LOADER: string = 'UPDATE_TRIPS_LOADER';
export const FETCH_ASSETS_EVENTS: string = 'FETCH_ASSETS_EVENTS';
export const FETCH_ASSETS_EVENTS_SUCCESS: string = 'FETCH_ASSETS_EVENTS_SUCCESS';
export const FETCH_ASSETS_EVENTS_ERROR: string = 'FETCH_ASSETS_EVENTS_ERROR';
export const FETCH_ASSETS_LIMIT: number = 10;
export const FETCH_TRIPS_LIMIT: number = 2500;
export const TRIPS_VIEW_PREF_KEY: string = 'fleetLocate.tripsViewConfig';
export const TRIP_EVENTS_PREF_KEY: string = 'fleetLocate.tripEvents';
export const FETCH_TRIPS_ERROR: string = 'FETCH_TRIPS_ERROR';
export const EVENT_HISTORY: string = 'EventHistory';
export const UPDATE_MAP_REFRESH_LOADER: string = 'UPDATE_MAP_REFRESH_LOADER';
export const EVENT_DRAWER_WIDTH: number = 700;
export const TRIP_DRAWER_WIDTH: number = 568;
export type AssetTypeAction = {
    type: string;
payload?: Object;
}
