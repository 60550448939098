/* @flow */
import React, { PureComponent } from 'react';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';
import { Grid, Paper, Card, Tooltip } from '@mui/material';

import {
    getEventNameCell,
    getHeading,
    getSpeedLimitCell,
    getSpeedCell,
    getCellSignalIcon,
    getBatteryIcon,
    getCellSignalText,
    getSatIcon,
    getBatteryIconTooltipText,
} from './../../components/Events/EventsListUtil';
import { convertDateTime, getSpeedUnitString } from './../../helper-classes/utility-functions';
import customStyles from './../MapsWrapper.module.scss';

type Props = {
  tripInfoBox: Object,
}

class CustomInfoWindow extends PureComponent<Props> {
    render() {
        const { tripInfoBox } = this.props;
        const eventColor = getEventNameCell(tripInfoBox).color;
        const eventName = getEventNameCell(tripInfoBox).name;
        const operatorName = tripInfoBox.operatorSegment ? `${tripInfoBox.operatorSegment.operatorFirstName} ${tripInfoBox.operatorSegment.operatorLastName}` : '';
        const movementSegment = {
            speed: `${getSpeedCell(tripInfoBox)}${getSpeedUnitString()}`,
            speedLimit: getSpeedLimitCell(tripInfoBox),
            heading: getHeading(tripInfoBox),
        };
        const { locationSegment } = tripInfoBox;
        let address = '';

        if (locationSegment && locationSegment.address) {
            address += `${(locationSegment.address.city) ? `${locationSegment.address.city} ` : ''}${(locationSegment.address.stateOrProvince) ? `${locationSegment.address.stateOrProvince}, ` : ''}${(locationSegment.address.postalCode) ? `${locationSegment.address.postalCode} ` : ''}`;
        }
        const rssi = tripInfoBox.networkSegment ? tripInfoBox.networkSegment.rssi : null;
        const batteryVoltage = tripInfoBox.batterySegment ?
            tripInfoBox.batterySegment.externalVoltage : null;
        const { batteryIconTooltipText } = getBatteryIconTooltipText(batteryVoltage);
        const { cellSignalText } = getCellSignalText(rssi);

        return (
            <InfoBox
                defaultPosition={{
                    lat: tripInfoBox.locationSegment.lat,
                    lng: tripInfoBox.locationSegment.lng,
                }}
                key={`${tripInfoBox.locationSegment.lat}-${tripInfoBox.locationSegment.lng}`}
                options={{
                    closeBoxURL: '',
                    enableEventPropagation: true,
                    closeBoxMargin: '10px 2px 2px 2px',
                    boxStyle: {
                        width: '332px',
                        margin: '10px',
                        minHeight: '92px',
                    },
                }}
            >
                <Paper>
                    <Card>
                        <Grid container className={customStyles.infoWindow}>
                            <Grid
                                item
                                xs={1}
                                className={customStyles.icon}
                            >
                                <div>
                                    <Tooltip title={cellSignalText || ''} disableFocusListener>
                                        <span>
                                            {getCellSignalIcon(rssi, customStyles)}
                                        </span>
                                    </Tooltip>
                                </div>
                                <div>
                                    <Tooltip title={batteryIconTooltipText || ''} disableFocusListener>
                                        <span>
                                            {getBatteryIcon(batteryVoltage, customStyles, true)}
                                        </span>
                                    </Tooltip>
                                </div>
                                <div className={customStyles.gpsIcon}>
                                    {getSatIcon(tripInfoBox.gpsSegment, customStyles, true)}
                                </div>
                            </Grid>
                            <Grid item xs={11}>
                                <Grid container className={customStyles.container}>
                                    <Grid
                                        item
                                        xs={6}
                                        className={customStyles.eventName}
                                        style={{ color: eventColor }}
                                    >
                                        <Tooltip title={eventName || ''}>
                                            <div
                                                className={customStyles.ellipsis}
                                                style={{ maxWidth: 135 }}
                                            >
                                                {eventName}
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        className={customStyles.operatorName}
                                    >
                                        <Tooltip title={operatorName || ''}>
                                            <div
                                                className={customStyles.ellipsis}
                                                style={{ maxWidth: 135 }}
                                            >
                                                {operatorName}
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        className={customStyles.eventDate}
                                    >
                                        {tripInfoBox.eventDate ? convertDateTime(tripInfoBox.eventDate) : ''}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        style={{ paddingTop: 1 }}
                                    >
                                        <span className={customStyles.speed}>
                                            {movementSegment.speed}
                                        </span>&nbsp;
                                        <span className={customStyles.heading}>
                                            {movementSegment.heading}
                                        </span>&nbsp;
                                        {movementSegment.speedLimit &&
                                            <span className={customStyles.speedLimit}>
                                                (limit {movementSegment.speedLimit})
                                            </span>
                                        }
                                    </Grid>
                                    {(locationSegment.address && locationSegment.address.line1) &&
                                    <Grid
                                        item
                                        xs={12}
                                        className={customStyles.address}
                                    >
                                        <Tooltip title={locationSegment.address.line1 || ''}>
                                            <div
                                                className={customStyles.ellipsis}
                                                style={{ maxWidth: 280 }}
                                            >
                                                {locationSegment.address.line1}
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                    }
                                    <Grid
                                        item
                                        xs={12}
                                        className={customStyles.address}
                                    >
                                        <Tooltip title={address || ''}>
                                            <div
                                                className={customStyles.ellipsis}
                                                style={{ maxWidth: 280 }}
                                            >
                                                {address}
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Paper>
            </InfoBox>
        );
    }
}

export default CustomInfoWindow;
