/* @flow */
import { combineEpics } from 'redux-observable';
import {
    USER_DATA,
    USER_ACCOUNT_AUTH,
} from './constants.userAccount';
import {
    userDataSucces,
    userDataError,
    setAccountSuccess,
    setAccountError,
} from './actions.userAccount';
import config from '../../constants/Config';
import {
    fetchLoggedInUserSuccess,
    loginSuccess,
} from '../Login/actions.login';
import { getAppToken, setTermsAndConditions, getUserToken, blockLoginDueToInstallAppPermission } from '../../helper-classes/utility-functions';
import { SSAS_URL_PARAMS, UNABLE_TO_LOGIN } from '../Login/contants.login';
import { fetchUserSettings } from './../Login/epics.login';
import { logout } from '../Header/actions.globalsearch';

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    of: Function
}

export const getAccountDetails =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(USER_DATA)
            .mergeMap(() => {
                const headers = {
                    'X-Nspire-UserToken': getUserToken(),
                };
                const timeStamp = (new Date()).getTime();
                return getJSON(`${config.get('PLATFORM_API_BASE_URL')}/accounts?date=${timeStamp}`, headers)
                    .map(result => userDataSucces(result))
                    .catch(error => of(userDataError(error)));
            });

export const selectAccountApi =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(USER_ACCOUNT_AUTH)
            .mergeMap((action) => {
                const appToken = getAppToken();
                const headers = {
                    'X-Nspire-AppToken': appToken,
                    'X-Nspire-UserToken': getUserToken(),
                    'X-NSpire-Account': action.payload.id,
                };
                const timeStamp = (new Date()).getTime();
                return getJSON(`${config.get('IDENTITY_BASE_URL')}/token?date=${timeStamp}`, headers)
                    .mergeMap((result) => {
                        const ssaHeaders = {
                            'Content-Type': 'application/json',
                            'X-Nspire-UserToken': result.token,
                            'X-Nspire-AppToken': appToken,
                        };
                        return getJSON(`${config.get('PLATFORM_API_BASE_URL')}${SSAS_URL_PARAMS}`, ssaHeaders)
                            .mergeMap(ssaResponse => setTermsAndConditions(ssaResponse))
                            .mergeMap(() => fetchUserSettings({ token: result.token }))
                            .mergeMap((userSettings) => {
                                const blockLogin =
                                    blockLoginDueToInstallAppPermission(userSettings);

                                if (blockLogin) {
                                    return of(logout({ message: UNABLE_TO_LOGIN }));
                                }

                                const userName = localStorage.getItem('username') || '';
                                return [
                                    setAccountSuccess(result),
                                    loginSuccess(),
                                    fetchLoggedInUserSuccess({ userName }, userSettings, false),
                                ];
                            });
                    })
                    .catch(error => of(setAccountError(error)));
            });
export default combineEpics(
    getAccountDetails,
    selectAccountApi,
);

