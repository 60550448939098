/* eslint jsx-a11y/no-noninteractive-element-interactions: 0 */
/* @flow */

import React, { Component } from 'react';
import { createTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { Paper, Typography, Toolbar, IconButton, TextField, Divider, FormControl, Grid, Button, Tabs, Tab } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { ValidatorForm } from 'react-material-ui-form-validator';
import back from '../../assets/icons/back.png';
import AssetCriteria from '../../components/SharedComponents/AssetCriteria/AssetCriteria';
import LandmarkCriteria from '../SharedComponents/LandmarkCriteria/LandmarkCriteria';
import { getPermissionValue } from '../../helper-classes/utility-functions';
import analytics from '../../analytics/index';

const styles = () => ({
    root: {
        padding: 16,
        display: 'flex',
    },
    formLabelRoot: {
        color: '#b0b8bf',
    },
    toolbar: {
        backgroundColor: '#343e48',
        color: '#ffffff',
    },
    activeTab: {
        width: 160,
        color: '#fff !important',
        fontSize: 16,
    },
    inActiveTab: {
        width: 160,
        color: 'rgba(255, 255, 255, 0.63) !important',
        fontSize: 16,
    },
    tabBar: {
        backgroundColor: '#343e48',
        color: '#ffffff',
    },
    formControl: {
        padding: '0 16px 16px',
        width: '90%',
    },
    card: { backgroundColor: '#999b9e' },
    app: {
        paddingTop: 16,
        paddingLeft: 17,
        paddingRight: 23,
    },
    button: { margin: '0 10px 0 0' },
    clearButton: { color: '#007aff', margin: '0 10px' },
    backButton: {
        verticalAlign: 'middle',
        paddingRight: 10,
        marginLeft: -12,
    },
    requiredField: {
        fontSize: 14,
        opacity: 0.87,
        whiteSpace: 'nowrap',
    },
    titleWrap: { lineHeight: 2 },
    divider: { borderRadius: 2 },
    sideBySide: { display: 'flex', overflow: 'hidden' },
    formFields: { padding: '30px 0 0 30px' },
    textField: {
        width: '30%',
        margin: '0 30px 0 0',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        minWidth: 250,
    },
    properties: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    propertyTextField: {
        flex: 'auto',
        margin: '0 16px 0 0',
        maxWidth: 170,
        minWidth: 126,
    },
    dropdownContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '12px 0',
        justifyContent: 'space-between',
    },
    dropdown: {
        flex: 'auto',
        minWidth: 152,
        margin: '0 20px 0 0',
        whiteSpace: 'nowrap',
    },
});

const theme = createTheme();
export type Props = {
    assetCriteria: Object,
    classes: Object,
    createUserAccess: Function,
    deleteUserAccess: Function,
    history: {
        goBack: Function,
    },
    isEdit: boolean,
    isUpdating: boolean,
    landmarkCriteria: Object,
    preloadData: Object,
    updateUserAccess: Function,
};

export type State = {
    value: number,
    name: string,
    comment: string,
    assetData: {},
    landmarkData: {},
    validators: Object,
};

class AddUserAccess extends Component<Props, State> {
    count: number;
    landmarkData: Object;
    assetData: Object;
    name: string;
    comment: string;
    isSystemUser: boolean;
    isLoaded: boolean;

    static defaultProps = {
        preloadData: {
            assetData: {},
            landmarkData: {},
            assetCriteria: {
                type: 'groups-tags',
                groups: [],
                tags: [],
                assets: [],
            },
            landmarkCriteria: {
                type: 'groups-tags',
                groups: [],
                landmarks: [],
            },
            name: '',
            description: '',
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            name: this.props.preloadData.name || '',
            comment: this.props.preloadData.description || '',
            assetData: {
                type: 'groups-tags',
                groups: [],
                tags: [],
                assets: [],
            },
            landmarkData: {
                type: 'groups-tags',
                groups: [],
                landmarks: [],
            },
            validators: { name: { isError: false, errorMessages: '' } },
        };
        this.name = this.props.preloadData.name || '';
        this.comment = this.props.preloadData.description || '';
        this.isSystemUser = false;
        this.isLoaded = false;
    }

    UNSAFE_componentWillMount() {
        ValidatorForm.addValidationRule('isEmpty', (value) => {
            if (typeof value === 'string' && value.replace(/\s/gi, '') === '') return false;
            else if (typeof value === 'number' && value === 0) return false;

            return true;
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps: Object) {
        if (!this.isLoaded && nextProps.isEdit && !nextProps.isUpdating) {
            this.setState({
                name: nextProps.preloadData.name || '',
                comment: nextProps.preloadData.description || '',
                assetData: nextProps.preloadData.assetCriteria || {},
                landmarkData: nextProps.preloadData.landmarkCriteria || {},
                value: 0,
            });

            this.name = nextProps.preloadData.name || '';
            this.comment = nextProps.preloadData.description || '';
            this.assetData = nextProps.preloadData.assetCriteria;
            this.landmarkData = nextProps.preloadData.landmarkCriteria;
            this.isSystemUser = nextProps.preloadData.readOnly;
        }
    }

    handleChangeTab = (event, value) => this.setState({ value });

    handleChangeIndex = index => this.setState({ value: index });

    saveData = () => {
        const {
            name, comment, assetData, landmarkData,
        } = this.state;
        this.isLoaded = true;

        if (this.validateData()) {
            const dataToSend = {
                name: this.name || name,
                description: this.comment || comment || '',
                assetData: this.assetData || assetData,
                landmarkData: this.landmarkData || landmarkData,
            };
            if (dataToSend.assetData && dataToSend.assetData.tags) {
                dataToSend.assetData.tags = [];
            }
            const params = {
                feature: 'Admin',
                name: this.name,
                assetCriteria: (this.assetData && this.assetData.type === 'custom') ? 'Custom Select' : 'Select Groups',
                landmarkCriteria: (this.landmarkData && this.landmarkData.type === 'custom') ? 'Custom Select' : 'Select Groups',
            };
            if (this.props.isEdit) {
                this.props.updateUserAccess(dataToSend);
                analytics.track('EDIT_USER_ACCESS', params);
            } else {
                this.props.createUserAccess(dataToSend);
                analytics.track('ADD_USER_ACCESS', params);
            }
        }
    };

    handleChange = (key, value) => {
        if (!key) return;
        if (this.state[key] !== value) {
            let { validators } = this.state;
            if (key === 'name') {
                validators = this.getValidatorData(value);
                this.name = value;
            }
            this.setState({ [key]: value || '', validators });
        }
    }

    updateNameInput = (value) => {
        this.name = value;
    }

    updateCommentInput = (value) => {
        this.comment = value;
    }

    deleteUserAccess = () => {
        this.props.deleteUserAccess();
    }

    getValidatorData = (name) => {
        const { validators } = this.state;
        if (name.replace(/[\s]*/, '') !== '') {
            validators.name.isError = false;
            validators.name.errorMessages = '';
        } else {
            validators.name.isError = true;
            validators.name.errorMessages = 'this field is required';
        }
        return { ...validators };
    }

    validateData = () => {
        const validators = this.getValidatorData(this.state.name);
        let isValid = true;

        Object.keys(validators).some((field) => {
            if (validators[field].isError) {
                isValid = false;
                return true;
            }
            return false;
        });
        this.setState({ validators });
        return isValid;
    }

    renderTab(tab) {
        const { preloadData } = this.props;
        const AssetCriteriaObj = this.state.assetData || preloadData.assetCriteria || {};
        const LandmarkCriteriaObj = this.state.landmarkData || preloadData.landmarkCriteria || {};
        let tabContent = this.state.value;

        if (tab === 'asset') {
            tabContent = (<AssetCriteria
                assetCriteria={AssetCriteriaObj}
                hideAllAssetsType
                onAssetCriteriaChanged={(assetCriteriaReceived) => {
                    this.assetData = assetCriteriaReceived;
                    this.setState({ assetData: this.assetData });
                }}
                isGrailsAPIReq
                hideTag
            />);
        } else {
            tabContent = (<LandmarkCriteria
                landmarkCriteria={LandmarkCriteriaObj}
                hideAllLandmarksType
                onLandmarkCriteriaChanged={(landmarkCriteriaReceived) => {
                    this.landmarkData = landmarkCriteriaReceived;
                    this.setState({ landmarkData: this.landmarkData });
                }}
            />);
        }
        return tabContent;
    }

    render() {
        const { classes, isEdit } = this.props;
        const {
            name, comment, value, validators,
        } = this.state;

        return (
            <Paper style={{ margin: '20px' }}>
                <ValidatorForm
                    autoComplete="off"
                    noValidate
                    className={classes.form}
                    onSubmit={() => {}}
                >
                    <Toolbar className={classes.toolbar}>
                        <span className={classes.backButton} >
                            <IconButton onClick={() => { this.props.history.goBack(); }} size="large">
                                <img src={back} alt="backArrow" />
                            </IconButton>
                        </span>
                        <Typography variant="h6" color="inherit" className={classes.titleWrap}>
                            <span>{isEdit ? 'Edit' : 'Add'} User Access</span>
                        </Typography>
                    </Toolbar>
                    <Tabs
                        value={value}
                        onChange={this.handleChangeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        color="primary"
                        style={{ backgroundColor: '#343e48' }}
                    >
                        <Tab label="Assets" className={value === 0 ? classes.activeTab : classes.inActiveTab} />
                        <Tab label="Landmarks" className={value === 1 ? classes.activeTab : classes.inActiveTab} />
                    </Tabs>
                    <div className={classes.formFields}>
                        <FormControl>
                            <TextField
                                id="name"
                                label="Name"
                                name="name"
                                key={this.name}
                                defaultValue={name}
                                className={classes.textField}
                                InputLabelProps={{
                                    classes: { root: classes.formLabelRoot },
                                }}
                                margin="normal"
                                onBlur={e => this.handleChange('name', e.target.value)}
                                onChange={e => this.updateNameInput(e.target.value)}
                                required
                                error={validators.name.isError}
                                helperText={validators.name.errorMessages}
                            />
                        </FormControl>
                        <TextField
                            name="comment"
                            label="Comments"
                            key={this.comment}
                            defaultValue={comment}
                            className={classes.textField}
                            InputLabelProps={{
                                classes: { root: classes.formLabelRoot },
                            }}
                            onBlur={e => this.handleChange('comment', e.target.value)}
                            onChange={e => this.updateCommentInput(e.target.value)}
                        />
                    </div>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={this.handleChangeIndex}
                        style={{ margin: '20px' }}
                    >
                        <div style={{ overflow: 'hidden' }} dir={theme.direction}>{this.renderTab('asset')}</div>
                        <div style={{ overflow: 'hidden' }} dir={theme.direction}>{this.renderTab('landmark')}</div>
                    </SwipeableViews>
                    <Divider className={classes.divider} />
                    <Toolbar>
                        <Grid item>
                            <div className={classes.requiredField}>* Required Field</div>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid item>
                                <Button
                                    className={classes.button}
                                    onClick={() => { this.props.history.goBack(); }}
                                >
                                    Cancel
                                </Button>
                                { isEdit && !this.isSystemUser &&
                                <Button
                                    className={classes.button}
                                    onClick={() => { this.deleteUserAccess(); }}
                                    disabled={getPermissionValue('Admin') !== 'Modify'}
                                >
                                    Delete
                                </Button>
                                }
                                { !this.isSystemUser &&
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    onClick={() => this.saveData()}
                                    disabled={getPermissionValue('Admin') !== 'Modify'}
                                >
                                    Save
                                </Button>
                                }
                            </Grid>
                        </Grid>
                    </Toolbar>
                </ValidatorForm>
            </Paper>
        );
    }
}

export default withStyles(styles)(AddUserAccess);
