/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_LANDMARKGROUP, SEARCH_LANDMARKGROUP, PAGE_SIZE } from './constants.landmarkGroup';
import { fetchLandmarkGroupSuccess, fetchLandmarkGroupError, searchLandmarkGroupSuccess, searchLandmarkGroupError } from './actions.landmarkGroup';
import config from '../../../constants/Config';

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    of: Function
}

const foundDataInStore = (storeData, id, of) => {
    let dataFound = false;
    let dataPosition = 0;
    dataFound = storeData.landmarkGroup.landmarkGroup.data.some((d, index) => {
        if (d.id === id) {
            dataFound = true;
            dataPosition = index;
        }
        return dataFound;
    });
    return (!dataFound) ? null : of({
        data: [storeData
            .landmarkGroup.landmarkGroup.data[dataPosition]],
    });
};

const formatData = data => ({
    data: [{
        id: data.id || '',
        name: data.name || '',
        landmarkGroupId: data.landmarkGroupId || '',
        landmarkGroupName: data.landmarkGroupName || '',
        totalAssets: data.totalAssets || '',
        address: data.address || '',
        city: data.city || '',
        state: data.state || '',
        shape: data.shape || '',
        marker: {
            x: (data.marker.x) ? data.marker.x : 0,
            y: (data.marker.y) ? data.marker.y : 0,
        },
    }],
});

let landmarkGroupData = {};

export const getLandmarkGroup =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_LANDMARKGROUP)
            .mergeMap((action) => {
                const storeData = store.getState();
                const headers = {
                    'X-Nspire-UserToken': store.getState().userSession.userToken,
                };
                const { id, pageNumber } = action.payload;
                const landmarkGroupDataFromStore = (storeData.landmarkGroup
                    && storeData.landmarkGroup.landmarkGroup
                    && storeData.landmarkGroup.landmarkGroup.data
                    && storeData.landmarkGroup.landmarkGroup.data.length > 0)
                    ? foundDataInStore(store.getState(), id, of) : null;
                if (!landmarkGroupDataFromStore) { // make the api call
                    return getJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/landmarkgroups/${id}/landmarks?start=${pageNumber}&limit=${PAGE_SIZE}&?cb=${new Date().getTime()}`, headers)
                        .map(result => fetchLandmarkGroupSuccess(result))
                        .takeUntil(actions$.ofType(FETCH_LANDMARKGROUP))
                        .retry(2)
                        .catch(error => of(fetchLandmarkGroupError(error)));
                }
                // take from the store
                return landmarkGroupDataFromStore.mergeMap((res) => {
                    if (res.data) {
                        landmarkGroupData = formatData(res.data[0]);
                    }
                    return [landmarkGroupData];
                }).map(() => fetchLandmarkGroupSuccess(landmarkGroupData))
                    .catch(error => of(fetchLandmarkGroupError(error)));
            });

export const searchLandmarkGroup =
(actions$: Function, store: Object, { getJSON, of, postJSON }: ObservarblesTypes) =>
    actions$
        .ofType(SEARCH_LANDMARKGROUP)
        .distinctUntilChanged()
        .debounceTime(1000)
        .mergeMap((actions) => {
            const {
                payload: {
                    search, pageNumber, id, filters,
                },
            } = actions;
            const headers = {
                'X-Nspire-UserToken': store.getState().userSession.userToken,
                'Content-Type': 'application/json',
            };

            if (filters) {
                return postJSON(
                    `${config.get('FLEET_VIEW_SERVICES_URL')}/landmarks?cb=${new Date().getTime()}`,
                    filters,
                    headers,
                )
                    .map(result => searchLandmarkGroupSuccess(result.response))
                    .takeUntil(actions$.ofType(SEARCH_LANDMARKGROUP))
                    .retry(2)
                    .catch(error => of(searchLandmarkGroupError(error)));
            }
            return getJSON(
                `${config.get('FLEET_VIEW_SERVICES_URL')}/landmarkgroups/${id}/landmarks?search=${search}&start=${pageNumber}&limit=${PAGE_SIZE}&cb=${new Date().getTime()}`,
                headers,
            )
                .map(result => searchLandmarkGroupSuccess(result))
                .takeUntil(actions$.ofType(SEARCH_LANDMARKGROUP))
                .retry(2)
                .catch(error => of(searchLandmarkGroupError(error)));
        });

export default combineEpics(getLandmarkGroup, searchLandmarkGroup);
