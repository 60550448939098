/* @flow */

import * as landmarkGroupConstants from './constants.landmarkGroupManage';
import type { LandmarkGroupManageAction } from './constants.landmarkGroupManage';

export const fetchLandmarks = (
    offset: number,
    limit: number,
    queryConfig: string,
): LandmarkGroupManageAction => ({
    type: landmarkGroupConstants.GRAILS_FETCH_LANDMARKS,
    payload: {
        offset,
        limit,
        queryConfig,
    },
});
export const fetchLandmarksSuccess =
    (landmarks: any): LandmarkGroupManageAction => ({
        type: landmarkGroupConstants.GRAILS_FETCH_LANDMARKS_SUCCESS,
        payload: { landmarks },
    });

export const fetchLandmarksError = (error: string): LandmarkGroupManageAction => ({
    type: landmarkGroupConstants.GRAILS_FETCH_LANDMARKS_ERROR,
    payload: { error },
});
export const postLandmarkGroup = (
    requestBody: Object,
    groupName: string,
    isNewGroupName: boolean,
): LandmarkGroupManageAction => ({
    type: landmarkGroupConstants.GRAILS_POST_LANDMARK_GROUP,
    payload: {
        requestBody,
        groupName,
        isNewGroupName,
    },
});
export const updateLandmarkGroup = (
    requestBody: Object,
    groupName: string,
    isNewGroupName: boolean,
): LandmarkGroupManageAction => ({
    type: landmarkGroupConstants.GRAILS_POST_LANDMARK_GROUP,
    payload: {
        requestBody: requestBody.firstCallData,
        update: true,
        secondCallData: requestBody.secondCallData,
        groupName,
        isNewGroupName,
    },
});
export const showLoader = (isLoader: boolean): LandmarkGroupManageAction => ({
    type: landmarkGroupConstants.SHOW_LOADER,
    payload: {
        showLoader: isLoader,
    },
});
export const updateLandmarkGroupSuccess = (response: any, data: any) => ({
    type: landmarkGroupConstants.GRAILS_POST_LANDMARK_GROUP,
    payload: {
        requestBody: data.secondCallData,
        groupName: data.groupName,
        isNewGroupName: data.isNewGroupName,
    },
});

export const fetchSelectedLandmarks = (queryConfig: Object): LandmarkGroupManageAction => ({
    type: landmarkGroupConstants.GRAILS_FETCH_SELECTED_LANDMARKS,
    payload: { queryConfig },
});

export const fetchSelectedLandmarksSuccess =
    (selectedLandmarks: any): LandmarkGroupManageAction => ({
        type: landmarkGroupConstants.GRAILS_FETCH_SELECTED_LANDMARKS_SUCCESS,
        payload: { selectedLandmarks },
    });
export const postLandmarkGroupSuccess =
    (postResponse: any): LandmarkGroupManageAction => ({
        type: landmarkGroupConstants.GRAILS_POST_LANDMARK_GROUP_SUCCESS,
        payload: { postResponse },
    });
export const fetchLandmarkGroupDetailFromGlobalid =
    (globalId: string): LandmarkGroupManageAction => ({
        type: landmarkGroupConstants.FETCH_LANDMARK_GROUP_DETAILS_GLOBALID,
        payload: { globalId },
    });
export const fetchLandmarkGroupDetailFromGlobalidSuccess =
    (landmarkGroupDetail: string): LandmarkGroupManageAction => ({
        type: landmarkGroupConstants.FETCH_LANDMARK_GROUP_DETAILS_GLOBALID_SUCCESS,
        payload: { landmarkGroupDetail },
    });
export const deleteLandmarkGroupGlobalId =
    (globalId: string): LandmarkGroupManageAction => ({
        type: landmarkGroupConstants.DELETE_LANDMARK_GROUP_GLOBALID,
        payload: { globalId },
    });
export const deleteLandmarkGroupGlobalIdSuccess =
    (deleteResponse: Object): LandmarkGroupManageAction => ({
        type: landmarkGroupConstants.DELETE_LANDMARK_GROUP_GLOBALID_SUCCESS,
        payload: { deleteResponse },
    });

export const fetchLandmarkAndSelectedLandmark = (data: any) => ({
    type: landmarkGroupConstants.LANDMARK_AND_SELECTED_LANDMARK,
    payload: data,
});

export const fetchLandmarkAndSelectedLandmarkSuccess =
    (response: any): LandmarkGroupManageAction => ({
        type: landmarkGroupConstants.GRAILS_FETCHLANDMARK_AND_SELECTED_LANDMARKS_SUCCESS,
        payload: { ...response },
    });

export const fetchLandmarkAndSelectedLandmarkError =
    (error: any): LandmarkGroupManageAction => ({
        type: landmarkGroupConstants.GRAILS_FETCHLANDMARK_AND_SELECTED_LANDMARKS_ERROR,
        payload: { error },
    });

export const closeDialog = () => ({
    type: landmarkGroupConstants.CLOSE_DIALOG,
    payload: {},
});
