/* @flow */
import React, { Component, Fragment } from 'react';
import { Grid, IconButton, Paper, Toolbar, Tooltip, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import customStyles from './EventVideo.module.scss';

import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { getEventFileLink } from '../../../containers/DashCam/helper-classes/dashcamServices';
import { EVENT_TYPE_MAPPING, EVENT_TYPE_COVER_OPENED } from './constants';
import {
    convertDateTime,
    getTimeFormatAsperUserSettting,
    getShortAddressLine1,
    getAddressLine2,
    formatAddress,
} from '../../../helper-classes/utility-functions';
import { videoPlayPromise } from '../../../util/dashcam_util';

export type Props = {
    event: Object,
    dashcam: Object,
    updateLoader: Function,
    setEventVideoPlayerEvent: Function,
    showNotification: Function,
};

export type State = {
    frontCameraVideoUrl: string,
    rearCameraVideoUrl: string,
    isFrontCameraPlaying: boolean,
    isRearCameraPlaying: boolean,
    address: string,
    frontCameraMissing: boolean,
    rearCameraMissing: boolean,
};

class EventVideo extends Component<Props, State> {
    frontCameraPlayerRef: Object;
    rearCameraPlayerRef: Object;

    constructor(props: Props) {
        super(props);
        this.state = this.initState();
        this.frontCameraPlayerRef = React.createRef();
        this.rearCameraPlayerRef = React.createRef();
    }

    componentDidMount() {
        const { dashcam, event } = this.props;
        this.getEventFileLink(dashcam, event);
        this.getAddress(event);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.props.event &&
            this.props.event.id &&
            nextProps.dashcam &&
            nextProps.event &&
            (this.props.event.id !== nextProps.event.id)) {
            this.getEventFileLink(nextProps.dashcam, nextProps.event);
            this.getAddress(nextProps.event);
            this.setState(this.initState());
        }
    }

    initState = () => ({
        isFrontCameraPlaying: true,
        isRearCameraPlaying: true,
        frontCameraVideoUrl: '',
        rearCameraVideoUrl: '',
        address: '',
        frontCameraMissing: false,
        rearCameraMissing: false,
    })

    showLoader = (showLoader: boolean) => this.props.updateLoader(showLoader);

    getEventFileLink = (dashcam: Object, event: Object) => {
        this.getEventCameraFileLink(dashcam, event, 1);
        this.getEventCameraFileLink(dashcam, event, 2);
    };

    getEventCameraFileLink = (dashcam: Object, event: Object, cameraId: number) => {
        this.showLoader(true);
        const fileObj = event.files.find(fileElem => fileElem.cameraId === cameraId);
        if (fileObj) {
            getEventFileLink(
                dashcam.imei,
                fileObj.fileId,
                fileObj.fileType,
                fileObj.cameraId,
            ).then((response) => {
                this.showLoader(false);
                if (response.status === 200) {
                    if (cameraId === 1) {
                        this.setState({
                            frontCameraVideoUrl: response.data ? response.data.url : '',
                        });
                    } else if (cameraId === 2) {
                        this.setState({
                            rearCameraVideoUrl: response.data ? response.data.url : '',
                        });
                    }
                } else if (response.status === 404) {
                    if (cameraId === 1) {
                        this.setState({ frontCameraMissing: true });
                    } else if (cameraId === 2) {
                        this.setState({ rearCameraMissing: true });
                    }
                } else {
                    this.props.showNotification(response.message);
                }
            });
        }
    };

    getAddress = (event: Object) => {
        try {
            const geocoder = window.googleMaps && window.googleMaps.Geocoder
                ? new window.googleMaps.Geocoder() : null;
            const { lat, lon } = event;
            if (geocoder && lat !== -1 && lon !== -1) {
                geocoder.geocode({ location: { lat, lng: lon } }, (results, status) => {
                    if (status === 'OK' && results[0]) {
                        const addrObj = formatAddress(results[0]);
                        const address = `${getShortAddressLine1(addrObj)}, ${getAddressLine2(addrObj)}`;
                        this.setState({ address });
                    } else {
                        this.setState({ address: '' });
                    }
                });
            } else {
                this.setState({ address: '' });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    };

    getFrontCameraVideoElement = () =>
        this.frontCameraPlayerRef.current && this.frontCameraPlayerRef.current.getVideoElement();

    getRearCameraVideoElement = () =>
        this.rearCameraPlayerRef.current && this.rearCameraPlayerRef.current.getVideoElement();

    frontCameraPlayPause = (isPlaying: boolean) => {
        const videoPlayer = this.getFrontCameraVideoElement();
        if (isPlaying) {
            videoPlayPromise(videoPlayer);
        } else {
            videoPlayer.play();
        }
        this.setState({ isFrontCameraPlaying: !isPlaying });
    };

    rearCameraPlayPause = (isPlaying: boolean) => {
        const videoPlayer = this.getRearCameraVideoElement();
        if (isPlaying) {
            videoPlayPromise(videoPlayer);
        } else {
            videoPlayer.play();
        }
        this.setState({ isRearCameraPlaying: !isPlaying });
    };

    handleBackButtonClick = () => {
        this.props.setEventVideoPlayerEvent(null);
    };

    checkMissingCamera = (isVideo: boolean) => {
        let message = '';
        const eventType = isVideo ? 'video' : 'snapshot';
        if (this.state.frontCameraMissing && !this.state.rearCameraMissing) {
            message = `Front camera: This event occurred, but the ${eventType} has not been uploaded yet. Please try again later.`;
        } else if (this.state.rearCameraMissing && !this.state.frontCameraMissing) {
            message = `Rear camera: This event occurred, but the ${eventType} has not been uploaded yet. Please try again later.`;
        } else if (this.state.frontCameraMissing && this.state.rearCameraMissing) {
            message = `This event occurred, but the ${eventType} has not been uploaded yet. Please try again later.`;
        }
        return message;
    }

    render() {
        const { event, dashcam } = this.props;
        const { frontCameraVideoUrl, rearCameraVideoUrl, address } = this.state;

        const eventName = EVENT_TYPE_MAPPING[event.eventType].name;
        const eventDateFormat = getTimeFormatAsperUserSettting() === '12h' ? 'hh:mm:ss A MMM DD YYYY' : 'HH:mm:ss MMM DD YYYY';

        const downloadFileDateFormat = getTimeFormatAsperUserSettting() === '12h' ? 'YYYY-MM-DDThh_mm_ss_A' : 'YYYY-MM-DDThh_mm_ss';
        const downloadFileNamePrefix = `${dashcam.name}_${dashcam.imei}`;
        const downloadFileNameSuffix = `${convertDateTime(event.time, downloadFileDateFormat)}.mp4`;

        if (!dashcam.name) dashcam.name = dashcam.imei;

        const isVideo = !!event.files.find(fileElem => fileElem.fileType === 'video');
        const delayMessage = this.checkMissingCamera(isVideo);

        return (
            <React.Fragment>
                <div className={customStyles.container} >
                    <Paper>
                        <Toolbar className={customStyles.toolbar}>
                            <span className={customStyles.backButton}>
                                <IconButton onClick={() => this.handleBackButtonClick()} size="large">
                                    <ArrowBack />
                                </IconButton>
                            </span>
                            <Grid container justifyContent="flex-start" alignItems="center">
                                <Grid item xs={12} >
                                    <Typography variant="body1" color="inherit" className={customStyles.eventTitle}>
                                        <span>{eventName}</span>
                                    </Typography>
                                    <Typography variant="caption" color="inherit" className={customStyles.eventSubTitle}>
                                        {event.time &&
                                            `${convertDateTime(event.time, eventDateFormat)}${address ? ` | ${address}` : ''}`
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Toolbar>
                        {delayMessage &&
                            <Typography className={!rearCameraVideoUrl && frontCameraVideoUrl ?
                                customStyles.rearDelayMessage : customStyles.delayMessage}
                            >
                                {delayMessage}
                            </Typography>
                        }
                        <Grid container justifyContent="flex-start" alignItems="center" className={customStyles.event_video_container} >
                            {(frontCameraVideoUrl && event.eventType !== EVENT_TYPE_COVER_OPENED) &&
                                <Fragment>
                                    <Grid item xs={12} >
                                        <div className={customStyles.video_header}>
                                            <Tooltip title={isVideo ? `${dashcam.name} - Front` : `${dashcam.name} - Snapshot (Front)`} disableFocusListener placement="right">
                                                <Typography className={customStyles.title}>
                                                    {isVideo ? `${dashcam.name} - Front` : `${dashcam.name} - Snapshot (Front)`}
                                                </Typography>
                                            </Tooltip>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {isVideo &&
                                            <VideoPlayer
                                                playPause={isPlaying =>
                                                    this.frontCameraPlayPause(isPlaying)}
                                                isPlaying={this.state.isFrontCameraPlaying}
                                                ref={this.frontCameraPlayerRef}
                                                recordingPlaybackUrl={frontCameraVideoUrl}
                                                playPauseTimer={false}
                                                download
                                                downloadFileName={`${downloadFileNamePrefix}_Front_${downloadFileNameSuffix}`}
                                                videoEnded={() => {
                                                    this.setState({ isFrontCameraPlaying: false });
                                                }}
                                            />
                                        }
                                        {!isVideo &&
                                            <div>
                                                <img src={frontCameraVideoUrl} width="560px" alt="" />
                                            </div>
                                        }
                                    </Grid>
                                </Fragment>
                            }
                            <Grid item xs={12}>
                                <br />
                            </Grid>
                            {rearCameraVideoUrl &&
                                <Fragment>
                                    <Grid item xs={12} >
                                        <div className={customStyles.video_header}>
                                            <Tooltip title={isVideo ? `${dashcam.name} - Rear` : `${dashcam.name} - Snapshot (Rear)`} disableFocusListener placement="right">
                                                <Typography className={customStyles.title}>
                                                    {isVideo ? `${dashcam.name} - Rear` : `${dashcam.name} - Snapshot (Rear)`}
                                                </Typography>
                                            </Tooltip>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {isVideo &&
                                            <VideoPlayer
                                                playPause={isPlaying =>
                                                    this.rearCameraPlayPause(isPlaying)
                                                }
                                                isPlaying={this.state.isRearCameraPlaying}
                                                ref={this.rearCameraPlayerRef}
                                                recordingPlaybackUrl={rearCameraVideoUrl}
                                                playPauseTimer={false}
                                                download
                                                downloadFileName={`${downloadFileNamePrefix}_Rear_${downloadFileNameSuffix}`}
                                                videoEnded={() => {
                                                    this.setState({ isRearCameraPlaying: false });
                                                }}
                                                muted
                                            />
                                        }
                                        {!isVideo &&
                                            <div>
                                                <img src={rearCameraVideoUrl} width="560px" alt="" />
                                            </div>
                                        }
                                    </Grid>
                                </Fragment>
                            }
                            <Grid item xs={12}>
                                <br />
                            </Grid>
                            <Grid item xs={12}>
                                <br />
                            </Grid>
                            <Grid item xs={12}>
                                <br />
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </React.Fragment>
        );
    }
}

export default EventVideo;
