import { ACTIVE, CLEARED } from './constants.diagnosticList';

const expectedSelections = [ACTIVE, CLEARED];

export function applyRadioSelectionFilter(selectedFilters, currentRadioSelection) {
    // this removes any previous Radio Button Filter
    const newSelectedFilter = selectedFilters.filter(filter => filter.property !== 'active');
    if (currentRadioSelection && expectedSelections.includes(currentRadioSelection)) {
        const radioFilter = {
            id: '99',
            property: 'active',
            value: undefined,
            data: {
                id: '99',
            },
        };
        if (currentRadioSelection === ACTIVE) {
            radioFilter.value = true;
        } else if (currentRadioSelection === CLEARED) {
            radioFilter.value = false;
        }
        newSelectedFilter.push(radioFilter);
    }
    return newSelectedFilter;
}

export function applyPreselectedAssetIdFilter(selectedFilters, assetId) {
    const alreadyHaveAssetSelected = selectedFilters.find(filter => filter.property === 'assetId');
    if (!assetId) {
        return selectedFilters;
    }
    if (alreadyHaveAssetSelected) {
        for (let i = 0; i < selectedFilters.length; i += 1) {
            if (selectedFilters[i].property === 'assetId') {
                selectedFilters.splice(i, 1);
            }
        }
    }
    selectedFilters.push({
        id: assetId,
        property: 'assetId',
        value: assetId,
        data: {
            id: assetId,
        },
    });
    return selectedFilters;
}
