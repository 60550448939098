import React from 'react';
import { TextField, FormHelperText, Stack, Checkbox } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import styles from '../../Steppers/AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';
import AsyncSelectWrapper from '../../../SharedComponents/AsyncSelectWrapper';
import dropdownNested from '../../../../assets/icons/arrow-down.svg';
import stylesAsset from '../../../SharedComponents/AssetCriteria/AssetCriteriaStyles';
import { getSpeedUnitString, convertUnitValueAsPerUserSetting } from '../../../../helper-classes/utility-functions';
import { fetchLandmarkList } from '../../../../containers/Maintenance/helper-classes/common-services';

type Props = {
    formData:{
        config:{
            speedThresholdMPH: Number,
            selectedLandmarkGroups: any,
            selectedLandmarkFilters: any,
        },
    },
    handleTextboxChange: Function,
    handleCheckboxChange: Function,
    clearLandmarkSelects: Function,
    allLandmarkGroups: any,
    validationError: String,
};

type State = {
    allLandmarkGroups: any,
    landmarks: any,
    unit: string,
    confineToLandmark: boolean,
};

class SpeedThresholdAlert extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        const landmarks = props.landmarks || {};

        landmarks.groups = props.formData.config.selectedLandmarkGroups || landmarks.groups;
        landmarks.landmarks = props.formData.config.selectedLandmarkFilters || landmarks.landmarks;
        this.state = {
            landmarks,
            allLandmarkGroups: [],
            unit: '',
            confineToLandmark: props.formData.config.confineToLandmark || false,
        };
    }

    UNSAFE_componentWillMount() {
        const unit = getSpeedUnitString();
        this.setState({
            unit,
        });
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        const { allLandmarkGroups, formData } = nextprops;
        const { stateAllLandmarkGroups, confineToLandmark } = this.state;
        if (allLandmarkGroups && allLandmarkGroups.length > 0 &&
            stateAllLandmarkGroups !== allLandmarkGroups) {
            this.setState({
                allLandmarkGroups,
            });
        }

        if (confineToLandmark !== formData.config.confineToLandmark) {
            this.setState({
                confineToLandmark: formData.config.confineToLandmark,
            });
        }
    }

    labelForType = type => (type === 'groups' ? 'name' : 'landmarks')

    loadOptions = (type, inputValue) =>
        new Promise((resolve) => {
            const loadCriteria = this.props.allLandmarkGroups;
            let optionData = loadCriteria.map(datum => ({
                value: datum.id,
                label: datum.name,
                data: datum,
            }));
            if (inputValue) {
                optionData = optionData.filter(option => (
                    option.label.toLowerCase().includes(inputValue.toLowerCase())
                ));
            }
            resolve(optionData);
        });

    onItemSelected = (type, selected) => {
        const { landmarks } = this.state;
        if (type === 'groups') {
            if (landmarks.landmarks.length >= 1 && landmarks[type].length === 0) {
                landmarks.landmarks=[];
                this.props.clearLandmarkSelects(landmarks.landmarks);
            }
        }
        if (landmarks[type].filter(item => item.id === selected.data.id).length === 0) {
            landmarks[type].push(selected.data);
            this.setState({ landmarks }, () => {
                this.props.handleTextboxChange(type)({
                    target: {
                        value: landmarks[type],
                    },
                });
            });
        }
    }

    handleDelete = (type, data) => {
        const { landmarks } = this.state;
        const landmarksGroups = landmarks[type];
        const chipToDelete = landmarksGroups.indexOf(data);
        landmarksGroups.splice(chipToDelete, 1);
        landmarks[type] = landmarksGroups;
        if (type === 'groups') {
            const deletlandmarklist = landmarks.landmarks.filter(item =>
                item.parentGroupId !== data.id);
            landmarks.landmarks = deletlandmarklist;
            this.setState({
                ...this.state,
                landmarks,
            }, () => {
                this.props.handleTextboxChange(type)({
                    target: {
                        value: landmarks[type],
                    },
                });
                this.props.clearLandmarkSelects(deletlandmarklist);
            });
        } else {
            this.setState({
                ...this.state,
                landmarks,
            }, () => {
                this.props.handleTextboxChange(type)({
                    target: {
                        value: landmarks[type],
                    },
                });
            });
        }
    }

    loadNext = loadedOptions => new Promise((resolve, reject) => {
        fetchLandmarkList(loadedOptions.length, this.props.formData.config.selectedLandmarkGroups)
            .then((response) => {
                const { landmarkList, data } = response;
                if (data && data.total) {
                    const hasMore = (loadedOptions.length + data.data.length) < data.total;
                    if (data) {
                        const optionData = landmarkList.map(datum => ({
                            value: datum.id,
                            label: datum.name,
                            data: datum,
                        }));
                        resolve({ options: optionData, hasMore });
                    } else reject();
                } else reject();
            });
    });

    updateConfineToLandmark = (e) => {
        const { landmarks } = this.state;
        const { checked } = e.target;

        if (!checked) {
            landmarks.groups = [];
            landmarks.landmarks = [];
        }
        this.setState({
            confineToLandmark: checked,
            landmarks,
        }, () => {
            this.props.handleCheckboxChange('confineToLandmark')(e);
            if (!checked) {
                this.props.handleTextboxChange('selectedLandmarkGroups')({
                    target: {
                        value: landmarks.groups,
                    },
                });
                this.props.handleTextboxChange('selectedLandmarkFilters')({
                    target: {
                        value: landmarks.landmarks,
                    },
                });
            }
        });
    }

    getLandmarkGroupSection = (disableChips) => {
        let landmarkGroupSection = null;
        if (this.state.allLandmarkGroups !== undefined && this.state.allLandmarkGroups.length > 0) {
            const type = 'groups';
            landmarkGroupSection = (
                <div>
                    <Grid container spacing={5}>
                        <Grid item xs={10}>
                            <FormControl className={styles['landmark-selection']}>
                                <AsyncSelectWrapper
                                    key={`${type}_${disableChips}`}
                                    handleDelete={item => this.handleDelete(type, item.id)}
                                    loadOptions={inputValue => this.loadOptions(type, inputValue)}
                                    onItemSelected={selected => this.onItemSelected(type, selected)}
                                    title="All Groups"
                                    disableChips={disableChips}
                                    disablePaging
                                    styles={disableChips ? { container: stylesAsset.selectInternalContainer } : { select: { width: '20%' } }}
                                    selectStyles={{
                                        control: () => ({
                                            display: 'flex',
                                            border: 'none',
                                            boxShadow: 'none !important',
                                        }),
                                        option: (s, { data }) => {
                                            const obj = data.data || {};
                                            if (obj.nestLevel) {
                                                return {
                                                    ...s,
                                                    marginLeft: 8 + (obj.nestLevel * 12),
                                                    paddingLeft: obj.nestLevel ? 24 : s.paddingLeft,
                                                    backgroundImage: `url(${dropdownNested})`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'left center',
                                                };
                                            }
                                            return s;
                                        },
                                    }}
                                    menuPlacement="bottom"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        return landmarkGroupSection;
    }

    getLandmarkSelection = (disableChips) => {
        let landmarksSection = null;
        const type = 'landmarks';
        const landmarkList = this.state.allLandmarkGroups;
        const lmgIds = this.props.formData.config.groups ?
            this.props.formData.config.groups.map(lmg => lmg.id) :
            this.props.formData.config.groups;
        if (this.props.formData.config.landmarks) {
            for (let i = 0; i < this.state.landmarks.landmarks.length; i+=1) {
                for (let j = 0; j < this.props.formData.config.landmarks.length; j+=1) {
                    if (this.state.landmarks.landmarks[i].value ===
                        this.props.formData.config.landmarks[j].id) {
                        this.state.landmarks.landmarks[i] = this.props.formData.config.landmarks[j];
                    }
                }
            }
        }
        landmarksSection = (
            <div style={{ width: '100%' }}>
                <Grid container spacing={5}>
                    <Grid item xs={10}>
                        <FormControl className={styles['landmark-selection']}>
                            <AsyncSelectWrapper
                                key={`${type}_${disableChips}_${lmgIds}`}
                                handleDelete={item => this.handleDelete(type, item.id)}
                                selectedOptions={landmarkList.map(item => ({
                                    value: item.id,
                                    label: item.name,
                                    data: item,
                                }))}
                                loadOptions={(inputValue, loadedOptions) =>
                                    this.loadNext(loadedOptions)}
                                onItemSelected={selected => this.onItemSelected(type, selected)}
                                title="All Landmarks"
                                disableChips={disableChips}
                                styles={disableChips ? { container: stylesAsset.selectInternalContainer } : { select: { width: '20%' } }}
                                selectStyles={{
                                    control: () => ({
                                        display: 'flex',
                                        border: 'none',
                                        boxShadow: 'none !important',
                                    }),
                                    option: (s, { data }) => {
                                        const obj = data.data || {};
                                        if (obj.nestLevel) {
                                            return {
                                                ...s,
                                                marginLeft: 8 + (obj.nestLevel * 12),
                                                paddingLeft: obj.nestLevel ? 24 : s.paddingLeft,
                                                backgroundImage: `url(${dropdownNested})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'left center',
                                            };
                                        }
                                        return s;
                                    },
                                }}
                                menuPlacement="bottom"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        );
        return landmarksSection;
    }

    render() {
        let { speedThresholdMPH = 0 } = this.props.formData.config;
        const { confineToLandmark } = this.state;
        const disableChips = true;
        if (speedThresholdMPH) {
            speedThresholdMPH = Math.round(speedThresholdMPH);
        }
        const minValue = convertUnitValueAsPerUserSetting(ALERT_DETAILS.VALUE.ST_MIN, 'round');
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider-speed-alert']}>
                    <div className={styles['step-rightside-speed-alert']}>
                        <form noValidate autoComplete="off">
                            <Stack direction="row" alignItems="center">
                                <div style={{ paddingTop: 16 }}>
                                    {ALERT_DETAILS.LABEL.SPEED_THRESHOLD}
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <TextField
                                        required
                                        margin="normal"
                                        sx={{ width: 70, pl: 1 }}
                                        id="speedThresholdMPH"
                                        name="speedThresholdMPH"
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: minValue,
                                                max: ALERT_DETAILS.VALUE.ST_MAX,
                                                style: { textAlign: 'center' },
                                                onKeyDown: (e) => {
                                                    // Prevent 'e', '+', '-', and '.'
                                                    // from being entered
                                                    if (['e', '+', '-', '.'].includes(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                },
                                            },
                                        }}
                                        value={speedThresholdMPH}
                                        onKeyPress={(event) => {
                                            if (event.charCode === 46) {
                                                event.preventDefault();
                                                return false;
                                            }
                                            return true;
                                        }}
                                        onChange={(e) => {
                                            let inputValue = e.target.value;

                                            if (inputValue !== '') {
                                                inputValue = (Math.trunc(inputValue)).toString();
                                                if (inputValue.indexOf('.') !== -1) {
                                                    inputValue = inputValue.substring(1, inputValue.indexOf('.'));
                                                }
                                            }
                                            this.props.handleTextboxChange('speedThresholdMPH')({
                                                target: {
                                                    value: inputValue,
                                                },
                                            });
                                        }}
                                    />
                                    <span className={`${styles['unit-Name']} ${styles['unit-Name-space']}`}>{this.state.unit}*</span>
                                    <FormHelperText className={styles['form-helper-text']}>
                                        {this.props.validationError}
                                    </FormHelperText>
                                </div>
                            </Stack>
                            <Stack
                                direction="row"
                                sx={{ pt: 2.5, alignItems: 'center' }}
                            >
                                <Stack>
                                    <Checkbox
                                        disableRipple
                                        sx={{ p: 0 }}
                                        onChange={this.updateConfineToLandmark}
                                        checked={confineToLandmark}
                                    />
                                </Stack>
                                <Stack sx={{ pl: 2 }}>
                                    Confine to Landmark
                                </Stack>
                            </Stack>
                            {confineToLandmark &&
                                <>
                                    <div style={{ paddingTop: 20 }}>
                                        <div>{ALERT_DETAILS.LABEL.LANDMARK_GROUP}</div>
                                        {this.getLandmarkGroupSection(disableChips)}
                                    </div>
                                    <div>
                                        {
                                            this.state.landmarks.groups.map(data => (
                                                <Chip
                                                    id={data.id}
                                                    className={styles['chip-spacing']}
                                                    key={data.id}
                                                    label={data.name}
                                                    onDelete={() => this.handleDelete('groups', data)}
                                                />
                                            ))
                                        }
                                    </div>
                                    <div className={styles['div-pad']}>
                                        <div>{ALERT_DETAILS.LABEL.LANDMARK}</div>
                                        {this.getLandmarkSelection(disableChips)}
                                    </div>
                                    <div>
                                        {
                                            this.state.landmarks.landmarks.map(data => (
                                                <Chip
                                                    id={data.id}
                                                    className={styles['chip-spacing']}
                                                    key={data.id ? data.id : data.value}
                                                    label={data.name}
                                                    onDelete={() => this.handleDelete('landmarks', data)}
                                                />
                                            ))
                                        }
                                    </div>
                                </>
                            }
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default SpeedThresholdAlert;
