export const FETCH_RECIPIENT_LIST_ADMIN: string = 'FETCH_RECIPIENT_LIST_ADMIN';
export const FETCH_RECIPIENT_LIST_SUCCESS_ADMIN: string = 'FETCH_RECIPIENT_LIST_SUCCESS_ADMIN';
export const FETCH_RECIPIENT_LIST_ERROR_ADMIN: string = 'FETCH_RECIPIENT_LIST_ERROR_ADMIN';

export const FETCH_RECIPIENT_ALERTS: string = 'FETCH_RECIPIENT_ALERTS';
export const FETCH_RECIPIENT_ALERTS_SUCCESS: string = 'FETCH_RECIPIENT_ALERTS_SUCCESS';
export const FETCH_RECIPIENT_ALERTS_FAILURE: string = 'FETCH_RECIPIENT_ALERTS_FAILURE';
export const RESET_RECIPIENT_ALERT_LIST = 'RESET_RECIPIENT_ALERT_LIST';

export const DELETE_RECIPIENT_ALERTS: string = 'DELETE_RECIPIENT_ALERTS';
export const DELETE_RECIPIENT_ALERTS_SUCCESS: string = 'DELETE_RECIPIENT_ALERTS_SUCCESS';
export const DELETE_RECIPIENT_ALERTS_FAILURE: string = 'DELETE_RECIPIENT_ALERTS_FAILURE';

export const ENTER_OR_EMAIL_MOBILE = 'Please enter a mobile number or email address in the search bar above';
export const INPUT_PLACE_HOLDER = 'Enter Recipient Email or Mobile Number';
export const DELETE_ALERT_CONFIRM_MSG = 'Are you sure you want to remove this recipient?';
export const DELETE_ALERT_SUCCESS_MSG = 'Delete Alert/Report Successful';
export const RECIPIENT_LIST_PREF_KEY = 'fleetLocate.recipientList';

export type RecipientListTypeAction = {
    type: string;
    payload?: Object;
}
export type AlertListTypeAction = {
    type: string;
    payload?: Object;
}

export type DeleteAlertTypeAction = {
    type: string;
    payload?: Object;
}
