import React from 'react';
import { Divider, Checkbox, Card, Button, Select, MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import AssetGroupList from './AssetGroupList';
import styles from './AssetGroupManageStyles';
import customStyles from './AssetGroupManage.module.scss';
import { getPermissionValue, getParentGroupsRelation } from '../../helper-classes/utility-functions';
import analytics from '../../analytics/index';
import { getAssetGroupsFVS, getAssetGroupById } from './../../services/assets/assetGroup';
import dropdownNested from '../../assets/icons/dropdown-nested.svg';

type Props = {
    assets: Object,
    totalAssets: number,
    loadAssets: Function,
    resetAssets: Function,
    isAssetsListUpdating: boolean,
    postAssetGroup: Function,
    updateAssetGroup: Function,
    selectedAssets: Object,
    actionType: string,
    selectedAssetGroup: Object,
    parentGroup: Object,
    history: Object,
    isAssetGroupDetailsFetching: boolean,
    showLoader: Function,
};
type State = {
    selectedAssets: [],
    groupName: string,
    assetGroups: [],
    assetParentGroups: Array<Object>,
    parentGroupSelected: number,
};

class AssetGroupManage extends React.Component<Props, State> {
    props: Props;
    state = {
        selectedAssets: [],
        assetGroups: [],
        assetParentGroups: [],
        parentGroupSelected: -1,
    };

    componentDidMount = () => {
        getAssetGroupsFVS().then((response) => {
            if (response && response.data) {
                this.setState({
                    assetGroups: response.data,
                    assetParentGroups: getParentGroupsRelation(response.data),
                });
            }
        });
        this.setState({
            selectedAssets: [],
        });
        ValidatorForm.addValidationRule('isMin3Characters', (value) => {
            if (value.length < 3) {
                return false;
            }
            return true;
        });
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState({ selectedAssets: nextProps.selectedAssets });

        if (nextProps.parentGroup && nextProps.parentGroup.id) {
            this.setState({
                parentGroupSelected: nextProps.parentGroup.id,
            });
        }

        if (nextProps.selectedAssetGroup && nextProps.selectedAssetGroup.name
            && this.props.selectedAssetGroup.name !== nextProps.selectedAssetGroup.name) {
            this.setState({
                groupName: nextProps.selectedAssetGroup.name,
                parentGroupSelected: nextProps.selectedAssetGroup.parentGroupId || -1,
            });
        }
    }

    resetSelectedAssets = () => {
        this.setState({ selectedAssets: [] });
        this.resetAssets([]);
    }

    selectAssets = (assetsToAdd) => {
        this.resetAssets([...this.state.selectedAssets, assetsToAdd]);
    }

    addBulkAssets = (assetsToAdd) => {
        let { selectedAssets } = this.state;
        selectedAssets = selectedAssets.concat(assetsToAdd);
        this.setState({ selectedAssets });
        this.resetAssets(selectedAssets);
    }

    removeAsset = (assetToRemove) => {
        const selectedAssets = this.state.selectedAssets.filter(asset => (
            assetToRemove.id !== asset.id
        ));
        this.resetAssets(selectedAssets);
    }

    resetAssets = (selectedAssets) => {
        const params = {
            selectedAssets,
            selectedAssetGroups: this.props.selectedAssetGroup,
            clearResult: true,
        };
        this.props.resetAssets(params);
    }

    clickSelectedElement = row => (event) => {
        if (!event.target.checked) {
            this.removeAsset(row);
        }
    }

    getSelectedAssets() {
        const { selectedAssets } = this.state;
        return selectedAssets.map(row => (
            <tr className={customStyles['table-tr']} key={row.id}>
                <td className={customStyles['checkbox-td']} >
                    <Checkbox
                        onChange={this.clickSelectedElement(row)}
                        style={{ padding: 0, color: '#007aff' }}
                        checked
                    />
                </td>
                <td>{row.name}</td>
                <td>{row.deviceSerialNumber}</td>
            </tr>
        ));
    }

    saveAssetGroup = () => {
        this.props.showLoader(true);
        const members = this.state.selectedAssets.map(a => a.deviceSerialNumber);
        const requestBody = {
            newRecords: [],
            removedRecords: [],
            updatedRecords: [],
        };
        const { groupName } = this.state;
        const parentGroupId = this.state.parentGroupSelected
            || parseInt(this.props.parentGroup.id, 10);

        const params = {
            feature: 'FLEET_Asset_Group',
            group: this.state.parentGroupSelected !== -1 ? 'true' : 'false',
            selectedAssets: this.state.selectedAssets.length ? 'true' : 'false',
        };
        if (this.props.actionType === 'add') {
            requestBody.newRecords = [
                {
                    description: groupName,
                    id: 0,
                    members,
                    name: groupName,
                    parentGroupId,
                },
            ];

            if (parentGroupId && parentGroupId !== -1) {
                getAssetGroupById(parentGroupId).then((res) => {
                    requestBody.newRecords[0].parentGroupId = res.data.id;
                    this.props.postAssetGroup(requestBody, groupName);
                });
            } else {
                this.props.postAssetGroup(requestBody, groupName);
            }
            if (this.props.parentGroup.name !== '') {
                analytics.track('ADD_SUBGROUP', params);
            } else analytics.track('ADD_ASSET_GROUP', params);
        } else if (this.props.actionType === 'edit') {
            getAssetGroupById(this.props.selectedAssetGroup.id).then((res) => {
                const selectedAssetGroup = res.data;
                requestBody.updatedRecords = [
                    {
                        description: groupName,
                        id: selectedAssetGroup.id || 0,
                        members,
                        name: groupName,
                        parentGroupId,
                    },
                ];

                if (this.state.parentGroupSelected === -1 ||
                    this.state.parentGroupSelected ===
                        this.props.selectedAssetGroup.parentGroupId) {
                    if (this.state.parentGroupSelected !== -1) {
                        requestBody.updatedRecords[0].parentGroupId =
                        selectedAssetGroup.parentGroupId;
                    }
                    this.props.postAssetGroup(requestBody, groupName);
                } else {
                    const filterResult = this.state.assetParentGroups
                        .filter(f => f.id === this.state.parentGroupSelected).pop() || {};
                    const selectedAssetGroupData = this.state.assetParentGroups
                        .filter(f => f.id === this.props.selectedAssetGroup.id).pop() || {};

                    getAssetGroupById(filterResult.id).then((pGroupData) => {
                        const selectedPGroupData = pGroupData.data;
                        let firstCallUpdatedRecords = [
                            {
                                description: groupName,
                                id: selectedAssetGroup.id || 0,
                                members,
                                name: groupName,
                                parentGroupId: selectedAssetGroup.parentGroupId || -1,
                            },
                            {
                                description: selectedPGroupData.name || '',
                                id: selectedPGroupData.id || 0,
                                name: selectedPGroupData.name || '',
                                parentGroupId: -1,
                            },
                        ];
                        let secondCallUpdateRecord = [{
                            description: groupName,
                            id: selectedAssetGroup.id || 0,
                            name: groupName,
                            parentGroupId: selectedPGroupData.id || -1,
                        },
                        {
                            description: selectedPGroupData.name || '',
                            id: selectedPGroupData.id || 0,
                            name: selectedPGroupData.name || '',
                            parentGroupId: selectedAssetGroup.parentGroupId || -1,
                        }];

                        if (selectedAssetGroupData.superParent !== filterResult.superParent) {
                            firstCallUpdatedRecords = [
                                {
                                    description: groupName,
                                    id: selectedAssetGroup.id || 0,
                                    members,
                                    name: groupName,
                                    parentGroupId: -1,
                                },
                            ];
                            secondCallUpdateRecord = [
                                {
                                    description: groupName,
                                    id: selectedAssetGroup.id || 0,
                                    name: groupName,
                                    parentGroupId: selectedPGroupData.id || -1,
                                },
                            ];
                        } else if (!filterResult.parentId ||
                                    filterResult.parentId <= 0
                        ) {
                            secondCallUpdateRecord = [
                                {
                                    description: groupName,
                                    id: selectedAssetGroup.id || 0,
                                    name: groupName,
                                    parentGroupId: selectedPGroupData.id || -1,
                                },
                            ];
                        }

                        const dataForUpdate = {
                            firstCallData: {
                                newRecords: [],
                                removedRecords: [],
                                updatedRecords: firstCallUpdatedRecords,
                            },
                            secondCallData: {
                                newRecords: [],
                                removedRecords: [],
                                updatedRecords: secondCallUpdateRecord,
                            },
                        };
                        this.props.updateAssetGroup(dataForUpdate, groupName);
                    });
                }
            });
            analytics.track('EDIT_ASSET_GROUP', params);
        }
    };

    renderCustomCriteriaSection = () => {
        const { selectedAssets } = this.state;

        return (
            <div >
                <Divider />
                <div style={styles.selectedAssetsContainer}>
                    <div style={styles.column}>
                        <AssetGroupList
                            selectedAssets={selectedAssets}
                            onAssetsSelected={(selected, asset) => {
                                if (selected) {
                                    this.selectAssets(asset);
                                } else {
                                    this.removeAsset(asset);
                                }
                            }}
                            assets={this.props.assets}
                            totalAssets={this.props.totalAssets}
                            loadAssets={this.props.loadAssets}
                            isAssetsListUpdating={this.props.isAssetsListUpdating}
                            addBulkAssets={this.addBulkAssets}
                            isAssetGroupDetailsFetching={this.props.isAssetGroupDetailsFetching}
                        />
                    </div>
                    <div style={styles.column}>
                        <div style={styles.assetsHeader}>
                            <span className={customStyles['selected-heading-text']}>
                                Selected Assets
                                <div className={customStyles['selected-page-info']}>
                                    Showing&nbsp;
                                    {this.state.selectedAssets &&
                                    this.state.selectedAssets.length ?
                                        this.state.selectedAssets.length : 0 }
                                    &nbsp;results
                                </div>
                            </span>
                            <button
                                type="button"
                                style={styles.reset}
                                onClick={
                                    this.state.selectedAssets &&
                                    this.state.selectedAssets.length > 0 ?
                                        this.resetSelectedAssets
                                        :
                                        () => {}
                                }
                            >
                                <span
                                    className={
                                        this.state.selectedAssets &&
                                        this.state.selectedAssets.length > 0 ?
                                            customStyles.resetText
                                            :
                                            customStyles.diabledResetText
                                    }
                                >
                                    RESET
                                </span>
                            </button>
                        </div>
                        <div style={styles.selectedWrapper}>
                            <table>
                                <tbody>
                                    {this.getSelectedAssets()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    changeGroupName = () => (event) => {
        this.setState({
            groupName: event.target.value,
        });
    };

    goToassetListing = () => {
        this.props.history.push('/asset-groups');
    }

    getParentGrpDropdownOptions = () => {
        if (this.state.assetGroups.length <= 0) {
            return false;
        }
        const value = this.state.assetGroups.map((grp) => {
            if (this.props.selectedAssetGroup.id
                && this.props.selectedAssetGroup.id === grp.id) {
                return false;
            }

            const selected = grp.id === this.state.parentGroupSelected;
            const options = (
                <MenuItem
                    key={grp.id}
                    value={grp.id}
                    selected={selected}
                >
                    <span
                        style={{ paddingLeft: grp.nestLevel * 15 }}
                    >
                        {(grp.nestLevel !== 0) ? <img style={{ paddingRight: 6 }} src={dropdownNested} alt="dropdownNested" /> : ''}{`${grp.name}`}
                    </span>
                </MenuItem>
            );

            return options;
        });

        return value;
    }

    onParentDropdownChange = (e) => {
        this.setState({ parentGroupSelected: e.target.value });
    }

    getDropDownValue = (e) => {
        let menu = { name: 'Select Group' };
        this.state.assetGroups.some((d) => {
            if (d.id === e) {
                menu = d;
                return true;
            }
            return false;
        });

        return menu.name;
    }

    render() {
        let section = null;
        section = this.renderCustomCriteriaSection();
        const { groupName } = this.state;
        const { parentGroup, actionType } = this.props;

        return (
            <Card className={customStyles['content-wrapper']}>
                <div className={customStyles['header-wrapper']}>
                    <div
                        className={customStyles['back-arrow-icon']}
                        tabIndex={0}
                        onClick={this.goToassetListing}
                        onKeyDown={() => {}}
                        role="button"
                    />
                    <div className={customStyles['header-text']}>
                        {parentGroup && parentGroup.id !== 0 ?
                            <div>
                                <div>{actionType === 'add' ? 'Add' : 'Edit'} Sub Group</div>
                                <div className={customStyles['subgroup-text']}>
                                    {parentGroup.name}
                                </div>
                            </div>
                            :
                            <div>{actionType === 'add' ? 'Add' : 'Edit'} Asset Group</div>
                        }
                    </div>
                    <div className={customStyles['header-pull-right']}>
                        <span className={customStyles['required-text']}>* Required Field</span>
                    </div>
                </div>
                <ValidatorForm
                    onSubmit={this.saveAssetGroup}
                >
                    <div style={styles.container}>
                        <div style={styles.header}>
                            <TextValidator
                                style={styles.textField}
                                value={groupName || ''}
                                onChange={this.changeGroupName()}
                                label="*Name"
                                name="name"
                                validators={['required', 'isMin3Characters']}
                                errorMessages={['Name is required', 'Name should be atleast 3 characters']}
                                autoComplete="off"
                            />
                            <div style={styles.parentGrpDropdown}>
                                <Select
                                    value={this.state.parentGroupSelected}
                                    onChange={(e) => {
                                        this.onParentDropdownChange(e);
                                    }}
                                    inputProps={{
                                        name: 'asset-groups',
                                        id: 'asset-groups',
                                    }}
                                    renderValue={e => this.getDropDownValue(e)}
                                    sx={{ minWidth: '125px' }}
                                >
                                    <MenuItem
                                        key="select-parent-group"
                                        value={-1}
                                        selected={this.state.parentGroupSelected === -1}
                                    >
                                        Select Group
                                    </MenuItem>
                                    {this.getParentGrpDropdownOptions()}
                                </Select>
                            </div>
                        </div>
                        {section}
                    </div>
                    <div className={customStyles['content-footer']}>
                        <div className={customStyles['action-buttons-wrapper']}>
                            <Button onClick={this.goToassetListing} className={customStyles['cancel-button-label']}>
                                CANCEL
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disableRipple
                                type="submit"
                                disabled={getPermissionValue('Admin') !== 'Modify'}
                                sx={{ minHeight: '36px !important' }}
                            >
                                <span className={customStyles['save-button-label']}>SAVE</span>
                            </Button>
                        </div>
                    </div>
                </ValidatorForm>
            </Card>
        );
    }
}
export default withStyles(styles)(AssetGroupManage);
