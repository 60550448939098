/* @flow */

export const FETCH_USER_ROLES_LIST: string = 'FETCH_USER_ROLES_LIST';
export const FETCH_USER_ROLES_LIST_SUCCESS: string = 'FETCH_USER_ROLES_LIST_SUCCESS';
export const FETCH_USER_ROLES_LIST_ERROR: string = 'FETCH_USER_ROLES_LIST_ERROR';
export const RESET_USER_ROLES_LIST: string = 'RESET_USER_ROLES_LIST';
export const USER_ROLES_PREF_KEY: string = 'fleetLocate.userRoleList';
export type UserRoleListTypeAction = {
    type: string;
    payload?: Object;
}
