/* @flow */

import {
    ACCOUNT_DETAIL, ACCOUNT_DETAIL_SUCCESS, ACCOUNT_DETAIL_ERROR,
    SAVE_ACCOUNT_DETAIL, SAVE_ACCOUNT_DETAIL_SUCCESS, SAVE_ACCOUNT_DETAIL_ERROR,
    SAVE_APPSETTINGS, SAVE_APPSETTINGS_SUCCESS, SAVE_APPSETTINGS_ERROR,
    SAVE_PREFERENCES, SAVE_PREFERENCES_SUCCESS, SAVE_PREFERENCES_ERROR,
    GET_ACCOUNT_DETAIL, GET_USER_BY_IDENTITY_SUCCESS, GET_USER_SETTINGS, GET_USER_SETTINGS_ERROR,
    ACCEPT_RESELLER_TERMS_AND_CONDITION,
    ACCEPT_RESELLER_TERMS_AND_CONDITION_SUCCESS,
    ACCEPT_RESELLER_TERMS_AND_CONDITION_ERROR,
} from './constants.settings';

import { updateLoader } from '../Header/actions.globalsearch';
import { FETCH_LOGGED_IN_USER_SUCCESS } from '../Login/contants.login';

export const accountDetail = (id: string) => ({
    type: ACCOUNT_DETAIL,
    payload: { id },
});

export const accountDetailByIdentity = () => ({
    type: GET_ACCOUNT_DETAIL,
    payload: {},
});

export const getUserSettings = () => ({
    type: GET_USER_SETTINGS,
    payload: {},
});

export const getUserSettingsSuccess = (user: any, userSettings: any) => ({
    type: FETCH_LOGGED_IN_USER_SUCCESS,
    payload: {
        user,
        userSettings,
    },
});

export const getUserSettingsError = (error: any) => ({
    type: GET_USER_SETTINGS_ERROR,
    payload: { error },
});

export const accountDetailByIdentitySuccess = (response: any) => ({
    type: GET_USER_BY_IDENTITY_SUCCESS,
    payload: { response },
});

export const accountDetailSuccess = (response: any) => ({
    type: ACCOUNT_DETAIL_SUCCESS,
    payload: { response },
    meta: {
        analytics: {
            type: ACCOUNT_DETAIL_SUCCESS,
        },
    },
});

export const accountDetailError = (error: any) => ({
    type: ACCOUNT_DETAIL_ERROR,
    payload: { error },
    meta: {
        analytics: {
            type: ACCOUNT_DETAIL_ERROR,
        },
    },
});

export const saveAccountDetail = (data: {}, id: string) => ({
    type: SAVE_ACCOUNT_DETAIL,
    payload: {
        data,
        id,
    },
});

export const getAccountDetail = (data: {}, id: string) => ({
    type: GET_ACCOUNT_DETAIL,
    payload: {
        data,
        id,
    },
});

export const saveAccountDetailSuccess = (response: any) => ({
    type: SAVE_ACCOUNT_DETAIL_SUCCESS,
    payload: { response },
});

export const saveAccountDetailError = (error: any) => ({
    type: SAVE_ACCOUNT_DETAIL_ERROR,
    payload: { error },
    meta: {
        analytics: {
            type: SAVE_ACCOUNT_DETAIL_ERROR,
        },
    },
});

export const saveAppSettings = (data: {}) => ({
    type: SAVE_APPSETTINGS,
    payload: { data },
});

export const saveAppSettingsSuccess = (response: any) => ({
    type: SAVE_APPSETTINGS_SUCCESS,
    payload: { response },
});


export const saveAppSettingsError = (error: any) => ({
    type: SAVE_APPSETTINGS_ERROR,
    payload: { error },
    meta: {
        analytics: {
            type: SAVE_APPSETTINGS_ERROR,
        },
    },
});

export const savePreferences = (data: Object) => ({
    type: SAVE_PREFERENCES,
    payload: { data },
});

export const savePreferencesSuccess = (response: any) => ({
    type: SAVE_PREFERENCES_SUCCESS,
    payload: { response },
});

export const savePreferencesError = (error: any) => ({
    type: SAVE_PREFERENCES_ERROR,
    payload: { error },
    meta: {
        analytics: {
            type: SAVE_PREFERENCES_ERROR,
        },
    },
});

export const updateUserLoader = updateLoader;

export const acceptResellerTermsAndCondition = (id: string) => ({
    type: ACCEPT_RESELLER_TERMS_AND_CONDITION,
    payload: { id },
});

export const acceptResellerTermsAndConditionSuccess = (data: {}) => ({
    type: ACCEPT_RESELLER_TERMS_AND_CONDITION_SUCCESS,
    payload: { data },
});

export const acceptResellerTermsAndConditionError = (data: {}) => ({
    type: ACCEPT_RESELLER_TERMS_AND_CONDITION_ERROR,
    payload: { data },
});
